import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import type { ReportScheduleFilterPredicate } from '@/features/report-schedules/types';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import ReportScheduleStatusFilterItem from '../ReportScheduleStatusFilterItem';
import ReportScheduleTemplateFilterItem from '../ReportScheduleTemplateFilterItem';

import type { ReportScheduleFilterFormProps } from './types';

const fields = [
  nameof<ReportScheduleFilterPredicate>('createdRange'),
  nameof<ReportScheduleFilterPredicate>('statuses'),
  nameof<ReportScheduleFilterPredicate>('templates'),
];

const createdMessages = {
  to: { label: <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_FILTER_FORM_CREATED_AT_TO_LABEL} /> },
  from: { label: <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_FILTER_FORM_CREATED_AT_FROM_LABEL} /> },
};

const ReportScheduleFilterFormRaw: React.FC<ReportScheduleFilterFormProps> = (props) => {
  const { 'data-test': dataTest } = props;

  return (
    <FilterForm<ReportScheduleFilterPredicate> {...props} fields={fields}>
      <DateTimeRangeItem<ReportScheduleFilterPredicate>
        data-test={dataTest && `${dataTest}-createdAt`}
        name={nameof<ReportScheduleFilterPredicate>('createdRange')}
        messages={createdMessages}
        required={false}
      />
      <ReportScheduleStatusFilterItem<ReportScheduleFilterPredicate>
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<ReportScheduleFilterPredicate>('statuses')}
        required={false}
      />
      <ReportScheduleTemplateFilterItem<ReportScheduleFilterPredicate>
        data-test={dataTest && `${dataTest}-template`}
        name={nameof<ReportScheduleFilterPredicate>('templates')}
        required={false}
      />
    </FilterForm>
  );
};

const ReportScheduleFilterForm = React.memo(ReportScheduleFilterFormRaw);

export default ReportScheduleFilterForm;
