import { Input, Space, Tag } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useCallback, useMemo } from 'react';

import MoneyAmount from '../MoneyAmount';

import type { MoneyAmountInputProps } from './types';
import type { ChangeEvent } from 'react';

const MoneyAmountInput: React.FC<MoneyAmountInputProps> = ({
  'data-test': dataTest,
  readonly,
  value,
  nonNegative,
  asset: { title, precision },
  onChange,
  separators: { decimal },
  noLabelOnHelp,
  InputProps = {},
}) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.currentTarget.value;
      const parsed = new BigNumber(inputValue);
      const valid = parsed.isFinite() && !parsed.isNaN() && !(nonNegative && parsed.isNegative());
      onChange?.({ value: parsed, valid, inputValue });
    },
    [nonNegative, onChange],
  );
  const placeholder = useMemo(
    () => `${nonNegative ? '' : '-'}00${decimal}${'0'.repeat(precision)}`,
    [nonNegative, precision, decimal],
  );

  return (
    <>
      <Input
        className="ym-hide-content"
        data-test={dataTest}
        disabled={readonly}
        value={value?.inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        {...InputProps}
      />
      {value?.value
        && value.valid
        && (noLabelOnHelp ? (
          <MoneyAmount value={value.value} data-test={dataTest && `${dataTest}-label`} />
        ) : (
          <Space>
            <Tag>{title}</Tag>
            <MoneyAmount value={value.value} data-test={dataTest && `${dataTest}-label`} />
          </Space>
        ))}
    </>
  );
};

const MoneyAmountInputMemo = React.memo(MoneyAmountInput);

export default MoneyAmountInputMemo;
