import React from 'react';

import { DocumentationLink } from '@/components';

import type { SettlementDocumentationLinkProps } from './types';

const SettlementDocumentationLink: React.FC<SettlementDocumentationLinkProps> = ({
  'data-test': dataTest,
  mode,
  title,
}) => <DocumentationLink mode={mode} title={title} data-test={dataTest} path="/merchant-onboarding/withdraw-funds" />;

const SettlementDocumentationLinkMemo = React.memo(SettlementDocumentationLink);

export default SettlementDocumentationLinkMemo;
