import { CHAIN_NAMESPACES } from '@web3auth/base';
import { EthereumSigningProvider } from '@web3auth/ethereum-mpc-provider';
import { makeEthereumSigner } from '@web3auth/mpc-core-kit';
import toString from 'lodash/toString';
import { getAddress, SwitchChainError, UserRejectedRequestError } from 'viem';
import { createConnector } from 'wagmi';

import type { Web3Auth } from '@/infrastructure/security/web3-auth';
import { assertNotNil } from '@/infrastructure/utils/functions';
import { notEmpty, asType } from '@/infrastructure/utils/ts';

import type { CustomChainConfig } from '@web3auth/base';
import type { CreateConnectorFn, Connector } from 'wagmi';

function normalizeChainId(chainId: string | number | bigint) {
  if (typeof chainId === 'string') return Number.parseInt(chainId, chainId.trim().startsWith('0x') ? 16 : 10);
  if (typeof chainId === 'bigint') return Number(chainId);
  return chainId;
}

export const Web3AuthConnector = 'web3auth';
export const Web3AuthConnectorId = `${Web3AuthConnector}Id`;

const createWebAuthConnector = ({ web3Auth }: { web3Auth: Web3Auth }): CreateConnectorFn => {
  let accountsChanged: Connector['onAccountsChanged'] | undefined;
  let chainChanged: Connector['onChainChanged'] | undefined;
  let disconnect: Connector['onDisconnect'] | undefined;
  let walletProvider: EthereumSigningProvider | undefined;

  return createConnector<EthereumSigningProvider>((config) =>
    asType<ReturnType<CreateConnectorFn<EthereumSigningProvider>>>({
      id: Web3AuthConnectorId,
      name: 'Web3Auth',
      type: Web3AuthConnector,

      async connect({ chainId } = {}) {
        if (!web3Auth.isConfirmed) {
          console.warn('Web3Auth is not ready');
          throw new UserRejectedRequestError(new Error('Web3Auth is not ready'));
        }
        try {
          config.emitter.emit('message', { type: 'connecting' });

          const provider = await this.getProvider();
          if (!accountsChanged) {
            accountsChanged = this.onAccountsChanged.bind(this);
            provider.on('accountsChanged', accountsChanged);
          }
          if (!chainChanged) {
            chainChanged = this.onChainChanged.bind(this);
            provider.on('chainChanged', chainChanged);
          }
          if (!disconnect) {
            disconnect = this.onDisconnect.bind(this);
            provider.on('disconnect', disconnect);
          }

          // Switch to chain if provided
          let currentChainId = await this.getChainId();
          if (chainId && currentChainId !== chainId) {
            const chain = await this.switchChain!({ chainId }).catch((error: unknown) => {
              if (
                error
                && typeof error === 'object'
                && 'code' in error
                && typeof error.code === 'number'
                && error.code === UserRejectedRequestError.code
              ) {
                // eslint-disable-next-line @typescript-eslint/only-throw-error
                throw error;
              }
              return { id: currentChainId };
            });
            currentChainId = chain.id;
          }
          const accounts = await this.getAccounts();
          return { accounts, chainId: currentChainId };
        } catch (error) {
          console.error('error while connecting', error);
          this.onDisconnect();
          throw new UserRejectedRequestError(new Error('Something went wrong'));
        }
      },
      async getAccounts() {
        const provider = await this.getProvider();
        return (
          (await provider.request<unknown, string[]>({
            method: 'eth_accounts',
          })) ?? []
        )
          .filter(notEmpty)
          .map((x) => getAddress(x));
      },
      async getProvider({ chainId } = {}): Promise<EthereumSigningProvider> {
        if (walletProvider) {
          return walletProvider;
        }
        if (!web3Auth.isConfirmed || !web3Auth.isAuthorized()) {
          console.warn('Web3Auth is not ready');
          throw new UserRejectedRequestError(new Error('Web3Auth is not ready'));
        }
        const networks = config.chains
          .map((chain) => ({
            chainNamespace: CHAIN_NAMESPACES.EIP155,
            chainId: `0x${chain.id.toString(16)}`,
            rpcTarget: chain.rpcUrls.default.http[0],
            displayName: chain.name,
            blockExplorer: chain.blockExplorers?.default.url[0] || '',
            ticker: chain.nativeCurrency.symbol || 'ETH',
            tickerName: chain.nativeCurrency.name || 'Ethereum',
            decimals: chain.nativeCurrency.decimals || 18,
          }))
          .reduce(
            (result, chain) => ({ ...result, [chain.chainId]: chain }),
            asType<Partial<Record<string, CustomChainConfig>>>({}),
          );

        const storageChainIdStr = toString(
          await config.storage?.getItem(window.env.WEB3AUTH_CONNECTOR_SELECTED_CHAIN_ID_KEY),
        );
        const storageChainId = storageChainIdStr ? parseInt(storageChainIdStr, 10) : NaN;
        const storedChainId = chainId ?? (!Number.isNaN(storageChainId) ? storageChainId : networks[0]?.chainId) ?? 1;
        const selectedChainIdHex = `0x${storedChainId.toString(16)}`;
        const selectedChain = networks[selectedChainIdHex];
        if (!selectedChain) {
          console.warn(`Chain is unsupported ${storedChainId}`);
          throw new UserRejectedRequestError(new Error(`Chain is unsupported ${storedChainId}`));
        }
        walletProvider = new EthereumSigningProvider({
          config: { networks: networks as Record<string, CustomChainConfig>, chainConfig: selectedChain },
          state: {
            chainId: selectedChain.chainId,
            name: selectedChain.displayName,
          },
        });
        const provider = walletProvider;
        Object.values(networks)
          .filter(notEmpty)
          .forEach((chain) => provider.addChain(chain));
        await walletProvider.setupProvider(makeEthereumSigner(web3Auth.instance));
        await config.storage?.setItem(window.env.WEB3AUTH_CONNECTOR_SELECTED_CHAIN_ID_KEY, `${storedChainId}`);
        return walletProvider;
      },
      async isAuthorized() {
        try {
          const account = await this.getAccounts();
          return !!account.length;
        } catch {
          return false;
        }
      },
      async getChainId(): Promise<number> {
        assertNotNil(walletProvider, () => new Error('Web3Auth is not initialized'));
        const chainId = await walletProvider.request<unknown, string>({ method: 'eth_chainId' });
        assertNotNil(chainId, () => new Error(`Unable to get chain id.`));
        return normalizeChainId(chainId);
      },
      async switchChain({ chainId }) {
        try {
          const chain = config.chains.find((x) => x.id === chainId);
          assertNotNil(chain, () => new Error(`Chain ${chainId} is not found on the connector.`));
          assertNotNil(walletProvider, () => new Error('Web3Auth is not initialized'));
          await walletProvider.switchChain({ chainId: `0x${chain.id.toString(16)}` });
          await config.storage?.setItem(window.env.WEB3AUTH_CONNECTOR_SELECTED_CHAIN_ID_KEY, `${chainId}`);
          return chain;
        } catch (error: unknown) {
          console.error('Error: Cannot change chain', error);
          throw new SwitchChainError(error as Error);
        }
      },

      // eslint-disable-next-line @typescript-eslint/require-await
      async disconnect(): Promise<void> {
        if (!walletProvider) {
          return;
        }

        if (accountsChanged) {
          walletProvider.removeListener('accountsChanged', accountsChanged);
          accountsChanged = undefined;
        }
        if (chainChanged) {
          walletProvider.removeListener('chainChanged', chainChanged);
          chainChanged = undefined;
        }
        if (disconnect) {
          walletProvider.removeListener('disconnect', disconnect);
          disconnect = undefined;
        }
      },

      onAccountsChanged(accounts) {
        if (accounts.length === 0) this.onDisconnect();
        else
          config.emitter.emit('change', {
            accounts: accounts.map((x) => getAddress(x)),
          });
      },
      onChainChanged(chain) {
        const chainId = Number(chain);
        config.emitter.emit('change', { chainId });
      },
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      async onDisconnect() {
        config.emitter.emit('disconnect');

        const provider = await this.getProvider();
        if (accountsChanged) {
          provider.removeListener('accountsChanged', accountsChanged);
          accountsChanged = undefined;
        }
        if (chainChanged) {
          provider.removeListener('chainChanged', chainChanged);
          chainChanged = undefined;
        }
        if (disconnect) {
          provider.removeListener('disconnect', disconnect);
          disconnect = undefined;
        }
      },
    }),
  );
};

export default createWebAuthConnector;
