/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { PaymentStatusAPIModel } from './PaymentStatusAPIModel';
import {
    PaymentStatusAPIModelFromJSON,
    PaymentStatusAPIModelFromJSONTyped,
    PaymentStatusAPIModelToJSON,
} from './PaymentStatusAPIModel';

/**
 * 
 * @export
 * @interface PaymentPredicatesAPIModel
 */
export interface PaymentPredicatesAPIModel {
    /**
     * 
     * @type {Array<PaymentStatusAPIModel>}
     * @memberof PaymentPredicatesAPIModel
     */
    statusIn?: Array<PaymentStatusAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PaymentPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof PaymentPredicatesAPIModel
     */
    createdRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof PaymentPredicatesAPIModel
     */
    network?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the PaymentPredicatesAPIModel interface.
 */
export function instanceOfPaymentPredicatesAPIModel(value: object): boolean {
    return true;
}

export function PaymentPredicatesAPIModelFromJSON(json: any): PaymentPredicatesAPIModel {
    return PaymentPredicatesAPIModelFromJSONTyped(json, false);
}

export function PaymentPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(PaymentStatusAPIModelFromJSON)),
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'createdRange': json['createdRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['createdRange']),
        'network': json['network'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['network']),
    };
}

export function PaymentPredicatesAPIModelToJSON(value?: PaymentPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(PaymentStatusAPIModelToJSON)),
        'idIn': value['idIn'],
        'createdRange': DateTimeRangeAPIModelToJSON(value['createdRange']),
        'network': BlockchainNetworkTypeAPIModelToJSON(value['network']),
    };
}

