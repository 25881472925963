import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'branding';

export interface BrandingProfile {
  id: string;
  name: string;
}

export interface BrandingProfileWithActive extends BrandingProfile {
  active: boolean;
}

export interface MerchantDomain {
  id: string;
  url: string;
  isApproved: boolean;
  isActive: boolean;
}

export enum BrandingLogoPosition {
  'top' = 'top',
  'bottom' = 'bottom',
}

export enum BrandingLang {
  'en' = 'en',
  'es' = 'es',
  'fr' = 'fr',
  'it' = 'it',
  'ko' = 'ko',
  'ru' = 'ru',
}

export enum BrandingAuth {
  'email' = 'email',
  'google' = 'google',
  'metamask' = 'metamask',
  'coinbase' = 'coinbase',
  'wallet-connect' = 'wallet-connect',
}

export enum BrandingOnRamp {
  'moonpay' = 'moonpay',
  'mercuryo' = 'mercuryo',
  'on-ramper' = 'on-ramper',
}

export interface PaymentsBranding {
  bgColor?: string;
  accentColor?: string;

  logo?: {
    visible: boolean;
    url: string;
    position: BrandingLogoPosition;
  };

  poweredBy?: {
    visible: boolean;
    customText?: { enabled: boolean; text: string };
  };

  supportedLang: BrandingLang[];
  contactEmail?: string;
  redirectURL?: string;
  redirectParam?: string;

  auth: BrandingAuth[];

  onRamp: BrandingOnRamp[];
}

export interface MerchantBrandingCommon {
  activeProfile?: string;
  profiles: BrandingProfile[];
}

export interface MerchantBrandingProfileContent {
  name: string;
  data: PaymentsBranding;
}

export interface MerchantBrandingProfileFull extends MerchantBrandingProfileContent {
  id: string;
}

export interface MerchantBrandingProfileExtended extends MerchantBrandingProfileFull {
  active: boolean;
  qr: BlockchainTypeAPIModel[];
}

export type BrandingData = PaymentsBranding & { qr: BlockchainTypeAPIModel[] };

export interface BrandingState {
  branding: LoadingStateWithDirty<MerchantBrandingCommon>;
  profiles: SingleState<PaymentsBranding>;
  tos: LoadingStateWithDirty<string>;
  domains: LoadingStateWithDirty<MerchantDomain[]>;
}
