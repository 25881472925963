import { Form, Select } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import MerchantWalletTransferReconciliationStatusView from '@/features/merchant-wallet-transfers/components/MerchantWalletTransferReconciliationStatusView';
import { MerchantWalletTransferReconciliationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { MerchantWalletTransferReconciliationStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const MerchantWalletTransferReconciliationStatusFilterItem = <Values extends Store = Store>({
  name,
  'data-test': dataTest,
  readonly,
  ItemProps = {},
}: MerchantWalletTransferReconciliationStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={
      <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_RECONCILIATION_STATUS_LABEL} />
    }
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      mode="multiple"
      disabled={readonly}
      placeholder={
        !readonly ? (
          <FormattedMessage
            id={I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_RECONCILIATION_STATUS_PLACEHOLDER}
          />
        ) : undefined
      }
      options={useMemo(
        () =>
          enumValues(MerchantWalletTransferReconciliationStatusAPIModel).map((code) => ({
            value: code,
            label: (
              <MerchantWalletTransferReconciliationStatusView
                value={code}
                mode="full"
                data-test={dataTest && `${dataTest}-status`}
              />
            ),
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

export default MerchantWalletTransferReconciliationStatusFilterItem;
