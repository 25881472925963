import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useAddressSignedUp } from '@/features/auth/hooks';
import type { WalletOperationClientAction, WalletOperationProps } from '@/features/web3/components';
import { WalletOperation } from '@/features/web3/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { emptyWith } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

import type { WalletLoginProps } from './types';

const InProgressDescription: React.FC<{ account?: string; provider: string }> = ({ provider, account }) => (
  <FormattedMessage
    id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_DESCRIPTION}
    values={{ provider: <b>{provider}</b>, account: account && <b>{account}</b> }}
  />
);
const inProgressMessages = { Description: InProgressDescription };

const notificationOptions = {
  error: (error: unknown) =>
    (error as Partial<Error | undefined>)?.message?.includes('wallet_requestPermissions')
      ? {
          message: <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_TITLE} />,
          description: (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_DESCRIPTION} />
          ),
        }
      : undefined,
};

const showAccount = { tooltip: <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_ACCOUNT_TOOLTIP} /> };

const WalletLogin: React.FC<WalletLoginProps> = ({
  'data-test': dataTest,
  login,
  inProgress: baseInProgress,
  disabled,
  withConnect: withConnectBase = emptyWith,
  inProgressMode,
}) => {
  const { account, connector } = useWeb3Connector();
  const { data: isNewAccount, loading: accountLoading } = useAddressSignedUp(account);
  const { withNotification } = useNotification();

  const doLogin = useCallback<WalletOperationClientAction>(
    async ({ account, client }) => {
      if (account) {
        await login({ connectorId: connector.id, address: account, client });
      }
    },
    [connector.id, login],
  );
  const withConnect = useMemo(
    () =>
      <V extends unknown[], R>(func: Func<V, R>): Func<V> =>
        withConnectBase(withNotification(func, notificationOptions)),
    [withConnectBase, withNotification],
  );
  return (
    <WalletOperation
      data-test={dataTest}
      connectorId={connector.id}
      inProgress={accountLoading || isNewAccount.isDirty || baseInProgress}
      disabled={disabled}
      showAccount={showAccount}
      withConnect={withConnect}
      inProgressMode={inProgressMode}
      inProgressMessages={inProgressMessages}
      mainAction={useMemo<WalletOperationProps['mainAction']>(
        () => ({
          withClient: true,
          onAction: doLogin,
          runOnConnect: true,
          title: !isNewAccount.data ? (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_SIGNUP_TITLE} />
          ) : (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WALLET_LOGIN_LOGIN_TITLE} />
          ),
          'data-test': dataTest && `${dataTest}-${!isNewAccount.data ? 'signup' : 'login'}`,
        }),
        [dataTest, doLogin, isNewAccount.data],
      )}
    />
  );
};

const WalletLoginHOC = withWeb3Connector(WalletLogin);

const WalletLoginMemo = React.memo(WalletLoginHOC) as typeof WalletLoginHOC;

export default WalletLoginMemo;
