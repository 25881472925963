/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  UserCompaniesAPIModel,
  UserMerchantWalletCreateAPIModel,
  UserMerchantWalletsAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    UserCompaniesAPIModelFromJSON,
    UserCompaniesAPIModelToJSON,
    UserMerchantWalletCreateAPIModelFromJSON,
    UserMerchantWalletCreateAPIModelToJSON,
    UserMerchantWalletsAPIModelFromJSON,
    UserMerchantWalletsAPIModelToJSON,
} from '../models/index';

export interface AddUserWalletRequest {
    userMerchantWalletCreateAPIModel: UserMerchantWalletCreateAPIModel;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * Creates a merchant wallet with no part
     * @param {UserMerchantWalletCreateAPIModel} userMerchantWalletCreateAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    addUserWalletRaw(requestParameters: AddUserWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMerchantWalletsAPIModel>>;

    /**
     * Creates a merchant wallet with no part
     */
    addUserWallet(userMerchantWalletCreateAPIModel: UserMerchantWalletCreateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMerchantWalletsAPIModel>;

    /**
     * Returns companies that this user has access to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompaniesAPIModel>>;

    /**
     * Returns companies that this user has access to
     */
    getUserCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompaniesAPIModel>;

    /**
     * Returns currently authenticated user merchant wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMerchantWalletsAPIModel>>;

    /**
     * Returns currently authenticated user merchant wallets
     */
    getUserWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMerchantWalletsAPIModel>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     * Creates a merchant wallet with no part
     */
    async addUserWalletRaw(requestParameters: AddUserWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMerchantWalletsAPIModel>> {
        if (requestParameters['userMerchantWalletCreateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'userMerchantWalletCreateAPIModel',
                'Required parameter "userMerchantWalletCreateAPIModel" was null or undefined when calling addUserWallet().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/wallets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMerchantWalletCreateAPIModelToJSON(requestParameters['userMerchantWalletCreateAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMerchantWalletsAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates a merchant wallet with no part
     */
    async addUserWallet(userMerchantWalletCreateAPIModel: UserMerchantWalletCreateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMerchantWalletsAPIModel> {
        const response = await this.addUserWalletRaw({ userMerchantWalletCreateAPIModel: userMerchantWalletCreateAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns companies that this user has access to
     */
    async getUserCompaniesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCompaniesAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/companies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCompaniesAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns companies that this user has access to
     */
    async getUserCompanies(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCompaniesAPIModel> {
        const response = await this.getUserCompaniesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns currently authenticated user merchant wallets
     */
    async getUserWalletsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMerchantWalletsAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/wallets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMerchantWalletsAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns currently authenticated user merchant wallets
     */
    async getUserWallets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMerchantWalletsAPIModel> {
        const response = await this.getUserWalletsRaw(initOverrides);
        return await response.value();
    }

}
