import { Space } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { SettlementPendingIntentsTable } from '@/features/settlements/components';
import { useScheduledIntents } from '@/features/settlements/hooks';
import { pendingIntentFilter } from '@/features/settlements/selectors';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { mapStoredState } from '@/infrastructure/model';

import type { SettlementPendingIntentsCardProps } from './types';

const SettlementPendingIntentsCard: React.FC<SettlementPendingIntentsCardProps> = (props) => {
  const { data: allData, loading, forceRefresh } = useScheduledIntents();
  const data = useMemo(
    () => mapStoredState(allData, (nonFiltered) => nonFiltered.filter(pendingIntentFilter)),
    [allData],
  );

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageSettlements.PENDING_COMPONENTS_INTENTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
        </Space>
      }
      cardSize="big"
    >
      <SettlementPendingIntentsTable {...props} data={data} loading={loading} forceRefresh={forceRefresh} />
    </PageCard>
  );
};

const SettlementPendingIntentsCardMemo = React.memo(SettlementPendingIntentsCard);

export default SettlementPendingIntentsCardMemo;
