import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { initLocale, updateLocale } from '@/features/i18n/actions';
import { makeSelectLocale, makeSelectLocaleIsInitialized } from '@/features/i18n/selectors';
import type { I18nLocales } from '@/generated/i18n/i18n';

export interface UseLocaleType {
  init: () => Promise<unknown>;
  update: (locale: I18nLocales) => Promise<unknown>;
  locale: I18nLocales;
  initializing: boolean;
  initialized: boolean;
}

const dataSelector = makeSelectLocale();
const dataInitializedSelector = makeSelectLocaleIsInitialized();

export default function useLocale(): UseLocaleType {
  const { withVoidDispatch } = useAppDispatch();
  const init = useMemo(() => withVoidDispatch(initLocale), [withVoidDispatch]);
  const update = useMemo(() => withVoidDispatch(updateLocale), [withVoidDispatch]);
  const locale = useAppSelector(dataSelector);
  const initialized = useAppSelector(dataInitializedSelector);
  const initializing = useActionPending(initLocale);

  return { init, initialized, locale, update, initializing };
}
