import { useCallback, useMemo } from 'react';

import { DataFetchError, FormattedMessage, PageCard, PageContainer } from '@/components';
import { ReportScheduleForm } from '@/features/report-schedules/components';
import { useReportSchedule, useReportScheduleActions } from '@/features/report-schedules/hooks';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { withPageParams } from '@/hocs';
import { requiredParamsParser } from '@/hocs/withPageParams/utils';
import { useDefaultNotification, useFormPageAction, useNotification, useStateMountSafe } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { reportScheduleViewLink } from '@/pages/reports/routes';

import { ReportScheduleEditBreadcrumb } from './components';

import type React from 'react';

interface ReportScheduleEditPageProps extends TestableProps {
  pageParams: { id: string };
}

const ReportScheduleEditPage: React.FC<ReportScheduleEditPageProps> = ({
  'data-test': dataTest = 'schedule-edit',
  pageParams: { id },
}) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { update: updateAction } = useReportScheduleActions(id);
  const { data: initialValues, forceRefresh } = useReportSchedule(id);
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(reportScheduleViewLink(id)), [id, redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);
  const doSave = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(updateAction.act, () => (
            <FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_EDIT_MESSAGES_SUCCESS} />
          )),
          () => <FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_EDIT_MESSAGES_ERROR} />,
        ),
        doRedirect,
      ),
    [withDefaultError, withSuccess, doRedirect, updateAction],
  );

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={
        <ReportScheduleEditBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} scheduleId={id} disabled />
      }
      submit={useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit])}
      cancel={doRedirect}
    >
      <PageCard loading={!initialValues} cardSize="big">
        {
          /* eslint-disable-next-line no-nested-ternary */
          !initialValues.data ? (
            !initialValues.isDirty ? (
              <DataFetchError refresh={forceRefresh} back message={initialValues.error} />
            ) : null
          ) : (
            <ReportScheduleForm
              data-test={dataTest && `${dataTest}-form`}
              initialValues={initialValues.data}
              onSubmit={doSave}
              onReset={doRedirect}
              submitCallback={updateOnSubmit}
            />
          )
        }
      </PageCard>
    </PageContainer>
  );
};

const ReportScheduleEditPageParams = withPageParams(requiredParamsParser(['id']))(ReportScheduleEditPage);

export default ReportScheduleEditPageParams;
