import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { usePayment } from '@/features/payments/hooks';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { OperationRefreshPaymentProps } from './types';

const messages = {
  title: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_TITLE} />,
  tooltip: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_SUCCESS_MESSAGE} />,
  },
  error: {
    message: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_FAILED_MESSAGE} />,
    description: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_REFRESH_PAYMENT_FAILED_DESCRIPTION} />,
  },
};

const OperationRefreshPaymentRaw: React.FC<OperationRefreshPaymentProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  paymentId,
}) => {
  const { forceRefresh } = usePayment(paymentId);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshPayment = React.memo(OperationRefreshPaymentRaw);

export default OperationRefreshPayment;
