import { SaveOutlined, StopOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem, Operation } from '@/components';
import type { BrandingProfileFormValues } from '@/features/branding/components/BrandingProfileForm/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { BrandingProfileNameEditPanelProps } from './types';
import type React from 'react';

const minNameLength = 4;
const maxNameLength = 50;

const BrandingProfileNameEditPanelPanel: React.FC<BrandingProfileNameEditPanelProps> = ({
  'data-test': dataTest,
  submitting,
  onSubmit,
  onReset,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Space style={{ alignItems: 'flex-start' }}>
      <InputItem<BrandingProfileFormValues>
        data-test={dataTest && `${dataTest}-name`}
        name={nameof<BrandingProfileFormValues>('name')}
        InputProps={{ style: { width: 300 }, disabled: submitting }}
        placeholder={formatMessage({
          id: I18nFeatureBranding.COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_PLACEHOLDER,
        })}
        messages={{
          required: (
            <FormattedMessage
              id={I18nFeatureBranding.COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_ERROR_REQUIRED}
            />
          ),
        }}
        rules={[
          {
            transform: (v?: string) => v?.trim(),
            max: maxNameLength,
            message: (
              <FormattedMessage
                id={I18nFeatureBranding.COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_ERROR_MAX}
                values={{ value: maxNameLength }}
              />
            ),
          },
          {
            transform: (v?: string) => v?.trim(),
            min: minNameLength,
            message: (
              <FormattedMessage
                id={I18nFeatureBranding.COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_ERROR_MIN}
                values={{ value: minNameLength }}
              />
            ),
          },
        ]}
      />
      <Operation
        data-test={dataTest && `${dataTest}-submit`}
        mode="inline"
        title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_SUBMIT_TITLE} />}
        icon={<SaveOutlined style={{ color: 'green' }} />}
        inProgress={submitting}
        disabled={!onSubmit}
        onClick={onSubmit ?? noop}
      />
      <Operation
        data-test={dataTest && `${dataTest}-reset`}
        mode="inline"
        title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_RESET_TITLE} />}
        icon={<StopOutlined style={{ color: 'red' }} />}
        disabled={!onReset || submitting}
        onClick={onReset ?? noop}
      />
    </Space>
  );
};

export default BrandingProfileNameEditPanelPanel;
