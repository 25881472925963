import React, { useCallback } from 'react';

import { FormattedMessage, InProgressView } from '@/components';
import Web3ProviderIcon from '@/features/web3/components/Web3ProviderIcon';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import type { StyledProps } from '@/infrastructure/utils/react';

import type { WalletInProgressProps } from './types';

const WalletInProgress: React.FC<WalletInProgressProps> = ({ 'data-test': dataTest, cancel, connector, message }) => {
  const WalletIcon = useCallback(
    (props: StyledProps) => <Web3ProviderIcon value={connector.id} {...props} />,
    [connector.id],
  );
  return (
    <InProgressView
      data-test={dataTest}
      cancel={cancel}
      Icon={WalletIcon}
      message={
        message ?? (
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_IN_PROGRESS_MESSAGE}
            values={{ provider: connector.name }}
          />
        )
      }
    />
  );
};
const WalletInProgressMemo = React.memo(WalletInProgress);

export default WalletInProgressMemo;
