/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { SubscriptionChargeStatusAPIModel } from './SubscriptionChargeStatusAPIModel';
import {
    SubscriptionChargeStatusAPIModelFromJSON,
    SubscriptionChargeStatusAPIModelFromJSONTyped,
    SubscriptionChargeStatusAPIModelToJSON,
} from './SubscriptionChargeStatusAPIModel';

/**
 * 
 * @export
 * @interface SubscriptionChargeAPIModel
 */
export interface SubscriptionChargeAPIModel {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargeAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargeAPIModel
     */
    contractAddress: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionChargeAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof SubscriptionChargeAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargeAPIModel
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargeAPIModel
     */
    planId: string;
    /**
     * 
     * @type {Date}
     * @memberof SubscriptionChargeAPIModel
     */
    chargeDate: Date;
    /**
     * 
     * @type {SubscriptionChargeStatusAPIModel}
     * @memberof SubscriptionChargeAPIModel
     */
    status: SubscriptionChargeStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargeAPIModel
     */
    hash?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargeAPIModel
     */
    blockNum?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionChargeAPIModel
     */
    withdrawnWith?: string;
}

/**
 * Check if a given object implements the SubscriptionChargeAPIModel interface.
 */
export function instanceOfSubscriptionChargeAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('contractAddress' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('planId' in value)) return false;
    if (!('chargeDate' in value)) return false;
    if (!('status' in value)) return false;
    return true;
}

export function SubscriptionChargeAPIModelFromJSON(json: any): SubscriptionChargeAPIModel {
    return SubscriptionChargeAPIModelFromJSONTyped(json, false);
}

export function SubscriptionChargeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionChargeAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'contractAddress': json['contractAddress'],
        'companyId': json['companyId'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'amount': json['amount'],
        'planId': json['planId'],
        'chargeDate': (new Date(json['chargeDate'])),
        'status': SubscriptionChargeStatusAPIModelFromJSON(json['status']),
        'hash': json['hash'] == null ? undefined : json['hash'],
        'blockNum': json['blockNum'] == null ? undefined : json['blockNum'],
        'withdrawnWith': json['withdrawnWith'] == null ? undefined : json['withdrawnWith'],
    };
}

export function SubscriptionChargeAPIModelToJSON(value?: SubscriptionChargeAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'contractAddress': value['contractAddress'],
        'companyId': value['companyId'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'amount': value['amount'],
        'planId': value['planId'],
        'chargeDate': ((value['chargeDate']).toISOString()),
        'status': SubscriptionChargeStatusAPIModelToJSON(value['status']),
        'hash': value['hash'],
        'blockNum': value['blockNum'],
        'withdrawnWith': value['withdrawnWith'],
    };
}

