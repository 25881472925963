import { List } from 'antd';
import React, { useCallback } from 'react';

import type { QRSupport } from '@/features/company-settings/types';
import type { SomeNullable } from '@/infrastructure/utils/ts';

import { QRSupportItem } from './components';

import type { QRSupportListProps } from './types';

const style: React.CSSProperties = { maxHeight: 400, overflowY: 'auto' };

const rowKey = ({ blockchain }: SomeNullable<QRSupport, 'isDeployed'>) => blockchain;

const QRSupportList: React.FC<QRSupportListProps> = ({
  'data-test': dataTest,
  settings,
  assets,
  Actions,
  Operations,
}) => (
  <List<SomeNullable<QRSupport, 'isDeployed'>>
    style={style}
    itemLayout="horizontal"
    dataSource={settings}
    rowKey={rowKey}
    renderItem={useCallback(
      (item: SomeNullable<QRSupport, 'isDeployed'>) => (
        <QRSupportItem
          settings={item}
          assets={assets.filter(({ blockchain }) => blockchain === item.blockchain)}
          data-test={dataTest && `${dataTest}-${item.blockchain}`}
          Operations={Operations}
          Actions={Actions}
        />
      ),
      [Actions, Operations, assets, dataTest],
    )}
  />
);

const QRSupportListMemo = React.memo(QRSupportList);

export default QRSupportListMemo;
