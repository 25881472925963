import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { GeneralSettingsCard, PermissionsBreadcrumb, UsersCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'permissions';

const SettingsPermissionsPage: React.FC = () => (
  <SettingsPageLayout
    data-test={dataTest && `${dataTest}-container`}
    selected={SettingsTabType.permissions}
    breadcrumb={<PermissionsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <GeneralSettingsCard data-test={dataTest && `${dataTest}-general`} />
    <UsersCard data-test={dataTest && `${dataTest}-users`} />
  </SettingsPageLayout>
);

export default SettingsPermissionsPage;
