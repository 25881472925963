import React from 'react';

import type { ReportParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/reports';

import SettlementReconciliationFilterForm from '../SettlementReconciliationFilterForm';

const SettlementReconciliationReportParametersView: React.FC<ReportParametersViewProps> = ({
  value,
  'data-test': dataTest,
}) => <SettlementReconciliationFilterForm data-test={dataTest} value={value} readonly />;

const SettlementReconciliationReportParametersViewMemo = React.memo(SettlementReconciliationReportParametersView);

export default SettlementReconciliationReportParametersViewMemo;
