import { Form, Select } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import type { SubscriptionPlanTagsItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SubscriptionPlanTagsItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: SubscriptionPlanTagsItemProps<Values>) => (
  <Form.Item
    name={name}
    label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_TAGS} />}
    {...ItemProps}
  >
    <Select
      data-test={dataTest}
      mode="tags"
      style={{ width: '100%' }}
      placeholder={
        !readonly ? (
          <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_TAGS_ITEM_PLACEHOLDER} />
        ) : undefined
      }
      disabled={readonly}
    />
  </Form.Item>
);

const SubscriptionPlanTagsItemMemo = React.memo(SubscriptionPlanTagsItem) as typeof SubscriptionPlanTagsItem;

export default SubscriptionPlanTagsItemMemo;
