import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import SettingsBreadcrumb from '@/pages/settings/components/SettingsBreadcrumb';

import PermissionsLink from '../PermissionsLink';

import type { PermissionsBreadcrumbProps } from './types';

const PermissionsBreadcrumb: React.FC<PermissionsBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  items = [],
}) => (
  <SettingsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.PERMISSIONS_BREADCRUMB} />
        ) : (
          <PermissionsLink mode="text" title={<FormattedMessage id={I18nPageSettings.PERMISSIONS_BREADCRUMB} />} />
        ),
      },
      ...items,
    ]}
  />
);

const PermissionsBreadcrumbMemo = React.memo(PermissionsBreadcrumb);

export default PermissionsBreadcrumbMemo;
