import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { markFeatureToggleDirty, storeFeatureToggle } from '@/features/feature-toggle/actions';
import type { FeatureToggleState } from '@/features/feature-toggle/types';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import type { Draft } from 'immer';

const initialState: FeatureToggleState = {
  value: storedDirtyData,
};

const { storeFeatureToggleReducer, markFeatureToggleDirtyReducer } = createLoadingDataReducers(
  'FeatureToggle',
  (state: Draft<FeatureToggleState>) => state.value,
  (global, value) => ({ ...global, value }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeFeatureToggle, storeFeatureToggleReducer)
    .addCase(markFeatureToggleDirty, markFeatureToggleDirtyReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      !isSameAddress(previous?.address, current?.address) ? { value: { ...state.value, isDirty: true } } : state,
    );
});

export default reducer;
