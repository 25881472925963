import type { LinkMode } from '@/components/Link/types';
import type { TestableProps } from '@/infrastructure/utils/react';

import type { ReactNode } from 'react';

export type WalletType = 'metaMask' | 'coinbaseWallet';

export const walletTypes: WalletType[] = ['metaMask', 'coinbaseWallet'] as const;

export interface WalletLinkProps extends TestableProps {
  mode?: LinkMode;
  title?: ReactNode;
  type: WalletType;
}
