import { UnorderedListOutlined } from '@ant-design/icons';
import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { OperationRefreshMerchantGasWallets } from '@/features/gas-wallets/components';
import { useMerchantGasWallets } from '@/features/gas-wallets/hooks';
import type { GasWallet } from '@/features/gas-wallets/types';
import { I18nPageDashboard } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';
import GasWalletsLink from '@/pages/settings/company/gas-wallets/components/GasWalletsLink';

import type React from 'react';

const withGasWalletsDataLoading = <
  Original extends { data: GasWallet[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithGasWalletsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useMerchantGasWallets();
    return withCardDataLoading<GasWallet[], Original>({
      ...data,
      'data-test': dataTest,
      hideBack: true,
      title: (
        <Space>
          <FormattedMessage id={I18nPageDashboard.COMPONENTS_GAS_WALLETS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      ),
      CardProps: {
        extra: [
          <Space key="options">
            <GasWalletsLink
              key="link"
              data-test={dataTest && `${dataTest}-walletsLink`}
              mode="icon"
              icon={<UnorderedListOutlined />}
            />
            <OperationRefreshMerchantGasWallets
              key="refresh"
              data-test={dataTest && `${dataTest}-opRefresh`}
              mode="inline"
            />
          </Space>,
        ],
      },
    })(Component)(props);
  });

export default withGasWalletsDataLoading;
