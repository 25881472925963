import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import DonationsBreadcrumb from '@/pages/donations/donations/components/DonationsBreadcrumb';

import DonationEditLink from '../DonationEditLink';

import type { DonationEditBreadcrumbProps } from './types';

const DonationEditBreadcrumb: React.FC<DonationEditBreadcrumbProps> = ({
  items = [],
  donationId,
  disabled,
  'data-test': dataTest,
}) => (
  <DonationsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageDonations.DONATION_EDIT_COMPONENTS_BREADCRUMB_TITLE}
            values={{ id: donationId }}
          />
        ) : (
          <DonationEditLink
            value={donationId}
            title={
              <FormattedMessage
                id={I18nPageDonations.DONATION_EDIT_COMPONENTS_BREADCRUMB_TITLE}
                values={{ id: donationId }}
              />
            }
            mode="link"
          />
        ),
        ...items,
      },
    ]}
  />
);

const DonationEditBreadcrumbMemo = React.memo(DonationEditBreadcrumb);

export default DonationEditBreadcrumbMemo;
