import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import ReportsBreadcrumb from '@/pages/reports/all/components/ReportsBreadcrumb';
import ReportViewLink from '@/pages/reports/report-view/components/ReportViewLink';

import type { ReportViewBreadcrumbProps } from './types';

const ReportBreadcrumb: React.FC<ReportViewBreadcrumbProps> = ({
  'data-test': dataTest,
  reportId,
  disabled,
  items = [],
}) => (
  <ReportsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageReports.REPORT_VIEW_COMPONENTS_BREADCRUMB} values={{ id: reportId }} />
        ) : (
          <ReportViewLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={reportId}
            title={
              <FormattedMessage id={I18nPageReports.REPORT_VIEW_COMPONENTS_BREADCRUMB} values={{ id: reportId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const ReportBreadcrumbMemo = React.memo(ReportBreadcrumb);

export default ReportBreadcrumbMemo;
