import { css } from '@emotion/css';
import { theme } from 'antd';
import { useMemo } from 'react';

const useStyles = () => {
  const { token } = theme.useToken();
  return useMemo(
    () => ({
      switchStyles: css`
        height: 24px;
        padding: unset;

        :not(&.ant-switch-checked) {
          background-color: ${token.orange2};
          border: ${token.orange} 1px solid;

          > span > span.ant-switch-inner-unchecked {
            color: ${token.orange};
          }
        }

        &.ant-switch-checked {
          background-color: ${token.blue2};
          border: ${token.blue} 1px solid;

          > span > span.ant-switch-inner-checked {
            color: ${token.blue};
          }
        }
      `,
    }),
    [token.blue, token.blue2, token.orange, token.orange2],
  );
};

export default useStyles;
