/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface PublicRechargeAddressAPIModel
 */
export interface PublicRechargeAddressAPIModel {
    /**
     * 
     * @type {string}
     * @memberof PublicRechargeAddressAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PublicRechargeAddressAPIModel
     */
    companyHid: string;
    /**
     * 
     * @type {string}
     * @memberof PublicRechargeAddressAPIModel
     */
    address: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof PublicRechargeAddressAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PublicRechargeAddressAPIModel
     */
    asset: string;
    /**
     * 
     * @type {string}
     * @memberof PublicRechargeAddressAPIModel
     */
    cid: string;
    /**
     * 
     * @type {Date}
     * @memberof PublicRechargeAddressAPIModel
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the PublicRechargeAddressAPIModel interface.
 */
export function instanceOfPublicRechargeAddressAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyHid' in value)) return false;
    if (!('address' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('asset' in value)) return false;
    if (!('cid' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function PublicRechargeAddressAPIModelFromJSON(json: any): PublicRechargeAddressAPIModel {
    return PublicRechargeAddressAPIModelFromJSONTyped(json, false);
}

export function PublicRechargeAddressAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicRechargeAddressAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyHid': json['companyHid'],
        'address': json['address'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'asset': json['asset'],
        'cid': json['cid'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function PublicRechargeAddressAPIModelToJSON(value?: PublicRechargeAddressAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyHid': value['companyHid'],
        'address': value['address'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'asset': value['asset'],
        'cid': value['cid'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

