import { Form, Input } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { DescriptionItemProps } from './types';
import type { FormItemProps } from 'antd/lib/form';
import type { Store } from 'rc-field-form/es/interface';

const { TextArea } = Input;

const maxDescriptionLength = 100;

const DescriptionItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  ItemProps = {},
}: DescriptionItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const rules = useMemo<FormItemProps<Values>['rules']>(
    () => [
      ...(ItemProps.required
        ? [
            {
              required: true,
              message: (
                <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_DESCRIPTION_REQUIRED} />
              ),
            },
          ]
        : []),
      {
        warningOnly: true,
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_, valueToTest?: string) => {
          if ((valueToTest?.length ?? 0) > maxDescriptionLength) {
            throw new Error();
          }
        },
        message: (
          <FormattedMessage
            id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_DESCRIPTION_TOO_LARGE}
            values={{ max: maxDescriptionLength }}
          />
        ),
      },
    ],
    [ItemProps.required],
  );
  return (
    <Form.Item<Values>
      label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_DESCRIPTION} />}
      name={name}
      required={ItemProps.required}
      rules={rules}
    >
      <TextArea
        data-test={dataTest}
        rows={4}
        placeholder={formatMessage({
          id: I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_DESCRIPTION_PLACEHOLDER,
        })}
        data-gramm="false"
      />
    </Form.Item>
  );
};

const DescriptionItemMemo = React.memo(DescriptionItem) as typeof DescriptionItem;

export default DescriptionItemMemo;
