import { Form, Input } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { validatePrivateKey } from '@/features/dictionary/blockchain/components/PrivateKeyItem/utils';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { toDerivedXpub } from '@/infrastructure/utils/hd';

import { PrivateKeyItemReadonly } from './components';

import type { PrivateKeyItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const { TextArea } = Input;

const PrivateKeyItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  noLabel,
  readonly,
  rules = [],
  required,
  expected,
  messages = {},
  ItemProps = {},
}: PrivateKeyItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const validatePKFormat = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (_: unknown, privateKey: string) => {
      if (!privateKey && required) {
        return;
      }
      validatePrivateKey(privateKey, i18n);

      // to respect the clean arch this block should be extracted to the separate method
      if (expected?.xpub) {
        const xpub = toDerivedXpub(privateKey);
        if (xpub !== expected.xpub) {
          throw new Error(
            expected.message || i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_ITEM_ERROR_XPUB_INVALID }),
          );
        }
      }
    },
    [expected?.message, expected?.xpub, i18n, required],
  );

  return (
    <Form.Item<Values>
      name={name}
      label={
        !noLabel ? messages.label || i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_ITEM_LABEL }) : undefined
      }
      rules={[
        { validator: validatePKFormat },
        ...(required
          ? [
              {
                required: true,
                message: i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_ITEM_ERROR_REQUIRED }),
              },
            ]
          : []),
        ...rules,
      ]}
      {...ItemProps}
    >
      {!readonly ? (
        <TextArea
          rows={4}
          name="no-autocomplete"
          autoComplete="off"
          placeholder={
            messages.placeholder || i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_ITEM_PLACEHOLDER })
          }
          className="ym-hide-content"
          data-test={dataTest && `${dataTest}-privateKey`}
          data-gramm="false"
        />
      ) : (
        <PrivateKeyItemReadonly data-test={dataTest && `${dataTest}-privateKey`} />
      )}
    </Form.Item>
  );
};

const PrivateKeyItemMemo = React.memo(PrivateKeyItem) as typeof PrivateKeyItem;

export default PrivateKeyItemMemo;
