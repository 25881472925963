import { css, cx } from '@emotion/css';
import React, { useMemo } from 'react';

import { ReadonlyComponent } from '@/components';
import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';
import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { identity } from '@/infrastructure/utils/functions';
import { addressFromHex } from '@/infrastructure/utils/tron/address';

import BlockchainLink from '../BlockchainLink';
import { BlockchainLinkType } from '../BlockchainLink/model';

import type { AddressLinkProps } from './types';

const AddressFormatter: Record<BlockchainTypeAPIModel, (address: string) => string> = {
  [BlockchainTypeAPIModel.TronMainNet]: (address) => addressFromHex(address),
  [BlockchainTypeAPIModel.TronNile]: (address) => addressFromHex(address),
  [BlockchainTypeAPIModel.TronShasta]: (address) => addressFromHex(address),
  [BlockchainTypeAPIModel.BinanceMainNet]: identity,
  [BlockchainTypeAPIModel.BinanceTestNet]: identity,
  [BlockchainTypeAPIModel.EthereumMainNet]: identity,
  [BlockchainTypeAPIModel.EthereumGoerli]: identity,
  [BlockchainTypeAPIModel.EthereumRopsten]: identity,
  [BlockchainTypeAPIModel.EthereumSepolia]: identity,
  [BlockchainTypeAPIModel.PolygonMainNet]: identity,
  [BlockchainTypeAPIModel.PolygonAmoy]: identity,
  [BlockchainTypeAPIModel.PolygonMumbaiNet]: identity,
  [BlockchainTypeAPIModel.ArbitrumMainNet]: identity,
  [BlockchainTypeAPIModel.ArbitrumTestNet]: identity,
  [BlockchainTypeAPIModel.ArbitrumSepolia]: identity,
  [BlockchainTypeAPIModel.BitcoinMainNet]: identity,
  [BlockchainTypeAPIModel.BitcoinTestNet]: identity,
};

const AddressPathFactory: Record<BlockchainTypeAPIModel, (address: string) => string> = {
  [BlockchainTypeAPIModel.TronMainNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.TronNile]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.TronShasta]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.BinanceMainNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.BinanceTestNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.EthereumMainNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.EthereumGoerli]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.EthereumRopsten]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.EthereumSepolia]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.PolygonMainNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.PolygonAmoy]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.PolygonMumbaiNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.ArbitrumMainNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.ArbitrumTestNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.ArbitrumSepolia]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.BitcoinMainNet]: (address) => `address/${address}`,
  [BlockchainTypeAPIModel.BitcoinTestNet]: (address) => `address/${address}`,
};

const AddressLink: React.FC<AddressLinkProps> = ({
  'data-test': dataTest,
  address,
  bt,
  style,
  className,
  children,
}) => {
  const formatted = useMemo(() => AddressFormatter[bt](address), [address, bt]);
  const path = useMemo(() => AddressPathFactory[bt](formatted), [formatted, bt]);
  return path ? (
    <BlockchainLink
      data-test={dataTest}
      path={path}
      chainType={bt}
      linkType={BlockchainLinkType.APP}
      style={style}
      className={cx(
        className,
        css`{
            width: 100%;
            display: inline-flex;
        }`,
      )}
    >
      <ReadonlyComponent
        className={cx(
          'ym-hide-content',
          css`
            width: 100%;
            display: inline-flex;

            > span {
              > div {
                display: inline-flex;
              }

              display: inline-flex;
            }
          `,
        )}
        value={children || formatted}
        copyable={{ text: formatted, alwaysVisible: true }}
      />
    </BlockchainLink>
  ) : (
    <ReadonlyComponent
      className="ym-hide-content"
      data-test={dataTest}
      value={children || formatted}
      copyable={{ text: formatted }}
    />
  );
};

const AddressLinkMemo = React.memo(withBlockchainType<AddressLinkProps>(AddressLink));

export default AddressLinkMemo;
