/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  DeleteWithdrawalScheduleResponseAPIModel,
  GetWithdrawalIntentByIdResponseAPIModel,
  GetWithdrawalScheduleResponseAPIModel,
  SearchWithdrawalIntentsRequestAPIModel,
  SliceWithdrawalIntentWithdrawalIntentSortByAPIModel,
  UpdateWithdrawalScheduleRequestAPIModel,
  UpdateWithdrawalScheduleResponseAPIModel,
  WithdrawNowRequestAPIModel,
  WithdrawNowResponseAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    DeleteWithdrawalScheduleResponseAPIModelFromJSON,
    DeleteWithdrawalScheduleResponseAPIModelToJSON,
    GetWithdrawalIntentByIdResponseAPIModelFromJSON,
    GetWithdrawalIntentByIdResponseAPIModelToJSON,
    GetWithdrawalScheduleResponseAPIModelFromJSON,
    GetWithdrawalScheduleResponseAPIModelToJSON,
    SearchWithdrawalIntentsRequestAPIModelFromJSON,
    SearchWithdrawalIntentsRequestAPIModelToJSON,
    SliceWithdrawalIntentWithdrawalIntentSortByAPIModelFromJSON,
    SliceWithdrawalIntentWithdrawalIntentSortByAPIModelToJSON,
    UpdateWithdrawalScheduleRequestAPIModelFromJSON,
    UpdateWithdrawalScheduleRequestAPIModelToJSON,
    UpdateWithdrawalScheduleResponseAPIModelFromJSON,
    UpdateWithdrawalScheduleResponseAPIModelToJSON,
    WithdrawNowRequestAPIModelFromJSON,
    WithdrawNowRequestAPIModelToJSON,
    WithdrawNowResponseAPIModelFromJSON,
    WithdrawNowResponseAPIModelToJSON,
} from '../models/index';

export interface GetWithdrawalIntentByIdRequest {
    intentId: string;
}

export interface SearchWithdrawalScheduleIntentsRequest {
    searchWithdrawalIntentsRequestAPIModel: SearchWithdrawalIntentsRequestAPIModel;
}

export interface TriggerWithdrawNowRequest {
    withdrawNowRequestAPIModel: WithdrawNowRequestAPIModel;
}

export interface UpdateWithdrawalScheduleRequest {
    updateWithdrawalScheduleRequestAPIModel: UpdateWithdrawalScheduleRequestAPIModel;
}

/**
 * WithdrawScheduleApi - interface
 * 
 * @export
 * @interface WithdrawScheduleApiInterface
 */
export interface WithdrawScheduleApiInterface {
    /**
     * Clears withdrawal schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    deleteWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteWithdrawalScheduleResponseAPIModel>>;

    /**
     * Clears withdrawal schedule
     */
    deleteWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteWithdrawalScheduleResponseAPIModel>;

    /**
     * Returns withdrawal intent
     * @param {string} intentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    getWithdrawalIntentByIdRaw(requestParameters: GetWithdrawalIntentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWithdrawalIntentByIdResponseAPIModel>>;

    /**
     * Returns withdrawal intent
     */
    getWithdrawalIntentById(intentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWithdrawalIntentByIdResponseAPIModel>;

    /**
     * Returns withdrawal schedule
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    getWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWithdrawalScheduleResponseAPIModel>>;

    /**
     * Returns withdrawal schedule
     */
    getWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWithdrawalScheduleResponseAPIModel>;

    /**
     * Returns scheduled withdrawal intents by filter
     * @param {SearchWithdrawalIntentsRequestAPIModel} searchWithdrawalIntentsRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    searchWithdrawalScheduleIntentsRaw(requestParameters: SearchWithdrawalScheduleIntentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalIntentWithdrawalIntentSortByAPIModel>>;

    /**
     * Returns scheduled withdrawal intents by filter
     */
    searchWithdrawalScheduleIntents(searchWithdrawalIntentsRequestAPIModel: SearchWithdrawalIntentsRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalIntentWithdrawalIntentSortByAPIModel>;

    /**
     * Trigger withdraw for the specific asset now
     * @param {WithdrawNowRequestAPIModel} withdrawNowRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    triggerWithdrawNowRaw(requestParameters: TriggerWithdrawNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawNowResponseAPIModel>>;

    /**
     * Trigger withdraw for the specific asset now
     */
    triggerWithdrawNow(withdrawNowRequestAPIModel: WithdrawNowRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawNowResponseAPIModel>;

    /**
     * Updates withdrawal schedule
     * @param {UpdateWithdrawalScheduleRequestAPIModel} updateWithdrawalScheduleRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WithdrawScheduleApiInterface
     */
    updateWithdrawalScheduleRaw(requestParameters: UpdateWithdrawalScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateWithdrawalScheduleResponseAPIModel>>;

    /**
     * Updates withdrawal schedule
     */
    updateWithdrawalSchedule(updateWithdrawalScheduleRequestAPIModel: UpdateWithdrawalScheduleRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateWithdrawalScheduleResponseAPIModel>;

}

/**
 * 
 */
export class WithdrawScheduleApi extends runtime.BaseAPI implements WithdrawScheduleApiInterface {

    /**
     * Clears withdrawal schedule
     */
    async deleteWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteWithdrawalScheduleResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/schedule`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteWithdrawalScheduleResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Clears withdrawal schedule
     */
    async deleteWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteWithdrawalScheduleResponseAPIModel> {
        const response = await this.deleteWithdrawalScheduleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal intent
     */
    async getWithdrawalIntentByIdRaw(requestParameters: GetWithdrawalIntentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWithdrawalIntentByIdResponseAPIModel>> {
        if (requestParameters['intentId'] == null) {
            throw new runtime.RequiredError(
                'intentId',
                'Required parameter "intentId" was null or undefined when calling getWithdrawalIntentById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/intents/{intentId}`.replace(`{${"intentId"}}`, encodeURIComponent(String(requestParameters['intentId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWithdrawalIntentByIdResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal intent
     */
    async getWithdrawalIntentById(intentId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWithdrawalIntentByIdResponseAPIModel> {
        const response = await this.getWithdrawalIntentByIdRaw({ intentId: intentId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns withdrawal schedule
     */
    async getWithdrawalScheduleRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetWithdrawalScheduleResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/schedule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetWithdrawalScheduleResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns withdrawal schedule
     */
    async getWithdrawalSchedule(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetWithdrawalScheduleResponseAPIModel> {
        const response = await this.getWithdrawalScheduleRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns scheduled withdrawal intents by filter
     */
    async searchWithdrawalScheduleIntentsRaw(requestParameters: SearchWithdrawalScheduleIntentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceWithdrawalIntentWithdrawalIntentSortByAPIModel>> {
        if (requestParameters['searchWithdrawalIntentsRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'searchWithdrawalIntentsRequestAPIModel',
                'Required parameter "searchWithdrawalIntentsRequestAPIModel" was null or undefined when calling searchWithdrawalScheduleIntents().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/intents/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchWithdrawalIntentsRequestAPIModelToJSON(requestParameters['searchWithdrawalIntentsRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceWithdrawalIntentWithdrawalIntentSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns scheduled withdrawal intents by filter
     */
    async searchWithdrawalScheduleIntents(searchWithdrawalIntentsRequestAPIModel: SearchWithdrawalIntentsRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceWithdrawalIntentWithdrawalIntentSortByAPIModel> {
        const response = await this.searchWithdrawalScheduleIntentsRaw({ searchWithdrawalIntentsRequestAPIModel: searchWithdrawalIntentsRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Trigger withdraw for the specific asset now
     */
    async triggerWithdrawNowRaw(requestParameters: TriggerWithdrawNowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WithdrawNowResponseAPIModel>> {
        if (requestParameters['withdrawNowRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'withdrawNowRequestAPIModel',
                'Required parameter "withdrawNowRequestAPIModel" was null or undefined when calling triggerWithdrawNow().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/withdraw-now`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WithdrawNowRequestAPIModelToJSON(requestParameters['withdrawNowRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WithdrawNowResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Trigger withdraw for the specific asset now
     */
    async triggerWithdrawNow(withdrawNowRequestAPIModel: WithdrawNowRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WithdrawNowResponseAPIModel> {
        const response = await this.triggerWithdrawNowRaw({ withdrawNowRequestAPIModel: withdrawNowRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Updates withdrawal schedule
     */
    async updateWithdrawalScheduleRaw(requestParameters: UpdateWithdrawalScheduleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateWithdrawalScheduleResponseAPIModel>> {
        if (requestParameters['updateWithdrawalScheduleRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'updateWithdrawalScheduleRequestAPIModel',
                'Required parameter "updateWithdrawalScheduleRequestAPIModel" was null or undefined when calling updateWithdrawalSchedule().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/withdrawals/schedule`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWithdrawalScheduleRequestAPIModelToJSON(requestParameters['updateWithdrawalScheduleRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateWithdrawalScheduleResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Updates withdrawal schedule
     */
    async updateWithdrawalSchedule(updateWithdrawalScheduleRequestAPIModel: UpdateWithdrawalScheduleRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateWithdrawalScheduleResponseAPIModel> {
        const response = await this.updateWithdrawalScheduleRaw({ updateWithdrawalScheduleRequestAPIModel: updateWithdrawalScheduleRequestAPIModel }, initOverrides);
        return await response.value();
    }

}
