import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useDonation } from '@/features/donations/hooks';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { OperationRefreshDonationProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_SUCCESS_MESSAGE} />,
  },
};

const OperationRefreshDonation: React.FC<OperationRefreshDonationProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  donationId,
}) => {
  const { forceRefresh } = useDonation(donationId);
  return (
    <OperationRefresh
      data-test={dataTest}
      mode={mode}
      refresh={forceRefresh}
      messages={messages}
      disabled={!donationId}
    />
  );
};

const OperationRefreshDonationMemo = React.memo(OperationRefreshDonation);

export default OperationRefreshDonationMemo;
