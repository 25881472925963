import Icon from '@ant-design/icons';
import React, { forwardRef, useMemo } from 'react';

import ArbitrumIcon from '@/assets/icons/arbitrum.svg?react';
import BTCIcon from '@/assets/icons/bitcoin.svg?react';
import BSCIcon from '@/assets/icons/bnb-logo.svg?react';
import DefaultLogo from '@/assets/icons/DefaultLogo';
import EthereumIcon from '@/assets/icons/ethereum.svg?react';
import PolygonIcon from '@/assets/icons/polygon-matic.svg?react';
import TronIcon from '@/assets/icons/tron-trx.svg?react';

import type { BlockchainIconProps } from './types';

const mapping: Partial<Record<string, typeof BSCIcon>> = {
  arbitrum: ArbitrumIcon,
  binance: BSCIcon,
  bitcoin: BTCIcon,
  ethereum: EthereumIcon,
  polygon: PolygonIcon,
  tron: TronIcon,
};

const BlockchainIcon = forwardRef<HTMLSpanElement, BlockchainIconProps>(function BlockchainIcon(
  { 'data-test': dataTest, bt, className, style },
  ref,
) {
  const Selected = useMemo(() => {
    const Ico = Object.entries(mapping)
      .filter(([type]) => bt.toLowerCase().includes(type))
      .map(([, mappingIcon]) => mappingIcon)[0];
    return Ico ?? DefaultLogo;
  }, [bt]);
  return <Icon component={Selected} className={className} style={style} data-test={dataTest} ref={ref} />;
});

const BlockchainIconMemo = React.memo(BlockchainIcon) as typeof BlockchainIcon;

export default BlockchainIconMemo;
