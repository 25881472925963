import { useAppDispatch } from '@/app/hooks';
import { PageContainer } from '@/components';
import { markBalancesDirty } from '@/features/statistics/actions';
import { useFirstRenderEffect } from '@/hooks';

import { ActualBalancesBreadcrumb, ActualBalancesCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'balances';

const ActualBalancesPage: React.FC = () => {
  const { withDispatch } = useAppDispatch();
  useFirstRenderEffect(withDispatch(markBalancesDirty));
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<ActualBalancesBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <ActualBalancesCard data-test={dataTest && `${dataTest}-card`} />
    </PageContainer>
  );
};

export default ActualBalancesPage;
