import { useMemo } from 'react';

import { Web3AuthRecoveryKeySecure } from '@/features/auth/components';
import type { EmailAuthOptions } from '@/features/auth/hooks';
import { useStateMountSafe } from '@/hooks';
import type { Web3Auth } from '@/infrastructure/security/web3-auth';
import { suppressPromise, withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { rejection } from '@/infrastructure/utils/ui';

const useWeb3AuthRecoveryKeySecure = ({ 'data-test': dataTest }: { 'data-test'?: string }) => {
  const [keySecure, setKeySecure] = useStateMountSafe<{
    doConfirm: Func<[string], boolean>;
    doReject: () => unknown;
  }>();
  const withSecure = useMemo(
    () =>
      <V extends unknown[] = unknown[], R = unknown>(
        func: (onRecoveryKeySecure: EmailAuthOptions['onRecoveryKeySecure']) => Func<V, R>,
      ) => {
        const onRecoveryKeySecure = (web3Auth: Web3Auth) =>
          new Promise((resolve, reject) => {
            setKeySecure(() => ({
              doConfirm: async (secret: string) => {
                try {
                  await web3Auth.recoveryKey.store(secret);
                  await web3Auth.sync();
                } catch (e) {
                  suppressPromise(web3Auth.logout(true));
                  // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                  reject(e);
                }
                resolve(true);
                return true;
              },
              doReject: () => {
                resolve(rejection);
              },
            }));
          });
        return withFinally(func(onRecoveryKeySecure), () => {
          setKeySecure(undefined);
        });
      },
    [setKeySecure],
  );

  return {
    withSecure,
    context: keySecure ? (
      <Web3AuthRecoveryKeySecure
        data-test={dataTest && `${dataTest}-recovery`}
        onLogout={keySecure.doReject}
        onSave={keySecure.doConfirm}
      />
    ) : null,
  };
};

export default useWeb3AuthRecoveryKeySecure;
