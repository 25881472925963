import { useBreakpoint } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { Space, Typography } from 'antd';
import React, { useMemo } from 'react';

import OperationSelectCompany from '@/features/company/components/OperationSelectCompany';
import { useActiveCompany, useCompanies } from '@/features/company/hooks';

import type { SelectCompanyProps } from './types';
import type { Breakpoint } from 'antd';

const { Text } = Typography;

const width: Record<Breakpoint, number> = {
  xs: 100,
  sm: 150,
  md: 150,
  lg: 350,
  xl: 550,
  xxl: 550,
};

const SelectCompany: React.FC<SelectCompanyProps> = ({ 'data-test': dataTest }) => {
  const { data: company } = useActiveCompany();
  const { data: companies } = useCompanies();
  const breakpoint = useBreakpoint();

  const title = useMemo(
    () => (
      <Text
        ellipsis={{ tooltip: company.data?.name }}
        data-test={dataTest}
        className={css`
          max-width: ${width[breakpoint ?? 'xl']}px !important;
          color: inherit;
          font: inherit;
        `}
      >
        {company.data?.name}
      </Text>
    ),
    [breakpoint, company.data?.name, dataTest],
  );

  return (
    <Space>{(companies.data?.length ?? 0) > 1 ? <OperationSelectCompany mode="link" title={title} /> : title}</Space>
  );
};

const SelectCompanyMemo = React.memo(SelectCompany);

export default SelectCompanyMemo;
