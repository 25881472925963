import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { SubscriptionsTable } from '@/features/subscriptions/components';
import BaseSubscriptionFilterForm from '@/features/subscriptions/components/SubscriptionFilterForm';
import type { SubscriptionFilterFormProps } from '@/features/subscriptions/components/SubscriptionFilterForm/types';
import { useSubscriptionForPlanListView } from '@/features/subscriptions/hooks';
import type { SubscriptionForPlanFilterPredicate } from '@/features/subscriptions/types';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';

import type { SubscriptionsCardProps } from './types';

const SubscriptionFilterForm: React.FC<
  Omit<SubscriptionFilterFormProps<SubscriptionForPlanFilterPredicate>, 'hidden'>
> = (props) => <BaseSubscriptionFilterForm {...props} hidden={{ plan: true }} />;

const SubscriptionsCard: React.FC<SubscriptionsCardProps> = ({ planId, ...props }) => {
  const data = useSubscriptionForPlanListView(planId);
  return (
    <PageCard
      title={<FormattedMessage id={I18nPageSubscriptions.PLAN_VIEW_COMPONENTS_SUBSCRIPTIONS_CARD_TITLE} />}
      cardSize="big"
    >
      <SubscriptionsTable {...data} {...props} FilterForm={SubscriptionFilterForm} />
    </PageCard>
  );
};

const SubscriptionsCardMemo = React.memo(SubscriptionsCard);

export default SubscriptionsCardMemo;
