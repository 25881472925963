import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import {
  activateReportSchedule,
  deactivateReportSchedule,
  deleteReportSchedule,
  updateReportSchedule,
} from '@/features/report-schedules/actions';
import type { ReportSchedule, UpdateReportSchedule } from '@/features/report-schedules/types';
import { ReportScheduleStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useReportSchedule from './useReportSchedule';

type UnavailabilityReason = 'no-data';
type UpdateStatusUnavailabilityReason = UnavailabilityReason | 'invalid-status';

export interface UseSingleReportScheduleActionsType {
  update: HookAction<[UpdateReportSchedule], ReportSchedule, UnavailabilityReason>;
  activate: HookAction<[], ReportSchedule, UpdateStatusUnavailabilityReason>;
  deactivate: HookAction<[string?], ReportSchedule, UpdateStatusUnavailabilityReason>;
  deleteReportSchedule: HookAction<[], void, UnavailabilityReason>;
}

export default function useReportScheduleActions(reportScheduleId: string): UseSingleReportScheduleActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useReportSchedule(reportScheduleId);

  const unavailabilityReason = useMemo<UnavailabilityReason | undefined>(() => {
    if (!data.data) {
      return 'no-data';
    }
    return undefined;
  }, [data.data]);
  const isUpdating = useActionPending(updateReportSchedule, reportScheduleId);
  const update: UseSingleReportScheduleActionsType['update'] = {
    act: useCallback(
      (value: UpdateReportSchedule) =>
        withExtractDataDispatch(updateReportSchedule)({ id: reportScheduleId, data: value }),
      [reportScheduleId, withExtractDataDispatch],
    ),
    unavailabilityReason,
    available: !unavailabilityReason,
    inAction: isUpdating,
  };

  const activateUnavailabilityReason = useMemo<UpdateStatusUnavailabilityReason | undefined>(() => {
    if (unavailabilityReason) {
      return unavailabilityReason;
    }
    if (data.data?.status !== ReportScheduleStatusAPIModel.Disabled) {
      return 'invalid-status';
    }
    return undefined;
  }, [data.data?.status, unavailabilityReason]);

  const isActivating = useActionPending(activateReportSchedule, reportScheduleId);
  const activate: UseSingleReportScheduleActionsType['activate'] = {
    act: useCallback(
      () => withExtractDataDispatch(activateReportSchedule)({ id: reportScheduleId }),
      [reportScheduleId, withExtractDataDispatch],
    ),
    available: !activateUnavailabilityReason,
    unavailabilityReason: activateUnavailabilityReason,
    inAction: isActivating,
  };

  const deactivateUnavailabilityReason = useMemo<UpdateStatusUnavailabilityReason | undefined>(() => {
    if (unavailabilityReason) {
      return unavailabilityReason;
    }
    if (data.data?.status !== ReportScheduleStatusAPIModel.Active) {
      return 'invalid-status';
    }
    return undefined;
  }, [data.data?.status, unavailabilityReason]);
  const isDeactivating = useActionPending(deactivateReportSchedule, reportScheduleId);
  const deactivate: UseSingleReportScheduleActionsType['deactivate'] = {
    act: useCallback(
      (reason?: string) => withExtractDataDispatch(deactivateReportSchedule)({ id: reportScheduleId, reason }),
      [reportScheduleId, withExtractDataDispatch],
    ),
    available: !deactivateUnavailabilityReason,
    unavailabilityReason: deactivateUnavailabilityReason,
    inAction: isDeactivating,
  };

  const isDeleting = useActionPending(deleteReportSchedule, reportScheduleId);
  const deleteReportScheduleHook: UseSingleReportScheduleActionsType['deleteReportSchedule'] = {
    act: useCallback(
      () => withExtractDataDispatch(deleteReportSchedule)(reportScheduleId),
      [reportScheduleId, withExtractDataDispatch],
    ),
    unavailabilityReason,
    available: !unavailabilityReason,
    inAction: isDeleting,
  };

  return { activate, deactivate, update, deleteReportSchedule: deleteReportScheduleHook };
}
