import type { BlockchainTypeAPIModel, MerchantWalletAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { SingleState } from '@/infrastructure/model/single/types';

import type { Address } from 'viem';

export const NAMESPACE = 'merchant-wallets';

export interface MerchantWalletId {
  bt: BlockchainTypeAPIModel;
  address: Address;
}

export type MerchantWalletDeploymentState = {
  bt: BlockchainTypeAPIModel;
} & ({ address: Address; deployed: true } | { deployed: false });

export type MerchantWalletSignature = MerchantWalletAPIModel;

export interface MerchantWalletsState {
  wallets: LoadingStateWithDirty<MerchantWalletSignature[]>;
  deployments: SingleState<MerchantWalletDeploymentState>;
}
