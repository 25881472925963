import { useAppDispatch } from '@/app/hooks';
import { PageContainer } from '@/components';
import { markPaymentsSummaryStatsDirty, markSubscriptionsSummaryStatsDirty } from '@/features/statistics/actions';
import { useFirstRenderEffect } from '@/hooks';

import { GasWalletsCard, SalesVolumeCard, PaymentsCard, SubscriptionsCard, DashboardBreadcrumb } from './components';

import type React from 'react';

const dataTest: string | undefined = 'dashboard';

const DashboardPage: React.FC = () => {
  const { withDispatch } = useAppDispatch();
  useFirstRenderEffect(withDispatch(markPaymentsSummaryStatsDirty));
  useFirstRenderEffect(withDispatch(markSubscriptionsSummaryStatsDirty));
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<DashboardBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <PaymentsCard data-test={dataTest && `${dataTest}-payments`} />
      <SalesVolumeCard data-test={dataTest && `${dataTest}-sales`} />
      <SubscriptionsCard data-test={dataTest && `${dataTest}-subscriptions`} />
      <GasWalletsCard data-test={dataTest && `${dataTest}-gas`} />
    </PageContainer>
  );
};

export default DashboardPage;
