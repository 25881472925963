import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import CollectPeriodItem from '@/features/collectable/components/CollectPeriodItem';
import type { CollectScheduleUpdate } from '@/features/collectable/types';
import { PushCollectPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import CollectScheduleAmountInputItem from '../CollectScheduleAmountInputItem';

import type { UpdateCollectScheduleFormProps } from './types';
import type { FormInstance } from 'antd/es/form';

const requiredFields = [nameof<CollectScheduleUpdate>('period'), nameof<CollectScheduleUpdate>('unifiedMinAmount')];

const footerMessages = {
  submit: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_SUBMIT} tagName="span" />,
};

const UpdateCollectScheduleForm: React.FC<UpdateCollectScheduleFormProps> = ({
  'data-test': dataTest,
  values,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { form, withResetForm } = useForm<CollectScheduleUpdate>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(() => withSubmitting(onSubmit), [onSubmit, withSubmitting]);
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_ERROR_COMMON} />;
  }, [error]);

  const isDataUpdated = useCallback(
    ({ getFieldValue }: FormInstance<CollectScheduleUpdate>) =>
      getFieldValue(nameof<CollectScheduleUpdate>('period')) !== values?.period
      || getFieldValue(nameof<CollectScheduleUpdate>('unifiedMinAmount')) !== values?.unifiedMinAmount,
    [values?.period, values?.unifiedMinAmount],
  );

  const initialValues = useMemo(
    () => ({ period: PushCollectPeriodAPIModel.LastDayOfMonth, unifiedMinAmount: 100, ...values }),
    [values],
  );

  return (
    <Form<CollectScheduleUpdate>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
      <CollectPeriodItem<CollectScheduleUpdate>
        data-test={dataTest && `${dataTest}-period`}
        name={nameof<CollectScheduleUpdate>('period')}
        required
      />
      <CollectScheduleAmountInputItem<CollectScheduleUpdate>
        data-test={dataTest && `${dataTest}-amount`}
        name={nameof<CollectScheduleUpdate>('unifiedMinAmount')}
      />
      <FormCompletenessItem<CollectScheduleUpdate>
        requiredFields={requiredFields}
        onChange={setFormComplete}
        checkIsComplete={isDataUpdated}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-submit`}
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
        messages={footerMessages}
      />
    </Form>
  );
};

const UpdateCollectScheduleFormMemo = React.memo(UpdateCollectScheduleForm);

export default UpdateCollectScheduleFormMemo;
