import {
  ExceptionOutlined,
  FileDoneOutlined,
  FileSyncOutlined,
  FileUnknownOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { ReadonlyDateTime, StatusViewElement } from '@/components';
import { WithdrawalReconciliationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';

import type { SettlementReconciliationStatusViewProps } from './types';

const icons: Record<WithdrawalReconciliationStatusAPIModel, React.ReactNode> = {
  [WithdrawalReconciliationStatusAPIModel.Unchecked]: <FileUnknownOutlined style={{ color: 'blue' }} />,
  [WithdrawalReconciliationStatusAPIModel.Active]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <FileSyncOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [WithdrawalReconciliationStatusAPIModel.Retrying]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <ExceptionOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [WithdrawalReconciliationStatusAPIModel.Failed]: <ExceptionOutlined style={{ color: 'red' }} />,
  [WithdrawalReconciliationStatusAPIModel.Succeeded]: <FileDoneOutlined style={{ color: 'green' }} />,
};

const i18n: Record<WithdrawalReconciliationStatusAPIModel, I18nFeatureSettlementReconciliations> = {
  [WithdrawalReconciliationStatusAPIModel.Unchecked]:
    I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_STATUS_VIEW_UNCHECKED,
  [WithdrawalReconciliationStatusAPIModel.Active]:
    I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_STATUS_VIEW_ACTIVE,
  [WithdrawalReconciliationStatusAPIModel.Retrying]:
    I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_STATUS_VIEW_RETRYING,
  [WithdrawalReconciliationStatusAPIModel.Failed]:
    I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_STATUS_VIEW_FAILED,
  [WithdrawalReconciliationStatusAPIModel.Succeeded]:
    I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_STATUS_VIEW_SUCCEEDED,
};

const SettlementReconciliationStatusView: React.FC<SettlementReconciliationStatusViewProps> = ({
  value,
  nextAttempt,
  'data-test': dataTest,
  mode = 'full',
}) =>
  // eslint-disable-next-line no-nested-ternary
  value === WithdrawalReconciliationStatusAPIModel.Retrying ? (
    <StatusViewElement
      data-test={dataTest}
      i18n={i18n[value]}
      i18nValues={{ next: <ReadonlyDateTime value={nextAttempt} /> }}
      icon={icons[value]}
      mode={mode}
    />
  ) : value ? (
    <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} />
  ) : null;

const SettlementReconciliationStatusViewMemo = React.memo(
  SettlementReconciliationStatusView,
) as typeof SettlementReconciliationStatusView;

export default SettlementReconciliationStatusViewMemo;
