import { ExportOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useRechargeAddressActions } from '@/features/recharges/hooks';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationGenerateRechargeURLProps } from './types';

const { Link } = Typography;

const OperationGenerateRechargeURL: React.FC<OperationGenerateRechargeURLProps> = ({
  'data-test': dataTest,
  rechargeId,
  disabled,
  mode = 'inline',
}) => {
  const { withError } = useNotification();
  const { generateRechargeAddressURL } = useRechargeAddressActions(rechargeId);

  const [modal, contextHolder] = Modal.useModal();
  const generateToken = useMemo(
    () =>
      withOnSuccess(
        withError(generateRechargeAddressURL.act, () => (
          <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_ERROR_MESSAGE} />
        )),
        (data) => {
          modal.info({
            title: (
              <FormattedMessage
                id={I18nFeatureRecharges.COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_SUCCESS_FORM_TITLE}
              />
            ),
            content: (
              <Link
                href={data}
                copyable={{ text: data }}
                target="_blank"
                data-test={dataTest && `${dataTest}-link`}
                data-ym-target="recharge-widget-link"
              >
                <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_LINK_TITLE} />
              </Link>
            ),
            maskClosable: true,
            width: 500,
          });
        },
      ),
    [withError, generateRechargeAddressURL.act, modal, dataTest],
  );

  return (
    <>
      {contextHolder}
      <Operation
        title={<FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_TITLE} />}
        icon={<ExportOutlined />}
        onClick={generateToken}
        disabled={disabled || !generateRechargeAddressURL.available}
        inProgress={generateRechargeAddressURL.inAction}
        data-test={dataTest && `${dataTest}-generate`}
        mode={mode}
      />
    </>
  );
};

const OperationGenerateRechargeURLMemo = React.memo(OperationGenerateRechargeURL);

export default OperationGenerateRechargeURLMemo;
