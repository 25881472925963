import { Row } from 'antd';

import { AuthBlock, ColorsBlock, ContactsBlock, LangBlock, LogoBlock, OnRampBlock, PoweredByBlock } from './components';

import type { BrandingDataEditPanelProps } from './types';
import type React from 'react';

const BrandingDataEditPanel: React.FC<BrandingDataEditPanelProps> = ({ 'data-test': dataTest, disabled }) => (
  <Row gutter={[16, 16]}>
    <ColorsBlock data-test={dataTest && `${dataTest}-colors`} disabled={disabled} />
    <AuthBlock data-test={dataTest && `${dataTest}-auth`} disabled={disabled} />
    <OnRampBlock data-test={dataTest && `${dataTest}-onRamp`} disabled={disabled} />
    <LangBlock data-test={dataTest && `${dataTest}-lang`} disabled={disabled} />
    <ContactsBlock data-test={dataTest && `${dataTest}-contacts`} disabled={disabled} />
    <LogoBlock data-test={dataTest && `${dataTest}-logo`} disabled={disabled} />
    <PoweredByBlock data-test={dataTest && `${dataTest}-poweredBy`} disabled={disabled} />
  </Row>
);

export default BrandingDataEditPanel;
