import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';

import DonationsLink from '../DonationsLink';

import type { DonationsBreadcrumbProps } from './types';

const DonationsBreadcrumb: React.FC<DonationsBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageDonations.DONATIONS_COMPONENTS_BREADCRUMB} />
        ) : (
          <DonationsLink
            mode="text"
            title={<FormattedMessage id={I18nPageDonations.DONATIONS_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const DonationsBreadcrumbMemo = React.memo(DonationsBreadcrumb);

export default DonationsBreadcrumbMemo;
