import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createSingleActions } from '@/infrastructure/model/single/actions';

export const createNormalizedPartialActions = <Value, Type extends string, Exc = string, Id = string>(
  namespace: string,
  type: Type,
) => {
  const listActions = createLoadingDataActions<Value[], `${Type}Batch`, Exc>(namespace, `${type}Batch`);
  const singleActions = createSingleActions<Value, Type, Id>(namespace, type);

  return {
    ...listActions,
    ...singleActions,
  };
};
