import { useCallback, useMemo } from 'react';

import { DataFetchError, FormattedMessage, PageCard } from '@/components';
import { UpdateQRSupportForm } from '@/features/company-settings/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useFormPageAction, useNotification, useStateMountSafe } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';
import { settingsCompanySummaryLink } from '@/pages/settings/routes';

import { UpdateQRSupportBreadcrumb } from './components';
import { useQRSupportFormInitialValues } from './hooks';

import type React from 'react';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nPageSettings.COMPANY_QR_SUPPORT_EDIT_MESSAGES_ERROR} />,
  success: () => <FormattedMessage id={I18nPageSettings.COMPANY_QR_SUPPORT_EDIT_MESSAGES_SUCCESS} />,
};

const dataTest: string | undefined = 'qr';

const UpdateQRSupportPage: React.FC = () => {
  const { withNotification } = useNotification();
  const { data: initialValues, forceRefresh, loading } = useQRSupportFormInitialValues();
  const { updateQRSupport: updateQRSupportAction } = useCompanySettingsActions();

  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(settingsCompanySummaryLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback(
    (func?: Func) => setOnSubmit(() => (func ? withOnSuccess(func, doRedirect) : undefined)),
    [doRedirect, setOnSubmit],
  );

  const doSubmit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  const updateActiveAssets = useMemo(
    () =>
      withSuppressError(withOnSuccess(withNotification(updateQRSupportAction.act, notificationOptions), doRedirect)),
    [withNotification, updateQRSupportAction.act, doRedirect],
  );

  return (
    <SettingsPageLayout
      data-test={dataTest && `${dataTest}-container`}
      selected={SettingsTabType.company}
      breadcrumb={<UpdateQRSupportBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={doSubmit}
      cancel={doRedirect}
    >
      <PageCard loading={(!initialValues.data && initialValues.isDirty) || loading} cardSize="big">
        {
          /* eslint-disable-next-line no-nested-ternary */
          !initialValues.data ? (
            !initialValues.isDirty ? (
              <DataFetchError refresh={forceRefresh} back message={initialValues.error} />
            ) : null
          ) : (
            <UpdateQRSupportForm
              data-test={dataTest && `${dataTest}-form`}
              data={initialValues.data}
              onSubmit={updateActiveAssets}
              onReset={doRedirect}
              submitCallback={updateOnSubmit}
            />
          )
        }
      </PageCard>
    </SettingsPageLayout>
  );
};

export default UpdateQRSupportPage;
