import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useTermsOfServiceDialog } from '@/features/auth/hooks';
import { isRecaptchaError } from '@/features/recaptcha/utils';
import { I18nPageAuth } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import type { Func } from '@/infrastructure/utils/ts';
import { isRejected } from '@/infrastructure/utils/ui';

import { Web3AuthJWTByEmail, Web3AuthJWTBySignature } from './components';

import type { Web3AuthJWTInProgressProps } from './types';

const notificationOptions = {
  success: () => ({
    message: <FormattedMessage id={I18nPageAuth.MESSAGE_SUCCESS} />,
    description: <FormattedMessage id={I18nPageAuth.MESSAGE_WELCOME} />,
  }),
  error: (error: unknown) =>
    !isRecaptchaError(error) && !isRejected(error)
      ? { message: <FormattedMessage id={I18nPageAuth.MESSAGE_GENERIC_ERROR} /> }
      : undefined,
};

const Web3AuthJWTInProgress: React.FC<Web3AuthJWTInProgressProps> = ({ 'data-test': dataTest, web3Auth }) => {
  const { withTermsAcceptance, acceptanceModal } = useTermsOfServiceDialog(dataTest);
  const { withNotification } = useNotification();

  const withLogIn = useMemo(
    () =>
      <V extends unknown[] = unknown[], R = unknown>(func: (onBeforeSignUp: Func) => Promise<R>): Func<V, R> =>
        withNotification(
          withTermsAcceptance((acceptTerms) => () => func(acceptTerms)),
          notificationOptions,
        ),
    [withTermsAcceptance, withNotification],
  );

  return (
    <>
      {acceptanceModal}
      {web3Auth.emailInfo?.token ? (
        <Web3AuthJWTByEmail data-test={dataTest} web3Auth={web3Auth} withLogIn={withLogIn} />
      ) : (
        <Web3AuthJWTBySignature data-test={dataTest} web3Auth={web3Auth} withLogIn={withLogIn} />
      )}
    </>
  );
};

const Web3AuthJWTInProgressMemo = React.memo(Web3AuthJWTInProgress);

export default Web3AuthJWTInProgressMemo;
