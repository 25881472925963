import { Space } from 'antd';
import { useMemo } from 'react';

import { DocumentationLink, FormattedMessage } from '@/components';
import { OperationAddWebhook, OperationRefreshWebhooks } from '@/features/company-settings/components';
import { useWebhooks } from '@/features/company-settings/hooks';
import type { Webhook } from '@/features/company-settings/types';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withWebhooksDataLoading =
  <
    Original extends { data: Webhook[] } & TestableProps,
    Wrapper extends Omit<Original, 'data'> = Omit<Original, 'data'>,
  >(
    Component: React.ComponentType<Original>,
  ): React.FC<Wrapper> =>
  (props) => {
    const dataTest = props['data-test'];
    const { data: baseData, loading, forceRefresh } = useWebhooks();
    const { network } = useSelectedNetwork();
    const data = useMemo(
      () => mapStoredState(baseData, (keys) => keys.filter((key) => key.network === network)),
      [baseData, network],
    );
    return withCardDataLoading<Webhook[], Original>({
      forceRefresh,
      loading,
      data,
      'data-test': dataTest,
      title: (
        <Space>
          <FormattedMessage id={I18nPageSettings.INTEGRATION_COMPONENTS_WEBHOOKS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <DocumentationLink data-test={dataTest && `${dataTest}-doc`} path="/api/webhooks" mode="text_icon" />
            <OperationAddWebhook key="create" data-test={dataTest && `${dataTest}-createOp`} mode="button" />
            <OperationRefreshWebhooks data-test={dataTest && `${dataTest}-refreshOp`} mode="inline" />
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withWebhooksDataLoading;
