import React, { useMemo } from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useSettlementReconciliation } from '@/features/settlement-reconciliations/hooks';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';
import { withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationRefreshSettlementReconciliationProps } from './types';

const messages = {
  tooltip: (
    <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_REFRESH_RECONCILIATION_TOOLTIP} />
  ),
  success: {
    message: (
      <FormattedMessage
        id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_REFRESH_RECONCILIATION_SUCCESS_MESSAGE}
      />
    ),
  },
  error: {
    message: (
      <FormattedMessage
        id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_REFRESH_RECONCILIATION_FAILED_MESSAGE}
      />
    ),
  },
};

const OperationRefreshSettlementReconciliation: React.FC<OperationRefreshSettlementReconciliationProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  settlementId,
  onSuccess,
}) => {
  const { forceRefresh } = useSettlementReconciliation(settlementId);
  const doRefresh = useMemo(
    () => (onSuccess ? withOnSuccess(forceRefresh, onSuccess) : forceRefresh),
    [forceRefresh, onSuccess],
  );
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={doRefresh} messages={messages} />;
};

const OperationRefreshSettlementReconciliationMemo = React.memo(OperationRefreshSettlementReconciliation);

export default OperationRefreshSettlementReconciliationMemo;
