import React from 'react';

import { CollectableEntityTransactionView } from '@/features/collectable/components';

import { withRechargeTransactionDataLoading } from './hocs';

const RechargeTransactionCard = withRechargeTransactionDataLoading((props) => (
  <CollectableEntityTransactionView {...props} columns={1} />
));

const RechargeTransactionCardMemo = React.memo(RechargeTransactionCard) as typeof RechargeTransactionCard;

export default RechargeTransactionCardMemo;
