import isNil from 'lodash/isNil';

import { FormattedMessage, NotificationMessage, PageLoading } from '@/components';
import { useMaintenance } from '@/features/maintenance/hooks';
import { I18nCommon } from '@/generated/i18n/i18n';
import { namedHOC } from '@/infrastructure/utils/react';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const withMaintenanceCheck = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithMaintenanceCheck',
  )((props) => {
    const { data, loading } = useMaintenance();
    if (loading && isNil(data.data)) {
      return (
        <MessageLayout>
          <PageLoading type="withMaintenanceCheck" />
        </MessageLayout>
      );
    }
    return data.data ? (
      <MessageLayout>
        <NotificationMessage
          severity="warning"
          message={<FormattedMessage id={I18nCommon.MESSAGES_MAINTENANCE_TITLE} />}
          description={<FormattedMessage id={I18nCommon.MESSAGES_MAINTENANCE_DETAILS} />}
        />
      </MessageLayout>
    ) : (
      <Component {...props} />
    );
  });

export default withMaintenanceCheck;
