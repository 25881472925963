/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewPaymentAPIModel
 */
export interface NewPaymentAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewPaymentAPIModel
     */
    amount: string;
    /**
     * 
     * @type {Date}
     * @memberof NewPaymentAPIModel
     */
    expiresAt: Date;
    /**
     * 
     * @type {object}
     * @memberof NewPaymentAPIModel
     */
    metadata?: object;
    /**
     * 
     * @type {string}
     * @memberof NewPaymentAPIModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof NewPaymentAPIModel
     */
    fullDetails?: string;
}

/**
 * Check if a given object implements the NewPaymentAPIModel interface.
 */
export function instanceOfNewPaymentAPIModel(value: object): boolean {
    if (!('amount' in value)) return false;
    if (!('expiresAt' in value)) return false;
    return true;
}

export function NewPaymentAPIModelFromJSON(json: any): NewPaymentAPIModel {
    return NewPaymentAPIModelFromJSONTyped(json, false);
}

export function NewPaymentAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPaymentAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'expiresAt': (new Date(json['expiresAt'])),
        'metadata': json['metadata'] == null ? undefined : json['metadata'],
        'description': json['description'] == null ? undefined : json['description'],
        'fullDetails': json['fullDetails'] == null ? undefined : json['fullDetails'],
    };
}

export function NewPaymentAPIModelToJSON(value?: NewPaymentAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'expiresAt': ((value['expiresAt']).toISOString()),
        'metadata': value['metadata'],
        'description': value['description'],
        'fullDetails': value['fullDetails'],
    };
}

