import { createAction } from '@reduxjs/toolkit';

import type { CommonLoadingState } from '@/infrastructure/model';

import type { UpdateFullDataParametersPayload } from './types';

export interface FullDataSingleUpdateAction<Value> {
  payload: Value;
}

export interface FullDataUpdateAction<Value> {
  payload: CommonLoadingState<Value[]>;
}

export interface FullParametersUpdateAction<Filter, SortBy extends string> {
  payload: UpdateFullDataParametersPayload<Filter, SortBy>;
}

// TODO: If the Value is object, the default Type should be name of this object - but how to realize it in TS?
export const createFullActions = <Value, Type extends string, Filter, SortBy extends string>(
  namespace: string,
  type: Type,
) => {
  const markDirtyName = `mark${type}FullDirty` as const;
  const storeDataName = `store${type}Data` as const;
  const storeFullDataName = `store${type}FullData` as const;
  const storeFullParametersName = `store${type}FullParameters` as const;

  const markDirty = createAction(`${namespace}/${markDirtyName}`);
  const storeData = createAction<FullDataSingleUpdateAction<Value>['payload']>(`${namespace}/${storeDataName}`);
  const storeFullData = createAction<FullDataUpdateAction<Value>['payload']>(`${namespace}/${storeFullDataName}`);
  const storeFullParameters = createAction<FullParametersUpdateAction<Filter, SortBy>['payload']>(
    `${namespace}/${storeFullParametersName}`,
  );

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [storeFullDataName]: storeFullData,
    [storeDataName]: storeData,
    [storeFullParametersName]: storeFullParameters,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof storeFullDataName, typeof storeFullData> &
    Record<typeof storeDataName, typeof storeData> &
    Record<typeof storeFullParametersName, typeof storeFullParameters>;
};

export interface NormalizedFullDataMarkDirtyAction<ParentId> {
  payload: ParentId;
}

export interface NormalizedFullDataUpdateAction<ParentId, Value> {
  payload: { parentId: ParentId; data: CommonLoadingState<Value[]> };
}

export interface NormalizedFullParametersUpdateAction<ParentId, Filter, SortBy extends string> {
  payload: { parentId: ParentId; parameters: UpdateFullDataParametersPayload<Filter, SortBy> };
}

// TODO: If the Value is object, the default Type should be name of this object - but how to realize it in TS?
export const createNormalizedFullActions = <
  Value,
  Type extends string,
  Filter,
  SortBy extends string,
  ParentId = string,
>(
  namespace: string,
  type: Type,
) => {
  const markDirtyName = `mark${type}FullDirty` as const;
  const storeDataName = `store${type}FullData` as const;
  const storeFullDataParametersName = `store${type}FullDataParameters` as const;

  const markDirty = createAction<NormalizedFullDataMarkDirtyAction<ParentId>['payload']>(
    `${namespace}/${markDirtyName}`,
  );
  const storeData = createAction<NormalizedFullDataUpdateAction<ParentId, Value>['payload']>(
    `${namespace}/${storeDataName}`,
  );
  const storeFullDataParameters = createAction<
    NormalizedFullParametersUpdateAction<ParentId, Filter, SortBy>['payload']
  >(`${namespace}/${storeFullDataParametersName}`);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [storeDataName]: storeData,
    [storeFullDataParametersName]: storeFullDataParameters,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof storeDataName, typeof storeData> &
    Record<typeof storeFullDataParametersName, typeof storeFullDataParameters>;
};
