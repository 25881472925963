import { FormOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useToSActions } from '@/features/branding/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { settingsBrandingToSLink } from '@/pages/settings/routes';

import type { ToSLinkProps } from './types';

const ToSLink: React.FC<ToSLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => {
  const { define: defineAction } = useToSActions();
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(settingsBrandingToSLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <FormOutlined />}
      disabled={!defineAction.available}
      title={title ?? <FormattedMessage id={I18nPageSettings.BRANDING_TOS_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const ToSLinkMemo = React.memo(ToSLink);

export default ToSLinkMemo;
