import type { MerchantFeatureFlagsAPIModel } from '@/generated/api/ncps-api';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export const NAMESPACE = 'feature-toggle';

export type FeatureFlagsKeys = keyof MerchantFeatureFlagsAPIModel;
export type FeatureFlags = MerchantFeatureFlagsAPIModel;

export interface FeatureToggleState {
  value: LoadingStateWithDirty<FeatureFlags>;
}
