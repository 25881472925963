import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import type { Donation } from '@/features/donations/types';
import { extractDonationId } from '@/features/donations/utils';
import { PushAddressSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { nameof } from '@/infrastructure/utils/ts';
import { sortOrderToTable } from '@/infrastructure/view';

import DonationAssetsView from '../DonationAssetsView';
import DonationStatusView from '../DonationStatusView';
import DonationView from '../DonationView';

import type { BaseDonationFilter, DonationsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<Donation>('createdAt')]: PushAddressSortByAPIModel.CreatedAt,
};
const extractKey = extractDonationId;
const sortColumnToModel = (s: string) => sortMapping[s];

const DonationsTable = <FilterType extends BaseDonationFilter = BaseDonationFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  DonationLink,
  Operations,
}: DonationsTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<Donation>[] = useMemo(
    () => [
      {
        key: nameof<Donation>('id'),
        title: <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_ID} />,
        dataIndex: nameof<Donation>('id'),
        render: (_, { id }) => <ReadonlyComponent value={id} data-test={dataTest && `${dataTest}-${id}-id`} copyable />,
      },
      {
        key: nameof<Donation>('title'),
        title: <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_TITLE} />,
        dataIndex: nameof<Donation>('title'),
        render: (_, { id, title }) => (
          <ReadonlyComponent value={title} data-test={dataTest && `${dataTest}-${id}-title`} />
        ),
      },
      {
        key: nameof<Donation>('addresses'),
        title: <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_ASSETS} />,
        dataIndex: nameof<Donation>('addresses'),
        render: (_, { id, addresses }) => (
          <DonationAssetsView
            data-test={dataTest && `${dataTest}-${id}-assets`}
            value={addresses}
            donationId={id}
            mode="active-tags"
          />
        ),
      },
      {
        key: nameof<Donation>('createdAt'),
        title: <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_CREATED_AT} />,
        dataIndex: nameof<Donation>('createdAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.CreatedAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime value={createdAt} data-test={dataTest && `${dataTest}-${id}-createdAt`} />
        ),
        width: 160,
      },
      {
        key: nameof<Donation>('isActive'),
        title: <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_IS_ACTIVE} />,
        dataIndex: nameof<Donation>('isActive'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { id, isActive }) => (
          <DonationStatusView value={isActive} data-test={dataTest && `${dataTest}-${id}-active`} mode="icon" />
        ),
        width: 60,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, value) => (
          <StopPropagationContainer>
            {DonationLink && <DonationLink value={value.id} data-test={dataTest && `${dataTest}-${value.id}-link`} />}
            {Operations && <Operations data-test={dataTest && `${dataTest}-${value.id}-op`} value={value} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [DonationLink, Operations, dataTest, sortBy.CreatedAt],
  );

  const preview = useCallback(
    (value: Donation) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureDonations.COMPONENTS_DONATIONS_TABLE_DONATION_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            DonationLink && (
              <DonationLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <DonationView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
          showAssets
        />
      ),
    }),
    [DonationLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'createdAtRange', 'createdAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey="createdAtRange"
              relativeKey="createdAtRangeRelative"
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      FilterForm,
      ExportOperation,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<Donation, FilterType, PushAddressSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const DonationsTableMemo = React.memo(DonationsTable);

export default DonationsTableMemo;
