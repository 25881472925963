import { useCallback, useEffect, useRef } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { storeViewHeight } from '@/features/global/actions';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withMobileDetection = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithMobileDetection',
  )((props) => {
    const dispatch = useAppDispatch();

    const vhSaved = useRef<number | undefined>();
    const updateVHState = useCallback((): void => {
      // mobile address bar trick: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
      const vh = window.innerHeight * 0.01;
      if (vh !== vhSaved.current) {
        vhSaved.current = vh;
        dispatch(storeViewHeight(vh));
      }
    }, [dispatch]);
    useEffect(() => {
      if (vhSaved.current === undefined) {
        updateVHState();
      }
      window.addEventListener('resize', updateVHState);
      return (): void => window.removeEventListener('resize', updateVHState);
    });

    return <Component {...props} />;
  });

export default withMobileDetection;
