import { QuestionCircleOutlined } from '@ant-design/icons';
import { Descriptions, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent, ReadonlyDateTime } from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import PayoutStatusView from '@/features/payouts/components/PayoutStatusView';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutViewProps } from './types';

const PayoutView: React.FC<PayoutViewProps> = ({
  data,
  'data-test': dataTest,
  style,
  className,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<PayoutStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_ID} />}>
      <ReadonlyComponent value={data.id} data-test={dataTest && `${dataTest}-id`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_TITLE} />}>
      <ReadonlyComponent value={data.title} data-test={dataTest && `${dataTest}-title`} />
    </Descriptions.Item>
    <Descriptions.Item
      label={
        <Space>
          <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_SETTLED_AMOUNT} />
          <Tooltip title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_VIEW_SETTLED_AMOUNT_HELP} />}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <ReadonlyComponent
        value={
          <AssetAmount
            data-test={dataTest && `${dataTest}-settledAmount`}
            value={data.settledAmount.value}
            assetId={data.settledAmount.asset}
            withCurrency
            currencyMode="long"
            withBlockchainMark
          />
        }
        copyable={false}
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={
        <Space>
          <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_TOTAL_AMOUNT} />
          <Tooltip title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_VIEW_TOTAL_AMOUNT_HELP} />}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <ReadonlyComponent
        value={
          <AssetAmount
            data-test={dataTest && `${dataTest}-amount`}
            value={data.amount.value}
            assetId={data.amount.asset}
            withCurrency
            currencyMode="long"
            withBlockchainMark
          />
        }
        copyable={false}
      />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_CREATED_AT} />}>
      <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_EXPIRES_AT} />}>
      <ReadonlyDateTime value={data.expiresAt} data-test={dataTest && `${dataTest}-expiresAt`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_DESTINATION_COUNT} />}>
      <ReadonlyComponent value={data.destinationsCount} data-test={dataTest && `${dataTest}-destinations`} />
    </Descriptions.Item>
  </Descriptions>
);

const PayoutViewMemo = React.memo(PayoutView) as typeof PayoutView;

export default PayoutViewMemo;
