import { PauseCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSubscription } from '@/features/subscriptions/hooks';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';

import { PauseSubscriptionModal } from './components';

import type { OperationPauseSubscriptionProps } from './types';

const OperationPauseSubscription: React.FC<OperationPauseSubscriptionProps> = ({
  'data-test': dataTest,
  subscriptionId,
  mode,
}) => {
  const formControl = useFormVisible(false);

  const { data: subscription } = useSubscription(subscriptionId);

  const pauseUnavailabilityReason = useMemo(() => {
    if (!subscription.data?.actions.pausable) {
      return <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_INVALID_STATUS} />;
    }
    return undefined;
  }, [subscription.data?.actions.pausable]);

  return (
    <>
      {formControl.visible && subscription.data && (
        <PauseSubscriptionModal
          subscription={subscription.data}
          onSuccess={formControl.hide}
          onCancel={formControl.hide}
        />
      )}
      <Operation
        data-test={dataTest && `${dataTest}-pause`}
        title={<FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_TITLE} />}
        mode={mode}
        icon={<PauseCircleOutlined style={{ color: 'orange' }} />}
        disabled={!!pauseUnavailabilityReason}
        disabledMessage={pauseUnavailabilityReason}
        onClick={formControl.show}
      />
    </>
  );
};

const OperationPauseSubscriptionMemo = React.memo(OperationPauseSubscription);

export default OperationPauseSubscriptionMemo;
