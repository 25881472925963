import { useCallback, useContext, useMemo } from 'react';

import { useIdleControl } from '@/features/global/hooks';
import Web3InitContext from '@/features/web3/hocs/withWeb3Support/context';
import type { Func } from '@/infrastructure/utils/ts';

export interface UseWeb3Singleton {
  initialized: boolean;
  withReloadIfInitialized: <T extends unknown[] = [], R = unknown>(func: Func<T, R>) => Func<T, R>;
  reloadIfInitialized: Func;
}

export default function useWeb3Singleton(): UseWeb3Singleton {
  const { initialized } = useContext(Web3InitContext);
  const { requestReload } = useIdleControl();
  const reloadIfInitialized = useCallback(() => {
    if (initialized) {
      requestReload();
    }
  }, [initialized, requestReload]);
  const withReloadIfInitialized = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        const result = await func(...params);
        reloadIfInitialized();
        return result;
      },
    [reloadIfInitialized],
  );
  return useMemo(
    () => ({
      initialized,
      withReloadIfInitialized,
      reloadIfInitialized,
    }),
    [initialized, reloadIfInitialized, withReloadIfInitialized],
  );
}
