import { mnemonicToSeedSync, validateMnemonic } from '@scure/bip39';
import { toHex } from 'viem';
import { english, generateMnemonic, mnemonicToAccount, HDKey, privateKeyToAccount } from 'viem/accounts';

type DerivationPath = `m/44'/60'/${string}`;

const derivationPath: DerivationPath = 'm/44/60/1/0/2021' as DerivationPath;

export const getKeyPath = (seqNum: number): DerivationPath => `${derivationPath}/${seqNum}`;

export const generateSeed = () => generateMnemonic(english);
export const isSeedValid = (seed: string) => validateMnemonic(seed, english);

export const toDerivedXpub = (seed: string) =>
  mnemonicToAccount(seed, { path: derivationPath }).getHdKey().publicExtendedKey;

export const xprvToDerivedXpub = (privateKey: string) =>
  HDKey.fromExtendedKey(privateKey).derive(derivationPath).publicExtendedKey;

export const toXprv = (seed: string) => {
  if (!isSeedValid(seed)) {
    throw new Error('seed is invalid');
  }
  return HDKey.fromMasterSeed(mnemonicToSeedSync(seed)).privateExtendedKey;
};
export const xprv0Derivation = (privateKey: string) =>
  privateKeyToAccount(toHex(HDKey.fromExtendedKey(privateKey).derive('m/0').privateKey!)).address;
