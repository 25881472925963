import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonation } from '@/features/donations/actions';
import { makeSelectDonationAsset } from '@/features/donations/selectors';
import type { Donation, DonationAddress } from '@/features/donations/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseDonationAsset = UseAppSingleData<DonationAddress, unknown>;

const noData = storedDataError<DonationAddress>('no-data');

const fetchFactory = (
  { donationId }: { donationId: string },
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Donation>, Parameters<typeof fetchDonation>[0]> =>
  fetchDonation({ donationId, force });

export default function useDonation(donationId: string | undefined, asset: string | undefined): UseDonationAsset {
  const id = useMemo(() => (donationId && asset ? { donationId, asset } : undefined), [asset, donationId]);
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchDonation, donationId), [donationId]);
  return useAppSingleData<{ donationId: string; asset: string }, DonationAddress, unknown>(
    fetchFactory,
    makeSelectDonationAsset,
    noData,
    id,
    dataFetchingSelector,
  );
}
