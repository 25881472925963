import isNil from 'lodash/isNil';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { logout } from '@/features/auth/actions';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { fetchAssets, fetchBlockchainsSystemInfo } from '@/features/dictionary/blockchain/actions';
import { makeSelectUser } from '@/features/user/selectors';
import type {
  BlockchainNetworkTypeAPIModel,
  ForwarderSettingsAPIModel,
  PaymentSettingsAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { withAPICall } from '@/infrastructure/api';
import type { CommonLoadingState } from '@/infrastructure/model';
import { loadingDataError, mapLoadingState, storedDataError } from '@/infrastructure/model';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { onlyUnique } from '@/infrastructure/utils/functions';
import { callWithThunkError } from '@/infrastructure/utils/redux';
import { notEmpty } from '@/infrastructure/utils/ts';

import {
  requestAddAPIKey,
  requestDeactivateAPIKey,
  requestActivateAPIKey,
  requestUpdateEmail,
  querySettings,
  requestDeleteAPIKey,
  requestUpdateWebhooks,
  queryAPIKeys,
  requestUpdateActiveAssets,
  requestUpdateDefaultAsset,
  requestUpdateForwarderSettings,
  requestConfirmEmail,
  requestResetEmail,
  requestUpdatePaymentSettings,
  requestUpdateWebsite,
} from './api';
import { makeSelectAPIKeys, makeSelectCompanySettings } from './selectors';
import { NAMESPACE } from './types';

import type {
  CompanySettings,
  Webhook,
  APIKeyId,
  APIKey,
  ConfirmCompanyEmailParams,
  RenewalTokenExpiration,
} from './types';

export const { storeCompanySettings, markCompanySettingsDirty } = createLoadingDataActions<
  CompanySettings,
  'CompanySettings'
>(NAMESPACE, 'CompanySettings');

const settingsFetchLimit = pLimit(1);
export const fetchCompanySettings = createAppAsyncThunk(
  `${NAMESPACE}/fetchCompanySettings`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    settingsFetchLimit(async () => {
      const saved = makeSelectCompanySettings()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySettings, 'unable to fetch settings')({ signal });
      dispatch(storeCompanySettings(data));
      return makeSelectCompanySettings()(getState());
    }),
);

export const updateWebhooks = createAppAsyncThunk(
  `${NAMESPACE}/updateWebhooks`,
  async (webhooks: Webhook[], { dispatch, signal }) => {
    const data = await withAPICall(requestUpdateWebhooks, 'unable to update webhook')(webhooks, { signal });
    if (data.data) {
      dispatch(storeCompanySettings(data));
    }
    return mapLoadingState(data, (settings) => settings.webhooks);
  },
);

export const { storeAPIKeys, markAPIKeysDirty } = createLoadingDataActions<APIKey[], 'APIKeys'>(NAMESPACE, 'APIKeys');
const apiKeysFetchLimit = pLimit(1);
export const fetchAPIKeys = createAppAsyncThunk(
  `${NAMESPACE}/fetchAPIKeys`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    apiKeysFetchLimit(async () => {
      const saved = makeSelectAPIKeys()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryAPIKeys, 'unable to fetch API keys')({ signal });
      dispatch(storeAPIKeys(data));
      return makeSelectAPIKeys()(getState());
    }),
);

export const addAPIKey = createAppAsyncThunk(
  `${NAMESPACE}/addAPIKey`,
  async ({ network }: { network: BlockchainNetworkTypeAPIModel }, { dispatch, signal }) => {
    const data = await withAPICall(requestAddAPIKey, 'unable to add API key')(network, { signal });
    dispatch(markAPIKeysDirty());
    return data;
  },
);
export const updateAPIKeyState = createAppAsyncThunk(
  `${NAMESPACE}/updateAPIKeyState`,
  async ({ key: { key, network }, active }: { key: APIKeyId; active: boolean }, { dispatch, signal }) => {
    const data = await withAPICall(
      active ? requestActivateAPIKey : requestDeactivateAPIKey,
      'unable to update API key state',
    )(key, network, { signal });
    if (data.data) {
      dispatch(markAPIKeysDirty());
    }
    return data;
  },
);
export const deleteAPIKey = createAppAsyncThunk(
  `${NAMESPACE}/deleteAPIKey`,
  async ({ key: { key, network } }: { key: APIKeyId }, { dispatch, signal }): Promise<CommonLoadingState<APIKey[]>> => {
    const data = await withAPICall(requestDeleteAPIKey, 'unable to delete API key')(key, network, { signal });
    if (!data.error) {
      return dispatch(fetchAPIKeys({ force: true })).unwrap();
    }
    return storedDataError<APIKey[]>(data.error);
  },
);

export const updateEmail = createAppAsyncThunk(
  `${NAMESPACE}/updateEmail`,
  async (
    { email }: { email: string },
    { dispatch, getState, signal },
  ): Promise<CommonLoadingState<CompanySettings | RenewalTokenExpiration>> => {
    const userEmail = makeSelectUser()(getState()).data?.email;
    if (userEmail === email) {
      const data = await withAPICall(requestResetEmail, 'unable to reset email')({ signal });
      if (data.data) {
        dispatch(storeCompanySettings(data));
      }
      return data;
    }
    return withAPICall(requestUpdateEmail, 'unable to update email')(email, { signal });
  },
);

export const confirmEmail = createAppAsyncThunk(
  `${NAMESPACE}/confirmEmail`,
  async (payload: ConfirmCompanyEmailParams, { dispatch, getState, signal }) => {
    const activeCompanyId = makeSelectAuthToken()(getState()).data?.info.activeCompanyId;
    const id = payload.companyId ?? activeCompanyId;
    if (isNil(id)) {
      return loadingDataError('NoCompanyId');
    }
    const result = await withAPICall(requestConfirmEmail, 'Unable to confirm email update request')(
      { ...payload, companyId: id },
      { signal },
    );
    if (result.error) {
      return result;
    }
    if (!isNil(activeCompanyId)) {
      if (isNil(payload.companyId) || payload.companyId === activeCompanyId) {
        dispatch(markCompanySettingsDirty());
      } else {
        await withAPICall(() => dispatch(logout({})).unwrap())();
      }
    }
    return result;
  },
);

export const updatePaymentSettings = createAppAsyncThunk(
  `${NAMESPACE}/updatePaymentSettings`,
  async (settings: Pick<PaymentSettingsAPIModel, 'compensateDirect' | 'deferredAllowed'>, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdatePaymentSettings, 'Unable to update settings')(settings, { signal });
    if (data.data) {
      dispatch(storeCompanySettings(data));
    }
    return data;
  },
);

export const updateForwarderSettings = createAppAsyncThunk(
  `${NAMESPACE}/updateForwarderSettings`,
  async (settings: ForwarderSettingsAPIModel[], { dispatch, signal }) => {
    const data = await withAPICall(requestUpdateForwarderSettings, 'Unable to update settings')(settings, { signal });
    if (data.data) {
      dispatch(storeCompanySettings(data));
    }
    return data;
  },
);

export const updateCompanyWebsite = createAppAsyncThunk(
  `${NAMESPACE}/updateCompanyWebsite`,
  async (url: string, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdateWebsite, 'Unable to update company website')(url, { signal });
    if (data.data) {
      dispatch(storeCompanySettings(data));
    }
    return data;
  },
);

export const updateActiveAssets = createAppAsyncThunk(
  `${NAMESPACE}/updateActiveAssets`,
  async ({ assets }: { assets: string[] }, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdateActiveAssets, 'unable to update active assets')(assets, { signal });
    if (data.data) {
      dispatch(storeCompanySettings(data));
      const qrSupport = data.data.qrSupport;
      return callWithThunkError(async () => {
        const { data: blockchainsMeta } = await dispatch(fetchBlockchainsSystemInfo({})).unwrap();
        const { data: assetsMeta } = await dispatch(fetchAssets({})).unwrap();
        if (blockchainsMeta && assetsMeta) {
          const newlyRequiredForwarders = assetsMeta
            .filter(({ code }) => assets.includes(code))
            .map(({ blockchain }) => blockchain)
            .filter(onlyUnique)
            .filter(notEmpty)
            .map((bt) => blockchainsMeta[bt])
            .filter(notEmpty)
            .filter(({ forwarder }) => forwarder === 'required')
            .map(({ bt }) => bt)
            .filter((bt) => !qrSupport.find(({ blockchain }) => blockchain === bt)?.isEnabled);
          if (newlyRequiredForwarders.length) {
            const toUpdate = qrSupport.map((fwSettings) =>
              newlyRequiredForwarders.includes(fwSettings.blockchain) ? { ...fwSettings, isEnabled: true } : fwSettings,
            );
            return dispatch(updateForwarderSettings(toUpdate)).unwrap();
          }
        }
        return data;
      });
    }
    return data;
  },
);

export const updateDefaultAsset = createAppAsyncThunk(
  `${NAMESPACE}/updateStatisticsAsset`,
  async (asset: string, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdateDefaultAsset, 'unable to update default asset')(asset, { signal });
    if (data.data) {
      dispatch(storeCompanySettings(data));
    }
    return data;
  },
);
