import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { exportGasHistory } from '@/features/gas-history/actions';
import { useActionPending } from '@/features/global/hooks';
import type { Report } from '@/features/reports/types';
import type { HookAction } from '@/infrastructure/model';

import useGasHistoryList from './useGasHistoryList';

export interface UseGasHistoryListActions {
  exportTransactions: HookAction<[], Report>;
}

export default function useGasHistoryListActions(): UseGasHistoryListActions {
  const { loading, filter } = useGasHistoryList();
  const { withExtractDataDispatch } = useAppDispatch();

  const isExporting = useActionPending(exportGasHistory);
  const exportTransactionsAction: UseGasHistoryListActions['exportTransactions'] = {
    act: useCallback(
      () => withExtractDataDispatch(exportGasHistory)({ predicates: filter }),
      [filter, withExtractDataDispatch],
    ),
    inAction: isExporting,
    available: !loading,
  };

  return { exportTransactions: exportTransactionsAction };
}
