import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationTitleLikeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_DONATION_FILTER_TITLE_LABEL} />,
};

const DonationTitleLikeFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: DonationTitleLikeFilterItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      required={required}
      messages={messages}
      placeholder={formatMessage({ id: I18nFeatureDonations.COMPONENTS_DONATION_FILTER_TITLE_PLACEHOLDER })}
      {...ItemProps}
    />
  );
};

const DonationTitleLikeFilterItemMemo = React.memo(DonationTitleLikeFilterItem) as typeof DonationTitleLikeFilterItem;

export default DonationTitleLikeFilterItemMemo;
