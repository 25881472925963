import React, { useMemo } from 'react';

import type { CollectScheduleViewProps } from '@/features/collectable/components';
import { CollectScheduleView } from '@/features/collectable/components';

import { withRechargeScheduleDataLoading } from './hocs';

import type { CollectableScheduleCardProps } from './types';

const RechargeScheduleCard = ({ Extra, ...props }: CollectableScheduleCardProps) => {
  const Component = useMemo(
    () =>
      withRechargeScheduleDataLoading<
        Omit<CollectScheduleViewProps, 'schedule'> & { data: NonNullable<CollectScheduleViewProps['schedule']> }
      >(({ data, ...props }) => <CollectScheduleView {...props} schedule={data} />),
    [],
  );
  return <Component {...props} />;
};

const RechargeScheduleCardMemo = React.memo(RechargeScheduleCard);

export default RechargeScheduleCardMemo;
