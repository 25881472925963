import { useAppLoadableData } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchGrowthRate } from '@/features/subscription-statistics/actions';
import { makeSelectSubscriptionMonthlyGrowthRate } from '@/features/subscription-statistics/selectors';

const dataSelector = makeSelectSubscriptionMonthlyGrowthRate();
const dataFetchingSelector = makeSelectPending(fetchGrowthRate);
const fetchFactory = (force: boolean) => fetchGrowthRate({ force });

export default function useSubscriptionGrowthRate() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
