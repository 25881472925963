import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import {
  markReloadOnIdleRequested,
  storeIdleFinished,
  storeReloadOnIdleBlocked,
  storeReloadOnIdleUnblocked,
} from '@/features/global/actions';
import { makeSelectIsIdle } from '@/features/global/selectors';

export interface UseIdleControlType {
  isIdle: boolean;
  markActive: () => void;
  markIdle: () => void;
  requestReload: () => void;
  blockReload: (task: string) => void;
  unblockReload: (task: string) => void;
}

const isIdleSelector = makeSelectIsIdle();

export default function useIdleControl(): UseIdleControlType {
  const { dispatch, withDispatch } = useAppDispatch();
  const markActive = useCallback(() => dispatch(storeIdleFinished()), [dispatch]);
  const markIdle = useCallback(() => dispatch(storeIdleFinished()), [dispatch]);
  const requestReload = useCallback(() => dispatch(markReloadOnIdleRequested()), [dispatch]);
  const blockReload = useMemo(() => withDispatch(storeReloadOnIdleBlocked), [withDispatch]);
  const unblockReload = useMemo(() => withDispatch(storeReloadOnIdleUnblocked), [withDispatch]);

  const isIdle = useAppSelector(isIdleSelector);

  return {
    isIdle,
    markActive,
    markIdle,
    requestReload,
    blockReload,
    unblockReload,
  };
}
