import { Space } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { OperationRefreshDonation, DonationsDocumentationLink } from '@/features/donations/components';
import { useDonation } from '@/features/donations/hooks';
import type { DonationAddress } from '@/features/donations/types';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDonationAssetsDataLoading = <
  Original extends { data: DonationAddress[]; donationId: string } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithDonationAssetsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const { data: donation, loading, forceRefresh } = useDonation(props.donationId);
    const data = useMemo(() => mapStoredState(donation, ({ addresses }) => addresses), [donation]);
    return withCardDataLoading<DonationAddress[], Original>({
      data,
      loading,
      forceRefresh,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageDonations.DONATION_VIEW_COMPONENTS_DONATION_ASSETS_CARD_TITLE}
          values={{ id: props.donationId }}
        />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <DonationsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationRefreshDonation data-test={dataTest && `${dataTest}-opRefresh`} donationId={props.donationId} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withDonationAssetsDataLoading;
