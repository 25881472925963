import { useMemo } from 'react';

import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayoutBatches } from '@/features/payouts/actions';
import { makeSelectPayoutBatches } from '@/features/payouts/selectors';
import type { PayoutBatch } from '@/features/payouts/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataError } from '@/infrastructure/model';

import type { UsePayoutBatches } from './types';

const noData = storedDataError<PayoutBatch[]>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<PayoutBatch[]>, Parameters<typeof fetchPayoutBatches>[0]> =>
  fetchPayoutBatches({ id, force });

export default function usePayoutBatches(id: string | undefined): UsePayoutBatches {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchPayoutBatches, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectPayoutBatches, noData, id, dataFetchingSelector);
}
