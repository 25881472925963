import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshSubscriptionPlan } from '@/features/subscription-plans/components';
import { useSubscriptionPlan } from '@/features/subscription-plans/hooks';
import type { SubscriptionPlan } from '@/features/subscription-plans/types';
import { SubscriptionsDocumentationLink } from '@/features/subscriptions/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionPlanCardDataLoading =
  <T extends { data: SubscriptionPlan } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { planId: string }> =>
  (props) => {
    const dataTest: string | undefined = props['data-test'];
    const data = useSubscriptionPlan(props.planId);
    return withCardDataLoading<SubscriptionPlan, T>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageSubscriptions.PLAN_VIEW_COMPONENTS_PLAN_CARD_TITLE}
          values={{ id: props.planId }}
        />
      ),
      CardProps: {
        extra: (
          <Space>
            <SubscriptionsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="text_icon" />
            <OperationRefreshSubscriptionPlan planId={props.planId} data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withSubscriptionPlanCardDataLoading;
