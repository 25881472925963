import { createAction } from '@reduxjs/toolkit';

import { createAppAsyncThunk } from '@/app/actions';
import { makeSelectLocale } from '@/features/i18n/selectors';
import { I18nLocales } from '@/generated/i18n/i18n';
import { defaultLocaleName } from '@/hocs/withI18nProvider/i18n';
import createCache from '@/infrastructure/utils/cache';
import { enumByKey } from '@/infrastructure/utils/ts';

import { NAMESPACE } from './types';

const localeCache = createCache<string>(import.meta.env.VITE_LOCALE_KEY);

export const storeLocale = createAction<I18nLocales>(`${NAMESPACE}/storeLocale`);

export const updateLocale = createAppAsyncThunk(
  `${NAMESPACE}/updateLocale`,
  (newLocale: I18nLocales, { dispatch, getState }) => {
    try {
      const oldLocale = makeSelectLocale()(getState());
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (newLocale !== oldLocale) {
        localeCache.safeSave(newLocale);
        dispatch(storeLocale(newLocale));
      }
    } finally {
      dispatch(storeLocale(newLocale));
    }
  },
);

export const initLocale = createAppAsyncThunk(`${NAMESPACE}/initLocale`, async (_, { dispatch }) => {
  const localeFromCache = localeCache.safeRead();
  const browserLang = navigator.language.replace(/-/g, '');
  const localeFromEnv = window.env.DEFAULT_LOCALE;

  const locale =
    enumByKey(I18nLocales, localeFromCache)
    ?? enumByKey(I18nLocales, browserLang)
    ?? enumByKey(I18nLocales, localeFromEnv)
    ?? defaultLocaleName;

  await dispatch(updateLocale(locale)).unwrap();
});
