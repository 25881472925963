/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MerchantPreferencesToStoreAPIModel
 */
export interface MerchantPreferencesToStoreAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MerchantPreferencesToStoreAPIModel
     */
    lang?: string;
    /**
     * 
     * @type {string}
     * @memberof MerchantPreferencesToStoreAPIModel
     */
    network?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPreferencesToStoreAPIModel
     */
    onboarded?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPreferencesToStoreAPIModel
     */
    cookiesAccepted?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MerchantPreferencesToStoreAPIModel
     */
    helpViewed?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPreferencesToStoreAPIModel
     */
    kybViewed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MerchantPreferencesToStoreAPIModel
     */
    privateKeySecured?: boolean;
}

/**
 * Check if a given object implements the MerchantPreferencesToStoreAPIModel interface.
 */
export function instanceOfMerchantPreferencesToStoreAPIModel(value: object): boolean {
    return true;
}

export function MerchantPreferencesToStoreAPIModelFromJSON(json: any): MerchantPreferencesToStoreAPIModel {
    return MerchantPreferencesToStoreAPIModelFromJSONTyped(json, false);
}

export function MerchantPreferencesToStoreAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantPreferencesToStoreAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'lang': json['lang'] == null ? undefined : json['lang'],
        'network': json['network'] == null ? undefined : json['network'],
        'onboarded': json['onboarded'] == null ? undefined : json['onboarded'],
        'cookiesAccepted': json['cookiesAccepted'] == null ? undefined : json['cookiesAccepted'],
        'helpViewed': json['helpViewed'] == null ? undefined : json['helpViewed'],
        'kybViewed': json['kybViewed'] == null ? undefined : json['kybViewed'],
        'privateKeySecured': json['privateKeySecured'] == null ? undefined : json['privateKeySecured'],
    };
}

export function MerchantPreferencesToStoreAPIModelToJSON(value?: MerchantPreferencesToStoreAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lang': value['lang'],
        'network': value['network'],
        'onboarded': value['onboarded'],
        'cookiesAccepted': value['cookiesAccepted'],
        'helpViewed': value['helpViewed'],
        'kybViewed': value['kybViewed'],
        'privateKeySecured': value['privateKeySecured'],
    };
}

