import { Spin } from 'antd';

import { DataFetchError, PageCard } from '@/components';
import { namedHOC } from '@/infrastructure/utils/react';
import { notEmpty } from '@/infrastructure/utils/ts';

import type { WithCardDataLoadingParams } from './types';
import type React from 'react';

const withCardDataLoading =
  <
    Value,
    Original extends { data: Value },
    Omitted extends keyof Original = 'data',
    Wrapper extends Omit<Original, 'data' | Omitted> = Omit<Original, 'data' | Omitted>,
  >({
    data,
    forceRefresh,
    loading,
    title,
    'data-test': dataTest,
    onErrorMessage,
    onError,
    CardProps = {},
    hideBack,
    checkDataPresented = notEmpty,
    cardSize = 'big',
    noData,
  }: WithCardDataLoadingParams<Value>) =>
  (Component: React.ComponentType<Original>): React.FC<Wrapper> =>
    namedHOC<Original, Wrapper>(
      Component,
      'WithCardDataLoading',
    )((props) => (
      <PageCard data-test={dataTest} loading={loading && !data.data} title={title} {...CardProps} cardSize={cardSize}>
        {data.error
          && (onError ? (
            onError(
              data.error,
              <DataFetchError refresh={forceRefresh} back={!hideBack} message={onErrorMessage?.(data.error)} />,
            )
          ) : (
            <DataFetchError refresh={forceRefresh} back={!hideBack} message={onErrorMessage?.(data.error)} />
          ))}
        {checkDataPresented(data.data) && (
          <Spin spinning={loading}>
            {/* @ts-expect-error - don't know how to prove ANY component with the same props fits */}
            <Component data-test={dataTest} {...props} data={data.data} />
          </Spin>
        )}
        {!data.data && !data.error && !loading && noData}
      </PageCard>
    ));

export default withCardDataLoading;
