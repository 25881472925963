import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { FormCompletenessItem } from '@/components';
import { useStateMountSafe } from '@/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { nameof } from '@/infrastructure/utils/ts';

import PayoutTableRowFormValueContext from './context';

import type { PayoutDestinationTableEditContextType, PayoutDestinationTableEditFormValues } from './types';
import type { FormInstance } from 'antd/es/form';
import type { Reference } from 'rc-table/lib/interface';

const withPayoutDestinationTableEditContext = <T extends EmptyObject>(
  Component: ReturnType<typeof React.forwardRef<Reference, T>>,
) =>
  namedHOC(
    Component,
    'WithPayoutDestinationTableEditContext',
  )(
    React.forwardRef<Reference, T>(function PayoutDestinationTableEditContext(props, ref) {
      const formItemName = useMemo(() => uuid(), []);
      const requiredFields = useMemo(
        () => [
          [formItemName, nameof<PayoutDestinationTableEditFormValues>('address')],
          [formItemName, nameof<PayoutDestinationTableEditFormValues>('amount')],
        ],
        [formItemName],
      );
      const [isReady, setIsReady] = useStateMountSafe(false);
      const [editingKey, setEditingKey] = useStateMountSafe<number>();
      const [initialValue, setInitialValue] = useStateMountSafe<Partial<PayoutDestinationTableEditFormValues>>();
      const context = useMemo<PayoutDestinationTableEditContextType>(
        () => ({ formItemName, isReady, setIsReady, initialValue, setInitialValue, editingKey, setEditingKey }),
        [editingKey, formItemName, initialValue, isReady, setEditingKey, setInitialValue, setIsReady],
      );
      return (
        <PayoutTableRowFormValueContext.Provider value={context}>
          <Component {...props} ref={ref} />
          <FormCompletenessItem
            requiredFields={requiredFields}
            onChange={setIsReady}
            checkIsComplete={useCallback(
              ({ getFieldValue }: FormInstance) => {
                const newAddress: Partial<PayoutDestinationTableEditFormValues['address']> | undefined = getFieldValue([
                  formItemName,
                  nameof<PayoutDestinationTableEditFormValues>('address'),
                ]);
                const newAmount: Partial<PayoutDestinationTableEditFormValues['amount']> | undefined = getFieldValue([
                  formItemName,
                  nameof<PayoutDestinationTableEditFormValues>('amount'),
                ]);
                return (
                  newAddress !== initialValue?.address
                  || (!!newAmount?.value && !initialValue?.amount?.value?.eq(newAmount.value))
                );
              },
              [formItemName, initialValue],
            )}
          />
        </PayoutTableRowFormValueContext.Provider>
      );
    }),
  );

export default withPayoutDestinationTableEditContext;
