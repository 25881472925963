import type { DataSlice, SliceDataRequestWithTotal } from '@/infrastructure/api';
import { type CommonLoadingState, loadingDataLoaded, mapLoadingState } from '@/infrastructure/model';
import type { ListData, ListState, LoadingListDataState } from '@/infrastructure/model/list/types';
import type { StoreMultipleActionPayload } from '@/infrastructure/model/single/types';

export const flatMapListState = <Value, Result, E = string>(
  state: LoadingListDataState<Value, E>,
  mapper: (data: ListData<Value>) => LoadingListDataState<Result, E>,
): LoadingListDataState<Result, E> => {
  const { data } = state;
  if (!data) {
    // data is empty, so T[] = R[], and I don't want to update the reference
    return state as unknown as LoadingListDataState<Result, E>;
  }
  return mapper(data);
};

export const mapListState = <Value, Result, E = string>(
  state: LoadingListDataState<Value, E>,
  mapper: (data: Value) => Result,
): LoadingListDataState<Result, E> =>
  flatMapListState(state, (data) => ({ ...state, data: { data: data.data.map(mapper), total: data.total } }));

export const sliceToListData =
  (defaultTotal?: number) =>
  <Value, SortBy extends string = string>({ list, total }: DataSlice<Value, SortBy>): ListData<Value> => ({
    data: list,
    total: total ?? defaultTotal ?? 0,
  });

export const mapLoadingSliceStateToListData =
  (defaultTotal?: number) =>
  <Value, SortBy extends string = string>(
    data: CommonLoadingState<DataSlice<Value, SortBy>>,
  ): CommonLoadingState<ListData<Value>> =>
    mapLoadingState(data, sliceToListData(defaultTotal));

export const sliceToMultipleEntities = <Value, Id, SortBy extends string = string>(
  { list }: DataSlice<Value, SortBy>,
  extractId: (value: Value) => Id,
): StoreMultipleActionPayload<Value, Id> =>
  list.map((data) => ({
    id: extractId(data),
    data: loadingDataLoaded(data),
  }));

export const listStateToSliceRequest = <Value, Filter, SortBy extends string = string>(
  { filter, sortBy, page, data }: Omit<ListState<Value, Filter, SortBy>, 'columnState'>,
  isForceRequest?: boolean,
): SliceDataRequestWithTotal<Filter, SortBy> => ({
  filter,
  orderBy: sortBy,
  page,
  withTotal: data.isTotalDirty || !!isForceRequest,
});

export const storedDirtyListData: LoadingListDataState<unknown> = {
  data: { data: [], total: 0 },
  isDirty: true,
  isTotalDirty: true,
};
export const storedDirtyListDataTyped = <T, E = string>(): LoadingListDataState<T, E> =>
  storedDirtyListData as unknown as LoadingListDataState<T, E>;
export const storedListDataError = <T, E = string>(error: E): LoadingListDataState<T, E> => ({
  error,
  isDirty: false,
  isTotalDirty: false,
});
