import { Form } from 'antd';
import React, { useMemo } from 'react';

import { EmailConfirmationInput } from './components';

import type { EmailConfirmationInputValues } from './components';
import type { EmailConfirmationItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const EmailConfirmationItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  ItemProps,
  passThroughEmail,
  disabled,
  withVerifying,
}: EmailConfirmationItemProps<Values>) => (
  <Form.Item<Values>
    name={name}
    rules={useMemo(
      () => [
        {
          validator: async (_, values: EmailConfirmationInputValues) => {
            if (!values.email) {
              throw new Error();
            }
            if (!values.auth0Token) {
              if (!passThroughEmail || values.email !== passThroughEmail) {
                throw new Error();
              }
            }
            return Promise.resolve();
          },
        },
      ],
      [passThroughEmail],
    )}
    {...ItemProps}
  >
    <EmailConfirmationInput
      data-test={dataTest && `${dataTest}-input`}
      withVerifying={withVerifying}
      passThroughEmail={passThroughEmail}
      disabled={disabled}
    />
  </Form.Item>
);

const EmailConfirmationItemMemo = React.memo(EmailConfirmationItem) as typeof EmailConfirmationItem;

export default EmailConfirmationItemMemo;
