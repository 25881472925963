import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { MerchantWalletTransfersTable } from '@/features/merchant-wallet-transfers/components';
import {
  useSettlementReconciliation,
  useSettlementReconciliationMWTransfersView,
} from '@/features/settlement-reconciliations/hooks';
import { MerchantWalletTransferDirectionAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { mapFullDataToListData } from '@/infrastructure/model/full/utils';
import MerchantWalletTransferEntityLink from '@/pages/audit/transfer-entity/components/MerchantWalletTransferEntityLink';
import MerchantWalletTransferLink from '@/pages/audit/transfer-view/components/MerchantWalletTransferLink';

import type { SettlementReconciliationOutgoingTransfersCardProps } from './types';

const SettlementReconciliationOutgoingTransfersCard: React.FC<SettlementReconciliationOutgoingTransfersCardProps> = ({
  'data-test': dataTest,
  settlementId,
}) => {
  const reconciliationData = useSettlementReconciliation(settlementId);
  const {
    data: filteredData,
    fullData,
    ...dataState
  } = useSettlementReconciliationMWTransfersView(MerchantWalletTransferDirectionAPIModel.Outgoing, settlementId);
  const data = useMemo(() => mapFullDataToListData(filteredData, fullData), [fullData, filteredData]);
  const sum = useMemo(
    () => fullData.data?.map(({ amount }) => amount.value).reduce((v1, v2) => v1.plus(v2), new BigNumber(0)),
    [fullData.data],
  );

  if (reconciliationData.data.error === 'WithdrawalReconciliationNotFound') {
    return null;
  }
  return (
    <PageCard
      data-test={dataTest && `${dataTest}-card`}
      title={<FormattedMessage id={I18nPageSettlements.SETTLEMENT_VIEW_COMPONENTS_OUTGOING_TRANSFERS_CARD_TITLE} />}
      cardSize="big"
    >
      <MerchantWalletTransfersTable
        data-test={dataTest}
        {...dataState}
        title={
          sum && reconciliationData.data.data ? (
            <FormattedMessage
              id={I18nPageSettlements.SETTLEMENT_VIEW_COMPONENTS_OUTGOING_TRANSFERS_CARD_TOTAL_LABEL}
              values={{
                amount: (
                  <>
                    &nbsp;
                    <AssetAmount
                      data-test={dataTest && `${dataTest}-total`}
                      assetId={reconciliationData.data.data.expectedAmount.asset}
                      value={sum}
                      withCurrency
                      withBlockchainMark
                    />
                  </>
                ),
              }}
            />
          ) : undefined
        }
        data={data}
        EntityLink={MerchantWalletTransferEntityLink}
        MerchantWalletTransferLink={MerchantWalletTransferLink}
      />
    </PageCard>
  );
};

const SettlementReconciliationOutgoingTransfersCardFlagged = withFeatureToggleGuard(
  'enableReconciliation',
  true,
)(SettlementReconciliationOutgoingTransfersCard);

const SettlementReconciliationOutgoingTransfersCardMemo = React.memo(
  SettlementReconciliationOutgoingTransfersCardFlagged,
);

export default SettlementReconciliationOutgoingTransfersCardMemo;
