import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useDonationTransaction } from '@/features/donations/hooks';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { OperationRefreshDonationTransactionProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_TRANSACTION_TOOLTIP} />,
  success: {
    message: (
      <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_TRANSACTION_SUCCESS_MESSAGE} />
    ),
  },
};

const OperationRefreshDonationTransaction: React.FC<OperationRefreshDonationTransactionProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  transactionId,
}) => {
  const { forceRefresh } = useDonationTransaction(transactionId);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshDonationTransactionMemo = React.memo(OperationRefreshDonationTransaction);

export default OperationRefreshDonationTransactionMemo;
