import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCompanySettings } from '@/features/company-settings/actions';
import { makeSelectCompanySettings } from '@/features/company-settings/selectors';
import type { CompanySettings } from '@/features/company-settings/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<CompanySettings>, Parameters<typeof fetchCompanySettings>[0]> =>
  fetchCompanySettings({ force });
const selectFetching = makeSelectPending(fetchCompanySettings);
const selectData = makeSelectCompanySettings();

export default function useCompanySettings() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
