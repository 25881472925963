import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import type { ReportScheduleSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { defaultPageFn, withAPICall } from '@/infrastructure/api';
import { mapLoadingState } from '@/infrastructure/model';
import { createNormalizedListActions } from '@/infrastructure/model/list/actions';
import { listStateToSliceRequest, mapLoadingSliceStateToListData } from '@/infrastructure/model/list/utils';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';

import {
  queryReportSchedule,
  queryReportSchedules,
  requestActivateReportSchedule,
  requestCreateReportSchedule,
  requestDeactivateReportSchedule,
  requestDeleteReportSchedule,
  requestUpdateReportSchedule,
} from './api';
import {
  makeSelectDirtyReportScheduleIds,
  makeSelectMultipleReportSchedule,
  makeSelectReportSchedule,
  makeSelectReportScheduleListData,
  makeSelectReportScheduleListParameters,
} from './selectors';
import { NAMESPACE } from './types';
import { extractReportScheduleId } from './utils';

import type { ReportSchedule, ReportScheduleFilterPredicate, NewReportSchedule, UpdateReportSchedule } from './types';

export const {
  markReportScheduleDirty,
  markReportScheduleListDirty,
  markMultipleReportScheduleDirty,
  storeReportScheduleListData,
  storeReportScheduleListParameters,
  storeReportSchedule,
  storeMultipleReportSchedule,
  storeRemoveReportSchedule,
} = createNormalizedListActions<
  ReportSchedule,
  'ReportSchedule',
  ReportScheduleFilterPredicate,
  ReportScheduleSortByAPIModel
>(NAMESPACE, 'ReportSchedule');

const schedulesFetchLimit = pLimit(1);
export const fetchReportSchedules = createAppAsyncThunk(
  `${NAMESPACE}/fetchReportSchedules`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    schedulesFetchLimit(async () => {
      const saved = makeSelectReportScheduleListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }
      const data = await withAPICall(queryReportSchedules, 'unable to fetch report schedules')(
        listStateToSliceRequest({ data: saved, ...makeSelectReportScheduleListParameters()(getState()) }),
        { signal },
      );
      dispatch(storeReportScheduleListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));
      return makeSelectReportScheduleListData()(getState());
    }),
);

const scheduleFetchLimit = pLimit(1);
export const fetchReportSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchReportSchedule`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    scheduleFetchLimit(async () => {
      const saved = makeSelectReportSchedule(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryReportSchedule, 'unable to fetch report schedule')(id, { signal });
      dispatch(storeReportSchedule({ id, data }));
      return makeSelectReportSchedule(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const schedulesByIdFetchLimit = pLimit(1);
export const fetchReportSchedulesByIds = createAppAsyncThunk(
  `${NAMESPACE}/fetchReportSchedulesByIds`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    schedulesByIdFetchLimit(async () => {
      const toFetch = force ? ids : makeSelectDirtyReportScheduleIds(ids)(getState());
      if (!toFetch.length) {
        return makeSelectMultipleReportSchedule(ids)(getState());
      }

      const data = await withAPICall(queryReportSchedules, 'unable to fetch report schedules by ids')(
        { filter: { ids: toFetch }, page: defaultPageFn({ perPage: toFetch.length }) },
        { signal },
      );
      dispatch(
        storeMultipleReportSchedule(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            toFetch,
            extractReportScheduleId,
          ),
        ),
      );
      if (data.data) {
        dispatch(markReportScheduleListDirty());
      }

      return makeSelectMultipleReportSchedule(ids)(getState());
    }),
);

export const createReportSchedule = createAppAsyncThunk(
  `${NAMESPACE}/createReportSchedule`,
  async (newReportSchedule: NewReportSchedule, { dispatch, signal }) => {
    const data = await withAPICall(requestCreateReportSchedule, 'unable to create report schedule')(newReportSchedule, {
      signal,
    });
    if (data.data) {
      dispatch(storeReportSchedule({ id: data.data.id, data }));
      dispatch(markReportScheduleListDirty());
    }
    return data;
  },
);

export const updateReportSchedule = createAppAsyncThunk(
  `${NAMESPACE}/updateReportSchedule`,
  async ({ id, data: reportScheduleUpdate }: { id: string; data: UpdateReportSchedule }, { dispatch, signal }) => {
    const data = await withAPICall(requestUpdateReportSchedule, 'unable to update report schedule')(
      id,
      reportScheduleUpdate,
      { signal },
    );
    if (data.data) {
      dispatch(storeReportSchedule({ id: data.data.id, data }));
    }
    return data;
  },
  { idGenerator: ({ id }) => id },
);

export const activateReportSchedule = createAppAsyncThunk(
  `${NAMESPACE}/activateReportSchedule`,
  async ({ id }: { id: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestActivateReportSchedule, 'unable to activate report schedule')(id, {
      signal,
    });
    if (data.data) {
      dispatch(storeReportSchedule({ id: data.data.id, data }));
    }
    return data;
  },
  { idGenerator: ({ id }) => id },
);

export const deactivateReportSchedule = createAppAsyncThunk(
  `${NAMESPACE}/deactivateReportSchedule`,
  async ({ id, reason }: { id: string; reason?: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestDeactivateReportSchedule, 'unable to deactivate report schedule')(
      id,
      reason,
      { signal },
    );
    if (data.data) {
      dispatch(storeReportSchedule({ id: data.data.id, data }));
    }
    return data;
  },
  { idGenerator: ({ id }) => id },
);

export const deleteReportSchedule = createAppAsyncThunk(
  `${NAMESPACE}/deleteReportSchedule`,
  async (id: string, { dispatch, signal }) => {
    const data = await withAPICall(requestDeleteReportSchedule, 'unable to delete report schedule')(id, {
      signal,
    });
    if (!data.error) {
      dispatch(storeRemoveReportSchedule(id));
      dispatch(markReportScheduleListDirty());
    }
    return data;
  },
  { idGenerator: (id) => id },
);
