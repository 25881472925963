import type { DateTimeRangeRelativeEnum, DateTimeRelativeQuickFilter } from '@/components';
import {
  defaultMerchantWalletTransferSortBy,
  type MerchantWalletTransferFilterPredicate,
} from '@/features/merchant-wallet-transfers/types';
import type {
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
  MerchantWalletTransferSortByAPIModel,
  MerchantWalletRunningBalanceAPIModel,
  MerchantWalletRunningBalancePredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletRunningBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import { defaultPage } from '@/infrastructure/api';
import { storedDirtyDataTyped } from '@/infrastructure/model';
import type { FullState } from '@/infrastructure/model/full/types';
import type { ListColumnState, ListSortBy, ListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'merchant-wallet-balance';

export const merchantWalletRunningBalanceExportReport = 'merchant-wallet-balances';

export interface MerchantWalletRunningBalance extends Omit<MerchantWalletRunningBalanceAPIModel, 'amount' | 'balance'> {
  amount: AssetAmountValue;
  balance: AssetAmountValue;
}

export interface FetchTransfersForRunningBalanceActionPayload {
  force?: boolean;
  blockchain: BlockchainTypeAPIModel;
  blockNum: string;
}

export interface MerchantWalletRunningBalanceFilterPredicate
  extends Pick<
    MerchantWalletRunningBalancePredicatesAPIModel,
    'networkEq' | 'broadcastAtRange' | 'btIn' | 'walletLike'
  > {
  broadcastAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export interface MerchantWalletRunningBalanceReportPredicates {
  companyHidIn?: string[];
  btIn?: BlockchainTypeAPIModel[];
  broadcastAtRangeRelative?: DateTimeRangeRelativeEnum;
  walletLike?: string;
  networkEq?: BlockchainNetworkTypeAPIModel;
}

export type MerchantWalletRunningBalanceTransferFilterPredicate = Pick<
  MerchantWalletTransferFilterPredicate,
  'broadcastAtRangeRelative' | 'broadcastAtRange' | 'kindIn' | 'directionEq'
>;

export type MerchantWalletRunningBalanceTransfersState = Omit<
  FullState<string, MerchantWalletRunningBalanceTransferFilterPredicate, MerchantWalletTransferSortByAPIModel>,
  'columnState'
>;

export const defaultMerchantWalletRunningBalanceSortBy: ListSortBy<MerchantWalletRunningBalanceSortByAPIModel> = {
  [MerchantWalletRunningBalanceSortByAPIModel.blockNum]: 'DESC',
};

export const defaultMerchantWalletRunningBalanceTransfersState: MerchantWalletRunningBalanceTransfersState = {
  data: storedDirtyDataTyped(),
  page: defaultPage,
  filter: {},
  sortBy: defaultMerchantWalletTransferSortBy,
};

export interface MerchantWalletRunningBalancesState {
  list: ListState<string, MerchantWalletRunningBalanceFilterPredicate, MerchantWalletRunningBalanceSortByAPIModel>;
  entities: SingleState<MerchantWalletRunningBalance>; // key - balance id

  transfers: {
    columnState: ListColumnState;
    data: Partial<Record<string, MerchantWalletRunningBalanceTransfersState>>; // by balance id
  };
}
