import type { AuthenticatedUserAPIModel, MerchantPreferencesToStoreAPIModel } from '@/generated/api/ncps-api';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

import type { Address } from 'viem';

export const NAMESPACE = 'user';

export interface User
  extends Omit<AuthenticatedUserAPIModel, 'tokenExpiresAt' | 'activeCompany' | 'roles' | 'address'> {
  address: Address;
}

export type UserPreferences = MerchantPreferencesToStoreAPIModel;

export interface UserState {
  user: LoadingStateWithDirty<User>;
  preferences: LoadingStateWithDirty<UserPreferences>;
}
