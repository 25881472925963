import withAuthorizedGuard from '@/features/auth/hocs/withAuthorizedGuard';
import withReCaptchaSupport from '@/features/recaptcha/hocs/withReCaptchaSupport';
import withOnboardUnfinishedGuard from '@/pages/onboard/hocs/withOnboardUnfinishedGuard';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type React from 'react';

const withOnboardPageGuard = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  withAuthorizedGuard(appRoute(AppPageRoutes.AUTH))(withOnboardUnfinishedGuard(withReCaptchaSupport(Component)));

export default withOnboardPageGuard;
