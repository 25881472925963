import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import MerchantWalletRunningBalancesBreadcrumb from '@/pages/audit/balances/components/MerchantWalletRunningBalancesBreadcrumb';

import MerchantWalletRunningBalanceLink from '../MerchantWalletRunningBalanceLink';

import type { MerchantWalletRunningBalanceBreadcrumbProps } from './types';

const MerchantWalletRunningBalanceBreadcrumbRaw: React.FC<MerchantWalletRunningBalanceBreadcrumbProps> = ({
  items = [],
  balanceId,
  disabled,
  'data-test': dataTest,
}) => (
  <MerchantWalletRunningBalancesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.BALANCE_VIEW_COMPONENTS_BREADCRUMB} values={{ id: balanceId }} />
        ) : (
          <MerchantWalletRunningBalanceLink
            value={balanceId}
            mode="text"
            title={
              <FormattedMessage id={I18nPageAudit.BALANCE_VIEW_COMPONENTS_BREADCRUMB} values={{ id: balanceId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const MerchantWalletRunningBalanceBreadcrumb = React.memo(MerchantWalletRunningBalanceBreadcrumbRaw);

export default MerchantWalletRunningBalanceBreadcrumb;
