/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { NewInvoiceProofAPIModel } from './NewInvoiceProofAPIModel';
import {
    NewInvoiceProofAPIModelFromJSON,
    NewInvoiceProofAPIModelFromJSONTyped,
    NewInvoiceProofAPIModelToJSON,
} from './NewInvoiceProofAPIModel';

/**
 * 
 * @export
 * @interface NewWithdrawalBatchAPIModel
 */
export interface NewWithdrawalBatchAPIModel {
    /**
     * 
     * @type {Array<NewInvoiceProofAPIModel>}
     * @memberof NewWithdrawalBatchAPIModel
     */
    invoiceProofs?: Array<NewInvoiceProofAPIModel>;
}

/**
 * Check if a given object implements the NewWithdrawalBatchAPIModel interface.
 */
export function instanceOfNewWithdrawalBatchAPIModel(value: object): boolean {
    return true;
}

export function NewWithdrawalBatchAPIModelFromJSON(json: any): NewWithdrawalBatchAPIModel {
    return NewWithdrawalBatchAPIModelFromJSONTyped(json, false);
}

export function NewWithdrawalBatchAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewWithdrawalBatchAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'invoiceProofs': json['invoiceProofs'] == null ? undefined : ((json['invoiceProofs'] as Array<any>).map(NewInvoiceProofAPIModelFromJSON)),
    };
}

export function NewWithdrawalBatchAPIModelToJSON(value?: NewWithdrawalBatchAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoiceProofs': value['invoiceProofs'] == null ? undefined : ((value['invoiceProofs'] as Array<any>).map(NewInvoiceProofAPIModelToJSON)),
    };
}

