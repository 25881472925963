import React from 'react';

import { CollectableEntityTransactionView } from '@/features/collectable/components';

import { withDonationTransactionDataLoading } from './hocs';

const DonationTransactionCard = withDonationTransactionDataLoading((props) => (
  <CollectableEntityTransactionView {...props} columns={1} />
));

const DonationTransactionCardMemo = React.memo(DonationTransactionCard) as typeof DonationTransactionCard;

export default DonationTransactionCardMemo;
