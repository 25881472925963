import { useContext, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage, PageLoading } from '@/components';
import { Web3AuthRecoveryKeySecure } from '@/features/auth/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useAuthActions } from '@/features/auth/hooks';
import { makeSelectAuthStatus } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayoutCardContainer from '@/layouts/components/MessageLayoutCardContainer';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthStatus = makeSelectAuthStatus();

const withWeb3AuthRecoveryKeySecure = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthRecoveryKeySecure',
  )((props) => {
    const authStatus = useAppSelector(selectAuthStatus);
    const ctx = useContext(Web3AuthContext);
    const [loginOut, withLoginOut] = useSubmitting(false);
    const { logout } = useAuthActions();
    const [submitting, withSubmitting] = useSubmitting(false);
    const doSaveRecoveryKey = useMemo(
      () =>
        withSubmitting(async (key: string) => {
          if (ctx.web3Auth) {
            await ctx.web3Auth.recoveryKey.store(key);
            await ctx.web3Auth.sync();
          }
        }),
      [ctx.web3Auth, withSubmitting],
    );
    const doLogout = useMemo(() => () => withLoginOut(logout.act)(true), [logout, withLoginOut]);
    if (loginOut) {
      return (
        <MessageLayout>
          <PageLoading type="WithWeb3AuthRecoveryKeySecure" />
        </MessageLayout>
      );
    }
    if (
      submitting
      || (ctx.initialized
        && ctx.web3Auth
        && ctx.web3Auth.isAuthorized()
        && !ctx.web3Auth.recoveryKey.isDefined
        && authStatus !== AuthStatus.PROCESSING)
    ) {
      return (
        <MessageLayout>
          <MessageLayoutCardContainer
            data-test="recovery-key-card"
            title={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_TITLE} />}
          >
            <Web3AuthRecoveryKeySecure data-test="recovery-key" onSave={doSaveRecoveryKey} onLogout={doLogout} />
          </MessageLayoutCardContainer>
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withWeb3AuthRecoveryKeySecure;
