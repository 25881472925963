import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { GasWalletInvalidStateBanner, type GasWalletStateCheckBannerProps } from '@/features/gas-wallets/components';
import { withSubscriptionDataLoading } from '@/features/subscriptions/hocs';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { withPageLayout } from '@/hocs';

import type { SubscriptionGasWalletAlertBannerProps } from './types';

const SubscriptionGasWalletAlertBannerRaw: React.FC<SubscriptionGasWalletAlertBannerProps> = ({
  'data-test': dataTest,
  data,
  pageSize,
}) => {
  const withContainer = useMemo(() => withPageLayout<GasWalletStateCheckBannerProps>(() => ({ pageSize })), [pageSize]);
  return data.status === SubscriptionStatusAPIModel.Pending ? (
    <GasWalletInvalidStateBanner
      withContainer={withContainer}
      mode="alert"
      data-test={dataTest}
      bt={data.blockchain}
      title={<FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_GAS_WALLET_ALERT_ERROR_MESSAGE} />}
    />
  ) : null;
};

const SubscriptionGasWalletAlertBannerLoaded = withSubscriptionDataLoading(
  SubscriptionGasWalletAlertBannerRaw,
  true,
  true,
);

const SubscriptionGasWalletAlertBanner = React.memo(
  SubscriptionGasWalletAlertBannerLoaded,
) as typeof SubscriptionGasWalletAlertBannerLoaded;

export default SubscriptionGasWalletAlertBanner;
