import { Space } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  OperationChangeSubscriptionPlanStatus,
  OperationRemoveSubscriptionPlan,
} from '@/features/subscription-plans/components';
import SubscriptionPlanEditLink from '@/pages/subscriptions/plan-edit/components/SubscriptionPlanEditLink';
import { subscriptionPlansLink } from '@/pages/subscriptions/routes';

import type { SubscriptionPlanViewOperationsProps } from './types';

const SubscriptionPlanViewOperations: React.FC<SubscriptionPlanViewOperationsProps> = ({
  'data-test': dataTest,
  value: { id },
}) => {
  const navigate = useNavigate();
  const redirectToSubscriptions = useCallback(() => navigate(subscriptionPlansLink()), [navigate]);

  return (
    <Space>
      <OperationChangeSubscriptionPlanStatus planId={id} data-test={dataTest && `${dataTest}-${id}-status`} />
      <SubscriptionPlanEditLink value={id} data-test={dataTest && `${dataTest}-${id}-update`} />
      <OperationRemoveSubscriptionPlan
        planId={id}
        data-test={dataTest && `${dataTest}-${id}-remove`}
        confirmation
        onSuccess={redirectToSubscriptions}
      />
    </Space>
  );
};

const SubscriptionPlanViewOperationsMemo = React.memo(SubscriptionPlanViewOperations);

export default SubscriptionPlanViewOperationsMemo;
