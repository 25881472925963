import dayjs from 'dayjs';
import { useMemo } from 'react';

import { useCompanyActiveAssets } from '@/features/company-settings/hooks';
import { nameof } from '@/infrastructure/utils/ts';

import type { CreatePaymentFormData } from '../types';

export default function useInitialValues(): Partial<CreatePaymentFormData | undefined> {
  const { data: companyAssets, loading } = useCompanyActiveAssets();
  return useMemo(
    () =>
      !companyAssets.data && !companyAssets.isDirty && !loading
        ? undefined
        : {
            ...(companyAssets.data?.[0]
              ? { [nameof<CreatePaymentFormData>('amount')]: { asset: companyAssets.data[0].code } }
              : {}),
            [nameof<CreatePaymentFormData>('expiresAt')]: dayjs().add(1, 'd'),
            [nameof<CreatePaymentFormData>('fwError')]: {},
          },
    [companyAssets.data, companyAssets.isDirty, loading],
  );
}
