import { DeleteOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { OperationRemoveDonationAssetProps } from './types';

const OperationRemoveDonationAsset: React.FC<OperationRemoveDonationAssetProps> = ({
  'data-test': dataTest,
  data,
  disabled,
  onRemove,
  mode,
}) => {
  const disabledMessage = data.id ? (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_REMOVE_DISABLE_DEPLOYED} />
  ) : undefined;
  return (
    <Operation
      mode={mode}
      data-test={dataTest}
      disabled={disabled || !!disabledMessage}
      disabledMessage={disabledMessage}
      onClick={onRemove}
      icon={<DeleteOutlined />}
      title={<FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_REMOVE_TITLE} />}
    />
  );
};

const OperationRemoveDonationAssetMemo = React.memo(OperationRemoveDonationAsset);

export default OperationRemoveDonationAssetMemo;
