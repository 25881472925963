import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import type { SettlementReconciliationFilterPredicate } from '@/features/settlement-reconciliations/types';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { SettlementReconciliationStatusFilterItem } from './components';

import type { SettlementReconciliationFilterFormProps } from './types';

const settledAtMessages = {
  from: {
    label: (
      <FormattedMessage
        id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_FILTER_FORM_SETTLED_AT_FROM_LABEL}
      />
    ),
  },
  to: {
    label: (
      <FormattedMessage
        id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_FILTER_FORM_SETTLED_AT_TO_LABEL}
      />
    ),
  },
};

const fields = [
  nameof<SettlementReconciliationFilterPredicate>('withdrawnAtRange'),
  nameof<SettlementReconciliationFilterPredicate>('statusIn'),
];

const SettlementReconciliationFilterForm: React.FC<
  SettlementReconciliationFilterFormProps<SettlementReconciliationFilterPredicate>
> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  return (
    <FilterForm fields={fields} {...props}>
      <DateTimeRangeItem<SettlementReconciliationFilterPredicate>
        data-test={dataTest && `${dataTest}-withdrawnAtRange`}
        name={nameof<SettlementReconciliationFilterPredicate>('withdrawnAtRange')}
        readonly={readonly}
        messages={settledAtMessages}
        relativeUpdateKey="withdrawnAtRangeRelative"
      />
      <SettlementReconciliationStatusFilterItem
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<SettlementReconciliationFilterPredicate>('statusIn')}
        readonly={readonly}
      />
    </FilterForm>
  );
};

const SettlementReconciliationFilterFormMemo = React.memo(SettlementReconciliationFilterForm);

export default SettlementReconciliationFilterFormMemo;
