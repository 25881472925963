import type { TestableProps } from '@/infrastructure/utils/react';

export enum DateTimeRangeRelativeEnum {
  'Today' = 'Today',
  'ThisWeek' = 'ThisWeek',
  'ThisMonth' = 'ThisMonth',
  'LastDay' = 'LastDay',
  'LastWeek' = 'LastWeek',
  'LastMonth' = 'LastMonth',
}

export interface DateTimeRelativeLabelProps extends TestableProps {
  value: DateTimeRangeRelativeEnum;
}
