import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useReportActions } from '@/features/reports/hooks';
import { I18nFeatureReports } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationDeleteReportProps } from './types';

const messageOptions = {
  success: () => <FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_DELETE_REPORT_SUCCESS_MESSAGE} />,
  error: () => <FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_DELETE_REPORT_ERROR_MESSAGE} />,
};

const OperationDeleteReportRaw: React.FC<OperationDeleteReportProps> = ({
  'data-test': dataTest,
  reportId,
  confirmation = true,
  mode = 'inline',
  onSuccess = noop,
}) => {
  const { withNotification } = useNotification();
  const { deleteReport: deleteReportAction } = useReportActions(reportId);

  const deleteKey = useMemo(
    () => withSuppressError(withOnSuccess(withNotification(deleteReportAction.act, messageOptions), onSuccess)),
    [deleteReportAction.act, onSuccess, withNotification],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? { title: <FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_DELETE_REPORT_CONFIRMATION_TITLE} /> }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_DELETE_REPORT_TITLE} />}
      icon={<DeleteOutlined />}
      onClick={deleteKey}
      disabled={!deleteReportAction.available}
      inProgress={deleteReportAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeleteReport = React.memo(OperationDeleteReportRaw);

export default OperationDeleteReport;
