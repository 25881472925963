import { useCallback, useContext, useMemo } from 'react';
import { parseSignature } from 'viem';
import { privateKeyToAccount } from 'viem/accounts';

import { useAppDispatch } from '@/app/hooks';
import { storeAuthStatus } from '@/features/auth/actions';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { AuthStatus } from '@/features/auth/types';
import { storeEmailConfirmationState } from '@/features/email/actions';
import { OpenIdProviderTypeAPIModel } from '@/generated/api/ncps-api';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import type { Web3Auth } from '@/infrastructure/security/web3-auth';
import { assertNotNil, suppressPromise, withOnError, withSuppressError } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { rejected, rejection } from '@/infrastructure/utils/ui';

import useAuthActions, { type UseAuthActionsType } from '../useAuthActions';

export type EmailAuthOptions = Parameters<UseAuthActionsType['login']['act']>[1] & {
  onRecoveryKeyRequest: Func<[Web3Auth]>;
  onRecoveryKeySecure: Func<[Web3Auth]>;
};

export interface UseEmailAuthType {
  loginByEmail: HookAction<[{ email: string; emailToken: string }, EmailAuthOptions]>;
  loginByGoogle: HookAction<[EmailAuthOptions]>;
}

const useEmailAuth = (): UseEmailAuthType => {
  const { dispatch, withDispatch } = useAppDispatch();
  const { web3Auth, initialized } = useContext(Web3AuthContext);
  const { login: baseLogin } = useAuthActions();

  const doLogin = baseLogin.act;
  const [loggingIn, withLoggingIn] = useSubmitting(false);

  const doWeb3AuthLogin = useCallback(
    async ({
      triggerLogin,
      reviveToken,
      tokenProvider,
      options,
    }: {
      triggerLogin: (web3Auth: Web3Auth) => Promise<void>;
      reviveToken: (web3Auth: Web3Auth) => string | undefined;
      tokenProvider: OpenIdProviderTypeAPIModel;
      options: EmailAuthOptions & { email?: string };
    }) =>
      withOnError(
        withLoggingIn(async () => {
          assertNotNil(web3Auth, () => new Error('Web3Auth not initialized'));
          withDispatch(storeAuthStatus)({ newState: AuthStatus.PROCESSING });
          try {
            const loginResult = await (options.cancellationPromise
              ? Promise.race([triggerLogin(web3Auth), options.cancellationPromise])
              : triggerLogin(web3Auth));
            if (loginResult === rejection) {
              throw rejected();
            }
          } finally {
            const { email } = options;
            if (email) {
              suppressPromise(dispatch(storeEmailConfirmationState({ email })));
            }
          }
          if (!web3Auth.isLoggedIn()) {
            throw new Error('LoginFailed');
          }
          if (!web3Auth.isAuthorized()) {
            if (!web3Auth.isInitialized) {
              const recoverySecureResult = await (options.cancellationPromise
                ? Promise.race([options.onRecoveryKeySecure(web3Auth), options.cancellationPromise])
                : options.onRecoveryKeySecure(web3Auth));
              if (recoverySecureResult === rejection) {
                await web3Auth.logout();
                throw rejected();
              }
            }
            const recoveryRecoveryResult = await (options.cancellationPromise
              ? Promise.race([options.onRecoveryKeyRequest(web3Auth), options.cancellationPromise])
              : options.onRecoveryKeyRequest(web3Auth));
            if (recoveryRecoveryResult === rejection) {
              await web3Auth.logout();
              throw rejected();
            }

            if (!web3Auth.isAuthorized()) {
              throw new Error('RecoveryFailed');
            }
          }
          const emailToken = reviveToken(web3Auth);
          assertNotNil(emailToken, () => new Error('NoEmailToken'));
          assertNotNil(web3Auth.state, () => new Error('AuthorizationFailed'));

          const { privateKey, address } = web3Auth.state;
          const account = privateKeyToAccount(privateKey);
          const signMessage = async (message: string) => {
            const rawSignature = await account.signMessage({ message });
            const signature = parseSignature(rawSignature);
            return { ...signature, v: Number(signature.v) };
          };
          return doLogin({ address, emailToken, emailProvider: tokenProvider, signMessage }, options);
        }),
        async () => {
          if (web3Auth?.logout) {
            await withSuppressError(
              () => web3Auth.logout(),
              (e) => console.warn(e),
            )();
          }
          withDispatch(storeAuthStatus)({ newState: AuthStatus.UNAUTHORIZED, expected: AuthStatus.PROCESSING });
        },
      )(),
    [withLoggingIn, web3Auth, doLogin, dispatch, withDispatch],
  );

  const loginByGoogleAction: UseEmailAuthType['loginByGoogle']['act'] = useCallback(
    (options) =>
      doWeb3AuthLogin({
        triggerLogin: (t) => t.triggerGoogleAuth(),
        tokenProvider: OpenIdProviderTypeAPIModel.google,
        reviveToken: (t) => t.emailInfo?.token,
        options,
      }),
    [doWeb3AuthLogin],
  );
  const loginByGoogle = useMemo(
    () => ({
      act: loginByGoogleAction,
      available: baseLogin.available && initialized && !!web3Auth && !web3Auth.isLoggedIn(),
      inAction: loggingIn,
    }),
    [loginByGoogleAction, baseLogin.available, initialized, web3Auth, loggingIn],
  );

  const loginByEmailAction: UseEmailAuthType['loginByEmail']['act'] = useCallback(
    ({ email, emailToken }, options) =>
      doWeb3AuthLogin({
        triggerLogin: (t) => t.triggerEmailAuth({ emailToken }),
        tokenProvider: OpenIdProviderTypeAPIModel.auth0,
        reviveToken: () => emailToken,
        options: { ...options, email },
      }),
    [doWeb3AuthLogin],
  );
  const loginByEmail = useMemo(
    () => ({
      act: loginByEmailAction,
      available: baseLogin.available && initialized && !!web3Auth && !web3Auth.isLoggedIn(),
      inAction: loggingIn,
    }),
    [loginByEmailAction, baseLogin.available, initialized, web3Auth, loggingIn],
  );

  return { loginByGoogle, loginByEmail };
};

export default useEmailAuth;
