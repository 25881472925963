import { Col, Row, Skeleton } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { DataFetchError, FormattedMessage } from '@/components';
import { useBrandingProfile } from '@/features/branding/hooks';
import type { BrandingData } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { noop, withVoidOrThrow } from '@/infrastructure/utils/functions';

import BrandingDataContainer from '../BrandingDataContainer';
import BrandingDataPreview from '../BrandingDataPreview';

import { BrandingProfileDataViewPanel, BrandingProfileSelectPanel } from './components';

import type { BrandingProfileViewProps } from './types';

const BrandingProfileView: React.FC<BrandingProfileViewProps> = ({
  'data-test': dataTest,
  selected,
  onCreate,
  onEdit,
  onDelete,
  disabled,
}) => {
  const [profileId, setProfileId] = useStateMountSafe(selected);
  const [preview, setPreview] = useStateMountSafe<(branding: BrandingData) => void>();
  const setShowPreview = useCallback((func: (branding: BrandingData) => void) => setPreview(() => func), [setPreview]);
  const { data, loading, forceRefresh } = useBrandingProfile(profileId);

  const doCallEdit = useMemo(
    () => (onEdit && profileId ? () => withVoidOrThrow(onEdit)(profileId) : noop),
    [profileId, onEdit],
  );
  const doCallDelete = useMemo(
    () => (onDelete && profileId ? () => withVoidOrThrow(onDelete)(profileId) : noop),
    [onDelete, profileId],
  );
  const profilePreviewData = useMemo(
    () => (data.data ? { id: data.data.id, data: { ...data.data.data, qr: data.data.qr } } : undefined),
    [data.data],
  );
  const shownProfileId = useRef<string>();
  useEffect(() => {
    if (profilePreviewData && shownProfileId.current !== profilePreviewData.id && preview) {
      shownProfileId.current = profilePreviewData.id;
      preview(profilePreviewData.data);
    }
  }, [preview, profilePreviewData]);

  return (
    <Row gutter={[16, 16]}>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!data.data && (data.isDirty || loading) ? (
        <>
          <Col span={24}>
            <Skeleton.Input active />
          </Col>
          <Col span={24}>
            <Skeleton active title={false} />
          </Col>
        </>
      ) : data.data ? (
        <>
          <Col span={24}>
            <BrandingProfileSelectPanel
              data-test={dataTest && `${dataTest}-select`}
              selected={data.data.id}
              onSelect={setProfileId}
              onCreate={onCreate}
              onSelectedEdit={doCallEdit}
              onSelectedDelete={doCallDelete}
              disabled={disabled}
            />
          </Col>
          <Col span={24}>
            <BrandingDataContainer
              dataPanel={
                <BrandingProfileDataViewPanel data-test={dataTest && `${dataTest}-content`} value={data.data.data} />
              }
              previewPanel={
                <BrandingDataPreview
                  data-test={dataTest && `${dataTest}-preview`}
                  onUpdaterReady={setShowPreview}
                  initialValue={profilePreviewData?.data}
                />
              }
            />
          </Col>
        </>
      ) : (
        <DataFetchError
          refresh={forceRefresh}
          message={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_VIEW_EMPTY} />}
          data-test={dataTest && `${dataTest}-error`}
          description={data.error}
        />
      )}
    </Row>
  );
};

const BrandingProfileViewMemo = React.memo(BrandingProfileView);

export default BrandingProfileViewMemo;
