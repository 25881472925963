/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainNetworkTypeAPIModel } from './BlockchainNetworkTypeAPIModel';
import {
    BlockchainNetworkTypeAPIModelFromJSON,
    BlockchainNetworkTypeAPIModelFromJSONTyped,
    BlockchainNetworkTypeAPIModelToJSON,
} from './BlockchainNetworkTypeAPIModel';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { DateTimeRangeAPIModel } from './DateTimeRangeAPIModel';
import {
    DateTimeRangeAPIModelFromJSON,
    DateTimeRangeAPIModelFromJSONTyped,
    DateTimeRangeAPIModelToJSON,
} from './DateTimeRangeAPIModel';
import type { PushAddressLinkAPIModel } from './PushAddressLinkAPIModel';
import {
    PushAddressLinkAPIModelFromJSON,
    PushAddressLinkAPIModelFromJSONTyped,
    PushAddressLinkAPIModelToJSON,
} from './PushAddressLinkAPIModel';
import type { PushCollectStatusAPIModel } from './PushCollectStatusAPIModel';
import {
    PushCollectStatusAPIModelFromJSON,
    PushCollectStatusAPIModelFromJSONTyped,
    PushCollectStatusAPIModelToJSON,
} from './PushCollectStatusAPIModel';
import type { PushKindAPIModel } from './PushKindAPIModel';
import {
    PushKindAPIModelFromJSON,
    PushKindAPIModelFromJSONTyped,
    PushKindAPIModelToJSON,
} from './PushKindAPIModel';

/**
 * 
 * @export
 * @interface CollectTaskPredicatesAPIModel
 */
export interface CollectTaskPredicatesAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    idIn?: Array<string>;
    /**
     * 
     * @type {PushKindAPIModel}
     * @memberof CollectTaskPredicatesAPIModel
     */
    kindEq?: PushKindAPIModel;
    /**
     * 
     * @type {Array<BlockchainTypeAPIModel>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    btIn?: Array<BlockchainTypeAPIModel>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    assetIn?: Array<string>;
    /**
     * 
     * @type {Array<PushCollectStatusAPIModel>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    statusIn?: Array<PushCollectStatusAPIModel>;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskPredicatesAPIModel
     */
    hashLike?: string;
    /**
     * 
     * @type {string}
     * @memberof CollectTaskPredicatesAPIModel
     */
    addressLike?: string;
    /**
     * 
     * @type {Array<PushAddressLinkAPIModel>}
     * @memberof CollectTaskPredicatesAPIModel
     */
    addressIn?: Array<PushAddressLinkAPIModel>;
    /**
     * 
     * @type {DateTimeRangeAPIModel}
     * @memberof CollectTaskPredicatesAPIModel
     */
    processAtRange?: DateTimeRangeAPIModel;
    /**
     * 
     * @type {BlockchainNetworkTypeAPIModel}
     * @memberof CollectTaskPredicatesAPIModel
     */
    networkEq?: BlockchainNetworkTypeAPIModel;
}

/**
 * Check if a given object implements the CollectTaskPredicatesAPIModel interface.
 */
export function instanceOfCollectTaskPredicatesAPIModel(value: object): boolean {
    return true;
}

export function CollectTaskPredicatesAPIModelFromJSON(json: any): CollectTaskPredicatesAPIModel {
    return CollectTaskPredicatesAPIModelFromJSONTyped(json, false);
}

export function CollectTaskPredicatesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectTaskPredicatesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'idIn': json['idIn'] == null ? undefined : json['idIn'],
        'kindEq': json['kindEq'] == null ? undefined : PushKindAPIModelFromJSON(json['kindEq']),
        'btIn': json['btIn'] == null ? undefined : ((json['btIn'] as Array<any>).map(BlockchainTypeAPIModelFromJSON)),
        'assetIn': json['assetIn'] == null ? undefined : json['assetIn'],
        'statusIn': json['statusIn'] == null ? undefined : ((json['statusIn'] as Array<any>).map(PushCollectStatusAPIModelFromJSON)),
        'hashLike': json['hashLike'] == null ? undefined : json['hashLike'],
        'addressLike': json['addressLike'] == null ? undefined : json['addressLike'],
        'addressIn': json['addressIn'] == null ? undefined : ((json['addressIn'] as Array<any>).map(PushAddressLinkAPIModelFromJSON)),
        'processAtRange': json['processAtRange'] == null ? undefined : DateTimeRangeAPIModelFromJSON(json['processAtRange']),
        'networkEq': json['networkEq'] == null ? undefined : BlockchainNetworkTypeAPIModelFromJSON(json['networkEq']),
    };
}

export function CollectTaskPredicatesAPIModelToJSON(value?: CollectTaskPredicatesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'idIn': value['idIn'],
        'kindEq': PushKindAPIModelToJSON(value['kindEq']),
        'btIn': value['btIn'] == null ? undefined : ((value['btIn'] as Array<any>).map(BlockchainTypeAPIModelToJSON)),
        'assetIn': value['assetIn'],
        'statusIn': value['statusIn'] == null ? undefined : ((value['statusIn'] as Array<any>).map(PushCollectStatusAPIModelToJSON)),
        'hashLike': value['hashLike'],
        'addressLike': value['addressLike'],
        'addressIn': value['addressIn'] == null ? undefined : ((value['addressIn'] as Array<any>).map(PushAddressLinkAPIModelToJSON)),
        'processAtRange': DateTimeRangeAPIModelToJSON(value['processAtRange']),
        'networkEq': BlockchainNetworkTypeAPIModelToJSON(value['networkEq']),
    };
}

