import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useDonationAddressDeployTransaction } from '@/features/donations/hooks';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { OperationRefreshDonationAssetDeployTransactionProps } from './types';

const messages = {
  tooltip: (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_ASSET_DEPLOY_TX_SUCCESS_MESSAGE} />
  ),
  success: {
    message: (
      <FormattedMessage
        id={I18nFeatureDonations.COMPONENTS_OPERATION_REFRESH_DONATION_ASSET_DEPLOY_TX_SUCCESS_MESSAGE}
      />
    ),
  },
};

const OperationRefreshDonationAssetDeployTransaction: React.FC<OperationRefreshDonationAssetDeployTransactionProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  donationId,
  asset,
}) => {
  const { forceRefresh } = useDonationAddressDeployTransaction(donationId, asset);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshDonationAssetDeployTransactionMemo = React.memo(OperationRefreshDonationAssetDeployTransaction);

export default OperationRefreshDonationAssetDeployTransactionMemo;
