import { useMemo } from 'react';

import type {
  EditDonationFormAssetData,
  EditDonationFormData,
} from '@/features/donations/components/EditDonationForm/types';
import type { Donation } from '@/features/donations/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';
import { nameof } from '@/infrastructure/utils/ts';

export default function useInitialValues(
  donation: LoadingStateWithDirty<Donation>,
): LoadingStateWithDirty<Partial<EditDonationFormData>> {
  return useMemo(
    () =>
      mapStoredState(donation, (data) => ({
        [nameof<EditDonationFormData>('title')]: data.title,
        [nameof<EditDonationFormData>('description')]: data.description,
        [nameof<EditDonationFormData>('assets')]: data.addresses.map(
          ({ asset, defaultAmounts, id, isActive }): EditDonationFormAssetData => ({
            id,
            asset,
            defaultAmounts,
            isActive,
          }),
        ),
      })),
    [donation],
  );
}
