import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlagsKeys } from '@/features/feature-toggle/types';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type React from 'react';

const emptyComponent = () => null;

const withFeatureToggleGuard =
  (
    flag: FeatureFlagsKeys,
    expected = true,
    EmptyComponent: React.ComponentType = emptyComponent,
    passLoading = false,
  ) =>
  <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithFeatureToggleGuard',
    )((props) => {
      const {
        data: { data: flags, isDirty },
      } = useFeatureToggle();
      if (!flags && isDirty) {
        return passLoading ? <Component {...props} /> : <EmptyComponent />;
      }
      return flags?.[flag] === expected ? <Component {...props} /> : <EmptyComponent />;
    });

export default withFeatureToggleGuard;
