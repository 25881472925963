import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { usePaymentTransaction } from '@/features/payment-transactions/hooks';
import { I18nFeaturePaymentTransactions } from '@/generated/i18n/i18n';

import type { OperationRefreshPaymentTransactionProps } from './types';

const messages = {
  tooltip: (
    <FormattedMessage id={I18nFeaturePaymentTransactions.COMPONENTS_OPERATION_REFRESH_PAYMENT_TRANSACTION_TOOLTIP} />
  ),
  success: {
    message: (
      <FormattedMessage
        id={I18nFeaturePaymentTransactions.COMPONENTS_OPERATION_REFRESH_PAYMENT_TRANSACTION_SUCCESS_MESSAGE}
      />
    ),
  },
  error: {
    message: (
      <FormattedMessage
        id={I18nFeaturePaymentTransactions.COMPONENTS_OPERATION_REFRESH_PAYMENT_TRANSACTION_FAILED_MESSAGE}
      />
    ),
  },
};

const OperationRefreshPaymentTransaction: React.FC<OperationRefreshPaymentTransactionProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  paymentId,
}) => {
  const { forceRefresh } = usePaymentTransaction(paymentId);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshPaymentTransactionMemo = React.memo(OperationRefreshPaymentTransaction);

export default OperationRefreshPaymentTransactionMemo;
