/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  TempUploadUrlRequestAPIModel,
  TempUploadUrlSummaryAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    TempUploadUrlRequestAPIModelFromJSON,
    TempUploadUrlRequestAPIModelToJSON,
    TempUploadUrlSummaryAPIModelFromJSON,
    TempUploadUrlSummaryAPIModelToJSON,
} from '../models/index';

export interface CreateFileTempUploadUrlRequest {
    tempUploadUrlRequestAPIModel: TempUploadUrlRequestAPIModel;
}

/**
 * FileApi - interface
 * 
 * @export
 * @interface FileApiInterface
 */
export interface FileApiInterface {
    /**
     * Creates signed url for upload directly to temporary s3 bucket
     * @param {TempUploadUrlRequestAPIModel} tempUploadUrlRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApiInterface
     */
    createFileTempUploadUrlRaw(requestParameters: CreateFileTempUploadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TempUploadUrlSummaryAPIModel>>;

    /**
     * Creates signed url for upload directly to temporary s3 bucket
     */
    createFileTempUploadUrl(tempUploadUrlRequestAPIModel: TempUploadUrlRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TempUploadUrlSummaryAPIModel>;

}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI implements FileApiInterface {

    /**
     * Creates signed url for upload directly to temporary s3 bucket
     */
    async createFileTempUploadUrlRaw(requestParameters: CreateFileTempUploadUrlRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TempUploadUrlSummaryAPIModel>> {
        if (requestParameters['tempUploadUrlRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'tempUploadUrlRequestAPIModel',
                'Required parameter "tempUploadUrlRequestAPIModel" was null or undefined when calling createFileTempUploadUrl().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/files/signed-urls`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TempUploadUrlRequestAPIModelToJSON(requestParameters['tempUploadUrlRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TempUploadUrlSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Creates signed url for upload directly to temporary s3 bucket
     */
    async createFileTempUploadUrl(tempUploadUrlRequestAPIModel: TempUploadUrlRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TempUploadUrlSummaryAPIModel> {
        const response = await this.createFileTempUploadUrlRaw({ tempUploadUrlRequestAPIModel: tempUploadUrlRequestAPIModel }, initOverrides);
        return await response.value();
    }

}
