import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import PayoutStatusView from '@/features/payouts/components/PayoutStatusView';
import { PayoutStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { PayoutStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const PayoutStatusFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: PayoutStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_FILTER_FORM_STATUS_LABEL} />}
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      mode="multiple"
      disabled={readonly}
      placeholder={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_FILTER_FORM_STATUS_PLACEHOLDER} />}
      options={useMemo(
        () =>
          enumValues(PayoutStatusAPIModel).map((code) => ({
            value: code,
            label: <PayoutStatusView value={code} mode="full" data-test={dataTest && `${dataTest}-status-${code}`} />,
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const PayoutStatusFilterItemMemo = React.memo(PayoutStatusFilterItem) as typeof PayoutStatusFilterItem;

export default PayoutStatusFilterItemMemo;
