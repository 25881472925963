import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import React, { useCallback, useRef } from 'react';

import { FormattedMessage } from '@/components';
import { useS3FilesActions } from '@/features/dictionary/files/hooks';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { useNotification, useStateMountSafe } from '@/hooks';

import type { WrappedUploadProps } from './types';
import type { UploadFile } from 'antd/es/upload/interface';
import type { RcFile, UploadChangeParam } from 'antd/lib/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

const WrappedUpload: React.FC<WrappedUploadProps> = ({ 'data-test': dataTest, onChange, restrictions }) => {
  const { generateUploadURL } = useS3FilesActions();
  const { notification } = useNotification();
  const fileKey = useRef<string>();
  const [savedFile, setSavedFile] = useStateMountSafe<UploadFile | undefined>(undefined);
  const doRequest = useCallback(
    async ({ file, onError, onSuccess }: UploadRequestOption) => {
      if (typeof file === 'string') {
        onError?.(new Error(I18nFeatureDictionary.COMPONENTS_FILE_UPLOAD_ITEM_ERRORS_FORMAT_UNSUPPORTED));
        return;
      }
      const { url, key } = await generateUploadURL.act(file.type);
      fileKey.current = key;
      try {
        await fetch(url, { body: file, headers: { 'Content-Type': file.type }, method: 'PUT' });
        onSuccess?.(file);
      } catch (e) {
        onError?.(e as Error);
      }
    },
    [generateUploadURL],
  );
  const beforeUpload = useCallback(
    (file: RcFile) => {
      if (!restrictions?.sizeInKB) {
        return true;
      }
      const fileSizeInKB = file.size / 1024;
      if (fileSizeInKB > restrictions.sizeInKB) {
        notification?.error({
          message: <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_FILE_UPLOAD_ITEM_ERRORS_SIZE_TOO_BIG} />,
        });
        return false;
      }
      return true;
    },
    [notification, restrictions?.sizeInKB],
  );
  const doChange = useCallback(
    (v?: Partial<UploadChangeParam>) => {
      if (v?.file?.status === 'removed') {
        onChange?.(undefined);
        fileKey.current = undefined;
        setSavedFile(undefined);
      } else if (v?.file?.response) {
        const key = fileKey.current;
        if (key && v.file.status === 'done') {
          onChange?.(key);
        }
        setSavedFile(v.file);
      } else if (v?.file?.status) {
        setSavedFile(v.file);
      }
    },
    [onChange, setSavedFile],
  );
  return (
    <Upload
      fileList={savedFile ? [savedFile] : []}
      accept={restrictions?.accepted}
      beforeUpload={beforeUpload}
      showUploadList={{ showPreviewIcon: false, showDownloadIcon: false, showRemoveIcon: true }}
      maxCount={1}
      customRequest={doRequest}
      onChange={doChange}
      data-test={dataTest}
    >
      <Button icon={<UploadOutlined />}>
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_FILE_UPLOAD_ITEM_UPLOAD_BUTTON} tagName="span" />
      </Button>
    </Upload>
  );
};

const WrappedUploadMemo = React.memo(WrappedUpload);

export default WrappedUploadMemo;
