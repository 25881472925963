import { CaretDownOutlined, CaretUpOutlined, WalletOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { MerchantWalletTransferDirectionAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import type { MerchantWalletTransferDirectionViewProps } from './types';

const icons: Record<MerchantWalletTransferDirectionAPIModel, React.ReactNode> = {
  [MerchantWalletTransferDirectionAPIModel.Incoming]: (
    <Badge count={<CaretDownOutlined style={{ fontSize: 'smaller', color: 'green' }} />} showZero>
      <WalletOutlined />
    </Badge>
  ),
  [MerchantWalletTransferDirectionAPIModel.Outgoing]: (
    <Badge count={<CaretUpOutlined style={{ fontSize: 'smaller', color: 'red' }} />} showZero>
      <WalletOutlined />
    </Badge>
  ),
};

const i18n: Record<MerchantWalletTransferDirectionAPIModel, I18nMessages> = {
  [MerchantWalletTransferDirectionAPIModel.Incoming]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_DIRECTION_VIEW_INCOMING,
  [MerchantWalletTransferDirectionAPIModel.Outgoing]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_DIRECTION_VIEW_OUTGOING,
};

const MerchantWalletTransferDirectionViewRaw: React.FC<MerchantWalletTransferDirectionViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const MerchantWalletTransferDirectionView = React.memo(MerchantWalletTransferDirectionViewRaw);

export default MerchantWalletTransferDirectionView;
