import { Popconfirm } from 'antd';
import React, { useMemo } from 'react';

import FormattedMessage from '@/components//FormattedMessage';
import type { ConfirmationProps, OperationProps, OperationWithConfirmationProps } from '@/components/Operation/types';
import { I18nComponents } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';

const OperationWithConfirmation: React.FC<
  Omit<OperationWithConfirmationProps, 'confirmation'> & { confirmation: ConfirmationProps } & {
    Component: React.ComponentType<OperationProps>;
  }
> = ({ onClick, inProgress, Component, confirmation, ...props }) => {
  const [beingConfirmed, withConfirming, resetConfirmation, confirm] = useSubmitting(false);
  const [submitting, withSubmitting] = useSubmitting(false);
  const doClick = useMemo(() => withConfirming(withSubmitting(onClick)), [onClick, withConfirming, withSubmitting]);

  return (
    <Popconfirm
      title={confirmation.title}
      open={beingConfirmed}
      onConfirm={doClick}
      okButtonProps={{ loading: submitting }}
      okText={<FormattedMessage id={I18nComponents.OPERATION_CONFIRMATION_OK_LABEL} />}
      cancelText={<FormattedMessage id={I18nComponents.OPERATION_CONFIRMATION_CANCEL_LABEL} />}
      onCancel={resetConfirmation}
    >
      <Component {...props} onClick={confirm} inProgress={submitting || inProgress} />
    </Popconfirm>
  );
};

const OperationWithConfirmationMemo = React.memo(OperationWithConfirmation) as typeof OperationWithConfirmation;

export default OperationWithConfirmationMemo;
