import type { AppRootState } from '@/app/store';
import { storedDirtyDataTyped } from '@/infrastructure/model';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';

import { NAMESPACE } from './types';

export const makeSelectAuthStatus = () => (state: AppRootState) => state[NAMESPACE].status;
export const { makeSelectAuthToken, makeSelectAuthTokenData } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].token,
  'AuthToken' as const,
);

export const makeSelectTermsOfServiceAcceptanceState = () => (state: AppRootState) => state[NAMESPACE].termsOfService;

export const makeSelectAddressSignupStatus = (address: string) => (state: AppRootState) =>
  state[NAMESPACE].addressSignupStatus[address] ?? storedDirtyDataTyped();

export const makeSelectEmailResetAllowanceState = (email: string | undefined) => (state: AppRootState) =>
  (email ? state[NAMESPACE].emailResetAllowance[email] : undefined) ?? storedDirtyDataTyped();
