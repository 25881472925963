import { Typography } from 'antd';
import { type DOMAttributes, useMemo } from 'react';
import React from 'react';

import { ReadonlyComponent } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';

import { BlockchainLinkType } from './model';

import type { BlockchainLinkProps } from './types';

const doClick: DOMAttributes<HTMLElement>['onClick'] = (e) => {
  e.stopPropagation();
};

const BlockchainLink: React.FC<BlockchainLinkProps> = ({
  'data-test': dataTest,
  path,
  chainType,
  linkType = BlockchainLinkType.APP,
  style,
  className,
  children,
}) => {
  const { data } = useBlockchainSystemInfo(chainType);
  const blockchain = data.data;
  const baseLink = blockchain && (linkType === BlockchainLinkType.APP ? blockchain.links.app : blockchain.links.api);
  const href = useMemo(() => `${baseLink ?? ''}${path ? `/${path}` : ''}`, [baseLink, path]);
  return blockchain && baseLink ? (
    <Typography.Link
      data-test={dataTest}
      className={className}
      style={style}
      ellipsis
      onClick={doClick}
      href={href}
      target="_blank"
    >
      {children}
    </Typography.Link>
  ) : (
    <ReadonlyComponent className={className} style={style} value={children} data-test={dataTest} />
  );
};

const BlockchainLinkMemo = React.memo(BlockchainLink) as typeof BlockchainLink;

export default BlockchainLinkMemo;
