import { UserApi, type UserMerchantWalletCreateAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/request';

const userAPI = new UserApi(coreConfigurationFactory('auth'));

export const queryWalletSignatures = async (initOverrides?: RequestInit) =>
  (await userAPI.getUserWallets(initOverrides)).wallets ?? [];

export const requestSaveWalletSignature = async (
  wallet: UserMerchantWalletCreateAPIModel,
  initOverrides?: RequestInit,
) => (await userAPI.addUserWallet(wallet, initOverrides)).wallets ?? [];
