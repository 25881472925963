import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';
import SubscriptionPlansDefaultRoute from '@/pages/subscriptions/plans/components/SubscriptionPlansDefaultRoute';

import SubscriptionsPage from './all';
import SubscriptionChargePage from './charge-view';
import SubscriptionPlanAddPage from './plan-add';
import SubscriptionPlanEditPage from './plan-edit';
import SubscriptionPlanViewPlanPage from './plan-view';
import SubscriptionPlansPage from './plans';
import { SubscriptionPlanRoutes, subscriptionsLink, SubscriptionsPageRoutes } from './routes';
import SubscriptionStatisticsPage from './statistics';
import SubscriptionViewPage from './subscription-view';

import type React from 'react';

export const SubscriptionsDefaultRoute: React.FC = () => <Navigate to={subscriptionsLink()} replace />;

const SubscriptionPagesRaw: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={SubscriptionsPageRoutes.ALL} Component={SubscriptionsPage} />
        <Route path={SubscriptionsPageRoutes.CHARGE_VIEW} Component={SubscriptionChargePage} />
        <Route path={SubscriptionsPageRoutes.PLANS}>
          <Route path="" Component={SubscriptionPlansPage} />
          <Route path={SubscriptionPlanRoutes.VIEW} Component={SubscriptionPlanViewPlanPage} />
          <Route path={SubscriptionPlanRoutes.EDIT} Component={SubscriptionPlanEditPage} />
          <Route path={SubscriptionPlanRoutes.ADD} Component={SubscriptionPlanAddPage} />
          <Route path="*" Component={SubscriptionPlansDefaultRoute} />
        </Route>
        <Route path={SubscriptionsPageRoutes.STATISTICS} Component={SubscriptionStatisticsPage} />
        <Route path={SubscriptionsPageRoutes.SUBSCRIPTION_VIEW} Component={SubscriptionViewPage} />
        <Route path="*" Component={SubscriptionsDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const SubscriptionPages: React.FC = withBasicPageGuard(SubscriptionPagesRaw);

export default (
  <Route key={AppPageRoutes.SUBSCRIPTIONS} path={`${AppPageRoutes.SUBSCRIPTIONS}/*`} Component={SubscriptionPages} />
);
