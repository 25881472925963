import { Select } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import NetworkLabel from '@/features/dictionary/blockchain/components/NetworkLabel';
import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { enumByKey, enumKeys } from '@/infrastructure/utils/ts';

import type { NetworkTypeSelectProps } from './types';

const options = enumKeys(BlockchainNetworkTypeAPIModel).map((code) => ({
  value: code,
  label: <NetworkLabel value={enumByKey(BlockchainNetworkTypeAPIModel, code)} noSwitcher />,
}));

const NetworkTypeSelectRaw = ({ 'data-test': dataTest, readonly, ...InputProps }: NetworkTypeSelectProps) => (
  <Select
    data-test={dataTest}
    disabled={readonly}
    options={options}
    allowClear
    placeholder={
      !readonly ? (
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_NETWORK_TYPE_ITEM_SELECT_PLACEHOLDER} />
      ) : undefined
    }
    {...InputProps}
  />
);

const NetworkTypeSelect = React.memo(NetworkTypeSelectRaw);

export default NetworkTypeSelect;
