/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainsApiAPIModel } from './BlockchainsApiAPIModel';
import {
    BlockchainsApiAPIModelFromJSON,
    BlockchainsApiAPIModelFromJSONTyped,
    BlockchainsApiAPIModelToJSON,
} from './BlockchainsApiAPIModel';

/**
 * 
 * @export
 * @interface BlockchainsResponseAPIModel
 */
export interface BlockchainsResponseAPIModel {
    /**
     * 
     * @type {object}
     * @memberof BlockchainsResponseAPIModel
     */
    blockchains: object;
    /**
     * key - blockchain type, e.g BinanceTestNet
     * @type {{ [key: string]: BlockchainsApiAPIModel; }}
     * @memberof BlockchainsResponseAPIModel
     */
    api: { [key: string]: BlockchainsApiAPIModel; };
}

/**
 * Check if a given object implements the BlockchainsResponseAPIModel interface.
 */
export function instanceOfBlockchainsResponseAPIModel(value: object): boolean {
    if (!('blockchains' in value)) return false;
    if (!('api' in value)) return false;
    return true;
}

export function BlockchainsResponseAPIModelFromJSON(json: any): BlockchainsResponseAPIModel {
    return BlockchainsResponseAPIModelFromJSONTyped(json, false);
}

export function BlockchainsResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockchainsResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'blockchains': json['blockchains'],
        'api': (mapValues(json['api'], BlockchainsApiAPIModelFromJSON)),
    };
}

export function BlockchainsResponseAPIModelToJSON(value?: BlockchainsResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'blockchains': value['blockchains'],
        'api': (mapValues(value['api'], BlockchainsApiAPIModelToJSON)),
    };
}

