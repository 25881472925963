import React from 'react';

import { CompanyUsersList } from '@/features/company/components';

import { withUsersDataLoading } from './hocs';

const CompanyUsersCardLoaded = withUsersDataLoading(CompanyUsersList);
const CompanyUsersCardMemo = React.memo(CompanyUsersCardLoaded) as typeof CompanyUsersCardLoaded;

export default CompanyUsersCardMemo;
