import { getPubKeyPoint } from '@tkey-mpc/common-types';
import { CHAIN_NAMESPACES } from '@web3auth/base';
import { EthereumSigningProvider } from '@web3auth/ethereum-mpc-provider';
import {
  COREKIT_STATUS,
  ERRORS,
  FactorKeyTypeShareDescription,
  TssSecurityQuestion,
  TssShareType,
  Web3AuthMPCCoreKit,
} from '@web3auth/mpc-core-kit';
import { BN } from 'bn.js';
import bowser from 'bowser';
import { privateKeyToAccount } from 'viem/accounts';

import { OpenIdProviderTypeAPIModel } from '@/generated/api/ncps-api';
import {
  getWebBrowserFactor,
  removeWebBrowserFactor,
  storeWebBrowserFactor,
} from '@/infrastructure/security/web3-auth-device-key-storage';
import { noop, suppressPromise, withSuppressError } from '@/infrastructure/utils/functions';
import { asType } from '@/infrastructure/utils/ts';

import type { Point } from '@tkey/common-types';
import type { AggregateVerifierLoginParams, Web3AuthOptions } from '@web3auth/mpc-core-kit';
import type { Address, Hex } from 'viem';

const enableLogging = true;

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: '0x1', // Please use 0x1 for Mainnet
  rpcTarget: 'https://rpc.ankr.com/eth',
  displayName: 'Ethereum Mainnet',
  blockExplorer: 'https://etherscan.io/',
  ticker: 'ETH',
  tickerName: 'Ethereum',
};

const googleVerifiedConfig: AggregateVerifierLoginParams = {
  aggregateVerifierIdentifier: window.env.AUTH_WEB3AUTH_AGGREGATE_VERIFIER,
  subVerifierDetailsArray: [
    {
      verifier: window.env.AUTH_WEB3AUTH_GOOGLE_VERIFIER, // Pass the Verifier name here. eg. w3a-agg-example
      typeOfLogin: 'google',
      clientId: window.env.AUTH_WEB3AUTH_GOOGLE_CLIENT_ID, // Pass the Google `Client ID` here.
    },
  ],
};

const emailVerifiedConfig = (idToken: string): AggregateVerifierLoginParams => ({
  aggregateVerifierIdentifier: window.env.AUTH_WEB3AUTH_AGGREGATE_VERIFIER,
  subVerifierDetailsArray: [
    {
      verifier: window.env.AUTH_WEB3AUTH_AUTH0_EMAIL_VERIFIER, // Pass the Verifier name here. eg. w3a-agg-example
      typeOfLogin: 'jwt',
      clientId: window.env.AUTH_WEB3AUTH_AUTH0_EMAIL_CLIENT_ID, // Pass the Google `Client ID` here.
      jwtParams: {
        id_token: idToken,
        domain: window.env.AUTH_WEB3AUTH_AUTH0_EMAIL_DOMAIN,
        verifierIdField: window.env.AUTH_WEB3AUTH_AUTH0_EMAIL_JWT_ID_FIELD,
        isVerifierIdCaseSensitive: false,
      },
    },
  ],
});

const SECURITY_QUESTION = 'smarty-pay-security-question';

const createAuthOptions = async (): Promise<Web3AuthOptions> => {
  const tssLib = await import('@toruslabs/tss-dkls-lib').then((module) => ({ default: module.tssLib }));
  return {
    uxMode: 'popup',
    web3AuthClientId: window.env.AUTH_WEB3AUTH_CLIENT_ID,
    web3AuthNetwork: window.env.AUTH_WEB3AUTH_NETWORK,
    // baseUrl: `${window.location.origin}/serviceworker`,
    storage: sessionStorage,
    redirectPathName: 'redirect.html',
    manualSync: true,
    enableLogging,
    tssLib,
    useDKG: false,
  };
};

const deviceKeyStorageType = 'local';

export class Web3Auth {
  instance;

  private _isConfirmed = false;
  private _isOutOfSync = false;

  private _emailInfo?: { email: string; token?: string; provider?: OpenIdProviderTypeAPIModel } = undefined;

  private _state?: {
    address: Address;
    privateKey: Hex;
  } = undefined;

  // deviceKey relies on a browser local storage
  readonly deviceKey: ReturnType<Web3Auth['createDeviceKey']>;
  readonly recoveryKey: ReturnType<Web3Auth['createRecoveryKey']>;

  private readonly _onUpdate: () => unknown;

  private createDeviceKey = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const web3Auth = this;
    return new (class {
      private _isDefined = false;
      private _isFound = false;

      // eslint-disable-next-line class-methods-use-this
      async cleanUp() {
        const factorKey = await getWebBrowserFactor(web3Auth.instance, deviceKeyStorageType);
        if (factorKey) {
          await removeWebBrowserFactor(web3Auth.instance, deviceKeyStorageType);
        }
      }

      async refresh() {
        if (!web3Auth.isLoggedIn()) {
          this._isDefined = false;
          this._isFound = false;
        }
        try {
          this._isDefined = !!web3Auth
            .readFactorPubs()
            .find(({ description }) => description.module === FactorKeyTypeShareDescription.DeviceShare);
        } catch {
          this._isDefined = false;
        }
        try {
          this._isFound = !!(await getWebBrowserFactor(web3Auth.instance, deviceKeyStorageType));
        } catch {
          this._isDefined = false;
        }
      }

      get isDefined() {
        return this._isDefined;
      }

      get isFound() {
        return this._isFound;
      }

      async tryRestore() {
        web3Auth.assertSynced();
        web3Auth.assertLoggedIn();

        try {
          const factorKey = await getWebBrowserFactor(web3Auth.instance, deviceKeyStorageType);
          if (!factorKey) {
            return false;
          }
          await web3Auth.instance.inputFactorKey(new BN(factorKey, 'hex'));
          await this.refresh();
          return await web3Auth.tryAuthorize();
        } catch (e) {
          console.warn(e);
          return false;
        } finally {
          web3Auth._onUpdate();
        }
      }

      async generate(notify = true) {
        web3Auth.assertSynced();
        web3Auth.assertAuthorized();

        try {
          const browserInfo = bowser.parse(navigator.userAgent);
          const browserName = browserInfo.browser.name || '';
          const browserData: Record<string, string> = {
            browserName,
            browserVersion: browserInfo.browser.version || '',
            deviceName: browserInfo.os.name || '',
          };
          const deviceFactor = await web3Auth.instance.createFactor({
            shareDescription: FactorKeyTypeShareDescription.DeviceShare,
            shareType: TssShareType.DEVICE,
            additionalMetadata: browserData,
          });
          const deviceFactorKey = new BN(deviceFactor, 'hex');
          await storeWebBrowserFactor(deviceFactorKey, web3Auth.instance, deviceKeyStorageType);
          const factorPubPoint = getPubKeyPoint(deviceFactorKey);
          const allFactors = web3Auth
            .readFactorPubs()
            .filter(({ description }) => description.shareType === TssShareType.DEVICE);
          const factorsToDelete = allFactors.filter(
            ({ factorPub }) => !factorPub.x!.eq(factorPubPoint.x!) || !factorPub.y!.eq(factorPubPoint.y!),
          );
          await factorsToDelete.reduce(async (result, { factorPub }) => {
            await result;
            await web3Auth.instance.deleteFactor(factorPub);
          }, Promise.resolve());
          await this.refresh();
          web3Auth._isOutOfSync = true;
        } finally {
          if (notify) {
            web3Auth._onUpdate();
          }
        }
      }
    })();
  };

  private createRecoveryKey = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const web3Auth = this;
    return new (class {
      private _isDefined = false;

      cleanUp() {
        this._isDefined = false;
      }

      refresh() {
        if (!web3Auth.isLoggedIn()) {
          this._isDefined = false;
          return;
        }
        const securityQuestion: TssSecurityQuestion = new TssSecurityQuestion();
        try {
          this._isDefined = securityQuestion.getQuestion(web3Auth.instance) === SECURITY_QUESTION;
        } catch (e: unknown) {
          if (!(e && typeof e === 'object' && 'message' in e && e.message === 'Security question does not exists')) {
            console.warn(e);
          }
          this._isDefined = false;
        }
      }

      get isDefined() {
        return this._isDefined;
      }

      // eslint-disable-next-line class-methods-use-this
      async tryRestore(secret: string) {
        web3Auth.assertSynced();
        web3Auth.assertLoggedIn();

        try {
          const securityQuestion: TssSecurityQuestion = new TssSecurityQuestion();
          const factorKey = await securityQuestion.recoverFactor(web3Auth.instance, secret);
          await web3Auth.instance.inputFactorKey(new BN(factorKey, 'hex'));
          return await web3Auth.tryAuthorize();
        } catch (e) {
          console.warn(e);
          return false;
        } finally {
          web3Auth._onUpdate();
        }
      }

      async store(secret: string) {
        web3Auth.assertSynced();
        web3Auth.assertAuthorized();
        try {
          const securityQuestion: TssSecurityQuestion = new TssSecurityQuestion();
          let recoveryFactor: string;
          if (this.isDefined) {
            await securityQuestion.deleteSecurityQuestion(web3Auth.instance);
            await securityQuestion.setSecurityQuestion({
              mpcCoreKit: web3Auth.instance,
              question: SECURITY_QUESTION,
              answer: secret,
              shareType: TssShareType.RECOVERY,
            });
            recoveryFactor = await securityQuestion.recoverFactor(web3Auth.instance, secret);
          } else {
            // FIXME: where the account reset has been initiated some unexpected changes appears on first login
            await web3Auth.instance.commitChanges();
            await web3Auth.instance.enableMFA({}, false);
            await securityQuestion.setSecurityQuestion({
              mpcCoreKit: web3Auth.instance,
              question: SECURITY_QUESTION,
              answer: secret,
              shareType: TssShareType.RECOVERY,
            });
            recoveryFactor = await securityQuestion.recoverFactor(web3Auth.instance, secret);
            await web3Auth.instance.inputFactorKey(new BN(recoveryFactor, 'hex'));
            await web3Auth.deviceKey.generate(false);
          }
          const recoveryFactorKey = new BN(recoveryFactor, 'hex');
          const factorPubPoint = getPubKeyPoint(recoveryFactorKey);
          web3Auth._isOutOfSync = true;
          const allFactors = web3Auth
            .readFactorPubs()
            .filter(({ description }) => description.shareType === TssShareType.RECOVERY);
          const factorsToDelete = allFactors.filter(
            ({ factorPub }) => !factorPub.x!.eq(factorPubPoint.x!) || !factorPub.y!.eq(factorPubPoint.y!),
          );
          await factorsToDelete.reduce(async (result, { factorPub }) => {
            await result;
            await web3Auth.instance.deleteFactor(factorPub);
          }, Promise.resolve());
        } finally {
          web3Auth._onUpdate();
        }
      }
    })();
  };

  protected constructor(authOptions: Web3AuthOptions, onUpdate?: () => unknown) {
    this.instance = new Web3AuthMPCCoreKit(authOptions);
    this.deviceKey = this.createDeviceKey();
    this.recoveryKey = this.createRecoveryKey();
    this._onUpdate = onUpdate
      ? () => {
          try {
            suppressPromise(this.deviceKey.refresh());
            this.recoveryKey.refresh();
            onUpdate();
          } catch (e) {
            console.warn('on update call error', e);
          }
        }
      : noop;
  }

  private async init() {
    try {
      await this.instance.init();
    } catch (e) {
      console.warn('Web3Auth init failed: calling cleanup', e);
      await withSuppressError(
        () => this.logout(),
        (e2) => console.warn(e2),
      )();
    }
    if (this.tryLogin(true)) {
      if (await this.tryAuthorize(true)) {
        this.recoveryKey.refresh();
        await this.deviceKey.refresh();
      }
    }
  }

  public static async create(options: { onUpdate?: () => unknown } = {}) {
    const web3Auth = new Web3Auth(await createAuthOptions(), options.onUpdate);
    await web3Auth.init();
    return web3Auth;
  }

  isLoggedIn(): boolean {
    return !!this._emailInfo;
  }

  isAuthorized(): boolean {
    return !!this._state;
  }

  get isConfirmed(): boolean {
    return this._isConfirmed;
  }

  get isInitialized(): boolean {
    return this.recoveryKey.isDefined;
  }

  get emailInfo(): typeof Web3Auth.prototype._emailInfo {
    return this._emailInfo;
  }

  get state(): typeof Web3Auth.prototype._state {
    return this._state;
  }

  get isOutOfSync(): boolean {
    return this._isOutOfSync;
  }

  private assertSynced() {
    if (this._isOutOfSync) {
      throw new Error('OutOfSync');
    }
  }

  private assertAuthorized() {
    if (!this._state) {
      throw new Error('NotAuthorized');
    }
  }

  private assertLoggedIn() {
    if (!this.isLoggedIn()) {
      throw new Error('NotLoggedIn');
    }
  }

  markConfirmed() {
    this.assertAuthorized();
    this._isConfirmed = true;
  }

  async sync() {
    if (this._isOutOfSync) {
      await this.instance.commitChanges();
      this._isOutOfSync = false;
      this._onUpdate();
    }
  }

  async triggerGoogleAuth() {
    if (this.isLoggedIn()) {
      throw new Error('AlreadyLoggedIn');
    }

    try {
      await this.instance.loginWithOAuth(googleVerifiedConfig);
      if (this.tryLogin()) {
        await this.deviceKey.refresh();
        if (this.deviceKey.isFound) {
          await this.deviceKey.tryRestore();
        } else {
          await this.tryAuthorize();
        }
      }
    } catch (e) {
      if ((e as Error).message !== ERRORS.TKEY_SHARES_REQUIRED) {
        throw e;
      }
    } finally {
      this._onUpdate();
    }
  }

  async triggerEmailAuth({ emailToken }: { emailToken: string }) {
    if (this.isLoggedIn()) {
      throw new Error('AlreadyLoggedIn');
    }

    try {
      await this.instance.loginWithOAuth(emailVerifiedConfig(emailToken));
      if (this.instance.status === COREKIT_STATUS.LOGGED_IN) {
        await this.instance.commitChanges(); // Needed for new accounts
      }
      if (this.tryLogin()) {
        await this.deviceKey.refresh();
        if (this.deviceKey.isFound) {
          await this.deviceKey.tryRestore();
        } else {
          await this.tryAuthorize();
        }
      }
    } catch (e) {
      if ((e as Error).message !== ERRORS.TKEY_SHARES_REQUIRED) {
        throw e;
      }
    } finally {
      this._onUpdate();
    }
  }

  private tryLogin(silent = false) {
    try {
      const { email, idToken, typeOfLogin } = this.instance.getUserInfo();
      if (!email) {
        return false;
      }
      this._emailInfo = {
        email,
        token: idToken,
        provider:
          // eslint-disable-next-line no-nested-ternary
          typeOfLogin === 'google'
            ? OpenIdProviderTypeAPIModel.google
            : typeOfLogin === 'jwt'
              ? OpenIdProviderTypeAPIModel.auth0
              : undefined,
      };
      return true;
    } catch (e) {
      if (!silent) {
        console.warn(e);
      }
      return false;
    }
  }

  private async tryAuthorize(silent = false) {
    try {
      const provider = new EthereumSigningProvider({
        config: { chainConfig },
        state: { chainId: chainConfig.chainId },
      });
      const privateKey: Hex = (await provider.request({ method: 'private_key' }))!;
      // // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      // if (this.instance.tKey.privKey) {
      //   const privateKey: Hex = `0x${this.instance.tKey.privKey.toString('hex', 64)}` as const;
      const { address } = privateKeyToAccount(privateKey);
      this._state = { address, privateKey };
      return true;
    } catch (e) {
      if (!silent) {
        console.warn(e);
      }
      return false;
    }
  }

  private readFactorPubs() {
    this.assertAuthorized();

    // const descriptions = Object.entries(this.instance.tKey.metadata.getShareDescription()).reduce(
    //   (result, [shareIndex, [description]]) => {
    //     const parsed: Record<string, unknown> = JSON.parse(description || '{}');
    //     return {
    //       ...result,
    //       [shareIndex]: {
    //         shareType: parsed.tssShareIndex as TssShareType | undefined,
    //         addedAt: parsed.dateAdded ? new Date(parsed.dateAdded as number) : undefined,
    //         module: parsed.module as FactorKeyTypeShareDescription | undefined,
    //       },
    //     };
    //   },
    //   asType<
    //     Partial<
    //       Record<
    //         string,
    //         {
    //           shareType?: TssShareType;
    //           addedAt?: Date;
    //           module?: FactorKeyTypeShareDescription;
    //         }
    //       >
    //     >
    //   >({}),
    // );

    return (
      // this.instance.tKey.metadata.factorPubs?.[this.instance.tKey.tssTag]?.map((factorPub) => {
      //   const shareIndex = Point.fromCompressedPub(factorPub).toBufferSEC1(true).toString('hex');
      //   return {
      //     factorPub,
      //     shareIndex,
      //     description: descriptions[shareIndex],
      //   };
      // })
      asType<
        {
          factorPub: Point;
          shareIndex: number;
          description: { shareType: TssShareType; module: FactorKeyTypeShareDescription };
        }[]
      >([])
    );
  }

  async reset() {
    this.assertSynced();
    await this.instance.tKey.storageLayer.setMetadata({
      privKey: new BN(this.instance.state.postBoxKey!, 'hex'),
      input: { message: 'KEY_NOT_FOUND' },
    });
    try {
      await this.instance.commitChanges();
    } catch (e) {
      console.warn(e);
    }
    await this.logout(true);
  }

  async logout(cleanDeviceKey = false) {
    this._state = undefined;
    this._emailInfo = undefined;
    this._isConfirmed = false;
    this._isOutOfSync = false;
    if (cleanDeviceKey) {
      await withSuppressError(
        () => this.deviceKey.cleanUp(),
        (e) => console.warn(e),
      )();
    }
    await this.instance.logout();
    this.instance = new Web3AuthMPCCoreKit(await createAuthOptions());
    await this.init();
    this._onUpdate();
  }
}

const create = Web3Auth.create.bind(Web3Auth);

export default create;
