import { StopOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSubscription } from '@/features/subscriptions/hooks';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';

import { CancelSubscriptionModal } from './components';

import type { OperationCancelSubscriptionProps } from './types';

const OperationCancelSubscriptionRaw: React.FC<OperationCancelSubscriptionProps> = ({
  'data-test': dataTest,
  subscriptionId,
  mode,
}) => {
  const formControl = useFormVisible(false);
  const { data: subscription } = useSubscription(subscriptionId);

  const cancelUnavailabilityReason = useMemo(() => {
    if (!subscription.data?.actions.cancelable) {
      return <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_INVALID_STATUS} />;
    }
    return undefined;
  }, [subscription.data?.actions.cancelable]);

  return (
    <>
      {formControl.visible && subscription.data && (
        <CancelSubscriptionModal
          subscription={subscription.data}
          onSuccess={formControl.hide}
          onCancel={formControl.hide}
        />
      )}
      <Operation
        data-test={dataTest && `${dataTest}-cancel`}
        title={<FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_TITLE} />}
        mode={mode}
        icon={<StopOutlined style={{ color: 'red' }} />}
        disabled={!!cancelUnavailabilityReason}
        disabledMessage={cancelUnavailabilityReason}
        onClick={formControl.show}
      />
    </>
  );
};

const OperationCancelSubscription = React.memo(OperationCancelSubscriptionRaw);

export default OperationCancelSubscription;
