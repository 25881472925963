import { Navigate, Route, Routes } from 'react-router-dom';

import AuthLayout from '@/layouts/AuthLayout';
import { withAuthPageGuard } from '@/pages/hocs';
import { AppPageRoutes } from '@/pages/routes';

import LoginPage from './login';
import { authLink } from './routes';

import type React from 'react';

export const AuthDefaultRoute: React.FC = () => <Navigate to={authLink()} replace />;

const AuthPages: React.FC = () => (
  <AuthLayout>
    <Routes>
      <Route path="" Component={LoginPage} />
      <Route path="*" Component={AuthDefaultRoute} />
    </Routes>
  </AuthLayout>
);

const AuthPagesHOCed = withAuthPageGuard(AuthPages);

export default <Route key={AppPageRoutes.AUTH} path={`${AppPageRoutes.AUTH}/*`} Component={AuthPagesHOCed} />;
