import { FormattedMessage } from '@/components';
import { OperationRefreshCollectTaskTransaction } from '@/features/collectable/components';
import { useCollectTaskProcessTransaction } from '@/features/collectable/hooks';
import type { CollectableEntityProcessTransaction } from '@/features/collectable/types';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withCollectTaskTransactionDataLoading = <
  Original extends { data: CollectableEntityProcessTransaction } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { taskId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithCollectTaskTransactionDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useCollectTaskProcessTransaction(props.taskId);
    return withCardDataLoading<CollectableEntityProcessTransaction, Original>({
      ...data,
      'data-test': dataTest,
      hideBack: true,
      title: <FormattedMessage id={I18nPageCollectable.TASK_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE} />,
      CardProps: {
        extra: (
          <OperationRefreshCollectTaskTransaction
            taskId={props.taskId}
            data-test={dataTest && `${dataTest}-opRefresh`}
          />
        ),
      },
    })(Component)(props);
  });

export default withCollectTaskTransactionDataLoading;
