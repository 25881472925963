import { Modal, Space, Spin } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { usePayout } from '@/features/payouts/hooks';
import { withWeb3Context } from '@/features/web3/hocs';
import { useWeb3, useWeb3Singleton } from '@/features/web3/hooks';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';

import OperationStartPayoutWithConnector from '../OperationStartPayoutWithConnector';

import type { StartPayoutModalProps } from './types';

const StartPayoutModal: React.FC<StartPayoutModalProps> = (props) => {
  const { 'data-test': dataTest, onSuccess, onCancel, payoutId } = props;
  const { loading } = usePayout(payoutId);
  const { orderedConnectors } = useWeb3();
  const { withReloadIfInitialized } = useWeb3Singleton();
  const [inProgress, withInProgress] = useSubmitting(false);
  const doSuccess = useMemo(() => withReloadIfInitialized(onSuccess), [onSuccess, withReloadIfInitialized]);
  const doCancel = useMemo(() => withReloadIfInitialized(onCancel), [onCancel, withReloadIfInitialized]);

  return (
    <Modal
      open
      maskClosable={false}
      title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_DIALOG_TITLE} />}
      data-test={dataTest && `${dataTest}-modal`}
      onOk={doSuccess}
      onCancel={!inProgress ? doCancel : undefined}
      closable={!inProgress}
      footer=""
    >
      <Spin spinning={loading}>
        <Space direction="vertical">
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_OPERATION_START_PAYOUT_DIALOG_DESCRIPTION}
            tagName="span"
          />
          <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
            {orderedConnectors.map((connector) => (
              <OperationStartPayoutWithConnector
                {...props}
                data-test={dataTest && `${dataTest}-${connector.id}`}
                onSuccess={doSuccess}
                key={connector.id}
                connectorId={connector.id}
                withInProgress={withInProgress}
              />
            ))}
          </Space>
        </Space>
      </Spin>
    </Modal>
  );
};

const StartPayoutModalContexted = withWeb3Context(StartPayoutModal);

const StartPayoutModalMemo = React.memo(StartPayoutModalContexted);

export default StartPayoutModalMemo;
