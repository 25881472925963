import React from 'react';

import { CollectableEntityProcessTransactionView } from '@/features/collectable/components';

import { withDonationAssetDeployTransactionDataLoading, withDonationAssetDeployTransactionAppearance } from './hocs';

const DonationAddressDeployTransactionCard = withDonationAssetDeployTransactionAppearance(
  withDonationAssetDeployTransactionDataLoading((props) => (
    <CollectableEntityProcessTransactionView {...props} columns={1} />
  )),
);

const DonationAddressDeployTransactionCardMemo = React.memo(
  DonationAddressDeployTransactionCard,
) as typeof DonationAddressDeployTransactionCard;

export default DonationAddressDeployTransactionCardMemo;
