import React from 'react';

import { CollectableEntityTransactionsTable } from '@/features/collectable/components';

import type { BaseDonationTransactionFilter, DonationTransactionsTableProps } from './types';

const DonationTransactionsTable = <FilterType extends BaseDonationTransactionFilter = BaseDonationTransactionFilter>({
  DonationTransactionLink,
  ...props
}: DonationTransactionsTableProps<FilterType>) => (
  <CollectableEntityTransactionsTable<FilterType>
    {...props}
    CollectableEntityTransactionLink={DonationTransactionLink}
  />
);

const DonationTransactionsTableMemo = React.memo(DonationTransactionsTable) as typeof DonationTransactionsTable;

export default DonationTransactionsTableMemo;
