import { useMemo } from 'react';

import { useBrandingProfile } from '@/features/branding/hooks';
import type { BrandingData } from '@/features/branding/types';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withBrandingProfileDataLoading = <T extends { data: BrandingData } & TestableProps>(
  Component: React.ComponentType<Omit<T, 'data'> & { data: BrandingData }>,
): React.FC<Omit<T, 'data'> & { profileId: string }> =>
  namedHOC<Omit<T, 'data'> & { data: BrandingData }, Omit<T, 'data'> & { profileId: string }>(
    Component,
    'WithBrandingProfileDataLoading',
  )((props) => {
    const { 'data-test': dataTest, profileId } = props;
    const profileState = useBrandingProfile(profileId);
    const data = useMemo(
      () => mapStoredState(profileState.data, (profile): BrandingData => ({ ...profile.data, qr: profile.qr })),
      [profileState.data],
    );
    return withCardDataLoading<BrandingData, Omit<T, 'data'> & { data: BrandingData }>({
      ...profileState,
      data,
      'data-test': dataTest,
      cardSize: 'big',
      title: null,
      hideBack: true,
    })(Component)(props);
  });

export default withBrandingProfileDataLoading;
