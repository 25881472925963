import React, { useMemo } from 'react';

import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';
import { useMerchantGasWalletState } from '@/features/gas-wallets/hooks';

import GasWalletStateCheckBanner from '../GasWalletStateCheckBanner';

import type { GasWalletInvalidStateBannerProps } from './types';

const GasWalletInvalidStateBanner: React.FC<GasWalletInvalidStateBannerProps> = ({
  'data-test': dataTest,
  bt,
  title,
  mode,
  withContainer,
}) => {
  const { loading, ready, issues } = useMerchantGasWalletState(bt);
  const Component = useMemo(
    () => (withContainer ? withContainer(GasWalletStateCheckBanner) : GasWalletStateCheckBanner),
    [withContainer],
  );
  if (ready || loading) {
    return null;
  }
  if (issues.includes('presence')) {
    return <Component data-test={dataTest} type="presence" bt={bt} title={title} mode={mode} />;
  }
  if (issues.includes('derived-balance')) {
    return <Component data-test={dataTest} type="derived-balance" bt={bt} title={title} mode={mode} />;
  }
  if (issues.includes('refunding')) {
    return <Component data-test={dataTest} type="refunding" bt={bt} title={title} mode={mode} />;
  }
  if (issues.includes('out-of-service')) {
    return <Component data-test={dataTest} type="all-service" bt={bt} title={title} mode={mode} />;
  }
  return null;
};

const GasWalletInvalidStateBannerHOC =
  withBlockchainType<GasWalletInvalidStateBannerProps>(GasWalletInvalidStateBanner);

const GasWalletInvalidStateBannerMemo = React.memo(GasWalletInvalidStateBannerHOC);

export default GasWalletInvalidStateBannerMemo;
