import { FormattedMessage } from '@/components';
import { useActiveCompany } from '@/features/company/hooks';
import type { Company } from '@/features/company/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withCompanyCardDataLoading = <
  Original extends { data: Company } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithCompanyCardDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useActiveCompany();
    return withCardDataLoading<Company, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.PERMISSIONS_COMPONENTS_GENERAL_CARD_TITLE} />,
    })(Component)(props);
  });

export default withCompanyCardDataLoading;
