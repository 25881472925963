import React from 'react';

import { DateTimeRangeItem, FormattedMessage } from '@/components';
import { I18nFeatureGasHistory } from '@/generated/i18n/i18n';

import type { GasHistoryCreatedAtRangeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  from: { label: <FormattedMessage id={I18nFeatureGasHistory.COMPONENTS_FILTER_FORM_CREATED_AT_ITEM_FROM_LABEL} /> },
  to: { label: <FormattedMessage id={I18nFeatureGasHistory.COMPONENTS_FILTER_FORM_CREATED_AT_ITEM_TO_LABEL} /> },
};

const GasHistoryCreatedAtRangeFilterItemRaw = <Values extends Store = Store>(
  props: GasHistoryCreatedAtRangeFilterItemProps<Values>,
) => <DateTimeRangeItem<Values> {...props} messages={messages} />;

const GasHistoryCreatedAtRangeFilterItem = React.memo(
  GasHistoryCreatedAtRangeFilterItemRaw,
) as typeof GasHistoryCreatedAtRangeFilterItemRaw;

export default GasHistoryCreatedAtRangeFilterItem;
