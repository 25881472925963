import { withUnAuthorizedGuard } from '@/features/auth/hocs';
import withReCaptchaSupport from '@/features/recaptcha/hocs/withReCaptchaSupport';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type React from 'react';

const withAuthPageGuard = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  withUnAuthorizedGuard(appRoute(AppPageRoutes.DASHBOARD))(withReCaptchaSupport(Component));

export default withAuthPageGuard;
