import React, { useMemo } from 'react';

import { FormattedMessage, FormFooter } from '@/components';
import { I18nFeatureEmail } from '@/generated/i18n/i18n';

import type { EmailConfirmationFormFooterProps } from './types';

const submitStyle = { minWidth: 150 };

const EmailConfirmationFormFooter: React.FC<EmailConfirmationFormFooterProps> = ({
  'data-test': dataTest,
  title,
  disabled,
  inProgress,
}) => (
  <FormFooter
    data-test={dataTest}
    submitting={inProgress}
    submitStyle={submitStyle}
    submitDisabled={disabled}
    noCancel
    messages={useMemo(
      () => ({
        submit: title ?? (
          <FormattedMessage id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_FORM_FOOTER_SUBMIT_TITLE} tagName="span" />
        ),
      }),
      [title],
    )}
  />
);

const EmailConfirmationFormFooterMemo = React.memo(EmailConfirmationFormFooter);

export default EmailConfirmationFormFooterMemo;
