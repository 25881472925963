import { SettingOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsCompanySummaryLink } from '@/pages/settings/routes';

import type { CompanyLinkProps } from './types';

const CompanyLink: React.FC<CompanyLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settingsCompanySummaryLink()}
    icon={icon ?? <SettingOutlined />}
    title={title ?? <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_LINK_TITLE} />}
  />
);

const CompanyLinkMemo = React.memo(CompanyLink);

export default CompanyLinkMemo;
