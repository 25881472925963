import { css } from '@emotion/css';
import { Form } from 'antd';

import { FormattedMessage } from '@/components';
import type { BrandingProfileFormValues } from '@/features/branding/components/BrandingProfileForm/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { BackgroundColorSelect } from './components';

import type { BackgroundColorItemProps } from './types';
import type React from 'react';

const BackgroundColorItem: React.FC<BackgroundColorItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <Form.Item<BrandingProfileFormValues>
    data-test={dataTest}
    name={nameof<BrandingProfileFormValues>('bgColor')}
    label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_BG_ITEM_LABEL} />}
    className={css`
      margin-bottom: 0;
    `}
  >
    <BackgroundColorSelect data-test={dataTest && `${dataTest}-select`} disabled={disabled} />
  </Form.Item>
);

export default BackgroundColorItem;
