import { LoadingOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation, WarningTooltip } from '@/components';
import { useTokenHistoryBalance } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import AssetAmount from '../AssetAmount';

import type { AddressTokenHistoryBalanceViewProps } from './types';

const zero = new BigNumber(0);

const AddressTokenHistoryBalanceView: React.FC<AddressTokenHistoryBalanceViewProps> = ({
  'data-test': dataTest,
  assetId,
  address,
  blockNum,
  withCurrency,
  currencyMode,
  withBlockchainMark,
  showZeroOnEmpty,
  hideReload,
}) => {
  const id = useMemo(() => ({ assetId, address, blockNum }), [address, blockNum, assetId]);
  const { data: balance, loading, forceRefresh } = useTokenHistoryBalance(id);
  const { withError } = useNotification();
  const reload = useMemo(
    () =>
      withSuppressError(
        withError(
          () => forceRefresh,
          () => (
            <FormattedMessage
              id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_ERROR_MESSAGE}
            />
          ),
        ),
      ),
    [forceRefresh, withError],
  );
  if (balance.isDirty || loading) {
    return <LoadingOutlined />;
  }
  return balance.data?.balance === undefined && !showZeroOnEmpty ? (
    <Space>
      <WarningTooltip
        title={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_BALANCE_NOT_FOUND_TITLE} />}
      >
        <WarningOutlined style={{ color: 'orange' }} />
      </WarningTooltip>
      {!hideReload ? (
        <Operation
          title={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_TITLE} />}
          icon={<ReloadOutlined />}
          onClick={reload}
          data-test={dataTest}
          mode="icon"
        />
      ) : null}
    </Space>
  ) : (
    <>
      <AssetAmount
        assetId={assetId}
        value={balance.data?.balance ?? zero}
        data-test={dataTest}
        withCurrency={withCurrency}
        currencyMode={currencyMode}
        withBlockchainMark={withBlockchainMark}
      />
      {!hideReload ? (
        <>
          &nbsp;
          <Operation
            title={
              <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_TITLE} />
            }
            icon={<ReloadOutlined />}
            onClick={reload}
            data-test={dataTest}
            mode="icon"
          />
        </>
      ) : null}
    </>
  );
};

const AddressTokenHistoryBalanceViewMemo = React.memo(AddressTokenHistoryBalanceView);

export default AddressTokenHistoryBalanceViewMemo;
