import { ArrowDownOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { BrandingLogoBottomPositionProps } from './types';

const BrandingLogoBottomPosition: React.FC<BrandingLogoBottomPositionProps> = ({ 'data-test': dataTest }) => (
  <Space data-test={dataTest}>
    <ArrowDownOutlined />
    <FormattedMessage id={I18nFeatureBranding.COMPONENTS_LOGO_POSITIONS_SWITCH_LABELS_BOTTOM} tagName="span" />
  </Space>
);

const BrandingLogoBottomPositionMemo = React.memo(BrandingLogoBottomPosition);

export default BrandingLogoBottomPositionMemo;
