import { Col, Form, Row } from 'antd';
import debounce from 'lodash/debounce';
import isNil from 'lodash/isNil';
import { useCallback, useMemo, useRef } from 'react';
import isEqual from 'react-fast-compare';

import { ErrorFormMessage, FormattedMessage, FormCompletenessItem, InputItem } from '@/components';
import BrandingDataContainer from '@/features/branding/components/BrandingDataContainer';
import type { BrandingData } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { withOnSuccess, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { nameof } from '@/infrastructure/utils/ts';

import BrandingDataEditPanel from '../BrandingDataEditPanel';
import BrandingDataPreview from '../BrandingDataPreview';

import { BrandingProfileNameEditPanel } from './components';

import type { BrandingProfileFormProps, BrandingProfileFormValues } from './types';
import type { FormInstance } from 'antd/es/form';
import type { NamePath } from 'rc-field-form/lib/interface';
import type React from 'react';

const requiredFields: NamePath[] = [
  nameof<BrandingProfileFormValues>('name'),
  nameof<BrandingProfileFormValues>('supportedLang'),
];

const fieldsToValidate: NamePath[] = [
  nameof<BrandingProfileFormValues>('accentColor'),
  nameof<BrandingProfileFormValues>('bgColor'),
  nameof<BrandingProfileFormValues>('logo'),
  nameof<BrandingProfileFormValues>('poweredBy'),
  nameof<BrandingProfileFormValues>('contactEmail'),
  nameof<BrandingProfileFormValues>('redirectURL'),
  nameof<BrandingProfileFormValues>('auth'),
  nameof<BrandingProfileFormValues>('onRamp'),
];

const checkFormCompleteness = ({ getFieldValue, isFieldValidating, getFieldError }: FormInstance) => {
  const logoVisible = !!getFieldValue([
    nameof<BrandingProfileFormValues>('logo'),
    nameof<NonNullable<BrandingProfileFormValues['logo']>>('visible'),
  ]);
  const poweredByRequired =
    !!getFieldValue([
      nameof<BrandingProfileFormValues>('poweredBy'),
      nameof<NonNullable<BrandingProfileFormValues['poweredBy']>>('visible'),
    ])
    && !!getFieldValue([
      nameof<BrandingProfileFormValues>('poweredBy'),
      nameof<NonNullable<BrandingProfileFormValues['poweredBy']>>('customText'),
      nameof<NonNullable<NonNullable<BrandingProfileFormValues['poweredBy']>['customText']>>('enabled'),
    ]);
  const extraFields = [
    ...(logoVisible
      ? [
          [nameof<BrandingProfileFormValues>('logo'), nameof<NonNullable<BrandingProfileFormValues['logo']>>('url')],
          [
            nameof<BrandingProfileFormValues>('logo'),
            nameof<NonNullable<BrandingProfileFormValues['logo']>>('position'),
          ],
        ]
      : []),
    ...(poweredByRequired
      ? [
          [
            nameof<BrandingProfileFormValues>('poweredBy'),
            nameof<NonNullable<BrandingProfileFormValues['poweredBy']>>('customText'),
            nameof<NonNullable<NonNullable<BrandingProfileFormValues['poweredBy']>['customText']>>('text'),
          ],
        ]
      : []),
  ];

  const allHaveValue = extraFields.reduce((result: boolean, field) => result && !isNil(getFieldValue(field)), true);
  const someValidating = extraFields.reduce((result: boolean, field) => result || isFieldValidating(field), false);
  const anyHasError = extraFields.reduce((result: boolean, field) => result || !!getFieldError(field).length, false);

  return allHaveValue && !someValidating && !anyHasError;
};
const BrandingProfileForm: React.FC<BrandingProfileFormProps> = ({
  'data-test': dataTest,
  initialValues,
  submitCallback,
  onSubmitting,
  onSubmit,
  onReset,
}) => {
  const { form, resetForm } = useForm<BrandingProfileFormValues>();
  const { error, submitting, withSubmitting, reset: resetSubmit } = useErrorSafeSubmitting();
  const [onSubmitLocal, setOnSubmitLocal] = useStateMountSafe<Func | undefined>();

  const isFormCompleteRef = useRef(false);
  const [showPreview, setShowPreview] = useStateMountSafe<(branding: BrandingProfileFormValues) => void>();
  const saveShowPreview = useCallback(
    (func: (branding: BrandingData) => void) => setShowPreview(() => func),
    [setShowPreview],
  );

  const doReset = useMemo(
    () => (onReset ? withVoidOrThrow(withOnSuccess(onReset, () => setOnSubmitLocal(undefined))) : undefined),
    [onReset, setOnSubmitLocal],
  );
  const doSave = useMemo(
    () =>
      withSubmitting(
        withVoidOrThrow(
          wrap(
            withOnSuccess(onSubmit, () => setOnSubmitLocal(undefined)),
            () => onSubmitting?.(true),
            () => onSubmitting?.(false),
          ),
        ),
      ),
    [onSubmit, onSubmitting, setOnSubmitLocal, withSubmitting],
  );
  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      if (complete) {
        showPreview?.(value);
      }
      if (!complete && !isFormCompleteRef.current) {
        return;
      }
      const hasBeenUpdated = !initialValues || !isEqual(initialValues, value);
      const isCompleteAndUpdated = complete && hasBeenUpdated;
      if (isCompleteAndUpdated === isFormCompleteRef.current) {
        return;
      }
      const doSubmit = isCompleteAndUpdated
        ? async () => {
            await doSave(form.getFieldsValue());
            setTimeout(resetForm, 0);
          }
        : undefined;
      submitCallback?.(doSubmit);
      setOnSubmitLocal(() => doSubmit);
      isFormCompleteRef.current = isCompleteAndUpdated;
    },
    [form, initialValues, submitCallback, setOnSubmitLocal, showPreview, doSave, resetForm],
  );
  const updateCompleteDebounced = useMemo(() => debounce(updateComplete, 50), [updateComplete]);

  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_FORM_ERROR_COMMON} />;
  }, [error]);

  return (
    <Form<BrandingProfileFormValues>
      onValuesChange={resetSubmit}
      autoComplete="off"
      initialValues={initialValues}
      form={form}
      size="middle"
      onFinish={doSave}
      onReset={doReset}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {errorMessage ? (
            <ErrorFormMessage content={errorMessage} />
          ) : (
            <FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_FORM_HELP} />
          )}
        </Col>
        <Col span={24}>
          <BrandingProfileNameEditPanel
            data-test={dataTest && `${dataTest}-header`}
            onSubmit={onSubmitLocal}
            onReset={doReset}
            submitting={submitting}
          />
        </Col>
        <Col span={24}>
          <BrandingDataContainer
            dataPanel={<BrandingDataEditPanel data-test={dataTest && `${dataTest}-content`} disabled={submitting} />}
            previewPanel={
              <BrandingDataPreview
                data-test={dataTest && `${dataTest}-preview`}
                initialValue={initialValues}
                onUpdaterReady={saveShowPreview}
              />
            }
          />
        </Col>
      </Row>
      <InputItem<BrandingProfileFormValues>
        data-test={dataTest && `${dataTest}-id`}
        name={nameof<BrandingProfileFormValues>('id')}
        ItemProps={{ hidden: true }}
      />
      <FormCompletenessItem<BrandingProfileFormValues>
        requiredFields={requiredFields}
        fields={fieldsToValidate}
        onChange={updateCompleteDebounced}
        checkIsComplete={checkFormCompleteness}
      />
    </Form>
  );
};

export default BrandingProfileForm;
