import { useMemo } from 'react';

import type { EditPayoutFormData } from '@/features/payouts/components';
import type { PayoutDestination, PayoutSummary } from '@/features/payouts/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { combine } from '@/infrastructure/model';

export default function useInitialValues(
  payoutState: LoadingStateWithDirty<PayoutSummary>,
  destinationsState: LoadingStateWithDirty<PayoutDestination[]>,
): LoadingStateWithDirty<EditPayoutFormData> {
  return useMemo(
    () =>
      combine(
        payoutState,
        destinationsState,
        (payout, destinations): EditPayoutFormData => ({
          asset: payout.amount.asset,
          title: payout.title,
          destinations: destinations.map(
            ({ num, amount, address }): NonNullable<EditPayoutFormData['destinations']>[0] => ({
              num,
              address,
              amount: amount.value,
            }),
          ),
        }),
      ),
    [destinationsState, payoutState],
  );
}
