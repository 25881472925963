import React from 'react';

import { DonationView } from '@/features/donations/components';

import { withDonationDataLoading } from './hocs';

const DonationCard = withDonationDataLoading((props) => <DonationView {...props} columns={1} />);

const DonationCardMemo = React.memo(DonationCard) as typeof DonationCard;

export default DonationCardMemo;
