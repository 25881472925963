import { useRechargeAddress } from '@/features/recharges/hooks';
import { PushContractDeployStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withRechargeAddressDeployTransactionAppearance = <Props extends { addressId: string } = { addressId: string }>(
  Component: React.ComponentType<Props>,
): React.FC<Props> =>
  namedHOC<Props, Props>(
    Component,
    'WithRechargeAddressDeployTransactionAppearance',
  )((props) => {
    const data = useRechargeAddress(props.addressId);
    return data.data.data?.gwtId
      && [PushContractDeployStatusAPIModel.Deploying, PushContractDeployStatusAPIModel.Failed].includes(
        data.data.data.deployStatus,
      ) ? (
      <Component {...props} />
    ) : null;
  });

export default withRechargeAddressDeployTransactionAppearance;
