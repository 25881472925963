import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeDonationListParameters } from '@/features/donations/actions';
import type { Donation, DonationFilterPredicate } from '@/features/donations/types';
import type { PushAddressSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useDonationList from './useDonationList';

export type UseDonationListView = UseAppListDataView<Donation, DonationFilterPredicate, PushAddressSortByAPIModel>;

export default function useDonationListView(): UseDonationListView {
  const state = useDonationList();
  return useAppListDataView(state, storeDonationListParameters);
}
