/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum NcpsRoleAPIModel {
    Merchant = 'Merchant',
    Supervisor = 'Supervisor'
}


export function NcpsRoleAPIModelFromJSON(json: any): NcpsRoleAPIModel {
    return NcpsRoleAPIModelFromJSONTyped(json, false);
}

export function NcpsRoleAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NcpsRoleAPIModel {
    return json as NcpsRoleAPIModel;
}

export function NcpsRoleAPIModelToJSON(value?: NcpsRoleAPIModel | null): any {
    return value as any;
}

