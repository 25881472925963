import { encodeAbiParameters, getContract, keccak256, parseSignature, publicActions, zeroAddress } from 'viem';

import type { SignatureComponentsAPIModel } from '@/generated/api/ncps-api';
import { asType } from '@/infrastructure/utils/ts';

import type { Account, Address, Chain, Hex, TransactionReceipt, Transport, WalletClient, Signature } from 'viem';

const walletAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'expectedCaller',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'caller',
        type: 'address',
      },
    ],
    name: 'ChangeFlushDestinationDenied',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'dest',
        type: 'address',
      },
    ],
    name: 'DuplicateFeeDestination',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'flushedAmount',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'minFlushAmount',
        type: 'uint256',
      },
    ],
    name: 'FlushedAmountTooLow',
    type: 'error',
  },
  {
    inputs: [],
    name: 'InsufficientFundsForFee',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'totalPercent',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'upperLimit',
        type: 'uint256',
      },
    ],
    name: 'TotalFeePercentOverflow',
    type: 'error',
  },
  {
    inputs: [],
    name: 'NONCE_SUFFIX',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'broker',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'totalAmount',
        type: 'uint256',
      },
    ],
    name: 'calculateFees',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'destinations',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'amounts',
        type: 'uint256[]',
      },
      {
        internalType: 'uint8',
        name: 'v',
        type: 'uint8',
      },
      {
        internalType: 'bytes32',
        name: 'r',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: 's',
        type: 'bytes32',
      },
    ],
    name: 'distribute',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'fees',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: '',
        type: 'uint256[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
    ],
    name: 'flush',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'destination',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'crossChainData',
        type: 'bytes',
      },
    ],
    name: 'flush',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'flush',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'flushDestination',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'isFlushAllowed',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_hash',
        type: 'bytes32',
      },
      {
        internalType: 'bytes',
        name: '_signature',
        type: 'bytes',
      },
    ],
    name: 'isValidSignature',
    outputs: [
      {
        internalType: 'bytes4',
        name: 'magicValue',
        type: 'bytes4',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'merchant',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
    ],
    name: 'minFlushAmount',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'nonce',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'notifier',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'destinations',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'percents',
        type: 'uint256[]',
      },
    ],
    name: 'setFees',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalExtraFeePercent',
    outputs: [
      {
        internalType: 'uint128',
        name: '',
        type: 'uint128',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newDestination',
        type: 'address',
      },
    ],
    name: 'updateFlushDestination',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newDestination',
        type: 'address',
      },
      {
        internalType: 'string',
        name: 'messagePrefix',
        type: 'string',
      },
      {
        internalType: 'uint8',
        name: 'v',
        type: 'uint8',
      },
      {
        internalType: 'bytes32',
        name: 'r',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: 's',
        type: 'bytes32',
      },
    ],
    name: 'updateFlushDestination',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'newValue',
        type: 'uint256',
      },
    ],
    name: 'updateMinFlushAmount',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

const payoutAbi = [
  {
    inputs: [],
    name: 'BadMerkleRoot',
    type: 'error',
  },
  {
    inputs: [],
    name: 'BadPaymentSubtree',
    type: 'error',
  },
  {
    inputs: [],
    name: 'BadSubtreeCapacity',
    type: 'error',
  },
  {
    inputs: [],
    name: 'ProofsCapacityTooLow',
    type: 'error',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'bytes32[]',
        name: 'bottomMerkleProofs',
        type: 'bytes32[]',
      },
      {
        internalType: 'bytes32',
        name: 'adjacentNode',
        type: 'bytes32',
      },
      {
        internalType: 'uint256',
        name: 'expired',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'destination',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        internalType: 'struct IMerklePayout.SinglePayout[]',
        name: 'singlePayouts',
        type: 'tuple[]',
      },
      {
        internalType: 'uint8',
        name: 'v',
        type: 'uint8',
      },
      {
        internalType: 'bytes32',
        name: 'r',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: 's',
        type: 'bytes32',
      },
    ],
    name: 'initialMerklePayout',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'bytes32',
        name: 'merkleRoot',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: 'adjacentNode',
        type: 'bytes32',
      },
      {
        internalType: 'uint256',
        name: 'nodeNum',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'expired',
        type: 'uint256',
      },
      {
        components: [
          {
            internalType: 'address',
            name: 'destination',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        internalType: 'struct IMerklePayout.SinglePayout[]',
        name: 'singlePayouts',
        type: 'tuple[]',
      },
    ],
    name: 'provideMerklePayout',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

const prepareDomain = (chainId: number) =>
  ({
    name: 'SmartyCryptoPayouts',
    version: '1',
    chainId,
  }) as const;

export const requestSignMerklePayout = async (
  client: WalletClient<Transport, Chain, Account>,
  assetAddress: Address,
  merkleRoot: Hex,
  expiresAt: Date,
): Promise<Signature> => {
  const typedSignature = await client.signTypedData({
    domain: prepareDomain(await client.getChainId()),
    types: {
      MerklePayout: [
        { name: 'root', type: 'bytes32' },
        { name: 'token', type: 'address' },
        { name: 'expired', type: 'uint256' },
      ],
    },
    primaryType: 'MerklePayout',
    message: {
      root: merkleRoot,
      token: assetAddress,
      expired: BigInt(Math.floor(expiresAt.getTime() / 1000)),
    },
  });
  return parseSignature(typedSignature);
};

export const requestSignSimplePayout = async (
  client: WalletClient<Transport, Chain, Account>,
  walletAddress: Address,
  assetAddress: Address,
  destinations: { address: Address; rawAmount: bigint }[],
): Promise<Signature> => {
  const contract = getContract({ address: walletAddress, abi: walletAbi, client });

  const nonce = await contract.read.nonce();
  const hash = keccak256(
    encodeAbiParameters(
      [{ type: 'address[]' }, { type: 'uint256[]' }],
      [destinations.map(({ address }) => address), destinations.map(({ rawAmount }) => rawAmount)],
    ),
  );
  const totalAmount = destinations.reduce((result, { rawAmount }) => result + rawAmount, 0n);

  const typedSignature = await client.signTypedData({
    domain: prepareDomain(await client.getChainId()),
    types: {
      Payout: [
        { name: 'hash', type: 'bytes32' },
        { name: 'token', type: 'address' },
        { name: 'totalAmount', type: 'uint256' },
        { name: 'nonce', type: 'uint256' },
      ],
    },
    primaryType: 'Payout',
    message: {
      hash,
      token: assetAddress,
      totalAmount,
      nonce,
    },
  });
  return parseSignature(typedSignature);
};

export const requestExecuteMerklePayoutTest = async (
  client: WalletClient<Transport, Chain, Account>,
  walletAddress: Address,
  assetAddress: Address,
  expiresAt: Date,
  destinations: { id: number; address: Address; rawAmount: bigint }[],
  eip712Signature: SignatureComponentsAPIModel,
  data: {
    rootProof: Hex;
    bottomProofs: Hex[];
    batches: { proof: Hex; adjacentProof: Hex; destinations: number[] }[];
  },
): Promise<TransactionReceipt[]> => {
  const contract = getContract({ address: walletAddress, abi: payoutAbi, client });
  const gasPrice = (await client.extend(publicActions).getGasPrice()) * 5n;

  const expiresAtSeconds = BigInt(Math.floor(expiresAt.getTime() / 1000));

  const prepareDestinationsArg = (batch: { proof: Hex; adjacentProof: Hex; destinations: number[] }) => [
    ...batch.destinations.map((destinationId) => {
      const { address, rawAmount } = destinations.find(({ id }) => id === destinationId)!;
      return { destination: address, amount: rawAmount };
    }),
    ...(batch.destinations.length < 256
      ? new Array<{ destination: Address; amount: bigint }>(256 - batch.destinations.length).fill({
          destination: zeroAddress,
          amount: BigInt(0),
        })
      : []),
  ];
  const init = async () => {
    const args = [
      assetAddress,
      data.bottomProofs,
      data.batches[0].adjacentProof,
      expiresAtSeconds,
      prepareDestinationsArg(data.batches[0]),
      eip712Signature.v,
      eip712Signature.r as Hex,
      eip712Signature.s as Hex,
    ] as const;
    const gasLimit = await contract.estimateGas.initialMerklePayout(args);
    const tx = await contract.write.initialMerklePayout(args, { gasPrice, gasLimit });
    return client.extend(publicActions).waitForTransactionReceipt({ hash: tx, confirmations: 3, timeout: 30_000 });
  };
  const sendBatch = async (batch: { proof: Hex; adjacentProof: Hex; destinations: number[] }, batchIdx: number) => {
    const args = [
      assetAddress,
      data.rootProof,
      batch.adjacentProof,
      BigInt(batchIdx),
      expiresAtSeconds,
      prepareDestinationsArg(batch),
    ] as const;
    const gasLimit = await contract.estimateGas.provideMerklePayout(args);
    const tx = await contract.write.provideMerklePayout(args, { gasPrice, gasLimit });
    return client.extend(publicActions).waitForTransactionReceipt({ hash: tx, confirmations: 3, timeout: 30_000 });
  };

  const initReceipt = await init();
  const [, ...batches] = data.batches;
  const receipts = await batches
    .map((batch, idx) => async () => sendBatch(batch, idx + 1))
    .reduce(
      async (result, call) => [...(await result), await call()],
      Promise.resolve(asType<TransactionReceipt[]>([])),
    );
  return [initReceipt, ...receipts];
};

export const requestExecuteSimplePayoutTest = async (
  client: WalletClient<Transport, Chain, Account>,
  walletAddress: Address,
  assetAddress: Address,
  destinations: { address: Address; rawAmount: bigint }[],
  eip712Signature: SignatureComponentsAPIModel,
): Promise<TransactionReceipt> => {
  const contract = getContract({ address: walletAddress, abi: walletAbi, client });
  const gasPrice = (await client.extend(publicActions).getGasPrice()) * 5n;
  const args = [
    assetAddress,
    destinations.map(({ address }) => address),
    destinations.map(({ rawAmount }) => rawAmount),
    eip712Signature.v,
    eip712Signature.r as Hex,
    eip712Signature.s as Hex,
  ] as const;
  const gasLimit = await contract.estimateGas.distribute(args);
  const tx = await contract.write.distribute(args, { gasPrice, gasLimit });
  return client.extend(publicActions).waitForTransactionReceipt({ hash: tx, confirmations: 3, timeout: 30_000 });
};
