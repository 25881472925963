import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import type { SubscriptionCharge } from '@/features/subscription-charges/types';
import { fetchSubscriptionChargeForSubscription } from '@/features/subscriptions/actions';
import { makeSelectChargesForSubscription } from '@/features/subscriptions/selectors';
import { type LoadingStateWithDirty, storedDataLoaded } from '@/infrastructure/model';

export type UseSubscriptionChargesForSubscription = UseAppSingleData<SubscriptionCharge[]>;

const noData = storedDataLoaded([]);

const fetchFactory = (
  subscriptionId: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<SubscriptionCharge[]>,
  Parameters<typeof fetchSubscriptionChargeForSubscription>[0]
> => fetchSubscriptionChargeForSubscription({ subscriptionId, force });

export default function useSubscriptionChargeForPayment(
  subscriptionId: string | undefined,
): UseSubscriptionChargesForSubscription {
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchSubscriptionChargeForSubscription, subscriptionId),
    [subscriptionId],
  );
  return useAppSingleData(fetchFactory, makeSelectChargesForSubscription, noData, subscriptionId, dataFetchingSelector);
}
