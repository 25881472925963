import React from 'react';

import { DurationView } from '@/components';

import type { SubscriptionPlanGraceViewProps } from './types';

const SubscriptionPlanGraceView: React.FC<SubscriptionPlanGraceViewProps> = ({
  'data-test': dataTest,
  value,
  mode = 'full',
}) => <DurationView data-test={dataTest} value={value} mode={mode} />;

const SubscriptionPlanGraceViewMemo = React.memo(SubscriptionPlanGraceView);

export default SubscriptionPlanGraceViewMemo;
