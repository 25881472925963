import pLimit from 'p-limit';
import isEqual from 'react-fast-compare';

import { createAppAsyncThunk } from '@/app/actions';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { storeSelectedNetwork } from '@/features/dictionary/blockchain/actions';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { makeSelectCookiesAcceptance } from '@/features/global/selectors';
import { storeLocale } from '@/features/i18n/actions';
import { makeSelectLocale } from '@/features/i18n/selectors';
import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nLocales } from '@/generated/i18n/i18n';
import { withAPICall } from '@/infrastructure/api';
import { storedDataError } from '@/infrastructure/model';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { callWithThunkError } from '@/infrastructure/utils/redux';
import { enumByKey } from '@/infrastructure/utils/ts';

import { queryCurrentUser, queryUserPreferences, requestUpdateUserPreferences } from './api';
import { makeSelectUser, makeSelectPreferences } from './selectors';
import { NAMESPACE } from './types';

import type { User, UserPreferences } from './types';

export const { storeUser, markUserDirty } = createLoadingDataActions<User, 'User'>(NAMESPACE, 'User' as const);

const userFetchLimit = pLimit(1);
export const fetchUser = createAppAsyncThunk(
  `${NAMESPACE}/fetchUser`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    userFetchLimit(async () => {
      const saved = makeSelectUser()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCurrentUser, 'unable to fetch user')({ signal });
      dispatch(storeUser(data));

      return makeSelectUser()(getState());
    }),
);

export const { storePreferences, markPreferencesDirty } = createLoadingDataActions<UserPreferences, 'Preferences'>(
  NAMESPACE,
  'Preferences' as const,
);

const preferencesFetchLimit = pLimit(1);
export const fetchPreferences = createAppAsyncThunk(
  `${NAMESPACE}/fetchPreferences`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    preferencesFetchLimit(async () => {
      const saved = makeSelectPreferences()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const lang = makeSelectLocale()(getState());
      const cookiesAccepted = makeSelectCookiesAcceptance()(getState()).value;
      const network = makeSelectSelectedNetwork()(getState());
      const newData: UserPreferences = { lang, cookiesAccepted, network };
      let data = await withAPICall(queryUserPreferences, 'unable to fetch preferences')({ signal });
      if (data.error === 'PreferencesNotFound') {
        data = await withAPICall(requestUpdateUserPreferences, 'unable to update user preferences')(newData, {
          signal,
        });
      } else if (data.data) {
        const storedNetwork = enumByKey(BlockchainNetworkTypeAPIModel, data.data.network);
        if (storedNetwork && network !== storedNetwork) {
          dispatch(storeSelectedNetwork(storedNetwork));
        }
        const storedLocale = enumByKey(I18nLocales, data.data.lang);
        if (lang !== storedLocale) {
          dispatch(storeLocale(storedLocale!));
        }
        if (cookiesAccepted && !data.data.cookiesAccepted) {
          data = await withAPICall(requestUpdateUserPreferences, 'unable to update user preferences')(
            { ...newData, cookiesAccepted },
            { signal },
          );
        }
      }

      dispatch(storePreferences(data));

      return makeSelectPreferences()(getState());
    }),
);

export const persistPreferences = createAppAsyncThunk(
  `${NAMESPACE}/persistPreferences`,
  async (newPreferences: Partial<UserPreferences>, { dispatch, getState, signal }) => {
    const login = makeSelectAuthToken()(getState());
    if (!login.data) {
      return storedDataError<UserPreferences>('Unauthorized');
    }
    const saved = makeSelectPreferences()(getState());
    const stored = saved.isDirty ? await callWithThunkError(() => dispatch(fetchPreferences({})).unwrap()) : saved;

    const dataToStore = { ...(stored.data ? stored.data : {}), ...newPreferences };
    if (isEqual(dataToStore, stored.data)) {
      return saved;
    }

    const result = await withAPICall(requestUpdateUserPreferences, 'unable to update user preferences')(dataToStore, {
      signal,
    });
    if (result.data) {
      dispatch(storePreferences(result));
    }

    return result;
  },
);
