import {
  CheckCircleOutlined,
  CloudSyncOutlined,
  ExclamationCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { SubscriptionChargeStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureSubscriptionCharges } from '@/generated/i18n/i18n';

import type { SubscriptionChargeStatusViewProps } from './types';

const icons: Record<SubscriptionChargeStatusAPIModel, React.ReactNode> = {
  [SubscriptionChargeStatusAPIModel.Retrying]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <CloudSyncOutlined style={{ color: 'orange' }} />
    </Badge>
  ),
  [SubscriptionChargeStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <CloudSyncOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [SubscriptionChargeStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  [SubscriptionChargeStatusAPIModel.Cancelled]: <StopOutlined style={{ color: 'red' }} />,
  [SubscriptionChargeStatusAPIModel.Succeeded]: <CheckCircleOutlined style={{ color: 'green' }} />,
};

const i18n: Record<SubscriptionChargeStatusAPIModel, I18nMessages> = {
  [SubscriptionChargeStatusAPIModel.Retrying]: I18nFeatureSubscriptionCharges.COMPONENTS_CHARGE_STATUS_VIEW_RETRYING,
  [SubscriptionChargeStatusAPIModel.Pending]: I18nFeatureSubscriptionCharges.COMPONENTS_CHARGE_STATUS_VIEW_PENDING,
  [SubscriptionChargeStatusAPIModel.Failed]: I18nFeatureSubscriptionCharges.COMPONENTS_CHARGE_STATUS_VIEW_FAILED,
  [SubscriptionChargeStatusAPIModel.Cancelled]: I18nFeatureSubscriptionCharges.COMPONENTS_CHARGE_STATUS_VIEW_CANCELLED,
  [SubscriptionChargeStatusAPIModel.Succeeded]: I18nFeatureSubscriptionCharges.COMPONENTS_CHARGE_STATUS_VIEW_SUCCEEDED,
};

const SubscriptionChargeStatusViewRaw: React.FC<SubscriptionChargeStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
  ...props
}) => (value ? <StatusViewElement i18n={i18n[value]} icon={icons[value]} mode={mode} {...props} /> : null);
const SubscriptionChargeStatusView = React.memo(
  SubscriptionChargeStatusViewRaw,
) as typeof SubscriptionChargeStatusViewRaw;

export default SubscriptionChargeStatusView;
