import { Card } from 'antd';
import React from 'react';

import { useSharedStyles } from '@/hooks';

import type { MessageLayoutCardContainerProps } from './types';

const MessageLayoutCardContainer: React.FC<MessageLayoutCardContainerProps> = ({
  'data-test': dataTest,
  title,
  children,
}) => {
  const { modalCard } = useSharedStyles();
  return (
    <Card data-test={dataTest} title={title} className={modalCard}>
      {children}
    </Card>
  );
};

const MessageLayoutCardContainerMemo = React.memo(MessageLayoutCardContainer);

export default MessageLayoutCardContainerMemo;
