import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlements } from '@/features/settlements/actions';
import { makeSelectSettlementsListData, makeSelectSettlementsListParameters } from '@/features/settlements/selectors';
import type { Settlement, SettlementFilterPredicate } from '@/features/settlements/types';
import type { WithdrawalSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseSettlementList = UseAppListData<Settlement, SettlementFilterPredicate, WithdrawalSortByAPIModel>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<Settlement>, Parameters<typeof fetchSettlements>[0]> =>
  fetchSettlements({ force });

const dataSelector = makeSelectSettlementsListData();
const dataFetchingSelector = makeSelectPending(fetchSettlements);
const parametersSelector = makeSelectSettlementsListParameters();

export default function useSettlementList(): UseSettlementList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
