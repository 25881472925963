import React from 'react';

import { OperationRefresh } from '@/components';
import { useAPIKeys } from '@/features/company-settings/hooks';

import type { OperationRefreshAPIKeysProps } from './types';

const OperationRefreshAPIKeys: React.FC<OperationRefreshAPIKeysProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useAPIKeys();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshAPIKeysMemo = React.memo(OperationRefreshAPIKeys);

export default OperationRefreshAPIKeysMemo;
