/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BottomProofsAPIModel
 */
export interface BottomProofsAPIModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof BottomProofsAPIModel
     */
    proofs?: Array<string>;
}

/**
 * Check if a given object implements the BottomProofsAPIModel interface.
 */
export function instanceOfBottomProofsAPIModel(value: object): boolean {
    return true;
}

export function BottomProofsAPIModelFromJSON(json: any): BottomProofsAPIModel {
    return BottomProofsAPIModelFromJSONTyped(json, false);
}

export function BottomProofsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BottomProofsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'proofs': json['proofs'] == null ? undefined : json['proofs'],
    };
}

export function BottomProofsAPIModelToJSON(value?: BottomProofsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'proofs': value['proofs'],
    };
}

