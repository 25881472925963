import type { DateTimeRelativeQuickFilter } from '@/components';
import type {
  NewPayoutAPIModel,
  PayoutBatchAPIModel,
  PayoutBatchStatusAPIModel,
  PayoutDestinationAPIModel,
  PayoutSummaryAPIModel,
  PayoutUpdateAPIModel,
  BigDecimalRangeAPIModel,
  SignatureComponentsAPIModel,
  PayoutPredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { PayoutSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import { defaultPage } from '@/infrastructure/api';
import type { FullParametersNoColumnsState } from '@/infrastructure/model/full/types';
import type { ListColumnState, ListSortBy, ListState } from '@/infrastructure/model/list/types';
import { defaultListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

import type { Hex } from 'viem';

export const NAMESPACE = 'payouts';

export interface PayoutSummary extends Omit<PayoutSummaryAPIModel, 'amount' | 'settledAmount'> {
  amount: AssetAmountValue;
  settledAmount: AssetAmountValue;
}

export interface PayoutDestination extends Omit<PayoutDestinationAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export type PayoutDestinationDetailed = PayoutDestination & {
  batchStatus?: PayoutBatchStatusAPIModel;
  batchNum?: number;
  batchHash?: string;
};

export interface PayoutBatch extends Omit<PayoutBatchAPIModel, 'amount' | 'id'> {
  amount: AssetAmountValue;
  broadcastAt?: Date;
  staleAt?: Date;
}

export interface PayoutFilterPredicate extends PayoutPredicatesAPIModel {
  createdAtRelative?: DateTimeRelativeQuickFilter;
}

export type PayoutSortBy = ListSortBy<PayoutSortByAPIModel>;

export type PayoutListState = ListState<string, PayoutFilterPredicate, PayoutSortByAPIModel>;

export interface NewPayout extends Omit<NewPayoutAPIModel, 'destinations'> {
  destinations: PayoutDestination[];
}

export interface PayoutUpdate extends Omit<PayoutUpdateAPIModel, 'destinations' | 'id'> {
  destinations: PayoutDestination[];
}

export interface PayoutMerkleTreeSignatureData {
  rootProof: Hex;
  bottomProofs: Hex[];
  batches: {
    proof: Hex;
    adjacentProof: Hex;
    destinations: number[];
  }[];
}

export interface MerklePayoutSignatureData extends PayoutMerkleTreeSignatureData {
  eip712signature: SignatureComponentsAPIModel;
  expiresAt: Date;
}

export interface SimplePayoutSignatureData {
  eip712signature: SignatureComponentsAPIModel;
  destinations: number[];
}

export interface PayoutDestinationFilter {
  addressLike?: string;
  amountRange?: BigDecimalRangeAPIModel;
}

export interface PayoutDestinationDetailedFilter extends PayoutDestinationFilter {
  batchHashLike?: string;
  batchStatusIn?: PayoutBatchStatusAPIModel;
}

export enum PayoutDestinationDetailedSortByAPIModel {
  Num = 'Num',
  Address = 'Address',
  Batch = 'Batch',
}

export enum PayoutDestinationSortByAPIModel {
  Num = 'Num',
  Address = 'Address',
}

export type PayoutDestinationDetailedParametersState = FullParametersNoColumnsState<
  PayoutDestinationDetailedFilter,
  PayoutDestinationDetailedSortByAPIModel
>;

export type PayoutDestinationParametersState = FullParametersNoColumnsState<
  PayoutDestinationFilter,
  PayoutDestinationSortByAPIModel
>;

export interface PayoutBatchId {
  payoutId: string;
  batchNum: number;
}

export const defaultPayoutSortBy: PayoutSortBy = { [PayoutSortByAPIModel.CreatedAt]: 'DESC' };
export const defaultPayoutListState: PayoutListState = defaultListState({}, defaultPayoutSortBy);

export const defaultPayoutDestinationDetailedParametersState: PayoutDestinationDetailedParametersState = {
  page: defaultPage,
  filter: {},
  sortBy: { [PayoutDestinationDetailedSortByAPIModel.Num]: 'DESC' },
};
export const defaultPayoutDestinationParametersState: PayoutDestinationParametersState = {
  page: defaultPage,
  filter: {},
  sortBy: { [PayoutDestinationSortByAPIModel.Num]: 'DESC' },
};

export interface PayoutsState {
  entities: SingleState<PayoutSummary>; // by payout id
  list: PayoutListState;
  bySettlement: SingleState<string>; // by settlement id

  destinations: SingleState<PayoutDestination[]>; // by payout id
  batches: SingleState<PayoutBatch[]>; // by payout id

  destinationsPerPayout: {
    parameters: Partial<Record<string, PayoutDestinationDetailedParametersState>>;
    columnState: ListColumnState;
  };

  destinationsPerBatch: {
    parameters: Partial<Record<string, PayoutDestinationParametersState>>;
    columnState: ListColumnState;
  };
}
