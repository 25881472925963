import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useAPIKey, useAPIKeyActions } from '@/features/company-settings/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationUpdateAPIKeyStatusProps } from './types';

const activateOptions = {
  error: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_API_KEY_ACTIVATE_MESSAGE_ERROR} />
  ),
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_API_KEY_ACTIVATE_MESSAGE_SUCCESS} />
  ),
};

const deactivateOptions = {
  error: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_API_KEY_DEACTIVATE_MESSAGE_ERROR} />
  ),
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_API_KEY_DEACTIVATE_MESSAGE_SUCCESS} />
  ),
};

const OperationUpdateAPIKeyStatus: React.FC<OperationUpdateAPIKeyStatusProps> = ({
  'data-test': dataTest,
  id,
  mode = 'inline',
}) => {
  const { withNotification } = useNotification();
  const { data } = useAPIKey(id);
  const { activateAPIKey, deactivateAPIKey } = useAPIKeyActions(id);

  const activate = useMemo(
    () => withSuppressError(withNotification(activateAPIKey.act, activateOptions)),
    [activateAPIKey.act, withNotification],
  );

  const deactivate = useMemo(
    () => withSuppressError(withNotification(deactivateAPIKey.act, deactivateOptions)),
    [deactivateAPIKey.act, withNotification],
  );

  return !data.data?.isActive ? (
    <Operation
      title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_API_KEY_ACTIVATE_TITLE} />}
      icon={<PlayCircleOutlined />}
      onClick={activate}
      disabled={!activateAPIKey.available}
      inProgress={activateAPIKey.inAction}
      data-test={dataTest}
      mode={mode}
    />
  ) : (
    <Operation
      title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_API_KEY_DEACTIVATE_TITLE} />}
      icon={<PauseCircleOutlined />}
      onClick={deactivate}
      disabled={!deactivateAPIKey.available}
      inProgress={deactivateAPIKey.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationUpdateAPIKeyStatusMemo = React.memo(OperationUpdateAPIKeyStatus);

export default OperationUpdateAPIKeyStatusMemo;
