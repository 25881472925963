import { useCallback, useMemo } from 'react';

import { type DataLinkProps, PageContainer } from '@/components';
import { RechargesDocumentationBanner } from '@/features/recharges/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import { withPayoutPageParams } from '@/pages/settlements/payouts/hocs';
import PayoutBatchViewLink from '@/pages/settlements/payouts/payout-batch-view/components/PayoutBatchViewLink';

import {
  PayoutBatchesCard,
  PayoutBatchesListOperations,
  PayoutCard,
  PayoutDestinationsCard,
  PayoutViewBreadcrumb,
} from './components';

import type React from 'react';

type PayoutViewPageProps = TestableProps & { pageParams: { payoutId: string } };

const PayoutViewPage: React.FC<PayoutViewPageProps> = ({
  'data-test': dataTest = 'payout',
  pageParams: { payoutId },
}) => {
  const BatchLink = useCallback(
    ({ value, ...props }: DataLinkProps<number>) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const id = useMemo(() => ({ payoutId, batchNum: value }), [value]);
      return <PayoutBatchViewLink {...props} value={id} />;
    },
    [payoutId],
  );
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={
        <PayoutViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} payoutId={payoutId} disabled />
      }
    >
      <RechargesDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
      <PayoutCard data-test={dataTest && `${dataTest}-card`} payoutId={payoutId} />
      <PayoutBatchesCard
        data-test={dataTest && `${dataTest}-batches`}
        payoutId={payoutId}
        BatchLink={BatchLink}
        Operations={PayoutBatchesListOperations}
      />
      <PayoutDestinationsCard
        data-test={dataTest && `${dataTest}-destinations`}
        payoutId={payoutId}
        BatchLink={BatchLink}
      />
    </PageContainer>
  );
};

export default withPayoutPageParams(PayoutViewPage);
