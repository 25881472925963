import { Form } from 'antd';
import { useCallback, useMemo } from 'react';

import { FormattedMessage, FormCompletenessItem } from '@/components';
import { CompanyNameItem } from '@/features/company/components';
import { useCompanyActions } from '@/features/company/hooks';
import type { NewCompany } from '@/features/company/types';
import { CompanyWebsiteItem } from '@/features/company-settings/components';
import type { EmailConfirmationInputValues } from '@/features/email/components';
import { EmailConfirmationItem } from '@/features/email/components';
import { I18nFeatureOnboard } from '@/generated/i18n/i18n';
import { useDefaultNotification, useForm, useStateMountSafe, useSubmitting } from '@/hooks';
import { withOnSuccess } from '@/infrastructure/utils/functions';
import type { DeepPartial } from '@/infrastructure/utils/ts';
import { nameof } from '@/infrastructure/utils/ts';
import type { OnboardingContainerProps } from '@/pages/onboard/main/components';
import { OnboardingStep } from '@/pages/onboard/main/types';

import OnboardingContainer from '../OnboardingContainer';

import type { CompanyStepProps } from './types';
import type { FormProps } from 'antd';
import type React from 'react';

type CompanyForm = Pick<NewCompany, 'companyName' | 'companyWebsite'> & {
  emailConfirmation: EmailConfirmationInputValues;
};

const requiredFields = [
  nameof<CompanyForm>('companyName'),
  nameof<CompanyForm>('companyWebsite'),
  nameof<CompanyForm>('emailConfirmation'),
];

const companyNameMessages = {
  label: <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_STEPS_COMPANY_FORM_COMPANY_NAME_LABEL} />,
};
const companyWebSiteMessages = {
  label: <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_STEPS_COMPANY_FORM_COMPANY_WEBSITE_LABEL} />,
};
const emailConfirmationProps = {
  required: true,
  label: <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_STEPS_COMPANY_FORM_COMPANY_EMAIL_LABEL} />,
};
const CompanyStep: React.FC<CompanyStepProps> = ({ 'data-test': dataTest, userEmail, onSubmit }) => {
  const { form } = useForm<CompanyForm>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { createCompany } = useCompanyActions();
  const [submitting, withSubmitting] = useSubmitting(false);
  const [values, setValues] = useStateMountSafe<CompanyForm>();
  const { withDefaultError } = useDefaultNotification();

  const doSubmit = useMemo(
    () =>
      withDefaultError(async () => {
        if (!values || !isFormComplete) {
          throw new Error('No data');
        }
        return withOnSuccess(
          withSubmitting(createCompany.act),
          onSubmit,
        )({
          companyName: values.companyName.trim(),
          companyWebsite: values.companyWebsite.trim(),
          companyEmail: values.emailConfirmation.email!,
          emailToken: values.emailConfirmation.auth0Token,
        });
      }),
    [createCompany.act, isFormComplete, onSubmit, values, withDefaultError, withSubmitting],
  );

  const submit = useMemo<OnboardingContainerProps['submit']>(
    () => ({
      disabled: !isFormComplete,
      onAct: doSubmit,
      title: <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_STEPS_COMPANY_SUBMIT_TITLE} />,
    }),
    [isFormComplete, doSubmit],
  );

  const initialValues = useMemo<DeepPartial<CompanyForm>>(
    () => ({ emailConfirmation: { email: userEmail } }),
    [userEmail],
  );
  const onValuesChange = useCallback<NonNullable<FormProps<CompanyForm>['onValuesChange']>>(
    (_, newValues) => setValues(newValues),
    [setValues],
  );

  return (
    <OnboardingContainer
      data-test={dataTest && `${dataTest}-container`}
      selected={OnboardingStep.company}
      submit={submit}
    >
      <Form<CompanyForm>
        initialValues={initialValues}
        form={form}
        size="large"
        layout="vertical"
        onValuesChange={onValuesChange}
      >
        <CompanyNameItem<CompanyForm>
          name={nameof<CompanyForm>('companyName')}
          data-test={dataTest && `${dataTest}-name`}
          messages={companyNameMessages}
          readonly={submitting}
          required
        />
        <CompanyWebsiteItem<CompanyForm>
          name={nameof<CompanyForm>('companyWebsite')}
          data-test={dataTest && `${dataTest}-url`}
          messages={companyWebSiteMessages}
          readonly={submitting}
          required
        />
        <EmailConfirmationItem<CompanyForm>
          name={nameof<CompanyForm>('emailConfirmation')}
          data-test={dataTest && `${dataTest}-email`}
          passThroughEmail={userEmail}
          disabled={submitting}
          ItemProps={emailConfirmationProps}
        />
        <FormCompletenessItem<CompanyForm> requiredFields={requiredFields} onChange={setFormComplete} />
      </Form>
    </OnboardingContainer>
  );
};

export default CompanyStep;
