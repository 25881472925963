import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum PaymentsPageRoutes {
  ALL = 'all',
  PAYMENT_NEW = 'new',
  PAYMENT_VIEW = 'view/:paymentId',
  PAYMENT_TRANSACTION_VIEW = 'view/:paymentId/transactions/:transactionId',
}

export const paymentsRoute = (page: PaymentsPageRoutes) => `${appRoute(AppPageRoutes.PAYMENTS)}/${page}`;

export const paymentsLink = () => paymentsRoute(PaymentsPageRoutes.ALL);

export const paymentAddLink = () => paymentsRoute(PaymentsPageRoutes.PAYMENT_NEW);
export const paymentViewLink = (paymentId: string) => `${appRoute(AppPageRoutes.PAYMENTS)}/view/${paymentId}`;
export const paymentTransactionViewLink = (paymentId: string, txId: string) =>
  `${appRoute(AppPageRoutes.PAYMENTS)}/view/${paymentId}/transactions/${txId}`;
