import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCompanies } from '@/features/company/actions';
import { makeSelectActiveCompany } from '@/features/company/selectors';
import type { Company, CompanyWithOwner } from '@/features/company/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Company[]>, Parameters<typeof fetchCompanies>[0]> =>
  fetchCompanies({ force });
const selectFetching = makeSelectPending(fetchCompanies);
const selectData = makeSelectActiveCompany();

export default function useActiveCompany() {
  return useAppLoadableData<CompanyWithOwner, LoadingStateWithDirty<Company[]>>(fetch, selectData, selectFetching);
}
