import { FormattedMessage } from '@/components';
import { OperationRefreshReport } from '@/features/reports/components';
import { useReport } from '@/features/reports/hooks';
import type { Report } from '@/features/reports/types';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withReportDataLoading =
  <T extends { data: Report } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { reportId: string }> =>
  (props) => {
    const data = useReport(props.reportId);
    const dataTest: string | undefined = props['data-test'];
    return withCardDataLoading<Report, T>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageReports.REPORT_VIEW_COMPONENTS_REPORT_CARD_TITLE}
          values={{ id: data.data.data?.filename || props.reportId }}
        />
      ),
      CardProps: {
        extra: <OperationRefreshReport reportId={props.reportId} data-test={dataTest && `${dataTest}-opRefresh`} />,
      },
    })(Component)(props);
  };

export default withReportDataLoading;
