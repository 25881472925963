import React from 'react';

import { AddressLink } from '@/features/dictionary/blockchain/components';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';
import GasWalletLink from '@/pages/settings/company/gas-wallet/components/GasWalletLink';

import type { GasWalletAddressLinkProps } from './types';

const GasWalletAddressLink: React.FC<GasWalletAddressLinkProps> = ({ 'data-test': dataTest, address, bt }) => {
  const { data } = useMerchantGasWallet(bt);
  return data.data?.derived.find((dw) => dw.address === address) ? (
    <GasWalletLink data-test={dataTest} value={bt} mode="text" title={address} />
  ) : (
    <AddressLink data-test={dataTest} address={address} bt={bt} />
  );
};

const GasWalletAddressLinkMemo = React.memo(GasWalletAddressLink);

export default GasWalletAddressLinkMemo;
