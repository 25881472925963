import Icon from '@ant-design/icons';
import React from 'react';

import EmailIcon from '@/assets/icons/email.svg?react';
import GoogleIcon from '@/assets/icons/google.svg?react';
import CoinbaseIcon from '@/assets/icons/providers/coinbase.svg?react';
import MetamaskIcon from '@/assets/icons/providers/metamask.svg?react';
import WalletConnectIcon from '@/assets/icons/providers/walletconnect.svg?react';
import { BrandingAuth } from '@/features/branding/types';

import type { BrandingAuthIconProps } from './types';

const mapping: Record<BrandingAuth, typeof EmailIcon> = {
  [BrandingAuth.google]: GoogleIcon,
  [BrandingAuth.email]: EmailIcon,
  [BrandingAuth.coinbase]: CoinbaseIcon,
  [BrandingAuth.metamask]: MetamaskIcon,
  [BrandingAuth['wallet-connect']]: WalletConnectIcon,
};

const BrandingAuthIcon: React.FC<BrandingAuthIconProps> = ({ 'data-test': dataTest, value, className, style }) => (
  <Icon component={mapping[value]} className={className} style={style} data-test={dataTest} />
);

const BrandingAuthIconMemo = React.memo(BrandingAuthIcon);

export default BrandingAuthIconMemo;
