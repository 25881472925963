import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { donationTransactionViewLink } from '@/pages/donations/routes';

import type { DonationTransactionLinkProps } from './types';

const DonationTransactionLink: React.FC<DonationTransactionLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={donationTransactionViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageDonations.TRANSACTION_VIEW_COMPONENTS_LINK} />}
    icon={icon}
  />
);

const DonationTransactionLinkMemo = React.memo(DonationTransactionLink);

export default DonationTransactionLinkMemo;
