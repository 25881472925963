import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum GasHistoryPageRoutes {
  ALL = 'all',
  HISTORY_ROW_VIEW = 'view/:id',
}

export const gasHistoryRoute = (page: GasHistoryPageRoutes) => `${appRoute(AppPageRoutes.GAS_HISTORY)}/${page}`;

export const gasHistoryLink = () => `${appRoute(AppPageRoutes.GAS_HISTORY)}/${GasHistoryPageRoutes.ALL}`;
export const gasHistoryViewLink = (id: string) => `${appRoute(AppPageRoutes.GAS_HISTORY)}/view/${id}`;
