import { notification } from 'antd';

import { namedHOC } from '@/infrastructure/utils/react';

import { Notifications } from '../../components';

import NotificationContext from './context';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const withGlobalNotifications = <P extends PropsWithChildren>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithGlobalNotifications',
  )((props) => {
    const { children } = props;
    const [api, contextHolder] = notification.useNotification();
    return (
      <NotificationContext.Provider value={api}>
        {contextHolder}
        <Component {...props}>
          <Notifications />
          {children}
        </Component>
      </NotificationContext.Provider>
    );
  });

export default withGlobalNotifications;
