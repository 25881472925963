import { Space } from 'antd';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import {
  OperationDeletePayout,
  OperationRefreshPayout,
  OperationStartPayout,
  OperationUpdatePayoutTitle,
  PayoutsDocumentationLink,
} from '@/features/payouts/components';
import { usePayout } from '@/features/payouts/hooks';
import type { PayoutSummary } from '@/features/payouts/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';
import PayoutAddLink from '@/pages/settlements/payouts/payout-add/components/PayoutAddLink';
import PayoutEditLink from '@/pages/settlements/payouts/payout-edit/components/PayoutEditLink';
import { settlementsPayoutsLink } from '@/pages/settlements/routes';

import type React from 'react';

const withPayoutDataLoading = <
  Original extends { data: PayoutSummary } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { payoutId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithPayoutDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = usePayout(props.payoutId);
    const navigate = useNavigate();
    const redirectOnDelete = useCallback(() => navigate(settlementsPayoutsLink()), [navigate]);
    return withCardDataLoading<PayoutSummary, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageSettlements.PAYOUT_VIEW_COMPONENTS_PAYOUT_CARD_TITLE}
          values={{ id: props.payoutId }}
        />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <PayoutsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationStartPayout
              payoutId={props.payoutId}
              data-test={dataTest && `${dataTest}-startOp`}
              mode="inline"
            />
            <PayoutEditLink data-test={dataTest && `${dataTest}-editLink`} value={props.payoutId} mode="inline" />
            <PayoutAddLink data-test={dataTest && `${dataTest}-copy`} mode="inline" copyOf={props.payoutId} />
            <OperationUpdatePayoutTitle
              payoutId={props.payoutId}
              data-test={dataTest && `${dataTest}-updateTitleOp`}
              mode="inline"
            />
            <OperationDeletePayout
              payoutId={props.payoutId}
              data-test={dataTest && `${dataTest}-deleteOp`}
              onSuccess={redirectOnDelete}
              mode="inline"
            />
            <OperationRefreshPayout data-test={dataTest && `${dataTest}-opRefresh`} payoutId={props.payoutId} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withPayoutDataLoading;
