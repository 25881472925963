import { FormOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { reportScheduleEditLink } from '@/pages/reports/routes';

import type { ReportScheduleEditLinkProps } from './types';

const ReportScheduleEditLink: React.FC<ReportScheduleEditLinkProps> = ({
  'data-test': dataTest,
  value,
  title,
  ...props
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useMemo(() => (value ? () => openForm(reportScheduleEditLink(value)) : noop), [openForm, value]);
  return (
    <Operation
      data-test={dataTest}
      {...props}
      onClick={doOpen}
      icon={<FormOutlined />}
      title={title ?? <FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_EDIT_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const ReportScheduleEditLinkMemo = React.memo(ReportScheduleEditLink);

export default ReportScheduleEditLinkMemo;
