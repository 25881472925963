import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { createRechargeAddress } from '@/features/recharges/actions';
import type { NewRechargeAddress, RechargeAddress } from '@/features/recharges/types';
import { type HookAction } from '@/infrastructure/model';

import useRechargeAddressList from './useRechargeAddressList';

export interface UseRechargeAddressesActions {
  create: HookAction<[NewRechargeAddress], RechargeAddress>;
}

export default function useRechargeAddressesActions(): UseRechargeAddressesActions {
  const { loading } = useRechargeAddressList();
  const { withExtractDataDispatch } = useAppDispatch();
  const isCreating = useActionPending(createRechargeAddress);
  const createAction: UseRechargeAddressesActions['create']['act'] = useMemo(
    () => withExtractDataDispatch(createRechargeAddress),
    [withExtractDataDispatch],
  );
  const createHook: UseRechargeAddressesActions['create'] = {
    act: createAction,
    inAction: isCreating,
    available: !loading,
  };

  return { create: createHook };
}
