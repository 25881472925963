import { Navigate, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/app/hooks';
import { makeSelectAuthStatus } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { prepareRedirectURLParam, REDIRECT_QUERY_PARAM } from '@/infrastructure/utils/http';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const selectAuthStatus = makeSelectAuthStatus();

const withAuthorizedGuard =
  (authRoute: string) =>
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
    namedHOC(
      Component,
      'WithAuthorizedGuard',
    )((props) => {
      const authStatus = useAppSelector(selectAuthStatus);
      const { pathname } = useLocation();
      if (authStatus === AuthStatus.UNAUTHORIZED && !pathname.startsWith(authRoute)) {
        return (
          <Navigate to={{ pathname: authRoute, search: `?${REDIRECT_QUERY_PARAM}=${prepareRedirectURLParam()}` }} />
        );
      }

      return <Component {...props} />;
    });

export default withAuthorizedGuard;
