import { ProLayout } from '@ant-design/pro-components';
import { css } from '@emotion/css';

import Logo from '@/assets/icons/DefaultLogo';
import type { TestableProps } from '@/infrastructure/utils/react';
import { layoutBgToken } from '@/layouts/theme';

import type React from 'react';
import type { PropsWithChildren } from 'react';

export type BlankLayoutProps = PropsWithChildren<TestableProps>;

const BlankLayout: React.FC<BlankLayoutProps> = ({ children }) => (
  <ProLayout
    layout="top"
    disableMobile
    title="SMARTy Pay"
    logo={<Logo width={24} />}
    token={layoutBgToken}
    className={css`
      height: 100vh;
    `}
  >
    {children}
  </ProLayout>
);

export default BlankLayout;
