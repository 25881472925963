import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import GasHistoryResultView from '@/features/gas-history/components/GasHistoryResultView';
import { TransactionResultAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureGasHistory } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { GasHistoryResultFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const GasHistoryDirectionFilterItemRaw = <Values extends Store = Store>({
  name,
  'data-test': dataTest,
  readonly,
  ItemProps = {},
}: GasHistoryResultFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureGasHistory.COMPONENTS_FILTER_FORM_RESULT_ITEM_LABEL} />}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      disabled={readonly}
      placeholder={
        !readonly ? (
          <FormattedMessage id={I18nFeatureGasHistory.COMPONENTS_FILTER_FORM_RESULT_ITEM_PLACEHOLDER} />
        ) : undefined
      }
      options={useMemo(
        () =>
          enumValues(TransactionResultAPIModel).map((code) => ({
            value: code,
            label: <GasHistoryResultView value={code} mode="full" data-test={dataTest && `${dataTest}-opt-${code}`} />,
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const GasHistoryDirectionFilterItem = React.memo(
  GasHistoryDirectionFilterItemRaw,
) as typeof GasHistoryDirectionFilterItemRaw;

export default GasHistoryDirectionFilterItem;
