import { Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { QRSupportList } from '@/features/company-settings/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import { withQRSupportDataLoading } from './hocs';

import type { QRSupportCardProps } from './types';

const { Paragraph } = Typography;

const QRSupportCard: React.FC<QRSupportCardProps> = ({ data, 'data-test': dataTest }) => (
  <>
    <Paragraph data-test={dataTest && `${dataTest}-description`}>
      <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_QR_SUPPORT_CARD_DESCRIPTION} />
    </Paragraph>
    <QRSupportList data-test={dataTest} settings={data.selected} assets={data.assets} />
  </>
);

const QRSupportCardLoaded = withQRSupportDataLoading(QRSupportCard);

const QRSupportCardMemo = React.memo(QRSupportCardLoaded) as typeof QRSupportCardLoaded;

export default QRSupportCardMemo;
