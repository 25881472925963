import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { useBrandingProfile } from '@/features/branding/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import BrandingProfilesBreadcrumb from '@/pages/settings/branding/profiles/components/BrandingProfilesBreadcrumb';

import BrandingProfileEditLink from '../BrandingProfileEditLink';

import type { BrandingProfileEditBreadcrumbProps } from './types';

const BrandingProfileEditBreadcrumb: React.FC<BrandingProfileEditBreadcrumbProps> = ({
  'data-test': dataTest,
  profileId,
  disabled,
  items = [],
}) => {
  const {
    data: { data: profile },
  } = useBrandingProfile(profileId);
  return (
    <BrandingProfilesBreadcrumb
      data-test={dataTest}
      items={[
        {
          title: disabled ? (
            <FormattedMessageWithMarkup
              id={I18nPageSettings.BRANDING_PROFILE_EDIT_COMPONENTS_BREADCRUMB_TITLE}
              values={{ name: profile?.name ?? profileId }}
            />
          ) : (
            <BrandingProfileEditLink
              value={profileId}
              title={
                <FormattedMessageWithMarkup
                  id={I18nPageSettings.BRANDING_PROFILE_EDIT_COMPONENTS_BREADCRUMB_TITLE}
                  values={{ name: profile?.name ?? profileId }}
                />
              }
              mode="link"
            />
          ),
        },
        ...items,
      ]}
    />
  );
};

const BrandingProfileEditBreadcrumbMemo = React.memo(BrandingProfileEditBreadcrumb);

export default BrandingProfileEditBreadcrumbMemo;
