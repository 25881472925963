import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { donationAssetViewLink } from '@/pages/donations/routes';

import type { DonationAssetViewLinkProps } from './types';

const DonationAssetViewLink: React.FC<DonationAssetViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={donationAssetViewLink(value.donationId, value.asset)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageDonations.DONATION_ASSET_VIEW_COMPONENTS_LINK} />}
    icon={icon}
  />
);

const DonationAssetViewLinkMemo = React.memo(DonationAssetViewLink);

export default DonationAssetViewLinkMemo;
