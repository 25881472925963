import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { logout, notifyAuthTokenUpdated, relogin } from '@/features/auth/actions';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';

import {
  fetchCompanies,
  markCompaniesDirty,
  markCompanyUsersDirty,
  storeCompanies,
  storeCompanyUsers,
} from './actions';
import { type CompanyState } from './types';

import type { Draft } from 'immer';

const initialState: CompanyState = {
  users: storedDirtyData,
  companies: storedDirtyData,
};

const { storeCompaniesReducer, markCompaniesDirtyReducer } = createLoadingDataReducers(
  'Companies',
  (state: Draft<CompanyState>) => state.companies,
  (state, companies) => ({ ...state, companies }),
);

const { storeCompanyUsersReducer, markCompanyUsersDirtyReducer } = createLoadingDataReducers(
  'CompanyUsers',
  (state: Draft<CompanyState>) => state.users,
  (state, users) => ({ ...state, users }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markCompaniesDirty, markCompaniesDirtyReducer)
    .addCase(storeCompanyUsers, storeCompanyUsersReducer)

    .addCase(markCompanyUsersDirty, markCompanyUsersDirtyReducer)
    .addCase(storeCompanies, storeCompaniesReducer)

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );

  listenerMiddleware.startListening({
    actionCreator: notifyAuthTokenUpdated,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    effect: async ({ payload: { current } }, { dispatch, getState }) => {
      if (!current) {
        return;
      }
      const loginInfo = makeSelectAuthToken()(getState());
      const companies = await dispatch(fetchCompanies({})).unwrap();
      const tokenActiveCompanyId = loginInfo.data?.info.activeCompanyId;
      if (companies.data?.length) {
        if (!tokenActiveCompanyId || !companies.data.map(({ id }) => id).includes(tokenActiveCompanyId)) {
          if (companies.data[0]) {
            await dispatch(relogin({ companyId: companies.data[0].id }));
          }
        }
      } else if (tokenActiveCompanyId) {
        // FIXME: it's not the best check, but it caused by reloading after login necessity
        if (companies.data) {
          await dispatch(logout({}));
        }
      }
    },
  });
});

export default reducer;
