import { css, cx } from '@emotion/css';
import Markdown from 'marked-react';
import React from 'react';

import type { BrandingToSPreviewProps } from './types';

import 'github-markdown-css/github-markdown-light.css';

const BrandingToSPreview: React.FC<BrandingToSPreviewProps> = ({ 'data-test': dataTest, value }) => (
  <div
    className={cx(
      css`
        height: 100%;
        border: 1px solid lightgray;
        padding: 5px;
        overflow-y: auto;
        background: #eee !important;
      `,
      'markdown-body',
    )}
  >
    <Markdown data-test={dataTest} value={value || ''} renderer={{}} />
  </div>
);

const BrandingToSPreviewMemo = React.memo(BrandingToSPreview);

export default BrandingToSPreviewMemo;
