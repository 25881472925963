import { LockOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Row, Tooltip } from 'antd';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import DataBlockContainer from '../DataBlockContainer';

import { BrandingAuthItem, SupportedQRItem } from './components';

import type { AuthBlockProps } from './types';
import type React from 'react';

const AuthBlock: React.FC<AuthBlockProps> = ({ 'data-test': dataTest, disabled }) => (
  <DataBlockContainer
    title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_TITLE} />}
    icon={<LockOutlined />}
    help={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_HELP} />}
  >
    <Row>
      <Col span={24}>
        <BrandingAuthItem data-test={dataTest && `${dataTest}-select`} disabled={disabled} />
      </Col>
      <Col span={24} style={{ display: 'none' }}>
        <Row>
          <Col span={24}>
            <FormattedMessage
              id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_SUPPORTED_QR_LABEL}
              values={{
                help: (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_SUPPORTED_QR_HELP}
                      />
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                ),
              }}
            />
          </Col>
          <Col span={24}>
            <SupportedQRItem data-test={dataTest && `${dataTest}-qr`} />
          </Col>
        </Row>
      </Col>
    </Row>
  </DataBlockContainer>
);

export default AuthBlock;
