import { Alert, Tag, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage, SupportEmail } from '@/components';
import type { SettlementReconciliationStatusBannerProps } from '@/features/settlement-reconciliations/components/SettlementReconciliationBanner/types';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';

const SettlementReconciliationActiveBanner: React.FC<SettlementReconciliationStatusBannerProps> = ({
  'data-test': dataTest,
  settlementId,
  companyId,
  mode,
  style,
  className,
  SettlementReconciliationLink,
}) =>
  mode === 'tag' ? (
    <Tooltip
      title={
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_ACTIVE_DESCRIPTION}
          values={{
            email: (text: React.ReactNode) => (
              <SupportEmail title={text} subject={`[${companyId}]: Reconciliation ${settlementId}`} />
            ),
            ln:
              SettlementReconciliationLink
              && ((title: React.ReactNode) => (
                <SettlementReconciliationLink value={settlementId} title={title} mode="text" />
              )),
          }}
        />
      }
    >
      <Tag data-test={dataTest} color="success" className={className} style={{ ...style, marginRight: 0 }}>
        <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_ACTIVE_TAG} />
      </Tag>
    </Tooltip>
  ) : (
    <Alert
      data-test={dataTest}
      className={className}
      style={style}
      message={
        <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_ACTIVE_TITLE} />
      }
      description={
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_ACTIVE_DESCRIPTION}
          values={{
            email: (text: React.ReactNode) => (
              <SupportEmail title={text} subject={`[${companyId}]: Reconciliation ${settlementId}`} />
            ),
            ln:
              SettlementReconciliationLink
              && ((title: React.ReactNode) => (
                <SettlementReconciliationLink value={settlementId} title={title} mode="text" />
              )),
          }}
        />
      }
      type="info"
      showIcon
    />
  );

const SettlementReconciliationActiveBannerMemo = React.memo(SettlementReconciliationActiveBanner);

export default SettlementReconciliationActiveBannerMemo;
