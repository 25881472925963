import type { AppRootState } from '@/app/store';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';

import { NAMESPACE } from './types';

export const { makeSelectBalances } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].balances,
  'Balances',
);

export const { makeSelectSubscriptionsSummaryStats } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].subscriptions,
  'SubscriptionsSummaryStats',
);

export const { makeSelectPaymentsSummaryStats } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].payments,
  'PaymentsSummaryStats',
);
