import { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import type { BrandingProfileFormValues } from '@/features/branding/components';
import { useBrandingProfileActions } from '@/features/branding/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withPageParams } from '@/hocs';
import { requiredParamsParser } from '@/hocs/withPageParams/utils';
import { useDefaultNotification, useFormPageAction, useNotification, useStateMountSafe } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';
import { settingsBrandingProfilesLink } from '@/pages/settings/routes';

import { BrandingProfileEditBreadcrumb, BrandingProfileEditCard } from './components';

import type React from 'react';

interface BrandingProfileEditPageProps extends TestableProps {
  pageParams: { id: string };
}

const BrandingProfileEditPage: React.FC<BrandingProfileEditPageProps> = ({
  'data-test': dataTest = 'profile-edit',
  pageParams: { id },
}) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { update: updateAction } = useBrandingProfileActions(id);
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(settingsBrandingProfilesLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  const doSave = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(
            ({ name, ...data }: BrandingProfileFormValues) => updateAction.act({ name, data }),
            () => <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_EDIT_MESSAGES_SUCCESS} />,
          ),
          () => <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_EDIT_MESSAGES_ERROR} />,
        ),
        doRedirect,
      ),
    [withDefaultError, withSuccess, doRedirect, updateAction],
  );
  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  return (
    <SettingsPageLayout
      data-test="container"
      selected={SettingsTabType.branding}
      breadcrumb={
        <BrandingProfileEditBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled profileId={id} />
      }
      submit={submit}
      cancel={doRedirect}
    >
      <BrandingProfileEditCard
        data-test={dataTest}
        profileId={id}
        onSave={doSave}
        onCancel={doRedirect}
        submitCallback={updateOnSubmit}
      />
    </SettingsPageLayout>
  );
};

export default withPageParams(requiredParamsParser(['id']))(BrandingProfileEditPage);
