import { useContext, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { PageLoading } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { makeSelectAuthStatus } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { useSingleRun } from '@/hooks';
import { withOnError, withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthStatus = makeSelectAuthStatus();

const withWeb3AuthDeviceKeyMemorize = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthDeviceKeyMemorize',
  )((props) => {
    const authState = useAppSelector(selectAuthStatus);
    const ctx = useContext(Web3AuthContext);
    const { withSingleRun, inProgress } = useSingleRun(true);

    const doSaveDeviceKey = useMemo(
      () =>
        withSuppressPromise(
          withSingleRun(
            withOnError(
              async () => {
                if (ctx.web3Auth && !ctx.web3Auth.isOutOfSync) {
                  await ctx.web3Auth.deviceKey.generate();
                  await ctx.web3Auth.sync();
                }
              },
              async (e) => {
                console.warn(e);
                if (ctx.web3Auth) {
                  await ctx.web3Auth.logout();
                }
              },
            ),
          ),
        ),
      [ctx.web3Auth, withSingleRun],
    );

    if (
      inProgress
      || (ctx.initialized
        && ctx.web3Auth
        && ctx.web3Auth.isInitialized
        && ctx.web3Auth.deviceKey.isDefined
        && !ctx.web3Auth.deviceKey.isFound)
    ) {
      if (authState === AuthStatus.AUTHORIZED) {
        doSaveDeviceKey();
      }
      return (
        <MessageLayout>
          <PageLoading type="withWeb3AuthDeviceKeyMemorize" />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withWeb3AuthDeviceKeyMemorize;
