import { Col, Row, Skeleton } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { useToS, useToSActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';

import { BrandingToSEditForm, BrandingToSPreview } from './components';

import type { BrandingToSFormProps } from './types';

const BrandingToSForm: React.FC<BrandingToSFormProps> = ({
  'data-test': dataTest,
  mode,
  submitCallback,
  onSubmitting,
}) => {
  const { data, loading } = useToS();
  const { define: defineAction } = useToSActions();
  const { withSuccess } = useNotification();

  const doDefine = useMemo(
    () =>
      withSuccess(defineAction.act, () => (
        <FormattedMessageWithMarkup id={I18nFeatureBranding.COMPONENTS_TOS_FORM_DEFINE_SUCCESS_MESSAGE} />
      )),
    [withSuccess, defineAction.act],
  );

  const height = useMemo(() => Math.max(window.innerHeight - 400, 450), []);

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} style={{ height }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!data.data && (data.isDirty || loading) ? (
          mode === 'edit' ? (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Skeleton.Input active />
              </Col>
              <Col span={12}>
                <Skeleton active title={false} />
              </Col>
              <Col span={12}>
                <Skeleton active title={false} />
              </Col>
            </Row>
          ) : (
            <Skeleton active title={false} />
          )
        ) : mode === 'edit' ? (
          <BrandingToSEditForm
            data-test={dataTest && `${dataTest}-edit`}
            value={data.data}
            onSubmit={doDefine}
            submitCallback={submitCallback}
            onSubmitting={onSubmitting}
            containerHeight={height}
          />
        ) : (
          <BrandingToSPreview data-test={dataTest && `${dataTest}-preview`} value={data.data} />
        )}
      </Col>
    </Row>
  );
};

const BrandingToSFormMemo = React.memo(BrandingToSForm);

export default BrandingToSFormMemo;
