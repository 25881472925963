import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useReportScheduleActions } from '@/features/report-schedules/hooks';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';

import { DeactivateReasonForm } from './components';

import type { OperationChangeReportScheduleStatusProps } from './types';

const options = {
  error: () => <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_ERROR_MESSAGE} />,
  success: () => (
    <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_SUCCESS_MESSAGE} />
  ),
};

const OperationChangeReportScheduleStatusRaw: React.FC<OperationChangeReportScheduleStatusProps> = ({
  'data-test': dataTest,
  scheduleId,
  mode,
}) => {
  const { activate: activateAction, deactivate: deactivateAction } = useReportScheduleActions(scheduleId);
  const deactivateFormControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const activate = useMemo(() => withNotification(activateAction.act, options), [activateAction, withNotification]);
  const deactivate = useMemo(
    () => withNotification(deactivateFormControl.withFormHide(deactivateAction.act), options),
    [withNotification, deactivateFormControl, deactivateAction],
  );

  return (
    <>
      {deactivateFormControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={
            <FormattedMessage
              id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_TITLE}
            />
          }
          closable
          onCancel={deactivateFormControl.hide}
          width={600}
        >
          <DeactivateReasonForm
            data-test={dataTest && `${dataTest}-deactivateReasonForm`}
            onReset={deactivateFormControl.hide}
            onSubmit={deactivate}
          />
        </Modal>
      )}
      {(deactivateAction.available || deactivateAction.unavailabilityReason !== 'invalid-status') && (
        <Operation
          data-test={dataTest && `${dataTest}-deactivate`}
          title={
            <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_TITLE} />
          }
          mode={mode}
          icon={<PauseCircleOutlined style={{ color: 'orange' }} />}
          onClick={deactivateFormControl.show}
        />
      )}
      {(activateAction.available || activateAction.unavailabilityReason !== 'invalid-status') && (
        <Operation
          data-test={dataTest && `${dataTest}-activate`}
          title={<FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE_TITLE} />}
          mode={mode}
          icon={<PlayCircleOutlined style={{ color: 'green' }} />}
          onClick={activate}
        />
      )}
    </>
  );
};

const OperationChangeReportScheduleStatus = React.memo(OperationChangeReportScheduleStatusRaw);

export default OperationChangeReportScheduleStatus;
