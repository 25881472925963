import { PageContainer } from '@/components';
import { PaymentsDocumentationBanner } from '@/features/payments/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { PaymentBreadcrumb, PaymentCard, PaymentTransactionsCard } from './components';
import { withPaymentPageParams } from './hocs';

import type React from 'react';

type PaymentViewPageProps = TestableProps & { pageParams: { paymentId: string } };

const PaymentViewPage: React.FC<PaymentViewPageProps> = ({
  'data-test': dataTest = 'payment',
  pageParams: { paymentId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<PaymentBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} paymentId={paymentId} disabled />}
  >
    <PaymentsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <PaymentCard data-test={dataTest && `${dataTest}-card`} paymentId={paymentId} />
    <PaymentTransactionsCard data-test={dataTest && `${dataTest}-transactions`} paymentId={paymentId} />
  </PageContainer>
);

export default withPaymentPageParams(PaymentViewPage);
