import { useCallback, useMemo } from 'react';

import { noop } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

import usePreferences from './usePreferences';
import usePreferencesActions from './usePreferencesActions';

export interface UseDocumentationViewedType {
  viewed: boolean;
  markViewed: Func;
}

export default function useDocumentationViewed(type: string): UseDocumentationViewedType {
  const { data } = usePreferences();
  const { persist } = usePreferencesActions();
  const markViewed = useCallback(
    (): unknown =>
      persist.available ? persist.act({ ...data.data, helpViewed: [...(data.data?.helpViewed ?? []), type] }) : noop(),
    [data.data, persist, type],
  );
  return useMemo(
    () => ({
      viewed: (!data.data && data.isDirty) || (data.data?.helpViewed ?? []).includes(type),
      markViewed,
    }),
    [data.data, data.isDirty, markViewed, type],
  );
}
