import { Space } from 'antd';
import React from 'react';

import {
  OperationChangeReportScheduleStatus,
  OperationDeleteReportSchedule,
} from '@/features/report-schedules/components';
import ReportScheduleEditLink from '@/pages/reports/report-schedule-edit/components/ReportScheduleEditLink';

import type { ReportSchedulesTableOperationsProps } from './types';

const ReportSchedulesTableOperationsRaw: React.FC<ReportSchedulesTableOperationsProps> = ({
  'data-test': dataTest,
  value: { id },
}) => (
  <Space>
    <OperationChangeReportScheduleStatus scheduleId={id} data-test={dataTest && `${dataTest}-status`} />
    <ReportScheduleEditLink value={id} data-test={dataTest && `${dataTest}-edit`} />
    <OperationDeleteReportSchedule scheduleId={id} data-test={dataTest && `${dataTest}-delete`} confirmation />
  </Space>
);

const ReportSchedulesTableOperations = React.memo(ReportSchedulesTableOperationsRaw);

export default ReportSchedulesTableOperations;
