import { Descriptions, Typography } from 'antd';
import React, { useEffect } from 'react';

import { FormattedMessage, ReadonlyComponent } from '@/components';
import { AssetAmount, AddressLink } from '@/features/dictionary/blockchain/components';
import { useAsset, useBlockchain } from '@/features/dictionary/blockchain/hooks';
import { usePaymentActions } from '@/features/payments/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useLocaleSettings, useStateMountSafe } from '@/hooks';

import PaymentErrorView from '../PaymentErrorView';
import PaymentStatusView from '../PaymentStatusView';

import type { PaymentViewProps } from './types';

const { Link } = Typography;

const PaymentViewRaw: React.FC<PaymentViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
}) => {
  const { formatDateTime } = useLocaleSettings();
  const asset = useAsset(data.asset);
  const bt = asset.data.data?.blockchain;
  const bc = useBlockchain(bt);
  const { generatePaymentURL } = usePaymentActions(data.id);
  const [paymentLink, setPaymentLink] = useStateMountSafe<string>();
  useEffect(() => {
    void (async () => {
      if (generatePaymentURL.available && !paymentLink) {
        const link = await generatePaymentURL.act();
        setPaymentLink(link);
      }
    })();
  }, [generatePaymentURL, paymentLink, setPaymentLink]);
  return (
    <Descriptions
      style={style}
      className={className}
      contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
      title={<PaymentStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
      column={columns}
    >
      {paymentLink && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_PAYMENT_LINK} />}>
          <Link
            href={paymentLink}
            copyable={{ text: paymentLink }}
            target="_blank"
            data-test={dataTest && `${dataTest}-link`}
            data-ym-target="payment-widget-link"
          >
            <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_LINK_TITLE} />
          </Link>
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_CREATED_AT} />}>
        <ReadonlyComponent value={formatDateTime(data.createdAt)} data-test={dataTest && `${dataTest}-createdAt`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_EXPIRES_AT} />}>
        <ReadonlyComponent value={formatDateTime(data.expiresAt)} data-test={dataTest && `${dataTest}-expiresAt`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_AMOUNT} />}>
        <AssetAmount
          value={data.amount}
          assetId={data.asset}
          withCurrency
          currencyMode="long"
          data-test={dataTest && `${dataTest}-amount`}
        />
      </Descriptions.Item>
      {data.paidAmount && data.paidAsset && (
        <>
          <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_PAID_AMOUNT} />}>
            <AssetAmount
              value={data.paidAmount}
              assetId={data.paidAsset}
              withCurrency
              currencyMode="long"
              data-test={dataTest && `${dataTest}-paidAmount`}
            />
          </Descriptions.Item>
          <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_PAID_AT} />}>
            <ReadonlyComponent value={formatDateTime(data.paidAt)} data-test={dataTest && `${dataTest}-paidAt`} />
          </Descriptions.Item>
        </>
      )}
      {data.errorCode && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_ERROR} />} span={2}>
          <PaymentErrorView value={data.errorCode} data-test={dataTest && `${dataTest}-error`} />
        </Descriptions.Item>
      )}
      {data.description && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_DESCRIPTION} />}>
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 3, expandable: true }}
            className="ym-hide-content"
            data-test={dataTest && `${dataTest}-description`}
          >
            {data.description}
          </Typography.Paragraph>
        </Descriptions.Item>
      )}
      {data.metadata && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_METADATA} />}>
          <Typography.Paragraph
            style={{ marginBottom: 0 }}
            ellipsis={{ rows: 3, expandable: true }}
            className="ym-hide-content"
            data-test={dataTest && `${dataTest}-metadata`}
          >
            {typeof data.metadata === 'string' ? data.metadata : JSON.stringify(data.metadata)}
          </Typography.Paragraph>
        </Descriptions.Item>
      )}
      {!!bt && !!data.paymentAddress && bc.data.data?.apiType === BlockchainAPITypeAPIModel.BTC && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_PAYMENT_ADDRESS} />}>
          <AddressLink bt={bt} address={data.paymentAddress} data-test={dataTest && `${dataTest}-address`} />
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const PaymentView = React.memo(PaymentViewRaw);

export default PaymentView;
