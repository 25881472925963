import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { mapStoredState } from '@/infrastructure/model';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { NAMESPACE } from './types';

import type { BrandingProfileWithActive } from './types';

export const makeSelectBranding = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].branding,
    (branding) =>
      mapStoredState(branding, ({ profiles, activeProfile }): BrandingProfileWithActive[] =>
        profiles.map((profile) => ({ ...profile, active: activeProfile === profile.id })),
      ),
  );

export const { makeSelectMerchantToS } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].tos,
  'MerchantToS',
);
export const { makeSelectMerchantDomains } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].domains,
  'MerchantDomains',
);

export const { makeSelectBrandingProfile } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].profiles,
  'BrandingProfile',
  undefined,
);
