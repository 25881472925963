import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useSubscriptionChargesForSubscription } from '@/features/subscriptions/hooks';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import type { OperationRefreshSubscriptionChargesProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGES_TOOLTIP} />,
  error: {
    message: (
      <FormattedMessage
        id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGES_FAILED_MESSAGE}
      />
    ),
  },
  success: {
    message: (
      <FormattedMessage
        id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGES_SUCCESS_MESSAGE}
      />
    ),
  },
};

const OperationRefreshSubscriptionCharges: React.FC<OperationRefreshSubscriptionChargesProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  subscriptionId,
}) => {
  const { forceRefresh } = useSubscriptionChargesForSubscription(subscriptionId);
  return <OperationRefresh messages={messages} refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshSubscriptionChargesMemo = React.memo(OperationRefreshSubscriptionCharges);

export default OperationRefreshSubscriptionChargesMemo;
