import { Typography } from 'antd';
import React from 'react';

import type { KYBMailToProps } from './types';

const { Link: TypographyLink } = Typography;

const KYBMailTo: React.FC<KYBMailToProps> = ({ 'data-test': dataTest, title, companyId }) => (
  <TypographyLink
    data-test={dataTest}
    href={`mailto:${window.env.KYB_EMAIL}?subject=[KYB, ${companyId}]: Company Verification`}
  >
    {title || window.env.KYB_EMAIL}
  </TypographyLink>
);

const KYBMailToMemo = React.memo(KYBMailTo) as typeof KYBMailTo;

export default KYBMailToMemo;
