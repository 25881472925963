import { Navigate, Route, Routes } from 'react-router-dom';

import MessageLayout from '@/layouts/MessageLayout';
import { AppPageRoutes } from '@/pages/routes';

import EmailRenewPage from './email-renew';
import { callbackEmailRenewLink } from './routes';

import type React from 'react';

export const CallbackDefaultRoute: React.FC = () => <Navigate to={callbackEmailRenewLink()} replace />;

const CallbackPages: React.FC = () => (
  <MessageLayout>
    <Routes>
      <Route path="" Component={EmailRenewPage} />
      <Route path="*" Component={CallbackDefaultRoute} />
    </Routes>
  </MessageLayout>
);

export default <Route key={AppPageRoutes.CALLBACK} path={`${AppPageRoutes.CALLBACK}/*`} Component={CallbackPages} />;
