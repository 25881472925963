import React from 'react';

import { useActiveCompany } from '@/features/company/hooks';

import OperationSelectNetwork from '../OperationSelectNetwork';
import SelectedNetworkLabel from '../SelectedNetworkLabel';

import type { SelectNetworkProps } from './types';

const SelectNetwork: React.FC<SelectNetworkProps> = ({ 'data-test': dataTest, short }) => {
  // FIXME: this is a dirty hack, there should be no dependency from settings here
  const {
    data: { data: company },
  } = useActiveCompany();

  return (
    <SelectedNetworkLabel
      suffix={company && company.isKYBFinished && <OperationSelectNetwork mode="icon" />}
      data-test={dataTest}
      mode={short ? 'short' : 'medium'}
    />
  );
};

const SelectNetworkMemo = React.memo(SelectNetwork);

export default SelectNetworkMemo;
