import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { SettingsTabIcon, SettingsTabType } from '@/pages/settings/components';
import { settingsCompanySummaryLink } from '@/pages/settings/routes';

import type { CompanySettingsLinkProps } from './types';

const CompanySettingsLink: React.FC<CompanySettingsLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settingsCompanySummaryLink()}
    icon={icon ?? <SettingsTabIcon value={SettingsTabType.company} />}
    title={title ?? <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_LINK_TITLE} />}
  />
);

const CompanySettingsLinkMemo = React.memo(CompanySettingsLink);

export default CompanySettingsLinkMemo;
