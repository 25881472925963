import { type UseAppListData, useAppNestedListData } from '@/app/hooks';
import { noIdNestedListFetchResultFn } from '@/app/hooks/useAppNestedListData';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRechargeTransactionForAddress } from '@/features/recharges/actions';
import {
  makeSelectRechargeTransactionForAddressListData,
  makeSelectRechargeTransactionForAddressListParameters,
} from '@/features/recharges/selectors';
import type { RechargeTransaction, RechargeTransactionFilterPredicate } from '@/features/recharges/types';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseRechargeTransactionsForAddressList = UseAppListData<
  RechargeTransaction,
  RechargeTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

const fetchFactory =
  (addressId: string) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<
    LoadingListDataState<RechargeTransaction>,
    Parameters<typeof fetchRechargeTransactionForAddress>[0]
  > =>
    fetchRechargeTransactionForAddress({ addressId, force });

const pendingSelectorCreator = (addressId: string) => makeSelectPending(fetchRechargeTransactionForAddress, addressId);

export default function useRechargeTransactionsForAddressList(
  addressId: string | undefined,
): UseRechargeTransactionsForAddressList {
  return useAppNestedListData(
    fetchFactory,
    makeSelectRechargeTransactionForAddressListParameters,
    pendingSelectorCreator,
    makeSelectRechargeTransactionForAddressListData,
    noIdNestedListFetchResultFn(),
    addressId,
  );
}
