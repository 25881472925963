import { useAppLoadableData } from '@/app/hooks';
import { fetchFeatureToogle } from '@/features/feature-toggle/actions';
import { makeSelectFeatureToggle } from '@/features/feature-toggle/selectors';
import { makeSelectPending } from '@/features/global/selectors';

const selector = makeSelectFeatureToggle();
const pendingSelector = makeSelectPending(fetchFeatureToogle);
const fetchFactory = (force: boolean) => fetchFeatureToogle({ force });

const useFeatureToggle = () => useAppLoadableData(fetchFactory, selector, pendingSelector);

export default useFeatureToggle;
