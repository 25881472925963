import { Navigate } from 'react-router-dom';

import { useAppSelector } from '@/app/hooks';
import { makeSelectAuthStatus } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { prepareRedirectURLParamRoute } from '@/infrastructure/utils/http';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const selectAuthStatus = makeSelectAuthStatus();

const withUnAuthorizedGuard =
  (defaultRoute: string) =>
  <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
    namedHOC(
      Component,
      'WithUnAuthorizedGuard',
    )((props) => {
      const authStatus = useAppSelector(selectAuthStatus);
      if (authStatus === AuthStatus.AUTHORIZED) {
        return <Navigate to={prepareRedirectURLParamRoute() ?? defaultRoute} />;
      }

      return <Component {...props} />;
    });

export default withUnAuthorizedGuard;
