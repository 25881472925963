import { Space, Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { GasWalletView } from '@/features/gas-wallets/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import { withGasWalletDataLoading } from './hocs';

import type { GasWalletCardCardProps } from './types';

const { Paragraph } = Typography;

const GasWalletCard: React.FC<GasWalletCardCardProps> = ({ 'data-test': dataTest, data }) => (
  <Space direction="vertical">
    <Paragraph data-test={dataTest && `${dataTest}-description`}>
      <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLET_COMPONENTS_WALLET_CARD_DESCRIPTION} />
    </Paragraph>
    <GasWalletView data-test={dataTest} wallet={data} />
  </Space>
);

const GasWalletCardCardHOC = withGasWalletDataLoading(GasWalletCard);

const GasWalletCardCardMemo = React.memo(GasWalletCardCardHOC) as typeof GasWalletCardCardHOC;

export default GasWalletCardCardMemo;
