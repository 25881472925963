import isEmpty from 'lodash/isEmpty';
import isEqual from 'react-fast-compare';

import { defaultPage } from '@/infrastructure/api';
import { mapLoadingState } from '@/infrastructure/model';
import type { ListColumnState, ListSortBy } from '@/infrastructure/model/list/types';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type {
  FullDataSingleUpdateAction,
  FullDataUpdateAction,
  FullParametersUpdateAction,
  NormalizedFullDataMarkDirtyAction,
  NormalizedFullDataUpdateAction,
  NormalizedFullParametersUpdateAction,
} from './actions';
import type {
  FullNoColumnsState,
  FullParametersNoColumnsState,
  FullParametersState,
  FullState,
  UpdateFullDataParametersPayload,
} from './types';
import type { Draft } from 'immer';

export const fullMarkDirtyReducer =
  <
    Global extends EmptyObject,
    Value,
    Filter,
    SortBy extends string,
    Full extends FullNoColumnsState<Value, Filter, SortBy> = FullNoColumnsState<Value, Filter, SortBy>,
  >(
    get: (global: Global) => Full,
    set: (global: Global, newFullDataState: Full) => Global,
  ) =>
  (state: Global) => {
    const fullState = get(state);
    return !fullState.data.isDirty
      ? set(state, {
          ...get(state),
          data: { ...fullState.data, isDirty: true },
        })
      : state;
  };

export const fullStoreDataReducer =
  <
    Global extends EmptyObject,
    Value,
    Filter,
    SortBy extends string,
    Full extends FullState<Value, Filter, SortBy> = FullState<Value, Filter, SortBy>,
  >(
    get: (global: Draft<Global>) => Full,
    set: (global: Draft<Global>, newFullDataState: Full) => Draft<Global>,
  ) =>
  (state: Draft<Global>, { payload: data }: FullDataUpdateAction<Value>) =>
    set(state, { ...get(state), data: { isDirty: false, ...data } });

export const reduceFullParamStateUpdate = <Global, Filter, SortBy extends string>(
  state: Draft<Global>,
  fullParametersState: FullParametersState<Filter, SortBy>,
  payload: UpdateFullDataParametersPayload<Filter, SortBy>,
  defaultSortBy: ListSortBy<SortBy>,
  updater: (currentState: Draft<Global>, newParametersState: FullParametersState<Filter, SortBy>) => Draft<Global>,
) => {
  const hasPage = payload.page !== undefined;
  const hasFilter = payload.filter !== undefined;
  const hasColumnState = payload.columnState !== undefined;
  const hasSortBy = payload.sortBy !== undefined;

  const isPageDirty = hasPage && !isEqual(payload.page, fullParametersState.page);
  const isFilterDirty = hasFilter && !isEqual(payload.filter, fullParametersState.filter);
  const isColumnStateDirty = hasColumnState && !isEqual(payload.columnState, fullParametersState.columnState);
  const isSortByDirty = hasSortBy && !isEqual(payload.sortBy, fullParametersState.sortBy);

  const isDirty = isPageDirty || isFilterDirty || isColumnStateDirty || isSortByDirty;
  const page = hasPage ? (payload.page ?? defaultPage) : fullParametersState.page;
  return isDirty
    ? updater(state, {
        page: isFilterDirty ? { ...page, page: defaultPage.page } : page,
        filter: isFilterDirty ? payload.filter! : fullParametersState.filter,
        columnState: isColumnStateDirty ? (payload.columnState ?? {}) : fullParametersState.columnState,
        // eslint-disable-next-line no-nested-ternary
        sortBy: isSortByDirty
          ? payload.sortBy && !isEmpty(payload.sortBy)
            ? payload.sortBy
            : defaultSortBy
          : fullParametersState.sortBy,
      })
    : state;
};

export const fullStoreParametersReducer =
  <Global extends EmptyObject, Value, Filter, SortBy extends string>(
    get: (global: Draft<Global>) => FullState<Value, Filter, SortBy>,
    set: (global: Draft<Global>, newFullDataState: FullState<Value, Filter, SortBy>) => Draft<Global>,
    defaultSortBy: ListSortBy<SortBy>,
  ) =>
  (state: Draft<Global>, { payload }: FullParametersUpdateAction<Filter, SortBy>) =>
    reduceFullParamStateUpdate(state, get(state), payload, defaultSortBy, (currentState, newParametersState) =>
      set(currentState, { data: { ...get(currentState).data }, ...newParametersState }),
    );

export const reduceFullParamSplitColumnStateUpdate = <
  Global,
  Filter,
  SortBy extends string,
  Full extends FullParametersNoColumnsState<Filter, SortBy> = FullParametersNoColumnsState<Filter, SortBy>,
>(
  state: Draft<Global>,
  fullParametersState: Full,
  columnState: ListColumnState,
  payload: UpdateFullDataParametersPayload<Filter, SortBy>,
  defaultSortBy: ListSortBy<SortBy>,
  parametersUpdater: (currentState: Draft<Global>, newParametersState: Full) => Draft<Global>,
  columnsUpdater: (currentState: Draft<Global>, newColumnState: ListColumnState) => Draft<Global>,
) => {
  const hasFilter = payload.filter !== undefined;
  const hasColumnState = payload.columnState !== undefined;
  const hasSortBy = payload.sortBy !== undefined;

  const isFilterDirty = hasFilter && !isEqual(payload.filter, fullParametersState.filter);
  const isColumnStateDirty = hasColumnState && !isEqual(payload.columnState, columnState);
  const isSortByDirty = hasSortBy && !isEqual(payload.sortBy, fullParametersState.sortBy);

  const isParametersDirty = isFilterDirty || isSortByDirty;
  const parametersState = isParametersDirty
    ? parametersUpdater(state, {
        ...fullParametersState,
        filter: isFilterDirty ? payload.filter : fullParametersState.filter,
        // eslint-disable-next-line no-nested-ternary
        sortBy: isSortByDirty
          ? payload.sortBy && !isEmpty(payload.sortBy)
            ? payload.sortBy
            : defaultSortBy
          : fullParametersState.sortBy,
      })
    : state;

  return isColumnStateDirty && payload.columnState
    ? columnsUpdater(parametersState, payload.columnState)
    : parametersState;
};

export const createFullReducers = <
  Type extends string,
  Global extends EmptyObject,
  Value,
  Filter,
  SortBy extends string,
>(
  type: Type,
  get: (global: Draft<Global>) => FullState<Value, Filter, SortBy>,
  set: (global: Draft<Global>, newFullDataState: FullState<Value, Filter, SortBy>) => Draft<Global>,
  defaultState: FullParametersState<Filter, SortBy>,
  extractId: (value: Value) => string,
) => {
  const markDirtyName = `mark${type}FullDirtyReducer` as const;
  const storeDataName = `store${type}DataReducer` as const;
  const storeFullDataName = `store${type}FullDataReducer` as const;
  const storeParametersName = `store${type}FullParametersReducer` as const;

  const markDirty = fullMarkDirtyReducer(get, set);
  const storeData = (state: Draft<Global>, { payload: data }: FullDataSingleUpdateAction<Value>) => {
    const fullState = get(state);
    const replaceOrAdd = (fullData: Value[], newValue: Value) => {
      const newId = extractId(newValue);
      const idxToReplace = fullData.findIndex((old) => extractId(old) === newId);
      return idxToReplace !== -1
        ? fullData.map((old, idx) => (idx === idxToReplace ? newValue : old))
        : [newValue, ...fullData];
    };

    // no data means the state should be refreshed
    const newFullState = !fullState.data.data
      ? { ...fullState, data: { ...fullState.data, isDirty: true } }
      : { ...fullState, data: { ...fullState.data, data: replaceOrAdd(fullState.data.data, data) } };
    return set(state, newFullState);
  };
  const storeFullData = fullStoreDataReducer(get, set);
  const storeParameters = fullStoreParametersReducer(get, set, defaultState.sortBy);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [storeDataName]: storeData,
    [storeFullDataName]: storeFullData,
    [storeParametersName]: storeParameters,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof storeDataName, typeof storeData> &
    Record<typeof storeFullDataName, typeof storeFullData> &
    Record<typeof storeParametersName, typeof storeParameters>;
};

export const createNormalizedFullReducers = <
  Type extends string,
  Global extends EmptyObject,
  Value,
  Filter,
  SortBy extends string,
  ParentId = string,
  Full extends FullNoColumnsState<string, Filter, SortBy> = FullNoColumnsState<string, Filter, SortBy>,
>(
  type: Type,
  getFull: (global: Draft<Global>, parentId: ParentId | undefined) => Full | undefined,
  setFull: (global: Draft<Global>, parentId: ParentId, newFullDataState: Full) => Draft<Global>,
  getColumnState: (global: Draft<Global>) => ListColumnState,
  setColumnState: (global: Draft<Global>, newColumnState: ListColumnState) => Draft<Global>,
  defaultState: Full,
  toKey: (value: Value) => string,
) => {
  const markDirtyName = `mark${type}FullDirtyReducer` as const;
  const storeDataName = `store${type}FullDataReducer` as const;
  const storeParametersName = `store${type}FullParametersReducer` as const;

  const markDirty = (state: Draft<Global>, { payload: parentId }: NormalizedFullDataMarkDirtyAction<ParentId>) =>
    fullMarkDirtyReducer(
      (st: Draft<Global>) => getFull(st, parentId) ?? defaultState,
      (global: Draft<Global>, newFullDataState: Full) => setFull(global, parentId, newFullDataState),
    )(state);
  const storeData = (
    state: Draft<Global>,
    { payload: { parentId, data } }: NormalizedFullDataUpdateAction<ParentId, Value>,
  ) =>
    setFull(state, parentId, {
      ...(getFull(state, parentId) ?? defaultState),
      data: {
        isDirty: false,
        ...mapLoadingState(data, (values) => values.map(toKey)),
      },
    });

  const storeParameters = (
    state: Draft<Global>,
    { payload: { parentId, parameters } }: NormalizedFullParametersUpdateAction<ParentId, Filter, SortBy>,
  ) =>
    reduceFullParamSplitColumnStateUpdate(
      state,
      getFull(state, parentId) ?? defaultState,
      getColumnState(state),
      parameters,
      defaultState.sortBy,
      (global: Draft<Global>, newFullDataState: Full) => setFull(global, parentId, newFullDataState),
      setColumnState,
    );

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [storeDataName]: storeData,
    [storeParametersName]: storeParameters,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof storeDataName, typeof storeData> &
    Record<typeof storeParametersName, typeof storeParameters>;
};
