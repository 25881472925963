import type {
  BlockchainNetworkTypeAPIModel,
  InvoicesStatsSimpleAPIModel,
  SubscriptionBriefStatsAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { CompanyApi, PaymentsModeAPIModel, SubscriptionStatisticsApi } from '@/generated/api/ncps-core/merchant-bo';
import { amountFromAPI } from '@/infrastructure/api';
import { coreConfigurationFactory } from '@/infrastructure/request';

import type { AssetBalances, PaymentSummaryStats, SubscriptionSummaryStats } from './types';

const companyApi = new CompanyApi(coreConfigurationFactory('auth'));
const subscriptionStatisticsApi = new SubscriptionStatisticsApi(coreConfigurationFactory('auth'));

const parsePaymentsStat = ({
  todaySales,
  todayInvoices,
  ...rest
}: InvoicesStatsSimpleAPIModel): PaymentSummaryStats => ({
  ...rest,
  todayPayments: todayInvoices,
  todaySales: { ...todaySales, salesVolume: amountFromAPI(todaySales.salesVolume) },
});

const parseSubscriptionsStats = ({ mrr, ...rest }: SubscriptionBriefStatsAPIModel): SubscriptionSummaryStats => ({
  ...rest,
  mrr: amountFromAPI(mrr),
});

export const queryPaymentsSummaryStats = async (network: BlockchainNetworkTypeAPIModel, initOverrides?: RequestInit) =>
  parsePaymentsStat(await companyApi.getDashboardInfoByNetwork(network, initOverrides));

export const querySubscriptionSummaryStats = async (
  network: BlockchainNetworkTypeAPIModel,
  initOverrides?: RequestInit,
) => parseSubscriptionsStats(await subscriptionStatisticsApi.getBriefStats(network, initOverrides));

export const queryBalances = async (
  network: BlockchainNetworkTypeAPIModel,
  initOverrides?: RequestInit,
): Promise<AssetBalances> => {
  const response = await companyApi.getBalances(network, PaymentsModeAPIModel.Simple, initOverrides);
  return {
    available: (response.availableBalances ?? []).map(amountFromAPI).sort((a, b) => a.asset.localeCompare(b.asset)),
    balances: (response.balances ?? []).map(amountFromAPI).sort((a, b) => a.asset.localeCompare(b.asset)),
    collectable: (response.collectableBalances ?? []).map(amountFromAPI).sort((a, b) => a.asset.localeCompare(b.asset)),
    locked: (response.lockedBalances ?? []).map(amountFromAPI).sort((a, b) => a.asset.localeCompare(b.asset)),
    total: amountFromAPI(response.total),
    totalLocked: amountFromAPI(response.totalLocked),
    totalAvailable: amountFromAPI(response.totalAvailable),
    totalCollectable: amountFromAPI(response.totalCollectable),
  };
};
