import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { ReportScheduleStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';

import type { ReportScheduleStatusViewProps } from './types';

const icons: Record<ReportScheduleStatusAPIModel, React.ReactNode> = {
  [ReportScheduleStatusAPIModel.Active]: <PlayCircleOutlined style={{ color: 'green' }} />,
  [ReportScheduleStatusAPIModel.Disabled]: <PauseCircleOutlined style={{ color: 'orange' }} />,
};

const i18n: Record<ReportScheduleStatusAPIModel, I18nMessages> = {
  [ReportScheduleStatusAPIModel.Active]: I18nFeatureReportSchedules.COMPONENTS_STATUS_VIEW_LABEL_ACTIVE,
  [ReportScheduleStatusAPIModel.Disabled]: I18nFeatureReportSchedules.COMPONENTS_STATUS_VIEW_LABEL_DISABLED,
};

const ReportScheduleStatusViewRaw: React.FC<ReportScheduleStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const ReportScheduleStatusView = React.memo(ReportScheduleStatusViewRaw);

export default ReportScheduleStatusView;
