import { List, Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import { OperationUpdateDeferredPaymentMark, OperationUpdateCompensationMark } from './components';
import { withPaymentsConfigurationDataLoading } from './hocs';

import type { PaymentsConfigurationCardProps } from './types';

const { Paragraph } = Typography;

const PaymentsConfigurationCard: React.FC<PaymentsConfigurationCardProps> = ({ data, 'data-test': dataTest }) => (
  <>
    <Paragraph style={{ paddingBottom: '20px' }} data-test={dataTest && `${dataTest}-description`}>
      <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_DESCRIPTION} />
    </Paragraph>
    <List>
      <List.Item>
        <List.Item.Meta
          style={{ alignItems: 'center' }}
          title={
            <FormattedMessage
              id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_COMPENSATION_TITLE}
            />
          }
          description={
            <FormattedMessage
              id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_COMPENSATION_DESCRIPTION}
            />
          }
        />
        <OperationUpdateCompensationMark
          data-test={dataTest && `${dataTest}-compensation`}
          value={data.payments.compensateDirect}
        />
      </List.Item>
      <List.Item>
        <List.Item.Meta
          style={{ alignItems: 'center' }}
          title={
            <FormattedMessage
              id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_DEFERRED_TITLE}
            />
          }
          description={
            <FormattedMessage
              id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_DEFERRED_DESCRIPTION}
            />
          }
        />
        <OperationUpdateDeferredPaymentMark
          data-test={dataTest && `${dataTest}-deferred`}
          value={data.payments.deferredAllowed}
        />
      </List.Item>
    </List>
  </>
);

const PaymentsConfigurationCardLoaded = withPaymentsConfigurationDataLoading(PaymentsConfigurationCard);

const PaymentsConfigurationCardMemo = React.memo(
  PaymentsConfigurationCardLoaded,
) as typeof PaymentsConfigurationCardLoaded;

export default PaymentsConfigurationCardMemo;
