import { PageContainer } from '@/components';
import { CollectTaskFilterForm } from '@/features/collectable/components';
import { CollectableScheduleCard } from '@/pages/collectable/components';
import CollectTaskLink from '@/pages/collectable/task-view/components/CollectTaskLink';

import { CollectTasksBreadcrumb, CollectTasksCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'tasks';

const CollectTasksPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<CollectTasksBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <CollectableScheduleCard data-test={dataTest && `${dataTest}-schedule`} />
    <CollectTasksCard data-test={dataTest} CollectTaskLink={CollectTaskLink} FilterForm={CollectTaskFilterForm} />
  </PageContainer>
);

export default CollectTasksPage;
