import { Form } from 'antd';

import { FormattedMessage } from '@/components';
import BrandingLangSelect from '@/features/branding/components/BrandingLangSelect';
import type { BrandingLang, PaymentsBranding } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { BrandingLangItemProps } from './types';
import type React from 'react';

const BrandingLangItem: React.FC<BrandingLangItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <Form.Item<PaymentsBranding>
    name={nameof<PaymentsBranding>('supportedLang')}
    label={null}
    rules={[
      {
        validator: async (_, value?: BrandingLang[]) =>
          !value?.length ? Promise.reject(new Error()) : Promise.resolve(),
        message: (
          <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LANG_BLOCK_LANG_ITEM_ERRORS_REQUIRED} />
        ),
      },
    ]}
  >
    <BrandingLangSelect data-test={dataTest} multiselect readonly={disabled} />
  </Form.Item>
);

export default BrandingLangItem;
