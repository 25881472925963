import React, { useMemo } from 'react';

import type { MoneyAmountInputValue } from '@/components';
import { I18nComponents } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import FormattedMessage from '../FormattedMessage';
import MoneyAmountItem from '../MoneyAmountItem';

import type { MoneyAmountRangeItemProps, MoneyAmountRangeItemValue } from './types';
import type { Rule } from 'rc-field-form/es/interface';

const MoneyAmountRangeItem = ({
  'data-test': dataTest,
  name,
  readonly,
  required,
  messages,
  asset,
  nonNegative,
}: MoneyAmountRangeItemProps) => {
  const gteName = useMemo(
    () => [...(Array.isArray(name) ? name : [name]), nameof<MoneyAmountRangeItemValue>('gte')],
    [name],
  );
  const lteName = useMemo(
    () => [...(Array.isArray(name) ? name : [name]), nameof<MoneyAmountRangeItemValue>('lte')],
    [name],
  );
  const rules: Rule[] = useMemo(
    () => [
      ({ getFieldValue }) => ({
        validator: async () => {
          const gte: MoneyAmountInputValue | undefined = getFieldValue([
            name,
            nameof<MoneyAmountRangeItemValue>('gte'),
          ]);
          const lte: MoneyAmountInputValue | undefined = getFieldValue([
            name,
            nameof<MoneyAmountRangeItemValue>('lte'),
          ]);
          if (!gte || !lte || !gte.valid || !lte.valid || !gte.value || !lte.value) {
            return Promise.resolve();
          }
          if (lte.value.lte(gte.value)) {
            throw new Error();
          }
          return Promise.resolve();
        },
        message: <FormattedMessage id={I18nComponents.MONEY_AMOUNT_RANGE_FILTER_ERRORS_INVALID_RANGE} />,
      }),
    ],
    [name],
  );
  const lteMessages = useMemo(
    () => ({
      label: messages?.lte?.label || <FormattedMessage id={I18nComponents.MONEY_AMOUNT_RANGE_FILTER_LTE_LABEL} />,
    }),
    [messages?.lte?.label],
  );
  const gteMessages = useMemo(
    () => ({
      label: messages?.gte?.label || <FormattedMessage id={I18nComponents.MONEY_AMOUNT_RANGE_FILTER_GTE_LABEL} />,
    }),
    [messages?.gte?.label],
  );
  const gteItemProps = useMemo(() => ({ dependencies: [gteName] }), [gteName]);
  const lteItemProps = useMemo(() => ({ dependencies: [lteName] }), [lteName]);
  return (
    <>
      <MoneyAmountItem
        data-test={dataTest && `${dataTest}-gte`}
        name={gteName}
        messages={gteMessages}
        readonly={readonly}
        required={required}
        asset={asset}
        rules={rules}
        ItemProps={lteItemProps}
        nonNegative={nonNegative}
      />
      <MoneyAmountItem
        data-test={dataTest && `${dataTest}-lte`}
        name={lteName}
        messages={lteMessages}
        readonly={readonly}
        required={required}
        asset={asset}
        rules={rules}
        ItemProps={gteItemProps}
        nonNegative={nonNegative}
      />
    </>
  );
};

const MoneyAmountRangeItemMemo = React.memo(MoneyAmountRangeItem) as typeof MoneyAmountRangeItem;

export default MoneyAmountRangeItemMemo;
