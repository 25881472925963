import React from 'react';

import { StatusViewElement } from '@/components';
import DonationsIcon from '@/features/donations/components/DonationsIcon';
import RechargesIcon from '@/features/recharges/components/RechargesIcon';
import { PushKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectableKindViewProps } from './types';

const i18n: Record<PushKindAPIModel, I18nMessages> = {
  [PushKindAPIModel.Donation]: I18nFeatureCollectable.COMPONENTS_COLLECTABLE_KIND_VIEW_DONATION,
  [PushKindAPIModel.Recharge]: I18nFeatureCollectable.COMPONENTS_COLLECTABLE_KIND_VIEW_RECHARGE,
};

const icons: Record<PushKindAPIModel, React.ReactNode> = {
  [PushKindAPIModel.Donation]: <DonationsIcon />,
  [PushKindAPIModel.Recharge]: <RechargesIcon />,
};

const CollectableKindView: React.FC<CollectableKindViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const CollectableKindViewMemo = React.memo(CollectableKindView);

export default CollectableKindViewMemo;
