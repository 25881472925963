import React from 'react';

import type { GasHistoryViewProps } from '@/features/gas-history/components';
import { GasHistoryView } from '@/features/gas-history/components';

import { withGasHistoryDataLoading } from './hocs';

const GasHistoryCardRaw = withGasHistoryDataLoading<GasHistoryViewProps>((props) => (
  <GasHistoryView {...props} columns={1} />
));

const GasHistoryCard = React.memo(GasHistoryCardRaw);

export default GasHistoryCard;
