import { Space } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { OperationRefreshCompanySettings } from '@/features/company-settings/components';
import { useCompanyActiveAssets } from '@/features/company-settings/hooks';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import UpdateActiveAssetsLink from '@/pages/settings/company/assets-edit/components/UpdateActiveAssetsLink';

import type React from 'react';

const withActiveAssetsDataLoading =
  <T extends { data: string[] } & TestableProps>(Component: React.ComponentType<T>): React.FC<Omit<T, 'data'>> =>
  (props) => {
    const dataTest: string | undefined = props['data-test'];
    const { data: settings, ...state } = useCompanyActiveAssets();

    const data = useMemo(() => mapStoredState(settings, (assets) => assets.map(({ code }) => code)), [settings]);
    return withCardDataLoading<string[], T>({
      ...state,
      data,
      'data-test': dataTest,
      title: (
        <Space>
          <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_ASSETS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <UpdateActiveAssetsLink data-test={dataTest} mode="button" />
            <OperationRefreshCompanySettings data-test={dataTest && `${dataTest}-opRefresh`} mode="inline" />,
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withActiveAssetsDataLoading;
