import { Modal, Space } from 'antd';
import React, { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { withWeb3Context } from '@/features/web3/hocs';
import { useWeb3, useWeb3Singleton } from '@/features/web3/hooks';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';

import OperationCancelSubscriptionWithConnector from '../OperationCancelSubscriptionWithConnector';

import type { CancelSubscriptionModalProps } from './types';

const selectAuthToken = makeSelectAuthToken();

const CancelSubscriptionModalRaw: React.FC<CancelSubscriptionModalProps> = (props) => {
  const { 'data-test': dataTest, onSuccess, onCancel, subscription } = props;
  const loginState = useAppSelector(selectAuthToken);
  const { orderedConnectors } = useWeb3();
  const { withReloadIfInitialized } = useWeb3Singleton();
  const [inProgress, withInProgress] = useSubmitting(false);
  const doSuccess = useMemo(() => withReloadIfInitialized(onSuccess), [onSuccess, withReloadIfInitialized]);
  const doCancel = useMemo(() => withReloadIfInitialized(onCancel), [onCancel, withReloadIfInitialized]);
  const account = loginState.data?.info.address;

  return (
    <Modal
      open
      maskClosable={false}
      title={<FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_DIALOG_TITLE} />}
      data-test={dataTest && `${dataTest}-modal`}
      onOk={doSuccess}
      onCancel={!inProgress ? doCancel : undefined}
      closable={!inProgress}
      footer=""
    >
      <Space direction="vertical">
        <FormattedMessage
          id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_DIALOG_DESCRIPTION}
          tagName="span"
        />
        {account && (
          <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
            {orderedConnectors.map((connector) => (
              <OperationCancelSubscriptionWithConnector
                {...props}
                connectorId={connector.id}
                onSuccess={doSuccess}
                account={account}
                subscription={subscription}
                withInProgress={withInProgress}
                key={connector.id}
              />
            ))}
          </Space>
        )}
      </Space>
    </Modal>
  );
};

const CancelSubscriptionModalContexted = withWeb3Context(CancelSubscriptionModalRaw, React.Fragment);

const CancelSubscriptionModal = React.memo(CancelSubscriptionModalContexted) as typeof CancelSubscriptionModalContexted;

export default CancelSubscriptionModal;
