/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  AuthenticatedUserAPIModel,
  EmailAuthAPIModel,
  TokenWithUserAPIModel,
  Web3AddressAuthNonceAPIModel,
  Web3CredentialsAPIModel,
} from '../models/index';
import {
    AuthenticatedUserAPIModelFromJSON,
    AuthenticatedUserAPIModelToJSON,
    EmailAuthAPIModelFromJSON,
    EmailAuthAPIModelToJSON,
    TokenWithUserAPIModelFromJSON,
    TokenWithUserAPIModelToJSON,
    Web3AddressAuthNonceAPIModelFromJSON,
    Web3AddressAuthNonceAPIModelToJSON,
    Web3CredentialsAPIModelFromJSON,
    Web3CredentialsAPIModelToJSON,
} from '../models/index';

export interface AuthCompanyRequest {
    companyId: number;
}

export interface AuthEmailRequest {
    emailAuthAPIModel: EmailAuthAPIModel;
}

export interface AuthWeb3Request {
    xRecaptcha: string;
    web3CredentialsAPIModel: Web3CredentialsAPIModel;
    xRecaptchaType?: AuthWeb3XRecaptchaTypeEnum;
}

export interface GetWeb3AddressAuthNonceRequest {
    address: string;
}

/**
 * MerchantAuthApi - interface
 * 
 * @export
 * @interface MerchantAuthApiInterface
 */
export interface MerchantAuthApiInterface {
    /**
     * Reauthenticate user (refreshes the token) with new company
     * @summary 
     * @param {number} companyId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    authCompanyRaw(requestParameters: AuthCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>>;

    /**
     * Reauthenticate user (refreshes the token) with new company
     * 
     */
    authCompany(companyId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel>;

    /**
     * Auth merchant by web3 signature with email
     * @summary 
     * @param {EmailAuthAPIModel} emailAuthAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    authEmailRaw(requestParameters: AuthEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>>;

    /**
     * Auth merchant by web3 signature with email
     * 
     */
    authEmail(emailAuthAPIModel: EmailAuthAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel>;

    /**
     * Auth merchant by web3 signature
     * @summary 
     * @param {string} xRecaptcha 
     * @param {Web3CredentialsAPIModel} web3CredentialsAPIModel 
     * @param {'v2' | 'v3'} [xRecaptchaType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    authWeb3Raw(requestParameters: AuthWeb3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>>;

    /**
     * Auth merchant by web3 signature
     * 
     */
    authWeb3(xRecaptcha: string, web3CredentialsAPIModel: Web3CredentialsAPIModel, xRecaptchaType?: AuthWeb3XRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel>;

    /**
     * Queries the authenticated user
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    getAuthenticatedUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticatedUserAPIModel>>;

    /**
     * Queries the authenticated user
     * 
     */
    getAuthenticatedUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticatedUserAPIModel>;

    /**
     * Queries the registered address nonce to sign the auth message
     * @summary 
     * @param {string} address 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuthApiInterface
     */
    getWeb3AddressAuthNonceRaw(requestParameters: GetWeb3AddressAuthNonceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Web3AddressAuthNonceAPIModel>>;

    /**
     * Queries the registered address nonce to sign the auth message
     * 
     */
    getWeb3AddressAuthNonce(address: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Web3AddressAuthNonceAPIModel>;

}

/**
 * 
 */
export class MerchantAuthApi extends runtime.BaseAPI implements MerchantAuthApiInterface {

    /**
     * Reauthenticate user (refreshes the token) with new company
     * 
     */
    async authCompanyRaw(requestParameters: AuthCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>> {
        if (requestParameters['companyId'] == null) {
            throw new runtime.RequiredError(
                'companyId',
                'Required parameter "companyId" was null or undefined when calling authCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/auth/company/{companyId}/auth`.replace(`{${"companyId"}}`, encodeURIComponent(String(requestParameters['companyId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Reauthenticate user (refreshes the token) with new company
     * 
     */
    async authCompany(companyId: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel> {
        const response = await this.authCompanyRaw({ companyId: companyId }, initOverrides);
        return await response.value();
    }

    /**
     * Auth merchant by web3 signature with email
     * 
     */
    async authEmailRaw(requestParameters: AuthEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>> {
        if (requestParameters['emailAuthAPIModel'] == null) {
            throw new runtime.RequiredError(
                'emailAuthAPIModel',
                'Required parameter "emailAuthAPIModel" was null or undefined when calling authEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/merchant-bff/auth/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailAuthAPIModelToJSON(requestParameters['emailAuthAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Auth merchant by web3 signature with email
     * 
     */
    async authEmail(emailAuthAPIModel: EmailAuthAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel> {
        const response = await this.authEmailRaw({ emailAuthAPIModel: emailAuthAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Auth merchant by web3 signature
     * 
     */
    async authWeb3Raw(requestParameters: AuthWeb3Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TokenWithUserAPIModel>> {
        if (requestParameters['xRecaptcha'] == null) {
            throw new runtime.RequiredError(
                'xRecaptcha',
                'Required parameter "xRecaptcha" was null or undefined when calling authWeb3().'
            );
        }

        if (requestParameters['web3CredentialsAPIModel'] == null) {
            throw new runtime.RequiredError(
                'web3CredentialsAPIModel',
                'Required parameter "web3CredentialsAPIModel" was null or undefined when calling authWeb3().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xRecaptcha'] != null) {
            headerParameters['x-recaptcha'] = String(requestParameters['xRecaptcha']);
        }

        if (requestParameters['xRecaptchaType'] != null) {
            headerParameters['x-recaptcha-type'] = String(requestParameters['xRecaptchaType']);
        }

        const response = await this.request({
            path: `/merchant-bff/auth/web3`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: Web3CredentialsAPIModelToJSON(requestParameters['web3CredentialsAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenWithUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Auth merchant by web3 signature
     * 
     */
    async authWeb3(xRecaptcha: string, web3CredentialsAPIModel: Web3CredentialsAPIModel, xRecaptchaType?: AuthWeb3XRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TokenWithUserAPIModel> {
        const response = await this.authWeb3Raw({ xRecaptcha: xRecaptcha, web3CredentialsAPIModel: web3CredentialsAPIModel, xRecaptchaType: xRecaptchaType }, initOverrides);
        return await response.value();
    }

    /**
     * Queries the authenticated user
     * 
     */
    async getAuthenticatedUserRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthenticatedUserAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/auth/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthenticatedUserAPIModelFromJSON(jsonValue));
    }

    /**
     * Queries the authenticated user
     * 
     */
    async getAuthenticatedUser(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthenticatedUserAPIModel> {
        const response = await this.getAuthenticatedUserRaw(initOverrides);
        return await response.value();
    }

    /**
     * Queries the registered address nonce to sign the auth message
     * 
     */
    async getWeb3AddressAuthNonceRaw(requestParameters: GetWeb3AddressAuthNonceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Web3AddressAuthNonceAPIModel>> {
        if (requestParameters['address'] == null) {
            throw new runtime.RequiredError(
                'address',
                'Required parameter "address" was null or undefined when calling getWeb3AddressAuthNonce().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/merchant-bff/auth/web3/{address}/nonce`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters['address']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Web3AddressAuthNonceAPIModelFromJSON(jsonValue));
    }

    /**
     * Queries the registered address nonce to sign the auth message
     * 
     */
    async getWeb3AddressAuthNonce(address: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Web3AddressAuthNonceAPIModel> {
        const response = await this.getWeb3AddressAuthNonceRaw({ address: address }, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum AuthWeb3XRecaptchaTypeEnum {
    v2 = 'v2',
    v3 = 'v3'
}
