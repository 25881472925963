import { Grid } from 'antd';
import { useMemo } from 'react';

import useDimensions from './useDimensions';

import type React from 'react';

export interface UseTableButtonsMode {
  ref: React.RefCallback<HTMLDivElement>;
  quickFilterMode: 'small' | 'medium' | 'large' | 'full';
  exportMode: 'inline' | 'button';
}

const { useBreakpoint } = Grid;

export default function useTableButtonsMode(): UseTableButtonsMode {
  const [ref, size] = useDimensions<HTMLDivElement>();
  const { md } = useBreakpoint();
  const quickFilterMode = useMemo(() => {
    if (!size?.width) {
      return 'small';
    }
    if (!md) {
      if (size.width < 380) {
        return 'small';
      }
      if (size.width < 470) {
        return 'medium';
      }
      if (size.width < 550) {
        return 'large';
      }
    } else {
      if (size.width < 520) {
        return 'small';
      }
      if (size.width < 610) {
        return 'medium';
      }
      if (size.width < 700) {
        return 'large';
      }
    }
    return 'full';
  }, [md, size?.width]);
  const exportMode = useMemo(() => {
    if (!size?.width) {
      return 'inline';
    }
    return (!md && size.width < 600) || size.width < 760 ? 'inline' : 'button';
  }, [md, size?.width]);
  return { ref, exportMode, quickFilterMode };
}
