import { Navigate, Route, Routes } from 'react-router-dom';

import { OnBoardLayout } from '@/layouts';
import { withOnboardPageGuard } from '@/pages/hocs';
import { AppPageRoutes } from '@/pages/routes';

import MainPage from './main';
import { OnboardPageRoutes, onboardLink } from './routes';

import type React from 'react';

export const OnboardDefaultRoute: React.FC = () => <Navigate to={onboardLink()} replace />;

const OnboardPages: React.FC = () => (
  <OnBoardLayout>
    <Routes>
      <Route path={OnboardPageRoutes.MAIN} Component={MainPage} />
      <Route path="*" Component={OnboardDefaultRoute} />
    </Routes>
  </OnBoardLayout>
);

const OnboardPagesHOC: React.FC = withOnboardPageGuard(OnboardPages);

export default <Route key={AppPageRoutes.ONBOARD} path={`${AppPageRoutes.ONBOARD}/*`} Component={OnboardPagesHOC} />;
