import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditWalletTransferViewLink } from '@/pages/audit/routes';

import type { MerchantWalletTransferLinkProps } from './types';

const MerchantWalletTransferLink: React.FC<MerchantWalletTransferLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
}) => (
  <Link
    data-test={dataTest}
    to={auditWalletTransferViewLink(value)}
    mode={mode}
    title={title || <FormattedMessage id={I18nPageAudit.TRANSFER_VIEW_COMPONENTS_LINK_TITLE} />}
  />
);

const MerchantWalletTransferLinkMemo = React.memo(MerchantWalletTransferLink);

export default MerchantWalletTransferLinkMemo;
