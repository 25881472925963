import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { useCollectTask } from '@/features/collectable/hooks';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { uuidToBase58 } from '@/infrastructure/utils/crypto';
import { collectTaskViewLink } from '@/pages/collectable/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { CollectTaskEntityLinkProps } from './types';

const CollectTaskEntityLink: React.FC<CollectTaskEntityLinkProps> = ({ 'data-test': dataTest, value }) => {
  const collectTaskId = useMemo(
    () => (value.entityId?.includes('-') ? uuidToBase58(value.entityId) : value.entityId),
    [value.entityId],
  );
  const data = useCollectTask(collectTaskId);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return <Navigate to={collectTaskViewLink(data.data.data.id)} replace />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.PushCollect}
      onRetry={data.forceRefresh}
    />
  );
};

const CollectTaskEntityLinkMemo = React.memo(CollectTaskEntityLink);

export default CollectTaskEntityLinkMemo;
