import { ShareAltOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { SettlementType } from '@/features/settlements/types';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import SettlementsIcon from '../SettlementsIcon';

import type { SettlementTypeViewProps } from './types';

const i18n: Record<SettlementType, I18nMessages> = {
  [SettlementType.Payout]: I18nFeatureSettlements.COMPONENTS_SETTLEMENT_TYPE_LABEL_PAYOUT,
  [SettlementType.Settlement]: I18nFeatureSettlements.COMPONENTS_SETTLEMENT_TYPE_LABEL_SETTLEMENT,
};

const icons: Record<SettlementType, React.ReactNode> = {
  [SettlementType.Payout]: <ShareAltOutlined />,
  [SettlementType.Settlement]: <SettlementsIcon />,
};

const SettlementTypeViewRaw: React.FC<SettlementTypeViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const SettlementTypeView = React.memo(SettlementTypeViewRaw);

export default SettlementTypeView;
