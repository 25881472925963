/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageWithdrawalIntentSortByAPIModel } from './PageWithdrawalIntentSortByAPIModel';
import {
    PageWithdrawalIntentSortByAPIModelFromJSON,
    PageWithdrawalIntentSortByAPIModelFromJSONTyped,
    PageWithdrawalIntentSortByAPIModelToJSON,
} from './PageWithdrawalIntentSortByAPIModel';
import type { WithdrawalIntentPredicatesAPIModel } from './WithdrawalIntentPredicatesAPIModel';
import {
    WithdrawalIntentPredicatesAPIModelFromJSON,
    WithdrawalIntentPredicatesAPIModelFromJSONTyped,
    WithdrawalIntentPredicatesAPIModelToJSON,
} from './WithdrawalIntentPredicatesAPIModel';

/**
 * 
 * @export
 * @interface SearchWithdrawalIntentsRequestAPIModel
 */
export interface SearchWithdrawalIntentsRequestAPIModel {
    /**
     * 
     * @type {WithdrawalIntentPredicatesAPIModel}
     * @memberof SearchWithdrawalIntentsRequestAPIModel
     */
    predicates: WithdrawalIntentPredicatesAPIModel;
    /**
     * 
     * @type {PageWithdrawalIntentSortByAPIModel}
     * @memberof SearchWithdrawalIntentsRequestAPIModel
     */
    page: PageWithdrawalIntentSortByAPIModel;
}

/**
 * Check if a given object implements the SearchWithdrawalIntentsRequestAPIModel interface.
 */
export function instanceOfSearchWithdrawalIntentsRequestAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function SearchWithdrawalIntentsRequestAPIModelFromJSON(json: any): SearchWithdrawalIntentsRequestAPIModel {
    return SearchWithdrawalIntentsRequestAPIModelFromJSONTyped(json, false);
}

export function SearchWithdrawalIntentsRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchWithdrawalIntentsRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': WithdrawalIntentPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageWithdrawalIntentSortByAPIModelFromJSON(json['page']),
    };
}

export function SearchWithdrawalIntentsRequestAPIModelToJSON(value?: SearchWithdrawalIntentsRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': WithdrawalIntentPredicatesAPIModelToJSON(value['predicates']),
        'page': PageWithdrawalIntentSortByAPIModelToJSON(value['page']),
    };
}

