import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { DonationStatusView } from '@/features/donations/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const DonationStatusFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps,
}: DonationStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureDonations.COMPONENTS_DONATION_FILTER_STATUS_LABEL} />}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      disabled={readonly}
      options={useMemo(
        () => [
          {
            value: true,
            label: <DonationStatusView value={true} data-test={dataTest && `${dataTest}-active`} />,
          },
          {
            value: false,
            label: <DonationStatusView value={false} data-test={dataTest && `${dataTest}-inactive`} />,
          },
        ],
        [dataTest],
      )}
      allowClear
      placeholder={
        !readonly ? (
          <FormattedMessage id={I18nFeatureDonations.COMPONENTS_DONATION_FILTER_STATUS_PLACEHOLDER} />
        ) : undefined
      }
    />
  </Form.Item>
);

const DonationStatusFilterItemMemo = React.memo(DonationStatusFilterItem) as typeof DonationStatusFilterItem;

export default DonationStatusFilterItemMemo;
