import React from 'react';

import { OperationRefresh } from '@/components';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';

import type { OperationRefreshMerchantGasWalletProps } from './types';

const OperationRefreshMerchantGasWallet: React.FC<OperationRefreshMerchantGasWalletProps> = ({
  'data-test': dataTest,
  bt,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useMerchantGasWallet(bt);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshMerchantGasWalletMemo = React.memo(OperationRefreshMerchantGasWallet);

export default OperationRefreshMerchantGasWalletMemo;
