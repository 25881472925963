import { Alert, Tag, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage, SupportEmail } from '@/components';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';

import type { SettlementReconciliationAbsentBannerProps } from './types';

const SettlementReconciliationAbsentBanner: React.FC<SettlementReconciliationAbsentBannerProps> = ({
  'data-test': dataTest,
  settlementId,
  companyId,
  mode,
  style,
  className,
}) =>
  mode === 'tag' ? (
    <Tooltip
      title={
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_EMPTY_DESCRIPTION}
          values={{
            email: (text: React.ReactNode) => (
              <SupportEmail title={text} subject={`[${companyId}]: Reconciliation ${settlementId}`} />
            ),
          }}
        />
      }
    >
      <Tag data-test={dataTest} color="success" className={className} style={{ ...style, marginRight: 0 }}>
        <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_EMPTY_TAG} />
      </Tag>
    </Tooltip>
  ) : (
    <Alert
      data-test={dataTest}
      className={className}
      style={style}
      message={
        <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_EMPTY_TITLE} />
      }
      description={
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_BANNER_EMPTY_DESCRIPTION}
          values={{
            email: (text: React.ReactNode) => (
              <SupportEmail title={text} subject={`[${companyId}]: Reconciliation ${settlementId}`} />
            ),
          }}
        />
      }
      type="info"
      showIcon
    />
  );

const SettlementReconciliationAbsentBannerMemo = React.memo(SettlementReconciliationAbsentBanner);

export default SettlementReconciliationAbsentBannerMemo;
