import { useCallback, useMemo } from 'react';

import { useAppListData, type UseAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import { useMerchantGasWallets } from '@/features/gas-wallets/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSubscriptionPlans } from '@/features/subscription-plans/actions';
import {
  makeSelectSubscriptionPlanListData,
  makeSelectSubscriptionPlanListParameters,
} from '@/features/subscription-plans/selectors';
import type {
  SubscriptionPlan,
  SubscriptionPlanExtended,
  SubscriptionPlanFilterPredicate,
} from '@/features/subscription-plans/types';
import type { SubscriptionPlanSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';
import { mapListState } from '@/infrastructure/model/list/utils';

export type UseSubscriptionPlanPlanList = UseAppListData<
  SubscriptionPlanExtended,
  SubscriptionPlanFilterPredicate,
  SubscriptionPlanSortByAPIModel,
  LoadingListDataState<SubscriptionPlan>
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<SubscriptionPlan>, Parameters<typeof fetchSubscriptionPlans>[0]> =>
  fetchSubscriptionPlans({ force });

const dataSelector = makeSelectSubscriptionPlanListData();
const dataFetchingSelector = makeSelectPending(fetchSubscriptionPlans);
const parametersSelector = makeSelectSubscriptionPlanListParameters();

export default function useSubscriptionPlanList(): UseSubscriptionPlanPlanList {
  const wallets = useMerchantGasWallets();
  const assets = useListAssets();
  const isGasWalletStateInvalid = useCallback(
    (assetCode: string) => {
      const bt = assets.data.data?.find(({ code }) => code === assetCode)?.blockchain;
      const wallet = wallets.data.data?.find((w) => w.bt === bt);
      return !wallet?.isReadyForAction;
    },
    [assets.data.data, wallets.data],
  );

  const { data: rawData, ...rest } = useAppListData(
    fetchFactory,
    parametersSelector,
    dataFetchingSelector,
    dataSelector,
  );
  const data = useMemo(
    () => mapListState(rawData, (d) => ({ ...d, isGasWalletStateInvalid: isGasWalletStateInvalid(d.amount.asset) })),
    [isGasWalletStateInvalid, rawData],
  );
  return { ...rest, data };
}
