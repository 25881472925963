import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { donationAddLink } from '@/pages/donations/routes';

import type { DonationAddLinkProps } from './types';

const DonationAddLink: React.FC<DonationAddLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(donationAddLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <PlusCircleOutlined />}
      title={title ?? <FormattedMessage id={I18nPageDonations.DONATION_ADD_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const DonationAddLinkMemo = React.memo(DonationAddLink);

export default DonationAddLinkMemo;
