import { List, Space, Tag } from 'antd';
import isNil from 'lodash/isNil';
import React from 'react';

import { FormattedMessage, WarningTooltip } from '@/components';
import { AssetLabel, BlockchainIcon, BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { QRSupportItemProps } from './types';

const QRSupportItem: React.FC<QRSupportItemProps> = ({
  'data-test': dataTest,
  settings,
  assets,
  Actions,
  Operations,
}) => (
  <List.Item
    key={settings.blockchain}
    actions={
      Actions && [
        <Actions
          key={`${settings.blockchain}-actions`}
          data-test={dataTest && `${dataTest}-actions`}
          settings={settings}
          assets={assets}
        />,
      ]
    }
  >
    <List.Item.Meta
      key={`${settings.blockchain}-meta`}
      style={{ alignItems: 'center' }}
      avatar={
        <BlockchainIcon style={{ fontSize: 32 }} bt={settings.blockchain} data-test={dataTest && `${dataTest}-icon`} />
      }
      description={
        <>
          <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_QR_SUPPORT_LIST_ITEM_ASSETS} />
          {assets.length
            ? assets.map((asset, idx) => (
                <React.Fragment key={asset.code}>
                  <AssetLabel
                    data-test={dataTest && `${dataTest}-asset-${asset.code}`}
                    value={asset}
                    mode="short"
                    style={{ display: 'inline' }}
                  />
                  {idx < assets.length - 1 && ', '}
                </React.Fragment>
              ))
            : null}
        </>
      }
      title={
        <Space>
          <BlockchainLabel data-test={dataTest && `${dataTest}-title`} bt={settings.blockchain} mode="short" />
          {!isNil(settings.isDeployed) && !settings.isDeployed && (
            <WarningTooltip
              type="error"
              title={
                <FormattedMessage
                  id={I18nFeatureCompanySettings.COMPONENTS_QR_SUPPORT_LIST_ITEM_NOT_DEPLOYED_TOOLTIP}
                />
              }
            >
              <Tag color="error" data-test={dataTest && `${dataTest}-notDeployed`}>
                <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_QR_SUPPORT_LIST_ITEM_NOT_DEPLOYED_TITLE} />
              </Tag>
            </WarningTooltip>
          )}
        </Space>
      }
    />
    {Operations && <Operations data-test={dataTest && `${dataTest}-ops`} settings={settings} assets={assets} />}
  </List.Item>
);

const QRSupportItemMemo = React.memo(QRSupportItem);

export default QRSupportItemMemo;
