import { ArrowUpOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { BrandingLogoTopPositionProps } from './types';

const BrandingLogoTopPosition: React.FC<BrandingLogoTopPositionProps> = ({ 'data-test': dataTest }) => (
  <Space data-test={dataTest}>
    <ArrowUpOutlined />
    <FormattedMessage id={I18nFeatureBranding.COMPONENTS_LOGO_POSITIONS_SWITCH_LABELS_TOP} tagName="span" />
  </Space>
);

const BrandingLogoTopPositionMemo = React.memo(BrandingLogoTopPosition);

export default BrandingLogoTopPositionMemo;
