import { useMemo } from 'react';

import { useAppSingleData } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletDeploymentState } from '@/features/merchant-wallets/actions';
import { makeSelectMerchantWalletDeployment } from '@/features/merchant-wallets/selectors';
import type { MerchantWalletDeploymentState, MerchantWalletId } from '@/features/merchant-wallets/types';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { useUser } from '@/features/user/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { storedDataLoaded } from '@/infrastructure/model';

const noData = storedDataLoaded<MerchantWalletDeploymentState>();

const fetchFactory = (id: MerchantWalletId, force: boolean) => fetchMerchantWalletDeploymentState({ id, force });

export default function useMerchantWalletDeployment(bt: BlockchainTypeAPIModel | undefined) {
  const { data: user } = useUser();
  const id = useMemo<MerchantWalletId | undefined>(
    () => (user.data?.address && bt ? { address: user.data.address, bt } : undefined),
    [bt, user.data?.address],
  );
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchMerchantWalletDeploymentState, id && merchantWalletIdToStoreKey(id)),
    [id],
  );

  return useAppSingleData(fetchFactory, makeSelectMerchantWalletDeployment, noData, id, dataFetchingSelector);
}
