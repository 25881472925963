import { withPageContainer } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import {
  MerchantWalletRunningBalanceBreadcrumb,
  MerchantWalletRunningBalanceTransfersCard,
  MerchantWalletRunningBalanceCard,
} from './components';
import { withAuditBalanceViewPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type MerchantWalletRunningBalancePageProps = PropsWithChildren<TestableProps & { pageParams: { balanceId: string } }>;

const MerchantWalletRunningBalancePage: React.FC<MerchantWalletRunningBalancePageProps> = ({
  'data-test': dataTest = 'mwb',
  pageParams: { balanceId },
}) => (
  <>
    <MerchantWalletRunningBalanceCard data-test={dataTest && `${dataTest}-balance`} balanceId={balanceId} />
    <MerchantWalletRunningBalanceTransfersCard data-test={dataTest && `${dataTest}-transfers`} balanceId={balanceId} />
  </>
);

export default withAuditBalanceViewPageParams(
  withPageContainer<MerchantWalletRunningBalancePageProps>(({ pageParams: { balanceId } }) => ({
    breadcrumbRender: () => <MerchantWalletRunningBalanceBreadcrumb balanceId={balanceId} disabled />,
  }))(MerchantWalletRunningBalancePage),
);
