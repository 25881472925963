import { useDonationAddressDeployTransaction } from '@/features/donations/hooks';
import type { DonationAssetId } from '@/features/donations/types';
import { TransactionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDonationAssetDeployTransactionAppearance = <Props extends DonationAssetId = DonationAssetId>(
  Component: React.ComponentType<Props>,
): React.FC<Props> =>
  namedHOC<Props, Props>(
    Component,
    'WithDonationAssetDeployTransactionAppearance',
  )((props) => {
    const data = useDonationAddressDeployTransaction(props.donationId, props.donationId);
    return data.data.data
      && [
        TransactionStatusAPIModel.Abandoned,
        TransactionStatusAPIModel.Failed,
        TransactionStatusAPIModel.Pending,
        TransactionStatusAPIModel.Sent,
      ].includes(data.data.data.status) ? (
      <Component {...props} />
    ) : null;
  });

export default withDonationAssetDeployTransactionAppearance;
