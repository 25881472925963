import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import DonationAssetsBreadcrumb from '@/pages/donations/donation-assets/components/DonationAssetsBreadcrumb';

import DonationAssetViewLink from '../DonationAssetViewLink';

import type { DonationAssetViewBreadcrumbProps } from './types';

const DonationAddressViewBreadcrumb: React.FC<DonationAssetViewBreadcrumbProps> = ({
  'data-test': dataTest,
  donationId,
  asset,
  disabled,
  items = [],
}) => {
  const value = useMemo(() => ({ donationId, asset }), [asset, donationId]);
  return (
    <DonationAssetsBreadcrumb
      data-test={dataTest}
      donationId={donationId}
      items={[
        {
          title: disabled ? (
            <FormattedMessage
              id={I18nPageDonations.DONATION_ASSET_VIEW_COMPONENTS_BREADCRUMB}
              values={{ asset: <AssetLabel value={asset} mode="medium-no-ico" /> }}
            />
          ) : (
            <DonationAssetViewLink
              data-test={dataTest && `${dataTest}-link`}
              mode="text"
              value={value}
              title={
                <FormattedMessage
                  id={I18nPageDonations.DONATION_ASSET_VIEW_COMPONENTS_BREADCRUMB}
                  values={{ asset: <AssetLabel value={asset} mode="medium-no-ico" /> }}
                />
              }
            />
          ),
        },
        ...items,
      ]}
    />
  );
};

const DonationAddressViewBreadcrumbMemo = React.memo(DonationAddressViewBreadcrumb);

export default DonationAddressViewBreadcrumbMemo;
