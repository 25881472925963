import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import {
  AssetsCard,
  QRSupportCard,
  GasWalletsCard,
  GeneralSettingsCard,
  PaymentsConfigurationCard,
  CompanySettingsBreadcrumb,
} from './components';

import type React from 'react';

const dataTest: string | undefined = 'settings';

const CompanySummaryPage: React.FC = () => (
  <SettingsPageLayout
    data-test={dataTest && `${dataTest}-container`}
    selected={SettingsTabType.company}
    breadcrumb={<CompanySettingsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <GeneralSettingsCard data-test={dataTest && `${dataTest}-general`} />
    <AssetsCard data-test={dataTest && `${dataTest}-assets`} />
    <PaymentsConfigurationCard data-test={dataTest && `${dataTest}-payments`} />
    <QRSupportCard data-test={dataTest && `${dataTest}-qr`} />
    <GasWalletsCard data-test={dataTest && `${dataTest}-gas`} />
  </SettingsPageLayout>
);

export default CompanySummaryPage;
