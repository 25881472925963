import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { extractMerchantWalletTransferId } from '@/features/merchant-wallet-transfers/utils';
import { createNormalizedFullReducers } from '@/infrastructure/model/full/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markIncomingForReconciliationFullDirty,
  markOutgoingForReconciliationFullDirty,
  markSettlementReconciliationDirty,
  storeIncomingForReconciliationFullData,
  storeIncomingForReconciliationFullDataParameters,
  storeMultipleSettlementReconciliation,
  storeOutgoingForReconciliationFullData,
  storeOutgoingForReconciliationFullDataParameters,
  storeSettlementReconciliation,
} from './actions';
import { defaultTransfersBySettlementState, type SettlementReconciliationsState } from './types';

import type { Draft } from 'immer';

const initialState: SettlementReconciliationsState = {
  entities: {},
  transfers: {
    columnState: {},
    outgoing: {},
    incoming: {},
  },
};

const {
  storeSettlementReconciliationReducer,
  storeMultipleSettlementReconciliationReducer,
  markSettlementReconciliationDirtyReducer,
} = createSingleReducers(
  'SettlementReconciliation',
  (state: Draft<SettlementReconciliationsState>) => state.entities,
  (global, entities) => ({ ...global, entities }),
);

const {
  storeOutgoingForReconciliationFullDataReducer,
  markOutgoingForReconciliationFullDirtyReducer,
  storeOutgoingForReconciliationFullParametersReducer,
} = createNormalizedFullReducers(
  'OutgoingForReconciliation',
  (state: Draft<SettlementReconciliationsState>, settlementId: string | undefined) =>
    state.transfers.outgoing[settlementId!],
  (state, settlementId, newFullDataState) => ({
    ...state,
    transfers: { ...state.transfers, outgoing: { ...state.transfers.outgoing, [settlementId]: newFullDataState } },
  }),
  (state) => state.transfers.columnState,
  (state, columnState) => ({ ...state, transfers: { ...state.transfers, columnState } }),
  defaultTransfersBySettlementState,
  extractMerchantWalletTransferId,
);

const {
  storeIncomingForReconciliationFullDataReducer,
  markIncomingForReconciliationFullDirtyReducer,
  storeIncomingForReconciliationFullParametersReducer,
} = createNormalizedFullReducers(
  'IncomingForReconciliation',
  (state: Draft<SettlementReconciliationsState>, settlementId: string | undefined) =>
    state.transfers.incoming[settlementId!],
  (state, settlementId, newFullDataState) => ({
    ...state,
    transfers: { ...state.transfers, incoming: { ...state.transfers.incoming, [settlementId]: newFullDataState } },
  }),
  (state) => state.transfers.columnState,
  (state, columnState) => ({ ...state, transfers: { ...state.transfers, columnState } }),
  defaultTransfersBySettlementState,
  extractMerchantWalletTransferId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markSettlementReconciliationDirty, markSettlementReconciliationDirtyReducer)
    .addCase(storeSettlementReconciliation, storeSettlementReconciliationReducer)
    .addCase(storeMultipleSettlementReconciliation, storeMultipleSettlementReconciliationReducer)

    .addCase(storeIncomingForReconciliationFullDataParameters, storeIncomingForReconciliationFullParametersReducer)
    .addCase(storeIncomingForReconciliationFullData, storeIncomingForReconciliationFullDataReducer)
    .addCase(markIncomingForReconciliationFullDirty, markIncomingForReconciliationFullDirtyReducer)

    .addCase(storeOutgoingForReconciliationFullDataParameters, storeOutgoingForReconciliationFullParametersReducer)
    .addCase(storeOutgoingForReconciliationFullData, storeOutgoingForReconciliationFullDataReducer)
    .addCase(markOutgoingForReconciliationFullDirty, markOutgoingForReconciliationFullDirtyReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
