import { css } from '@emotion/css';
import { Col, Row } from 'antd';
import React from 'react';

import type { BrandingDataContainerProps } from './types';

const BrandingDataContainer: React.FC<BrandingDataContainerProps> = ({
  'data-test': dataTest,
  dataPanel,
  previewPanel,
}) => (
  <Row data-test={dataTest}>
    <Col
      flex="1 1 300px"
      className={css`
        height: 100%;
        max-height: unset;
        border: 1px solid lightgray;
        padding: 5px;
        overflow-y: auto;
      `}
    >
      {dataPanel}
    </Col>
    <Col
      xl={{ flex: '0 1 495px' }}
      lg={{ flex: '1 1 495px' }}
      md={{ flex: '1 1 495px' }}
      xs={{ flex: '1 1 495px' }}
      sm={{ flex: '1 1 495px' }}
      style={{ minHeight: 700 }}
    >
      {previewPanel}
    </Col>
  </Row>
);

const BrandingDataContainerMemo = React.memo(BrandingDataContainer);

export default BrandingDataContainerMemo;
