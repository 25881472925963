import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import SettlementReconciliationStatusView from '@/features/settlement-reconciliations/components/SettlementReconciliationStatusView';
import { WithdrawalReconciliationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { SettlementReconciliationStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SettlementReconciliationStatusFilterItem = <Values extends Store = Store>({
  name,
  'data-test': dataTest,
  readonly,
  required,
  ItemProps = {},
}: SettlementReconciliationStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={
      <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_FILTER_FORM_STATUS_LABEL} />
    }
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      mode="multiple"
      disabled={readonly}
      placeholder={
        !readonly ? (
          <FormattedMessage
            id={I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_FILTER_FORM_STATUS_PLACEHOLDER}
          />
        ) : undefined
      }
      options={useMemo(
        () =>
          enumValues(WithdrawalReconciliationStatusAPIModel).map((code) => ({
            value: code,
            label: (
              <SettlementReconciliationStatusView
                value={code}
                mode="full"
                data-test={dataTest && `${dataTest}-status`}
              />
            ),
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const SettlementReconciliationStatusFilterItemMemo = React.memo(
  SettlementReconciliationStatusFilterItem,
) as typeof SettlementReconciliationStatusFilterItem;

export default SettlementReconciliationStatusFilterItemMemo;
