import useResizeObserver from '@react-hook/resize-observer';
import { useLayoutEffect } from 'react';

import useStateMountSafe from '@/hooks/useStateMountSafe';

import type React from 'react';

const useBounds = <T extends HTMLElement>(target: React.RefObject<T> | null): DOMRect | undefined => {
  const [size, setSize] = useStateMountSafe<DOMRect>();

  useLayoutEffect(() => {
    if (target?.current) {
      setSize(target.current.getBoundingClientRect());
    }
  }, [setSize, target]);

  useResizeObserver(target, (entry) => {
    setSize(entry.contentRect);
  });
  return size;
};

export default useBounds;
