import { Space } from 'antd';
import { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useSubscriptionPlan } from '@/features/subscription-plans/hooks';
import type { SubscriptionPlan } from '@/features/subscription-plans/types';
import { OperationRefreshSubscription, SubscriptionsDocumentationLink } from '@/features/subscriptions/components';
import { useSubscription } from '@/features/subscriptions/hooks';
import type { Subscription } from '@/features/subscriptions/types';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { combine } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionDataLoading = <
  Original extends { data: { subscription: Subscription; plan: SubscriptionPlan } } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { subscriptionId: string } = Omit<Original, 'data'> & {
    subscriptionId: string;
  },
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithSubscriptionDataLoading',
  )((props) => {
    const subscriptionState = useSubscription(props.subscriptionId);
    const planState = useSubscriptionPlan(subscriptionState.data.data?.planId);
    const forceRefresh = useCallback(
      async () => Promise.all([subscriptionState.forceRefresh(), planState.forceRefresh()]),
      [planState, subscriptionState],
    );
    const data: LoadingStateWithDirty<{ subscription: Subscription; plan: SubscriptionPlan }> = useMemo(
      () => combine(subscriptionState.data, planState.data, (subscription, plan) => ({ subscription, plan })),
      [subscriptionState.data, planState.data],
    );
    const dataTest = props['data-test'];
    return withCardDataLoading<{ subscription: Subscription; plan: SubscriptionPlan }, Original>({
      data,
      loading: subscriptionState.loading || planState.loading,
      forceRefresh,
      hideBack: true,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageSubscriptions.SUBSCRIPTION_VIEW_COMPONENTS_SUBSCRIPTION_CARD_TITLE}
          values={{ id: props.subscriptionId }}
        />
      ),
      CardProps: {
        extra: (
          <Space>
            <SubscriptionsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="text_icon" />
            <OperationRefreshSubscription
              subscriptionId={props.subscriptionId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withSubscriptionDataLoading;
