import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementsPayoutBatchViewLink } from '@/pages/settlements/routes';

import type { PayoutBatchViewLinkProps } from './types';

const PayoutBatchViewLink: React.FC<PayoutBatchViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={settlementsPayoutBatchViewLink(value.payoutId, value.batchNum)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageSettlements.PAYOUT_BATCH_VIEW_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const PayoutBatchViewLinkMemo = React.memo(PayoutBatchViewLink);

export default PayoutBatchViewLinkMemo;
