import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementsScheduleLink } from '@/pages/settlements/routes';

import type { SettlementScheduleLinkProps } from './types';

const SettlementScheduleLink: React.FC<SettlementScheduleLinkProps> = ({ title, assetId, ...props }) => (
  <Link
    to={settlementsScheduleLink(assetId)}
    title={title ?? <FormattedMessage id={I18nPageSettlements.SCHEDULE_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SettlementScheduleLinkMemo = React.memo(SettlementScheduleLink);

export default SettlementScheduleLinkMemo;
