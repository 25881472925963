import { Space } from 'antd';
import React, { useMemo, useRef } from 'react';

import type { LinkMode, OperationMode } from '@/components';
import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { DonationsTable, DonationsDocumentationLink } from '@/features/donations/components';
import { useDonationListView } from '@/features/donations/hooks';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { useBounds } from '@/hooks';
import DonationAddLink from '@/pages/donations/donation-add/components/DonationAddLink';
import DonationsTableOperations from '@/pages/donations/donations/components/DonationsTableOperations';

import type { DonationsCardProps } from './types';
import type { FC } from 'react';

const DonationsCard: FC<DonationsCardProps> = (props) => {
  const parameters = useDonationListView();
  const ref = useRef<HTMLDivElement>(null);
  const size = useBounds(ref);
  const documentationLinkMode: LinkMode = useMemo(
    () => ((size?.width ?? 0) < 610 ? 'icon' : 'text_icon'),
    [size?.width],
  );
  const createMode: OperationMode = useMemo(() => ((size?.width ?? 0) < 510 ? 'inline' : 'button'), [size?.width]);

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPageDonations.DONATIONS_COMPONENTS_DONATIONS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <DonationsDocumentationLink
            data-test={dataTest && `${dataTest}-documentationLink`}
            mode={documentationLinkMode}
          />
          <DonationAddLink data-test={dataTest && `${dataTest}-create`} mode={createMode} />
        </Space>
      }
    >
      <DonationsTable {...parameters} {...props} Operations={DonationsTableOperations} />
    </PageCard>
  );
};

const DonationsCardMemo = React.memo(DonationsCard);

export default DonationsCardMemo;
