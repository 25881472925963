import { FormOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSubscriptionPlanActions } from '@/features/subscription-plans/hooks';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { subscriptionPlanEditLink } from '@/pages/subscriptions/routes';

import type { SubscriptionPlanEditLinkProps } from './types';

const SubscriptionPlanEditLink: React.FC<SubscriptionPlanEditLinkProps> = ({
  'data-test': dataTest,
  value,
  title,
  ...props
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useMemo(() => (value ? () => openForm(subscriptionPlanEditLink(value)) : noop), [openForm, value]);
  const { update: updateAction } = useSubscriptionPlanActions(value);
  const disableReason = useMemo(() => {
    if (updateAction.unavailabilityReason === 'invalid-status') {
      return <FormattedMessage id={I18nPageSubscriptions.PLAN_EDIT_COMPONENTS_DISABLED_REASON_INVALID_STATUS} />;
    }
    return undefined;
  }, [updateAction.unavailabilityReason]);
  return (
    <Operation
      data-test={dataTest}
      {...props}
      disabled={!updateAction.available}
      disabledMessage={disableReason}
      onClick={doOpen}
      icon={<FormOutlined />}
      title={title ?? <FormattedMessage id={I18nPageSubscriptions.PLAN_EDIT_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const SubscriptionPlanEditLinkMemo = React.memo(SubscriptionPlanEditLink);

export default SubscriptionPlanEditLinkMemo;
