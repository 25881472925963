import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useReport } from '@/features/reports/hooks';
import { I18nFeatureReports } from '@/generated/i18n/i18n';

import type { OperationRefreshReportProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_REFRESH_REPORT_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_REFRESH_REPORT_SUCCESS_MESSAGE} />,
  },
  error: {
    message: <FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_REFRESH_REPORT_FAILED_MESSAGE} />,
  },
};

const OperationRefreshReportRaw: React.FC<OperationRefreshReportProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  reportId,
}) => {
  const { forceRefresh } = useReport(reportId);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshReport = React.memo(OperationRefreshReportRaw);

export default OperationRefreshReport;
