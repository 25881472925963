import React, { useMemo } from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import { AssetItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import SettlementReconciliationStatusFilterItem from '@/features/settlement-reconciliations/components/SettlementReconciliationFilterForm/components/SettlementReconciliationStatusFilterItem';
import type { SettlementFilterPredicate } from '@/features/settlements/types';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { SettlementStatusFilterItem } from './components';

import type { SettlementFilterFormProps } from './types';

const baseFields = [
  nameof<SettlementFilterPredicate>('withdrawnAtRange'),

  nameof<SettlementFilterPredicate>('statuses'),
  nameof<SettlementFilterPredicate>('reconciliationStatusIn'),
];

const reconciliationStatusProps = {
  label: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_FILTER_FORM_RECONCILIATION_LABEL} />,
};

const dateTimeRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_FILTER_FORM_SETTLED_AT_TO_LABEL} />,
  },
  from: {
    label: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_FILTER_FORM_SETTLED_AT_FROM_LABEL} />,
  },
};

const SettlementFilterForm: React.FC<SettlementFilterFormProps> = (props) => {
  const { 'data-test': dataTest, excludeAsset, readonly } = props;
  const fields = useMemo(
    () => [...baseFields, ...(excludeAsset ? [] : [nameof<SettlementFilterPredicate>('asset')])],
    [excludeAsset],
  );
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<SettlementFilterPredicate> fields={fields} {...props}>
      <DateTimeRangeItem<SettlementFilterPredicate>
        data-test={dataTest && `${dataTest}-settledAt`}
        name={nameof<SettlementFilterPredicate>('withdrawnAtRange')}
        messages={dateTimeRangeMessages}
        readonly={readonly}
        required={false}
        relativeUpdateKey="withdrawnAtRelative"
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<SettlementFilterPredicate>('asset')}
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
      />
      <SettlementStatusFilterItem
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<SettlementFilterPredicate>('statuses')}
        readonly={readonly}
        required={false}
      />
      <SettlementReconciliationStatusFilterItem
        data-test={dataTest && `${dataTest}-reconciliationStatus`}
        name={nameof<SettlementFilterPredicate>('reconciliationStatusIn')}
        readonly={readonly}
        required={false}
        ItemProps={reconciliationStatusProps}
      />
    </FilterForm>
  );
};

const SettlementFilterFormMemo = React.memo(SettlementFilterForm);

export default SettlementFilterFormMemo;
