import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { acceptTermsOfService, initTermsOfService } from '@/features/auth/actions';
import { makeSelectTermsOfServiceAcceptanceState } from '@/features/auth/selectors';
import { useFetching } from '@/hooks';
import { InitStatus } from '@/infrastructure/model';

export interface UseTermsOfServiceType {
  accept: () => Promise<void>;
  accepted: boolean;
  initialized: boolean;
}

const dataSelector = makeSelectTermsOfServiceAcceptanceState();

export default function useTermsOfService(): UseTermsOfServiceType {
  const { withVoidDispatch } = useAppDispatch();
  const init = useMemo(() => withVoidDispatch(initTermsOfService), [withVoidDispatch]);
  const accept = useMemo(() => withVoidDispatch(acceptTermsOfService), [withVoidDispatch]);
  const { value: accepted, status } = useAppSelector(dataSelector);
  const initialized = status === InitStatus.FINISHED;
  useFetching(init, !initialized);

  return { initialized, accepted, accept };
}
