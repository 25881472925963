import { createAppAsyncThunk } from '@/app/actions';
import { withAPICall } from '@/infrastructure/api';
import { mapLoadingState } from '@/infrastructure/model';
import { identity } from '@/infrastructure/utils/functions';

import { queryTemporaryURL } from './api';
import { NAMESPACE } from './types';

export const generateUploadURL = createAppAsyncThunk(
  `${NAMESPACE}/generateUploadURL`,
  async (type: string, { signal }) =>
    mapLoadingState(
      await withAPICall(queryTemporaryURL, 'unable to generate link')(type, {
        signal,
      }),
      identity,
    ),
  { idGenerator: identity },
);
