import { shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import { flatmapStoredState, type LoadingStateWithDirty, mapStoredState } from '@/infrastructure/model';
import type { ListColumnState, ListSortBy } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';
import { toMultipleMerged } from '@/infrastructure/model/single/utils';
import { identity } from '@/infrastructure/utils/functions';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type { FullParametersState, FullState, LoadingFullDataState } from './types';

export const createFullSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Value,
  Id,
  SortBy extends string,
  Filter,
>(
  getFullState: (state: Global) => FullState<Value, Filter, SortBy>,
  type: Type,
  compareFnFactory: (sortBy: ListSortBy<SortBy>) => (a: Value, b: Value) => number,
  filterFnFactory: (filters: Filter) => (value: Value) => boolean,
  extractId: (value: Value) => Id,
  isEqual: (id1: Id, id2: Id) => boolean = (id1, id2) => id1 === id2,
) => {
  const makeSelectFullStateName = `makeSelect${type}FullState` as const;
  const makeSelectDataName = `makeSelect${type}` as const;
  const makeSelectFullDataName = `makeSelect${type}FullData` as const;
  const makeSelectFilteredDataName = `makeSelect${type}FilteredData` as const;
  const makeSelectFullSortByName = `makeSelect${type}FullSortBy` as const;
  const makeSelectFullPageName = `makeSelect${type}FullPage` as const;
  const makeSelectFullFilterName = `makeSelect${type}FullFilter` as const;
  const makeSelectFullColumnsName = `makeSelect${type}FullColumns` as const;
  const makeSelectFullParametersName = `makeSelect${type}FullParameters` as const;

  const makeSelectFullState = () => (state: Global) => getFullState(state);
  const makeSelectFullData = () => (state: Global) => getFullState(state).data;
  const makeSelectData = (id: Id) => {
    const selectFullData = makeSelectFullData();
    return createSelector(selectFullData, (data) =>
      mapStoredState(data, (values) => values.find((value) => isEqual(extractId(value), id))),
    );
  };
  const makeSelectFullSortBy = () => (state: Global) => getFullState(state).sortBy;
  const makeSelectFullPage = () => (state: Global) => getFullState(state).page;
  const makeSelectFullFilter = () => (state: Global) => getFullState(state).filter;
  const makeSelectFullColumns = () => (state: Global) => getFullState(state).columnState;

  const makeSelectFilteredData = () => {
    const selectFullData = makeSelectFullData();
    const selectFullSortBy = makeSelectFullSortBy();
    const selectFullFilter = makeSelectFullFilter();
    return createSelector(selectFullData, selectFullSortBy, selectFullFilter, (data, sortBy, filter) =>
      mapStoredState(data, (value) => value.filter(filterFnFactory(filter)).sort(compareFnFactory(sortBy))),
    );
  };

  const makeSelectFullParameters = (): ((state: Global) => FullParametersState<Filter, SortBy>) =>
    createSelector(
      makeSelectFullPage(),
      makeSelectFullFilter(),
      makeSelectFullColumns(),
      makeSelectFullSortBy(),
      (page, filter, columnState, sortBy) => ({
        page,
        filter,
        columnState,
        sortBy,
      }),
    );

  // FIXME: redefine the type without the cast
  return {
    [makeSelectFullStateName]: makeSelectFullState,
    [makeSelectFullDataName]: makeSelectFullData,
    [makeSelectDataName]: makeSelectData,
    [makeSelectFilteredDataName]: makeSelectFilteredData,
    [makeSelectFullSortByName]: makeSelectFullSortBy,
    [makeSelectFullPageName]: makeSelectFullPage,
    [makeSelectFullFilterName]: makeSelectFullFilter,
    [makeSelectFullColumnsName]: makeSelectFullColumns,
    [makeSelectFullParametersName]: makeSelectFullParameters,
  } as Record<typeof makeSelectFullStateName, typeof makeSelectFullState> &
    Record<typeof makeSelectFullDataName, typeof makeSelectFullData> &
    Record<typeof makeSelectDataName, typeof makeSelectData> &
    Record<typeof makeSelectFilteredDataName, typeof makeSelectFilteredData> &
    Record<typeof makeSelectFullSortByName, typeof makeSelectFullSortBy> &
    Record<typeof makeSelectFullPageName, typeof makeSelectFullPage> &
    Record<typeof makeSelectFullFilterName, typeof makeSelectFullFilter> &
    Record<typeof makeSelectFullColumnsName, typeof makeSelectFullColumns> &
    Record<typeof makeSelectFullParametersName, typeof makeSelectFullParameters>;
};

export const createNormalizedFullSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Value,
  SortBy extends string,
  Filter,
  ParentId,
  Full extends Omit<FullState<string, Filter, SortBy>, 'columnState'> = Omit<
    FullState<string, Filter, SortBy>,
    'columnState'
  >,
>(
  getFullState: (state: Global, parentId: ParentId | undefined) => Full,
  getColumnState: (state: Global) => ListColumnState,
  getSingleState: (state: Global) => SingleState<Value>,
  type: Type,
  compareFnFactory: (sortBy: ListSortBy<SortBy>) => (a: Value, b: Value) => number,
  filterFnFactory: (filters: Filter) => (value: Value) => boolean,
) => {
  const makeSelectFullStateName = `makeSelect${type}FullState` as const;
  const makeSelectFullDataName = `makeSelect${type}FullData` as const;
  const makeSelectFilteredDataName = `makeSelect${type}FilteredData` as const;
  const makeSelectFullSortByName = `makeSelect${type}FullSortBy` as const;
  const makeSelectFullPageName = `makeSelect${type}FullPage` as const;
  const makeSelectFullFilterName = `makeSelect${type}FullFilter` as const;
  const makeSelectFullColumnsName = `makeSelect${type}FullColumns` as const;
  const makeSelectFullParametersName = `makeSelect${type}FullParameters` as const;

  const makeSelectFullState = (parentId: ParentId | undefined) => (state: Global) => getFullState(state, parentId);

  const makeSelectFullData = (parentId: ParentId | undefined) => {
    let cache: (LoadingStateWithDirty<Value> | undefined)[] | undefined;
    let cachedList: LoadingFullDataState<string> | undefined;
    let result: LoadingFullDataState<Value> | undefined;
    return createSelector(
      (state: Global) => getFullState(state, parentId).data,
      (state: Global) => getSingleState(state),
      (full, entities): LoadingFullDataState<Value> => {
        if (result && cachedList === full) {
          if (shallowEqual(full.data?.map((id) => entities[id]) ?? [], cache)) {
            return result;
          }
        }
        cache = full.data?.map((id) => entities[id]) ?? [];
        cachedList = full;
        result = flatmapStoredState(full, (fullData) => ({
          ...mapStoredState(toMultipleMerged<Value>(entities, fullData), identity),
        }));
        return result;
      },
    );
  };
  const makeSelectFullPage = (parentId: ParentId | undefined) => (state: Global) => getFullState(state, parentId).page;
  const makeSelectFullSortBy = (parentId: ParentId | undefined) => (state: Global) =>
    getFullState(state, parentId).sortBy;
  const makeSelectFullFilter = (parentId: ParentId | undefined) => (state: Global) =>
    getFullState(state, parentId).filter;
  const makeSelectFullColumns = () => getColumnState;

  const makeSelectFilteredData = (parentId: ParentId | undefined) => {
    const selectFullData = makeSelectFullData(parentId);
    const selectFullSortBy = makeSelectFullSortBy(parentId);
    const selectFullFilter = makeSelectFullFilter(parentId);
    return createSelector(selectFullData, selectFullSortBy, selectFullFilter, (data, sortBy, filter) =>
      mapStoredState(data, (value) => value.filter(filterFnFactory(filter)).sort(compareFnFactory(sortBy))),
    );
  };

  const makeSelectFullParameters = (
    parentId: ParentId | undefined,
  ): ((state: Global) => FullParametersState<Filter, SortBy>) =>
    createSelector(
      makeSelectFullPage(parentId),
      makeSelectFullFilter(parentId),
      makeSelectFullColumns(),
      makeSelectFullSortBy(parentId),
      (page, filter, columnState, sortBy) => ({
        page,
        filter,
        columnState,
        sortBy,
      }),
    );

  // FIXME: redefine the type without the cast
  return {
    [makeSelectFullStateName]: makeSelectFullState,
    [makeSelectFullDataName]: makeSelectFullData,
    [makeSelectFilteredDataName]: makeSelectFilteredData,
    [makeSelectFullSortByName]: makeSelectFullSortBy,
    [makeSelectFullPageName]: makeSelectFullPage,
    [makeSelectFullFilterName]: makeSelectFullFilter,
    [makeSelectFullColumnsName]: makeSelectFullColumns,
    [makeSelectFullParametersName]: makeSelectFullParameters,
  } as Record<typeof makeSelectFullStateName, typeof makeSelectFullState> &
    Record<typeof makeSelectFullDataName, typeof makeSelectFullData> &
    Record<typeof makeSelectFilteredDataName, typeof makeSelectFilteredData> &
    Record<typeof makeSelectFullSortByName, typeof makeSelectFullSortBy> &
    Record<typeof makeSelectFullPageName, typeof makeSelectFullPage> &
    Record<typeof makeSelectFullFilterName, typeof makeSelectFullFilter> &
    Record<typeof makeSelectFullColumnsName, typeof makeSelectFullColumns> &
    Record<typeof makeSelectFullParametersName, typeof makeSelectFullParameters>;
};
