import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import PaymentStatusView from '@/features/payments/components/PaymentStatusView';
import { PaymentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { PaymentStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const PaymentStatusFilterItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: PaymentStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_PAYMENT_FILTER_STATUS_LABEL} />}
    {...ItemProps}
    name={name}
  >
    <Select
      disabled={readonly}
      data-test={dataTest}
      placeholder={
        !readonly ? <FormattedMessage id={I18nFeaturePayments.COMPONENTS_PAYMENT_FILTER_STATUS_PLACEHOLDER} /> : null
      }
      mode="multiple"
      options={useMemo(
        () =>
          enumValues(PaymentStatusAPIModel).map((code) => ({
            value: code,
            label: (
              <PaymentStatusView data-test={dataTest && `${dataTest}-${code}`} key={code} value={code} mode="full" />
            ),
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const PaymentStatusFilterItem = React.memo(PaymentStatusFilterItemRaw) as typeof PaymentStatusFilterItemRaw;

export default PaymentStatusFilterItem;
