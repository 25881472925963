import { PageContainer } from '@/components';
import { CollectTaskForAddressesFilterForm } from '@/features/collectable/components';
import { RechargesDocumentationBanner, RechargeTransactionFilterForm } from '@/features/recharges/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import CollectTaskLink from '@/pages/collectable/task-view/components/CollectTaskLink';
import RechargeTransactionViewLink from '@/pages/recharges/transaction-view/components/RechargeTransactionLink';

import {
  RechargeAddressCard,
  RechargeAddressCollectTasksCard,
  RechargeAddressDeployTransactionCard,
  RechargeAddressTransactionsCard,
  RechargeAddressViewBreadcrumb,
} from './components';
import { withAddressPageParams } from './hocs';

import type React from 'react';

type RechargeAddressViewPageProps = TestableProps & { pageParams: { addressId: string } };

const RechargeAddressViewPage: React.FC<RechargeAddressViewPageProps> = ({
  'data-test': dataTest = 'address',
  pageParams: { addressId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <RechargeAddressViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} addressId={addressId} disabled />
    }
  >
    <RechargesDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <RechargeAddressCard data-test={dataTest && `${dataTest}-card`} addressId={addressId} />
    <RechargeAddressTransactionsCard
      data-test={dataTest && `${dataTest}-transactions`}
      addressId={addressId}
      RechargeTransactionLink={RechargeTransactionViewLink}
      FilterForm={RechargeTransactionFilterForm}
    />
    <RechargeAddressDeployTransactionCard data-test={dataTest && `${dataTest}-deploy`} addressId={addressId} />
    <RechargeAddressCollectTasksCard
      data-test={dataTest && `${dataTest}-tasks`}
      addressId={addressId}
      CollectTaskLink={CollectTaskLink}
      FilterForm={CollectTaskForAddressesFilterForm}
    />
  </PageContainer>
);

export default withAddressPageParams(RechargeAddressViewPage);
