import type { EmptyObject } from '@/infrastructure/utils/ts';

import type { FormItemProps as BaseFormItemProps } from 'antd/lib/form';
import type React from 'react';

export interface TestableProps {
  'data-test'?: string;
}

export interface StyledProps extends TestableProps {
  style?: React.CSSProperties;
  className?: string;
}

export type SVGProps = React.ComponentProps<'svg'> & TestableProps;

export interface FormItemProps<Values> extends TestableProps {
  name: NonNullable<BaseFormItemProps<Values>['name']>;
  ItemProps?: Omit<BaseFormItemProps<Values>, 'name'>;
}

export interface FormItemInputProps<Values> extends TestableProps {
  onChange?: (value?: Values) => unknown;
  value?: Values;
}

export interface FormItemWithRulesProps<Values> extends TestableProps {
  name: NonNullable<BaseFormItemProps<Values>['name']>;
  rules?: BaseFormItemProps<Values>['rules'];
  ItemProps?: Omit<BaseFormItemProps<Values>, 'name' | 'rules'>;
}

export const namedHOC =
  <
    Source extends EmptyObject = EmptyObject,
    Result extends EmptyObject = Source,
    C extends Pick<React.FC<Result>, 'displayName'> = React.FC<Result>,
  >(
    WrapperComponent: React.ComponentType<Source>,
    hocName: string,
  ) =>
  (Component: C): C => {
    // noinspection UnnecessaryLocalVariableJS
    const Wrapped: C = Component;
    Wrapped.displayName = `${hocName}(${WrapperComponent.displayName || WrapperComponent.name})`;
    return Component;
  };
