export const recaptchaError = () => new Error('RecaptchaFailed');

export const isRecaptchaError = (e: unknown) =>
  e === 'RecaptchaException'
  || e === 'RecaptchaFailed'
  || (!!e
    && typeof e === 'object'
    && 'message' in e
    && (e.message === 'RecaptchaException' || e.message === 'RecaptchaFailed'));

export const mockedRecaptchaToken = 'recaptcha-disabled';
export const executeRecaptchaMocked = async () => Promise.resolve(mockedRecaptchaToken);
