import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';

import ReportScheduleLink from '../ReportSchedulesLink';

import type { ReportSchedulesBreadcrumbProps } from './types';

const ReportSchedulesBreadcrumbRaw: React.FC<ReportSchedulesBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  items = [],
}) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageReports.REPORT_SCHEDULES_COMPONENTS_BREADCRUMB} />
        ) : (
          <ReportScheduleLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            title={<FormattedMessage id={I18nPageReports.REPORT_SCHEDULES_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const ReportSchedulesBreadcrumb = React.memo(ReportSchedulesBreadcrumbRaw);

export default ReportSchedulesBreadcrumb;
