import type {
  SubscriptionPlanAPIModel,
  SubscriptionPlanPredicatesAPIModel,
  NewSubscriptionPlanAPIModel,
  UpdateSubscriptionPlanAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { SubscriptionPlanSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import type { ListSortBy, ListState, UpdateListParametersPayload } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'subscription-plans';

export interface NewSubscriptionPlan extends Omit<NewSubscriptionPlanAPIModel, 'amount' | 'tags'> {
  amount: AssetAmountValue;
  tags: string[];
}

export interface UpdateSubscriptionPlan extends Omit<UpdateSubscriptionPlanAPIModel, 'amount' | 'tags'> {
  amount: AssetAmountValue;
  tags: string[];
}

export interface SubscriptionPlan extends Omit<SubscriptionPlanAPIModel, 'amount' | 'tags'> {
  amount: AssetAmountValue;
  tags: string[];
}

export interface SubscriptionPlanExtended extends SubscriptionPlan {
  isGasWalletStateInvalid: boolean;
}

export type SubscriptionPlanFilterPredicate = SubscriptionPlanPredicatesAPIModel;

export type SubscriptionPlanUpdateListParametersPayload = UpdateListParametersPayload<
  SubscriptionPlanFilterPredicate,
  SubscriptionPlanSortByAPIModel
>;

export type SubscriptionPlanSortBy = ListSortBy<SubscriptionPlanSortByAPIModel>;

export const defaultSortBy: SubscriptionPlanSortBy = { [SubscriptionPlanSortByAPIModel.CreatedAt]: 'DESC' };

export interface SubscriptionPlanState {
  list: ListState<string, SubscriptionPlanFilterPredicate, SubscriptionPlanSortByAPIModel>;
  entities: SingleState<SubscriptionPlan>;
}
