import { Card } from 'antd';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { BrandingProfileForm } from '@/features/branding/components';
import type { BrandingProfileFormValues } from '@/features/branding/components/BrandingProfileForm/types';
import { useBrandingProfilesActions } from '@/features/branding/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useDefaultNotification, useFormPageAction, useNotification, useStateMountSafe } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';
import { settingsBrandingProfilesLink } from '@/pages/settings/routes';

import { BrandingProfileAddBreadcrumb } from './components';
import { usePrepareInitialData } from './hooks';

import type React from 'react';

type BrandingProfileAddPageProps = TestableProps;

const BrandingProfileAddPage: React.FC<BrandingProfileAddPageProps> = ({ 'data-test': dataTest = 'profile-add' }) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const { data: initialValues } = usePrepareInitialData(params.get('copy-of') ?? undefined);
  const { create: createAction } = useBrandingProfilesActions();
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(settingsBrandingProfilesLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  const doCreate = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(
            ({ name, ...data }: BrandingProfileFormValues) => createAction.act({ name, data }),
            () => <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_ADD_MESSAGES_SUCCESS} />,
          ),
          () => <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_ADD_MESSAGES_ERROR} />,
        ),
        doRedirect,
      ),
    [withDefaultError, withSuccess, doRedirect, createAction],
  );

  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  return (
    <SettingsPageLayout
      data-test="container"
      selected={SettingsTabType.branding}
      breadcrumb={<BrandingProfileAddBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={submit}
      cancel={doRedirect}
    >
      <Card loading={!initialValues}>
        {initialValues && (
          <BrandingProfileForm
            data-test={dataTest && `${dataTest}-form`}
            initialValues={initialValues}
            onSubmit={doCreate}
            onReset={doRedirect}
            submitCallback={updateOnSubmit}
          />
        )}
      </Card>
    </SettingsPageLayout>
  );
};

export default BrandingProfileAddPage;
