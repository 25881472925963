import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationAddDomain, OperationRefreshMerchantDomains } from '@/features/branding/components';
import { useDomains } from '@/features/branding/hooks';
import type { MerchantDomain } from '@/features/branding/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDomainsDataLoading = <T extends { data: MerchantDomain[] } & TestableProps>(
  Component: React.ComponentType<Omit<T, 'data'> & { data: MerchantDomain[] }>,
): React.FC<Omit<T, 'data'>> =>
  namedHOC<Omit<T, 'data'> & { data: MerchantDomain[] }, Omit<T, 'data'>>(
    Component,
    'WithDomainsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useDomains();
    return withCardDataLoading<MerchantDomain[], Omit<T, 'data'> & { data: MerchantDomain[] }>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <OperationAddDomain key="create" data-test={dataTest && `${dataTest}-createOp`} mode="button" />
            <OperationRefreshMerchantDomains data-test={dataTest && `${dataTest}-refreshOp`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withDomainsDataLoading;
