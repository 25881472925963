import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayouts } from '@/features/payouts/actions';
import { makeSelectPayoutListData, makeSelectPayoutListParameters } from '@/features/payouts/selectors';
import type { PayoutSummary } from '@/features/payouts/types';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

import type { UsePayoutList } from './types';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<PayoutSummary>, Parameters<typeof fetchPayouts>[0]> =>
  fetchPayouts({ force });

const dataSelector = makeSelectPayoutListData();
const dataFetchingSelector = makeSelectPending(fetchPayouts);
const parametersSelector = makeSelectPayoutListParameters();

export default function usePayoutList(): UsePayoutList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
