import React from 'react';

import { SupportEmail } from '@/components';

import type { GasWalletSupportEmailProps } from './types';

const GasWalletSupportEmail: React.FC<GasWalletSupportEmailProps> = ({ 'data-test': dataTest, subject, title }) => (
  <SupportEmail data-test={dataTest} title={title} subject={`GW: ${subject}`} />
);

const GasWalletSupportEmailMemo = React.memo(GasWalletSupportEmail);

export default GasWalletSupportEmailMemo;
