import { useAppNestedListData } from '@/app/hooks';
import { noIdNestedListFetchResultFn } from '@/app/hooks/useAppNestedListData';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectTasksForAddresses } from '@/features/collectable/actions';
import {
  makeSelectCollectTasksForAddressesListData,
  makeSelectCollectTasksForAddressesListParameters,
} from '@/features/collectable/selectors';
import type { CollectTaskSummary } from '@/features/collectable/types';
import { collectableTaskLinksToId } from '@/features/collectable/utils';
import { makeSelectPending } from '@/features/global/selectors';
import type { PushAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

const fetchFactory =
  (addresses: PushAddressLinkAPIModel[]) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<
    LoadingListDataState<CollectTaskSummary>,
    Parameters<typeof fetchCollectTasksForAddresses>[0]
  > =>
    fetchCollectTasksForAddresses({ addresses, force });

const pendingSelectorCreator = (addresses: PushAddressLinkAPIModel[]) =>
  makeSelectPending(fetchCollectTasksForAddresses, collectableTaskLinksToId(addresses));

export default function useCollectTaskSummaryForAddressesListView(addresses: PushAddressLinkAPIModel[] | undefined) {
  return useAppNestedListData(
    fetchFactory,
    makeSelectCollectTasksForAddressesListParameters,
    pendingSelectorCreator,
    makeSelectCollectTasksForAddressesListData,
    noIdNestedListFetchResultFn(),
    addresses,
  );
}
