import { useCallback, useMemo } from 'react';

import { useAppNestedListData } from '@/app/hooks';
import { noIdNestedListFetchResultFn } from '@/app/hooks/useAppNestedListData';
import type { AppAsyncThunkAction } from '@/app/store';
import { useMerchantGasWallets } from '@/features/gas-wallets/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSubscriptionsForPlan } from '@/features/subscriptions/actions';
import {
  makeSelectSubscriptionsForPlanListData,
  makeSelectSubscriptionsForPlanListParameters,
} from '@/features/subscriptions/selectors';
import type { Subscription } from '@/features/subscriptions/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';
import { mapListState } from '@/infrastructure/model/list/utils';

const fetchFactory =
  (planId: string) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<LoadingListDataState<Subscription>, Parameters<typeof fetchSubscriptionsForPlan>[0]> =>
    fetchSubscriptionsForPlan({ planId, force });

const pendingSelectorCreator = (planId: string) => makeSelectPending(fetchSubscriptionsForPlan, planId);

export default function useSubscriptionForPlanList(planId: string | undefined) {
  const wallets = useMerchantGasWallets();
  const isGasWalletStateInvalid = useCallback(
    (bt: BlockchainTypeAPIModel) => {
      const wallet = wallets.data.data?.find((w) => w.bt === bt);
      return !wallet?.isReadyForAction;
    },
    [wallets.data],
  );

  const { data: rawData, ...rest } = useAppNestedListData(
    fetchFactory,
    makeSelectSubscriptionsForPlanListParameters,
    pendingSelectorCreator,
    makeSelectSubscriptionsForPlanListData,
    noIdNestedListFetchResultFn(),
    planId,
  );
  const data = useMemo(
    () => mapListState(rawData, (d) => ({ ...d, isGasWalletStateInvalid: isGasWalletStateInvalid(d.blockchain) })),
    [isGasWalletStateInvalid, rawData],
  );
  return { ...rest, data };
}
