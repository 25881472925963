import { Breadcrumb } from 'antd';
import React from 'react';

import { I18nMenu } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';
import Link from '../Link';

import { createHomeLink } from './utils';

import type { HomeBreadcrumbProps } from './types';

const HomeBreadcrumb: React.FC<HomeBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <Breadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nMenu.HOME} />
        ) : (
          <Link mode="text" to={createHomeLink()} title={<FormattedMessage id={I18nMenu.HOME} />} />
        ),
      },
      ...items,
    ]}
  />
);

const HomeBreadcrumbMemo = React.memo(HomeBreadcrumb);

export default HomeBreadcrumbMemo;
