import { Form } from 'antd';
import React from 'react';

import { defaultDialogFormLayout } from '@/components';
import type { ReportScheduleParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/schedules';

import SettlementReconciliationScheduleParametersForm from '../SettlementReconciliationScheduleParametersForm';

const SettlementReconciliationScheduleParametersView: React.FC<ReportScheduleParametersViewProps> = ({
  value,
  'data-test': dataTest,
}) => (
  <Form initialValues={{ parameters: value }} {...defaultDialogFormLayout}>
    <SettlementReconciliationScheduleParametersForm data-test={dataTest} name="parameters" readonly />
  </Form>
);

const SettlementReconciliationScheduleParametersViewMemo = React.memo(SettlementReconciliationScheduleParametersView);

export default SettlementReconciliationScheduleParametersViewMemo;
