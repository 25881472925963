/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CompanyApiKeyAPIModel } from './CompanyApiKeyAPIModel';
import {
    CompanyApiKeyAPIModelFromJSON,
    CompanyApiKeyAPIModelFromJSONTyped,
    CompanyApiKeyAPIModelToJSON,
} from './CompanyApiKeyAPIModel';

/**
 * 
 * @export
 * @interface CompanyApiKeysSliceAPIModel
 */
export interface CompanyApiKeysSliceAPIModel {
    /**
     * 
     * @type {Array<CompanyApiKeyAPIModel>}
     * @memberof CompanyApiKeysSliceAPIModel
     */
    data?: Array<CompanyApiKeyAPIModel>;
}

/**
 * Check if a given object implements the CompanyApiKeysSliceAPIModel interface.
 */
export function instanceOfCompanyApiKeysSliceAPIModel(value: object): boolean {
    return true;
}

export function CompanyApiKeysSliceAPIModelFromJSON(json: any): CompanyApiKeysSliceAPIModel {
    return CompanyApiKeysSliceAPIModelFromJSONTyped(json, false);
}

export function CompanyApiKeysSliceAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyApiKeysSliceAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'] == null ? undefined : ((json['data'] as Array<any>).map(CompanyApiKeyAPIModelFromJSON)),
    };
}

export function CompanyApiKeysSliceAPIModelToJSON(value?: CompanyApiKeysSliceAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'] == null ? undefined : ((value['data'] as Array<any>).map(CompanyApiKeyAPIModelToJSON)),
    };
}

