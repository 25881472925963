import React from 'react';

import { FormattedMessage } from '@/components';
import { NativeAssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import GasWalletLink from '@/pages/settings/company/gas-wallet/components/GasWalletLink';

import type { GasWalletDerivedBalanceMessageProps } from './types';
import type { ReactNode } from 'react';

const GasWalletDerivedBalanceMessage: React.FC<GasWalletDerivedBalanceMessageProps> = ({
  'data-test': dataTest,
  wallet,
  mode,
}) =>
  // eslint-disable-next-line no-nested-ternary
  mode === 'short' ? (
    <FormattedMessage
      data-test={dataTest}
      id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_DERIVED_BALANCE_SHORT}
    />
  ) : mode === 'tag' ? (
    <FormattedMessage
      data-test={dataTest}
      id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_DERIVED_BALANCE_TAG}
    />
  ) : (
    <FormattedMessage
      id={I18nFeatureGasWallets.COMPONENTS_INVALID_STATE_MESSAGE_DERIVED_BALANCE_FULL}
      values={{
        amount: (
          <NativeAssetAmount style={{ color: 'inherit' }} value={wallet.lowWatermark} bt={wallet.bt} withCurrency />
        ),
        ln: (text: ReactNode) => (
          <GasWalletLink data-test={dataTest && `${dataTest}-link`} value={wallet.bt} mode="text" title={text} />
        ),
      }}
    />
  );

const GasWalletDerivedBalanceMessageMemo = React.memo(GasWalletDerivedBalanceMessage);

export default GasWalletDerivedBalanceMessageMemo;
