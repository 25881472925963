import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationAddCompanyUser, OperationRefreshCompanyUsers } from '@/features/company/components';
import { useCompanyUsers } from '@/features/company/hooks';
import type { CompanyUser } from '@/features/company/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { namedHOC, type TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withUsersDataLoading = <
  Original extends { data: CompanyUser[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithUsersDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useCompanyUsers();
    return withCardDataLoading<CompanyUser[], Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.PERMISSIONS_COMPONENTS_USERS_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <OperationAddCompanyUser key="create" data-test={dataTest && `${dataTest}-addOp`} mode="button" />
            <OperationRefreshCompanyUsers data-test={dataTest && `${dataTest}-refreshOp`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withUsersDataLoading;
