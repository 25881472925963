import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { createMerchantDomain, removeMerchantDomain, updateMerchantDomainState } from '@/features/branding/actions';
import type { MerchantDomain } from '@/features/branding/types';
import { useActionPending } from '@/features/global/hooks';
import { type HookAction } from '@/infrastructure/model';

import useDomains from './useDomains';

export type CreateDomainUnavailabilityReason = 'too-many-domains';

export interface UseDomainsActions {
  create: HookAction<[string], MerchantDomain, CreateDomainUnavailabilityReason>;
  activate: HookAction<[string], MerchantDomain[]>;
  deactivate: HookAction<[string], MerchantDomain[]>;
  remove: HookAction<[string], MerchantDomain[]>;
}

const MAX_DOMAINS = 10;

export default function useMerchantDomainsActions(): UseDomainsActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const {
    data: { data: domains },
  } = useDomains();
  const createPending = useActionPending(createMerchantDomain);
  const createUnavailabilityReason: CreateDomainUnavailabilityReason | undefined =
    domains?.length && domains.length >= MAX_DOMAINS ? 'too-many-domains' : undefined;
  const createAction: UseDomainsActions['create']['act'] = useCallback(
    (url: string) => withExtractDataDispatch(createMerchantDomain)({ url }),
    [withExtractDataDispatch],
  );
  const create: UseDomainsActions['create'] = useMemo(
    () => ({
      act: createAction,
      available: !createUnavailabilityReason,
      unavailabilityReason: createUnavailabilityReason,
      inAction: createPending,
    }),
    [createAction, createUnavailabilityReason, createPending],
  );

  const updatePending = useActionPending(updateMerchantDomainState);
  const activateAction: UseDomainsActions['activate']['act'] = useCallback(
    (domainId) => withExtractDataDispatch(updateMerchantDomainState)({ id: domainId, activate: true }),
    [withExtractDataDispatch],
  );
  const activate: UseDomainsActions['activate'] = useMemo(
    () => ({ act: activateAction, available: true, inAction: updatePending }),
    [activateAction, updatePending],
  );

  const deactivateAction: UseDomainsActions['deactivate']['act'] = useCallback(
    (domainId) => withExtractDataDispatch(updateMerchantDomainState)({ id: domainId, activate: false }),
    [withExtractDataDispatch],
  );
  const deactivate: UseDomainsActions['deactivate'] = useMemo(
    () => ({ act: deactivateAction, available: true, inAction: updatePending }),
    [deactivateAction, updatePending],
  );

  const removePending = useActionPending(removeMerchantDomain);
  const removeAction: UseDomainsActions['remove']['act'] = useCallback(
    (domainId) => withExtractDataDispatch(removeMerchantDomain)({ id: domainId }),
    [withExtractDataDispatch],
  );
  const remove: UseDomainsActions['remove'] = useMemo(
    () => ({ act: removeAction, available: true, inAction: removePending }),
    [removeAction, removePending],
  );
  return { create, activate, deactivate, remove };
}
