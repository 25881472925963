import { Typography } from 'antd';
import React from 'react';

import type { SupportEmailProps } from './types';

const { Link } = Typography;

const SupportEmail: React.FC<SupportEmailProps> = ({ 'data-test': dataTest, subject, title, companyId }) => (
  <Link
    data-test={dataTest}
    href={`mailto:${window.env.SUPPORT_EMAIL}?subject=[NCPS-BO${companyId ? `, ${companyId}` : ''}]: ${subject || ''}`}
  >
    {title || window.env.SUPPORT_EMAIL}
  </Link>
);

const SupportEmailMemo = React.memo(SupportEmail);

export default SupportEmailMemo;
