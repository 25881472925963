import { WarningOutlined } from '@ant-design/icons';
import { Alert, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import OperationUpdateStatisticsAsset from '../OperationUpdateStatisticsAsset';

import type { NoExchangeRatesAlertProps } from './types';

const NoExchangeRatesAlert: React.FC<NoExchangeRatesAlertProps> = ({
  'data-test': datTest,
  onUpdate,
  mode = 'full',
}) =>
  mode === 'full' ? (
    <Alert
      data-test={datTest}
      message={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_NO_EXCHANGE_RATES_ALERT_MESSAGE} />}
      showIcon
      type="warning"
      description={<OperationUpdateStatisticsAsset mode="button" onSuccess={onUpdate} />}
    />
  ) : (
    <Tooltip
      title={
        <FormattedMessage
          id={I18nFeatureCompanySettings.COMPONENTS_NO_EXCHANGE_RATES_ALERT_TOOLTIP}
          values={{ operation: <OperationUpdateStatisticsAsset mode="icon" onSuccess={onUpdate} /> }}
        />
      }
    >
      <WarningOutlined style={{ color: 'orange' }} />
    </Tooltip>
  );

const NoExchangeRatesAlertMemo = React.memo(NoExchangeRatesAlert);

export default NoExchangeRatesAlertMemo;
