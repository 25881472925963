import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import {
  CollectTaskAddressesCard,
  CollectTaskCard,
  CollectTaskTransactionCard,
  CollectTaskBreadcrumb,
} from './components';
import { withCollectTaskPageParams } from './hocs';

import type React from 'react';

type CollectTaskViewPageProps = TestableProps & { pageParams: { taskId: string } };

const CollectTaskViewPage: React.FC<CollectTaskViewPageProps> = ({
  'data-test': dataTest = 'task',
  pageParams: { taskId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<CollectTaskBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} taskId={taskId} disabled />}
  >
    <CollectTaskCard data-test={dataTest && `${dataTest}-card`} taskId={taskId} />
    <CollectTaskAddressesCard data-test={dataTest && `${dataTest}-addresses`} taskId={taskId} />
    <CollectTaskTransactionCard data-test={dataTest && `${dataTest}-transaction`} taskId={taskId} />
  </PageContainer>
);

export default withCollectTaskPageParams(CollectTaskViewPage);
