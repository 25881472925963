import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nMenu } from '@/generated/i18n/i18n';
import type { CustomizableRoute } from '@/layouts/types';
import { BalancesIcon } from '@/pages/balances/components';
import { balancesActualLink } from '@/pages/balances/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (formatMessage: IntlFormattersArrow['formatMessage']): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.BALANCES }),
    icon: <BalancesIcon />,
    path: balancesActualLink(),
    hideChildrenInMenu: true,
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const { formatMessage } = useIntl();
  return useMemo(() => menuCreator(formatMessage), [formatMessage]);
}
