import { Form } from 'antd';

import BrandingLogoPositionSwitch from '@/features/branding/components/BrandingLogoPositionSwitch';
import type { PaymentsBranding } from '@/features/branding/types';
import { nameof } from '@/infrastructure/utils/ts';

import type { LogoPositionItemProps } from './types';
import type React from 'react';

const LogoPositionItem: React.FC<LogoPositionItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <Form.Item<PaymentsBranding>
    required={!disabled}
    name={[nameof<PaymentsBranding, 'logo'>('logo'), nameof<NonNullable<PaymentsBranding['logo']>>('position')]}
    noStyle
    label={null}
  >
    <BrandingLogoPositionSwitch data-test={dataTest} SwitchProps={{ disabled }} />
  </Form.Item>
);

export default LogoPositionItem;
