import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import CompanyBreadcrumb from '@/pages/settings/company/components/CompanyBreadcrumb';

import UpdateQRSupportLink from '../UpdateQRSupportLink';

import type { UpdateQRSupportBreadcrumbProps } from './types';

const UpdateQRSupportBreadcrumb: React.FC<UpdateQRSupportBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <CompanyBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.COMPANY_QR_SUPPORT_EDIT_BREADCRUMB_TITLE} />
        ) : (
          <UpdateQRSupportLink
            mode="link"
            title={<FormattedMessage id={I18nPageSettings.COMPANY_QR_SUPPORT_EDIT_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const UpdateQRSupportBreadcrumbMemo = React.memo(UpdateQRSupportBreadcrumb);

export default UpdateQRSupportBreadcrumbMemo;
