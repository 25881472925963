import { useCallback } from 'react';

import { useEmailAuth as useEmailAuthBase } from '@/features/auth/hooks';
import type { EmailAuthOptions } from '@/features/auth/hooks';
import { withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { cancellable } from '@/infrastructure/utils/ui';

const useGoogleAuth = ({
  onSelectProvider,
  withAuth,
}: {
  onSelectProvider: (selected?: { id: string; onCancel?: () => void }) => void;
  withAuth: <V extends unknown[] = unknown[], R = unknown>(
    func: (options: Omit<EmailAuthOptions, 'cancellationPromise'>) => Func<V, R>,
  ) => Func<V>;
}) => {
  const { loginByGoogle } = useEmailAuthBase();

  const auth = useCallback(() => {
    const cancellationPromise = cancellable((onCancel) => {
      onSelectProvider({ id: 'google', onCancel });
    });
    return withFinally(
      withAuth((options) => () => loginByGoogle.act({ cancellationPromise, ...options })),
      () => onSelectProvider(),
    )();
  }, [loginByGoogle, onSelectProvider, withAuth]);
  return { auth, isAvailable: loginByGoogle.available };
};

export default useGoogleAuth;
