import { Space } from 'antd';

import { FormattedMessage, OperationRefresh } from '@/components';
import { NoExchangeRatesAlert, OperationUpdateStatisticsAsset } from '@/features/company-settings/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { useSubscriptionsSummaryStats } from '@/features/statistics/hooks';
import type { SubscriptionSummaryStats } from '@/features/statistics/types';
import { I18nPageDashboard } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDashboardSubscriptionsDataLoading = <
  Original extends { data: SubscriptionSummaryStats } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithDashboardSubscriptionsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useSubscriptionsSummaryStats();
    return withCardDataLoading<SubscriptionSummaryStats, Original>({
      ...data,
      onError: (error, defaultComponent) =>
        error === 'NoExchangeRateFound' ? (
          <NoExchangeRatesAlert
            data-test={props['data-test'] && `${props['data-test']}-alert`}
            onUpdate={data.forceRefresh}
          />
        ) : (
          defaultComponent
        ),
      'data-test': dataTest,
      hideBack: true,
      title: (
        <Space>
          <FormattedMessage id={I18nPageDashboard.COMPONENTS_SUBSCRIPTIONS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <OperationUpdateStatisticsAsset data-test={props['data-test'] && `${props['data-test']}-opUpdateAsset`} />
            <OperationRefresh
              refresh={data.forceRefresh}
              data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withDashboardSubscriptionsDataLoading;
