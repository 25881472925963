import { BrandingProfileForm } from '@/features/branding/components';

import { withBrandingProfileDataLoading } from './hocs';

import type { BrandingProfileEditCardProps } from './types';
import type React from 'react';

const BrandingProfileEditCard: React.FC<BrandingProfileEditCardProps> = ({
  'data-test': dataTest,
  data,
  onSave,
  onCancel,
  submitCallback,
}) => (
  <BrandingProfileForm
    data-test={dataTest && `${dataTest}-form`}
    initialValues={data}
    onSubmit={onSave}
    onReset={onCancel}
    submitCallback={submitCallback}
  />
);

const BrandingProfileEditCardHOC =
  withBrandingProfileDataLoading<BrandingProfileEditCardProps>(BrandingProfileEditCard);

export default BrandingProfileEditCardHOC;
