import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import ReportScheduleAddPage from '@/pages/reports/report-schedule-add';
import ReportScheduleEditPage from '@/pages/reports/report-schedule-edit';
import ReportScheduleViewPage from '@/pages/reports/report-schedule-view';
import ReportSchedulesPage from '@/pages/reports/report-schedules';
import ReportSchedulesDefaultRoute from '@/pages/reports/report-schedules/components/ReportSchedulesDefaultRoute';
import { AppPageRoutes } from '@/pages/routes';

import ReportsPage from './all';
import ReportViewPage from './report-view';
import { ReportSchedulesRoutes, reportsLink, ReportsPageRoutes } from './routes';

import type React from 'react';

export const ReportsDefaultRoute: React.FC = () => <Navigate to={reportsLink()} replace />;

const ReportPages: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={ReportsPageRoutes.ALL} Component={ReportsPage} />
        <Route path={ReportsPageRoutes.REPORT_VIEW} Component={ReportViewPage} />
        <Route path={ReportsPageRoutes.REPORT_SCHEDULES}>
          <Route path="" Component={ReportSchedulesPage} />
          <Route path={ReportSchedulesRoutes.VIEW} Component={ReportScheduleViewPage} />
          <Route path={ReportSchedulesRoutes.EDIT} Component={ReportScheduleEditPage} />
          <Route path={ReportSchedulesRoutes.ADD} Component={ReportScheduleAddPage} />
          <Route path="*" Component={ReportSchedulesDefaultRoute} />
        </Route>
        <Route path="*" Component={ReportsDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const ReportPagesHOC: React.FC = withBasicPageGuard(ReportPages);

export default <Route key={AppPageRoutes.REPORTS} path={`${AppPageRoutes.REPORTS}/*`} Component={ReportPagesHOC} />;
