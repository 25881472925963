import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { DonationFilterForm, DonationsDocumentationBanner } from '@/features/donations/components';
import { CollectableScheduleCard } from '@/pages/collectable/components';
import DonationViewLink from '@/pages/donations/donation-view/components/DonationViewLink';

import { DonationsBreadcrumb, DonationsCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'donations';

const DonationsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<DonationsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test="kyb-banner" PageProps={{ pageSize: 'big' }} />
    <DonationsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <CollectableScheduleCard data-test={dataTest && `${dataTest}-schedule`} />
    <DonationsCard data-test={dataTest} DonationLink={DonationViewLink} FilterForm={DonationFilterForm} />
  </PageContainer>
);

export default DonationsPage;
