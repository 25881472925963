import * as channelJWT from '@/infrastructure/security/jwt-broadcast-channel';
import * as storageJWT from '@/infrastructure/security/jwt-session-storage';
import type { JWTTokenWithInfo } from '@/infrastructure/security/types';
import { parseJWT } from '@/infrastructure/security/types';
import createWeb3Auth from '@/infrastructure/security/web3-auth';
import { withSuppressError } from '@/infrastructure/utils/functions';

export const jwt = {
  parse: parseJWT,
  restoreFromStorage: async () => {
    let token: JWTTokenWithInfo | null | undefined = storageJWT.load();
    if (!token) {
      token = await channelJWT.load();
      if (token) {
        storageJWT.store(token);
      }
    }
    return token;
  },
  store: (token: JWTTokenWithInfo, silent?: boolean) => {
    storageJWT.store(token);
    if (!silent) {
      channelJWT.send(token);
    }
  },
  cleanUp: async (silent?: boolean) => {
    await withSuppressError(storageJWT.cleanUp)();
    if (!silent) {
      await withSuppressError(channelJWT.cleanUp)();
    }
  },
  sessionStorage: {
    cleanUp: storageJWT.cleanUp,
    store: storageJWT.store,
    load: storageJWT.load,
  },
  channel: {
    cleanUp: channelJWT.cleanUp,
    send: channelJWT.send,
    load: channelJWT.load,
  },
};

export const web3Auth = {
  init: createWeb3Auth,
};
