import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchAPIKeys } from '@/features/company-settings/actions';
import { makeSelectAPIKeys } from '@/features/company-settings/selectors';
import type { APIKey } from '@/features/company-settings/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<APIKey[]>, Parameters<typeof fetchAPIKeys>[0]> => fetchAPIKeys({ force });
const selectFetching = makeSelectPending(fetchAPIKeys);
const selectData = makeSelectAPIKeys();

export default function useAPIKeys() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
