/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  BlockchainsResponseAPIModel,
} from '../models/index';
import {
    BlockchainsResponseAPIModelFromJSON,
    BlockchainsResponseAPIModelToJSON,
} from '../models/index';

/**
 * BlockchainsApi - interface
 * 
 * @export
 * @interface BlockchainsApiInterface
 */
export interface BlockchainsApiInterface {
    /**
     * Get blockchains
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BlockchainsApiInterface
     */
    blockchainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlockchainsResponseAPIModel>>;

    /**
     * Get blockchains
     * 
     */
    blockchains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlockchainsResponseAPIModel>;

}

/**
 * 
 */
export class BlockchainsApi extends runtime.BaseAPI implements BlockchainsApiInterface {

    /**
     * Get blockchains
     * 
     */
    async blockchainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlockchainsResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blockchains/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlockchainsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Get blockchains
     * 
     */
    async blockchains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlockchainsResponseAPIModel> {
        const response = await this.blockchainsRaw(initOverrides);
        return await response.value();
    }

}
