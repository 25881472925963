import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { storedDirtyData } from '@/infrastructure/model';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markMerchantWalletDeploymentDirty,
  markMerchantWalletsDirty,
  storeMerchantWalletDeployment,
  storeMerchantWallets,
} from './actions';
import { type MerchantWalletsState } from './types';

import type { Draft } from 'immer';

const initialState: MerchantWalletsState = {
  deployments: {},
  wallets: storedDirtyData,
};

const { storeMerchantWalletDeploymentReducer, markMerchantWalletDeploymentDirtyReducer } = createSingleReducers(
  'MerchantWalletDeployment',
  (state: Draft<MerchantWalletsState>) => state.deployments,
  (global, deployments) => ({ ...global, deployments }),
  merchantWalletIdToStoreKey,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markMerchantWalletDeploymentDirty, markMerchantWalletDeploymentDirtyReducer)
    .addCase(storeMerchantWalletDeployment, storeMerchantWalletDeploymentReducer)

    .addCase(markMerchantWalletsDirty, (state) =>
      state.wallets.isDirty ? state : { ...state, wallets: { ...state.wallets, isDirty: true } },
    )
    .addCase(storeMerchantWallets, (state, { payload: wallets }) => ({
      ...state,
      wallets: { ...wallets, isDirty: false },
    }))

    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
