import { Form } from 'antd';

import BrandingOnRampSelect from '@/features/branding/components/BrandingOnRampSelect';
import type { PaymentsBranding } from '@/features/branding/types';
import { nameof } from '@/infrastructure/utils/ts';

import type { BrandingOnRampItemProps } from './types';
import type React from 'react';

const BrandingOnRampItem: React.FC<BrandingOnRampItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <Form.Item<PaymentsBranding> name={nameof<PaymentsBranding>('onRamp')} label={null}>
    <BrandingOnRampSelect data-test={dataTest} multiselect readonly={disabled} />
  </Form.Item>
);

export default BrandingOnRampItem;
