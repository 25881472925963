import { useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { useLocale } from '@/features/i18n/hooks';
import allLocales from '@/hocs/withI18nProvider/i18n';
import { namedHOC } from '@/infrastructure/utils/react';

import { withI18nBigNumber, withI18nDayjs } from './hocs';

import type React from 'react';

const withI18nProvider = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithI18nProvider',
  )((props) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const LocalizedComponent = useMemo(() => withI18nDayjs(withI18nBigNumber(Component)), [Component]);
    const { locale } = useLocale();
    return (
      <IntlProvider locale={locale} messages={allLocales[locale]}>
        <LocalizedComponent {...props} />
      </IntlProvider>
    );
  });

export default withI18nProvider;
