import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchEmailResetAllowance } from '@/features/auth/actions';
import { makeSelectEmailResetAllowanceState } from '@/features/auth/selectors';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseEmailResetAllowanceType = UseAppSingleData<boolean>;

const noData = storedDataError<boolean>('no-data');

const fetchFactory = (
  email: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<boolean>, Parameters<typeof fetchEmailResetAllowance>[0]> =>
  fetchEmailResetAllowance({ email, force });
const dataSelector = (email: string) => makeSelectEmailResetAllowanceState(email);

export default function useEmailResetAllowance(email: string | undefined): UseEmailResetAllowanceType {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchEmailResetAllowance, email), [email]);
  return useAppSingleData(fetchFactory, dataSelector, noData, email, dataFetchingSelector);
}
