import { UnorderedListOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsBrandingDomainsLink } from '@/pages/settings/routes';

import type { MerchantDomainsLinkProps } from './types';

const MerchantDomainsLink: React.FC<MerchantDomainsLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => {
  const navigate = useNavigate();
  const doOpen = useCallback(() => navigate(settingsBrandingDomainsLink()), [navigate]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <UnorderedListOutlined />}
      title={title ?? <FormattedMessage id={I18nPageSettings.BRANDING_DOMAINS_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const MerchantDomainsLinkMemo = React.memo(MerchantDomainsLink);

export default MerchantDomainsLinkMemo;
