import { InteractionOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditWalletTransferEntityLink } from '@/pages/audit/routes';
import { linkSupport } from '@/pages/audit/transfer-entity/utils';

import type { MerchantWalletTransferEntityLinkProps } from './types';

const MerchantWalletTransferEntityLinkRaw: React.FC<MerchantWalletTransferEntityLinkProps> = ({
  value,
  title,
  mode = 'inline',
  icon,
  'data-test': dataTest,
}) => {
  const id = typeof value === 'string' ? value : value.id;
  const linkSupported = typeof value !== 'string' && linkSupport[value.kind];
  const unlinked = typeof value !== 'string' && (!value.entityId || !linkSupported);
  return (
    <Link
      data-test={dataTest}
      to={auditWalletTransferEntityLink(id)}
      disabled={unlinked}
      icon={icon ?? <InteractionOutlined />}
      disabledMessage={
        unlinked ? <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_LINK_UNLINKED} /> : undefined
      }
      mode={mode}
      title={title || <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_LINK_TITLE} />}
    />
  );
};
const MerchantWalletTransferEntityLink = React.memo(MerchantWalletTransferEntityLinkRaw);

export default MerchantWalletTransferEntityLink;
