import { CloseOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { theme } from 'antd';
import React from 'react';

import FormattedMessage from '@/components/FormattedMessage';
import { I18nComponents } from '@/generated/i18n/i18n';

import Operation from '../Operation';

import type { InProgressViewProps } from './types';

const InProgressView: React.FC<InProgressViewProps> = ({ 'data-test': dataTest, cancel, Icon, message }) => {
  const { token } = theme.useToken();
  return (
    <div
      className={css`
        display: flex;
        flex: 1;
        position: relative;
        flex-direction: column;
        align-items: center;
      `}
    >
      {cancel && (
        <Operation
          className={css`
            position: absolute;
            right: 0;
            top: 0;
          `}
          title={cancel.title ?? <FormattedMessage id={I18nComponents.IN_PROGRESS_CANCEL_TITLE} />}
          data-test={dataTest && `${dataTest}-cancel`}
          icon={<CloseOutlined />}
          onClick={cancel.onAction}
          mode="icon"
        />
      )}
      {Icon && (
        <Icon
          data-test={dataTest && `${dataTest}-logo`}
          className={css`
            > svg {
              height: 100px;
              width: 100px;
            }
          `}
        />
      )}
      {message && (
        <div
          className={css`
            display: block;
            width: 100%;
            word-wrap: break-word;
            text-align: center;
            padding-top: ${token.paddingLG};
          `}
          data-test={dataTest && `${dataTest}-message`}
        >
          {message}
        </div>
      )}
    </div>
  );
};

const InProgressViewMemo = React.memo(InProgressView);

export default InProgressViewMemo;
