import { UnorderedListOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsBrandingProfilesLink } from '@/pages/settings/routes';

import type { BrandingProfilesLinkProps } from './types';

const BrandingProfilesLink: React.FC<BrandingProfilesLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settingsBrandingProfilesLink()}
    icon={icon ?? <UnorderedListOutlined />}
    title={title ?? <FormattedMessage id={I18nPageSettings.BRANDING_PROFILES_COMPONENTS_LINK_TITLE} />}
  />
);

const BrandingProfilesLinkMemo = React.memo(BrandingProfilesLink);

export default BrandingProfilesLinkMemo;
