import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { ActiveProfileCard, BrandingSummaryBreadcrumb, DomainsCard, TermsOfServiceCard } from './components';

import type React from 'react';

const BrandingSummaryPage: React.FC = () => (
  <SettingsPageLayout
    data-test="container"
    selected={SettingsTabType.branding}
    breadcrumb={<BrandingSummaryBreadcrumb data-test="breadcrumb" disabled />}
  >
    <ActiveProfileCard data-test="active" />
    <TermsOfServiceCard data-test="tos" />
    <DomainsCard data-test="domains" />
  </SettingsPageLayout>
);

export default BrandingSummaryPage;
