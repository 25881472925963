import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { AssetItem } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { UpdateStatisticsAssetFormProps } from './types';
import type { FormInstance } from 'antd/es/form';

interface FormProps {
  asset: string;
}

const requiredFields = [nameof<FormProps>('asset')];

const assetMessages = {
  label: (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_ASSET_LABEL} />
  ),
};

const footerMessages = {
  submit: (
    <FormattedMessage
      id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_SUBMIT}
      tagName="span"
    />
  ),
};

const UpdateStatisticsAssetForm: React.FC<UpdateStatisticsAssetFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  value,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const initialValues = useMemo(() => ({ [nameof<FormProps>('asset')]: value }), [value]);
  const { form, withResetForm } = useForm<FormProps>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: FormProps): unknown => onSubmit(val.asset)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return (
      <FormattedMessage
        id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_ERROR_COMMON}
      />
    );
  }, [error]);
  const isComplete = useCallback(
    ({ getFieldValue }: FormInstance) => getFieldValue(nameof<FormProps>('asset')) !== value,
    [value],
  );

  return (
    <Form
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage content={errorMessage} />}
      <AssetItem<FormProps>
        name={nameof<FormProps>('asset')}
        data-test={dataTest && `${dataTest}-asset`}
        messages={assetMessages}
      />
      <FormCompletenessItem<FormProps>
        requiredFields={requiredFields}
        onChange={setFormComplete}
        checkIsComplete={isComplete}
      />
      <FormFooter
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
        messages={footerMessages}
        data-test={dataTest && `${dataTest}-submit`}
      />
    </Form>
  );
};

const UpdateStatisticsAssetFormMemo = React.memo(UpdateStatisticsAssetForm);

export default UpdateStatisticsAssetFormMemo;
