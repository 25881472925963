import { useGoogleReCaptcha as useBaseRecaptcha } from 'react-google-recaptcha-v3';

export interface IGoogleReCaptchaConsumerProps {
  execute?: (action?: string) => Promise<string>;
}

const useV3ReCaptcha = (): IGoogleReCaptchaConsumerProps => {
  const { executeRecaptcha } = useBaseRecaptcha();
  return { execute: executeRecaptcha };
};

export default useV3ReCaptcha;
