export enum AppPageRoutes {
  AUDIT = 'audit',
  AUTH = 'auth',
  BALANCES = 'balances',
  CALLBACK = 'callback',
  COLLECTABLE = 'collectable',
  DASHBOARD = 'dashboard',
  DONATIONS = 'donations',
  GAS_HISTORY = 'gas-history',
  ONBOARD = 'onboard',
  PAYMENTS = 'payments',
  PAYOUTS = 'payouts',
  RECHARGES = 'recharges',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  SETTLEMENTS = 'settlements',
  SUBSCRIPTIONS = 'subscriptions',
}

export const appRoute = (route: AppPageRoutes) => `/${route}`;
