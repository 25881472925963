/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { AuthenticatedUserCompanyAPIModel } from './AuthenticatedUserCompanyAPIModel';
import {
    AuthenticatedUserCompanyAPIModelFromJSON,
    AuthenticatedUserCompanyAPIModelFromJSONTyped,
    AuthenticatedUserCompanyAPIModelToJSON,
} from './AuthenticatedUserCompanyAPIModel';
import type { NcpsRoleAPIModel } from './NcpsRoleAPIModel';
import {
    NcpsRoleAPIModelFromJSON,
    NcpsRoleAPIModelFromJSONTyped,
    NcpsRoleAPIModelToJSON,
} from './NcpsRoleAPIModel';

/**
 * 
 * @export
 * @interface AuthenticatedUserAPIModel
 */
export interface AuthenticatedUserAPIModel {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUserAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUserAPIModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatedUserAPIModel
     */
    address: string;
    /**
     * 
     * @type {Date}
     * @memberof AuthenticatedUserAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Array<NcpsRoleAPIModel>}
     * @memberof AuthenticatedUserAPIModel
     */
    roles: Array<NcpsRoleAPIModel>;
    /**
     * 
     * @type {Date}
     * @memberof AuthenticatedUserAPIModel
     */
    tokenExpiresAt: Date;
    /**
     * 
     * @type {AuthenticatedUserCompanyAPIModel}
     * @memberof AuthenticatedUserAPIModel
     */
    activeCompany: AuthenticatedUserCompanyAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatedUserAPIModel
     */
    isEmailAuth: boolean;
}

/**
 * Check if a given object implements the AuthenticatedUserAPIModel interface.
 */
export function instanceOfAuthenticatedUserAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('email' in value)) return false;
    if (!('address' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('roles' in value)) return false;
    if (!('tokenExpiresAt' in value)) return false;
    if (!('activeCompany' in value)) return false;
    if (!('isEmailAuth' in value)) return false;
    return true;
}

export function AuthenticatedUserAPIModelFromJSON(json: any): AuthenticatedUserAPIModel {
    return AuthenticatedUserAPIModelFromJSONTyped(json, false);
}

export function AuthenticatedUserAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticatedUserAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'email': json['email'],
        'address': json['address'],
        'createdAt': (new Date(json['createdAt'])),
        'roles': ((json['roles'] as Array<any>).map(NcpsRoleAPIModelFromJSON)),
        'tokenExpiresAt': (new Date(json['tokenExpiresAt'])),
        'activeCompany': AuthenticatedUserCompanyAPIModelFromJSON(json['activeCompany']),
        'isEmailAuth': json['isEmailAuth'],
    };
}

export function AuthenticatedUserAPIModelToJSON(value?: AuthenticatedUserAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'email': value['email'],
        'address': value['address'],
        'createdAt': ((value['createdAt']).toISOString()),
        'roles': ((value['roles'] as Array<any>).map(NcpsRoleAPIModelToJSON)),
        'tokenExpiresAt': ((value['tokenExpiresAt']).toISOString()),
        'activeCompany': AuthenticatedUserCompanyAPIModelToJSON(value['activeCompany']),
        'isEmailAuth': value['isEmailAuth'],
    };
}

