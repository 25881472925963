import { useSubscription } from '@/features/subscriptions/hooks';
import type { Subscription } from '@/features/subscriptions/types';
import { withDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionDataLoading = <
  Original extends { data: Subscription } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { subscriptionId: string },
>(
  Component: React.ComponentType<Original>,
  hideOnLoading = false,
  hideSpinner = false,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithSubscriptionDataLoading',
  )((props) => {
    const data = useSubscription(props.subscriptionId);
    return withDataLoading<Subscription, Original>({
      ...data,
      hideOnLoading,
      noSpinner: hideSpinner,
      'data-test': props['data-test'],
    })(Component)(props);
  });

export default withSubscriptionDataLoading;
