import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchBrandingProfiles } from '@/features/branding/actions';
import { makeSelectBranding as makeSelectBrandingProfiles } from '@/features/branding/selectors';
import type { BrandingProfileWithActive } from '@/features/branding/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const dataFetchingSelector = makeSelectPending(fetchBrandingProfiles);
const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<BrandingProfileWithActive[]>,
  Parameters<typeof fetchBrandingProfiles>[0]
> => fetchBrandingProfiles({ force });
const dataSelector = makeSelectBrandingProfiles();

export default function useBrandingProfiles() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
