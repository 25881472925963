import BigNumber from 'bignumber.js';
import { useEffect } from 'react';

import { useLocaleSettings } from '@/hooks';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withI18nBigNumber = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithI18nBigNumber',
  )((props) => {
    const { groupSeparator, decimalSeparator } = useLocaleSettings();
    useEffect(() => {
      BigNumber.config({
        FORMAT: {
          decimalSeparator,
          groupSeparator,
          groupSize: 3,
          fractionGroupSeparator: undefined,
          fractionGroupSize: undefined,
          secondaryGroupSize: undefined,
        },
      });
    }, [decimalSeparator, groupSeparator]);
    return <Component {...props} />;
  });

export default withI18nBigNumber;
