import type { TestableProps } from '@/infrastructure/utils/react';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { GasWalletsCard, GasWalletsBreadcrumb } from './components';

import type React from 'react';

const GasWalletsPage: React.FC<TestableProps> = ({ 'data-test': dataTest = 'gasWallets' }) => (
  <SettingsPageLayout
    data-test={dataTest && `${dataTest}-container`}
    selected={SettingsTabType.company}
    breadcrumb={<GasWalletsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <GasWalletsCard data-test={dataTest && `${dataTest}-wallets`} />
  </SettingsPageLayout>
);

export default GasWalletsPage;
