import isNil from 'lodash/isNil';
import React, { useContext } from 'react';

import { PayoutTableRowFormValueContext } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';

import { PayoutDestinationTableConfirmRowOperations, PayoutDestinationTableEditRowOperations } from './components';

import type { PayoutDestinationTableEditOperationProps } from './types';

const PayoutDestinationTableEditOperation: React.FC<PayoutDestinationTableEditOperationProps> = ({
  'data-test': dataTest,
  data,
  disabled,
  onEdit,
  onSave,
  onCancel,
  onDelete,
}) => {
  const { editingKey } = useContext(PayoutTableRowFormValueContext);
  return editingKey === data.num ? (
    <PayoutDestinationTableConfirmRowOperations
      data-test={dataTest && `${dataTest}-edit`}
      disabled={disabled}
      onSave={onSave}
      onCancel={onCancel}
    />
  ) : (
    <PayoutDestinationTableEditRowOperations
      data-test={dataTest && `${dataTest}-edit`}
      data={data}
      disabled={disabled || !isNil(editingKey)}
      onEdit={onEdit}
      onDelete={onDelete}
    />
  );
};

const PayoutDestinationTableEditOperationMemo = React.memo(PayoutDestinationTableEditOperation);

export default PayoutDestinationTableEditOperationMemo;
