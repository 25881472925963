import React from 'react';

import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';

import {
  GasWalletAllOutOfServiceCheckBanner,
  GasWalletAnyOutOfServiceCheckBanner,
  GasWalletDerivedBalanceCheckBanner,
  GasWalletNativeBalanceCheckBanner,
  GasWalletPresenceCheckBanner,
  GasWalletRefundingCheckBanner,
} from './components';

import type { GasWalletStateCheckBannerProps } from './types';

const GasWalletStateCheckBannerByBT: React.FC<GasWalletStateCheckBannerProps> = ({ type, bt, ...props }) => (
  <>
    {type === 'presence' && <GasWalletPresenceCheckBanner bt={bt} {...props} />}
    {type === 'refunding' && <GasWalletRefundingCheckBanner bt={bt} {...props} />}
    {type === 'any-service' && <GasWalletAnyOutOfServiceCheckBanner bt={bt} {...props} />}
    {type === 'all-service' && <GasWalletAllOutOfServiceCheckBanner bt={bt} {...props} />}
    {type === 'native-balance' && <GasWalletNativeBalanceCheckBanner bt={bt} {...props} />}
    {type === 'derived-balance' && <GasWalletDerivedBalanceCheckBanner bt={bt} {...props} />}
  </>
);

const GasWalletStateCheckBannerHOC = withBlockchainType<GasWalletStateCheckBannerProps>(GasWalletStateCheckBannerByBT);

const GasWalletStateCheckBanner: typeof GasWalletStateCheckBannerHOC = React.memo(GasWalletStateCheckBannerHOC);

export default GasWalletStateCheckBanner;
