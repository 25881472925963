import { CheckOutlined, ClockCircleOutlined, CopyOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { StatisticItem } from '@/features/statistics/components';
import { I18nPageDashboard } from '@/generated/i18n/i18n';

import { withDashboardPaymentsDataLoading } from './hocs';

import type { PaymentsCardProps } from './types';

const span = { xs: 24, sm: 8, md: 8, lg: 8, xl: 8 };

const PaymentsCard: React.FC<PaymentsCardProps> = ({ data, 'data-test': dataTest }) => (
  <Row>
    <StatisticItem
      data-test={dataTest && `${dataTest}-todayCreated`}
      span={span}
      title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_PAYMENTS_CARD_TODAY_COL_CREATED_TITLE} />}
      icon={<CopyOutlined />}
      value={data.todayPayments.Created ?? 0}
      color="blue"
    />
    <StatisticItem
      data-test={dataTest && `${dataTest}-todayPaid`}
      span={span}
      title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_PAYMENTS_CARD_TODAY_COL_PAID_TITLE} />}
      icon={<CheckOutlined />}
      value={data.todayPayments.Paid ?? 0}
      color="green"
    />
    <StatisticItem
      data-test={dataTest && `${dataTest}-todayExpired`}
      span={span}
      title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_PAYMENTS_CARD_TODAY_COL_EXPIRED_TITLE} />}
      icon={<ClockCircleOutlined />}
      value={data.todayPayments.Expired ?? 0}
      color="orange"
    />
  </Row>
);

const PaymentsCardLoaded = withDashboardPaymentsDataLoading(PaymentsCard);

const PaymentsCardMemo = React.memo(PaymentsCardLoaded) as typeof PaymentsCardLoaded;

export default PaymentsCardMemo;
