import { useCallback, useMemo } from 'react';

import EditSubscriptionPlanForm from 'src/features/subscription-plans/components/SubscriptionPlanEditForm';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { useSubscriptionPlansActions } from '@/features/subscription-plans/hooks';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import {
  useDefaultNotification,
  useFirstRenderEffect,
  useFormPageAction,
  useNotification,
  useStateMountSafe,
} from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { subscriptionPlansLink } from '@/pages/subscriptions/routes';

import { SubscriptionPlanAddBreadcrumb } from './components';

import type React from 'react';

type SubscriptionPlanAddPageProps = TestableProps;

const SubscriptionPlanAddPage: React.FC<SubscriptionPlanAddPageProps> = ({ 'data-test': dataTest = 'plan-add' }) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { create: createAction } = useSubscriptionPlansActions();
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(subscriptionPlansLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  useFirstRenderEffect(() => {
    goalReached(YMGoals.SUBSCRIPTION_PLAN_CREATE_INIT);
  });

  const doCreate = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(createAction.act, () => (
            <FormattedMessage id={I18nPageSubscriptions.PLAN_ADD_COMPONENTS_SUCCESS_MESSAGE} />
          )),
          () => <FormattedMessage id={I18nPageSubscriptions.PLAN_ADD_COMPONENTS_ERROR_MESSAGE} />,
        ),
        () => {
          goalReached(YMGoals.SUBSCRIPTION_PLAN_CREATED);
          doRedirect();
        },
      ),
    [withDefaultError, withSuccess, doRedirect, createAction],
  );
  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={<SubscriptionPlanAddBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={submit}
      cancel={doRedirect}
    >
      <PageCard>
        <EditSubscriptionPlanForm
          data-test={dataTest && `${dataTest}-form`}
          onSubmit={doCreate}
          onReset={doRedirect}
          submitCallback={updateOnSubmit}
        />
      </PageCard>
    </PageContainer>
  );
};

export default SubscriptionPlanAddPage;
