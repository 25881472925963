import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import { AddressLink, AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectableEntityTransactionViewProps } from './types';

const CollectableEntityTransactionView: React.FC<CollectableEntityTransactionViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    column={columns}
  >
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_CREATED_AT} />}>
      <ReadonlyDateTime value={data.createdAt} data-test={dataTest && `${dataTest}-createdAt`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_BROADCAST_AT} />}>
      <ReadonlyDateTime value={data.broadcastAt} data-test={dataTest && `${dataTest}-broadcastAt`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_FROM_ADDRESS} />}>
      <AddressLink address={data.fromAddress} bt={data.blockchain} data-test={dataTest && `${dataTest}-fromAddress`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_AMOUNT} />}>
      <AssetAmount
        value={data.amount.value}
        assetId={data.amount.asset}
        withCurrency
        withBlockchainMark
        data-test={dataTest && `${dataTest}-amount`}
      />
    </Descriptions.Item>
    {data.hash && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_HASH} />}>
        <TransactionLink tx={data.hash} bt={data.blockchain} data-test={dataTest && `${dataTest}-hash`} />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const CollectableEntityTransactionViewMemo = React.memo(CollectableEntityTransactionView);

export default CollectableEntityTransactionViewMemo;
