import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { useToSActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationDeleteToSProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_TOS_ERROR_MESSAGE} />,
  success: () => (
    <FormattedMessageWithMarkup id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_TOS_SUCCESS_MESSAGE} />
  ),
};

const OperationDeleteToS: React.FC<OperationDeleteToSProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  confirmation,
  onSuccess = noop,
  mode = 'inline',
  title,
}) => {
  const { withNotification } = useNotification();
  const { remove: removeAction } = useToSActions();

  const deleteNotification = useMemo(
    () => withSuppressError(withOnSuccess(withNotification(removeAction.act, notificationOptions), onSuccess)),
    [withNotification, removeAction.act, onSuccess],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? {
            title: (
              <FormattedMessageWithMarkup id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_TOS_CONFIRMATION_TITLE} />
            ),
          }
        : undefined,
    [confirmation],
  );
  const disabledMessage = useMemo(
    () =>
      removeAction.unavailabilityReason === 'no-data' ? (
        <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_TOS_ERROR_NO_DATA} />
      ) : undefined,
    [removeAction.unavailabilityReason],
  );

  return (
    <Operation
      data-test={dataTest}
      title={title ?? <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_TOS_TITLE} />}
      confirmation={confirmationProps}
      icon={<DeleteOutlined />}
      onClick={deleteNotification}
      disabled={disabled || !!disabledMessage || !removeAction.available}
      disabledMessage={disabledMessage}
      inProgress={inProgress || removeAction.inAction}
      mode={mode}
    />
  );
};

const OperationDeleteToSMemo = React.memo(OperationDeleteToS);

export default OperationDeleteToSMemo;
