import { Typography } from 'antd';
import React from 'react';

import type { PrivateKeyItemReadonlyProps } from './types';

const { Text } = Typography;

const PrivateKeyItemReadonly: React.FC<PrivateKeyItemReadonlyProps> = ({ 'data-test': dataTest, value }) => (
  <Text ellipsis copyable style={{ width: '100%' }} data-test={dataTest} className="ym-hide-content">
    {value}
  </Text>
);

const PrivateKeyItemReadonlyMemo = React.memo(PrivateKeyItemReadonly) as typeof PrivateKeyItemReadonly;

export default PrivateKeyItemReadonlyMemo;
