import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { usePayoutActions } from '@/features/payouts/hooks';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationDeletePayoutProps } from './types';

const messageOptions = {
  success: () => <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_DELETE_PAYOUT_SUCCESS_MESSAGE} />,
  error: () => <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_DELETE_PAYOUT_ERROR_MESSAGE} />,
};

const OperationDeletePayout: React.FC<OperationDeletePayoutProps> = ({
  'data-test': dataTest,
  payoutId,
  confirmation = true,
  mode = 'inline',
  onSuccess = noop,
}) => {
  const { withNotification } = useNotification();
  const { remove: removePayoutAction } = usePayoutActions(payoutId);

  const deleteKey = useMemo(
    () => withSuppressError(withOnSuccess(withNotification(removePayoutAction.act, messageOptions), onSuccess)),
    [removePayoutAction.act, onSuccess, withNotification],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? { title: <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_DELETE_PAYOUT_CONFIRMATION_TITLE} /> }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_DELETE_PAYOUT_TITLE} />}
      icon={<DeleteOutlined />}
      onClick={deleteKey}
      disabled={!removePayoutAction.available}
      inProgress={removePayoutAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeletePayoutMemo = React.memo(OperationDeletePayout);

export default OperationDeletePayoutMemo;
