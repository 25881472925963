import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useDomainActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationUpdateDomainStatusProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_ERROR_MESSAGE} />,
  success: () => (
    <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_SUCCESS_MESSAGE} />
  ),
};

const OperationUpdateDomainStatus: React.FC<OperationUpdateDomainStatusProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  domainId,
}) => {
  const { withNotification } = useNotification();
  const { activate: activateAction, deactivate: deactivateAction } = useDomainActions(domainId);

  const doUpdateStatus = useMemo(
    () =>
      withSuppressError(
        withNotification(activateAction.available ? activateAction.act : deactivateAction.act, notificationOptions),
      ),
    [withNotification, activateAction.available, activateAction.act, deactivateAction.act],
  );

  const title = deactivateAction.available ? (
    <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_ACTIVATE_TITLE} />
  ) : (
    <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_DEACTIVATE_TITLE} />
  );
  const tooltip = <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_TOOLTIP} />;
  const icon = deactivateAction.available ? (
    <StopOutlined style={{ color: 'red' }} />
  ) : (
    <PlayCircleOutlined style={{ color: 'green' }} />
  );

  const disabledMessage =
    activateAction.unavailabilityReason === 'unapproved' ? (
      <FormattedMessage
        id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_DISABLED_MESSAGE_UNAPPROVED}
      />
    ) : undefined;

  return (
    <Operation
      data-test={dataTest}
      title={title}
      tooltip={tooltip}
      icon={icon}
      onClick={doUpdateStatus}
      disabled={!activateAction.available && !deactivateAction.available}
      disabledMessage={disabledMessage}
      inProgress={activateAction.inAction || deactivateAction.inAction}
      mode={mode}
    />
  );
};

const OperationUpdateDomainStatusMemo = React.memo(OperationUpdateDomainStatus);

export default OperationUpdateDomainStatusMemo;
