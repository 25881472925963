import React from 'react';

import { OperationRefresh } from '@/components';
import { useMerchantWalletDeployment } from '@/features/merchant-wallets/hooks';

import type { OperationRefreshMerchantWalletDeploymentProps } from './types';

const OperationRefreshMerchantWalletDeployment: React.FC<OperationRefreshMerchantWalletDeploymentProps> = ({
  'data-test': dataTest,
  bt,
  title,
  mode = 'inline',
}) => {
  const { forceRefresh } = useMerchantWalletDeployment(bt);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={{ title }} />;
};

const OperationRefreshMerchantWalletDeploymentMemo = React.memo(OperationRefreshMerchantWalletDeployment);

export default OperationRefreshMerchantWalletDeploymentMemo;
