import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectTask } from '@/features/collectable/actions';
import { makeSelectCollectTask } from '@/features/collectable/selectors';
import type { CollectTask } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseCollectTask = UseAppSingleData<CollectTask>;

const noData = storedDataError<CollectTask>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<CollectTask>, Parameters<typeof fetchCollectTask>[0]> =>
  fetchCollectTask({ id, force });

export default function useCollectTask(id: string | undefined): UseCollectTask {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchCollectTask, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectCollectTask, noData, id, dataFetchingSelector);
}
