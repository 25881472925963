import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { extractMerchantWalletTransferId } from '@/features/merchant-wallet-transfers/utils';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { defaultListState } from '@/infrastructure/model/list/types';

import {
  markMerchantWalletTransferDirty,
  storeMultipleMerchantWalletTransfer,
  storeMerchantWalletTransfer,
  storeMerchantWalletTransferListData,
  storeMerchantWalletTransferListParameters,
  markMerchantWalletTransferListDirty,
} from './actions';
import { defaultMerchantWalletTransferSortBy } from './types';

import type { MerchantWalletTransfersState } from './types';
import type { Draft } from 'immer';

const initialState: MerchantWalletTransfersState = {
  entities: {},
  list: defaultListState({}, defaultMerchantWalletTransferSortBy),
};

const {
  storeMultipleMerchantWalletTransferReducer,
  storeMerchantWalletTransferReducer,
  markMerchantWalletTransferDirtyReducer,
  storeMerchantWalletTransferListParametersReducer,
  markMerchantWalletTransferListDirtyReducer,
  storeMerchantWalletTransferListDataReducer,
} = createNormalizedListReducers(
  'MerchantWalletTransfer' as const,
  (state: Draft<MerchantWalletTransfersState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  // this cast is workaround caused by BigNumber in the store
  (state: Draft<MerchantWalletTransfersState>) => state.entities as MerchantWalletTransfersState['entities'],
  (state, entities) => ({ ...state, entities }),
  extractMerchantWalletTransferId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeMerchantWalletTransferListData, storeMerchantWalletTransferListDataReducer)
    .addCase(storeMerchantWalletTransferListParameters, storeMerchantWalletTransferListParametersReducer)
    .addCase(markMerchantWalletTransferListDirty, markMerchantWalletTransferListDirtyReducer)

    .addCase(markMerchantWalletTransferDirty, markMerchantWalletTransferDirtyReducer)
    .addCase(storeMerchantWalletTransfer, storeMerchantWalletTransferReducer)
    .addCase(storeMultipleMerchantWalletTransfer, storeMultipleMerchantWalletTransferReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
