import { base58 } from '@scure/base';

export const uuidToBase58 = (uuid: string) => {
  const cleanUuid = uuid.replace(/-/g, '');

  const byteArray = cleanUuid.match(/.{1,2}/g)?.map((byte) => parseInt(byte, 16));
  if (!byteArray) {
    return uuid;
  }
  const bytes = new Uint8Array(byteArray);
  return base58.encode(bytes);
};
