import type { SubscriptionBriefStatsAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export const NAMESPACE = 'statistics';

export interface AssetBalances {
  balances: AssetAmountValue[];
  available: AssetAmountValue[];
  collectable: AssetAmountValue[];
  locked: AssetAmountValue[];
  total: AssetAmountValue;
  totalAvailable: AssetAmountValue;
  totalCollectable: AssetAmountValue;
  totalLocked: AssetAmountValue;
}

export interface DayPayments {
  Paid?: number;
  Created?: number;
  Expired?: number;
}

export interface DaySales {
  salesVolume: AssetAmountValue;
}

export interface PaymentSummaryStats {
  todayPayments: DayPayments;
  todaySales: DaySales;
}

export interface SubscriptionSummaryStats extends Omit<SubscriptionBriefStatsAPIModel, 'mrr'> {
  mrr: AssetAmountValue;
}

export interface StatisticsState {
  balances: LoadingStateWithDirty<AssetBalances>;
  payments: LoadingStateWithDirty<PaymentSummaryStats>;
  subscriptions: LoadingStateWithDirty<SubscriptionSummaryStats>;
}
