import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCompanies } from '@/features/company/actions';
import { makeSelectCompanies } from '@/features/company/selectors';
import type { Company } from '@/features/company/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Company[]>, Parameters<typeof fetchCompanies>[0]> =>
  fetchCompanies({ force });
const selectFetching = makeSelectPending(fetchCompanies);
const selectData = makeSelectCompanies();

export default function useCompanies() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
