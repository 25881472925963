import { StopOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useBrandingProfileActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationDeactivateBrandingProfileProps } from './types';

const OperationDeactivateBrandingProfile: React.FC<OperationDeactivateBrandingProfileProps> = ({
  'data-test': dataTest,
  profileId,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { deactivate } = useBrandingProfileActions(profileId);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const doDeactivateProfile = useMemo(
    () =>
      withSuccess(withDefaultError(withOnSuccess(deactivate.act, onSuccess)), () => (
        <FormattedMessage
          id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_DEACTIVATE_MESSAGE_SUCCESS}
        />
      )),
    [withSuccess, withDefaultError, deactivate.act, onSuccess],
  );

  const disabledMessage = useMemo(() => {
    if (disabled) {
      return undefined;
    }
    if (deactivate.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ERROR_NO_DATA} />;
    }
    if (deactivate.unavailabilityReason === 'already-inactive') {
      return (
        <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ERROR_ALREADY_INACTIVE} />
      );
    }
    return undefined;
  }, [deactivate.unavailabilityReason, disabled]);

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_DEACTIVATE_TITLE} />}
      icon={<StopOutlined style={{ color: 'red' }} />}
      onClick={doDeactivateProfile}
      disabled={disabled || !!disabledMessage}
      disabledMessage={disabledMessage}
      inProgress={inProgress || deactivate.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeactivateBrandingProfileMemo = React.memo(OperationDeactivateBrandingProfile);

export default OperationDeactivateBrandingProfileMemo;
