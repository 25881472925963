import { withPageParams } from '@/hocs';
import { requiredParamsParser } from '@/hocs/withPageParams/utils';
import type { TestableProps } from '@/infrastructure/utils/react';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { BrandingProfileViewBreadcrumb, BrandingProfileViewCard } from './components';

import type React from 'react';

interface BrandingProfileViewPageProps extends TestableProps {
  pageParams: { id: string };
}

const BrandingProfileViewPage: React.FC<BrandingProfileViewPageProps> = ({
  'data-test': dataTest = 'profile-view',
  pageParams: { id },
}) => (
  <SettingsPageLayout
    data-test="container"
    selected={SettingsTabType.branding}
    breadcrumb={
      <BrandingProfileViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled profileId={id} />
    }
  >
    <BrandingProfileViewCard data-test={dataTest} profileId={id} />
  </SettingsPageLayout>
);

export default withPageParams(requiredParamsParser(['id']))(BrandingProfileViewPage);
