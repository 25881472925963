import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { InputNumberTransform } from '@/components';
import { FormattedMessage, InputNumberItem } from '@/components';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import type { SubscriptionPlanScheduleItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';
import type { Rule } from 'rc-field-form/lib/interface';

const minDays = 1;
const maxDays = 365;

const transform: InputNumberTransform = {
  toInput: (value) => Math.floor((value ?? 0) / 60 / 60 / 24),
  fromInput: (value) => Math.floor((value ?? 0) * 60 * 60 * 24),
};

const messages = {
  label: <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_LABEL} />,
};

const SubscriptionPlanScheduleItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: SubscriptionPlanScheduleItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const rules: Rule[] = useMemo(
    () =>
      !readonly
        ? [
            {
              // eslint-disable-next-line @typescript-eslint/require-await
              validator: async (_, v) => {
                if ((v || 0) < minDays * 60 * 60 * 24) {
                  throw new Error(
                    formatMessage(
                      { id: I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_ERROR_MIN_DAYS },
                      { days: minDays },
                    ),
                  );
                }
                if ((v || 0) > maxDays * 60 * 60 * 24) {
                  throw new Error(
                    formatMessage(
                      { id: I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_ERROR_MAX_DAYS },
                      { days: maxDays },
                    ),
                  );
                }
              },
            },
            {
              required: true,
              message: formatMessage({
                id: I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_ERROR_REQUIRED,
              }),
            },
          ]
        : [],
    [formatMessage, readonly],
  );
  return (
    <InputNumberItem<Values>
      name={name}
      readonly={readonly}
      rules={rules}
      data-test={dataTest}
      transform={transform}
      InputProps={useMemo(
        () => ({
          min: minDays,
          max: maxDays,
          addonAfter: (
            <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_LABEL_SUFFIX} />
          ),
          placeholder: formatMessage({
            id: I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_PLACEHOLDER,
          }),
        }),
        [formatMessage],
      )}
      ItemProps={ItemProps}
      messages={messages}
    />
  );
};

const SubscriptionPlanScheduleItemMemo = React.memo(
  SubscriptionPlanScheduleItem,
) as typeof SubscriptionPlanScheduleItem;

export default SubscriptionPlanScheduleItemMemo;
