import React from 'react';
import { useIntl } from 'react-intl';

import { DateTimeRelativeItem, FormattedMessage, InputItem } from '@/components';
import { BlockchainItem, NetworkTypeItem } from '@/features/dictionary/blockchain/components';
import type { MerchantWalletRunningBalanceReportPredicates } from '@/features/merchant-wallet-balance/types';
import { I18nFeatureMerchantWalletBalance } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { MerchantWalletRunningBalancesScheduleParametersFormProps } from './types';

const walletMessages = {
  label: <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_FILTER_FORM_WALLET_LABEL} />,
};

const MerchantWalletRunningBalancesScheduleParametersFormRaw: React.FC<
  MerchantWalletRunningBalancesScheduleParametersFormProps
> = ({ 'data-test': dataTest, name, readonly }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <InputItem
        data-test={dataTest && `${dataTest}-wallet`}
        name={[name, nameof<MerchantWalletRunningBalanceReportPredicates>('walletLike')]}
        messages={walletMessages}
        placeholder={formatMessage({ id: I18nFeatureMerchantWalletBalance.COMPONENTS_FILTER_FORM_WALLET_PLACEHOLDER })}
        readonly={readonly}
      />
      <DateTimeRelativeItem
        label={
          <FormattedMessage
            id={I18nFeatureMerchantWalletBalance.COMPONENTS_REPORT_SCHEDULE_PARAMETERS_BROADCAST_AT_RELATIVE_ITEM_LABEL}
          />
        }
        readonly={readonly}
        data-test={dataTest && `${dataTest}-broadcastAt`}
        name={[name, nameof<MerchantWalletRunningBalanceReportPredicates>('broadcastAtRangeRelative')]}
        required={false}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={[name, nameof<MerchantWalletRunningBalanceReportPredicates>('btIn')]}
        required={false}
        readonly={readonly}
        multiselect
      />
      <NetworkTypeItem
        data-test={dataTest && `${dataTest}-network`}
        name={[name, nameof<MerchantWalletRunningBalanceReportPredicates>('networkEq')]}
        readonly={readonly}
        required={false}
        mode="select"
      />
    </>
  );
};

const MerchantWalletRunningBalancesScheduleParametersForm = React.memo(
  MerchantWalletRunningBalancesScheduleParametersFormRaw,
);

export default MerchantWalletRunningBalancesScheduleParametersForm;
