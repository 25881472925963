// supported locales: https://developers.google.com/recaptcha/docs/language
import { I18nLocales } from '@/generated/i18n/i18n';

export enum ReCaptchaLanguage {
  'ar' = 'ar', // Arabic
  'af' = 'af', // Afrikaans
  'sq' = 'sq', // Albanian
  'am' = 'am', // Amharic
  'hy' = 'hy', // Armenian
  'az' = 'az', // Azerbaijani
  'eu' = 'eu', // Basque
  'bn' = 'bn', // Bengali
  'bs' = 'bs', // Bosnian
  'bg' = 'bg', // Bulgarian
  'ca' = 'ca', // Catalan
  'zh-CN' = 'zh-CN', // Chinese Simplified
  'zh-TW' = 'zh-TW', // Chinese Traditional
  'zh-HK' = 'zh-HK', // Chinese Hong Kong
  'hr' = 'hr', // Croatian
  'cs' = 'cs', // Czech
  'da' = 'da', // Danish
  'nl' = 'nl', // Dutch
  'en-GB' = 'en-GB', // English (UK)
  'en' = 'en', // English (US)
  'et' = 'et', // Estonian
  'fil' = 'fil', // Filipino
  'fi' = 'fi', // Finnish
  'fr' = 'fr', // French
  'fr-CA' = 'fr-CA', // French (Canadian)
  'gl' = 'gl', // Galician
  'ka' = 'ka', // Georgian
  'de' = 'de', // German
  'el' = 'el', // Greek
  'gu' = 'gu', // Gujarati
  'iw' = 'iw', // Hebrew
  'hi' = 'hi', // Hindi
  'hu' = 'hu', // Hungarian
  'is' = 'is', // Icelandic
  'id' = 'id', // Indonesian
  'ga' = 'ga', // Irish
  'it' = 'it', // Italian
  'ja' = 'ja', // Japanese
  'kn' = 'kn', // Kannada
  'kk' = 'kk', // Kazakh
  'km' = 'km', // Khmer
  'ko' = 'ko', // Korean
  'ky' = 'ky', // Kyrgyz
  'lo' = 'lo', // Lao
  'lv' = 'lv', // Latvian
  'lt' = 'lt', // Lithuanian
  'mk' = 'mk', // Macedonian
  'ms' = 'ms', // Malay
  'ml' = 'ml', // Malayalam
  'mt' = 'mt', // Maltese
  'mr' = 'mr', // Marathi
  'mn' = 'mn', // Mongolian
  'ne' = 'ne', // Nepali
  'no' = 'no', // Norwegian
  'fa' = 'fa', // Persian
  'pl' = 'pl', // Polish
  'pt' = 'pt', // Portuguese
  'pt-BR' = 'pt-BR', // Portuguese (Brazil)
  'pa' = 'pa', // Punjabi
  'ro' = 'ro', // Romanian
  'ru' = 'ru', // Russian
  'sr' = 'sr', // Serbian
  'si' = 'si', // Sinhala
  'sk' = 'sk', // Slovak
  'sl' = 'sl', // Slovenian
  'es' = 'es', // Spanish
  'es-419' = 'es-419', // Spanish (Latin America)
  'sw' = 'sw', // Swahili
  'sv' = 'sv', // Swedish
  'ta' = 'ta', // Tamil
  'te' = 'te', // Telugu
  'th' = 'th', // Thai
  'tr' = 'tr', // Turkish
  'uk' = 'uk', // Ukrainian
  'ur' = 'ur', // Urdu
  'uz' = 'uz', // Uzbek
  'vi' = 'vi', // Vietnamese
  'zu' = 'zu', // Zulu
}

const recaptchaLocales: Record<I18nLocales, ReCaptchaLanguage> = {
  [I18nLocales['en-US']]: ReCaptchaLanguage.en,
};

export default recaptchaLocales;
