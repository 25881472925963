import type { ReCaptchaParams } from '@/features/recaptcha/types';
import {
  MerchantAuth0Api,
  RequestMerchantEmailCodeXRecaptchaTypeEnum,
  VerifyMerchantEmailCodeXRecaptchaTypeEnum,
} from '@/generated/api/ncps-api';
import { apiConfigurationFactory } from '@/infrastructure/request';

const auth0APINoAuth = new MerchantAuth0Api(apiConfigurationFactory('public'));

export const requestSendEmailCode = async (
  email: string,
  { recaptchaToken, recaptchaType }: ReCaptchaParams,
  initOverrides?: RequestInit,
): Promise<'ok'> => {
  await auth0APINoAuth.requestMerchantEmailCode(
    recaptchaToken,
    { email },
    RequestMerchantEmailCodeXRecaptchaTypeEnum[recaptchaType],
    initOverrides,
  );
  return 'ok';
};

export const requestVerifyEmailCode = async (
  data: { email: string; code: string },
  { recaptchaToken, recaptchaType }: ReCaptchaParams,
  initOverrides?: RequestInit,
) =>
  auth0APINoAuth.verifyMerchantEmailCode(
    recaptchaToken,
    data,
    VerifyMerchantEmailCodeXRecaptchaTypeEnum[recaptchaType],
    initOverrides,
  );
