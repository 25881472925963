import { useAppNestedListDataView } from '@/app/hooks';
import { storeCollectTasksForAddressesListParameters } from '@/features/collectable/actions';
import type { PushAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useCollectTaskSummaryForAddresses from './useCollectTaskSummaryForAddresses';

export default function useCollectTaskSummaryForAddressesListView(addresses: PushAddressLinkAPIModel[] | undefined) {
  return useAppNestedListDataView(
    useCollectTaskSummaryForAddresses(addresses),
    storeCollectTasksForAddressesListParameters,
    addresses,
  );
}
