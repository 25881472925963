import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshPaymentTransaction } from '@/features/payment-transactions/components';
import { usePaymentTransaction } from '@/features/payment-transactions/hooks';
import type { PaymentTransaction } from '@/features/payment-transactions/types';
import { PaymentsDocumentationLink } from '@/features/payments/components';
import type { PaymentTransactionSearchIdAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPaymentTransactionDataLoading = <
  Original extends { data: PaymentTransaction } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { paymentTransactionId: PaymentTransactionSearchIdAPIModel },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithPaymentTransactionDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = usePaymentTransaction(props.paymentTransactionId);
    return withCardDataLoading<PaymentTransaction, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPagePayments.TRANSACTION_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <PaymentsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationRefreshPaymentTransaction
              paymentId={props.paymentTransactionId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withPaymentTransactionDataLoading;
