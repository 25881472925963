import { Space } from 'antd';
import React, { useMemo, useRef } from 'react';

import type { LinkMode, OperationMode } from '@/components';
import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { PayoutsTable, PayoutsDocumentationLink } from '@/features/payouts/components';
import { usePayoutListView } from '@/features/payouts/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { useBounds } from '@/hooks';
import PayoutAddLink from '@/pages/settlements/payouts/payout-add/components/PayoutAddLink';

import type { PayoutsCardProps } from './types';
import type { FC } from 'react';

const PayoutsCard: FC<PayoutsCardProps> = (props) => {
  const parameters = usePayoutListView();
  const ref = useRef<HTMLDivElement>(null);
  const size = useBounds(ref);
  const documentationLinkMode: LinkMode = useMemo(
    () => ((size?.width ?? 0) < 530 ? 'icon' : 'text_icon'),
    [size?.width],
  );
  const createMode: OperationMode = useMemo(() => ((size?.width ?? 0) < 430 ? 'inline' : 'button'), [size?.width]);

  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPageSettlements.PAYOUTS_COMPONENTS_PAYOUTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <PayoutsDocumentationLink
            data-test={dataTest && `${dataTest}-documentationLink`}
            mode={documentationLinkMode}
          />
          <PayoutAddLink data-test={dataTest && `${dataTest}-create`} mode={createMode} />
        </Space>
      }
    >
      <PayoutsTable {...parameters} {...props} />
    </PageCard>
  );
};

const PayoutsCardMemo = React.memo(PayoutsCard);

export default PayoutsCardMemo;
