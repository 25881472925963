import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import SettingsBreadcrumb from '@/pages/settings/components/SettingsBreadcrumb';

import BrandingSummaryLink from '../BrandingSummaryLink';

import type { BrandingSummaryBreadcrumbProps } from './types';

const BrandingSummaryBreadcrumb: React.FC<BrandingSummaryBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <SettingsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <BrandingSummaryLink
            title={<FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="text"
          />
        ),
      },
      ...items,
    ]}
  />
);

const BrandingSummaryBreadcrumbMemo = React.memo(BrandingSummaryBreadcrumb);

export default BrandingSummaryBreadcrumbMemo;
