import { useMemo } from 'react';

import type { UseAppLoadableData } from '@/app/hooks';
import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { useListBlockchains, useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import { fetchGasWallets } from '@/features/gas-wallets/actions';
import { makeSelectGasWalletBatch } from '@/features/gas-wallets/selectors';
import type { GasWallet } from '@/features/gas-wallets/types';
import { makeSelectPending } from '@/features/global/selectors';
import { mapStoredState } from '@/infrastructure/model';
import type { LoadingPartialDataState, PartialData } from '@/infrastructure/model/partial/types';

const selectPending = makeSelectPending(fetchGasWallets);
const selectData = makeSelectGasWalletBatch();

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingPartialDataState<GasWallet>, Parameters<typeof fetchGasWallets>[0]> =>
  fetchGasWallets({ force });

export default function useMerchantGasWallets(): UseAppLoadableData<
  PartialData<GasWallet>,
  LoadingPartialDataState<GasWallet>,
  LoadingPartialDataState<GasWallet>
> {
  const { network } = useSelectedNetworkActions();
  const blockchainsData = useListBlockchains(network);
  const blockchains = useMemo(
    () => (blockchainsData.data.data ?? []).map(({ blockchain }) => blockchain),
    [blockchainsData.data.data],
  );
  const { data: baseData, ...state } = useAppLoadableData(fetchFactory, selectData, selectPending);
  const data = useMemo(
    () =>
      mapStoredState(baseData, (wallets) =>
        wallets.filter(({ bt }) => blockchains.includes(bt)).sort((v1, v2) => v1.bt.localeCompare(v2.bt)),
      ),
    [baseData, blockchains],
  );
  return { ...state, data };
}
