import { PageContainer } from '@/components';
import SettlementIntentLink from '@/pages/settlements/intent-view/components/SettlementIntentLink';

import { SettlementPendingIntentsCard, SettlementPendingIntentsBreadcrumb } from './components';

import type React from 'react';

const dataTest: string | undefined = 'intents';

const PendingIntentsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<SettlementPendingIntentsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <SettlementPendingIntentsCard
      data-test={dataTest && `${dataTest}-card`}
      SettlementIntentLink={SettlementIntentLink}
    />
  </PageContainer>
);

export default PendingIntentsPage;
