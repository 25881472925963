import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  HourglassOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  SnippetsOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { pendingStatusIcons } from '@/features/global/components/PendingStatusView';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import type { PlainSubscriptionStatusViewProps } from './types';

const icons: Record<SubscriptionStatusAPIModel, React.ReactNode> = {
  [SubscriptionStatusAPIModel.Draft]: <SnippetsOutlined style={{ color: 'blue' }} />,
  [SubscriptionStatusAPIModel.Trial]: (
    <Badge count={<ClockCircleOutlined style={{ fontSize: 'smaller' }} />} showZero>
      <PlayCircleOutlined style={{ color: 'green' }} />
    </Badge>
  ),
  [SubscriptionStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <HourglassOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [SubscriptionStatusAPIModel.Active]: <PlayCircleOutlined style={{ color: 'green' }} />,
  [SubscriptionStatusAPIModel.Suspended]: <ExclamationCircleOutlined style={{ color: 'orange' }} />,
  [SubscriptionStatusAPIModel.PendingPause]: pendingStatusIcons.SubscriptionPausing,
  [SubscriptionStatusAPIModel.Paused]: <PauseCircleOutlined style={{ color: 'orange' }} />,
  [SubscriptionStatusAPIModel.PendingUnPause]: pendingStatusIcons.SubscriptionUnPausing,
  [SubscriptionStatusAPIModel.Finished]: <CheckCircleOutlined style={{ color: 'blue' }} />,
  [SubscriptionStatusAPIModel.PendingCancel]: pendingStatusIcons.SubscriptionCancelling,
  [SubscriptionStatusAPIModel.Cancelled]: <StopOutlined style={{ color: 'red' }} />,
  [SubscriptionStatusAPIModel.Error]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const i18n: Record<SubscriptionStatusAPIModel, I18nMessages> = {
  [SubscriptionStatusAPIModel.Draft]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_DRAFT,
  [SubscriptionStatusAPIModel.Trial]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_TRIAL,
  [SubscriptionStatusAPIModel.Pending]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDING,
  [SubscriptionStatusAPIModel.Active]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_ACTIVE,
  [SubscriptionStatusAPIModel.Suspended]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_SUSPENDED,
  [SubscriptionStatusAPIModel.PendingPause]:
    I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDINGPAUSE,
  [SubscriptionStatusAPIModel.Paused]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PAUSED,
  [SubscriptionStatusAPIModel.PendingUnPause]:
    I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDINGUNPAUSE,
  [SubscriptionStatusAPIModel.Finished]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_FINISHED,
  [SubscriptionStatusAPIModel.PendingCancel]:
    I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDINGCANCEL,
  [SubscriptionStatusAPIModel.Cancelled]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_CANCELLED,
  [SubscriptionStatusAPIModel.Error]: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_ERROR,
};

const PlainSubscriptionStatusView: React.FC<PlainSubscriptionStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const PlainSubscriptionStatusViewMemo = React.memo(PlainSubscriptionStatusView);

export default PlainSubscriptionStatusViewMemo;
