import { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import type { Payment, PaymentFilterPredicate } from '@/features/payments/types';
import { PaymentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { enumByKey, nameof } from '@/infrastructure/utils/ts';
import { sortOrderToTable } from '@/infrastructure/view';

import OperationGeneratePaymentURL from '../OperationGeneratePaymentURL';
import PaymentStatusView from '../PaymentStatusView';
import PaymentView from '../PaymentView';

import type { PaymentsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';
import type React from 'react';

const doExtractKey = ({ id }: Payment) => id;
const doSortColumn = (s: string) => enumByKey(PaymentSortByAPIModel, s);

const PaymentsTable = <FilterType extends PaymentFilterPredicate = PaymentFilterPredicate>({
  'data-test': dataTest,
  data,
  loading,
  page,
  forceRefresh,
  columnState,
  sortBy,
  updateParameters,
  filter,
  ExportOperation,
  FilterForm,
  PaymentLink,
}: PaymentsTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<Payment>[] = useMemo(
    () => [
      {
        key: nameof<Payment>('id'),
        title: <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_ID} />,
        dataIndex: nameof<Payment>('id'),
        hideInSetting: true,
        render: (_, { id }) => <ReadonlyComponent data-test={dataTest && `${dataTest}-${id}-id`} value={id} />,
      },
      {
        title: <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_CREATED_AT} />,
        dataIndex: nameof<Payment>('createdAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.createdAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, createdAt }) => (
          <ReadonlyDateTime value={createdAt} data-test={dataTest && `${dataTest}-${id}-createdAt`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_EXPIRES_AT} />,
        dataIndex: nameof<Payment>('expiresAt'),
        valueType: 'dateTime',
        render: (_, { id, expiresAt }) => (
          <ReadonlyDateTime value={expiresAt} data-test={dataTest && `${dataTest}-${id}-expiresAt`} />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_AMOUNT} />,
        dataIndex: nameof<Payment>('amount'),
        fixed: 'right',
        width: 120,
        render: (_, { id, amount, asset }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={asset}
                value={amount}
                withCurrency
                withBlockchainMark
                data-test={dataTest && `${dataTest}-${id}-amount`}
              />
            }
            copyable={false}
          />
        ),
      },
      {
        title: <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_STATUS} />,
        dataIndex: nameof<Payment>('status'),
        width: 1,
        fixed: 'right',
        render: (_, { id, status }) => (
          <PaymentStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        align: 'center',
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {PaymentLink && <PaymentLink value={id} data-test={dataTest && `${dataTest}-${id}-linkTo`} />}
            <OperationGeneratePaymentURL paymentId={id} data-test={dataTest && `${dataTest}-${id}-opGenerateURL`} />
          </StopPropagationContainer>
        ),
      },
    ],
    [PaymentLink, dataTest, sortBy.createdAt],
  );

  const preview = useCallback(
    (value: Payment) => ({
      title: PaymentLink ? (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeaturePayments.COMPONENTS_PAYMENTS_TABLE_PAYMENT_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            <PaymentLink
              value={value.id}
              mode="text"
              title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
            />
          }
          data-test={dataTest && `${dataTest}-paymentDialogTitle`}
        />
      ) : (
        <FormattedMessage
          id={I18nFeaturePayments.COMPONENTS_PAYMENTS_TABLE_PAYMENT_DIALOG_TITLE}
          values={{ id: value.id }}
        />
      ),
      content: (
        <PaymentView
          data={value}
          data-test={dataTest && `${dataTest}-payment`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [PaymentLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'createdRange', 'createdRangeRelative', FilterType>
              key="filter"
              absoluteKey={nameof<FilterType, 'createdRange'>('createdRange')}
              relativeKey={nameof<FilterType, 'createdRangeRelative'>('createdRangeRelative')}
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
            />,
            filterComponent,
          ]
        : undefined,
    [ExportOperation, FilterForm, dataTest, exportMode, data.data?.total, quickFilterMode, filter, updateParameters],
  );

  const filterOpts = useMemo(() => FilterForm && { Form: FilterForm, empty: {} as FilterType }, [FilterForm]);

  return (
    <div ref={ref}>
      <NCPSTable<Payment, FilterType, PaymentSortByAPIModel>
        data-test={dataTest}
        filter={filterOpts}
        preview={preview}
        sortColumnToModel={doSortColumn}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={doExtractKey}
        toolBarRender={toolBar}
      />
    </div>
  );
};

export default PaymentsTable;
