import { useCallback, useEffect, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { DataFetchError, FormattedMessage, Operation, PageLoading } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useActiveCompany } from '@/features/company/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { withFinally, withSuppressError } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthToken = makeSelectAuthToken();

const withActiveCompanyGuard = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithActiveCompanyGuard',
  )((props) => {
    const [hasBeenLoaded, setHasBeenLoaded] = useStateMountSafe(false);
    const [isRefreshed, setIsRefreshed] = useStateMountSafe(false);
    const { data: company, forceRefresh: companyRefresh, loading: companyLoading } = useActiveCompany();
    const loginInfo = useAppSelector(selectAuthToken);
    const activeCompanyId = loginInfo.data?.info.activeCompanyId;
    const { logout } = useAuthActions();
    const refreshCompany = useMemo(
      () => withFinally(companyRefresh, () => setIsRefreshed(true)),
      [companyRefresh, setIsRefreshed],
    );

    useEffect(() => {
      if (company.data && !hasBeenLoaded) {
        setHasBeenLoaded(true);
      }
    }, [company.data, hasBeenLoaded, setHasBeenLoaded]);
    const logoutLink = useCallback(
      (val: React.ReactNode) => (
        <Operation title={val} onClick={withSuppressError(logout.act)} icon={null} mode="link" />
      ),
      [logout.act],
    );

    if (!hasBeenLoaded && (companyLoading || company.isDirty)) {
      return (
        <MessageLayout>
          <PageLoading type="WithActiveCompanyGuard" />
        </MessageLayout>
      );
    }

    if (!companyLoading && !company.isDirty && !company.data) {
      if (!activeCompanyId || isRefreshed) {
        return (
          <MessageLayout>
            <DataFetchError
              message={
                <FormattedMessage
                  id={I18nFeatureCompany.HOCS_ACTIVE_COMPANY_GUARD_COMPANY_NO_ACTIVE}
                  values={{ ln: logoutLink }}
                />
              }
            />
          </MessageLayout>
        );
      }
      return (
        <MessageLayout>
          <DataFetchError
            refresh={refreshCompany}
            message={<FormattedMessage id={I18nFeatureCompany.HOCS_ACTIVE_COMPANY_GUARD_COMPANY_UNAVAILABLE} />}
          />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withActiveCompanyGuard;
