import React, { useMemo } from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useActualBalances } from '@/features/statistics/hooks';
import { I18nFeatureStatistics } from '@/generated/i18n/i18n';

import type { OperationRefreshBalancesProps } from './types';

const OperationRefreshBalances: React.FC<OperationRefreshBalancesProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
}) => {
  const { forceRefresh } = useActualBalances();
  const messages = useMemo(
    () => ({
      title: title ?? <FormattedMessage id={I18nFeatureStatistics.COMPONENTS_OPERATION_REFRESH_BALANCES_TITLE} />,
      error: {
        message: <FormattedMessage id={I18nFeatureStatistics.COMPONENTS_OPERATION_REFRESH_BALANCES_ERROR_MESSAGE} />,
      },
      success: {
        message: <FormattedMessage id={I18nFeatureStatistics.COMPONENTS_OPERATION_REFRESH_BALANCES_SUCCESS_MESSAGE} />,
      },
    }),
    [title],
  );
  return <OperationRefresh data-test={dataTest} messages={messages} refresh={forceRefresh} mode={mode} />;
};

const OperationRefreshBalancesMemo = React.memo(OperationRefreshBalances);

export default OperationRefreshBalancesMemo;
