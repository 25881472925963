import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { extractMerchantWalletRunningBalanceId } from '@/features/merchant-wallet-balance/utils';
import { extractMerchantWalletTransferId } from '@/features/merchant-wallet-transfers/utils';
import { createNormalizedFullReducers } from '@/infrastructure/model/full/reducers';
import { createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { defaultListState } from '@/infrastructure/model/list/types';

import {
  markMerchantWalletRunningBalanceDirty,
  storeMultipleMerchantWalletRunningBalance,
  storeMerchantWalletRunningBalance,
  storeMerchantWalletRunningBalanceListData,
  storeMerchantWalletRunningBalanceListParameters,
  markMerchantWalletRunningBalanceListDirty,
  storeTransfersForRunningBalanceFullData,
  markTransfersForRunningBalanceFullDirty,
  storeTransfersForRunningBalanceFullDataParameters,
} from './actions';
import { defaultMerchantWalletRunningBalanceSortBy, defaultMerchantWalletRunningBalanceTransfersState } from './types';

import type { MerchantWalletRunningBalancesState } from './types';
import type { Draft } from 'immer';

const initialState: MerchantWalletRunningBalancesState = {
  entities: {},
  list: defaultListState({}, defaultMerchantWalletRunningBalanceSortBy),
  transfers: {
    columnState: {},
    data: {},
  },
};

const {
  storeMultipleMerchantWalletRunningBalanceReducer,
  storeMerchantWalletRunningBalanceReducer,
  markMerchantWalletRunningBalanceDirtyReducer,
  storeMerchantWalletRunningBalanceListParametersReducer,
  markMerchantWalletRunningBalanceListDirtyReducer,
  storeMerchantWalletRunningBalanceListDataReducer,
} = createNormalizedListReducers(
  'MerchantWalletRunningBalance' as const,
  (state: Draft<MerchantWalletRunningBalancesState>) => state.list,
  (state, list) => ({ ...state, list }),
  initialState.list,
  // this cast is workaround caused by BigNumber in the store
  (state) => state.entities as MerchantWalletRunningBalancesState['entities'],
  (state, entities) => ({ ...state, entities }),
  extractMerchantWalletRunningBalanceId,
);

const {
  storeTransfersForRunningBalanceFullDataReducer,
  markTransfersForRunningBalanceFullDirtyReducer,
  storeTransfersForRunningBalanceFullParametersReducer,
} = createNormalizedFullReducers(
  'TransfersForRunningBalance',
  (state: Draft<MerchantWalletRunningBalancesState>, balanceId: string | undefined) => state.transfers.data[balanceId!],
  (state, balanceId, newFullDataState) => ({
    ...state,
    transfers: { ...state.transfers, data: { ...state.transfers.data, [balanceId]: newFullDataState } },
  }),
  (state) => state.transfers.columnState,
  (state, columnState) => ({ ...state, transfers: { ...state.transfers, columnState } }),
  defaultMerchantWalletRunningBalanceTransfersState,
  extractMerchantWalletTransferId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeMerchantWalletRunningBalanceListData, storeMerchantWalletRunningBalanceListDataReducer)
    .addCase(storeMerchantWalletRunningBalanceListParameters, storeMerchantWalletRunningBalanceListParametersReducer)
    .addCase(markMerchantWalletRunningBalanceListDirty, markMerchantWalletRunningBalanceListDirtyReducer)

    .addCase(markTransfersForRunningBalanceFullDirty, markTransfersForRunningBalanceFullDirtyReducer)
    .addCase(storeTransfersForRunningBalanceFullData, storeTransfersForRunningBalanceFullDataReducer)
    .addCase(storeTransfersForRunningBalanceFullDataParameters, storeTransfersForRunningBalanceFullParametersReducer)

    .addCase(markMerchantWalletRunningBalanceDirty, markMerchantWalletRunningBalanceDirtyReducer)
    .addCase(storeMerchantWalletRunningBalance, storeMerchantWalletRunningBalanceReducer)
    .addCase(storeMultipleMerchantWalletRunningBalance, storeMultipleMerchantWalletRunningBalanceReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
