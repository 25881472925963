/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalBatchStatsAPIModel } from './WithdrawalBatchStatsAPIModel';
import {
    WithdrawalBatchStatsAPIModelFromJSON,
    WithdrawalBatchStatsAPIModelFromJSONTyped,
    WithdrawalBatchStatsAPIModelToJSON,
} from './WithdrawalBatchStatsAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalBatchesAPIModel
 */
export interface WithdrawalBatchesAPIModel {
    /**
     * 
     * @type {Array<WithdrawalBatchStatsAPIModel>}
     * @memberof WithdrawalBatchesAPIModel
     */
    batches?: Array<WithdrawalBatchStatsAPIModel>;
}

/**
 * Check if a given object implements the WithdrawalBatchesAPIModel interface.
 */
export function instanceOfWithdrawalBatchesAPIModel(value: object): boolean {
    return true;
}

export function WithdrawalBatchesAPIModelFromJSON(json: any): WithdrawalBatchesAPIModel {
    return WithdrawalBatchesAPIModelFromJSONTyped(json, false);
}

export function WithdrawalBatchesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalBatchesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'batches': json['batches'] == null ? undefined : ((json['batches'] as Array<any>).map(WithdrawalBatchStatsAPIModelFromJSON)),
    };
}

export function WithdrawalBatchesAPIModelToJSON(value?: WithdrawalBatchesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'batches': value['batches'] == null ? undefined : ((value['batches'] as Array<any>).map(WithdrawalBatchStatsAPIModelToJSON)),
    };
}

