import { Space } from 'antd';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import {
  GasHistoryFilterForm as BaseGasHistoryFilterForm,
  type GasHistoryFilterFormProps,
  GasHistoryTable,
  OperationExportGasHistory,
} from '@/features/gas-history/components';
import { useGasHistoryListView } from '@/features/gas-history/hooks';
import type { GasHistoryFilterPredicate } from '@/features/gas-history/types';
import { I18nPageGasHistory } from '@/generated/i18n/i18n';
import GasHistoryViewLink from '@/pages/gas-history/gas-history-view/components/GasHistoryViewLink';

import { GasHistoryBreadcrumb } from './components';

import type React from 'react';

const hiddenNetwork = { network: true };
const GasHistoryFilterForm: React.FC<Omit<GasHistoryFilterFormProps<GasHistoryFilterPredicate>, 'hidden'>> = (
  props,
) => <BaseGasHistoryFilterForm {...props} hidden={hiddenNetwork} />;

const dataTest: string | undefined = 'gas-history';

const GasHistoryPage: React.FC = () => {
  const view = useGasHistoryListView();
  return (
    <PageContainer
      data-test={dataTest && `${dataTest}-container`}
      breadcrumb={<GasHistoryBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <PageCard
        title={
          <Space>
            <FormattedMessage id={I18nPageGasHistory.ALL_COMPONENTS_CARD} />
            <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
          </Space>
        }
        cardSize="big"
      >
        <GasHistoryTable
          data-test={dataTest}
          {...view}
          ExportOperation={OperationExportGasHistory}
          FilterForm={GasHistoryFilterForm}
          GasHistoryLink={GasHistoryViewLink}
        />
      </PageCard>
    </PageContainer>
  );
};

export default GasHistoryPage;
