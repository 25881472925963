import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, WebURLItem } from '@/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { CompanyWebsiteItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const CompanyWebsiteItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  placeholder,
  readonly,
  required,
  messages: baseMessages,
  InputProps = {},
  ItemProps = {},
}: CompanyWebsiteItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const messages = useMemo(
    () => ({
      label: baseMessages?.label ?? (
        <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_WEBSITE_LABEL} />
      ),
      required: baseMessages?.required ?? (
        <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_WEBSITE_ERROR_REQUIRED} />
      ),
      placeholder: placeholder ?? i18n({ id: I18nFeatureCompanySettings.COMPONENTS_COMPANY_WEBSITE_PLACEHOLDER }),
    }),
    [baseMessages?.label, baseMessages?.required, placeholder, i18n],
  );
  return (
    <WebURLItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      required={required}
      messages={messages}
      InputProps={InputProps}
      ItemProps={ItemProps}
    />
  );
};

const CompanyWebsiteItemMemo = React.memo(CompanyWebsiteItem) as typeof CompanyWebsiteItem;

export default CompanyWebsiteItemMemo;
