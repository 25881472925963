import { useIntl } from 'react-intl';

import { FormattedMessage, WebURLItem } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { LogoURLItemProps } from './types';
import type React from 'react';

const LogoURLItem: React.FC<LogoURLItemProps> = ({ 'data-test': dataTest, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <WebURLItem<PaymentsBranding>
      data-test={dataTest}
      name={[nameof<PaymentsBranding, 'logo'>('logo'), nameof<NonNullable<PaymentsBranding['logo']>>('url')]}
      readonly={disabled}
      messages={{
        required: <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_URL_ITEM_REQUIRED} />,
        placeholder: formatMessage({
          id: I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_URL_ITEM_PLACEHOLDER,
        }),
      }}
      ItemProps={{ label: null }}
    />
  );
};

export default LogoURLItem;
