import { ExclamationCircleOutlined, FileDoneOutlined, FileOutlined, HourglassOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { PaymentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { PaymentStatusViewProps } from './types';

const icons: Record<PaymentStatusAPIModel, React.ReactNode> = {
  [PaymentStatusAPIModel.Created]: <FileOutlined style={{ color: 'blue' }} />,
  [PaymentStatusAPIModel.InProgress]: <HourglassOutlined spin style={{ color: 'blue' }} />,
  [PaymentStatusAPIModel.Expired]: <HourglassOutlined style={{ color: 'red' }} />,
  [PaymentStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  [PaymentStatusAPIModel.Succeeded]: <FileDoneOutlined style={{ color: 'green' }} />,
};

const i18n: Record<PaymentStatusAPIModel, I18nMessages> = {
  [PaymentStatusAPIModel.Created]: I18nFeaturePayments.COMPONENTS_PAYMENT_STATUS_VIEW_CREATED,
  [PaymentStatusAPIModel.InProgress]: I18nFeaturePayments.COMPONENTS_PAYMENT_STATUS_VIEW_INPROGRESS,
  [PaymentStatusAPIModel.Expired]: I18nFeaturePayments.COMPONENTS_PAYMENT_STATUS_VIEW_EXPIRED,
  [PaymentStatusAPIModel.Failed]: I18nFeaturePayments.COMPONENTS_PAYMENT_STATUS_VIEW_FAILED,
  [PaymentStatusAPIModel.Succeeded]: I18nFeaturePayments.COMPONENTS_PAYMENT_STATUS_VIEW_SUCCEEDED,
};

const PaymentStatusViewRaw: React.FC<PaymentStatusViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const PaymentStatusView = React.memo(PaymentStatusViewRaw) as typeof PaymentStatusViewRaw;

export default PaymentStatusView;
