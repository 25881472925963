/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyAPIModel
 */
export interface CompanyAPIModel {
    /**
     * 
     * @type {number}
     * @memberof CompanyAPIModel
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyAPIModel
     */
    hid: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAPIModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAPIModel
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAPIModel
     */
    extendedPubKey?: string;
    /**
     * 
     * @type {string}
     * @memberof CompanyAPIModel
     */
    partnerAddress?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyAPIModel
     */
    isKYBFinished: boolean;
    /**
     * 
     * @type {Date}
     * @memberof CompanyAPIModel
     */
    createdAt: Date;
}

/**
 * Check if a given object implements the CompanyAPIModel interface.
 */
export function instanceOfCompanyAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('hid' in value)) return false;
    if (!('name' in value)) return false;
    if (!('owner' in value)) return false;
    if (!('isKYBFinished' in value)) return false;
    if (!('createdAt' in value)) return false;
    return true;
}

export function CompanyAPIModelFromJSON(json: any): CompanyAPIModel {
    return CompanyAPIModelFromJSONTyped(json, false);
}

export function CompanyAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hid': json['hid'],
        'name': json['name'],
        'owner': json['owner'],
        'extendedPubKey': json['extendedPubKey'] == null ? undefined : json['extendedPubKey'],
        'partnerAddress': json['partnerAddress'] == null ? undefined : json['partnerAddress'],
        'isKYBFinished': json['isKYBFinished'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function CompanyAPIModelToJSON(value?: CompanyAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'hid': value['hid'],
        'name': value['name'],
        'owner': value['owner'],
        'extendedPubKey': value['extendedPubKey'],
        'partnerAddress': value['partnerAddress'],
        'isKYBFinished': value['isKYBFinished'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

