import {
  SubscriptionPlanShortView,
  type SubscriptionPlanShortViewProps,
} from '@/features/subscription-plans/components';

import { withSubscriptionPlanShortCard } from './hocs';

export default withSubscriptionPlanShortCard<SubscriptionPlanShortViewProps>((props) => (
  <SubscriptionPlanShortView {...props} columns={1} />
));
