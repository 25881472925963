import { useMemo } from 'react';

import { useCollectTaskSummaryForAddressesListView } from '@/features/collectable/hooks';

import useDonation from './useDonation';

export default function useCollectTaskSummaryForDonationListView(donationId: string | undefined) {
  const { data } = useDonation(donationId);
  const addresses = useMemo(
    () => data.data?.addresses.map(({ address, asset }) => ({ address, asset })),
    [data.data?.addresses],
  );
  return useCollectTaskSummaryForAddressesListView(addresses);
}
