import { useCallback, useMemo } from 'react';

import { mapStoredState } from '@/infrastructure/model';

import useCompanySettings from '../useCompanySettings';

export default function useWebhooks() {
  const { forceRefresh, data, ...state } = useCompanySettings();
  return {
    ...state,
    data: useMemo(() => mapStoredState(data, ({ webhooks }) => webhooks), [data]),
    forceRefresh: useCallback(
      async () => mapStoredState(await forceRefresh(), ({ webhooks }) => webhooks),
      [forceRefresh],
    ),
  };
}
