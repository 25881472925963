import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { APIKeysCard, CompanyIntegrationBreadcrumb, WebhooksCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'integration';

const SettingsIntegrationPage: React.FC = () => (
  <SettingsPageLayout
    data-test={dataTest && `${dataTest}-container`}
    selected={SettingsTabType.integration}
    breadcrumb={<CompanyIntegrationBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <APIKeysCard data-test={dataTest && `${dataTest}-keys`} />
    <WebhooksCard data-test={dataTest && `${dataTest}-webhooks`} />
  </SettingsPageLayout>
);

export default SettingsIntegrationPage;
