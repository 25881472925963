import { useCallback } from 'react';

import useStateMountSafe from '@/hooks/useStateMountSafe';
import type { Func } from '@/infrastructure/utils/ts';

export default function useFormStateVisible<T>(initialState?: T | (() => T)): {
  visible: boolean;
  state?: T;
  show: (state: T) => void;
  hide: () => void;
  withFormHide: <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>;
} {
  const [state, setFormState] = useStateMountSafe<T | undefined>(initialState);
  const withFormHide = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        const value = await func(...params);
        setFormState(undefined);
        return value;
      },
    [setFormState],
  );
  const hide = useCallback(() => setFormState(undefined), [setFormState]);
  const show = useCallback((newState: T) => setFormState(newState), [setFormState]);

  return { visible: !!state, state, show, hide, withFormHide };
}
