import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
  InputItem,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  FormattedMessage,
} from '@/components';
import type { NewWebhook } from '@/features/company-settings/types';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { WebhooksFormContent, WebhooksFormProps } from './types';

const requiredFields = [nameof<WebhooksFormContent>('url'), nameof<WebhooksFormContent>('network')];

const WebhooksForm: React.FC<WebhooksFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  initialValues,
  selectedValues = [],
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { formatMessage } = useIntl();
  const { form, withResetForm } = useForm<WebhooksFormContent>();
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const doSubmit = useCallback(
    ({ url, ...rest }: NewWebhook) => withSubmitting(onSubmit)({ ...rest, url: url.trim() }),
    [onSubmit, withSubmitting],
  );
  const doReset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const validateValue = useCallback(
    (value: NewWebhook) => {
      const newURL = value.url.trim();
      const isNewValue = value.network !== initialValues?.network || newURL !== initialValues.url;
      if (isNewValue && !!selectedValues.find(({ network, url }) => value.network === network && newURL === url)) {
        throw new Error(formatMessage({ id: I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_URL_DUPLICATE }));
      }
    },
    [formatMessage, initialValues?.network, initialValues?.url, selectedValues],
  );
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_ERROR_COMMON} />;
  }, [error]);

  return (
    <Form<WebhooksFormContent>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      size="middle"
      onFinish={doSubmit}
      onReset={doReset}
    >
      {errorMessage && <ErrorFormMessage content={errorMessage} />}
      <InputItem
        name={nameof<WebhooksFormContent>('network')}
        data-test={dataTest && `${dataTest}-network`}
        ItemProps={{ hidden: true }}
        required
      />
      <InputItem
        name={nameof<WebhooksFormContent>('url')}
        data-test={dataTest && `${dataTest}-url`}
        messages={{
          label: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_URL_LABEL} />,
          required: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_URL_REQUIRED} />,
        }}
        placeholder={formatMessage({ id: I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_URL_PLACEHOLDER })}
        rules={[
          {
            type: 'url',
            message: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_URL_ERROR_INVALID} />,
          },
          {
            // eslint-disable-next-line @typescript-eslint/require-await
            validator: async (_, url?: string) => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              const network: BlockchainNetworkTypeAPIModel | undefined = form.getFieldValue(
                nameof<WebhooksFormContent>('network'),
              );
              if (url && network) {
                validateValue({
                  url: url.trim(),
                  network,
                });
              }
            },
          },
        ]}
        InputProps={{ className: 'ym-hide-content' }}
        ItemProps={{ dependencies: [nameof<WebhooksFormContent>('network')] }}
      />
      <FormCompletenessItem
        requiredFields={requiredFields}
        onChange={setFormComplete}
        checkIsComplete={({ getFieldValue }) =>
          getFieldValue(nameof<WebhooksFormContent>('url')) !== initialValues?.url
          || getFieldValue(nameof<WebhooksFormContent>('network')) !== initialValues?.network
        }
      />
      <FormFooter
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
        messages={{
          submit: initialValues?.url ? (
            <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_SUBMIT_UPDATE} tagName="span" />
          ) : (
            <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_WEBHOOK_FORM_SUBMIT_CREATE} tagName="span" />
          ),
        }}
        data-test={dataTest && `${dataTest}-submit`}
      />
    </Form>
  );
};

const WebhooksFormMemo = React.memo(WebhooksForm);

export default WebhooksFormMemo;
