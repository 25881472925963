import type {
  BlockchainNetworkTypeAPIModel,
  ChurnRateAPIModel,
  MonthlyGrowthRateStatisticsAPIModel,
  MonthlyRevenueStatisticsAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { SubscriptionStatisticsApi } from '@/generated/api/ncps-core/merchant-bo';
import { amountFromAPI } from '@/infrastructure/api';
import { coreConfigurationFactory } from '@/infrastructure/request';
import { asType } from '@/infrastructure/utils/ts';

import type { SubscriptionChurnRate, SubscriptionGrowthRate, SubscriptionRevenue } from './types';

const subscriptionStatisticsApi = new SubscriptionStatisticsApi(coreConfigurationFactory('auth'));

export const parseMonthlyGrowthRate = ({
  mean,
  median,
  perPlan,
  ...rest
}: MonthlyGrowthRateStatisticsAPIModel): SubscriptionGrowthRate => ({
  ...rest,
  mean: mean ?? [],
  median: median ?? [],
  perPlan: (perPlan ?? []).map(({ perMonth, ...grow }) => ({ ...grow, perMonth: perMonth ?? [] })),
});

export const parseMonthlyRevenue = ({
  total,
  mean,
  median,
  perPlan,
  ...rest
}: MonthlyRevenueStatisticsAPIModel): SubscriptionRevenue => ({
  ...rest,
  total: (total ?? []).map(({ amount, ...revenue }) => ({ ...revenue, amount: amountFromAPI(amount) })),
  mean: (asType<typeof total>(mean) ?? []).map(({ amount, ...revenue }) => ({
    ...revenue,
    amount: amountFromAPI(amount),
  })),
  median: (asType<typeof total>(median) ?? []).map(({ amount, ...revenue }) => ({
    ...revenue,
    amount: amountFromAPI(amount),
  })),
  perPlan: (perPlan ?? []).map(({ perMonth, ...plan }) => ({
    ...plan,
    perMonth: (perMonth ?? []).map(({ amount, ...revenue }) => ({ ...revenue, amount: amountFromAPI(amount) })),
  })),
});

export const parseChurnRate = ({ mean, median, perPlan, ...rest }: ChurnRateAPIModel): SubscriptionChurnRate => ({
  ...rest,
  mean: mean ?? [],
  median: median ?? [],
  perPlan: (perPlan ?? []).map(({ perCharge, ...plan }) => ({ ...plan, perCharge: perCharge ?? [] })),
});

export const queryMonthlyGrowthRate = async (
  network: BlockchainNetworkTypeAPIModel,
  initOverrides?: RequestInit,
): Promise<SubscriptionGrowthRate> =>
  parseMonthlyGrowthRate(await subscriptionStatisticsApi.getMonthlyGrowthRate(network, initOverrides));

export const queryMonthlyRevenue = async (
  network: BlockchainNetworkTypeAPIModel,
  initOverrides?: RequestInit,
): Promise<SubscriptionRevenue> =>
  parseMonthlyRevenue(await subscriptionStatisticsApi.getMonthlyRevenue(network, initOverrides));

export const queryChurnRate = async (
  network: BlockchainNetworkTypeAPIModel,
  initOverrides?: RequestInit,
): Promise<SubscriptionChurnRate> =>
  parseChurnRate(await subscriptionStatisticsApi.getChurnRate(network, initOverrides));
