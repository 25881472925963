import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { createPayment, exportPayments } from '@/features/payments/actions';
import type { Payment } from '@/features/payments/types';
import type { Report } from '@/features/reports/types';
import type { NewPaymentAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type HookAction } from '@/infrastructure/model';

import usePaymentList from './usePaymentList';

export interface UsePaymentActionsType {
  create: HookAction<[NewPaymentAPIModel], Payment>;
  exportPayments: HookAction<[], Report>;
}

export default function usePaymentsActions(): UsePaymentActionsType {
  const { loading } = usePaymentList();
  const { withExtractDataDispatch } = useAppDispatch();
  const isCreating = useActionPending(createPayment);
  const create: UsePaymentActionsType['create'] = {
    act: useMemo(() => withExtractDataDispatch(createPayment), [withExtractDataDispatch]),
    inAction: isCreating,
    available: !loading,
  };

  const isExporting = useActionPending(exportPayments);
  const exportPaymentsAction: UsePaymentActionsType['exportPayments'] = {
    act: useMemo(() => withExtractDataDispatch(exportPayments), [withExtractDataDispatch]),
    inAction: isExporting,
    available: !loading,
  };

  return { create, exportPayments: exportPaymentsAction };
}
