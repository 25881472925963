import { useCallback } from 'react';

import useStateMountSafe from '@/hooks/useStateMountSafe';
import type { Func } from '@/infrastructure/utils/ts';
import { isThenable } from '@/infrastructure/utils/ts';

export default function useSubmitting(
  initialValue: boolean,
): [
  boolean,
  <T extends unknown[] = unknown[], R = unknown, F = Func<T, R>>(func: F) => F,
  () => void,
  () => void,
  () => void,
] {
  const [submitting, setSubmitting] = useStateMountSafe(initialValue);
  const reset = useCallback(() => {
    setSubmitting(initialValue);
  }, [initialValue, setSubmitting]);
  const withSubmitting = useCallback(
    <V extends unknown[], R, F = Func<V, R>>(func: F): F =>
      ((...params: V) => {
        try {
          setSubmitting(true);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const r: R | Promise<R> = func(...params);
          if (isThenable(r)) {
            return (async () => {
              try {
                return await r;
              } finally {
                setSubmitting(false);
              }
            })();
          }
          setSubmitting(false);
          return r;
        } catch (e) {
          setSubmitting(false);
          throw e;
        }
      }) as F,
    [setSubmitting],
  );
  const markSubmitting = useCallback(() => setSubmitting(true), [setSubmitting]);
  const clearSubmitting = useCallback(() => setSubmitting(false), [setSubmitting]);

  return [submitting, withSubmitting, reset, markSubmitting, clearSubmitting];
}
