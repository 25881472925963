import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useSubscriptionChargesForSubscription } from '@/features/subscriptions/hooks';
import { I18nFeatureSubscriptionCharges } from '@/generated/i18n/i18n';

import type { OperationRefreshSubscriptionChargeProps } from './types';

const messages = {
  tooltip: (
    <FormattedMessage id={I18nFeatureSubscriptionCharges.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGE_TOOLTIP} />
  ),
  success: {
    message: (
      <FormattedMessage
        id={I18nFeatureSubscriptionCharges.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGE_SUCCESS_MESSAGE}
      />
    ),
  },
};

const OperationRefreshSubscriptionCharge: React.FC<OperationRefreshSubscriptionChargeProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  subscriptionChargeId,
}) => {
  const { forceRefresh } = useSubscriptionChargesForSubscription(subscriptionChargeId);
  return <OperationRefresh data-test={dataTest} messages={messages} refresh={forceRefresh} mode={mode} />;
};

const OperationRefreshSubscriptionChargeMemo = React.memo(OperationRefreshSubscriptionCharge);

export default OperationRefreshSubscriptionChargeMemo;
