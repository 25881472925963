import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import SettingsBreadcrumb from '@/pages/settings/components/SettingsBreadcrumb';

import ProfileLink from '../ProfileLink';

import type { ProfileBreadcrumbProps } from './types';

const ProfileBreadcrumb: React.FC<ProfileBreadcrumbProps> = ({ 'data-test': dataTest, disabled, items = [] }) => (
  <SettingsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.PROFILE_BREADCRUMB} />
        ) : (
          <ProfileLink mode="text" title={<FormattedMessage id={I18nPageSettings.PROFILE_BREADCRUMB} />} />
        ),
      },
      ...items,
    ]}
  />
);

const ProfileBreadcrumbMemo = React.memo(ProfileBreadcrumb);

export default ProfileBreadcrumbMemo;
