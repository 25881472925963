import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useDomains, useDomainsActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import MerchantDomainForm from '../MerchantDomainForm';

import type { OperationAddDomainProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_ADD_DOMAIN_MESSAGE_ERROR} />,
  success: () => <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_ADD_DOMAIN_MESSAGE_SUCCESS} />,
};

const OperationAddDomain: React.FC<OperationAddDomainProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { create: createAction } = useDomainsActions();
  const { data } = useDomains();
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const addNotification = useMemo(
    () => withNotification(formControl.withFormHide(withOnSuccess(createAction.act, onSuccess)), notificationOptions),
    [withNotification, formControl, onSuccess, createAction],
  );

  const disabledMessage = useMemo(
    () =>
      createAction.unavailabilityReason === 'too-many-domains' ? (
        <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_ADD_DOMAIN_ERROR_TOO_MANY} />
      ) : null,
    [createAction.unavailabilityReason],
  );

  const urls = useMemo(() => data.data?.map((domain) => domain.url), [data.data]);

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_ADD_DOMAIN_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <MerchantDomainForm
            selectedValues={urls}
            layout={{ labelCol: { span: 0 } }}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={addNotification}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_ADD_DOMAIN_TITLE} />}
        icon={<PlusCircleOutlined />}
        onClick={formControl.show}
        disabled={disabled || !createAction.available || !!disabledMessage}
        disabledMessage={disabledMessage}
        inProgress={inProgress || formControl.visible || createAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationAddDomainMemo = React.memo(OperationAddDomain);

export default OperationAddDomainMemo;
