import { useMemo } from 'react';

import type { AppAsyncThunkAction, AppRootState } from '@/app/store';
import { useFetching, useSubmitting } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

export interface UseAppMultipleData<
  Value,
  Id = string,
  ValueState extends { id: Id; data: LoadingStateWithDirty<Value> }[] = {
    id: Id;
    data: LoadingStateWithDirty<Value>;
  }[],
  FetchResult = ValueState,
> {
  loading: boolean;
  forceRefresh: () => Promise<FetchResult>;
  data: ValueState;
}

const falseOp = () => false;

export default function useAppMultipleData<
  Value,
  Id = string,
  ValueState extends { id: Id; data: LoadingStateWithDirty<Value> }[] = {
    id: Id;
    data: LoadingStateWithDirty<Value>;
  }[],
  FetchResult = ValueState,
>(
  fetchActionFactory: (params: { ids: Id[]; force: boolean }) => AppAsyncThunkAction<FetchResult, unknown>,
  multipleDataSelector: (ids: Id[]) => (state: AppRootState) => ValueState,
  multipleDataDirtyIdsSelector: (ids: Id[]) => (state: AppRootState) => Id[],
  ids: Id[],
  dataFetchingSelector?: (state: AppRootState) => boolean,
): UseAppMultipleData<Value, Id, ValueState, FetchResult> {
  const data = useAppSelector(useMemo(() => multipleDataSelector(ids), [ids, multipleDataSelector]));
  const dataFetching = useAppSelector(dataFetchingSelector ?? falseOp);
  const [fetching, withFetching] = useSubmitting(false);
  const { dispatch } = useAppDispatch();
  const dirtyIds = useAppSelector(
    useMemo(() => multipleDataDirtyIdsSelector(ids), [ids, multipleDataDirtyIdsSelector]),
  );
  const isDirty = !!dirtyIds.length;
  const loading = dataFetching || fetching;
  const fetch = useMemo(
    () => withFetching(() => dispatch(fetchActionFactory({ ids, force: false })).unwrap()),
    [withFetching, dispatch, fetchActionFactory, ids],
  );
  const forceRefresh = useMemo(
    () => withFetching(() => dispatch(fetchActionFactory({ ids, force: true })).unwrap()),
    [withFetching, dispatch, fetchActionFactory, ids],
  );
  useFetching(fetch, isDirty, loading);
  return { forceRefresh, loading, data };
}
