import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { RechargeTransactionsTable } from '@/features/recharges/components';
import { useRechargeTransactionsForAddressListView } from '@/features/recharges/hooks';
import { I18nPageRecharges } from '@/generated/i18n/i18n';

import type { RechargeAddressTransactionsCardProps } from './types';
import type { FC } from 'react';

const RechargeAddressTransactionsCard: FC<RechargeAddressTransactionsCardProps> = ({ addressId, ...props }) => {
  const parameters = useRechargeTransactionsForAddressListView(addressId);
  return (
    <PageCard
      title={<FormattedMessage id={I18nPageRecharges.ADDRESS_VIEW_COMPONENTS_TRANSACTIONS_CARD_TITLE} />}
      cardSize="big"
    >
      <RechargeTransactionsTable {...parameters} {...props} />
    </PageCard>
  );
};

const RechargeAddressTransactionsCardMemo = React.memo(RechargeAddressTransactionsCard);

export default RechargeAddressTransactionsCardMemo;
