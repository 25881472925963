import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import PayoutsBreadcrumb from '@/pages/settlements/payouts/components/PayoutsBreadcrumb';
import { settlementsPayoutAddLink } from '@/pages/settlements/routes';

import type { PayoutAddBreadcrumbProps } from './types';

const PayoutAddBreadcrumb: React.FC<PayoutAddBreadcrumbProps> = ({ 'data-test': dataTest, items = [], disabled }) => (
  <PayoutsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettlements.PAYOUT_ADD_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <Link
            mode="text"
            to={settlementsPayoutAddLink()}
            title={<FormattedMessage id={I18nPageSettlements.PAYOUT_ADD_COMPONENTS_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const PayoutAddBreadcrumbMemo = React.memo(PayoutAddBreadcrumb);

export default PayoutAddBreadcrumbMemo;
