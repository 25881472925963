import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';

import type { GasWalletRefundingTaskTitleProps } from './types';

const GasWalletRefundingTaskTitle: React.FC<GasWalletRefundingTaskTitleProps> = ({ payload }) => (
  <FormattedMessage
    id={I18nFeatureGasWallets.COMPONENTS_REFUNDING_TASK_MESSAGE}
    values={{
      count: payload && typeof payload === 'object' && !Array.isArray(payload) ? Object.keys(payload).length : 0,
    }}
  />
);

const GasWalletRefundingTaskTitleMemo = React.memo(GasWalletRefundingTaskTitle);

export default GasWalletRefundingTaskTitleMemo;
