import type { ReportParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/reports';

import MerchantWalletTransferFilterForm from '../MerchantWalletTransferFilterForm';

import type React from 'react';

const MerchantWalletTransfersReportParametersView: React.FC<ReportParametersViewProps> = ({
  value,
  'data-test': dataTest,
}) => <MerchantWalletTransferFilterForm data-test={dataTest} value={value} readonly />;

export default MerchantWalletTransfersReportParametersView;
