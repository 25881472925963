import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import {
  updateEmail,
  updateActiveAssets,
  updateDefaultAsset,
  updatePaymentSettings,
  confirmEmail,
  updateCompanyWebsite,
  updateForwarderSettings,
} from '@/features/company-settings/actions';
import type {
  CompanySettings,
  ConfirmCompanyEmailParams,
  RenewalTokenExpiration,
} from '@/features/company-settings/types';
import { useListAssets, useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { useActionPending } from '@/features/global/hooks';
import type { ForwarderSettingsAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useCompanySettings from '../useCompanySettings';

export type UpdateUnavailabilityReason = 'loading';

export interface UseCompanySettingsActionsType {
  updateActiveAssets: HookAction<[string[]], CompanySettings, UpdateUnavailabilityReason>;
  updateStatisticsAsset: HookAction<[string], CompanySettings, UpdateUnavailabilityReason>;
  updateCompensationMark: HookAction<[boolean], CompanySettings, UpdateUnavailabilityReason>;
  updateDeferredMark: HookAction<[boolean], CompanySettings, UpdateUnavailabilityReason>;
  updateEmail: HookAction<[string], CompanySettings | RenewalTokenExpiration, UpdateUnavailabilityReason>;
  confirmEmail: HookAction<[ConfirmCompanyEmailParams], void>;
  updateQRSupport: HookAction<[ForwarderSettingsAPIModel[]], CompanySettings>;
  updateWebsite: HookAction<[string], CompanySettings, UpdateUnavailabilityReason>;
}

export default function useCompanySettingsActions(): UseCompanySettingsActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data, loading } = useCompanySettings();
  const { network } = useSelectedNetwork();
  const { data: selectedNetworkAssets } = useListAssets(network);

  const updateUnavailabilityReason: UpdateUnavailabilityReason | undefined = (() => {
    if (loading || data.isDirty) {
      return 'loading';
    }
    return undefined;
  })();

  const updateAssetsPending = useActionPending(updateActiveAssets);
  const updateAssetsAction: UseCompanySettingsActionsType['updateActiveAssets']['act'] = useCallback(
    (assets: string[]) => {
      const unselectedNetworkActiveAssets =
        data.data?.activeAssets.filter((asset) => !selectedNetworkAssets.data?.find(({ code }) => code === asset))
        ?? [];
      return withExtractDataDispatch(updateActiveAssets)({
        assets: [...assets, ...unselectedNetworkActiveAssets],
      });
    },
    [data.data?.activeAssets, selectedNetworkAssets.data, withExtractDataDispatch],
  );
  const updateAssetsHook: UseCompanySettingsActionsType['updateActiveAssets'] = useMemo(
    () => ({
      act: updateAssetsAction,
      available: !updateUnavailabilityReason,
      unavailabilityReason: updateUnavailabilityReason,
      inAction: updateAssetsPending,
    }),
    [updateAssetsAction, updateAssetsPending, updateUnavailabilityReason],
  );

  const updateStatisticsAssetPending = useActionPending(updateDefaultAsset);
  const updateStatisticsAssetAction: UseCompanySettingsActionsType['updateStatisticsAsset']['act'] = useCallback(
    (asset: string) => withExtractDataDispatch(updateDefaultAsset)(asset),
    [withExtractDataDispatch],
  );
  const updateStatisticsAssetHook: UseCompanySettingsActionsType['updateStatisticsAsset'] = useMemo(
    () => ({
      act: updateStatisticsAssetAction,
      available: !updateUnavailabilityReason,
      unavailabilityReason: updateUnavailabilityReason,
      inAction: updateStatisticsAssetPending,
    }),
    [updateStatisticsAssetAction, updateStatisticsAssetPending, updateUnavailabilityReason],
  );

  const updatePaymentSettingsPending = useActionPending(updatePaymentSettings);
  const updateCompensationMarkAction: UseCompanySettingsActionsType['updateCompensationMark']['act'] = useCallback(
    (mark: boolean) =>
      withExtractDataDispatch(updatePaymentSettings)({ ...data.data!.payments, compensateDirect: mark }),
    [data.data, withExtractDataDispatch],
  );
  const updateCompensationMarkHook: UseCompanySettingsActionsType['updateCompensationMark'] = useMemo(
    () => ({
      act: updateCompensationMarkAction,
      available: !updateUnavailabilityReason,
      unavailabilityReason: updateUnavailabilityReason,
      inAction: updatePaymentSettingsPending,
    }),
    [updateCompensationMarkAction, updatePaymentSettingsPending, updateUnavailabilityReason],
  );
  const updateDeferredMarkAction: UseCompanySettingsActionsType['updateDeferredMark']['act'] = useCallback(
    (mark: boolean) =>
      withExtractDataDispatch(updatePaymentSettings)({ ...data.data!.payments, deferredAllowed: mark }),
    [data.data, withExtractDataDispatch],
  );
  const updateDeferredMarkHook: UseCompanySettingsActionsType['updateDeferredMark'] = useMemo(
    () => ({
      act: updateDeferredMarkAction,
      available: !updateUnavailabilityReason,
      unavailabilityReason: updateUnavailabilityReason,
      inAction: updatePaymentSettingsPending,
    }),
    [updateDeferredMarkAction, updatePaymentSettingsPending, updateUnavailabilityReason],
  );

  const updateEmailPending = useActionPending(updateEmail);
  const updateEmailAction: UseCompanySettingsActionsType['updateEmail']['act'] = useCallback(
    (email: string) => withExtractDataDispatch(updateEmail)({ email }),
    [withExtractDataDispatch],
  );
  const updateEmailHook: UseCompanySettingsActionsType['updateEmail'] = useMemo(
    () => ({
      act: updateEmailAction,
      available: !updateUnavailabilityReason,
      unavailabilityReason: updateUnavailabilityReason,
      inAction: updateEmailPending,
    }),
    [updateEmailAction, updateEmailPending, updateUnavailabilityReason],
  );

  const confirmEmailPending = useActionPending(confirmEmail);
  const confirmEmailAction: UseCompanySettingsActionsType['confirmEmail']['act'] = useMemo(
    () => withExtractDataDispatch(confirmEmail),
    [withExtractDataDispatch],
  );
  const confirmEmailHook: UseCompanySettingsActionsType['confirmEmail'] = useMemo(
    () => ({
      act: confirmEmailAction,
      available: true,
      inAction: confirmEmailPending,
    }),
    [confirmEmailAction, confirmEmailPending],
  );

  const updateQRSupportPending = useActionPending(updateForwarderSettings);
  const updateQRSupportAction: UseCompanySettingsActionsType['updateQRSupport']['act'] = useMemo(
    () => withExtractDataDispatch(updateForwarderSettings),
    [withExtractDataDispatch],
  );
  const updateQRSupportHook: UseCompanySettingsActionsType['updateQRSupport'] = useMemo(
    () => ({
      act: updateQRSupportAction,
      available: !updateUnavailabilityReason,
      unavailabilityReason: updateUnavailabilityReason,
      inAction: updateQRSupportPending,
    }),
    [updateQRSupportAction, updateQRSupportPending, updateUnavailabilityReason],
  );

  const updateWebsitePending = useActionPending(updateCompanyWebsite);
  const updateWebsiteAction: UseCompanySettingsActionsType['updateWebsite']['act'] = useMemo(
    () => withExtractDataDispatch(updateCompanyWebsite),
    [withExtractDataDispatch],
  );
  const updateWebsiteHook: UseCompanySettingsActionsType['updateWebsite'] = useMemo(
    () => ({
      act: updateWebsiteAction,
      available: !updateUnavailabilityReason,
      unavailabilityReason: updateUnavailabilityReason,
      inAction: updateWebsitePending,
    }),
    [updateWebsiteAction, updateWebsitePending, updateUnavailabilityReason],
  );

  return {
    updateActiveAssets: updateAssetsHook,
    updateStatisticsAsset: updateStatisticsAssetHook,
    updateCompensationMark: updateCompensationMarkHook,
    updateDeferredMark: updateDeferredMarkHook,
    updateEmail: updateEmailHook,
    confirmEmail: confirmEmailHook,
    updateQRSupport: updateQRSupportHook,
    updateWebsite: updateWebsiteHook,
  };
}
