import { EditOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { settingsCompanyAssetsEditLink } from '@/pages/settings/routes';

import type { UpdateActiveAssetsLinkProps } from './types';

const UpdateActiveAssetsLink: React.FC<UpdateActiveAssetsLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => {
  const { updateActiveAssets } = useCompanySettingsActions();
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(settingsCompanyAssetsEditLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <EditOutlined />}
      disabled={!updateActiveAssets.available}
      title={title ?? <FormattedMessage id={I18nPageSettings.COMPANY_ASSETS_EDIT_LINK_TITLE} />}
    />
  );
};

const UpdateActiveAssetsLinkMemo = React.memo(UpdateActiveAssetsLink);

export default UpdateActiveAssetsLinkMemo;
