import { useCallback, useMemo } from 'react';

import type { AppAsyncThunkAction, AppRootState } from '@/app/store';
import { useFetching, useSubmitting } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { isThenable } from '@/infrastructure/utils/ts';

import useAppDispatch from './useAppDispatch';
import useAppSelector from './useAppSelector';

export interface UseAppLoadableData<
  Value,
  FetchResult = void,
  ValueState extends LoadingStateWithDirty<Value> = LoadingStateWithDirty<Value>,
> {
  data: ValueState;
  loading: boolean;
  forceRefresh: () => Promise<FetchResult>;
}

const falseOp = () => false;

export default function useAppLoadableData<
  Value,
  FetchResult = void,
  ValueState extends LoadingStateWithDirty<Value> = LoadingStateWithDirty<Value>,
>(
  // this function should fail-safe
  fetchActionFactory:
    | ((force: boolean) => AppAsyncThunkAction<FetchResult, unknown>)
    | ((force: boolean) => Promise<FetchResult>),
  dataSelector: (state: AppRootState) => ValueState,
  dataFetchingSelector?: (state: AppRootState) => boolean,
  loadable = true,
): UseAppLoadableData<Value, FetchResult, ValueState> {
  const [fetching, withFetching] = useSubmitting(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector(dataSelector);
  const dataFetching = useAppSelector(dataFetchingSelector ?? falseOp);
  const { isDirty } = data;
  const loading = dataFetching || fetching;
  const callOrDispatch = useMemo(
    () =>
      withFetching(async (force: boolean) => {
        const call = fetchActionFactory(force);
        // to think of unwrap the action
        return isThenable(call) ? call : dispatch(call).unwrap();
      }),
    [dispatch, fetchActionFactory, withFetching],
  );
  const fetch = useCallback(() => callOrDispatch(false), [callOrDispatch]);
  const forceRefresh = useCallback(() => callOrDispatch(true), [callOrDispatch]);
  useFetching(fetch, isDirty && loadable, loading);
  return { data, forceRefresh, loading };
}
