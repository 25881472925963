import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useCompanyActiveAssets } from '@/features/company-settings/hooks';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { OperationAddDonationAssetProps } from './types';

const OperationAddDonationAsset: React.FC<OperationAddDonationAssetProps> = ({
  'data-test': dataTest,
  disabled,
  selectedAssets,
  onAdd,
}) => {
  const { data: companyAssets } = useCompanyActiveAssets();
  const doAdd = useCallback(() => {
    const assetName = companyAssets.data?.map(({ code }) => code).find((v) => !selectedAssets.includes(v));
    if (assetName) {
      onAdd({ asset: assetName, defaultAmounts: [], isActive: true });
    }
  }, [companyAssets.data, onAdd, selectedAssets]);
  return (
    <Operation
      mode="button"
      data-test={dataTest}
      disabled={disabled || selectedAssets.length === companyAssets.data?.length}
      onClick={doAdd}
      icon={<PlusCircleOutlined />}
      title={<FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ADD_TITLE} />}
    />
  );
};

const OperationAddDonationAssetMemo = React.memo(OperationAddDonationAsset);

export default OperationAddDonationAssetMemo;
