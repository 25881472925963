import { FormOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { List, Space, Tag, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage, Operation } from '@/components';
import { AssetAmount, AssetIcon, AssetLabel } from '@/features/dictionary/blockchain/components';
import { DonationAssetStatusView } from '@/features/donations/components';
import { donationAssetStatusIcons } from '@/features/donations/components/DonationAssetStatusView';
import OperationRemoveDonationAsset from '@/features/donations/components/EditDonationForm/components/DonationAssetsItem/components/OperationRemoveDonationAsset';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationAssetReadonlyViewProps } from './types';

const DonationAssetReadonlyView: React.FC<DonationAssetReadonlyViewProps> = ({
  'data-test': dataTest,
  value,
  onEdit,
  onUpdateStatus,
  onRemove,
  editable,
}) => (
  <List.Item
    actions={[
      <Space key="actions">
        <Operation
          key="edit"
          data-test={dataTest && `${dataTest}-opEdit`}
          title={<FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_EDIT_TITLE} />}
          icon={<FormOutlined />}
          onClick={onEdit}
          disabled={!editable}
          mode="inline"
        />
        <Operation
          key="update-status"
          data-test={dataTest && `${dataTest}-opUpdateStatus`}
          title={
            value.isActive ? (
              <FormattedMessage
                id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_DEACTIVATE_TITLE}
              />
            ) : (
              <FormattedMessage
                id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ACTIVATE_TITLE}
              />
            )
          }
          icon={donationAssetStatusIcons[`${!value.isActive}`]}
          onClick={onUpdateStatus}
          disabled={!editable}
          mode="inline"
        />
        <OperationRemoveDonationAsset
          key="remove"
          data-test={dataTest && `${dataTest}-opRemove`}
          data={value}
          disabled={!editable}
          onRemove={onRemove}
          mode="inline"
        />
      </Space>,
    ]}
  >
    <List.Item.Meta
      className={css`
        align-items: center;

        > div > h4 {
          display: flex;

          > div {
            overflow: auto;
          }
        }
      `}
      avatar={
        <AssetIcon
          data-test={dataTest && `${dataTest}-${value.asset}-icon`}
          type={value.asset}
          style={{ fontSize: 32 }}
        />
      }
      title={
        <Space>
          <AssetLabel
            data-test={dataTest && `${dataTest}-${value.asset}-label`}
            value={value.asset}
            mode="short"
            withBlockchainMark
            ellipsis
          />
          <Tag>
            <DonationAssetStatusView value={value.isActive} />
          </Tag>
        </Space>
      }
      description={
        <Tooltip
          title={
            <FormattedMessage
              id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_TOOLTIP}
            />
          }
        >
          <Space>
            {value.defaultAmounts.map((amount, idx) => (
              <Tag key={idx}>
                <AssetAmount data-test={dataTest && `${dataTest}-${idx}`} value={amount.value} assetId={amount.asset} />
              </Tag>
            ))}
          </Space>
        </Tooltip>
      }
    />
  </List.Item>
);

const DonationAssetReadonlyViewMemo = React.memo(DonationAssetReadonlyView);

export default DonationAssetReadonlyViewMemo;
