import { FileExcelOutlined } from '@ant-design/icons';
import { Modal, Space } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { ReportDownloader } from '@/features/reports/components';
import type { Report } from '@/features/reports/types';
import { useSettlementsActions } from '@/features/settlements/hooks';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification, useStateMountSafe } from '@/hooks';
import ReportLink from '@/pages/reports/report-view/components/ReportViewLink';

import type { OperationExportReconciliationBySettlementProps } from './types';

const OperationExportReconciliationBySettlement: React.FC<OperationExportReconciliationBySettlementProps> = ({
  'data-test': dataTest,
  disabled,
  mode = 'inline',
  rows,
}) => {
  const formControl = useFormVisible(false);
  const [reportId, setReportId] = useStateMountSafe<string>();
  const { withNotification } = useNotification();
  const { exportReconciliations: exportReconciliationsAction } = useSettlementsActions();

  const exportReconciliations = useCallback(
    async (includeTransactions: boolean) => {
      const report: Report = await withNotification(async () => exportReconciliationsAction.act(includeTransactions), {
        error: () => (
          <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_ERROR_MESSAGE} />
        ),
        success: (r: Report) => (
          <FormattedMessage
            id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_GENERATING_MESSAGE}
            values={{
              ln: (text: React.ReactNode) => (
                <ReportLink value={r.id} title={text} data-test={dataTest && `${dataTest}-reportLink`} mode="text" />
              ),
            }}
          />
        ),
      })();
      setReportId(report.id);
    },
    [dataTest, exportReconciliationsAction, setReportId, withNotification],
  );
  const exportReconciliationsWOTransactions = useCallback(
    async () => formControl.withFormHide(exportReconciliations)(false),
    [exportReconciliations, formControl],
  );
  const exportReconciliationsWTransactions = useCallback(
    async () => formControl.withFormHide(exportReconciliations)(true),
    [exportReconciliations, formControl],
  );

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={
            <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_FORM_TITLE} />
          }
          closable
          onCancel={formControl.hide}
        >
          <FormattedMessageWithMarkup
            id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_FORM_MESSAGE}
          />
          <Space direction="vertical">
            <Operation
              title={
                <FormattedMessage
                  id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_NO_TRANSFERS_TITLE}
                />
              }
              icon={<FileExcelOutlined />}
              onClick={exportReconciliationsWOTransactions}
              disabled={disabled || !exportReconciliationsAction.available || !rows}
              inProgress={exportReconciliationsAction.inAction}
              data-test={dataTest && `${dataTest}-withoutTx`}
              mode="button"
            />
            <Operation
              key="export-with-transfers"
              title={
                <FormattedMessage
                  id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_TRANSFERS_TITLE}
                />
              }
              icon={<FileExcelOutlined />}
              onClick={exportReconciliationsWTransactions}
              disabled={disabled || !exportReconciliationsAction.available || !rows}
              inProgress={exportReconciliationsAction.inAction}
              data-test={dataTest && `${dataTest}-withTx`}
              mode="button"
            />
          </Space>
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_TOOLTIP} />}
        icon={<FileExcelOutlined />}
        onClick={formControl.show}
        disabled={disabled || !exportReconciliationsAction.available || !rows}
        inProgress={exportReconciliationsAction.inAction}
        data-test={dataTest && `${dataTest}-withoutTx`}
        mode={mode}
      />
      {reportId && <ReportDownloader data-test={dataTest && `${dataTest}-report`} reportId={reportId} />}
    </>
  );
};

const OperationExportReconciliationBySettlementMemo = React.memo(OperationExportReconciliationBySettlement);

export default OperationExportReconciliationBySettlementMemo;
