import { useCallback } from 'react';

import FormattedMessage from '@/components/FormattedMessage';
import MessageToSupport from '@/components/MessageToSupport';
import { I18nCommon } from '@/generated/i18n/i18n';
import useNotification from '@/hooks/useNotification';
import type { Func } from '@/infrastructure/utils/ts';

import type React from 'react';

export type DefaultErrorOpts =
  | {
      message?: (e: unknown) => React.ReactNode;
      description?: (e: unknown) => React.ReactNode;
      skip?: (e: unknown) => boolean;
    }
  | ((e: unknown) => React.ReactNode);

export default function useDefaultNotification(): {
  withDefaultError: <V extends unknown[], R>(func: Func<V, R>, options?: DefaultErrorOpts) => Func<V, R>;
  withDefaultSuccess: <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>;
  withDefaultNotification: <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>;
} {
  const { withError, withSuccess } = useNotification();
  const withDefaultError = useCallback(
    <V extends unknown[], R>(func: Func<V, R>, options?: DefaultErrorOpts) =>
      withError(func, (e) =>
        options && (typeof options === 'function' || !options.skip?.(e))
          ? {
              message: (typeof options === 'function' ? options : options.message)?.(e) ?? (
                <FormattedMessage id={I18nCommon.MESSAGES_NOTIFICATIONS_FAILED_TITLE} />
              ),
              description: (typeof options !== 'function' && options.description?.(e)) ?? (
                <FormattedMessage
                  id={I18nCommon.MESSAGES_NOTIFICATIONS_FAILED_DESCRIPTION}
                  values={{ support: <MessageToSupport /> }}
                />
              ),
            }
          : undefined,
      ),
    [withError],
  );
  const withDefaultSuccess = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      withSuccess(func, () => <FormattedMessage id={I18nCommon.MESSAGES_NOTIFICATIONS_SUCCESS_TITLE} />),
    [withSuccess],
  );

  const withDefaultNotification = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) => withDefaultError(withDefaultSuccess(func)),
    [withDefaultError, withDefaultSuccess],
  );

  return {
    withDefaultError,
    withDefaultSuccess,
    withDefaultNotification,
  };
}
