import { Col, Row } from 'antd';
import React from 'react';

import type { BalanceCardContainerProps } from './types';

const BalanceCardContainer: React.FC<BalanceCardContainerProps> = ({ left, right }) => (
  <Row>
    <Col span={20}>{left}</Col>
    <Col span={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      {right}
    </Col>
  </Row>
);

const BalanceCardContainerMemo = React.memo(BalanceCardContainer);

export default BalanceCardContainerMemo;
