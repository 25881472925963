import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';

import SubscriptionsLink from '../SubscriptionsLink';

import type { SubscriptionsBreadcrumbProps } from './types';

const SubscriptionsBreadcrumb: React.FC<SubscriptionsBreadcrumbProps> = ({
  'data-test': dataTest,
  items = [],
  disabled,
}) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSubscriptions.ALL_COMPONENTS_BREADCRUMB} />
        ) : (
          <SubscriptionsLink
            mode="text"
            title={<FormattedMessage id={I18nPageSubscriptions.ALL_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const SubscriptionsBreadcrumbMemo = React.memo(SubscriptionsBreadcrumb);

export default SubscriptionsBreadcrumbMemo;
