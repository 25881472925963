import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nMenu } from '@/generated/i18n/i18n';
import type { CustomizableRoute } from '@/layouts/types';
import DashboardIcon from '@/pages/dashboard/components/DashboardIcon';
import { dashboardLink } from '@/pages/dashboard/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (formatMessage: IntlFormattersArrow['formatMessage']): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.DASHBOARD }),
    icon: <DashboardIcon />,
    path: dashboardLink(),
    hideChildrenInMenu: true,
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const { formatMessage } = useIntl();
  return useMemo(() => menuCreator(formatMessage), [formatMessage]);
}
