import type { DateTimeRangeRelativeEnum, DateTimeRelativeQuickFilter } from '@/components';
import type {
  BlockchainNetworkTypeAPIModel,
  BlockchainTypeAPIModel,
  MerchantWalletTransferDirectionAPIModel,
  MerchantWalletTransferKindAPIModel,
  MerchantWalletTransferReconciliationStatusAPIModel,
  MerchantWalletTransferAPIModel,
  MerchantWalletTransferPredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import type { ListSortBy, ListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'merchant-wallet-transfers';

export const merchantWalletTransfersExportReport = 'merchant-wallet-transfers';

export interface MerchantWalletTransfer extends Omit<MerchantWalletTransferAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export interface MerchantWalletTransferFilterPredicate
  extends Pick<
    MerchantWalletTransferPredicatesAPIModel,
    | 'kindIn'
    | 'directionEq'
    | 'networkEq'
    | 'broadcastAtRange'
    | 'reconciliationStatusIn'
    | 'btIn'
    | 'walletLike'
    | 'senderLike'
    | 'destinationLike'
  > {
  broadcastAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export interface MerchantWalletTransferReportPredicates {
  companyHidIn?: string[];
  btIn?: BlockchainTypeAPIModel[];
  directionEq?: MerchantWalletTransferDirectionAPIModel;
  kindIn?: MerchantWalletTransferKindAPIModel[];
  reconciliationStatusIn?: MerchantWalletTransferReconciliationStatusAPIModel[];
  broadcastAtRangeRelative?: DateTimeRangeRelativeEnum;
  walletLike?: string;
  networkEq?: BlockchainNetworkTypeAPIModel;
}

export const defaultMerchantWalletTransferSortBy: ListSortBy<MerchantWalletTransferSortByAPIModel> = {
  [MerchantWalletTransferSortByAPIModel.broadcastAt]: 'DESC',
};

export interface MerchantWalletTransfersState {
  list: ListState<string, MerchantWalletTransferFilterPredicate, MerchantWalletTransferSortByAPIModel>;
  entities: SingleState<MerchantWalletTransfer>; // key - transfer id
}
