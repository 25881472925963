import { useMemo } from 'react';

import type { AppAsyncThunkAction } from '@/app/store';
import type { LoadingListDataState, UpdateListParametersPayload } from '@/infrastructure/model/list/types';
import { withVoidOrThrow } from '@/infrastructure/utils/functions';
import { isThenable } from '@/infrastructure/utils/ts';

import useAppDispatch from './useAppDispatch';

import type { UseAppListData } from './useAppListData';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface UseAppListDataView<Value, Filter, SortBy extends string, FetchResult = LoadingListDataState<Value>>
  extends UseAppListData<Value, Filter, SortBy, FetchResult> {
  updateParameters: (parameters: UpdateListParametersPayload<Filter, SortBy>) => unknown;
}

export default function useAppListDataView<
  Value,
  Filter,
  SortBy extends string,
  FetchResult = LoadingListDataState<Value>,
>(
  state: UseAppListData<Value, Filter, SortBy, FetchResult>,
  updateParametersFactory:
    | ((parameters: UpdateListParametersPayload<Filter, SortBy>) => AppAsyncThunkAction<unknown, unknown>)
    | ((
        payload: UpdateListParametersPayload<Filter, SortBy>,
      ) => PayloadAction<UpdateListParametersPayload<Filter, SortBy>>)
    | ((parameters: UpdateListParametersPayload<Filter, SortBy>) => Promise<unknown>),
): UseAppListDataView<Value, Filter, SortBy, FetchResult> {
  const { dispatch } = useAppDispatch();
  const updateParameters = useMemo(
    () =>
      withVoidOrThrow(async (parameters: UpdateListParametersPayload<Filter, SortBy>) => {
        const call = updateParametersFactory(parameters);
        if (isThenable(call)) {
          return call;
        }
        const dispatched = dispatch(call);
        return isThenable(dispatched) ? dispatched.unwrap() : dispatched;
      }),
    [dispatch, updateParametersFactory],
  );

  return { ...state, updateParameters };
}
