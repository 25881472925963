/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DateTimeRangeAPIModel
 */
export interface DateTimeRangeAPIModel {
    /**
     * 
     * @type {Date}
     * @memberof DateTimeRangeAPIModel
     */
    from?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DateTimeRangeAPIModel
     */
    to?: Date;
}

/**
 * Check if a given object implements the DateTimeRangeAPIModel interface.
 */
export function instanceOfDateTimeRangeAPIModel(value: object): boolean {
    return true;
}

export function DateTimeRangeAPIModelFromJSON(json: any): DateTimeRangeAPIModel {
    return DateTimeRangeAPIModelFromJSONTyped(json, false);
}

export function DateTimeRangeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DateTimeRangeAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'from': json['from'] == null ? undefined : (new Date(json['from'])),
        'to': json['to'] == null ? undefined : (new Date(json['to'])),
    };
}

export function DateTimeRangeAPIModelToJSON(value?: DateTimeRangeAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'from': value['from'] == null ? undefined : ((value['from']).toISOString()),
        'to': value['to'] == null ? undefined : ((value['to']).toISOString()),
    };
}

