import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureGasHistory } from '@/generated/i18n/i18n';

import type { GasHistorySponsorAddressFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureGasHistory.COMPONENTS_FILTER_FORM_SPONSOR_ADDRESS_ITEM_LABEL} />,
};

const GasHistoryAddressFilterItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: GasHistorySponsorAddressFilterItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      messages={messages}
      placeholder={formatMessage({ id: I18nFeatureGasHistory.COMPONENTS_FILTER_FORM_SPONSOR_ADDRESS_ITEM_PLACEHOLDER })}
      {...ItemProps}
    />
  );
};

const GasHistoryAddressFilterItem = React.memo(GasHistoryAddressFilterItemRaw) as typeof GasHistoryAddressFilterItemRaw;

export default GasHistoryAddressFilterItem;
