import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { extractEntityTransactionId } from '@/features/collectable/utils';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { extractDonationId } from '@/features/donations/utils';
import { createNestedNormalizedListReducers, createNormalizedListReducers } from '@/infrastructure/model/list/reducers';

import {
  markDonationDirty,
  markDonationListDirty,
  markDonationTransactionDirty,
  markDonationTransactionForDonationListDirty,
  markDonationTransactionListDirty,
  storeMultipleDonation,
  storeDonation,
  storeDonationListData,
  storeDonationListParameters,
  storeDonationTransaction,
  storeDonationTransactionForDonationListData,
  storeDonationTransactionForDonationListParameters,
  storeDonationTransactionListData,
  storeDonationTransactionListParameters,
  storeMultipleDonationTransaction,
} from './actions';
import {
  defaultDonationListState,
  defaultDonationTransactionForDonationListState,
  defaultDonationTransactionListState,
} from './types';

import type { DonationsState } from './types';
import type { Draft } from 'immer';

const initialState: DonationsState = {
  list: defaultDonationListState,
  entities: {},
  transactions: {
    entities: {},
    list: defaultDonationTransactionForDonationListState,
    columnState: {},
    byDonation: {},
  },
};

const {
  storeDonationReducer,
  storeMultipleDonationReducer,
  storeDonationListDataReducer,
  storeDonationListParametersReducer,
  markDonationListDirtyReducer,
  markDonationDirtyReducer,
} = createNormalizedListReducers(
  'Donation' as const,
  (state: Draft<DonationsState>) => state.list,
  (state, list) => ({ ...state, list }),
  defaultDonationListState,
  // this cast is workaround caused by BigNumber in the store
  (state: Draft<DonationsState>) => state.entities as DonationsState['entities'],
  (state, entities) => ({ ...state, entities }),
  extractDonationId,
);

const {
  storeDonationTransactionForDonationListDataReducer,
  storeDonationTransactionForDonationListParametersReducer,
  markDonationTransactionForDonationListDirtyReducer,
} = createNestedNormalizedListReducers(
  'DonationTransactionForDonation' as const,
  (state: Draft<DonationsState>, donationId: string | undefined) => state.transactions.byDonation[donationId!],
  (state, donationId, newListState) => ({
    ...state,
    transactions: {
      ...state.transactions,
      byDonation: { ...state.transactions.byDonation, [donationId]: newListState },
    },
  }),
  (state) => state.transactions.columnState,
  (state, columnState) => ({ ...state, transactions: { ...state.transactions, columnState } }),
  defaultDonationTransactionForDonationListState,
  extractEntityTransactionId,
);

const {
  storeDonationTransactionReducer,
  markDonationTransactionDirtyReducer,
  storeMultipleDonationTransactionReducer,
  storeDonationTransactionListDataReducer,
  storeDonationTransactionListParametersReducer,
  markDonationTransactionListDirtyReducer,
} = createNormalizedListReducers(
  'DonationTransaction' as const,
  (state: Draft<DonationsState>) => ({ ...state.transactions.list, columnState: state.transactions.columnState }),
  (state, { columnState, ...list }) => ({ ...state, transactions: { ...state.transactions, list, columnState } }),
  defaultDonationTransactionListState,
  (state: Draft<DonationsState>) => state.transactions.entities,
  (state, entities) => ({ ...state, transactions: { ...state.transactions, entities } }),
  extractEntityTransactionId,
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storeDonationTransactionForDonationListData, storeDonationTransactionForDonationListDataReducer)
    .addCase(
      storeDonationTransactionForDonationListParameters,
      storeDonationTransactionForDonationListParametersReducer,
    )
    .addCase(markDonationTransactionForDonationListDirty, markDonationTransactionForDonationListDirtyReducer)

    .addCase(storeDonation, storeDonationReducer)
    .addCase(storeMultipleDonation, storeMultipleDonationReducer)
    .addCase(storeDonationListData, storeDonationListDataReducer)
    .addCase(storeDonationListParameters, storeDonationListParametersReducer)
    .addCase(markDonationListDirty, markDonationListDirtyReducer)
    .addCase(markDonationDirty, markDonationDirtyReducer)

    .addCase(storeDonationTransaction, storeDonationTransactionReducer)
    .addCase(storeMultipleDonationTransaction, storeMultipleDonationTransactionReducer)
    .addCase(storeDonationTransactionListData, storeDonationTransactionListDataReducer)
    .addCase(storeDonationTransactionListParameters, storeDonationTransactionListParametersReducer)
    .addCase(markDonationTransactionListDirty, markDonationTransactionListDirtyReducer)
    .addCase(markDonationTransactionDirty, markDonationTransactionDirtyReducer)

    .addCase(notifyNetworkUpdated, (state) => ({
      ...initialState,
      list: {
        ...initialState.list,
        columnState: state.list.columnState,
        filter: state.list.filter,
        sortBy: state.list.sortBy,
      },
      transactions: {
        ...state.transactions,
        columnState: state.list.columnState,
        list: {
          ...state.transactions.list,
          filter: state.transactions.list.filter,
          sortBy: state.transactions.list.sortBy,
        },
      },
    }))
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
