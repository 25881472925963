import type { CommonLoadingState, LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataLoaded, storedDataError, storedDirtyDataTyped } from '@/infrastructure/model';
import type { SingleState, StoreMultipleActionPayload } from '@/infrastructure/model/single/types';

interface ToMultiplePayload {
  <Value>(
    data: CommonLoadingState<Value[]>,
    ids: string[],
    getId: (data: Value) => string,
  ): StoreMultipleActionPayload<Value>;

  <Value, Id>(
    data: CommonLoadingState<Value[]>,
    ids: Id[],
    getId: (data: Value) => Id,
    toKey: (id: Id) => string,
  ): StoreMultipleActionPayload<Value, Id>;

  <Value>(
    data: CommonLoadingState<Value[]>,
    ids: string[],
    getId: (data: Value) => string,
  ): StoreMultipleActionPayload<Value>;

  <Value, Id>(
    data: CommonLoadingState<Value[]>,
    ids: Id[],
    getId: (data: Value) => Id,
    toKey: (id: Id) => string,
  ): StoreMultipleActionPayload<Value, Id>;
}

export const toMultiplePayload: ToMultiplePayload = <Value, Id = string>(
  data: CommonLoadingState<Value[]>,
  ids: Id[],
  getId: (data: Value) => Id,
  toKey?: (id: Id) => string,
): StoreMultipleActionPayload<Value, Id> => {
  const values = data.data?.reduce(
    (r, value) => {
      const id = getId(value);
      const key = typeof id === 'string' ? id : toKey!(id);
      return { ...r, [key]: value };
    },
    {} as Partial<Record<string, Value>>,
  );

  return values
    ? ids.map((id) => {
        const key = typeof id === 'string' ? id : toKey!(id);
        const mbValue = values[key];
        return { id, data: mbValue ? storedDataLoaded(mbValue) : storedDataError<Value>('DataNotFound') };
      })
    : ids.map((id) => ({ id, data: storedDataError(data.error) }));
};

type MultipleArray<Value, Id = string> = { id: Id; data: LoadingStateWithDirty<Value> }[];

interface ToMultipleMerged {
  <Value>(data: SingleState<Value> | MultipleArray<Value>, ids: string[]): LoadingStateWithDirty<Value[]>;

  <Value, Id>(data: MultipleArray<Value, Id>, ids: Id[]): LoadingStateWithDirty<Value[]>;

  <Value, Id>(data: SingleState<Value>, ids: Id[], toKey: (id: Id) => string): LoadingStateWithDirty<Value[]>;
}

class DirtyStateError extends Error {}

class ErrorStateError extends Error {}

export const toMultipleMerged: ToMultipleMerged = <Value, Id>(
  data: SingleState<Value> | MultipleArray<Value>,
  ids: Id[],
  toKey?: (id: Id) => string,
): LoadingStateWithDirty<Value[]> => {
  try {
    return Array.isArray(data)
      ? storedDataLoaded(
          data.map((item) => {
            const value = item.data;
            if (value.isDirty) {
              throw new DirtyStateError();
            }
            if (value.error) {
              throw new ErrorStateError(value.error);
            }
            return value.data!; // data may be undefined only if the Value allows undefined that is fine
          }),
        )
      : storedDataLoaded(
          ids.map((id) => {
            const key = typeof id === 'string' ? id : toKey!(id);
            const value = data[key];
            if (!value || value.isDirty) {
              throw new DirtyStateError();
            }
            if (value.error) {
              throw new ErrorStateError(value.error);
            }
            return value.data!; // data may be undefined only if the Value allows undefined that is fine
          }),
        );
  } catch (e) {
    if (e instanceof DirtyStateError) {
      return storedDirtyDataTyped<Value[]>();
    }
    if (e instanceof ErrorStateError) {
      return storedDataError<Value[]>(e.message);
    }
    throw e;
  }
};
