import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useAPIKeyActions } from '@/features/company-settings/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationDeleteAPIKeyProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_API_KEY_MESSAGE_ERROR} />,
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_API_KEY_MESSAGE_SUCCESS} />
  ),
};

const OperationDeleteAPIKey: React.FC<OperationDeleteAPIKeyProps> = ({
  'data-test': dataTest,
  id,
  confirmation,
  mode = 'inline',
}) => {
  const { withNotification } = useNotification();

  const { deleteAPIKey: deleteAction } = useAPIKeyActions(id);

  const doDelete = useMemo(
    () => withSuppressError(withNotification(deleteAction.act, notificationOptions)),
    [deleteAction.act, withNotification],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? {
            title: (
              <FormattedMessage
                id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_API_KEY_CONFIRMATION_TITLE}
              />
            ),
          }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_API_KEY_TITLE} />}
      icon={<DeleteOutlined />}
      onClick={doDelete}
      disabled={!deleteAction.available}
      inProgress={deleteAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeleteAPIKeyMemo = React.memo(OperationDeleteAPIKey);

export default OperationDeleteAPIKeyMemo;
