import { CopyrightOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Space, Tooltip } from 'antd';

import { FormattedMessage } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import DataBlockContainer from '../DataBlockContainer';

import { PoweredByCustomEnabledItem, PoweredByTextItem, PoweredByVisibilityItem } from './components';

import type { PoweredByBlockProps } from './types';
import type React from 'react';

const PoweredByBlock: React.FC<PoweredByBlockProps> = ({ 'data-test': dataTest, disabled }) => {
  const [required, setRequired] = useStateMountSafe({ block: false, custom: false });
  return (
    <DataBlockContainer
      icon={<CopyrightOutlined />}
      title={
        <Space>
          <PoweredByVisibilityItem data-test={dataTest && `${dataTest}-visible`} disabled={disabled} />
          <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_TITLE} />
        </Space>
      }
      help={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_HELP} />}
    >
      <Row style={!required.block ? { display: 'none' } : undefined}>
        <Col span={24}>
          <Space>
            <PoweredByCustomEnabledItem data-test={dataTest && `${dataTest}-enableCustom`} disabled={disabled} />
            <FormattedMessage
              id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_CUSTOM_ENABLED_ITEM_LABEL}
              values={{
                help: (
                  <Tooltip
                    title={
                      <FormattedMessage
                        id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_CUSTOM_ENABLED_ITEM_HELP}
                      />
                    }
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                ),
              }}
            />
          </Space>
        </Col>
        <Col span={24}>
          <PoweredByTextItem
            data-test={dataTest && `${dataTest}-customText`}
            disabled={disabled || !required.block || !required.custom}
          />
        </Col>
      </Row>
      <Form.Item
        noStyle
        hidden
        dependencies={[
          [nameof<PaymentsBranding>('poweredBy'), nameof<NonNullable<PaymentsBranding['poweredBy']>>('visible')],
          [
            nameof<PaymentsBranding>('poweredBy'),
            nameof<NonNullable<PaymentsBranding['poweredBy']>>('customText'),
            nameof<NonNullable<NonNullable<PaymentsBranding['poweredBy']>['customText']>>('enabled'),
          ],
        ]}
      >
        {({ getFieldValue, validateFields }) => {
          setTimeout(() => {
            const blockVisible = !!getFieldValue([
              nameof<PaymentsBranding>('poweredBy'),
              nameof<NonNullable<PaymentsBranding['poweredBy']>>('visible'),
            ]);
            const customEnabled = !!getFieldValue([
              nameof<PaymentsBranding>('poweredBy'),
              nameof<NonNullable<PaymentsBranding['poweredBy']>>('customText'),
              nameof<NonNullable<NonNullable<PaymentsBranding['poweredBy']>['customText']>>('enabled'),
            ]);
            if (blockVisible !== required.block || customEnabled !== required.custom) {
              setRequired({ block: blockVisible, custom: customEnabled });
              setTimeout(() => {
                withSuppressPromise(validateFields)([
                  [
                    nameof<PaymentsBranding>('poweredBy'),
                    nameof<NonNullable<PaymentsBranding['poweredBy']>>('customText'),
                    nameof<NonNullable<NonNullable<PaymentsBranding['poweredBy']>['customText']>>('text'),
                  ],
                ]);
              }, 50);
            }
          });
          return null;
        }}
      </Form.Item>
    </DataBlockContainer>
  );
};

const Hidden = () => (
  <Col span={24} style={{ display: 'none' }}>
    <PoweredByVisibilityItem disabled />
    <PoweredByCustomEnabledItem disabled />
    <PoweredByTextItem disabled />
  </Col>
);

const PoweredByBlockHOC = withFeatureToggleGuard('enableBrandingPoweredBy', true, Hidden)(PoweredByBlock);

export default PoweredByBlockHOC;
