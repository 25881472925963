import { Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { BrandingOnRamp } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import BrandingOnRampLabel from '../BrandingOnRampLabel';

import type { BrandingOnRampSelectProps } from './types';

const BrandingOnRampSelect: React.FC<BrandingOnRampSelectProps> = ({
  'data-test': dataTest,
  style,
  className,
  readonly,
  multiselect,
  onChange,
  value,
}) => {
  const options = useMemo(
    () =>
      multiselect
        ? enumValues(BrandingOnRamp).map((val) => ({
            value: val,
            inline: (
              <BrandingOnRampLabel data-test={dataTest && `${dataTest}-selected-${val}`} value={val} mode="full" />
            ),
            label: <BrandingOnRampLabel data-test={dataTest && `${dataTest}-value-${val}`} value={val} mode="full" />,
          }))
        : enumValues(BrandingOnRamp).map((val) => ({
            value: val,
            label: <BrandingOnRampLabel data-test={dataTest && `${dataTest}-value-${val}`} value={val} mode="full" />,
          })),
    [dataTest, multiselect],
  );

  return multiselect ? (
    <Select<BrandingOnRamp[]>
      data-test={dataTest}
      style={style}
      mode="tags"
      className={className}
      disabled={readonly}
      onChange={onChange}
      value={value}
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_ON_RAMP_SELECT_PLACEHOLDER} />}
      optionLabelProp="inline"
      options={options}
    />
  ) : (
    <Select<BrandingOnRamp>
      data-test={dataTest}
      style={style}
      className={className}
      disabled={readonly}
      onChange={onChange}
      value={value}
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_ON_RAMP_SELECT_PLACEHOLDER} />}
      options={options}
    />
  );
};

const BrandingOnRampSelectMemo = React.memo(BrandingOnRampSelect);

export default BrandingOnRampSelectMemo;
