import React from 'react';

import CollectTaskFromAddressFilterItem from 'src/features/collectable/components/CollectTaskFilterForm/components/CollectTaskPushAddressFilterItem';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import type { CollectTaskFilterPredicate } from '@/features/collectable/types';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { CollectTaskStatusFilterItem } from './components';

import type { CollectTaskFilterFormProps } from './types';

const fields = [
  nameof<CollectTaskFilterPredicate>('processAtRange'),
  nameof<CollectTaskFilterPredicate>('addressLike'),
  nameof<CollectTaskFilterPredicate>('statusIn'),
  nameof<CollectTaskFilterPredicate>('assetIn'),
  nameof<CollectTaskFilterPredicate>('btIn'),
];

const processAtRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_FILTER_FORM_PROCESS_AT_TO_LABEL} />,
  },
  from: {
    label: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_FILTER_FORM_PROCESS_AT_FROM_LABEL} />,
  },
};

const CollectTaskFilterForm: React.FC<CollectTaskFilterFormProps> = ({ hidden, ...props }) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<CollectTaskFilterPredicate> fields={fields} {...props}>
      {!hidden?.processAtRange ? (
        <DateTimeRangeItem<CollectTaskFilterPredicate>
          data-test={dataTest && `${dataTest}-processAt`}
          name={nameof<CollectTaskFilterPredicate>('processAtRange')}
          messages={processAtRangeMessages}
          readonly={readonly}
          required={false}
          relativeUpdateKey="processAtRangeRelative"
        />
      ) : null}
      {!hidden?.addressLike ? (
        <CollectTaskFromAddressFilterItem
          data-test={dataTest && `${dataTest}-addressLike`}
          name={nameof<CollectTaskFilterPredicate>('addressLike')}
          readonly={readonly}
          required={false}
        />
      ) : null}
      {!hidden?.btIn ? (
        <BlockchainItem
          data-test={dataTest && `${dataTest}-blockchain`}
          name={nameof<CollectTaskFilterPredicate>('btIn')}
          multiselect
          network={network}
          readonly={readonly}
          required={false}
        />
      ) : null}
      {!hidden?.assetIn ? (
        <AssetItem
          data-test={dataTest && `${dataTest}-asset`}
          name={nameof<CollectTaskFilterPredicate>('assetIn')}
          multiselect
          assetType="exchangeable"
          network={network}
          readonly={readonly}
          required={false}
        />
      ) : null}
      {!hidden?.statusIn ? (
        <CollectTaskStatusFilterItem
          data-test={dataTest && `${dataTest}-status`}
          name={nameof<CollectTaskFilterPredicate>('statusIn')}
          readonly={readonly}
          required={false}
        />
      ) : null}
    </FilterForm>
  );
};

const CollectTaskFilterFormMemo = React.memo(CollectTaskFilterForm);

export default CollectTaskFilterFormMemo;
