import { type UseAppListData, useAppNestedListData } from '@/app/hooks';
import { noIdNestedListFetchResultFn } from '@/app/hooks/useAppNestedListData';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonationTransactionForDonation } from '@/features/donations/actions';
import {
  makeSelectDonationTransactionForDonationListData,
  makeSelectDonationTransactionForDonationListParameters,
} from '@/features/donations/selectors';
import type { DonationTransaction, DonationTransactionFilterPredicate } from '@/features/donations/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseDonationTransactionsForDonationList = UseAppListData<
  DonationTransaction,
  DonationTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

const fetchFactory =
  (donationId: string) =>
  (
    force: boolean,
  ): AppAsyncThunkAction<
    LoadingListDataState<DonationTransaction>,
    Parameters<typeof fetchDonationTransactionForDonation>[0]
  > =>
    fetchDonationTransactionForDonation({ donationId, force });

const pendingSelectorCreator = (donationId: string) =>
  makeSelectPending(fetchDonationTransactionForDonation, donationId);

export default function useDonationTransactionsForDonationList(
  donationId: string | undefined,
): UseDonationTransactionsForDonationList {
  return useAppNestedListData(
    fetchFactory,
    makeSelectDonationTransactionForDonationListParameters,
    pendingSelectorCreator,
    makeSelectDonationTransactionForDonationListData,
    noIdNestedListFetchResultFn(),
    donationId,
  );
}
