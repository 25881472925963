import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchGasHistory } from '@/features/gas-history/actions';
import { makeSelectGasHistory } from '@/features/gas-history/selectors';
import type { GasHistoryRow } from '@/features/gas-history/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseGasHistory = UseAppSingleData<GasHistoryRow>;

const noData = storedDataError<GasHistoryRow>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<GasHistoryRow>, Parameters<typeof fetchGasHistory>[0]> =>
  fetchGasHistory({ id, force });

export default function useGasHistory(id: string | undefined): UseGasHistory {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchGasHistory, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectGasHistory, noData, id, dataFetchingSelector);
}
