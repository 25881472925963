import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { paymentAddLink } from '@/pages/payments/routes';

import type { PaymentAddLinkProps } from './types';

const PaymentAddLink: React.FC<PaymentAddLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(paymentAddLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <PlusCircleOutlined />}
      title={title ?? <FormattedMessage id={I18nPagePayments.PAYMENT_ADD_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const PaymentAddLinkMemo = React.memo(PaymentAddLink);

export default PaymentAddLinkMemo;
