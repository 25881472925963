import React from 'react';

import { useBrandingActiveProfile, useBrandingProfiles } from '@/features/branding/hooks';

import {
  ActiveProfileContent,
  ActiveProfileDescription,
  InactiveProfileContent,
  InactiveProfileDescription,
  ProfileCardContainer,
} from './components';

import type { ActiveProfileCardProps } from './types';

const ActiveProfileCard: React.FC<ActiveProfileCardProps> = ({ 'data-test': dataTest }) => {
  const profiles = useBrandingProfiles();
  const activeData = useBrandingActiveProfile();
  const active = activeData.data.data;

  return (
    <ProfileCardContainer
      data-test={dataTest && `${dataTest}-card`}
      description={
        active ? (
          <ActiveProfileDescription data-test={dataTest && `${dataTest}-description`} value={active} />
        ) : (
          <InactiveProfileDescription data-test={dataTest && `${dataTest}-description`} profiles={profiles.data.data} />
        )
      }
      loading={profiles.loading || activeData.loading}
    >
      {active ? (
        <ActiveProfileContent data-test={dataTest && `${dataTest}-content`} value={active} />
      ) : (
        <InactiveProfileContent data-test={dataTest && `${dataTest}-content`} profiles={profiles.data.data} />
      )}
    </ProfileCardContainer>
  );
};

const ActiveProfileCardMemo = React.memo(ActiveProfileCard);

export default ActiveProfileCardMemo;
