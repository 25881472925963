import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useReportScheduleActions } from '@/features/report-schedules/hooks';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationDeleteReportScheduleProps } from './types';

const options = {
  error: () => <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_DELETE_ERROR_MESSAGE} />,
  success: () => <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_DELETE_SUCCESS_MESSAGE} />,
};

const OperationDeleteReportScheduleRaw: React.FC<OperationDeleteReportScheduleProps> = ({
  'data-test': dataTest,
  scheduleId,
  confirmation = true,
  mode = 'inline',
  onSuccess = noop,
}) => {
  const { withNotification } = useNotification();
  const { deleteReportSchedule: deleteReportScheduleAction } = useReportScheduleActions(scheduleId);

  const deleteKey = useMemo(
    () => withSuppressError(withOnSuccess(withNotification(deleteReportScheduleAction.act, options), onSuccess)),
    [deleteReportScheduleAction.act, onSuccess, withNotification],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? { title: <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_DELETE_CONFIRMATION_TITLE} /> }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_DELETE_TITLE} />}
      icon={<DeleteOutlined />}
      onClick={deleteKey}
      disabled={!deleteReportScheduleAction.available}
      inProgress={deleteReportScheduleAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeleteReportSchedule = React.memo(OperationDeleteReportScheduleRaw);

export default OperationDeleteReportSchedule;
