import { KeyOutlined } from '@ant-design/icons';
import { Space, Typography, Upload } from 'antd';
import React, { useMemo } from 'react';

import { ErrorFormMessage, FormattedMessage, Operation } from '@/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting } from '@/hooks';

import type { Web3AuthRecoveryKeyEnterProps } from './types';
import type { RcFile } from 'antd/lib/upload/interface';
import type { UploadRequestOption } from 'rc-upload/lib/interface';

const { Dragger } = Upload;
const { Text } = Typography;

const Web3AuthRecoveryKeyEnter: React.FC<Web3AuthRecoveryKeyEnterProps> = ({
  'data-test': dataTest,
  onCheck,
  onCancel,
  onReset,
}) => {
  const { error, withSubmitting, submitting } = useErrorSafeSubmitting();
  const onCheckFile = useMemo(
    () =>
      withSubmitting(async (file: UploadRequestOption['file']) => {
        if (!(await onCheck(new TextDecoder().decode(await (file as RcFile).arrayBuffer()).replace(/\s/g, '')))) {
          throw new Error('RecoveryFailed');
        }
      }),
    [onCheck, withSubmitting],
  );
  return (
    <Space direction="vertical" data-test={dataTest && `${dataTest}-container `}>
      <Text>
        <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_MESSAGE} />
      </Text>
      {error && (
        <ErrorFormMessage
          content={
            <FormattedMessage
              id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_INVALID}
              values={{
                ln: (text: React.ReactNode) => (
                  <Operation
                    title={text}
                    data-test={dataTest && `${dataTest}-cancel`}
                    icon={null}
                    onClick={onCancel}
                    mode="link"
                  />
                ),
              }}
            />
          }
          data-test={dataTest && `${dataTest}-error`}
        />
      )}
      <Dragger
        disabled={submitting}
        maxCount={1}
        showUploadList={false}
        customRequest={({ file }) => onCheckFile(file)}
      >
        <p className="ant-upload-drag-icon">
          <KeyOutlined />
        </p>
        <p className="ant-upload-text">
          <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_DROPZONE} />
        </p>
      </Dragger>

      {onReset && (
        <FormattedMessage
          id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_RESET_MESSAGE}
          values={{
            ln: (text: React.ReactNode) => (
              <Operation
                data-test={dataTest && `${dataTest}-resetOp`}
                icon={null}
                mode="link"
                onClick={onReset}
                title={text}
              />
            ),
          }}
        />
      )}
    </Space>
  );
};

const Web3AuthRecoveryKeyEnterMemo = React.memo(Web3AuthRecoveryKeyEnter);

export default Web3AuthRecoveryKeyEnterMemo;
