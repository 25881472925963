import { Alert, Empty, Select, Space, Spin } from 'antd';
import React, { useMemo } from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';
import OperationRefresh from '../OperationRefresh';

import { useLazyLoadDefault, useLazyLoadState, useLoader } from './hooks';

import type { LazyLoadSelectProps } from './types';

const { Option } = Select;

const LazyLoadSelectRaw = <T extends number | string = string>({
  'data-test': dataTest,
  value,
  onChange,
  cacheKeyPrefix,
  requestDataById,
  requestFilteredData,
  disabled,
  messages,
}: LazyLoadSelectProps<T>) => {
  const { loading, load, loadNextPage, state, cache, error } = useLazyLoadState<T>({
    cacheKeyPrefix,
    fetchData: requestFilteredData,
  });
  const { value: initialValue } = useLazyLoadDefault<T>({ cache, key: value, fetchData: requestDataById });
  const isInitialValueInSeparateOption = useMemo(
    () => value === initialValue?.value && !state?.options.find((cached) => cached.value === value),
    [initialValue?.value, state?.options, value],
  );

  const { LoadingOption } = useLoader({
    fetchMore: loadNextPage,
    loading,
    hasMore: !state || state.hasMore,
  });
  return (
    <Select
      allowClear
      showSearch
      filterOption={false}
      onSearch={load}
      dropdownRender={(menu) => (
        <div>
          {menu}
          {loading && !!state?.options.length && !error && (
            <Space data-test={dataTest && `${dataTest}-loading`}>
              <Spin size="small" />
              <FormattedMessage id={I18nComponents.LAZY_LOAD_SELECT_LOADING} tagName="span" />
            </Space>
          )}
          {!loading && error && (
            <Alert
              data-test={dataTest && `${dataTest}-error`}
              message={<FormattedMessage id={I18nComponents.LAZY_LOAD_SELECT_ERROR_TEXT} />}
              type="error"
              showIcon
              action={
                <OperationRefresh
                  data-test={dataTest && `${dataTest}-refresh`}
                  refresh={loadNextPage}
                  inProgress={loading}
                  messages={{
                    title: <FormattedMessage id={I18nComponents.LAZY_LOAD_SELECT_ERROR_RELOAD_TITLE} />,
                    tooltip: <FormattedMessage id={I18nComponents.LAZY_LOAD_SELECT_ERROR_RELOAD_TOOLTIP} />,
                  }}
                />
              }
            />
          )}
        </div>
      )}
      onChange={onChange}
      value={value}
      placeholder={messages?.placeholder || <FormattedMessage id={I18nComponents.LAZY_LOAD_SELECT_PLACEHOLDER} />}
      loading={loading}
      notFoundContent={
        <Spin
          data-test={dataTest && `${dataTest}-emptyLoading`}
          tip={<FormattedMessage id={I18nComponents.LAZY_LOAD_SELECT_LOADING} />}
          style={{ textAlign: 'center', width: '100%' }}
          spinning={loading}
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} data-test={dataTest && `${dataTest}-empty`} />
        </Spin>
      }
      disabled={disabled}
      data-test={dataTest}
    >
      {isInitialValueInSeparateOption && initialValue && !state?.input && (
        <Option key={initialValue.value} value={initialValue.value}>
          {initialValue.label}
        </Option>
      )}
      {(state?.options ?? [])
        .filter(({ value: key }) => !isInitialValueInSeparateOption || key !== initialValue?.value)
        .map(({ value: key, label }) => (
          <Option key={key} value={key}>
            {label}
          </Option>
        ))}
      {!error && LoadingOption}
    </Select>
  );
};

const LazyLoadSelect: React.FC<LazyLoadSelectProps> = React.memo<LazyLoadSelectProps>(LazyLoadSelectRaw);

export default LazyLoadSelect;
