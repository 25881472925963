import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import MemoryStorage from 'memorystorage';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { createClient, http } from 'viem';
import { createConfig, WagmiProvider, createStorage } from 'wagmi';
import * as allChains from 'wagmi/chains';
import { injected, coinbaseWallet, walletConnect } from 'wagmi/connectors';

import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { useCookiesAgreement } from '@/features/global/hooks';
import { useStateMountSafe } from '@/hooks';
import type { Web3Auth } from '@/infrastructure/security/web3-auth';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import Web3InitContext from './context';
import createWebAuthConnector from './web3auth-connector';

// @ts-expect-error
import type { BaseStorage } from '@wagmi/core/src/createStorage';
import type React from 'react';

const chains = [
  // mainnet:
  allChains.bsc,
  allChains.arbitrum,
  allChains.mainnet,
  allChains.polygon,
  // testnet:
  allChains.bscTestnet,
  allChains.arbitrumSepolia,
  allChains.sepolia,
  allChains.polygonAmoy,
] as const;

const realConfig = ({
  coinbaseEnabled,
  walletConnectEnabled,
  web3Auth,
  storage,
}: {
  coinbaseEnabled: boolean;
  walletConnectEnabled: boolean;
  web3Auth?: Web3Auth;
  storage: BaseStorage;
}) =>
  createConfig({
    storage: createStorage({ storage }),
    multiInjectedProviderDiscovery: false,
    chains,
    connectors: [
      ...(web3Auth ? [createWebAuthConnector({ web3Auth })] : []),
      injected({ target: 'metaMask' }),
      ...(coinbaseEnabled ? [coinbaseWallet({ version: '4', appName: 'SMARTy Pay' })] : []),
      ...(walletConnectEnabled
        ? [walletConnect({ projectId: window.env.WALLET_CONNECT_PROJECT_ID, showQrModal: true })]
        : []),
    ],
    client: ({ chain }) => createClient({ chain, transport: http() }),
  });

const noConnectorConfig = createConfig({
  multiInjectedProviderDiscovery: false,
  chains,
  connectors: [],
  client: ({ chain }) => createClient({ chain, transport: http() }),
});

const queryClient = new QueryClient();

const withWeb3Support = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3Support',
  )((props) => {
    const [initialized, setInitialized] = useStateMountSafe(false);
    const featureFlags = useFeatureToggle();
    const { accepted: cookiesAccepted } = useCookiesAgreement();
    const { web3Auth: web3Auth } = useContext(Web3AuthContext);
    const client = useRef(noConnectorConfig);
    const initializationCalled = useRef<boolean>(false);
    const initialize = useCallback(() => {
      setTimeout(() => {
        if (!initializationCalled.current) {
          initializationCalled.current = true;
          client.current = realConfig({
            web3Auth,
            storage:
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
              (cookiesAccepted && (window.localStorage ?? window.sessionStorage))
              || new MemoryStorage('web3auth-connector'),
            walletConnectEnabled: !!featureFlags.data.data?.enableWalletConnect,
            coinbaseEnabled: !!featureFlags.data.data?.enableCoinbase,
          });
          setInitialized(true);
        }
      }, 0);
    }, [
      web3Auth,
      cookiesAccepted,
      featureFlags.data.data?.enableWalletConnect,
      featureFlags.data.data?.enableCoinbase,
      setInitialized,
    ]);
    const context = useMemo(() => ({ initialized, initialize }), [initialize, initialized]);
    return (
      <Web3InitContext.Provider value={context}>
        <WagmiProvider config={client.current}>
          <QueryClientProvider client={queryClient}>
            <Component {...props} />
          </QueryClientProvider>
        </WagmiProvider>
      </Web3InitContext.Provider>
    );
  });

export default withWeb3Support;
