import React from 'react';

import { FormattedMessage } from '@/components';
import { S3TempFileUploadItem } from '@/features/dictionary/files/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationImageItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_IMAGE} />,
  required: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_IMAGE_ITEM_REQUIRED} />,
};

const restrictions = {
  accepted: 'image/*',
  sizeInKB: 500,
};

const DonationImageItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  required,
  readonly,
}: DonationImageItemProps<Values>) => (
  <S3TempFileUploadItem
    name={name}
    data-test={dataTest}
    required={required}
    readonly={readonly}
    restrictions={restrictions}
    messages={messages}
  />
);

const DonationImageItemMemo = React.memo(DonationImageItem) as typeof DonationImageItem;

export default DonationImageItemMemo;
