import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import RechargeAddressAddPage from './address-add';
import RechargeAddressViewPage from './address-view';
import RechargeAddressesPage from './addresses';
import { RechargesPageRoutes, rechargeAddressesLink } from './routes';
import RechargeTransactionViewPage from './transaction-view';
import RechargeTransactionsPage from './transactions';

import type React from 'react';

export const RechargesDefaultRoute: React.FC = () => <Navigate to={rechargeAddressesLink()} replace />;

const RechargePagesRaw: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={RechargesPageRoutes.ADDRESSES} Component={RechargeAddressesPage} />
        <Route path={RechargesPageRoutes.ADDRESS_NEW} Component={RechargeAddressAddPage} />
        <Route path={RechargesPageRoutes.ADDRESS_VIEW} Component={RechargeAddressViewPage} />
        <Route path={RechargesPageRoutes.TRANSACTIONS} Component={RechargeTransactionsPage} />
        <Route path={RechargesPageRoutes.TRANSACTION_VIEW} Component={RechargeTransactionViewPage} />
        <Route path="*" Component={RechargesDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const RechargePages: React.FC = withBasicPageGuard(RechargePagesRaw);

export default <Route key={AppPageRoutes.RECHARGES} path={`${AppPageRoutes.RECHARGES}/*`} Component={RechargePages} />;
