import { useAppLoadableData } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMaintenance } from '@/features/maintenance/actions';
import { makeSelectIsOnMaintenance } from '@/features/maintenance/selectors';

const dataFetchingSelector = makeSelectPending(fetchMaintenance);
const dataSelector = makeSelectIsOnMaintenance();
const fetchFactory = (force: boolean) => fetchMaintenance({ force });

export const useMaintenance = () => useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);

export default useMaintenance;
