import type { DateTimeRangeRelativeEnum, DateTimeRelativeQuickFilter } from '@/components';
import type { MerchantWalletTransferFilterPredicate } from '@/features/merchant-wallet-transfers/types';
import { defaultMerchantWalletTransferSortBy } from '@/features/merchant-wallet-transfers/types';
import type {
  BlockchainNetworkTypeAPIModel,
  MerchantWalletTransferSortByAPIModel,
  WithdrawalReconciliationAPIModel,
  WithdrawalReconciliationPredicatesAPIModel,
  WithdrawalReconciliationStatusAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import { defaultPage } from '@/infrastructure/api';
import { storedDirtyDataTyped } from '@/infrastructure/model';
import type { FullState } from '@/infrastructure/model/full/types';
import type { ListColumnState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'settlement-reconciliation';

export const settlementReconciliationTransfersExportReport = 'withdrawal-reconciliation-transfers';
export const settlementReconciliationsExportReport = 'withdrawal-reconciliations';

export interface SettlementReconciliation
  extends Omit<WithdrawalReconciliationAPIModel, 'expectedAmount' | 'calculatedAmount'> {
  expectedAmount: AssetAmountValue;
  calculatedAmount: AssetAmountValue;
}

export interface SettlementReconciliationFilterPredicate extends WithdrawalReconciliationPredicatesAPIModel {
  withdrawnAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type SettlementReconciliationTransferFilterPredicate = Pick<
  MerchantWalletTransferFilterPredicate,
  'broadcastAtRangeRelative' | 'broadcastAtRange' | 'kindIn'
>;

export type SettlementMerchantWalletTransfersState = Omit<
  FullState<string, SettlementReconciliationTransferFilterPredicate, MerchantWalletTransferSortByAPIModel>,
  'columnState'
>;

export interface SettlementReconciliationReportPredicates {
  statusIn?: WithdrawalReconciliationStatusAPIModel[];
  withdrawnAtRangeRelative?: DateTimeRangeRelativeEnum;
  network?: BlockchainNetworkTypeAPIModel;
}

export const defaultTransfersBySettlementState: SettlementMerchantWalletTransfersState = {
  data: storedDirtyDataTyped(),
  filter: {},
  sortBy: defaultMerchantWalletTransferSortBy,
  page: defaultPage,
};

export interface SettlementReconciliationsState {
  entities: SingleState<SettlementReconciliation>;

  transfers: {
    columnState: ListColumnState;
    outgoing: Partial<Record<string, SettlementMerchantWalletTransfersState>>; // by settlementId
    incoming: Partial<Record<string, SettlementMerchantWalletTransfersState>>; // by settlementId
  };
}
