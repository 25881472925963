import { Form, Select } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureReports } from '@/generated/i18n/i18n';

import type { ReportTemplateFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const ReportTemplateFilterItemRaw = <Values extends Store = Store>({
  name,
  readonly,
  'data-test': dataTest,
  ItemProps = {},
}: ReportTemplateFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_FILTER_TEMPLATE_LABEL} />}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      placeholder={<FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_FILTER_TEMPLATE_PLACEHOLDER} />}
      disabled={readonly}
      mode="multiple"
      allowClear
    />
  </Form.Item>
);

const ReportTemplateFilterItem = React.memo(ReportTemplateFilterItemRaw) as typeof ReportTemplateFilterItemRaw;

export default ReportTemplateFilterItem;
