import { GlobalOutlined } from '@ant-design/icons';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import DataBlockContainer from '../DataBlockContainer';

import { BrandingLangItem } from './components';

import type { LangBlockProps } from './types';
import type React from 'react';

const LangBlock: React.FC<LangBlockProps> = ({ 'data-test': dataTest, disabled }) => (
  <DataBlockContainer
    title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LANG_BLOCK_TITLE} />}
    icon={<GlobalOutlined />}
    help={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_LANG_BLOCK_HELP} />}
  >
    <BrandingLangItem data-test={dataTest && `${dataTest}-select`} disabled={disabled} />
  </DataBlockContainer>
);

export default LangBlock;
