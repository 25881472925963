/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PushCollectPeriodAPIModel } from './PushCollectPeriodAPIModel';
import {
    PushCollectPeriodAPIModelFromJSON,
    PushCollectPeriodAPIModelFromJSONTyped,
    PushCollectPeriodAPIModelToJSON,
} from './PushCollectPeriodAPIModel';

/**
 * 
 * @export
 * @interface CollectScheduleAPIModel
 */
export interface CollectScheduleAPIModel {
    /**
     * 
     * @type {PushCollectPeriodAPIModel}
     * @memberof CollectScheduleAPIModel
     */
    period: PushCollectPeriodAPIModel;
    /**
     * 
     * @type {number}
     * @memberof CollectScheduleAPIModel
     */
    unifiedMinAmount: number;
}

/**
 * Check if a given object implements the CollectScheduleAPIModel interface.
 */
export function instanceOfCollectScheduleAPIModel(value: object): boolean {
    if (!('period' in value)) return false;
    if (!('unifiedMinAmount' in value)) return false;
    return true;
}

export function CollectScheduleAPIModelFromJSON(json: any): CollectScheduleAPIModel {
    return CollectScheduleAPIModelFromJSONTyped(json, false);
}

export function CollectScheduleAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectScheduleAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'period': PushCollectPeriodAPIModelFromJSON(json['period']),
        'unifiedMinAmount': json['unifiedMinAmount'],
    };
}

export function CollectScheduleAPIModelToJSON(value?: CollectScheduleAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'period': PushCollectPeriodAPIModelToJSON(value['period']),
        'unifiedMinAmount': value['unifiedMinAmount'],
    };
}

