import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum RechargesPageRoutes {
  ADDRESSES = 'addresses',
  ADDRESS_NEW = 'addresses/new',
  ADDRESS_VIEW = 'addresses/view/:addressId',
  TRANSACTIONS = 'transactions',
  TRANSACTION_VIEW = 'transactions/:transactionId',
}

export const rechargesRoute = (page: RechargesPageRoutes) => `${appRoute(AppPageRoutes.RECHARGES)}/${page}`;

export const rechargeAddressesLink = () => rechargesRoute(RechargesPageRoutes.ADDRESSES);

export const rechargeAddressAddLink = () => `${rechargeAddressesLink()}/new`;
export const rechargeAddressViewLink = (addressId: string) => `${rechargeAddressesLink()}/view/${addressId}`;

export const rechargeTransactionsLink = () => rechargesRoute(RechargesPageRoutes.TRANSACTIONS);
export const rechargeTransactionViewLink = (txId: string) => `${rechargeTransactionsLink()}/${txId}`;
