import { BellOutlined } from '@ant-design/icons';
import { Dropdown, type DropdownProps } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { useHandlers } from '@/features/global/hooks';
import type { UserAlertPayload } from '@/features/global/types';
import { useStateMountSafe } from '@/hooks';
import { notEmpty } from '@/infrastructure/utils/ts';

import { UserAlertsPayloadSelect } from './components';

import type { UserAlertsDropdownProps } from './types';

const UserAlertsDropdown: React.FC<UserAlertsDropdownProps> = ({ 'data-test': dataTest, title }) => {
  const handlers = useHandlers('alerts');

  const [payloads, setPayloads] = useStateMountSafe<{ type: string; payload: UserAlertPayload }[]>([]);
  const setPayload = useCallback(
    (type: string, payload: UserAlertPayload) =>
      setPayloads((previous) => {
        const exists = !!previous.find((old) => old.type === type);
        return exists
          ? previous.map((old) => (old.type === type ? { type, payload } : old))
          : [...previous, { type, payload }];
      }),
    [setPayloads],
  );

  const items = useMemo<NonNullable<DropdownProps['menu']>['items']>(
    () =>
      handlers
        .map(({ selector, ...handler }) => {
          const payload = payloads.find((p) => p.type === handler.type)?.payload;
          return payload ? { ...handler, payload } : undefined;
        })
        .filter(notEmpty)
        .map(({ payload, type, Icon, Message }) => ({
          'data-test': dataTest && `${dataTest}-item-${type}`,
          key: type,
          icon: <Icon />,
          label: <Message payload={payload} />,
        })),
    [dataTest, handlers, payloads],
  );

  return (
    <>
      {handlers.map(({ type, selector }) => (
        <UserAlertsPayloadSelect key={type} onChange={(payload) => setPayload(type, payload)} selector={selector} />
      ))}
      <Dropdown menu={useMemo(() => ({ items, selectedKeys: [] }), [items])}>{title ?? <BellOutlined />}</Dropdown>
    </>
  );
};

const UserAlertsDropdownMemo = React.memo(UserAlertsDropdown);

export default UserAlertsDropdownMemo;
