import { Descriptions, Space } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import { AssetAmount, AddressLink, TransactionLink } from '@/features/dictionary/blockchain/components';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import MerchantWalletTransferDirectionView from '../MerchantWalletTransferDirectionView';
import MerchantWalletTransferKindView from '../MerchantWalletTransferKindView';
import MerchantWalletTransferReconciliationStatusView from '../MerchantWalletTransferReconciliationStatusView';

import type { MerchantWalletTransferViewProps } from './types';

const MerchantWalletTransferView: React.FC<MerchantWalletTransferViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
  EntityLink,
  SettlementLink,
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={
      <Space>
        <MerchantWalletTransferDirectionView value={data.direction} mode="icon" />
        <MerchantWalletTransferDirectionView
          value={data.direction}
          data-test={dataTest && `${dataTest}-direction`}
          mode="text"
        />
        <MerchantWalletTransferKindView data-test={dataTest && `${dataTest}-kind`} value={data.kind} mode="text" />
      </Space>
    }
    column={columns}
  >
    {EntityLink && (
      <Descriptions.Item
        label={
          <MerchantWalletTransferKindView data-test={dataTest && `${dataTest}-kind`} value={data.kind} mode="text" />
        }
      >
        <EntityLink data-test={dataTest && `${dataTest}-entity`} value={data} mode="text" />
      </Descriptions.Item>
    )}
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_SENDER} />}>
      <AddressLink data-test={dataTest && `${dataTest}-sender`} bt={data.blockchain} address={data.sender} />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_DESTINATION} />}
    >
      <AddressLink data-test={dataTest && `${dataTest}-destination`} bt={data.blockchain} address={data.destination} />
    </Descriptions.Item>
    {data.destination !== data.wallet && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_WALLET} />}>
        <AddressLink data-test={dataTest && `${dataTest}-wallet`} bt={data.blockchain} address={data.wallet} />
      </Descriptions.Item>
    )}
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_TX} />}>
      <TransactionLink data-test={dataTest && `${dataTest}-tx`} bt={data.blockchain} tx={data.txHash} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_AMOUNT} />}>
      <AssetAmount
        value={data.amount.value}
        assetId={data.amount.asset}
        withCurrency
        currencyMode="long"
        data-test={dataTest && `${dataTest}-amount`}
      />
    </Descriptions.Item>
    <Descriptions.Item
      label={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.LABELS_ROW_TITLE_BROADCAST_AT} />}
    >
      <ReadonlyDateTime value={data.broadcastAt} data-test={dataTest && `${dataTest}-broadcastAt`} />
    </Descriptions.Item>
    {SettlementLink && data.withdrawalId && (
      <Descriptions.Item
        label={
          <FormattedMessage
            id={I18nFeatureMerchantWalletTransfers.COMPONENTS_TRANSFER_VIEW_ROW_TITLE_SETTLEMENT_LINK}
          />
        }
      >
        <SettlementLink data-test={dataTest && `${dataTest}-settlement`} value={data.withdrawalId} mode="text" />
      </Descriptions.Item>
    )}
    <Descriptions.Item
      label={
        <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_TRANSFER_VIEW_ROW_TITLE_RECONCILIATION} />
      }
    >
      <MerchantWalletTransferReconciliationStatusView
        data-test={dataTest && `${dataTest}-reconciliationLink`}
        value={data.reconciliationStatus}
        mode="full"
      />
      {SettlementLink && data.withdrawalId && (
        <>
          &nbsp;
          <SettlementLink
            data-test={dataTest && `${dataTest}-reconciliationLink`}
            value={data.withdrawalId}
            title={
              <FormattedMessage
                id={I18nFeatureMerchantWalletTransfers.COMPONENTS_TRANSFER_VIEW_ROW_TITLE_RECONCILIATION_LINK}
              />
            }
            mode="text"
          />
        </>
      )}
    </Descriptions.Item>
  </Descriptions>
);

const MerchantWalletTransferViewMemo = React.memo(MerchantWalletTransferView);

export default MerchantWalletTransferViewMemo;
