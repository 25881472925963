import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useRechargeAddressDeployTransaction } from '@/features/recharges/hooks';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';

import type { OperationRefreshRechargeAddressDeployTransactionProps } from './types';

const messages = {
  tooltip: (
    <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_DEPLOY_TX_TOOLTIP} />
  ),
  success: {
    message: (
      <FormattedMessage
        id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_DEPLOY_TX_SUCCESS_MESSAGE}
      />
    ),
  },
};

const OperationRefreshRechargeAddressDeployTransaction: React.FC<
  OperationRefreshRechargeAddressDeployTransactionProps
> = ({ 'data-test': dataTest, mode = 'inline', addressId }) => {
  const { forceRefresh } = useRechargeAddressDeployTransaction(addressId);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshRechargeAddressDeployTransactionMemo = React.memo(
  OperationRefreshRechargeAddressDeployTransaction,
);

export default OperationRefreshRechargeAddressDeployTransactionMemo;
