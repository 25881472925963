import isNumber from 'lodash/isNumber';
import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { usePayoutBatch } from '@/features/payouts/hooks';
import type { PayoutBatchId } from '@/features/payouts/types';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { uuidToBase58 } from '@/infrastructure/utils/crypto';
import { settlementsPayoutBatchViewLink } from '@/pages/settlements/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { PayoutEntityLinkProps } from './types';

const PayoutEntityLink: React.FC<PayoutEntityLinkProps> = ({ 'data-test': dataTest, value }) => {
  const id = useMemo<PayoutBatchId | undefined>(() => {
    const [payoutIdOrHid, batchNumString] = value.entityId?.split('_') ?? [];
    const payoutHid = payoutIdOrHid.includes('-') ? uuidToBase58(payoutIdOrHid) : payoutIdOrHid;
    const batchNum = (() => {
      try {
        return parseInt(batchNumString, 10);
      } catch {
        return undefined;
      }
    })();
    return payoutHid && isNumber(batchNum) ? { payoutId: payoutHid, batchNum } : undefined;
  }, [value.entityId]);
  const data = usePayoutBatch(id?.payoutId, id?.batchNum);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (id) {
    return <Navigate to={settlementsPayoutBatchViewLink(id.payoutId, id.batchNum)} replace />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.Payout}
      onRetry={data.forceRefresh}
    />
  );
};

const PayoutEntityLinkMemo = React.memo(PayoutEntityLink);

export default PayoutEntityLinkMemo;
