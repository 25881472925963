import { Tooltip } from 'antd';
import React from 'react';

import { BrandingLang } from '@/features/branding/types';

import { BrandingLangIcon } from './components';

import type { BrandingLangLabelProps } from './types';

const mapping: Record<BrandingLang, string> = {
  [BrandingLang.en]: 'English',
  [BrandingLang.es]: 'Español',
  [BrandingLang.fr]: 'Français',
  [BrandingLang.ko]: '한국인',
  [BrandingLang.it]: 'Italiano',
  [BrandingLang.ru]: 'Русский',
};

const BrandingLangLabel: React.FC<BrandingLangLabelProps> = ({ mode = 'medium', value }) =>
  // eslint-disable-next-line no-nested-ternary
  mode === 'short' ? (
    <Tooltip title={mapping[value]}>
      <BrandingLangIcon value={value} />
    </Tooltip>
  ) : mode === 'medium' ? (
    <>{mapping[value]}</>
  ) : (
    <>
      <BrandingLangIcon value={value} />
      &nbsp;
      {mapping[value]}
    </>
  );

const BrandingLangLabelMemo = React.memo(BrandingLangLabel);

export default BrandingLangLabelMemo;
