import { Form, Input } from 'antd';
import React, { useMemo } from 'react';

import type { InputItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const InputItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  placeholder,
  readonly,
  required,
  messages,
  rules: extraRules = [],
  InputProps = {},
  ItemProps = {},
}: InputItemProps<Values>) => {
  const rules = useMemo(
    () => [
      ...extraRules,
      ...(!readonly && messages?.required && (required || ItemProps.required)
        ? [
            {
              transform: (value: string) => value.trim(),
              required: true,
              message: messages.required,
            },
          ]
        : []),
    ],
    [ItemProps.required, extraRules, messages?.required, readonly, required],
  );
  return (
    <Form.Item<Values>
      label={messages?.label}
      rules={rules}
      required={!readonly && !!messages?.required && (ItemProps.required || required)}
      hasFeedback={!readonly}
      {...ItemProps}
      name={name}
    >
      <Input
        name="no-autocomplete"
        autoComplete="off"
        disabled={readonly}
        placeholder={!readonly ? placeholder : undefined}
        data-test={dataTest}
        {...InputProps}
      />
    </Form.Item>
  );
};

const InputItemMemo = React.memo(InputItem) as typeof InputItem;

export default InputItemMemo;
