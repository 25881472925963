import { Descriptions, Space, Typography } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent } from '@/components';
import { AddressLink } from '@/features/dictionary/blockchain/components';
import SubscriptionChargeAtView from '@/features/subscription-charges/components/SubscriptionChargeAtView';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';

import OperationCancelSubscription from '../OperationCancelSubscription';
import OperationUpdateSubscriptionStatus from '../OperationUpdateSubscriptionStatus';
import SubscriptionAllowanceView from '../SubscriptionAllowanceView';
import SubscriptionCancellationTimeoutAlert from '../SubscriptionCancellationTimeoutAlert';
import SubscriptionDeployAlert from '../SubscriptionDeployAlert';
import SubscriptionMaxPeriodView from '../SubscriptionMaxPeriodView';
import SubscriptionStatusView from '../SubscriptionStatusView';

import { SubscriptionPlanDetails } from './components';

import type { SubscriptionViewProps } from './types';

const SubscriptionView: React.FC<SubscriptionViewProps> = ({
  data,
  plan,
  'data-test': dataTest,
  hideOperations,
  style,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
  planLink = 'link',
  SubscriptionPlanLink,
}) => {
  const { formatDateTime } = useLocaleSettings();
  return (
    <Descriptions
      style={style}
      contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
      title={
        <SubscriptionStatusView
          value={data.status}
          pendingStatus={data.pendingStatuses[0]}
          data-test={dataTest && `${dataTest}-status`}
        />
      }
      extra={
        !hideOperations && (
          <Space>
            <OperationUpdateSubscriptionStatus
              subscriptionId={data.id}
              data-test={dataTest && `${dataTest}-opUpdateStatus`}
            />
            <OperationCancelSubscription subscriptionId={data.id} data-test={dataTest && `${dataTest}-opCancel`} />
          </Space>
        )
      }
      column={columns}
    >
      {(data.status === SubscriptionStatusAPIModel.Draft || data.status === SubscriptionStatusAPIModel.Pending) && (
        <Descriptions.Item>
          <SubscriptionDeployAlert subscriptionId={data.id} data-test={dataTest && `${dataTest}-deployAlert`} />
          <SubscriptionCancellationTimeoutAlert
            subscriptionId={data.id}
            data-test={dataTest && `${dataTest}-cancellationAlert`}
          />
        </Descriptions.Item>
      )}
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_ADDRESS} />}
        contentStyle={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <AddressLink
          address={data.contractAddress}
          bt={data.blockchain}
          style={{ whiteSpace: 'nowrap' }}
          data-test={dataTest && `${dataTest}-address`}
        />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_CUSTOMER} />}>
        <ReadonlyComponent value={data.customerId} data-test={dataTest && `${dataTest}-customer`} />
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_PAYER} />}
        contentStyle={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <AddressLink
          address={data.payer}
          bt={data.blockchain}
          style={{ whiteSpace: 'nowrap' }}
          data-test={dataTest && `${dataTest}-payer`}
        />
      </Descriptions.Item>
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_ALLOWANCE} />}
        contentStyle={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <SubscriptionAllowanceView
          data-test={dataTest && `${dataTest}-allowance`}
          subscription={data}
          mode={!hideOperations ? 'full' : 'short'}
        />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_CREATED_AT} />}>
        <ReadonlyComponent value={formatDateTime(data.createdAt)} data-test={dataTest && `${dataTest}-createdAt`} />
      </Descriptions.Item>
      {SubscriptionPlanLink && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_PLAN} />}>
          {planLink === 'id' && (
            <SubscriptionPlanLink value={data.planId} data-test={dataTest && `${dataTest}-plan`} title={data.planId} />
          )}
          {(planLink === 'link' || planLink === 'details') && (
            <SubscriptionPlanDetails
              mode={planLink}
              planId={data.planId}
              data-test={dataTest && `${dataTest}-plan`}
              SubscriptionPlanLink={SubscriptionPlanLink}
            />
          )}
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_START_FROM} />}>
        <ReadonlyComponent value={formatDateTime(data.startFrom)} data-test={dataTest && `${dataTest}-startFrom`} />
      </Descriptions.Item>
      {plan ? (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_LAST_CHARGE_AT} />}>
          <SubscriptionMaxPeriodView
            value={data}
            mode="date"
            plan={plan}
            data-test={dataTest && `${dataTest}-maxPeriod`}
          />
        </Descriptions.Item>
      ) : (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_MAX_PERIOD} />}>
          <SubscriptionMaxPeriodView value={data} data-test={dataTest && `${dataTest}-maxPeriod`} />
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_NEXT_CHARGE_AT} />}>
        <SubscriptionChargeAtView value={data} data-test={dataTest && `${dataTest}-chargeAt`} />
      </Descriptions.Item>
      {data.metadata && (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_METADATA} />}>
          <Typography.Paragraph
            ellipsis={{ rows: 3, expandable: true }}
            className="ym-hide-content"
            data-test={dataTest && `${dataTest}-metadata`}
          >
            {data.metadata}
          </Typography.Paragraph>
        </Descriptions.Item>
      )}
    </Descriptions>
  );
};

const SubscriptionViewMemo = React.memo(SubscriptionView);

export default SubscriptionViewMemo;
