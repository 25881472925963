/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  IsOnMaintenanceResponseAPIModel,
} from '../models/index';
import {
    IsOnMaintenanceResponseAPIModelFromJSON,
    IsOnMaintenanceResponseAPIModelToJSON,
} from '../models/index';

/**
 * SystemApi - interface
 * 
 * @export
 * @interface SystemApiInterface
 */
export interface SystemApiInterface {
    /**
     * Checks the maintenance is on
     * @summary 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemApiInterface
     */
    isOnMaintenanceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsOnMaintenanceResponseAPIModel>>;

    /**
     * Checks the maintenance is on
     * 
     */
    isOnMaintenance(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsOnMaintenanceResponseAPIModel>;

}

/**
 * 
 */
export class SystemApi extends runtime.BaseAPI implements SystemApiInterface {

    /**
     * Checks the maintenance is on
     * 
     */
    async isOnMaintenanceRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IsOnMaintenanceResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/system/maintenance`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IsOnMaintenanceResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Checks the maintenance is on
     * 
     */
    async isOnMaintenance(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IsOnMaintenanceResponseAPIModel> {
        const response = await this.isOnMaintenanceRaw(initOverrides);
        return await response.value();
    }

}
