import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { PayoutBatchStatusView } from '@/features/payouts/components';
import { PayoutBatchStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { PayoutBatchStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const PayoutBatchStatusFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: PayoutBatchStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_DESTINATION_DETAILED_FILTER_FORM_STATUS_LABEL} />}
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      mode="multiple"
      disabled={readonly}
      placeholder={
        <FormattedMessage
          id={I18nFeaturePayouts.COMPONENTS_PAYOUT_DESTINATION_DETAILED_FILTER_FORM_STATUS_PLACEHOLDER}
        />
      }
      options={useMemo(
        () =>
          enumValues(PayoutBatchStatusAPIModel).map((code) => ({
            value: code,
            label: (
              <PayoutBatchStatusView value={code} mode="full" data-test={dataTest && `${dataTest}-${code}-status`} />
            ),
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const PayoutBatchStatusFilterItemMemo = React.memo(PayoutBatchStatusFilterItem) as typeof PayoutBatchStatusFilterItem;

export default PayoutBatchStatusFilterItemMemo;
