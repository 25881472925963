import { Form, Select } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { PaymentCompatStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { PaymentStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const options = enumValues(PaymentCompatStatusAPIModel).map((code) => ({
  value: code,
  label: code,
}));

const PaymentCompatStatusFilterItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: PaymentStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_PAYMENT_FILTER_STATUS_LABEL} />}
    {...ItemProps}
    required={required}
    name={name}
  >
    <Select
      disabled={readonly}
      data-test={dataTest}
      placeholder={
        !readonly ? <FormattedMessage id={I18nFeaturePayments.COMPONENTS_PAYMENT_FILTER_STATUS_PLACEHOLDER} /> : null
      }
      mode="multiple"
      options={options}
      allowClear
    />
  </Form.Item>
);

const PaymentCompatStatusFilterItem = React.memo(
  PaymentCompatStatusFilterItemRaw,
) as typeof PaymentCompatStatusFilterItemRaw;

export default PaymentCompatStatusFilterItem;
