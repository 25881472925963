/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalReconciliationStatusAPIModel } from './WithdrawalReconciliationStatusAPIModel';
import {
    WithdrawalReconciliationStatusAPIModelFromJSON,
    WithdrawalReconciliationStatusAPIModelFromJSONTyped,
    WithdrawalReconciliationStatusAPIModelToJSON,
} from './WithdrawalReconciliationStatusAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalReconciliationAPIModel
 */
export interface WithdrawalReconciliationAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    wallet?: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalReconciliationAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    expectedAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    calculatedAmount: string;
    /**
     * 
     * @type {WithdrawalReconciliationStatusAPIModel}
     * @memberof WithdrawalReconciliationAPIModel
     */
    status: WithdrawalReconciliationStatusAPIModel;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    blockNum: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    hash: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalReconciliationAPIModel
     */
    attempts: number;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalReconciliationAPIModel
     */
    withdrawnAt: Date;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    previousBlockNum?: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    reportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalReconciliationAPIModel
     */
    nextAttempt?: Date;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalReconciliationAPIModel
     */
    errorCode?: string;
}

/**
 * Check if a given object implements the WithdrawalReconciliationAPIModel interface.
 */
export function instanceOfWithdrawalReconciliationAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('expectedAmount' in value)) return false;
    if (!('calculatedAmount' in value)) return false;
    if (!('status' in value)) return false;
    if (!('blockNum' in value)) return false;
    if (!('hash' in value)) return false;
    if (!('attempts' in value)) return false;
    if (!('withdrawnAt' in value)) return false;
    return true;
}

export function WithdrawalReconciliationAPIModelFromJSON(json: any): WithdrawalReconciliationAPIModel {
    return WithdrawalReconciliationAPIModelFromJSONTyped(json, false);
}

export function WithdrawalReconciliationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalReconciliationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'wallet': json['wallet'] == null ? undefined : json['wallet'],
        'companyId': json['companyId'],
        'expectedAmount': json['expectedAmount'],
        'calculatedAmount': json['calculatedAmount'],
        'status': WithdrawalReconciliationStatusAPIModelFromJSON(json['status']),
        'blockNum': json['blockNum'],
        'hash': json['hash'],
        'attempts': json['attempts'],
        'withdrawnAt': (new Date(json['withdrawnAt'])),
        'previousBlockNum': json['previousBlockNum'] == null ? undefined : json['previousBlockNum'],
        'reportId': json['reportId'] == null ? undefined : json['reportId'],
        'nextAttempt': json['nextAttempt'] == null ? undefined : (new Date(json['nextAttempt'])),
        'errorCode': json['errorCode'] == null ? undefined : json['errorCode'],
    };
}

export function WithdrawalReconciliationAPIModelToJSON(value?: WithdrawalReconciliationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'wallet': value['wallet'],
        'companyId': value['companyId'],
        'expectedAmount': value['expectedAmount'],
        'calculatedAmount': value['calculatedAmount'],
        'status': WithdrawalReconciliationStatusAPIModelToJSON(value['status']),
        'blockNum': value['blockNum'],
        'hash': value['hash'],
        'attempts': value['attempts'],
        'withdrawnAt': ((value['withdrawnAt']).toISOString()),
        'previousBlockNum': value['previousBlockNum'],
        'reportId': value['reportId'],
        'nextAttempt': value['nextAttempt'] == null ? undefined : ((value['nextAttempt']).toISOString()),
        'errorCode': value['errorCode'],
    };
}

