import { FilterForm } from '@/components';
import type { MerchantWalletTransferFilterPredicate } from '@/features/merchant-wallet-transfers/types';
import { nameof } from '@/infrastructure/utils/ts';

import {
  MerchantWalletTransferKindFilterItem,
  MerchantWalletTransferDirectionFilterItem,
  MerchantWalletTransferReconciliationStatusFilterItem,
  MerchantWalletLikeFilterItem,
  MerchantWalletTransferBroadcastAtRangeFilterItem,
  MerchantWalletTransferSenderLikeFilterItem,
} from './components';

import type { MerchantWalletTransferFilterFormProps } from './types';
import type React from 'react';

const fields = [
  nameof<MerchantWalletTransferFilterPredicate>('directionEq'),
  nameof<MerchantWalletTransferFilterPredicate>('kindIn'),
  nameof<MerchantWalletTransferFilterPredicate>('walletLike'),
  nameof<MerchantWalletTransferFilterPredicate>('senderLike'),
  nameof<MerchantWalletTransferFilterPredicate>('destinationLike'),
  nameof<MerchantWalletTransferFilterPredicate>('broadcastAtRange'),
  nameof<MerchantWalletTransferFilterPredicate>('reconciliationStatusIn'),
];

const MerchantWalletTransferFilterForm: React.FC<
  MerchantWalletTransferFilterFormProps<MerchantWalletTransferFilterPredicate>
> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  return (
    <FilterForm fields={fields} {...props}>
      <MerchantWalletTransferDirectionFilterItem
        data-test={dataTest && `${dataTest}-direction`}
        name={nameof<MerchantWalletTransferFilterPredicate>('directionEq')}
        readonly={readonly}
      />
      <MerchantWalletTransferKindFilterItem
        data-test={dataTest && `${dataTest}-kind`}
        name={nameof<MerchantWalletTransferFilterPredicate>('kindIn')}
        readonly={readonly}
      />
      <MerchantWalletLikeFilterItem
        data-test={dataTest && `${dataTest}-wallet`}
        name={nameof<MerchantWalletTransferFilterPredicate>('walletLike')}
        readonly={readonly}
      />
      <MerchantWalletTransferSenderLikeFilterItem
        data-test={dataTest && `${dataTest}-sender`}
        name={nameof<MerchantWalletTransferFilterPredicate>('senderLike')}
        readonly={readonly}
      />
      <MerchantWalletTransferDirectionFilterItem
        data-test={dataTest && `${dataTest}-destination`}
        name={nameof<MerchantWalletTransferFilterPredicate>('destinationLike')}
        readonly={readonly}
      />
      <MerchantWalletTransferBroadcastAtRangeFilterItem<MerchantWalletTransferFilterPredicate>
        data-test={dataTest && `${dataTest}-broadcastAt`}
        name={nameof<MerchantWalletTransferFilterPredicate>('broadcastAtRange')}
        readonly={readonly}
        relativeUpdateKey="broadcastAtRangeRelative"
      />
      <MerchantWalletTransferReconciliationStatusFilterItem
        data-test={dataTest && `${dataTest}-reconciliation`}
        name={nameof<MerchantWalletTransferFilterPredicate>('reconciliationStatusIn')}
        readonly={readonly}
      />
    </FilterForm>
  );
};

export default MerchantWalletTransferFilterForm;
