import { LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Checkbox, Space, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { WalletLink } from '@/features/web3/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useStateMountSafe } from '@/hooks';

import type { Web3AuthPrivateKeySecureProps } from './types';

const { Text } = Typography;

const Web3AuthPrivateKeySecure: React.FC<Web3AuthPrivateKeySecureProps> = ({
  'data-test': dataTest,
  value,
  onConfirm,
  onLogout,
}) => {
  const [checked, setChecked] = useStateMountSafe(false);
  const { submitting, withSubmitting } = useErrorSafeSubmitting();
  const doConfirm = useMemo(() => withSubmitting(onConfirm), [onConfirm, withSubmitting]);
  const doLogout = useMemo(() => (onLogout ? withSubmitting(onLogout) : undefined), [onLogout, withSubmitting]);
  return (
    <Space direction="vertical">
      <Text data-test={dataTest && `${dataTest}-message`}>
        <FormattedMessageWithMarkup
          id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_MESSAGE}
          values={{
            coinbase: <WalletLink type="coinbaseWallet" />,
            metamask: <WalletLink type="metaMask" />,
          }}
        />
      </Text>
      <Text code copyable className="ym-hide-content">
        {value}
      </Text>
      <Checkbox style={{ lineHeight: '32px' }} checked={checked} onChange={(e) => setChecked(e.target.checked)}>
        <FormattedMessageWithMarkup id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_CONFIRMATION} />
      </Checkbox>
      <Space style={{ display: 'flex', justifyContent: 'end' }}>
        <Operation
          data-test={dataTest && `${dataTest}-confirmOp`}
          icon={<LockOutlined />}
          primary
          mode="button"
          disabled={submitting || !checked}
          onClick={doConfirm}
          title={
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_SUBMIT_TITLE} tagName="span" />
          }
        />
        {doLogout && (
          <Operation
            data-test={dataTest && `${dataTest}-logoutOp`}
            icon={<LogoutOutlined />}
            mode="button"
            disabled={submitting}
            onClick={doLogout}
            title={
              <FormattedMessage
                id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_CANCEL_TITLE}
                tagName="span"
              />
            }
          />
        )}
      </Space>
    </Space>
  );
};

const Web3AuthPrivateKeySecureMemo = React.memo(Web3AuthPrivateKeySecure);

export default Web3AuthPrivateKeySecureMemo;
