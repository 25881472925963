/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributePushCollectSortByAPIModel } from './SortAttributePushCollectSortByAPIModel';
import {
    SortAttributePushCollectSortByAPIModelFromJSON,
    SortAttributePushCollectSortByAPIModelFromJSONTyped,
    SortAttributePushCollectSortByAPIModelToJSON,
} from './SortAttributePushCollectSortByAPIModel';

/**
 * 
 * @export
 * @interface PagePushCollectSortByAPIModel
 */
export interface PagePushCollectSortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PagePushCollectSortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PagePushCollectSortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributePushCollectSortByAPIModel>}
     * @memberof PagePushCollectSortByAPIModel
     */
    sortBy?: Array<SortAttributePushCollectSortByAPIModel>;
}

/**
 * Check if a given object implements the PagePushCollectSortByAPIModel interface.
 */
export function instanceOfPagePushCollectSortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PagePushCollectSortByAPIModelFromJSON(json: any): PagePushCollectSortByAPIModel {
    return PagePushCollectSortByAPIModelFromJSONTyped(json, false);
}

export function PagePushCollectSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PagePushCollectSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributePushCollectSortByAPIModelFromJSON)),
    };
}

export function PagePushCollectSortByAPIModelToJSON(value?: PagePushCollectSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributePushCollectSortByAPIModelToJSON)),
    };
}

