import type { PageLayoutProps } from '@/components';
import { PageLayout } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPageLayout =
  <T extends TestableProps>(layoutProps?: (props: T) => PageLayoutProps) =>
  (Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithPageLayout',
    )((props: T) => (
      <PageLayout {...layoutProps?.(props)}>
        <Component {...props} />
      </PageLayout>
    ));

export default withPageLayout;
