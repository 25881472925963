import { useMemo } from 'react';

import type { EditPayoutFormData } from '@/features/payouts/components';
import { usePayout, usePayoutDestinations } from '@/features/payouts/hooks';
import { useLocaleSettings } from '@/hooks';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataLoaded, combine, mapStoredState } from '@/infrastructure/model';
import { noopAsync, withVoidOrThrow } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

interface UseInitialValues {
  data: LoadingStateWithDirty<EditPayoutFormData>;
  loading: boolean;
  forceRefresh: Func;
}

export default function useInitialValues(copyOf?: string): UseInitialValues {
  const payoutState = usePayout(copyOf);
  const destinationsState = usePayoutDestinations(copyOf);
  const { formatDateTime } = useLocaleSettings();
  return useMemo(
    () =>
      copyOf
        ? {
            data: mapStoredState(
              combine(payoutState.data, destinationsState.data, (payout, destinations) => ({ payout, destinations })),
              ({ payout, destinations }): EditPayoutFormData => ({
                title: `${payout.title} (copy)`,
                asset: payout.amount.asset,
                destinations: destinations.map(
                  ({ num, amount, address }): NonNullable<EditPayoutFormData['destinations']>[0] => ({
                    num,
                    address,
                    amount: amount.value,
                  }),
                ),
              }),
            ),
            loading: payoutState.loading || destinationsState.loading,
            forceRefresh: withVoidOrThrow(() =>
              Promise.all([payoutState.forceRefresh, destinationsState.forceRefresh]),
            ),
          }
        : {
            data: storedDataLoaded<EditPayoutFormData>({ title: formatDateTime(new Date()) }),
            forceRefresh: noopAsync,
            loading: false,
          },
    [
      copyOf,
      destinationsState.data,
      destinationsState.forceRefresh,
      destinationsState.loading,
      formatDateTime,
      payoutState.data,
      payoutState.forceRefresh,
      payoutState.loading,
    ],
  );
}
