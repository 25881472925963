import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageGasHistory } from '@/generated/i18n/i18n';

import GasHistoryLink from '../GasHistoryLink';

import type { GasHistoryBreadcrumbProps } from './types';

const GasHistoryBreadcrumb: React.FC<GasHistoryBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageGasHistory.ALL_COMPONENTS_BREADCRUMB} />
        ) : (
          <GasHistoryLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            title={<FormattedMessage id={I18nPageGasHistory.ALL_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const GasHistoryBreadcrumbMemo = React.memo(GasHistoryBreadcrumb);

export default GasHistoryBreadcrumbMemo;
