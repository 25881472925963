import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Space, Upload } from 'antd';
import BigNumber from 'bignumber.js';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import type { PayoutDestinationRow } from '@/features/payouts/components';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { noop } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import type { PayoutDestinationsPersistOperationProps } from './types';

const inputStyle: React.CSSProperties = { visibility: 'hidden', display: 'none' };

const PayoutDestinationsPersistOperation: React.FC<PayoutDestinationsPersistOperationProps> = ({
  'data-test': dataTest,
  value,
  onChange,
  disabled,
  mode,
}) => {
  const loadDataFromFile = useCallback(
    (fileText: string) => {
      const dataToMerge: Omit<PayoutDestinationRow, 'num'>[] = fileText
        .split('\n')
        .map((line) => {
          try {
            const parts = line.trim().split(',');
            const address = (parts[0] || '').trim();
            const amount = new BigNumber((parts[1] || '').trim());
            if (address === '' || amount.lte(0)) return undefined;
            return { address, amount };
          } catch {
            return undefined;
          }
        })
        .filter(notEmpty);

      if (!dataToMerge.length) {
        return;
      }

      const replaced = [
        ...value.map((v) => {
          const foundIdx = dataToMerge.findIndex((v2) => isSameAddress(v.address, v2.address));
          const found = foundIdx !== -1 ? dataToMerge.splice(foundIdx, 1)[0] : undefined;
          return found ? { ...found, num: v.num } : v;
        }),
      ];
      let idx = Math.max(...(replaced.length ? replaced.map(({ num }) => num) : [0])) + 1;
      onChange([...dataToMerge.map((v) => ({ ...v, num: idx++ })), ...replaced]);
    },
    [onChange, value],
  );

  const saveDataToFile = useCallback(() => {
    const csvContent = value.map((item) => `${item.address},${item.amount.toString()}`).join('\n');
    const a = document.createElement('a');
    a.href = URL.createObjectURL(new Blob([csvContent], { type: 'text/csv;charset=utf-8' }));
    a.setAttribute('download', `payout_destinations_${value.length}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, [value]);

  return (
    <Space>
      <Upload
        data-test={dataTest && `${dataTest}-load`}
        beforeUpload={(file) => {
          const enc = new TextDecoder('utf-8');
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target?.result) {
              loadDataFromFile(typeof e.target.result === 'string' ? e.target.result : enc.decode(e.target.result));
            }
          };
          reader.readAsText(file);
          return false;
        }}
        showUploadList={false}
        accept=".csv"
        style={inputStyle}
        // onChange={processSelectedFile}
      >
        <Operation
          data-test={dataTest && `${dataTest}-upload-button`}
          disabled={disabled}
          onClick={noop}
          icon={<UploadOutlined />}
          title={
            <FormattedMessage
              id={
                I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_PERSIST_OPERATION_LOAD_TITLE
              }
              tagName="span"
            />
          }
          mode={mode}
        />
      </Upload>
      <Operation
        data-test={dataTest && `${dataTest}-download-button`}
        disabled={disabled || !value.length}
        onClick={saveDataToFile}
        icon={<DownloadOutlined />}
        title={
          <FormattedMessage
            id={
              I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_PERSIST_OPERATION_SAVE_TITLE
            }
            tagName="span"
          />
        }
        mode={mode}
      />
    </Space>
  );
};

const PayoutDestinationsPersistOperationMemo = React.memo(PayoutDestinationsPersistOperation);

export default PayoutDestinationsPersistOperationMemo;
