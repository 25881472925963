import { Form } from 'antd';
import React, { useMemo } from 'react';

import {
  FormattedMessage,
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import CompanyItem from '@/features/company/components/CompanyItem';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { FormProps, SelectCompanyFormProps } from './types';

const fields = [nameof<FormProps>('company')];

const SelectCompanyForm: React.FC<SelectCompanyFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  values,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { form, withResetForm } = useForm<FormProps>();
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: FormProps): unknown => onSubmit(val.company)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_SELECT_COMPANY_FORM_ERROR_COMMON} />;
  }, [error]);

  return (
    <Form
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={values}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage content={errorMessage} />}
      <CompanyItem name={nameof<FormProps>('company')} data-test={dataTest && `${dataTest}-company`} />
      <FormCompletenessItem
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={({ getFieldValue }) => getFieldValue(nameof<FormProps>('company')) !== values?.company}
      />
      <FormFooter
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
        messages={{
          submit: (
            <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_SELECT_COMPANY_FORM_SUBMIT} tagName="span" />
          ),
        }}
        data-test={dataTest && `${dataTest}-submit`}
      />
    </Form>
  );
};

const SelectCompanyFormMemo = React.memo(SelectCompanyForm);

export default SelectCompanyFormMemo;
