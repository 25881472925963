import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  HourglassOutlined,
  PauseCircleOutlined,
  StopOutlined,
} from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { WithdrawalJobStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementStatusViewProps } from './types';

const i18n: Record<WithdrawalJobStatusAPIModel, I18nFeatureSettlements> = {
  [WithdrawalJobStatusAPIModel.New]: I18nFeatureSettlements.COMPONENTS_STATUS_VIEW_NEW,
  [WithdrawalJobStatusAPIModel.Finished]: I18nFeatureSettlements.COMPONENTS_STATUS_VIEW_FINISHED,
  [WithdrawalJobStatusAPIModel.Running]: I18nFeatureSettlements.COMPONENTS_STATUS_VIEW_RUNNING,
  [WithdrawalJobStatusAPIModel.Paused]: I18nFeatureSettlements.COMPONENTS_STATUS_VIEW_PAUSED,
  [WithdrawalJobStatusAPIModel.Cancelled]: I18nFeatureSettlements.COMPONENTS_STATUS_VIEW_CANCELLED,
  [WithdrawalJobStatusAPIModel.Error]: I18nFeatureSettlements.COMPONENTS_STATUS_VIEW_ERROR,
};

const icons: Record<WithdrawalJobStatusAPIModel, React.ReactNode> = {
  [WithdrawalJobStatusAPIModel.New]: <FileOutlined style={{ color: 'blue' }} />,
  [WithdrawalJobStatusAPIModel.Finished]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [WithdrawalJobStatusAPIModel.Running]: <HourglassOutlined style={{ color: 'blue' }} />,
  [WithdrawalJobStatusAPIModel.Paused]: <PauseCircleOutlined style={{ color: 'orange' }} />,
  [WithdrawalJobStatusAPIModel.Cancelled]: <StopOutlined style={{ color: 'red' }} />,
  [WithdrawalJobStatusAPIModel.Error]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const SettlementStatusView: React.FC<SettlementStatusViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;
const SettlementStatusViewMemo = React.memo(SettlementStatusView) as typeof SettlementStatusView;

export default SettlementStatusViewMemo;
