/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PaymentsModeAPIModel } from './PaymentsModeAPIModel';
import {
    PaymentsModeAPIModelFromJSON,
    PaymentsModeAPIModelFromJSONTyped,
    PaymentsModeAPIModelToJSON,
} from './PaymentsModeAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalEstimationAPIModel
 */
export interface WithdrawalEstimationAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalEstimationAPIModel
     */
    amount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalEstimationAPIModel
     */
    estimatedAmount: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalEstimationAPIModel
     */
    estimatedAmountInvoice: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalEstimationAPIModel
     */
    estimatedAmountPush: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalEstimationAPIModel
     */
    estimatedAmountDefault: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalEstimationAPIModel
     */
    processingFee: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalEstimationAPIModel
     */
    processingFeeUnpaid: string;
    /**
     * 
     * @type {Date}
     * @memberof WithdrawalEstimationAPIModel
     */
    estimatedAt: Date;
    /**
     * 
     * @type {PaymentsModeAPIModel}
     * @memberof WithdrawalEstimationAPIModel
     */
    mode: PaymentsModeAPIModel;
}

/**
 * Check if a given object implements the WithdrawalEstimationAPIModel interface.
 */
export function instanceOfWithdrawalEstimationAPIModel(value: object): boolean {
    if (!('amount' in value)) return false;
    if (!('estimatedAmount' in value)) return false;
    if (!('estimatedAmountInvoice' in value)) return false;
    if (!('estimatedAmountPush' in value)) return false;
    if (!('estimatedAmountDefault' in value)) return false;
    if (!('processingFee' in value)) return false;
    if (!('processingFeeUnpaid' in value)) return false;
    if (!('estimatedAt' in value)) return false;
    if (!('mode' in value)) return false;
    return true;
}

export function WithdrawalEstimationAPIModelFromJSON(json: any): WithdrawalEstimationAPIModel {
    return WithdrawalEstimationAPIModelFromJSONTyped(json, false);
}

export function WithdrawalEstimationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalEstimationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'estimatedAmount': json['estimatedAmount'],
        'estimatedAmountInvoice': json['estimatedAmountInvoice'],
        'estimatedAmountPush': json['estimatedAmountPush'],
        'estimatedAmountDefault': json['estimatedAmountDefault'],
        'processingFee': json['processingFee'],
        'processingFeeUnpaid': json['processingFeeUnpaid'],
        'estimatedAt': (new Date(json['estimatedAt'])),
        'mode': PaymentsModeAPIModelFromJSON(json['mode']),
    };
}

export function WithdrawalEstimationAPIModelToJSON(value?: WithdrawalEstimationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'estimatedAmount': value['estimatedAmount'],
        'estimatedAmountInvoice': value['estimatedAmountInvoice'],
        'estimatedAmountPush': value['estimatedAmountPush'],
        'estimatedAmountDefault': value['estimatedAmountDefault'],
        'processingFee': value['processingFee'],
        'processingFeeUnpaid': value['processingFeeUnpaid'],
        'estimatedAt': ((value['estimatedAt']).toISOString()),
        'mode': PaymentsModeAPIModelToJSON(value['mode']),
    };
}

