import { List } from 'antd';
import React, { useCallback } from 'react';

import type { GasWallet } from '@/features/gas-wallets/types';

import { GasWalletItem } from './components';

import type { GasWalletsListProps } from './types';

const rowKey = ({ bt }: GasWallet) => bt;

const GasWalletsList: React.FC<GasWalletsListProps> = ({ 'data-test': dataTest, GasWalletLink, data }) => (
  <List<GasWallet>
    itemLayout="horizontal"
    dataSource={data}
    rowKey={rowKey}
    renderItem={useCallback(
      (item: GasWallet) => (
        <GasWalletItem
          data-test={dataTest && `${dataTest}-${item.bt}`}
          key={item.bt}
          wallet={item}
          GasWalletLink={GasWalletLink}
        />
      ),
      [GasWalletLink, dataTest],
    )}
  />
);

const GasWalletsListMemo = React.memo(GasWalletsList);

export default GasWalletsListMemo;
