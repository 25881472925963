import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { querySystemFlags, queryUserFlags } from '@/features/feature-toggle/api';
import { makeSelectFeatureToggle } from '@/features/feature-toggle/selectors';
import { withAPICall } from '@/infrastructure/api';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';

import { NAMESPACE } from './types';

import type { FeatureFlags } from './types';

export const { storeFeatureToggle, markFeatureToggleDirty } = createLoadingDataActions<FeatureFlags, 'FeatureToggle'>(
  NAMESPACE,
  'FeatureToggle',
);

const flagsFetchLimit = pLimit(1);
export const fetchFeatureToogle = createAppAsyncThunk(
  `${NAMESPACE}/fetchFeatureToogle`,
  async ({ force }: { force?: boolean }, { dispatch, getState }) =>
    flagsFetchLimit(async () => {
      const saved = makeSelectFeatureToggle()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const user = makeSelectAuthToken()(getState());
      const data = await withAPICall(user.data ? queryUserFlags : querySystemFlags)();
      dispatch(storeFeatureToggle(data));
      return data;
    }),
);
