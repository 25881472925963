import { Form } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import SubscriptionPlanSelect from '@/features/subscription-plans/components/SubscriptionPlanSelect';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import type { SubscriptionPlanItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SubscriptionPlanItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: SubscriptionPlanItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_PLAN_ITEM_LABEL} />}
    {...ItemProps}
    name={name}
  >
    <SubscriptionPlanSelect data-test={dataTest} disabled={readonly} />
  </Form.Item>
);

const SubscriptionPlanItemMemo = React.memo(SubscriptionPlanItem);

export default SubscriptionPlanItemMemo;
