import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { createCompany, updateCompanyKey, updateCompanyName } from '@/features/company/actions';
import type { Company, NewCompany, UpdateCompanyName } from '@/features/company/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction } from '@/infrastructure/model';

import useActiveCompany from '../useActiveCompany';

type CreateCompanyUnavailabilityReason = 'already-exists';
type UpdateCompanyKeyUnavailabilityReason = 'no-company' | 'key-is-defined';
type UpdateCompanyUnavailabilityReason = 'no-company';

export interface UseCompanyActionsType {
  createCompany: HookAction<[NewCompany], Company, CreateCompanyUnavailabilityReason>;
  updateKey: HookAction<[string], Company, UpdateCompanyKeyUnavailabilityReason>;
  updateName: HookAction<[UpdateCompanyName], Company, UpdateCompanyUnavailabilityReason>;
  data?: Company;
}

export default function useCompanyActions(): UseCompanyActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data: company } = useActiveCompany();

  const creating = useActionPending(createCompany);
  const createCompanyAction: UseCompanyActionsType['createCompany']['act'] = useMemo(
    () => withExtractDataDispatch(createCompany),
    [withExtractDataDispatch],
  );
  const createCompanyHook: UseCompanyActionsType['createCompany'] = useMemo(
    () => ({
      act: createCompanyAction,
      available: !company.data,
      unavailabilityReason: !company.data ? 'already-exists' : undefined,
      inAction: creating,
    }),
    [company.data, createCompanyAction, creating],
  );

  const updateCompanyKeyUnavailabilityReason = useMemo(() => {
    if (!company.data) {
      return 'no-company';
    }
    if (!company.data.extendedPubKey) {
      return 'key-is-defined';
    }
    return undefined;
  }, [company.data]);

  const updatingCompanyKey = useActionPending(updateCompanyKey);
  const updateCompanyKeyAction: UseCompanyActionsType['updateKey']['act'] = useCallback(
    (key: string) => withExtractDataDispatch(updateCompanyKey)({ key }),
    [withExtractDataDispatch],
  );
  const updateKey: UseCompanyActionsType['updateKey'] = useMemo(
    () => ({
      act: updateCompanyKeyAction,
      available: !updateCompanyKeyUnavailabilityReason,
      unavailabilityReason: updateCompanyKeyUnavailabilityReason,
      inAction: updatingCompanyKey,
    }),
    [updateCompanyKeyAction, updateCompanyKeyUnavailabilityReason, updatingCompanyKey],
  );

  const updatingCompanyName = useActionPending(updateCompanyName);
  const updateCompanyNameAction: UseCompanyActionsType['updateName']['act'] = useMemo(
    () => withExtractDataDispatch(updateCompanyName),
    [withExtractDataDispatch],
  );
  const updateNameHook: HookAction<[UpdateCompanyName], Company, UpdateCompanyUnavailabilityReason> = useMemo(
    () => ({
      act: updateCompanyNameAction,
      available: !!company.data,
      unavailabilityReason: company.data ? 'no-company' : undefined,
      inAction: updatingCompanyName,
    }),
    [company.data, updateCompanyNameAction, updatingCompanyName],
  );

  return { createCompany: createCompanyHook, updateName: updateNameHook, updateKey, data: company.data };
}
