import { Form } from 'antd';
import { useMemo } from 'react';

import { ErrorFormMessage, FormCompletenessItem } from '@/components';
import { useErrorSafeSubmitting, useForm, useStateMountSafe, useSubmitting } from '@/hooks';
import { nameof } from '@/infrastructure/utils/ts';

import EmailConfirmationItem from '../EmailConfirmationItem';

import { EmailConfirmationFormFooter } from './components';

import type { EmailConfirmationFormValues, EmailConfirmationFormProps } from './types';
import type React from 'react';

const requiredFields = [nameof<EmailConfirmationFormValues>('confirmation')];

const EmailConfirmationForm: React.FC<EmailConfirmationFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  submitText,
  disabled,
}) => {
  const { submitting, error, withSubmitting } = useErrorSafeSubmitting();
  const [verifying, withVerifying] = useSubmitting(false);
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);

  const { form } = useForm<EmailConfirmationFormValues>();
  const doSubmit = useMemo(
    () =>
      withSubmitting((values: EmailConfirmationFormValues) =>
        onSubmit({
          email: values.confirmation.email!,
          auth0Token: values.confirmation.auth0Token!,
        }),
      ),
    [onSubmit, withSubmitting],
  );

  return (
    <Form<EmailConfirmationFormValues> form={form} onFinish={doSubmit} size="large">
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <EmailConfirmationItem<EmailConfirmationFormValues>
        name={nameof<EmailConfirmationFormValues>('confirmation')}
        withVerifying={withVerifying}
      />
      <FormCompletenessItem<EmailConfirmationFormValues> requiredFields={requiredFields} onChange={setFormComplete} />
      <EmailConfirmationFormFooter
        data-test={dataTest && `${dataTest}-footer`}
        disabled={!isFormComplete || verifying || disabled}
        inProgress={submitting}
        title={submitText}
      />
    </Form>
  );
};

export default EmailConfirmationForm;
