import { Space } from 'antd';
import React, { useMemo, useRef } from 'react';

import type { LinkMode, OperationMode } from '@/components';
import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { SubscriptionPlansTable } from '@/features/subscription-plans/components';
import { useSubscriptionPlanListView } from '@/features/subscription-plans/hooks';
import { SubscriptionsDocumentationLink } from '@/features/subscriptions/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { useBounds } from '@/hooks';
import SubscriptionPlanAddLink from '@/pages/subscriptions/plan-add/components/SubscriptionPlanAddLink';

import type { SubscriptionPlansCardProps } from './types';

const SubscriptionPlansCard: React.FC<SubscriptionPlansCardProps> = ({ 'data-test': dataTest, ...props }) => {
  const data = useSubscriptionPlanListView();
  const ref = useRef<HTMLDivElement>(null);
  const size = useBounds(ref);
  const documentationLinkMode: LinkMode = useMemo(
    () => ((size?.width ?? 0) < 530 ? 'icon' : 'text_icon'),
    [size?.width],
  );
  const createMode: OperationMode = useMemo(() => ((size?.width ?? 0) < 450 ? 'inline' : 'button'), [size?.width]);
  return (
    <PageCard
      ref={ref}
      title={
        <Space>
          <FormattedMessage id={I18nPageSubscriptions.PLANS_COMPONENTS_PLANS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <SubscriptionsDocumentationLink
            data-test={dataTest && `${dataTest}-documentationLink`}
            mode={documentationLinkMode}
          />
          <SubscriptionPlanAddLink data-test={dataTest && `${dataTest}-add`} mode={createMode} />
        </Space>
      }
    >
      <SubscriptionPlansTable {...data} {...props} />
    </PageCard>
  );
};

const SubscriptionPlansCardMemo = React.memo(SubscriptionPlansCard);

export default SubscriptionPlansCardMemo;
