import { Form } from 'antd';
import isArray from 'lodash/isArray';
import React from 'react';

import { FormattedMessage } from '@/components';
import { AssetAmountItem as BaseAssetAmountItem } from '@/features/dictionary/blockchain/components';
import { I18nComponents, I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import CompanyActiveAssetItem from '../CompanyActiveAssetItem';

import { withAmountLimits } from './hocs';

import type { CompanyActiveAssetAmountItemProps, CompanyActiveAssetAmountItemValue } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AssetAmountItem = withAmountLimits(BaseAssetAmountItem) as typeof BaseAssetAmountItem;

const CompanyActiveAssetAmountItem = <Values extends Store = Store>({
  'data-test': dataTest,
  baseName,
  name,
  selectedAssets,
  excludeAPIs,
}: CompanyActiveAssetAmountItemProps<Values>) => (
  <>
    <CompanyActiveAssetItem
      data-test={dataTest && `${dataTest}-asset`}
      name={name && [...(isArray(name) ? name : [name]), nameof<CompanyActiveAssetAmountItemValue>('asset')]}
      excludeAPIs={excludeAPIs}
      selectedAssets={selectedAssets}
    />
    <Form.Item noStyle shouldUpdate key="container">
      {({ getFieldValue }) =>
        name
        && getFieldValue([...(isArray(name) ? name : [name]), nameof<CompanyActiveAssetAmountItemValue>('asset')]) ? (
          <AssetAmountItem
            key="amount"
            data-test={dataTest && `${dataTest}-amount`}
            assetId={getFieldValue([
              ...(baseName ? (isArray(baseName) ? baseName : [baseName]) : []),
              ...(isArray(name) ? name : [name]),
              nameof<CompanyActiveAssetAmountItemValue>('asset'),
            ])}
            required
            ItemProps={{
              dependencies: [
                [
                  ...(baseName ? (isArray(baseName) ? baseName : [baseName]) : []),
                  ...(isArray(name) ? name : [name]),
                  nameof<CompanyActiveAssetAmountItemValue>('asset'),
                ],
              ],
            }}
            name={[...(isArray(name) ? name : [name]), nameof<CompanyActiveAssetAmountItemValue>('amount')]}
          />
        ) : (
          <Form.Item<Values> label={<FormattedMessage id={I18nComponents.MONEY_AMOUNT_ITEM_LABEL} />}>
            <FormattedMessage
              id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_AMOUNT_ITEM_SELECT_ASSET}
            />
          </Form.Item>
        )
      }
    </Form.Item>
  </>
);

const CompanyActiveAssetAmountItemMemo = React.memo(
  CompanyActiveAssetAmountItem,
) as typeof CompanyActiveAssetAmountItem;

export default CompanyActiveAssetAmountItemMemo;
