import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum AuditPageRoutes {
  TRANSFERS = 'transfers',
  TRANSFER_VIEW = 'transfers/:transferId/view',
  TRANSFER_ENTITY = 'transfers/:transferId/entity',
  BALANCES = 'balance',
  BALANCE_VIEW = 'balance/:balanceId/view',
}

export const auditRoute = (page: AuditPageRoutes) => `${appRoute(AppPageRoutes.AUDIT)}/${page}`;

export const auditWalletTransfersLink = () => `${appRoute(AppPageRoutes.AUDIT)}/${AuditPageRoutes.TRANSFERS}`;
export const auditWalletTransferViewLink = (id: string) => `${auditWalletTransfersLink()}/${id}/view`;
export const auditWalletTransferEntityLink = (id: string) => `${auditWalletTransfersLink()}/${id}/entity`;

export const auditWalletRunningBalancesLink = () => `${appRoute(AppPageRoutes.AUDIT)}/${AuditPageRoutes.BALANCES}`;
export const auditWalletRunningBalanceViewLink = (id: string) => `${auditWalletRunningBalancesLink()}/${id}/view`;
