import { enumValues } from '@/infrastructure/utils/ts';

import { BrandingAuth, BrandingLang, BrandingLogoPosition, BrandingOnRamp, type PaymentsBranding } from './types';

export const defaultBranding = (): PaymentsBranding => ({
  contactEmail: '',
  poweredBy: {
    visible: false,
    customText: { enabled: false, text: '' },
  },
  logo: {
    visible: false,
    url: '',
    position: BrandingLogoPosition.top,
  },
  redirectURL: '',
  supportedLang: [BrandingLang.en],
  auth: enumValues(BrandingAuth),
  onRamp: enumValues(BrandingOnRamp),
});
