import { FileExcelOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { usePaymentsActions } from '@/features/payments/hooks';
import { ReportDownloader } from '@/features/reports/components';
import type { Report } from '@/features/reports/types';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useNotification, useStateMountSafe } from '@/hooks';
import ReportLink from '@/pages/reports/report-view/components/ReportViewLink';

import type { OperationExportPaymentsProps } from './types';

const OperationExportPaymentsRaw: React.FC<OperationExportPaymentsProps> = ({
  'data-test': dataTest,
  disabled,
  mode = 'inline',
  rows,
}) => {
  const [reportId, setReportId] = useStateMountSafe<string>();
  const { withNotification } = useNotification();
  const { exportPayments: exportPaymentsAction } = usePaymentsActions();

  const exportPayments = useCallback(async () => {
    const report: Report = await withNotification(exportPaymentsAction.act, {
      error: () => <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_EXPORT_ERROR_MESSAGE} />,
      success: (r: Report) => (
        <FormattedMessage
          id={I18nFeaturePayments.COMPONENTS_OPERATION_EXPORT_GENERATING_MESSAGE}
          values={{
            ln: (text: React.ReactNode) => (
              <ReportLink value={r.id} title={text} data-test={dataTest && `${dataTest}-reportLink`} mode="text" />
            ),
          }}
        />
      ),
    })();
    setReportId(report.id);
  }, [dataTest, exportPaymentsAction.act, setReportId, withNotification]);

  return (
    <>
      <Operation
        title={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_EXPORT_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_EXPORT_TOOLTIP} />}
        icon={<FileExcelOutlined />}
        onClick={exportPayments}
        disabled={disabled || !exportPaymentsAction.available || rows === 0}
        inProgress={exportPaymentsAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
      {reportId && <ReportDownloader data-test={dataTest && `${dataTest}-report`} reportId={reportId} />}
    </>
  );
};

const OperationExportPayments = React.memo(OperationExportPaymentsRaw);

export default OperationExportPayments;
