import { useCollectTask } from '@/features/collectable/hooks';
import type { CollectTask, CollectTaskSummary } from '@/features/collectable/types';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

const withCollectTaskLoad = <T extends { task: CollectTask | CollectTaskSummary }>(
  Component: React.ComponentType<Omit<T, 'task'> & { task: CollectTask | CollectTaskSummary }>,
  EmptyComponent: React.ComponentType<Omit<T, 'task'>>,
) =>
  namedHOC<Omit<T, 'task'> & { task: CollectTask | CollectTaskSummary }, Omit<T, 'task'> & { taskId: string }>(
    Component,
    'WithCollectScheduleLoad',
  )((props) => {
    const { taskId } = props;
    const task = useCollectTask(taskId);

    return task.data.data ? <Component {...props} task={task.data.data} /> : <EmptyComponent {...props} />;
  });

const withCollectTask = <T extends { task: CollectTask | CollectTaskSummary }>(
  EmptyComponent: React.ComponentType<Omit<T, 'task'>> = emptyComponent,
) => {
  return (Component: React.ComponentType<Omit<T, 'task'> & { task: CollectTask | CollectTaskSummary }>) => {
    const LoadComponent = withCollectTaskLoad<T>(Component, EmptyComponent);
    return namedHOC<
      Omit<T, 'task'> & { task: CollectTask | CollectTaskSummary },
      T | (Omit<T, 'task'> & { taskId: string })
    >(
      Component,
      'WithCollectTask',
    )((props) => ('task' in props ? <Component {...props} /> : <LoadComponent {...props} />));
  };
};

export default withCollectTask;
