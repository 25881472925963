import { createAction } from '@reduxjs/toolkit';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { fetchWeb3CompatBlockchainAPI } from '@/features/dictionary/blockchain/actions';
import type { UserMerchantWalletCreateAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { withAPICall } from '@/infrastructure/api';
import type { CommonLoadingState } from '@/infrastructure/model';
import { mapLoadingState } from '@/infrastructure/model';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { callWithThunkError } from '@/infrastructure/utils/redux';

import { queryWalletSignatures, requestSaveWalletSignature } from './api';
import { makeSelectMerchantWalletDeployment, makeSelectMerchantWallets } from './selectors';
import { NAMESPACE } from './types';
import { merchantWalletIdToStoreKey } from './utils';
import { queryWeb3MerchantAddress } from './web3-api';

import type { MerchantWalletDeploymentState, MerchantWalletId, MerchantWalletSignature } from './types';

export const { storeMerchantWalletDeployment, markMerchantWalletDeploymentDirty } = createSingleActions<
  MerchantWalletDeploymentState,
  'MerchantWalletDeployment',
  MerchantWalletId
>(NAMESPACE, 'MerchantWalletDeployment');

const walletDeploymentFetchLimit = pLimit(1);
export const fetchMerchantWalletDeploymentState = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletDeploymentState`,
  async ({ force, id }: { force?: boolean; id: MerchantWalletId }, { dispatch, getState }) =>
    walletDeploymentFetchLimit(async () => {
      const saved = makeSelectMerchantWalletDeployment(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const status = await callWithThunkError(async () => {
        const bcMeta = await dispatch(fetchWeb3CompatBlockchainAPI({ bt: id.bt, force })).unwrap();
        const queryResult = await withAPICall(queryWeb3MerchantAddress)(
          { meta: { host: bcMeta.host, auth: bcMeta.auth } },
          bcMeta.merchantWalletFactoryAddress,
          id.address,
        );
        return mapLoadingState(
          queryResult,
          (address): MerchantWalletDeploymentState => ({
            bt: bcMeta.bt,
            ...(address ? { address, deployed: true } : { deployed: false }),
          }),
        );
      });
      dispatch(storeMerchantWalletDeployment({ id, data: status }));

      return makeSelectMerchantWalletDeployment(id)(getState());
    }),
  { idGenerator: ({ id }) => merchantWalletIdToStoreKey(id) },
);

export const storeMerchantWallets = createAction<CommonLoadingState<MerchantWalletSignature[]>>(
  `${NAMESPACE}/storeMerchantWallets`,
);
export const markMerchantWalletsDirty = createAction(`${NAMESPACE}/markMerchantWalletsDirty`);

const walletsFetchLimit = pLimit(1);
export const fetchMerchantWallets = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWallets`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    walletsFetchLimit(async () => {
      const saved = makeSelectMerchantWallets()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryWalletSignatures, 'unable to fetch signatures')({ signal });
      dispatch(storeMerchantWallets(data));

      return makeSelectMerchantWallets()(getState());
    }),
);

export const createMerchantWalletSignature = createAppAsyncThunk(
  `${NAMESPACE}/fetchMerchantWalletSignature`,
  async (signature: UserMerchantWalletCreateAPIModel, { dispatch, signal }) => {
    const data = await withAPICall(requestSaveWalletSignature, 'unable to save signature')(signature, { signal });
    if (data.data) {
      dispatch(storeMerchantWallets(data));
    }
    return data;
  },
);
