import { useAppLoadableData } from '@/app/hooks';
import { fetchMerchantDomains } from '@/features/branding/actions';
import { makeSelectMerchantDomains } from '@/features/branding/selectors';
import { makeSelectPending } from '@/features/global/selectors';

const dataFetchingSelector = makeSelectPending(fetchMerchantDomains);
const fetchFactory = (force: boolean) => fetchMerchantDomains({ force });
const dataSelector = makeSelectMerchantDomains();

export default function useDomains() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
