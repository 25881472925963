import { ArrowLeftOutlined, ReloadOutlined } from '@ant-design/icons';
import { Alert, Button, Space } from 'antd';
import isFunction from 'lodash/isFunction';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import FormattedMessage from '@/components//FormattedMessage';
import { I18nComponents } from '@/generated/i18n/i18n';

import type { DataFetchErrorProps } from './types';

const DataFetchError: React.FC<DataFetchErrorProps> = ({
  'data-test': dataTest,
  back,
  refresh,
  message,
  description,
  extra,
}) => {
  const history = useNavigate();
  const onBack = useCallback((): unknown => (isFunction(back) ? back() : history(-1)), [back, history]);
  return (
    <Alert
      message={message ?? <FormattedMessage id={I18nComponents.DATA_FETCH_TITLE} />}
      showIcon
      description={
        <Space direction="vertical">
          {description ?? <FormattedMessage id={I18nComponents.DATA_FETCH_DESCRIPTION} />}
          {refresh && (
            <Button danger data-test={dataTest && `${dataTest}-refresh`} onClick={refresh} icon={<ReloadOutlined />}>
              <FormattedMessage id={I18nComponents.DATA_FETCH_REFRESH} tagName="span" />
            </Button>
          )}
          {back && (
            <Button data-test={dataTest && `${dataTest}-back`} onClick={onBack} icon={<ArrowLeftOutlined />}>
              <FormattedMessage id={I18nComponents.DATA_FETCH_BACK} tagName="span" />
            </Button>
          )}
          {extra}
        </Space>
      }
      type="error"
    />
  );
};

const DataFetchErrorMemo = React.memo(DataFetchError);

export default DataFetchErrorMemo;
