import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Space, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { FormattedMessage } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useFirstRenderEffect } from '@/hooks';
import { asType } from '@/infrastructure/utils/ts';

import { MetadataInput } from './components';

import type { MetadataInternalValue, MetadataItemProps } from './types';
import type { FormItemProps } from 'antd/lib/form';
import type { Store } from 'rc-field-form/es/interface';

const tempRules: FormItemProps<unknown>['rules'] = [
  {
    // eslint-disable-next-line @typescript-eslint/require-await
    validator: async (_, valueToTest?: MetadataInternalValue) => {
      if (valueToTest?.inputValue && !valueToTest.isValid) {
        throw new Error();
      }
    },
    message: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_ERROR_INVALID_JSON} />,
  },
];

const MetadataItem = <Values extends Store = Store>({ name, 'data-test': dataTest }: MetadataItemProps<Values>) => {
  const tempName = useMemo(() => uuid(), []);
  const form = Form.useFormInstance();

  const rules = useMemo<FormItemProps<Values>['rules']>(
    () => [
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async () => {
          const valueToTest: MetadataInternalValue | undefined = form.getFieldValue(tempName);
          if (valueToTest?.inputValue && !valueToTest.isValid) {
            throw new Error();
          }
        },
        message: (
          <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_ERROR_INVALID_JSON} />
        ),
      },
    ],
    [form, tempName],
  );

  useFirstRenderEffect(() => {
    const value: object | undefined = form.getFieldValue(name);
    form.setFieldValue(
      tempName,
      asType<MetadataInternalValue>({
        value,
        inputValue: value ? JSON.stringify(value) : undefined,
        isValid: !!value,
      }),
    );
  });
  return (
    <Form.Item<Values> noStyle>
      <Form.Item<Values> name={name} dependencies={[tempName]} hidden rules={rules} />
      <Form.Item dependencies={[tempName]} hidden>
        {({ setFieldValue, getFieldValue }) => {
          const value: MetadataInternalValue | undefined = getFieldValue(tempName);
          setFieldValue(name, value?.value);
          return null;
        }}
      </Form.Item>
      <Form.Item
        name={tempName}
        label={
          <Space>
            <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_METADATA} />
            <Tooltip title={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_HELP} />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        }
        rules={tempRules}
      >
        <MetadataInput data-test={dataTest} />
      </Form.Item>
    </Form.Item>
  );
};

const MetadataItemMemo = React.memo(MetadataItem) as typeof MetadataItem;

export default MetadataItemMemo;
