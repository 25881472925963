import { useCallback, useMemo } from 'react';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { CreateRechargeAddressForm } from '@/features/recharges/components';
import { useRechargeAddressesActions } from '@/features/recharges/hooks';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import {
  useDefaultNotification,
  useFirstRenderEffect,
  useFormPageAction,
  useNotification,
  useStateMountSafe,
} from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { rechargeAddressesLink } from '@/pages/recharges/routes';

import { RechargeAddressAddBreadcrumb } from './components';

import type React from 'react';

type RechargeAddressAddPageProps = TestableProps;

const RechargeAddressAddPage: React.FC<RechargeAddressAddPageProps> = ({ 'data-test': dataTest = 'recharge-add' }) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { create: createAction } = useRechargeAddressesActions();
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(rechargeAddressesLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  useFirstRenderEffect(() => {
    goalReached(YMGoals.RECHARGE_CREATE_INIT);
  });

  const doCreate = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(createAction.act, () => <FormattedMessage id={I18nPageRecharges.ADDRESS_ADD_MESSAGES_SUCCESS} />),
          () => <FormattedMessage id={I18nPageRecharges.ADDRESS_ADD_MESSAGES_ERROR} />,
        ),
        () => {
          goalReached(YMGoals.RECHARGE_CREATED);
          doRedirect();
        },
      ),
    [withDefaultError, withSuccess, doRedirect, createAction],
  );
  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={<RechargeAddressAddBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={submit}
      cancel={doRedirect}
    >
      <PageCard>
        <CreateRechargeAddressForm
          data-test={dataTest && `${dataTest}-form`}
          onSubmit={doCreate}
          onReset={doRedirect}
          submitCallback={updateOnSubmit}
        />
      </PageCard>
    </PageContainer>
  );
};

export default RechargeAddressAddPage;
