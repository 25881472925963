import type { UseAppLoadableData } from '@/app/hooks';
import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectSchedule } from '@/features/collectable/actions';
import { makeSelectCollectSchedule } from '@/features/collectable/selectors';
import type { CollectSchedule } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export type UseCollectSchedule = UseAppLoadableData<CollectSchedule, LoadingStateWithDirty<CollectSchedule>>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<CollectSchedule>, Parameters<typeof fetchCollectSchedule>[0]> =>
  fetchCollectSchedule({ force });

const selectFetching = makeSelectPending(fetchCollectSchedule);
const selectData = makeSelectCollectSchedule();

export default function useCollectSchedule(): UseCollectSchedule {
  return useAppLoadableData(fetchFactory, selectData, selectFetching);
}
