import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshSettlement } from '@/features/settlements/components';
import { useSettlement } from '@/features/settlements/hooks';
import type { Settlement } from '@/features/settlements/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withSettlementDataLoading =
  <
    Original extends { data: Settlement } & TestableProps,
    Wrapper extends Omit<Original, 'data'> & { settlementId: string } = Omit<Original, 'data'> & {
      settlementId: string;
    },
  >(
    Component: React.ComponentType<Original>,
  ): React.FC<Wrapper> =>
  (props) => {
    const dataTest = props['data-test'];
    const data = useSettlement(props.settlementId);
    return withCardDataLoading<Settlement, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageSettlements.SETTLEMENT_VIEW_COMPONENTS_SETTLEMENT_CARD_TITLE}
          values={{ id: props.settlementId }}
        />
      ),
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshSettlement
              settlementId={props.settlementId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withSettlementDataLoading;
