import { cx, css } from '@emotion/css';
import { List, Space } from 'antd';
import React from 'react';

import { ReadonlyComponent } from '@/components';
import BlockchainLabel from '@/features/dictionary/blockchain/components/BlockchainLabel';
import { withAssetsDataLoading } from '@/features/dictionary/blockchain/hocs';

import AssetCode from '../AssetCode';
import AssetIcon from '../AssetIcon';
import AssetNetworkMark from '../AssetNetworkMark';
import AssetTooltip from '../AssetTooltip';

import type { AssetLabelCodeOnlyProps, AssetLabelFilledProps, AssetLabelProps } from './types';

const styles = {
  assetsMeta: css`
    align-items: center;
  `,
  assetsWrapper: css`
    display: inline-flex;
    overflow: inherit;

    > div {
      display: inline-flex;
      overflow: inherit;
    }
  `,
};

const AssetLabelFilled: React.FC<AssetLabelFilledProps> = ({
  'data-test': dataTest,
  value,
  className,
  style,
  mode = 'long',
  longLabels,
  withBlockchainMark,
  withNetworkMark,
  copyable = true,
  ellipsis,
}) => {
  switch (mode) {
    case 'long':
      return (
        <List.Item.Meta
          style={style}
          className={cx(styles.assetsMeta, className)}
          avatar={
            <AssetTooltip type={value.code}>
              <AssetIcon style={{ fontSize: 32 }} type={value.code} />
            </AssetTooltip>
          }
          description={
            <Space>
              {withBlockchainMark && <BlockchainLabel assetId={value.code} mode="icon" />}
              <ReadonlyComponent copyable={false} value={value.name} />
            </Space>
          }
          title={
            <span data-test={dataTest}>
              <AssetTooltip type={value.code}>
                <AssetCode value={value.code} />
              </AssetTooltip>
              {withNetworkMark && (
                <>
                  &nbsp;
                  <AssetNetworkMark value={value.code} />
                </>
              )}
              {longLabels && (
                <>
                  &nbsp;
                  {longLabels}
                </>
              )}
            </span>
          }
        />
      );
    case 'medium':
      return (
        <ReadonlyComponent
          style={style}
          className={className}
          value={
            <>
              {withBlockchainMark && (
                <>
                  <BlockchainLabel assetId={value.code} mode="icon" />
                  &nbsp;
                </>
              )}
              <AssetTooltip type={value.code}>
                <AssetIcon type={value.code} />
              </AssetTooltip>
              &nbsp;
              <AssetTooltip type={value.code}>
                <AssetCode value={value.code} />
              </AssetTooltip>
              &nbsp;
              {`(${value.name})`}
              {withNetworkMark && (
                <>
                  &nbsp;
                  <AssetNetworkMark value={value.code} />
                </>
              )}
            </>
          }
          data-test={dataTest}
          ellipsis={ellipsis}
          copyable={copyable && { text: value.code }}
        />
      );
    case 'medium-no-ico':
      return (
        <ReadonlyComponent
          style={style}
          className={className}
          value={
            <>
              {withBlockchainMark && (
                <>
                  <BlockchainLabel assetId={value.code} mode="icon" />
                  &nbsp;
                </>
              )}
              <AssetTooltip type={value.code}>
                <AssetCode value={value.code} />
              </AssetTooltip>
              &nbsp;
              {`(${value.name})`}
              {withNetworkMark && (
                <>
                  &nbsp;
                  <AssetNetworkMark value={value.code} />
                </>
              )}
            </>
          }
          ellipsis={ellipsis}
          copyable={copyable && { text: value.code }}
          data-test={dataTest}
        />
      );
    case 'short':
      return (
        <ReadonlyComponent
          data-test={dataTest}
          style={{ color: 'inherit', ...style }}
          value={
            <>
              {withBlockchainMark && (
                <>
                  <BlockchainLabel assetId={value.code} mode="icon" />
                  &nbsp;
                </>
              )}
              <AssetTooltip type={value.code}>
                <AssetCode value={value.code} />
              </AssetTooltip>
            </>
          }
          copyable={false}
          ellipsis={ellipsis}
        />
      );
    default:
      return null;
  }
};

const AssetLabelCodeOnly: React.FC<Omit<AssetLabelCodeOnlyProps, 'data'>> = withAssetsDataLoading(
  'all',
  undefined,
  styles.assetsWrapper,
)(({ value, data, ...props }: AssetLabelCodeOnlyProps) => {
  const asset = data.find(({ code }) => code === value);
  return asset ? (
    <AssetLabelFilled value={{ code: asset.code || value, name: asset.name || value }} {...props} />
  ) : null;
});

const AssetLabel: React.FC<AssetLabelProps> = ({ value, ...props }) =>
  typeof value === 'string' ? (
    <AssetLabelCodeOnly value={value} {...props} />
  ) : (
    <AssetLabelFilled value={value} {...props} />
  );

const AssetLabelMemo = React.memo(AssetLabel);

export default AssetLabelMemo;
