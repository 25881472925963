import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { persistPreferences } from '@/features/user/actions';
import type { UserPreferences } from '@/features/user/types';
import type { HookAction } from '@/infrastructure/model';

import usePreferences from './usePreferences';

type PersistUnavailabilityReason = 'no-data';

export interface UsePreferencesActions {
  persist: HookAction<[Partial<UserPreferences>], UserPreferences, PersistUnavailabilityReason>;
}

export default function usePreferencesActions(): UsePreferencesActions {
  const { data } = usePreferences();
  const { withExtractDataDispatch } = useAppDispatch();

  const isPersisting = useActionPending(persistPreferences);
  const persistAction: UsePreferencesActions['persist']['act'] = useMemo(
    () => withExtractDataDispatch(persistPreferences),
    [withExtractDataDispatch],
  );

  const unavailabilityReason = !data.data ? 'no-data' : undefined;

  const persist: UsePreferencesActions['persist'] = {
    act: persistAction,
    inAction: isPersisting,
    unavailabilityReason,
    available: !unavailabilityReason,
  };

  return { persist };
}
