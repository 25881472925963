import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import SettlementPeriodLabel from '../SettlementPeriodLabel';

import type { SettlementPeriodItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SettlementPeriodItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  messages,
  rules: extraRules = [],
  ItemProps = {},
}: SettlementPeriodItemProps<Values>) => {
  const rules = useMemo(
    () => [
      ...extraRules,
      ...(!readonly && required
        ? [
            {
              required: true,
              message: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_SETTLEMENT_PERIOD_ITEM_REQUIRED} />,
            },
          ]
        : []),
    ],
    [extraRules, required, readonly],
  );

  return (
    <Form.Item
      label={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_SETTLEMENT_PERIOD_ITEM_LABEL} />}
      rules={rules}
      required={!readonly && required}
      {...ItemProps}
      name={name}
    >
      <Select
        disabled={readonly}
        data-test={dataTest}
        options={useMemo(
          () =>
            enumValues(WithdrawalPeriodAPIModel)
              .filter((code) => code !== WithdrawalPeriodAPIModel.NotSet)
              .map((code) => ({
                value: code,
                label: <SettlementPeriodLabel data-test={dataTest && `${dataTest}-${code}`} value={code} />,
              })),
          [dataTest],
        )}
        placeholder={
          !readonly
            ? (messages?.placeholder ?? (
                <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_SETTLEMENT_PERIOD_ITEM_PLACEHOLDER} />
              ))
            : undefined
        }
      />
    </Form.Item>
  );
};

const SettlementPeriodItemMemo = React.memo(SettlementPeriodItem) as typeof SettlementPeriodItem;

export default SettlementPeriodItemMemo;
