import Icon, { LinkOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import CoinbaseIcon from '@/assets/icons/providers/coinbase.svg?react';
import MetamaskIcon from '@/assets/icons/providers/metamask.svg?react';
import WalletConnectIcon from '@/assets/icons/providers/walletconnect.svg?react';

import type { Web3ProviderIconProps } from './types';
import type { CustomIconComponentProps } from '@ant-design/icons/es/components/Icon';

const mapping: Partial<Record<string, typeof CoinbaseIcon>> = {
  metamask: MetamaskIcon,
  coinbase: CoinbaseIcon,
  walletconnect: WalletConnectIcon,
};

const Web3ProviderIcon: React.FC<Web3ProviderIconProps> = ({ 'data-test': dataTest, value, className, style }) => {
  const icon = useMemo(() => {
    const ico = Object.entries(mapping)
      .filter(([type]) => value.toLowerCase().includes(type))
      .map(([, mappingIcon]) => mappingIcon)[0];
    // typecast from antd official docs
    return ico ?? (LinkOutlined as React.ForwardRefExoticComponent<CustomIconComponentProps>);
  }, [value]);
  return <Icon component={icon} className={className} style={style} data-test={dataTest} />;
};

const Web3ProviderIconMemo = React.memo(Web3ProviderIcon);

export default Web3ProviderIconMemo;
