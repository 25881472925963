import React from 'react';

import { PaymentView } from '@/features/payments/components';

import { withPaymentDataLoading } from './hocs';

const PaymentCard = withPaymentDataLoading((props) => <PaymentView {...props} columns={1} />);

const PaymentCardMemo = React.memo(PaymentCard) as typeof PaymentCard;

export default PaymentCardMemo;
