import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import SettlementPeriodItem from '@/features/settlements/components/SettlementPeriodItem';
import type { SettlementScheduleUpdate } from '@/features/settlements/types';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import SettlementScheduleAmountInputItem from '../SettlementScheduleAmountInputItem';

import type { UpdateSettlementScheduleFormProps } from './types';
import type { FormInstance } from 'antd/es/form';

const requiredFields = [
  nameof<SettlementScheduleUpdate>('period'),
  nameof<SettlementScheduleUpdate>('unifiedMinAmount'),
];

const footerMessages = {
  submit: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_SUBMIT} tagName="span" />,
};

const UpdateSettlementScheduleForm: React.FC<UpdateSettlementScheduleFormProps> = ({
  'data-test': dataTest,
  values,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { form, withResetForm } = useForm<SettlementScheduleUpdate>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(() => withSubmitting(onSubmit), [onSubmit, withSubmitting]);
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_ERROR_COMMON} />;
  }, [error]);

  const isDataUpdated = useCallback(
    ({ getFieldValue }: FormInstance<SettlementScheduleUpdate>) =>
      getFieldValue(nameof<SettlementScheduleUpdate>('period')) !== values?.period
      || getFieldValue(nameof<SettlementScheduleUpdate>('unifiedMinAmount')) !== values?.unifiedMinAmount,
    [values?.period, values?.unifiedMinAmount],
  );

  const initialValues = useMemo(
    () => ({ period: WithdrawalPeriodAPIModel.FirstDayOfMonth, unifiedMinAmount: 100, ...values }),
    [values],
  );

  return (
    <Form<SettlementScheduleUpdate>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
      <SettlementPeriodItem<SettlementScheduleUpdate>
        data-test={dataTest && `${dataTest}-period`}
        name={nameof<SettlementScheduleUpdate>('period')}
        required
      />
      <SettlementScheduleAmountInputItem<SettlementScheduleUpdate>
        data-test={dataTest && `${dataTest}-amount`}
        name={nameof<SettlementScheduleUpdate>('unifiedMinAmount')}
      />
      <FormCompletenessItem<SettlementScheduleUpdate>
        requiredFields={requiredFields}
        onChange={setFormComplete}
        checkIsComplete={isDataUpdated}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-submit`}
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
        messages={footerMessages}
      />
    </Form>
  );
};

const UpdateSettlementScheduleFormMemo = React.memo(UpdateSettlementScheduleForm);

export default UpdateSettlementScheduleFormMemo;
