import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import { collectTasksLink } from '@/pages/collectable/routes';

import type { CollectTasksLinkProps } from './types';

const CollectTasksLink: React.FC<CollectTasksLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={collectTasksLink()}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageCollectable.TASKS_COMPONENTS_LINK_TITLE} />}
  />
);

const CollectTasksLinkMemo = React.memo(CollectTasksLink);

export default CollectTasksLinkMemo;
