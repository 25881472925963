import type { EmptyObject } from '@/infrastructure/utils/ts';

import type {
  MultipleStateUpdateAction,
  SingleStateDirtyMarkUpdateAction,
  SingleStateMultipleDirtyMarksUpdateAction,
  SingleStateRemoveAction,
  SingleStateUpdateAction,
} from './actions';
import type { SingleState } from './types';
import type { Draft } from 'immer';

interface SingleMarkDirtyReducer {
  <Global extends EmptyObject = EmptyObject, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
  ): (state: Draft<Global>, action: SingleStateDirtyMarkUpdateAction<Value>) => Draft<Global>;

  <Global extends EmptyObject = EmptyObject, Id = unknown, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper: (id: Id) => Key,
  ): (state: Draft<Global>, action: SingleStateDirtyMarkUpdateAction<Id>) => Draft<Global>;
}

export const singleMarkDirtyReducer: SingleMarkDirtyReducer =
  <Global extends EmptyObject = EmptyObject, Id = string, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper?: (id: Id) => Key,
  ) =>
  (state: Global, { payload: key }: SingleStateDirtyMarkUpdateAction<Id>) => {
    const id = typeof key === 'string' ? (key as unknown as Key) : mapper!(key);
    const singleValue = get(state)[id];
    return !singleValue?.isDirty ? set(state, { ...get(state), [id]: { ...singleValue, isDirty: true } }) : state;
  };

interface SingleMarkMultipleDirtyReducer {
  <Global extends EmptyObject = EmptyObject, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
  ): (state: Draft<Global>, action: SingleStateMultipleDirtyMarksUpdateAction<Value>) => Draft<Global>;

  <Global extends EmptyObject = EmptyObject, Id = unknown, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper: (id: Id) => Key,
  ): (state: Draft<Global>, action: SingleStateMultipleDirtyMarksUpdateAction<Id>) => Draft<Global>;
}

export const singleMarkMultipleDirtyReducer: SingleMarkMultipleDirtyReducer =
  <Global extends EmptyObject = EmptyObject, Id = string, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper?: (id: Id) => Key,
  ) =>
  (state: Global, { payload: keys }: SingleStateMultipleDirtyMarksUpdateAction<Id>) => {
    const ids = keys.map((key) => (typeof key === 'string' ? (key as unknown as Key) : mapper!(key)));
    const singleState = get(state);
    const isSomeNotDirty = !!ids.find((id) => !singleState[id]?.isDirty);
    return isSomeNotDirty
      ? set(
          state,
          ids.reduce(
            (result, id) => ({
              ...result,
              [id]: result[id]?.isDirty ? result[id] : { ...result[id], isDirty: true },
            }),
            singleState,
          ),
        )
      : state;
  };

interface SingleRemoveReducer {
  <Global extends EmptyObject = EmptyObject, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
  ): (state: Draft<Global>, action: SingleStateRemoveAction<Key>) => Draft<Global>;

  <Global extends EmptyObject = EmptyObject, Id = unknown, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper: (id: Id) => Key,
  ): (state: Draft<Global>, action: SingleStateRemoveAction<Id>) => Draft<Global>;
}

export const singleRemoveReducer: SingleRemoveReducer =
  <Global extends EmptyObject = EmptyObject, Id = string, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper?: (id: Id) => Key,
  ) =>
  (state: Global, { payload: key }: SingleStateRemoveAction<Id>) => {
    const id = typeof key === 'string' ? (key as unknown as Key) : mapper!(key);
    const singleState = get(state);
    const { [id]: singleValue, ...newState } = singleState;
    return singleValue ? set(state, newState as typeof singleState) : state;
  };

interface SingleStoreReducer {
  <Global extends EmptyObject = EmptyObject, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
  ): (state: Draft<Global>, { payload }: SingleStateUpdateAction<Value>) => Draft<Global>;

  <Global extends EmptyObject = EmptyObject, Value = unknown, Id = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper: (id: Id) => Key,
  ): (state: Draft<Global>, { payload }: SingleStateUpdateAction<Value, Id>) => Draft<Global>;
}

export const singleStoreReducer: SingleStoreReducer =
  <Global extends EmptyObject = EmptyObject, Id = string, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (global: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper?: (id: Id) => Key,
  ) =>
  (state: Global, { payload: { id, data } }: SingleStateUpdateAction<Value, Id>) =>
    set(state, { ...get(state), [typeof id === 'string' ? id : mapper!(id)]: { ...data, isDirty: false } });

interface MultipleStoreReducer {
  <Global extends EmptyObject = EmptyObject, Value = unknown, Key extends string = string>(
    get: (global: Draft<Global>) => SingleState<Value, Key>,
    set: (state: Draft<Global>, newSingleState: SingleState<Value, Key>) => Draft<Global>,
  ): (state: Draft<Global>, { payload }: MultipleStateUpdateAction<Value, Key>) => Draft<Global>;

  <Global extends EmptyObject = EmptyObject, Value = unknown, Id = unknown, Key extends string = string>(
    get: (global: Draft<Global>) => SingleState<Value, Key>,
    set: (state: Draft<Global>, newSingleState: SingleState<Value, Key>) => Draft<Global>,
    mapper: (id: Id) => Key,
  ): (state: Draft<Global>, { payload }: MultipleStateUpdateAction<Value, Id>) => Draft<Global>;
}

export const multipleStoreReducer: MultipleStoreReducer =
  <Global extends EmptyObject = EmptyObject, Id = string, Value = unknown, Key extends string = string>(
    get: (global: Global) => SingleState<Value, Key>,
    set: (state: Global, newSingleState: SingleState<Value, Key>) => Global,
    mapper?: (id: Id) => Key,
  ) =>
  (state: Global, { payload }: MultipleStateUpdateAction<Value, Id>) =>
    set(state, {
      ...payload.reduce(
        (result, { id, data }) => ({
          ...result,
          [typeof id === 'string' ? id : mapper!(id)]: { ...data, isDirty: false },
        }),
        get(state),
      ),
    });

export const createSingleReducers = <
  Global extends EmptyObject,
  Value,
  Type extends string,
  Id = string,
  Key extends string = string,
>(
  type: Type,
  get: (global: Draft<Global>) => SingleState<Value, Key>,
  set: (state: Draft<Global>, newSingleState: SingleState<Value, Key>) => Draft<Global>,
  mapper?: (id: Id) => Key,
) => {
  const markDirtyName = `mark${type}DirtyReducer` as const;
  const markMultipleDirtyName = `markMultiple${type}DirtyReducer` as const;
  const storeName = `store${type}Reducer` as const;
  const storeMultipleName = `storeMultiple${type}Reducer` as const;
  const removeName = `storeRemove${type}Reducer` as const;

  const markDirty = singleMarkDirtyReducer(get, set, mapper!);
  const markMultipleDirty = singleMarkMultipleDirtyReducer(get, set, mapper!);
  const store = singleStoreReducer(get, set, mapper!);
  const storeMultiple = multipleStoreReducer(get, set, mapper!);
  const remove = singleRemoveReducer(get, set, mapper!);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [markMultipleDirtyName]: markMultipleDirty,
    [storeName]: store,
    [storeMultipleName]: storeMultiple,
    [removeName]: remove,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof markMultipleDirtyName, typeof markMultipleDirty> &
    Record<typeof storeName, typeof store> &
    Record<typeof storeMultipleName, typeof storeMultiple> &
    Record<typeof removeName, typeof remove>;
};
