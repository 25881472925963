import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureMerchantWalletBalance } from '@/generated/i18n/i18n';

const MerchantWalletRunningBalancesReportTitle: React.FC = () => (
  <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_REPORT_TITLE} />
);

export default MerchantWalletRunningBalancesReportTitle;
