import React from 'react';

import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';

import NetworkLabel from '../NetworkLabel';

import type { SelectedNetworkLabelProps } from './types';

const SelectedNetworkLabel: React.FC<SelectedNetworkLabelProps> = (props) => {
  const { network } = useSelectedNetworkActions();
  return <NetworkLabel {...props} value={network} />;
};

const SelectedNetworkLabelMemo = React.memo(SelectedNetworkLabel);

export default SelectedNetworkLabelMemo;
