import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export const linkSupport: Record<MerchantWalletTransferKindAPIModel, boolean> = {
  [MerchantWalletTransferKindAPIModel.PaymentTransaction]: true,
  [MerchantWalletTransferKindAPIModel.SubscriptionCharge]: true,
  [MerchantWalletTransferKindAPIModel.DonationTransaction]: true,
  [MerchantWalletTransferKindAPIModel.RechargeTransaction]: true,
  [MerchantWalletTransferKindAPIModel.Withdrawal]: true,
  [MerchantWalletTransferKindAPIModel.PushCollect]: true,
  [MerchantWalletTransferKindAPIModel.Payout]: true,
  [MerchantWalletTransferKindAPIModel.Unknown]: false,
  [MerchantWalletTransferKindAPIModel.PartnerFee]: false,
  [MerchantWalletTransferKindAPIModel.ProcessingFee]: false,
};
