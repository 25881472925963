import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSubscriptionCharge } from '@/features/subscription-charges/actions';
import { makeSelectSubscriptionCharge } from '@/features/subscription-charges/selectors';
import type { SubscriptionCharge } from '@/features/subscription-charges/types';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseSubscriptionCharge = UseAppSingleData<SubscriptionCharge>;

const noData = storedDataError<SubscriptionCharge>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<SubscriptionCharge>, Parameters<typeof fetchSubscriptionCharge>[0]> =>
  fetchSubscriptionCharge({ id, force });

export default function useSubscriptionCharge(id: string | undefined): UseSubscriptionCharge {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSubscriptionCharge, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectSubscriptionCharge, noData, id, dataFetchingSelector);
}
