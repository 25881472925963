import React from 'react';

import { DocumentationLink, FormattedMessage } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { PaymentsDocumentationLinkProps } from './types';

const PaymentsDocumentationLinkRaw: React.FC<PaymentsDocumentationLinkProps> = ({
  mode,
  title,
  'data-test': dataTest,
}) => (
  <DocumentationLink
    mode={mode}
    title={title ?? <FormattedMessage id={I18nFeaturePayments.COMPONENTS_DOCUMENTATION_LINK_TITLE} />}
    data-test={dataTest}
    path="/general/payment-button"
  />
);

const PaymentsDocumentationLink = React.memo(PaymentsDocumentationLinkRaw);

export default PaymentsDocumentationLink;
