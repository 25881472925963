import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutPendingAlertTitleProps } from './types';

const PayoutPendingAlertTitle: React.FC<PayoutPendingAlertTitleProps> = ({ payload }) => (
  <FormattedMessage
    id={I18nFeaturePayouts.COMPONENTS_PENDING_ALERT_MESSAGE}
    values={{ count: Array.isArray(payload) ? payload.length : 0 }}
  />
);

const PayoutPendingAlertTitleMemo = React.memo(PayoutPendingAlertTitle);

export default PayoutPendingAlertTitleMemo;
