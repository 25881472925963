import { createAction } from '@reduxjs/toolkit';

import type { CommonLoadingState } from '@/infrastructure/model';
import { createSingleActions } from '@/infrastructure/model/single/actions';

import type { ListData, UpdateListParametersPayload } from './types';

export interface ListDataUpdateAction<Value> {
  payload: CommonLoadingState<ListData<Value>>;
}

export interface ListParametersUpdateAction<Filter, SortBy extends string> {
  payload: UpdateListParametersPayload<Filter, SortBy>;
}

// TODO: If the Value is object, the default Type should be name of this object - but how to realize it in TS?
export const createListActions = <Value, Type extends string, Filter, SortBy extends string>(
  namespace: string,
  type: Type,
) => {
  const markDirtyName = `mark${type}ListDirty` as const;
  const markTotalDirtyName = `mark${type}ListTotalDirty` as const;
  const storeDataName = `store${type}ListData` as const;
  const storeListParametersName = `store${type}ListParameters` as const;

  const markDirty = createAction(`${namespace}/${markDirtyName}`);
  const markTotalDirty = createAction(`${namespace}/${markTotalDirtyName}`);
  const storeData = createAction<ListDataUpdateAction<Value>['payload']>(`${namespace}/${storeDataName}`);
  const storeListParameters = createAction<ListParametersUpdateAction<Filter, SortBy>['payload']>(
    `${namespace}/${storeListParametersName}`,
  );

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [markTotalDirtyName]: markTotalDirty,
    [storeDataName]: storeData,
    [storeListParametersName]: storeListParameters,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof markTotalDirtyName, typeof markTotalDirty> &
    Record<typeof storeDataName, typeof storeData> &
    Record<typeof storeListParametersName, typeof storeListParameters>;
};

export const createNormalizedListActions = <Value, Type extends string, Filter, SortBy extends string, Id = string>(
  namespace: string,
  type: Type,
) => {
  const listActions = createListActions<Value, Type, Filter, SortBy>(namespace, type);
  const singleActions = createSingleActions<Value, Type, Id>(namespace, type);

  return {
    ...listActions,
    ...singleActions,
  };
};

export interface NestedListDataMarkDirtyAction<ParentId> {
  payload: ParentId;
}

export interface NestedListDataUpdateAction<ParentId, Value> {
  payload: { parentId: ParentId; data: CommonLoadingState<ListData<Value>> };
}

export interface NestedListParametersUpdateAction<ParentId, Filter, SortBy extends string> {
  payload: { parentId: ParentId; parameters: UpdateListParametersPayload<Filter, SortBy> };
}

export const createNestedListParametersActions = <
  Type extends string,
  Filter,
  SortBy extends string,
  ParentId = string,
>(
  namespace: string,
  type: Type,
) => {
  const storeListParametersName = `store${type}ListParameters` as const;
  const storeListParameters = createAction<NestedListParametersUpdateAction<ParentId, Filter, SortBy>['payload']>(
    `${namespace}/${storeListParametersName}`,
  );
  // FIXME: redefine the type without the cast
  return {
    [storeListParametersName]: storeListParameters,
  } as Record<typeof storeListParametersName, typeof storeListParameters>;
};

export const createNestedListActions = <Value, Type extends string, Filter, SortBy extends string, ParentId = string>(
  namespace: string,
  type: Type,
) => {
  const markDirtyName = `mark${type}ListDirty` as const;
  const markTotalDirtyName = `mark${type}ListTotalDirty` as const;
  const storeDataName = `store${type}ListData` as const;
  const storeListParametersName = `store${type}ListParameters` as const;

  const markDirty = createAction<NestedListDataMarkDirtyAction<ParentId>['payload']>(`${namespace}/${markDirtyName}`);
  const markTotalDirty = createAction<NestedListDataMarkDirtyAction<ParentId>['payload']>(
    `${namespace}/${markTotalDirtyName}`,
  );
  const storeData = createAction<NestedListDataUpdateAction<ParentId, Value>['payload']>(
    `${namespace}/${storeDataName}`,
  );
  const storeListParameters = createAction<NestedListParametersUpdateAction<ParentId, Filter, SortBy>['payload']>(
    `${namespace}/${storeListParametersName}`,
  );

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [markTotalDirtyName]: markTotalDirty,
    [storeDataName]: storeData,
    [storeListParametersName]: storeListParameters,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof markTotalDirtyName, typeof markTotalDirty> &
    Record<typeof storeDataName, typeof storeData> &
    Record<typeof storeListParametersName, typeof storeListParameters>;
};
