import { UserOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsProfileLink } from '@/pages/settings/routes';

import type { ProfileLinkProps } from './types';

const ProfileLinkLink: React.FC<ProfileLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    mode={mode}
    to={settingsProfileLink()}
    icon={icon ?? <UserOutlined />}
    data-test={dataTest}
    title={title ?? <FormattedMessage id={I18nPageSettings.PROFILE_LINK} />}
  />
);

const ProfileLinkLinkMemo = React.memo(ProfileLinkLink);

export default ProfileLinkLinkMemo;
