import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useAsset } from '@/features/dictionary/blockchain/hooks';
import { useActionPending } from '@/features/global/hooks';
import { useMerchantWalletDeployment } from '@/features/merchant-wallets/hooks';
import { settleNow } from '@/features/settlements/actions';
import { makeSelectPendingIntents } from '@/features/settlements/selectors';
import type { SettlementIntent } from '@/features/settlements/types';
import { useActualBalances } from '@/features/statistics/hooks';
import type { HookAction } from '@/infrastructure/model';

type SettlementUnavailabilityReason = 'no-data' | 'in-progress' | 'no-wallet' | 'empty-balance';

export interface UseSettlementScheduleActionsType {
  settleNow: HookAction<[], SettlementIntent, SettlementUnavailabilityReason>;
}

const selectPendingIntents = makeSelectPendingIntents();

export default function useSettlementScheduleActions(assetId: string): UseSettlementScheduleActionsType {
  // TODO: selectPendingIntents should be hook?
  const pendingIntents = useAppSelector(selectPendingIntents);
  const { data: asset } = useAsset(assetId);
  const { data: balances, loading: balancesLoading } = useActualBalances();
  const balance = useMemo(() => balances.data?.available.find(({ asset }) => asset === assetId), [assetId, balances]);
  const { data: wallet, loading: walletLoading } = useMerchantWalletDeployment(asset.data?.blockchain);
  const { withExtractDataDispatch } = useAppDispatch();

  const settling = useActionPending(settleNow, assetId);
  const settleUnavailabilityReason: SettlementUnavailabilityReason | undefined = useMemo(() => {
    if (wallet.isDirty || walletLoading || balances.isDirty || balancesLoading) {
      return 'no-data';
    }
    if (balance && !balance.value.isGreaterThan(0)) {
      return 'empty-balance';
    }
    if (pendingIntents.find((pending) => pending.assetId === assetId)) {
      return 'in-progress';
    }
    if (wallet.data && !wallet.data.deployed) {
      return 'no-wallet';
    }
    return undefined;
  }, [assetId, balance, balances.isDirty, balancesLoading, pendingIntents, wallet.data, wallet.isDirty, walletLoading]);
  const settleNowAction = {
    act: useCallback(() => withExtractDataDispatch(settleNow)({ asset: assetId }), [withExtractDataDispatch, assetId]),
    inAction: settling,
    available: !settleUnavailabilityReason,
    unavailabilityReason: settleUnavailabilityReason,
  };

  return { settleNow: settleNowAction };
}
