import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import BrandingSummaryBreadcrumb from '@/pages/settings/branding/summary/components/BrandingSummaryBreadcrumb';

import BrandingProfilesLink from '../BrandingProfilesLink';

import type { BrandingProfilesBreadcrumbProps } from './types';

const BrandingProfilesBreadcrumb: React.FC<BrandingProfilesBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <BrandingSummaryBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.BRANDING_PROFILES_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <BrandingProfilesLink
            title={<FormattedMessage id={I18nPageSettings.BRANDING_PROFILES_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="text"
          />
        ),
      },
      ...items,
    ]}
  />
);

const BrandingProfilesBreadcrumbMemo = React.memo(BrandingProfilesBreadcrumb);

export default BrandingProfilesBreadcrumbMemo;
