export const NAMESPACE = 'email';

export interface EmailConfirmationState {
  email: string;
  code: string;
  auth0Token: string;
  expiresAt: Date;
}

export interface EmailCodeState {
  data?: {
    sentAt: Date;
    retryableAfter: Date;
    expiresAt: Date;
  };
  error?: string;
}

export interface EmailState {
  codeState: Partial<Record<string, EmailCodeState>>;
  confirmationState: Partial<Record<string, EmailConfirmationState>>;
}
