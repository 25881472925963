import { SubscriptionView, type SubscriptionViewProps } from '@/features/subscriptions/components';
import type { Subscription } from '@/features/subscriptions/types';
import SubscriptionPlanLink from '@/pages/subscriptions/plan-view/components/SubscriptionPlanLink';

import { withSubscriptionDataLoading } from './hocs';

export default withSubscriptionDataLoading<Omit<SubscriptionViewProps, 'plan' | 'data'> & { data: Subscription }>(
  ({ data, ...props }) => (
    <SubscriptionView {...props} data={data} columns={1} planLink="link" SubscriptionPlanLink={SubscriptionPlanLink} />
  ),
);
