import { useMemo } from 'react';

import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { notEmpty } from '@/infrastructure/utils/ts';

import useMerchantGasWallet from '../useMerchantGasWallet';

import type { GasWalletState, GasWalletStateIssue } from './types';

const merchantGasWalletLoadingState: GasWalletState = { ready: false, loading: true, issues: [] };
const merchantGasWalletReadyLoadingState: GasWalletState = { ready: true, loading: true, issues: [] };
const merchantGasWalletReadyLoadedState: GasWalletState = { ready: true, loading: false, issues: [] };
const merchantGasWalletAbsentState: GasWalletState = { ready: false, loading: true, issues: ['presence'] };

export default function useMerchantGasWalletState(bt: BlockchainTypeAPIModel | undefined): GasWalletState {
  const { data, loading } = useMerchantGasWallet(bt);
  return useMemo(() => {
    if (!data.data) {
      return data.isDirty || loading ? merchantGasWalletLoadingState : merchantGasWalletAbsentState;
    }

    const refunding: GasWalletStateIssue | undefined = data.data.isAnyRefunding ? 'refunding' : undefined;
    const allOutOfService: GasWalletStateIssue | undefined = data.data.isAllOutOfService ? 'out-of-service' : undefined;
    const derivedBalance: GasWalletStateIssue | undefined = data.data.isDerivedBalanceEnough
      ? 'derived-balance'
      : undefined;

    if (refunding || allOutOfService || derivedBalance) {
      return {
        ready: false,
        loading: data.isDirty || loading,
        issues: [refunding, allOutOfService, derivedBalance].filter(notEmpty),
      };
    }
    return data.isDirty || loading ? merchantGasWalletReadyLoadingState : merchantGasWalletReadyLoadedState;
  }, [data.data, data.isDirty, loading]);
}
