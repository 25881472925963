import { withLogErrorPure } from '@/infrastructure/utils/functions';

export interface JsonCache<T> {
  save: (data: T) => void;
  safeSave: (data: T) => void;
  read: () => T | undefined;
  safeRead: () => T | undefined;
  clear: () => void;
  safeClear: () => void;
}

const localCache: Record<string, unknown> = {};

const modeHolder: { mode: 'device' | 'mem' } = { mode: 'device' };

export const setCacheMode = (mode: 'device' | 'mem'): void => {
  modeHolder.mode = mode;
};

const save = <T>(key: string, type: 'persistent' | 'temporal', data: T): void => {
  if (modeHolder.mode === 'device') {
    (type === 'persistent' ? localStorage : sessionStorage).setItem(key, JSON.stringify(data));
  } else {
    localCache[key] = data;
  }
};
const read = <T>(
  key: string,
  type: 'persistent' | 'temporal',
  valueParsers?: Partial<Record<string, <T, R>(value: T) => R>>,
): T | undefined => {
  if (modeHolder.mode === 'device') {
    const lsItem = (type === 'persistent' ? localStorage : sessionStorage).getItem(key);
    // prettier-ignore
    const reviver: Parameters<typeof JSON.parse>[1] = valueParsers
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      ? (key, value) => valueParsers[key] ? valueParsers[key](value) : value
      : undefined;
    return lsItem ? (JSON.parse(lsItem, reviver) as T) : undefined;
  }
  return localCache[key] ? (localCache[key] as T) : undefined;
};

const clear = (key: string, type: 'persistent' | 'temporal'): void => {
  if (modeHolder.mode === 'device') {
    (type === 'persistent' ? localStorage : sessionStorage).removeItem(key);
  } else {
    delete localCache[key];
  }
};

const createCache = <T>(
  key: string,
  type: 'persistent' | 'temporal' = 'persistent',
  valueParsers?: Partial<Record<string, <T, R>(value: T) => R>>,
): JsonCache<T> => ({
  save: (data: T): void => save(key, type, data),
  safeSave: (data: T): void => withLogErrorPure(save)(key, type, data),
  read: (): T | undefined => read(key, type, valueParsers),
  safeRead: (): T | undefined => withLogErrorPure(read, () => undefined)(key, type, valueParsers),
  clear: (): void => clear(key, type),
  safeClear: (): void => withLogErrorPure(clear)(key, type),
});

export default createCache;
