import { useCallback } from 'react';

import useStateMountSafe from '@/hooks/useStateMountSafe';
import type { Func } from '@/infrastructure/utils/ts';

export interface UseFormVisibleType {
  visible: boolean;
  show: () => void;
  hide: () => void;
  withFormHide: <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>;
}

export default function useFormVisible(initialState: boolean | (() => boolean)): UseFormVisibleType {
  const [visible, setFormVisible] = useStateMountSafe<boolean>(initialState);
  const withFormHide = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        const value = await func(...params);
        setFormVisible(false);
        return value;
      },
    [setFormVisible],
  );
  const hide = useCallback(() => setFormVisible(false), [setFormVisible]);
  const show = useCallback(() => setFormVisible(true), [setFormVisible]);

  return { visible, show, hide, withFormHide };
}
