import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeRechargeTransactionListParameters } from '@/features/recharges/actions';
import type { RechargeTransaction, RechargeTransactionFilterPredicate } from '@/features/recharges/types';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useRechargeTransactionList from './useRechargeTransactionList';

export type UseRechargeTransactionListView = UseAppListDataView<
  RechargeTransaction,
  RechargeTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

export default function useRechargeTransactionListView(): UseRechargeTransactionListView {
  const state = useRechargeTransactionList();
  return useAppListDataView(state, storeRechargeTransactionListParameters);
}
