import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { DonationsDocumentationBanner, DonationTransactionFilterForm } from '@/features/donations/components';
import { CollectableScheduleCard } from '@/pages/collectable/components';
import DonationTransactionViewLink from '@/pages/donations/transaction-view/components/DonationTransactionLink';

import { DonationTransactionsBreadcrumb, DonationTransactionsCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'transactions';

const DonationTransactionsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<DonationTransactionsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test="kyb-banner" PageProps={{ pageSize: 'big' }} />
    <DonationsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <CollectableScheduleCard data-test={dataTest && `${dataTest}-schedule`} />
    <DonationTransactionsCard
      data-test={dataTest}
      DonationTransactionLink={DonationTransactionViewLink}
      FilterForm={DonationTransactionFilterForm}
    />
  </PageContainer>
);

export default DonationTransactionsPage;
