import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeSubscriptionPlansListParameters } from '@/features/subscription-plans/actions';
import useSubscriptionPlanList from '@/features/subscription-plans/hooks/useSubscriptionPlanList';
import type {
  SubscriptionPlan,
  SubscriptionPlanExtended,
  SubscriptionPlanFilterPredicate,
} from '@/features/subscription-plans/types';
import type { SubscriptionPlanSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseSubscriptionPlanPlanListView = UseAppListDataView<
  SubscriptionPlanExtended,
  SubscriptionPlanFilterPredicate,
  SubscriptionPlanSortByAPIModel,
  LoadingListDataState<SubscriptionPlan>
>;

export default function useSubscriptionPlanListView(): UseSubscriptionPlanPlanListView {
  const state = useSubscriptionPlanList();
  return useAppListDataView(state, storeSubscriptionPlansListParameters);
}
