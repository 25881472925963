import { useCallback, useRef } from 'react';

import { useEmailAuth as useEmailAuthBase } from '@/features/auth/hooks';
import type { EmailAuthOptions } from '@/features/auth/hooks';
import { withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import type { Cancellable } from '@/infrastructure/utils/ui';
import { cancellable } from '@/infrastructure/utils/ui';

const useEmailAuth = ({
  onSelectProvider,
  withAuth,
}: {
  onSelectProvider: (selected?: { id: string; onCancel?: () => void }) => void;
  withAuth: <V extends unknown[] = unknown[], R = unknown>(
    func: (options: Omit<EmailAuthOptions, 'cancellationPromise'>) => Func<V, R>,
  ) => Func<V>;
}) => {
  const { loginByEmail } = useEmailAuthBase();
  const cancellationPromise = useRef<Cancellable>();

  const select = useCallback(() => {
    cancellationPromise.current = cancellable((cancel) => {
      onSelectProvider({
        id: 'email',
        onCancel: () => {
          cancel();
          cancellationPromise.current = undefined;
          onSelectProvider();
        },
      });
    });
  }, [onSelectProvider]);
  const auth = useCallback(
    (request: Parameters<typeof loginByEmail.act>[0]) =>
      withFinally(
        withAuth(
          (options) => () =>
            loginByEmail.act(request, { cancellationPromise: cancellationPromise.current, ...options }),
        ),
        () => onSelectProvider(),
      )(),
    [loginByEmail, onSelectProvider, withAuth],
  );
  return { auth, select, isAvailable: loginByEmail.available };
};

export default useEmailAuth;
