import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchBalances } from '@/features/statistics/actions';
import { makeSelectBalances } from '@/features/statistics/selectors';
import type { AssetBalances } from '@/features/statistics/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<AssetBalances>, Parameters<typeof fetchBalances>[0]> =>
  fetchBalances({ force });
const selectFetching = makeSelectPending(fetchBalances);
const selectData = makeSelectBalances();

export default function useActualBalances() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
