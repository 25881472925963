import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshDonationTransaction, DonationsDocumentationLink } from '@/features/donations/components';
import { useDonationTransaction } from '@/features/donations/hooks';
import type { DonationTransaction } from '@/features/donations/types';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDonationTransactionDataLoading = <
  Original extends { data: DonationTransaction } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { transactionId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithDonationTransactionDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useDonationTransaction(props.transactionId);
    return withCardDataLoading<DonationTransaction, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageDonations.TRANSACTION_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <DonationsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationRefreshDonationTransaction
              transactionId={props.transactionId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withDonationTransactionDataLoading;
