import { useContext } from 'react';

import type { HandlersContextType } from '@/features/global/hocs/withHandlerRegistration/context';
import HandlersContext from '@/features/global/hocs/withHandlerRegistration/context';

export type HandlerType = keyof HandlersContextType;

export default function useHandlers<K extends HandlerType>(handlersType: K) {
  return useContext(HandlersContext)[handlersType];
}
