import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import SubscriptionStatusView from '@/features/subscriptions/components/SubscriptionStatusView';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import type { SubscriptionStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const SubscriptionStatusFilterItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  multiple,
  ItemProps = {},
}: SubscriptionStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_FILTER_FORM_STATUS_LABEL} />}
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      disabled={readonly}
      mode={multiple ? 'multiple' : undefined}
      placeholder={
        !readonly && (
          <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_FILTER_FORM_STATUS_PLACEHOLDER} />
        )
      }
      options={useMemo(
        () =>
          enumValues(SubscriptionStatusAPIModel).map((code) => ({
            value: code,
            label: (
              <SubscriptionStatusView value={code} mode="full" data-test={dataTest && `${dataTest}-status-${code}`} />
            ),
          })),
        [dataTest],
      )}
      allowClear
    />
  </Form.Item>
);

const SubscriptionStatusFilterItem = React.memo(
  SubscriptionStatusFilterItemRaw,
) as typeof SubscriptionStatusFilterItemRaw;

export default SubscriptionStatusFilterItem;
