import { Space } from 'antd';
import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { SubscriptionsDocumentationLink, SubscriptionsTable } from '@/features/subscriptions/components';
import SubscriptionFilterForm from '@/features/subscriptions/components/SubscriptionFilterForm';
import { useSubscriptionListView } from '@/features/subscriptions/hooks';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';

import type { SubscriptionsCardProps } from './types';

const SubscriptionCard: React.FC<SubscriptionsCardProps> = (props) => {
  const data = useSubscriptionListView();
  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageSubscriptions.ALL_COMPONENTS_SUBSCRIPTIONS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
        </Space>
      }
      cardSize="big"
      extra={
        <Space>
          <SubscriptionsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="text_icon" />
        </Space>
      }
    >
      <SubscriptionsTable FilterForm={SubscriptionFilterForm} {...data} {...props} />
    </PageCard>
  );
};

const SubscriptionCardMemo = React.memo(SubscriptionCard);

export default SubscriptionCardMemo;
