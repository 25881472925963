import { Space } from 'antd';
import React from 'react';

import { OperationDeletePayout, OperationStartPayout, OperationUpdatePayoutTitle } from '@/features/payouts/components';

import type { PayoutsTableOperationsProps } from './types';

const PayoutsTableOperations: React.FC<PayoutsTableOperationsProps> = ({ 'data-test': dataTest, value: { id } }) => (
  <Space>
    <OperationStartPayout data-test={dataTest && `${dataTest}-startOp`} payoutId={id} mode="inline" />
    <OperationUpdatePayoutTitle data-test={dataTest && `${dataTest}-updateOp`} payoutId={id} mode="inline" />
    <OperationDeletePayout data-test={dataTest && `${dataTest}-deleteOp`} payoutId={id} mode="inline" />
  </Space>
);

const PayoutsTableOperationsMemo = React.memo(PayoutsTableOperations);

export default PayoutsTableOperationsMemo;
