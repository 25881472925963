import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import type { RechargeTransactionFilterPredicate } from '@/features/recharges/types';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { RechargeTransactionFromAddressFilterItem } from './components';

import type { RechargeTransactionFilterFormProps } from './types';

const fields = [
  nameof<RechargeTransactionFilterPredicate>('broadcastAtRange'),
  nameof<RechargeTransactionFilterPredicate>('fromAddressLike'),
  nameof<RechargeTransactionFilterPredicate>('assetIn'),
  nameof<RechargeTransactionFilterPredicate>('btIn'),
];

const processAtRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_RECHARGE_TRANSACTION_FILTER_BROADCAST_AT_TO_LABEL} />,
  },
  from: {
    label: (
      <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_RECHARGE_TRANSACTION_FILTER_BROADCAST_AT_FROM_LABEL} />
    ),
  },
};

const RechargeTransactionFilterForm: React.FC<RechargeTransactionFilterFormProps> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<RechargeTransactionFilterPredicate> fields={fields} {...props}>
      <DateTimeRangeItem<RechargeTransactionFilterPredicate>
        data-test={dataTest && `${dataTest}-processAt`}
        name={nameof<RechargeTransactionFilterPredicate>('broadcastAtRange')}
        messages={processAtRangeMessages}
        readonly={readonly}
        required={false}
        relativeUpdateKey="broadcastAtRangeRelative"
      />
      <RechargeTransactionFromAddressFilterItem
        data-test={dataTest && `${dataTest}-fromAddress`}
        name={nameof<RechargeTransactionFilterPredicate>('fromAddressLike')}
        readonly={readonly}
        required={false}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<RechargeTransactionFilterPredicate>('btIn')}
        multiselect
        network={network}
        readonly={readonly}
        required={false}
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<RechargeTransactionFilterPredicate>('assetIn')}
        multiselect
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const RechargeTransactionFilterFormMemo = React.memo(RechargeTransactionFilterForm);

export default RechargeTransactionFilterFormMemo;
