import BigNumber from 'bignumber.js';
import isNil from 'lodash/isNil';
import React, { useCallback, useMemo } from 'react';

import { FilterForm } from '@/components';
import { AssetAmountRangeItem } from '@/features/dictionary/blockchain/components';
import { PayoutAddressFilterItem } from '@/features/payouts/components/PayoutDestinationFilterForm/components';
import type { PayoutDestinationFilterFormValues } from '@/features/payouts/components/PayoutDestinationFilterForm/types';
import type { PayoutDestinationDetailedFilter } from '@/features/payouts/types';
import { nameof } from '@/infrastructure/utils/ts';

import { PayoutBatchStatusFilterItem } from './components';

import type { PayoutDestinationDetailedFilterFormProps, PayoutDestinationDetailedFilterFormValues } from './types';

const fields = [
  nameof<PayoutDestinationDetailedFilter>('addressLike'),
  nameof<PayoutDestinationDetailedFilter>('amountRange'),
  nameof<PayoutDestinationDetailedFilter>('batchStatusIn'),
];

const PayoutDestinationDetailedFilterForm: React.FC<PayoutDestinationDetailedFilterFormProps> = ({
  value,
  asset,
  onSubmit,
  ...props
}) => {
  const { 'data-test': dataTest, readonly } = props;
  const doSubmit = useCallback(
    ({ amountRange, ...values }: PayoutDestinationDetailedFilterFormValues) =>
      onSubmit?.({
        ...values,
        amountRange: amountRange && {
          gte: amountRange.gte?.valid ? amountRange.gte.value?.toNumber() : undefined,
          lte: amountRange.lte?.valid ? amountRange.lte.value?.toNumber() : undefined,
        },
      }),
    [onSubmit],
  );
  const initialValues = useMemo<PayoutDestinationFilterFormValues>(
    () => ({
      ...value,
      amountRange: {
        gte: !isNil(value?.amountRange?.gte)
          ? {
              value: BigNumber(value.amountRange.gte),
              inputValue: `${value.amountRange.gte}`,
              valid: true,
            }
          : { value: undefined, inputValue: '', valid: true },
        lte: !isNil(value?.amountRange?.lte)
          ? {
              value: BigNumber(value.amountRange.lte),
              inputValue: `${value.amountRange.lte}`,
              valid: true,
            }
          : { value: undefined, inputValue: '', valid: true },
      },
    }),
    [value],
  );
  return (
    <FilterForm<PayoutDestinationDetailedFilterFormValues>
      fields={fields}
      {...props}
      onSubmit={doSubmit}
      value={initialValues}
    >
      {asset && (
        <AssetAmountRangeItem
          data-test={dataTest && `${dataTest}-amount`}
          name={nameof<PayoutDestinationDetailedFilter>('amountRange')}
          assetId={asset}
          readonly={readonly}
          required={false}
          nonNegative
        />
      )}
      <PayoutAddressFilterItem<PayoutDestinationDetailedFilter>
        data-test={dataTest && `${dataTest}-address`}
        name={nameof<PayoutDestinationDetailedFilter>('addressLike')}
        readonly={readonly}
        required={false}
      />
      <PayoutBatchStatusFilterItem
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<PayoutDestinationDetailedFilter>('batchStatusIn')}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const PayoutDestinationDetailedFilterFormMemo = React.memo(PayoutDestinationDetailedFilterForm);

export default PayoutDestinationDetailedFilterFormMemo;
