import { useCallback, useMemo } from 'react';

import { usePreferences, usePreferencesActions } from '@/features/user/hooks';
import { noop } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

export interface UseKYBBannerViewedType {
  viewed: boolean;
  markViewed: Func;
}

export default function useKYBBannerViewed(): UseKYBBannerViewedType {
  const { data } = usePreferences();
  const { persist } = usePreferencesActions();
  const markViewed = useCallback(() => (persist.available ? persist.act({ kybViewed: true }) : noop()), [persist]);
  return useMemo(() => ({ viewed: !!data.data?.kybViewed, markViewed }), [data.data?.kybViewed, markViewed]);
}
