import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { useAppSelector } from '@/app/hooks';
import { useReCaptchaLocale } from '@/features/recaptcha/hooks';
import { makeSelectReCaptcha } from '@/features/recaptcha/selectors';
import { suppressPromise } from '@/infrastructure/utils/functions';

import type { ReCaptchaV2Props } from './types';

const selectRecaptcha = makeSelectReCaptcha();

const ReCaptchaV2: React.FC<ReCaptchaV2Props> = ({ 'data-test': dataTest, onSuccess, onError }) => {
  const { publicV2Key } = useAppSelector(selectRecaptcha);
  const { locale } = useReCaptchaLocale();

  return publicV2Key ? (
    <ReCAPTCHA
      data-test={dataTest && `${dataTest}-recaptcha`}
      hl={locale}
      sitekey={publicV2Key}
      onChange={onSuccess}
      onErrored={() => suppressPromise(onError('Error'))}
      onExpired={() => suppressPromise(onError('Expired'))}
    />
  ) : null;
};

const ReCaptchaV2Memo = React.memo(ReCaptchaV2);

export default ReCaptchaV2Memo;
