import React, { useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import type { SVGProps } from '@/infrastructure/utils/react';

const DefaultLogo: React.FC<SVGProps> = (props) => {
  const [id] = useMemo(() => uuid().replace(/-/g, ''), []);
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ height: props.height ?? 'inherit', width: props.width ?? 'inherit' }}
      {...props}
    >
      <path
        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
        fill={`url(#${id})`}
      />
      <path
        d="M17.2323 9.24376C17.2323 10.3552 16.3313 11.2562 15.2199 11.2562C14.1085 11.2562 13.2075 10.3552 13.2075 9.24376C13.2075 8.13232 14.1085 7.23132 15.2199 7.23132C16.3313 7.23132 17.2323 8.13232 17.2323 9.24376Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.76767 7.23132L10.4244 14.529V18.5009H13.2075V14.1183C13.2075 13.8491 13.1457 13.5843 13.0282 13.3498L9.96232 7.23132H6.76767Z"
        fill="white"
      />
      <defs>
        <linearGradient id={id} x1="0" y1="0" x2="24.1011" y2="23.6463" gradientUnits="userSpaceOnUse">
          <stop stopColor="#F0875C" />
          <stop offset="1" stopColor="#EC5A69" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const DefaultLogoMemo = React.memo(DefaultLogo) as typeof DefaultLogo;

export default DefaultLogoMemo;
