import React, { useMemo } from 'react';

import { AddressTokenHistoryBalanceView } from '@/features/dictionary/blockchain/components';

import type { SettlementReconciliationBlockBalanceViewProps } from './types';

const SettlementReconciliationBlockBalanceViewRaw: React.FC<SettlementReconciliationBlockBalanceViewProps> = ({
  'data-test': dataTest,
  assetId,
  address,
  blockNum,
}) => {
  const id = useMemo(() => ({ assetId, address, blockNum }), [address, assetId, blockNum]);

  return (
    <AddressTokenHistoryBalanceView
      data-test={dataTest}
      assetId={assetId}
      address={address}
      blockNum={id.blockNum}
      withCurrency
      currencyMode="long"
      hideReload
      showZeroOnEmpty
    />
  );
};

const SettlementReconciliationBlockBalanceView = React.memo(SettlementReconciliationBlockBalanceViewRaw);

export default SettlementReconciliationBlockBalanceView;
