import { useCallback, useMemo } from 'react';

import type { AppRootState, AppAsyncThunkAction } from '@/app/store';
import { type LoadingStateWithDirty, mapStoredState, storedDataError } from '@/infrastructure/model';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';

import useAppLoadableData, { type UseAppLoadableData } from './useAppLoadableData';

export type UseAppFullDataRow<Value> = UseAppLoadableData<
  Value,
  LoadingStateWithDirty<Value>,
  LoadingStateWithDirty<Value>
>;

export default function useAppFullDataRow<Id, Value>(
  // this function should fail-safe
  fetchActionFactory: (force: boolean) => AppAsyncThunkAction<LoadingFullDataState<Value>, unknown>,
  singleDataSelector: (id: Id) => (state: AppRootState) => LoadingStateWithDirty<Value>,
  extractId: (value: Value) => Id,
  id: Id | undefined,
  isEqual: (id1: Id, id2: Id) => boolean = (id1, id2) => id1 === id2,
  dataFetchingSelector?: (state: AppRootState) => boolean,
): UseAppFullDataRow<Value> {
  const noId = useMemo(() => storedDataError<Value>('NoId'), []);
  const dataSelector = useMemo(() => (id ? singleDataSelector(id) : () => noId), [id, noId, singleDataSelector]);
  const {
    data,
    forceRefresh: baseForceRefresh,
    loading,
  } = useAppLoadableData(fetchActionFactory, dataSelector, dataFetchingSelector);
  const forceRefresh = useCallback(
    async () =>
      id
        ? mapStoredState(await baseForceRefresh(), (values) => values.find((value) => isEqual(extractId(value), id)))
        : noId,
    [baseForceRefresh, extractId, id, isEqual, noId],
  );
  return { data, forceRefresh, loading };
}
