import { useAppLoadableData } from '@/app/hooks';
import { fetchMerchantToS } from '@/features/branding/actions';
import { makeSelectMerchantToS } from '@/features/branding/selectors';
import { makeSelectPending } from '@/features/global/selectors';

const dataFetchingSelector = makeSelectPending(fetchMerchantToS);
const fetchFactory = (force: boolean) => fetchMerchantToS({ force });
const dataSelector = makeSelectMerchantToS();

export default function useToS() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
