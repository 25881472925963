import { createAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { createAppAsyncThunk } from '@/app/actions';
import createCache from '@/infrastructure/utils/cache';
import { setAbortableTimeout } from '@/infrastructure/utils/functions';

import { NAMESPACE } from './types';

import type { GlobalIdentifiedNotification, GlobalLoadingState, GlobalNotification } from './types';

export const globalInit = createAction(`${NAMESPACE}/INIT`);

export const addNotificationToStore = createAction<GlobalIdentifiedNotification>(`${NAMESPACE}/storeNotification`);
export const removeNotificationFromStore = createAction<GlobalIdentifiedNotification['id']>(
  `${NAMESPACE}/removeNotification`,
);

export const createNotification = createAppAsyncThunk(
  `${NAMESPACE}/createNotification`,
  (
    { timeout = 6000, ...notification }: GlobalNotification & { timeout?: number | 'infinite' },
    { dispatch, signal },
  ) => {
    const id = uuid();
    dispatch(addNotificationToStore({ id, ...notification }));
    if (timeout !== 'infinite') {
      setAbortableTimeout(() => dispatch(removeNotificationFromStore(id)), timeout, signal);
    }
  },
);

export const storeLoading = createAction<GlobalLoadingState>(`${NAMESPACE}/storeLoading`);
export const storeViewHeight = createAction<number>(`${NAMESPACE}/storeViewHeight`);

export const storeCookiesAcceptance = createAction<{ accepted: boolean }>(`${NAMESPACE}/storeCookiesAcceptance`);

const cookiesAcceptanceCache = createCache<boolean>(import.meta.env.VITE_COOKIES_AGREED_KEY, 'persistent');

export const initCookiesAcceptance = createAppAsyncThunk(`${NAMESPACE}/initCookiesAcceptance`, (_, { dispatch }) => {
  const accepted = !!cookiesAcceptanceCache.safeRead();
  dispatch(storeCookiesAcceptance({ accepted }));
});

export const acceptCookies = createAppAsyncThunk(`${NAMESPACE}/acceptCookies`, (_, { dispatch }) => {
  cookiesAcceptanceCache.safeSave(true);
  dispatch(storeCookiesAcceptance({ accepted: true }));
});

export const storeIdleStarted = createAction(`${NAMESPACE}/storeIdleStarted`);
export const storeIdleFinished = createAction(`${NAMESPACE}/storeIdleFinished`);
export const markReloadOnIdleRequested = createAction(`${NAMESPACE}/markReloadOnIdleRequested`);
export const storeReloadOnIdleBlocked = createAction<string>(`${NAMESPACE}/storeReloadOnIdleBlocked`);
export const storeReloadOnIdleUnblocked = createAction<string>(`${NAMESPACE}/storeReloadOnIdleUnblocked`);
