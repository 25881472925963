import { FormOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { donationEditLink } from '@/pages/donations/routes';

import type { DonationEditLinkProps } from './types';

const DonationEditLink: React.FC<DonationEditLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(donationEditLink(value)), [openForm, value]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <FormOutlined />}
      title={
        title ?? <FormattedMessage id={I18nPageDonations.DONATION_EDIT_COMPONENTS_LINK_TITLE} values={{ id: value }} />
      }
    />
  );
};

const DonationEditLinkMemo = React.memo(DonationEditLink);

export default DonationEditLinkMemo;
