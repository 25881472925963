import { Skeleton } from 'antd';
import React, { Suspense } from 'react';

import type { CompaniesListProps } from './types';

const CompaniesListLazy = React.lazy(() => import('./components/CompaniesListLazy'));

const CompaniesList: React.FC<CompaniesListProps> = (props) => (
  <Suspense fallback={<Skeleton />}>
    <CompaniesListLazy {...props} />
  </Suspense>
);

const CompaniesListMemo = React.memo(CompaniesList);

export default CompaniesListMemo;
