import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useCompanyUsers } from '@/features/company/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import type { OperationRefreshCompanyUsersProps } from './types';

const messages = {
  title: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REFRESH_COMPANY_USERS_TITLE} />,
  success: {
    message: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REFRESH_COMPANY_USERS_SUCCESS_MESSAGE} />,
  },
  error: {
    message: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REFRESH_COMPANY_USERS_ERROR_MESSAGE} />,
  },
};

const OperationRefreshUsers: React.FC<OperationRefreshCompanyUsersProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useCompanyUsers();
  return <OperationRefresh data-test={dataTest} messages={messages} refresh={forceRefresh} mode={mode} />;
};

const OperationRefreshUsersMemo = React.memo(OperationRefreshUsers);

export default OperationRefreshUsersMemo;
