import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import CollectableTransactionIcon from '@/features/collectable/components/CollectableTransactionIcon';
import DonationsIcon from '@/features/donations/components/DonationsIcon';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import { I18nMenu } from '@/generated/i18n/i18n';
import type { CustomizableRoute } from '@/layouts/types';
import { donationsLink, DonationsPageRoutes, donationsRoute, donationTransactionsLink } from '@/pages/donations/routes';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (
  selected: boolean,
  formatMessage: IntlFormattersArrow['formatMessage'],
  featureFlags: FeatureFlags | undefined,
): CustomizableRoute[] =>
  featureFlags?.enableDonations
    ? [
        {
          name: formatMessage({ id: I18nMenu.DONATIONS_MAIN }),
          key: appRoute(AppPageRoutes.DONATIONS),
          icon: <DonationsIcon />,
          path: donationsLink(),
          hideChildrenInMenu: !selected,
          children: [
            {
              name: formatMessage({ id: I18nMenu.DONATIONS_DONATIONS }),
              icon: <DonationsIcon />,
              path: donationsLink(),
              hideChildrenInMenu: true,

              children: [
                DonationsPageRoutes.DONATION_VIEW,
                DonationsPageRoutes.DONATION_EDIT,
                DonationsPageRoutes.DONATION_NEW,
                DonationsPageRoutes.DONATION_ASSET_VIEW,
              ]
                .map((route) => donationsRoute(route))
                .map((path) => ({ path })),
            },
            {
              name: formatMessage({ id: I18nMenu.DONATIONS_TRANSACTIONS }),
              icon: <CollectableTransactionIcon />,
              path: donationTransactionsLink(),
              hideChildrenInMenu: true,

              children: [DonationsPageRoutes.TRANSACTION_VIEW]
                .map((route) => donationsRoute(route))
                .map((path) => ({ path })),
            },
          ],
        },
      ]
    : [];

export default function useMenuData(): CustomizableRoute[] {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.DONATIONS));
  return useMemo(() => menuCreator(selected, formatMessage, featureFlags), [featureFlags, formatMessage, selected]);
}
