import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import {
  OperationCancelSettlementSchedule,
  OperationRefreshSettlementSchedule,
  OperationSetSettlementSchedule,
  SettlementDocumentationLink,
} from '@/features/settlements/components';
import { useSettlementSchedule } from '@/features/settlements/hooks';
import type { SettlementSchedule } from '@/features/settlements/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import SettlementScheduleLink from '@/pages/settlements/schedule/components/SettlementScheduleLink';

import type React from 'react';

const withSettlementScheduleDataLoading =
  <T extends { data: SettlementSchedule } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'>> =>
  (props) => {
    const data = useSettlementSchedule();
    return withCardDataLoading<SettlementSchedule, T>({
      ...data,
      'data-test': props['data-test'],
      title: (
        <Space>
          <FormattedMessage id={I18nPageSettlements.HISTORY_COMPONENTS_SCHEDULE_CARD_TITLE} />
          <SettlementScheduleLink data-test={props['data-test'] && `${props['data-test']}-opSet`} mode="text" />
        </Space>
      ),
      CardProps: {
        extra: (
          <Space>
            <SettlementDocumentationLink
              data-test={props['data-test'] && `${props['data-test']}-docs`}
              mode="text_icon"
            />
            <OperationSetSettlementSchedule
              data-test={props['data-test'] && `${props['data-test']}-opSet`}
              schedule={data.data.data}
            />
            <OperationCancelSettlementSchedule data-test={props['data-test'] && `${props['data-test']}-opCancel`} />
            <OperationRefreshSettlementSchedule data-test={props['data-test'] && `${props['data-test']}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withSettlementScheduleDataLoading;
