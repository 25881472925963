import { ContactsOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import { FormattedMessage } from '@/components';
import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import DataBlockContainer from '../DataBlockContainer';

import { ContactEmailItem, RedirectParamItem, RedirectURLItem } from './components';

import type { ContactsBlockProps } from './types';
import type React from 'react';

const ContactsBlock: React.FC<ContactsBlockProps> = ({ 'data-test': dataTest, disabled }) => (
  <DataBlockContainer
    title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_TITLE} />}
    icon={<ContactsOutlined />}
    help={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_HELP} />}
  >
    <Row>
      <Col span={24}>
        <ContactEmailItem data-test={dataTest && `${dataTest}-email`} disabled={disabled} />
      </Col>
      <Col span={24}>
        <RedirectURLItem data-test={dataTest && `${dataTest}-redirect`} disabled={disabled} />
      </Col>
      <Col span={24}>
        <RedirectParamItem data-test={dataTest && `${dataTest}-param`} disabled={disabled} />
      </Col>
    </Row>
  </DataBlockContainer>
);

const Hidden = () => (
  <Col span={24} style={{ display: 'none' }}>
    <ContactEmailItem disabled />
    <RedirectURLItem disabled />
  </Col>
);

const ContactsBlockHOC = withFeatureToggleGuard('enableBrandingContacts', true, Hidden)(ContactsBlock);

export default ContactsBlockHOC;
