import { useMemo } from 'react';

import { PageContainer } from '@/components';
import { PaymentsDocumentationBanner } from '@/features/payments/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { PaymentCard, PaymentTransactionBreadcrumb, PaymentTransactionCard } from './components';
import { withPaymentTransactionPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type PaymentTransactionViewPageProps = PropsWithChildren<
  TestableProps & {
    pageParams: { paymentId: string; transactionId: string };
  }
>;

const PaymentTransactionViewPage: React.FC<PaymentTransactionViewPageProps> = ({
  'data-test': dataTest = 'pt',
  pageParams: { paymentId, transactionId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <PaymentTransactionBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        paymentId={paymentId}
        hash={transactionId}
        disabled
      />
    }
  >
    <PaymentsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <PaymentCard data-test={dataTest && `${dataTest}-payment`} paymentId={paymentId} />
    <PaymentTransactionCard
      paymentTransactionId={useMemo(() => ({ paymentId, hash: transactionId }), [paymentId, transactionId])}
      data-test={dataTest && `${dataTest}-transactions`}
    />
  </PageContainer>
);

export default withPaymentTransactionPageParams(PaymentTransactionViewPage);
