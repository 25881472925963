import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import {
  OperationRefreshDonation,
  DonationsDocumentationLink,
  OperationUpdateDonationStatus,
  OperationGenerateDonationURL,
} from '@/features/donations/components';
import { useDonation } from '@/features/donations/hooks';
import type { Donation } from '@/features/donations/types';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';
import DonationEditLink from '@/pages/donations/donation-edit/components/DonationEditLink';

import type React from 'react';

const withDonationDataLoading = <
  Original extends { data: Donation } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { donationId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithDonationDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useDonation(props.donationId);
    return withCardDataLoading<Donation, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageDonations.DONATION_VIEW_COMPONENTS_DONATION_CARD_TITLE}
          values={{ id: props.donationId }}
        />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <DonationsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <DonationEditLink data-test={dataTest && `${dataTest}-link`} value={props.donationId} mode="inline" />
            <OperationUpdateDonationStatus
              data-test={dataTest && `${dataTest}-statusOp`}
              donationId={props.donationId}
              mode="inline"
            />
            <OperationGenerateDonationURL
              data-test={dataTest && `${dataTest}-generateOp`}
              donationId={props.donationId}
              mode="inline"
            />
            <OperationRefreshDonation data-test={dataTest && `${dataTest}-opRefresh`} donationId={props.donationId} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withDonationDataLoading;
