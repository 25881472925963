import { useAppSelector } from '@/app/hooks';
import { makeSelectSelectedNetworkState } from '@/features/dictionary/blockchain/selectors';
import { InitStatus } from '@/infrastructure/model';

const networkSelector = makeSelectSelectedNetworkState();

export default function useSelectedNetworkActions() {
  const { value: network, status } = useAppSelector(networkSelector);
  const initialized = status === InitStatus.FINISHED;
  return { network, initialized };
}
