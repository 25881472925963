import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';

import DonationTransactionsLink from '../DonationTransactionsLink';

import type { DonationTransactionsBreadcrumbProps } from './types';

const DonationTransactionsBreadcrumb: React.FC<DonationTransactionsBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageDonations.TRANSACTIONS_COMPONENTS_BREADCRUMB} />
        ) : (
          <DonationTransactionsLink
            mode="text"
            title={<FormattedMessage id={I18nPageDonations.TRANSACTIONS_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const DonationTransactionsBreadcrumbMemo = React.memo(DonationTransactionsBreadcrumb);

export default DonationTransactionsBreadcrumbMemo;
