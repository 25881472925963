import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsCompanyGasWalletLink } from '@/pages/settings/routes';

import type { GasWalletLinkProps } from './types';

const GasWalletLink: React.FC<GasWalletLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={settingsCompanyGasWalletLink(value)}
    mode={mode}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLET_LINK_TITLE} />}
  />
);

const GasWalletLinkMemo = React.memo(GasWalletLink);

export default GasWalletLinkMemo;
