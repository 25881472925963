import React, { useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { useDonationTransaction } from '@/features/donations/hooks';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { uuidToBase58 } from '@/infrastructure/utils/crypto';
import { donationTransactionViewLink } from '@/pages/donations/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { DonationTransactionEntityLinkProps } from './types';

const DonationTransactionEntityLink: React.FC<DonationTransactionEntityLinkProps> = ({
  'data-test': dataTest,
  value,
}) => {
  const txId = useMemo(
    () => (value.entityId?.includes('-') ? uuidToBase58(value.entityId) : value.entityId),
    [value.entityId],
  );
  const data = useDonationTransaction(txId);
  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return <Navigate to={donationTransactionViewLink(data.data.data.id)} replace />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.DonationTransaction}
      onRetry={data.forceRefresh}
    />
  );
};

const DonationTransactionEntityLinkMemo = React.memo(DonationTransactionEntityLink);

export default DonationTransactionEntityLinkMemo;
