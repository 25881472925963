import { Alert } from 'antd';
import React, { useMemo } from 'react';

import { DurationView, FormattedMessage } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { withSettlement } from '@/features/settlements/hocs';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementDurationAlertProps } from './types';

const SettlementDurationAlert: React.FC<SettlementDurationAlertProps> = ({ 'data-test': dataTest, settlement }) => {
  const bc = useBlockchainSystemInfo(settlement.blockchain);
  const duration = useMemo(
    () => (bc.data.data?.apiType === BlockchainAPITypeAPIModel.BTC ? 60 * 60 : 30),
    [bc.data.data?.apiType],
  );
  return (
    <Alert
      message={
        <FormattedMessage
          id={I18nFeatureSettlements.COMPONENTS_DURATION_ALERT_TITLE}
          values={{ time: <DurationView value={duration} mode="full" /> }}
        />
      }
      data-test={dataTest}
      type="info"
      showIcon
    />
  );
};

const SettlementDurationAlertHOC = React.memo(withSettlement<SettlementDurationAlertProps>()(SettlementDurationAlert));

export default SettlementDurationAlertHOC;
