import { PlayCircleOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import DataFetchError from '@/components/DataFetchError';
import { useBrandingProfiles } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { BrandingProfileSelectProps } from './types';

const BrandingProfileSelect: React.FC<BrandingProfileSelectProps> = ({
  'data-test': dataTest,
  value,
  style,
  className,
  onChange,
  readonly,
}) => {
  const { data, forceRefresh, loading } = useBrandingProfiles();
  const options = useMemo(
    () =>
      data.data?.map(({ id, name: label, active }) => ({
        value: id,
        label: (
          <>
            {active && (
              <>
                <PlayCircleOutlined style={{ color: 'green' }} />
                &nbsp;
              </>
            )}
            {label}
          </>
        ),
        search: `label`,
      })),
    [data.data],
  );
  return !data.error ? (
    <Select
      style={style}
      className={className}
      value={value}
      onSelect={onChange}
      data-test={dataTest}
      showSearch
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_SELECT_PLACEHOLDER} />}
      loading={loading}
      disabled={loading || readonly}
      options={options}
      optionFilterProp="search"
    />
  ) : (
    <DataFetchError
      refresh={forceRefresh}
      message={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_SELECT_EMPTY} />}
      data-test={dataTest && `${dataTest}-error`}
      description={data.error}
    />
  );
};

const BrandingProfileSelectMemo = React.memo(BrandingProfileSelect);

export default BrandingProfileSelectMemo;
