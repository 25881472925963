import { type CommonLoadingState, loadingDataError } from '@/infrastructure/model';
import { withCatchError } from '@/infrastructure/utils/functions';

import type { AsyncThunk, CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export const withIgnoreAbortion =
  <State, ActionCreator extends AsyncThunk<unknown, unknown, NonNullable<unknown>>['rejected']>(
    func: CaseReducer<State, ReturnType<ActionCreator>>,
  ): CaseReducer<State, ReturnType<ActionCreator>> =>
  (state, action) => {
    if (action.meta.aborted || action.meta.condition) {
      return state;
    }
    return func(state, action);
  };

export const isFeatureAction =
  (feature: string) =>
  (action: unknown): action is PayloadAction =>
    (action as PayloadAction).type.startsWith(feature);

export const withThunkError = <V extends unknown[], T, R extends CommonLoadingState<T> = CommonLoadingState<T>>(
  func: (...args: V) => Promise<R>,
) =>
  withCatchError(func, (e) =>
    loadingDataError<T>((e as Partial<Error>).message ?? (typeof e === 'string' ? e : '')),
  );

export const callWithThunkError = <T>(func: () => Promise<CommonLoadingState<T>>): Promise<CommonLoadingState<T>> =>
  withCatchError(func, (e) =>
    loadingDataError<T>((e as Partial<Error>).message ?? (typeof e === 'string' ? e : '')),
  )();

export default { withIgnoreAbortion, isFeatureAction, callWithThunkError };
