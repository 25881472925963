import { useMemo } from 'react';

import type { MerchantBrandingProfileContent, MerchantBrandingProfileExtended } from '@/features/branding/types';
import type { LoadingStateWithDirty, HookAction } from '@/infrastructure/model';
import { noop } from '@/infrastructure/utils/functions';

import useBrandingProfile from './useBrandingProfile';
import useBrandingProfilesActions from './useBrandingProfilesActions';

export type RemoveUnavailabilityReason = 'no-data';
export type ActivateUnavailabilityReason = 'no-data' | 'already-active';
export type DeactivateUnavailabilityReason = 'no-data' | 'already-inactive';
export type UpdateUnavailabilityReason = 'no-data';

export interface UseBrandingProfileActions {
  data?: LoadingStateWithDirty<MerchantBrandingProfileExtended>;
  update: HookAction<[MerchantBrandingProfileContent], unknown, UpdateUnavailabilityReason>;
  activate: HookAction<[], unknown, ActivateUnavailabilityReason>;
  deactivate: HookAction<[], unknown, DeactivateUnavailabilityReason>;
  remove: HookAction<[], unknown, RemoveUnavailabilityReason>;
}

export default function useBrandingProfileActions(profileId: string | undefined): UseBrandingProfileActions {
  const { data: profile } = useBrandingProfile(profileId);
  const { update, activate, deactivate, remove } = useBrandingProfilesActions();

  const profileUnavailabilityReason = !profile.data ? 'no-data' : undefined;

  return {
    data: profile,
    activate: useMemo(
      () => ({
        ...activate,
        act: profileId ? () => activate.act(profileId) : noop,
        available: !profileUnavailabilityReason && !!profile.data?.active,
        unavailabilityReason: profileUnavailabilityReason ?? (profile.data?.active ? 'already-active' : undefined),
      }),
      [activate, profile.data?.active, profileId, profileUnavailabilityReason],
    ),
    deactivate: useMemo(
      () => ({
        ...deactivate,
        act: profileId ? () => deactivate.act(profileId) : noop,
        available: !profileUnavailabilityReason && !profile.data?.active,
        unavailabilityReason: profileUnavailabilityReason ?? (!profile.data?.active ? 'already-inactive' : undefined),
      }),
      [deactivate, profile.data?.active, profileId, profileUnavailabilityReason],
    ),
    update: useMemo(
      () => ({
        ...update,
        act: profileId ? (data: MerchantBrandingProfileContent) => update.act(profileId, data) : noop,
        available: !profileUnavailabilityReason,
        unavailabilityReason: profileUnavailabilityReason,
      }),
      [profileId, profileUnavailabilityReason, update],
    ),
    remove: useMemo(
      () => ({
        ...remove,
        act: profileId ? () => remove.act(profileId) : noop,
        available: !profileUnavailabilityReason,
        unavailabilityReason: profileUnavailabilityReason,
      }),
      [profileId, profileUnavailabilityReason, remove],
    ),
  };
}
