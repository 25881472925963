import { QuestionCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Form, Input, Tooltip } from 'antd';
import React, { useContext } from 'react';

import { FormattedMessage } from '@/components';
import { OperationDisconnectWeb3AuthWallet } from '@/features/auth/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useSharedStyles } from '@/hooks';

import { withProfileDataLoading } from './hocs';

import type { ProfileCardProps } from './types';

const ProfileCard: React.FC<ProfileCardProps> = ({ data, 'data-test': dataTest }) => {
  const { disabledTextInput } = useSharedStyles();
  const { web3Auth } = useContext(Web3AuthContext);
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal">
      {data.user.email && (
        <Form.Item
          label={<FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_LOGIN_EMAIL_ITEM_LABEL} />}
        >
          <Input
            data-test={dataTest && `${dataTest}-email`}
            value={data.user.email}
            disabled
            className={cx(disabledTextInput, 'ym-hide-content')}
            suffix={
              web3Auth?.isConfirmed ? (
                <OperationDisconnectWeb3AuthWallet data-test={dataTest && `${dataTest}-disconnectOp`} mode="icon" />
              ) : null
            }
          />
        </Form.Item>
      )}
      <Form.Item
        label={
          <>
            <FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_ADDRESS_LABEL} />
            &nbsp;
            <Tooltip title={<FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_ADDRESS_HELP} />}>
              <QuestionCircleOutlined />
            </Tooltip>
          </>
        }
      >
        <Input
          data-test={dataTest && `${dataTest}-address`}
          value={data.user.address}
          disabled
          className={cx(disabledTextInput, 'ym-hide-content')}
        />
      </Form.Item>
    </Form>
  );
};

const ProfileCardLoaded = withProfileDataLoading(ProfileCard);

const ProfileCardMemo = React.memo(ProfileCardLoaded);

export default ProfileCardMemo;
