import { Tooltip } from 'antd';
import React, { useMemo } from 'react';

import { ReadonlyComponent } from '@/components';
import { useHandlers } from '@/features/global/hooks';

import type { ReportScheduleTemplateTitleProps } from './types';

const ReportScheduleTemplateTitleRaw: React.FC<ReportScheduleTemplateTitleProps> = ({ value, ...props }) => {
  const handlers = useHandlers('reportSchedules');
  const handler = useMemo(() => handlers.find((h) => h.template === value), [handlers, value]);

  return handler?.Title ? (
    <Tooltip title={value}>
      <ReadonlyComponent value={<handler.Title />} copyable={{ text: value }} {...props} />
    </Tooltip>
  ) : (
    <ReadonlyComponent value={value} copyable {...props} />
  );
};

const ReportScheduleTemplateTitle = React.memo(ReportScheduleTemplateTitleRaw);

export default ReportScheduleTemplateTitle;
