import React from 'react';

import type { RechargesDocumentationLinkProps } from './types';

const RechargesDocumentationLink: React.FC<RechargesDocumentationLinkProps> = (
  // eslint-disable-next-line no-empty-pattern
  {
    // mode,
    // title,
    // 'data-test': dataTest,
  },
) => null;
// (
//   <DocumentationLink
//     mode={mode}
//     title={title ?? <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_DOCUMENTATION_LINK_TITLE} />}
//     data-test={dataTest}
//     path="/general/push-payment-button"
//   />
//
// )
const RechargesDocumentationLinkMemo = React.memo(RechargesDocumentationLink);

export default RechargesDocumentationLinkMemo;
