import React, { useMemo } from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import { useSubscriptionCharge } from '@/features/subscription-charges/hooks';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import SubscriptionsBreadcrumb from '@/pages/subscriptions/all/components/SubscriptionsBreadcrumb';
import SubscriptionBreadcrumb from '@/pages/subscriptions/subscription-view/components/SubscriptionBreadcrumb';

import SubscriptionChargeLink from '../SubscriptionChargeLink';

import type { SubscriptionChargeBreadcrumbProps } from './types';

const SubscriptionChargeBreadcrumb: React.FC<SubscriptionChargeBreadcrumbProps> = ({
  'data-test': dataTest,
  items = [],
  subscriptionChargeId,
  disabled,
}) => {
  const { data } = useSubscriptionCharge(subscriptionChargeId);
  const memoItems = useMemo(
    () => [
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageSubscriptions.CHARGE_VIEW_COMPONENTS_BREADCRUMB_TITLE}
            values={{ date: <ReadonlyDateTime value={data.data?.chargeDate} /> }}
          />
        ) : (
          <SubscriptionChargeLink
            mode="text"
            value={subscriptionChargeId}
            title={
              <FormattedMessage
                id={I18nPageSubscriptions.CHARGE_VIEW_COMPONENTS_BREADCRUMB_TITLE}
                values={{ date: <ReadonlyDateTime value={data.data?.chargeDate} /> }}
              />
            }
          />
        ),
        ...items,
      },
    ],
    [data.data?.chargeDate, disabled, items, subscriptionChargeId],
  );
  return data.data?.contractAddress ? (
    <SubscriptionBreadcrumb data-test={dataTest} subscriptionId={data.data.contractAddress} items={memoItems} />
  ) : (
    <SubscriptionsBreadcrumb data-test={dataTest} items={memoItems} />
  );
};
const SubscriptionChargeBreadcrumbMemo = React.memo(SubscriptionChargeBreadcrumb);

export default SubscriptionChargeBreadcrumbMemo;
