import { PageLoading } from '@/components';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const withFeatureToggleInit = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithFeatureToggleInit',
  )((props) => {
    const {
      loading,
      data: { isDirty },
    } = useFeatureToggle();
    return !loading && !isDirty ? (
      <Component {...props} />
    ) : (
      <MessageLayout>
        <PageLoading type="withFeatureToggleInit" />
      </MessageLayout>
    );
  });

export default withFeatureToggleInit;
