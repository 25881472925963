import { useSettlementIntent } from '@/features/settlements/hooks';
import type { SettlementIntent } from '@/features/settlements/types';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

const withSettlementIntentLoad = <T extends { intent: SettlementIntent }>(
  Component: React.ComponentType<Omit<T, 'intent'> & { intent: SettlementIntent }>,
  EmptyComponent: React.ComponentType<Omit<T, 'intent'>>,
) =>
  namedHOC<Omit<T, 'intent'> & { intent: SettlementIntent }, Omit<T, 'intent'> & { intentId: string }>(
    Component,
    'WithSettlementIntentLoad',
  )((props) => {
    const { intentId } = props;
    const intent = useSettlementIntent(intentId);

    return intent.data.data ? <Component {...props} intent={intent.data.data} /> : <EmptyComponent {...props} />;
  });

const withSettlementIntent =
  <T extends { intent: SettlementIntent }>(EmptyComponent: React.ComponentType<Omit<T, 'intent'>> = emptyComponent) =>
  (Component: React.ComponentType<Omit<T, 'intent'> & { intent: SettlementIntent }>) => {
    const LoadComponent = withSettlementIntentLoad<T>(Component, EmptyComponent);
    return namedHOC<Omit<T, 'intent'> & { intent: SettlementIntent }, T | (Omit<T, 'intent'> & { intentId: string })>(
      Component,
      'WithSettlementIntent',
    )((props) => {
      return 'intent' in props ? <Component {...props} /> : <LoadComponent {...props} />;
    });
  };

export default withSettlementIntent;
