import React, { useMemo } from 'react';

import CollectTaskFilterForm from '../CollectTaskFilterForm';

import type { CollectTaskForAddressesFilterFormProps } from './types';

const CollectTaskForAddressesFilterForm: React.FC<CollectTaskForAddressesFilterFormProps> = ({
  hidden = {},
  ...props
}) => <CollectTaskFilterForm {...props} hidden={useMemo(() => ({ ...hidden, addressLike: true }), [hidden])} />;

const CollectTaskForAddressesFilterFormMemo = React.memo(CollectTaskForAddressesFilterForm);

export default CollectTaskForAddressesFilterFormMemo;
