import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWalletRunningBalances } from '@/features/merchant-wallet-balance/actions';
import {
  makeSelectMerchantWalletRunningBalanceListData,
  makeSelectMerchantWalletRunningBalanceListParameters,
} from '@/features/merchant-wallet-balance/selectors';
import type {
  MerchantWalletRunningBalance,
  MerchantWalletRunningBalanceFilterPredicate,
} from '@/features/merchant-wallet-balance/types';
import type { MerchantWalletRunningBalanceSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseMerchantWalletRunningBalanceList = UseAppListData<
  MerchantWalletRunningBalance,
  MerchantWalletRunningBalanceFilterPredicate,
  MerchantWalletRunningBalanceSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingListDataState<MerchantWalletRunningBalance>,
  Parameters<typeof fetchMerchantWalletRunningBalances>[0]
> => fetchMerchantWalletRunningBalances({ force });

const dataSelector = makeSelectMerchantWalletRunningBalanceListData();
const dataFetchingSelector = makeSelectPending(fetchMerchantWalletRunningBalances);
const parametersSelector = makeSelectMerchantWalletRunningBalanceListParameters();

export default function useMerchantWalletRunningBalanceList(): UseMerchantWalletRunningBalanceList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
