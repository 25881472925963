import { Tag } from 'antd';
import React from 'react';

import { AssetLabel } from '@/features/dictionary/blockchain/components';

import type { DonationAssetsTagsProps } from './types';

const DonationAssetsTags: React.FC<DonationAssetsTagsProps> = ({ 'data-test': dataTest, data, onlyActive }) =>
  data
    .filter(({ isActive }) => !onlyActive || isActive)
    .map(({ asset }) => (
      <Tag key={asset}>
        <AssetLabel data-test={dataTest && `${dataTest}-${asset}`} value={asset} mode="short" withBlockchainMark />
      </Tag>
    ));

const DonationAssetsTagsMemo = React.memo(DonationAssetsTags);

export default DonationAssetsTagsMemo;
