import { Form } from 'antd';
import React, { useMemo } from 'react';
import { isAddress } from 'viem';

import {
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
  FormattedMessage,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import AddressItem from '@/features/dictionary/blockchain/components/AddressItem';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import type { AddUserFormProps, AddUserFormValues } from './types';

const fields = [nameof<AddUserFormValues>('address')];

const footerMessages = {
  submit: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_SUBMIT} tagName="span" />,
};

const AddUserForm: React.FC<AddUserFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  registered,
  owner,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { network } = useSelectedNetwork();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { form, withResetForm } = useForm<AddUserFormValues>();
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: AddUserFormValues): unknown => onSubmit(val.address)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ERROR_COMMON} />;
  }, [error]);

  const rules = useMemo(
    () => [
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_: unknown, value: string) => {
          if (value && isAddress(value) && registered.find((v) => isSameAddress(v, value))) {
            throw new Error();
          }
        },
        message: (
          <FormattedMessage
            id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ADDRESS_ERROR_DUPLICATE}
          />
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_: unknown, value: string) => {
          if (value && value === owner) {
            throw new Error();
          }
        },
        message: (
          <FormattedMessage id={I18nFeatureCompany.COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ADDRESS_ERROR_OWNER} />
        ),
      },
    ],
    [owner, registered],
  );

  return (
    <Form<AddUserFormValues> {...layout} autoComplete="off" form={form} size="middle" onFinish={submit} onReset={reset}>
      {errorMessage && <ErrorFormMessage content={errorMessage} />}
      <AddressItem<AddUserFormValues>
        name={nameof<AddUserFormValues>('address')}
        data-test={dataTest && `${dataTest}-address`}
        rules={rules}
        blockchainApis={[BlockchainAPITypeAPIModel.WEB3]}
        net={network}
      />
      <FormCompletenessItem<AddUserFormValues> requiredFields={fields} onChange={setFormComplete} />
      <FormFooter
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
        messages={footerMessages}
        data-test={dataTest && `${dataTest}-submit`}
      />
    </Form>
  );
};

const AddUserFormMemo = React.memo(AddUserForm);

export default AddUserFormMemo;
