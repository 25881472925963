import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent, ReadonlyDateTime } from '@/components';
import { TransactionLink } from '@/features/dictionary/blockchain/components';
import { GasWalletAddressLink, GasWalletTransactionStatusView } from '@/features/gas-wallets/components';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectableEntityProcessTransactionViewProps } from './types';

const CollectableEntityProcessTransactionView: React.FC<CollectableEntityProcessTransactionViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={
      <GasWalletTransactionStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} mode="full" />
    }
    column={columns}
  >
    {data.wallet && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureCollectable.LABELS_PROCESS_TX_ROW_TITLE_ASSIGNED_WALLET} />}
      >
        <GasWalletAddressLink data-test={dataTest && `${dataTest}-wallet`} address={data.wallet} bt={data.blockchain} />
      </Descriptions.Item>
    )}
    {data.sentAt && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_PROCESS_TX_ROW_TITLE_SENT_AT} />}>
        <ReadonlyDateTime value={data.sentAt} data-test={dataTest && `${dataTest}-sentAt`} />
      </Descriptions.Item>
    )}
    {data.hash && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_PROCESS_TX_ROW_TITLE_HASH} />}>
        <TransactionLink tx={data.hash} bt={data.blockchain} data-test={dataTest && `${dataTest}-hash`} />
      </Descriptions.Item>
    )}
    {data.blockNum && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureCollectable.LABELS_PROCESS_TX_ROW_TITLE_BLOCK_NUM} />}>
        <ReadonlyComponent value={data.blockNum} data-test={dataTest && `${dataTest}-blockNum`} />
      </Descriptions.Item>
    )}
    {data.failCount && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureCollectable.LABELS_PROCESS_TX_ROW_TITLE_FAIL_COUNT} />}
      >
        <ReadonlyComponent value={data.failCount} data-test={dataTest && `${dataTest}-failCount`} />
      </Descriptions.Item>
    )}
    {data.errorReason && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeatureCollectable.LABELS_PROCESS_TX_ROW_TITLE_ERROR_REASON} />}
      >
        <ReadonlyComponent value={data.errorReason} data-test={dataTest && `${dataTest}-errorReason`} />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const CollectableEntityProcessTransactionViewMemo = React.memo(CollectableEntityProcessTransactionView);

export default CollectableEntityProcessTransactionViewMemo;
