import React, { useContext, useMemo } from 'react';

import { AssetAmountItem } from '@/features/dictionary/blockchain/components';
import type { PayoutDestinationTableEditFormValues } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';
import { PayoutTableRowFormValueContext } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';
import { nameof } from '@/infrastructure/utils/ts';

import type { EditableAmountCellProps } from './types';

const ItemProps = { label: undefined };

const EditableAmountCell: React.FC<EditableAmountCellProps> = ({ 'data-test': dataTest, asset }) => {
  const { formItemName } = useContext(PayoutTableRowFormValueContext);
  const name = useMemo(() => [formItemName, nameof<PayoutDestinationTableEditFormValues>('amount')], [formItemName]);

  return asset ? (
    <AssetAmountItem data-test={dataTest} name={name} nonNegative assetId={asset} ItemProps={ItemProps} />
  ) : null;
};

const EditableAmountCellMemo = React.memo(EditableAmountCell);

export default EditableAmountCellMemo;
