import type { AppRootState } from '@/app/store';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectReport,
  makeSelectReportListParameters,
  makeSelectReportListData,
  makeSelectDirtyReportIds,
  makeSelectMultipleReport,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state: AppRootState) => state[NAMESPACE].entities,
  'Report' as const,
  undefined,
);

export const makeSelectGeneratingReports = () => (state: AppRootState) =>
  Object.entries(state[NAMESPACE].entities)
    .filter(([, data]) => data?.data?.status === ReportStatusAPIModel.Generating)
    .map(([, data]) => data!.data!);
