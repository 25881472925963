import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { createNormalizedPartialDataSelectors } from '@/infrastructure/model/partial/selectors';
import { enumKeys } from '@/infrastructure/utils/ts';

import { type GasWallet, NAMESPACE } from './types';

export const { makeSelectGasWallet, makeSelectGasWalletBatch } = createNormalizedPartialDataSelectors<
  AppRootState,
  'GasWallet',
  GasWallet,
  BlockchainTypeAPIModel,
  BlockchainTypeAPIModel
>(
  (state: AppRootState) => state[NAMESPACE].wallets,
  (state: AppRootState) => state[NAMESPACE].entities,
  'GasWallet' as const,
  undefined,
);

export const makeSelectRefundingGasWallets = () =>
  createSelector(
    (state: AppRootState) => state[NAMESPACE].entities,
    (state) => enumKeys(BlockchainTypeAPIModel).filter((bt) => state[bt]?.data?.isRefunding),
  );

export const makeSelectGasWalletIsRefunding = (bt: BlockchainTypeAPIModel | undefined) =>
  createSelector(makeSelectRefundingGasWallets(), (state) => !!bt && state.includes(bt));
