import React from 'react';

import type { UserAlertHandler } from './alerts';
import type { BackgroundTaskHandler } from './background';
import type { ReportHandler } from './reports';
import type { ReportScheduleHandler } from './schedules';

export interface HandlersContextType {
  alerts: UserAlertHandler[];
  background: BackgroundTaskHandler[];
  reports: ReportHandler[];
  reportSchedules: ReportScheduleHandler[];
}

const HandlersContext = React.createContext<HandlersContextType>({
  alerts: [],
  background: [],
  reports: [],
  reportSchedules: [],
});

export default HandlersContext;
