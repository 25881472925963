import { useAppNestedFullDataFilteredView } from '@/app/hooks';
import {
  storeIncomingForReconciliationFullDataParameters,
  storeOutgoingForReconciliationFullDataParameters,
} from '@/features/settlement-reconciliations/actions';
import useSettlementReconciliationMWTransfers from '@/features/settlement-reconciliations/hooks/useSettlementReconciliationMWTransfers';
import {
  makeSelectIncomingForReconciliationFullData,
  makeSelectOutgoingForReconciliationFullData,
} from '@/features/settlement-reconciliations/selectors';
import { MerchantWalletTransferDirectionAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export default function useSettlementReconciliationMWTransfersView(
  direction: MerchantWalletTransferDirectionAPIModel,
  settlementId: string | undefined,
) {
  return useAppNestedFullDataFilteredView(
    useSettlementReconciliationMWTransfers(direction, settlementId),
    direction === MerchantWalletTransferDirectionAPIModel.Outgoing
      ? makeSelectOutgoingForReconciliationFullData
      : makeSelectIncomingForReconciliationFullData,
    direction === MerchantWalletTransferDirectionAPIModel.Outgoing
      ? storeOutgoingForReconciliationFullDataParameters
      : storeIncomingForReconciliationFullDataParameters,
    settlementId,
  );
}
