import { css } from '@emotion/css';
import { Alert, Button } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureGlobal } from '@/generated/i18n/i18n';

import type { CookiesBannerMessageProps } from './types';

const blockStyles = css`
  width: 100%;
  position: fixed;
  z-index: 1000;
  bottom: 0;
`;

const CookiesBannerMessage: React.FC<CookiesBannerMessageProps> = ({
  className,
  style,
  onAccept,
  'data-test': dataTest,
}) => (
  <div className={blockStyles}>
    <Alert
      type="info"
      data-test={dataTest}
      showIcon={false}
      message={<FormattedMessage id={I18nFeatureGlobal.COMPONENTS_COOKIES_BANNER_MESSAGE} />}
      banner
      className={className}
      style={style}
      action={
        <Button onClick={onAccept} data-test={dataTest && `${dataTest}-accept`}>
          <FormattedMessage id={I18nFeatureGlobal.COMPONENTS_COOKIES_BANNER_BUTTON_TITLE} />
        </Button>
      }
    />
  </div>
);

const CookiesBannerMessageMemo = React.memo(CookiesBannerMessage);

export default CookiesBannerMessageMemo;
