import { Alert, Space } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { AssetsForm } from '@/features/dictionary/blockchain/components';
import type { AssetsInputRow } from '@/features/dictionary/blockchain/components';
import { useListAssets, useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { useMerchantGasWallets, useMerchantGasWalletsActions } from '@/features/gas-wallets/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useNotification, useStateMountSafe } from '@/hooks';
import { combine } from '@/infrastructure/model';
import { onlyUnique, suppressPromise, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { notEmpty } from '@/infrastructure/utils/ts';

import type { CompanyActiveAssetsEditFormProps } from './types';

const notificationOptions = {
  error: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_MESSAGE_ERROR} />
  ),
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_MESSAGE_SUCCESS} />
  ),
};

const CompanyActiveAssetsEditForm: React.FC<CompanyActiveAssetsEditFormProps> = ({
  isNew,
  onSubmit,
  silent,
  ...props
}) => {
  const { 'data-test': dataTest, selected } = props;

  const { network } = useSelectedNetwork();
  const assetsState = useListAssets(network);
  const walletsState = useMerchantGasWallets();
  const [selectedAssets, setSelectedAssets] = useStateMountSafe(selected);

  const newBlockchains = useMemo(
    () =>
      combine(walletsState.data, assetsState.data, (gasWallets, assets) => {
        const walletBlockchains = gasWallets.map(({ bt }) => bt);
        return assets
          .filter(({ code }) => selectedAssets.includes(code))
          .map(({ blockchain }) => blockchain)
          .filter(notEmpty)
          .filter(onlyUnique)
          .filter((bt) => !walletBlockchains.includes(bt));
      }),
    [assetsState.data, selectedAssets, walletsState.data],
  );
  const onAssetSelection = useCallback(
    (newAssets: AssetsInputRow[]) =>
      setSelectedAssets(newAssets.filter(({ isActive }) => isActive).map(({ code }) => code)),
    [setSelectedAssets],
  );

  const { updateActiveAssets: updateAssetsAction } = useCompanySettingsActions();
  const { create } = useMerchantGasWalletsActions();
  const { withNotification } = useNotification();

  const doSubmit = useMemo(
    () =>
      withSuppressError(
        withOnSuccess(
          withNotification(
            async (activeAssets: string[]) => {
              const result = await updateAssetsAction.act(activeAssets);
              if (newBlockchains.data?.length) {
                suppressPromise(Promise.all([newBlockchains.data.map((bt) => create.act(bt))]), (e) => console.warn(e));
              }
              return result;
            },
            !silent ? notificationOptions : {},
          ),
          onSubmit,
        ),
      ),
    [withNotification, silent, onSubmit, updateAssetsAction, newBlockchains, create],
  );

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      {!!newBlockchains.data?.length && (
        <Alert
          data-test={dataTest && `${dataTest}-blockchains`}
          type="info"
          style={{ width: '100%' }}
          message={
            <FormattedMessageWithMarkup
              id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_WALLETS_ALERT_MESSAGE}
              values={{ blockchains: <b>{newBlockchains.data.join(', ')}</b> }}
            />
          }
        />
      )}
      <AssetsForm
        {...props}
        readonly={false}
        onChange={onAssetSelection}
        onSubmit={doSubmit}
        description={
          <FormattedMessageWithMarkup
            id={I18nFeatureCompanySettings.COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_DESCRIPTION}
          />
        }
      />
    </Space>
  );
};

const CompanyActiveAssetsEditFormMemo = React.memo(CompanyActiveAssetsEditForm);

export default CompanyActiveAssetsEditFormMemo;
