import { useContext, useMemo } from 'react';

import type { ReCaptchaParams } from '@/features/recaptcha/types';
import type { Func } from '@/infrastructure/utils/ts';

import ReCaptchaActionContext from '../hocs/withReCaptchaSupport/context';

export interface UseReCaptchaActionType {
  withRecaptcha: <T extends unknown[] = [], R = unknown>(
    func: (v: ReCaptchaParams) => Func<T, R>,
  ) => (...args: T) => Promise<R>;
  initialized: boolean;
}

const useReCaptchaAction = (action: string): UseReCaptchaActionType => {
  const { withReCaptchaAction, initialized } = useContext(ReCaptchaActionContext);
  const withRecaptcha = useMemo(
    () =>
      withReCaptchaAction
        ? withReCaptchaAction(action)
        : () => () => {
            throw new Error('Not initialized');
          },
    [action, withReCaptchaAction],
  );
  return { withRecaptcha, initialized: initialized && !!withReCaptchaAction };
};

export default useReCaptchaAction;
