import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import ReportSchedulesIcon from '@/features/report-schedules/components/ReportSchedulesIcon';
import ReportsIcon from '@/features/reports/components/ReportsIcon';
import { I18nMenu } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';
import type { CustomizableRoute } from '@/layouts/types';
import {
  reportSchedulesLink,
  reportSchedulesRoute,
  ReportSchedulesRoutes,
  reportsLink,
  ReportsPageRoutes,
  reportsRoute,
} from '@/pages/reports/routes';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (selected: boolean, formatMessage: IntlFormattersArrow['formatMessage']): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.REPORTS_MAIN }),
    key: appRoute(AppPageRoutes.REPORTS),
    icon: <ReportsIcon />,
    path: reportsLink(),
    hideChildrenInMenu: !selected,
    children: [
      {
        name: formatMessage({ id: I18nMenu.REPORTS_REPORTS }),
        icon: <ReportsIcon />,
        path: reportsLink(),
        hideChildrenInMenu: true,

        children: [ReportsPageRoutes.REPORT_VIEW].map((route) => reportsRoute(route)).map((path) => ({ path })),
      },
      {
        name: formatMessage({ id: I18nMenu.REPORTS_SCHEDULES }),
        icon: <ReportSchedulesIcon />,
        path: reportSchedulesLink(),
        hideChildrenInMenu: true,

        children: enumValues(ReportSchedulesRoutes)
          .map((route) => reportSchedulesRoute(route))
          .map((path) => ({ path })),
      },
    ],
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.REPORTS));
  return useMemo(() => menuCreator(selected, formatMessage), [formatMessage, selected]);
}
