import { Form } from 'antd';
import React from 'react';

import { defaultDialogFormLayout } from '@/components';
import type { ReportScheduleParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/schedules';

import PaymentsReportScheduleParametersForm from '../PaymentsReportScheduleParametersForm';

const PaymentsReportScheduleParametersView: React.FC<ReportScheduleParametersViewProps> = ({
  value,
  'data-test': dataTest,
}) => (
  <Form initialValues={{ parameters: value }} {...defaultDialogFormLayout}>
    <PaymentsReportScheduleParametersForm data-test={dataTest} name="parameters" readonly />
  </Form>
);

const PaymentsReportScheduleParametersViewMemo = React.memo(PaymentsReportScheduleParametersView);

export default PaymentsReportScheduleParametersViewMemo;
