import { PageContainer } from '@/components';
import { CollectTaskForAddressesFilterForm } from '@/features/collectable/components';
import { DonationsDocumentationBanner, DonationTransactionFilterForm } from '@/features/donations/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import CollectTaskLink from '@/pages/collectable/task-view/components/CollectTaskLink';
import DonationTransactionViewLink from '@/pages/donations/transaction-view/components/DonationTransactionLink';

import { withAddressPageParams } from '../hocs';

import {
  DonationCard,
  DonationAssetsCard,
  DonationTransactionsCard,
  DonationViewBreadcrumb,
  DonationCollectTasksCard,
} from './components';

import type React from 'react';

type DonationViewPageProps = TestableProps & { pageParams: { donationId: string } };

const DonationViewPage: React.FC<DonationViewPageProps> = ({
  'data-test': dataTest = 'address',
  pageParams: { donationId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <DonationViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} donationId={donationId} disabled />
    }
  >
    <DonationsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <DonationCard data-test={dataTest && `${dataTest}-card`} donationId={donationId} />
    <DonationAssetsCard data-test={dataTest && `${dataTest}-assets`} donationId={donationId} />
    <DonationTransactionsCard
      data-test={dataTest && `${dataTest}-transactions`}
      donationId={donationId}
      DonationTransactionLink={DonationTransactionViewLink}
      FilterForm={DonationTransactionFilterForm}
    />
    <DonationCollectTasksCard
      data-test={dataTest && `${dataTest}-tasks`}
      donationId={donationId}
      CollectTaskLink={CollectTaskLink}
      FilterForm={CollectTaskForAddressesFilterForm}
    />
  </PageContainer>
);

export default withAddressPageParams(DonationViewPage);
