import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { donationsLink } from '@/pages/donations/routes';

import type { DonationsLinkProps } from './types';

const DonationsLink: React.FC<DonationsLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={donationsLink()}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageDonations.DONATIONS_COMPONENTS_LINK_TITLE} />}
  />
);

const DonationsLinkMemo = React.memo(DonationsLink);

export default DonationsLinkMemo;
