import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useRechargeTransaction } from '@/features/recharges/hooks';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';

import type { OperationRefreshRechargeTransactionProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_TRANSACTION_TOOLTIP} />,
  success: {
    message: (
      <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_TRANSACTION_SUCCESS_MESSAGE} />
    ),
  },
};

const OperationRefreshRechargeTransaction: React.FC<OperationRefreshRechargeTransactionProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  transactionId,
}) => {
  const { forceRefresh } = useRechargeTransaction(transactionId);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshRechargeTransactionMemo = React.memo(OperationRefreshRechargeTransaction);

export default OperationRefreshRechargeTransactionMemo;
