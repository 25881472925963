import React from 'react';

import { useSubscription } from '@/features/subscriptions/hooks';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import OperationPauseSubscription from '../OperationPauseSubscription';
import OperationUnpauseSubscription from '../OperationUnpauseSubscription';

import type { OperationUpdateSubscriptionStatusProps } from './types';

const OperationUpdateSubscriptionStatus: React.FC<OperationUpdateSubscriptionStatusProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  subscriptionId,
}) => {
  const { data: subscription } = useSubscription(subscriptionId);

  return (
    <>
      {subscription.data?.status === SubscriptionStatusAPIModel.Paused ? (
        <OperationUnpauseSubscription
          subscriptionId={subscriptionId}
          data-test={dataTest && `${dataTest}-unpause`}
          mode={mode}
        />
      ) : (
        <OperationPauseSubscription
          subscriptionId={subscriptionId}
          data-test={dataTest && `${dataTest}-pause`}
          mode={mode}
        />
      )}
    </>
  );
};

const OperationUpdateSubscriptionStatusMemo = React.memo(OperationUpdateSubscriptionStatus);

export default OperationUpdateSubscriptionStatusMemo;
