import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useCompanyUserActions } from '@/features/company/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import type { OperationRemoveCompanyUserProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REMOVE_COMPANY_USER_ERROR_MESSAGE} />,
  success: () => <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REMOVE_COMPANY_USER_SUCCESS_MESSAGE} />,
};

const OperationRemoveCompanyUser: React.FC<OperationRemoveCompanyUserProps> = ({
  'data-test': dataTest,
  address,
  confirmation,
  mode = 'inline',
  onSuccess,
}) => {
  const { withNotification } = useNotification();
  const { remove: removeAction } = useCompanyUserActions(address);

  const deleteUser = useMemo(
    () =>
      withSuppressError(
        withOnSuccess(withNotification(removeAction.act, notificationOptions), () => {
          goalReached(YMGoals.COMPANY_USER_REMOVED);
          onSuccess?.();
        }),
      ),
    [onSuccess, removeAction.act, withNotification],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? {
            title: (
              <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REMOVE_COMPANY_USER_CONFIRMATION_TITLE} />
            ),
          }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REMOVE_COMPANY_USER_TITLE} />}
      icon={<DeleteOutlined />}
      onClick={deleteUser}
      disabled={!removeAction.available}
      inProgress={removeAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationRemoveCompanyUserMemo = React.memo(OperationRemoveCompanyUser);

export default OperationRemoveCompanyUserMemo;
