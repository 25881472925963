import { StopOutlined } from '@ant-design/icons';
import { css, cx } from '@emotion/css';
import { Alert, theme } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import type { WalletType } from '@/features/web3/components/WalletLink/types';
import { walletTypes } from '@/features/web3/components/WalletLink/types';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';
import { useDefaultNotification, useStateMountSafe, useSubmitting } from '@/hooks';
import { emptyWith, noop, withLogError } from '@/infrastructure/utils/functions';
import { cancellable, rejection } from '@/infrastructure/utils/ui';

import WalletInProgress from '../WalletInProgress';
import WalletLink from '../WalletLink';
import Web3ProviderIcon from '../Web3ProviderIcon';

import type { WalletConnectProps } from './types';

const WalletConnect: React.FC<WalletConnectProps> = ({
  'data-test': dataTest,
  isConnecting: baseIsConnection,
  onlyLocal,
  disabled,
  onConnect = noop,
  requiredAccount,
  withConnect = emptyWith,
  inProgressMode = 'button',
}) => {
  const { token } = theme.useToken();
  const styles = useMemo(
    () => ({
      container: css`
        width: 100%;
        display: flex;
      `,
      operation: css`
        display: flex;
        flex: 1;
        align-items: center;
        margin-left: auto;
        width: 100%;
      `,
      cancellable: css`
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      `,
      cancel: css`
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;

        > button {
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
        }
      `,
      icon: css`
        display: flex;
      `,
      description: css`
        text-align: center;
        padding-top: ${token.paddingLG};
      `,
    }),
    [token.paddingLG],
  );

  const { connector, refreshAccount, account, isLocal, isReady } = useWeb3Connector();
  const [onCancel, setOnCancel] = useStateMountSafe<(() => void) | undefined>();
  const { withDefaultError } = useDefaultNotification();
  const [submitting, withConnecting] = useSubmitting(false);
  const isConnecting = submitting || baseIsConnection;

  const doConnect = useMemo(
    () =>
      withLogError(
        withDefaultError(
          withConnect(
            withConnecting(async () => {
              try {
                const cancellationPromise = cancellable((cancel) => setOnCancel(() => cancel));

                const result = await Promise.race([connector.connect(), cancellationPromise]);
                if (result === rejection) {
                  await connector.disconnect();
                } else {
                  const newAccount = await refreshAccount();
                  if (!requiredAccount || requiredAccount === newAccount) {
                    await onConnect();
                  }
                }
              } finally {
                setOnCancel(undefined);
              }
            }),
          ),
        ),
      ),
    [withConnect, withConnecting, withDefaultError, connector, setOnCancel, onConnect, refreshAccount, requiredAccount],
  );

  if (!isReady || (onlyLocal && !isLocal)) {
    return (
      <Alert
        data-test={dataTest && `${dataTest}-notSupported`}
        showIcon
        message={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_NOT_SUPPORTED}
            values={{
              provider: walletTypes.find((v) => v === connector.id) ? (
                <WalletLink type={connector.id as WalletType} />
              ) : (
                <b>{connector.name}</b>
              ),
            }}
            tagName="span"
          />
        }
      />
    );
  }

  if (account) {
    return null;
  }

  if (isConnecting && inProgressMode === 'view') {
    return (
      <WalletInProgress
        data-test={dataTest}
        connector={connector}
        // Metamask fails to restore if the connection has been cancelled
        // cancel={
        //   onCancel && {
        //     onAction: onCancel,
        //     title: <FormattedMessage id="auth.components.wallet-connect.cancel-connect.title" />,
        //   }
        // }
        message={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_DESCRIPTION}
            values={{ provider: <b>{connector.name}</b> }}
          />
        }
      />
    );
  }

  return (
    <div className={styles.container}>
      <Operation
        ButtonProps={{ size: 'large' }}
        title={
          <FormattedMessage
            id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_CONNECT_TITLE}
            values={{ provider: connector.name }}
          />
        }
        className={cx(styles.operation, onCancel && styles.cancellable)}
        data-test={dataTest && `${dataTest}-submit`}
        inProgress={isConnecting}
        disabled={disabled}
        icon={<Web3ProviderIcon value={connector.id} className={styles.icon} />}
        onClick={doConnect}
        mode="button"
      />
      {onCancel && (
        <Operation
          ButtonProps={{ size: 'large' }}
          className={styles.cancel}
          title={<FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_CONNECT_CANCEL_CONNECT_TITLE} />}
          data-test={dataTest && `${dataTest}-cancel`}
          inProgress={isConnecting}
          icon={<StopOutlined />}
          onClick={onCancel}
          mode="inline"
        />
      )}
    </div>
  );
};

const WalletConnectWithConnector = withWeb3Connector<WalletConnectProps>(WalletConnect);

const WalletConnectMemo = React.memo(WalletConnectWithConnector) as typeof WalletConnectWithConnector;

export default WalletConnectMemo;
