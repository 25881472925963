import React, { useMemo } from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';
import ReadonlyComponent from '../ReadonlyComponent';

import type { DurationViewProps } from './types';

const DurationViewRaw: React.FC<DurationViewProps> = ({ 'data-test': dataTest, value, mode }) => {
  const { seconds, minutes, hours, days } = useMemo(
    () => ({
      seconds: value % 60,
      minutes: Math.floor(value / 60) % 60,
      hours: Math.floor(value / 60 / 60) % 24,
      days: Math.floor(value / 60 / 60 / 24),
    }),
    [value],
  );

  const formatted = useMemo(
    () => (
      <>
        {[
          (mode === 'short' || !!days) && (
            <FormattedMessage id={I18nComponents.DURATION_VIEW_DAYS} values={{ value: days }} />
          ),
          mode === 'full' && !!hours && (
            <FormattedMessage id={I18nComponents.DURATION_VIEW_HOURS} values={{ value: hours }} />
          ),
          mode === 'full' && !!minutes && (
            <FormattedMessage id={I18nComponents.DURATION_VIEW_MINUTES} values={{ value: minutes }} />
          ),
          mode === 'full' && !!seconds && (
            <FormattedMessage id={I18nComponents.DURATION_VIEW_SECONDS} values={{ value: seconds }} />
          ),
        ]
          .filter((v) => !!v)
          .reduce((r, v) => (
            <>
              <>{r} </>
              {v}
            </>
          ))}
      </>
    ),
    [days, hours, minutes, mode, seconds],
  );
  return <ReadonlyComponent data-test={dataTest} value={formatted} />;
};

const DurationView = React.memo(DurationViewRaw);

export default DurationView;
