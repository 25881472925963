import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import {
  createBrandingProfile,
  removeBrandingProfile,
  updateBrandingProfile,
  updateBrandingProfileState,
} from '@/features/branding/actions';
import type { MerchantBrandingProfileContent, MerchantBrandingProfileFull } from '@/features/branding/types';
import { defaultBranding } from '@/features/branding/utils';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { useActionPending } from '@/features/global/hooks';
import { type HookAction } from '@/infrastructure/model';

export interface UseBrandingProfilesActions {
  prepareDefault: () => MerchantBrandingProfileContent;
  create: HookAction<[MerchantBrandingProfileContent], MerchantBrandingProfileFull>;
  update: HookAction<[string, MerchantBrandingProfileContent]>;
  remove: HookAction<[string]>;
  activate: HookAction<[string]>;
  deactivate: HookAction<[string]>;
}

export default function useBrandingProfilesActions(): UseBrandingProfilesActions {
  const { withExtractDataDispatch } = useAppDispatch();

  const { data: settings } = useCompanySettings();

  const createDefault: UseBrandingProfilesActions['prepareDefault'] = useCallback(
    () => ({ data: { ...defaultBranding(), contactEmail: settings.data?.email }, name: 'Default Profile' }),
    [settings.data?.email],
  );

  const createPending = useActionPending(createBrandingProfile);
  const createAction: UseBrandingProfilesActions['create']['act'] = useMemo(
    () => withExtractDataDispatch(createBrandingProfile),
    [withExtractDataDispatch],
  );

  const create: UseBrandingProfilesActions['create'] = useMemo(
    () => ({ act: createAction, available: true, inAction: createPending }),
    [createAction, createPending],
  );

  const updatePending = useActionPending(updateBrandingProfile);
  const updateAction: UseBrandingProfilesActions['update']['act'] = useCallback(
    (profileId, content) => withExtractDataDispatch(updateBrandingProfile)({ id: profileId, content }),
    [withExtractDataDispatch],
  );
  const update: UseBrandingProfilesActions['update'] = useMemo(
    () => ({ act: updateAction, available: true, inAction: updatePending }),
    [updateAction, updatePending],
  );

  const removePending = useActionPending(removeBrandingProfile);
  const removeAction: UseBrandingProfilesActions['remove']['act'] = useCallback(
    (profileId) => withExtractDataDispatch(removeBrandingProfile)(profileId),
    [withExtractDataDispatch],
  );
  const remove: UseBrandingProfilesActions['remove'] = useMemo(
    () => ({ act: removeAction, available: true, inAction: removePending }),
    [removeAction, removePending],
  );

  const updateStatePending = useActionPending(updateBrandingProfileState);
  const activateAction: UseBrandingProfilesActions['activate']['act'] = useCallback(
    (profileId) => withExtractDataDispatch(updateBrandingProfileState)({ id: profileId, activate: true }),
    [withExtractDataDispatch],
  );
  const activate: UseBrandingProfilesActions['activate'] = useMemo(
    () => ({ act: activateAction, available: true, inAction: updateStatePending }),
    [activateAction, updateStatePending],
  );

  const deactivateAction: UseBrandingProfilesActions['deactivate']['act'] = useCallback(
    (profileId) => withExtractDataDispatch(updateBrandingProfileState)({ id: profileId, activate: false }),
    [withExtractDataDispatch],
  );
  const deactivate: UseBrandingProfilesActions['activate'] = useMemo(
    () => ({ act: deactivateAction, available: false, inAction: updateStatePending }),
    [deactivateAction, updateStatePending],
  );
  return { create, prepareDefault: createDefault, update, remove, activate, deactivate };
}
