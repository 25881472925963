/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { WithdrawalFeeActiveTransactionAPIModel } from './WithdrawalFeeActiveTransactionAPIModel';
import {
    WithdrawalFeeActiveTransactionAPIModelFromJSON,
    WithdrawalFeeActiveTransactionAPIModelFromJSONTyped,
    WithdrawalFeeActiveTransactionAPIModelToJSON,
} from './WithdrawalFeeActiveTransactionAPIModel';

/**
 * 
 * @export
 * @interface WithdrawalFeePaymentAPIModel
 */
export interface WithdrawalFeePaymentAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalFeePaymentAPIModel
     */
    unpaidAmount: string;
    /**
     * 
     * @type {WithdrawalFeeActiveTransactionAPIModel}
     * @memberof WithdrawalFeePaymentAPIModel
     */
    activeTransaction?: WithdrawalFeeActiveTransactionAPIModel;
}

/**
 * Check if a given object implements the WithdrawalFeePaymentAPIModel interface.
 */
export function instanceOfWithdrawalFeePaymentAPIModel(value: object): boolean {
    if (!('unpaidAmount' in value)) return false;
    return true;
}

export function WithdrawalFeePaymentAPIModelFromJSON(json: any): WithdrawalFeePaymentAPIModel {
    return WithdrawalFeePaymentAPIModelFromJSONTyped(json, false);
}

export function WithdrawalFeePaymentAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalFeePaymentAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'unpaidAmount': json['unpaidAmount'],
        'activeTransaction': json['activeTransaction'] == null ? undefined : WithdrawalFeeActiveTransactionAPIModelFromJSON(json['activeTransaction']),
    };
}

export function WithdrawalFeePaymentAPIModelToJSON(value?: WithdrawalFeePaymentAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'unpaidAmount': value['unpaidAmount'],
        'activeTransaction': WithdrawalFeeActiveTransactionAPIModelToJSON(value['activeTransaction']),
    };
}

