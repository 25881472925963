/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  AddressAssetHistoryBalanceAPIModel,
  ApiErrorAPIModel,
  GetAssetsResponseAPIModel,
  GetBlockchainsResponseAPIModel,
  GetEventTypesResponseAPIModel,
} from '../models/index';
import {
    AddressAssetHistoryBalanceAPIModelFromJSON,
    AddressAssetHistoryBalanceAPIModelToJSON,
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    GetAssetsResponseAPIModelFromJSON,
    GetAssetsResponseAPIModelToJSON,
    GetBlockchainsResponseAPIModelFromJSON,
    GetBlockchainsResponseAPIModelToJSON,
    GetEventTypesResponseAPIModelFromJSON,
    GetEventTypesResponseAPIModelToJSON,
} from '../models/index';

export interface GetAddressAssetBlockBalanceRequest {
    address: string;
    asset: string;
    blockNum: string;
}

/**
 * DictionaryApi - interface
 * 
 * @export
 * @interface DictionaryApiInterface
 */
export interface DictionaryApiInterface {
    /**
     * Dictionary of assets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApiInterface
     */
    assetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetsResponseAPIModel>>;

    /**
     * Dictionary of assets
     */
    assets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetsResponseAPIModel>;

    /**
     * Dictionary of blockchain types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApiInterface
     */
    blockchainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBlockchainsResponseAPIModel>>;

    /**
     * Dictionary of blockchain types
     */
    blockchains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBlockchainsResponseAPIModel>;

    /**
     * Dictionary of event types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApiInterface
     */
    eventTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEventTypesResponseAPIModel>>;

    /**
     * Dictionary of event types
     */
    eventTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEventTypesResponseAPIModel>;

    /**
     * Address asset balance for the particular block
     * @param {string} address 
     * @param {string} asset 
     * @param {string} blockNum 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApiInterface
     */
    getAddressAssetBlockBalanceRaw(requestParameters: GetAddressAssetBlockBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddressAssetHistoryBalanceAPIModel>>;

    /**
     * Address asset balance for the particular block
     */
    getAddressAssetBlockBalance(address: string, asset: string, blockNum: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddressAssetHistoryBalanceAPIModel>;

    /**
     * Dictionary of globally supported assets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DictionaryApiInterface
     */
    supportedAssetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetsResponseAPIModel>>;

    /**
     * Dictionary of globally supported assets
     */
    supportedAssets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetsResponseAPIModel>;

}

/**
 * 
 */
export class DictionaryApi extends runtime.BaseAPI implements DictionaryApiInterface {

    /**
     * Dictionary of assets
     */
    async assetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetsResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssetsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Dictionary of assets
     */
    async assets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetsResponseAPIModel> {
        const response = await this.assetsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Dictionary of blockchain types
     */
    async blockchainsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBlockchainsResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dictionary/blockchains`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBlockchainsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Dictionary of blockchain types
     */
    async blockchains(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBlockchainsResponseAPIModel> {
        const response = await this.blockchainsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Dictionary of event types
     */
    async eventTypesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEventTypesResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/dictionary/event-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEventTypesResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Dictionary of event types
     */
    async eventTypes(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEventTypesResponseAPIModel> {
        const response = await this.eventTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Address asset balance for the particular block
     */
    async getAddressAssetBlockBalanceRaw(requestParameters: GetAddressAssetBlockBalanceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddressAssetHistoryBalanceAPIModel>> {
        if (requestParameters['address'] == null) {
            throw new runtime.RequiredError(
                'address',
                'Required parameter "address" was null or undefined when calling getAddressAssetBlockBalance().'
            );
        }

        if (requestParameters['asset'] == null) {
            throw new runtime.RequiredError(
                'asset',
                'Required parameter "asset" was null or undefined when calling getAddressAssetBlockBalance().'
            );
        }

        if (requestParameters['blockNum'] == null) {
            throw new runtime.RequiredError(
                'blockNum',
                'Required parameter "blockNum" was null or undefined when calling getAddressAssetBlockBalance().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/balance/{address}/{asset}/{blockNum}`.replace(`{${"address"}}`, encodeURIComponent(String(requestParameters['address']))).replace(`{${"asset"}}`, encodeURIComponent(String(requestParameters['asset']))).replace(`{${"blockNum"}}`, encodeURIComponent(String(requestParameters['blockNum']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddressAssetHistoryBalanceAPIModelFromJSON(jsonValue));
    }

    /**
     * Address asset balance for the particular block
     */
    async getAddressAssetBlockBalance(address: string, asset: string, blockNum: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddressAssetHistoryBalanceAPIModel> {
        const response = await this.getAddressAssetBlockBalanceRaw({ address: address, asset: asset, blockNum: blockNum }, initOverrides);
        return await response.value();
    }

    /**
     * Dictionary of globally supported assets
     */
    async supportedAssetsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAssetsResponseAPIModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/dictionary/supported-assets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAssetsResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Dictionary of globally supported assets
     */
    async supportedAssets(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAssetsResponseAPIModel> {
        const response = await this.supportedAssetsRaw(initOverrides);
        return await response.value();
    }

}
