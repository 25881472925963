import { PageContainer } from '@/components';

import { ReportScheduleViewBreadcrumb, ReportScheduleCard, ReportScheduleViewOperations } from './components';
import { withReportScheduleViewPageParams } from './hocs';

import type { ReportSchedulePageProps } from './types';
import type React from 'react';

const ReportScheduleViewPageRaw: React.FC<ReportSchedulePageProps> = ({
  'data-test': dataTest = 'report',
  pageParams: { id },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <ReportScheduleViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled scheduleId={id} />
    }
  >
    <ReportScheduleCard
      data-test={dataTest && `${dataTest}-card`}
      scheduleId={id}
      Operations={ReportScheduleViewOperations}
    />
  </PageContainer>
);

const ReportScheduleViewPage = withReportScheduleViewPageParams(ReportScheduleViewPageRaw);

export default ReportScheduleViewPage;
