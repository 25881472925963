import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import SubscriptionsBreadcrumb from '@/pages/subscriptions/all/components/SubscriptionsBreadcrumb';

import SubscriptionStatisticsLink from '../SubscriptionStatisticsLink';

import type { SubscriptionStatisticsBreadcrumbProps } from './types';

const SubscriptionStatisticsBreadcrumb: React.FC<SubscriptionStatisticsBreadcrumbProps> = ({
  'data-test': dataTest,
  items = [],
  disabled,
}) => (
  <SubscriptionsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSubscriptions.STATISTICS_COMPONENTS_BREADCRUMB} />
        ) : (
          <SubscriptionStatisticsLink
            mode="text"
            title={<FormattedMessage id={I18nPageSubscriptions.STATISTICS_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const SubscriptionStatisticsBreadcrumbMemo = React.memo(SubscriptionStatisticsBreadcrumb);

export default SubscriptionStatisticsBreadcrumbMemo;
