import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import DonationAddPage from './donation-add';
import DonationAssetViewPage from './donation-asset-view';
import DonationEditPage from './donation-edit';
import DonationViewPage from './donation-view';
import DonationsPage from './donations';
import { DonationsPageRoutes, donationsLink } from './routes';
import DonationTransactionViewPage from './transaction-view';
import DonationTransactionsPage from './transactions';

import type React from 'react';

export const DonationsDefaultRoute: React.FC = () => <Navigate to={donationsLink()} replace />;

const DonationPagesRaw: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={DonationsPageRoutes.DONATIONS} Component={DonationsPage} />
        <Route path={DonationsPageRoutes.DONATION_NEW} Component={DonationAddPage} />
        <Route path={DonationsPageRoutes.DONATION_EDIT} Component={DonationEditPage} />
        <Route path={DonationsPageRoutes.DONATION_VIEW} Component={DonationViewPage} />
        <Route path={DonationsPageRoutes.DONATION_ASSET_VIEW} Component={DonationAssetViewPage} />
        <Route path={DonationsPageRoutes.TRANSACTIONS} Component={DonationTransactionsPage} />
        <Route path={DonationsPageRoutes.TRANSACTION_VIEW} Component={DonationTransactionViewPage} />
        <Route path="*" Component={DonationsDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const DonationPages: React.FC = withBasicPageGuard(DonationPagesRaw);

export default <Route key={AppPageRoutes.DONATIONS} path={`${AppPageRoutes.DONATIONS}/*`} Component={DonationPages} />;
