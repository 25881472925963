import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { queryMultiplePaymentTransactions, queryPaymentTransaction } from '@/features/payment-transactions/api';
import {
  makeSelectDirtyPaymentTransactionIds,
  makeSelectMultiplePaymentTransaction,
  makeSelectPaymentTransaction,
} from '@/features/payment-transactions/selectors';
import { searchIdToStoreKey, txToSearchId } from '@/features/payment-transactions/utils';
import type { PaymentTransactionSearchIdAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { withApiRequest } from '@/infrastructure/model';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';

import { NAMESPACE, type PaymentTransaction } from './types';

export const { storePaymentTransaction, storeMultiplePaymentTransaction, markPaymentTransactionDirty } =
  createSingleActions<PaymentTransaction, 'PaymentTransaction', PaymentTransactionSearchIdAPIModel>(
    NAMESPACE,
    'PaymentTransaction',
  );

const transactionFetchLimit = pLimit(1);
export const fetchPaymentTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchPaymentTransaction`,
  async ({ force, id }: { force?: boolean; id: PaymentTransactionSearchIdAPIModel }, { dispatch, getState, signal }) =>
    transactionFetchLimit(async () => {
      const saved = makeSelectPaymentTransaction(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withApiRequest(queryPaymentTransaction, 'unable to fetch transaction')(id, { signal });
      dispatch(storePaymentTransaction({ id, data }));

      return makeSelectPaymentTransaction(id)(getState());
    }),
  { idGenerator: ({ id }) => searchIdToStoreKey(id) },
);

const transactionsFetchLimit = pLimit(1);
export const fetchMultiplePaymentTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultiplePaymentTransaction`,
  async (
    { force, ids }: { force?: boolean; ids: PaymentTransactionSearchIdAPIModel[] },
    { dispatch, getState, signal },
  ) =>
    transactionsFetchLimit(async () => {
      const absent = makeSelectDirtyPaymentTransactionIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultiplePaymentTransaction(ids)(getState());
      }

      const data = await withApiRequest(queryMultiplePaymentTransactions, 'unable to fetch transactions')(absent, {
        signal,
      });
      dispatch(storeMultiplePaymentTransaction(toMultiplePayload(data, ids, txToSearchId, searchIdToStoreKey)));

      return makeSelectMultiplePaymentTransaction(ids)(getState());
    }),
);
