import React from 'react';

import { DateTimeRelativeItem, FormattedMessage } from '@/components';
import { NetworkTypeItem } from '@/features/dictionary/blockchain/components';
import { SettlementReconciliationStatusFilterItem } from '@/features/settlement-reconciliations/components/SettlementReconciliationFilterForm/components';
import type { SettlementReconciliationReportPredicates } from '@/features/settlement-reconciliations/types';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { SettlementReconciliationScheduleParametersFormProps } from './types';

const SettlementReconciliationScheduleParametersForm: React.FC<SettlementReconciliationScheduleParametersFormProps> = ({
  'data-test': dataTest,
  name,
  readonly,
}) => (
  <>
    <DateTimeRelativeItem
      data-test={dataTest && `${dataTest}-settledAt`}
      name={[name, nameof<SettlementReconciliationReportPredicates>('withdrawnAtRangeRelative')]}
      label={
        <FormattedMessage
          id={
            I18nFeatureSettlementReconciliations.COMPONENTS_RECONCILIATION_REPORT_SCHEDULE_PARAMETERS_SETTLED_AT_LABEL
          }
        />
      }
      readonly={readonly}
      required={false}
    />
    <SettlementReconciliationStatusFilterItem
      data-test={dataTest && `${dataTest}-status`}
      name={[name, nameof<SettlementReconciliationReportPredicates>('statusIn')]}
      readonly={readonly}
      required={false}
    />
    <NetworkTypeItem
      data-test={dataTest && `${dataTest}-network`}
      name={[name, nameof<SettlementReconciliationReportPredicates>('network')]}
      readonly={readonly}
      required={false}
      mode="select"
    />
  </>
);

const SettlementReconciliationScheduleParametersFormMemo = React.memo(SettlementReconciliationScheduleParametersForm);

export default SettlementReconciliationScheduleParametersFormMemo;
