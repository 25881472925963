import React from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { useSettlement } from '@/features/settlements/hooks';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { settlementViewLink } from '@/pages/settlements/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { SettlementEntityLinkProps } from './types';

const SettlementEntityLink: React.FC<SettlementEntityLinkProps> = ({ 'data-test': dataTest, value }) => {
  const data = useSettlement(value.entityId);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return <Navigate to={settlementViewLink(data.data.data.id)} replace />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.Withdrawal}
      onRetry={data.forceRefresh}
    />
  );
};

const SettlementEntityLinkMemo = React.memo(SettlementEntityLink);

export default SettlementEntityLinkMemo;
