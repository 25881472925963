import { App as AntApp } from 'antd';
import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';
import hljs from 'highlight.js/lib/core';
import hljsJson from 'highlight.js/lib/languages/json';
// best theme for json text view
import 'highlight.js/styles/stackoverflow-dark.min.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { withStore } from '@/app/hocs';
import withAuthorizationConsistencyGuard from '@/features/auth/hocs/withAuthorizationConsistencyGuard';
import withAuthorizationInit from '@/features/auth/hocs/withAuthorizationInit';
import withWeb3AuthContext from '@/features/auth/hocs/withWeb3AuthContext';
import withWeb3AuthLifecycle from '@/features/auth/hocs/withWeb3AuthLifecycle';
import { withFeatureToggleInit } from '@/features/feature-toggle/hocs';
import {
  withCookiesAgreement,
  withGlobalNotifications,
  withHandlerRegistration,
  withIdleControl,
  withMobileDetection,
} from '@/features/global/hocs';
import withI18nDetection from '@/features/i18n/hocs/withI18nDetection';
import { withMaintenanceCheck } from '@/features/maintenance/hocs';
import withWeb3Support from '@/features/web3/hocs/withWeb3Support';
import { withErrorBoundary, withI18nProvider, withTheme } from '@/hocs';
import { withSentryInitialization } from '@/infrastructure/sentry';
import { withTawk } from '@/infrastructure/tawk/hocs';
import { withYandexMetrika } from '@/infrastructure/ym/hocs';
import {
  pagesAudit,
  pagesAuth,
  pagesBalances,
  pagesCallback,
  pagesCollectable,
  pagesDashboard,
  pagesDonations,
  pagesGasHistory,
  pagesOnboard,
  pagesPayments,
  pagesRecharges,
  pagesReports,
  pagesSettings,
  pagesSettlements,
  pagesSubscriptions,
} from '@/pages';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type React from 'react';

polyfillCountryFlagEmojis();

// doing a setup of 'json' only for a smaller bundle size
// see docs: https://highlightjs.org/
hljs.registerLanguage('json', hljsJson);

const DefaultRoute: React.FC = () => <Navigate to={appRoute(AppPageRoutes.AUTH)} />;

const MyApp = withGlobalNotifications(AntApp);

const App: React.FC = () => (
  <BrowserRouter>
    <MyApp>
      <Routes>
        {pagesAuth}
        {pagesAudit}
        {pagesCallback}
        {pagesCollectable}
        {pagesBalances}
        {pagesDashboard}
        {pagesDonations}
        {pagesGasHistory}
        {pagesOnboard}
        {pagesPayments}
        {pagesRecharges}
        {pagesReports}
        {pagesSettlements}
        {pagesSettings}
        {pagesSubscriptions}
        <Route path="*" Component={DefaultRoute} />
      </Routes>
    </MyApp>
  </BrowserRouter>
);

// prettier-ignore
const AppWithHOCs =
  withSentryInitialization(
    withStore(
      withWeb3Support(
        withI18nDetection(
          withI18nProvider(
            withTheme(
              withErrorBoundary(
                withMobileDetection(
                  withMaintenanceCheck(
                    withTawk(
                      withWeb3AuthContext(
                        withAuthorizationInit(
                          withAuthorizationConsistencyGuard(
                            withWeb3AuthLifecycle(
                              withFeatureToggleInit(
                                withYandexMetrika(
                                  withHandlerRegistration(
                                    withCookiesAgreement(
                                      withIdleControl(
                                        App,
                                      ),
                                    ),
                                  ),
                                ),
                              ),
                            ),
                          ),
                        ),
                      ),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

export default AppWithHOCs;
