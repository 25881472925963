import { QuestionCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { Form, Input, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { OperationConfirmEmail, OperationUpdateEmail } from '@/features/company-settings/components';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useSharedStyles } from '@/hooks';

import type { CompanyEmailItemProps } from './types';

const CompanyEmailItem: React.FC<CompanyEmailItemProps> = ({ 'data-test': dataTest, style, className }) => {
  const {
    data: { data: company },
  } = useCompanySettings();
  const { disabledTextInput } = useSharedStyles();
  return (
    <Form.Item
      label={
        <>
          <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_CONTACT_EMAIL_ITEM_LABEL} />
          &nbsp;
          <Tooltip
            title={
              <FormattedMessage
                id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_CONTACT_EMAIL_ITEM_HELP}
              />
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </>
      }
    >
      <Input
        style={style}
        className={cx(className, disabledTextInput, 'ym-hide-content')}
        suffix={
          <Space>
            <OperationConfirmEmail data-test={dataTest && `${dataTest}-emailConfirm`} mode="icon" />
            <OperationUpdateEmail data-test={dataTest && `${dataTest}-emailUpdate`} mode="icon" />
          </Space>
        }
        data-test={dataTest && `${dataTest}-email`}
        value={company?.email}
        disabled
      />
    </Form.Item>
  );
};

export default React.memo(CompanyEmailItem);
