import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';
import type { GasWallet } from '@/features/gas-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const EmptyComponentImpl: React.FC = () => null;

const withGasWalletLoad = <T extends { wallet: GasWallet }>(
  Component: React.ComponentType<Omit<T, 'wallet'> & { wallet: GasWallet }>,
  EmptyComponent: React.ComponentType,
) =>
  namedHOC<Omit<T, 'wallet'> & { wallet: GasWallet }, Omit<T, 'wallet'> & { bt: BlockchainTypeAPIModel }>(
    Component,
    'WithGasWalletLoad',
  )((props) => {
    const { bt } = props;
    const {
      data: { data: wallet },
    } = useMerchantGasWallet(bt);
    return wallet ? <Component {...props} wallet={wallet} /> : <EmptyComponent />;
  });

const withGasWallet = <T extends { wallet: GasWallet }>(
  Component: React.ComponentType<Omit<T, 'wallet'> & { wallet: GasWallet }>,
  EmptyComponent: React.ComponentType = EmptyComponentImpl,
) => {
  const WalletComponent = withGasWalletLoad<T>(Component, EmptyComponent);
  return namedHOC<Omit<T, 'wallet'> & { wallet: GasWallet }, T | (Omit<T, 'wallet'> & { bt: BlockchainTypeAPIModel })>(
    Component,
    'WithGasWallet',
  )((props) => ('wallet' in props ? <Component {...props} /> : <WalletComponent {...props} />));
};

export default withGasWallet;
