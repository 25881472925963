import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { SettlementsIcon } from '@/features/settlements/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementViewLink } from '@/pages/settlements/routes';

import type { SettlementViewLinkProps } from './types';

const SettlementViewLink: React.FC<SettlementViewLinkProps> = ({
  'data-test': dataTest,
  value,
  icon,
  mode = 'inline',
  title,
}) => (
  <Link
    data-test={dataTest}
    to={settlementViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageSettlements.SETTLEMENT_VIEW_COMPONENTS_LINK_TITLE} />}
    icon={icon ?? <SettlementsIcon />}
  />
);

const SettlementViewLinkMemo = React.memo(SettlementViewLink);

export default SettlementViewLinkMemo;
