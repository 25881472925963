import { Tooltip, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { ReadonlyComponent } from '@/components';
import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';
import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import BlockchainLink from '../BlockchainLink';
import { BlockchainLinkType } from '../BlockchainLink/model';

import type { TransactionLinkProps } from './types';

const { Text } = Typography;

const TransactionFormatter: Record<BlockchainTypeAPIModel, (tx: string) => string> = {
  [BlockchainTypeAPIModel.TronMainNet]: (tx) => tx.replace(/^0x/, ''),
  [BlockchainTypeAPIModel.TronNile]: (tx) => tx.replace(/^0x/, ''),
  [BlockchainTypeAPIModel.TronShasta]: (tx) => tx.replace(/^0x/, ''),
  [BlockchainTypeAPIModel.BinanceMainNet]: (tx) => tx,
  [BlockchainTypeAPIModel.BinanceTestNet]: (tx) => tx,
  [BlockchainTypeAPIModel.EthereumMainNet]: (tx) => tx,
  [BlockchainTypeAPIModel.EthereumGoerli]: (tx) => tx,
  [BlockchainTypeAPIModel.EthereumRopsten]: (tx) => tx,
  [BlockchainTypeAPIModel.EthereumSepolia]: (tx) => tx,
  [BlockchainTypeAPIModel.PolygonMainNet]: (tx) => tx,
  [BlockchainTypeAPIModel.PolygonAmoy]: (tx) => tx,
  [BlockchainTypeAPIModel.PolygonMumbaiNet]: (tx) => tx,
  [BlockchainTypeAPIModel.ArbitrumMainNet]: (tx) => tx,
  [BlockchainTypeAPIModel.ArbitrumTestNet]: (tx) => tx,
  [BlockchainTypeAPIModel.ArbitrumSepolia]: (tx) => tx,
  [BlockchainTypeAPIModel.BitcoinMainNet]: (tx) => tx,
  [BlockchainTypeAPIModel.BitcoinTestNet]: (tx) => tx,
};

const TransactionPathFactory: Record<BlockchainTypeAPIModel, (tx: string) => string> = {
  [BlockchainTypeAPIModel.TronMainNet]: (tx) => `transaction/${tx}`,
  [BlockchainTypeAPIModel.TronNile]: (tx) => `transaction/${tx}`,
  [BlockchainTypeAPIModel.TronShasta]: (tx) => `transaction/${tx}`,
  [BlockchainTypeAPIModel.BinanceMainNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.BinanceTestNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.EthereumMainNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.EthereumGoerli]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.EthereumRopsten]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.EthereumSepolia]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.PolygonMainNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.PolygonAmoy]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.PolygonMumbaiNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.ArbitrumMainNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.ArbitrumTestNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.ArbitrumSepolia]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.BitcoinMainNet]: (tx) => `tx/${tx}`,
  [BlockchainTypeAPIModel.BitcoinTestNet]: (tx) => `tx/${tx}`,
};

const TransactionLink: React.FC<TransactionLinkProps> = ({ 'data-test': dataTest, tx, bt, style, className }) => {
  const formatted = useMemo(() => TransactionFormatter[bt](tx), [tx, bt]);
  const path = useMemo(() => TransactionPathFactory[bt](formatted), [formatted, bt]);
  const Nested: React.FC = useCallback(
    () => (
      <Tooltip
        title={
          <Text style={{ color: '#fff' }} copyable>
            {formatted}
          </Text>
        }
      >
        <ReadonlyComponent data-test={dataTest} value={formatted} copyable />
      </Tooltip>
    ),
    [dataTest, formatted],
  );
  return path ? (
    <BlockchainLink
      data-test={dataTest && `${dataTest}-link`}
      path={path}
      chainType={bt}
      linkType={BlockchainLinkType.APP}
      style={style}
      className={className}
    >
      <Nested />
    </BlockchainLink>
  ) : (
    <Nested />
  );
};

const TransactionLinkHOC = withBlockchainType<TransactionLinkProps>(TransactionLink);
const TransactionLinkMemo = React.memo(TransactionLinkHOC) as typeof TransactionLinkHOC;

export default TransactionLinkMemo;
