import React from 'react';

import type { PayoutBatchViewProps } from '@/features/payouts/components';
import { PayoutBatchView } from '@/features/payouts/components';

import { withPayoutBatchDataLoading } from './hocs';

const PayoutBatchCard = withPayoutBatchDataLoading((props: Omit<PayoutBatchViewProps, 'columns'>) => (
  <PayoutBatchView {...props} columns={1} />
));

const PayoutBatchCardMemo = React.memo(PayoutBatchCard) as typeof PayoutBatchCard;

export default PayoutBatchCardMemo;
