import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonationTransaction } from '@/features/donations/actions';
import { makeSelectDonationTransaction } from '@/features/donations/selectors';
import type { DonationTransaction } from '@/features/donations/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseDonationTransaction = UseAppSingleData<DonationTransaction>;

const noData = storedDataError<DonationTransaction>('no-data');

const fetchFactory = (
  txId: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<DonationTransaction>, Parameters<typeof fetchDonationTransaction>[0]> =>
  fetchDonationTransaction({ txId, force });

export default function useDonationTransaction(txId: string | undefined): UseDonationTransaction {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchDonationTransaction, txId), [txId]);
  return useAppSingleData(fetchFactory, makeSelectDonationTransaction, noData, txId, dataFetchingSelector);
}
