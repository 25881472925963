import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';

import ReportLink from '../ReportsLink';

import type { ReportsBreadcrumbProps } from './types';

const ReportsBreadcrumbRaw: React.FC<ReportsBreadcrumbProps> = ({ 'data-test': dataTest, disabled, items = [] }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageReports.ALL_COMPONENTS_BREADCRUMB} />
        ) : (
          <ReportLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            title={<FormattedMessage id={I18nPageReports.ALL_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const ReportsBreadcrumb = React.memo(ReportsBreadcrumbRaw);

export default ReportsBreadcrumb;
