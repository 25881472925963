import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import GasHistoryPage from './all';
import GasHistoryViewPage from './gas-history-view';
import { gasHistoryLink, GasHistoryPageRoutes } from './routes';

import type React from 'react';

export const GasHistoryDefaultRoute: React.FC = () => <Navigate to={gasHistoryLink()} replace />;

const GasHistoryPages: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={GasHistoryPageRoutes.ALL} Component={GasHistoryPage} />
        <Route path={GasHistoryPageRoutes.HISTORY_ROW_VIEW} Component={GasHistoryViewPage} />
        <Route path="*" Component={GasHistoryDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const GasHistoryPagesHOC: React.FC = withBasicPageGuard(GasHistoryPages);

export default (
  <Route key={AppPageRoutes.GAS_HISTORY} path={`${AppPageRoutes.GAS_HISTORY}/*`} Component={GasHistoryPagesHOC} />
);
