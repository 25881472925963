import { AuditOutlined, FileUnknownOutlined, WarningOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { MerchantWalletTransferReconciliationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import type { MerchantWalletTransferReconciliationStatusViewProps } from './types';

const icons: Record<MerchantWalletTransferReconciliationStatusAPIModel, React.ReactNode> = {
  [MerchantWalletTransferReconciliationStatusAPIModel.Unchecked]: <FileUnknownOutlined />,
  [MerchantWalletTransferReconciliationStatusAPIModel.Unconfirmed]: <WarningOutlined style={{ color: 'orange' }} />,
  [MerchantWalletTransferReconciliationStatusAPIModel.Confirmed]: <AuditOutlined style={{ color: 'green' }} />,
};

const i18n: Record<MerchantWalletTransferReconciliationStatusAPIModel, I18nFeatureMerchantWalletTransfers> = {
  [MerchantWalletTransferReconciliationStatusAPIModel.Unchecked]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_RECONCILIATION_STATUS_VIEW_UNCHECKED,
  [MerchantWalletTransferReconciliationStatusAPIModel.Unconfirmed]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_RECONCILIATION_STATUS_VIEW_UNCONFIRMED,
  [MerchantWalletTransferReconciliationStatusAPIModel.Confirmed]:
    I18nFeatureMerchantWalletTransfers.COMPONENTS_RECONCILIATION_STATUS_VIEW_CONFIRMED,
};

const MerchantWalletTransferReconciliationStatusViewRaw: React.FC<
  MerchantWalletTransferReconciliationStatusViewProps
> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const MerchantWalletTransferReconciliationStatusView = React.memo(MerchantWalletTransferReconciliationStatusViewRaw);

export default MerchantWalletTransferReconciliationStatusView;
