import { useLocale } from '@/features/i18n/hooks';
import recaptchaLocales from '@/features/recaptcha/i18n';

const useReCaptchaLocale = () => {
  const { locale } = useLocale();
  const localeKey = recaptchaLocales[locale];
  return { locale: localeKey };
};

export default useReCaptchaLocale;
