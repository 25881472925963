import React from 'react';
import { Navigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import { useSubscriptionCharge } from '@/features/subscription-charges/hooks';
import { MerchantWalletTransferKindAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { subscriptionChargeViewLink } from '@/pages/subscriptions/routes';

import EntityNotFoundAlert from '../EntityNotFoundAlert';

import type { SubscriptionChargeEntityLinkProps } from './types';

const SubscriptionChargeEntityLink: React.FC<SubscriptionChargeEntityLinkProps> = ({
  'data-test': dataTest,
  value,
}) => {
  const data = useSubscriptionCharge(value.entityId);

  if (data.data.isDirty || data.loading) {
    return <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING} />;
  }

  if (data.data.data?.id) {
    return <Navigate to={subscriptionChargeViewLink(data.data.data.id)} replace />;
  }

  return (
    <EntityNotFoundAlert
      data-test={dataTest}
      value={MerchantWalletTransferKindAPIModel.SubscriptionCharge}
      onRetry={data.forceRefresh}
    />
  );
};

const SubscriptionChargeEntityLinkMemo = React.memo(SubscriptionChargeEntityLink);

export default SubscriptionChargeEntityLinkMemo;
