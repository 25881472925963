import React from 'react';

import { PayoutBatchesList } from '@/features/payouts/components';

import { withPayoutBatchesDataLoading } from './hocs';

const PayoutBatchesCard = withPayoutBatchesDataLoading(PayoutBatchesList);

const PayoutBatchesCardMemo = React.memo(PayoutBatchesCard);

export default PayoutBatchesCardMemo;
