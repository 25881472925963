import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayments } from '@/features/payments/actions';
import { makeSelectPaymentListData, makeSelectPaymentListParameters } from '@/features/payments/selectors';
import type { Payment, PaymentFilterPredicate } from '@/features/payments/types';
import type { PaymentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UsePaymentList = UseAppListData<Payment, PaymentFilterPredicate, PaymentSortByAPIModel>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<Payment>, Parameters<typeof fetchPayments>[0]> => fetchPayments({ force });

const dataSelector = makeSelectPaymentListData();
const dataFetchingSelector = makeSelectPending(fetchPayments);
const parametersSelector = makeSelectPaymentListParameters();

export default function usePaymentList(): UsePaymentList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
