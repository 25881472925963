import { CheckCircleOutlined, ExclamationCircleOutlined, HourglassOutlined, SendOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { TransactionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';

import type { GasWalletTransactionStatusViewProps } from './types';

const i18n: Record<TransactionStatusAPIModel, I18nMessages> = {
  [TransactionStatusAPIModel.Sent]: I18nFeatureGasWallets.COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_SENT,
  [TransactionStatusAPIModel.Pending]: I18nFeatureGasWallets.COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_PENDING,
  [TransactionStatusAPIModel.Succeeded]: I18nFeatureGasWallets.COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_SUCCEEDED,
  [TransactionStatusAPIModel.Abandoned]: I18nFeatureGasWallets.COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_ABANDONED,
  [TransactionStatusAPIModel.Failed]: I18nFeatureGasWallets.COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_FAILED,
};

const icons: Record<TransactionStatusAPIModel, React.ReactNode> = {
  [TransactionStatusAPIModel.Sent]: <SendOutlined style={{ color: 'blue' }} />,
  [TransactionStatusAPIModel.Pending]: <HourglassOutlined spin style={{ color: 'blue' }} />,
  [TransactionStatusAPIModel.Succeeded]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [TransactionStatusAPIModel.Abandoned]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
  [TransactionStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const GasWalletTransactionStatusView: React.FC<GasWalletTransactionStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const GasWalletTransactionStatusViewMemo = React.memo(GasWalletTransactionStatusView);

export default GasWalletTransactionStatusViewMemo;
