import React from 'react';

import GasHistoryBreadcrumb from 'src/pages/gas-history/all/components/GasHistoryBreadcrumb';

import { FormattedMessage } from '@/components';
import { I18nPageGasHistory } from '@/generated/i18n/i18n';

import GasHistoryViewLink from '../GasHistoryViewLink';

import type { GasHistoryViewBreadcrumbProps } from './types';

const GasHistoryViewBreadcrumb: React.FC<GasHistoryViewBreadcrumbProps> = ({
  items = [],
  historyId,
  disabled,
  'data-test': dataTest,
}) => (
  <GasHistoryBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageGasHistory.GAS_HISTORY_VIEW_COMPONENTS_BREADCRUMB} values={{ id: historyId }} />
        ) : (
          <GasHistoryViewLink
            value={historyId}
            mode="text"
            title={
              <FormattedMessage
                id={I18nPageGasHistory.GAS_HISTORY_VIEW_COMPONENTS_BREADCRUMB}
                values={{ id: historyId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const GasHistoryViewBreadcrumbMemo = React.memo(GasHistoryViewBreadcrumb);

export default GasHistoryViewBreadcrumbMemo;
