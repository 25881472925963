import type { SettlementScheduleViewProps } from '@/features/settlements/components';
import { SettlementScheduleView } from '@/features/settlements/components';

import { withSettlementScheduleDataLoading } from './hocs';

export default withSettlementScheduleDataLoading<
  Omit<SettlementScheduleViewProps, 'schedule'> & {
    data: NonNullable<SettlementScheduleViewProps['schedule']>;
  }
>(({ data, ...props }) => <SettlementScheduleView {...props} schedule={data} />);
