import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Divider, Row, Space, Tooltip, Typography } from 'antd';

import type { DataBlockContainerProps } from './types';
import type React from 'react';

const { Text } = Typography;

const DataBlockContainer: React.FC<DataBlockContainerProps> = ({
  'data-test': dataTest,
  icon,
  title,
  help,
  children,
}) => (
  <Col data-test={dataTest} span={24}>
    <Row>
      <Col flex="1 1">
        <Divider type="horizontal" orientation="left" orientationMargin={0} style={{ margin: 0 }}>
          <Space>
            {icon}
            <Text strong>{title}</Text>
          </Space>
        </Divider>
      </Col>
      {help && (
        <Col flex="0 0 16px">
          <Tooltip title={help}>
            <QuestionCircleOutlined />
          </Tooltip>
        </Col>
      )}
    </Row>
    <Row>
      <Col flex="0 0 16px" style={{ display: 'flex', justifyContent: 'center' }}>
        <Divider type="vertical" style={{ margin: 0, backgroundColor: '#DDD', height: '100%' }} />
      </Col>
      <Col flex="1 1" style={{ paddingTop: 8 }}>
        {children}
      </Col>
    </Row>
  </Col>
);

export default DataBlockContainer;
