export const dateComparator =
  <T>(valueGetter: (v: T) => Date) =>
  (mode: 'DESC' | 'ASC') =>
    mode === 'ASC'
      ? (a: T, b: T) => valueGetter(a).getTime() - valueGetter(b).getTime()
      : (a: T, b: T) => valueGetter(b).getTime() - valueGetter(a).getTime();

export const stringComparator =
  <T>(valueGetter: (v: T) => string) =>
  (mode: 'DESC' | 'ASC') =>
    mode === 'ASC'
      ? (a: T, b: T) => valueGetter(a).localeCompare(valueGetter(b))
      : (a: T, b: T) => -valueGetter(a).localeCompare(valueGetter(b));

export const numberComparator =
  <T>(valueGetter: (v: T) => number) =>
  (mode: 'DESC' | 'ASC') =>
    mode === 'ASC' ? (a: T, b: T) => valueGetter(a) - valueGetter(b) : (a: T, b: T) => valueGetter(b) - valueGetter(a);
