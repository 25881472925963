import { Tag } from 'antd';
import React, { useMemo } from 'react';

import { ReadonlyComponent } from '@/components';

import type { SubscriptionPlanTagsViewProps } from './types';

const SubscriptionPlanTagsView: React.FC<SubscriptionPlanTagsViewProps> = ({ 'data-test': dataTest, value }) => (
  <ReadonlyComponent
    data-test={dataTest}
    value={useMemo(
      () =>
        value.map((tag) => (
          <Tag key={tag} style={{ fontWeight: 'bold' }}>
            {tag}
          </Tag>
        )),
      [value],
    )}
    copyable={false}
  />
);

const SubscriptionPlanTagsViewMemo = React.memo(SubscriptionPlanTagsView);

export default SubscriptionPlanTagsViewMemo;
