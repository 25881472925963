import { useEffect, useRef } from 'react';

import { withFinally, withSuppressPromise } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

export default function useFetching(fetch: Func, isDirty: boolean, loading?: boolean): void {
  const fetching = useRef(false);

  useEffect(() => {
    if (!loading && isDirty && !fetching.current) {
      fetching.current = true;
      withSuppressPromise(
        withFinally(fetch, () => {
          fetching.current = false;
        }),
      )();
    }
  }, [fetch, isDirty, loading]);
}
