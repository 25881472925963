import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { exportMerchantWalletTransfers } from '@/features/merchant-wallet-transfers/actions';
import type { Report } from '@/features/reports/types';
import type { HookAction } from '@/infrastructure/model';

import useMerchantWalletTransferList from './useMerchantWalletTransferList';

export interface UseMerchantWalletTransfersActions {
  exportTransfers: HookAction<[], Report>;
}

export default function useMerchantWalletTransfersActions(): UseMerchantWalletTransfersActions {
  const { loading, filter } = useMerchantWalletTransferList();
  const { withExtractDataDispatch } = useAppDispatch();

  const isExporting = useActionPending(exportMerchantWalletTransfers);
  const exportTransfersAction: UseMerchantWalletTransfersActions['exportTransfers'] = {
    act: useCallback(
      () => withExtractDataDispatch(exportMerchantWalletTransfers)({ predicates: filter }),
      [filter, withExtractDataDispatch],
    ),
    inAction: isExporting,
    available: !loading,
  };

  return { exportTransfers: exportTransfersAction };
}
