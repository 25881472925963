import { useCallback, useMemo } from 'react';

import type { AppAsyncThunkAction, AppRootState } from '@/app/store';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { toMultipleMerged } from '@/infrastructure/model/single/utils';

import useAppMultipleData from './useAppMultipleData';

export interface UseAppMultipleDataMerged<
  Value,
  ValueState extends LoadingStateWithDirty<Value[]> = LoadingStateWithDirty<Value[]>,
  FetchResult = ValueState,
> {
  loading: boolean;
  forceRefresh: () => Promise<FetchResult>;
  data: ValueState;
}

export default function useAppMultipleDataMerged<Value, Id = string>(
  fetchActionFactory: (params: {
    ids: Id[];
    force: boolean;
  }) => AppAsyncThunkAction<{ id: Id; data: LoadingStateWithDirty<Value> }[], unknown>,
  multipleDataSelector: (ids: Id[]) => (state: AppRootState) => { id: Id; data: LoadingStateWithDirty<Value> }[],
  multipleDataDirtyIdsSelector: (ids: Id[]) => (state: AppRootState) => Id[],
  ids: Id[],
  dataFetchingSelector?: (state: AppRootState) => boolean,
): UseAppMultipleDataMerged<Value> {
  const base = useAppMultipleData(
    fetchActionFactory,
    multipleDataSelector,
    multipleDataDirtyIdsSelector,
    ids,
    dataFetchingSelector,
  );
  const data = useMemo(() => toMultipleMerged(base.data, ids), [base.data, ids]);
  const forceRefresh = useCallback(async () => toMultipleMerged(await base.forceRefresh(), ids), [base, ids]);
  return { loading: base.loading, data, forceRefresh };
}
