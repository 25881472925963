import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSubscriptionsSummaryStats } from '@/features/statistics/actions';
import { makeSelectSubscriptionsSummaryStats } from '@/features/statistics/selectors';
import type { SubscriptionSummaryStats } from '@/features/statistics/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<SubscriptionSummaryStats>,
  Parameters<typeof fetchSubscriptionsSummaryStats>[0]
> => fetchSubscriptionsSummaryStats({ force });
const selectFetching = makeSelectPending(fetchSubscriptionsSummaryStats);
const selectData = makeSelectSubscriptionsSummaryStats();

export default function useSubscriptionsSummaryStats() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
