import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { fetchContractExistence } from '@/features/dictionary/blockchain/actions';
import { useActionPending } from '@/features/global/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

export interface UseBlockchainActions {
  isContractDeployed: HookAction<[string], boolean>;
}

export default function useBlockchainActions(bt: BlockchainTypeAPIModel | undefined): UseBlockchainActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const contractExistenceChecking = useActionPending(fetchContractExistence, bt);
  const contractExistenceCheckAction = useMemo(
    () => (bt ? (address: string) => withExtractDataDispatch(fetchContractExistence)({ address, bt }) : () => false),
    [bt, withExtractDataDispatch],
  );
  const isContractDeployed = {
    act: contractExistenceCheckAction,
    available: !!bt,
    inAction: contractExistenceChecking,
  };

  return { isContractDeployed };
}
