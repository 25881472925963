/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface Web3CredentialsAPIModel
 */
export interface Web3CredentialsAPIModel {
    /**
     * 
     * @type {string}
     * @memberof Web3CredentialsAPIModel
     */
    address: string;
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof Web3CredentialsAPIModel
     */
    signature: SignatureComponentsAPIModel;
    /**
     * 
     * @type {string}
     * @memberof Web3CredentialsAPIModel
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Web3CredentialsAPIModel
     */
    isNewScheme?: boolean;
}

/**
 * Check if a given object implements the Web3CredentialsAPIModel interface.
 */
export function instanceOfWeb3CredentialsAPIModel(value: object): boolean {
    if (!('address' in value)) return false;
    if (!('signature' in value)) return false;
    return true;
}

export function Web3CredentialsAPIModelFromJSON(json: any): Web3CredentialsAPIModel {
    return Web3CredentialsAPIModelFromJSONTyped(json, false);
}

export function Web3CredentialsAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Web3CredentialsAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'address': json['address'],
        'signature': SignatureComponentsAPIModelFromJSON(json['signature']),
        'message': json['message'] == null ? undefined : json['message'],
        'isNewScheme': json['isNewScheme'] == null ? undefined : json['isNewScheme'],
    };
}

export function Web3CredentialsAPIModelToJSON(value?: Web3CredentialsAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'address': value['address'],
        'signature': SignatureComponentsAPIModelToJSON(value['signature']),
        'message': value['message'],
        'isNewScheme': value['isNewScheme'],
    };
}

