import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import CollectTasksBreadcrumb from '@/pages/collectable/tasks/components/CollectTasksBreadcrumb';

import CollectTaskLink from '../CollectTaskLink';

import type { CollectTaskBreadcrumbProps } from './types';

const CollectTaskBreadcrumb: React.FC<CollectTaskBreadcrumbProps> = ({
  'data-test': dataTest,
  taskId,
  disabled,
  items = [],
}) => (
  <CollectTasksBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageCollectable.TASK_VIEW_COMPONENTS_BREADCRUMB} values={{ id: taskId }} />
        ) : (
          <CollectTaskLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={taskId}
            title={
              <FormattedMessage id={I18nPageCollectable.TASK_VIEW_COMPONENTS_BREADCRUMB} values={{ id: taskId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const CollectTaskBreadcrumbMemo = React.memo(CollectTaskBreadcrumb);

export default CollectTaskBreadcrumbMemo;
