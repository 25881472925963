import { Col, Statistic } from 'antd';
import React, { useMemo } from 'react';

import type { StatisticItemProps } from './types';

const StatisticItem: React.FC<StatisticItemProps> = ({ 'data-test': dataTest, title, icon, value, color, span }) => {
  const simpleValue = useMemo(
    () => (typeof value === 'string' || typeof value === 'number' ? value : undefined),
    [value],
  );
  const toRender = useMemo(() => (simpleValue === undefined ? () => value : undefined), [simpleValue, value]);
  return (
    <Col {...span}>
      <div style={{ textAlign: 'center' }} data-test={dataTest}>
        <Statistic
          title={title}
          value={simpleValue}
          valueRender={toRender}
          precision={0}
          prefix={icon}
          valueStyle={{ color }}
        />
      </div>
    </Col>
  );
};

const StatisticItemMemo = React.memo(StatisticItem);

export default StatisticItemMemo;
