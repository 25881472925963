import React from 'react';

import { CollectTaskView } from '@/features/collectable/components';

import { withCollectTaskDataLoading } from './hocs';

const CollectTaskCard = withCollectTaskDataLoading((props) => <CollectTaskView {...props} columns={1} />);

const CollectTaskCardMemo = React.memo(CollectTaskCard) as typeof CollectTaskCard;

export default CollectTaskCardMemo;
