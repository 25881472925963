import { css } from '@emotion/css';
import { useInViewport } from 'ahooks';
import { Checkbox, Modal, Typography } from 'antd';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import React, { useEffect, useRef } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useLocale } from '@/features/i18n/hooks';
import { I18nFeatureAuth, I18nLocales } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';

import * as texts from './texts';

import type { TermsOfServiceProps, TermsOfServiceTextProps } from './types';

const styles = {
  dialog: css`
    top: 60px;
    width: calc(100vw - 100px);
    max-width: 800px;
    min-width: 460px;
  `,
  header: css`
    margin: 0;
  `,
  content: css`
    height: calc(100vh - 300px);
    overflow-y: auto;
  `,
  footer: css`
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  `,
};

const { Title } = Typography;

const tosI18n: Record<I18nLocales, React.FC<TermsOfServiceTextProps>> = {
  [I18nLocales['en-US']]: texts.enUS,
};

const TermsOfService: React.FC<TermsOfServiceProps> = ({ 'data-test': dataTest, onAccept, onReject }) => {
  const { locale } = useLocale();
  const ref = useRef(null);
  const [inViewport] = useInViewport(ref);
  const [checked, setChecked] = useStateMountSafe(false);
  const [scrolled, setScrolled] = useStateMountSafe(false);
  const screens = useBreakpoint();
  useEffect(() => {
    if (inViewport && !scrolled) {
      setScrolled(true);
    }
  }, [inViewport, scrolled, setScrolled]);
  const TextComponent = tosI18n[locale];
  return (
    <Modal
      className={styles.dialog}
      data-test={dataTest && `${dataTest}-modal`}
      open
      maskClosable={false}
      width={screens.xs ? '100vw' : 'calc(100vw - 100px)'}
      footer={
        <div className={styles.footer}>
          <Checkbox
            data-test={dataTest && `${dataTest}-checkbox`}
            checked={checked}
            disabled={!scrolled}
            onChange={(e) => setChecked(e.target.checked)}
          >
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_TERMS_OF_SERVICE_CHECKBOX} />
          </Checkbox>
          <Operation
            data-test={dataTest && `${dataTest}-accept`}
            icon={null}
            disabled={!checked}
            onClick={onAccept}
            primary
            mode="button"
            title={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_TERMS_OF_SERVICE_SUBMIT_TITLE} />}
          />
        </div>
      }
      title={
        <Title level={2} className={styles.header}>
          <FormattedMessage id={I18nFeatureAuth.COMPONENTS_TERMS_OF_SERVICE_DIALOG_TITLE} />
        </Title>
      }
      closable
      onCancel={onReject}
    >
      <div className={styles.content}>
        <TextComponent data-test={dataTest} lastElementRef={ref} />
      </div>
    </Modal>
  );
};

const TermsOfServiceMemo = React.memo(TermsOfService);

export default TermsOfServiceMemo;
