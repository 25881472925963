import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import BalancesBreadcrumb from '@/pages/balances/components/BalancesBreadcrumb';

import ActualBalancesLink from '../ActualBalancesLink';

import type { ActualBalancesBreadcrumbProps } from './types';

const ActualBalancesBreadcrumb: React.FC<ActualBalancesBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <BalancesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageBalances.ACTUAL_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <ActualBalancesLink
            mode="text"
            title={<FormattedMessage id={I18nPageBalances.ACTUAL_COMPONENTS_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const ActualBalancesBreadcrumbMemo = React.memo(ActualBalancesBreadcrumb);

export default ActualBalancesBreadcrumbMemo;
