import cronstrue from 'cronstrue';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ReadonlyComponent } from '@/components';

import type { ReportScheduleCronViewProps } from './types';

const ReportScheduleCronViewRaw: React.FC<ReportScheduleCronViewProps> = ({ 'data-test': dataTest, value }) => {
  const { locale } = useIntl();
  const simplifiedLocale = useMemo(() => locale.split('-')[0] || locale, [locale]);

  return (
    <ReadonlyComponent
      data-test={dataTest}
      value={value ? cronstrue.toString(value, { locale: simplifiedLocale }) : ''}
    />
  );
};

const ReportScheduleCronView = React.memo(ReportScheduleCronViewRaw);

export default ReportScheduleCronView;
