import { Typography } from 'antd';
import React, { useCallback } from 'react';
import { Link as RouterLink, useInRouterContext } from 'react-router-dom';

import type { ContextableLinkProps } from './types';

const { Link: TypographyLink } = Typography;

const ContextableLink = React.forwardRef<HTMLAnchorElement, ContextableLinkProps>(function ContextableLink(
  { 'data-test': testLocator, to, className, style, children },
  ref,
) {
  const isInContext = useInRouterContext();
  const doClick = useCallback(() => {
    window.history.pushState({}, '', to);
  }, [to]);
  return isInContext ? (
    <RouterLink data-test={testLocator} to={to} ref={ref} className={className} style={style}>
      {children}
    </RouterLink>
  ) : (
    <TypographyLink data-test={testLocator} onClick={doClick} ref={ref} className={className} style={style}>
      {children}
    </TypographyLink>
  );
});

const ContextableLinkMemo = React.memo(ContextableLink);

export default ContextableLinkMemo;
