import type { AppRootState } from '@/app/store';
import { searchIdToStoreKey } from '@/features/payment-transactions/utils';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectPaymentTransaction,
  makeSelectMultiplePaymentTransaction,
  makeSelectDirtyPaymentTransactionIds,
} = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].entities,
  'PaymentTransaction' as const,
  searchIdToStoreKey,
);
