import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import ReadonlyComponent from '../ReadonlyComponent';

import type { ReadonlyDateProps } from './types';

const ReadonlyDateRaw: React.FC<ReadonlyDateProps> = (props) => {
  const { formatDate } = useIntl();
  const { plain, value } = props;
  const formattedValue = useMemo(() => (value ? formatDate(value) : undefined), [formatDate, value]);
  if (plain) {
    return <>{formattedValue}</>;
  }
  const { className, style, 'data-test': dataTest } = props;
  return (
    <ReadonlyComponent
      data-test={dataTest}
      style={style}
      className={className}
      copyable={false}
      value={formattedValue}
    />
  );
};

const ReadonlyDate = React.memo(ReadonlyDateRaw) as typeof ReadonlyDateRaw;

export default ReadonlyDate;
