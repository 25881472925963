import React from 'react';

import { ReadonlyDateTime } from '@/components';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { SubscriptionChargeAtViewProps } from './types';

const SubscriptionChargeAtView: React.FC<SubscriptionChargeAtViewProps> = ({ value, 'data-test': dataTest }) =>
  value?.status === SubscriptionStatusAPIModel.Active ? (
    <ReadonlyDateTime value={value.nextChargeAt} data-test={dataTest} />
  ) : null;

const SubscriptionChargeAtViewMemo = React.memo(SubscriptionChargeAtView);

export default SubscriptionChargeAtViewMemo;
