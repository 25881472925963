import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useRechargeAddress } from '@/features/recharges/hooks';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';

import type { OperationRefreshRechargeAddressProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_TOOLTIP} />,
  success: {
    message: (
      <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_SUCCESS_MESSAGE} />
    ),
  },
};

const OperationRefreshRechargeAddress: React.FC<OperationRefreshRechargeAddressProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  addressId,
}) => {
  const { forceRefresh } = useRechargeAddress(addressId);
  return (
    <OperationRefresh
      data-test={dataTest}
      mode={mode}
      refresh={forceRefresh}
      messages={messages}
      disabled={!addressId}
    />
  );
};

const OperationRefreshRechargeAddressMemo = React.memo(OperationRefreshRechargeAddress);

export default OperationRefreshRechargeAddressMemo;
