import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageGasHistory } from '@/generated/i18n/i18n';
import { gasHistoryViewLink } from '@/pages/gas-history/routes';

import type { GasHistoryViewLinkProps } from './types';

const GasHistoryViewLinkRaw: React.FC<GasHistoryViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={gasHistoryViewLink(value)}
    mode={mode}
    title={title || <FormattedMessage id={I18nPageGasHistory.GAS_HISTORY_VIEW_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const GasHistoryViewLink = React.memo(GasHistoryViewLinkRaw);

export default GasHistoryViewLink;
