import React from 'react';
import { useIntl } from 'react-intl';

import { DateTimeRelativeItem, FormattedMessage, InputItem } from '@/components';
import { BlockchainItem, NetworkTypeItem } from '@/features/dictionary/blockchain/components';
import {
  MerchantWalletTransferDirectionFilterItem,
  MerchantWalletTransferKindFilterItem,
  MerchantWalletTransferReconciliationStatusFilterItem,
} from '@/features/merchant-wallet-transfers/components/MerchantWalletTransferFilterForm/components';
import type { MerchantWalletTransferReportPredicates } from '@/features/merchant-wallet-transfers/types';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { MerchantWalletTransfersScheduleParametersFormProps } from './types';

const walletMessages = {
  label: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_WALLET_LABEL} />,
};

const MerchantWalletTransfersScheduleParametersFormRaw: React.FC<
  MerchantWalletTransfersScheduleParametersFormProps
> = ({ 'data-test': dataTest, name, readonly }) => {
  const { formatMessage } = useIntl();
  return (
    <>
      <MerchantWalletTransferDirectionFilterItem
        data-test={dataTest && `${dataTest}-direction`}
        name={[name, nameof<MerchantWalletTransferReportPredicates>('directionEq')]}
        readonly={readonly}
      />
      <MerchantWalletTransferKindFilterItem
        data-test={dataTest && `${dataTest}-kind`}
        name={[name, nameof<MerchantWalletTransferReportPredicates>('kindIn')]}
        readonly={readonly}
      />
      <InputItem
        data-test={dataTest && `${dataTest}-wallet`}
        name={[name, nameof<MerchantWalletTransferReportPredicates>('walletLike')]}
        messages={walletMessages}
        placeholder={formatMessage({
          id: I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_WALLET_PLACEHOLDER,
        })}
        readonly={readonly}
      />
      <DateTimeRelativeItem
        label={
          <FormattedMessage
            id={
              I18nFeatureMerchantWalletTransfers.COMPONENTS_REPORT_SCHEDULE_PARAMETERS_BROADCAST_AT_RELATIVE_ITEM_LABEL
            }
          />
        }
        readonly={readonly}
        required={false}
        data-test={dataTest && `${dataTest}-broadcastAt`}
        name={[name, nameof<MerchantWalletTransferReportPredicates>('broadcastAtRangeRelative')]}
      />
      <MerchantWalletTransferReconciliationStatusFilterItem
        data-test={dataTest && `${dataTest}-reconciliation`}
        name={[name, nameof<MerchantWalletTransferReportPredicates>('reconciliationStatusIn')]}
        readonly={readonly}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={[name, nameof<MerchantWalletTransferReportPredicates>('btIn')]}
        required={false}
        readonly={readonly}
        multiselect
      />
      <NetworkTypeItem
        data-test={dataTest && `${dataTest}-network`}
        name={[name, nameof<MerchantWalletTransferReportPredicates>('networkEq')]}
        readonly={readonly}
        required={false}
        mode="select"
      />
    </>
  );
};

const MerchantWalletTransfersScheduleParametersForm = React.memo(MerchantWalletTransfersScheduleParametersFormRaw);

export default MerchantWalletTransfersScheduleParametersForm;
