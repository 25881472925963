import { Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, DataFetchError } from '@/components';
import { useDomains } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { MerchantDomainSelectProps } from './types';

const MerchantDomainSelect: React.FC<MerchantDomainSelectProps> = ({
  'data-test': dataTest,
  value,
  style,
  className,
  onChange,
  readonly,
  approvedOnly,
  SelectProps = {},
}) => {
  const { data, forceRefresh, loading } = useDomains();

  const options = useMemo(
    () =>
      data.data
        ?.filter(({ isApproved }) => !approvedOnly || isApproved)
        .map(({ url }) => ({ value: url, label: url, search: `label` })),
    [approvedOnly, data.data],
  );
  return !data.error ? (
    <Select
      data-test={dataTest}
      style={style}
      className={className}
      value={value}
      onSelect={onChange}
      showSearch
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_MERCHANT_DOMAIN_SELECT_PLACEHOLDER} />}
      loading={loading}
      disabled={loading || readonly}
      options={options}
      optionFilterProp="search"
      {...SelectProps}
    />
  ) : (
    <DataFetchError
      refresh={forceRefresh}
      message={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_MERCHANT_DOMAIN_SELECT_EMPTY} />}
      data-test={dataTest && `${dataTest}-error`}
      description={data.error}
    />
  );
};

const MerchantDomainSelectMemo = React.memo(MerchantDomainSelect);

export default MerchantDomainSelectMemo;
