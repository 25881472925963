import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useMerchantWalletRunningBalance } from '@/features/merchant-wallet-balance/hooks';
import { I18nFeatureMerchantWalletBalance } from '@/generated/i18n/i18n';

import type { OperationRefreshMerchantWalletRunningBalanceProps } from './types';

const messages = {
  title: <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_OPERATION_RELOAD_BALANCE_TITLE} />,
  tooltip: <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_OPERATION_RELOAD_BALANCE_TOOLTIP} />,
  success: {
    message: (
      <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_OPERATION_RELOAD_BALANCE_SUCCESS_MESSAGE} />
    ),
  },
  error: {
    message: (
      <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_OPERATION_RELOAD_BALANCE_FAILED_MESSAGE} />
    ),
    description: (
      <FormattedMessage id={I18nFeatureMerchantWalletBalance.COMPONENTS_OPERATION_RELOAD_BALANCE_FAILED_DESCRIPTION} />
    ),
  },
};

const OperationRefreshMerchantWalletRunningBalanceRaw: React.FC<OperationRefreshMerchantWalletRunningBalanceProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  id,
}) => {
  const { forceRefresh } = useMerchantWalletRunningBalance(id);
  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshMerchantWalletRunningBalance = React.memo(OperationRefreshMerchantWalletRunningBalanceRaw);

export default OperationRefreshMerchantWalletRunningBalance;
