import type { AppAsyncThunk, AppRootState } from '@/app/store';

import { NAMESPACE } from './types';

export const makeSelectPending =
  <Returned, ThunkArg = void>(action: Pick<AppAsyncThunk<Returned, ThunkArg>, 'typePrefix'>, id?: string) =>
  (state: AppRootState) =>
    id
      ? !!state[NAMESPACE].pending[action.typePrefix]?.includes(id)
      : !!state[NAMESPACE].pending[action.typePrefix]?.length;

export const makeSelectNotifications = () => (state: AppRootState) => state[NAMESPACE].notifications;

export const makeSelectGlobalLoading = () => (state: AppRootState) => state[NAMESPACE].loading;

export const makeSelectViewHeight = () => (state: AppRootState) => state[NAMESPACE].vh;

export const makeSelectCookiesAcceptance = () => (state: AppRootState) => state[NAMESPACE].cookies;

export const makeSelectIsIdle = () => (state: AppRootState) => state[NAMESPACE].idle.isIdle;
export const makeSelectIdleReloadBlock = () => (state: AppRootState) => state[NAMESPACE].idle.reload.blockedBy;
