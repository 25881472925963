import React from 'react';

import { DonationAssetView } from '@/features/donations/components';

import { withDonationAssetDataLoading } from './hocs';

const DonationAddressCard = withDonationAssetDataLoading((props) => <DonationAssetView {...props} columns={1} />);

const DonationAddressCardMemo = React.memo(DonationAddressCard) as typeof DonationAddressCard;

export default DonationAddressCardMemo;
