import { Form } from 'antd';
import React from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';

import { FormCheckbox } from './components';

import type { CheckboxItemProps } from './types';
import type { CheckboxRef } from 'antd';
import type { Store } from 'rc-field-form/es/interface';
import type { ForwardedRef, RefAttributes } from 'react';

const CheckboxItemRaw = <Values extends Store = Store>(
  { 'data-test': dataTest, name, readonly, messages, CheckboxProps, ItemProps = {} }: CheckboxItemProps<Values>,
  ref: ForwardedRef<CheckboxRef>,
) => (
  <Form.Item<Values>
    label={messages?.label ?? <FormattedMessage id={I18nComponents.CHECKBOX_LABEL} />}
    {...ItemProps}
    name={name}
  >
    <FormCheckbox data-test={dataTest} readonly={readonly} CheckboxProps={CheckboxProps} ref={ref} />
  </Form.Item>
);

const CheckboxItemWithRef = React.forwardRef(CheckboxItemRaw) as typeof CheckboxItemRaw & RefAttributes<unknown>;

const CheckboxItem = React.memo(CheckboxItemWithRef) as typeof CheckboxItemWithRef;

export default CheckboxItem;
