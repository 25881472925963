import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { FormattedMessage, WebURLItem } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { RedirectURLItemProps } from './types';
import type React from 'react';

const RedirectURLItem: React.FC<RedirectURLItemProps> = ({ 'data-test': dataTest, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <WebURLItem<PaymentsBranding>
      data-test={dataTest}
      name={nameof<PaymentsBranding>('redirectURL')}
      messages={{
        placeholder: formatMessage({
          id: I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_ITEM_PLACEHOLDER,
        }),
      }}
      readonly={disabled}
      ItemProps={{ label: null }}
      InputProps={{
        suffix: (
          <Tooltip
            title={
              <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_ITEM_HELP} />
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        ),
      }}
    />
  );
};

export default RedirectURLItem;
