import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import { paymentTransactionViewLink } from '@/pages/payments/routes';

import type { PaymentTransactionLinkProps } from './types';

const PaymentTransactionLink: React.FC<PaymentTransactionLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={paymentTransactionViewLink(value.paymentId, value.hash)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPagePayments.TRANSACTION_VIEW_COMPONENTS_LINK} />}
    icon={icon}
  />
);

const PaymentTransactionLinkMemo = React.memo(PaymentTransactionLink);

export default PaymentTransactionLinkMemo;
