import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { GasHistoryViewBreadcrumb, GasHistoryViewCard } from './components';
import { withGasHistoryViewPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type GasHistoryPageProps = PropsWithChildren<TestableProps & { pageParams: { id: string } }>;

const dataTest: string | undefined = 'gas-history';

const GasHistoryPage: React.FC<GasHistoryPageProps> = ({ pageParams: { id } }) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<GasHistoryViewBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} historyId={id} disabled />}
  >
    <GasHistoryViewCard data-test={dataTest && `${dataTest}-card`} historyId={id} />
  </PageContainer>
);

export default withGasHistoryViewPageParams(GasHistoryPage);
