import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { createGasWallet, deleteGasWallet, refundGasWallet } from '@/features/gas-wallets/actions';
import { makeSelectRefundingGasWallets } from '@/features/gas-wallets/selectors';
import type { GasWallet } from '@/features/gas-wallets/types';
import { useActionPending } from '@/features/global/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';
import { generateSeed, toXprv } from '@/infrastructure/utils/hd';

import useMerchantGasWallets from '../useMerchantGasWallets';

import type { Address } from 'viem';

export type RefundUnavailabilityReason = 'no-data';
export type DeleteUnavailabilityReason = 'no-data';

export interface UseMerchantGasWalletsActions {
  create: HookAction<[BlockchainTypeAPIModel], GasWallet>;
  refund: HookAction<[BlockchainTypeAPIModel, Address], GasWallet, RefundUnavailabilityReason>;
  deleteWallet: HookAction<[BlockchainTypeAPIModel], GasWallet[], DeleteUnavailabilityReason>;
}

const selectRefunding = makeSelectRefundingGasWallets();

export default function useMerchantGasWalletsActions(): UseMerchantGasWalletsActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data, loading } = useMerchantGasWallets();

  const isRefunding = !!useAppSelector(selectRefunding).length;

  const createPending = useActionPending(createGasWallet);
  const createAction: UseMerchantGasWalletsActions['create']['act'] = useCallback(
    (bt: BlockchainTypeAPIModel) =>
      withExtractDataDispatch(createGasWallet)({
        newGasWallet: { bt, privateKey: toXprv(generateSeed()) },
      }),
    [withExtractDataDispatch],
  );
  const create: UseMerchantGasWalletsActions['create'] = useMemo(
    () => ({
      act: createAction,
      available: !!data.data && !data.isDirty && !loading && !isRefunding,
      inAction: createPending,
    }),
    [createAction, data.data, data.isDirty, loading, isRefunding, createPending],
  );

  const refundUnavailabilityReason: RefundUnavailabilityReason | undefined =
    !data.data || data.isDirty || loading ? 'no-data' : undefined;
  const refundAction: UseMerchantGasWalletsActions['refund']['act'] = useCallback(
    (bt: BlockchainTypeAPIModel, to: Address) => withExtractDataDispatch(refundGasWallet)({ bt, to }),
    [withExtractDataDispatch],
  );
  const refund: UseMerchantGasWalletsActions['refund'] = useMemo(
    () => ({
      act: refundAction,
      available: !refundUnavailabilityReason,
      unavailabilityReason: refundUnavailabilityReason,
      inAction: isRefunding,
    }),
    [refundAction, refundUnavailabilityReason, isRefunding],
  );

  const deleteUnavailabilityReason: DeleteUnavailabilityReason | undefined =
    !data.data || data.isDirty || loading ? 'no-data' : undefined;
  const deletePending = useActionPending(deleteGasWallet);
  const deleteWalletAction: UseMerchantGasWalletsActions['deleteWallet']['act'] = useCallback(
    (bt: BlockchainTypeAPIModel) => withExtractDataDispatch(deleteGasWallet)(bt),
    [withExtractDataDispatch],
  );
  const deleteWallet: UseMerchantGasWalletsActions['deleteWallet'] = useMemo(
    () => ({
      act: deleteWalletAction,
      available: !deleteUnavailabilityReason && !isRefunding,
      unavailabilityReason: deleteUnavailabilityReason,
      inAction: deletePending,
    }),
    [deleteWalletAction, deleteUnavailabilityReason, isRefunding, deletePending],
  );

  return { create, refund, deleteWallet };
}
