/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantReportPredicatesAPIModel } from './MerchantReportPredicatesAPIModel';
import {
    MerchantReportPredicatesAPIModelFromJSON,
    MerchantReportPredicatesAPIModelFromJSONTyped,
    MerchantReportPredicatesAPIModelToJSON,
} from './MerchantReportPredicatesAPIModel';
import type { PageReportSortByAPIModel } from './PageReportSortByAPIModel';
import {
    PageReportSortByAPIModelFromJSON,
    PageReportSortByAPIModelFromJSONTyped,
    PageReportSortByAPIModelToJSON,
} from './PageReportSortByAPIModel';

/**
 * 
 * @export
 * @interface ReportsFilterAPIModel
 */
export interface ReportsFilterAPIModel {
    /**
     * 
     * @type {MerchantReportPredicatesAPIModel}
     * @memberof ReportsFilterAPIModel
     */
    predicates: MerchantReportPredicatesAPIModel;
    /**
     * 
     * @type {PageReportSortByAPIModel}
     * @memberof ReportsFilterAPIModel
     */
    page: PageReportSortByAPIModel;
}

/**
 * Check if a given object implements the ReportsFilterAPIModel interface.
 */
export function instanceOfReportsFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function ReportsFilterAPIModelFromJSON(json: any): ReportsFilterAPIModel {
    return ReportsFilterAPIModelFromJSONTyped(json, false);
}

export function ReportsFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ReportsFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': MerchantReportPredicatesAPIModelFromJSON(json['predicates']),
        'page': PageReportSortByAPIModelFromJSON(json['page']),
    };
}

export function ReportsFilterAPIModelToJSON(value?: ReportsFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': MerchantReportPredicatesAPIModelToJSON(value['predicates']),
        'page': PageReportSortByAPIModelToJSON(value['page']),
    };
}

