import { QuestionCircleOutlined } from '@ant-design/icons';
import { Descriptions, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { AddressLink, BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { withGasWallet } from '@/features/gas-wallets/hocs';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';

import GasWalletDerivedBalanceView from '../GasWalletDerivedBalanceView';
import GasWalletNativeBalanceView from '../GasWalletNativeBalanceView';

import type { GasWalletViewProps } from './types';

const GasWalletView: React.FC<GasWalletViewProps> = ({ 'data-test': dataTest, wallet }) => (
  <Descriptions contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }} column={1}>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasWallets.ROW_BLOCKCHAIN} />}>
      <BlockchainLabel bt={wallet.bt} data-test={dataTest && `${dataTest}-blockchain`} mode="medium" />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureGasWallets.ROW_ADDRESS} />}>
      <AddressLink address={wallet.address} bt={wallet.bt} data-test={dataTest && `${dataTest}-address`} />
    </Descriptions.Item>
    <Descriptions.Item
      label={
        <Space>
          <FormattedMessage id={I18nFeatureGasWallets.ROW_NATIVE_BALANCE} />
          <Tooltip
            title={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_GAS_WALLET_VIEW_NATIVE_BALANCE_HELP} />}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <GasWalletNativeBalanceView data-test={dataTest && `${dataTest}-balance`} wallet={wallet} />
    </Descriptions.Item>
    <Descriptions.Item
      label={
        <Space>
          <FormattedMessage id={I18nFeatureGasWallets.ROW_DERIVED_BALANCE} />
          <Tooltip
            title={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_GAS_WALLET_VIEW_DERIVED_BALANCE_HELP} />}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </Space>
      }
    >
      <GasWalletDerivedBalanceView data-test={dataTest && `${dataTest}-balance`} wallet={wallet} />
    </Descriptions.Item>
  </Descriptions>
);

const GasWalletViewHOC = withGasWallet(GasWalletView);

const GasWalletViewMemo = React.memo(GasWalletViewHOC) as typeof GasWalletViewHOC;

export default GasWalletViewMemo;
