import { Tooltip } from 'antd';
import React from 'react';

import { BrandingAuth } from '@/features/branding/types';

import { BrandingAuthIcon } from './components';

import type { BrandingAuthLabelProps } from './types';

const mapping: Record<BrandingAuth, string> = {
  [BrandingAuth.google]: 'Google Auth',
  [BrandingAuth.email]: 'Email',
  [BrandingAuth.coinbase]: 'Coinbase Wallet',
  [BrandingAuth.metamask]: 'Metamask Wallet',
  [BrandingAuth['wallet-connect']]: 'WalletConnect',
};

const BrandingAuthLabel: React.FC<BrandingAuthLabelProps> = ({ mode = 'medium', value, 'data-test': dataTest }) =>
  // eslint-disable-next-line no-nested-ternary
  mode === 'short' ? (
    <Tooltip title={mapping[value]}>
      <BrandingAuthIcon data-test={dataTest} value={value} />
    </Tooltip>
  ) : mode === 'medium' ? (
    <>{mapping[value]}</>
  ) : (
    <>
      <BrandingAuthIcon data-test={dataTest} value={value} />
      &nbsp;
      {mapping[value]}
    </>
  );

const BrandingAuthLabelMemo = React.memo(BrandingAuthLabel);

export default BrandingAuthLabelMemo;
