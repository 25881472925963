import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPreferences } from '@/features/user/actions';
import { makeSelectPreferences } from '@/features/user/selectors';
import type { UserPreferences } from '@/features/user/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

import useUser from './useUser';

const dataFetchingSelector = makeSelectPending(fetchPreferences);
const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<UserPreferences>, Parameters<typeof fetchPreferences>[0]> =>
  fetchPreferences({ force });
const dataSelector = makeSelectPreferences();

export default function usePreferences() {
  const { data } = useUser();
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector, !!data.data);
}
