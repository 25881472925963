import type {
  ChurnRateAPIModel,
  GrowthRatePerPlanAPIModel,
  MonthlyGrowthRateRecordAPIModel,
  MonthlyGrowthRateStatisticsAPIModel,
  MonthlyRevenuePerPlanAPIModel,
  MonthlyRevenueRecordAPIModel,
  MonthlyRevenueStatisticsAPIModel,
  OverallPlanChurnRateAPIModel,
  PlanChurnRateAPIModel,
  PlanChurnRateRecordAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

export const NAMESPACE = 'subscription-statistics';

export interface PlanChurnRate extends Omit<PlanChurnRateAPIModel, 'perCharge'> {
  perCharge: PlanChurnRateRecordAPIModel[];
}

export interface SubscriptionChurnRate extends Omit<ChurnRateAPIModel, 'median' | 'mean' | 'perPlan'> {
  median: OverallPlanChurnRateAPIModel[];
  mean: OverallPlanChurnRateAPIModel[];
  perPlan: PlanChurnRate[];
}

export interface MonthlyRevenue extends Omit<MonthlyRevenueRecordAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export interface MonthlyRevenuePerPlan extends Omit<MonthlyRevenuePerPlanAPIModel, 'perMonth'> {
  perMonth: MonthlyRevenue[];
}

export interface SubscriptionRevenue
  extends Omit<MonthlyRevenueStatisticsAPIModel, 'total' | 'median' | 'mean' | 'perPlan'> {
  total: MonthlyRevenue[];
  median: MonthlyRevenue[];
  mean: MonthlyRevenue[];
  perPlan: MonthlyRevenuePerPlan[];
}

export type MonthlyGrowthRate = MonthlyGrowthRateRecordAPIModel;

export interface MonthlyGrowthRatePerPlan extends Omit<GrowthRatePerPlanAPIModel, 'perMonth'> {
  perMonth: MonthlyGrowthRate[];
}

export interface SubscriptionGrowthRate
  extends Omit<MonthlyGrowthRateStatisticsAPIModel, 'median' | 'mean' | 'perPlan'> {
  median: MonthlyGrowthRate[];
  mean: MonthlyGrowthRate[];
  perPlan: MonthlyGrowthRatePerPlan[];
}

export interface SubscriptionStatisticsState {
  asset?: string;
  churnRate: LoadingStateWithDirty<SubscriptionChurnRate>;
  growthRate: LoadingStateWithDirty<SubscriptionGrowthRate>;
  revenue: LoadingStateWithDirty<SubscriptionRevenue>;
}
