import React from 'react';

import { PaymentTransactionView } from '@/features/payment-transactions/components';

import { withPaymentTransactionDataLoading } from './hocs';

const PaymentTransactionCard = withPaymentTransactionDataLoading((props) => (
  <PaymentTransactionView {...props} columns={1} />
));

const PaymentTransactionCardMemo = React.memo(PaymentTransactionCard) as typeof PaymentTransactionCard;

export default PaymentTransactionCardMemo;
