import { cx } from '@emotion/css';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import type { CompanyNameItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const rules = [
  {
    transform: (v?: string) => v?.trim(),
    min: 4,
    message: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANY_NAME_ITEM_ERROR_TOO_SHORT} />,
  },
];

const CompanyNameItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  placeholder,
  readonly,
  required,
  messages: baseMessages,
  InputProps: BaseInputProps = {},
  ItemProps = {},
}: CompanyNameItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const messages = useMemo(
    () => ({
      label: baseMessages?.label || <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANY_NAME_ITEM_LABEL} />,
      required: baseMessages?.required ?? (
        <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANY_NAME_ITEM_ERROR_REQUIRED} />
      ),
    }),
    [baseMessages?.label, baseMessages?.required],
  );
  const InputProps = useMemo(
    () => ({
      ...BaseInputProps,
      className: cx(BaseInputProps.className, 'ym-hide-content'),
    }),
    [BaseInputProps],
  );
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      required={required}
      messages={messages}
      rules={rules}
      placeholder={placeholder ?? i18n({ id: I18nFeatureCompany.COMPONENTS_COMPANY_NAME_ITEM_PLACEHOLDER })}
      InputProps={InputProps}
      ItemProps={ItemProps}
    />
  );
};

const CompanyNameItemMemo = React.memo(CompanyNameItem) as typeof CompanyNameItem;

export default CompanyNameItemMemo;
