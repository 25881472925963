import { PageLoading } from '@/components';
import { useLocale } from '@/features/i18n/hooks';
import { useFetching } from '@/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import { BlankLayout } from '@/layouts';

import type React from 'react';

const withI18nDetection = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithI18nDetection',
  )((props) => {
    const { locale, initialized, init, initializing } = useLocale();
    useFetching(init, !initialized, initializing);
    return initialized ? (
      <Component locale={locale} {...props} />
    ) : (
      <BlankLayout>
        <PageLoading type="withI18nDetection" />
      </BlankLayout>
    );
  });

export default withI18nDetection;
