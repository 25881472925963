import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeSubscriptionsListParameters } from '@/features/subscriptions/actions';
import useSubscriptionList from '@/features/subscriptions/hooks/useSubscriptionList';
import type { Subscription, SubscriptionExtended, SubscriptionFilterPredicate } from '@/features/subscriptions/types';
import type { SubscriptionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseSubscriptionListView = UseAppListDataView<
  SubscriptionExtended,
  SubscriptionFilterPredicate,
  SubscriptionSortByAPIModel,
  LoadingListDataState<Subscription>
>;

export default function useSubscriptionListView(): UseSubscriptionListView {
  const state = useSubscriptionList();
  return useAppListDataView(state, storeSubscriptionsListParameters);
}
