import React, { useCallback, useMemo } from 'react';

import type { EditDonationFormAssetData } from '@/features/donations/components/EditDonationForm/types';
import { withAntDesignInputMemo } from '@/hocs';
import { noop } from '@/infrastructure/utils/functions';

import { DonationAssetReadonlyView, DonationAssetEditFormItemInput } from './components';

import type { DonationAssetListItemProps } from './types';

const DonationAssetListItem: React.FC<DonationAssetListItemProps> = ({
  'data-test': dataTest,
  name,
  fullName,
  editing,
  editable,
  onEdit,
  onCancelEdit,
  onFinishEdit,
  value,
  onChange,
  onRemove,
  selectedAssets,
}) => {
  const otherSelectedAssets = useMemo(
    () => (value?.asset ? selectedAssets.filter((selectedAsset) => selectedAsset !== value.asset) : selectedAssets),
    [selectedAssets, value?.asset],
  );
  const doUpdateStatus = useCallback(
    () => (value ? onChange?.({ ...value, isActive: !value.isActive }) : noop()),
    [onChange, value],
  );
  if (!value) {
    return null;
  }
  return editing ? (
    <DonationAssetEditFormItemInput
      data-test={dataTest}
      name={name}
      fullName={fullName}
      value={value}
      onChange={onChange}
      onCancel={onCancelEdit}
      onFinish={onFinishEdit}
      selectedAssets={otherSelectedAssets}
    />
  ) : (
    <DonationAssetReadonlyView
      data-test={dataTest}
      value={value}
      onEdit={onEdit}
      onUpdateStatus={doUpdateStatus}
      onRemove={onRemove}
      editable={editable}
    />
  );
};

const DonationAssetListItemMemo = React.memo(
  withAntDesignInputMemo<EditDonationFormAssetData, DonationAssetListItemProps>(DonationAssetListItem),
) as typeof DonationAssetListItem;

export default DonationAssetListItemMemo;
