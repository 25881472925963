import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { ProfileCard, CompaniesCard, ProfileBreadcrumb } from './components';

import type React from 'react';

const dataTest: string | undefined = 'profile';

const SettingsProfilePage: React.FC = () => (
  <SettingsPageLayout
    data-test={dataTest && `${dataTest}-container`}
    selected={SettingsTabType.profile}
    breadcrumb={<ProfileBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <ProfileCard data-test={dataTest && `${dataTest}-card`} />
    <CompaniesCard data-test={dataTest && `${dataTest}-companies`} />
  </SettingsPageLayout>
);

export default SettingsProfilePage;
