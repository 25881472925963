import React, { useCallback } from 'react';

import { FormattedMessage, PageCard } from '@/components';
import {
  type PayoutDestinationDetailedFilterFormProps,
  PayoutDestinationFilterForm,
  PayoutDestinationTable,
} from '@/features/payouts/components';
import { usePayout, usePayoutBatchDestinationsListView } from '@/features/payouts/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';

import type { PayoutDestinationsCardProps } from './types';
import type { FC } from 'react';

const PayoutDestinationsCard: FC<PayoutDestinationsCardProps> = ({ id, ...props }) => {
  const parameters = usePayoutBatchDestinationsListView(id);
  const { data: payout } = usePayout(id.payoutId);
  const FilterForm = useCallback(
    (filterProps: Omit<PayoutDestinationDetailedFilterFormProps, 'asset'>) => (
      <PayoutDestinationFilterForm asset={payout.data?.amount.asset} {...filterProps} />
    ),
    [payout.data?.amount.asset],
  );
  return (
    <PageCard
      title={<FormattedMessage id={I18nPageSettlements.PAYOUT_BATCH_VIEW_COMPONENTS_DESTINATIONS_CARD_TITLE} />}
      cardSize="big"
    >
      <PayoutDestinationTable {...parameters} {...props} FilterForm={FilterForm} />
    </PageCard>
  );
};

const PayoutDestinationsCardMemo = React.memo(PayoutDestinationsCard);

export default PayoutDestinationsCardMemo;
