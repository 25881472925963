import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import { fetchBrandingProfile } from '@/features/branding/actions';
import { makeSelectBrandingProfile } from '@/features/branding/selectors';
import type { MerchantBrandingProfileExtended, PaymentsBranding } from '@/features/branding/types';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { combine, mapStoredState, storedDataLoaded } from '@/infrastructure/model';
import { withVoidOrThrow } from '@/infrastructure/utils/functions';

import useBrandingProfiles from './useBrandingProfiles';

export type UseBrandingProfile = UseAppSingleData<MerchantBrandingProfileExtended, void>;

const fetchFactory = (profileId: string, force: boolean) => fetchBrandingProfile({ id: profileId, force });
const noProfilesData = storedDataLoaded<PaymentsBranding>();
const noData = storedDataLoaded<MerchantBrandingProfileExtended>();

export default function useBrandingProfile(profileId: string | undefined): UseBrandingProfile {
  const profilesState = useBrandingProfiles();
  const settingsState = useCompanySettings();
  const profileState = useAppSingleData(
    fetchFactory,
    makeSelectBrandingProfile,
    noProfilesData,
    profileId,
    makeSelectPending(fetchBrandingProfile, profileId),
  );
  return useMemo(
    () => ({
      data: profileId
        ? combine(
            settingsState.data,
            combine(
              mapStoredState(profilesState.data, (profilesData) => profilesData.find(({ id }) => id === profileId)),
              profileState.data,
              (metadata, branding) => ({ ...metadata, data: branding }),
            ),
            (settingsData, profileData) => ({
              ...profileData,
              qr: settingsData.qrSupport.filter(({ isEnabled }) => isEnabled).map(({ blockchain }) => blockchain),
            }),
          )
        : noData,
      loading: profilesState.loading || profileState.loading,
      forceRefresh: withVoidOrThrow(() =>
        Promise.all([profilesState.forceRefresh(), profileState.forceRefresh(), settingsState.forceRefresh()]),
      ),
    }),
    [profilesState, profileState, profileId, settingsState],
  );
}
