import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';

import MerchantWalletTransfersLink from '../MerchantWalletTransfersLink';

import type { MerchantWalletTransfersBreadcrumbProps } from './types';

const MerchantWalletTransfersBreadcrumb: React.FC<MerchantWalletTransfersBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  items = [],
}) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.TRANSFERS_COMPONENTS_BREADCRUMB} />
        ) : (
          <MerchantWalletTransfersLink
            mode="text"
            title={<FormattedMessage id={I18nPageAudit.TRANSFERS_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const MerchantWalletTransfersBreadcrumbMemo = React.memo(MerchantWalletTransfersBreadcrumb);

export default MerchantWalletTransfersBreadcrumbMemo;
