import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { rechargeTransactionViewLink } from '@/pages/recharges/routes';

import type { RechargeTransactionLinkProps } from './types';

const RechargeTransactionLink: React.FC<RechargeTransactionLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={rechargeTransactionViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageRecharges.TRANSACTION_VIEW_COMPONENTS_LINK} />}
    icon={icon}
  />
);

const RechargeTransactionLinkMemo = React.memo(RechargeTransactionLink);

export default RechargeTransactionLinkMemo;
