import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useCompanies } from '@/features/company/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import type { OperationRefreshCompaniesProps } from './types';

const messages = {
  title: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REFRESH_COMPANIES_TITLE} />,
  error: {
    message: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REFRESH_COMPANIES_ERROR_MESSAGE} />,
  },
  success: {
    message: <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_REFRESH_COMPANIES_SUCCESS_MESSAGE} />,
  },
};

const OperationRefreshCompanies: React.FC<OperationRefreshCompaniesProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useCompanies();

  return <OperationRefresh data-test={dataTest} messages={messages} refresh={forceRefresh} mode={mode} />;
};

const OperationRefreshCompaniesMemo = React.memo(OperationRefreshCompanies);

export default OperationRefreshCompaniesMemo;
