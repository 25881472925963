import React from 'react';

import { OperationRefresh } from '@/components';
import { useSettlementSchedule } from '@/features/settlements/hooks';

import type { OperationRefreshSettlementScheduleProps } from './types';

const OperationRefreshSettlementSchedule: React.FC<OperationRefreshSettlementScheduleProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useSettlementSchedule();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshSettlementScheduleMemo = React.memo(OperationRefreshSettlementSchedule);

export default OperationRefreshSettlementScheduleMemo;
