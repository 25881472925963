import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import {
  fetchMultiplePaymentTransaction,
  storeMultiplePaymentTransaction,
} from '@/features/payment-transactions/actions';
import { queryPaymentTransactionsForPayment } from '@/features/payment-transactions/api';
import { makeSelectDirtyPaymentTransactionIds } from '@/features/payment-transactions/selectors';
import { searchIdToStoreKey, txToSearchId } from '@/features/payment-transactions/utils';
import { markReportListDirty, storeReport } from '@/features/reports/actions';
import type {
  PaymentSortByAPIModel,
  PaymentTransactionSearchIdAPIModel,
  NewPaymentAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { defaultPageFn } from '@/infrastructure/api';
import { mapLoadingState, mapStoredState, storedDataLoaded, withApiRequest } from '@/infrastructure/model';
import { createListActions } from '@/infrastructure/model/list/actions';
import { listStateToSliceRequest, sliceToListData, sliceToMultipleEntities } from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { stringFormat } from '@/infrastructure/utils/string';

import { queryPayment, queryPayments, requestCreatePayment, requestExportPayments } from './api';
import {
  makeSelectDirtyPaymentIds,
  makeSelectMultiplePayment,
  makeSelectPayment,
  makeSelectPaymentListData,
  makeSelectPaymentListParameters,
  makeSelectTransactionsForPayment,
} from './selectors';
import { NAMESPACE } from './types';

import type { Payment, PaymentFilterPredicate } from './types';

export const { storePayment, storeMultiplePayment, markPaymentDirty } = createSingleActions<Payment, 'Payment'>(
  NAMESPACE,
  'Payment',
);

export const { storePaymentListParameters, storePaymentListData, markPaymentListDirty } = createListActions<
  Payment,
  'Payment',
  PaymentFilterPredicate,
  PaymentSortByAPIModel
>(NAMESPACE, 'Payment' as const);

export const { markTransactionsForPaymentDirty, storeTransactionsForPayment } = createSingleActions<
  PaymentTransactionSearchIdAPIModel[],
  'TransactionsForPayment'
>(NAMESPACE, 'TransactionsForPayment');

const paymentFetchLimit = pLimit(1);
export const fetchPayment = createAppAsyncThunk(
  `${NAMESPACE}/fetchPayment`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    paymentFetchLimit(async () => {
      const saved = makeSelectPayment(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withApiRequest(queryPayment, 'unable to fetch payment')(id, { signal });
      dispatch(storePayment({ id, data }));

      return makeSelectPayment(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const paymentsMultipleFetchLimit = pLimit(1);
export const fetchMultiplePayments = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultiplePayments`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    paymentsMultipleFetchLimit(async () => {
      const absent = makeSelectDirtyPaymentIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultiplePayment(ids)(getState());
      }

      // const network = makeSelectSelectedNetwork()(getState());
      const data = await withApiRequest(queryPayments, 'unable to fetch payments')(
        { filter: { idIn: absent }, page: defaultPageFn({ perPage: absent.length }) },
        {
          signal,
        },
      );
      dispatch(
        storeMultiplePayment(
          toMultiplePayload(
            mapStoredState(data, ({ list }) => list),
            ids,
            ({ id }) => id,
          ),
        ),
      );

      return makeSelectMultiplePayment(ids)(getState());
    }),
);

const paymentsFetchLimit = pLimit(1);
export const fetchPayments = createAppAsyncThunk(
  `${NAMESPACE}/fetchPayments`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    paymentsFetchLimit(async () => {
      const saved = makeSelectPaymentListData()(getState());
      if (!force && !saved.isDirty && !saved.isTotalDirty) {
        return saved;
      }

      const data = await withApiRequest(queryPayments, 'unable to fetch payments')(
        listStateToSliceRequest({ data: saved, ...makeSelectPaymentListParameters()(getState()) }),
        { signal },
      );
      dispatch(storePaymentListData(mapLoadingState(data, sliceToListData(saved.data?.total))));
      if (data.data) {
        dispatch(storeMultiplePayment(sliceToMultipleEntities(data.data, ({ id }) => id)));
      }

      return makeSelectPaymentListData()(getState());
    }),
);

const transactionPerPaymentFetchLimit = pLimit(1);
export const fetchPaymentTransactionForPayment = createAppAsyncThunk(
  `${NAMESPACE}/fetchPaymentTransactionForPayment`,
  async ({ force, paymentId }: { force?: boolean; paymentId: string }, { dispatch, getState, signal }) =>
    transactionPerPaymentFetchLimit(async () => {
      const saved = makeSelectTransactionsForPayment(paymentId)(getState());
      if (!force && !saved.isDirty) {
        if (saved.data) {
          const absent = makeSelectDirtyPaymentTransactionIds(saved.data.map(txToSearchId))(getState());
          if (absent.length) {
            await dispatch(fetchMultiplePaymentTransaction({ ids: absent }));
          }
        }
        return makeSelectTransactionsForPayment(paymentId)(getState());
      }

      const data = await withApiRequest(queryPaymentTransactionsForPayment, 'unable to fetch transactions')(paymentId, {
        signal,
      });
      if (data.data) {
        dispatch(
          storeMultiplePaymentTransaction(
            toMultiplePayload({ data: data.data }, data.data.map(txToSearchId), txToSearchId, searchIdToStoreKey),
          ),
        );
      }
      dispatch(
        storeTransactionsForPayment({
          id: paymentId,
          data: mapStoredState(data, (transactions) => transactions.map(txToSearchId)),
        }),
      );

      return makeSelectTransactionsForPayment(paymentId)(getState());
    }),
);

export const createPayment = createAppAsyncThunk(
  `${NAMESPACE}/createPayment`,
  async (payment: NewPaymentAPIModel, { dispatch, signal }) => {
    const result = await withApiRequest(requestCreatePayment)(payment, { signal });
    if (result.data) {
      dispatch(markPaymentListDirty());
    }
    return result;
  },
);

export const exportPayments = createAppAsyncThunk(
  `${NAMESPACE}/exportPayments`,
  async (_, { getState, dispatch, signal }) => {
    const { filter: filters } = makeSelectPaymentListParameters()(getState());
    const { createdRangeRelative, ...filter } = filters;
    const data = await withApiRequest(requestExportPayments)(filter, { signal });

    if (data.data) {
      dispatch(storeReport({ id: data.data.id, data }));
      dispatch(markReportListDirty());
    }
    return data;
  },
);

export const generatePaymentURL = createAppAsyncThunk(
  `${NAMESPACE}/generatePaymentURL`,
  ({ paymentId }: { paymentId: string }) =>
    storedDataLoaded(stringFormat(window.env.NCPS_PAYMENTS_WIDGET_URL_TEMPLATE, { paymentId })),
);

// FIXME: implement me!
export const fetchNotifications = createAppAsyncThunk(`${NAMESPACE}/fetchNotifications`, () => storedDataLoaded([]));

// FIXME: implement me!
export const retryNotification = createAppAsyncThunk(
  `${NAMESPACE}/fetchNotifications`,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (_: { paymentId: string; notificationId: string }) => storedDataLoaded(undefined),
);
