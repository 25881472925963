import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import ReportScheduleViewLink from '@/pages/reports/report-schedule-view/components/ReportScheduleViewLink';
import ReportSchedulesBreadcrumb from '@/pages/reports/report-schedules/components/ReportSchedulesBreadcrumb';

import type { ReportScheduleViewBreadcrumbProps } from './types';

const ReportScheduleBreadcrumbRaw: React.FC<ReportScheduleViewBreadcrumbProps> = ({
  items = [],
  scheduleId,
  disabled,
  'data-test': dataTest,
}) => (
  <ReportSchedulesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageReports.REPORT_SCHEDULE_VIEW_COMPONENTS_BREADCRUMB}
            values={{ id: scheduleId }}
          />
        ) : (
          <ReportScheduleViewLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={scheduleId}
            title={
              <FormattedMessage
                id={I18nPageReports.REPORT_SCHEDULE_VIEW_COMPONENTS_BREADCRUMB}
                values={{ id: scheduleId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const ReportScheduleBreadcrumb = React.memo(ReportScheduleBreadcrumbRaw);

export default ReportScheduleBreadcrumb;
