import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import SettlementHistoryBreadcrumb from '@/pages/settlements/history/components/SettlementHistoryBreadcrumb';
import SettlementViewLink from '@/pages/settlements/settlement-view/components/SettlementViewLink';

import type { SettlementViewBreadcrumbProps } from './types';

const SettlementViewBreadcrumbRaw: React.FC<SettlementViewBreadcrumbProps> = ({
  'data-test': dataTest,
  settlementId,
  disabled,
  items = [],
}) => (
  <SettlementHistoryBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageSettlements.SETTLEMENT_VIEW_COMPONENTS_BREADCRUMB}
            values={{ id: settlementId }}
          />
        ) : (
          <SettlementViewLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={settlementId}
            title={
              <FormattedMessage
                id={I18nPageSettlements.SETTLEMENT_VIEW_COMPONENTS_BREADCRUMB}
                values={{ id: settlementId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const SettlementViewBreadcrumb = React.memo(SettlementViewBreadcrumbRaw);

export default SettlementViewBreadcrumb;
