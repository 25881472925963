import { StopOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSettlementScheduleActions } from '@/features/settlements/hooks';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationCancelSettlementScheduleProps } from './types';

const OperationCancelSettlementSchedule: React.FC<OperationCancelSettlementScheduleProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { cancel: cancelAction } = useSettlementScheduleActions();

  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const disabledMessage = useMemo(() => {
    if (cancelAction.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_CANCEL_SCHEDULE_DISABLED_NO_DATA} />;
    }
    return undefined;
  }, [cancelAction.unavailabilityReason]);

  const doCancel = useMemo(
    () =>
      withSuppressError(
        withSuccess(
          withDefaultError(cancelAction.act, {
            message: () => (
              <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_CANCEL_SCHEDULE_FAILED_MESSAGE} />
            ),
          }),
          () => ({
            message: (
              <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_CANCEL_SCHEDULE_SUCCESS_MESSAGE} />
            ),
          }),
        ),
      ),
    [cancelAction.act, withDefaultError, withSuccess],
  );

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_CANCEL_SCHEDULE_TITLE} />}
      tooltip={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_CANCEL_SCHEDULE_TOOLTIP} />}
      disabled={!!disabledMessage}
      disabledMessage={disabledMessage}
      icon={<StopOutlined style={{ color: 'red' }} />}
      onClick={doCancel}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationCancelSettlementScheduleMemo = React.memo(OperationCancelSettlementSchedule);

export default OperationCancelSettlementScheduleMemo;
