import { FormattedMessage } from '@/components';
import type { CollectableEntityProcessTransaction } from '@/features/collectable/types';
import { OperationRefreshDonationAssetDeployTransaction } from '@/features/donations/components';
import { useDonationAddressDeployTransaction } from '@/features/donations/hooks';
import type { DonationAssetId } from '@/features/donations/types';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDonationAssetDeployTransactionDataLoading = <
  Original extends { data: CollectableEntityProcessTransaction } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & DonationAssetId,
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithDonationAddressDeployTransactionDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useDonationAddressDeployTransaction(props.donationId, props.asset);
    return withCardDataLoading<CollectableEntityProcessTransaction, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageDonations.DONATION_ASSET_VIEW_COMPONENTS_DEPLOY_TRANSACTION_CARD_TITLE}
          values={{ id: props.donationId }}
        />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <OperationRefreshDonationAssetDeployTransaction
            data-test={dataTest && `${dataTest}-opRefresh`}
            donationId={props.donationId}
            asset={props.asset}
          />
        ),
      },
    })(Component)(props);
  });

export default withDonationAssetDeployTransactionDataLoading;
