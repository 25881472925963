import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import { AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import { I18nFeatureSubscriptionCharges } from '@/generated/i18n/i18n';

import SubscriptionChargeStatusView from '../SubscriptionChargeStatusView';

import type { SubscriptionChargeViewProps } from './types';

const SubscriptionChargeView: React.FC<SubscriptionChargeViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  columns = {
    xl: 2,
    lg: 1,
    md: 1,
    sm: 1,
    xs: 1,
  },
  SubscriptionLink,
  SettlementLink,
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<SubscriptionChargeStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    {SubscriptionLink && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionCharges.LABELS_ROW_TITLE_ADDRESS} />}>
        <SubscriptionLink data-test={dataTest && `${dataTest}-address`} value={data.contractAddress} />
      </Descriptions.Item>
    )}
    {data.hash && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionCharges.LABELS_ROW_TITLE_HASH} />}>
        <TransactionLink data-test={dataTest && `${dataTest}-txId`} assetId={data.amount.asset} tx={data.hash} />
      </Descriptions.Item>
    )}
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionCharges.LABELS_ROW_TITLE_CHARGED_AT} />}>
      <ReadonlyDateTime data-test={dataTest && `${dataTest}-chargeAt`} value={data.chargeDate} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionCharges.LABELS_ROW_TITLE_AMOUNT} />}>
      <AssetAmount
        value={data.amount.value}
        assetId={data.amount.asset}
        withCurrency
        currencyMode="long"
        data-test={dataTest && `${dataTest}-amount`}
      />
    </Descriptions.Item>
    {SettlementLink && data.withdrawnWith && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionCharges.LABELS_ROW_TITLE_SETTLEMENT} />}>
        <SettlementLink data-test={dataTest && `${dataTest}-settlement`} value={data.withdrawnWith} />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const SubscriptionChargeViewMemo = React.memo(SubscriptionChargeView);

export default SubscriptionChargeViewMemo;
