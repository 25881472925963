import { SafetyCertificateOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation, defaultDialogFormLayout, defaultDialogFormTailLayout } from '@/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import type { NotificationOptions } from '@/hooks';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import { ConfirmEmailForm } from './components';

import type { OperationConfirmEmailProps } from './types';

const options: NotificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_MESSAGE_ERROR} />,
  success: () => ({
    message: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_MESSAGE_SUCCESS} />,
    description: (
      <FormattedMessage
        id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_MESSAGE_SUCCESS_DESCRIPTION}
      />
    ),
  }),
};

const OperationConfirmEmail: React.FC<OperationConfirmEmailProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { confirmEmail: confirmEmailAction } = useCompanySettingsActions();
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const confirmEmail = useMemo(
    () =>
      withNotification(
        formControl.withFormHide(
          withOnSuccess((data: { email: string; token: string }) => confirmEmailAction.act(data), onSuccess),
        ),
        options,
      ),
    [withNotification, formControl, onSuccess, confirmEmailAction],
  );

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <ConfirmEmailForm
            layout={defaultDialogFormLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={confirmEmail}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_CONFIRM_EMAIL_LABEL} />}
        icon={<SafetyCertificateOutlined />}
        onClick={formControl.show}
        disabled={disabled || !confirmEmailAction.available}
        inProgress={inProgress || formControl.visible || confirmEmailAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationConfirmEmailMemo = React.memo(OperationConfirmEmail);

export default OperationConfirmEmailMemo;
