import { Space, Tag } from 'antd';
import React, { useMemo } from 'react';

import { seedToParts } from '@/features/dictionary/blockchain/components/SeedItem/utils';

import type { SeedViewProps } from './types';

const SeedView: React.FC<SeedViewProps> = ({ 'data-test': dataTest, value }) => {
  const items = useMemo(
    () =>
      (Array.isArray(value) ? value : seedToParts(value)).map((i) => (
        <Tag key={i} style={{ fontWeight: 'bold' }}>
          {i}
        </Tag>
      )),
    [value],
  );
  return (
    <Space direction="horizontal" wrap className="ym-hide-content" data-test={dataTest}>
      {items}
    </Space>
  );
};

const SeedViewMemo = React.memo(SeedView);

export default SeedViewMemo;
