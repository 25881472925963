/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface PaymentTransactionBlockchainIdAPIModel
 */
export interface PaymentTransactionBlockchainIdAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof PaymentTransactionBlockchainIdAPIModel
     */
    bt: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof PaymentTransactionBlockchainIdAPIModel
     */
    hash: string;
}

/**
 * Check if a given object implements the PaymentTransactionBlockchainIdAPIModel interface.
 */
export function instanceOfPaymentTransactionBlockchainIdAPIModel(value: object): boolean {
    if (!('bt' in value)) return false;
    if (!('hash' in value)) return false;
    return true;
}

export function PaymentTransactionBlockchainIdAPIModelFromJSON(json: any): PaymentTransactionBlockchainIdAPIModel {
    return PaymentTransactionBlockchainIdAPIModelFromJSONTyped(json, false);
}

export function PaymentTransactionBlockchainIdAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionBlockchainIdAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'bt': BlockchainTypeAPIModelFromJSON(json['bt']),
        'hash': json['hash'],
    };
}

export function PaymentTransactionBlockchainIdAPIModelToJSON(value?: PaymentTransactionBlockchainIdAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'bt': BlockchainTypeAPIModelToJSON(value['bt']),
        'hash': value['hash'],
    };
}

