import { Space } from 'antd';
import React from 'react';

import {
  OperationChangeSubscriptionPlanStatus,
  OperationRemoveSubscriptionPlan,
} from '@/features/subscription-plans/components';
import SubscriptionPlanEditLink from '@/pages/subscriptions/plan-edit/components/SubscriptionPlanEditLink';

import type { SubscriptionPlansTableOperationsProps } from './types';

const SubscriptionPlansTableOperations: React.FC<SubscriptionPlansTableOperationsProps> = ({
  'data-test': dataTest,
  value: { id },
}) => (
  <Space>
    <OperationChangeSubscriptionPlanStatus planId={id} data-test={dataTest && `${dataTest}-${id}-status`} />
    <SubscriptionPlanEditLink value={id} data-test={dataTest && `${dataTest}-${id}-update`} />
    <OperationRemoveSubscriptionPlan planId={id} data-test={dataTest && `${dataTest}-${id}-remove`} confirmation />
  </Space>
);

const SubscriptionPlansTableOperationsMemo = React.memo(SubscriptionPlansTableOperations);

export default SubscriptionPlansTableOperationsMemo;
