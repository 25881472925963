import { Checkbox } from 'antd';
import isNil from 'lodash/isNil';
import React, { type ForwardedRef, useCallback } from 'react';

import type { FormCheckboxProps } from '@/components/CheckboxItem/types';

import type { CheckboxRef } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox';

const FormCheckboxRaw = (
  { 'data-test': dataTest, readonly, value, onChange: baseOnChange, CheckboxProps }: FormCheckboxProps,
  ref: ForwardedRef<CheckboxRef>,
) => {
  const onChange = useCallback(
    ({ target }: CheckboxChangeEvent) => baseOnChange && baseOnChange(target.checked),
    [baseOnChange],
  );
  return (
    <Checkbox
      ref={ref}
      onChange={onChange}
      {...(!isNil(value) ? { checked: value } : {})}
      data-test={dataTest}
      disabled={readonly}
      {...CheckboxProps}
    />
  );
};

const FormCheckboxWithRef = React.forwardRef(FormCheckboxRaw);

const FormCheckbox = React.memo(FormCheckboxWithRef) as typeof FormCheckboxWithRef;

export default FormCheckbox;
