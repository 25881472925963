import { PageLoading } from '@/components';
import { useMerchantWallets } from '@/features/merchant-wallets/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

import { MerchantWalletOwnershipCard } from './components';
import { withMerchantWalletOwnershipCheckGuard } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

const withMerchantWalletOwnershipCheck = <T extends PropsWithChildren<EmptyObject>>(
  Component: React.ComponentType<T>,
): React.FC<T> =>
  withMerchantWalletOwnershipCheckGuard(
    Component,
    namedHOC(
      Component,
      'WithMerchantWalletOwnershipCheck',
    )((props) => {
      const walletsState = useMerchantWallets();

      if (!walletsState.data.data && (walletsState.data.isDirty || walletsState.loading)) {
        return (
          <MessageLayout>
            <PageLoading type="withMerchantWalletOwnershipCheck" />
          </MessageLayout>
        );
      }

      if (
        walletsState.data.data
        && !walletsState.data.isDirty
        && !walletsState.loading
        && !walletsState.data.data.length
      ) {
        return (
          <MessageLayout>
            <MerchantWalletOwnershipCard data-test="wallet" />
          </MessageLayout>
        );
      }

      return <Component {...props} />;
    }),
  );

export default withMerchantWalletOwnershipCheck;
