import React, { forwardRef } from 'react';

import { useAsset } from '@/features/dictionary/blockchain/hooks';

import type { AssetCodeProps } from './types';

const AssetCode: React.FC<AssetCodeProps> = React.memo(
  forwardRef<HTMLSpanElement, AssetCodeProps>(({ value, ...props }, ref) => {
    const { data } = useAsset(value);
    return (
      <span {...props} ref={ref}>
        {data.data?.label || value}
      </span>
    );
  }),
);

export default AssetCode;
