import { createAction } from '@reduxjs/toolkit';
import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import type { AppDispatch } from '@/app/store';
import type { ReCaptchaParams } from '@/features/recaptcha/types';
import type { User } from '@/features/user/types';
import type { OpenIdProviderTypeAPIModel } from '@/generated/api/ncps-api';
import { withAPICall } from '@/infrastructure/api';
import type { CommonLoadingState } from '@/infrastructure/model';
import { loadingDataError, loadingDataLoaded, mapLoadingState, mapStoredState } from '@/infrastructure/model';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { jwt } from '@/infrastructure/security';
import type { JWTTokenInfo, JWTTokenWithInfo } from '@/infrastructure/security/types';
import { parseJWT } from '@/infrastructure/security/types';
import createCache from '@/infrastructure/utils/cache';
import { suppressPromise, withSuppressError } from '@/infrastructure/utils/functions';
import { appPath, prepareRedirectURLParam, REDIRECT_QUERY_PARAM } from '@/infrastructure/utils/http';
import { isSameAddress } from '@/infrastructure/utils/web3/address';
import { authLink } from '@/pages/auth/routes';

import {
  queryNonce,
  requestAuthEmail,
  requestAuthWeb3,
  requestEmailResetAllowance,
  requestLogout,
  requestRelogin,
} from './api';
import { makeSelectAddressSignupStatus, makeSelectAuthToken, makeSelectEmailResetAllowanceState } from './selectors';
import { NAMESPACE } from './types';

import type { AuthStatus, JWTTokenWithUser, Web3Credentials } from './types';

export const storeAuthStatus = createAction<{
  newState: AuthStatus;
  expected?: AuthStatus;
}>(`${NAMESPACE}/storeAuthStatus`);

export const storeAuth = createAction<{
  token: CommonLoadingState<JWTTokenWithInfo>;
  user?: User;
}>(`${NAMESPACE}/storeAuth`);

const processAuth = (auth: CommonLoadingState<JWTTokenWithUser>, { dispatch }: { dispatch: AppDispatch }) => {
  try {
    if (auth.data) {
      const tokenWithInfo = parseJWT(auth.data.token);
      jwt.store(tokenWithInfo);
      dispatch(storeAuth({ token: loadingDataLoaded(tokenWithInfo), user: auth.data.user }));
      return loadingDataLoaded(tokenWithInfo.info);
    }
    dispatch(storeAuth({ token: loadingDataError(auth.error!) }));
    return loadingDataError<JWTTokenInfo>(auth.error!);
  } catch (e) {
    console.warn(e);
    dispatch(storeAuth({ token: loadingDataError('unable to parse token') }));
    return loadingDataError<JWTTokenInfo>('unable to parse token');
  }
};

export const authWeb3 = createAppAsyncThunk(
  `${NAMESPACE}/authWeb3`,
  async (
    { credentials, reCaptcha }: { credentials: Web3Credentials; reCaptcha: ReCaptchaParams },
    { dispatch, signal },
  ) =>
    processAuth(await withAPICall(requestAuthWeb3, 'Unable to login user')(credentials, reCaptcha, { signal }), {
      dispatch,
    }),
);

export interface EmailAuthPayload {
  emailToken: string;
  emailProvider: OpenIdProviderTypeAPIModel;
  credentials: Web3Credentials;
}

export const authEmail = createAppAsyncThunk(
  `${NAMESPACE}/authEmail`,
  async ({ emailToken, credentials, emailProvider }: EmailAuthPayload, { dispatch, signal }) =>
    processAuth(
      await withAPICall(requestAuthEmail, 'Unable to login user')(credentials, emailToken, emailProvider, { signal }),
      { dispatch },
    ),
);

export const storeLogout = createAction(`${NAMESPACE}/storeLogout`);

export const logout = createAppAsyncThunk(
  `${NAMESPACE}/logout`,
  async ({ silent }: { silent?: boolean }, { dispatch, signal }) => {
    const redirectURL = prepareRedirectURLParam();
    await requestLogout({ signal });
    await withSuppressError(jwt.cleanUp)(silent);
    dispatch(storeLogout());
    window.location.replace(`${appPath}${authLink()}?${REDIRECT_QUERY_PARAM}=${redirectURL}`);
  },
);

export const relogin = createAppAsyncThunk(
  `${NAMESPACE}/relogin`,
  async ({ companyId }: { companyId: number }, { dispatch, getState, signal }) => {
    const loginState = makeSelectAuthToken()(getState());
    if (loginState.data?.info.activeCompanyId === companyId) {
      return mapStoredState(loginState, ({ info }) => info);
    }
    const result = await withAPICall(requestRelogin, 'Unable to relogin user')(companyId, { signal });
    if (result.data) {
      try {
        const token = jwt.parse(result.data);
        jwt.store(token);
        dispatch(storeAuth({ token: loadingDataLoaded(token) }));
        return loadingDataLoaded(token.info);
      } catch (e) {
        console.error('unable to parse relogin token', e);
        suppressPromise(dispatch(logout({})));
        return loadingDataError<JWTTokenInfo>('unable to parse relogin token');
      }
    }
    return loadingDataError<JWTTokenInfo>(result.error!);
  },
);

export const channelLogin = createAppAsyncThunk(
  `${NAMESPACE}/channelLogin`,
  async ({ token }: { token: JWTTokenWithInfo }, { dispatch, getState }) => {
    const loginState = makeSelectAuthToken()(getState());
    if (
      loginState.data?.info.activeCompanyId === token.info.activeCompanyId
      && isSameAddress(loginState.data?.info.address, token.info.address)
    ) {
      return loginState.data;
    }
    try {
      jwt.store(token, true);
      dispatch(storeAuth({ token: loadingDataLoaded(token) }));
      return loadingDataLoaded(token.info);
    } catch (e) {
      await dispatch(logout({}));
      console.error('unable to store channel token', e);
      return loadingDataError('unable to store channel token');
    }
  },
);

export const tryInitFromJWT = createAppAsyncThunk(`${NAMESPACE}/tryInitFromJWT`, async (_, { dispatch }) => {
  try {
    const token = await jwt.restoreFromStorage();
    if (token) {
      jwt.channel.send(token);
      dispatch(storeAuth({ token: loadingDataLoaded(token) }));
      return;
    }
    dispatch(storeAuth({ token: loadingDataLoaded() }));
  } catch (e) {
    console.error(e);
    // ignoring it
    dispatch(storeAuth({ token: loadingDataError('failed to restore from JWT') }));
    await jwt.cleanUp(true);
  }
});

export const notifyAuthTokenUpdated = createAction<{
  previous?: JWTTokenInfo | undefined;
  current?: JWTTokenInfo | undefined;
}>(`${NAMESPACE}/notifyAuthTokenUpdated`);

export const storeTermsOfServiceAcceptance = createAction<{
  accepted: boolean;
}>(`${NAMESPACE}/storeTermsOfServiceAcceptance`);

const tosAcceptanceCache = createCache<boolean>(import.meta.env.VITE_TERMS_OF_SERVICE_KEY, 'temporal');

export const initTermsOfService = createAppAsyncThunk(`${NAMESPACE}/initTermsOfService`, (_, { dispatch }) => {
  const accepted = !!tosAcceptanceCache.safeRead();
  dispatch(storeTermsOfServiceAcceptance({ accepted }));
});

export const acceptTermsOfService = createAppAsyncThunk(`${NAMESPACE}/acceptTermsOfService`, (_, { dispatch }) => {
  tosAcceptanceCache.safeSave(true);
  dispatch(storeTermsOfServiceAcceptance({ accepted: true }));
});

export const { markSignupStatusDirty, storeSignupStatus } = createSingleActions<boolean, 'SignupStatus'>(
  NAMESPACE,
  'SignupStatus',
);

const fetchNonceLimit = pLimit(1);
export const fetchSignupStatus = createAppAsyncThunk(
  `${NAMESPACE}/fetchSignupStatus`,
  async ({ address, force }: { address: string; force?: boolean }, { dispatch, getState, signal }) =>
    fetchNonceLimit(async () => {
      const saved = makeSelectAddressSignupStatus(address)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }
      const result = await withAPICall(queryNonce, 'Unable to request nonce')(address, { signal });
      const data = mapLoadingState(result, (v) => v !== '0');
      dispatch(storeSignupStatus({ id: address, data }));
      return makeSelectAddressSignupStatus(address)(getState());
    }),
);
export const fetchAuthNonce = createAppAsyncThunk(
  `${NAMESPACE}/fetchAuthNonce`,
  async ({ address }: { address: string }, { dispatch, signal }) =>
    fetchNonceLimit(async () => {
      const result = await withAPICall(queryNonce, 'Unable to request nonce')(address, { signal });
      dispatch(storeSignupStatus({ id: address, data: mapLoadingState(result, (v) => v !== '0') }));
      return result;
    }),
);

export const { markEmailResetAllowanceDirty, storeEmailResetAllowance } = createSingleActions<
  boolean,
  'EmailResetAllowance'
>(NAMESPACE, 'EmailResetAllowance');

const fetchEmailResetLimit = pLimit(1);
export const fetchEmailResetAllowance = createAppAsyncThunk(
  `${NAMESPACE}/requestEmailResetAllowance`,
  async ({ email, force }: { email: string; force?: boolean }, { dispatch, getState, signal }) =>
    fetchEmailResetLimit(async () => {
      const saved = makeSelectEmailResetAllowanceState(email)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }
      const data = await withAPICall(requestEmailResetAllowance, 'Unable to request email allowance')(email, {
        signal,
      });
      dispatch(storeEmailResetAllowance({ id: email, data }));
      return makeSelectEmailResetAllowanceState(email)(getState());
    }),
);
