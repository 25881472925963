/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PayoutSortByAPIModel } from './PayoutSortByAPIModel';
import {
    PayoutSortByAPIModelFromJSON,
    PayoutSortByAPIModelFromJSONTyped,
    PayoutSortByAPIModelToJSON,
} from './PayoutSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributePayoutSortByAPIModel
 */
export interface SortAttributePayoutSortByAPIModel {
    /**
     * 
     * @type {PayoutSortByAPIModel}
     * @memberof SortAttributePayoutSortByAPIModel
     */
    attr: PayoutSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributePayoutSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributePayoutSortByAPIModel interface.
 */
export function instanceOfSortAttributePayoutSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributePayoutSortByAPIModelFromJSON(json: any): SortAttributePayoutSortByAPIModel {
    return SortAttributePayoutSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributePayoutSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributePayoutSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': PayoutSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributePayoutSortByAPIModelToJSON(value?: SortAttributePayoutSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': PayoutSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

