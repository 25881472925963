import { FormOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { settingsBrandingProfileEditLink } from '@/pages/settings/routes';

import type { BrandingProfileEditLinkProps } from './types';

const BrandingProfileEditLink: React.FC<BrandingProfileEditLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
  value: profileId,
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useMemo(
    () => (profileId ? () => openForm(settingsBrandingProfileEditLink(profileId)) : noop),
    [openForm, profileId],
  );
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      disabled={!profileId}
      icon={icon ?? <FormOutlined />}
      title={title ?? <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_EDIT_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const BrandingProfileEditLinkMemo = React.memo(BrandingProfileEditLink);

export default BrandingProfileEditLinkMemo;
