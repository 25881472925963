import useFeatureToggle from '@/features/feature-toggle/hooks/useFeatureToggle';

export type UseWeb3AuthEnabledType = boolean;

export default function useWeb3AuthEnabled(): UseWeb3AuthEnabledType {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  return (
    !!featureFlags
    && (!featureFlags.disableEmailAuth || !featureFlags.disableGoogleAuth)
    && !featureFlags.disableWeb3Auth
  );
}
