import React from 'react';

import { useCollectSchedule } from '@/features/collectable/hooks';
import type { CollectSchedule } from '@/features/collectable/types';
import { namedHOC } from '@/infrastructure/utils/react';

const emptyComponent = () => null;

const withCollectScheduleLoad = <T extends { schedule: CollectSchedule }>(
  Component: React.ComponentType<Omit<T, 'schedule'> & { schedule: CollectSchedule }>,
  EmptyComponent: React.ComponentType<Omit<T, 'schedule'>>,
) =>
  namedHOC<Omit<T, 'schedule'> & { schedule: CollectSchedule }, Omit<T, 'schedule'>>(
    Component,
    'WithCollectScheduleLoad',
  )((props) => {
    const schedule = useCollectSchedule();

    return schedule.data.data ? <Component {...props} schedule={schedule.data.data} /> : <EmptyComponent {...props} />;
  });

const withCollectSchedule =
  <T extends { schedule: CollectSchedule }>(
    EmptyComponent: React.ComponentType<Omit<T, 'schedule'>> = emptyComponent,
  ) =>
  (Component: React.ComponentType<Omit<T, 'schedule'> & { schedule: CollectSchedule }>) => {
    const LoadComponent = withCollectScheduleLoad<T>(Component, EmptyComponent);
    return namedHOC(
      Component,
      'withCollectSchedule',
    )((props) => {
      const { schedule } = props as Partial<T>;
      return schedule ? <Component {...props} schedule={schedule} /> : <LoadComponent {...props} />;
    });
  };

export default withCollectSchedule;
