import { Space } from 'antd';
import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { CollectTasksTable } from '@/features/collectable/components';
import { useCollectTaskSummaryListView } from '@/features/collectable/hooks';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageCollectable } from '@/generated/i18n/i18n';

import type { CollectTasksCardProps } from './types';

const CollectTasksCard: React.FC<CollectTasksCardProps> = (props) => {
  const parameters = useCollectTaskSummaryListView();
  const { 'data-test': dataTest } = props;
  return (
    <PageCard
      title={
        <Space>
          <FormattedMessage id={I18nPageCollectable.TASKS_COMPONENTS_TASKS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      }
      cardSize="big"
    >
      <CollectTasksTable {...parameters} {...props} />
    </PageCard>
  );
};

const CollectTasksCardMemo = React.memo(CollectTasksCard);

export default CollectTasksCardMemo;
