import { useMemo } from 'react';

import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayoutForSettlement } from '@/features/payouts/actions';
import type { UsePayoutForSettlement } from '@/features/payouts/hooks/usePayoutForSettlement/types';
import { makeSelectPayout } from '@/features/payouts/selectors';
import type { PayoutSummary } from '@/features/payouts/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetchFactory = (
  settlementId: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<PayoutSummary>, Parameters<typeof fetchPayoutForSettlement>[0]> =>
  fetchPayoutForSettlement({ settlementId, force });

export default function usePayoutForSettlement(settlementId: string | undefined): UsePayoutForSettlement {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchPayoutForSettlement, settlementId), [settlementId]);
  return useAppSingleData(fetchFactory, makeSelectPayout, noSingleDataIdFn(), settlementId, dataFetchingSelector);
}
