import { Skeleton } from 'antd';
import React, { Suspense } from 'react';

import type { CompanyUsersListProps } from './types';

const CompanyUsersListLazy = React.lazy(() => import('./components/CompanyUsersListLazy'));

const CompanyUsersList: React.FC<CompanyUsersListProps> = (props) => (
  <Suspense fallback={<Skeleton />}>
    <CompanyUsersListLazy {...props} />
  </Suspense>
);

const CompanyUsersListMemo = React.memo(CompanyUsersList);

export default CompanyUsersListMemo;
