import { Spin } from 'antd';

import { DataFetchError } from '@/components';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';

import type React from 'react';
import type { ReactNode } from 'react';

export interface WithDataLoadingParams<E> extends TestableProps {
  data: LoadingStateWithDirty<E> | undefined;
  loading: boolean;
  forceRefresh: Func;
  isNotEmptyData?: (v?: E) => boolean;
  onError?: (error: string) => ReactNode;
  hideOnLoading?: boolean;
  noSpinner?: boolean;
  hideBack?: boolean;
}

const isNotEmpty = (v: unknown) => !!v;

const withDataLoading =
  <
    Value,
    Original extends { data: Value; wrapperClassName?: string },
    Omitted extends keyof Original = 'data',
    Wrapper extends Omit<Original, 'data' | Omitted> & { wrapperClassName?: string } = Omit<Original, 'data' | Omitted>,
  >({
    data,
    forceRefresh,
    loading,
    'data-test': dataTest,
    onError,
    isNotEmptyData = isNotEmpty,
    hideOnLoading,
    noSpinner,
    hideBack,
  }: WithDataLoadingParams<Value>) =>
  (Component: React.ComponentType<Original>): React.FC<Wrapper> =>
    namedHOC<Original, Wrapper>(
      Component,
      'WithDataLoading',
    )((props) => {
      if (noSpinner) {
        return !hideOnLoading || !loading ? (
          <>
            {data?.error && <DataFetchError refresh={forceRefresh} back={!hideBack} message={onError?.(data.error)} />}
            {/* @ts-expect-error - don't know how to prove ANY component with the same props fits */}
            {data?.data && isNotEmptyData(data.data) && <Component data={data.data} data-test={dataTest} {...props} />}
          </>
        ) : null;
      }
      const { wrapperClassName } = props;

      return !hideOnLoading || !loading ? (
        <Spin
          spinning={loading}
          style={{ overflow: 'inherit', display: 'inherit' }}
          wrapperClassName={wrapperClassName}
        >
          {data?.error && <DataFetchError refresh={forceRefresh} back={!hideBack} message={onError?.(data.error)} />}
          {/* @ts-expect-error - don't know how to prove ANY component with the same props fits */}
          {data?.data && isNotEmptyData(data.data) && <Component data={data.data} data-test={dataTest} {...props} />}
        </Spin>
      ) : null;
    });

export default withDataLoading;
