import React from 'react';

import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { MerchantWalletTransfersTable } from '@/features/merchant-wallet-transfers/components';
import MerchantWalletTransferEntityLink from '@/pages/audit/transfer-entity/components/MerchantWalletTransferEntityLink';
import MerchantWalletTransferLink from '@/pages/audit/transfer-view/components/MerchantWalletTransferLink';

import { withMerchantWalletRunningBalanceTransfersCardLoading } from './hocs';

const MerchantWalletRunningBalanceTransfersCardRaw = withFeatureToggleGuard(
  'enableReconciliation',
  true,
)(
  withMerchantWalletRunningBalanceTransfersCardLoading((props) => (
    <MerchantWalletTransfersTable
      {...props}
      EntityLink={MerchantWalletTransferEntityLink}
      MerchantWalletTransferLink={MerchantWalletTransferLink}
    />
  )),
);

const MerchantWalletRunningBalanceTransfersCard = React.memo(MerchantWalletRunningBalanceTransfersCardRaw);

export default MerchantWalletRunningBalanceTransfersCard;
