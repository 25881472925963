import React from 'react';

import { FormattedMessage } from '@/components';
import { DocumentationBanner } from '@/features/user/components';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';

import RechargesDocumentationLink from '../RechargesDocumentationLink';

import type { RechargesDocumentationBannerProps } from './types';

const RechargesDocumentationBanner: React.FC<RechargesDocumentationBannerProps> = ({
  'data-test': dataTest,
  pageSize,
}) => (
  <DocumentationBanner
    PageProps={{ pageSize }}
    data-test={dataTest}
    type="recharges"
    message={
      <FormattedMessage
        id={I18nFeatureRecharges.COMPONENTS_DOCUMENTATION_BANNER_MESSAGE}
        values={{
          lk: (message: React.ReactNode) => <RechargesDocumentationLink title={message} mode="text" />,
        }}
      />
    }
  />
);

const RechargesDocumentationBannerMemo = React.memo(RechargesDocumentationBanner);

export default RechargesDocumentationBannerMemo;
