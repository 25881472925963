/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewDonationAPIModel
 */
export interface NewDonationAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewDonationAPIModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof NewDonationAPIModel
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof NewDonationAPIModel
     */
    imageKey: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewDonationAPIModel
     */
    assets?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewDonationAPIModel
     */
    defaultAmounts?: Array<string>;
}

/**
 * Check if a given object implements the NewDonationAPIModel interface.
 */
export function instanceOfNewDonationAPIModel(value: object): boolean {
    if (!('title' in value)) return false;
    if (!('description' in value)) return false;
    if (!('imageKey' in value)) return false;
    return true;
}

export function NewDonationAPIModelFromJSON(json: any): NewDonationAPIModel {
    return NewDonationAPIModelFromJSONTyped(json, false);
}

export function NewDonationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewDonationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'title': json['title'],
        'description': json['description'],
        'imageKey': json['imageKey'],
        'assets': json['assets'] == null ? undefined : json['assets'],
        'defaultAmounts': json['defaultAmounts'] == null ? undefined : json['defaultAmounts'],
    };
}

export function NewDonationAPIModelToJSON(value?: NewDonationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'title': value['title'],
        'description': value['description'],
        'imageKey': value['imageKey'],
        'assets': value['assets'],
        'defaultAmounts': value['defaultAmounts'],
    };
}

