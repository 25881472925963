import { BarChartOutlined } from '@ant-design/icons';
import { Skeleton } from 'antd';
import React, { Suspense, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { LineType, useChartPlanLabels } from '@/features/subscription-statistics/hooks';
import { I18nFeatureSubscriptionStatistics } from '@/generated/i18n/i18n';

import type { ChurnRateGraphData, SubscriptionChurnRateGraphProps } from './types';

const yAxisLabel = (text: string) => `${text} %`;
const DualAxes = React.lazy(() => import('@ant-design/charts').then((module) => ({ default: module.DualAxes })));

const SubscriptionChurnRateGraph: React.FC<SubscriptionChurnRateGraphProps> = ({
  'data-test': dataTest,
  data: { statistics, plans },
}) => {
  const { formatMessage: i18n } = useIntl();
  const { onPlanViewClick, tooltipItemValue } = useChartPlanLabels(plans);
  // TODO: 1. tooltip name configuration is not working, 2. legend configuration ruins the first render
  // const daysToPeriodPerPlan = useMemo<Record<string, Record<string, number>>>(
  //   () =>
  //     statistics.perPlan.reduce(
  //       (result, { planId, perCharge }) => ({
  //         ...result,
  //         [planId]: perCharge.reduce(
  //           (r, { chargesCount, daysToTheCharge }) => ({ ...r, [chargesCount]: daysToTheCharge }),
  //           {},
  //         ),
  //       }),
  //       {},
  //     ),
  //   [statistics.perPlan],
  // );
  // const tooltipFormatter = useCallback(
  //   (datum: Partial<ChurnRateGraphData>) => {
  //     const datumName = datum.name || '';
  //     const name =
  //       (datumName
  //         && (plans[datumName]?.description
  //           ? i18n(
  //             { id: I18nFeatureSubscriptionStatistics.COMPONENTS_CHURN_RATE_GRAPH_TOOLTIP_PLAN_TITLE },
  //             {
  //               name: plans[datumName].description.substring(0, 15),
  //               days: daysToPeriodPerPlan[datumName][`${datum.period || ''}`] ?? 0,
  //             },
  //           )
  //           : isEnumKey(LineType, datumName) && overallLineLabels[datumName]))
  //       || datumName;
  //     const value = i18n(
  //       { id: I18nFeatureSubscriptionStatistics.COMPONENTS_CHURN_RATE_GRAPH_TOOLTIP_VALUE },
  //       { value: datum.value },
  //     );
  //     return { name, value };
  //   },
  //   [daysToPeriodPerPlan, i18n, overallLineLabels, plans],
  // );

  const tooltip = useMemo(
    () => ({
      // name: 'tooltip',
      channel: 'y',
      valueFormatter: (value?: ChurnRateGraphData['value']) =>
        i18n({ id: I18nFeatureSubscriptionStatistics.COMPONENTS_CHURN_RATE_GRAPH_TOOLTIP_VALUE }, { value }),
    }),
    [i18n],
  );
  const planData = useMemo<ChurnRateGraphData[]>(
    () =>
      statistics.perPlan.flatMap(({ planId, perCharge }) =>
        perCharge.map(({ percentage, chargesCount }) => ({
          // graph will be broken if the axis data type is number
          period: `${chargesCount}`,
          value: percentage,
          name: tooltipItemValue(planId),
        })),
      ),
    [statistics.perPlan, tooltipItemValue],
  );
  const overallData = useMemo<ChurnRateGraphData[]>(
    () => [
      ...statistics.median.map(({ percentage, chargesCount }) => ({
        period: `${chargesCount}`,
        value: percentage,
        name: tooltipItemValue(LineType.median),
      })),
      ...statistics.mean.map(({ percentage, chargesCount }) => ({
        period: `${chargesCount}`,
        value: percentage,
        name: tooltipItemValue(LineType.mean),
      })),
    ],
    [statistics.mean, statistics.median, tooltipItemValue],
  );
  const max = useMemo(
    () =>
      Math.max(
        statistics.perPlan
          .flatMap(({ perCharge }) => perCharge.map(({ percentage }) => percentage))
          .reduce((r, v) => (r > v ? r : v), 0),
        0,
      ),
    [statistics.perPlan],
  );

  const axis = useMemo(
    () => ({
      x: { title: i18n({ id: I18nFeatureSubscriptionStatistics.COMPONENTS_CHURN_RATE_GRAPH_X_AXIS_TITLE }) },
      y: { labelFormatter: yAxisLabel },
    }),
    [i18n],
  );

  // const legend = useMemo(() => ({ color: { itemLabelText: legendItemName } }), [legendItemName]);
  const children = useMemo(
    () => [
      {
        data: planData,
        type: 'interval',
        yField: 'value',
        colorField: 'name',
      },
      {
        data: overallData,
        type: 'line',
        yField: 'value',
        colorField: 'name',
        seriesField: 'name',
        axis: { y: { position: 'right' } },
        style: { lineWidth: 2 },
      },
    ],
    [overallData, planData],
  );

  const scale = useMemo(() => ({ y: { domainMin: 0, domainMax: max } }), [max]);

  return (
    <Suspense
      fallback={
        <Skeleton.Node active>
          <BarChartOutlined />
        </Skeleton.Node>
      }
    >
      <DualAxes
        data-test={dataTest}
        height={600}
        scale={scale}
        onEvent={onPlanViewClick}
        xField="period"
        axis={axis}
        tooltip={tooltip}
        // legend={legend}
      >
        {children}
      </DualAxes>
    </Suspense>
  );
};

const SubscriptionChurnRateGraphMemo = React.memo(SubscriptionChurnRateGraph);

export default SubscriptionChurnRateGraphMemo;
