import { LoadingOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation, WarningTooltip } from '@/components';
import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';
import { useNativeBalance } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import NativeAssetAmount from '../NativeAssetAmount';

import type { AddressNativeBalanceViewProps } from './types';

const AddressNativeBalanceView: React.FC<AddressNativeBalanceViewProps> = ({
  'data-test': dataTest,
  bt,
  address,
  withCurrency,
  onRefresh = noop,
}) => {
  const id = useMemo(() => ({ bt, address }), [address, bt]);
  const { data: balance, loading, forceRefresh } = useNativeBalance(id);
  const { withError } = useNotification();
  const reload = useMemo(
    () =>
      withSuppressError(
        withError(withOnSuccess(forceRefresh, onRefresh), () => (
          <FormattedMessage
            id={I18nFeatureDictionary.COMPONENTS_ADDRESS_NATIVE_BALANCE_REFRESH_BALANCE_ERROR_MESSAGE}
          />
        )),
      ),
    [forceRefresh, onRefresh, withError],
  );
  if (balance.isDirty || loading) {
    return <LoadingOutlined />;
  }
  return balance.data?.amount === undefined ? (
    <Space>
      <WarningTooltip
        title={
          <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_NATIVE_BALANCE_BALANCE_NOT_FOUND_TITLE} />
        }
      >
        <WarningOutlined style={{ color: 'orange' }} />
      </WarningTooltip>
      <Operation
        title={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_NATIVE_BALANCE_REFRESH_BALANCE_TITLE} />}
        icon={<ReloadOutlined />}
        onClick={reload}
        data-test={dataTest}
        mode="icon"
      />
    </Space>
  ) : (
    <Space>
      <NativeAssetAmount bt={bt} value={balance.data.amount} data-test={dataTest} withCurrency={withCurrency} />
      <Operation
        title={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_NATIVE_BALANCE_REFRESH_BALANCE_TITLE} />}
        icon={<ReloadOutlined />}
        onClick={reload}
        data-test={dataTest}
        mode="icon"
      />
    </Space>
  );
};

const AddressNativeBalanceViewMemo = React.memo(
  withBlockchainType<AddressNativeBalanceViewProps>(AddressNativeBalanceView),
);

export default AddressNativeBalanceViewMemo;
