import { Alert } from 'antd';
import React from 'react';

import type { ErrorFormMessageProps } from './types';

const ErrorFormMessage: React.FC<ErrorFormMessageProps> = ({ content, 'data-test': dataTest }) => (
  <Alert style={{ marginBottom: 24 }} message={content} type="error" showIcon data-test={dataTest} />
);

const ErrorFormMessageMemo = React.memo(ErrorFormMessage);

export default ErrorFormMessageMemo;
