import { shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';
import type {
  ListState,
  ListParametersState,
  LoadingListDataState,
  ListColumnState,
  ListStateNoColumns,
} from '@/infrastructure/model/list/types';
import { flatMapListState } from '@/infrastructure/model/list/utils';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import type { SingleState } from '@/infrastructure/model/single/types';
import { toMultipleMerged } from '@/infrastructure/model/single/utils';
import type { EmptyObject } from '@/infrastructure/utils/ts';

export const createListSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Value,
  Filter,
  SortBy extends string,
>(
  getListState:
    | ((state: Global) => ListState<Value, Filter, SortBy>)
    | {
        listNoColumn: (state: Global) => ListStateNoColumns<Value, Filter, SortBy>;
        columnState: (state: Global) => ListColumnState;
      },
  type: Type,
) => {
  const makeSelectListStateName = `makeSelect${type}ListState` as const;
  const makeSelectListDataName = `makeSelect${type}ListData` as const;
  const makeSelectListSortByName = `makeSelect${type}ListSortBy` as const;
  const makeSelectListPageName = `makeSelect${type}ListPage` as const;
  const makeSelectListFilterName = `makeSelect${type}ListFilter` as const;
  const makeSelectListColumnsName = `makeSelect${type}ListColumns` as const;
  const makeSelectListParametersName = `makeSelect${type}ListParameters` as const;

  const makeSelectListState = () =>
    typeof getListState === 'function'
      ? (state: Global) => getListState(state)
      : createSelector(getListState.listNoColumn, getListState.columnState, (list, columnState) => ({
          ...list,
          columnState,
        }));
  const makeSelectListData = () =>
    typeof getListState === 'function'
      ? (state: Global) => getListState(state).data
      : (state: Global) => getListState.listNoColumn(state).data;
  const makeSelectListSortBy = () =>
    typeof getListState === 'function'
      ? (state: Global) => getListState(state).sortBy
      : (state: Global) => getListState.listNoColumn(state).sortBy;
  const makeSelectListPage = () =>
    typeof getListState === 'function'
      ? (state: Global) => getListState(state).page
      : (state: Global) => getListState.listNoColumn(state).page;
  const makeSelectListFilter = () =>
    typeof getListState === 'function'
      ? (state: Global) => getListState(state).filter
      : (state: Global) => getListState.listNoColumn(state).filter;
  const makeSelectListColumns = () =>
    typeof getListState === 'function'
      ? (state: Global) => getListState(state).columnState
      : (state: Global) => getListState.columnState(state);

  const makeSelectListParameters = (): ((state: Global) => ListParametersState<Filter, SortBy>) =>
    createSelector(
      makeSelectListPage(),
      makeSelectListFilter(),
      makeSelectListColumns(),
      makeSelectListSortBy(),
      (page, filter, columnState, sortBy) => ({
        page,
        filter,
        columnState,
        sortBy,
      }),
    );

  // FIXME: redefine the type without the cast
  return {
    [makeSelectListStateName]: makeSelectListState,
    [makeSelectListDataName]: makeSelectListData,
    [makeSelectListSortByName]: makeSelectListSortBy,
    [makeSelectListPageName]: makeSelectListPage,
    [makeSelectListFilterName]: makeSelectListFilter,
    [makeSelectListColumnsName]: makeSelectListColumns,
    [makeSelectListParametersName]: makeSelectListParameters,
  } as Record<typeof makeSelectListStateName, typeof makeSelectListState> &
    Record<typeof makeSelectListDataName, typeof makeSelectListData> &
    Record<typeof makeSelectListSortByName, typeof makeSelectListSortBy> &
    Record<typeof makeSelectListPageName, typeof makeSelectListPage> &
    Record<typeof makeSelectListFilterName, typeof makeSelectListFilter> &
    Record<typeof makeSelectListColumnsName, typeof makeSelectListColumns> &
    Record<typeof makeSelectListParametersName, typeof makeSelectListParameters>;
};

export const createListParameterSelectors = <
  Global extends EmptyObject,
  Type extends string,
  SortBy extends string,
  Filter,
>(
  getListParametersState: (state: Global) => ListParametersState<Filter, SortBy>,
  type: Type,
) => {
  const makeSelectListSortByName = `makeSelect${type}ListSortBy` as const;
  const makeSelectListPageName = `makeSelect${type}ListPage` as const;
  const makeSelectListFilterName = `makeSelect${type}ListFilter` as const;
  const makeSelectListColumnsName = `makeSelect${type}ListColumns` as const;
  const makeSelectListParametersName = `makeSelect${type}ListParameters` as const;

  const makeSelectListParametersState = () => (state: Global) => getListParametersState(state);
  const makeSelectListSortBy = () => (state: Global) => getListParametersState(state).sortBy;
  const makeSelectListPage = () => (state: Global) => getListParametersState(state).page;
  const makeSelectListFilter = () => (state: Global) => getListParametersState(state).filter;
  const makeSelectListColumns = () => (state: Global) => getListParametersState(state).columnState;

  // FIXME: redefine the type without the cast
  return {
    [makeSelectListSortByName]: makeSelectListSortBy,
    [makeSelectListPageName]: makeSelectListPage,
    [makeSelectListFilterName]: makeSelectListFilter,
    [makeSelectListColumnsName]: makeSelectListColumns,
    [makeSelectListParametersName]: makeSelectListParametersState,
  } as Record<typeof makeSelectListSortByName, typeof makeSelectListSortBy> &
    Record<typeof makeSelectListPageName, typeof makeSelectListPage> &
    Record<typeof makeSelectListFilterName, typeof makeSelectListFilter> &
    Record<typeof makeSelectListColumnsName, typeof makeSelectListColumns> &
    Record<typeof makeSelectListParametersName, typeof makeSelectListParametersState>;
};

export const createNestedListParametersSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Filter,
  SortBy extends string,
  ParentId = string,
>(
  getListState: (
    state: Global,
    parentId: ParentId | undefined,
  ) => Omit<ListParametersState<Filter, SortBy>, 'columnState'>,
  getColumnState: (state: Global) => ListColumnState,
  type: Type,
) => {
  const makeSelectListSortByName = `makeSelect${type}ListSortBy` as const;
  const makeSelectListPageName = `makeSelect${type}ListPage` as const;
  const makeSelectListFilterName = `makeSelect${type}ListFilter` as const;
  const makeSelectListColumnsName = `makeSelect${type}ListColumns` as const;
  const makeSelectListParametersName = `makeSelect${type}ListParameters` as const;

  const makeSelectListSortBy = (parentId: ParentId | undefined) => (state: Global) =>
    getListState(state, parentId).sortBy;
  const makeSelectListPage = (parentId: ParentId | undefined) => (state: Global) => getListState(state, parentId).page;
  const makeSelectListFilter = (parentId: ParentId | undefined) => (state: Global) =>
    getListState(state, parentId).filter;
  const makeSelectListColumns = () => getColumnState;

  const makeSelectListParameters = (
    parentId: ParentId | undefined,
  ): ((state: Global) => ListParametersState<Filter, SortBy>) =>
    createSelector(
      makeSelectListPage(parentId),
      makeSelectListFilter(parentId),
      makeSelectListColumns(),
      makeSelectListSortBy(parentId),
      (page, filter, columnState, sortBy) => ({
        page,
        filter,
        columnState,
        sortBy,
      }),
    );

  // FIXME: redefine the type without the cast
  return {
    [makeSelectListSortByName]: makeSelectListSortBy,
    [makeSelectListPageName]: makeSelectListPage,
    [makeSelectListFilterName]: makeSelectListFilter,
    [makeSelectListColumnsName]: makeSelectListColumns,
    [makeSelectListParametersName]: makeSelectListParameters,
  } as Record<typeof makeSelectListSortByName, typeof makeSelectListSortBy> &
    Record<typeof makeSelectListPageName, typeof makeSelectListPage> &
    Record<typeof makeSelectListFilterName, typeof makeSelectListFilter> &
    Record<typeof makeSelectListColumnsName, typeof makeSelectListColumns> &
    Record<typeof makeSelectListParametersName, typeof makeSelectListParameters>;
};

export const createNormalizedListSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Value,
  Filter,
  SortBy extends string,
  Id = string,
  Key extends string = string,
>(
  getListState:
    | ((state: Global) => ListState<Key, Filter, SortBy>)
    | {
        listNoColumn: (state: Global) => ListStateNoColumns<Key, Filter, SortBy>;
        columnState: (state: Global) => ListColumnState;
      },
  getSingleState: (state: Global) => SingleState<Value>,
  type: Type,
  mapper: Id extends Key ? undefined : (id: Id) => Key,
) => {
  const singleSelectors = createSingleSelectors<Global, Type, Value, Id, Key>(getSingleState, type, mapper);
  const listSelectors = createListSelectors<Global, Type, Key, Filter, SortBy>(getListState, type);
  const makeSelectListDataName = `makeSelect${type}ListData` as const;
  const makeSelectListData = () => {
    let cache: (LoadingStateWithDirty<Value> | undefined)[] | undefined;
    let cachedList: LoadingListDataState<Key> | undefined;
    let result: LoadingListDataState<Value> | undefined;
    return createSelector(
      (state: Global) =>
        (typeof getListState === 'function' ? getListState(state) : getListState.listNoColumn(state)).data,
      (state: Global) => getSingleState(state),
      (list, entities): LoadingListDataState<Value> => {
        if (result && cachedList === list) {
          if (shallowEqual(list.data?.data.map((id) => entities[id]) ?? [], cache)) {
            return result;
          }
        }
        cachedList = list;
        cache = list.data?.data.map((id) => entities[id]) ?? [];
        result = flatMapListState(list, (listData) => ({
          ...mapStoredState(toMultipleMerged<Value>(entities, listData.data), (data) => ({
            total: listData.total,
            data,
          })),
          isDirty: list.isDirty,
          isTotalDirty: list.isTotalDirty,
        }));
        return result;
      },
    );
  };

  // FIXME: redefine the type without the cast
  return {
    ...listSelectors,
    ...singleSelectors,
    [makeSelectListDataName]: makeSelectListData,
  } as Omit<typeof listSelectors, typeof makeSelectListDataName> &
    typeof singleSelectors &
    Record<typeof makeSelectListDataName, typeof makeSelectListData>;
};

export const createNestedNormalizedListSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Value,
  Filter,
  SortBy extends string,
  ParentId = string,
>(
  getListState: (state: Global, parentId: ParentId | undefined) => ListStateNoColumns<string, Filter, SortBy>,
  getColumnState: (state: Global) => ListColumnState,
  getSingleState: (state: Global) => SingleState<Value>,
  type: Type,
) => {
  const makeSelectListStateName = `makeSelect${type}ListState` as const;
  const makeSelectListDataName = `makeSelect${type}ListData` as const;
  const makeSelectListSortByName = `makeSelect${type}ListSortBy` as const;
  const makeSelectListPageName = `makeSelect${type}ListPage` as const;
  const makeSelectListFilterName = `makeSelect${type}ListFilter` as const;
  const makeSelectListColumnsName = `makeSelect${type}ListColumns` as const;
  const makeSelectListParametersName = `makeSelect${type}ListParameters` as const;

  const makeSelectListState = (parentId: ParentId | undefined) => (state: Global) => getListState(state, parentId);
  const makeSelectListData = (parentId: ParentId | undefined) => {
    let cache: (LoadingStateWithDirty<Value> | undefined)[] | undefined;
    let cachedList: LoadingListDataState<string> | undefined;
    let result: LoadingListDataState<Value> | undefined;
    return createSelector(
      (state: Global) => getListState(state, parentId).data,
      (state: Global) => getSingleState(state),
      (list, entities): LoadingListDataState<Value> => {
        if (result && cachedList === list) {
          if (shallowEqual(list.data?.data.map((id) => entities[id]) ?? [], cache)) {
            return result;
          }
        }
        cachedList = list;
        cache = list.data?.data.map((id) => entities[id]) ?? [];
        result = flatMapListState(list, (listData) => ({
          ...mapStoredState(toMultipleMerged<Value>(entities, listData.data), (data) => ({
            total: listData.total,
            data,
          })),
          isDirty: list.isDirty,
          isTotalDirty: list.isTotalDirty,
        }));
        return result;
      },
    );
  };
  const makeSelectListSortBy = (parentId: ParentId | undefined) => (state: Global) =>
    getListState(state, parentId).sortBy;
  const makeSelectListPage = (parentId: ParentId | undefined) => (state: Global) => getListState(state, parentId).page;
  const makeSelectListFilter = (parentId: ParentId | undefined) => (state: Global) =>
    getListState(state, parentId).filter;
  const makeSelectListColumns = () => getColumnState;

  const makeSelectListParameters = (
    parentId: ParentId | undefined,
  ): ((state: Global) => ListParametersState<Filter, SortBy>) =>
    createSelector(
      makeSelectListPage(parentId),
      makeSelectListFilter(parentId),
      makeSelectListColumns(),
      makeSelectListSortBy(parentId),
      (page, filter, columnState, sortBy) => ({
        page,
        filter,
        columnState,
        sortBy,
      }),
    );

  // FIXME: redefine the type without the cast
  return {
    [makeSelectListStateName]: makeSelectListState,
    [makeSelectListDataName]: makeSelectListData,
    [makeSelectListSortByName]: makeSelectListSortBy,
    [makeSelectListPageName]: makeSelectListPage,
    [makeSelectListFilterName]: makeSelectListFilter,
    [makeSelectListColumnsName]: makeSelectListColumns,
    [makeSelectListParametersName]: makeSelectListParameters,
  } as Record<typeof makeSelectListDataName, typeof makeSelectListData> &
    Record<typeof makeSelectListStateName, typeof makeSelectListState> &
    Record<typeof makeSelectListSortByName, typeof makeSelectListSortBy> &
    Record<typeof makeSelectListPageName, typeof makeSelectListPage> &
    Record<typeof makeSelectListFilterName, typeof makeSelectListFilter> &
    Record<typeof makeSelectListColumnsName, typeof makeSelectListColumns> &
    Record<typeof makeSelectListParametersName, typeof makeSelectListParameters>;
};
