import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { updateSettlementSchedule } from '@/features/settlements/actions';
import type { SettlementSchedule, SettlementScheduleUpdate } from '@/features/settlements/types';
import { WithdrawalPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useSettlementSchedule from './useSettlementSchedule';

type CancelUnavailabilityReason = 'no-data';

export interface UseSettlementScheduleActionsType {
  set: HookAction<[SettlementScheduleUpdate]>;
  cancel: HookAction<[], SettlementSchedule, CancelUnavailabilityReason>;
}

export default function useSettlementScheduleActions(): UseSettlementScheduleActionsType {
  const { data: schedule } = useSettlementSchedule();
  const { withExtractDataDispatch } = useAppDispatch();

  const updating = useActionPending(updateSettlementSchedule);

  const set: UseSettlementScheduleActionsType['set'] = {
    act: useCallback(
      (data: SettlementScheduleUpdate) => withExtractDataDispatch(updateSettlementSchedule)(data),
      [withExtractDataDispatch],
    ),
    inAction: updating,
    available: true,
  };

  const cancelUnavailabilityReason: CancelUnavailabilityReason | undefined = useMemo(() => {
    if (!schedule.data || schedule.data.period === WithdrawalPeriodAPIModel.NotSet) {
      return 'no-data';
    }
    return undefined;
  }, [schedule.data]);
  const cancel: UseSettlementScheduleActionsType['cancel'] = {
    act: useCallback(() => withExtractDataDispatch(updateSettlementSchedule)(undefined), [withExtractDataDispatch]),
    inAction: updating,
    available: !cancelUnavailabilityReason,
    unavailabilityReason: cancelUnavailabilityReason,
  };

  return { set, cancel };
}
