import React from 'react';

import { FormattedMessage } from '@/components';
import { DocumentationBanner } from '@/features/user/components';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import type { MerchantWalletTransferDocumentationBannerProps } from './types';

const MerchantWalletTransferDocumentationBannerRaw: React.FC<MerchantWalletTransferDocumentationBannerProps> = ({
  'data-test': dataTest,
  pageSize,
}) => (
  <DocumentationBanner
    PageProps={{ pageSize }}
    data-test={dataTest}
    type="merchantWalletTransfers"
    message={<FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_DOCUMENTATION_BANNER_MESSAGE} />}
  />
);

const MerchantWalletTransferDocumentationBanner = React.memo(MerchantWalletTransferDocumentationBannerRaw);

export default MerchantWalletTransferDocumentationBanner;
