import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { requestEmailCode, verifyEmailCode } from '@/features/email/actions';
import type { EmailConfirmationState } from '@/features/email/types';
import { useActionPending } from '@/features/global/hooks';
import type { ReCaptchaParams } from '@/features/recaptcha/types';
import type { HookAction } from '@/infrastructure/model';

export interface UseEmailConfirmationType {
  requestEmailCode: HookAction<[{ email: string } & ReCaptchaParams]>;
  verifyEmailCode: HookAction<[{ email: string; code: string } & ReCaptchaParams], EmailConfirmationState>;
}

const useEmailConfirmationActions = (): UseEmailConfirmationType => {
  const { withExtractDataDispatch } = useAppDispatch();

  const requesting = useActionPending(requestEmailCode);
  const requestEmailCodeAction: UseEmailConfirmationType['requestEmailCode']['act'] = useMemo(
    () => withExtractDataDispatch(requestEmailCode),
    [withExtractDataDispatch],
  );
  const requestEmailCodeHook = useMemo(
    () => ({
      act: requestEmailCodeAction,
      available: true,
      inAction: requesting,
    }),
    [requestEmailCodeAction, requesting],
  );

  const verifying = useActionPending(verifyEmailCode);
  const verifyEmailCodeAction: UseEmailConfirmationType['verifyEmailCode']['act'] = useMemo(
    () => withExtractDataDispatch(verifyEmailCode),
    [withExtractDataDispatch],
  );
  const verifyEmailCodeHook = useMemo(
    () => ({
      act: verifyEmailCodeAction,
      available: true,
      inAction: verifying,
    }),
    [verifyEmailCodeAction, verifying],
  );

  return { requestEmailCode: requestEmailCodeHook, verifyEmailCode: verifyEmailCodeHook };
};

export default useEmailConfirmationActions;
