import { createAction } from '@reduxjs/toolkit';

import { NAMESPACE } from './types';

import type { channelLogin, logout } from './actions';

// Actions should be used to break the dependency chain to be used in non managed code

export const logoutActionTrigger = createAction<Parameters<typeof logout>[0]>(`${NAMESPACE}/logoutTrigger`);

export const channelLoginTrigger = createAction<Parameters<typeof channelLogin>[0]>(`${NAMESPACE}/channelLoginTrigger`);
