/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  CheckCodeReqAPIModel,
  CheckCodeRespAPIModel,
  SendCodeReqAPIModel,
} from '../models/index';
import {
    CheckCodeReqAPIModelFromJSON,
    CheckCodeReqAPIModelToJSON,
    CheckCodeRespAPIModelFromJSON,
    CheckCodeRespAPIModelToJSON,
    SendCodeReqAPIModelFromJSON,
    SendCodeReqAPIModelToJSON,
} from '../models/index';

export interface RequestMerchantEmailCodeRequest {
    xRecaptcha: string;
    sendCodeReqAPIModel: SendCodeReqAPIModel;
    xRecaptchaType?: RequestMerchantEmailCodeXRecaptchaTypeEnum;
}

export interface VerifyMerchantEmailCodeRequest {
    xRecaptcha: string;
    checkCodeReqAPIModel: CheckCodeReqAPIModel;
    xRecaptchaType?: VerifyMerchantEmailCodeXRecaptchaTypeEnum;
}

/**
 * MerchantAuth0Api - interface
 * 
 * @export
 * @interface MerchantAuth0ApiInterface
 */
export interface MerchantAuth0ApiInterface {
    /**
     * 
     * @summary 
     * @param {string} xRecaptcha 
     * @param {SendCodeReqAPIModel} sendCodeReqAPIModel 
     * @param {'v2' | 'v3'} [xRecaptchaType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuth0ApiInterface
     */
    requestMerchantEmailCodeRaw(requestParameters: RequestMerchantEmailCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * 
     */
    requestMerchantEmailCode(xRecaptcha: string, sendCodeReqAPIModel: SendCodeReqAPIModel, xRecaptchaType?: RequestMerchantEmailCodeXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary 
     * @param {string} xRecaptcha 
     * @param {CheckCodeReqAPIModel} checkCodeReqAPIModel 
     * @param {'v2' | 'v3'} [xRecaptchaType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantAuth0ApiInterface
     */
    verifyMerchantEmailCodeRaw(requestParameters: VerifyMerchantEmailCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckCodeRespAPIModel>>;

    /**
     * 
     */
    verifyMerchantEmailCode(xRecaptcha: string, checkCodeReqAPIModel: CheckCodeReqAPIModel, xRecaptchaType?: VerifyMerchantEmailCodeXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckCodeRespAPIModel>;

}

/**
 * 
 */
export class MerchantAuth0Api extends runtime.BaseAPI implements MerchantAuth0ApiInterface {

    /**
     * 
     */
    async requestMerchantEmailCodeRaw(requestParameters: RequestMerchantEmailCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['xRecaptcha'] == null) {
            throw new runtime.RequiredError(
                'xRecaptcha',
                'Required parameter "xRecaptcha" was null or undefined when calling requestMerchantEmailCode().'
            );
        }

        if (requestParameters['sendCodeReqAPIModel'] == null) {
            throw new runtime.RequiredError(
                'sendCodeReqAPIModel',
                'Required parameter "sendCodeReqAPIModel" was null or undefined when calling requestMerchantEmailCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xRecaptcha'] != null) {
            headerParameters['x-recaptcha'] = String(requestParameters['xRecaptcha']);
        }

        if (requestParameters['xRecaptchaType'] != null) {
            headerParameters['x-recaptcha-type'] = String(requestParameters['xRecaptchaType']);
        }

        const response = await this.request({
            path: `/merchant-bff/auth0/email/request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendCodeReqAPIModelToJSON(requestParameters['sendCodeReqAPIModel']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     */
    async requestMerchantEmailCode(xRecaptcha: string, sendCodeReqAPIModel: SendCodeReqAPIModel, xRecaptchaType?: RequestMerchantEmailCodeXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.requestMerchantEmailCodeRaw({ xRecaptcha: xRecaptcha, sendCodeReqAPIModel: sendCodeReqAPIModel, xRecaptchaType: xRecaptchaType }, initOverrides);
    }

    /**
     * 
     */
    async verifyMerchantEmailCodeRaw(requestParameters: VerifyMerchantEmailCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CheckCodeRespAPIModel>> {
        if (requestParameters['xRecaptcha'] == null) {
            throw new runtime.RequiredError(
                'xRecaptcha',
                'Required parameter "xRecaptcha" was null or undefined when calling verifyMerchantEmailCode().'
            );
        }

        if (requestParameters['checkCodeReqAPIModel'] == null) {
            throw new runtime.RequiredError(
                'checkCodeReqAPIModel',
                'Required parameter "checkCodeReqAPIModel" was null or undefined when calling verifyMerchantEmailCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['xRecaptcha'] != null) {
            headerParameters['x-recaptcha'] = String(requestParameters['xRecaptcha']);
        }

        if (requestParameters['xRecaptchaType'] != null) {
            headerParameters['x-recaptcha-type'] = String(requestParameters['xRecaptchaType']);
        }

        const response = await this.request({
            path: `/merchant-bff/auth0/email/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CheckCodeReqAPIModelToJSON(requestParameters['checkCodeReqAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CheckCodeRespAPIModelFromJSON(jsonValue));
    }

    /**
     * 
     */
    async verifyMerchantEmailCode(xRecaptcha: string, checkCodeReqAPIModel: CheckCodeReqAPIModel, xRecaptchaType?: VerifyMerchantEmailCodeXRecaptchaTypeEnum, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CheckCodeRespAPIModel> {
        const response = await this.verifyMerchantEmailCodeRaw({ xRecaptcha: xRecaptcha, checkCodeReqAPIModel: checkCodeReqAPIModel, xRecaptchaType: xRecaptchaType }, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum RequestMerchantEmailCodeXRecaptchaTypeEnum {
    v2 = 'v2',
    v3 = 'v3'
}
/**
  * @export
  * @enum {string}
  */
export enum VerifyMerchantEmailCodeXRecaptchaTypeEnum {
    v2 = 'v2',
    v3 = 'v3'
}
