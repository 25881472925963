import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { OperationRefreshMerchantGasWallets } from '@/features/gas-wallets/components';
import { useMerchantGasWallets } from '@/features/gas-wallets/hooks';
import type { GasWallet } from '@/features/gas-wallets/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withGasWalletsDataLoading = <T extends { data: GasWallet[] } & TestableProps>(
  Component: React.ComponentType<Omit<T, 'data'> & { data: GasWallet[] }>,
) =>
  namedHOC<Omit<T, 'data'> & { data: GasWallet[] }, Omit<T, 'data'>>(
    Component,
    'WithGasWalletsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useMerchantGasWallets();
    return withCardDataLoading<GasWallet[], Omit<T, 'data'> & { data: GasWallet[] }>({
      ...data,
      'data-test': dataTest,
      hideBack: true,
      title: (
        <Space>
          <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLETS_COMPONENTS_WALLETS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      ),
      CardProps: {
        extra: [
          <OperationRefreshMerchantGasWallets
            key="refresh"
            data-test={dataTest && `${dataTest}-opRefresh`}
            mode="inline"
          />,
        ],
      },
    })(Component)(props);
  });

export default withGasWalletsDataLoading;
