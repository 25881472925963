/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PaymentTransactionPredicatesAPIModel } from './PaymentTransactionPredicatesAPIModel';
import {
    PaymentTransactionPredicatesAPIModelFromJSON,
    PaymentTransactionPredicatesAPIModelFromJSONTyped,
    PaymentTransactionPredicatesAPIModelToJSON,
} from './PaymentTransactionPredicatesAPIModel';

/**
 * 
 * @export
 * @interface PaymentTransactionsFilterAPIModel
 */
export interface PaymentTransactionsFilterAPIModel {
    /**
     * 
     * @type {PaymentTransactionPredicatesAPIModel}
     * @memberof PaymentTransactionsFilterAPIModel
     */
    predicates: PaymentTransactionPredicatesAPIModel;
}

/**
 * Check if a given object implements the PaymentTransactionsFilterAPIModel interface.
 */
export function instanceOfPaymentTransactionsFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    return true;
}

export function PaymentTransactionsFilterAPIModelFromJSON(json: any): PaymentTransactionsFilterAPIModel {
    return PaymentTransactionsFilterAPIModelFromJSONTyped(json, false);
}

export function PaymentTransactionsFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentTransactionsFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': PaymentTransactionPredicatesAPIModelFromJSON(json['predicates']),
    };
}

export function PaymentTransactionsFilterAPIModelToJSON(value?: PaymentTransactionsFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': PaymentTransactionPredicatesAPIModelToJSON(value['predicates']),
    };
}

