import { Space } from 'antd';
import React from 'react';

import { OperationDeactivateBrandingProfile } from '@/features/branding/components';
import { useBrandingActiveProfile } from '@/features/branding/hooks';
import BrandingProfileAddLink from '@/pages/settings/branding/profile-add/components/BrandingProfileAddLink';
import BrandingProfileEditLink from '@/pages/settings/branding/profile-edit/components/BrandingProfileEditLink';
import BrandingProfilesLink from '@/pages/settings/branding/profiles/components/BrandingProfilesLink';

import type { ActiveProfileCardOperationsProps } from './types';

const ActiveProfileCardOperationsRaw: React.FC<ActiveProfileCardOperationsProps> = ({ 'data-test': dataTest }) => {
  const active = useBrandingActiveProfile();
  const activeId = active.data.data?.id;

  return (
    <Space>
      {activeId ? (
        <>
          <BrandingProfileEditLink data-test={dataTest && `${dataTest}-createOp`} mode="button" value={activeId} />
          <OperationDeactivateBrandingProfile
            data-test={dataTest && `${dataTest}-deactivateOp`}
            profileId={activeId}
            mode="inline"
          />
        </>
      ) : (
        <>
          <BrandingProfileAddLink data-test={dataTest && `${dataTest}-updateOp`} mode="button" />
          <OperationDeactivateBrandingProfile
            data-test={dataTest && `${dataTest}-deactivateOp`}
            disabled
            mode="inline"
          />
        </>
      )}
      <BrandingProfilesLink data-test={dataTest && `${dataTest}-viewOp`} mode="icon" />
    </Space>
  );
};

const ActiveProfileCardOperations = React.memo(ActiveProfileCardOperationsRaw);

export default ActiveProfileCardOperations;
