import { Col, Form, Row } from 'antd';
import debounce from 'lodash/debounce';
import React, { useCallback, useMemo, useRef } from 'react';

import { ErrorFormMessage, FormattedMessage, FormCompletenessItem, Link } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import BrandingToSPreview from '../BrandingToSPreview';

import { BrandingToSTextItem } from './components';

import type { BrandingToSEditFormProps } from './types';

export interface BrandingToSEditFormValues {
  text: string;
}

const requiredFields = [nameof<BrandingToSEditFormValues>('text')];

const BrandingToSEditForm: React.FC<BrandingToSEditFormProps> = ({
  'data-test': dataTest,
  value: defaultValue,
  onSubmit,
  submitCallback,
  onSubmitting,
  containerHeight,
}) => {
  const [value, setValue] = useStateMountSafe(defaultValue);
  const { form, resetForm, withResetForm } = useForm<BrandingToSEditFormValues>();
  const { error, submitting, withSubmitting, reset: resetSubmit } = useErrorSafeSubmitting();
  const isFormCompleteRef = useRef(false);
  const doSave = useMemo(
    () =>
      withSubmitting(
        withVoidOrThrow(
          wrap(
            ({ text }: BrandingToSEditFormValues) => onSubmit(text),
            () => onSubmitting?.(true),
            () => onSubmitting?.(false),
          ),
        ),
      ),
    [onSubmit, onSubmitting, withSubmitting],
  );
  const doReset = useMemo(() => withResetForm(resetForm), [resetForm, withResetForm]);
  const updateComplete = useCallback(
    (complete: boolean) => {
      if (!complete && !isFormCompleteRef.current) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newText: string | undefined = form.getFieldValue(nameof<BrandingToSEditFormValues>('text'));
      const hasBeenUpdated = defaultValue?.length !== newText?.length || defaultValue !== newText;
      const isCompleteAndUpdated = complete && hasBeenUpdated;
      if (isCompleteAndUpdated === isFormCompleteRef.current) {
        return;
      }
      submitCallback?.(
        isCompleteAndUpdated
          ? async () => {
              await doSave(form.getFieldsValue());
              setTimeout(resetForm, 0);
            }
          : undefined,
      );
      isFormCompleteRef.current = isCompleteAndUpdated;
    },
    [defaultValue, doSave, form, resetForm, submitCallback],
  );
  const updateCompleteDebounced = useMemo(() => debounce(updateComplete, 50), [updateComplete]);

  const initialValues = useMemo(() => ({ text: defaultValue }), [defaultValue]);
  const onValuesChange = useCallback(
    (changedValues: BrandingToSEditFormValues) => {
      setValue(changedValues.text);
      resetSubmit();
    },
    [resetSubmit, setValue],
  );
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureBranding.COMPONENTS_TOS_FORM_EDIT_FORM_ERROR_COMMON} />;
  }, [error]);
  const formHeight = containerHeight - 50;

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        {errorMessage ? (
          <ErrorFormMessage content={errorMessage} />
        ) : (
          <FormattedMessage
            id={I18nFeatureBranding.COMPONENTS_TOS_FORM_EDIT_FORM_HELP}
            values={{
              ln: (text: React.ReactNode) => <Link external title={text} to="https://commonmark.org/help/" />,
            }}
          />
        )}
      </Col>
      <Col span={12} style={{ height: formHeight }}>
        <Form<BrandingToSEditFormValues>
          labelCol={{ span: 0 }}
          data-test={dataTest && `${dataTest}-form`}
          autoComplete="off"
          size="middle"
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          onFinish={doSave}
          onReset={doReset}
        >
          <BrandingToSTextItem
            data-test={dataTest && `${dataTest}-text`}
            name={nameof<BrandingToSEditFormValues>('text')}
            readonly={submitting}
            containerHeight={formHeight}
          />
          <FormCompletenessItem<BrandingToSEditFormValues>
            requiredFields={requiredFields}
            onChange={updateCompleteDebounced}
          />
        </Form>
      </Col>
      <Col span={12} style={{ height: formHeight }}>
        <BrandingToSPreview data-test={dataTest && `${dataTest}-preview`} value={value} />
      </Col>
    </Row>
  );
};

const BrandingToSEditFormMemo = React.memo(BrandingToSEditForm);

export default BrandingToSEditFormMemo;
