import { useAsset } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

export const withBlockchainTypeLoad = <T extends { bt: BlockchainTypeAPIModel }>(
  Component: React.ComponentType<Omit<T, 'bt'> & { bt: BlockchainTypeAPIModel }>,
  EmptyComponent: React.ComponentType<Omit<T, 'bt'>>,
) =>
  namedHOC<Omit<T, 'bt'> & { bt: BlockchainTypeAPIModel }, Omit<T, 'bt'> & { assetId: string }>(
    Component,
    'WithBlockchainTypeLoad',
  )((props) => {
    const { assetId } = props;
    const asset = useAsset(assetId);
    return asset.data.data?.blockchain ? (
      <Component {...props} bt={asset.data.data.blockchain} />
    ) : (
      <EmptyComponent {...props} />
    );
  });

const withBlockchainType = <T extends { bt: BlockchainTypeAPIModel }>(
  Component: React.ComponentType<Omit<T, 'bt'> & { bt: BlockchainTypeAPIModel }>,
  EmptyComponent: React.ComponentType<Omit<T, 'bt'>> = emptyComponent,
) => {
  const AssetComponent = withBlockchainTypeLoad<T>(Component, EmptyComponent);
  return namedHOC<
    Omit<T, 'bt'> & { bt: BlockchainTypeAPIModel },
    (Omit<T, 'bt'> & { bt: BlockchainTypeAPIModel }) | (Omit<T, 'bt'> & { assetId: string })
  >(
    Component,
    'WithBlockchainType',
  )((props) => ('bt' in props ? <Component {...props} /> : <AssetComponent {...props} />));
};

export default withBlockchainType;
