import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type {
  AllowanceId,
  Asset,
  AssetAddress,
  AssetAddressHistoryKey,
  BlockchainAddress,
  BlockchainInfo,
} from './types';

export const explorers: Record<BlockchainTypeAPIModel, string> = {
  [BlockchainTypeAPIModel.TronMainNet]: 'https://tronscan.org/#',
  [BlockchainTypeAPIModel.TronNile]: 'https://nile.tronscan.org/#',
  [BlockchainTypeAPIModel.TronShasta]: 'https://shasta.tronscan.org/#',
  [BlockchainTypeAPIModel.ArbitrumMainNet]: 'https://arbiscan.io',
  [BlockchainTypeAPIModel.ArbitrumTestNet]: 'https://goerli-rollup-explorer.arbitrum.io',
  [BlockchainTypeAPIModel.ArbitrumSepolia]: 'https://sepolia.arbiscan.io',
  [BlockchainTypeAPIModel.BinanceMainNet]: 'https://bscscan.com',
  [BlockchainTypeAPIModel.BinanceTestNet]: 'https://testnet.bscscan.com',
  [BlockchainTypeAPIModel.EthereumMainNet]: 'https://etherscan.io',
  [BlockchainTypeAPIModel.EthereumGoerli]: 'https://goerli.etherscan.io/',
  [BlockchainTypeAPIModel.EthereumRopsten]: 'https://ropsten.etherscan.io',
  [BlockchainTypeAPIModel.EthereumSepolia]: 'https://sepolia.etherscan.io/',
  [BlockchainTypeAPIModel.PolygonMainNet]: 'https://polygonscan.com/',
  [BlockchainTypeAPIModel.PolygonAmoy]: 'https://amoy.polygonscan.com/',
  [BlockchainTypeAPIModel.PolygonMumbaiNet]: 'https://mumbai.polygonscan.com/',
  [BlockchainTypeAPIModel.BitcoinMainNet]: 'https://blockstream.info',
  [BlockchainTypeAPIModel.BitcoinTestNet]: 'https://blockstream.info/testnet',
};

export const createBlockchainAddressKey = ({ address, bt }: BlockchainAddress) => `${bt}_${address}`;
export const createAssetAddressKey = ({ address, assetId }: AssetAddress) => `${assetId}_${address}`;
export const createAssetAddressHistoryKey = ({ address, assetId, blockNum }: AssetAddressHistoryKey) =>
  `${assetId}_${address}_${blockNum}`;
export const createAllowanceKey = ({ spender, owner, assetId }: AllowanceId) => `${assetId}_${owner}_${spender}`;

export const extractBlockchainType = ({ blockchain }: BlockchainInfo) => blockchain;
export const extractAssetId = ({ code }: Asset) => code;
