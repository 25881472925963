/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PushTransactionSortByAPIModel {
    CreatedAt = 'CreatedAt',
    BroadcastAt = 'BroadcastAt'
}


export function PushTransactionSortByAPIModelFromJSON(json: any): PushTransactionSortByAPIModel {
    return PushTransactionSortByAPIModelFromJSONTyped(json, false);
}

export function PushTransactionSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PushTransactionSortByAPIModel {
    return json as PushTransactionSortByAPIModel;
}

export function PushTransactionSortByAPIModelToJSON(value?: PushTransactionSortByAPIModel | null): any {
    return value as any;
}

