import React from 'react';

import { OperationRefresh } from '@/components';
import { useBrandingProfiles } from '@/features/branding/hooks';

import type { OperationRefreshBrandingProfilesProps } from './types';

const OperationRefreshBrandingProfiles: React.FC<OperationRefreshBrandingProfilesProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useBrandingProfiles();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshBrandingProfilesMemo = React.memo(OperationRefreshBrandingProfiles);

export default OperationRefreshBrandingProfilesMemo;
