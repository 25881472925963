import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeDonationTransactionListParameters } from '@/features/donations/actions';
import type { DonationTransaction, DonationTransactionFilterPredicate } from '@/features/donations/types';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useDonationTransactionList from './useDonationTransactionList';

export type UseDonationTransactionListView = UseAppListDataView<
  DonationTransaction,
  DonationTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

export default function useDonationTransactionListView(): UseDonationTransactionListView {
  const state = useDonationTransactionList();
  return useAppListDataView(state, storeDonationTransactionListParameters);
}
