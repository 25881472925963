import { List } from 'antd';
import React, { useCallback } from 'react';

import { ReadonlyComponent, ReadonlyDateTime } from '@/components';
import { AssetAmount, AddressLink } from '@/features/dictionary/blockchain/components';
import type { SubscriptionCharge } from '@/features/subscription-charges/types';

import SubscriptionChargeStatusView from '../SubscriptionChargeStatusView';

import type { SubscriptionChargesListProps } from './types';

const SubscriptionChargesList: React.FC<SubscriptionChargesListProps> = ({
  data,
  'data-test': dataTest,
  SubscriptionChargeLink,
}) => (
  <List<SubscriptionCharge>
    itemLayout="horizontal"
    dataSource={data}
    renderItem={useCallback(
      (item: SubscriptionCharge, idx: number) => (
        <List.Item
          key={item.chargeDate.getTime()}
          actions={[
            SubscriptionChargeLink ? (
              <SubscriptionChargeLink key="link" data-test={dataTest && `${dataTest}-link-${idx}`} value={item.id} />
            ) : (
              <ReadonlyComponent key="id" data-test={dataTest && `${dataTest}-id-${idx}`} value={item.id} />
            ),
          ]}
        >
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={
              <SubscriptionChargeStatusView
                style={{ fontSize: 32 }}
                mode="icon"
                value={item.status}
                data-test={dataTest && `${dataTest}-icon-${idx}`}
              />
            }
            description={<ReadonlyDateTime value={item.chargeDate} data-test={dataTest && `${dataTest}-date-${idx}`} />}
            title={
              <AddressLink
                assetId={item.amount.asset}
                address={item.contractAddress}
                data-test={dataTest && `${dataTest}-addressLink-${idx}`}
              >
                <ReadonlyComponent
                  value={
                    <AssetAmount
                      withCurrency
                      withBlockchainMark
                      value={item.amount.value}
                      assetId={item.amount.asset}
                      data-test={dataTest && `${dataTest}-amount-${idx}`}
                    />
                  }
                  copyable={false}
                />
              </AddressLink>
            }
          />
        </List.Item>
      ),
      [SubscriptionChargeLink, dataTest],
    )}
  />
);

const SubscriptionChargesListMemo = React.memo(SubscriptionChargesList);

export default SubscriptionChargesListMemo;
