import { createReducer } from '@reduxjs/toolkit';

import { defaultLocaleName } from '@/hocs/withI18nProvider/i18n';
import { InitStatus } from '@/infrastructure/model';

import { storeLocale } from './actions';

import type { I18nState } from './types';

const initialState: I18nState = {
  status: InitStatus.NOT_INITIALIZED,
  value: defaultLocaleName,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(storeLocale, (state, { payload }) => ({ ...state, value: payload, status: InitStatus.FINISHED }));
});

export default reducer;
