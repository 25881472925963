import { FormattedMessage } from '@/components';
import type { SubscriptionCharge } from '@/features/subscription-charges/types';
import { OperationRefreshSubscriptionCharges } from '@/features/subscriptions/components';
import { useSubscriptionChargesForSubscription } from '@/features/subscriptions/hooks';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionChargesDataLoading =
  <Original extends { data: SubscriptionCharge[] } & TestableProps>(
    Component: React.ComponentType<Original>,
  ): React.FC<Omit<Original, 'data'> & { subscriptionId: string }> =>
  (props) => {
    const data = useSubscriptionChargesForSubscription(props.subscriptionId);
    return withCardDataLoading<SubscriptionCharge[], Original>({
      ...data,
      'data-test': props['data-test'],
      title: (
        <FormattedMessage
          id={I18nPageSubscriptions.SUBSCRIPTION_VIEW_COMPONENTS_CHARGES_CARD_TITLE}
          values={{ id: props.subscriptionId }}
        />
      ),
      hideBack: true,
      CardProps: {
        extra: (
          <OperationRefreshSubscriptionCharges
            subscriptionId={props.subscriptionId}
            data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
          />
        ),
      },
    })(Component)(props);
  };

export default withSubscriptionChargesDataLoading;
