import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { updateWebhooks } from '@/features/company-settings/actions';
import type { Webhook } from '@/features/company-settings/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction } from '@/infrastructure/model';

import useWebhooks from '../useWebhooks';

export type WebhookActionUnavailability = 'no-data';

export interface UseWebhooksActionsType {
  updateWebhook: HookAction<[string], Webhook[], WebhookActionUnavailability>;
  deleteWebhook: HookAction<[], Webhook[], WebhookActionUnavailability>;
}

export default function useWebhookActions(id: string): UseWebhooksActionsType {
  const { withExtractDataDispatch } = useAppDispatch();

  const { data } = useWebhooks();

  const unavailabilityReason: WebhookActionUnavailability | undefined = !data.data ? 'no-data' : undefined;

  const updatesWebhooksPending = useActionPending(updateWebhooks, id);
  const updateWebhookAction = useCallback(
    (replacementUrl: string) =>
      withExtractDataDispatch(updateWebhooks)(
        data.data!.map((webhook) => (webhook.id === id ? { ...webhook, url: replacementUrl } : webhook)),
      ),
    [data.data, id, withExtractDataDispatch],
  );
  const updateWebhookHook = useMemo(
    () => ({
      act: updateWebhookAction,
      available: !unavailabilityReason,
      unavailabilityReason,
      inAction: updatesWebhooksPending,
    }),
    [unavailabilityReason, updateWebhookAction, updatesWebhooksPending],
  );

  const deleteWebhookAction = useCallback(
    () => withExtractDataDispatch(updateWebhooks)(data.data!.filter((webhook) => webhook.id !== id)),
    [data.data, id, withExtractDataDispatch],
  );
  const deleteWebhookHook = useMemo(
    () => ({
      act: deleteWebhookAction,
      available: !unavailabilityReason,
      unavailabilityReason,
      inAction: updatesWebhooksPending,
    }),
    [deleteWebhookAction, unavailabilityReason, updatesWebhooksPending],
  );

  return {
    updateWebhook: updateWebhookHook,
    deleteWebhook: deleteWebhookHook,
  };
}
