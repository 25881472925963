import * as Sentry from '@sentry/react';
import { Space, Typography } from 'antd';
import React from 'react';

import { FormattedMessage, NotificationMessage } from '@/components';
import { I18nCommon } from '@/generated/i18n/i18n';
import BlankLayout from '@/layouts/BlankLayout';

import type { PropsWithChildren, ReactNode, ErrorInfo } from 'react';

const { Link } = Typography;

class ErrorBoundary extends React.Component<
  PropsWithChildren,
  { error?: Error; errorInfo?: ErrorInfo; showDetails: boolean }
> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = { showDetails: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo, showDetails: false });
    Sentry.captureException(error);
  }

  render(): ReactNode {
    const {
      props: { children },
      state: { error, errorInfo, showDetails },
    } = this;
    return error ? (
      <BlankLayout>
        <NotificationMessage
          message={
            <Space>
              <FormattedMessage id={I18nCommon.MESSAGES_ERRORS_TITLE} />

              {}
              <Link onClick={() => this.setState((prev) => ({ ...prev, error: undefined, errorInfo: undefined }))}>
                <FormattedMessage id={I18nCommon.MESSAGES_ERRORS_RETRY} />
              </Link>
            </Space>
          }
          description={
            <div>
              <Space>
                <Typography.Text type="danger">
                  {error.name}: {error.message}
                </Typography.Text>
                {!showDetails && errorInfo?.componentStack && (
                  <Link onClick={() => this.setState((prev) => ({ ...prev, showDetails: true }))}>
                    <FormattedMessage id={I18nCommon.MESSAGES_ERRORS_DETAILS_SHOW} />
                  </Link>
                )}
                {showDetails && (
                  <Link onClick={() => this.setState((prev) => ({ ...prev, showDetails: false }))}>
                    <FormattedMessage id={I18nCommon.MESSAGES_ERRORS_DETAILS_HIDE} />
                  </Link>
                )}
              </Space>
              <div style={{ overflow: 'scroll', textWrap: 'nowrap' }}>
                {showDetails
                  && errorInfo?.componentStack?.split(/\n/).map((stack, idx) => <div key={idx}>{stack}</div>)}
              </div>
            </div>
          }
        />
      </BlankLayout>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
