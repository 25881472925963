import type { AppRootState, AppAsyncThunkAction } from '@/app/store';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';

import useAppLoadableData, { type UseAppLoadableData } from './useAppLoadableData';

export type UseAppFullData<Value, FetchResult = LoadingFullDataState<Value>> = UseAppLoadableData<
  Value[],
  FetchResult,
  LoadingFullDataState<Value>
>;

export default function useAppFullData<Value, Result = LoadingFullDataState<Value>>(
  // this function should fail-safe
  fetchActionFactory:
    | ((force: boolean) => AppAsyncThunkAction<Result, unknown>)
    | ((force: boolean) => Promise<Result>),
  dataFetchingSelector: (state: AppRootState) => boolean,
  dataSelector: (state: AppRootState) => LoadingFullDataState<Value>,
): UseAppFullData<Value, Result> {
  return useAppLoadableData(fetchActionFactory, dataSelector, dataFetchingSelector);
}
