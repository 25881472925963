import { Space } from 'antd';
import { useCallback, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useTokenHistoryBalance } from '@/features/dictionary/blockchain/hooks';
import {
  OperationRecalculateSettlementReconciliation,
  OperationRefreshSettlementReconciliation,
  SettlementReconciliationBanner,
} from '@/features/settlement-reconciliations/components';
import { useSettlementReconciliation } from '@/features/settlement-reconciliations/hooks';
import type { SettlementReconciliation } from '@/features/settlement-reconciliations/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const selectToken = makeSelectAuthToken();

const withSettlementReconciliationDataLoading =
  <T extends { data: SettlementReconciliation } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { settlementId: string }> =>
  (props) => {
    const token = useAppSelector(selectToken);
    const companyId = token.data?.info.activeCompanyId;
    const baseData = useSettlementReconciliation(props.settlementId);
    const data = useMemo(
      () =>
        baseData.data.error === 'WithdrawalReconciliationNotFound'
          ? { ...baseData, data: { ...baseData.data, error: undefined } }
          : baseData,
      [baseData],
    );
    const reconciliation = data.data.data;
    const { forceRefresh: refreshPreviousBlock } = useTokenHistoryBalance(
      useMemo(
        () =>
          reconciliation?.wallet && reconciliation.previousBlockNum
            ? {
                assetId: reconciliation.expectedAmount.asset,
                address: reconciliation.wallet,
                blockNum: reconciliation.previousBlockNum,
              }
            : undefined,
        [reconciliation?.previousBlockNum, reconciliation?.expectedAmount.asset, reconciliation?.wallet],
      ),
    );
    const { forceRefresh: refreshCurrentBlock } = useTokenHistoryBalance(
      useMemo(
        () =>
          reconciliation?.wallet
            ? {
                assetId: reconciliation.expectedAmount.asset,
                address: reconciliation.wallet,
                blockNum: reconciliation.blockNum,
              }
            : undefined,
        [reconciliation?.blockNum, reconciliation?.expectedAmount.asset, reconciliation?.wallet],
      ),
    );
    const refreshBalances = useCallback(
      async () => Promise.all([refreshPreviousBlock(), refreshCurrentBlock()]),
      [refreshPreviousBlock, refreshCurrentBlock],
    );

    return withCardDataLoading<SettlementReconciliation, T>({
      ...data,
      'data-test': props['data-test'],
      title: <FormattedMessage id={I18nPageSettlements.SETTLEMENT_VIEW_COMPONENTS_RECONCILIATION_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <OperationRecalculateSettlementReconciliation
              settlementId={props.settlementId}
              data-test={props['data-test'] && `${props['data-test']}-opRecalculate`}
            />
            <OperationRefreshSettlementReconciliation
              settlementId={props.settlementId}
              data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
              onSuccess={refreshBalances}
            />
          </Space>
        ),
      },
      noData: <SettlementReconciliationBanner settlementId={props.settlementId} companyId={companyId!} mode="alert" />,
    })(Component)(props);
  };

export default withSettlementReconciliationDataLoading;
