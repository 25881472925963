import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshPayout, PayoutsDocumentationLink } from '@/features/payouts/components';
import { usePayout } from '@/features/payouts/hooks';
import type { PayoutSummary } from '@/features/payouts/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPayoutDataLoading = <
  Original extends { data: PayoutSummary } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { payoutId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithPayoutDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = usePayout(props.payoutId);
    return withCardDataLoading<PayoutSummary, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettlements.PAYOUT_BATCH_VIEW_COMPONENTS_PAYOUT_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <PayoutsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationRefreshPayout data-test={dataTest && `${dataTest}-opRefresh`} payoutId={props.payoutId} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withPayoutDataLoading;
