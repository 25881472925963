import React from 'react';

import { FormattedMessage } from '@/components';
import { DocumentationBanner } from '@/features/user/components';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import PayoutsDocumentationLink from '../PayoutsDocumentationLink';

import type { PayoutsDocumentationBannerProps } from './types';

const PayoutsDocumentationBanner: React.FC<PayoutsDocumentationBannerProps> = ({ 'data-test': dataTest, pageSize }) =>
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition,no-constant-condition
  true ? null : (
    <DocumentationBanner
      PageProps={{ pageSize }}
      data-test={dataTest}
      type="payouts"
      message={
        <FormattedMessage
          id={I18nFeaturePayouts.COMPONENTS_DOCUMENTATION_BANNER_MESSAGE}
          values={{
            lk: (message: React.ReactNode) => <PayoutsDocumentationLink title={message} mode="text" />,
          }}
        />
      }
    />
  );

const PayoutsDocumentationBannerMemo = React.memo(PayoutsDocumentationBanner);

export default PayoutsDocumentationBannerMemo;
