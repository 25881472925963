import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum SettingsPageRoutes {
  BRANDING = 'branding',
  COMPANY = 'company',
  INTEGRATION = 'integration',
  PROFILE = 'profile',
  PERMISSIONS = 'permissions',
}

export enum CompanyPageRoutes {
  SUMMARY = 'summary',
  ASSETS = 'assets',
  GAS_WALLETS = 'gas-wallets',
  GAS_WALLET_VIEW = 'gas-wallets/view/:bt',
  QR_SUPPORT = 'qr-support',
}

export enum BrandingPageRoutes {
  SUMMARY = 'summary',
  DOMAINS = 'domains',
  PROFILES = 'profiles',
  PROFILE_VIEW = 'profiles/view/:id',
  PROFILE_EDIT = 'profiles/edit/:id',
  PROFILE_ADD = 'profiles/new',
  TOS = 'tos',
}

export const settingsRoute = (page: SettingsPageRoutes) => `${appRoute(AppPageRoutes.SETTINGS)}/${page}`;

export const settingsBrandingRoute = (page: BrandingPageRoutes) =>
  `${settingsRoute(SettingsPageRoutes.BRANDING)}/${page}`;
export const settingsBrandingSummaryLink = () => settingsBrandingRoute(BrandingPageRoutes.SUMMARY);
export const settingsBrandingDomainsLink = () => settingsBrandingRoute(BrandingPageRoutes.DOMAINS);
export const settingsBrandingProfilesLink = () => settingsBrandingRoute(BrandingPageRoutes.PROFILES);
export const settingsBrandingProfileViewLink = (profileId: string) =>
  `${settingsBrandingProfilesLink()}/view/${profileId}`;
export const settingsBrandingProfileAddLink = (copyOf?: string) =>
  `${settingsBrandingProfilesLink()}/new${copyOf ? `?copy-of=${copyOf}` : ''}`;
export const settingsBrandingProfileEditLink = (profileId: string) =>
  `${settingsBrandingProfilesLink()}/edit/${profileId}`;
export const settingsBrandingToSLink = () => settingsBrandingRoute(BrandingPageRoutes.TOS);

export const settingsCompanyRoute = (page: CompanyPageRoutes) => `${settingsRoute(SettingsPageRoutes.COMPANY)}/${page}`;
export const settingsCompanySummaryLink = () => settingsCompanyRoute(CompanyPageRoutes.SUMMARY);
export const settingsCompanyAssetsEditLink = () => settingsCompanyRoute(CompanyPageRoutes.ASSETS);
export const settingsCompanyGasWalletsLink = () => settingsCompanyRoute(CompanyPageRoutes.GAS_WALLETS);
export const settingsCompanyGasWalletLink = (bt: BlockchainTypeAPIModel) =>
  `${settingsCompanyGasWalletsLink()}/view/${bt}`;
export const settingsCompanyQRSupportEditLink = () => settingsCompanyRoute(CompanyPageRoutes.QR_SUPPORT);

export const settingsIntegrationLink = () => settingsRoute(SettingsPageRoutes.INTEGRATION);
export const settingsProfileLink = () => settingsRoute(SettingsPageRoutes.PROFILE);
export const settingsPermissionsLink = () => settingsRoute(SettingsPageRoutes.PERMISSIONS);
