import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { updateCollectSchedule } from '@/features/collectable/actions';
import type { CollectSchedule, CollectScheduleUpdate } from '@/features/collectable/types';
import { useActionPending } from '@/features/global/hooks';
import { PushCollectPeriodAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useCollectSchedule from './useCollectSchedule';

type CancelUnavailabilityReason = 'no-data';

export interface UseCollectScheduleActions {
  set: HookAction<[CollectScheduleUpdate]>;
  cancel: HookAction<[], CollectSchedule, CancelUnavailabilityReason>;
}

export default function useCollectScheduleActions(): UseCollectScheduleActions {
  const { data: schedule } = useCollectSchedule();
  const { withExtractDataDispatch } = useAppDispatch();

  const updating = useActionPending(updateCollectSchedule);

  const set: UseCollectScheduleActions['set'] = {
    act: useCallback(
      (data: CollectScheduleUpdate) => withExtractDataDispatch(updateCollectSchedule)(data),
      [withExtractDataDispatch],
    ),
    inAction: updating,
    available: true,
  };

  const cancelUnavailabilityReason: CancelUnavailabilityReason | undefined = useMemo(() => {
    if (!schedule.data || schedule.data.period === PushCollectPeriodAPIModel.NotSet) {
      return 'no-data';
    }
    return undefined;
  }, [schedule.data]);
  const cancel: UseCollectScheduleActions['cancel'] = {
    act: useCallback(() => withExtractDataDispatch(updateCollectSchedule)(undefined), [withExtractDataDispatch]),
    inAction: updating,
    available: !cancelUnavailabilityReason,
    unavailabilityReason: cancelUnavailabilityReason,
  };

  return { set, cancel };
}
