import { EditOutlined, EditTwoTone } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { Operation, defaultDialogFormTailLayout, FormattedMessage, MessageToSupport } from '@/components';
import { useWebhookActions, useWebhooks } from '@/features/company-settings/hooks';
import type { NewWebhook } from '@/features/company-settings/types';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import WebhooksForm from '../WebhooksForm';

import type { OperationUpdateWebhookProps } from './types';

const notificationOptions = {
  error: () => ({
    message: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBHOOK_MESSAGE_ERROR} />,
    description: <MessageToSupport />,
  }),
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBHOOK_MESSAGE_SUCCESS} />
  ),
};

const formLayout = { labelCol: { span: 0 } };

const OperationUpdateWebhook: React.FC<OperationUpdateWebhookProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess = noop,
  value,
  mode = 'inline',
}) => {
  const { updateWebhook } = useWebhookActions(value.id);
  const { data } = useWebhooks();
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const doUpdate = useCallback(
    ({ url }: NewWebhook) =>
      withNotification(formControl.withFormHide(withOnSuccess(updateWebhook.act, onSuccess)), notificationOptions)(url),
    [withNotification, formControl, updateWebhook.act, onSuccess],
  );

  const selected = useMemo(
    () => data.data?.filter((old) => old.network !== value.network || old.url !== value.url),
    [data.data, value],
  );

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBHOOK_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <WebhooksForm
            selectedValues={selected}
            initialValues={value}
            layout={formLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={doUpdate}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_WEBHOOK_LABEL} />}
        icon={mode === 'icon' ? <EditTwoTone /> : <EditOutlined />}
        onClick={formControl.show}
        disabled={disabled || !updateWebhook.available || (!data.data && !data.isDirty)}
        inProgress={inProgress || formControl.visible || updateWebhook.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationUpdateWebhookMemo = React.memo(OperationUpdateWebhook);

export default OperationUpdateWebhookMemo;
