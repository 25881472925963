/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum OpenIdProviderTypeAPIModel {
    google = 'google',
    auth0 = 'auth0'
}


export function OpenIdProviderTypeAPIModelFromJSON(json: any): OpenIdProviderTypeAPIModel {
    return OpenIdProviderTypeAPIModelFromJSONTyped(json, false);
}

export function OpenIdProviderTypeAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OpenIdProviderTypeAPIModel {
    return json as OpenIdProviderTypeAPIModel;
}

export function OpenIdProviderTypeAPIModelToJSON(value?: OpenIdProviderTypeAPIModel | null): any {
    return value as any;
}

