import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import useActiveCompany from '@/features/company/hooks/useActiveCompany';
import { storeSelectedNetwork } from '@/features/dictionary/blockchain/actions';
import { makeSelectSelectedNetworkState } from '@/features/dictionary/blockchain/selectors';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';
import { InitStatus } from '@/infrastructure/model';

type UpdateNetworkUnavailabilityReason = 'kyb-required';

export interface UseSelectedNetworkActionsType {
  update: HookAction<[BlockchainNetworkTypeAPIModel], void, UpdateNetworkUnavailabilityReason>;
  network: BlockchainNetworkTypeAPIModel;
  initialized: boolean;
}

const networkSelector = makeSelectSelectedNetworkState();

export default function useSelectedNetworkActions(): UseSelectedNetworkActionsType {
  const { withDispatch } = useAppDispatch();
  const { value: network, status } = useAppSelector(networkSelector);
  const initialized = status === InitStatus.FINISHED;

  // FIXME: this is a dirty hack, there should be no dependency from company here
  const {
    data: { data: company },
  } = useActiveCompany();

  const updateUnavailability: UseSelectedNetworkActionsType['update']['unavailabilityReason'] = !company?.isKYBFinished
    ? 'kyb-required'
    : undefined;

  const updateAction = useMemo<UseSelectedNetworkActionsType['update']['act']>(
    () => withDispatch(storeSelectedNetwork),
    [withDispatch],
  );
  const update = useMemo<UseSelectedNetworkActionsType['update']>(
    () => ({
      act: updateAction,
      available: !updateUnavailability,
      inAction: false,
      unavailabilityReason: updateUnavailability,
    }),
    [updateAction, updateUnavailability],
  );

  return { update, network, initialized };
}
