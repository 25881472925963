import { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { withPageParams } from '@/hocs';
import { enumByKey } from '@/infrastructure/utils/ts';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { GasWalletAssetsCard, GasWalletBreadcrumb, GasWalletCard, GasWalletDerivedCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'gwt';

interface PageParams {
  bt: BlockchainTypeAPIModel;
}

const paramParser = (params: Partial<Record<keyof PageParams, string | undefined>>): PageParams | undefined => {
  try {
    const bt = enumByKey(BlockchainTypeAPIModel, params.bt);
    return bt ? { bt } : undefined;
  } catch {
    return undefined;
  }
};

const GasWalletViewPage: React.FC<{ pageParams: PageParams }> = ({ pageParams: { bt } }) => (
  <SettingsPageLayout
    data-test={dataTest && `${dataTest}-container`}
    selected={SettingsTabType.company}
    breadcrumb={<GasWalletBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} bt={bt} disabled />}
  >
    <GasWalletCard data-test={dataTest && `${dataTest}-wallet`} bt={bt} />
    <GasWalletAssetsCard data-test={dataTest && `${dataTest}-assets`} bt={bt} />
    <GasWalletDerivedCard data-test={dataTest && `${dataTest}-derived`} bt={bt} />
  </SettingsPageLayout>
);

export default withPageParams<keyof PageParams, PageParams>(paramParser)(GasWalletViewPage);
