import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementsPayoutsLink } from '@/pages/settlements/routes';

import type { PayoutsLinkProps } from './types';

const PayoutsLink: React.FC<PayoutsLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settlementsPayoutsLink()}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageSettlements.PAYOUTS_COMPONENTS_LINK_TITLE} />}
  />
);

const PayoutsLinkMemo = React.memo(PayoutsLink);

export default PayoutsLinkMemo;
