import React from 'react';

import { OperationRefresh } from '@/components';
import { useBrandingActiveProfile } from '@/features/branding/hooks';

import type { OperationRefreshActiveProfileProps } from './types';

const OperationRefreshActiveProfile: React.FC<OperationRefreshActiveProfileProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useBrandingActiveProfile();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshActiveProfileMemo = React.memo(OperationRefreshActiveProfile);

export default OperationRefreshActiveProfileMemo;
