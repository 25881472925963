import type { UseAppListDataView } from '@/app/hooks';
import useListDataView from '@/app/hooks/useAppListDataView';
import { storeMerchantWalletTransferListParameters } from '@/features/merchant-wallet-transfers/actions';
import useMerchantWalletTransferList from '@/features/merchant-wallet-transfers/hooks/useMerchantWalletTransferList';
import type {
  MerchantWalletTransfer,
  MerchantWalletTransferFilterPredicate,
} from '@/features/merchant-wallet-transfers/types';
import type { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export type UseMerchantWalletTransferListView = UseAppListDataView<
  MerchantWalletTransfer,
  MerchantWalletTransferFilterPredicate,
  MerchantWalletTransferSortByAPIModel
>;

export default function useMerchantWalletTransferListView(): UseMerchantWalletTransferListView {
  const state = useMerchantWalletTransferList();
  return useListDataView(state, storeMerchantWalletTransferListParameters);
}
