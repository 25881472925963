import React from 'react';

import type { OperationRefreshProps } from '@/components';
import { FormattedMessage, OperationRefresh } from '@/components';
import { useSettlement } from '@/features/settlements/hooks';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { OperationRefreshSettlementProps } from './types';

const messages: OperationRefreshProps['messages'] = {
  tooltip: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_REFRESH_SETTLEMENT_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_REFRESH_SETTLEMENT_MESSAGE_SUCCESS} />,
  },
};

const OperationRefreshSettlement: React.FC<OperationRefreshSettlementProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  settlementId,
}) => {
  const { forceRefresh } = useSettlement(settlementId);
  return <OperationRefresh messages={messages} refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshSettlementMemo = React.memo(OperationRefreshSettlement);

export default OperationRefreshSettlementMemo;
