import { DatePicker, Form } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import FormattedMessage from '@/components/FormattedMessage';
import { I18nComponents } from '@/generated/i18n/i18n';

import ReadonlyDate from '../ReadonlyDate';

import type { DateItemProps } from './types';
import type { Dayjs } from 'dayjs';
import type { Store } from 'rc-field-form/es/interface';

const DateItemRaw = <Values extends Store = Store>({
  name,
  placeholder,
  readonly,
  required,
  messages,
  'data-test': dataTest,
  rules = [],
  ItemProps = {},
  InputProps = {},
}: DateItemProps<Values>) => {
  const { formatMessage, formatDate } = useIntl();
  const formatValue = useCallback((v?: Dayjs) => formatDate(v?.toDate()), [formatDate]);

  return (
    <Form.Item<Values>
      label={messages?.label ?? <FormattedMessage id={I18nComponents.DATE_LABEL} />}
      rules={[
        ...(!readonly && required
          ? [
              {
                required: true,
                message: messages?.required ?? <FormattedMessage id={I18nComponents.DATE_REQUIRED} />,
              },
              ...rules,
            ]
          : []),
      ]}
      required={!readonly}
      {...ItemProps}
      name={name}
    >
      {readonly ? (
        <ReadonlyDate data-test={dataTest} />
      ) : (
        <DatePicker
          size="middle"
          format={formatValue}
          placeholder={placeholder || formatMessage({ id: I18nComponents.DATE_PLACEHOLDER })}
          data-test={dataTest}
          {...InputProps}
        />
      )}
    </Form.Item>
  );
};

const DateItem = React.memo(DateItemRaw) as typeof DateItemRaw;

export default DateItem;
