/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  OnboardRequestAPIModel,
  OnboardResponseAPIModel,
  RenewEmailConfirmationAPIModel,
  RenewEmailConfirmationResponseAPIModel,
  RenewEmailRequestAPIModel,
  RenewEmailResponseAPIModel,
} from '../models/index';
import {
    OnboardRequestAPIModelFromJSON,
    OnboardRequestAPIModelToJSON,
    OnboardResponseAPIModelFromJSON,
    OnboardResponseAPIModelToJSON,
    RenewEmailConfirmationAPIModelFromJSON,
    RenewEmailConfirmationAPIModelToJSON,
    RenewEmailConfirmationResponseAPIModelFromJSON,
    RenewEmailConfirmationResponseAPIModelToJSON,
    RenewEmailRequestAPIModelFromJSON,
    RenewEmailRequestAPIModelToJSON,
    RenewEmailResponseAPIModelFromJSON,
    RenewEmailResponseAPIModelToJSON,
} from '../models/index';

export interface ConfirmEmailNoAuthRequest {
    renewEmailConfirmationAPIModel: RenewEmailConfirmationAPIModel;
}

export interface OnboardRequest {
    onboardRequestAPIModel: OnboardRequestAPIModel;
}

export interface UpdateEmailRequest {
    renewEmailRequestAPIModel: RenewEmailRequestAPIModel;
}

/**
 * MerchantCompaniesApi - interface
 * 
 * @export
 * @interface MerchantCompaniesApiInterface
 */
export interface MerchantCompaniesApiInterface {
    /**
     * Confirms the email update and sends the renewal email (for unauthenticated user)
     * @summary 
     * @param {RenewEmailConfirmationAPIModel} renewEmailConfirmationAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantCompaniesApiInterface
     */
    confirmEmailNoAuthRaw(requestParameters: ConfirmEmailNoAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewEmailConfirmationResponseAPIModel>>;

    /**
     * Confirms the email update and sends the renewal email (for unauthenticated user)
     * 
     */
    confirmEmailNoAuth(renewEmailConfirmationAPIModel: RenewEmailConfirmationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewEmailConfirmationResponseAPIModel>;

    /**
     * Prepares the company for a user
     * @summary 
     * @param {OnboardRequestAPIModel} onboardRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantCompaniesApiInterface
     */
    onboardRaw(requestParameters: OnboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardResponseAPIModel>>;

    /**
     * Prepares the company for a user
     * 
     */
    onboard(onboardRequestAPIModel: OnboardRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardResponseAPIModel>;

    /**
     * Sends update email request
     * @summary 
     * @param {RenewEmailRequestAPIModel} renewEmailRequestAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MerchantCompaniesApiInterface
     */
    updateEmailRaw(requestParameters: UpdateEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewEmailResponseAPIModel>>;

    /**
     * Sends update email request
     * 
     */
    updateEmail(renewEmailRequestAPIModel: RenewEmailRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewEmailResponseAPIModel>;

}

/**
 * 
 */
export class MerchantCompaniesApi extends runtime.BaseAPI implements MerchantCompaniesApiInterface {

    /**
     * Confirms the email update and sends the renewal email (for unauthenticated user)
     * 
     */
    async confirmEmailNoAuthRaw(requestParameters: ConfirmEmailNoAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewEmailConfirmationResponseAPIModel>> {
        if (requestParameters['renewEmailConfirmationAPIModel'] == null) {
            throw new runtime.RequiredError(
                'renewEmailConfirmationAPIModel',
                'Required parameter "renewEmailConfirmationAPIModel" was null or undefined when calling confirmEmailNoAuth().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/merchant-bff/companies/confirm-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenewEmailConfirmationAPIModelToJSON(requestParameters['renewEmailConfirmationAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenewEmailConfirmationResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Confirms the email update and sends the renewal email (for unauthenticated user)
     * 
     */
    async confirmEmailNoAuth(renewEmailConfirmationAPIModel: RenewEmailConfirmationAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewEmailConfirmationResponseAPIModel> {
        const response = await this.confirmEmailNoAuthRaw({ renewEmailConfirmationAPIModel: renewEmailConfirmationAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Prepares the company for a user
     * 
     */
    async onboardRaw(requestParameters: OnboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OnboardResponseAPIModel>> {
        if (requestParameters['onboardRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'onboardRequestAPIModel',
                'Required parameter "onboardRequestAPIModel" was null or undefined when calling onboard().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/companies/onboard`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardRequestAPIModelToJSON(requestParameters['onboardRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OnboardResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Prepares the company for a user
     * 
     */
    async onboard(onboardRequestAPIModel: OnboardRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OnboardResponseAPIModel> {
        const response = await this.onboardRaw({ onboardRequestAPIModel: onboardRequestAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Sends update email request
     * 
     */
    async updateEmailRaw(requestParameters: UpdateEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RenewEmailResponseAPIModel>> {
        if (requestParameters['renewEmailRequestAPIModel'] == null) {
            throw new runtime.RequiredError(
                'renewEmailRequestAPIModel',
                'Required parameter "renewEmailRequestAPIModel" was null or undefined when calling updateEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("dashboard-header", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/merchant-bff/companies/renew-email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RenewEmailRequestAPIModelToJSON(requestParameters['renewEmailRequestAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RenewEmailResponseAPIModelFromJSON(jsonValue));
    }

    /**
     * Sends update email request
     * 
     */
    async updateEmail(renewEmailRequestAPIModel: RenewEmailRequestAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RenewEmailResponseAPIModel> {
        const response = await this.updateEmailRaw({ renewEmailRequestAPIModel: renewEmailRequestAPIModel }, initOverrides);
        return await response.value();
    }

}
