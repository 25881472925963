import React from 'react';

import { OperationRefresh } from '@/components';
import { useWebhooks } from '@/features/company-settings/hooks';

import type { OperationRefreshWebhooksProps } from './types';

const OperationRefreshWebhooks: React.FC<OperationRefreshWebhooksProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useWebhooks();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshWebhooksMemo = React.memo(OperationRefreshWebhooks);

export default OperationRefreshWebhooksMemo;
