import { Typography } from 'antd';
import React from 'react';

import ContextableLink from '../ContextableLink';

import type { DisableableLinkProps } from './types';

const { Link: TypographyLink } = Typography;

const DisableableLink = React.forwardRef<HTMLAnchorElement, DisableableLinkProps>(function DisableableLink(
  { children, 'data-test': testLocator, external, to, disabled, className, style },
  ref,
) {
  // eslint-disable-next-line no-nested-ternary
  return disabled ? (
    <TypographyLink disabled={disabled} data-test={testLocator} ref={ref} className={className} style={style}>
      {children}
    </TypographyLink>
  ) : external ? (
    <TypographyLink data-test={testLocator} href={to} target="_blank" ref={ref} className={className} style={style}>
      {children}
    </TypographyLink>
  ) : (
    <ContextableLink to={to} data-test={testLocator} ref={ref} className={className} style={style}>
      {children}
    </ContextableLink>
  );
});

const DisableableLinkMemo = React.memo(DisableableLink);

export default DisableableLinkMemo;
