import React, { useContext } from 'react';

import { PayoutTableRowFormValueContext } from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';

import { EditableAddressCell, EditableAmountCell } from './components';

import type { PayoutDestinationTableEditableCellProps } from './types';

const PayoutDestinationTableEditableCell: React.FC<PayoutDestinationTableEditableCellProps> = ({
  'data-test': dataTest,
  dataIndex,
  record,
  children,
  asset,
  data,
  ...restProps
}) => {
  const { editingKey } = useContext(PayoutTableRowFormValueContext);
  return (
    <td key={record?.num} {...restProps} style={{ verticalAlign: 'top' }}>
      {editingKey === record?.num ? (
        <>
          {dataIndex === 'address' ? (
            <EditableAddressCell data-test={dataTest} asset={asset} record={record} existItems={data} />
          ) : dataIndex === 'amount' ? (
            asset && <EditableAmountCell data-test={dataTest} asset={asset} />
          ) : (
            children
          )}
        </>
      ) : (
        children
      )}
    </td>
  );
};

const PayoutDestinationTableEditableCellMemo = React.memo(PayoutDestinationTableEditableCell);

export default PayoutDestinationTableEditableCellMemo;
