import { Form } from 'antd';
import React from 'react';

import { defaultDialogFormLayout } from '@/components';
import type { ReportScheduleParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/schedules';

import MerchantWalletTransfersScheduleParametersForm from '../MerchantWalletTransfersReportScheduleParametersForm';

const MerchantWalletTransfersScheduleParametersView: React.FC<ReportScheduleParametersViewProps> = ({
  value,
  'data-test': dataTest,
}) => (
  <Form initialValues={{ parameters: value }} {...defaultDialogFormLayout}>
    <MerchantWalletTransfersScheduleParametersForm data-test={dataTest} name="parameters" readonly />
  </Form>
);

const MerchantWalletTransfersScheduleParametersViewMemo = React.memo(
  MerchantWalletTransfersScheduleParametersView,
) as typeof MerchantWalletTransfersScheduleParametersView;

export default MerchantWalletTransfersScheduleParametersViewMemo;
