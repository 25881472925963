import { useMemo } from 'react';

import type { BrandingProfileFormValues } from '@/features/branding/components';
import { useBrandingProfile } from '@/features/branding/hooks';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withBrandingProfileDataLoading = <T extends { data: BrandingProfileFormValues } & TestableProps>(
  Component: React.ComponentType<Omit<T, 'data'> & { data: BrandingProfileFormValues }>,
): React.FC<Omit<T, 'data'> & { profileId: string }> =>
  namedHOC<Omit<T, 'data'> & { data: BrandingProfileFormValues }, Omit<T, 'data'> & { profileId: string }>(
    Component,
    'WithBrandingProfileDataLoading',
  )((props) => {
    const { 'data-test': dataTest, profileId } = props;
    const profileState = useBrandingProfile(profileId);
    const data = useMemo(
      () =>
        mapStoredState(profileState.data, (profile) => ({
          ...profile.data,
          qr: profile.qr,
          id: profile.id,
          name: profile.name,
        })),
      [profileState.data],
    );
    return withCardDataLoading<BrandingProfileFormValues, Omit<T, 'data'> & { data: BrandingProfileFormValues }>({
      ...profileState,
      data,
      'data-test': dataTest,
      cardSize: 'big',
      title: null,
      hideBack: true,
    })(Component)(props);
  });

export default withBrandingProfileDataLoading;
