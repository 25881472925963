import React, { useMemo } from 'react';

import { FormattedMessage, NCPSTable, ReadonlyComponent, StopPropagationContainer } from '@/components';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import type { PayoutDestination } from '@/features/payouts/types';
import { PayoutDestinationSortByAPIModel } from '@/features/payouts/types';
import { I18nComponents, I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { asType, nameof } from '@/infrastructure/utils/ts';
import { sortOrderToTable } from '@/infrastructure/view';

import type { BasePayoutFilter, PayoutDestinationTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<PayoutDestination>('num')]: PayoutDestinationSortByAPIModel.Num,
  [nameof<PayoutDestination>('address')]: PayoutDestinationSortByAPIModel.Address,
};
const extractKey = ({ num }: PayoutDestination) => `${num}`;
const sortColumnToModel = (s: string) => sortMapping[s];

const PayoutDestinationTable = <FilterType extends BasePayoutFilter = BasePayoutFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  Operations,
}: PayoutDestinationTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<PayoutDestination>[] = useMemo(
    () => [
      {
        key: nameof<PayoutDestination>('num'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_DESTINATION_ID} />,
        dataIndex: nameof<PayoutDestination>('num'),
        sortOrder: sortOrderToTable(sortBy.Num),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { num }) => (
          <ReadonlyComponent data-test={dataTest && `${dataTest}-${num}-id`} value={num} copyable={false} />
        ),
      },
      {
        key: nameof<PayoutDestination>('address'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_DESTINATION_ADDRESS} />,
        dataIndex: nameof<PayoutDestination>('address'),
        sortOrder: sortOrderToTable(sortBy.Address),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { num, address, amount }) => (
          <AddressLink data-test={dataTest && `${dataTest}-${num}-address`} assetId={amount.asset} address={address} />
        ),
      },
      {
        key: nameof<PayoutDestination>('amount'),
        title: <FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_DESTINATION_AMOUNT} />,
        dataIndex: nameof<PayoutDestination>('amount'),
        fixed: 'right',
        render: (_, { num, amount }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                data-test={dataTest && `${dataTest}-${num}-amount`}
                assetId={amount.asset}
                value={amount.value}
                withCurrency
                withBlockchainMark
              />
            }
            copyable={false}
          />
        ),
      },
      ...asType<ProColumns<PayoutDestination>[]>(
        Operations
          ? [
              {
                key: 'options',
                title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
                width: 1,
                fixed: 'right',
                render: (_, value) => (
                  <StopPropagationContainer>
                    <Operations value={value} data-test={dataTest && `${dataTest}-${value.num}-op`} />
                  </StopPropagationContainer>
                ),
              },
            ]
          : [],
      ),
    ],
    [sortBy.Num, sortBy.Address, dataTest, Operations],
  );

  const { ref, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            filterComponent,
          ]
        : undefined,
    [ExportOperation, FilterForm, dataTest, exportMode, data.data?.total],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<PayoutDestination, FilterType, PayoutDestinationSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const PayoutDestinationTableMemo = React.memo(PayoutDestinationTable);

export default PayoutDestinationTableMemo;
