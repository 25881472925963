import React from 'react';

import { Link, FormattedMessage } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { subscriptionPlansLink } from '@/pages/subscriptions/routes';

import type { SubscriptionPlansLinkProps } from './types';

const SubscriptionPlansLink: React.FC<SubscriptionPlansLinkProps> = ({ 'data-test': dataTest, title, ...props }) => (
  <Link
    to={subscriptionPlansLink()}
    data-test={dataTest}
    title={title || <FormattedMessage id={I18nPageSubscriptions.PLANS_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SubscriptionPlansLinkMemo = React.memo(SubscriptionPlansLink);

export default SubscriptionPlansLinkMemo;
