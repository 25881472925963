/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CompanyAPIModel } from './CompanyAPIModel';
import {
    CompanyAPIModelFromJSON,
    CompanyAPIModelFromJSONTyped,
    CompanyAPIModelToJSON,
} from './CompanyAPIModel';

/**
 * 
 * @export
 * @interface OnboardResponseAPIModel
 */
export interface OnboardResponseAPIModel {
    /**
     * 
     * @type {CompanyAPIModel}
     * @memberof OnboardResponseAPIModel
     */
    company: CompanyAPIModel;
}

/**
 * Check if a given object implements the OnboardResponseAPIModel interface.
 */
export function instanceOfOnboardResponseAPIModel(value: object): boolean {
    if (!('company' in value)) return false;
    return true;
}

export function OnboardResponseAPIModelFromJSON(json: any): OnboardResponseAPIModel {
    return OnboardResponseAPIModelFromJSONTyped(json, false);
}

export function OnboardResponseAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardResponseAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'company': CompanyAPIModelFromJSON(json['company']),
    };
}

export function OnboardResponseAPIModelToJSON(value?: OnboardResponseAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'company': CompanyAPIModelToJSON(value['company']),
    };
}

