import { useEffect, useState } from 'react';

import * as SentryInitializer from '@/infrastructure/sentry/utils';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withSentryInitialization = <P extends Record<string, unknown>>(Component: React.ComponentType<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithSentryInitialization',
  )((props: P) => {
    const [initialized, setInitialized] = useState(false);
    useEffect(() => {
      if (!initialized) {
        try {
          SentryInitializer.init();
        } finally {
          setInitialized(true);
        }
      }
    }, [initialized]);
    return initialized ? <Component {...props} /> : null;
  });

export default withSentryInitialization;
