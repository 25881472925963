import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { generatePaymentURL } from '@/features/payments/actions';
import usePayment from '@/features/payments/hooks/usePayment';
import { PaymentStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

export interface UseSinglePaymentActionsType {
  generatePaymentURL: HookAction<[], string>;
}

export default function usePaymentActions(paymentId: string): UseSinglePaymentActionsType {
  const { data } = usePayment(paymentId);
  const isGenerating = useActionPending(generatePaymentURL, paymentId);
  const { withExtractDataDispatch } = useAppDispatch();
  const generatePaymentURLAction = {
    act: useCallback(
      () => withExtractDataDispatch(generatePaymentURL)({ paymentId }),
      [paymentId, withExtractDataDispatch],
    ),
    available: data.data?.status === PaymentStatusAPIModel.Created,
    inAction: isGenerating,
  };

  return { generatePaymentURL: generatePaymentURLAction };
}
