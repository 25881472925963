import React from 'react';

import { OperationRefresh } from '@/components';
import { usePayoutBatches } from '@/features/payouts/hooks';

import type { OperationRefreshPayoutBatchesProps } from './types';

const OperationRefreshPayoutBatches: React.FC<OperationRefreshPayoutBatchesProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  payoutId,
}) => {
  const { forceRefresh } = usePayoutBatches(payoutId);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshPayoutBatchesMemo = React.memo(OperationRefreshPayoutBatches);

export default OperationRefreshPayoutBatchesMemo;
