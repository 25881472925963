import { CheckOutlined, StopOutlined } from '@ant-design/icons';
import { Form, Space } from 'antd';
import React, { useCallback, useContext } from 'react';

import { FormattedMessage, Operation } from '@/components';
import {
  type PayoutDestinationTableEditFormValues,
  PayoutTableRowFormValueContext,
} from '@/features/payouts/components/EditPayoutForm/components/PayoutDestinationsTableInput/hocs';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { assertNotNil, noop } from '@/infrastructure/utils/functions';

import type { PayoutDestinationTableConfirmRowOperationsProps } from './types';

const PayoutDestinationTableConfirmRowOperations: React.FC<PayoutDestinationTableConfirmRowOperationsProps> = ({
  'data-test': dataTest,
  onSave,
  onCancel = noop,
  disabled,
}) => {
  const { formItemName, isReady, editingKey } = useContext(PayoutTableRowFormValueContext);
  const form = Form.useFormInstance();

  const doSave = useCallback(async () => {
    assertNotNil(editingKey);
    if (!isReady) {
      throw new Error('Form is not ready yet');
    }
    const { address, amount }: PayoutDestinationTableEditFormValues = form.getFieldValue(formItemName);
    assertNotNil(amount.value);
    await onSave({ amount: amount.value, num: editingKey, address });
  }, [editingKey, form, formItemName, isReady, onSave]);
  return (
    <Space>
      <Operation
        data-test={dataTest && `${dataTest}-save`}
        mode="inline"
        icon={<CheckOutlined style={{ color: 'green' }} />}
        title={
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_SAVE}
          />
        }
        onClick={doSave}
        disabled={disabled || !isReady}
      />
      <Operation
        data-test={dataTest && `${dataTest}-cancel`}
        mode="inline"
        icon={<StopOutlined style={{ color: 'red' }} />}
        title={
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_CANCEL}
          />
        }
        onClick={onCancel}
        disabled={disabled}
      />
    </Space>
  );
};

const PayoutDestinationTableConfirmRowOperationsMemo = React.memo(PayoutDestinationTableConfirmRowOperations);

export default PayoutDestinationTableConfirmRowOperationsMemo;
