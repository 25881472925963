/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';

/**
 * 
 * @export
 * @interface Tuple2BlockchainTypeStringAPIModel
 */
export interface Tuple2BlockchainTypeStringAPIModel {
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof Tuple2BlockchainTypeStringAPIModel
     */
    _1: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof Tuple2BlockchainTypeStringAPIModel
     */
    _2: string;
}

/**
 * Check if a given object implements the Tuple2BlockchainTypeStringAPIModel interface.
 */
export function instanceOfTuple2BlockchainTypeStringAPIModel(value: object): boolean {
    if (!('_1' in value)) return false;
    if (!('_2' in value)) return false;
    return true;
}

export function Tuple2BlockchainTypeStringAPIModelFromJSON(json: any): Tuple2BlockchainTypeStringAPIModel {
    return Tuple2BlockchainTypeStringAPIModelFromJSONTyped(json, false);
}

export function Tuple2BlockchainTypeStringAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tuple2BlockchainTypeStringAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        '_1': BlockchainTypeAPIModelFromJSON(json['_1']),
        '_2': json['_2'],
    };
}

export function Tuple2BlockchainTypeStringAPIModelToJSON(value?: Tuple2BlockchainTypeStringAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        '_1': BlockchainTypeAPIModelToJSON(value['_1']),
        '_2': value['_2'],
    };
}

