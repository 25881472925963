/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { ReportScheduleSortByAPIModel } from './ReportScheduleSortByAPIModel';
import {
    ReportScheduleSortByAPIModelFromJSON,
    ReportScheduleSortByAPIModelFromJSONTyped,
    ReportScheduleSortByAPIModelToJSON,
} from './ReportScheduleSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeReportScheduleSortByAPIModel
 */
export interface SortAttributeReportScheduleSortByAPIModel {
    /**
     * 
     * @type {ReportScheduleSortByAPIModel}
     * @memberof SortAttributeReportScheduleSortByAPIModel
     */
    attr: ReportScheduleSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeReportScheduleSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeReportScheduleSortByAPIModel interface.
 */
export function instanceOfSortAttributeReportScheduleSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeReportScheduleSortByAPIModelFromJSON(json: any): SortAttributeReportScheduleSortByAPIModel {
    return SortAttributeReportScheduleSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeReportScheduleSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeReportScheduleSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': ReportScheduleSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeReportScheduleSortByAPIModelToJSON(value?: SortAttributeReportScheduleSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': ReportScheduleSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

