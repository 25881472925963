import { Tooltip } from 'antd';
import React, { useCallback } from 'react';

import { BooleanSwitch, FormattedMessage } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { QRSupportSelectOperationProps } from './types';

const QRSupportSelectOperation: React.FC<QRSupportSelectOperationProps> = ({
  'data-test': dataTest,
  settings,
  assets,
  onSelect,
}) => {
  const doSelect = useCallback(
    (checked: boolean) => onSelect(settings.blockchain, checked),
    [onSelect, settings.blockchain],
  );
  const { data: systemInfo } = useBlockchainSystemInfo(settings.blockchain);
  const readonly = systemInfo.data?.forwarder === 'required' && !!assets.length && settings.isEnabled;
  return readonly ? (
    <Tooltip
      title={
        <FormattedMessage
          id={I18nFeatureCompanySettings.COMPONENTS_UPDATE_QR_SUPPORT_FORM_INPUT_REQUIRED_NETWORK_TOOLTIP}
          tagName="span"
        />
      }
    >
      <span>
        <BooleanSwitch data-test={dataTest} value={settings.isEnabled} onChange={doSelect} readonly />
      </span>
    </Tooltip>
  ) : (
    <BooleanSwitch data-test={dataTest} value={settings.isEnabled} onChange={doSelect} />
  );
};

const QRSupportSelectOperationMemo = React.memo(QRSupportSelectOperation);

export default QRSupportSelectOperationMemo;
