import { css, cx } from '@emotion/css';
import React, { useEffect } from 'react';

import type { PageLoadingProps } from './types';

const PageLoading: React.FC<PageLoadingProps> = ({ 'data-test': dataTest, type, className, style }) => {
  useEffect(() => {
    const timeout = setTimeout(() => console.warn(`Loading is stale for "${type}"`), 1000);
    return () => clearTimeout(timeout);
  }, [type]);
  return (
    <div
      data-test={dataTest}
      className={cx(
        css`
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          min-height: 420px;
          height: 100%;
          margin-top: -28px;
        `,
        className,
      )}
      style={style}
    >
      <style>
        {`
        @keyframes innerFlip {
          0 % { transform: rotateY(0deg); }
          50% { transform: rotateY(180deg); }
          100% { transform: rotateY(360deg); }
      }`}
      </style>
      <div
        className={css`
          width: 48px;
          height: 48px;
          z-index: 9999;
        `}
      >
        <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient
              id="loader_logo1_paint"
              x1="0"
              y1="0"
              x2="24.1011"
              y2="23.6463"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F0875C"></stop>
              <stop offset="1" stopColor="#EC5A69"></stop>
            </linearGradient>
          </defs>
          <path
            d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
            fill="url(#loader_logo1_paint)"
          ></path>
          <g style={{ animation: '4s 0s infinite normal none running innerFlip', transformOrigin: 'center' }}>
            <path
              d="M17.2323 9.24376C17.2323 10.3552 16.3313 11.2562 15.2199 11.2562C14.1085 11.2562 13.2075 10.3552 13.2075 9.24376C13.2075 8.13232 14.1085 7.23132 15.2199 7.23132C16.3313 7.23132 17.2323 8.13232 17.2323 9.24376Z"
              fill="white"
            ></path>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.76767 7.23132L10.4244 14.529V18.5009H13.2075V14.1183C13.2075 13.8491 13.1457 13.5843 13.0282 13.3498L9.96232 7.23132H6.76767Z"
              fill="white"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  );
};

const PageLoadingMemo = React.memo(PageLoading);

export default PageLoadingMemo;
