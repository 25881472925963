import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import type { CollectableEntityTransaction } from '@/features/collectable/types';
import { extractEntityTransactionId } from '@/features/collectable/utils';
import { AddressLink, AssetAmount } from '@/features/dictionary/blockchain/components';
import { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { nameof } from '@/infrastructure/utils/ts';
import { sortOrderToTable } from '@/infrastructure/view';

import CollectableEntityTransactionView from '../CollectableEntityTransactionView';

import type { BaseCollectableEntityTransactionFilter, CollectableEntityTransactionsTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<CollectableEntityTransaction>('createdAt')]: PushTransactionSortByAPIModel.CreatedAt,
  [nameof<CollectableEntityTransaction>('broadcastAt')]: PushTransactionSortByAPIModel.BroadcastAt,
};
const extractKey = extractEntityTransactionId;
const sortColumnToModel = (s: string) => sortMapping[s];

const CollectableEntityTransactionsTable = <
  FilterType extends BaseCollectableEntityTransactionFilter = BaseCollectableEntityTransactionFilter,
>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  CollectableEntityTransactionLink,
}: CollectableEntityTransactionsTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<CollectableEntityTransaction>[] = useMemo(
    () => [
      {
        key: nameof<CollectableEntityTransaction>('id'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_ID} />,
        dataIndex: nameof<CollectableEntityTransaction>('id'),
        render: (_, { id }) => <ReadonlyComponent value={id} data-test={dataTest && `${dataTest}-${id}-id`} copyable />,
      },
      {
        key: nameof<CollectableEntityTransaction>('fromAddress'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_FROM_ADDRESS} />,
        dataIndex: nameof<CollectableEntityTransaction>('fromAddress'),
        render: (_, { id, fromAddress, blockchain }) => (
          <AddressLink address={fromAddress} bt={blockchain} data-test={dataTest && `${dataTest}-${id}-fromAddress`} />
        ),
      },
      {
        key: nameof<CollectableEntityTransaction>('amount'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_AMOUNT} />,
        dataIndex: nameof<CollectableEntityTransaction>('amount'),
        render: (_, { id, amount }) => (
          <ReadonlyComponent
            value={
              <AssetAmount
                assetId={amount.asset}
                value={amount.value}
                withCurrency
                withBlockchainMark
                data-test={dataTest && `${dataTest}-${id}-amount`}
              />
            }
            copyable={false}
          />
        ),
        width: 120,
      },
      {
        key: nameof<CollectableEntityTransaction>('broadcastAt'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TX_ROW_TITLE_BROADCAST_AT} />,
        dataIndex: nameof<CollectableEntityTransaction>('broadcastAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.BroadcastAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, broadcastAt }) => (
          <ReadonlyDateTime value={broadcastAt} data-test={dataTest && `${dataTest}-${id}-broadcastAt`} />
        ),
        width: 160,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {CollectableEntityTransactionLink && (
              <CollectableEntityTransactionLink value={id} data-test={dataTest && `${dataTest}-${id}-link`} />
            )}
          </StopPropagationContainer>
        ),
      },
    ],
    [CollectableEntityTransactionLink, dataTest, sortBy.BroadcastAt],
  );

  const preview = useCallback(
    (value: CollectableEntityTransaction) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            CollectableEntityTransactionLink && (
              <CollectableEntityTransactionLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <CollectableEntityTransactionView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [CollectableEntityTransactionLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'broadcastAtRange', 'broadcastAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey="broadcastAtRange"
              relativeKey="broadcastAtRangeRelative"
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      FilterForm,
      ExportOperation,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<CollectableEntityTransaction, FilterType, PushTransactionSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const CollectableEntityTransactionsTableMemo = React.memo(
  CollectableEntityTransactionsTable,
) as typeof CollectableEntityTransactionsTable;

export default CollectableEntityTransactionsTableMemo;
