import type { Chart, PlotEvent } from '@ant-design/charts';

export interface UseChartPlanLabels {
  overallLineLabels: Record<LineType, string>;
  legendItemValue: (text: string) => string | null;
  legendItemName: (text: string) => string;
  tooltipItemValue: (text: string) => string;
  onPlanViewClick: (chart: Chart, event: PlotEvent) => void;
}

export enum LineType {
  total = 'total',
  mean = 'mean',
  median = 'median',
}
