import React, { useCallback, useMemo } from 'react';

import {
  FormattedMessage,
  NCPSTable,
  OperationDateTimeRelativeQuickFilter,
  ReadonlyComponent,
  ReadonlyDateTime,
  StopPropagationContainer,
  TitleWithDetails,
} from '@/components';
import type { CollectTaskSummary } from '@/features/collectable/types';
import { extractCollectTaskId } from '@/features/collectable/utils';
import { AssetAmount, AssetLabel } from '@/features/dictionary/blockchain/components';
import { PushCollectSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nComponents, I18nFeatureCollectable } from '@/generated/i18n/i18n';
import { useDefaultNotification, useTableButtonsMode } from '@/hooks';
import { nameof } from '@/infrastructure/utils/ts';
import { sortOrderToTable } from '@/infrastructure/view';

import CollectableKindView from '../CollectableKindView';
import CollectTaskStatusView from '../CollectTaskStatusView';
import CollectTaskView from '../CollectTaskView';

import type { BaseCollectTaskFilter, CollectTasksTableProps } from './types';
import type { ProColumns } from '@ant-design/pro-table';

const sortMapping = {
  [nameof<CollectTaskSummary>('processAt')]: PushCollectSortByAPIModel.ProcessAt,
};
const extractKey = extractCollectTaskId;
const sortColumnToModel = (s: string) => sortMapping[s];

const CollectTasksTable = <FilterType extends BaseCollectTaskFilter = BaseCollectTaskFilter>({
  'data-test': dataTest,
  data,
  columnState,
  filter,
  updateParameters,
  loading,
  forceRefresh,
  page,
  sortBy,
  ExportOperation,
  FilterForm,
  CollectTaskLink,
}: CollectTasksTableProps<FilterType>) => {
  const { withDefaultError } = useDefaultNotification();

  const reload = useMemo(() => withDefaultError(forceRefresh), [forceRefresh, withDefaultError]);

  const columns: ProColumns<CollectTaskSummary>[] = useMemo(
    () => [
      {
        key: nameof<CollectTaskSummary>('id'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_ID} />,
        dataIndex: nameof<CollectTaskSummary>('id'),
        render: (_, { id }) => <ReadonlyComponent value={id} data-test={dataTest && `${dataTest}-${id}-id`} copyable />,
      },
      {
        key: nameof<CollectTaskSummary>('kind'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_KIND} />,
        dataIndex: nameof<CollectTaskSummary>('kind'),
        render: (_, { id, kind }) => (
          <CollectableKindView value={kind} data-test={dataTest && `${dataTest}-${id}-kind`} mode="icon" />
        ),
      },
      {
        key: nameof<CollectTaskSummary>('asset'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_ASSET} />,
        dataIndex: nameof<CollectTaskSummary>('asset'),
        render: (_, { id, asset }) => (
          <AssetLabel mode="short" value={asset} withBlockchainMark data-test={dataTest && `${dataTest}-${id}-asset`} />
        ),
      },
      {
        key: nameof<CollectTaskSummary>('collectedAmount'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_COLLECTED_AMOUNT} />,
        dataIndex: nameof<CollectTaskSummary>('collectedAmount'),
        render: (_, { id, collectedAmount }) =>
          collectedAmount && (
            <ReadonlyComponent
              value={
                <AssetAmount
                  assetId={collectedAmount.asset}
                  value={collectedAmount.value}
                  withCurrency
                  withBlockchainMark
                  data-test={dataTest && `${dataTest}-${id}-amount`}
                />
              }
              copyable={false}
            />
          ),
        width: 120,
      },
      {
        key: nameof<CollectTaskSummary>('processAt'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_PROCESS_AT} />,
        dataIndex: nameof<CollectTaskSummary>('processAt'),
        valueType: 'dateTime',
        sortOrder: sortOrderToTable(sortBy.ProcessAt),
        sorter: true,
        sortDirections: ['descend', 'ascend', 'descend'],
        render: (_, { id, processAt }) => (
          <ReadonlyDateTime value={processAt} data-test={dataTest && `${dataTest}-${id}-processAt`} />
        ),
        width: 160,
      },
      {
        key: nameof<CollectTaskSummary>('status'),
        title: <FormattedMessage id={I18nFeatureCollectable.LABELS_TASK_ROW_TITLE_STATUS} />,
        dataIndex: nameof<CollectTaskSummary>('status'),
        hideInSetting: true,
        align: 'center',
        fixed: 'right',
        render: (_, { id, status }) => (
          <CollectTaskStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
        width: 60,
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {CollectTaskLink && <CollectTaskLink value={id} data-test={dataTest && `${dataTest}-${id}-link`} />}
          </StopPropagationContainer>
        ),
      },
    ],
    [CollectTaskLink, dataTest, sortBy.ProcessAt],
  );

  const preview = useCallback(
    (value: CollectTaskSummary) => ({
      title: (
        <TitleWithDetails
          title={
            <FormattedMessage
              id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_TABLE_DIALOG_TITLE}
              values={{ id: value.id }}
            />
          }
          link={
            CollectTaskLink && (
              <CollectTaskLink
                value={value.id}
                mode="text"
                title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} values={{ id: value.id }} />}
              />
            )
          }
          data-test={dataTest && `${dataTest}-dialogTitle`}
        />
      ),
      content: (
        <CollectTaskView
          data={value}
          data-test={dataTest && `${dataTest}-view`}
          style={{ paddingTop: '10px' }}
          columns={1}
        />
      ),
    }),
    [CollectTaskLink, dataTest],
  );

  const { ref, quickFilterMode, exportMode } = useTableButtonsMode();
  const toolBar = useMemo(
    () =>
      FilterForm
        ? (filterComponent?: React.ReactNode) => [
            ...(ExportOperation
              ? [
                  <ExportOperation
                    key="export"
                    data-test={dataTest && `${dataTest}-export`}
                    mode={exportMode}
                    rows={data.data?.total}
                  />,
                ]
              : []),
            <OperationDateTimeRelativeQuickFilter<'processAtRange', 'processAtRangeRelative', FilterType>
              key="quickFilter"
              absoluteKey="processAtRange"
              relativeKey="processAtRangeRelative"
              onChange={(newValue) => updateParameters({ filter: newValue })}
              mode={quickFilterMode}
              value={filter}
              disabled={loading}
            />,
            filterComponent,
          ]
        : undefined,
    [
      FilterForm,
      ExportOperation,
      dataTest,
      exportMode,
      data.data?.total,
      quickFilterMode,
      filter,
      loading,
      updateParameters,
    ],
  );

  const filterForm = useMemo(
    () => (FilterForm ? { Form: FilterForm, empty: {} as FilterType } : undefined),
    [FilterForm],
  );

  return (
    <div ref={ref}>
      <NCPSTable<CollectTaskSummary, FilterType, PushCollectSortByAPIModel>
        data-test={dataTest}
        filter={filterForm}
        preview={preview}
        toolBarRender={toolBar}
        sortColumnToModel={sortColumnToModel}
        columns={columns}
        data={data}
        loading={loading}
        page={page}
        filterData={filter}
        columnsState={columnState}
        reload={reload}
        updateParameters={updateParameters}
        extractKey={extractKey}
      />
    </div>
  );
};

const CollectTasksTableMemo = React.memo(CollectTasksTable);

export default CollectTasksTableMemo;
