import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import PaymentsBreadcrumb from '@/pages/payments/all/components/PaymentsBreadcrumb';

import PaymentLink from '../PaymentLink';

import type { PaymentBreadcrumbProps } from './types';

const PaymentBreadcrumb: React.FC<PaymentBreadcrumbProps> = ({
  'data-test': dataTest,
  paymentId,
  disabled,
  items = [],
}) => (
  <PaymentsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPagePayments.PAYMENT_VIEW_COMPONENTS_BREADCRUMB} values={{ id: paymentId }} />
        ) : (
          <PaymentLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={paymentId}
            title={
              <FormattedMessage id={I18nPagePayments.PAYMENT_VIEW_COMPONENTS_BREADCRUMB} values={{ id: paymentId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const PaymentBreadcrumbMemo = React.memo(PaymentBreadcrumb);

export default PaymentBreadcrumbMemo;
