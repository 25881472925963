/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

/**
 * 
 * @export
 * @enum {string}
 */
export enum PaymentCompatStatusAPIModel {
    Created = 'Created',
    Paid = 'Paid',
    SimplePaid = 'SimplePaid',
    Withdrawn = 'Withdrawn',
    Confirming = 'Confirming',
    OverPaid = 'OverPaid',
    UnderPaid = 'UnderPaid',
    Expired = 'Expired',
    Invalid = 'Invalid'
}


export function PaymentCompatStatusAPIModelFromJSON(json: any): PaymentCompatStatusAPIModel {
    return PaymentCompatStatusAPIModelFromJSONTyped(json, false);
}

export function PaymentCompatStatusAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PaymentCompatStatusAPIModel {
    return json as PaymentCompatStatusAPIModel;
}

export function PaymentCompatStatusAPIModelToJSON(value?: PaymentCompatStatusAPIModel | null): any {
    return value as any;
}

