import { type UseAppListDataView, useAppNestedListDataView } from '@/app/hooks';
import { storeDonationTransactionForDonationListParameters } from '@/features/donations/actions';
import type { DonationTransaction, DonationTransactionFilterPredicate } from '@/features/donations/types';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useDonationTransactionsForDonationList from './useDonationTransactionsForDonationList';

export type UseDonationTransactionsForDonationListView = UseAppListDataView<
  DonationTransaction,
  DonationTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

export default function useDonationTransactionsForDonationListView(
  donationId: string | undefined,
): UseDonationTransactionsForDonationListView {
  return useAppNestedListDataView(
    useDonationTransactionsForDonationList(donationId),
    storeDonationTransactionForDonationListParameters,
    donationId,
  );
}
