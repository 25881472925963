import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { createPayout } from '@/features/payouts/actions';
import type { NewPayout } from '@/features/payouts/types';

import type { UsePayoutsActions } from './types';

export default function usePayoutsActions(): UsePayoutsActions {
  const { withExtractDataDispatch } = useAppDispatch();

  const isCreating = useActionPending(createPayout);
  const createAction: UsePayoutsActions['create']['act'] = useCallback(
    (data: NewPayout) => withExtractDataDispatch(createPayout)(data),
    [withExtractDataDispatch],
  );
  const createHook: UsePayoutsActions['create'] = {
    act: createAction,
    inAction: isCreating,
    available: true,
  };

  return { create: createHook };
}
