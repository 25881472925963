import React from 'react';

import { DateTimeRelativeItem, FormattedMessage } from '@/components';
import { NetworkTypeItem } from '@/features/dictionary/blockchain/components';
import type { PaymentReportFilterPredicate } from '@/features/payments/types';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { PaymentCompatStatusFilterItem } from './components';

import type { PaymentsReportScheduleParametersFormProps } from './types';

const PaymentsReportScheduleParametersForm: React.FC<PaymentsReportScheduleParametersFormProps> = ({
  'data-test': dataTest,
  name,
  readonly,
}) => (
  <>
    <DateTimeRelativeItem
      label={
        <FormattedMessage
          id={I18nFeaturePayments.COMPONENTS_PAYMENTS_REPORT_SCHEDULE_PARAMETERS_FORM_CREATED_AT_RELATIVE_ITEM_LABEL}
        />
      }
      readonly={readonly}
      required={false}
      data-test={dataTest && `${dataTest}-createdAt`}
      name={[name, nameof<PaymentReportFilterPredicate>('createdRangeRelative')]}
    />
    <PaymentCompatStatusFilterItem
      readonly={readonly}
      data-test={dataTest && `${dataTest}-status`}
      required={false}
      name={[name, nameof<PaymentReportFilterPredicate>('statuses')]}
    />
    <NetworkTypeItem
      readonly={readonly}
      mode="select"
      data-test={dataTest && `${dataTest}-network`}
      required={false}
      name={[name, nameof<PaymentReportFilterPredicate>('network')]}
    />
  </>
);

const PaymentsReportScheduleParametersFormMemo = React.memo(PaymentsReportScheduleParametersForm);

export default PaymentsReportScheduleParametersFormMemo;
