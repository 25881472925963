import { namedHOC } from '@/infrastructure/utils/react';

import { OperationWithConfirmation } from './components';

import type { OperationProps, OperationWithConfirmationProps } from '../types';
import type React from 'react';

const withConfirmation = (Component: React.ComponentType<OperationProps>): React.FC<OperationWithConfirmationProps> =>
  namedHOC(
    Component,
    'WithConfirmation',
  )(({ confirmation, ...props }: OperationWithConfirmationProps) => {
    if (!confirmation) {
      return <Component {...props} />;
    }
    return <OperationWithConfirmation {...props} Component={Component} confirmation={confirmation} />;
  });

export default withConfirmation;
