import { useCallback } from 'react';

import { FormattedMessage } from '@/components';
import { useSubscriptionPlan } from '@/features/subscription-plans/hooks';
import type { SubscriptionPlan } from '@/features/subscription-plans/types';
import { useSubscription } from '@/features/subscriptions/hooks';
import { I18nComponents, I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import SubscriptionPlanLink from '@/pages/subscriptions/plan-view/components/SubscriptionPlanLink';

import type React from 'react';

const withSubscriptionPlanShortCard =
  <
    Original extends { data: SubscriptionPlan } & TestableProps,
    Wrapper extends Omit<Original, 'data'> & { subscriptionId: string } = Omit<Original, 'data'> & {
      subscriptionId: string;
    },
  >(
    Component: React.ComponentType<Original>,
  ): React.FC<Wrapper> =>
  (props) => {
    const dataTest: string | undefined = props['data-test'];
    const subscription = useSubscription(props.subscriptionId);
    const plan = useSubscriptionPlan(subscription.data.data?.planId);
    const forceRefresh = useCallback(
      async () => Promise.all([subscription.forceRefresh(), plan.forceRefresh()]),
      [plan, subscription],
    );
    return withCardDataLoading<SubscriptionPlan, Original>({
      data: plan.data,
      loading: subscription.loading || plan.loading,
      forceRefresh,
      hideBack: true,
      'data-test': dataTest,
      CardProps: {
        extra: plan.data.data?.id && (
          <SubscriptionPlanLink
            data-test={dataTest && `${dataTest}-link`}
            value={plan.data.data.id}
            mode="text"
            title={<FormattedMessage id={I18nComponents.TABLE_LINK_DETAILS} />}
          />
        ),
      },
      title: (
        <FormattedMessage
          id={I18nPageSubscriptions.SUBSCRIPTION_VIEW_COMPONENTS_PLAN_SHORT_CARD_TITLE}
          values={{ id: plan.data.data?.id }}
        />
      ),
    })(Component)(props);
  };

export default withSubscriptionPlanShortCard;
