import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useCollectTaskProcessTransaction } from '@/features/collectable/hooks';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { OperationRefreshCollectTaskTransactionProps } from './types';

const messages = {
  tooltip: (
    <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_TRANSACTION_TOOLTIP} />
  ),
  success: {
    message: (
      <FormattedMessage
        id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_TRANSACTION_MESSAGE_SUCCESS}
      />
    ),
  },
};

const OperationRefreshCollectTaskTransaction: React.FC<OperationRefreshCollectTaskTransactionProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  taskId,
}) => {
  const { forceRefresh } = useCollectTaskProcessTransaction(taskId);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={messages} />;
};

const OperationRefreshCollectTaskTransactionMemo = React.memo(OperationRefreshCollectTaskTransaction);

export default OperationRefreshCollectTaskTransactionMemo;
