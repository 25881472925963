import React from 'react';

import type { DonationsDocumentationLinkProps } from './types';

const DonationsDocumentationLink: React.FC<DonationsDocumentationLinkProps> = (
  // eslint-disable-next-line no-empty-pattern
  {
    // mode,
    // title,
    // 'data-test': dataTest,
  },
) => null;
//   (
//   <DocumentationLink
//     mode={mode}
//     title={title ?? <FormattedMessage id={I18nFeatureDonations.COMPONENTS_DOCUMENTATION_LINK_TITLE} />}
//     data-test={dataTest}
//     path="/general/push-payment-button"
//   />
// );

const DonationsDocumentationLinkMemo = React.memo(DonationsDocumentationLink);

export default DonationsDocumentationLinkMemo;
