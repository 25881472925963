import { flatten } from 'flat';

import { I18nLocales } from '@/generated/i18n/i18n';
import { asType } from '@/infrastructure/utils/ts';

import enUS from './en-US.json';

const allLocales = asType<Record<I18nLocales, Record<string, string>>>({
  [I18nLocales['en-US']]: flatten(enUS),
});
export const defaultLocaleName = I18nLocales['en-US'];
export const defaultLocale = allLocales[defaultLocaleName];

export default allLocales;
