import { useMemo } from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useMerchantWalletTransfer } from '@/features/merchant-wallet-transfers/hooks';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import type { OperationRefreshMerchantWalletTransferProps } from './types';
import type React from 'react';

const OperationRefreshMerchantWalletTransfer: React.FC<OperationRefreshMerchantWalletTransferProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  id,
}) => {
  const { forceRefresh } = useMerchantWalletTransfer(id);
  return (
    <OperationRefresh
      data-test={dataTest}
      mode={mode}
      refresh={forceRefresh}
      messages={useMemo(
        () => ({
          title: (
            <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_RELOAD_TRANSFER_TITLE} />
          ),
          tooltip: (
            <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_RELOAD_TRANSFER_TOOLTIP} />
          ),
          success: {
            message: (
              <FormattedMessage
                id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_RELOAD_TRANSFER_SUCCESS_MESSAGE}
              />
            ),
          },
          error: {
            message: (
              <FormattedMessage
                id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_RELOAD_TRANSFER_FAILED_MESSAGE}
              />
            ),
            description: (
              <FormattedMessage
                id={I18nFeatureMerchantWalletTransfers.COMPONENTS_OPERATION_RELOAD_TRANSFER_FAILED_DESCRIPTION}
              />
            ),
          },
        }),
        [],
      )}
    />
  );
};

export default OperationRefreshMerchantWalletTransfer;
