import React from 'react';

import { CollectableEntityProcessTransactionView } from '@/features/collectable/components';

import { withRechargeAddressDataLoading, withRechargeAddressDeployTransactionAppearance } from './hocs';

const RechargeAddressDeployTransactionCard = withRechargeAddressDeployTransactionAppearance(
  withRechargeAddressDataLoading((props) => <CollectableEntityProcessTransactionView {...props} columns={1} />),
);

const RechargeAddressDeployTransactionCardMemo = React.memo(
  RechargeAddressDeployTransactionCard,
) as typeof RechargeAddressDeployTransactionCard;

export default RechargeAddressDeployTransactionCardMemo;
