import type {
  PayoutBatchAPIModel,
  PayoutDestinationAPIModel,
  PayoutSummaryAPIModel,
  PayoutSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { PayoutApi } from '@/generated/api/ncps-core/merchant-bo';
import type { DataSlice, SliceDataRequestWithTotal } from '@/infrastructure/api';
import { amountToAPI, pageToAPI, sliceFromAPI, amountFromAPI } from '@/infrastructure/api';
import { coreConfigurationFactory } from '@/infrastructure/request';

import type {
  MerklePayoutSignatureData,
  NewPayout,
  PayoutBatch,
  PayoutDestination,
  PayoutFilterPredicate,
  PayoutSummary,
  PayoutUpdate,
} from './types';

const payoutApi = new PayoutApi(coreConfigurationFactory('auth'));

const parsePayout = ({ amount, settledAmount, ...payout }: PayoutSummaryAPIModel): PayoutSummary => ({
  amount: amountFromAPI(amount),
  settledAmount: amountFromAPI(settledAmount),
  ...payout,
});

const parsePayoutBatch = ({ amount, ...batch }: PayoutBatchAPIModel): PayoutBatch => ({
  amount: amountFromAPI(amount),
  ...batch,
});
const parseDestination = ({ amount, ...destination }: PayoutDestinationAPIModel): PayoutDestination => ({
  ...destination,
  amount: amountFromAPI(amount),
});

export const queryPayouts = async (
  { page, filter, orderBy }: SliceDataRequestWithTotal<PayoutFilterPredicate, PayoutSortByAPIModel>,
  initOverrides?: RequestInit,
): Promise<DataSlice<PayoutSummary, PayoutSortByAPIModel>> =>
  sliceFromAPI(
    await payoutApi.searchPayouts({ page: pageToAPI(page, orderBy), predicates: filter ?? {} }, initOverrides),
    parsePayout,
  );

export const queryPayout = async (payoutId: string, initOverrides?: RequestInit): Promise<PayoutSummary> =>
  parsePayout(await payoutApi.getPayout(payoutId, initOverrides));

export const queryPayoutBySettlement = async (
  settlementId: string,
  initOverrides?: RequestInit,
): Promise<PayoutSummary> => {
  const batch = await payoutApi.getPayoutBatch(settlementId, initOverrides);
  return parsePayout(await payoutApi.getPayout(batch.payoutId, initOverrides));
};

export const queryPayoutBatches = async (payoutId: string, initOverrides?: RequestInit): Promise<PayoutBatch[]> =>
  (await payoutApi.getPayoutBatches(payoutId, initOverrides)).map(parsePayoutBatch);

export const queryPayoutDestinations = async (
  payoutId: string,
  initOverrides?: RequestInit,
): Promise<PayoutDestination[]> =>
  (await payoutApi.getPayoutDestinations(payoutId, initOverrides)).map(parseDestination);

export const requestCreatePayout = async (
  { destinations, ...payout }: NewPayout,
  initOverrides?: RequestInit,
): Promise<PayoutSummary> =>
  parsePayout(
    await payoutApi.createPayout(
      {
        ...payout,
        destinations: destinations.map(({ amount, ...destination }) => ({
          ...destination,
          amount: amountToAPI(amount),
        })),
      },
      initOverrides,
    ),
  );

export const requestUpdatePayout = async (
  payoutId: string,
  { destinations, ...payout }: PayoutUpdate,
  initOverrides?: RequestInit,
): Promise<PayoutSummary> =>
  parsePayout(
    await payoutApi.updatePayout(
      {
        id: payoutId,
        destinations: destinations.map(({ num, amount, address }) => ({ amount: amountToAPI(amount), address, num })),
        ...payout,
      },
      initOverrides,
    ),
  );

export const requestUpdatePayoutTitle = async (
  payoutId: string,
  title: string,
  initOverrides?: RequestInit,
): Promise<PayoutSummary> => parsePayout(await payoutApi.updatePayoutTitle(payoutId, { title }, initOverrides));

export const requestStartMerklePayout = async (
  payoutId: string,
  data: MerklePayoutSignatureData,
  initOverrides?: RequestInit,
): Promise<PayoutSummary> => parsePayout(await payoutApi.startPayout({ id: payoutId, ...data }, initOverrides));

export const requestDeletePayout = (payoutId: string, initOverrides?: RequestInit) =>
  payoutApi.deletePayout(payoutId, initOverrides);
