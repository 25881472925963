import React from 'react';

import { Link, FormattedMessage } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { subscriptionsLink } from '@/pages/subscriptions/routes';

import type { SubscriptionsLinkProps } from './types';

const SubscriptionsLink: React.FC<SubscriptionsLinkProps> = ({ 'data-test': dataTest, title, ...props }) => (
  <Link
    to={subscriptionsLink()}
    data-test={dataTest}
    title={title ?? <FormattedMessage id={I18nPageSubscriptions.ALL_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SubscriptionsLinkMemo = React.memo(SubscriptionsLink);

export default SubscriptionsLinkMemo;
