import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAuth } from '@/generated/i18n/i18n';

import type { AuthCardTitleProps } from './types';

const AuthCardTitle: React.FC<AuthCardTitleProps> = ({ activeProvider }) => {
  if (activeProvider === 'google') {
    return <FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_TITLE_GOOGLE} />;
  }
  if (activeProvider?.startsWith('email')) {
    return <FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_TITLE_EMAIL} />;
  }
  if (activeProvider) {
    return <FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_TITLE_WALLET} />;
  }
  return <FormattedMessage id={I18nPageAuth.COMPONENTS_EMAIL_BLOCK_TITLE_WELCOME} />;
};

const AuthCardTitleMemo = React.memo(AuthCardTitle);

export default AuthCardTitleMemo;
