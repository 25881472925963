import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { generateAddressRechargeURL } from '@/features/recharges/actions';
import type { HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';

import useRechargeAddress from './useRechargeAddress';

export type RechargeAddressActionUnavailabilityReason = 'no-data';

export interface UseRechargeAddressActions {
  generateRechargeAddressURL: HookAction<[], string, RechargeAddressActionUnavailabilityReason>;
}

export default function useRechargeAddressActions(rechargeId: string | undefined): UseRechargeAddressActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useRechargeAddress(rechargeId);

  const unavailabilityReason = !data.data ? 'no-data' : undefined;

  const isGenerating = useActionPending(generateAddressRechargeURL);
  const generateRechargeAddressURLAction: UseRechargeAddressActions['generateRechargeAddressURL']['act'] = useCallback(
    () => withExtractDataDispatch(generateAddressRechargeURL)(someOrFail(rechargeId)),
    [rechargeId, withExtractDataDispatch],
  );

  const generateRechargeAddressURLHook: UseRechargeAddressActions['generateRechargeAddressURL'] = useMemo(
    () => ({
      act: generateRechargeAddressURLAction,
      available: !!data,
      inAction: isGenerating,
      unavailabilityReason,
    }),
    [generateRechargeAddressURLAction, data, isGenerating, unavailabilityReason],
  );

  return {
    generateRechargeAddressURL: generateRechargeAddressURLHook,
  };
}
