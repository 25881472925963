import { Col, Row } from 'antd';
import React from 'react';

import type { PageLayoutProps } from './types';
import type { PropsWithChildren } from 'react';

const pageStyle = {
  paddingTop: '20px',
};

const SmallColl: React.FC<PropsWithChildren> = ({ children }) => (
  <Col xs={24} sm={24} md={20} lg={16} xl={16}>
    {children}
  </Col>
);

const BigColl: React.FC<PropsWithChildren> = ({ children }) => (
  <Col xs={24} sm={24} md={24} lg={22} xl={20}>
    {children}
  </Col>
);

const PageLayout = React.forwardRef<HTMLDivElement, PropsWithChildren<PageLayoutProps>>(function PageLayout(
  { pageSize = 'small', children },
  ref,
) {
  return pageSize === 'full' ? (
    children
  ) : (
    <Row justify="center" style={pageStyle} ref={ref}>
      {pageSize === 'small' && <SmallColl>{children}</SmallColl>}
      {pageSize === 'big' && <BigColl>{children}</BigColl>}
    </Row>
  );
});

export default PageLayout;
