import BigNumber from 'bignumber.js';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

export interface UseLocaleSettingsType {
  decimalSeparator: string;
  groupSeparator: string;
  formatDateTime: (value?: Parameters<Intl.DateTimeFormat['format']>[0] | string) => string;
  formatBigNumber: (value: BigNumber, fraction?: number) => string;
}

const trailingZeroRegExp = /(\d+)\.0*$|(\d*\.*\d*?)(\.*0*)$/gm;

export default function useLocaleSettings(): UseLocaleSettingsType {
  const { formatNumberToParts, formatDate, formatTime } = useIntl();
  const decimalSeparator = useMemo(
    () => formatNumberToParts(1.1).find(({ type }: Intl.NumberFormatPart) => type === 'decimal')?.value || '.',
    [formatNumberToParts],
  );
  const groupSeparator = useMemo(
    () => formatNumberToParts(1111).find(({ type }: Intl.NumberFormatPart) => type === 'group')?.value || ' ',
    [formatNumberToParts],
  );
  const formatDateTime = useCallback(
    (value: Parameters<Intl.DateTimeFormat['format']>[0] | string) =>
      !value
        ? ''
        : `${formatDate(value)} ${formatTime(value, { second: 'numeric', hour: 'numeric', minute: 'numeric' })}`,
    [formatDate, formatTime],
  );
  const formatBigNumber = useCallback(
    (value: BigNumber, fraction?: number) =>
      (fraction
        ? value.toFormat(fraction, { ...BigNumber.config().FORMAT, decimalSeparator, groupSeparator })
        : value.toFormat({ ...BigNumber.config().FORMAT, decimalSeparator, groupSeparator })
      ).replace(trailingZeroRegExp, '$1$2'),

    [decimalSeparator, groupSeparator],
  );

  return {
    decimalSeparator,
    groupSeparator,
    formatDateTime,
    formatBigNumber,
  };
}
