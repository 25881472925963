import { Form, Select } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureReports } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import ReportStatusView from '../ReportStatusView';

import type { ReportStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const options = enumValues(ReportStatusAPIModel).map((code) => ({
  value: code,
  label: <ReportStatusView value={code} />,
}));

const ReportStatusFilterItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: ReportStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_FILTER_STATUS_LABEL} />}
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      placeholder={<FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_FILTER_STATUS_PLACEHOLDER} />}
      disabled={readonly}
      mode="multiple"
      options={options}
      allowClear
    />
  </Form.Item>
);

const ReportStatusFilterItem = React.memo(ReportStatusFilterItemRaw) as typeof ReportStatusFilterItemRaw;

export default ReportStatusFilterItem;
