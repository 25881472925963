// eslint-disable-next-line @eslint-community/eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/no-unused-vars */
export const requiredParamsParser =
  <Key extends string>(keys: readonly Key[]) =>
  <K extends (typeof keys)[number]>(params: Partial<Record<K, string | undefined>>): Record<K, string> | undefined =>
    keys.reduce(
      (result, k) => {
        const key = k as K;
        if (!result) {
          return undefined;
        }
        const value = params[key];
        return !value || typeof value !== 'string' ? undefined : { ...result, [key]: value };
      },
      // eslint-disable-next-line @typescript-eslint/prefer-reduce-type-parameter
      {} as Record<K, string> | undefined,
    );

export const optionalParamsParser =
  <Key extends string>(keys: readonly Key[]) =>
  <K extends (typeof keys)[number]>(
    params: Partial<Record<K, string | undefined>>,
  ): Partial<Record<K, string | undefined>> =>
    params;
