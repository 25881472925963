import { Select, Tag, Tooltip } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { SupportedQRListProps } from './types';
import type React from 'react';

const SupportedQRList: React.FC<SupportedQRListProps> = ({
  'data-test': dataTest,
  value: selected,
  className,
  style,
}) => {
  const options = useMemo(
    () =>
      selected?.map((blockchain) => ({
        value: blockchain,
        label: <BlockchainLabel mode="medium" withNetworkMark bt={blockchain} />,
        search: `label`,
      })) ?? [],
    [selected],
  );
  return (
    <Select
      data-test={dataTest}
      className={className}
      style={style}
      value={selected}
      mode="tags"
      maxTagCount="responsive"
      maxTagPlaceholder={(v) => (
        <Tooltip
          overlayInnerStyle={{ backgroundColor: '#EEE' }}
          title={
            <span>
              {v.map(({ label }) => (
                <>
                  <Tag>{label}</Tag>{' '}
                </>
              ))}
            </span>
          }
        >
          +{v.length}
        </Tooltip>
      )}
      placeholder={
        <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_SUPPORTED_QR_PLACEHOLDER} />
      }
      disabled
      options={options}
      optionFilterProp="search"
    />
  );
};

export default SupportedQRList;
