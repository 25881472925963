import { useAppLoadableData } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchChurnRate } from '@/features/subscription-statistics/actions';
import { makeSelectSubscriptionChurnRate } from '@/features/subscription-statistics/selectors';

const dataSelector = makeSelectSubscriptionChurnRate();
const dataFetchingSelector = makeSelectPending(fetchChurnRate);
const fetchFactory = (force: boolean) => fetchChurnRate({ force });

export default function useChurnRates() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
