import { useContext, useEffect } from 'react';

import { useAppSelector } from '@/app/hooks';
import { PageLoading } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useAuthActions } from '@/features/auth/hooks';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useSingleRun } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthToken = makeSelectAuthToken();

const withWeb3AuthConsistencyGuard = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthConsistencyGuard',
  )((props) => {
    const { logout } = useAuthActions();
    const { withSingleRun, inProgress } = useSingleRun(true);

    const { web3Auth, initialized: web3AuthInitialized } = useContext(Web3AuthContext);
    const jwtState = useAppSelector(selectAuthToken);

    const isJWTActual = !jwtState.isDirty;
    const isAddressConsistent =
      !isJWTActual
      || !web3AuthInitialized
      || !web3Auth?.isAuthorized()
      || isSameAddress(jwtState.data?.info.address, web3Auth.state?.address);

    useEffect(() => {
      if (web3Auth?.isAuthorized() && !isAddressConsistent) {
        if (logout.available && !logout.inAction) {
          if (!inProgress) {
            withSuppressPromise(withSingleRun(logout.act))(true);
          }
        }
      }
    }, [
      inProgress,
      isAddressConsistent,
      logout.act,
      logout.available,
      logout.inAction,
      web3Auth,
      web3Auth?.state,
      withSingleRun,
    ]);

    if (!isAddressConsistent && inProgress) {
      return (
        <MessageLayout>
          <PageLoading type="withAuthorizationConsistencyGuard" />
        </MessageLayout>
      );
    }
    return <Component {...props} />;
  });

export default withWeb3AuthConsistencyGuard;
