import { List, Typography } from 'antd';
import React from 'react';

import { OperationDeleteBrandingProfile, OperationUpdateBrandingProfileStatus } from '@/features/branding/components';
import BrandingProfileEditLink from '@/pages/settings/branding/profile-edit/components/BrandingProfileEditLink';
import BrandingProfileViewLink from '@/pages/settings/branding/profile-view/components/BrandingProfileViewLink';

import type { InactiveProfileContentProps } from './types';

const { Text } = Typography;

const InactiveProfileContent: React.FC<InactiveProfileContentProps> = ({ 'data-test': dataTest, profiles }) =>
  profiles?.length ? (
    <List
      style={{ width: '350px' }}
      dataSource={profiles}
      renderItem={({ id, name }) => (
        <li key={id} style={{ listStyle: 'disc' }}>
          <Text data-test={dataTest && `${dataTest}-profiles-${id}`} ellipsis strong>
            {name}
          </Text>
          &nbsp;
          <BrandingProfileViewLink data-test={dataTest && `${dataTest}-profiles-${id}-viewOp`} value={id} mode="icon" />
          &nbsp;
          <BrandingProfileEditLink data-test={dataTest && `${dataTest}-profiles-${id}-editOp`} value={id} mode="icon" />
          &nbsp;
          <OperationUpdateBrandingProfileStatus
            data-test={dataTest && `${dataTest}-profiles-${id}-updateStatusOp`}
            profileId={id}
            mode="icon"
          />
          &nbsp;
          <OperationDeleteBrandingProfile
            data-test={dataTest && `${dataTest}-profiles-${id}-deleteOp`}
            profileId={id}
            mode="icon"
            confirmation
          />
        </li>
      )}
    />
  ) : null;

const InactiveProfileContentMemo = React.memo(InactiveProfileContent);

export default InactiveProfileContentMemo;
