import { useMemo } from 'react';

import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchGasWallet } from '@/features/gas-wallets/actions';
import { makeSelectGasWallet } from '@/features/gas-wallets/selectors';
import type { GasWallet } from '@/features/gas-wallets/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type LoadingStateWithDirty, storedDataLoaded } from '@/infrastructure/model';

const noData = storedDataLoaded<GasWallet>();

const fetchFactory = (
  bt: BlockchainTypeAPIModel,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<GasWallet>, Parameters<typeof fetchGasWallet>[0]> =>
  fetchGasWallet({ bt, force });

export default function useMerchantGasWallet(bt: BlockchainTypeAPIModel | undefined) {
  const selectPending = useMemo(() => makeSelectPending(fetchGasWallet, bt), [bt]);
  return useAppSingleData(fetchFactory, makeSelectGasWallet, noData, bt, selectPending);
}
