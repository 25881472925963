import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { subscriptionPlanAddLink } from '@/pages/subscriptions/routes';

import type { SubscriptionPlanAddLinkProps } from './types';

const SubscriptionPlanAddLink: React.FC<SubscriptionPlanAddLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(subscriptionPlanAddLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <PlusCircleOutlined />}
      title={title ?? <FormattedMessage id={I18nPageSubscriptions.PLAN_ADD_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const SubscriptionPlanAddLinkMemo = React.memo(SubscriptionPlanAddLink);

export default SubscriptionPlanAddLinkMemo;
