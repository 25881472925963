import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { SubscriptionsDocumentationBanner } from '@/features/subscriptions/components';
import SubscriptionPlanLink from '@/pages/subscriptions/plan-view/components/SubscriptionPlanLink';

import { SubscriptionPlansBreadcrumb, SubscriptionPlansCard, SubscriptionPlansTableOperations } from './components';

import type React from 'react';

const dataTest: string | undefined = 'plans';

const SubscriptionPlansPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<SubscriptionPlansBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test={dataTest && `${dataTest}-kyb-banner`} PageProps={{ pageSize: 'big' }} />
    <SubscriptionsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <SubscriptionPlansCard
      data-test={dataTest && `${dataTest}-plans`}
      SubscriptionPlanLink={SubscriptionPlanLink}
      Operations={SubscriptionPlansTableOperations}
    />
  </PageContainer>
);

export default SubscriptionPlansPage;
