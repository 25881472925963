import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import { fetchAssets } from '@/features/dictionary/blockchain/actions';
import { makeSelectAsset } from '@/features/dictionary/blockchain/selectors';
import type { AssetWithNetwork } from '@/features/dictionary/blockchain/types';
import { makeSelectPending } from '@/features/global/selectors';
import { mapStoredState, storedDataLoaded } from '@/infrastructure/model';

import useListBlockchains from './useListBlockchains';

export type UseAsset = UseAppSingleData<AssetWithNetwork, unknown>;

const noData = storedDataLoaded<AssetWithNetwork>();

const fetchFactory = (_: unknown, force: boolean) => fetchAssets({ force });

export default function useAsset(id: string | undefined): UseAsset {
  const state = useAppSingleData<string, AssetWithNetwork, unknown>(
    fetchFactory,
    makeSelectAsset,
    noData,
    id,
    useMemo(() => makeSelectPending(fetchAssets, id), [id]),
  );
  const blockchainsState = useListBlockchains();
  return useMemo(
    () => ({
      ...state,
      data: mapStoredState(state.data, (asset) => ({
        ...asset,
        net: blockchainsState.data.data?.find(({ blockchain }) => blockchain === asset.blockchain)?.net,
      })),
    }),
    [blockchainsState.data.data, state],
  );
}
