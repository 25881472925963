import { createReducer } from '@reduxjs/toolkit';

import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { collectableTaskLinksToId, extractCollectTaskId } from '@/features/collectable/utils';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import type { PushAddressLinkAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';
import { createNestedNormalizedListReducers, createNormalizedListReducers } from '@/infrastructure/model/list/reducers';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markCollectTaskDirty,
  markCollectScheduleDirty,
  storeMultipleCollectTaskSummary,
  storeCollectTask,
  storeCollectSchedule,
  markCollectTaskSummaryDirty,
  storeCollectTaskSummary,
  storeCollectTaskSummaryListData,
  markCollectTaskSummaryListDirty,
  markCollectableTransactionDirty,
  storeCollectableTransaction,
  storeCollectEntityProcessTransaction,
  markCollectEntityProcessTransactionDirty,
  storeMultipleCollectableTransaction,
  storeCollectTaskSummaryListParameters,
  storeCollectTasksForAddressesListData,
  markCollectTasksForAddressesListDirty,
  storeCollectTasksForAddressesListParameters,
} from './actions';
import { type CollectableState, defaultCollectTaskForAddressListState, defaultCollectTaskListState } from './types';

import type { Draft } from 'immer';

const initialState: CollectableState = {
  schedule: storedDirtyData,
  gwtTransactions: {},
  transactions: {},
  tasks: {
    columnState: {},
    list: defaultCollectTaskListState,
    byAddresses: {},
    summaries: {},
    entities: {},
  },
};

const {
  storeCollectableTransactionReducer,
  markCollectableTransactionDirtyReducer,
  storeMultipleCollectableTransactionReducer,
} = createSingleReducers(
  'CollectableTransaction',
  (state: Draft<CollectableState>) => state.transactions,
  (global, transactions) => ({ ...global, transactions }),
);

const { storeCollectEntityProcessTransactionReducer, markCollectEntityProcessTransactionDirtyReducer } =
  createSingleReducers(
    'CollectEntityProcessTransaction',
    (state: Draft<CollectableState>) => state.gwtTransactions,
    (global, gwtTransactions) => ({ ...global, gwtTransactions }),
  );

const { storeCollectTaskReducer, markCollectTaskDirtyReducer } = createSingleReducers(
  'CollectTask',
  (state: Draft<CollectableState>) => state.tasks.entities,
  (global, entities) => ({ ...global, tasks: { ...global.tasks, entities } }),
);

const {
  storeCollectTaskSummaryReducer,
  storeMultipleCollectTaskSummaryReducer,
  storeCollectTaskSummaryListParametersReducer,
  markCollectTaskSummaryDirtyReducer,
  storeCollectTaskSummaryListDataReducer,
  markCollectTaskSummaryListDirtyReducer,
} = createNormalizedListReducers(
  'CollectTaskSummary',
  (state: Draft<CollectableState>) => ({ ...state.tasks.list, columnState: state.tasks.columnState }),
  (global, list) => ({ ...global, tasks: { ...global.tasks, list } }),
  defaultCollectTaskListState,
  (state: Draft<CollectableState>) => state.tasks.summaries,
  (global, summaries) => ({ ...global, tasks: { ...global.tasks, summaries } }),
  extractCollectTaskId,
);

const {
  storeCollectTasksForAddressesListDataReducer,
  markCollectTasksForAddressesListDirtyReducer,
  storeCollectTasksForAddressesListParametersReducer,
} = createNestedNormalizedListReducers(
  'CollectTasksForAddresses',
  (state: Draft<CollectableState>, addresses: PushAddressLinkAPIModel[] | undefined) =>
    addresses ? state.tasks.byAddresses[collectableTaskLinksToId(addresses)] : undefined,
  (state, addresses, newListState) => ({
    ...state,
    tasks: {
      ...state.tasks,
      byAddresses: { ...state.tasks.byAddresses, [collectableTaskLinksToId(addresses)]: newListState },
    },
  }),
  (state) => state.tasks.columnState,
  (state, columnState) => ({ ...state, tasks: { ...state.tasks, columnState } }),
  defaultCollectTaskForAddressListState,
  extractCollectTaskId,
);

const { storeCollectScheduleReducer, markCollectScheduleDirtyReducer } = createLoadingDataReducers(
  'CollectSchedule',
  (state: Draft<CollectableState>) => state.schedule,
  (global, schedule) => ({ ...global, schedule }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markCollectTaskDirty, markCollectTaskDirtyReducer)
    .addCase(storeCollectTask, storeCollectTaskReducer)

    .addCase(markCollectTaskSummaryDirty, markCollectTaskSummaryDirtyReducer)
    .addCase(storeCollectTaskSummary, storeCollectTaskSummaryReducer)
    .addCase(storeMultipleCollectTaskSummary, storeMultipleCollectTaskSummaryReducer)
    .addCase(storeCollectTaskSummaryListParameters, storeCollectTaskSummaryListParametersReducer)
    .addCase(storeCollectTaskSummaryListData, storeCollectTaskSummaryListDataReducer)
    .addCase(markCollectTaskSummaryListDirty, markCollectTaskSummaryListDirtyReducer)

    .addCase(storeCollectTasksForAddressesListData, storeCollectTasksForAddressesListDataReducer)
    .addCase(markCollectTasksForAddressesListDirty, markCollectTasksForAddressesListDirtyReducer)
    .addCase(storeCollectTasksForAddressesListParameters, storeCollectTasksForAddressesListParametersReducer)

    .addCase(markCollectableTransactionDirty, markCollectableTransactionDirtyReducer)
    .addCase(storeCollectableTransaction, storeCollectableTransactionReducer)
    .addCase(storeMultipleCollectableTransaction, storeMultipleCollectableTransactionReducer)

    .addCase(markCollectEntityProcessTransactionDirty, markCollectEntityProcessTransactionDirtyReducer)
    .addCase(storeCollectEntityProcessTransaction, storeCollectEntityProcessTransactionReducer)

    .addCase(markCollectScheduleDirty, markCollectScheduleDirtyReducer)
    .addCase(storeCollectSchedule, storeCollectScheduleReducer)

    .addCase(notifyNetworkUpdated, (state) => ({
      ...initialState,
      tasks: {
        ...initialState.tasks,
        columnState: state.tasks.columnState,
        list: {
          ...initialState.tasks.list,
          filter: state.tasks.list.filter,
          sortBy: state.tasks.list.sortBy,
        },
      },
    }))
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );
});

export default reducer;
