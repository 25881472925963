import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import type { DonationTransactionFilterPredicate } from '@/features/donations/types';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { DonationTransactionFromAddressFilterItem } from './components';

import type { DonationTransactionFilterFormProps } from './types';

const fields = [
  nameof<DonationTransactionFilterPredicate>('broadcastAtRange'),
  nameof<DonationTransactionFilterPredicate>('fromAddressLike'),
  nameof<DonationTransactionFilterPredicate>('assetIn'),
  nameof<DonationTransactionFilterPredicate>('btIn'),
];

const broadcastAtRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_DONATION_TRANSACTION_FILTER_BROADCAST_AT_TO_LABEL} />,
  },
  from: {
    label: (
      <FormattedMessage id={I18nFeatureDonations.COMPONENTS_DONATION_TRANSACTION_FILTER_BROADCAST_AT_FROM_LABEL} />
    ),
  },
};

const DonationTransactionFilterForm: React.FC<DonationTransactionFilterFormProps> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<DonationTransactionFilterPredicate> fields={fields} {...props}>
      <DateTimeRangeItem<DonationTransactionFilterPredicate>
        data-test={dataTest && `${dataTest}-broadcastAt`}
        name={nameof<DonationTransactionFilterPredicate>('broadcastAtRange')}
        messages={broadcastAtRangeMessages}
        readonly={readonly}
        required={false}
        relativeUpdateKey="broadcastAtRangeRelative"
      />
      <DonationTransactionFromAddressFilterItem
        data-test={dataTest && `${dataTest}-fromAddress`}
        name={nameof<DonationTransactionFilterPredicate>('fromAddressLike')}
        readonly={readonly}
        required={false}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<DonationTransactionFilterPredicate>('btIn')}
        multiselect
        network={network}
        readonly={readonly}
        required={false}
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<DonationTransactionFilterPredicate>('assetIn')}
        multiselect
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const DonationTransactionFilterFormMemo = React.memo(DonationTransactionFilterForm);

export default DonationTransactionFilterFormMemo;
