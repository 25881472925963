import { EyeOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsBrandingProfileViewLink } from '@/pages/settings/routes';

import type { BrandingProfileViewLinkProps } from './types';

const BrandingProfileViewLink: React.FC<BrandingProfileViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
  value,
}) => {
  const navigate = useNavigate();

  const doOpen = useCallback(() => navigate(settingsBrandingProfileViewLink(value)), [navigate, value]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <EyeOutlined />}
      title={title ?? <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_VIEW_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const BrandingProfileViewLinkMemo = React.memo(BrandingProfileViewLink);

export default BrandingProfileViewLinkMemo;
