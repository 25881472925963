import { Select } from 'antd';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { InView } from 'react-intersection-observer';

import FormattedMessage from '@/components/FormattedMessage';
import { I18nComponents } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import type { Func } from '@/infrastructure/utils/ts';

import type React from 'react';

const { Option } = Select;

const LOADER_KEY = 'observer-bbb2e3d0-a02b-463b-aa37-4988b206cd72';

interface UseLoaderType {
  LoadingOption: React.ReactElement | null;
}

const useLoader = ({
  fetchMore,
  loading,
  hasMore,
}: {
  fetchMore: Func;
  loading: boolean;
  hasMore: boolean;
}): UseLoaderType => {
  const previousLoading = useRef(loading);
  const [loadMore, , resetLoadMore, requestLoadMore] = useSubmitting(false);
  const onLoadMore = useCallback(
    (inView: boolean) => {
      if (inView) {
        requestLoadMore();
      }
    },
    [requestLoadMore],
  );
  useEffect(() => {
    if (loadMore && !loading) {
      fetchMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMore, fetchMore]);
  useEffect(() => {
    // data has been reloaded
    if (previousLoading.current && !loading) {
      resetLoadMore();
    }
    previousLoading.current = loading;
  }, [loading, resetLoadMore]);

  const LoadingOption = useMemo(
    () =>
      hasMore && !loadMore && !loading ? (
        <Option key={LOADER_KEY} disabled>
          <InView onChange={onLoadMore}>
            <FormattedMessage id={I18nComponents.LAZY_LOAD_SELECT_LOADING} tagName="span" />
          </InView>
        </Option>
      ) : null,
    [hasMore, loadMore, loading, onLoadMore],
  );
  return { LoadingOption };
};

export default useLoader;
