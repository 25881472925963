import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import { DashboardPageRoutes, dashboardLink } from './routes';
import SummaryPage from './summary';

import type React from 'react';

export const DashboardDefaultRoute: React.FC = () => <Navigate to={dashboardLink()} replace />;

const DashboardPages: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={DashboardPageRoutes.SUMMARY} Component={SummaryPage} />
        <Route path="*" Component={DashboardDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const DashboardPagesHOC: React.FC = withBasicPageGuard(DashboardPages);

export default (
  <Route key={AppPageRoutes.DASHBOARD} path={`${AppPageRoutes.DASHBOARD}/*`} Component={DashboardPagesHOC} />
);
