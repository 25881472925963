import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';

import type {
  SubscriptionChurnRate,
  SubscriptionGrowthRate,
  SubscriptionRevenue,
  PlanChurnRate,
} from '@/features/subscription-statistics/types';
import type { OverallPlanChurnRateAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { asType } from '@/infrastructure/utils/ts';

import type { Dayjs } from 'dayjs';

const monthsNumber = 12;
const generateLabels = () =>
  Array.from({ length: monthsNumber }, (_, idx) => idx)
    .reduce<[Dayjs, ...Dayjs[]]>(
      (result) => [result[0].clone().subtract(2, 'day').startOf('month'), ...result],
      [dayjs().startOf('month')],
    )
    .map((date) => date.format('YYYY-MM'));

export const mockMonthlyGrowthRate = (allPlans: string[]): SubscriptionGrowthRate => {
  const labels = generateLabels();
  const plans = allPlans.length ? allPlans.slice(0, 5) : ['TestPlan'];

  const perPlan = plans.map(() => Array.from({ length: 12 }, () => Math.floor(Math.random() * 42) - 10));

  const median = Array.from({ length: monthsNumber }, (_, idx) => {
    const rates = perPlan.map((months) => months[idx]).sort((v1 = 0, v2 = 0) => v1 - v2);
    return Math.floor(
      plans.length / 2 === 0
        ? ((rates[plans.length / 2 - 1] ?? 0) + (rates[plans.length / 2] ?? 0)) / 2
        : rates[Math.floor(plans.length / 2)],
    );
  });
  const mean = Array.from(
    { length: monthsNumber },
    (_, idx) =>
      Math.floor((perPlan.map((months) => months[idx]).reduce((v1 = 0, v2 = 0) => v1 + v2) / plans.length) * 100) / 100,
  );

  return asType<SubscriptionGrowthRate>({
    perPlan: plans.map((planId, planIdx) => ({
      planId,
      perMonth: perPlan[planIdx]?.map((percentage, monthIdx) => ({ month: labels[monthIdx], percentage })) ?? [],
    })),
    mean: mean.map((percentage, monthIdx) => ({ month: labels[monthIdx], percentage })),
    median: median.map((percentage, monthIdx) => ({ month: labels[monthIdx], percentage })),
  });
};

export const mockMonthlyRevenue = (allPlans: string[], asset: string): SubscriptionRevenue => {
  const labels = generateLabels();
  const plans = allPlans.length ? allPlans.slice(0, 5) : ['TestPlan'];

  const perPlan = plans.map(() => Array.from({ length: 12 }, () => Math.floor(Math.random() * 1000)));
  const total = Array.from({ length: 12 }, (_v, idx) =>
    plans.map((_, planIdx) => perPlan[planIdx]?.[idx]).reduce((v1 = 0, v2 = 0) => v1 + v2),
  );

  const median = Array.from({ length: monthsNumber }, (_, idx) => {
    const revenues = perPlan.map((months) => months[idx] ?? 0).sort((v1 = 0, v2 = 0) => v1 - v2);
    return Math.floor(
      plans.length / 2 === 0
        ? ((revenues[plans.length / 2 - 1] ?? 0) + (revenues[plans.length / 2] ?? 0)) / 2
        : (revenues[Math.floor(plans.length / 2)] ?? 0),
    );
  });
  const mean = Array.from(
    { length: monthsNumber },
    (_, idx) =>
      Math.floor((perPlan.map((months) => months[idx]).reduce((v1 = 0, v2 = 0) => v1 + v2) / plans.length) * 100) / 100,
  );

  return asType<SubscriptionRevenue>({
    perPlan: plans.map((planId, planIdx) => ({
      planId,
      perMonth: (perPlan[planIdx] ?? []).map((value, monthIdx) => ({
        month: labels[monthIdx],
        amount: { value: BigNumber(value), asset },
      })),
    })),
    total: total.map((value, idx) => ({
      month: labels[idx],
      amount: { value: BigNumber(value), asset },
    })),
    median: median.map((value, idx) => ({
      month: labels[idx],
      amount: { value: BigNumber(value), asset },
    })),
    mean: mean.map((value, idx) => ({
      month: labels[idx],
      amount: { value: BigNumber(value), asset },
    })),
  });
};

export const mockChurnRate = (allPlans: string[]): SubscriptionChurnRate => {
  const plans = allPlans.length ? allPlans.slice(0, 5) : ['TestPlan'];
  const chargesNum = 10;
  const perPlan: PlanChurnRate[] = plans.map((planId) => {
    const days = Math.floor(Math.random() * (30 - 10) + 10);
    return {
      planId,
      perCharge: Array.from({ length: chargesNum }, (_, idx) => ({
        chargesCount: idx + 1,
        percentage: Math.floor(Math.random() * 10),
        daysToTheCharge: idx * days,
      })),
    };
  });
  const median: OverallPlanChurnRateAPIModel[] = Array.from({ length: chargesNum }, (_, idx) => {
    const rates = perPlan.map(({ perCharge }) => perCharge[idx]?.percentage ?? 0).sort((v1, v2) => v1 - v2);
    return {
      chargesCount: idx + 1,
      percentage: Math.floor(
        plans.length / 2 === 0
          ? ((rates[plans.length / 2 - 1] ?? 0) + (rates[plans.length / 2] ?? 0)) / 2
          : rates[Math.floor(plans.length / 2)],
      ),
    };
  });
  const mean: OverallPlanChurnRateAPIModel[] = Array.from({ length: chargesNum }, (_, idx) => ({
    chargesCount: idx + 1,
    percentage: Math.floor(
      perPlan.map(({ perCharge }) => perCharge[idx]?.percentage ?? 0).reduce((v1, v2) => v1 + v2) / plans.length,
    ),
  }));

  return { mean, median, perPlan };
};
