import { css } from '@emotion/css';
import { theme } from 'antd';
import { useMemo } from 'react';

const useStyles = () => {
  const { token } = theme.useToken();
  return useMemo(
    () => ({
      card: css`
        margin: ${token.margin}px auto 0px;
        min-height: 550px;
        max-width: 800px;
        width: 95%;
        border-radius: 8px;
        display: flex;

        > div.ant-card-body {
          display: flex;
          flex: 1;
          flex-direction: column;
          align-items: center;
        }
      `,

      container: css`
        flex: 1;
        width: 100%;
      `,

      steps: css`
        padding: 25px;
      `,

      description: css`
        text-align: center;
      `,

      submit: css`
        align-self: flex-start;
      `,
    }),
    [token.margin],
  );
};

export default useStyles;
