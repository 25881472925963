import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import GasWalletsBreadcrumb from '@/pages/settings/company/gas-wallets/components/GasWalletsBreadcrumb';

import GasWalletLink from '../GasWalletLink';

import type { GasWalletBreadcrumbProps } from './types';

const GasWalletBreadcrumb: React.FC<GasWalletBreadcrumbProps> = ({
  items = [],
  bt,
  disabled,
  'data-test': dataTest,
}) => (
  <GasWalletsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessageWithMarkup id={I18nPageSettings.COMPANY_GAS_WALLET_BREADCRUMB_TITLE} values={{ bt }} />
        ) : (
          <GasWalletLink
            mode="text"
            value={bt}
            title={
              <FormattedMessageWithMarkup id={I18nPageSettings.COMPANY_GAS_WALLET_BREADCRUMB_TITLE} values={{ bt }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const GasWalletBreadcrumbMemo = React.memo(GasWalletBreadcrumb);

export default GasWalletBreadcrumbMemo;
