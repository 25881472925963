import debounce from 'lodash/debounce';
import toLower from 'lodash/toLower';
import { useCallback, useMemo, useRef } from 'react';

import type { ValueStore, ValueType } from '@/components/LazyLoadSelect/types';
import { useErrorSafeSubmitting, useStateMountSafe, useSubmitting } from '@/hooks';
import { withOnSuccess } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

interface State<T extends number | string = string> {
  key: string;
  input: string;
  hasMore: boolean;
  options: ValueType<T>[];
}

interface UseLazyLoadStateType<T extends number | string = string> {
  load: Func<[string]>;
  loadNextPage: Func;
  loading: boolean;
  state: State<T> | undefined;
  cache: ValueStore<T>;
  error: string | undefined;
}

const useLazyLoadState = <T extends number | string = string>({
  cacheKeyPrefix,
  fetchData,
  debounceTimeout = 500,
}: {
  cacheKeyPrefix?: string;
  fetchData: Func<[{ input: string; page: number }], { data: ValueType<T>[]; hasMore: boolean }>;
  debounceTimeout?: number;
}): UseLazyLoadStateType<T> => {
  const [requested, , resetRequested, markRequested] = useSubmitting(false);
  const { submitting: fetching, error, withSubmitting } = useErrorSafeSubmitting();

  const cache = useRef<ValueStore<T>>({});
  const [state, setState] = useStateMountSafe<State<T>>();
  const refreshState = useCallback(
    (input: string, cacheKey: string) => {
      setState({
        input,
        key: cacheKey,
        options: cache.current[cacheKey]?.values ?? [],
        hasMore: !cache.current[cacheKey] || !!cache.current[cacheKey].hasMore,
      });
    },
    [setState],
  );
  const loadData = useMemo(
    () =>
      withSubmitting(
        withOnSuccess(async (input: string, nextPage?: boolean) => {
          const normalized = input.trim();
          const cacheKey = toLower(cacheKeyPrefix ? `${cacheKeyPrefix}_${normalized}` : normalized);
          if (!cache.current[cacheKey] || (cache.current[cacheKey].hasMore && nextPage)) {
            const page = (cache.current[cacheKey]?.page ?? 0) + 1;
            const { data, hasMore } = await fetchData({ input, page });
            cache.current = {
              ...cache.current,
              [cacheKey]: { page, hasMore, values: [...(cache.current[cacheKey]?.values ?? []), ...data] },
            };
          }
          refreshState(normalized, cacheKey);
        }, resetRequested),
      ),
    [withSubmitting, resetRequested, cacheKeyPrefix, refreshState, fetchData],
  );
  const debounceFetcher = useMemo(() => debounce(loadData, debounceTimeout), [debounceTimeout, loadData]);
  const load = useCallback(
    async (inputValue: string, nextPage?: boolean) => {
      markRequested();
      return debounceFetcher(inputValue, nextPage);
    },
    [debounceFetcher, markRequested],
  );
  const loadNextPage = useCallback(async () => load(state?.input || '', true), [load, state?.input]);

  return { load, loadNextPage, state, cache: cache.current, loading: fetching || requested, error };
};

export default useLazyLoadState;
