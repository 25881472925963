import { PageContainer } from '@/components';
import { RechargesDocumentationBanner } from '@/features/recharges/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { SettlementViewLinkProps } from '@/pages/settlements/settlement-view/components';
import BaseSettlementViewLink from '@/pages/settlements/settlement-view/components/SettlementViewLink';

import { PayoutBatchCard, PayoutBatchViewBreadcrumb, PayoutCard, PayoutDestinationsCard } from './components';
import { withPayoutBatchPageParams } from './hocs';

import type React from 'react';

type PayoutViewPageProps = TestableProps & { pageParams: { payoutId: string; batchNum: number } };

const SettlementViewLink: React.FC<Omit<SettlementViewLinkProps, 'mode'>> = (props) => (
  <BaseSettlementViewLink {...props} mode="text" />
);

const PayoutViewPage: React.FC<PayoutViewPageProps> = ({ 'data-test': dataTest = 'payout', pageParams }) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <PayoutBatchViewBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        payoutId={pageParams.payoutId}
        batchNum={pageParams.batchNum}
        disabled
      />
    }
  >
    <RechargesDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <PayoutBatchCard data-test={dataTest && `${dataTest}-batch`} id={pageParams} SettlementLink={SettlementViewLink} />
    <PayoutDestinationsCard data-test={dataTest && `${dataTest}-destinations`} id={pageParams} />
    <PayoutCard data-test={dataTest && `${dataTest}-card`} payoutId={pageParams.payoutId} />
  </PageContainer>
);

export default withPayoutBatchPageParams(PayoutViewPage);
