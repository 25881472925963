import { ApiOutlined, BankOutlined, FormatPainterOutlined, LockOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';

import { SettingsTabType } from '@/pages/settings/components/SettingsPageContainer/types';

import type { SettingsTabIconProps } from './types';

const icons: Record<SettingsTabType, React.ReactNode> = {
  [SettingsTabType.company]: <BankOutlined />,
  [SettingsTabType.branding]: <FormatPainterOutlined />,
  [SettingsTabType.integration]: <ApiOutlined />,
  [SettingsTabType.permissions]: <LockOutlined />,
  [SettingsTabType.profile]: <UserOutlined />,
};

const SettingsTabIcon: React.FC<SettingsTabIconProps> = ({ value }) => <>{icons[value]}</>;

const SettingsTabIconMemo = React.memo(SettingsTabIcon);

export default SettingsTabIconMemo;
