import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Space, Tag } from 'antd';

import { FormattedMessage } from '@/components';
import OperationRefreshMerchantGasWallet from '@/features/gas-wallets/components/OperationRefreshMerchantGasWallet';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';
import type { GasWallet } from '@/features/gas-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withWalletLoading = <T extends TestableProps & { wallet: GasWallet }>(
  Component: React.ComponentType<Omit<T, 'wallet'> & { wallet: GasWallet }>,
): React.ComponentType<Omit<T, 'wallet'> & { bt: BlockchainTypeAPIModel }> =>
  namedHOC<Omit<T, 'wallet'> & { wallet: GasWallet }, Omit<T, 'wallet'> & { bt: BlockchainTypeAPIModel }>(
    Component,
    'WithWalletLoading',
  )((props) => {
    const { 'data-test': dataTest, bt } = props;
    const {
      data: { data: wallet, isDirty },
      loading,
    } = useMerchantGasWallet(bt);
    if (!wallet) {
      return isDirty || loading ? (
        <Tag color="success" data-test={dataTest}>
          <Space>
            <LoadingOutlined />
            <OperationRefreshMerchantGasWallet data-test={dataTest && `${dataTest}-opRefresh`} bt={bt} />
          </Space>
        </Tag>
      ) : (
        <Tag color="error" data-test={dataTest}>
          <Space>
            <WarningOutlined />
            <FormattedMessage id={I18nFeatureGasWallets.HOCS_BALANCE_LOADING_ERRORS_NO_WALLET_TITLE} tagName="span" />
            <OperationRefreshMerchantGasWallet data-test={dataTest && `${dataTest}-opRefresh`} bt={bt} />
          </Space>
        </Tag>
      );
    }
    return <Component {...props} wallet={wallet} />;
  });

const withWalletBalanceLoading = <T extends { wallet: GasWallet }>(
  Component: React.ComponentType<Omit<T, 'wallet'> & { wallet: GasWallet }>,
) => {
  const WalletComponent = withWalletLoading(Component);
  return namedHOC<Omit<T, 'wallet'> & { wallet: GasWallet }, T | (Omit<T, 'wallet'> & { bt: BlockchainTypeAPIModel })>(
    Component,
    'WithWalletBalanceLoading',
  )((props) => ('wallet' in props ? <Component {...props} /> : <WalletComponent {...props} />));
};

export default withWalletBalanceLoading;
