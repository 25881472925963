import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';

import type { SettlementsBreadcrumbProps } from './types';

const SettlementsBreadcrumb: React.FC<SettlementsBreadcrumbProps> = ({ 'data-test': dataTest, items = [] }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[{ title: <FormattedMessage id={I18nPageSettlements.SETTLEMENTS_COMPONENTS_BREADCRUMB} /> }, ...items]}
  />
);

const SettlementsBreadcrumbMemo = React.memo(SettlementsBreadcrumb);

export default SettlementsBreadcrumbMemo;
