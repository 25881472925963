import { Descriptions } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent, ReadonlyDateTime } from '@/components';
import { AssetAmount, TransactionLink } from '@/features/dictionary/blockchain/components';
import PayoutBatchStatusView from '@/features/payouts/components/PayoutBatchStatusView';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutBatchViewProps } from './types';

const PayoutBatchView: React.FC<PayoutBatchViewProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
  SettlementLink,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
}) => (
  <Descriptions
    style={style}
    className={className}
    contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
    title={<PayoutBatchStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} />}
    column={columns}
  >
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_ORDER} />}>
      <ReadonlyComponent value={data.num} data-test={dataTest && `${dataTest}-num`} copyable={false} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_PAYOUT_TOTAL_AMOUNT} />}>
      <AssetAmount
        data-test={dataTest && `${dataTest}-amount`}
        value={data.amount.value}
        assetId={data.amount.asset}
        withCurrency
        withBlockchainMark
        currencyMode="long"
      />
    </Descriptions.Item>
    {data.hash && (
      <Descriptions.Item
        label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_TX} />}
        contentStyle={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }}
      >
        <TransactionLink data-test={dataTest && `${dataTest}-transaction`} assetId={data.amount.asset} tx={data.hash} />
      </Descriptions.Item>
    )}
    {data.broadcastAt && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_SENT_AT} />}>
        <ReadonlyDateTime value={data.broadcastAt} data-test={dataTest && `${dataTest}-broadcastAt`} />
      </Descriptions.Item>
    )}
    {data.settlementId && SettlementLink && (
      <Descriptions.Item label={<FormattedMessage id={I18nFeaturePayouts.LABELS_ROW_TITLE_BATCH_SETTLEMENT} />}>
        <SettlementLink value={data.settlementId} data-test={dataTest && `${dataTest}-settlement`} />
      </Descriptions.Item>
    )}
  </Descriptions>
);

const PayoutBatchViewMemo = React.memo(PayoutBatchView) as typeof PayoutBatchView;

export default PayoutBatchViewMemo;
