import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { I18nFeatureOnboard } from '@/generated/i18n/i18n';
import { OnboardingStep } from '@/pages/onboard/main/types';

import OnboardingContainer from '../OnboardingContainer';

import type { CongratulationsStepProps } from './types';
import type { OnboardingContainerProps } from '../OnboardingContainer/types';

const CongratulationsStepRaw: React.FC<CongratulationsStepProps> = ({ 'data-test': dataTest, settings, onSubmit }) => {
  const submit = useMemo<OnboardingContainerProps['submit']>(
    () => ({
      onAct: onSubmit,
      title: <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_STEPS_CONGRATULATIONS_SUBMIT_TITLE} tagName="span" />,
    }),
    [onSubmit],
  );

  return (
    <OnboardingContainer
      data-test={dataTest && `${dataTest}-container`}
      selected={OnboardingStep.congratulations}
      submit={submit}
    >
      <FormattedMessageWithMarkup
        id={I18nFeatureOnboard.COMPONENTS_STEPS_CONGRATULATIONS_CONTENT}
        values={{ email: settings.email }}
      />
    </OnboardingContainer>
  );
};

const CongratulationsStep = React.memo(CongratulationsStepRaw) as typeof CongratulationsStepRaw;

export default CongratulationsStep;
