import { Alert, Tag } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, WarningTooltip } from '@/components';
import { withBlockchainType } from '@/features/dictionary/blockchain/hocs';
import { useMerchantWalletDeployment } from '@/features/merchant-wallets/hooks';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';

import OperationDeployMerchantWallet from '../OperationDeployMerchantWallet';
import OperationRefreshMerchantWalletDeployment from '../OperationRefreshMerchantWalletDeployment';

import type { MerchantWalletDeploymentStatusBannerProps } from './types';

const MerchantWalletDeploymentStatusBanner: React.FC<MerchantWalletDeploymentStatusBannerProps> = ({
  'data-test': dataTest,
  bt,
  mode,
  style,
  className,
}) => {
  const { data: wallet } = useMerchantWalletDeployment(bt);
  const [tooltipVisible, setTooltipVisible] = useStateMountSafe<boolean | undefined>(undefined);
  const updateTooltipVisibility = useCallback(
    (dialogVisible: boolean) => setTooltipVisible(dialogVisible ? false : undefined),
    [setTooltipVisible],
  );
  const details = useMemo(
    () => (
      <FormattedMessageWithMarkup
        id={I18nFeatureMerchantWallets.COMPONENTS_STATUS_BANNER_DESCRIPTION}
        values={{
          bt,
          deploy: (text: React.ReactNode) => (
            <OperationDeployMerchantWallet
              data-test={dataTest && `${dataTest}-opDeploy`}
              bt={bt}
              mode="link"
              title={text}
              onVisibleChange={updateTooltipVisibility}
            />
          ),
          check: (text: React.ReactNode) => (
            <OperationRefreshMerchantWalletDeployment
              data-test={dataTest && `${dataTest}-opCheck`}
              bt={bt}
              mode="link"
              title={text}
            />
          ),
        }}
      />
    ),
    [bt, dataTest, updateTooltipVisibility],
  );
  if (!wallet.data || wallet.data.deployed) {
    return null;
  }
  return mode === 'tag' ? (
    <WarningTooltip type="error" title={details} open={tooltipVisible}>
      <Tag data-test={dataTest} color="error" className={className} style={{ ...style, marginRight: 0 }}>
        <FormattedMessage id={I18nFeatureMerchantWallets.COMPONENTS_STATUS_BANNER_TAG} />
      </Tag>
    </WarningTooltip>
  ) : (
    <Alert
      data-test={dataTest}
      className={className}
      style={style}
      message={<FormattedMessage id={I18nFeatureMerchantWallets.COMPONENTS_STATUS_BANNER_TITLE} />}
      description={details}
      type="error"
      showIcon
    />
  );
};

const MerchantWalletDeploymentStatusBannerHOCed = withBlockchainType<MerchantWalletDeploymentStatusBannerProps>(
  MerchantWalletDeploymentStatusBanner,
);

const MerchantWalletDeploymentStatusBannerMemo = React.memo(
  MerchantWalletDeploymentStatusBannerHOCed,
) as typeof MerchantWalletDeploymentStatusBannerHOCed;

export default MerchantWalletDeploymentStatusBannerMemo;
