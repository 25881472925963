import { FormOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { usePayoutActions } from '@/features/payouts/hooks';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { settlementsPayoutEditLink } from '@/pages/settlements/routes';

import type { PayoutEditLinkProps } from './types';

const PayoutEditLink: React.FC<PayoutEditLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => {
  const { update: updateAction } = usePayoutActions(value);
  const disabledMessage =
    updateAction.unavailabilityReason === 'invalid-status' ? (
      <FormattedMessage id={I18nPageSettlements.PAYOUT_EDIT_COMPONENTS_LINK_MESSAGES_INVALID_STATUS} />
    ) : null;
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(settlementsPayoutEditLink(value)), [openForm, value]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <FormOutlined />}
      title={
        title ?? <FormattedMessage id={I18nPageSettlements.PAYOUT_EDIT_COMPONENTS_LINK_TITLE} values={{ id: value }} />
      }
      disabled={!!disabledMessage}
      disabledMessage={disabledMessage}
    />
  );
};

const PayoutEditLinkMemo = React.memo(PayoutEditLink);

export default PayoutEditLinkMemo;
