import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { relogin } from '@/features/auth/actions';
import { withAPICall, withFetchAllDataOrThrow } from '@/infrastructure/api';
import { storedDataError } from '@/infrastructure/model';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { loadingSliceStateToFullData } from '@/infrastructure/model/full/utils';
import { callWithThunkError } from '@/infrastructure/utils/redux';

import { requestDeleteCompanyUser, requestAddCompanyUser, requestUpdateCompanyKey } from './api';
import {
  type OnboardRequestModel,
  queryCompanies,
  queryCompanyUsers,
  requestCreateCompany,
  requestUpdateCompanyName,
} from './api';
import { makeSelectActiveCompany, makeSelectCompanies, makeSelectCompanyUsers } from './selectors';
import { NAMESPACE } from './types';

import type { Company, CompanyUser, CompanyWithOwner, NewCompany, UpdateCompanyName } from './types';
import type { Address } from 'viem';

export const { storeCompanies, markCompaniesDirty } = createLoadingDataActions<Company[], 'Companies'>(
  NAMESPACE,
  'Companies',
);

const companiesFetchLimit = pLimit(1);
export const fetchCompanies = createAppAsyncThunk(
  `${NAMESPACE}/fetchCompanies`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    companiesFetchLimit(async () => {
      const saved = makeSelectCompanies()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCompanies, 'Unable to fetch companies')({ signal });
      dispatch(storeCompanies(data));

      return makeSelectCompanies()(getState());
    }),
);

export const createCompany = createAppAsyncThunk(
  `${NAMESPACE}/createCompany`,
  async ({ emailToken, ...payload }: NewCompany, { dispatch, signal }) => {
    const requestData: OnboardRequestModel = {
      ...payload,
      ...(emailToken ? { emailToken, emailTokenProvider: 'auth0' } : {}),
    };
    const data = await withAPICall(requestCreateCompany, 'Unable to create company')(requestData, { signal });
    if (data.error) {
      return storedDataError<Company>(data.error);
    }
    if (data.data) {
      await dispatch(fetchCompanies({ force: true })).unwrap();
      await dispatch(relogin({ companyId: data.data.id }));
    }
    return data;
  },
);

export const updateCompanyName = createAppAsyncThunk(
  `${NAMESPACE}/updateCompanyName`,
  async (payload: UpdateCompanyName, { dispatch, getState, signal }) => {
    const data = await withAPICall(requestUpdateCompanyName, 'Unable to update company name')(payload, { signal });
    if (data.error) {
      return storedDataError<CompanyWithOwner>(data.error);
    }
    await callWithThunkError(async () => dispatch(fetchCompanies({ force: true })).unwrap());
    return makeSelectActiveCompany()(getState());
  },
);

export const updateCompanyKey = createAppAsyncThunk(
  `${NAMESPACE}/updateCompanyKey`,
  async ({ key }: { key: string }, { dispatch, getState, signal }) => {
    const data = await withAPICall(requestUpdateCompanyKey, 'Unable to update company key')(key, { signal });
    if (data.error) {
      return storedDataError<CompanyWithOwner>(data.error);
    }
    await callWithThunkError(async () => dispatch(fetchCompanies({ force: true })).unwrap());
    return makeSelectActiveCompany()(getState());
  },
);

export const { storeCompanyUsers, markCompanyUsersDirty } = createLoadingDataActions<CompanyUser[], 'CompanyUsers'>(
  NAMESPACE,
  'CompanyUsers',
);

const usersFetchLimit = pLimit(1);
export const fetchCompanyUsers = createAppAsyncThunk(
  `${NAMESPACE}/fetchCompanyUsers`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    usersFetchLimit(async () => {
      const saved = makeSelectCompanyUsers()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(
        withFetchAllDataOrThrow((page) => () => queryCompanyUsers({ page }, { signal })),
        'Unable to fetch users',
      )();
      dispatch(storeCompanyUsers(loadingSliceStateToFullData(data)));

      return makeSelectCompanyUsers()(getState());
    }),
);

export const addCompanyUser = createAppAsyncThunk(
  `${NAMESPACE}/addCompanyUser`,
  async ({ address }: { address: Address }, { dispatch, signal }) => {
    const data = await withAPICall(requestAddCompanyUser, 'Unable to add user')(address, { signal });
    if (data.error) {
      return storedDataError<CompanyUser[]>(data.error);
    }
    return callWithThunkError(() => dispatch(fetchCompanyUsers({ force: true })).unwrap());
  },
);

export const removeCompanyUser = createAppAsyncThunk(
  `${NAMESPACE}/removeCompanyUser`,
  async ({ address }: { address: Address }, { dispatch, signal }) => {
    const data = await withAPICall(requestDeleteCompanyUser, 'Unable to update user')(address, { signal });
    if (data.error) {
      return storedDataError<CompanyUser[]>(data.error);
    }
    return callWithThunkError(() => dispatch(fetchCompanyUsers({ force: true })).unwrap());
  },
);
