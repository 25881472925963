/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WithdrawalBatchBTCOutputInvoicePaymentAPIModel
 */
export interface WithdrawalBatchBTCOutputInvoicePaymentAPIModel {
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputInvoicePaymentAPIModel
     */
    invoiceId: string;
    /**
     * 
     * @type {number}
     * @memberof WithdrawalBatchBTCOutputInvoicePaymentAPIModel
     */
    sequenceNumber: number;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputInvoicePaymentAPIModel
     */
    script: string;
    /**
     * 
     * @type {string}
     * @memberof WithdrawalBatchBTCOutputInvoicePaymentAPIModel
     */
    hashToSign: string;
}

/**
 * Check if a given object implements the WithdrawalBatchBTCOutputInvoicePaymentAPIModel interface.
 */
export function instanceOfWithdrawalBatchBTCOutputInvoicePaymentAPIModel(value: object): boolean {
    if (!('invoiceId' in value)) return false;
    if (!('sequenceNumber' in value)) return false;
    if (!('script' in value)) return false;
    if (!('hashToSign' in value)) return false;
    return true;
}

export function WithdrawalBatchBTCOutputInvoicePaymentAPIModelFromJSON(json: any): WithdrawalBatchBTCOutputInvoicePaymentAPIModel {
    return WithdrawalBatchBTCOutputInvoicePaymentAPIModelFromJSONTyped(json, false);
}

export function WithdrawalBatchBTCOutputInvoicePaymentAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): WithdrawalBatchBTCOutputInvoicePaymentAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'invoiceId': json['invoiceId'],
        'sequenceNumber': json['sequenceNumber'],
        'script': json['script'],
        'hashToSign': json['hashToSign'],
    };
}

export function WithdrawalBatchBTCOutputInvoicePaymentAPIModelToJSON(value?: WithdrawalBatchBTCOutputInvoicePaymentAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'invoiceId': value['invoiceId'],
        'sequenceNumber': value['sequenceNumber'],
        'script': value['script'],
        'hashToSign': value['hashToSign'],
    };
}

