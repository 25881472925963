import { ApiOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsIntegrationLink } from '@/pages/settings/routes';

import type { CompanyIntegrationLinkProps } from './types';

const CompanyIntegrationLink: React.FC<CompanyIntegrationLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
}) => (
  <Link
    mode={mode}
    to={settingsIntegrationLink()}
    icon={<ApiOutlined />}
    data-test={dataTest}
    title={title || <FormattedMessage id={I18nPageSettings.INTEGRATION_COMPONENTS_LINK_TITLE} />}
  />
);

const CompanyIntegrationLinkMemo = React.memo(CompanyIntegrationLink);

export default CompanyIntegrationLinkMemo;
