import { Space } from 'antd';
import React from 'react';

import type { ReportScheduleViewProps } from '@/features/report-schedules/components';
import { ReportScheduleParametersView, ReportScheduleView } from '@/features/report-schedules/components';

import { withReportScheduleDataLoading } from './hocs';

const ReportScheduleViewCardRaw = withReportScheduleDataLoading<ReportScheduleViewProps>((props) => (
  <Space direction="vertical">
    <ReportScheduleView {...props} columns={1} />
    <ReportScheduleParametersView
      value={props.data.parameters}
      template={props.data.template}
      data-test={props['data-test']}
    />
  </Space>
));

const ReportScheduleViewCard = React.memo(ReportScheduleViewCardRaw);

export default ReportScheduleViewCard;
