import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import type { RechargeAddressFilterPredicate } from '@/features/recharges/types';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { RechargeCIdFilterItem, RechargeAddressFilterItem, RechargeDeployStatusFilterItem } from './components';

import type { RechargeAddressFilterFormProps } from './types';

const fields = [
  nameof<RechargeAddressFilterPredicate>('createdAtRange'),
  nameof<RechargeAddressFilterPredicate>('addressLike'),
  nameof<RechargeAddressFilterPredicate>('cidLike'),
  nameof<RechargeAddressFilterPredicate>('deployStatusIn'),
  nameof<RechargeAddressFilterPredicate>('assetIn'),
  nameof<RechargeAddressFilterPredicate>('btIn'),
];

const createdAtRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_RECHARGE_ADDRESS_FILTER_CREATED_AT_TO_LABEL} />,
  },
  from: {
    label: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_RECHARGE_ADDRESS_FILTER_CREATED_AT_FROM_LABEL} />,
  },
};

const RechargeAddressFilterForm: React.FC<RechargeAddressFilterFormProps> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<RechargeAddressFilterPredicate> fields={fields} {...props}>
      <DateTimeRangeItem<RechargeAddressFilterPredicate>
        data-test={dataTest && `${dataTest}-processAt`}
        name={nameof<RechargeAddressFilterPredicate>('createdAtRange')}
        messages={createdAtRangeMessages}
        readonly={readonly}
        required={false}
        relativeUpdateKey="createdAtRangeRelative"
      />
      <RechargeAddressFilterItem
        data-test={dataTest && `${dataTest}-address`}
        name={nameof<RechargeAddressFilterPredicate>('addressLike')}
        readonly={readonly}
        required={false}
      />
      <RechargeCIdFilterItem
        data-test={dataTest && `${dataTest}-cid`}
        name={nameof<RechargeAddressFilterPredicate>('cidLike')}
        readonly={readonly}
        required={false}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<RechargeAddressFilterPredicate>('btIn')}
        multiselect
        network={network}
        readonly={readonly}
        required={false}
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<RechargeAddressFilterPredicate>('assetIn')}
        multiselect
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
      />
      <RechargeDeployStatusFilterItem
        data-test={dataTest && `${dataTest}-deployStatus`}
        name={nameof<RechargeAddressFilterPredicate>('deployStatusIn')}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const RechargeAddressFilterFormMemo = React.memo(RechargeAddressFilterForm);

export default RechargeAddressFilterFormMemo;
