import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import type { PayoutFilterPredicate } from '@/features/payouts/types';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { PayoutStatusFilterItem, PayoutTitleFilterItem } from './components';

import type { PayoutFilterFormProps } from './types';

const fields = [
  nameof<PayoutFilterPredicate>('createdAtRange'),
  nameof<PayoutFilterPredicate>('statusIn'),
  nameof<PayoutFilterPredicate>('assetIn'),
  nameof<PayoutFilterPredicate>('titleLike'),
  nameof<PayoutFilterPredicate>('btIn'),
];

const dateTimeRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_FILTER_FORM_CREATED_AT_TO_LABEL} />,
  },
  from: {
    label: <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_FILTER_FORM_CREATED_AT_FROM_LABEL} />,
  },
};

const PayoutFilterForm: React.FC<PayoutFilterFormProps> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<PayoutFilterPredicate> fields={fields} {...props}>
      <DateTimeRangeItem<PayoutFilterPredicate>
        data-test={dataTest && `${dataTest}-settledAt`}
        name={nameof<PayoutFilterPredicate>('createdAtRange')}
        messages={dateTimeRangeMessages}
        readonly={readonly}
        required={false}
        relativeUpdateKey="createdAtRelative"
      />
      <PayoutTitleFilterItem
        data-test={dataTest && `${dataTest}-title`}
        name={nameof<PayoutFilterPredicate>('titleLike')}
        readonly={readonly}
        required={false}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<PayoutFilterPredicate>('btIn')}
        network={network}
        readonly={readonly}
        required={false}
        multiselect
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<PayoutFilterPredicate>('assetIn')}
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
        multiselect
      />
      <PayoutStatusFilterItem
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<PayoutFilterPredicate>('statusIn')}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const PayoutFilterFormMemo = React.memo(PayoutFilterForm);

export default PayoutFilterFormMemo;
