import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectTask } from '@/features/collectable/actions';
import { makeSelectCollectableTransaction } from '@/features/collectable/selectors';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRechargeTransaction } from '@/features/recharges/actions';
import type { RechargeTransaction } from '@/features/recharges/types';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseRechargeTransaction = UseAppSingleData<RechargeTransaction>;

const noData = storedDataError<RechargeTransaction>('no-data');

const fetchFactory = (
  txId: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<RechargeTransaction>, Parameters<typeof fetchRechargeTransaction>[0]> =>
  fetchRechargeTransaction({ txId, force });

export default function useRechargeTransaction(txId: string | undefined): UseRechargeTransaction {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchCollectTask, txId), [txId]);
  return useAppSingleData(fetchFactory, makeSelectCollectableTransaction, noData, txId, dataFetchingSelector);
}
