import { CheckboxItem } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { nameof } from '@/infrastructure/utils/ts';

import type { LogoVisibilityItemProps } from './types';
import type React from 'react';

const LogoVisibilityItem: React.FC<LogoVisibilityItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <CheckboxItem
    data-test={dataTest}
    name={[nameof<PaymentsBranding>('logo'), nameof<NonNullable<PaymentsBranding['logo']>>('visible')]}
    readonly={disabled}
    ItemProps={{ noStyle: true, label: null }}
  />
);

export default LogoVisibilityItem;
