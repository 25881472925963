import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import DonationsBreadcrumb from '@/pages/donations/donations/components/DonationsBreadcrumb';

import DonationViewLink from '../DonationViewLink';

import type { DonationViewBreadcrumbProps } from './types';

const DonationViewBreadcrumb: React.FC<DonationViewBreadcrumbProps> = ({
  'data-test': dataTest,
  donationId,
  disabled,
  items = [],
}) => (
  <DonationsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageDonations.DONATION_VIEW_COMPONENTS_BREADCRUMB} values={{ id: donationId }} />
        ) : (
          <DonationViewLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={donationId}
            title={
              <FormattedMessage
                id={I18nPageDonations.DONATION_VIEW_COMPONENTS_BREADCRUMB}
                values={{ id: donationId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const DonationViewBreadcrumbMemo = React.memo(DonationViewBreadcrumb);

export default DonationViewBreadcrumbMemo;
