import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { balancesActualLink, BalancesPageRoutes } from '@/pages/balances/routes';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import ActualPage from './actual';

import type React from 'react';

export const BalancesDefaultRoute: React.FC = () => <Navigate to={balancesActualLink()} replace />;

const BalancesPages: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={BalancesPageRoutes.ACTUAL} Component={ActualPage} />
        <Route path="*" Component={BalancesDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const BalancesPagesHOC: React.FC = withBasicPageGuard(BalancesPages);

export default <Route key={AppPageRoutes.BALANCES} path={`${AppPageRoutes.BALANCES}/*`} Component={BalancesPagesHOC} />;
