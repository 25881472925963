import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonation } from '@/features/donations/actions';
import { makeSelectDonation } from '@/features/donations/selectors';
import type { Donation } from '@/features/donations/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseDonation = UseAppSingleData<Donation>;

const noData = storedDataError<Donation>('no-data');

const fetchFactory = (
  donationId: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<Donation>, Parameters<typeof fetchDonation>[0]> =>
  fetchDonation({ donationId, force });

export default function useDonation(donationId: string | undefined): UseDonation {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchDonation, donationId), [donationId]);
  return useAppSingleData(fetchFactory, makeSelectDonation, noData, donationId, dataFetchingSelector);
}
