import { FormattedMessage } from '@/components';
import { OperationRefreshSettlementIntent } from '@/features/settlements/components';
import { useSettlementIntent } from '@/features/settlements/hooks';
import type { SettlementIntent } from '@/features/settlements/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withIntentDataLoading =
  <T extends { data: SettlementIntent } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { intentId: string }> =>
  (props) => {
    const data = useSettlementIntent(props.intentId);
    return withCardDataLoading<SettlementIntent, T>({
      ...data,
      'data-test': props['data-test'],
      title: <FormattedMessage id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_INTENT_CARD_TITLE} />,
      CardProps: {
        extra: (
          <OperationRefreshSettlementIntent
            intentId={props.intentId}
            data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
          />
        ),
      },
    })(Component)(props);
  };

export default withIntentDataLoading;
