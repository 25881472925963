import { LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { Checkbox, Space, Typography } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import { ErrorFormMessage, FormattedMessage, FormattedMessageWithMarkup, Operation, SupportEmail } from '@/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useStateMountSafe } from '@/hooks';
import { withOnError } from '@/infrastructure/utils/functions';
import { generateSeed } from '@/infrastructure/utils/hd';

import { RecoveryKeyDownloader } from './components';

import type { Web3AuthRecoveryKeySecureProps } from './types';

const { Text } = Typography;

const Web3AuthRecoveryKeySecureRaw: React.FC<Web3AuthRecoveryKeySecureProps> = ({
  'data-test': dataTest,
  onSave,
  onLogout,
}) => {
  const key = useMemo(
    () => `${generateSeed()}${uuid()}${generateSeed()}${uuid()}${generateSeed()}`.replace(/ /g, ''),
    [],
  );
  const [downloading, setDownloading] = useStateMountSafe(false);
  const [manual, setManual] = useStateMountSafe(false);
  const [checked, setChecked] = useStateMountSafe(false);
  const { error, submitting, withSubmitting } = useErrorSafeSubmitting();
  const doStartDownloading = useCallback(() => setDownloading(true), [setDownloading]);
  const doMarkManual = useCallback(() => setManual(true), [setManual]);
  const doSaveKey = useMemo(
    () =>
      withSubmitting(
        withOnError(
          () => onSave(key),
          (e) => console.error(e),
        ),
      ),
    [key, onSave, withSubmitting],
  );
  const doLogout = useMemo(() => (onLogout ? withSubmitting(onLogout) : undefined), [onLogout, withSubmitting]);
  return (
    <Space direction="vertical" size="large">
      {error && (
        <ErrorFormMessage
          data-test={dataTest && `${dataTest}-error`}
          content={
            <FormattedMessage
              id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_ERROR_MESSAGE}
              values={{
                b: (text: React.ReactNode) => <strong>{text}</strong>,
                email: <SupportEmail data-test={dataTest && `${dataTest}-support`} />,
              }}
            />
          }
        />
      )}
      <Text data-test={dataTest && `${dataTest}-prepare`}>
        <FormattedMessageWithMarkup
          id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_PREPARE_MESSAGE}
          values={{
            store: (text: React.ReactNode) => (
              <Operation
                data-test={dataTest && `${dataTest}-saveOp`}
                title={text}
                onClick={doStartDownloading}
                icon={null}
                mode="link"
              />
            ),
          }}
        />
      </Text>
      {downloading && (
        <React.Fragment key="downloading">
          {!manual && (
            <Text data-test={dataTest && `${dataTest}-confirmation`}>
              <FormattedMessage
                id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_CONFIRMATION_MESSAGE}
                values={{
                  ln: (text: React.ReactNode) => (
                    <Operation
                      data-test={dataTest && `${dataTest}-manualOp`}
                      title={text}
                      onClick={doMarkManual}
                      icon={null}
                      mode="link"
                    />
                  ),
                }}
              />
            </Text>
          )}
          {manual && (
            <>
              <Text data-test={dataTest && `${dataTest}-manual`}>
                <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_MANUAL_MESSAGE} />
              </Text>
              <Text code copyable className="ym-hide-content">
                {key}
              </Text>
            </>
          )}
          <Checkbox style={{ lineHeight: '32px' }} checked={checked} onChange={(e) => setChecked(e.target.checked)}>
            <FormattedMessageWithMarkup id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_CONFIRMATION} />
          </Checkbox>
          <Space style={{ display: 'flex', justifyContent: 'end' }}>
            <Operation
              data-test={dataTest && `${dataTest}-confirmOp`}
              icon={<LockOutlined />}
              primary
              mode="button"
              onClick={doSaveKey}
              disabled={submitting || !checked}
              title={
                <FormattedMessageWithMarkup
                  id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_SUBMIT_TITLE}
                  tagName="span"
                />
              }
            />
            {doLogout && (
              <Operation
                data-test={dataTest && `${dataTest}-cancelOp`}
                icon={<LogoutOutlined />}
                mode="button"
                onClick={doLogout}
                disabled={submitting}
                title={
                  <FormattedMessage
                    id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_CANCEL_TITLE}
                    tagName="span"
                  />
                }
              />
            )}
          </Space>
          <RecoveryKeyDownloader key="downloader" value={key} />
        </React.Fragment>
      )}
    </Space>
  );
};

const Web3AuthRecoveryKeySecure = React.memo(Web3AuthRecoveryKeySecureRaw);

export default Web3AuthRecoveryKeySecure;
