import { Tooltip } from 'antd';
import React, { useState } from 'react';
import DetectableOverflow from 'react-detectable-overflow';

import type { ReactNode } from 'react';

interface TruncatedTextProps {
  children?: ReactNode;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ children }) => {
  const [isOverflowed, setOverflowed] = useState(false);
  return isOverflowed ? (
    <Tooltip placement="top" title={children}>
      <span>
        <DetectableOverflow onChange={setOverflowed}>{children}</DetectableOverflow>
      </span>
    </Tooltip>
  ) : (
    <DetectableOverflow onChange={setOverflowed}>{children}</DetectableOverflow>
  );
};

const TruncatedTextMemo = React.memo(TruncatedText);

export default TruncatedTextMemo;
