import { ReportsTable } from '@/features/reports/components';
import ReportFilterForm from '@/features/reports/components/ReportFilterForm';
import { useReportListView } from '@/features/reports/hooks';
import { ReportsPageContainer } from '@/pages/reports/components';
import ReportLink from '@/pages/reports/report-view/components/ReportViewLink';

import type React from 'react';

const dataTest: string | undefined = 'reports';

const ReportsPage: React.FC = () => {
  const view = useReportListView();
  return (
    <ReportsPageContainer data-test={dataTest && `${dataTest}-container`} mode="reports">
      <ReportsTable
        data-test={dataTest && `${dataTest}-table`}
        {...view}
        FilterForm={ReportFilterForm}
        ReportLink={ReportLink}
      />
    </ReportsPageContainer>
  );
};

export default ReportsPage;
