import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import CollectableTransactionIcon from '@/features/collectable/components/CollectableTransactionIcon';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import RechargesIcon from '@/features/recharges/components/RechargesIcon';
import { I18nMenu } from '@/generated/i18n/i18n';
import type { CustomizableRoute } from '@/layouts/types';
import {
  rechargeAddressesLink,
  RechargesPageRoutes,
  rechargesRoute,
  rechargeTransactionsLink,
} from '@/pages/recharges/routes';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (
  selected: boolean,
  formatMessage: IntlFormattersArrow['formatMessage'],
  featureFlags: FeatureFlags | undefined,
): CustomizableRoute[] =>
  featureFlags?.enableRecharges
    ? [
        {
          name: formatMessage({ id: I18nMenu.RECHARGES_MAIN }),
          key: appRoute(AppPageRoutes.RECHARGES),
          icon: <RechargesIcon />,
          path: rechargeAddressesLink(),
          hideChildrenInMenu: !selected,
          children: [
            {
              name: formatMessage({ id: I18nMenu.RECHARGES_ADDRESSES }),
              icon: <RechargesIcon />,
              path: rechargeAddressesLink(),
              hideChildrenInMenu: true,

              children: [RechargesPageRoutes.ADDRESS_VIEW, RechargesPageRoutes.ADDRESS_NEW]
                .map((route) => rechargesRoute(route))
                .map((path) => ({ path })),
            },
            {
              name: formatMessage({ id: I18nMenu.RECHARGES_TRANSACTIONS }),
              icon: <CollectableTransactionIcon />,
              path: rechargeTransactionsLink(),
              hideChildrenInMenu: true,

              children: [RechargesPageRoutes.TRANSACTION_VIEW]
                .map((route) => rechargesRoute(route))
                .map((path) => ({ path })),
            },
          ],
        },
      ]
    : [];

export default function useMenuData(): CustomizableRoute[] {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.RECHARGES));
  return useMemo(() => menuCreator(selected, formatMessage, featureFlags), [featureFlags, formatMessage, selected]);
}
