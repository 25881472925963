import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { usePrevious } from '@/hooks';

import type { BackgroundTaskPayloadSelectProps } from './types';

const BackgroundTaskPayloadSelect: React.FC<BackgroundTaskPayloadSelectProps> = ({ onChange, selector }) => {
  const data = useAppSelector(selector);
  const previous = usePrevious(data);
  if (data !== previous) {
    onChange(data);
  }
  return null;
};

const BackgroundTaskPayloadSelectMemo = React.memo(BackgroundTaskPayloadSelect);

export default BackgroundTaskPayloadSelectMemo;
