import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { payoutBatchIdToKey } from '@/features/payouts/utils';
import {
  createNestedListParametersSelectors,
  createNormalizedListSelectors,
} from '@/infrastructure/model/list/selectors';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import type { SingleState } from '@/infrastructure/model/single/types';

import {
  defaultPayoutDestinationDetailedParametersState,
  defaultPayoutDestinationParametersState,
  NAMESPACE,
} from './types';

import type { PayoutBatchId, PayoutSummary } from './types';

export const {
  makeSelectPayout,
  makeSelectPayoutListData,
  makeSelectPayoutListParameters: baseMakeSelectPayoutsListParameters,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state): SingleState<PayoutSummary> => state[NAMESPACE].entities,
  'Payout' as const,
  undefined,
);
export const makeSelectPayoutListParameters: typeof baseMakeSelectPayoutsListParameters = () =>
  createSelector(
    baseMakeSelectPayoutsListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, networkEq): ReturnType<ReturnType<typeof baseMakeSelectPayoutsListParameters>> => ({
      ...parameters,
      filter: { ...filter, networkEq },
    }),
  );

export const { makeSelectPayoutBatches } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].batches,
  'PayoutBatches' as const,
  undefined,
);

export const { makeSelectPayoutDestinations } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].destinations,
  'PayoutDestinations' as const,
  undefined,
);

export const { makeSelectDestinationsPerPayoutListParameters } = createNestedListParametersSelectors(
  (state: AppRootState, payoutId: string | undefined) =>
    (payoutId ? state[NAMESPACE].destinationsPerPayout.parameters[payoutId] : undefined)
    ?? defaultPayoutDestinationDetailedParametersState,
  (state: AppRootState) => state[NAMESPACE].destinationsPerPayout.columnState,
  'DestinationsPerPayout' as const,
);

export const { makeSelectDestinationsPerBatchListParameters } = createNestedListParametersSelectors(
  (state: AppRootState, batchId: PayoutBatchId | undefined) =>
    (batchId ? state[NAMESPACE].destinationsPerBatch.parameters[payoutBatchIdToKey(batchId)] : undefined)
    ?? defaultPayoutDestinationParametersState,
  (state: AppRootState) => state[NAMESPACE].destinationsPerBatch.columnState,
  'DestinationsPerBatch' as const,
);

export const {
  makeSelectPayoutForSettlement,
  makeSelectDirtyPayoutForSettlementIds,
  makeSelectPayoutForSettlementData,
  makeSelectPayoutForSettlementIsDirty,
} = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].bySettlement,
  'PayoutForSettlement' as const,
  undefined,
);
