import { List, Space } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDate } from '@/components';
import { AssetIcon, AssetLabel } from '@/features/dictionary/blockchain/components';
import { MerchantWalletDeploymentStatusBanner } from '@/features/merchant-wallets/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';
import SettlementIntentLink from '@/pages/settlements/intent-view/components/SettlementIntentLink';

import OperationWithdrawNow from '../OperationSettleNow';
import SettlementIntentStatusBanner from '../SettlementIntentStatusBanner';

import type { SettlementIntentsListProps } from './types';

const SettlementIntentsList: React.FC<SettlementIntentsListProps> = ({ 'data-test': dataTest, intents }) => (
  <List
    itemLayout="horizontal"
    loadMore={false}
    dataSource={intents}
    renderItem={(item) => (
      <List.Item
        key={item.id}
        actions={[
          <SettlementIntentLink
            key="intent"
            data-test={dataTest && `${dataTest}-${item.asset}-intent`}
            value={item.id}
            mode="inline"
            title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_INTENTS_LIST_LINK} />}
          />,
          <OperationWithdrawNow
            key="withdraw-now"
            data-test={dataTest && `${dataTest}-${item.asset}-withdraw`}
            assetId={item.asset}
            mode="inline"
          />,
        ]}
      >
        <List.Item.Meta
          style={{ alignItems: 'center' }}
          avatar={
            <AssetIcon
              data-test={dataTest && `${dataTest}-${item.asset}-icon`}
              type={item.asset}
              style={{ fontSize: 32 }}
            />
          }
          title={
            <Space>
              <AssetLabel data-test={dataTest && `${dataTest}-${item.asset}-label`} value={item.asset} mode="short" />
              <SettlementIntentStatusBanner
                data-test={dataTest && `${dataTest}-${item.asset}-intentStatus`}
                intent={item}
                mode="tag"
              />
              <MerchantWalletDeploymentStatusBanner
                data-test={dataTest && `${dataTest}-${item.asset}-walletStatus`}
                assetId={item.asset}
                mode="tag"
              />
            </Space>
          }
          description={
            <FormattedMessage
              id={I18nFeatureSettlements.COMPONENTS_INTENTS_LIST_DESCRIPTION}
              tagName="span"
              values={{
                datetime: (
                  <ReadonlyDate
                    data-test={dataTest && `${dataTest}-${item.asset}-processAt`}
                    value={item.processAt}
                    plain
                  />
                ),
              }}
            />
          }
        />
      </List.Item>
    )}
  />
);

const SettlementIntentsListMemo = React.memo(SettlementIntentsList);

export default SettlementIntentsListMemo;
