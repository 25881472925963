import React, { useCallback } from 'react';

import { useAppSelector } from '@/app/hooks';
import type { LazyLoadSelectProps } from '@/components';
import { FormattedMessage, LazyLoadSelect } from '@/components';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { querySubscriptionPlan, querySubscriptionPlans } from '@/features/subscription-plans/api';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { defaultPage } from '@/infrastructure/api';

import type { SubscriptionPlanSelectProps } from './types';

const PAGE_SIZE = defaultPage.perPage;
const networkSelector = makeSelectSelectedNetwork();

const messages = {
  placeholder: <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLAN_SELECT_PLACEHOLDER} />,
};

const SubscriptionPlanSelect: React.FC<SubscriptionPlanSelectProps> = (props) => {
  const network = useAppSelector(networkSelector);
  const fetchData = useCallback<LazyLoadSelectProps['requestFilteredData']>(
    async ({ input, page }) => {
      const { list } = await querySubscriptionPlans({
        page: { page, perPage: PAGE_SIZE },
        filter: { networkType: network, name: input },
      });
      return { data: list.map(({ id, name }) => ({ value: id, label: name })), hasMore: list.length === PAGE_SIZE };
    },
    [network],
  );
  const fetchDataById = useCallback<LazyLoadSelectProps['requestDataById']>(async (id) => {
    try {
      const { id: value, name: label } = await querySubscriptionPlan(id);
      return { value, label };
    } catch (e) {
      console.warn(`Unable to fetch plan by id ${id}`, e);
      return undefined;
    }
  }, []);
  return (
    <LazyLoadSelect
      {...props}
      cacheKeyPrefix={network}
      messages={messages}
      requestFilteredData={fetchData}
      requestDataById={fetchDataById}
    />
  );
};

const SubscriptionPlanSelectMemo = React.memo(SubscriptionPlanSelect);

export default SubscriptionPlanSelectMemo;
