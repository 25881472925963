import { PageContainer } from '@/components';
import { DonationsDocumentationBanner } from '@/features/donations/components';
import type { TestableProps } from '@/infrastructure/utils/react';
import { DonationCard } from '@/pages/donations/donation-view/components';

import { DonationAssetCard, DonationAssetDeployTransactionCard, DonationAssetViewBreadcrumb } from './components';
import { withAddressPageParams } from './hocs';

import type React from 'react';

type DonationAddressViewPageProps = TestableProps & { pageParams: { donationId: string; asset: string } };

const DonationAddressViewPage: React.FC<DonationAddressViewPageProps> = ({
  'data-test': dataTest = 'asset',
  pageParams: { donationId, asset },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <DonationAssetViewBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        donationId={donationId}
        asset={asset}
        disabled
      />
    }
  >
    <DonationsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <DonationAssetCard data-test={dataTest && `${dataTest}-card`} donationId={donationId} asset={asset} />
    <DonationCard data-test={dataTest && `${dataTest}-donation`} donationId={donationId} />
    <DonationAssetDeployTransactionCard
      data-test={dataTest && `${dataTest}-deploy`}
      donationId={donationId}
      asset={asset}
    />
  </PageContainer>
);

export default withAddressPageParams(DonationAddressViewPage);
