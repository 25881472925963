import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useCollectSchedule } from '@/features/collectable/hooks';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { OperationRefreshCollectScheduleProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_SCHEDULE_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_SCHEDULE_MESSAGE_SUCCESS} />,
  },
};

const OperationRefreshCollectSchedule: React.FC<OperationRefreshCollectScheduleProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useCollectSchedule();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={messages} />;
};

const OperationRefreshCollectScheduleMemo = React.memo(OperationRefreshCollectSchedule);

export default OperationRefreshCollectScheduleMemo;
