import { Divider, Form } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useHandlers } from '@/features/global/hooks';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';

import type { ReportScheduleParametersItemProps } from './types';
import type { NamePath, Store } from 'rc-field-form/es/interface';

const ReportScheduleParametersItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  template,
  readonly,
  required,
  ItemProps = {},
}: ReportScheduleParametersItemProps<Values>) => {
  const handlers = useHandlers('reportSchedules');
  const handler = useMemo(() => handlers.find((h) => h.template === template), [handlers, template]);

  return (
    <>
      <Divider type="horizontal">
        <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_PARAMETERS_FORM_TITLE} />
      </Divider>
      <Form.Item<Values> name={name as NamePath} required={required} wrapperCol={{ span: 24 }} noStyle {...ItemProps}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {!template ? (
          <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_PARAMETERS_FORM_NO_TEMPLATE} tagName="span" />
        ) : readonly ? (
          handler?.ParametersView && <handler.ParametersView data-test={dataTest} />
        ) : (
          handler?.ParametersForm && <handler.ParametersForm name={name} data-test={dataTest} />
        )}
      </Form.Item>
    </>
  );
};

const ReportScheduleParametersItemMemo = React.memo(
  ReportScheduleParametersItem,
) as typeof ReportScheduleParametersItem;

export default ReportScheduleParametersItemMemo;
