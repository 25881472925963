import { useMemo } from 'react';

import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withHideIfOtherActive =
  <T extends { activeProvider?: string }>(providerFilter: (activeProvider?: string) => boolean) =>
  (Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithHideIfOtherActive',
    )((props) => {
      const { activeProvider } = props;
      const isVisible = useMemo(() => providerFilter(activeProvider), [activeProvider]);
      return isVisible ? <Component {...props} /> : null;
    });

export default withHideIfOtherActive;
