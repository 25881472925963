import { CheckCircleOutlined, RestOutlined, SnippetsOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { SubscriptionPlanStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import type { SubscriptionPlanStatusViewProps } from './types';

const icons: Record<SubscriptionPlanStatusAPIModel, React.ReactNode> = {
  [SubscriptionPlanStatusAPIModel.Draft]: <SnippetsOutlined style={{ color: 'blue' }} />,
  [SubscriptionPlanStatusAPIModel.Active]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [SubscriptionPlanStatusAPIModel.Archived]: <RestOutlined style={{ color: 'gray' }} />,
};

const i18n: Record<SubscriptionPlanStatusAPIModel, I18nMessages> = {
  [SubscriptionPlanStatusAPIModel.Draft]: I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLAN_STATUS_VIEW_DRAFT,
  [SubscriptionPlanStatusAPIModel.Active]: I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLAN_STATUS_VIEW_ACTIVE,
  [SubscriptionPlanStatusAPIModel.Archived]:
    I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLAN_STATUS_VIEW_ARCHIVED,
};

const SubscriptionPlanStatusView: React.FC<SubscriptionPlanStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const SubscriptionPlanStatusViewMemo = React.memo(SubscriptionPlanStatusView);

export default SubscriptionPlanStatusViewMemo;
