import isNil from 'lodash/isNil';

import { withPageParams } from '@/hocs';
import { requiredParamsParser } from '@/hocs/withPageParams/utils';

const paramParser = (params: Partial<Record<string, string | undefined>>) => {
  const requiredParams = requiredParamsParser(['payoutId', 'batchNum'])(params);
  const batchNumStr = requiredParams?.batchNum;
  const payoutId = requiredParams?.payoutId;
  const batchNum = batchNumStr
    ? (() => {
        try {
          return parseInt(batchNumStr);
        } catch {
          return undefined;
        }
      })()
    : undefined;
  return payoutId && !isNil(batchNum) ? { payoutId, batchNum } : undefined;
};

const withPayoutBatchPageParams = withPageParams(paramParser);

export default withPayoutBatchPageParams;
