import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, SelectWithReloadingItem } from '@/components';
import { useCompanies } from '@/features/company/hooks';
import type { Company } from '@/features/company/types';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';

import type { CompanyItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const CompanyItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  placeholder,
  readonly,
  required,
  messages,
  isDisabled,
  ItemProps = {},
}: CompanyItemProps<Values>) => {
  const { data, forceRefresh, loading } = useCompanies();
  return (
    <SelectWithReloadingItem<Values, Company, number>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      required={required}
      messages={useMemo(
        () => ({
          label: messages?.label || <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANY_ITEM_SELECT_LABEL} />,
          dataEmpty: messages?.empty || (
            <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANY_ITEM_SELECT_EMPTY_ERROR} />
          ),
          placeholder: placeholder || (
            <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANY_ITEM_SELECT_PLACEHOLDER} />
          ),
          required: messages?.required ?? (
            <FormattedMessage id={I18nFeatureCompany.COMPONENTS_COMPANY_ITEM_SELECT_REQUIRED} />
          ),
        }),
        [messages?.empty, messages?.label, messages?.required, placeholder],
      )}
      refresh={forceRefresh}
      loading={loading}
      options={data}
      dataToOptions={useCallback(
        ({ id, name: label }) => ({
          value: id,
          disabled: isDisabled?.(id),
          label,
        }),
        [isDisabled],
      )}
      ItemProps={ItemProps}
    />
  );
};

const CompanyItemMemo = React.memo(CompanyItem) as typeof CompanyItem;

export default CompanyItemMemo;
