/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewEmailRequestAPIModel
 */
export interface RenewEmailRequestAPIModel {
    /**
     * 
     * @type {string}
     * @memberof RenewEmailRequestAPIModel
     */
    email: string;
}

/**
 * Check if a given object implements the RenewEmailRequestAPIModel interface.
 */
export function instanceOfRenewEmailRequestAPIModel(value: object): boolean {
    if (!('email' in value)) return false;
    return true;
}

export function RenewEmailRequestAPIModelFromJSON(json: any): RenewEmailRequestAPIModel {
    return RenewEmailRequestAPIModelFromJSONTyped(json, false);
}

export function RenewEmailRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewEmailRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
    };
}

export function RenewEmailRequestAPIModelToJSON(value?: RenewEmailRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
    };
}

