import { useMemo } from 'react';

import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchReportSchedule } from '@/features/report-schedules/actions';
import { makeSelectReportSchedule } from '@/features/report-schedules/selectors';
import type { ReportSchedule } from '@/features/report-schedules/types';
import { type LoadingStateWithDirty, storedDataLoaded } from '@/infrastructure/model';

const noData = storedDataLoaded<ReportSchedule>();

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<ReportSchedule>, Parameters<typeof fetchReportSchedule>[0]> =>
  fetchReportSchedule({ force, id });

export default function useReportSchedule(id: string | undefined) {
  return useAppSingleData(
    fetchFactory,
    makeSelectReportSchedule,
    noData,
    id,
    useMemo(() => (id ? makeSelectPending(fetchReportSchedule, id) : undefined), [id]),
  );
}
