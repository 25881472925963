import { Form } from 'antd';
import React, { useMemo } from 'react';

import FormattedMessage from '@/components/FormattedMessage';
import MoneyAmountInput from '@/components/MoneyAmountInput';
import type { MoneyAmountInputValue } from '@/components/MoneyAmountInput/types';
import { I18nComponents } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';

import type { MoneyAmountItemProps } from './types';
import type { Rule, Store } from 'rc-field-form/es/interface';

const MoneyAmountItem = <Values extends Store = Store>({
  'data-test': dataTest,
  readonly,
  required,
  max,
  min,
  nonNegative,
  asset: { precision, title: assetTitle },
  messages,
  name,
  rules = [],
  noLabelOnHelp,
  InputProps,
  ItemProps,
}: MoneyAmountItemProps<Values>) => {
  const { groupSeparator, decimalSeparator } = useLocaleSettings();
  const maxValue = useMemo(() => (max && ('value' in max ? max.value : max)) ?? undefined, [max]);
  const minValue = useMemo(() => (min && ('value' in min ? min.value : min)) ?? undefined, [min]);
  const combinedRules: Rule[] = useMemo(
    () => [
      {
        message: <FormattedMessage id={I18nComponents.MONEY_AMOUNT_ITEM_ERROR_VALUE_INVALID} />,
        validator: async (_: unknown, value: MoneyAmountInputValue) => {
          if ((required || !!value.inputValue) && !value.valid) {
            return Promise.reject(new Error());
          }
        },
        validateTrigger: ['onChange', 'onBlur'],
      },
      {
        message: (
          <FormattedMessage id={I18nComponents.MONEY_AMOUNT_ITEM_ERROR_TOO_LONG_PRECISION} values={{ precision }} />
        ),
        validator: async (_: unknown, value: MoneyAmountInputValue) => {
          if ((value.value?.dp() ?? 0) > precision) {
            return Promise.reject(new Error());
          }
        },
        validateTrigger: ['onChange'],
      },
      ...(required
        ? [
            {
              message: messages?.required ?? (
                <FormattedMessage id={I18nComponents.MONEY_AMOUNT_ITEM_ERROR_VALUE_REQUIRED} />
              ),
              validator: async (_: unknown, value: MoneyAmountInputValue) => {
                if (value.valid && value.value?.isZero()) {
                  return Promise.reject(new Error());
                }
              },
              validateTrigger: ['onChange', 'onBlur'],
            },
          ]
        : []),
      ...(maxValue && max
        ? [
            {
              message: ('message' in max && max.message) || (
                <FormattedMessage
                  id={I18nComponents.MONEY_AMOUNT_ITEM_ERROR_MAX_LIMIT}
                  values={{ amount: maxValue.toFormat() }}
                />
              ),
              validator: async (_: unknown, value: MoneyAmountInputValue) => {
                if (value.valid && value.value?.gt(maxValue)) {
                  return Promise.reject(new Error());
                }
              },
              validateTrigger: ['onChange', 'onBlur'],
            },
          ]
        : []),
      ...(minValue && min
        ? [
            {
              message: ('message' in min && min.message) || (
                <FormattedMessage
                  id={I18nComponents.MONEY_AMOUNT_ITEM_ERROR_MIN_LIMIT}
                  values={{ amount: minValue.toFormat() }}
                />
              ),
              validator: async (_: unknown, value: MoneyAmountInputValue) => {
                if (value.valid && value.value?.lt(minValue)) {
                  return Promise.reject(new Error());
                }
              },
              validateTrigger: ['onChange', 'onBlur'],
            },
          ]
        : []),
      ...rules,
    ],
    [max, maxValue, messages?.required, min, minValue, precision, required, rules],
  );
  return (
    <Form.Item<Values>
      label={messages?.label ?? <FormattedMessage id={I18nComponents.MONEY_AMOUNT_ITEM_LABEL} />}
      name={name}
      required={!readonly && required}
      rules={combinedRules}
      {...ItemProps}
    >
      <MoneyAmountInput
        InputProps={InputProps}
        noLabelOnHelp={noLabelOnHelp}
        nonNegative={nonNegative}
        asset={useMemo(() => ({ title: assetTitle, precision }), [assetTitle, precision])}
        data-test={dataTest}
        readonly={readonly}
        separators={useMemo(
          () => ({ group: groupSeparator, decimal: decimalSeparator }),
          [decimalSeparator, groupSeparator],
        )}
      />
    </Form.Item>
  );
};

const MoneyAmountItemMemo = React.memo(MoneyAmountItem) as typeof MoneyAmountItem;

export default MoneyAmountItemMemo;
