import { EditOutlined, EditTwoTone } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { makeSelectStatisticsAsset } from '@/features/company-settings/selectors';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';

import { UpdateStatisticsAssetForm } from './components';

import type { OperationUpdateStatisticsAssetProps } from './types';

const selectAsset = makeSelectStatisticsAsset();

const notificationOptions = {
  error: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_MESSAGE_ERROR} />
  ),
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_MESSAGE_SUCCESS} />
  ),
};

const formLayout = {
  labelCol: { span: 0 },
};

const OperationUpdateStatisticsAsset: React.FC<OperationUpdateStatisticsAssetProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess,
  mode = 'inline',
}) => {
  const { updateStatisticsAsset: updateStatisticsAssetAction } = useCompanySettingsActions();
  const oldAsset = useAppSelector(selectAsset);
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const updateDefaultAsset = useMemo(
    () =>
      withNotification(
        formControl.withFormHide(async (asset: string) => {
          await updateStatisticsAssetAction.act(asset);
          onSuccess?.(asset);
        }),
        notificationOptions,
      ),
    [withNotification, formControl, onSuccess, updateStatisticsAssetAction],
  );

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={
            <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_TITLE} />
          }
          closable
          onCancel={formControl.hide}
        >
          <UpdateStatisticsAssetForm
            value={oldAsset.data}
            layout={formLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={updateDefaultAsset}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_TITLE} />}
        icon={
          mode === 'icon' && !disabled && updateStatisticsAssetAction.available ? <EditTwoTone /> : <EditOutlined />
        }
        onClick={formControl.show}
        disabled={disabled || !updateStatisticsAssetAction.available || (!oldAsset.data && !oldAsset.isDirty)}
        inProgress={inProgress || formControl.visible || updateStatisticsAssetAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationUpdateStatisticsAssetMemo = React.memo(OperationUpdateStatisticsAsset);

export default OperationUpdateStatisticsAssetMemo;
