import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRechargeAddresses } from '@/features/recharges/actions';
import {
  makeSelectRechargeAddressesListParameters,
  makeSelectRechargeAddressListData,
} from '@/features/recharges/selectors';
import type { RechargeAddress, RechargeAddressFilterPredicate } from '@/features/recharges/types';
import type { PushAddressSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseRechargeAddressList = UseAppListData<
  RechargeAddress,
  RechargeAddressFilterPredicate,
  PushAddressSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<RechargeAddress>, Parameters<typeof fetchRechargeAddresses>[0]> =>
  fetchRechargeAddresses({ force });

const dataSelector = makeSelectRechargeAddressListData();
const dataFetchingSelector = makeSelectPending(fetchRechargeAddresses);
const parametersSelector = makeSelectRechargeAddressesListParameters();

export default function useRechargeAddressList(): UseRechargeAddressList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
