import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum BalancesPageRoutes {
  ACTUAL = 'actual',
}

export const balancesActualRoute = (route: BalancesPageRoutes) =>
  `${appRoute(AppPageRoutes.BALANCES)}${route.length ? `/${route}` : ''}`;

export const balancesActualLink = () => balancesActualRoute(BalancesPageRoutes.ACTUAL);
