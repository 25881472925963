import { Modal, Space, Typography } from 'antd';
import { useCallback } from 'react';

import { FormattedMessage } from '@/components';
import ReCaptchaV2 from '@/features/recaptcha/components/ReCaptchaV2';
import { I18nFeatureRecaptcha } from '@/generated/i18n/i18n';
import { noop } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';

import type React from 'react';

export interface UseV2ReCaptchaType {
  execute: () => Promise<string | null>;
  contextHolder: React.ReactElement;
}

const useV2ReCaptcha = (): UseV2ReCaptchaType => {
  const [modal, contextHolder] = Modal.useModal();
  const executeRecaptcha = useCallback(() => {
    let doCancel: Func<[string]> = noop;
    let doSubmit: Func<[string | null]> = noop;
    const result = new Promise<string | null>((resolve, reject) => {
      doSubmit = resolve;
      doCancel = reject;
    });
    const { destroy } = modal.info({
      title: <FormattedMessage id={I18nFeatureRecaptcha.RECAPTCHA_V2_MODAL_TITLE} />,
      open: true,
      maskClosable: false,
      closable: true,
      onCancel: () => {
        doCancel('Cancelled');
        destroy();
      },
      content: (
        <Space direction="vertical">
          <Typography.Text>
            <FormattedMessage id={I18nFeatureRecaptcha.RECAPTCHA_V2_MESSAGE} />
          </Typography.Text>
          <ReCaptchaV2
            data-test="recaptcha-v2"
            onError={(e) => {
              destroy();
              doCancel(e);
            }}
            onSuccess={(res) => {
              destroy();
              doSubmit(res);
            }}
          />
        </Space>
      ),
    });
    return result;
  }, [modal]);

  return { execute: executeRecaptcha, contextHolder };
};

export default useV2ReCaptcha;
