import { useAppLoadableData } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRevenue } from '@/features/subscription-statistics/actions';
import { makeSelectSubscriptionMonthlyRevenue } from '@/features/subscription-statistics/selectors';

const dataSelector = makeSelectSubscriptionMonthlyRevenue();
const dataFetchingSelector = makeSelectPending(fetchRevenue);
const fetchFactory = (force: boolean) => fetchRevenue({ force });

export default function useSubscriptionRevenue() {
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector);
}
