import React from 'react';

import type { OperationRefreshProps } from '@/components';
import { FormattedMessage, OperationRefresh } from '@/components';
import { usePayout } from '@/features/payouts/hooks';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { OperationRefreshPayoutProps } from './types';

const messages: OperationRefreshProps['messages'] = {
  tooltip: <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_REFRESH_PAYOUT_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_REFRESH_PAYOUT_MESSAGE_SUCCESS} />,
  },
};

const OperationRefreshPayout: React.FC<OperationRefreshPayoutProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  payoutId,
}) => {
  const { forceRefresh } = usePayout(payoutId);
  return <OperationRefresh messages={messages} refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshPayoutMemo = React.memo(OperationRefreshPayout);

export default OperationRefreshPayoutMemo;
