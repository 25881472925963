import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useDomainActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationDeleteDomainProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_DOMAIN_ERROR_MESSAGE} />,
  success: () => <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_DOMAIN_SUCCESS_MESSAGE} />,
};

const OperationDeleteDomain: React.FC<OperationDeleteDomainProps> = ({
  'data-test': dataTest,
  confirmation,
  domainId,
  mode = 'inline',
  onSuccess = noop,
}) => {
  const { remove: removeAction } = useDomainActions(domainId);

  const { withNotification } = useNotification();

  const deleteNotification = useMemo(
    () => withSuppressError(withOnSuccess(withNotification(removeAction.act, notificationOptions), onSuccess)),
    [withNotification, removeAction.act, onSuccess],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? {
            title: <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_DOMAIN_CONFIRMATION_TITLE} />,
          }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_DELETE_DOMAIN_TITLE} />}
      icon={<DeleteOutlined />}
      onClick={deleteNotification}
      disabled={!removeAction.available}
      inProgress={removeAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeleteDomainMemo = React.memo(OperationDeleteDomain);

export default OperationDeleteDomainMemo;
