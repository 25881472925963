import { MerchantWalletTransferDocumentationBanner } from '@/features/merchant-wallet-transfers/components';
import { withPageContainer } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { withAuditTransferPageParams } from '@/pages/audit/hocs';
import MerchantWalletTransferEntityLink from '@/pages/audit/transfer-entity/components/MerchantWalletTransferEntityLink';
import SettlementViewLink from '@/pages/settlements/settlement-view/components/SettlementViewLink';

import { MerchantWalletTransferBreadcrumb, MerchantWalletTransferCard } from './components';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type MerchantWalletTransferPageProps = PropsWithChildren<TestableProps & { pageParams: { transferId: string } }>;

const MerchantWalletTransferPage: React.FC<MerchantWalletTransferPageProps> = ({
  'data-test': dataTest = 'mwt',
  pageParams: { transferId },
}) => (
  <>
    <MerchantWalletTransferDocumentationBanner data-test={dataTest && `${dataTest}-docs`} pageSize="big" />
    <MerchantWalletTransferCard
      data-test={dataTest && `${dataTest}-card`}
      transferId={transferId}
      SettlementLink={SettlementViewLink}
      EntityLink={MerchantWalletTransferEntityLink}
    />
  </>
);

export default withAuditTransferPageParams(
  withPageContainer<MerchantWalletTransferPageProps>(({ pageParams: { transferId } }) => ({
    breadcrumbRender: () => <MerchantWalletTransferBreadcrumb transferId={transferId} disabled />,
  }))(MerchantWalletTransferPage),
);
