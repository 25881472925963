import { createReducer } from '@reduxjs/toolkit';

import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import { PaymentSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { defaultPage } from '@/infrastructure/api';
import { createListReducers } from '@/infrastructure/model/list/reducers';
import { storedDirtyListDataTyped } from '@/infrastructure/model/list/utils';
import { createSingleReducers } from '@/infrastructure/model/single/reducers';

import {
  markPaymentDirty,
  markPaymentListDirty,
  markTransactionsForPaymentDirty,
  storeMultiplePayment,
  storePayment,
  storePaymentListData,
  storePaymentListParameters,
  storeTransactionsForPayment,
} from './actions';

import type { PaymentsState, PaymentSortBy } from './types';
import type { Draft } from 'immer';

const defaultSortBy: PaymentSortBy = { [PaymentSortByAPIModel.createdAt]: 'DESC' };

const initialState: PaymentsState = {
  list: {
    data: storedDirtyListDataTyped(),
    filter: {},
    sortBy: defaultSortBy,
    columnState: {},
    page: defaultPage,
  },
  entities: {},
  transactions: {},
};

const { storeMultiplePaymentReducer, storePaymentReducer, markPaymentDirtyReducer } = createSingleReducers(
  'Payment' as const,
  (state: Draft<PaymentsState>) => state.entities,
  (state, entities) => ({ ...state, entities }),
);

const { storePaymentListParametersReducer, markPaymentListDirtyReducer, storePaymentListDataReducer } =
  createListReducers(
    'Payment' as const,
    (state: Draft<PaymentsState>) => state.list,
    (state, list) => ({ ...state, list }),
    initialState.list,
  );

const { markTransactionsForPaymentDirtyReducer, storeTransactionsForPaymentReducer } = createSingleReducers(
  'TransactionsForPayment' as const,
  (state: Draft<PaymentsState>) => state.transactions,
  (state, transactions) => ({ ...state, transactions }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(storePaymentListData, storePaymentListDataReducer)
    .addCase(storePaymentListParameters, storePaymentListParametersReducer)
    .addCase(markPaymentListDirty, markPaymentListDirtyReducer)

    .addCase(storeMultiplePayment, storeMultiplePaymentReducer)
    .addCase(markPaymentDirty, markPaymentDirtyReducer)
    .addCase(storePayment, storePaymentReducer)

    .addCase(markTransactionsForPaymentDirty, markTransactionsForPaymentDirtyReducer)
    .addCase(storeTransactionsForPayment, storeTransactionsForPaymentReducer)

    .addCase(notifyNetworkUpdated, (state) => ({
      ...initialState,
      list: {
        ...initialState.list,
        columnState: state.list.columnState,
        filter: state.list.filter,
        sortBy: state.list.sortBy,
      },
    }));
});

export default reducer;
