import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import {
  fetchMultipleSubscriptionCharge,
  storeMultipleSubscriptionCharge,
} from '@/features/subscription-charges/actions';
import { querySubscriptionCharges } from '@/features/subscription-charges/api';
import { extractChargeId } from '@/features/subscription-charges/utils';
import type { SubscriptionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { defaultPageFn, withAPICall, withFetchAllDataOrThrow } from '@/infrastructure/api';
import { loadingDataError, mapLoadingState, mapStoredState, withApiRequest } from '@/infrastructure/model';
import { createNestedListActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { identity } from '@/infrastructure/utils/functions';

import {
  querySubscription,
  querySubscriptions,
  requestCancelSubscription,
  requestHintSubscriptionStatusChanged,
} from './api';
import {
  makeSelectChargeIdsForSubscription,
  makeSelectChargesForSubscription,
  makeSelectDirtyChargesForSubscriptionIds,
  makeSelectDirtySubscriptionIds,
  makeSelectMultipleSubscription,
  makeSelectSubscription,
  makeSelectSubscriptionListData,
  makeSelectSubscriptionListParameters,
  makeSelectSubscriptionsForPlanListData,
  makeSelectSubscriptionsForPlanListParameters,
} from './selectors';
import { NAMESPACE } from './types';
import { extractSubscriptionId } from './utils';

import type { Subscription, SubscriptionFilterPredicate, SubscriptionWithActions } from './types';

export const { storeSubscription, storeMultipleSubscription, markSubscriptionDirty } = createSingleActions<
  Subscription,
  'Subscription'
>(NAMESPACE, 'Subscription');

const subscriptionFetchLimit = pLimit(1);
export const fetchSubscription = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscription`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    subscriptionFetchLimit(async () => {
      const saved = makeSelectSubscription(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySubscription, 'unable to fetch subscription')(id, {
        signal,
      });
      dispatch(storeSubscription({ id, data }));

      return makeSelectSubscription(id)(getState());
    }),
  { idGenerator: ({ id }) => id },
);

const multipleSubscriptionsFetchLimit = pLimit(1);
export const fetchMultipleSubscription = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleSubscription`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleSubscriptionsFetchLimit(async () => {
      const absent = makeSelectDirtySubscriptionIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleSubscription(ids)(getState());
      }

      const data = await withAPICall(querySubscriptions, 'unable to fetch subscriptions')(
        { filter: { ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleSubscription(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractSubscriptionId,
            identity,
          ),
        ),
      );

      return makeSelectMultipleSubscription(ids)(getState());
    }),
);

export const {
  storeSubscriptionsForPlanListData,
  storeSubscriptionsForPlanListParameters,
  markSubscriptionsForPlanListDirty,
} = createNestedListActions<
  Subscription,
  'SubscriptionsForPlan',
  SubscriptionFilterPredicate,
  SubscriptionSortByAPIModel
>(NAMESPACE, 'SubscriptionsForPlan');

const subscriptionsPerAssetFetchLimit = pLimit(1);
export const fetchSubscriptionsForPlan = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscriptionsForPlan`,
  async ({ force, planId }: { force?: boolean; planId: string }, { dispatch, getState, signal }) =>
    subscriptionsPerAssetFetchLimit(async () => {
      const saved = makeSelectSubscriptionsForPlanListData(planId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySubscriptions, 'unable to fetch subscriptions')(
        listStateToSliceRequest({ data: saved, ...makeSelectSubscriptionsForPlanListParameters(planId)(getState()) }),
        { signal },
      );
      dispatch(
        storeSubscriptionsForPlanListData({
          parentId: planId,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(storeMultipleSubscription(sliceToMultipleEntities(data.data, extractSubscriptionId)));
      }

      return makeSelectSubscriptionsForPlanListData(planId)(getState());
    }),
  { idGenerator: ({ planId }) => planId },
);

export const { storeSubscriptionsListData, storeSubscriptionsListParameters, markSubscriptionsListDirty } =
  createNormalizedListActions<Subscription, 'Subscriptions', SubscriptionFilterPredicate, SubscriptionSortByAPIModel>(
    NAMESPACE,
    'Subscriptions',
  );

const subscriptionsFetchLimit = pLimit(1);
export const fetchSubscriptions = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscriptions`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    subscriptionsFetchLimit(async () => {
      const saved = makeSelectSubscriptionListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(querySubscriptions, 'unable to fetch subscriptions')(
        listStateToSliceRequest({ data: saved, ...makeSelectSubscriptionListParameters()(getState()) }),
        { signal },
      );
      dispatch(storeSubscriptionsListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectSubscriptionListData()(getState());
    }),
);

export const hintSubscriptionStatusCharge = createAppAsyncThunk(
  `${NAMESPACE}/hintSubscriptionStatusCharge`,
  async (
    { id, hash, newStatus }: { id: string; hash?: string; newStatus: SubscriptionStatusAPIModel },
    { dispatch, getState, signal },
  ) => {
    const subscription = makeSelectSubscription(id)(getState());
    const blockchain = subscription.data?.blockchain;
    if ((!hash || !blockchain) && newStatus !== SubscriptionStatusAPIModel.Cancelled) {
      return loadingDataError<SubscriptionWithActions>('No data');
    }

    const data = await (hash && blockchain
      ? withAPICall(requestHintSubscriptionStatusChanged, 'Unable to update subscription status')(blockchain, hash, {
          signal,
        })
      : withAPICall(requestCancelSubscription, 'Unable to cancel subscription')(id, { signal }));
    if (data.data) {
      dispatch(storeSubscription({ id, data }));
    } else {
      dispatch(markSubscriptionDirty(id));
    }

    return makeSelectSubscription(id)(getState());
  },
  { idGenerator: ({ id }) => id },
);

export const { storeChargesForSubscription, markChargesForSubscriptionDirty } = createSingleActions<
  string[],
  'ChargesForSubscription'
>(NAMESPACE, 'ChargesForSubscription');

const chargesPerSubscriptionFetchLimit = pLimit(1);
export const fetchSubscriptionChargeForSubscription = createAppAsyncThunk(
  `${NAMESPACE}/fetchSubscriptionChargeForSubscription`,
  async ({ force, subscriptionId }: { force?: boolean; subscriptionId: string }, { dispatch, getState, signal }) =>
    chargesPerSubscriptionFetchLimit(async () => {
      const saved = makeSelectChargeIdsForSubscription(subscriptionId)(getState());
      if (!force && !saved.isDirty) {
        if (saved.data) {
          const absent = makeSelectDirtyChargesForSubscriptionIds(saved.data)(getState());
          if (absent.length) {
            await dispatch(fetchMultipleSubscriptionCharge({ ids: absent }));
          }
        }
        return makeSelectChargesForSubscription(subscriptionId)(getState());
      }

      const data = await withApiRequest(
        withFetchAllDataOrThrow(
          (page) => () => querySubscriptionCharges({ filter: { subscriptionId }, page }, { signal }),
        ),
        'unable to fetch charges',
      )();
      dispatch(
        storeChargesForSubscription({
          id: subscriptionId,
          data: mapStoredState(data, (subscriptions) => subscriptions.list.map(extractChargeId)),
        }),
      );

      if (data.data) {
        dispatch(storeMultipleSubscriptionCharge(sliceToMultipleEntities(data.data, extractChargeId)));
      }

      return makeSelectChargesForSubscription(subscriptionId)(getState());
    }),
  { idGenerator: ({ subscriptionId }) => subscriptionId },
);
