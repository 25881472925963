import React from 'react';

import { PaymentTransactionsTable } from '@/features/payment-transactions/components';

import { withPaymentTransactionsLoading } from './hocs';

const PaymentTransactionsCard = withPaymentTransactionsLoading(PaymentTransactionsTable);
const PaymentTransactionsCardMemo = React.memo(PaymentTransactionsCard) as typeof PaymentTransactionsCard;

export default PaymentTransactionsCardMemo;
