import { GlobalOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, List, Space, Tag, Typography } from 'antd';
import { useCallback } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import {
  OperationAddDomain,
  OperationDeleteDomain,
  OperationRefreshMerchantDomains,
  OperationUpdateDomainStatus,
} from '@/features/branding/components';
import { useDomains } from '@/features/branding/hooks';
import type { MerchantDomain } from '@/features/branding/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import { SettingsPageLayout, SettingsTabType } from '@/pages/settings/components';

import { MerchantDomainsBreadcrumb } from './components';

import type React from 'react';

const { Paragraph, Text } = Typography;

type BrandingDomainsPageProps = TestableProps;

const BrandingDomainsPage: React.FC<BrandingDomainsPageProps> = ({ 'data-test': dataTest = 'domains' }) => {
  const {
    data: { data, isDirty },
    loading,
  } = useDomains();

  const renderItem = useCallback(
    (item: MerchantDomain) => (
      <List.Item key={item.id}>
        <List.Item.Meta
          style={{ alignItems: 'center' }}
          avatar={<GlobalOutlined style={{ fontSize: 32 }} />}
          title={
            <Text
              data-test={dataTest && `${dataTest}-title`}
              ellipsis
              copyable
              style={{ width: '100%' }}
              className="ym-hide-content"
            >
              {item.url}
            </Text>
          }
          description={
            <>
              {item.isActive && (
                <Tag color="success">
                  <FormattedMessage id={I18nPageSettings.BRANDING_DOMAINS_DOMAIN_ACTIVE_MESSAGE} />
                </Tag>
              )}
              {!item.isApproved && (
                <Text type="secondary" data-test={dataTest && `${dataTest}-title`} ellipsis style={{ width: '100%' }}>
                  <WarningOutlined />
                  &nbsp;
                  <FormattedMessage id={I18nPageSettings.BRANDING_DOMAINS_DOMAIN_UNAPPROVED_TITLE} />
                </Text>
              )}
            </>
          }
        />
        <Space>
          <OperationUpdateDomainStatus
            data-test={dataTest && `${dataTest}-${item.id}-updateStatus`}
            domainId={item.id}
          />
          <OperationDeleteDomain
            data-test={dataTest && `${dataTest}-${item.id}-delete`}
            domainId={item.id}
            confirmation
          />
        </Space>
      </List.Item>
    ),
    [dataTest],
  );
  return (
    <SettingsPageLayout
      data-test="container"
      selected={SettingsTabType.branding}
      breadcrumb={<MerchantDomainsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
    >
      <Card
        data-test={dataTest && `${dataTest}-card`}
        extra={
          <Space>
            <OperationAddDomain data-test={dataTest && `${dataTest}-addOp`} mode="button" />
            <OperationRefreshMerchantDomains data-test={dataTest && `${dataTest}-refreshOp`} />
          </Space>
        }
      >
        <Paragraph style={{ paddingBottom: '20px' }} data-test={dataTest && `${dataTest}-description`}>
          <FormattedMessageWithMarkup id={I18nPageSettings.BRANDING_DOMAINS_DESCRIPTION} />
        </Paragraph>
        <List<MerchantDomain>
          itemLayout="horizontal"
          dataSource={data}
          renderItem={renderItem}
          loading={loading || isDirty}
        />
      </Card>
    </SettingsPageLayout>
  );
};

export default BrandingDomainsPage;
