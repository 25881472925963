import { useMemo } from 'react';
import { useLocation, Navigate } from 'react-router-dom';

import { FormattedMessage, PageLoading } from '@/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { I18nPageCallback } from '@/generated/i18n/i18n';
import { useNotification, useSingleRun, useStateMountSafe } from '@/hooks';
import { suppressPromise, withFinally } from '@/infrastructure/utils/functions';

import type React from 'react';

const errorNotification = (error: unknown) => {
  if (error === 'RenewalTokenExpired') {
    return <FormattedMessage id={I18nPageCallback.EMAIL_RENEW_ERROR_EXPIRED_TOKEN} />;
  }
  if (error === 'RenewalTokenNotFound' || error === 'RenewalTokenInvalid') {
    return <FormattedMessage id={I18nPageCallback.EMAIL_RENEW_ERROR_INVALID_TOKEN} />;
  }
  return {
    message: <FormattedMessage id={I18nPageCallback.EMAIL_RENEW_ERROR_COMMON_TITLE} />,
    description: <FormattedMessage id={I18nPageCallback.EMAIL_RENEW_ERROR_COMMON_DESCRIPTION} />,
  };
};

const successNotification = () => <FormattedMessage id={I18nPageCallback.EMAIL_RENEW_SUCCESS_MESSAGE} />;

const RenewEmailPage: React.FC = () => {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const { withSingleRun, inProgress } = useSingleRun();
  const companyId = useMemo(() => {
    try {
      const value = params.get('companyId');
      return value ? Number.parseInt(value, 10) : undefined;
    } catch {
      return undefined;
    }
  }, [params]);
  const token = params.get('token');
  const email = params.get('email');
  const [checked, setChecked] = useStateMountSafe(false);
  const { notification, withNotification } = useNotification();
  const {
    confirmEmail: { act, inAction },
  } = useCompanySettingsActions();

  if (!inProgress && !checked && (!companyId || !token || !email)) {
    notification?.error({
      message: <FormattedMessage id={I18nPageCallback.EMAIL_RENEW_ERROR_NO_PARAMETERS_TITLE} />,
      description: <FormattedMessage id={I18nPageCallback.EMAIL_RENEW_ERROR_NO_PARAMETERS_DESCRIPTION} />,
    });
    return <Navigate to="/" />;
  }
  const confirmEmail = withSingleRun(
    withFinally(withNotification(act, { error: errorNotification, success: successNotification }), () => {
      setChecked(true);
    }),
  );
  if (!inAction && !inProgress && !checked) {
    if (companyId && token && email) {
      suppressPromise(confirmEmail({ email, companyId, token }));
    }
  }
  if (!checked) {
    return <PageLoading type="RenewEmailPage" />;
  }
  return <Navigate to="/settings" />;
};

export default RenewEmailPage;
