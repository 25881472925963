import React from 'react';

import { DonationAssetsView } from '@/features/donations/components';
import DonationAssetViewLink from '@/pages/donations/donation-asset-view/components/DonationAssetViewLink';

import { withDonationAssetsDataLoading } from './hocs';

const DonationAssetsCard = withDonationAssetsDataLoading(({ data, ...props }) => (
  <DonationAssetsView {...props} value={data} mode="list" DonationAssetLink={DonationAssetViewLink} />
));

const DonationAssetsCardMemo = React.memo(DonationAssetsCard) as typeof DonationAssetsCard;

export default DonationAssetsCardMemo;
