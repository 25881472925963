import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import ReportSchedulesBreadcrumb from '@/pages/reports/report-schedules/components/ReportSchedulesBreadcrumb';

import ReportScheduleEditLink from '../ReportScheduleEditLink';

import type { ReportScheduleEditBreadcrumbProps } from './types';

const ReportScheduleEditBreadcrumbRaw: React.FC<ReportScheduleEditBreadcrumbProps> = ({
  'data-test': dataTest,
  scheduleId,
  items = [],
  disabled,
}) => (
  <ReportSchedulesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessageWithMarkup
            id={I18nPageReports.REPORT_SCHEDULE_EDIT_COMPONENTS_BREADCRUMB}
            values={{ id: scheduleId }}
          />
        ) : (
          <ReportScheduleEditLink
            data-test={dataTest && `${dataTest}-link`}
            mode="link"
            value={scheduleId}
            title={
              <FormattedMessageWithMarkup
                id={I18nPageReports.REPORT_SCHEDULE_EDIT_COMPONENTS_BREADCRUMB}
                values={{ id: scheduleId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const ReportScheduleEditBreadcrumb = React.memo(ReportScheduleEditBreadcrumbRaw);

export default ReportScheduleEditBreadcrumb;
