import { SettingOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsCompanySummaryLink } from '@/pages/settings/routes';

import type { SettingsLinkProps } from './types';

const SettingsLink: React.FC<SettingsLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settingsCompanySummaryLink()}
    icon={icon ?? <SettingOutlined />}
    title={title ?? <FormattedMessage id={I18nPageSettings.COMPONENTS_LINK} />}
  />
);

const SettingsLinkMemo = React.memo(SettingsLink);

export default SettingsLinkMemo;
