import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import type { UseActualBalances } from '@/features/statistics/hooks/useActualAssetBalance/types';
import { mapStoredState, storedDataError } from '@/infrastructure/model';

import useActualBalances from '../useActualBalances';

export default function useActualAssetBalance(asset: string | undefined): UseActualBalances {
  const balancesState = useActualBalances();
  const data = useMemo(
    () =>
      asset
        ? mapStoredState(balancesState.data, (balances) => ({
            available: balances.available.find((balance) => balance.asset === asset) ?? { asset, value: BigNumber(0) },
            collectable: balances.collectable.find((balance) => balance.asset === asset) ?? {
              asset,
              value: BigNumber(0),
            },
            locked: balances.locked.find((balance) => balance.asset === asset) ?? { asset, value: BigNumber(0) },
          }))
        : storedDataError<NonNullable<UseActualBalances['data']['data']>>('No asset'),
    [asset, balancesState.data],
  );
  return { ...balancesState, data };
}
