import React, { useMemo } from 'react';

import { WithdrawalReconciliationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import {
  SettlementReconciliationAbsentBanner,
  SettlementReconciliationFailedBanner,
  SettlementReconciliationActiveBanner,
  SettlementReconciliationRetryingBanner,
  SettlementReconciliationSucceededBanner,
} from './components';

import type { SettlementReconciliationBannerProps, SettlementReconciliationStatusBannerProps } from './types';

const components: Record<
  WithdrawalReconciliationStatusAPIModel,
  React.FC<SettlementReconciliationStatusBannerProps>
> = {
  [WithdrawalReconciliationStatusAPIModel.Unchecked]: SettlementReconciliationAbsentBanner,
  [WithdrawalReconciliationStatusAPIModel.Active]: SettlementReconciliationActiveBanner,
  [WithdrawalReconciliationStatusAPIModel.Retrying]: SettlementReconciliationRetryingBanner,
  [WithdrawalReconciliationStatusAPIModel.Failed]: SettlementReconciliationFailedBanner,
  [WithdrawalReconciliationStatusAPIModel.Succeeded]: SettlementReconciliationSucceededBanner,
};

const SettlementReconciliationBanner: React.FC<SettlementReconciliationBannerProps> = ({
  'data-test': dataTest,
  data = WithdrawalReconciliationStatusAPIModel.Unchecked,
  companyId,
  settlementId,
  mode,
  style,
  className,
  SettlementReconciliationLink,
}) => {
  const Component = useMemo(() => components[data], [data]);
  return (
    <Component
      data-test={dataTest}
      settlementId={settlementId}
      companyId={companyId}
      mode={mode}
      style={style}
      className={className}
      SettlementReconciliationLink={SettlementReconciliationLink}
    />
  );
};

const SettlementReconciliationBannerMemo = React.memo(SettlementReconciliationBanner);

export default SettlementReconciliationBannerMemo;
