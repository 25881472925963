import { Form, Input } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import type { DescriptionItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const { TextArea } = Input;

const minDescriptionLength = 4;

const DescriptionItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: DescriptionItemProps<Values>) => {
  const { formatMessage } = useIntl();

  return (
    <Form.Item<Values>
      label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_DESCRIPTION} />}
      name={name}
      required={required}
      rules={useMemo(
        () => [
          ...(required
            ? [
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_DESCRIPTION_ITEM_VALIDATION_REQUIRED}
                    />
                  ),
                },
              ]
            : []),
          {
            transform: (v?: string) => v?.trim(),
            min: minDescriptionLength,
            message: (
              <FormattedMessage
                id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_DESCRIPTION_ITEM_VALIDATION_MIN}
                values={{ value: minDescriptionLength }}
              />
            ),
          },
        ],
        [required],
      )}
      {...ItemProps}
    >
      <TextArea
        data-test={dataTest && `${dataTest}-description`}
        rows={4}
        disabled={readonly}
        placeholder={
          !readonly
            ? formatMessage({ id: I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_DESCRIPTION_ITEM_PLACEHOLDER })
            : undefined
        }
        data-gramm="false"
        className="ym-hide-content"
      />
    </Form.Item>
  );
};

const DescriptionItemMemo = React.memo(DescriptionItem) as typeof DescriptionItem;

export default DescriptionItemMemo;
