import useSubscriptionPlan from '@/features/subscription-plans/hooks/useSubscriptionPlan';
import type { SubscriptionPlan } from '@/features/subscription-plans/types';
import { withDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionPlanDataLoading = <
  Original extends { data: SubscriptionPlan } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { planId: string },
>(
  Component: React.ComponentType<Original>,
  hideOnLoading = false,
  hideSpinner = false,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithSubscriptionPlanDataLoading',
  )((props) => {
    const data = useSubscriptionPlan(props.planId);
    return withDataLoading<SubscriptionPlan, Original>({
      ...data,
      hideOnLoading,
      noSpinner: hideSpinner,
      'data-test': props['data-test'],
    })(Component)(props);
  });

export default withSubscriptionPlanDataLoading;
