import { Space } from 'antd';
import React from 'react';

import type { HeaderTitleProps } from './types';

const HeaderTitle: React.FC<HeaderTitleProps> = ({ title, postfix, 'data-test': dataTest }) => (
  <Space
    size={2}
    style={{
      flexGrow: 1,
      alignItems: 'center',
    }}
  >
    <Space size={0} style={{ flexGrow: 1 }} data-test={dataTest && `${dataTest}-title`}>
      {title ?? <>&nbsp;</>}
    </Space>
    {postfix && (
      <Space style={{ justifySelf: 'flex-end' }} size={0} data-test={dataTest && `${dataTest}-postfix`}>
        {postfix}
      </Space>
    )}
  </Space>
);

const HeaderTitleMemo = React.memo(HeaderTitle);

export default HeaderTitleMemo;
