import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSettlementSchedule } from '@/features/settlements/actions';
import { makeSelectSettlementSchedule } from '@/features/settlements/selectors';
import type { SettlementSchedule } from '@/features/settlements/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<SettlementSchedule>, Parameters<typeof fetchSettlementSchedule>[0]> =>
  fetchSettlementSchedule({ force });

const selectFetching = makeSelectPending(fetchSettlementSchedule);
const selectData = makeSelectSettlementSchedule();

export default function useSettlementSchedule() {
  return useAppLoadableData(fetchFactory, selectData, selectFetching);
}
