import { type UseAppListDataView, useAppListDataView } from '@/app/hooks';
import { storeCollectTaskSummaryListParameters } from '@/features/collectable/actions';
import type { CollectTaskFilterPredicate, CollectTaskSummary } from '@/features/collectable/types';
import type { PushCollectSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useCollectTaskSummaryList from './useCollectTaskSummaryList';

export type UseCollectTaskSummaryListView = UseAppListDataView<
  CollectTaskSummary,
  CollectTaskFilterPredicate,
  PushCollectSortByAPIModel
>;

export default function useCollectTaskSummaryListView(): UseCollectTaskSummaryListView {
  return useAppListDataView(useCollectTaskSummaryList(), storeCollectTaskSummaryListParameters);
}
