import type { PayoutBatchDetailed } from '@/features/payouts/hooks';
import { usePayoutBatch } from '@/features/payouts/hooks';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

const withPayoutBatchLoad = <T extends { batch: PayoutBatchDetailed }>(
  Component: React.ComponentType<Omit<T, 'batch'> & { batch: PayoutBatchDetailed }>,
  EmptyComponent: React.ComponentType<Omit<T, 'batch'>>,
) =>
  namedHOC<
    Omit<T, 'batch'> & { batch: PayoutBatchDetailed },
    Omit<T, 'batch'> & { payoutId: string; batchNum: number }
  >(
    Component,
    'WithPayoutBatchLoad',
  )((props) => {
    const { payoutId, batchNum } = props;
    const batch = usePayoutBatch(payoutId, batchNum);
    return batch.data.data ? <Component {...props} batch={batch.data.data} /> : <EmptyComponent {...props} />;
  });

const withPayoutBatch =
  <T extends { batch: PayoutBatchDetailed }>(EmptyComponent: React.ComponentType<Omit<T, 'batch'>> = emptyComponent) =>
  (Component: React.ComponentType<Omit<T, 'batch'> & { batch: PayoutBatchDetailed }>) => {
    const LoadComponent = withPayoutBatchLoad<T>(Component, EmptyComponent);
    return namedHOC<
      Omit<T, 'batch'> & { batch: PayoutBatchDetailed },
      T | (Omit<T, 'batch'> & { payoutId: string; batchNum: number })
    >(
      Component,
      'WithPayoutBatch',
    )((props) => ('batch' in props ? <Component {...props} /> : <LoadComponent {...props} />));
  };

export default withPayoutBatch;
