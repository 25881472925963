import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useCollectTask } from '@/features/collectable/hooks';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { OperationRefreshCollectTaskProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureCollectable.COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_MESSAGE_SUCCESS} />,
  },
};

const OperationRefreshCollectTask: React.FC<OperationRefreshCollectTaskProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  taskId,
}) => {
  const { forceRefresh } = useCollectTask(taskId);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={messages} />;
};

const OperationRefreshCollectTaskMemo = React.memo(OperationRefreshCollectTask);

export default OperationRefreshCollectTaskMemo;
