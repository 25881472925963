import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import type { ReportFilterPredicate } from '@/features/reports/types';
import { I18nFeatureReports } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import ReportStatusFilterItem from '../ReportStatusFilterItem';
import ReportTemplateFilterItem from '../ReportTemplateFilterItem';

import type { ReportFilterFormProps } from './types';

const fields = [
  nameof<ReportFilterPredicate>('createdRange'),
  nameof<ReportFilterPredicate>('statuses'),
  nameof<ReportFilterPredicate>('templates'),
];

const createdAtMessages = {
  to: { label: <FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_FILTER_CREATED_AT_TO_LABEL} /> },
  from: { label: <FormattedMessage id={I18nFeatureReports.COMPONENTS_REPORTS_FILTER_CREATED_AT_FROM_LABEL} /> },
};

const ReportFilterFormRaw: React.FC<ReportFilterFormProps> = (props) => {
  const { 'data-test': dataTest } = props;

  return (
    <FilterForm<ReportFilterPredicate> {...props} fields={fields}>
      <DateTimeRangeItem<ReportFilterPredicate>
        data-test={dataTest && `${dataTest}-createdAt`}
        name={nameof<ReportFilterPredicate>('createdRange')}
        messages={createdAtMessages}
        required={false}
      />
      <ReportStatusFilterItem<ReportFilterPredicate>
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<ReportFilterPredicate>('statuses')}
        required={false}
      />
      <ReportTemplateFilterItem<ReportFilterPredicate>
        data-test={dataTest && `${dataTest}-template`}
        name={nameof<ReportFilterPredicate>('templates')}
        readonly={false}
      />
    </FilterForm>
  );
};

const ReportFilterForm = React.memo(ReportFilterFormRaw);

export default ReportFilterForm;
