import { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useUser } from '@/features/user/hooks';
import type { User } from '@/features/user/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { combine } from '@/infrastructure/model';
import type { JWTTokenInfo } from '@/infrastructure/security/types';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const selectLoginState = makeSelectAuthToken();

const withProfileDataLoading =
  <T extends { data: { token: JWTTokenInfo; user: User } } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'>> =>
  (props) => {
    const dataTest = props['data-test'];
    const tokenState = useAppSelector(selectLoginState);
    const userState = useUser();
    const data = useMemo(
      () => combine(tokenState, userState.data, (token, user) => ({ token: token.info, user })),
      [tokenState, userState.data],
    );

    return withCardDataLoading<{ token: JWTTokenInfo; user: User }, T>({
      forceRefresh: userState.forceRefresh,
      loading: tokenState.isDirty || userState.loading,
      data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_PROFILE_CARD_TITLE} />,
    })(Component)(props);
  };

export default withProfileDataLoading;
