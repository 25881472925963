import { useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { acceptCookies } from '@/features/global/actions';
import { makeSelectCookiesAcceptance } from '@/features/global/selectors';
import { InitStatus } from '@/infrastructure/model';

export interface UseCookiesAgreementType {
  accept: () => Promise<void>;
  accepted: boolean;
  initialized: boolean;
  strict: boolean;
}

const dataSelector = makeSelectCookiesAcceptance();

export default function useCookiesAgreement(): UseCookiesAgreementType {
  const { withVoidDispatch } = useAppDispatch();
  const accept = useMemo(() => withVoidDispatch(acceptCookies), [withVoidDispatch]);
  const { value: accepted, status } = useAppSelector(dataSelector);
  const initialized = status === InitStatus.FINISHED;
  const { data } = useFeatureToggle();
  const strict = !!data.data?.strictCookiePolicy;

  return { accepted, accept, strict, initialized };
}
