import React from 'react';

import { CompaniesList } from '@/features/company/components';

import { withCompaniesDataLoading } from './hocs';

const CompaniesCardLoaded = withCompaniesDataLoading(CompaniesList);
const CompaniesCardMemo = React.memo(CompaniesCardLoaded) as typeof CompaniesCardLoaded;

export default CompaniesCardMemo;
