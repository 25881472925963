import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { GasWalletInvalidStateBanner, type GasWalletStateCheckBannerProps } from '@/features/gas-wallets/components';
import { withSubscriptionPlanDataLoading } from '@/features/subscription-plans/hocs';
import { SubscriptionPlanStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { withPageLayout } from '@/hocs';

import type { SubscriptionPlanGasWalletAlertBannerProps } from './types';

const SubscriptionPlanGasWalletAlertBanner: React.FC<SubscriptionPlanGasWalletAlertBannerProps> = ({
  'data-test': dataTest,
  data,
  pageSize,
}) => {
  const withContainer = useMemo(() => withPageLayout<GasWalletStateCheckBannerProps>(() => ({ pageSize })), [pageSize]);
  return data.status === SubscriptionPlanStatusAPIModel.Active ? (
    <GasWalletInvalidStateBanner
      data-test={dataTest}
      mode="alert"
      withContainer={withContainer}
      assetId={data.amount.asset}
      title={
        <FormattedMessage
          id={I18nFeatureSubscriptionPlans.COMPONENTS_SUBSCRIPTION_PLANS_TABLE_GAS_WALLET_ERROR_MESSAGE}
        />
      }
    />
  ) : null;
};

const SubscriptionPlanGasWalletAlertBannerLoaded = withSubscriptionPlanDataLoading(
  SubscriptionPlanGasWalletAlertBanner,
  true,
  true,
);

const SubscriptionPlanGasWalletAlertBannerMemo = React.memo(
  SubscriptionPlanGasWalletAlertBannerLoaded,
) as typeof SubscriptionPlanGasWalletAlertBannerLoaded;

export default SubscriptionPlanGasWalletAlertBannerMemo;
