import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { usePrevious } from '@/hooks';

import type { UserAlertsPayloadSelectProps } from './types';

const UserAlertsPayloadSelect: React.FC<UserAlertsPayloadSelectProps> = ({ onChange, selector }) => {
  const data = useAppSelector(selector);
  const previous = usePrevious(data);
  if (data !== previous) {
    onChange(data);
  }
  return null;
};

const UserAlertsPayloadSelectMemo = React.memo(UserAlertsPayloadSelect);

export default UserAlertsPayloadSelectMemo;
