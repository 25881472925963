import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum CollectablePageRoutes {
  TASKS = 'tasks',
  TASK_VIEW = 'tasks/:taskId',
}

export const collectableRoute = (page: CollectablePageRoutes) => `${appRoute(AppPageRoutes.COLLECTABLE)}/${page}`;

export const collectTasksLink = () => collectableRoute(CollectablePageRoutes.TASKS);
export const collectTaskViewLink = (taskId: string) => `${collectTasksLink()}/${taskId}`;
