import { Modal } from 'antd';
import React from 'react';

import { CookiesBannerMessage } from './components';

import type { CookiesAgreementBannerProps } from './types';

const CookiesAgreementBanner: React.FC<CookiesAgreementBannerProps> = ({
  'data-test': dataTest,
  strict,
  accepted,
  onAccept,
  children,
}) => (
  <>
    {children}
    {!accepted
      && (strict ? (
        <Modal
          open
          footer={null}
          closable={false}
          styles={{ body: { padding: 0 } }}
          style={{ top: 0, position: 'absolute', width: '100%' }}
          width="100%"
        >
          <CookiesBannerMessage onAccept={onAccept} data-test={dataTest} />
        </Modal>
      ) : (
        <CookiesBannerMessage onAccept={onAccept} data-test={dataTest} />
      ))}
  </>
);

const CookiesAgreementBannerMemo = React.memo(CookiesAgreementBanner);

export default CookiesAgreementBannerMemo;
