import { TagsOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { defaultDialogFormLayout, defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { usePayout, usePayoutActions } from '@/features/payouts/hooks';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useDefaultNotification, useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import { UpdatePayoutTitleForm } from './components';

import type { OperationUpdatePayoutTitleProps } from './types';

const OperationUpdatePayoutTitle: React.FC<OperationUpdatePayoutTitleProps> = ({
  'data-test': dataTest,
  payoutId,
  disabled,
  inProgress,
  onSuccess,
  mode = 'inline',
}) => {
  const { data } = usePayout(payoutId);
  const { updateTitle: updatePayoutAction } = usePayoutActions(payoutId);
  const formControl = useFormVisible(false);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const updatePayout = useMemo(
    () =>
      withDefaultError(
        withSuccess(formControl.withFormHide(withOnSuccess(updatePayoutAction.act, onSuccess ?? noop)), () => ({
          message: (
            <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_SUCCESS_MESSAGE} />
          ),
        })),
        () => <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_FAILED_MESSAGE} />,
      ),
    [formControl, onSuccess, updatePayoutAction, withDefaultError, withSuccess],
  );

  const showForm = formControl.show;

  const initialValues = useMemo(() => ({ title: data.data?.title }), [data.data?.title]);

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_DIALOG_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <UpdatePayoutTitleForm
            values={initialValues}
            layout={defaultDialogFormLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={updatePayout}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_TOOLTIP} />}
        icon={<TagsOutlined />}
        onClick={showForm}
        disabled={disabled || !updatePayoutAction.available}
        inProgress={inProgress || formControl.visible || updatePayoutAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationUpdatePayoutTitleMemo = React.memo(OperationUpdatePayoutTitle);

export default OperationUpdatePayoutTitleMemo;
