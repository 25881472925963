import { jwtDecode } from 'jwt-decode';
import { getAddress } from 'viem';

import { NcpsRoleAPIModel } from '@/generated/api/ncps-api';
import { someOrFail } from '@/infrastructure/utils/functions';
import { enumByKey, notEmpty } from '@/infrastructure/utils/ts';

import type { JwtPayload } from 'jwt-decode';
import type { Address } from 'viem';

export interface JWTTokenInfo {
  iss: string;
  sub: string;
  address: Address;
  roles: NcpsRoleAPIModel[];
  expiresAt: Date;
  activeCompanyId?: number;
}

export interface JWTTokenWithInfo {
  token: string;
  info: JWTTokenInfo;
}

export const parseJWT = (token: string): JWTTokenWithInfo => {
  const parsed = jwtDecode<
    JwtPayload & {
      address?: string;
      roles?: string[];
      activeCompanyId?: string | number;
    }
  >(token);
  const address = getAddress(someOrFail(parsed.address, () => new Error('"address" is absent')));
  const iss = someOrFail(parsed.iss, () => new Error('"iss" is absent'));
  const expiresAt = someOrFail(
    parsed.exp ? new Date(parsed.exp * 1000) : undefined,
    () => new Error('Address is absent'),
  );
  const sub = someOrFail(parsed.sub, () => new Error('"sub" is absent'));
  const roles: NcpsRoleAPIModel[] = (parsed.roles ?? []).map((v) => enumByKey(NcpsRoleAPIModel, v)).filter(notEmpty);
  // eslint-disable-next-line no-nested-ternary
  const activeCompanyId: number | undefined = parsed.activeCompanyId
    ? typeof parsed.activeCompanyId === 'string'
      ? parseInt(parsed.activeCompanyId, 10)
      : parsed.activeCompanyId
    : undefined;

  const info: JWTTokenInfo = {
    address,
    iss,
    expiresAt,
    sub,
    roles,
    activeCompanyId,
  };
  return { token, info };
};
