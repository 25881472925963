import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import SubscriptionsBreadcrumb from '@/pages/subscriptions/all/components/SubscriptionsBreadcrumb';
import SubscriptionLink from '@/pages/subscriptions/subscription-view/components/SubscriptionLink';

import type { SubscriptionBreadcrumbProps } from './types';

const SubscriptionBreadcrumb: React.FC<SubscriptionBreadcrumbProps> = ({
  items = [],
  subscriptionId,
  disabled,
  'data-test': dataTest,
}) => (
  <SubscriptionsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageSubscriptions.SUBSCRIPTION_VIEW_COMPONENTS_BREADCRUMB}
            values={{ id: subscriptionId }}
          />
        ) : (
          <SubscriptionLink
            mode="text"
            value={subscriptionId}
            title={
              <FormattedMessage
                id={I18nPageSubscriptions.SUBSCRIPTION_VIEW_COMPONENTS_BREADCRUMB}
                values={{ id: subscriptionId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const SubscriptionBreadcrumbMemo = React.memo(SubscriptionBreadcrumb);

export default SubscriptionBreadcrumbMemo;
