import { Form, Input } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationDescriptionItemProps } from './types';
import type { FormItemProps } from 'antd/lib/form';
import type { Store } from 'rc-field-form/es/interface';

const { TextArea } = Input;

const maxDescriptionLength = 4096;
const minDescriptionLength = 4;

const DonationDescriptionItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: DonationDescriptionItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const rules = useMemo<FormItemProps<Values>['rules']>(
    () =>
      !readonly
        ? [
            ...(required || ItemProps.required
              ? [
                  {
                    required: true,
                    message: (
                      <FormattedMessage
                        id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_ERRORS_REQUIRED}
                      />
                    ),
                  },
                ]
              : []),
            {
              transform: (value?: string) => value?.trim(),
              max: maxDescriptionLength,
              message: (
                <FormattedMessage
                  id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_ERRORS_MAX_LENGTH}
                  values={{ val: maxDescriptionLength }}
                />
              ),
            },
            {
              transform: (value?: string) => value?.trim(),
              min: minDescriptionLength,
              message: (
                <FormattedMessage
                  id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_ERRORS_MIN_LENGTH}
                  values={{ val: minDescriptionLength }}
                />
              ),
            },
          ]
        : [],
    [ItemProps.required, readonly, required],
  );
  return (
    <Form.Item<Values>
      label={<FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_DESCRIPTION} />}
      name={name}
      hasFeedback={!readonly}
      required={!readonly && (required || ItemProps.required)}
      rules={rules}
    >
      <TextArea
        data-test={dataTest}
        rows={4}
        disabled={readonly}
        placeholder={
          !readonly
            ? formatMessage({
                id: I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_PLACEHOLDER,
              })
            : undefined
        }
        data-gramm="false"
      />
    </Form.Item>
  );
};

const DonationDescriptionItemMemo = React.memo(DonationDescriptionItem) as typeof DonationDescriptionItem;

export default DonationDescriptionItemMemo;
