import Icon from '@ant-design/icons';
import { List, Space } from 'antd';
import React, { useCallback } from 'react';

import DefaultLogo from '@/assets/icons/DefaultLogo';
import { ReadonlyComponent } from '@/components';
import type { Webhook } from '@/features/company-settings/types';

import OperationDeleteWebhook from '../OperationDeleteWebhook';
import OperationUpdateWebhook from '../OperationUpdateWebhook';

import type { WebhooksListProps } from './types';

const WebhooksList: React.FC<WebhooksListProps> = ({ data, 'data-test': dataTest }) => (
  <List<Webhook>
    itemLayout="horizontal"
    dataSource={data}
    renderItem={useCallback(
      (item: Webhook, idx: number) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={<Icon style={{ fontSize: 32 }} component={DefaultLogo} />}
            title={<ReadonlyComponent data-test={dataTest && `${dataTest}-${idx}-value`} value={item.url} />}
          />
          <Space>
            <OperationUpdateWebhook value={item} data-test={dataTest && `${dataTest}-${idx}-update`} />
            <OperationDeleteWebhook value={item.id} data-test={dataTest && `${dataTest}-${idx}-delete`} confirmation />
          </Space>
        </List.Item>
      ),
      [dataTest],
    )}
  />
);

const WebhooksListMemo = React.memo(WebhooksList);

export default WebhooksListMemo;
