import { CloudServerOutlined, CloudUploadOutlined, ExclamationCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { PushContractDeployStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectableContractDeployStatusViewProps } from './types';

const i18n: Record<PushContractDeployStatusAPIModel, I18nMessages> = {
  [PushContractDeployStatusAPIModel.NotDeployed]:
    I18nFeatureCollectable.COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_NOTDEPLOYED,
  [PushContractDeployStatusAPIModel.Deploying]:
    I18nFeatureCollectable.COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_DEPLOYING,
  [PushContractDeployStatusAPIModel.Deployed]:
    I18nFeatureCollectable.COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_DEPLOYED,
  [PushContractDeployStatusAPIModel.Failed]:
    I18nFeatureCollectable.COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_FAILED,
};

const icons: Record<PushContractDeployStatusAPIModel, React.ReactNode> = {
  [PushContractDeployStatusAPIModel.NotDeployed]: <CloudUploadOutlined style={{ color: 'blue' }} />,
  [PushContractDeployStatusAPIModel.Deploying]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <CloudUploadOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [PushContractDeployStatusAPIModel.Deployed]: <CloudServerOutlined style={{ color: 'green' }} />,
  [PushContractDeployStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const CollectableContractDeployStatusView: React.FC<CollectableContractDeployStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const CollectableContractDeployStatusViewMemo = React.memo(CollectableContractDeployStatusView);

export default CollectableContractDeployStatusViewMemo;
