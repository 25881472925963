/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  DonationTransactionAPIModel,
  DonationTransactionFilterAPIModel,
  SliceDonationTransactionPushTransactionSortByAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    DonationTransactionAPIModelFromJSON,
    DonationTransactionAPIModelToJSON,
    DonationTransactionFilterAPIModelFromJSON,
    DonationTransactionFilterAPIModelToJSON,
    SliceDonationTransactionPushTransactionSortByAPIModelFromJSON,
    SliceDonationTransactionPushTransactionSortByAPIModelToJSON,
} from '../models/index';

export interface GetDonationTransactionRequest {
    txId: string;
}

export interface SearchDonationTransactionsRequest {
    donationTransactionFilterAPIModel: DonationTransactionFilterAPIModel;
    withTotal?: boolean;
}

/**
 * DonationTransactionApi - interface
 * 
 * @export
 * @interface DonationTransactionApiInterface
 */
export interface DonationTransactionApiInterface {
    /**
     * Returns donation transaction by id
     * @param {string} txId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationTransactionApiInterface
     */
    getDonationTransactionRaw(requestParameters: GetDonationTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationTransactionAPIModel>>;

    /**
     * Returns donation transaction by id
     */
    getDonationTransaction(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationTransactionAPIModel>;

    /**
     * Returns donation transactions by filter
     * @param {DonationTransactionFilterAPIModel} donationTransactionFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DonationTransactionApiInterface
     */
    searchDonationTransactionsRaw(requestParameters: SearchDonationTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceDonationTransactionPushTransactionSortByAPIModel>>;

    /**
     * Returns donation transactions by filter
     */
    searchDonationTransactions(donationTransactionFilterAPIModel: DonationTransactionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceDonationTransactionPushTransactionSortByAPIModel>;

}

/**
 * 
 */
export class DonationTransactionApi extends runtime.BaseAPI implements DonationTransactionApiInterface {

    /**
     * Returns donation transaction by id
     */
    async getDonationTransactionRaw(requestParameters: GetDonationTransactionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DonationTransactionAPIModel>> {
        if (requestParameters['txId'] == null) {
            throw new runtime.RequiredError(
                'txId',
                'Required parameter "txId" was null or undefined when calling getDonationTransaction().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donation-transactions/{txId}`.replace(`{${"txId"}}`, encodeURIComponent(String(requestParameters['txId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DonationTransactionAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns donation transaction by id
     */
    async getDonationTransaction(txId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DonationTransactionAPIModel> {
        const response = await this.getDonationTransactionRaw({ txId: txId }, initOverrides);
        return await response.value();
    }

    /**
     * Returns donation transactions by filter
     */
    async searchDonationTransactionsRaw(requestParameters: SearchDonationTransactionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceDonationTransactionPushTransactionSortByAPIModel>> {
        if (requestParameters['donationTransactionFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'donationTransactionFilterAPIModel',
                'Required parameter "donationTransactionFilterAPIModel" was null or undefined when calling searchDonationTransactions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/donation-transactions/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DonationTransactionFilterAPIModelToJSON(requestParameters['donationTransactionFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceDonationTransactionPushTransactionSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns donation transactions by filter
     */
    async searchDonationTransactions(donationTransactionFilterAPIModel: DonationTransactionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceDonationTransactionPushTransactionSortByAPIModel> {
        const response = await this.searchDonationTransactionsRaw({ donationTransactionFilterAPIModel: donationTransactionFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
