import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { FormattedMessage } from '@/components';
import { SubscriptionMonthlyGrowthGraph } from '@/features/subscription-statistics/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withMonthlyGrowthRateDataLoading } from '@/pages/subscriptions/statistics/hocs';

export default withMonthlyGrowthRateDataLoading(
  SubscriptionMonthlyGrowthGraph,
  <FormattedMessage id={I18nPageSubscriptions.STATISTICS_COMPONENTS_GROWTH_CARD_TITLE} />,
  <Tooltip
    title={<FormattedMessage id={I18nPageSubscriptions.STATISTICS_COMPONENTS_GROWTH_CARD_HELP} />}
    placement="bottomLeft"
  >
    <QuestionCircleOutlined />
  </Tooltip>,
);
