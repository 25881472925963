import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { rechargeTransactionsLink } from '@/pages/recharges/routes';

import type { RechargeTransactionsLinkProps } from './types';

const RechargeTransactionsLink: React.FC<RechargeTransactionsLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={rechargeTransactionsLink()}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageRecharges.TRANSACTIONS_COMPONENTS_LINK} />}
  />
);

const RechargeTransactionsLinkMemo = React.memo(RechargeTransactionsLink);

export default RechargeTransactionsLinkMemo;
