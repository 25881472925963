import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  EmailItem,
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { UpdateEmailFormProps, UpdateEmailFormValues } from './types';
import type { FormInstance } from 'antd/es/form';

const fields = [nameof<UpdateEmailFormValues>('email')];

const footerMessages = {
  submit: (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_SUBMIT} tagName="span" />
  ),
};

const UpdateEmailForm: React.FC<UpdateEmailFormProps> = ({
  'data-test': dataTest,
  values,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const oldValue = values?.email;
  const { form, withResetForm } = useForm<UpdateEmailFormValues>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: UpdateEmailFormValues): unknown => onSubmit(val.email)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    if (error === 'UserAlreadyExists') {
      return (
        <FormattedMessage
          id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_ERROR_DUPLICATE_EMAIL}
        />
      );
    }
    return <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_ERROR_COMMON} />;
  }, [error]);

  const checkFormIComplete = useCallback(
    ({ getFieldValue }: FormInstance<UpdateEmailFormValues>) =>
      getFieldValue(nameof<UpdateEmailFormValues>('email')) !== oldValue,
    [oldValue],
  );

  return (
    <Form<UpdateEmailFormValues>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={values}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
      <EmailItem<UpdateEmailFormValues>
        name={nameof<UpdateEmailFormValues>('email')}
        data-test={dataTest && `${dataTest}-email`}
      />
      <FormCompletenessItem<UpdateEmailFormValues>
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={checkFormIComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-submit`}
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
        messages={footerMessages}
      />
    </Form>
  );
};

const UpdateEmailFormMemo = React.memo(UpdateEmailForm);

export default UpdateEmailFormMemo;
