import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { I18nFeatureEmail } from '@/generated/i18n/i18n';

import type { EmailCodeDescriptionProps } from './types';

const EmailCodeDescription: React.FC<EmailCodeDescriptionProps> = ({ email, isConfirmed, isSent }) => {
  if (!email) {
    return (
      <FormattedMessageWithMarkup id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_NO_EMAIL} />
    );
  }
  if (isConfirmed) {
    return (
      <FormattedMessageWithMarkup
        id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_CONFIRMED}
        values={{ email }}
      />
    );
  }
  if (isSent) {
    return (
      <FormattedMessageWithMarkup
        id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_SENT}
        values={{ email }}
      />
    );
  }
  return (
    <FormattedMessageWithMarkup
      id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_READY_TO_SEND}
      values={{ email }}
    />
  );
};

const EmailCodeDescriptionMemo = React.memo(EmailCodeDescription) as typeof EmailCodeDescription;

export default EmailCodeDescriptionMemo;
