import { useCallback, useMemo } from 'react';

import { useListAssets, useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { combine, mapStoredState } from '@/infrastructure/model';

import useCompanySettings from '../useCompanySettings';

export default function useCompanyActiveAssets() {
  const { forceRefresh, data: settingsData, ...state } = useCompanySettings();
  const { network } = useSelectedNetwork();
  const { data: assetsData } = useListAssets(network);

  return {
    ...state,
    data: useMemo(
      () =>
        combine(settingsData, assetsData, ({ activeAssets }, assets) =>
          assets.filter(({ code }) => activeAssets.includes(code)),
        ),
      [assetsData, settingsData],
    ),
    forceRefresh: useCallback(
      async () => mapStoredState(await forceRefresh(), ({ activeAssets }) => activeAssets),
      [forceRefresh],
    ),
  };
}
