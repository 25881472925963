import React from 'react';

import { PayoutView } from '@/features/payouts/components';

import { withPayoutDataLoading } from './hocs';

const PayoutCard = withPayoutDataLoading((props) => <PayoutView {...props} columns={1} />);

const PayoutCardMemo = React.memo(PayoutCard) as typeof PayoutCard;

export default PayoutCardMemo;
