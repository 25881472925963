import { useAppNestedFullDataFilteredView } from '@/app/hooks';
import { storeTransfersForRunningBalanceFullDataParameters } from '@/features/merchant-wallet-balance/actions';
import { makeSelectTransfersForRunningBalanceFullData } from '@/features/merchant-wallet-balance/selectors';

import useMerchantWalletRunningBalanceTransfers from './useMerchantWalletRunningBalanceTransfers';

export default function useMerchantWalletRunningBalanceTransfersView(balanceId: string | undefined) {
  return useAppNestedFullDataFilteredView(
    useMerchantWalletRunningBalanceTransfers(balanceId),
    makeSelectTransfersForRunningBalanceFullData,
    storeTransfersForRunningBalanceFullDataParameters,
    balanceId,
  );
}
