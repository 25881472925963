import React from 'react';

import type { SettlementFilterFormProps } from '@/features/settlements/components';
import {
  OperationExportReconciliationBySettlement,
  SettlementFilterForm as BaseSettlementFilterForm,
  SettlementsTable,
} from '@/features/settlements/components';
import { useSettlementForAssetListView } from '@/features/settlements/hooks';

import type { SettlementsAssetTableProps } from './types';

const SettlementFilterForm = (props: Omit<SettlementFilterFormProps, 'excludeAsset'>) => (
  <BaseSettlementFilterForm {...props} excludeAsset />
);

const SettlementsAssetTable: React.FC<SettlementsAssetTableProps> = ({ assetId, ...props }) => {
  const view = useSettlementForAssetListView(assetId);
  return (
    <SettlementsTable
      FilterForm={SettlementFilterForm}
      ExportOperation={OperationExportReconciliationBySettlement}
      {...view}
      {...props}
    />
  );
};

const SettlementsAssetTableMemo = React.memo(SettlementsAssetTable);

export default SettlementsAssetTableMemo;
