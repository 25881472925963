/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { BlockchainTypeAPIModel } from './BlockchainTypeAPIModel';
import {
    BlockchainTypeAPIModelFromJSON,
    BlockchainTypeAPIModelFromJSONTyped,
    BlockchainTypeAPIModelToJSON,
} from './BlockchainTypeAPIModel';
import type { PushKindAPIModel } from './PushKindAPIModel';
import {
    PushKindAPIModelFromJSON,
    PushKindAPIModelFromJSONTyped,
    PushKindAPIModelToJSON,
} from './PushKindAPIModel';

/**
 * 
 * @export
 * @interface CollectableEntityTransactionAPIModel
 */
export interface CollectableEntityTransactionAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CollectableEntityTransactionAPIModel
     */
    id: string;
    /**
     * 
     * @type {PushKindAPIModel}
     * @memberof CollectableEntityTransactionAPIModel
     */
    kind: PushKindAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectableEntityTransactionAPIModel
     */
    hash: string;
    /**
     * 
     * @type {BlockchainTypeAPIModel}
     * @memberof CollectableEntityTransactionAPIModel
     */
    blockchain: BlockchainTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof CollectableEntityTransactionAPIModel
     */
    blockNum: string;
    /**
     * 
     * @type {string}
     * @memberof CollectableEntityTransactionAPIModel
     */
    fromAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CollectableEntityTransactionAPIModel
     */
    addressId: string;
    /**
     * 
     * @type {string}
     * @memberof CollectableEntityTransactionAPIModel
     */
    amount: string;
    /**
     * 
     * @type {Date}
     * @memberof CollectableEntityTransactionAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CollectableEntityTransactionAPIModel
     */
    broadcastAt: Date;
}

/**
 * Check if a given object implements the CollectableEntityTransactionAPIModel interface.
 */
export function instanceOfCollectableEntityTransactionAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('kind' in value)) return false;
    if (!('hash' in value)) return false;
    if (!('blockchain' in value)) return false;
    if (!('blockNum' in value)) return false;
    if (!('fromAddress' in value)) return false;
    if (!('addressId' in value)) return false;
    if (!('amount' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('broadcastAt' in value)) return false;
    return true;
}

export function CollectableEntityTransactionAPIModelFromJSON(json: any): CollectableEntityTransactionAPIModel {
    return CollectableEntityTransactionAPIModelFromJSONTyped(json, false);
}

export function CollectableEntityTransactionAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectableEntityTransactionAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'kind': PushKindAPIModelFromJSON(json['kind']),
        'hash': json['hash'],
        'blockchain': BlockchainTypeAPIModelFromJSON(json['blockchain']),
        'blockNum': json['blockNum'],
        'fromAddress': json['fromAddress'],
        'addressId': json['addressId'],
        'amount': json['amount'],
        'createdAt': (new Date(json['createdAt'])),
        'broadcastAt': (new Date(json['broadcastAt'])),
    };
}

export function CollectableEntityTransactionAPIModelToJSON(value?: CollectableEntityTransactionAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'kind': PushKindAPIModelToJSON(value['kind']),
        'hash': value['hash'],
        'blockchain': BlockchainTypeAPIModelToJSON(value['blockchain']),
        'blockNum': value['blockNum'],
        'fromAddress': value['fromAddress'],
        'addressId': value['addressId'],
        'amount': value['amount'],
        'createdAt': ((value['createdAt']).toISOString()),
        'broadcastAt': ((value['broadcastAt']).toISOString()),
    };
}

