import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useNativeBalance } from '@/features/dictionary/blockchain/hooks';
import { deleteGasWallet, refundGasWallet } from '@/features/gas-wallets/actions';
import { makeSelectGasWalletIsRefunding } from '@/features/gas-wallets/selectors';
import type { GasWallet } from '@/features/gas-wallets/types';
import { useActionPending } from '@/features/global/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useMerchantGasWallet from '../useMerchantGasWallet';

import type { Address } from 'viem';

export type RefundUnavailabilityReason = 'no-data' | 'empty-balance';
export type DeleteUnavailabilityReason = 'no-data' | 'native-balance-unknown' | 'non-empty-balance';

export interface UseMerchantGasWalletActions {
  refund: HookAction<[Address], GasWallet, RefundUnavailabilityReason>;
  deleteWallet: HookAction<[], GasWallet[], DeleteUnavailabilityReason>;
}

export default function useMerchantGasWalletActions(
  bt: BlockchainTypeAPIModel | undefined,
): UseMerchantGasWalletActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useMerchantGasWallet(bt);
  const address = data.data?.address;
  const {
    data: { data: balance, isDirty: isBalanceDirty },
    loading: isBalanceLoading,
  } = useNativeBalance(bt && address && { address, bt });
  const selectRefunding = makeSelectGasWalletIsRefunding(bt);
  const isRefunding = useAppSelector(selectRefunding);
  const refundUnavailabilityReason: RefundUnavailabilityReason | undefined = useMemo(() => {
    if (!bt || !data.data || isBalanceDirty || isBalanceLoading) return 'no-data';
    if (!!balance?.amount.isZero() && data.data.derivedBalance.isZero()) return 'empty-balance';
    return undefined;
  }, [balance?.amount, bt, data.data, isBalanceDirty, isBalanceLoading]);
  const refundAction: UseMerchantGasWalletActions['refund']['act'] = useCallback(
    (to: Address) => withExtractDataDispatch(refundGasWallet)({ bt: bt!, to }),
    [bt, withExtractDataDispatch],
  );
  const refund: UseMerchantGasWalletActions['refund'] = useMemo(
    () => ({
      act: refundAction,
      available: !refundUnavailabilityReason,
      unavailabilityReason: refundUnavailabilityReason,
      inAction: isRefunding,
    }),
    [refundAction, refundUnavailabilityReason, isRefunding],
  );

  const deleteUnavailabilityReason: DeleteUnavailabilityReason | undefined = useMemo(() => {
    if (!bt || !data.data) return 'no-data';
    if (!balance) return 'native-balance-unknown';
    if (!balance.amount.isZero() || !data.data.derivedBalance.isZero()) return 'non-empty-balance';
    return undefined;
  }, [balance, bt, data.data]);
  const deletePending = useActionPending(deleteGasWallet, bt);
  const deleteWalletAction: UseMerchantGasWalletActions['deleteWallet']['act'] = useCallback(
    () => withExtractDataDispatch(deleteGasWallet)(bt!),
    [bt, withExtractDataDispatch],
  );
  const deleteWallet: UseMerchantGasWalletActions['deleteWallet'] = useMemo(
    () => ({
      act: deleteWalletAction,
      available: !deleteUnavailabilityReason && !isRefunding,
      unavailabilityReason: deleteUnavailabilityReason,
      inAction: deletePending,
    }),
    [deleteWalletAction, deleteUnavailabilityReason, isRefunding, deletePending],
  );

  return { refund, deleteWallet };
}
