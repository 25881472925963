import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutDestinationTableEditRowOperationsProps } from './types';

const PayoutDestinationTableEditRowOperations: React.FC<PayoutDestinationTableEditRowOperationsProps> = ({
  'data-test': dataTest,
  disabled,
  data,
  onEdit,
  onDelete,
}) => {
  const doDelete = useCallback(() => onDelete(data), [data, onDelete]);
  const doEdit = useCallback(() => onEdit(data), [data, onEdit]);
  return (
    <Space>
      <Operation
        disabled={disabled}
        title={
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_TITLE}
          />
        }
        icon={<EditOutlined />}
        onClick={doEdit}
        data-test={dataTest && `${dataTest}-edit`}
        mode="inline"
      />
      <Operation
        disabled={disabled}
        confirmation={{
          title: (
            <FormattedMessage
              id={
                I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_DELETE_CONFIRMATION
              }
            />
          ),
        }}
        title={
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_DELETE_TITLE}
          />
        }
        icon={<DeleteOutlined />}
        onClick={doDelete}
        mode="inline"
      />
    </Space>
  );
};

const PayoutDestinationTableEditRowOperationsMemo = React.memo(PayoutDestinationTableEditRowOperations);

export default PayoutDestinationTableEditRowOperationsMemo;
