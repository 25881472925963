import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { ParamsNotFound } from '@/hocs/withPageParams/components';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPageParams =
  <
    K extends string,
    P extends Record<K, unknown>,
    PC extends Record<K, string | undefined> = Record<K, string | undefined>,
    T extends object & { pageParams: P } = { pageParams: P },
  >(
    pageParamsParser: (params: Partial<PC>, props: Omit<T, 'pageParams'>) => P | undefined,
    ErrorPage: React.FC = ParamsNotFound,
  ) =>
  (Component: React.ComponentType<Omit<T, 'pageParams'> & { pageParams: P }>): React.FC<Omit<T, 'pageParams'>> =>
    namedHOC<Omit<T, 'pageParams'> & { pageParams: P }, Omit<T, 'pageParams'>>(
      Component,
      'WithPageParams',
    )((props: Omit<T, 'pageParams'>) => {
      const params = useParams<PC>();
      // TODO: it's unsafe
      const pageParams = useMemo(() => pageParamsParser(params as unknown as Partial<PC>, props), [params, props]);
      return pageParams ? <Component {...props} pageParams={pageParams} /> : <ErrorPage />;
    });

export default withPageParams;
