import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { rechargeAddressAddLink } from '@/pages/recharges/routes';

import type { RechargeAddressAddLinkProps } from './types';

const RechargeAddressAddLink: React.FC<RechargeAddressAddLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(rechargeAddressAddLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <PlusCircleOutlined />}
      title={title ?? <FormattedMessage id={I18nPageRecharges.ADDRESS_ADD_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const RechargeAddressAddLinkMemo = React.memo(RechargeAddressAddLink);

export default RechargeAddressAddLinkMemo;
