import { Form } from 'antd';
import React, { useMemo } from 'react';

import BrandingDataEditPanel from '@/features/branding/components/BrandingDataEditPanel';
import type { BrandingProfileFormValues } from '@/features/branding/components/BrandingProfileForm/types';

import type { BrandingProfileDataViewPanelProps } from './types';

const BrandingProfileDataViewPanel: React.FC<BrandingProfileDataViewPanelProps> = ({
  'data-test': dataTest,
  value,
}) => (
  <Form<BrandingProfileFormValues> initialValues={useMemo(() => ({ ...value, id: 'view', name: 'view' }), [value])}>
    <BrandingDataEditPanel data-test={dataTest} disabled />
  </Form>
);

const BrandingProfileDataViewPanelMemo = React.memo(BrandingProfileDataViewPanel);

export default BrandingProfileDataViewPanelMemo;
