import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import type { NameItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const minDescriptionLength = 4;

const messages = {
  label: <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_NAME} />,
  required: <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_NAME_ITEM_VALIDATION_REQUIRED} />,
};

const rules = [
  {
    transform: (v?: string) => v?.trim(),
    min: minDescriptionLength,
    message: (
      <FormattedMessage
        id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_NAME_ITEM_VALIDATION_MIN}
        values={{ value: minDescriptionLength }}
      />
    ),
  },
];

const NameItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: NameItemProps<Values>) => {
  const { formatMessage } = useIntl();

  return (
    <InputItem<Values>
      data-test={dataTest}
      messages={messages}
      placeholder={formatMessage({ id: I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_NAME_ITEM_PLACEHOLDER })}
      name={name}
      readonly={readonly}
      required={required}
      rules={rules}
      ItemProps={ItemProps}
    />
  );
};

const NameItemMemo = React.memo(NameItem) as typeof NameItem;

export default NameItemMemo;
