import { BgColorsOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import DataBlockContainer from '../DataBlockContainer';

import { AccentColorItem, BackgroundColorItem } from './components';

import type { ColorsBlockProps } from './types';
import type React from 'react';

const ColorsBlock: React.FC<ColorsBlockProps> = ({ 'data-test': dataTest, disabled }) => (
  <DataBlockContainer
    title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_TITLE} />}
    icon={<BgColorsOutlined />}
    help={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_HELP} />}
  >
    <Row gutter={[16, 16]}>
      <Col span={12}>
        <BackgroundColorItem data-test={dataTest && `${dataTest}-bg`} disabled={disabled} />
      </Col>
      <Col span={12}>
        <AccentColorItem data-test={dataTest && `${dataTest}-accent`} disabled={disabled} />
      </Col>
    </Row>
  </DataBlockContainer>
);

export default ColorsBlock;
