import { Input } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import type { MetadataInternalValue } from '@/features/payments/components/CreatePaymentForm/components/MetadataItem/types';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { MetadataInputProps } from './types';
import type { ChangeEventHandler } from 'react';

const { TextArea } = Input;

const MetadataInput: React.FC<MetadataInputProps> = ({ 'data-test': dataTest, value, onChange }) => {
  const { formatMessage } = useIntl();
  const doChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    ({ currentTarget }) => {
      if (!onChange) {
        return;
      }
      const newValue = currentTarget.value;
      const updated: MetadataInternalValue = (() => {
        try {
          const parsed = newValue ? JSON.parse(newValue) : undefined;
          const isValid = typeof parsed === 'object';
          return isValid
            ? { value: parsed, isValid: true, inputValue: newValue }
            : { isValid: false, inputValue: newValue };
        } catch {
          return { isValid: false, inputValue: newValue };
        }
      })();
      onChange(updated);
    },
    [onChange],
  );
  return (
    <TextArea
      data-test={dataTest}
      rows={4}
      placeholder={formatMessage({
        id: I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_METADATA_PLACEHOLDER,
      })}
      className="ym-hide-content"
      data-gramm="false"
      value={value?.inputValue}
      onChange={doChange}
    />
  );
};

const MetadataInputMemo = React.memo(MetadataInput);

export default MetadataInputMemo;
