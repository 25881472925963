import { FormattedMessage } from '@/components';
import { OperationRefreshReportSchedule } from '@/features/report-schedules/components';
import { useReportSchedule } from '@/features/report-schedules/hooks';
import type { ReportSchedule } from '@/features/report-schedules/types';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withReportScheduleDataLoading =
  <T extends { data: ReportSchedule } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { scheduleId: string }> =>
  (props) => {
    const data = useReportSchedule(props.scheduleId);
    const dataTest: string | undefined = props['data-test'];
    return withCardDataLoading<ReportSchedule, T>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageReports.REPORT_SCHEDULE_VIEW_COMPONENTS_REPORT_SCHEDULE_CARD_TITLE}
          values={{ id: props.scheduleId }}
        />
      ),
      CardProps: {
        extra: (
          <OperationRefreshReportSchedule
            scheduleId={props.scheduleId}
            data-test={dataTest && `${dataTest}-opRefresh`}
          />
        ),
      },
    })(Component)(props);
  };

export default withReportScheduleDataLoading;
