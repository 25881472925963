import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { storeEmailCodeState } from '@/features/email/actions';
import { makeSelectEmailCodeState } from '@/features/email/selectors';
import type { EmailCodeState } from '@/features/email/types';

export type UseEmailCodeStateType = EmailCodeState | undefined;

export default function useEmailCodeState(maybeEmail: string | undefined): UseEmailCodeStateType {
  const { withDispatch } = useAppDispatch();
  const state = useAppSelector(useMemo(() => makeSelectEmailCodeState(maybeEmail), [maybeEmail]));
  const updateState = useCallback(
    (email: string) => withDispatch(storeEmailCodeState)({ email, data: { error: 'login.CodeExpired' } }),
    [withDispatch],
  );
  useEffect(() => {
    if (maybeEmail && state?.data?.expiresAt) {
      const diff = -dayjs().diff(state.data.expiresAt, 'ms');
      if (diff > 0) {
        const tm = setTimeout(() => updateState(maybeEmail), diff);
        return () => clearTimeout(tm);
      }

      updateState(maybeEmail);
    }

    return;
  }, [maybeEmail, state?.data?.expiresAt, updateState, withDispatch]);

  return state;
}
