import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import CompanyBreadcrumb from '@/pages/settings/company/components/CompanyBreadcrumb';

import GasWalletsLink from '../GasWalletsLink';

import type { GasWalletsBreadcrumbProps } from './types';

const GasWalletsBreadcrumb: React.FC<GasWalletsBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <CompanyBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLETS_BREADCRUMB_TITLE} />
        ) : (
          <GasWalletsLink
            title={<FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLETS_BREADCRUMB_TITLE} />}
            mode="text"
          />
        ),
      },
      ...items,
    ]}
  />
);

const GasWalletsBreadcrumbMemo = React.memo(GasWalletsBreadcrumb);

export default GasWalletsBreadcrumbMemo;
