import Icon from '@ant-design/icons';
import React from 'react';

import MercuryoIcon from '@/assets/icons/branding/mercuryo-logo.svg?react';
import MoonpayIcon from '@/assets/icons/branding/moonpay-logo.svg?react';
import OnRamperIcon from '@/assets/icons/branding/onramper-logo.svg?react';
import { BrandingOnRamp } from '@/features/branding/types';

import type { BrandingOnRampIconProps } from './types';

const mapping: Record<BrandingOnRamp, typeof MercuryoIcon> = {
  [BrandingOnRamp.moonpay]: MoonpayIcon,
  [BrandingOnRamp.mercuryo]: MercuryoIcon,
  [BrandingOnRamp['on-ramper']]: OnRamperIcon,
};

const BrandingOnRampIcon: React.FC<BrandingOnRampIconProps> = ({ 'data-test': dataTest, value, className, style }) => (
  <Icon component={mapping[value]} className={className} style={style} data-test={dataTest} />
);

const BrandingOnRampIconMemo = React.memo(BrandingOnRampIcon);

export default BrandingOnRampIconMemo;
