import { PageLoading } from '@/components';
import { useActiveCompany } from '@/features/company/hooks';
import { usePreferences } from '@/features/user/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import { OnboardingStep } from '@/pages/onboard/main/types';

import type React from 'react';

const withOnboardingStep = <
  Original extends { currentStep: OnboardingStep },
  Wrapper extends Omit<Original, 'currentStep'>,
>(
  Component: React.FC<Wrapper & { currentStep: OnboardingStep }>,
) =>
  namedHOC<Wrapper & { currentStep: OnboardingStep }, Wrapper>(
    Component,
    'WithOnboardingStep',
  )((props) => {
    const { data: preferences } = usePreferences();
    const { data: company } = useActiveCompany();

    if (!preferences.data && !preferences.error && !company.data && !company.error) {
      return <PageLoading type="withRedirectOnFinish" />;
    }

    const currentStep = !company.data ? OnboardingStep.company : OnboardingStep.congratulations;

    return <Component {...props} currentStep={currentStep} />;
  });

export default withOnboardingStep;
