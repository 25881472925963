import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import {
  generateDonationURL,
  updateDonation,
  updateDonationImage,
  updateDonationStatus,
} from '@/features/donations/actions';
import type { Donation, UpdateDonation } from '@/features/donations/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction, LoadingStateWithDirty } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';

import useDonation from './useDonation';

export type DonationActionUnavailabilityReason = 'no-data';

export interface UseDonationActions {
  data: LoadingStateWithDirty<Donation>;
  generateDonationURL: HookAction<[], string, DonationActionUnavailabilityReason>;
  update: HookAction<[UpdateDonation], Donation, DonationActionUnavailabilityReason>;
  updateImage: HookAction<[string], Donation, DonationActionUnavailabilityReason>;
  updateStatus: HookAction<[boolean], Donation, DonationActionUnavailabilityReason>;
  updateAssetStatus: HookAction<[string, boolean], Donation, DonationActionUnavailabilityReason>;
}

export default function useDonationActions(donationId: string | undefined): UseDonationActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useDonation(donationId);

  const unavailabilityReason = !data.data ? 'no-data' : undefined;

  const isGenerating = useActionPending(generateDonationURL);
  const generateDonationURLAction: UseDonationActions['generateDonationURL']['act'] = useCallback(
    () => withExtractDataDispatch(generateDonationURL)(someOrFail(donationId)),
    [donationId, withExtractDataDispatch],
  );

  const generateDonationURLHook: UseDonationActions['generateDonationURL'] = useMemo(
    () => ({
      act: generateDonationURLAction,
      available: !!data,
      inAction: isGenerating,
      unavailabilityReason,
    }),
    [generateDonationURLAction, data, isGenerating, unavailabilityReason],
  );

  const isUpdating = useActionPending(updateDonation);
  const updateAction: UseDonationActions['update']['act'] = useCallback(
    (donation: Omit<UpdateDonation, 'id'>) =>
      withExtractDataDispatch(updateDonation)({ id: someOrFail(donationId), ...donation }),
    [donationId, withExtractDataDispatch],
  );
  const updateHook: UseDonationActions['update'] = useMemo(
    () => ({
      act: updateAction,
      available: !!data,
      inAction: isUpdating,
      unavailabilityReason,
    }),
    [updateAction, data, isUpdating, unavailabilityReason],
  );

  const isUpdatingImage = useActionPending(updateDonationImage);
  const updateImageAction: UseDonationActions['updateImage']['act'] = useCallback(
    (imageKey: string) => withExtractDataDispatch(updateDonationImage)({ id: someOrFail(donationId), imageKey }),
    [donationId, withExtractDataDispatch],
  );
  const updateImageHook: UseDonationActions['updateImage'] = useMemo(
    () => ({
      act: updateImageAction,
      available: !!data,
      inAction: isUpdatingImage,
      unavailabilityReason,
    }),
    [updateImageAction, data, isUpdatingImage, unavailabilityReason],
  );

  const isUpdatingStatus = useActionPending(updateDonationStatus);
  const updateStatusAction: UseDonationActions['updateStatus']['act'] = useCallback(
    (isActive: boolean) => withExtractDataDispatch(updateDonationStatus)({ id: someOrFail(donationId), isActive }),
    [donationId, withExtractDataDispatch],
  );
  const updateStatusHook: UseDonationActions['updateStatus'] = useMemo(
    () => ({
      act: updateStatusAction,
      available: !!data,
      inAction: isUpdatingStatus,
      unavailabilityReason,
    }),
    [updateStatusAction, data, isUpdatingStatus, unavailabilityReason],
  );

  const updateAssetStatusAction: UseDonationActions['updateAssetStatus']['act'] = useCallback(
    (asset: string, isActive: boolean) => {
      const donation = someOrFail(data.data);
      const activeAssets = donation.addresses.filter((address) => address.isActive).map((address) => address.asset);
      if (activeAssets.includes(asset) ? isActive : !isActive) {
        throw new Error('Invalid status');
      }
      return withExtractDataDispatch(updateDonation)({
        id: donation.id,
        title: donation.title,
        description: donation.description,
        activeAssets: isActive ? [...activeAssets, asset] : activeAssets.filter((old) => old !== asset),
        defaultAmounts: donation.addresses.flatMap(({ defaultAmounts }) => defaultAmounts),
      });
    },
    [data.data, withExtractDataDispatch],
  );
  const updateAssetStatusHook: UseDonationActions['updateAssetStatus'] = useMemo(
    () => ({
      act: updateAssetStatusAction,
      available: !!data,
      inAction: isUpdating,
      unavailabilityReason,
    }),
    [updateAssetStatusAction, data, isUpdating, unavailabilityReason],
  );

  return {
    data,
    update: updateHook,
    updateImage: updateImageHook,
    updateStatus: updateStatusHook,
    updateAssetStatus: updateAssetStatusHook,
    generateDonationURL: generateDonationURLHook,
  };
}
