import { createReducer } from '@reduxjs/toolkit';

import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';

import { storeMaintenance, markMaintenanceDirty } from './actions';

import type { MaintenanceState } from './types';
import type { Draft } from 'immer';

const { storeMaintenanceReducer, markMaintenanceDirtyReducer } = createLoadingDataReducers(
  'Maintenance',
  (state: Draft<MaintenanceState>) => state.isOnMaintenance,
  (global, isOnMaintenance) => ({ ...global, isOnMaintenance }),
);

const initialState: MaintenanceState = {
  isOnMaintenance: storedDirtyData,
};

export const reducer = createReducer(initialState, (builder) => {
  builder.addCase(storeMaintenance, storeMaintenanceReducer).addCase(markMaintenanceDirty, markMaintenanceDirtyReducer);
});

export default reducer;
