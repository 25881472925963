import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageDashboard } from '@/generated/i18n/i18n';

import DashboardLink from '../DashboardLink';

import type { DashboardBreadcrumbProps } from './types';

const DashboardBreadcrumb: React.FC<DashboardBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageDashboard.COMPONENTS_BREADCRUMB} />
        ) : (
          <DashboardLink mode="text" title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_BREADCRUMB} />} />
        ),
      },
      ...items,
    ]}
  />
);

const DashboardBreadcrumbMemo = React.memo(DashboardBreadcrumb);

export default DashboardBreadcrumbMemo;
