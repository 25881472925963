import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { type Account, type Chain, type Client, parseSignature, type Transport, walletActions } from 'viem';

import { useAppDispatch } from '@/app/hooks';
import { createMerchantWalletSignature } from '@/features/merchant-wallets/actions';
import { useUser } from '@/features/user/hooks';
import type { MerchantWalletAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import { type HookAction } from '@/infrastructure/model';
import { someOrFail } from '@/infrastructure/utils/functions';
import { type Cancellable, rejected, rejection } from '@/infrastructure/utils/ui';

import useMerchantWallets from './useMerchantWallets';

export type ConfirmUnavailabilityReason = 'loading' | 'already-confirmed' | 'no-data';

export interface UseMerchantWalletsActionsType {
  confirmOwnership: HookAction<
    [client: Client<Transport, Chain, Account>, { cancellationPromise?: Cancellable }],
    MerchantWalletAPIModel[],
    ConfirmUnavailabilityReason
  >;
}

export default function useMerchantWalletsActions(): UseMerchantWalletsActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const walletsState = useMerchantWallets();

  const { formatMessage } = useIntl();
  const userState = useUser();

  const confirmUnavailabilityReason: UseMerchantWalletsActionsType['confirmOwnership']['unavailabilityReason'] =
    useMemo(() => {
      if (
        userState.data.isDirty
        || (userState.loading && !userState.data.data)
        || walletsState.data.isDirty
        || (walletsState.loading && !walletsState.data.data)
      ) {
        return 'loading';
      }
      if (!userState.data.data) {
        return 'no-data';
      }
      if (walletsState.data.data?.length) {
        return 'already-confirmed';
      }
      return undefined;
    }, [
      userState.data.data,
      userState.data.isDirty,
      userState.loading,
      walletsState.data.data,
      walletsState.data.isDirty,
      walletsState.loading,
    ]);

  const [confirming, withConfirming] = useSubmitting(false);
  const createAction: UseMerchantWalletsActionsType['confirmOwnership']['act'] = useMemo(
    () =>
      withConfirming(async (client, { cancellationPromise } = {}) => {
        const address = someOrFail(
          userState.data.data?.address,
          () => new Error(confirmUnavailabilityReason ?? 'no-data'),
        );
        const signer = client.extend(walletActions);
        const messageToSign = formatMessage(
          { id: I18nFeatureMerchantWallets.HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE },
          { address: address.toLowerCase() },
        );
        const signature = await (async () => {
          try {
            const rawSignature = await (cancellationPromise
              ? Promise.race([signer.signMessage({ message: messageToSign, account: address }), cancellationPromise])
              : await signer.signMessage({ message: messageToSign }));
            if (rawSignature === rejection) {
              throw rejected();
            }
            return parseSignature(rawSignature);
          } catch {
            throw rejected();
          }
        })();
        return withExtractDataDispatch(createMerchantWalletSignature)({
          signature: { ...signature, v: Number(signature.v) },
          message: messageToSign,
        });
      }),
    [confirmUnavailabilityReason, formatMessage, userState.data.data?.address, withConfirming, withExtractDataDispatch],
  );
  const confirmOwnership: UseMerchantWalletsActionsType['confirmOwnership'] = {
    act: createAction,
    available: !confirmUnavailabilityReason,
    unavailabilityReason: confirmUnavailabilityReason,
    inAction: confirming,
  };

  return { confirmOwnership };
}
