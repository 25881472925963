import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { addCompanyUser } from '@/features/company/actions';
import useActiveCompany from '@/features/company/hooks/useActiveCompany';
import type { CompanyUser } from '@/features/company/types';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction } from '@/infrastructure/model';

import type { Address } from 'viem';

type AddUserUnavailabilityReason = 'no-company';

export interface UseCompanyUsersActions {
  add: HookAction<[Address], CompanyUser[], AddUserUnavailabilityReason>;
}

export default function useCompanyUsersActions(): UseCompanyUsersActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useActiveCompany();
  const unavailabilityReason = useMemo<AddUserUnavailabilityReason | undefined>(
    () => (!data.data ? 'no-company' : undefined),
    [data.data],
  );
  const adding = useActionPending(addCompanyUser);
  const addAction: UseCompanyUsersActions['add']['act'] = useCallback(
    (address: Address) => withExtractDataDispatch(addCompanyUser)({ address }),
    [withExtractDataDispatch],
  );
  const add: UseCompanyUsersActions['add'] = useMemo(
    () => ({
      act: addAction,
      inAction: adding,
      available: !unavailabilityReason,
      unavailabilityReason,
    }),
    [adding, addAction, unavailabilityReason],
  );
  return { add };
}
