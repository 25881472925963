import { Space } from 'antd';
import React from 'react';

import { OperationGenerateRechargeURL } from '@/features/recharges/components';

import type { RechargeAddressesTableOperationsProps } from './types';

const RechargeAddressesTableOperations: React.FC<RechargeAddressesTableOperationsProps> = ({
  'data-test': dataTest,
  value: { id },
}) => (
  <Space>
    <OperationGenerateRechargeURL data-test={dataTest && `${dataTest}-generateOp`} rechargeId={id} mode="inline" />
  </Space>
);

const RechargeAddressesTableOperationsMemo = React.memo(RechargeAddressesTableOperations);

export default RechargeAddressesTableOperationsMemo;
