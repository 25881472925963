import { Form, Input, Typography } from 'antd';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { validatePrivateKey } from '@/features/dictionary/blockchain/components/PrivateKeyItem/utils';
import {
  normalizeSeed,
  seedToParts,
  validateSeed as validateSeedBase,
} from '@/features/dictionary/blockchain/components/SeedItem/utils';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { toDerivedXpub, toXprv, xprvToDerivedXpub } from '@/infrastructure/utils/hd';

import type { PrivateKeyOrSeedItemProps, PrivateKeyOrSeedItemValue } from './types';
import type { Store } from 'rc-field-form/es/interface';
import type { ChangeEvent } from 'react';

const { TextArea } = Input;
const { Text } = Typography;

const PrivateKeyOrSeedItemReadonly: React.FC<{
  'data-test'?: string;
  value?: PrivateKeyOrSeedItemValue;
}> = ({ 'data-test': dataTest, value }) => (
  <Text ellipsis copyable style={{ width: '100%' }} data-test={dataTest} className="ym-hide-content">
    {value?.privateKey}
  </Text>
);

const PrivateKeyOrSeedItemEdit: React.FC<{
  'data-test'?: string;
  placeholder: string;
  onChange?: (value: PrivateKeyOrSeedItemValue) => void;
  value?: PrivateKeyOrSeedItemValue;
}> = ({ 'data-test': dataTest, value, onChange, placeholder }) => {
  const doChange = useCallback(
    (event?: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event?.currentTarget.value;
      const seedParts = seedToParts(newValue);
      const isSeed = !!newValue && seedParts.length > 1;
      const seed = seedParts.join(' ');
      if (isSeed) {
        let privateKey;
        try {
          privateKey = toXprv(seed);
        } catch {
          privateKey = undefined;
        }
        onChange?.({ seed: newValue, privateKey });
      } else {
        onChange?.({ privateKey: newValue });
      }
    },
    [onChange],
  );
  return (
    <TextArea
      rows={4}
      name="no-autocomplete"
      autoComplete="off"
      placeholder={placeholder}
      className="ym-hide-content"
      data-test={dataTest}
      data-gramm="false"
      onChange={doChange}
      value={value?.seed || value?.privateKey}
    />
  );
};

const PrivateKeyOrSeedItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  noLabel,
  readonly,
  rules = [],
  required,
  messages = {},
  expected,
  ...rest
}: PrivateKeyOrSeedItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const validateFormat = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (_: unknown, newValue?: PrivateKeyOrSeedItemValue) => {
      if (!newValue) return;
      if (newValue.seed) {
        validateSeedBase(newValue.seed, i18n);
      } else if (newValue.privateKey) {
        validatePrivateKey(newValue.privateKey, i18n);
      }
      // to respect the clean arch this block should be extracted to the separate method
      if (expected?.xpub) {
        const val = newValue.seed || newValue.privateKey;
        if (val) {
          const xpub = newValue.seed ? toDerivedXpub(normalizeSeed(val)) : xprvToDerivedXpub(val);
          if (xpub !== expected.xpub) {
            throw new Error(
              expected.message
                || i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_ERROR_XPUB_INVALID }),
            );
          }
        }
      }
    },
    [expected?.message, expected?.xpub, i18n],
  );

  return (
    <Form.Item<Values>
      name={name}
      label={
        !noLabel
          ? messages.label || i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_LABEL })
          : undefined
      }
      rules={[
        { validator: validateFormat },
        ...(required
          ? [
              {
                required: true,
                message: i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_ERROR_REQUIRED }),
              },
            ]
          : []),
        ...rules,
      ]}
      {...rest}
    >
      {!readonly ? (
        <PrivateKeyOrSeedItemEdit
          placeholder={
            messages.placeholder || i18n({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_PLACEHOLDER })
          }
          data-test={dataTest && `${dataTest}-privateKey`}
        />
      ) : (
        <PrivateKeyOrSeedItemReadonly data-test={dataTest && `${dataTest}-privateKey`} />
      )}
    </Form.Item>
  );
};

const PrivateKeyOrSeedItemMemo = React.memo(PrivateKeyOrSeedItem) as typeof PrivateKeyOrSeedItem;

export default PrivateKeyOrSeedItemMemo;
