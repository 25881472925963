import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { generateUploadURL } from '@/features/dictionary/files/actions';
import type { FileUploadURL } from '@/features/dictionary/files/types';
import { useActionPending } from '@/features/global/hooks';
import { type HookAction } from '@/infrastructure/model';

export interface UseNetworkActionsType {
  generateUploadURL: HookAction<[string], FileUploadURL>;
}

export default function useS3FilesActions(): UseNetworkActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const generating = useActionPending(generateUploadURL);
  const generateUploadURLAction = {
    act: useMemo(() => withExtractDataDispatch(generateUploadURL), [withExtractDataDispatch]),
    available: true,
    inAction: generating,
  };

  return { generateUploadURL: generateUploadURLAction };
}
