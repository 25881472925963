import type { AppRootState, AppAsyncThunkAction } from '@/app/store';
import type { LoadingFullDataState, FullParametersState } from '@/infrastructure/model/full/types';

import useAppLoadableData, { type UseAppLoadableData } from './useAppLoadableData';
import useAppSelector from './useAppSelector';

export type UseAppFullDataFiltered<
  Value,
  Filter,
  SortBy extends string,
  FetchResult = LoadingFullDataState<Value>,
> = UseAppLoadableData<Value[], FetchResult, LoadingFullDataState<Value>> & FullParametersState<Filter, SortBy>;

export default function useAppFullDataFiltered<
  Value,
  Filter,
  SortBy extends string,
  Result = LoadingFullDataState<Value>,
>(
  // this function should fail-safe
  fetchActionFactory:
    | ((force: boolean) => AppAsyncThunkAction<Result, unknown>)
    | ((force: boolean) => Promise<Result>),
  parametersSelector: (state: AppRootState) => FullParametersState<Filter, SortBy>,
  dataFetchingSelector: (state: AppRootState) => boolean,
  dataSelector: (state: AppRootState) => LoadingFullDataState<Value>,
): UseAppFullDataFiltered<Value, Filter, SortBy, Result> {
  const { data, forceRefresh, loading } = useAppLoadableData(fetchActionFactory, dataSelector, dataFetchingSelector);
  const { page, filter, columnState, sortBy } = useAppSelector(parametersSelector);
  return { data, forceRefresh, loading, page, filter, columnState, sortBy };
}
