import { useMemo } from 'react';

import type { APIKeyId } from '@/features/company-settings/types';
import { mapStoredState } from '@/infrastructure/model';

import useAPIKeys from '../useAPIKeys';

export default function useAPIKey(id: APIKeyId) {
  const state = useAPIKeys();
  return useMemo(
    () => ({
      ...state,
      data: mapStoredState(state.data, (keys) => keys.find((key) => key.network === id.network && key.key === id.key)),
    }),
    [id.key, id.network, state],
  );
}
