import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import { SubscriptionsDocumentationBanner } from '@/features/subscriptions/components';
import SubscriptionPlanLink from '@/pages/subscriptions/plan-view/components/SubscriptionPlanLink';
import SubscriptionLink from '@/pages/subscriptions/subscription-view/components/SubscriptionLink';

import { SubscriptionsBreadcrumb, SubscriptionsCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'subscriptions';

const SubscriptionsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<SubscriptionsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test={dataTest && `${dataTest}-kyb`} PageProps={{ pageSize: 'big' }} />
    <SubscriptionsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <SubscriptionsCard
      data-test={dataTest && `${dataTest}-card`}
      SubscriptionLink={SubscriptionLink}
      SubscriptionPlanLink={SubscriptionPlanLink}
    />
  </PageContainer>
);

export default SubscriptionsPage;
