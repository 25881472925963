import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import MerchantWalletTransferEntityLink from '@/pages/audit/transfer-entity/components/MerchantWalletTransferEntityLink';
import MerchantWalletTransferBreadcrumb from '@/pages/audit/transfer-view/components/MerchantWalletTransferBreadcrumb';

import type { MerchantWalletTransferEntityBreadcrumbProps } from './types';

const MerchantWalletTransferEntityBreadcrumbRaw: React.FC<MerchantWalletTransferEntityBreadcrumbProps> = ({
  items = [],
  transferId,
  disabled,
  'data-test': dataTest,
}) => (
  <MerchantWalletTransferBreadcrumb
    data-test={dataTest}
    transferId={transferId}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_BREADCRUMB} values={{ id: transferId }} />
        ) : (
          <MerchantWalletTransferEntityLink
            value={transferId}
            mode="text"
            title={
              <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_BREADCRUMB} values={{ id: transferId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const MerchantWalletTransferEntityBreadcrumb = React.memo(MerchantWalletTransferEntityBreadcrumbRaw);

export default MerchantWalletTransferEntityBreadcrumb;
