import { Form } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';
import PasswordItem from '../PasswordItem';

import type { PasswordConfirmItemProps } from './types';
import type { Rule, Store } from 'rc-field-form/es/interface';

const PasswordConfirmItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  passwordName,
  placeholder,
  messages,
  required,
  readonly,
  ItemProps = {},
}: PasswordConfirmItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const { getFieldValue } = Form.useFormInstance();
  const rules = useMemo<Rule[]>(
    () => [
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_, value) => {
          const password: unknown = passwordName && getFieldValue(passwordName);
          if (password !== value) {
            throw new Error(formatMessage({ id: I18nComponents.PASSWORD_CONFIRM_MESSAGE_DIFFERENT }));
          }
        },
      },
    ],
    [formatMessage, getFieldValue, passwordName],
  );
  return (
    <PasswordItem<Values>
      data-test={dataTest}
      isNew
      name={name}
      dependencies={[passwordName]}
      placeholder={placeholder || formatMessage({ id: I18nComponents.PASSWORD_CONFIRM_PLACEHOLDER })}
      ItemProps={useMemo(
        () => ({
          label: messages?.label ?? <FormattedMessage id={I18nComponents.PASSWORD_CONFIRM_LABEL} />,
          validateTrigger: ['onBlur', 'onChange'],
        }),
        [messages?.label],
      )}
      rules={rules}
      required={required}
      readonly={readonly}
      {...ItemProps}
    />
  );
};

const PasswordConfirmItem = React.memo(PasswordConfirmItemRaw) as typeof PasswordConfirmItemRaw;

export default PasswordConfirmItem;
