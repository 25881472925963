/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewRechargeAddressAPIModel
 */
export interface NewRechargeAddressAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewRechargeAddressAPIModel
     */
    cid: string;
    /**
     * 
     * @type {string}
     * @memberof NewRechargeAddressAPIModel
     */
    asset: string;
}

/**
 * Check if a given object implements the NewRechargeAddressAPIModel interface.
 */
export function instanceOfNewRechargeAddressAPIModel(value: object): boolean {
    if (!('cid' in value)) return false;
    if (!('asset' in value)) return false;
    return true;
}

export function NewRechargeAddressAPIModelFromJSON(json: any): NewRechargeAddressAPIModel {
    return NewRechargeAddressAPIModelFromJSONTyped(json, false);
}

export function NewRechargeAddressAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewRechargeAddressAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'cid': json['cid'],
        'asset': json['asset'],
    };
}

export function NewRechargeAddressAPIModelToJSON(value?: NewRechargeAddressAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'cid': value['cid'],
        'asset': value['asset'],
    };
}

