import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectGasHistoryListParameters: baseMakeSelectGasHistoryListParameters,
  makeSelectGasHistoryListData,
  makeSelectGasHistory,
  makeSelectMultipleGasHistory,
  makeSelectDirtyGasHistoryIds,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state: AppRootState) => state[NAMESPACE].entities,
  'GasHistory' as const,
  undefined,
);

export const makeSelectGasHistoryListParameters: typeof baseMakeSelectGasHistoryListParameters = () =>
  createSelector(
    baseMakeSelectGasHistoryListParameters(),
    makeSelectSelectedNetwork(),
    ({ filter, ...parameters }, networkEq): ReturnType<ReturnType<typeof baseMakeSelectGasHistoryListParameters>> => ({
      ...parameters,
      filter: { ...filter, networkEq },
    }),
  );
