import { useMemo } from 'react';

import type { UseAppFullData } from '@/app/hooks';
import { useAppFullData } from '@/app/hooks';
import { fetchBlockchains } from '@/features/dictionary/blockchain/actions';
import { makeSelectBlockchainFullData } from '@/features/dictionary/blockchain/selectors';
import type { BlockchainInfo } from '@/features/dictionary/blockchain/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { mapStoredState } from '@/infrastructure/model';
import type { LoadingFullDataState } from '@/infrastructure/model/full/types';

export type UseListBlockchainsType = UseAppFullData<BlockchainInfo>;

const dataSelector = makeSelectBlockchainFullData();
const dataFetchingSelector = makeSelectPending(fetchBlockchains);
const fetchFactory = (force: boolean) => fetchBlockchains({ force });

export default function useListBlockchains(network?: BlockchainNetworkTypeAPIModel): UseListBlockchainsType {
  const fullData = useAppFullData<BlockchainInfo, LoadingFullDataState<BlockchainInfo>>(
    fetchFactory,
    dataFetchingSelector,
    dataSelector,
  );
  const data = useMemo(
    () => (network ? mapStoredState(fullData.data, (bcs) => bcs.filter(({ net }) => net === network)) : fullData.data),
    [fullData.data, network],
  );
  return useMemo(() => ({ ...fullData, data }), [data, fullData]);
}
