import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageGasHistory } from '@/generated/i18n/i18n';
import { gasHistoryLink } from '@/pages/gas-history/routes';

import type { GasHistoryLinkProps } from './types';

const GasHistoryLinkPropsRaw: React.FC<GasHistoryLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  icon,
  title,
}) => (
  <Link
    data-test={dataTest}
    to={gasHistoryLink()}
    mode={mode}
    title={title || <FormattedMessage id={I18nPageGasHistory.ALL_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const GasHistoryLink = React.memo(GasHistoryLinkPropsRaw);

export default GasHistoryLink;
