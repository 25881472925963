import type {
  CompanyBrandingProfileAPIModel,
  CompanyBrandingResponseAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { CompanyBrandingApi, CompanyDomainsApi } from '@/generated/api/ncps-core/merchant-bo';
import { coreConfigurationFactory } from '@/infrastructure/request';

import type { PaymentsBranding, BrandingProfile, MerchantDomain, MerchantBrandingProfileFull } from './types';

const brandingApi = new CompanyBrandingApi(coreConfigurationFactory('auth'));
const domainsApi = new CompanyDomainsApi(coreConfigurationFactory('auth'));

interface BrandingResponse {
  activeProfile?: string;
  profiles: BrandingProfile[];
}

const parseBrandingResponse = ({ profiles, ...rest }: CompanyBrandingResponseAPIModel): BrandingResponse => ({
  ...rest,
  profiles: profiles ?? [],
});

const parseBrandingProfile = ({ data, ...branding }: CompanyBrandingProfileAPIModel): MerchantBrandingProfileFull => ({
  ...branding,
  data: data as PaymentsBranding,
});

export const queryBrandingProfiles = async (initOverrides?: RequestInit): Promise<BrandingResponse> =>
  parseBrandingResponse(await brandingApi.getBrandingProfiles(initOverrides));

export const requestActivateBrandingProfile = async (
  profileId: string,
  initOverrides?: RequestInit,
): Promise<BrandingResponse> =>
  parseBrandingResponse(await brandingApi.activateBrandingProfile(profileId, initOverrides));
export const requestDeactivateBrandingProfile = async (
  profileId: string,
  initOverrides?: RequestInit,
): Promise<BrandingResponse> =>
  parseBrandingResponse(await brandingApi.deactivateBrandingProfile(profileId, initOverrides));

export const queryBrandingProfile = async (
  profileId: string,
  initOverrides?: RequestInit,
): Promise<MerchantBrandingProfileFull> =>
  parseBrandingProfile(await brandingApi.getBrandingProfile(profileId, initOverrides));

export const requestUpdateBrandingProfile = async (
  profileId: string,
  name: string,
  data: PaymentsBranding,
  initOverrides?: RequestInit,
): Promise<BrandingResponse> =>
  parseBrandingResponse(await brandingApi.updateBrandingProfile(profileId, { name, data }, initOverrides));

export const requestCreateBrandingProfile = async (
  name: string,
  data: PaymentsBranding,
  initOverrides?: RequestInit,
): Promise<MerchantBrandingProfileFull> =>
  parseBrandingProfile(await brandingApi.createBrandingProfile({ name, data }, initOverrides));

export const requestDeleteBrandingProfile = async (
  profileId: string,
  initOverrides?: RequestInit,
): Promise<BrandingResponse> =>
  parseBrandingResponse(await brandingApi.deleteBrandingProfile(profileId, initOverrides));

export const queryToS = async (initOverrides?: RequestInit): Promise<string> =>
  (await brandingApi.getTermsOfService(initOverrides)).content;

export const requestSaveToS = async (content: string, initOverrides?: RequestInit): Promise<string> => {
  await brandingApi.updateTermsOfService({ content }, initOverrides);
  return content;
};

export const requestDeleteToS = async (initOverrides?: RequestInit): Promise<void> =>
  brandingApi.deleteTermsOfService(initOverrides);

export const queryDomains = async (initOverrides?: RequestInit): Promise<MerchantDomain[]> =>
  (await domainsApi.getCompanyDomains(initOverrides)).domains ?? [];

export const requestCreateDomain = async (url: string, initOverrides?: RequestInit): Promise<MerchantDomain[]> =>
  (await domainsApi.createCompanyDomain({ url }, initOverrides)).domains ?? [];

export const requestUpdateDomainStatus = async (
  id: string,
  active: boolean,
  initOverrides?: RequestInit,
): Promise<MerchantDomain[]> =>
  (
    await (active
      ? domainsApi.activateCompanyDomain(id, initOverrides)
      : domainsApi.deactivateCompanyDomain(id, initOverrides))
  ).domains ?? [];

export const requestDeleteDomain = async (id: string, initOverrides?: RequestInit): Promise<MerchantDomain[]> =>
  (await domainsApi.deleteCompanyDomain(id, initOverrides)).domains ?? [];
