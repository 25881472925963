import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeSettlementsListParameters } from '@/features/settlements/actions';
import useSettlementList from '@/features/settlements/hooks/useSettlementList';
import type { Settlement, SettlementFilterPredicate } from '@/features/settlements/types';
import type { WithdrawalSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

export type UseSettlementListView = UseAppListDataView<Settlement, SettlementFilterPredicate, WithdrawalSortByAPIModel>;

export default function useSettlementListView(): UseSettlementListView {
  const state = useSettlementList();
  return useAppListDataView(state, storeSettlementsListParameters);
}
