import { CloudDownloadOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useMerchantGasWalletActions } from '@/features/gas-wallets/hooks';
import { I18nCommon, I18nFeatureGasWallets } from '@/generated/i18n/i18n';
import type { NotificationOptions } from '@/hooks';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import { GasWalletRefundDialog } from './components';

import type { OperationRefundGasWalletProps } from './types';

const options: NotificationOptions = {
  error: () => ({
    message: <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_MESSAGE_ERROR} />,
    description: <FormattedMessage id={I18nCommon.MESSAGES_NOTIFICATIONS_FAILED_DESCRIPTION} />,
  }),
  success: () => <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_MESSAGE_SUCCESS} />,
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const OperationRefundGasWallet: React.FC<OperationRefundGasWalletProps> = ({
  'data-test': dataTest,
  bt,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { refund: refundGasWalletAction } = useMerchantGasWalletActions(bt);
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const doRefund = useMemo(
    () => withNotification(formControl.withFormHide(withOnSuccess(refundGasWalletAction.act, onSuccess)), options),
    [withNotification, formControl, refundGasWalletAction, onSuccess],
  );

  const showForm = formControl.show;

  const disabledMessage = useMemo(() => {
    if (refundGasWalletAction.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_UNAVAILABILITY_NO_DATA} />;
    }
    if (refundGasWalletAction.unavailabilityReason === 'empty-balance') {
      return (
        <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_UNAVAILABILITY_EMPTY_BALANCE} />
      );
    }
    return undefined;
  }, [refundGasWalletAction.unavailabilityReason]);

  return (
    <>
      {formControl.visible && (
        <GasWalletRefundDialog
          layout={layout}
          bt={bt}
          tailLayout={defaultDialogFormTailLayout}
          data-test={dataTest && `${dataTest}-form`}
          onCancel={formControl.hide}
          onSubmit={doRefund}
        />
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_REFUND_WALLET_TITLE} />}
        icon={<CloudDownloadOutlined />}
        disabled={disabled || !refundGasWalletAction.available}
        disabledMessage={disabledMessage}
        onClick={showForm}
        inProgress={inProgress || formControl.visible || refundGasWalletAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationRefundGasWalletMemo = React.memo(OperationRefundGasWallet);

export default OperationRefundGasWalletMemo;
