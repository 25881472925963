/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PageSubscriptionSortByAPIModel } from './PageSubscriptionSortByAPIModel';
import {
    PageSubscriptionSortByAPIModelFromJSON,
    PageSubscriptionSortByAPIModelFromJSONTyped,
    PageSubscriptionSortByAPIModelToJSON,
} from './PageSubscriptionSortByAPIModel';
import type { SubscriptionSummaryAPIModel } from './SubscriptionSummaryAPIModel';
import {
    SubscriptionSummaryAPIModelFromJSON,
    SubscriptionSummaryAPIModelFromJSONTyped,
    SubscriptionSummaryAPIModelToJSON,
} from './SubscriptionSummaryAPIModel';

/**
 * 
 * @export
 * @interface SliceSubscriptionSummarySubscriptionSortByAPIModel
 */
export interface SliceSubscriptionSummarySubscriptionSortByAPIModel {
    /**
     * 
     * @type {Array<SubscriptionSummaryAPIModel>}
     * @memberof SliceSubscriptionSummarySubscriptionSortByAPIModel
     */
    list?: Array<SubscriptionSummaryAPIModel>;
    /**
     * 
     * @type {PageSubscriptionSortByAPIModel}
     * @memberof SliceSubscriptionSummarySubscriptionSortByAPIModel
     */
    page: PageSubscriptionSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceSubscriptionSummarySubscriptionSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceSubscriptionSummarySubscriptionSortByAPIModel interface.
 */
export function instanceOfSliceSubscriptionSummarySubscriptionSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceSubscriptionSummarySubscriptionSortByAPIModelFromJSON(json: any): SliceSubscriptionSummarySubscriptionSortByAPIModel {
    return SliceSubscriptionSummarySubscriptionSortByAPIModelFromJSONTyped(json, false);
}

export function SliceSubscriptionSummarySubscriptionSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceSubscriptionSummarySubscriptionSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(SubscriptionSummaryAPIModelFromJSON)),
        'page': PageSubscriptionSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceSubscriptionSummarySubscriptionSortByAPIModelToJSON(value?: SliceSubscriptionSummarySubscriptionSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(SubscriptionSummaryAPIModelToJSON)),
        'page': PageSubscriptionSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

