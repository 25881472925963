import React from 'react';

import { DonationAssetsList, DonationAssetsTags } from './components';

import type { DonationAssetsViewProps } from './types';

const DonationAssetsView: React.FC<DonationAssetsViewProps> = ({
  'data-test': dataTest,
  value,
  donationId,
  mode = 'list',
  DonationAssetLink,
}) =>
  mode == 'list' ? (
    <DonationAssetsList
      data-test={dataTest}
      data={value}
      donationId={donationId}
      DonationAssetLink={DonationAssetLink}
    />
  ) : (
    <DonationAssetsTags data-test={dataTest} data={value} onlyActive={mode === 'active-tags'} />
  );

const DonationAssetsViewMemo = React.memo(DonationAssetsView);

export default DonationAssetsViewMemo;
