import { useCallback, useContext } from 'react';

import { FormattedMessage, PageLoading } from '@/components';
import Web3AuthPrivateKeySecure from '@/features/auth/components/Web3AuthPrivateKeySecure';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useAuthActions } from '@/features/auth/hooks';
import { usePreferences, usePreferencesActions } from '@/features/user/hooks';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayoutCardContainer from '@/layouts/components/MessageLayoutCardContainer';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const withWeb3AuthPrivateKeySecure = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthPrivateKeySecure',
  )((props) => {
    const ctx = useContext(Web3AuthContext);
    const { logout } = useAuthActions();
    const doLogout = useCallback(() => logout.act(true), [logout]);
    const { data: preferences } = usePreferences();
    const { persist } = usePreferencesActions();
    const isPreferencesActual = !!preferences.data && !preferences.isDirty;
    const isPrivateKeySecured = !!preferences.data?.privateKeySecured;
    const [submitting, withSubmitting] = useSubmitting(false);
    const onConfirm = useCallback(
      () => withSubmitting(persist.act)({ privateKeySecured: true }),
      [withSubmitting, persist],
    );
    if (logout.inAction) {
      return (
        <MessageLayout>
          <PageLoading type="withWeb3AuthPrivateKeySecure" />
        </MessageLayout>
      );
    }

    if (submitting || (ctx.web3Auth?.state && isPreferencesActual && !isPrivateKeySecured)) {
      return (
        <MessageLayout>
          <MessageLayoutCardContainer
            data-test="private-key-card"
            title={<FormattedMessage id={I18nFeatureAuth.HOOKS_WEB3AUTH_LIFECYCLE_PRIVATE_KEY_TITLE} />}
          >
            {ctx.web3Auth?.state && persist.available ? (
              <Web3AuthPrivateKeySecure
                data-test="private-key"
                value={ctx.web3Auth.state.privateKey}
                onConfirm={onConfirm}
                onLogout={doLogout}
              />
            ) : (
              <PageLoading type="withWeb3AuthPrivateKeySecure" />
            )}
          </MessageLayoutCardContainer>
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withWeb3AuthPrivateKeySecure;
