import { FileSyncOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSettlementReconciliationActions } from '@/features/settlement-reconciliations/hooks';
import { I18nFeatureSettlementReconciliations } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationRecalculateSettlementReconciliationProps } from './types';

const OperationRecalculateSettlementReconciliation: React.FC<OperationRecalculateSettlementReconciliationProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  settlementId,
  onSuccess,
  disabled,
}) => {
  const { recalculate: recalculateAction } = useSettlementReconciliationActions(settlementId);

  const { withNotification } = useNotification();

  const recalculate = useMemo(
    () =>
      withSuppressError(
        withOnSuccess(
          withNotification(recalculateAction.act, {
            success: () => (
              <FormattedMessage
                id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_RECALCULATE_SUCCESS_MESSAGE}
              />
            ),
            error: () => (
              <FormattedMessage
                id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_RECALCULATE_FAILED_MESSAGE}
              />
            ),
          }),
          onSuccess ?? noop,
        ),
      ),
    [withNotification, onSuccess, recalculateAction],
  );

  const disabledMessage = useMemo(() => {
    if (recalculateAction.unavailabilityReason === 'no-data') {
      return (
        <FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_RECALCULATE_DISABLED_NO_DATA} />
      );
    }
    if (recalculateAction.unavailabilityReason === 'invalid-status') {
      return (
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_RECALCULATE_DISABLED_INVALID_STATUS}
        />
      );
    }
    if (recalculateAction.unavailabilityReason === 'too-late') {
      return (
        <FormattedMessage
          id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_RECALCULATE_DISABLED_TOO_LATE}
        />
      );
    }
    return undefined;
  }, [recalculateAction.unavailabilityReason]);

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_RECALCULATE_TITLE} />}
      tooltip={<FormattedMessage id={I18nFeatureSettlementReconciliations.COMPONENTS_OPERATION_RECALCULATE_TOOLTIP} />}
      icon={<FileSyncOutlined />}
      onClick={recalculate}
      disabled={disabled || !recalculateAction.available}
      disabledMessage={disabledMessage}
      inProgress={recalculateAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationRecalculateSettlementReconciliationMemo = React.memo(
  OperationRecalculateSettlementReconciliation,
) as typeof OperationRecalculateSettlementReconciliation;

export default OperationRecalculateSettlementReconciliationMemo;
