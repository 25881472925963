import { PlayCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSubscription } from '@/features/subscriptions/hooks';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';

import { UnpauseSubscriptionModal } from './components';

import type { OperationUnpauseSubscriptionProps } from './types';

const OperationUnpauseSubscription: React.FC<OperationUnpauseSubscriptionProps> = ({
  'data-test': dataTest,
  subscriptionId,
  mode,
}) => {
  const formControl = useFormVisible(false);

  const { data: subscription } = useSubscription(subscriptionId);

  const unpauseUnavailabilityReason = useMemo(() => {
    if (!subscription.data?.actions.unpausable) {
      return (
        <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_INVALID_STATUS} />
      );
    }
    return undefined;
  }, [subscription.data?.actions.unpausable]);

  return (
    <>
      {formControl.visible && subscription.data && (
        <UnpauseSubscriptionModal
          subscription={subscription.data}
          onSuccess={formControl.hide}
          onCancel={formControl.hide}
        />
      )}
      <Operation
        data-test={dataTest && `${dataTest}-unpause`}
        title={<FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_TITLE} />}
        mode={mode}
        icon={<PlayCircleOutlined style={{ color: 'green' }} />}
        disabled={!!unpauseUnavailabilityReason}
        disabledMessage={unpauseUnavailabilityReason}
        onClick={formControl.show}
      />
    </>
  );
};

const OperationUnpauseSubscriptionMemo = React.memo(OperationUnpauseSubscription);

export default OperationUnpauseSubscriptionMemo;
