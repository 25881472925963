import { Form } from 'antd';
import React from 'react';

import { QRSupportInput } from './components';

import type { QRSupportItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const QRSupportItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  ...rest
}: QRSupportItemProps<Values>) => (
  <Form.Item<Values> data-test={dataTest && `${dataTest}-item`} name={name}>
    <QRSupportInput data-test={dataTest && `${dataTest}-input`} {...rest} />
  </Form.Item>
);

const QRSupportItemMemo = React.memo(QRSupportItem) as typeof QRSupportItem;

export default QRSupportItemMemo;
