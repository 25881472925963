import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchSubscription } from '@/features/subscriptions/actions';
import { makeSelectSubscription } from '@/features/subscriptions/selectors';
import type { SubscriptionWithActions } from '@/features/subscriptions/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataError } from '@/infrastructure/model';

export type UseSubscription = UseAppSingleData<SubscriptionWithActions>;

const noData = storedDataError<SubscriptionWithActions>('no-data');

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<SubscriptionWithActions>, Parameters<typeof fetchSubscription>[0]> =>
  fetchSubscription({ id, force });

export default function useSubscription(id: string | undefined): UseSubscription {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSubscription, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectSubscription, noData, id, dataFetchingSelector);
}
