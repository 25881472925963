import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';

import type { RechargeCIdItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_LABEL} />,
  required: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_ERRORS_REQUIRED} />,
};

const maxTitleLength = 1024;
const minTitleLength = 3;

const rules = [
  {
    transform: (value?: string) => value?.trim(),
    max: maxTitleLength,
    message: (
      <FormattedMessage
        id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_ERRORS_MAX_LENGTH}
        values={{ val: maxTitleLength }}
      />
    ),
  },
  {
    transform: (value?: string) => value?.trim(),
    min: minTitleLength,
    message: (
      <FormattedMessage
        id={I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_ERRORS_MIN_LENGTH}
        values={{ val: minTitleLength }}
      />
    ),
  },
];

const RechargeCIdItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  required,
  readonly,
  ItemProps,
}: RechargeCIdItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<Values>
      name={name}
      required={required}
      readonly={readonly}
      messages={messages}
      data-test={dataTest}
      rules={rules}
      placeholder={formatMessage({
        id: I18nFeatureRecharges.COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_PLACEHOLDER,
      })}
      ItemProps={ItemProps}
    />
  );
};

const RechargeCIdItemMemo = React.memo(RechargeCIdItem) as typeof RechargeCIdItem;

export default RechargeCIdItemMemo;
