import { Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { BrandingLang } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import BrandingLangLabel from '../BrandingLangLabel';

import type { BrandingLangSelectProps } from './types';

const BrandingLangSelect: React.FC<BrandingLangSelectProps> = ({
  'data-test': dataTest,
  style,
  className,
  readonly,
  multiselect,
  onChange,
  value,
}) => {
  const options = useMemo(
    () =>
      multiselect
        ? enumValues(BrandingLang).map((val) => ({
            value: val,
            inline: <BrandingLangLabel data-test={dataTest && `${dataTest}-selected-${val}`} value={val} mode="full" />,
            label: <BrandingLangLabel data-test={dataTest && `${dataTest}-value-${val}`} value={val} mode="full" />,
          }))
        : enumValues(BrandingLang).map((val) => ({
            value: val,
            label: <BrandingLangLabel data-test={dataTest && `${dataTest}-value-${val}`} value={val} mode="full" />,
          })),
    [dataTest, multiselect],
  );
  return multiselect ? (
    <Select<BrandingLang[]>
      data-test={dataTest}
      style={style}
      mode="tags"
      className={className}
      disabled={readonly}
      onChange={onChange}
      value={value}
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_LANG_SELECT_PLACEHOLDER} />}
      optionLabelProp="inline"
      options={options}
    />
  ) : (
    <Select<BrandingLang>
      data-test={dataTest}
      style={style}
      className={className}
      disabled={readonly}
      onChange={onChange}
      value={value}
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_LANG_SELECT_PLACEHOLDER} />}
      options={options}
    />
  );
};

const BrandingLangSelectMemo = React.memo(BrandingLangSelect);

export default BrandingLangSelectMemo;
