import { CloseOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Space, Typography } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { EmailConfirmationForm, type EmailConfirmationFormProps } from '@/features/email/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';

import type { EmailEnterFormProps } from './types';

const { Text } = Typography;
const styles = {
  container: css`
    display: flex;
    flex: 1;
    position: relative;
  `,
  form: css`
    display: flex;
    flex: 1;
  `,
  cancel: css`
    position: absolute;
    right: 0;
    top: 0;
  `,
};

const EmailEnterForm: React.FC<EmailEnterFormProps> = ({ 'data-test': dataTest, onLogin, onCancel }) => {
  const doLogin = useCallback(
    ({ auth0Token, email }: Parameters<EmailConfirmationFormProps['onSubmit']>[0]) =>
      onLogin({ email, emailToken: auth0Token }),
    [onLogin],
  );
  return (
    <div className={styles.container}>
      {onCancel && (
        <Operation
          className={styles.cancel}
          title={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_EMAIL_ENTER_FORM_CANCEL} />}
          data-test={dataTest && `${dataTest}-cancel`}
          icon={<CloseOutlined />}
          onClick={onCancel}
          mode="icon"
        />
      )}
      <Space direction="vertical" size="small" className={styles.form}>
        <Text data-test={dataTest && `${dataTest}-description`}>
          <FormattedMessage id={I18nFeatureAuth.COMPONENTS_EMAIL_ENTER_FORM_DESCRIPTION} />
        </Text>
        <EmailConfirmationForm
          data-test={dataTest && `${dataTest}-form`}
          onSubmit={doLogin}
          submitText={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_EMAIL_ENTER_FORM_SUBMIT} tagName="span" />}
        />
      </Space>
    </div>
  );
};

const EmailEnterFormMemo = React.memo(EmailEnterForm);

export default EmailEnterFormMemo;
