/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { CollectTaskPredicatesAPIModel } from './CollectTaskPredicatesAPIModel';
import {
    CollectTaskPredicatesAPIModelFromJSON,
    CollectTaskPredicatesAPIModelFromJSONTyped,
    CollectTaskPredicatesAPIModelToJSON,
} from './CollectTaskPredicatesAPIModel';
import type { PagePushCollectSortByAPIModel } from './PagePushCollectSortByAPIModel';
import {
    PagePushCollectSortByAPIModelFromJSON,
    PagePushCollectSortByAPIModelFromJSONTyped,
    PagePushCollectSortByAPIModelToJSON,
} from './PagePushCollectSortByAPIModel';

/**
 * 
 * @export
 * @interface CollectTaskFilterAPIModel
 */
export interface CollectTaskFilterAPIModel {
    /**
     * 
     * @type {CollectTaskPredicatesAPIModel}
     * @memberof CollectTaskFilterAPIModel
     */
    predicates: CollectTaskPredicatesAPIModel;
    /**
     * 
     * @type {PagePushCollectSortByAPIModel}
     * @memberof CollectTaskFilterAPIModel
     */
    page: PagePushCollectSortByAPIModel;
}

/**
 * Check if a given object implements the CollectTaskFilterAPIModel interface.
 */
export function instanceOfCollectTaskFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function CollectTaskFilterAPIModelFromJSON(json: any): CollectTaskFilterAPIModel {
    return CollectTaskFilterAPIModelFromJSONTyped(json, false);
}

export function CollectTaskFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CollectTaskFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': CollectTaskPredicatesAPIModelFromJSON(json['predicates']),
        'page': PagePushCollectSortByAPIModelFromJSON(json['page']),
    };
}

export function CollectTaskFilterAPIModelToJSON(value?: CollectTaskFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': CollectTaskPredicatesAPIModelToJSON(value['predicates']),
        'page': PagePushCollectSortByAPIModelToJSON(value['page']),
    };
}

