import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import isEqual from 'react-fast-compare';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormCompletenessItem,
  FormFooter,
} from '@/components';
import ReportScheduleCronItem from '@/features/report-schedules/components/ReportScheduleCronItem';
import ReportScheduleParametersItem from '@/features/report-schedules/components/ReportScheduleParametersItem';
import ReportScheduleTemplateItem from '@/features/report-schedules/components/ReportScheduleTemplateItem';
import type { NewReportSchedule } from '@/features/report-schedules/types';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop, withVoidOrThrow, wrap } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { ReportScheduleFormProps } from './types';

type ReportScheduleFormData = NewReportSchedule;

const requiredFields = [nameof<ReportScheduleFormData>('cron'), nameof<ReportScheduleFormData>('template')];

const fields = [nameof<ReportScheduleFormData>('parameters')];

const ReportScheduleFormRaw: React.FC<ReportScheduleFormProps> = ({
  'data-test': dataTest,
  isNew,
  initialValues,
  className,
  style,
  onSubmit,
  onReset = noop,
  submitCallback,
  onSubmitting,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { form, withResetForm } = useForm<ReportScheduleFormData>();

  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling, reset: resetSubmit } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withResetForm(resetSubmit), [resetSubmit, withResetForm]);
  const doCancel = useMemo(() => withErrorHandling(onReset), [onReset, withErrorHandling]);

  const updateComplete = useCallback(
    (complete: boolean) => {
      const value = form.getFieldsValue();
      const hasBeenUpdated = !initialValues || !isEqual(initialValues, value);
      const isCompleteAndUpdated = complete && hasBeenUpdated;

      if (isFormComplete != isCompleteAndUpdated) {
        setFormComplete(isCompleteAndUpdated);
        const doSubmit = isCompleteAndUpdated
          ? withSubmitting(
              withVoidOrThrow(
                wrap(
                  async () => {
                    await onSubmit(value);
                    setTimeout(doReset, 0);
                  },
                  () => onSubmitting?.(true),
                  () => onSubmitting?.(false),
                ),
              ),
            )
          : undefined;
        submitCallback?.(doSubmit);
      }
    },
    [
      form,
      initialValues,
      isFormComplete,
      setFormComplete,
      withSubmitting,
      submitCallback,
      onSubmit,
      doReset,
      onSubmitting,
    ],
  );
  return (
    <Form<ReportScheduleFormData>
      onValuesChange={resetSubmit}
      onResetCapture={doReset}
      className={className}
      style={style}
      autoComplete="off"
      form={form}
      onFinish={onSubmit}
      onReset={doCancel}
      initialValues={initialValues}
      {...layout}
    >
      {error && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={error} />}
      <ReportScheduleTemplateItem<ReportScheduleFormData>
        data-test={dataTest && `${dataTest}-template`}
        name={nameof<ReportScheduleFormData>('template')}
        required
        readonly={!!initialValues?.template && !isNew}
      />
      <Form.Item<ReportScheduleFormData> shouldUpdate noStyle>
        {({ getFieldValue }) => (
          <ReportScheduleParametersItem
            data-test={dataTest && `${dataTest}-parameters`}
            name={nameof<ReportScheduleFormData>('parameters')}
            /* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */
            template={getFieldValue(nameof<ReportScheduleFormData>('template'))}
          />
        )}
      </Form.Item>
      <ReportScheduleCronItem<ReportScheduleFormData>
        data-test={dataTest && `${dataTest}-cron`}
        name={nameof<ReportScheduleFormData>('cron')}
        required
      />
      <FormCompletenessItem<ReportScheduleFormData>
        requiredFields={requiredFields}
        fields={fields}
        onChange={updateComplete}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        noStyle={!!submitCallback}
        style={submitCallback ? { display: 'none' } : undefined}
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
      />
    </Form>
  );
};

const ReportScheduleForm = React.memo(ReportScheduleFormRaw);

export default ReportScheduleForm;
