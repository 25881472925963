import { useMemo } from 'react';

import { useCollectTaskSummaryForAddressesListView } from '@/features/collectable/hooks';

import useRechargeAddress from './useRechargeAddress';

export default function useCollectTaskSummaryForRechargeAddressListView(addressId: string | undefined) {
  const { data } = useRechargeAddress(addressId);
  const addresses = useMemo(
    () => (data.data ? [{ address: data.data.address, asset: data.data.asset }] : []),
    [data.data],
  );
  return useCollectTaskSummaryForAddressesListView(addresses);
}
