/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PaymentsModeAPIModel } from './PaymentsModeAPIModel';
import {
    PaymentsModeAPIModelFromJSON,
    PaymentsModeAPIModelFromJSONTyped,
    PaymentsModeAPIModelToJSON,
} from './PaymentsModeAPIModel';

/**
 * 
 * @export
 * @interface NewWithdrawalEstimationAPIModel
 */
export interface NewWithdrawalEstimationAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewWithdrawalEstimationAPIModel
     */
    amount: string;
    /**
     * 
     * @type {PaymentsModeAPIModel}
     * @memberof NewWithdrawalEstimationAPIModel
     */
    mode?: PaymentsModeAPIModel;
    /**
     * 
     * @type {Date}
     * @memberof NewWithdrawalEstimationAPIModel
     */
    includeBefore?: Date;
}

/**
 * Check if a given object implements the NewWithdrawalEstimationAPIModel interface.
 */
export function instanceOfNewWithdrawalEstimationAPIModel(value: object): boolean {
    if (!('amount' in value)) return false;
    return true;
}

export function NewWithdrawalEstimationAPIModelFromJSON(json: any): NewWithdrawalEstimationAPIModel {
    return NewWithdrawalEstimationAPIModelFromJSONTyped(json, false);
}

export function NewWithdrawalEstimationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewWithdrawalEstimationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'mode': json['mode'] == null ? undefined : PaymentsModeAPIModelFromJSON(json['mode']),
        'includeBefore': json['includeBefore'] == null ? undefined : (new Date(json['includeBefore'])),
    };
}

export function NewWithdrawalEstimationAPIModelToJSON(value?: NewWithdrawalEstimationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'amount': value['amount'],
        'mode': PaymentsModeAPIModelToJSON(value['mode']),
        'includeBefore': value['includeBefore'] == null ? undefined : ((value['includeBefore']).toISOString()),
    };
}

