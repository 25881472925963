import React from 'react';

import { withFeatureToggleGuard } from '@/features/feature-toggle/hocs';
import { SettlementReconciliationView } from '@/features/settlement-reconciliations/components';

import { withSettlementReconciliationDataLoading } from './hocs';

const SettlementReconciliationCard = withFeatureToggleGuard(
  'enableReconciliation',
  true,
)(withSettlementReconciliationDataLoading((props) => <SettlementReconciliationView {...props} columns={1} />));

const SettlementReconciliationCardMemo = React.memo(SettlementReconciliationCard);

export default SettlementReconciliationCardMemo;
