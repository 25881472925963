import { Row } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { StatisticItem } from '@/features/statistics/components';
import { I18nPageDashboard } from '@/generated/i18n/i18n';

import { withDashboardDataLoading } from './hocs';

import type { SalesVolumeCardProps } from './types';

const span = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };

const SalesVolumeCard: React.FC<SalesVolumeCardProps> = ({ data, 'data-test': dataTest }) => (
  <Row>
    <StatisticItem
      data-test={dataTest && `${dataTest}-todayCount`}
      span={span}
      title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_SALES_VOLUME_CARD_COL_COUNT_TODAY_TITLE} />}
      value={data.todayPayments.Paid ?? 0}
      color="green"
    />
    <StatisticItem
      data-test={dataTest && `${dataTest}-todayPaid`}
      span={span}
      title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_SALES_VOLUME_CARD_COL_AMOUNT_TODAY_TITLE} />}
      value={
        <AssetAmount
          assetId={data.todaySales.salesVolume.asset}
          value={data.todaySales.salesVolume.value}
          withCurrency
        />
      }
      color="green"
    />
  </Row>
);

const SalesVolumeCardLoaded = withDashboardDataLoading(SalesVolumeCard);

const SalesVolumeCardMemo = React.memo(SalesVolumeCardLoaded) as typeof SalesVolumeCardLoaded;

export default SalesVolumeCardMemo;
