import React from 'react';

import type { MerchantWalletRunningBalanceViewProps } from '@/features/merchant-wallet-balance/components';
import { MerchantWalletRunningBalanceView } from '@/features/merchant-wallet-balance/components';

import { withMerchantWalletRunningBalanceDataLoading } from './hocs';

const MerchantWalletRunningBalanceCardRaw =
  withMerchantWalletRunningBalanceDataLoading<MerchantWalletRunningBalanceViewProps>((props) => (
    <MerchantWalletRunningBalanceView {...props} columns={1} />
  ));

const MerchantWalletRunningBalanceCard = React.memo(MerchantWalletRunningBalanceCardRaw);

export default MerchantWalletRunningBalanceCard;
