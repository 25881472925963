import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

const PaymentParametersTitleRaw: React.FC = () => (
  <FormattedMessage id={I18nFeaturePayments.COMPONENTS_PAYMENTS_REPORT_TITLE} />
);

const PaymentParametersTitle = React.memo(PaymentParametersTitleRaw);

export default PaymentParametersTitle;
