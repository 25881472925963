import type { DateTimeRelativeQuickFilter } from '@/components';
import type {
  NewDonationAPIModel,
  DonationAPIModel,
  DonationPredicatesAPIModel,
  DonationTransactionPredicatesAPIModel,
  DonationAddressAPIModel,
  UpdateDonationAPIModel,
  DonationTransactionAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { DonationSortByAPIModel, PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { AssetAmountValue } from '@/infrastructure/api';
import type { ListColumnState, ListSortBy, ListState, ListStateNoColumns } from '@/infrastructure/model/list/types';
import { defaultListState } from '@/infrastructure/model/list/types';
import type { SingleState } from '@/infrastructure/model/single/types';

export const NAMESPACE = 'donations';

export interface NewDonation extends Omit<NewDonationAPIModel, 'defaultAmounts'> {
  defaultAmounts?: AssetAmountValue[];
}

export interface UpdateDonation extends Omit<UpdateDonationAPIModel, 'defaultAmounts'> {
  id: string;
  defaultAmounts?: AssetAmountValue[];
}

export interface DonationAddress extends Omit<DonationAddressAPIModel, 'balance' | 'defaultAmounts'> {
  balance: AssetAmountValue;
  defaultAmounts: AssetAmountValue[];
}

export interface Donation extends Omit<DonationAPIModel, 'addresses'> {
  addresses: DonationAddress[];
}

export interface DonationFilterPredicate extends DonationPredicatesAPIModel {
  createdAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export type DonationListState = ListState<string, DonationFilterPredicate, DonationSortByAPIModel>;
export type DonationSortBy = ListSortBy<DonationSortByAPIModel>;

export const defaultDonationSortBy: DonationSortBy = { [DonationSortByAPIModel.CreatedAt]: 'DESC' };
export const defaultDonationListState: DonationListState = defaultListState({}, defaultDonationSortBy);

export interface DonationTransactionFilterPredicate extends DonationTransactionPredicatesAPIModel {
  createdAtRangeRelative?: DateTimeRelativeQuickFilter;
  broadcastAtRangeRelative?: DateTimeRelativeQuickFilter;
}

export interface DonationTransaction extends Omit<DonationTransactionAPIModel, 'amount'> {
  amount: AssetAmountValue;
}

export type DonationTransactionListState = ListStateNoColumns<
  string,
  DonationTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;
export type DonationTransactionSortBy = ListSortBy<PushTransactionSortByAPIModel>;

export interface DonationAssetId {
  donationId: string;
  asset: string;
}

export const defaultDonationTransactionSortBy: DonationTransactionSortBy = {
  [PushTransactionSortByAPIModel.BroadcastAt]: 'DESC',
};
export const defaultDonationTransactionForDonationListState: DonationTransactionListState = defaultListState(
  {},
  defaultDonationTransactionSortBy,
);

export interface DonationsState {
  entities: SingleState<Donation>;
  list: DonationListState;

  transactions: {
    entities: SingleState<DonationTransaction>;
    columnState: ListColumnState;
    byDonation: Partial<Record<string, DonationTransactionListState>>;
    list: DonationTransactionListState;
  };
}

export const defaultDonationTransactionListState = {
  ...defaultDonationTransactionForDonationListState,
  columnState: {},
};
