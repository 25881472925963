import type { AppRootState, AppAsyncThunkAction } from '@/app/store';
import type { LoadingListDataState, ListData, ListParametersState } from '@/infrastructure/model/list/types';

import useAppLoadableData, { type UseAppLoadableData } from './useAppLoadableData';
import useAppSelector from './useAppSelector';

export type UseAppListData<
  Value,
  Filter,
  SortBy extends string,
  FetchResult = LoadingListDataState<Value>,
> = UseAppLoadableData<ListData<Value>, FetchResult, LoadingListDataState<Value>> & ListParametersState<Filter, SortBy>;

export default function useAppListData<Value, Filter, SortBy extends string, Result = LoadingListDataState<Value>>(
  // this function should fail-safe
  fetchActionFactory:
    | ((force: boolean) => AppAsyncThunkAction<Result, unknown>)
    | ((force: boolean) => Promise<Result>),
  parametersSelector: (state: AppRootState) => ListParametersState<Filter, SortBy>,
  dataFetchingSelector: (state: AppRootState) => boolean,
  dataSelector: (state: AppRootState) => LoadingListDataState<Value>,
): UseAppListData<Value, Filter, SortBy, Result> {
  const { data, forceRefresh, loading } = useAppLoadableData(fetchActionFactory, dataSelector, dataFetchingSelector);
  const { filter, columnState, page, sortBy } = useAppSelector(parametersSelector);
  return { data, forceRefresh, loading, page, filter, columnState, sortBy };
}
