import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonations } from '@/features/donations/actions';
import { makeSelectDonationsListParameters, makeSelectDonationListData } from '@/features/donations/selectors';
import type { Donation, DonationFilterPredicate } from '@/features/donations/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { PushAddressSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseDonationList = UseAppListData<Donation, DonationFilterPredicate, PushAddressSortByAPIModel>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<Donation>, Parameters<typeof fetchDonations>[0]> =>
  fetchDonations({ force });

const dataSelector = makeSelectDonationListData();
const dataFetchingSelector = makeSelectPending(fetchDonations);
const parametersSelector = makeSelectDonationsListParameters();

export default function useDonationList(): UseDonationList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
