import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import MerchantWalletTransfersBreadcrumb from '@/pages/audit/transfers/components/MerchantWalletTransfersBreadcrumb';

import MerchantWalletTransferLink from '../MerchantWalletTransferLink';

import type { MerchantWalletTransferBreadcrumbProps } from './types';

const MerchantWalletTransferBreadcrumbRaw: React.FC<MerchantWalletTransferBreadcrumbProps> = ({
  'data-test': dataTest,
  transferId,
  disabled,
  items = [],
}) => (
  <MerchantWalletTransfersBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageAudit.TRANSFER_VIEW_COMPONENTS_BREADCRUMB} values={{ id: transferId }} />
        ) : (
          <MerchantWalletTransferLink
            mode="text"
            value={transferId}
            title={
              <FormattedMessage id={I18nPageAudit.TRANSFER_VIEW_COMPONENTS_BREADCRUMB} values={{ id: transferId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const MerchantWalletTransferBreadcrumb = React.memo(MerchantWalletTransferBreadcrumbRaw);

export default MerchantWalletTransferBreadcrumb;
