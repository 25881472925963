import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import isNil from 'lodash/isNil';
import React from 'react';

import { StatusViewElement } from '@/components';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationStatusViewProps } from './types';

const i18n: Record<'true' | 'false', I18nMessages> = {
  true: I18nFeatureDonations.COMPONENTS_DONATION_STATUS_VIEW_LABEL_ACTIVE,
  false: I18nFeatureDonations.COMPONENTS_DONATION_STATUS_VIEW_LABEL_DISABLED,
};

export const donationStatusIcons: Record<'true' | 'false', React.ReactElement> = {
  true: <PlayCircleOutlined style={{ color: 'green' }} />,
  false: <PauseCircleOutlined style={{ color: 'orange' }} />,
};

const DonationStatusView: React.FC<DonationStatusViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  !isNil(value) ? (
    <StatusViewElement
      data-test={dataTest}
      i18n={i18n[`${value}`]}
      icon={donationStatusIcons[`${value}`]}
      mode={mode}
    />
  ) : null;

const DonationStatusViewMemo = React.memo(DonationStatusView);

export default DonationStatusViewMemo;
