import { createReducer } from '@reduxjs/toolkit';

import { noop } from '@/infrastructure/utils/functions';

import type { ReCaptchaState } from './types';

const initialState: ReCaptchaState = {
  publicV2Key: window.env.RECAPTCHA_V2_PUBLIC_KEY,
  publicV3Key: window.env.RECAPTCHA_V3_PUBLIC_KEY,
};
export const reducer = createReducer(initialState, noop);

export default reducer;
