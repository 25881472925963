import { Alert } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { useAsset, useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import type { QRSupportErrorType } from '@/features/payments/components/CreatePaymentForm/components/QRSupportErrorItem/types';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import CompanySettingsLink from '@/pages/settings/company/summary/components/CompanySettingsLink';

import type { QRSupportItemInputProps } from './types';

const errorMessages: Record<QRSupportErrorType, React.ReactNode> = {
  'no-wallet': (
    <FormattedMessage
      id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NO_WALLET_ERROR_MESSAGE}
    />
  ),
  'not-selected': (
    <FormattedMessage
      id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NOT_SELECTED_ERROR_MESSAGE}
    />
  ),
};

const errorDescriptions: Record<QRSupportErrorType, (props: TestableProps) => React.ReactNode> = {
  'no-wallet': ({ 'data-test': dataTest }) => (
    <FormattedMessage
      id={
        I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NO_WALLET_ERROR_DESCRIPTION
      }
      values={{
        ln: (title: React.ReactNode) => (
          <CompanySettingsLink data-test={dataTest && `${dataTest}-link`} title={title} mode="text" />
        ),
      }}
    />
  ),
  'not-selected': ({ 'data-test': dataTest }) => (
    <FormattedMessage
      id={
        I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NOT_SELECTED_ERROR_DESCRIPTION
      }
      values={{
        ln: (title: React.ReactNode) => (
          <CompanySettingsLink data-test={dataTest && `${dataTest}-link`} title={title} mode="text" />
        ),
      }}
    />
  ),
};

const QRSupportItemInput: React.FC<QRSupportItemInputProps> = ({ 'data-test': dataTest, value, asset, onChange }) => {
  const { data: assetState } = useAsset(asset);
  const { data: blockchainState } = useBlockchainSystemInfo(assetState.data?.blockchain);
  const { data: settingsState } = useCompanySettings();
  const settings = useMemo(
    () => settingsState.data?.qrSupport.find((btFw) => btFw.blockchain === assetState.data?.blockchain),
    [assetState.data?.blockchain, settingsState.data?.qrSupport],
  );
  useEffect(() => {
    if (!blockchainState.data?.forwarder || !settings) {
      if (value?.error) {
        onChange?.({ ...value, error: undefined });
      }
      return;
    }
    if (blockchainState.data.forwarder === 'required') {
      if (!settings.isEnabled) {
        if (value?.error !== 'not-selected') {
          onChange?.({ ...value, error: 'not-selected' });
        }
        return;
      }
      if (!settings.isDeployed) {
        if (value?.error !== 'no-wallet') {
          onChange?.({ ...value, error: 'no-wallet' });
        }
        return;
      }
    }
    if (value?.error) {
      onChange?.({ ...value, error: undefined });
    }
  }, [blockchainState.data?.forwarder, settings, onChange, value]);

  return value?.error ? (
    <Alert
      data-test={dataTest}
      showIcon
      type="error"
      message={errorMessages[value.error]}
      description={errorDescriptions[value.error]({ 'data-test': dataTest })}
    />
  ) : null;
};

const QRSupportItemInputMemo = React.memo(QRSupportItemInput);

export default QRSupportItemInputMemo;
