import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { reportScheduleAddLink } from '@/pages/reports/routes';

import type { ReportScheduleAddLinkProps } from './types';

const ReportScheduleAddLinkRaw: React.FC<ReportScheduleAddLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(reportScheduleAddLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={<PlusCircleOutlined />}
      title={title ?? <FormattedMessage id={I18nPageReports.REPORT_SCHEDULE_ADD_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const ReportScheduleAddLink = React.memo(ReportScheduleAddLinkRaw);

export default ReportScheduleAddLink;
