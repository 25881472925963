import React, { useCallback } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import type { WalletOperationClientAction } from '@/features/web3/components';
import { WalletOperation } from '@/features/web3/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { I18nHocs } from '@/generated/i18n/i18n';
import type { Cancellable } from '@/infrastructure/utils/ui';

import type { OperationConfirmOwnershipProps } from './types';

const OperationConfirmOwnership: React.FC<OperationConfirmOwnershipProps> = ({
  connectorId,
  onSubmit,
  requiredAccount,
  disabled,
  'data-test': dataTest,
}) => {
  const doConfirmOwnership = useCallback<WalletOperationClientAction>(
    async ({ account, client }, cancellationPromise?: Cancellable) => {
      if (account) {
        await onSubmit(client, cancellationPromise);
      }
    },
    [onSubmit],
  );

  return (
    <WalletOperation
      data-test={dataTest}
      connectorId={connectorId}
      requiredAccount={{
        value: requiredAccount,
        title: (
          <FormattedMessageWithMarkup
            id={I18nHocs.WALLET_OWNERSHIP_ACCOUNT_TOOLTIP}
            values={{ address: <b>{requiredAccount}</b> }}
          />
        ),
      }}
      disabled={disabled}
      mainAction={{
        withClient: true,
        onAction: doConfirmOwnership,
        runOnConnect: true,
        title: <FormattedMessage id={I18nHocs.WALLET_OWNERSHIP_CREATE_SUBMIT_TITLE} />,
        'data-test': dataTest && `${dataTest}-submit`,
      }}
      supportCancellation={{
        title: <FormattedMessage id={I18nHocs.WALLET_OWNERSHIP_CREATE_CANCEL_TITLE} />,
      }}
    />
  );
};

const OperationConfirmOwnershipHOCed = withWeb3Connector(OperationConfirmOwnership);

const OperationConfirmOwnershipMemo = React.memo(
  OperationConfirmOwnershipHOCed,
) as typeof OperationConfirmOwnershipHOCed;

export default OperationConfirmOwnershipMemo;
