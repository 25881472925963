import { FormattedMessage } from '@/components';
import { OperationRefreshCompanySettings } from '@/features/company-settings/components';
import { useCompanySettings } from '@/features/company-settings/hooks';
import type { CompanySettings } from '@/features/company-settings/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPaymentsConfigurationDataLoading = <
  Original extends { data: Pick<CompanySettings, 'payments'> } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithPaymentsConfigurationDataLoading',
  )((props) => {
    const dataTest: string | undefined = props['data-test'];
    const data = useCompanySettings();
    return withCardDataLoading<Pick<CompanySettings, 'payments'>, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_TITLE} />,
      CardProps: {
        extra: <OperationRefreshCompanySettings data-test={dataTest && `${dataTest}-opRefresh`} mode="inline" />,
      },
    })(Component)(props);
  });

export default withPaymentsConfigurationDataLoading;
