import { Card } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useHandlers } from '@/features/global/hooks';
import { I18nFeatureReports } from '@/generated/i18n/i18n';

import type { ReportParametersViewProps } from './types';

const ReportParametersViewRaw: React.FC<ReportParametersViewProps> = ({ value, template, 'data-test': dataTest }) => {
  const handlers = useHandlers('reports');
  const handler = useMemo(() => handlers.find((h) => h.template === template), [handlers, template]);

  return handler?.ParametersView ? (
    <Card title={<FormattedMessage id={I18nFeatureReports.COMPONENTS_PARAMETERS_VIEW_TITLE} />}>
      <handler.ParametersView value={value} data-test={dataTest} />
    </Card>
  ) : null;
};

const ReportParametersView = React.memo(ReportParametersViewRaw);

export default ReportParametersView;
