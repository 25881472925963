import { Space } from 'antd';
import React from 'react';

import { OperationGenerateDonationURL, OperationUpdateDonationStatus } from '@/features/donations/components';
import DonationEditLink from '@/pages/donations/donation-edit/components/DonationEditLink';

import type { DonationsTableOperationsProps } from './types';

const DonationsTableOperations: React.FC<DonationsTableOperationsProps> = ({
  'data-test': dataTest,
  value: { id },
}) => (
  <Space>
    <DonationEditLink data-test={dataTest && `${dataTest}-link`} value={id} mode="inline" />
    <OperationUpdateDonationStatus data-test={dataTest && `${dataTest}-statusOp`} donationId={id} mode="inline" />
    <OperationGenerateDonationURL data-test={dataTest && `${dataTest}-generateOp`} donationId={id} mode="inline" />
  </Space>
);

const ReportSchedulesTableOperationsMemo = React.memo(DonationsTableOperations);

export default ReportSchedulesTableOperationsMemo;
