import type { UseAppListDataView } from '@/app/hooks';
import { useAppListDataView } from '@/app/hooks';
import { storeRechargeAddressListParameters } from '@/features/recharges/actions';
import type { RechargeAddress, RechargeAddressFilterPredicate } from '@/features/recharges/types';
import type { PushAddressSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useRechargeAddressList from './useRechargeAddressList';

export type UseRechargeAddressListView = UseAppListDataView<
  RechargeAddress,
  RechargeAddressFilterPredicate,
  PushAddressSortByAPIModel
>;

export default function useRechargeAddressListView(): UseRechargeAddressListView {
  const state = useRechargeAddressList();
  return useAppListDataView(state, storeRechargeAddressListParameters);
}
