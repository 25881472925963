import { cx } from '@emotion/css';
import { Form, Input } from 'antd';
import React from 'react';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage } from '@/components';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useSharedStyles } from '@/hooks';

import { withCompanyCardDataLoading } from './hocs';

import type { GeneralSettingsCardProps } from './types';

const selectAuthToken = makeSelectAuthToken();

const GeneralSettingsCard: React.FC<GeneralSettingsCardProps> = ({ data, 'data-test': dataTest }) => {
  const { disabledTextInput } = useSharedStyles();
  // FIXME: there is no way to retrieve the owner's email, but company page access is restricted only to the owner, so current user is owner
  const loginInfo = useAppSelector(selectAuthToken);
  return (
    <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }} layout="horizontal">
      <Form.Item
        label={
          <FormattedMessage id={I18nPageSettings.PERMISSIONS_COMPONENTS_GENERAL_CARD_NAME_ITEM_LABEL} tagName="span" />
        }
      >
        <Input data-test={dataTest && `${dataTest}-name`} value={data.name} className={disabledTextInput} disabled />
      </Form.Item>
      <Form.Item
        label={
          <FormattedMessage id={I18nPageSettings.PERMISSIONS_COMPONENTS_GENERAL_CARD_OWNER_ITEM_LABEL} tagName="span" />
        }
      >
        <Input
          data-test={dataTest && `${dataTest}-owner`}
          value={loginInfo.data?.info.address}
          className={cx(disabledTextInput, 'ym-hide-content')}
          disabled
        />
      </Form.Item>
    </Form>
  );
};

const GeneralSettingsCardLoaded = withCompanyCardDataLoading(GeneralSettingsCard);
const GeneralSettingsCardMemo = React.memo(GeneralSettingsCardLoaded);

export default GeneralSettingsCardMemo;
