import React from 'react';

import { PendingStatusView } from '@/features/global/components';

import { PlainSubscriptionStatusView } from './components';

import type { SubscriptionStatusViewProps } from './types';

const SubscriptionStatusView: React.FC<SubscriptionStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
  pendingStatus,
}) => (
  <>
    <PlainSubscriptionStatusView data-test={dataTest} value={value} mode={mode} />
    {pendingStatus && (
      <>
        {' ('}
        <PendingStatusView value={pendingStatus} data-test={dataTest && `${dataTest}-pending`} mode={mode} />
        {' )'}
      </>
    )}
  </>
);

const SubscriptionStatusViewMemo = React.memo(SubscriptionStatusView);

export default SubscriptionStatusViewMemo;
