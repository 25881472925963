import { PlayCircleOutlined } from '@ant-design/icons';
import { Row } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { StatisticItem } from '@/features/statistics/components';
import { I18nPageDashboard } from '@/generated/i18n/i18n';

import { withDashboardSubscriptionsDataLoading } from './hocs';

import type { SubscriptionsCardProps } from './types';

const span = { xs: 24, sm: 12, md: 12, lg: 12, xl: 12 };

const SubscriptionsCard: React.FC<SubscriptionsCardProps> = ({ data, 'data-test': dataTest }) => (
  <Row>
    <StatisticItem
      data-test={dataTest && `${dataTest}-active`}
      span={span}
      title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_SUBSCRIPTIONS_CARD_ACTIVE_TITLE} />}
      icon={<PlayCircleOutlined />}
      value={data.active || 0}
      color="green"
    />
    <StatisticItem
      data-test={dataTest && `${dataTest}-mrr`}
      span={span}
      title={<FormattedMessage id={I18nPageDashboard.COMPONENTS_SUBSCRIPTIONS_CARD_MRR_TITLE} />}
      value={<AssetAmount assetId={data.mrr.asset} value={data.mrr.value} withCurrency />}
      color="blue"
    />
  </Row>
);

const SubscriptionsCardLoaded = withDashboardSubscriptionsDataLoading(SubscriptionsCard);
const SubscriptionsCardMemo = React.memo(SubscriptionsCardLoaded) as typeof SubscriptionsCardLoaded;

export default SubscriptionsCardMemo;
