import { LoadingOutlined } from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { useSubscriptionPlan } from '@/features/subscription-plans/hooks';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import type { SubscriptionAllowanceViewProps } from './types';

const SubscriptionAllowanceView: React.FC<SubscriptionAllowanceViewProps> = ({
  'data-test': dataTest,
  subscription,
  mode = 'full',
}) => {
  const planState = useSubscriptionPlan(subscription.planId);
  const periods = useMemo(() => {
    const amount = planState.data.data?.amount;
    if (!amount) {
      return null;
    }
    return (
      <FormattedMessage
        id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_ALLOWANCE_PERIOD}
        values={{
          times: subscription.allowance.value.div(amount.value).integerValue(BigNumber.ROUND_DOWN).toString(),
        }}
      />
    );
  }, [planState.data.data?.amount, subscription.allowance.value]);
  if (planState.loading || planState.data.isDirty) {
    return <LoadingOutlined />;
  }
  return mode === 'full' ? (
    <>
      {periods} (
      <AssetAmount
        data-test={dataTest && `${dataTest}-amount`}
        assetId={subscription.allowance.asset}
        value={subscription.allowance.value}
        currencyMode="long"
        withCurrency
      />
      )
    </>
  ) : (
    <>
      {periods ?? (
        <AssetAmount
          data-test={dataTest && `${dataTest}-amount`}
          assetId={subscription.allowance.asset}
          value={subscription.allowance.value}
          currencyMode="long"
          withCurrency
        />
      )}
    </>
  );
};

const SubscriptionAllowanceViewMemo = React.memo(SubscriptionAllowanceView);

export default SubscriptionAllowanceViewMemo;
