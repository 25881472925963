import { useMemo } from 'react';
import { parseSignature, walletActions } from 'viem';

import type { ReCaptchaParams } from '@/features/recaptcha/types';
import { isRejectedByMetaMaskUser } from '@/features/web3/utils';
import { useSubmitting } from '@/hooks';
import type { HookAction } from '@/infrastructure/model';
import type { JWTTokenInfo } from '@/infrastructure/security/types';
import type { Func } from '@/infrastructure/utils/ts';
import { rejected } from '@/infrastructure/utils/ui';

import useAuthActions from '../useAuthActions';

import type { UseAuthActionsType } from '../useAuthActions';
import type { Account, Chain, Client, Transport } from 'viem';

export interface UseAuthWeb3ActionsType {
  login: HookAction<
    [
      {
        client: Client<Transport, Chain, Account>;
        withRecaptcha: <T extends unknown[] = [], R = unknown>(
          func: (v: ReCaptchaParams) => Func<T, R>,
        ) => (...args: T) => Promise<R>;
      },
      Parameters<UseAuthActionsType['login']['act']>[1],
    ],
    JWTTokenInfo
  >;
  disconnect: HookAction<
    [{ client: Client<Transport, Chain, Account> }, Parameters<UseAuthActionsType['disconnectWeb3Auth']['act']>[1]],
    Awaited<ReturnType<UseAuthActionsType['disconnectWeb3Auth']['act']>>
  >;
}

export default function useAuthWeb3Actions(): UseAuthWeb3ActionsType {
  const { login: baseLogin, disconnectWeb3Auth: baseDisconnectWeb3Auth } = useAuthActions();

  const doLogin = baseLogin.act;
  const [loggingIn, withLoggingIn] = useSubmitting(false);
  const loginAction: UseAuthWeb3ActionsType['login']['act'] = useMemo(
    () =>
      withLoggingIn(async ({ client, withRecaptcha }, options) => {
        const signMessage = async (message: string) => {
          try {
            const rawSignature = await client.extend(walletActions).signMessage({ message });
            const signature = parseSignature(rawSignature);
            return { ...signature, v: Number(signature.v) };
          } catch (e: unknown) {
            if (isRejectedByMetaMaskUser(e)) {
              throw rejected();
            }
            throw e;
          }
        };
        return doLogin({ address: client.account.address, signMessage, withRecaptcha }, options);
      }),
    [withLoggingIn, doLogin],
  );
  const login = useMemo(
    () => ({
      act: loginAction,
      available: baseLogin.available,
      inAction: loggingIn,
    }),
    [loginAction, baseLogin.available, loggingIn],
  );

  const doDisconnectWeb3Auth = baseDisconnectWeb3Auth.act;
  const [disconnecting, withDisconnecting] = useSubmitting(false);
  const disconnectWeb3AuthAction: UseAuthWeb3ActionsType['disconnect']['act'] = useMemo(
    () =>
      withDisconnecting(async ({ client }, options) => {
        const signMessage = async (message: string) => {
          try {
            return await client.extend(walletActions).signMessage({ message });
          } catch (e: unknown) {
            if (isRejectedByMetaMaskUser(4001)) {
              throw rejected();
            }
            throw e;
          }
        };
        return doDisconnectWeb3Auth({ signMessage }, options);
      }),
    [withDisconnecting, doDisconnectWeb3Auth],
  );
  const disconnectWeb3Auth = useMemo(
    () => ({
      act: disconnectWeb3AuthAction,
      available: baseDisconnectWeb3Auth.available,
      inAction: disconnecting,
    }),
    [disconnectWeb3AuthAction, baseDisconnectWeb3Auth.available, disconnecting],
  );

  return { login, disconnect: disconnectWeb3Auth };
}
