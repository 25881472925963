import { css } from '@emotion/css';
import { Form } from 'antd';

import { FormattedMessage } from '@/components';
import type { BrandingProfileFormValues } from '@/features/branding/components/BrandingProfileForm/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { AccentColorSelect } from './components';

import type { AccentColorItemProps } from './types';
import type React from 'react';

const AccentColorItem: React.FC<AccentColorItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <Form.Item<BrandingProfileFormValues>
    data-test={dataTest}
    name={nameof<BrandingProfileFormValues>('accentColor')}
    label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_ACCENT_ITEM_LABEL} />}
    className={css`
      margin-bottom: 0;
    `}
  >
    <AccentColorSelect data-test={dataTest && `${dataTest}-select`} disabled={disabled} />
  </Form.Item>
);

export default AccentColorItem;
