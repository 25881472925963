import { RouteContext } from '@ant-design/pro-components';
import { Divider } from 'antd';
import { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useTermsOfServiceDialog } from '@/features/auth/hooks';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { useReCaptchaAction } from '@/features/recaptcha/hooks';
import type { ReCaptchaParams } from '@/features/recaptcha/types';
import { isRecaptchaError } from '@/features/recaptcha/utils';
import { withWeb3Context } from '@/features/web3/hocs';
import { I18nPageAuth } from '@/generated/i18n/i18n';
import { useNotification, useStateMountSafe } from '@/hooks';
import { withLogError, withOnSuccess } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { isRejected } from '@/infrastructure/utils/ui';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { AuthFormContainer, AuthCardTitle, EmailBlock, WalletsBlock, AuthTitle } from './components';

import type { RouteContextType } from '@ant-design/pro-components';
import type React from 'react';

type LoginPageProps = object;

const dataTest: string | undefined = 'login';

const notificationOptions = {
  success: () => ({
    message: <FormattedMessage id={I18nPageAuth.MESSAGE_SUCCESS} />,
    description: <FormattedMessage id={I18nPageAuth.MESSAGE_WELCOME} />,
  }),
  error: (error: unknown) =>
    !isRecaptchaError(error) && !isRejected(error)
      ? { message: <FormattedMessage id={I18nPageAuth.MESSAGE_GENERIC_ERROR} /> }
      : undefined,
};

const LoginPage: React.FC<LoginPageProps> = () => {
  const { withTermsAcceptance, acceptanceModal } = useTermsOfServiceDialog(dataTest && `${dataTest}-acceptance`);
  const { withRecaptcha, initialized } = useReCaptchaAction('auth');

  const [activeProvider, setActiveProvider] = useStateMountSafe<{ id: string; onCancel?: () => void } | undefined>();
  const { withNotification } = useNotification();
  const onSuccessLogin = useCallback(() => {
    goalReached(activeProvider?.id.startsWith('web3-') ? YMGoals.USER_LOGGED_IN_WEB3 : YMGoals.USER_LOGGED_IN_WEB3AUTH);
    goalReached(YMGoals.USER_LOGGED_IN);
  }, [activeProvider?.id]);

  const withLogIn = useMemo(
    () =>
      <V extends unknown[] = unknown[], R = unknown>(
        func: (
          onBeforeSignUp: Func,
          withRecaptcha: <T extends unknown[] = [], R2 = unknown>(
            func: (v: ReCaptchaParams) => Func<T, R2>,
          ) => (...args: T) => Promise<R2>,
        ) => Func<V, R>,
      ): Func<V> =>
        withLogError(
          withOnSuccess(
            withNotification(
              withTermsAcceptance((acceptTerms) => func(acceptTerms, withRecaptcha)),
              notificationOptions,
            ),
            onSuccessLogin,
          ),
        ),
    [onSuccessLogin, withTermsAcceptance, withNotification, withRecaptcha],
  );
  const featureFlags = useFeatureToggle();
  const isEmailDisabled =
    featureFlags.loading
    || featureFlags.data.isDirty
    || featureFlags.data.data?.disableWeb3Auth
    || (featureFlags.data.data?.disableEmailAuth && featureFlags.data.data.disableGoogleAuth);

  return (
    <>
      {acceptanceModal}
      <RouteContext.Consumer>
        {({ pageTitleInfo }: RouteContextType) => (
          <AuthTitle title={pageTitleInfo?.title} data-test={dataTest && `${dataTest}-title`} />
        )}
      </RouteContext.Consumer>
      <AuthFormContainer
        data-test={dataTest && `${dataTest}-container`}
        loading={!initialized}
        title={<AuthCardTitle data-test={dataTest && `${dataTest}-title`} activeProvider={activeProvider?.id} />}
        onBack={activeProvider?.onCancel}
      >
        <WalletsBlock
          data-test={dataTest && `${dataTest}-web3`}
          activeProvider={activeProvider?.id}
          onSelectProvider={setActiveProvider}
          withLogIn={withLogIn}
        />
        {!isEmailDisabled && (
          <>
            {!activeProvider && (
              <Divider type="horizontal">
                <FormattedMessage id={I18nPageAuth.BLOCK_DIVIDER} />
              </Divider>
            )}
            <EmailBlock
              data-test={dataTest && `${dataTest}-email`}
              activeProvider={activeProvider?.id}
              onSelectProvider={setActiveProvider}
              withLogIn={withLogIn}
            />
          </>
        )}
      </AuthFormContainer>
    </>
  );
};

export default withWeb3Context(LoginPage);
