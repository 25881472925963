import React from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useSubscription } from '@/features/subscriptions/hooks';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import type { OperationRefreshSubscriptionProps } from './types';

const messages = {
  tooltip: <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_TOOLTIP} />,
  error: {
    message: (
      <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_FAILED_MESSAGE} />
    ),
  },
  success: {
    message: (
      <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_SUCCESS_MESSAGE} />
    ),
  },
};

const OperationRefreshSubscription: React.FC<OperationRefreshSubscriptionProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  subscriptionId,
}) => {
  const { forceRefresh } = useSubscription(subscriptionId);
  return <OperationRefresh data-test={dataTest} refresh={forceRefresh} messages={messages} mode={mode} />;
};

const OperationRefreshSubscriptionMemo = React.memo(OperationRefreshSubscription);

export default OperationRefreshSubscriptionMemo;
