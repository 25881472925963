import { Form, Select } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';

import type { ReportScheduleTemplateFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const ReportScheduleTemplateFilterItemRaw = <Values extends Store = Store>({
  name,
  readonly,
  'data-test': dataTest,
  ItemProps = {},
}: ReportScheduleTemplateFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_FILTER_FORM_TEMPLATE_LABEL} />}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      placeholder={<FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_FILTER_FORM_TEMPLATE_PLACEHOLDER} />}
      disabled={readonly}
      mode="multiple"
      allowClear
    />
  </Form.Item>
);

const ReportScheduleTemplateFilterItem = React.memo(
  ReportScheduleTemplateFilterItemRaw,
) as typeof ReportScheduleTemplateFilterItemRaw;

export default ReportScheduleTemplateFilterItem;
