import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectTaskSummaries } from '@/features/collectable/actions';
import {
  makeSelectCollectTaskSummaryListData,
  makeSelectCollectTaskSummaryListParameters,
} from '@/features/collectable/selectors';
import type { CollectTaskFilterPredicate, CollectTaskSummary } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { PushCollectSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseCollectTaskSummaryList = UseAppListData<
  CollectTaskSummary,
  CollectTaskFilterPredicate,
  PushCollectSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<CollectTaskSummary>, Parameters<typeof fetchCollectTaskSummaries>[0]> =>
  fetchCollectTaskSummaries({ force });

const dataFetchingSelector = makeSelectPending(fetchCollectTaskSummaries);
const dataSelector = makeSelectCollectTaskSummaryListData();
const parametersSelector = makeSelectCollectTaskSummaryListParameters();

export default function useCollectTaskSummaryList(): UseCollectTaskSummaryList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
