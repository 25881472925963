import { Space } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { OperationRefreshCollectTask } from '@/features/collectable/components';
import { useCollectTask } from '@/features/collectable/hooks';
import type { CollectTaskAddress } from '@/features/collectable/types';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withCollectTaskAddressesDataLoading = <
  Original extends { data: CollectTaskAddress[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { taskId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithCollectTaskDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const { loading, forceRefresh, data } = useCollectTask(props.taskId);
    const addressesData = useMemo(() => mapStoredState(data, ({ addresses }) => addresses), [data]);
    return withCardDataLoading<CollectTaskAddress[], Original>({
      loading,
      forceRefresh,
      data: addressesData,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageCollectable.TASK_VIEW_COMPONENTS_ADDRESSES_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshCollectTask taskId={props.taskId} data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withCollectTaskAddressesDataLoading;
