import { EditOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { settingsCompanyQRSupportEditLink } from '@/pages/settings/routes';

import type { UpdateQRSupportLinkProps } from './types';

const UpdateQRSupportLink: React.FC<UpdateQRSupportLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title }) => {
  const { updateQRSupport } = useCompanySettingsActions();
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(settingsCompanyQRSupportEditLink()), [openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={<EditOutlined />}
      disabled={!updateQRSupport.available}
      title={title ?? <FormattedMessage id={I18nPageSettings.COMPANY_QR_SUPPORT_EDIT_LINK_TITLE} />}
    />
  );
};

const UpdateQRSupportLinkMemo = React.memo(UpdateQRSupportLink);

export default UpdateQRSupportLinkMemo;
