import BigNumber from 'bignumber.js';
import { useMemo } from 'react';

import type { AssetAmountItemProps } from '@/features/dictionary/blockchain/components/AssetAmountItem/types';
import { useAsset, useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withAmountLimits = <T extends AssetAmountItemProps>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithAmountLimits',
  )((props) => {
    const { assetId } = props;
    const asset = useAsset(assetId);
    const bc = useBlockchainSystemInfo(asset.data.data?.blockchain);
    const min = useMemo(() => {
      if (asset.data.data?.isStable) {
        return BigNumber('0.1');
      }
      if (bc.data.data?.apiType === BlockchainAPITypeAPIModel.BTC) {
        return BigNumber('.0001');
      }
      return null;
    }, [asset.data.data?.isStable, bc.data.data?.apiType]);
    return <Component min={min} nonNegative {...props} />;
  });

export default withAmountLimits;
