import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import type { CollectableEntityProcessTransaction } from '@/features/collectable/types';
import { fetchDonationDeployTransaction } from '@/features/donations/actions';
import { makeSelectDonationAssetDeployTransaction } from '@/features/donations/selectors';
import type { DonationAssetId } from '@/features/donations/types';
import { donationAssetIdToKey } from '@/features/donations/utils';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseDonationAssetDeployTransaction = UseAppSingleData<CollectableEntityProcessTransaction>;

const noData = storedDataError<CollectableEntityProcessTransaction>('no-data');

const fetchFactory = (
  { donationId, asset }: DonationAssetId,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<CollectableEntityProcessTransaction>,
  Parameters<typeof fetchDonationDeployTransaction>[0]
> => fetchDonationDeployTransaction({ donationId, asset, force });

export default function useDonationAddressDeployTransaction(
  donationId: string | undefined,
  asset: string | undefined,
): UseDonationAssetDeployTransaction {
  const id = useMemo(() => (donationId && asset ? { donationId, asset } : undefined), [asset, donationId]);
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchDonationDeployTransaction, id ? donationAssetIdToKey(id) : undefined),
    [id],
  );
  return useAppSingleData(fetchFactory, makeSelectDonationAssetDeployTransaction, noData, id, dataFetchingSelector);
}
