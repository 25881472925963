import { Space } from 'antd';

import { FormattedMessage, OperationRefresh } from '@/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { usePaymentsSummaryStats } from '@/features/statistics/hooks';
import type { PaymentSummaryStats } from '@/features/statistics/types';
import { I18nPageDashboard } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withDashboardPaymentsDataLoading = <
  Original extends { data: PaymentSummaryStats } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithDashboardPaymentsDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = usePaymentsSummaryStats();
    return withCardDataLoading<PaymentSummaryStats, Original>({
      ...data,
      'data-test': dataTest,
      hideBack: true,
      title: (
        <Space>
          <FormattedMessage id={I18nPageDashboard.COMPONENTS_PAYMENTS_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" />
        </Space>
      ),
      CardProps: {
        extra: (
          <OperationRefresh
            refresh={data.forceRefresh}
            data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
          />
        ),
      },
    })(Component)(props);
  });

export default withDashboardPaymentsDataLoading;
