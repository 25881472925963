import React from 'react';

import { ActualBalancesView } from '@/features/statistics/components';

import { withActualBalancesDataLoading } from './hocs';

const ActualBalancesCardLoaded = withActualBalancesDataLoading(ActualBalancesView);
const ActualBalancesCardMemo = React.memo(ActualBalancesCardLoaded);

export default ActualBalancesCardMemo;
