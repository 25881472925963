import React, { useMemo } from 'react';

import { FormattedMessage, SelectWithReloadingItem } from '@/components';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import type { Asset } from '@/features/dictionary/blockchain/types';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

import AssetLabel from '../AssetLabel';

import type { AssetItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AssetItemRaw = <Values extends Store = Store>({
  name,
  placeholder,
  readonly,
  required,
  multiselect,
  messages,
  'data-test': dataTest,
  assetType,
  network,
  isAssetDisabled,
  SelectInputProps = {},
  ItemProps = {},
}: AssetItemProps<Values>) => {
  const { data, exchangeableAssets, supportedAssets, forceRefresh, loading } = useListAssets(network);
  const options =
    // eslint-disable-next-line no-nested-ternary
    assetType === 'supported' ? supportedAssets : assetType === 'exchangeable' ? exchangeableAssets : data;
  return (
    <SelectWithReloadingItem<Values, Asset>
      data-test={dataTest}
      multiselect={multiselect}
      readonly={readonly}
      required={required}
      name={name}
      messages={useMemo(
        () => ({
          label: messages?.label ?? <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_LABEL} />,
          dataEmpty: messages?.empty ?? (
            <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_EMPTY_ERROR} />
          ),
          placeholder: placeholder ?? <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_PLACEHOLDER} />,
          required: messages?.required ?? (
            <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ASSET_ITEM_REQUIRED} />
          ),
        }),
        [messages?.empty, messages?.label, messages?.required, placeholder],
      )}
      refresh={forceRefresh}
      loading={loading}
      options={options}
      dataToOptions={({ code, name: label }) => ({
        value: code,
        disabled: isAssetDisabled?.(code),
        label: (
          <AssetLabel
            value={{ code, name: label }}
            data-test={dataTest && `${dataTest}-${code}-label`}
            mode="medium"
            copyable={false}
            ellipsis={false}
          />
        ),
        inline: (
          <AssetLabel
            value={{ code, name: label }}
            data-test={dataTest && `${dataTest}-selected`}
            mode="medium"
            copyable={false}
            ellipsis={false}
          />
        ),
        search: `${code} ${label}`,
      })}
      SelectInputProps={{
        ...SelectInputProps,
        optionLabelProp: 'inline',
        optionFilterProp: 'search',
      }}
      ItemProps={ItemProps}
    />
  );
};

const AssetItem = React.memo(AssetItemRaw) as typeof AssetItemRaw;

export default AssetItem;
