import React from 'react';

import { FormattedMessage } from '@/components';
import Link from '@/components/Link';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';

import type { WalletLinkProps, WalletType } from './types';

const links: Record<WalletType, string> = {
  coinbaseWallet: 'https://www.coinbase.com/wallet',
  metaMask: 'https://metamask.io/download',
};

const titles: Record<WalletType, React.ReactNode> = {
  coinbaseWallet: <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_LINK_COINBASE} />,
  metaMask: <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_LINK_METAMASK} />,
};

const WalletLink: React.FC<WalletLinkProps> = ({ 'data-test': dataTest, title, mode = 'text', type }) => (
  <Link data-test={dataTest} mode={mode} title={title || titles[type]} to={links[type]} external />
);

const WalletLinkMemo = React.memo(WalletLink);

export default WalletLinkMemo;
