import { useMemo } from 'react';
import { parseSignature } from 'viem';
import { privateKeyToAccount } from 'viem/accounts';

import { PageLoading } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { useSingleRun } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { isRejected } from '@/infrastructure/utils/ui';
import MessageLayout from '@/layouts/MessageLayout';

import type { Web3AuthJWTByEmailProps } from './types';
import type React from 'react';

const Web3AuthJWTByEmail: React.FC<Web3AuthJWTByEmailProps> = ({ 'data-test': dataTest, web3Auth, withLogIn }) => {
  const { login, logout } = useAuthActions();
  const { withSingleRun, inProgress } = useSingleRun(true);

  const doLogin = useMemo(() => {
    const address = web3Auth.state?.address;
    const privateKey = web3Auth.state?.privateKey;
    const emailToken = web3Auth.emailInfo?.token;
    const emailProvider = web3Auth.emailInfo?.provider;
    return address && emailProvider && emailToken && privateKey
      ? withSuppressPromise(
          withSingleRun(
            withLogIn(async (onBeforeSignUp) =>
              login.act(
                {
                  address,
                  emailToken,
                  emailProvider,
                  signMessage: async (message) => {
                    const account = privateKeyToAccount(privateKey);
                    const rawSignature = await account.signMessage({ message });
                    const signature = parseSignature(rawSignature);
                    return { ...signature, v: Number(signature.v) };
                  },
                },
                { onBeforeSignUp },
              ),
            ),
          ),
          (e) => {
            if (!isRejected(e)) {
              console.error(e);
            }
            withSuppressPromise(logout.act)(true);
          },
        )
      : undefined;
  }, [
    web3Auth.state?.address,
    web3Auth.state?.privateKey,
    web3Auth.emailInfo?.token,
    web3Auth.emailInfo?.provider,
    withSingleRun,
    withLogIn,
    login,
    logout.act,
  ]);

  if (doLogin && !inProgress) {
    doLogin();
  }
  return (
    <MessageLayout>
      <PageLoading data-test={dataTest} type="Web3AuthJWTByEmail" />
    </MessageLayout>
  );
};

export default Web3AuthJWTByEmail;
