import { useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';

export default function useActionPending(
  action: Parameters<typeof makeSelectPending>[0],
  id?: Parameters<typeof makeSelectPending>[1],
) {
  const dataFetchingSelector = useMemo(() => makeSelectPending(action, id), [action, id]);
  return useAppSelector(dataFetchingSelector);
}
