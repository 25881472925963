import { Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import UpdateActiveAssetsLink from '@/pages/settings/company/assets-edit/components/UpdateActiveAssetsLink';

import { withGasWalletAssetsDataLoading } from './hocs';

import type { GasWalletAssetsCardProps } from './types';

const { Paragraph } = Typography;

const GasWalletAssetsCard: React.FC<GasWalletAssetsCardProps> = ({ 'data-test': dataTest, data }) => {
  const assets = useMemo(
    () =>
      data.map((assetId, idx) => (
        <>
          <AssetLabel value={assetId} mode="medium" />
          {idx < data.length - 1 && ', '}
        </>
      )),
    [data],
  );
  return (
    <Paragraph data-test={dataTest && `${dataTest}-description`}>
      {data.length ? (
        <FormattedMessageWithMarkup
          id={I18nPageSettings.COMPANY_GAS_WALLET_COMPONENTS_ASSETS_CARD_MESSAGES_ASSETS}
          values={{
            assets,
            ln: (text: React.ReactNode) => (
              <UpdateActiveAssetsLink data-test={dataTest && `${dataTest}-assetsLink`} title={text} mode="link" />
            ),
          }}
        />
      ) : (
        <FormattedMessage
          id={I18nPageSettings.COMPANY_GAS_WALLET_COMPONENTS_ASSETS_CARD_MESSAGES_NO_ASSETS}
          values={{
            ln: (text: React.ReactNode) => (
              <UpdateActiveAssetsLink data-test={dataTest && `${dataTest}-assetsLink`} title={text} mode="link" />
            ),
          }}
        />
      )}
    </Paragraph>
  );
};

const GasWalletAssetsCardHOC = withGasWalletAssetsDataLoading(GasWalletAssetsCard);

const GasWalletAssetsCardMemo = React.memo(GasWalletAssetsCardHOC) as typeof GasWalletAssetsCardHOC;

export default GasWalletAssetsCardMemo;
