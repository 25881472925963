import { Space } from 'antd';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  OperationChangeReportScheduleStatus,
  OperationDeleteReportSchedule,
} from '@/features/report-schedules/components';
import ReportScheduleEditLink from '@/pages/reports/report-schedule-edit/components/ReportScheduleEditLink';
import { reportSchedulesLink } from '@/pages/reports/routes';

import type { ReportScheduleViewOperationsProps } from './types';

const ReportScheduleViewOperationsRaw: React.FC<ReportScheduleViewOperationsProps> = ({
  'data-test': dataTest,
  value: { id },
}) => {
  const navigate = useNavigate();

  const redirectOnDelete = useCallback(() => navigate(reportSchedulesLink()), [navigate]);
  return (
    <Space>
      <OperationChangeReportScheduleStatus scheduleId={id} data-test={dataTest && `${dataTest}-status`} />
      <ReportScheduleEditLink value={id} data-test={dataTest && `${dataTest}-edit`} />
      <OperationDeleteReportSchedule
        scheduleId={id}
        data-test={dataTest && `${dataTest}-delete`}
        confirmation
        onSuccess={redirectOnDelete}
      />
    </Space>
  );
};

const ReportScheduleViewOperations = React.memo(ReportScheduleViewOperationsRaw);

export default ReportScheduleViewOperations;
