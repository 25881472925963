/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SignatureComponentsAPIModel } from './SignatureComponentsAPIModel';
import {
    SignatureComponentsAPIModelFromJSON,
    SignatureComponentsAPIModelFromJSONTyped,
    SignatureComponentsAPIModelToJSON,
} from './SignatureComponentsAPIModel';

/**
 * 
 * @export
 * @interface UserMerchantWalletCreateAPIModel
 */
export interface UserMerchantWalletCreateAPIModel {
    /**
     * 
     * @type {SignatureComponentsAPIModel}
     * @memberof UserMerchantWalletCreateAPIModel
     */
    signature: SignatureComponentsAPIModel;
    /**
     * 
     * @type {string}
     * @memberof UserMerchantWalletCreateAPIModel
     */
    message: string;
}

/**
 * Check if a given object implements the UserMerchantWalletCreateAPIModel interface.
 */
export function instanceOfUserMerchantWalletCreateAPIModel(value: object): boolean {
    if (!('signature' in value)) return false;
    if (!('message' in value)) return false;
    return true;
}

export function UserMerchantWalletCreateAPIModelFromJSON(json: any): UserMerchantWalletCreateAPIModel {
    return UserMerchantWalletCreateAPIModelFromJSONTyped(json, false);
}

export function UserMerchantWalletCreateAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMerchantWalletCreateAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'signature': SignatureComponentsAPIModelFromJSON(json['signature']),
        'message': json['message'],
    };
}

export function UserMerchantWalletCreateAPIModelToJSON(value?: UserMerchantWalletCreateAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'signature': SignatureComponentsAPIModelToJSON(value['signature']),
        'message': value['message'],
    };
}

