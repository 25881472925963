/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlockchainsApiAPIModel
 */
export interface BlockchainsApiAPIModel {
    /**
     * 
     * @type {string}
     * @memberof BlockchainsApiAPIModel
     */
    publicApi: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainsApiAPIModel
     */
    securedApi?: string;
    /**
     * 
     * @type {string}
     * @memberof BlockchainsApiAPIModel
     */
    openApi?: string;
}

/**
 * Check if a given object implements the BlockchainsApiAPIModel interface.
 */
export function instanceOfBlockchainsApiAPIModel(value: object): boolean {
    if (!('publicApi' in value)) return false;
    return true;
}

export function BlockchainsApiAPIModelFromJSON(json: any): BlockchainsApiAPIModel {
    return BlockchainsApiAPIModelFromJSONTyped(json, false);
}

export function BlockchainsApiAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlockchainsApiAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'publicApi': json['publicApi'],
        'securedApi': json['securedApi'] == null ? undefined : json['securedApi'],
        'openApi': json['openApi'] == null ? undefined : json['openApi'],
    };
}

export function BlockchainsApiAPIModelToJSON(value?: BlockchainsApiAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'publicApi': value['publicApi'],
        'securedApi': value['securedApi'],
        'openApi': value['openApi'],
    };
}

