/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckEmailResetAllowanceRespAPIModel
 */
export interface CheckEmailResetAllowanceRespAPIModel {
    /**
     * 
     * @type {boolean}
     * @memberof CheckEmailResetAllowanceRespAPIModel
     */
    isAllowed: boolean;
}

/**
 * Check if a given object implements the CheckEmailResetAllowanceRespAPIModel interface.
 */
export function instanceOfCheckEmailResetAllowanceRespAPIModel(value: object): boolean {
    if (!('isAllowed' in value)) return false;
    return true;
}

export function CheckEmailResetAllowanceRespAPIModelFromJSON(json: any): CheckEmailResetAllowanceRespAPIModel {
    return CheckEmailResetAllowanceRespAPIModelFromJSONTyped(json, false);
}

export function CheckEmailResetAllowanceRespAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckEmailResetAllowanceRespAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'isAllowed': json['isAllowed'],
    };
}

export function CheckEmailResetAllowanceRespAPIModelToJSON(value?: CheckEmailResetAllowanceRespAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'isAllowed': value['isAllowed'],
    };
}

