import { useCallback } from 'react';

import { useStateMountSafe } from '@/hooks';
import { noop, withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { rejected } from '@/infrastructure/utils/ui';

import useTermsOfService from '../useTermsOfService';

import { TermsOfService } from './components';

import type React from 'react';

interface UseTermsOfServiceDialogType {
  withTermsAcceptance: <V extends unknown[], R = unknown>(func: (acceptance: Func) => Func<V, R>) => Func<V, R>;
  acceptanceModal: React.ReactNode;
}

export default function useTermsOfServiceDialog(dataTest?: string): UseTermsOfServiceDialogType {
  const { accepted, accept } = useTermsOfService();
  const [acceptance, setAcceptance] = useStateMountSafe<{
    onAccept: () => unknown;
    onCancel: () => unknown;
  }>();

  const withTermsAcceptance = useCallback(
    <V extends unknown[], R>(func: (acceptance: Func) => Func<V, R>) =>
      async (...params: V) => {
        const acceptancePromise = !accepted
          ? () =>
              new Promise((resolve, reject) => {
                setAcceptance({
                  onAccept: withFinally(
                    async () => {
                      await accept();
                      resolve('ok');
                    },
                    () => setAcceptance(undefined),
                  ),
                  onCancel: withFinally(
                    () => reject(rejected()),
                    () => setAcceptance(undefined),
                  ),
                });
              })
          : noop;
        return func(acceptancePromise)(...params);
      },
    [accept, accepted, setAcceptance],
  );
  return {
    withTermsAcceptance,
    acceptanceModal: acceptance ? (
      <TermsOfService data-test={dataTest} onAccept={acceptance.onAccept} onReject={acceptance.onCancel} />
    ) : undefined,
  };
}
