export const appPathPrefix = window.env.PUBLIC_URL.replace(/\/$/, '');
export const appPath = `${window.location.origin}${appPathPrefix.length ? `/${appPathPrefix}` : ''}`;

export const REDIRECT_QUERY_PARAM = 'redirect';

export const parseRedirectURLParam = () => {
  try {
    const redirectURL = new URLSearchParams(window.location.search).get(REDIRECT_QUERY_PARAM);
    return redirectURL?.startsWith(appPath) ? redirectURL : null;
  } catch (e) {
    console.warn(e);
    return null;
  }
};

export const prepareRedirectURLParamRoute = () => parseRedirectURLParam()?.replace(appPath, '');

export const prepareRedirectURLParam = () => {
  const maybeDefinedURL = parseRedirectURLParam();
  const currentURL = window.location.href;
  return maybeDefinedURL?.startsWith(appPath) ? maybeDefinedURL : currentURL;
};
