import { useCallback, useMemo } from 'react';

import type { UpdateQRSupportFormData } from '@/features/company-settings/components';
import { useCompanyActiveAssets, useCompanySettings } from '@/features/company-settings/hooks';
import { useNetworkBlockchainSystemInfos, useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { combine, type LoadingStateWithDirty } from '@/infrastructure/model';

const useQRSupportFormInitialValues = () => {
  const { network } = useSelectedNetwork();
  const settingState = useCompanySettings();
  const assetsState = useCompanyActiveAssets();
  const blockchainState = useNetworkBlockchainSystemInfos(network);

  const data = useMemo<LoadingStateWithDirty<UpdateQRSupportFormData>>(
    () =>
      combine(
        combine(settingState.data, blockchainState.data, (settings, blockchains) =>
          blockchains
            .filter(({ forwarder }) => forwarder !== 'not-supported')
            .map(
              ({ bt }) =>
                settings.qrSupport.find(({ blockchain }) => blockchain === bt) ?? {
                  blockchain: bt,
                  isEnabled: false,
                  isDeployed: false,
                },
            ),
        ),
        assetsState.data,
        (selected, assets) => ({ assets, selected }),
      ),
    [assetsState.data, blockchainState.data, settingState.data],
  );
  const loading = settingState.loading || blockchainState.loading;
  const forceRefresh = useCallback(
    () => Promise.all([settingState.forceRefresh(), blockchainState.forceRefresh()]),
    [blockchainState, settingState],
  );
  return { data, loading, forceRefresh };
};

export default useQRSupportFormInitialValues;
