import { UserOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import React, { useCallback } from 'react';
import Truncate from 'react-truncate-inside';

import { useAppSelector } from '@/app/hooks';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useStateMountSafe } from '@/hooks';

import type { AvatarTitleProps } from './types';

const selectAuthToken = makeSelectAuthToken();

const AvatarTitle: React.FC<AvatarTitleProps> = ({ 'data-test': dataTest }) => {
  const { data: auth } = useAppSelector(selectAuthToken);
  const [mouseIn, setMouseIn] = useStateMountSafe<boolean>();
  const onMouseEnter = useCallback(() => setMouseIn(true), [setMouseIn]);
  const onMouseLeave = useCallback(() => setMouseIn(false), [setMouseIn]);
  return (
    <Space className="ym-hide-content" data-test={dataTest} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <UserOutlined />
      {auth?.info.address && (
        <>
          <Truncate text={auth.info.address} offset={mouseIn ? 4 : 5} width={mouseIn ? 100 : 114} />
          <Typography.Text key={`${auth.info.address}${mouseIn}`} copyable={mouseIn && { text: auth.info.address }} />
        </>
      )}
    </Space>
  );
};

const AvatarTitleMemo = React.memo(AvatarTitle);

export default AvatarTitleMemo;
