import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { DonationTransactionsTable } from '@/features/donations/components';
import { useDonationTransactionsForDonationListView } from '@/features/donations/hooks';
import { I18nPageDonations } from '@/generated/i18n/i18n';

import type { DonationTransactionsCardProps } from './types';
import type { FC } from 'react';

const DonationTransactionsCard: FC<DonationTransactionsCardProps> = ({ donationId, ...props }) => {
  const parameters = useDonationTransactionsForDonationListView(donationId);
  return (
    <PageCard
      title={<FormattedMessage id={I18nPageDonations.DONATION_VIEW_COMPONENTS_TRANSACTIONS_CARD_TITLE} />}
      cardSize="big"
    >
      <DonationTransactionsTable {...parameters} {...props} />
    </PageCard>
  );
};

const DonationTransactionsCardMemo = React.memo(DonationTransactionsCard);

export default DonationTransactionsCardMemo;
