import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshMerchantWalletTransfer } from '@/features/merchant-wallet-transfers/components';
import { useMerchantWalletTransfer } from '@/features/merchant-wallet-transfers/hooks';
import type { MerchantWalletTransfer } from '@/features/merchant-wallet-transfers/types';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';

import type React from 'react';

const withMerchantWalletTransferDataLoading =
  <
    Original extends { data: MerchantWalletTransfer; forceRefresh: Func } & TestableProps,
    Wrapper extends Omit<Original, 'data' | 'forceRefresh'> & {
      transferId: string;
    } = Omit<Original, 'data' | 'forceRefresh'> & { transferId: string },
  >(
    Component: React.ComponentType<Original>,
  ): React.FC<Wrapper> =>
  (props) => {
    const dataTest = props['data-test'];
    const data = useMerchantWalletTransfer(props.transferId);
    return withCardDataLoading<MerchantWalletTransfer, Original, 'data' | 'forceRefresh'>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshMerchantWalletTransfer
              id={props.transferId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)({ ...props, forceRefresh: data.forceRefresh });
  };

export default withMerchantWalletTransferDataLoading;
