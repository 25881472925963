import { Spin, Typography } from 'antd';
import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';

import { ActiveProfileCardOperations } from './components';

import type { ProfileCardContainerProps } from './types';

const { Paragraph } = Typography;

const ProfileCardContainer: React.FC<ProfileCardContainerProps> = ({
  'data-test': dataTest,
  description,
  loading,
  children,
}) => (
  <PageCard
    data-test={dataTest}
    title={<FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_TITLE} />}
    extra={<ActiveProfileCardOperations data-test={dataTest} />}
    cardSize="big"
  >
    <Spin spinning={loading}>
      <Paragraph data-test={dataTest && `${dataTest}-description`}>{description}</Paragraph>
      {children}
    </Spin>
  </PageCard>
);

const ProfileCardContainerMemo = React.memo(ProfileCardContainer);

export default ProfileCardContainerMemo;
