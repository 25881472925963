import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import { paymentsLink } from '@/pages/payments/routes';

import type { PaymentsLinkProps } from './types';

const PaymentsLink: React.FC<PaymentsLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={paymentsLink()}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPagePayments.PAYMENTS_COMPONENTS_LINK_TITLE} />}
  />
);

const PaymentsLinkMemo = React.memo(PaymentsLink);

export default PaymentsLinkMemo;
