import type {
  WithdrawalReconciliationAPIModel,
  WithdrawalReconciliationPredicatesAPIModel,
  WithdrawalReconciliationSortByAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { WithdrawalReconciliationApi } from '@/generated/api/ncps-core/merchant-bo';
import { amountFromAPI, pageToAPI, type SliceDataRequestWithTotal, sliceFromAPI } from '@/infrastructure/api';
import { coreConfigurationFactory } from '@/infrastructure/request';

import type { SettlementReconciliation } from './types';

const settlementReconciliationApi = new WithdrawalReconciliationApi(coreConfigurationFactory('auth'));

export const parseSettlementReconciliation = ({
  expectedAmount,
  calculatedAmount,
  ...settlementReconciliation
}: WithdrawalReconciliationAPIModel): SettlementReconciliation => ({
  ...settlementReconciliation,
  expectedAmount: amountFromAPI(expectedAmount),
  calculatedAmount: amountFromAPI(calculatedAmount),
});

export const queryReconciliations = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<WithdrawalReconciliationPredicatesAPIModel, WithdrawalReconciliationSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await settlementReconciliationApi.getWithdrawalReconciliations(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseSettlementReconciliation,
  );

export const querySettlementReconciliation = async (settlementId: string, initOverrides?: RequestInit) =>
  parseSettlementReconciliation(
    await settlementReconciliationApi.getWithdrawalReconciliation(settlementId, initOverrides),
  );

export const requestRecalculateSettlementReconciliation = async (settlementId: string, initOverrides?: RequestInit) =>
  parseSettlementReconciliation(
    await settlementReconciliationApi.recalculateWithdrawalReconciliation(settlementId, initOverrides),
  );
