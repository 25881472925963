import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPaymentsSummaryStats } from '@/features/statistics/actions';
import { makeSelectPaymentsSummaryStats } from '@/features/statistics/selectors';
import type { PaymentSummaryStats } from '@/features/statistics/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<PaymentSummaryStats>, Parameters<typeof fetchPaymentsSummaryStats>[0]> =>
  fetchPaymentsSummaryStats({ force });
const selectFetching = makeSelectPending(fetchPaymentsSummaryStats);
const selectData = makeSelectPaymentsSummaryStats();

export default function usePaymentsSummaryStats() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
