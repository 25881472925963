import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { donationViewLink } from '@/pages/donations/routes';

import type { DonationViewLinkProps } from './types';

const DonationViewLink: React.FC<DonationViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={donationViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageDonations.DONATION_VIEW_COMPONENTS_LINK} />}
    icon={icon}
  />
);

const DonationViewLinkMemo = React.memo(DonationViewLink);

export default DonationViewLinkMemo;
