import { Form } from 'antd';
import { useCallback } from 'react';

import type { Func } from '@/infrastructure/utils/ts';

import useIsMounted from './useIsMounted';
import useStateMountSafe from './useStateMountSafe';

import type { FormInstance } from 'antd/es/form';

const { useForm: useFormBase } = Form;

interface UseFormType<Values> {
  form: FormInstance<Values>;
  withResetForm: <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>;
  resetForm: () => void;
}

export default function useForm<Values = unknown>(initialForm?: FormInstance<Values>): UseFormType<Values> {
  const [form] = useFormBase<Values>(initialForm);
  const isMounted = useIsMounted();
  const [, setRefreshMarker] = useStateMountSafe({});
  const [, setResetMarker] = useStateMountSafe({});
  const withResetForm = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        const result = await func(...params);
        if (isMounted()) {
          form.resetFields();
        }
        setRefreshMarker({});
        return result;
      },
    [form, isMounted, setRefreshMarker],
  );
  const resetForm = useCallback(() => {
    setResetMarker({});
  }, [setResetMarker]);
  return { form, withResetForm, resetForm };
}
