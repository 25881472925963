import { Space, Typography } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { GasWalletsList } from '@/features/gas-wallets/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import GasWalletLink from '@/pages/settings/company/gas-wallet/components/GasWalletLink';

import { withGasWalletsDataLoading } from './hocs';

import type { GasWalletsCardProps } from './types';

const { Paragraph } = Typography;

const GasWalletsCard: React.FC<GasWalletsCardProps> = ({ 'data-test': dataTest, data }) => (
  <Space direction="vertical" style={{ display: 'flex' }}>
    <Paragraph data-test={dataTest && `${dataTest}-description`}>
      <FormattedMessage id={I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_GAS_WALLETS_CARD_DESCRIPTION} />
    </Paragraph>
    <GasWalletsList data-test={dataTest && `${dataTest}-list`} data={data} GasWalletLink={GasWalletLink} />
  </Space>
);

const GasWalletsCardHOC = withGasWalletsDataLoading(GasWalletsCard);

const GasWalletsCardMemo = React.memo(GasWalletsCardHOC) as typeof GasWalletsCardHOC;

export default GasWalletsCardMemo;
