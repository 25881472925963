import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import { collectTaskViewLink } from '@/pages/collectable/routes';

import type { CollectTaskLinkProps } from './types';

const CollectTaskLink: React.FC<CollectTaskLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={collectTaskViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageCollectable.TASK_VIEW_COMPONENTS_LINK} />}
    icon={icon}
  />
);

const CollectTaskLinkMemo = React.memo(CollectTaskLink);

export default CollectTaskLinkMemo;
