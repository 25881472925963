import { css } from '@emotion/css';
import { Segmented, theme } from 'antd';
import React, { useMemo } from 'react';

import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import type { NetworkTypeSegmentProps } from './types';

const NetworkTypeSegment = ({ 'data-test': dataTest, readonly, ...InputProps }: NetworkTypeSegmentProps) => {
  const { token } = theme.useToken();
  return (
    <Segmented
      data-test={dataTest}
      disabled={readonly}
      options={useMemo(
        () => [
          {
            disabled: readonly,
            className: css`
              &.ant-segmented-item-selected {
                color: ${token.blue};
              }
            `,
            icon: BlockchainNetworkTypeAPIModel.MainNet,
            value: BlockchainNetworkTypeAPIModel.MainNet,
          },
          {
            disabled: readonly,
            className: css`
              &.ant-segmented-item-selected {
                color: ${token.orange};
              }
            `,
            icon: BlockchainNetworkTypeAPIModel.TestNet,
            value: BlockchainNetworkTypeAPIModel.TestNet,
          },
        ],
        [readonly, token.blue, token.orange],
      )}
      {...InputProps}
    />
  );
};

const NetworkTypeSegmentMemo = React.memo(NetworkTypeSegment);

export default NetworkTypeSegmentMemo;
