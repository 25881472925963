import React from 'react';

import type { TitleWithDetailsProps } from './types';

const TitleWithDetails: React.FC<TitleWithDetailsProps> = ({ title, link, 'data-test': dataTest }) => (
  <span style={{ display: 'flex' }} data-test={dataTest}>
    {title}
    <span style={{ marginLeft: 'auto' }}>{link}</span>
  </span>
);

const TitleWithDetailsMemo = React.memo(TitleWithDetails) as typeof TitleWithDetails;

export default TitleWithDetailsMemo;
