import { useAppLoadableData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCompanyUsers } from '@/features/company/actions';
import { makeSelectCompanyUsers } from '@/features/company/selectors';
import type { CompanyUser } from '@/features/company/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const fetch = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<CompanyUser[]>, Parameters<typeof fetchCompanyUsers>[0]> =>
  fetchCompanyUsers({ force });
const selectFetching = makeSelectPending(fetchCompanyUsers);
const selectData = makeSelectCompanyUsers();

export default function useCompanyUsers() {
  return useAppLoadableData(fetch, selectData, selectFetching);
}
