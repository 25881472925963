import { Form, Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useHandlers } from '@/features/global/hooks';
import { I18nFeatureReports } from '@/generated/i18n/i18n';

import ReportScheduleTemplateTitle from '../ReportScheduleTemplateTitle';

import type { ReportScheduleTemplateItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const ReportScheduleTemplateItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: ReportScheduleTemplateItemProps<Values>) => {
  const handlers = useHandlers('reportSchedules');

  const templates = useMemo(
    () =>
      handlers.map(({ template, Title }) => ({
        value: template,
        label: <Title />,
      })),
    [handlers],
  );
  return (
    <Form.Item<Values>
      required={required}
      name={name}
      label={<FormattedMessage id={I18nFeatureReports.LABELS_ROW_TITLE_TEMPLATE} />}
      {...ItemProps}
    >
      {readonly ? (
        <ReportScheduleTemplateTitle data-test={dataTest} />
      ) : (
        <Select disabled={readonly} data-test={dataTest} options={templates} allowClear />
      )}
    </Form.Item>
  );
};

const ReportScheduleTemplateItem = React.memo(ReportScheduleTemplateItemRaw) as typeof ReportScheduleTemplateItemRaw;

export default ReportScheduleTemplateItem;
