import {
  FileExcelOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
  FileWordOutlined,
} from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { ReportTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureReports } from '@/generated/i18n/i18n';

import type { ReportTypeViewProps } from './types';

const icons: Record<ReportTypeAPIModel, React.ReactNode> = {
  [ReportTypeAPIModel.DOC]: <FileWordOutlined />,
  [ReportTypeAPIModel.RTF]: <FileWordOutlined />,
  [ReportTypeAPIModel.CSV]: <FileExcelOutlined />,
  [ReportTypeAPIModel.XLS]: <FileExcelOutlined />,
  [ReportTypeAPIModel.PDF]: <FilePdfOutlined />,
  [ReportTypeAPIModel.TXT]: <FileTextOutlined />,
  [ReportTypeAPIModel.Unknown]: <FileUnknownOutlined />,
};

const i18n: Record<ReportTypeAPIModel, I18nMessages> = {
  [ReportTypeAPIModel.DOC]: I18nFeatureReports.COMPONENTS_TYPE_VIEW_LABEL_DOC,
  [ReportTypeAPIModel.RTF]: I18nFeatureReports.COMPONENTS_TYPE_VIEW_LABEL_RTF,
  [ReportTypeAPIModel.CSV]: I18nFeatureReports.COMPONENTS_TYPE_VIEW_LABEL_CSV,
  [ReportTypeAPIModel.XLS]: I18nFeatureReports.COMPONENTS_TYPE_VIEW_LABEL_XLS,
  [ReportTypeAPIModel.PDF]: I18nFeatureReports.COMPONENTS_TYPE_VIEW_LABEL_PDF,
  [ReportTypeAPIModel.TXT]: I18nFeatureReports.COMPONENTS_TYPE_VIEW_LABEL_TXT,
  [ReportTypeAPIModel.Unknown]: I18nFeatureReports.COMPONENTS_TYPE_VIEW_LABEL_UNKNOWN,
};

const ReportTypeViewRaw: React.FC<ReportTypeViewProps> = ({
  'data-test': dataTest,
  value = ReportTypeAPIModel.Unknown,
  mode = 'full',
}) => <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} />;

const ReportTypeView = React.memo(ReportTypeViewRaw);

export default ReportTypeView;
