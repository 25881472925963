import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

import type { IconActionProps } from './types';

const IconAction: React.FC<IconActionProps> = ({ 'data-test': dataTest, inProgress, icon, disabled, onClick }) => {
  if (inProgress) {
    return <LoadingOutlined data-test={dataTest} />;
  }
  if (!icon) {
    return null;
  }

  return disabled ? (
    React.cloneElement(icon, {
      ...icon.props,
      'data-test': dataTest,
      style: { ...icon.props.style, color: 'lightgray' },
    })
  ) : (
    <button
      onClick={onClick}
      style={{
        color: 'inherit',
        background: 'unset',
        borderBlock: 'unset',
        borderCollapse: 'unset',
        borderInline: 'unset',
        cursor: 'pointer',
        padding: 'unset',
        margin: 'unset',
      }}
      type="button"
      data-test={dataTest}
    >
      {icon}
    </button>
  );
};

const IconActionMemo = React.memo(IconAction);

export default IconActionMemo;
