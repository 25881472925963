import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { SettlementScheduleBreadcrumb } from '@/pages/settlements/schedule/components';

import SettlementIntentLink from '../SettlementIntentLink';

import type { SettlementIntentBreadcrumbProps } from './types';

const SettlementIntentBreadcrumb: React.FC<SettlementIntentBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  intentId,
  items = [],
}) => (
  <SettlementScheduleBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: <FormattedMessage id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_BREADCRUMB_PARENT_TITLE} />,
      },
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_BREADCRUMB_TITLE}
            values={{ id: intentId }}
          />
        ) : (
          <SettlementIntentLink
            value={intentId}
            mode="text"
            title={
              <FormattedMessage
                id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_BREADCRUMB_TITLE}
                values={{ id: intentId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const SettlementIntentBreadcrumbMemo = React.memo(SettlementIntentBreadcrumb);

export default SettlementIntentBreadcrumbMemo;
