import { FormattedMessage } from '@/components';
import { OperationRefreshSettlement } from '@/features/settlements/components';
import { useSettlement, useSettlementIntent } from '@/features/settlements/hooks';
import type { Settlement } from '@/features/settlements/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withSettlementDataLoading =
  <T extends { data: Settlement } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { intentId: string }> =>
  (props) => {
    const { data: intent } = useSettlementIntent(props.intentId);
    const data = useSettlement(intent.data?.withdrawalId);
    if (!intent.data?.withdrawalId) {
      return null;
    }
    return withCardDataLoading<Settlement, T>({
      ...data,
      'data-test': props['data-test'],
      title: <FormattedMessage id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_SETTLEMENT_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: intent.data.withdrawalId && (
          <OperationRefreshSettlement
            settlementId={intent.data.withdrawalId}
            data-test={props['data-test'] && `${props['data-test']}-opRefresh`}
          />
        ),
      },
    })(Component)(props);
  };

export default withSettlementDataLoading;
