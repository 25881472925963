import { PageContainer } from '@/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { SettlementIntentCard, SettlementCard, SettlementIntentBreadcrumb } from './components';
import { withIntentViewPageParams } from './hocs';

import type React from 'react';

type SettlementIntentPageProps = TestableProps & { pageParams: { intentId: string } };

const dataTest: string | undefined = 'intent';

const SettlementIntentPage: React.FC<SettlementIntentPageProps> = ({ pageParams: { intentId } }) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SettlementIntentBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} intentId={intentId} disabled />
    }
  >
    <SettlementIntentCard intentId={intentId} data-test={dataTest && `${dataTest}-card`} />
    <SettlementCard intentId={intentId} data-test={dataTest && `${dataTest}-withdrawal`} />
  </PageContainer>
);

export default withIntentViewPageParams(SettlementIntentPage);
