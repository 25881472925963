import React from 'react';

import type { OperationRefreshProps } from '@/components';
import { FormattedMessage, OperationRefresh } from '@/components';
import { useSubscriptionPlan } from '@/features/subscription-plans/hooks';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';

import type { OperationRefreshSubscriptionPlanProps } from './types';

const messages: OperationRefreshProps['messages'] = {
  tooltip: <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_RELOAD_PLAN_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_RELOAD_PLAN_SUCCESS_MESSAGE} />,
  },
};

const OperationRefreshSubscriptionPlan: React.FC<OperationRefreshSubscriptionPlanProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  planId,
}) => {
  const { forceRefresh } = useSubscriptionPlan(planId);
  return <OperationRefresh messages={messages} refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshSubscriptionPlanMemo = React.memo(OperationRefreshSubscriptionPlan);

export default OperationRefreshSubscriptionPlanMemo;
