import {
  DeploymentUnitOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  StopOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { LongRunningTaskStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureGlobal } from '@/generated/i18n/i18n';

import type { PendingStatusViewProps } from './types';

const i18n: Record<LongRunningTaskStatusAPIModel, I18nMessages> = {
  [LongRunningTaskStatusAPIModel.SubscriptionCancelling]:
    I18nFeatureGlobal.COMPONENTS_PENDING_STATUS_VIEW_SUBSCRIPTIONCANCELLING,
  [LongRunningTaskStatusAPIModel.SubscriptionPausing]:
    I18nFeatureGlobal.COMPONENTS_PENDING_STATUS_VIEW_SUBSCRIPTIONPAUSING,
  [LongRunningTaskStatusAPIModel.SubscriptionUnPausing]:
    I18nFeatureGlobal.COMPONENTS_PENDING_STATUS_VIEW_SUBSCRIPTIONUNPAUSING,
  [LongRunningTaskStatusAPIModel.RechargeCollecting]:
    I18nFeatureGlobal.COMPONENTS_PENDING_STATUS_VIEW_RECHARGECOLLECTING,
};

export const pendingStatusIcons: Record<LongRunningTaskStatusAPIModel, React.ReactNode> = {
  [LongRunningTaskStatusAPIModel.SubscriptionCancelling]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <StopOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [LongRunningTaskStatusAPIModel.SubscriptionPausing]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <PauseCircleOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [LongRunningTaskStatusAPIModel.SubscriptionUnPausing]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <PlayCircleOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [LongRunningTaskStatusAPIModel.RechargeCollecting]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <DeploymentUnitOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
};

const PendingStatusViewRaw: React.FC<PendingStatusViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? (
    <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={pendingStatusIcons[value]} mode={mode} />
  ) : null;

const PendingStatusView = React.memo(PendingStatusViewRaw);

export default PendingStatusView;
