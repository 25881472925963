import { QuestionCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import type { InputNumberTransform } from '@/components';
import { FormattedMessage, InputNumberItem } from '@/components';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { asType } from '@/infrastructure/utils/ts';

import type { SubscriptionPlanGraceItemProps } from './types';
import type { Store, Rule } from 'rc-field-form/lib/interface';

const minHours = 12;
export const recommendedHours = 24;

const transform: InputNumberTransform = {
  toInput: (value) => Math.floor((value ?? 0) / 60 / 60),
  fromInput: (value) => Math.floor((value ?? 0) * 60 * 60),
};

const SubscriptionPlanGraceItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  periodName,
  readonly,
  ItemProps = {},
}: SubscriptionPlanGraceItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const rules: Rule[] = useMemo(
    () =>
      !readonly
        ? [
            asType<Rule>({
              required: true,
              message: (
                <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_ERROR_REQUIRED} />
              ),
            }),
            ({ getFieldValue }) => ({
              validator: async (_, value) => {
                const period: number = getFieldValue(periodName) || 0;
                const grace: number = value || 0;
                return period && grace && period < grace ? Promise.reject(new Error()) : Promise.resolve();
              },
              message: (
                <FormattedMessage
                  id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_ERROR_BIGGER_THAN_PERIOD}
                />
              ),
            }),
            asType<Rule>({
              validator: async (_, v) =>
                (v || 0) < minHours * 60 * 60 ? Promise.reject(new Error()) : Promise.resolve(),
              message: (
                <FormattedMessage
                  id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_WARNING_TOO_LOW}
                  values={{ min: minHours, recommended: recommendedHours }}
                />
              ),
            }),
            asType<Rule>({
              validator: async (_, v) =>
                minHours * 60 * 60 <= (v || 0) && (v || 0) < recommendedHours * 60 * 60
                  ? Promise.reject(new Error())
                  : Promise.resolve(),
              warningOnly: true,
              message: (
                <FormattedMessage
                  id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_WARNING_TOO_LOW}
                  values={{ min: minHours, recommended: recommendedHours }}
                />
              ),
            }),
          ]
        : [],
    [periodName, readonly],
  );
  return (
    <InputNumberItem<Values>
      name={name}
      readonly={readonly}
      rules={rules}
      data-test={dataTest}
      transform={transform}
      InputProps={{
        min: minHours,
        addonAfter: <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_LABEL_SUFFIX} />,
        placeholder: formatMessage({ id: I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_PLACEHOLDER }),
      }}
      ItemProps={{ dependencies: [periodName], required: true, ...ItemProps }}
      messages={{
        label: (
          <Space>
            <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_LABEL} />
            <Tooltip
              title={<FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_HELP} />}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          </Space>
        ),
      }}
    />
  );
};

const SubscriptionPlanGraceItemMemo = React.memo(SubscriptionPlanGraceItem) as typeof SubscriptionPlanGraceItem;

export default SubscriptionPlanGraceItemMemo;
