import React from 'react';

import { CollectableEntityProcessTransactionView } from '@/features/collectable/components';

import { withCollectTaskTransactionDataLoading } from './hocs';

const CollectTaskTransactionCard = withCollectTaskTransactionDataLoading((props) => (
  <CollectableEntityProcessTransactionView {...props} columns={1} />
));
const CollectTaskTransactionCardMemo = React.memo(CollectTaskTransactionCard) as typeof CollectTaskTransactionCard;

export default CollectTaskTransactionCardMemo;
