/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { OpenIdProviderTypeAPIModel } from './OpenIdProviderTypeAPIModel';
import {
    OpenIdProviderTypeAPIModelFromJSON,
    OpenIdProviderTypeAPIModelFromJSONTyped,
    OpenIdProviderTypeAPIModelToJSON,
} from './OpenIdProviderTypeAPIModel';

/**
 * 
 * @export
 * @interface OnboardRequestAPIModel
 */
export interface OnboardRequestAPIModel {
    /**
     * 
     * @type {string}
     * @memberof OnboardRequestAPIModel
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardRequestAPIModel
     */
    companyWebsite: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardRequestAPIModel
     */
    companyEmail: string;
    /**
     * 
     * @type {OpenIdProviderTypeAPIModel}
     * @memberof OnboardRequestAPIModel
     */
    emailTokenProvider?: OpenIdProviderTypeAPIModel;
    /**
     * 
     * @type {string}
     * @memberof OnboardRequestAPIModel
     */
    emailToken?: string;
}

/**
 * Check if a given object implements the OnboardRequestAPIModel interface.
 */
export function instanceOfOnboardRequestAPIModel(value: object): boolean {
    if (!('companyName' in value)) return false;
    if (!('companyWebsite' in value)) return false;
    if (!('companyEmail' in value)) return false;
    return true;
}

export function OnboardRequestAPIModelFromJSON(json: any): OnboardRequestAPIModel {
    return OnboardRequestAPIModelFromJSONTyped(json, false);
}

export function OnboardRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnboardRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'companyName': json['companyName'],
        'companyWebsite': json['companyWebsite'],
        'companyEmail': json['companyEmail'],
        'emailTokenProvider': json['emailTokenProvider'] == null ? undefined : OpenIdProviderTypeAPIModelFromJSON(json['emailTokenProvider']),
        'emailToken': json['emailToken'] == null ? undefined : json['emailToken'],
    };
}

export function OnboardRequestAPIModelToJSON(value?: OnboardRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companyName': value['companyName'],
        'companyWebsite': value['companyWebsite'],
        'companyEmail': value['companyEmail'],
        'emailTokenProvider': OpenIdProviderTypeAPIModelToJSON(value['emailTokenProvider']),
        'emailToken': value['emailToken'],
    };
}

