import { Form } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import { v4 as uuid } from 'uuid';

import { EmailItem, FormattedMessage } from '@/components';
import { useEmailCodeState, useEmailConfirmation, useEmailConfirmationActions } from '@/features/email/hooks';
import { useReCaptchaAction } from '@/features/recaptcha/hooks';
import { isRecaptchaError } from '@/features/recaptcha/utils';
import { I18nFeatureEmail } from '@/generated/i18n/i18n';
import {
  useDefaultNotification,
  useFirstRenderEffect,
  useNotification,
  useStateMountSafe,
  useSubmitting,
} from '@/hooks';
import type { Func } from '@/infrastructure/utils/ts';
import { isRejected } from '@/infrastructure/utils/ui';

import { EmailCodeInputItem } from './components';

import type { EmailCodeInputItemProps } from './components';
import type { EmailConfirmationInputProps } from './types';

const EmailConfirmationInput: React.FC<EmailConfirmationInputProps> = ({
  'data-test': dataTest,
  value,
  onChange,
  passThroughEmail,
  disabled,
  withVerifying: withVerifyingBase,
}) => {
  const emailField = useMemo(() => uuid(), []);
  const codeField = useMemo(() => uuid(), []);
  const [email, saveEmail] = useStateMountSafe(() => value?.email);
  const confirmationState = useEmailConfirmation(email);
  const codeState = useEmailCodeState(email || value?.email);
  const isSelectedEmailPassedThrough = passThroughEmail === email;
  const { verifyEmailCode, requestEmailCode } = useEmailConfirmationActions();
  const doChange = useRef(onChange);
  useEffect(() => {
    doChange.current?.({ email, auth0Token: confirmationState?.auth0Token });
  }, [confirmationState?.auth0Token, email]);
  const [verifying, withVerifyingLocal] = useSubmitting(false);
  const withVerifying = useMemo(
    () => (withVerifyingBase ? withVerifyingBase(withVerifyingLocal) : withVerifyingLocal),
    [withVerifyingBase, withVerifyingLocal],
  );

  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const withNotification = useMemo(
    () =>
      <V extends unknown[], R>(func: Func<V, R>) =>
        withDefaultError(
          withSuccess(func, () => (
            <FormattedMessage id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_SEND_SUCCESS_MESSAGE} />
          )),
          {
            message: () => (
              <FormattedMessage id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_SEND_ERROR_MESSAGE} />
            ),
            skip: (e) => isRecaptchaError(e) || isRejected(e),
          },
        ),
    [withDefaultError, withSuccess],
  );

  const { withRecaptcha } = useReCaptchaAction('email_confirmation');

  const form = Form.useFormInstance<unknown>();
  useFirstRenderEffect(() => {
    if (value?.email) {
      setTimeout(() => {
        form.setFieldValue(emailField, value.email);
      }, 0);
    }
  });
  useEffect(() => {
    if (confirmationState?.auth0Token) {
      form.resetFields([codeField]);
      if (confirmationState.email === email) {
        setTimeout(() => form.setFieldValue(codeField, confirmationState.code));
      }
    }
  }, [codeField, confirmationState?.auth0Token, confirmationState?.code, confirmationState?.email, email, form]);

  const onRequireCode: EmailCodeInputItemProps<unknown>['onRequireCode'] = useMemo(
    () =>
      email
        ? withNotification(
            withVerifying(
              withRecaptcha((reCaptcha) => async () => {
                await requestEmailCode.act({ email, ...reCaptcha });
                form.resetFields([codeField]);
              }),
            ),
          )
        : undefined,
    [email, codeField, form, requestEmailCode, withNotification, withRecaptcha, withVerifying],
  );
  const onVerifyCode: EmailCodeInputItemProps<unknown>['onVerifyCode'] = useMemo(
    () =>
      email
        ? withVerifying(
            withRecaptcha(
              (reCaptcha) => async (v: { code: string }) => verifyEmailCode.act({ email, ...v, ...reCaptcha }),
            ),
          )
        : undefined,
    [email, verifyEmailCode, withRecaptcha, withVerifying],
  );

  return (
    <>
      <EmailItem
        data-test={dataTest && `${dataTest}-email`}
        name={emailField}
        required
        ItemProps={{ label: null }}
        InputProps={{ disabled: disabled || verifying, placeholder: 'email@gmail.com' }}
      />
      <EmailCodeInputItem
        data-test={dataTest && `${dataTest}-code`}
        name={codeField}
        email={email}
        disabled={
          !email || disabled || (!!passThroughEmail && isSelectedEmailPassedThrough) || !!confirmationState?.auth0Token
        }
        onRequireCode={onRequireCode}
        onVerifyCode={onVerifyCode}
        isConfirmed={!!confirmationState?.auth0Token || (!!passThroughEmail && email === passThroughEmail)}
        codeState={codeState}
      />
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue, resetFields, getFieldError, isFieldValidating }) => {
          const emailValue: string | undefined = getFieldValue(emailField);
          const isEmailStateConsistent =
            !getFieldError(emailField).length && !isFieldValidating(emailField) && !!emailValue;
          const newEmail = isEmailStateConsistent ? emailValue : undefined;
          if (email !== newEmail) {
            setTimeout(() => {
              saveEmail(newEmail);
              resetFields([codeField]);
            }, 0);
          }
          return null;
        }}
      </Form.Item>
    </>
  );
};

const EmailConfirmationInputMemo = React.memo(EmailConfirmationInput);

export default EmailConfirmationInputMemo;
