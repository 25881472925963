import { Statistic } from 'antd';
import React, { useCallback } from 'react';

import { AssetAmount } from '@/features/dictionary/blockchain/components';

import type { BalanceStatisticsContainerProps } from './types';

const style: React.CSSProperties = { textAlign: 'center' };

const BalanceStatisticsContainer: React.FC<BalanceStatisticsContainerProps> = ({
  'data-test': dataTest,
  title,
  data,
}) => (
  <Statistic
    style={style}
    title={title}
    valueRender={useCallback(
      () => (
        <AssetAmount data-test={dataTest} value={data.value} assetId={data.asset} withCurrency />
      ),
      [data.asset, data.value, dataTest],
    )}
  />
);

const BalanceStatisticsContainerMemo = React.memo(BalanceStatisticsContainer);

export default BalanceStatisticsContainerMemo;
