import { Button, Form, Space } from 'antd';
import React from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';

import type { FormFooterProps } from './types';

const FormFooterRaw: React.FC<FormFooterProps> = ({
  'data-test': dataTest,
  className,
  style,
  submitting,
  submitStyle,
  submitDisabled,
  cancelStyle,
  cancelDisabled,
  readonly,
  messages,
  tailLayout,
  noStyle,
  extraButtons,
  noCancel,
}) =>
  !readonly ? (
    <Form.Item noStyle={noStyle} {...tailLayout}>
      <Space className={className} style={style}>
        {extraButtons?.start}
        <Button
          data-test={dataTest && `${dataTest}-submit`}
          // size="middle"
          type="primary"
          htmlType="submit"
          loading={submitting}
          disabled={submitDisabled}
          style={submitStyle}
        >
          {messages?.submit || <FormattedMessage id={I18nComponents.FORM_FOOTER_SUBMIT} tagName="span" />}
        </Button>
        {extraButtons?.mid}
        {!noCancel && (
          <Button
            data-test={dataTest && `${dataTest}-cancel`}
            // size="middle"
            htmlType="reset"
            disabled={cancelDisabled}
            style={cancelStyle}
          >
            {messages?.cancel || <FormattedMessage id={I18nComponents.FORM_FOOTER_CANCEL} tagName="span" />}
          </Button>
        )}
        {extraButtons?.end}
      </Space>
    </Form.Item>
  ) : null;

const FormFooter = React.memo(FormFooterRaw);

export default FormFooter;
