import { QuestionCircleOutlined } from '@ant-design/icons';
import { Descriptions, Space, Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent } from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';

import SubscriptionPlanGraceView from '../SubscriptionPlanGraceView';
import SubscriptionPlanScheduleView from '../SubscriptionPlanScheduleView';
import SubscriptionPlanStatusView from '../SubscriptionPlanStatusView';
import SubscriptionPlanTagsView from '../SubscriptionPlanTagsView';

import type { SubscriptionPlanViewProps } from './types';

const SubscriptionPlanView: React.FC<SubscriptionPlanViewProps> = ({
  'data-test': dataTest,
  data,
  className,
  style,
  columns = { xl: 2, lg: 1, md: 1, sm: 1, xs: 1 },
  Operations,
}) => {
  const { formatDateTime } = useLocaleSettings();
  return (
    <Descriptions
      style={style}
      className={className}
      contentStyle={{ fontWeight: 'bold', overflow: 'hidden' }}
      title={
        <SubscriptionPlanStatusView value={data.status} data-test={dataTest && `${dataTest}-status`} mode="full" />
      }
      extra={Operations && <Operations value={data} data-test={dataTest && `${dataTest}-op`} />}
      column={columns}
    >
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_PERIOD} />}>
        <SubscriptionPlanScheduleView
          value={data.periodSeconds}
          data-test={dataTest && `${dataTest}-period`}
          mode="full"
        />
      </Descriptions.Item>
      {data.gracePeriodSeconds && (
        <Descriptions.Item
          label={
            <Space>
              <FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_GRACE_PERIOD} />
              <Tooltip
                title={<FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_EDIT_FORM_GRACE_ITEM_HELP} />}
              >
                <QuestionCircleOutlined />
              </Tooltip>
            </Space>
          }
        >
          <SubscriptionPlanGraceView
            value={data.gracePeriodSeconds}
            data-test={dataTest && `${dataTest}-grace`}
            mode="full"
          />
        </Descriptions.Item>
      )}
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_AMOUNT} />}>
        <AssetAmount
          withCurrency
          assetId={data.amount.asset}
          value={data.amount.value}
          currencyMode="long"
          data-test={dataTest && `${dataTest}-amount`}
        />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_NAME} />}>
        <ReadonlyComponent value={data.name} data-test={dataTest && `${dataTest}-name`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_DESCRIPTION} />}>
        <ReadonlyComponent value={data.description} data-test={dataTest && `${dataTest}-description`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_CREATED_AT} />}>
        <ReadonlyComponent value={formatDateTime(data.createdAt)} data-test={dataTest && `${dataTest}-createdAt`} />
      </Descriptions.Item>
      <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_TAGS} />}>
        <SubscriptionPlanTagsView value={data.tags} data-test={dataTest && `${dataTest}-tags`} />
      </Descriptions.Item>
    </Descriptions>
  );
};

const SubscriptionPlanViewMemo = React.memo(SubscriptionPlanView);

export default SubscriptionPlanViewMemo;
