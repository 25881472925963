import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectTask } from '@/features/collectable/actions';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRechargeAddress } from '@/features/recharges/actions';
import { makeSelectRechargeAddress } from '@/features/recharges/selectors';
import type { RechargeAddress } from '@/features/recharges/types';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseRechargeAddress = UseAppSingleData<RechargeAddress>;

const noData = storedDataError<RechargeAddress>('no-data');

const fetchFactory = (
  addressId: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<RechargeAddress>, Parameters<typeof fetchRechargeAddress>[0]> =>
  fetchRechargeAddress({ addressId, force });

export default function useRechargeAddress(addressId: string | undefined): UseRechargeAddress {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchCollectTask, addressId), [addressId]);
  return useAppSingleData(fetchFactory, makeSelectRechargeAddress, noData, addressId, dataFetchingSelector);
}
