import { CheckOutlined, HourglassOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { InvoicePaymentTransactionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { PaymentTransactionStatusViewProps } from './types';

const icons: Record<InvoicePaymentTransactionStatusAPIModel, React.ReactNode> = {
  [InvoicePaymentTransactionStatusAPIModel.Confirmed]: <CheckOutlined style={{ color: 'green' }} />,
  [InvoicePaymentTransactionStatusAPIModel.Confirming]: <HourglassOutlined spin style={{ color: 'blue' }} />,
};

const i18n: Record<InvoicePaymentTransactionStatusAPIModel, I18nMessages> = {
  [InvoicePaymentTransactionStatusAPIModel.Confirmed]: I18nFeaturePayments.COMPONENTS_TRANSACTION_STATUS_CONFIRMED,
  [InvoicePaymentTransactionStatusAPIModel.Confirming]: I18nFeaturePayments.COMPONENTS_TRANSACTION_STATUS_CONFIRMING,
};

const PaymentTransactionStatusViewRaw: React.FC<PaymentTransactionStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const PaymentTransactionStatusView = React.memo(
  PaymentTransactionStatusViewRaw,
) as typeof PaymentTransactionStatusViewRaw;

export default PaymentTransactionStatusView;
