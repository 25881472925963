import { useCallback } from 'react';

import useStateMountSafe from '@/hooks/useStateMountSafe';
import type { Func } from '@/infrastructure/utils/ts';

export type UseErrorHandlerType = [
  error: string | undefined,
  withErrorHandling: <V extends unknown[], R>(func: Func<V, R>) => Func<V, void>,
  reset: () => void,
];

export default function useErrorHandler(initialValue?: string): UseErrorHandlerType {
  const [error, setError] = useStateMountSafe<string | undefined>(initialValue);
  const reset = useCallback(() => setError(initialValue), [initialValue, setError]);
  const withErrorHandling = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        try {
          await func(...params);
          setError(undefined);
        } catch (e: unknown) {
          // eslint-disable-next-line no-nested-ternary
          setError(e instanceof Error ? e.message : typeof e === 'string' ? e : JSON.stringify(e));
        }
      },
    [setError],
  );

  return [error, withErrorHandling, reset];
}
