import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  ErrorFormMessage,
  FormattedMessage,
  FormFooter,
  InputItem,
} from '@/components';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { DeactivateReasonFormValues, DeactivateReasonFormProps } from './types';

const reasonMessages = {
  label: (
    <FormattedMessage
      id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_REASON_LABEL}
    />
  ),
  required: (
    <FormattedMessage
      id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_REASON_REQUIRED}
    />
  ),
};

const footerMessages = {
  submit: (
    <FormattedMessage
      id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_SUBMIT}
      tagName="span"
    />
  ),
};

const DeactivateReasonFormRaw: React.FC<DeactivateReasonFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const { formatMessage } = useIntl();
  const { form, withResetForm } = useForm<DeactivateReasonFormValues>();
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const doReset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const doSubmit = useCallback(
    (values: DeactivateReasonFormValues): unknown => withSubmitting(onSubmit)(values.reason),
    [onSubmit, withSubmitting],
  );

  return (
    <Form<DeactivateReasonFormValues> {...layout} autoComplete="off" form={form} onFinish={doSubmit} onReset={doReset}>
      {error && <ErrorFormMessage content={error} />}
      <InputItem<DeactivateReasonFormValues>
        data-test={dataTest && `${dataTest}-deactivateReasonInput`}
        name={nameof<DeactivateReasonFormValues>('reason')}
        required
        messages={reasonMessages}
        placeholder={formatMessage({
          id: I18nFeatureReportSchedules.COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_REASON_PLACEHOLDER,
        })}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-footer`}
        tailLayout={tailLayout}
        submitting={submitting}
        messages={footerMessages}
      />
    </Form>
  );
};

const DeactivateReasonForm = React.memo(DeactivateReasonFormRaw);

export default DeactivateReasonForm;
