import { LockTwoTone } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Form, Input } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';

import type { PasswordItemProps } from './types';
import type { Rule, Store } from 'rc-field-form/es/interface';

const PasswordItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  placeholder,
  messages,
  isNew,
  readonly,
  required,
  ItemProps = {},
}: PasswordItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const rules = useMemo<Rule[]>(
    () => [
      {
        pattern: /.{8,}/,
        message: <FormattedMessage id={I18nComponents.PASSWORD_MESSAGE_WEAK} />,
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async ({ pattern }, value?: string) => {
          if (!value) {
            return;
          }
          if (!pattern?.test(value)) {
            throw new Error();
          }
        },
      },
      ...(required
        ? [
            {
              required: true,
              message: <FormattedMessage id={I18nComponents.PASSWORD_REQUIRED} />,
            },
          ]
        : []),
    ],
    [required],
  );
  return (
    <Form.Item<Values>
      label={messages?.label ?? <FormattedMessage id={I18nComponents.PASSWORD_LABEL} />}
      rules={rules}
      required={required}
      {...ItemProps}
      name={name}
    >
      <Input.Password
        data-test={dataTest}
        className="ym-hide-content"
        disabled={readonly}
        autoComplete={isNew ? 'new-password' : undefined}
        prefix={
          <LockTwoTone
            className={css`
              padding-top: 2px;
              color: #1890ff;
            `}
          />
        }
        placeholder={!readonly ? placeholder || formatMessage({ id: I18nComponents.PASSWORD_PLACEHOLDER }) : undefined}
      />
    </Form.Item>
  );
};

const PasswordItem = React.memo(PasswordItemRaw) as typeof PasswordItemRaw;

export default PasswordItem;
