/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { DonationAddressAPIModel } from './DonationAddressAPIModel';
import {
    DonationAddressAPIModelFromJSON,
    DonationAddressAPIModelFromJSONTyped,
    DonationAddressAPIModelToJSON,
} from './DonationAddressAPIModel';

/**
 * 
 * @export
 * @interface DonationAPIModel
 */
export interface DonationAPIModel {
    /**
     * 
     * @type {string}
     * @memberof DonationAPIModel
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DonationAPIModel
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DonationAPIModel
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof DonationAPIModel
     */
    imageUrl: string;
    /**
     * 
     * @type {Date}
     * @memberof DonationAPIModel
     */
    createdAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DonationAPIModel
     */
    isActive: boolean;
    /**
     * 
     * @type {Array<DonationAddressAPIModel>}
     * @memberof DonationAPIModel
     */
    addresses?: Array<DonationAddressAPIModel>;
}

/**
 * Check if a given object implements the DonationAPIModel interface.
 */
export function instanceOfDonationAPIModel(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('title' in value)) return false;
    if (!('description' in value)) return false;
    if (!('imageUrl' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('isActive' in value)) return false;
    return true;
}

export function DonationAPIModelFromJSON(json: any): DonationAPIModel {
    return DonationAPIModelFromJSONTyped(json, false);
}

export function DonationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DonationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'description': json['description'],
        'imageUrl': json['imageUrl'],
        'createdAt': (new Date(json['createdAt'])),
        'isActive': json['isActive'],
        'addresses': json['addresses'] == null ? undefined : ((json['addresses'] as Array<any>).map(DonationAddressAPIModelFromJSON)),
    };
}

export function DonationAPIModelToJSON(value?: DonationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'description': value['description'],
        'imageUrl': value['imageUrl'],
        'createdAt': ((value['createdAt']).toISOString()),
        'isActive': value['isActive'],
        'addresses': value['addresses'] == null ? undefined : ((value['addresses'] as Array<any>).map(DonationAddressAPIModelToJSON)),
    };
}

