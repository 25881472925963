import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import { AssetItem, BlockchainItem } from '@/features/dictionary/blockchain/components';
import { useSelectedNetworkActions } from '@/features/dictionary/blockchain/hooks';
import type { DonationFilterPredicate } from '@/features/donations/types';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { DonationTitleLikeFilterItem, DonationStatusFilterItem } from './components';

import type { DonationFilterFormProps } from './types';

const fields = [
  nameof<DonationFilterPredicate>('titleLike'),
  nameof<DonationFilterPredicate>('createdAtRange'),
  nameof<DonationFilterPredicate>('assetIn'),
  nameof<DonationFilterPredicate>('btIn'),
  nameof<DonationFilterPredicate>('isActiveEq'),
];

const createdAtRangeMessages = {
  to: {
    label: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_DONATION_FILTER_CREATED_AT_TO_LABEL} />,
  },
  from: {
    label: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_DONATION_FILTER_CREATED_AT_FROM_LABEL} />,
  },
};

const DonationFilterForm: React.FC<DonationFilterFormProps> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  const { network } = useSelectedNetworkActions();
  return (
    <FilterForm<DonationFilterPredicate> fields={fields} {...props}>
      <DateTimeRangeItem<DonationFilterPredicate>
        data-test={dataTest && `${dataTest}-createdAt`}
        name={nameof<DonationFilterPredicate>('createdAtRange')}
        messages={createdAtRangeMessages}
        readonly={readonly}
        required={false}
        relativeUpdateKey="createdAtRangeRelative"
      />
      <DonationTitleLikeFilterItem
        data-test={dataTest && `${dataTest}-title`}
        name={nameof<DonationFilterPredicate>('titleLike')}
        readonly={readonly}
        required={false}
      />
      <BlockchainItem
        data-test={dataTest && `${dataTest}-blockchain`}
        name={nameof<DonationFilterPredicate>('btIn')}
        multiselect
        network={network}
        readonly={readonly}
        required={false}
      />
      <AssetItem
        data-test={dataTest && `${dataTest}-asset`}
        name={nameof<DonationFilterPredicate>('assetIn')}
        multiselect
        assetType="exchangeable"
        network={network}
        readonly={readonly}
        required={false}
      />
      <DonationStatusFilterItem
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<DonationFilterPredicate>('isActiveEq')}
        readonly={readonly}
      />
    </FilterForm>
  );
};

const DonationFilterFormMemo = React.memo(DonationFilterForm);

export default DonationFilterFormMemo;
