import { Form } from 'antd';

import { FormattedMessage } from '@/components';
import BrandingAuthSelect from '@/features/branding/components/BrandingAuthSelect';
import type { BrandingAuth, BrandingData } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { BrandingAuthItemProps } from './types';
import type React from 'react';

const BrandingAuthItem: React.FC<BrandingAuthItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <Form.Item<BrandingData>
    name={nameof<BrandingData>('auth')}
    label={null}
    rules={[
      {
        validator: async (_, value?: BrandingAuth[]) =>
          !value?.length ? Promise.reject(new Error()) : Promise.resolve(),
        message: (
          <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_AUTH_ITEM_ERRORS_REQUIRED} />
        ),
      },
    ]}
  >
    <BrandingAuthSelect data-test={dataTest} multiselect readonly={disabled} />
  </Form.Item>
);

export default BrandingAuthItem;
