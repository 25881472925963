/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { SortAttributeGasHistorySortByAPIModel } from './SortAttributeGasHistorySortByAPIModel';
import {
    SortAttributeGasHistorySortByAPIModelFromJSON,
    SortAttributeGasHistorySortByAPIModelFromJSONTyped,
    SortAttributeGasHistorySortByAPIModelToJSON,
} from './SortAttributeGasHistorySortByAPIModel';

/**
 * 
 * @export
 * @interface PageGasHistorySortByAPIModel
 */
export interface PageGasHistorySortByAPIModel {
    /**
     * 
     * @type {number}
     * @memberof PageGasHistorySortByAPIModel
     */
    limit: number;
    /**
     * 
     * @type {number}
     * @memberof PageGasHistorySortByAPIModel
     */
    offset: number;
    /**
     * 
     * @type {Array<SortAttributeGasHistorySortByAPIModel>}
     * @memberof PageGasHistorySortByAPIModel
     */
    sortBy?: Array<SortAttributeGasHistorySortByAPIModel>;
}

/**
 * Check if a given object implements the PageGasHistorySortByAPIModel interface.
 */
export function instanceOfPageGasHistorySortByAPIModel(value: object): boolean {
    if (!('limit' in value)) return false;
    if (!('offset' in value)) return false;
    return true;
}

export function PageGasHistorySortByAPIModelFromJSON(json: any): PageGasHistorySortByAPIModel {
    return PageGasHistorySortByAPIModelFromJSONTyped(json, false);
}

export function PageGasHistorySortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageGasHistorySortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'limit': json['limit'],
        'offset': json['offset'],
        'sortBy': json['sortBy'] == null ? undefined : ((json['sortBy'] as Array<any>).map(SortAttributeGasHistorySortByAPIModelFromJSON)),
    };
}

export function PageGasHistorySortByAPIModelToJSON(value?: PageGasHistorySortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'limit': value['limit'],
        'offset': value['offset'],
        'sortBy': value['sortBy'] == null ? undefined : ((value['sortBy'] as Array<any>).map(SortAttributeGasHistorySortByAPIModelToJSON)),
    };
}

