import { LoadingOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';

import type { GasWalletOperationConfirmationDialogContentProps } from './types';

const GasWalletOperationConfirmationDialogContent: React.FC<GasWalletOperationConfirmationDialogContentProps> = ({
  'data-test': dataTest,
  inProgress,
  isBalanceFailed,
  isBalanceNonZero,
}) => {
  if (inProgress) {
    return (
      <Space direction="vertical">
        <LoadingOutlined />
        <FormattedMessage
          id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CONFIRMATION_DIALOG_MESSAGE_LOADING}
          tagName="span"
          data-test={dataTest}
        />
      </Space>
    );
  }

  if (isBalanceNonZero) {
    return (
      <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CONFIRMATION_DIALOG_MESSAGE_NON_ZERO_BALANCE} />
    );
  }
  return isBalanceFailed ? (
    <FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CONFIRMATION_DIALOG_MESSAGE_BALANCE_CHECK_ERROR} />
  ) : null;
};

const GasWalletOperationConfirmationDialogContentMemo = React.memo(GasWalletOperationConfirmationDialogContent);

export default GasWalletOperationConfirmationDialogContentMemo;
