import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { BrandingProfilesBreadcrumb } from '@/pages/settings/branding/profiles/components';

import BrandingProfileAddLink from '../BrandingProfileAddLink';

import type { BrandingProfileAddBreadcrumbProps } from './types';

const BrandingProfileAddBreadcrumb: React.FC<BrandingProfileAddBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <BrandingProfilesBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_ADD_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <BrandingProfileAddLink
            title={<FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_ADD_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="link"
          />
        ),
        ...items,
      },
    ]}
  />
);

const BrandingProfileAddBreadcrumbMemo = React.memo(BrandingProfileAddBreadcrumb);

export default BrandingProfileAddBreadcrumbMemo;
