import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRechargeTransactions } from '@/features/recharges/actions';
import {
  makeSelectRechargeTransactionsListData,
  makeSelectRechargeTransactionsListParameters,
} from '@/features/recharges/selectors';
import type { RechargeTransaction, RechargeTransactionFilterPredicate } from '@/features/recharges/types';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseRechargeTransactionList = UseAppListData<
  RechargeTransaction,
  RechargeTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<RechargeTransaction>, Parameters<typeof fetchRechargeTransactions>[0]> =>
  fetchRechargeTransactions({ force });

const dataSelector = makeSelectRechargeTransactionsListData();
const dataFetchingSelector = makeSelectPending(fetchRechargeTransactions);
const parametersSelector = makeSelectRechargeTransactionsListParameters();

export default function useRechargeTransactionList(): UseRechargeTransactionList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
