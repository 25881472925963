import { Form } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
  WebURLItem,
} from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { MerchantDomainFormProps, MerchantDomainFormValues } from './types';

const fields = [nameof<MerchantDomainFormValues>('url')];

const MerchantDomainForm: React.FC<MerchantDomainFormProps> = ({
  'data-test': dataTest,
  onSubmit,
  values,
  selectedValues = [],
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const oldValue = values?.url;
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { formatMessage } = useIntl();
  const { form, withResetForm } = useForm<MerchantDomainFormValues>();
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: MerchantDomainFormValues): unknown => onSubmit(val.url)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DOMAIN_FORM_ERROR_COMMON} />;
  }, [error]);

  return (
    <Form
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={values}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage content={errorMessage} />}
      <WebURLItem
        name={nameof<MerchantDomainFormValues>('url')}
        data-test={dataTest && `${dataTest}-url`}
        messages={{
          required: <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DOMAIN_FORM_URL_REQUIRED} />,
          placeholder: formatMessage({ id: I18nFeatureBranding.COMPONENTS_DOMAIN_FORM_URL_PLACEHOLDER }),
        }}
        rules={[
          {
            // eslint-disable-next-line @typescript-eslint/require-await
            validator: async (_, value: string) => {
              if (value !== oldValue && selectedValues.includes(value)) {
                throw new Error(formatMessage({ id: I18nFeatureBranding.COMPONENTS_DOMAIN_FORM_URL_DUPLICATE }));
              }
            },
            message: <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DOMAIN_FORM_URL_DUPLICATE} />,
          },
        ]}
        InputProps={{ className: 'ym-hide-content' }}
      />
      <FormCompletenessItem
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={({ getFieldValue }) =>
          !oldValue || getFieldValue(nameof<MerchantDomainFormValues>('url')) !== oldValue
        }
      />
      <FormFooter
        tailLayout={tailLayout}
        submitDisabled={!isFormComplete}
        submitting={submitting}
        messages={{
          submit: oldValue ? (
            <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DOMAIN_FORM_CREATE_SUBMIT} tagName="span" />
          ) : (
            <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DOMAIN_FORM_UPDATE_SUBMIT} tagName="span" />
          ),
        }}
        data-test={dataTest && `${dataTest}-submit`}
      />
    </Form>
  );
};

const MerchantDomainFormMemo = React.memo(MerchantDomainForm);

export default MerchantDomainFormMemo;
