import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { rechargeAddressViewLink } from '@/pages/recharges/routes';

import type { RechargeAddressViewLinkProps } from './types';

const RechargeAddressViewLink: React.FC<RechargeAddressViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={rechargeAddressViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageRecharges.ADDRESS_VIEW_COMPONENTS_LINK} />}
    icon={icon}
  />
);

const RechargeAddressViewLinkMemo = React.memo(RechargeAddressViewLink);

export default RechargeAddressViewLinkMemo;
