import { Typography } from 'antd';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nFeatureRecaptcha } from '@/generated/i18n/i18n';

import type { TextProps } from 'antd/es/typography/Text';

const i18Values = {
  pl: (link: React.ReactNode) => <Link external to="https://policies.google.com/privacy" title={link} />,
  tl: (link: React.ReactNode) => <Link external to="https://policies.google.com/terms" title={link} />,
};

const ReCaptchaLicense: React.FC<TextProps> = (props) => (
  <Typography.Text {...props}>
    <FormattedMessage id={I18nFeatureRecaptcha.LICENSE} values={i18Values} />
  </Typography.Text>
);

const ReCaptchaLicenseMemo = React.memo(ReCaptchaLicense);

export default ReCaptchaLicenseMemo;
