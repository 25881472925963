import { ReportSchedulesTable, ReportScheduleFilterForm } from '@/features/report-schedules/components';
import { useReportScheduleListView } from '@/features/report-schedules/hooks';
import { ReportsPageContainer } from '@/pages/reports/components';
import ReportScheduleAddLink from '@/pages/reports/report-schedule-add/components/ReportScheduleAddLink';
import ReportScheduleLink from '@/pages/reports/report-schedule-view/components/ReportScheduleViewLink';

import { ReportSchedulesTableOperations } from './components';

import type React from 'react';

const dataTest: string | undefined = 'reports';

const ReportSchedulesPage: React.FC = () => {
  const view = useReportScheduleListView();
  return (
    <ReportsPageContainer data-test={dataTest && `${dataTest}-container`} mode="report-schedules">
      <ReportSchedulesTable
        data-test={dataTest && `${dataTest}-table`}
        {...view}
        title={<ReportScheduleAddLink data-test={dataTest && `${dataTest}-add`} mode="button" />}
        FilterForm={ReportScheduleFilterForm}
        ReportScheduleLink={ReportScheduleLink}
        Operations={ReportSchedulesTableOperations}
      />
    </ReportsPageContainer>
  );
};

export default ReportSchedulesPage;
