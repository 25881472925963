import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshRechargeTransaction, RechargesDocumentationLink } from '@/features/recharges/components';
import { useRechargeTransaction } from '@/features/recharges/hooks';
import type { RechargeTransaction } from '@/features/recharges/types';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withRechargeTransactionDataLoading = <
  Original extends { data: RechargeTransaction } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { transactionId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithRechargeTransactionDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useRechargeTransaction(props.transactionId);
    return withCardDataLoading<RechargeTransaction, Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageRecharges.TRANSACTION_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: (
          <Space>
            <RechargesDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationRefreshRechargeTransaction
              transactionId={props.transactionId}
              data-test={dataTest && `${dataTest}-opRefresh`}
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withRechargeTransactionDataLoading;
