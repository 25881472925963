import React from 'react';

import DataFetchError from '@/components/DataFetchError';
import FormattedMessage from '@/components/FormattedMessage';
import MessageToSupport from '@/components/MessageToSupport';
import { I18nComponents } from '@/generated/i18n/i18n';

import type { IFrameErrorBannerProps } from './types';

const IFrameErrorBanner: React.FC<IFrameErrorBannerProps> = ({
  'data-test': dataTest,
  refresh,
  title,
  description,
}) => (
  <DataFetchError
    data-test={dataTest}
    message={title ?? <FormattedMessage id={I18nComponents.IFRAME_ERROR_BANNER_TITLE} />}
    description={description ?? <MessageToSupport data-test={dataTest} />}
    refresh={refresh}
  />
);

const IFrameErrorBannerMemo = React.memo(IFrameErrorBanner);

export default IFrameErrorBannerMemo;
