import { PlusCircleOutlined } from '@ant-design/icons';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useFormPageAction } from '@/hooks';
import { settingsBrandingProfileAddLink } from '@/pages/settings/routes';

import type { BrandingProfileAddLinkProps } from './types';

const BrandingProfileAddLink: React.FC<BrandingProfileAddLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
  copyOf,
}) => {
  const { openForm } = useFormPageAction();
  const doOpen = useCallback(() => openForm(settingsBrandingProfileAddLink(copyOf)), [copyOf, openForm]);
  return (
    <Operation
      data-test={dataTest}
      mode={mode}
      onClick={doOpen}
      icon={icon ?? <PlusCircleOutlined />}
      title={title ?? <FormattedMessage id={I18nPageSettings.BRANDING_PROFILE_ADD_COMPONENTS_LINK_TITLE} />}
    />
  );
};

const BrandingProfileAddLinkMemo = React.memo(BrandingProfileAddLink);

export default BrandingProfileAddLinkMemo;
