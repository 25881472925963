import React from 'react';

import { CompanyActiveAssetsView } from '@/features/company-settings/components';

import { withActiveAssetsDataLoading } from './hocs';

import type { AssetsCardProps } from './types';

const AssetsCard: React.FC<AssetsCardProps> = ({ data, 'data-test': dataTest }) => (
  <CompanyActiveAssetsView data-test={dataTest} selected={data} />
);

const AssetsCardLoaded = withActiveAssetsDataLoading(AssetsCard);

const AssetsCardMemo = React.memo(AssetsCardLoaded) as typeof AssetsCardLoaded;

export default AssetsCardMemo;
