import { BankOutlined } from '@ant-design/icons';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import DataBlockContainer from '../DataBlockContainer';

import { BrandingOnRampItem } from './components';

import type { OnRampBlockProps } from './types';
import type React from 'react';

const OnRampBlock: React.FC<OnRampBlockProps> = ({ 'data-test': dataTest, disabled }) => (
  <DataBlockContainer
    title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_ON_RAMP_BLOCK_TITLE} />}
    icon={<BankOutlined />}
    help={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_ON_RAMP_BLOCK_HELP} />}
  >
    <BrandingOnRampItem data-test={dataTest && `${dataTest}-select`} disabled={disabled} />
  </DataBlockContainer>
);

export default OnRampBlock;
