import type { AppRootState } from '@/app/store';
import { merchantWalletIdToStoreKey } from '@/features/merchant-wallets/utils';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { NAMESPACE } from './types';

export const { makeSelectMerchantWalletDeployment } = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].deployments,
  'MerchantWalletDeployment' as const,
  merchantWalletIdToStoreKey,
);

export const makeSelectMerchantWallets = () => (state: AppRootState) => state[NAMESPACE].wallets;
