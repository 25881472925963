import { useCallback, useEffect, useMemo } from 'react';

import { Web3AuthRecoveryKeyEnter, Web3AuthAccountReset } from '@/features/auth/components';
import { useEmailResetAllowance } from '@/features/auth/hooks';
import type { EmailAuthOptions } from '@/features/auth/hooks';
import { useStateMountSafe, usePrevious } from '@/hooks';
import type { Web3Auth } from '@/infrastructure/security/web3-auth';
import { withFinally } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { rejected, rejection } from '@/infrastructure/utils/ui';

const useWeb3AuthRecoveryKeyRecovery = ({ 'data-test': dataTest }: { 'data-test'?: string }) => {
  const [keyChecker, setKeyChecker] = useStateMountSafe<{
    web3Auth: Web3Auth;
    doReset: (() => Promise<unknown>) | undefined;
    email: string;
    doCheckKey: Func<[string], boolean>;
    doRejectKeyCheck: () => unknown;
  }>();
  const [isResetMode, setIsResetMode] = useStateMountSafe(false);
  const { data, forceRefresh } = useEmailResetAllowance(keyChecker?.email);
  const doSelectResetMode = useMemo(() => () => setIsResetMode(true), [setIsResetMode]);
  const doDeselectResetMode = useCallback(() => setIsResetMode(false), [setIsResetMode]);
  const previousData = usePrevious(data.data);
  useEffect(() => {
    if (previousData !== data.data) {
      setKeyChecker((prevState) => {
        if (!prevState) {
          return prevState;
        }
        return {
          ...prevState,
          doReset: data.data
            ? async () => {
                await prevState.web3Auth.reset();
                prevState.doRejectKeyCheck();
              }
            : undefined,
        };
      });
    }
  }, [previousData, data.data, setKeyChecker]);

  const withRecovery = useMemo(
    () =>
      <V extends unknown[] = unknown[], R = unknown>(
        func: (onRecoveryKeyRequest: EmailAuthOptions['onRecoveryKeyRequest']) => Func<V, R>,
      ) => {
        const onRecoveryKeyRequest = (web3Auth: Web3Auth) => {
          const { emailInfo } = web3Auth;
          if (!emailInfo) {
            throw rejected();
          }
          return new Promise((resolve, reject) => {
            setKeyChecker(() => ({
              web3Auth,
              email: emailInfo.email,
              doReset: data.data ? () => web3Auth.reset() : undefined,
              doCheckKey: async (secret: string) => {
                try {
                  const result = await web3Auth.recoveryKey.tryRestore(secret);
                  if (result) {
                    resolve(result);
                  }
                  return result;
                } catch (e) {
                  // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
                  reject(e);
                  return false;
                }
              },
              doRejectKeyCheck: () => {
                resolve(rejection);
              },
            }));
          });
        };
        return withFinally(func(onRecoveryKeyRequest), () => {
          doDeselectResetMode();
          setKeyChecker(undefined);
        });
      },
    [data.data, doDeselectResetMode, setKeyChecker],
  );

  return {
    withRecovery,
    // eslint-disable-next-line no-nested-ternary
    context: keyChecker ? (
      !isResetMode ? (
        <Web3AuthRecoveryKeyEnter
          data-test={dataTest && `${dataTest}-recovery`}
          onCancel={keyChecker.doRejectKeyCheck}
          onCheck={keyChecker.doCheckKey}
          onReset={doSelectResetMode}
        />
      ) : (
        <Web3AuthAccountReset
          data-test="reset-account"
          email={keyChecker.email}
          onReset={keyChecker.doReset}
          onCancel={doDeselectResetMode}
          onCheck={forceRefresh}
        />
      )
    ) : null,
  };
};

export default useWeb3AuthRecoveryKeyRecovery;
