import dayjs from 'dayjs';
import React from 'react';
import { useIntl } from 'react-intl';

import type { DateTimeItemProps } from '@/components';
import { DateTimeItem, FormattedMessage } from '@/components';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';

import type { ExpiresAtItemProps } from './types';
import type { FormItemProps } from 'antd/lib/form';
import type { Dayjs } from 'dayjs';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeaturePayments.LABELS_ROW_TITLE_EXPIRES_AT} />,
  required: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_EXPIRES_AT_REQUIRED} />,
};

const InputProps: DateTimeItemProps<unknown>['InputProps'] = {
  style: { width: '100%' },
  disabledDate: (current) => current < dayjs(),
};

const rules: FormItemProps<unknown>['rules'] = [
  {
    // eslint-disable-next-line @typescript-eslint/require-await
    validator: async (_, value?: Dayjs) => {
      if (!value) {
        return;
      }
      if (value.isBefore(dayjs().add(30, 'm'))) {
        throw new Error('');
      }
    },
    message: (
      <FormattedMessage
        id={I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_EXPIRES_AT_TOO_SMALL}
        values={{ minutes: 30 }}
      />
    ),
    validateTrigger: ['onBlur', 'onChange'],
  },
];

const ExpiresAtItem = <Values extends Store = Store>({ 'data-test': dataTest, name }: ExpiresAtItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <DateTimeItem<Values>
      data-test={dataTest}
      name={name}
      placeholder={formatMessage({
        id: I18nFeaturePayments.COMPONENTS_CREATE_PAYMENT_FORM_EXPIRES_AT_PLACEHOLDER,
      })}
      required
      messages={messages}
      InputProps={InputProps}
      rules={rules}
    />
  );
};

const ExpiresAtItemMemo = React.memo(ExpiresAtItem) as typeof ExpiresAtItem;

export default ExpiresAtItemMemo;
