import { useEffect, useMemo } from 'react';

import type { ValueStore, ValueType } from '@/components/LazyLoadSelect/types';
import { useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import type { Func } from '@/infrastructure/utils/ts';
import { notEmpty } from '@/infrastructure/utils/ts';

interface UseLazyLoadDefaultType<T extends number | string = string> {
  value: ValueType<T> | undefined;
  fetched: boolean;
}

const useLazyLoadDefault = <T extends number | string = string>({
  key,
  fetchData,
  cache,
}: {
  key?: T;
  cache: ValueStore<T>;
  fetchData: Func<[T], ValueType<T> | undefined>;
}): UseLazyLoadDefaultType<T> => {
  const [value, setValue] = useStateMountSafe<ValueType<T> | undefined>();
  const [fetched, setFetched] = useStateMountSafe<boolean>(false);
  const doUpdateValue = useMemo(
    () =>
      withSuppressPromise(async () => {
        if (!fetched) {
          setFetched(true);
          if (key) {
            const cachedValue = Object.values(cache)
              .filter(notEmpty)
              .flatMap(({ values }) => values)
              .find((cached) => cached.value === key);
            setValue(cachedValue ?? (await fetchData(key)));
          }
        }
      }),
    [cache, fetchData, fetched, key, setFetched, setValue],
  );
  useEffect(() => {
    if (key && !value && !fetched) {
      doUpdateValue();
    }
  }, [doUpdateValue, fetched, key, value]);

  return { value, fetched };
};

export default useLazyLoadDefault;
