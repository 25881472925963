import { Modal, Space, Spin } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import OperationRefreshMerchantWalletDeployment from '@/features/merchant-wallets/components/OperationRefreshMerchantWalletDeployment';
import { useMerchantWalletDeployment } from '@/features/merchant-wallets/hooks';
import { withWeb3Context } from '@/features/web3/hocs';
import { useWeb3, useWeb3Singleton } from '@/features/web3/hooks';
import { I18nFeatureMerchantWallets } from '@/generated/i18n/i18n';
import { useSubmitting } from '@/hooks';

import OperationDeployMerchantWalletWithConnectorMemo from '../OperationDeployMerchantWalletWithConnector';

import type { DeployMerchantWalletModalProps } from './types';

const DeployMerchantWalletModal: React.FC<DeployMerchantWalletModalProps> = (props) => {
  const { 'data-test': dataTest, onSuccess, onCancel, bt } = props;
  const { loading } = useMerchantWalletDeployment(bt);
  const { orderedConnectors } = useWeb3();
  const { withReloadIfInitialized } = useWeb3Singleton();
  const [inProgress, withInProgress] = useSubmitting(false);
  const doSuccess = useMemo(() => withReloadIfInitialized(onSuccess), [onSuccess, withReloadIfInitialized]);
  const doCancel = useMemo(() => withReloadIfInitialized(onCancel), [onCancel, withReloadIfInitialized]);

  return (
    <Modal
      open
      maskClosable={false}
      title={<FormattedMessage id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_DIALOG_TITLE} />}
      data-test={dataTest && `${dataTest}-modal`}
      onOk={doSuccess}
      onCancel={!inProgress ? doCancel : undefined}
      closable={!inProgress}
      footer=""
    >
      <Spin spinning={loading}>
        <Space direction="vertical">
          <FormattedMessage
            id={I18nFeatureMerchantWallets.COMPONENTS_OPERATION_DEPLOY_WALLET_DIALOG_DESCRIPTION}
            tagName="span"
            values={{
              refresh: (title: React.ReactNode) => (
                <OperationRefreshMerchantWalletDeployment bt={bt} title={title} mode="link" />
              ),
            }}
          />
          <Space direction="vertical" style={{ display: 'flex', flex: 1 }} size="middle">
            {orderedConnectors.map((connector) => (
              <OperationDeployMerchantWalletWithConnectorMemo
                {...props}
                data-test={dataTest && `${dataTest}-${connector.id}`}
                onSuccess={doSuccess}
                key={connector.id}
                connectorId={connector.id}
                bt={bt}
                withInProgress={withInProgress}
              />
            ))}
          </Space>
        </Space>
      </Spin>
    </Modal>
  );
};

const DeployMerchantWalletModalContexted = withWeb3Context(DeployMerchantWalletModal);

const DeployMerchantWalletModalMemo = React.memo(DeployMerchantWalletModalContexted);

export default DeployMerchantWalletModalMemo;
