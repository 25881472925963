import { GlobalOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nComponents } from '@/generated/i18n/i18n';
import { useSharedStyles } from '@/hooks';

import FormattedMessage from '../FormattedMessage';
import InputItem from '../InputItem';

import type { WebURLItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

// noinspection RegExpUnnecessaryNonCapturingGroup
const urlRegexp =
  /^(?:(?:http[s]?)(?::\/\/))?(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3]))|(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\.(?:[a-z\\u00a1-\\uffff]{2,})))\b(?:\/[^:]*)?$/;

const WebURLItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  messages: baseMessages,
  rules: baseRules = [],
  InputProps: BaseInputProps = {},
  ItemProps = {},
}: WebURLItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const { prefixIcon } = useSharedStyles();
  const rules = useMemo<NonNullable<WebURLItemProps<Values>['rules']>>(
    () => [
      !readonly
        ? {
            transform: (v?: string) => v?.trim(),
            // eslint-disable-next-line @typescript-eslint/require-await
            validator: async (_, value?: string) => {
              if (value && !urlRegexp.test(value)) {
                throw new Error(baseMessages?.error ?? i18n({ id: I18nComponents.WEB_URL_ERROR_INVALID }));
              }
            },
          }
        : { type: 'string' },
      ...baseRules,
    ],
    [baseRules, i18n, baseMessages?.error, readonly],
  );
  const messages = useMemo(
    () => ({
      label: baseMessages?.label ?? <FormattedMessage id={I18nComponents.WEB_URL_LABEL} />,
      required: baseMessages?.required ?? <FormattedMessage id={I18nComponents.WEB_URL_ERROR_REQUIRED} />,
    }),
    [baseMessages?.label, baseMessages?.required],
  );
  const InputProps = useMemo(
    () => ({
      prefix: !readonly ? <GlobalOutlined className={prefixIcon} /> : undefined,
      ...BaseInputProps,
      className: cx(BaseInputProps.className, 'ym-hide-content'),
    }),
    [BaseInputProps, prefixIcon, readonly],
  );
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      required={required}
      messages={messages}
      rules={rules}
      placeholder={baseMessages?.placeholder ?? i18n({ id: I18nComponents.WEB_URL_PLACEHOLDER })}
      InputProps={InputProps}
      ItemProps={ItemProps}
    />
  );
};

export const WebURLItemMemo = React.memo(WebURLItem) as typeof WebURLItem;

export default WebURLItemMemo;
