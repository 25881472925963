import { Form, Select } from 'antd';

import { FormattedMessage } from '@/components';
import { ReportScheduleStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import ReportScheduleStatusView from '../ReportScheduleStatusView';

import type { ReportScheduleStatusFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const options = enumValues(ReportScheduleStatusAPIModel).map((code) => ({
  value: code,
  label: <ReportScheduleStatusView value={code} />,
}));

const ReportScheduleStatusFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: ReportScheduleStatusFilterItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_FILTER_FORM_STATUS_LABEL} />}
    required={required}
    {...ItemProps}
    name={name}
  >
    <Select
      data-test={dataTest}
      placeholder={<FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_FILTER_FORM_STATUS_PLACEHOLDER} />}
      mode="multiple"
      options={options}
      allowClear
      disabled={readonly}
    />
  </Form.Item>
);

export default ReportScheduleStatusFilterItem;
