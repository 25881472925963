import React from 'react';

import { OperationRefresh } from '@/components';
import { useToS } from '@/features/branding/hooks';

import type { OperationRefreshToSProps } from './types';

const OperationRefreshToS: React.FC<OperationRefreshToSProps> = ({ 'data-test': dataTest, mode = 'inline' }) => {
  const { forceRefresh, loading } = useToS();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshToSMemo = React.memo(OperationRefreshToS);

export default OperationRefreshToSMemo;
