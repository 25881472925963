import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import {
  OperationCancelCollectSchedule,
  OperationRefreshCollectSchedule,
  OperationSetCollectSchedule,
} from '@/features/collectable/components';
import { useCollectSchedule } from '@/features/collectable/hooks';
import type { CollectSchedule } from '@/features/collectable/types';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withCollectableScheduleDataLoading = <
  Original extends { data: CollectSchedule } & TestableProps,
  Wrapper extends Omit<Original, 'data'> = Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
  Extra?: React.FC<TestableProps>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithCollectTaskDataLoading',
  )((props) => {
    const data = useCollectSchedule();
    return withCardDataLoading<CollectSchedule, Original>({
      ...data,
      'data-test': props['data-test'],
      title: (
        <Space>
          <FormattedMessage id={I18nPageCollectable.COMPONENTS_SCHEDULE_CARD_TITLE} />
        </Space>
      ),
      hideBack: true,
      cardSize: 'big',
      CardProps: {
        extra: (
          <Space>
            {Extra && <Extra data-test={props['data-test'] && `${props['data-test']}-docs`} />}
            <OperationSetCollectSchedule
              data-test={props['data-test'] && `${props['data-test']}-opSet`}
              schedule={data.data.data}
            />
            <OperationCancelCollectSchedule data-test={props['data-test'] && `${props['data-test']}-opCancel`} />
            <OperationRefreshCollectSchedule data-test={props['data-test'] && `${props['data-test']}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withCollectableScheduleDataLoading;
