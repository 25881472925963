import { FormattedMessage } from '@/components';
import { OperationRefreshCompanies } from '@/features/company/components';
import { useCompanies } from '@/features/company/hooks';
import type { CompanyWithSelection } from '@/features/company/types';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withCompaniesDataLoading = <
  Original extends { data: CompanyWithSelection[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'>,
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithUsersDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useCompanies();
    return withCardDataLoading<CompanyWithSelection[], Original>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.PROFILE_COMPONENTS_COMPANIES_CARD_TITLE} />,
      CardProps: {
        extra: <OperationRefreshCompanies data-test={dataTest && `${dataTest}-refreshOp`} />,
      },
    })(Component)(props);
  });

export default withCompaniesDataLoading;
