import { useContext } from 'react';

import { useAppSelector } from '@/app/hooks';
import { Web3AuthJWTInProgress } from '@/features/auth/hocs/withAuthorizationInit/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { makeSelectAuthStatus } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthStatus = makeSelectAuthStatus();

const withWeb3AuthJWTInit = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithWeb3AuthJWTInit',
  )((props) => {
    const web3Auth = useContext(Web3AuthContext);
    const authStatus = useAppSelector(selectAuthStatus);
    const isJWTUnauthorized = authStatus === AuthStatus.UNAUTHORIZED;
    const isWeb3AuthAuthorized = web3Auth.initialized && web3Auth.web3Auth?.isAuthorized();

    if (isWeb3AuthAuthorized && isJWTUnauthorized && web3Auth.web3Auth) {
      return (
        <MessageLayout>
          <Web3AuthJWTInProgress data-test="web3auth-jwt" web3Auth={web3Auth.web3Auth} />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withWeb3AuthJWTInit;
