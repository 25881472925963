import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPaymentTransaction } from '@/features/payment-transactions/actions';
import { makeSelectPaymentTransaction } from '@/features/payment-transactions/selectors';
import type { PaymentTransaction } from '@/features/payment-transactions/types';
import { searchIdToStoreKey } from '@/features/payment-transactions/utils';
import type { PaymentTransactionSearchIdAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UsePaymentTransaction = UseAppSingleData<PaymentTransaction>;

const noData = storedDataError<PaymentTransaction>('no-data');

const fetchFactory = (
  id: PaymentTransactionSearchIdAPIModel,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<PaymentTransaction>, Parameters<typeof fetchPaymentTransaction>[0]> =>
  fetchPaymentTransaction({ id, force });

export default function usePaymentTransaction(
  id: PaymentTransactionSearchIdAPIModel | undefined,
): UsePaymentTransaction {
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchPaymentTransaction, id && searchIdToStoreKey(id)),
    [id],
  );
  return useAppSingleData(fetchFactory, makeSelectPaymentTransaction, noData, id, dataFetchingSelector);
}
