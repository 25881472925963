import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { SettlementsBreadcrumb } from '@/pages/settlements/components';

import SettlementPendingIntentsLink from '../SettlementPendingIntentsLink';

import type { SettlementPendingIntentsBreadcrumbProps } from './types';

const SettlementPendingIntentsBreadcrumbRaw: React.FC<SettlementPendingIntentsBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <SettlementsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettlements.PENDING_COMPONENTS_BREADCRUMB} />
        ) : (
          <SettlementPendingIntentsLink
            mode="text"
            title={<FormattedMessage id={I18nPageSettlements.PENDING_COMPONENTS_BREADCRUMB} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const SettlementPendingIntentsBreadcrumb = React.memo(SettlementPendingIntentsBreadcrumbRaw);

export default SettlementPendingIntentsBreadcrumb;
