import React from 'react';

import { OperationRefresh } from '@/components';
import { useBrandingProfile } from '@/features/branding/hooks';

import type { OperationRefreshBrandingProfileProps } from './types';

const OperationRefreshBrandingProfile: React.FC<OperationRefreshBrandingProfileProps> = ({
  'data-test': dataTest,
  profileId,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useBrandingProfile(profileId);
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshBrandingProfileMemo = React.memo(OperationRefreshBrandingProfile);

export default OperationRefreshBrandingProfileMemo;
