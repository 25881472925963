import { FormOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import BrandingProfileSelect from '@/features/branding/components/BrandingProfileSelect';
import OperationDeleteBrandingProfile from '@/features/branding/components/OperationDeleteBrandingProfile';
import OperationUpdateBrandingProfileStatus from '@/features/branding/components/OperationUpdateBrandingProfileStatus';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { BrandingProfileSelectPanelProps } from './types';

const BrandingProfileSelectPanel: React.FC<BrandingProfileSelectPanelProps> = ({
  'data-test': dataTest,
  selected,
  disabled,
  onCreate,
  onSelect,
  onSelectedEdit,
  onSelectedDelete,
}) => {
  const onCopy = useCallback(() => (onCreate ? onCreate(selected) : undefined), [onCreate, selected]);
  return (
    <Space>
      <BrandingProfileSelect
        style={{ width: 300 }}
        data-test={dataTest && `${dataTest}-select`}
        value={selected}
        onChange={onSelect}
        readonly={disabled}
      />
      <Operation
        data-test={dataTest && `${dataTest}-editOp`}
        mode="inline"
        title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_VIEW_PROFILE_SELECT_PANEL_EDIT_TITLE} />}
        disabled={disabled}
        icon={<FormOutlined />}
        onClick={onSelectedEdit}
      />
      <OperationDeleteBrandingProfile
        data-test={dataTest && `${dataTest}-deleteOp`}
        mode="inline"
        profileId={selected}
        disabled={disabled}
        onSuccess={onSelectedDelete}
        confirmation
      />
      <OperationUpdateBrandingProfileStatus
        data-test={dataTest && `${dataTest}-statusOp`}
        mode="inline"
        profileId={selected}
        disabled={disabled}
      />
      {onCreate && (
        <Operation
          data-test={dataTest && `${dataTest}-copyOp`}
          mode="inline"
          title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_PROFILE_VIEW_PROFILE_SELECT_PANEL_COPY_TITLE} />}
          disabled={disabled}
          icon={<SnippetsOutlined />}
          onClick={onCopy}
        />
      )}
    </Space>
  );
};

const BrandingProfileSelectPanelMemo = React.memo(BrandingProfileSelectPanel);

export default BrandingProfileSelectPanelMemo;
