import { Alert } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyDateTime } from '@/components';
import { withSubscriptionDataLoading, withSubscriptionStatusGuard } from '@/features/subscriptions/hocs';
import { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import type { SubscriptionCancellationTimeoutAlertProps } from './types';

const SubscriptionCancellationTimeoutAlertRaw: React.FC<SubscriptionCancellationTimeoutAlertProps> = ({
  'data-test': dataTest,
  data,
}) => (
  <Alert
    message={
      <FormattedMessage
        id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_CANCELLATION_TIMEOUT_ALERT_TITLE}
        values={{ date: <ReadonlyDateTime value={data.nextChargeAt} /> }}
      />
    }
    data-test={dataTest}
    type="info"
    showIcon
  />
);

const SubscriptionCancellationTimeoutAlertLoaded = withSubscriptionDataLoading(
  withSubscriptionStatusGuard([SubscriptionStatusAPIModel.Draft])(SubscriptionCancellationTimeoutAlertRaw),
  true,
  true,
);
const SubscriptionCancellationTimeoutAlert = React.memo(
  SubscriptionCancellationTimeoutAlertLoaded,
) as typeof SubscriptionCancellationTimeoutAlertLoaded;

export default SubscriptionCancellationTimeoutAlert;
