import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { reportViewLink } from '@/pages/reports/routes';

import type { ReportViewLinkProps } from './types';

const ReportViewLinkRaw: React.FC<ReportViewLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={reportViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageReports.REPORT_VIEW_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const ReportViewLink = React.memo(ReportViewLinkRaw);

export default ReportViewLink;
