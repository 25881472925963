import { CheckboxItem } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { nameof } from '@/infrastructure/utils/ts';

import type { PoweredByVisibilityItemProps } from './types';
import type React from 'react';

const PoweredByVisibilityItem: React.FC<PoweredByVisibilityItemProps> = ({ 'data-test': dataTest, disabled }) => (
  <CheckboxItem<PaymentsBranding>
    data-test={dataTest}
    name={[
      nameof<PaymentsBranding, 'poweredBy'>('poweredBy'),
      nameof<NonNullable<PaymentsBranding['poweredBy']>>('visible'),
    ]}
    readonly={disabled}
    ItemProps={{ noStyle: true, label: null }}
  />
);

export default PoweredByVisibilityItem;
