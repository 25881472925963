import React from 'react';

import { CollectableEntityTransactionsTable } from '@/features/collectable/components';

import type { BaseRechargeTransactionFilter, RechargeTransactionsTableProps } from './types';

const RechargeTransactionsTable = <FilterType extends BaseRechargeTransactionFilter = BaseRechargeTransactionFilter>({
  RechargeTransactionLink,
  ...props
}: RechargeTransactionsTableProps<FilterType>) => (
  <CollectableEntityTransactionsTable<FilterType>
    {...props}
    CollectableEntityTransactionLink={RechargeTransactionLink}
  />
);

const RechargeTransactionsTableMemo = React.memo(RechargeTransactionsTable) as typeof RechargeTransactionsTable;

export default RechargeTransactionsTableMemo;
