import type { CommonLoadingState, LoadingStateWithDirty } from '@/infrastructure/model';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type { Draft } from 'immer';

export const loadingDataMarkDirtyReducer =
  <Global extends EmptyObject, Value, Exc = string>(
    get: (global: Draft<Global>) => LoadingStateWithDirty<Value, Exc>,
    set: (global: Draft<Global>, newState: LoadingStateWithDirty<Value, Exc>) => Draft<Global>,
  ) =>
  (state: Draft<Global>) => {
    const newState = get(state);
    return !newState.isDirty ? set(state, { ...newState, isDirty: true }) : state;
  };

export const loadingDataStoreDataReducer =
  <Global extends EmptyObject, Value, Exc = string>(
    _: (global: Draft<Global>) => LoadingStateWithDirty<Value, Exc>,
    set: (global: Draft<Global>, newState: LoadingStateWithDirty<Value, Exc>) => Draft<Global>,
  ) =>
  (state: Draft<Global>, { payload }: { payload: CommonLoadingState<Value, Exc> }) =>
    set(state, { ...payload, isDirty: false });

export const createLoadingDataReducers = <Global extends EmptyObject, Value, Type extends string, Exc = string>(
  type: Type,
  get: (global: Draft<Global>) => LoadingStateWithDirty<Value, Exc>,
  set: (global: Draft<Global>, newState: LoadingStateWithDirty<Value, Exc>) => Draft<Global>,
) => {
  const markDirtyName = `mark${type}DirtyReducer` as const;
  const storeDataName = `store${type}Reducer` as const;

  const markDirty = loadingDataMarkDirtyReducer(get, set);
  const storeData = loadingDataStoreDataReducer(get, set);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [storeDataName]: storeData,
  } as Record<typeof markDirtyName, typeof markDirty> & Record<typeof storeDataName, typeof storeData>;
};
