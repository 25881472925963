export enum ReCaptchaType {
  V2 = 'v2',
  V3 = 'v3',
}

export interface ReCaptchaParams {
  recaptchaToken: string;
  recaptchaType: ReCaptchaType;
}

export const NAMESPACE = 'recaptcha';

export interface ReCaptchaState {
  publicV2Key?: string;
  publicV3Key?: string;
}
