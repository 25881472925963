import { useEffect } from 'react';

import { useAppSelector } from '@/app/hooks';
import { PageLoading } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { useUser } from '@/features/user/hooks';
import { useSingleRun } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthToken = makeSelectAuthToken();

const withAuthorizationConsistencyGuard = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithAuthorizationConsistencyGuard',
  )((props) => {
    const { data: userState, loading: userLoading } = useUser();
    const { logout } = useAuthActions();
    const { withSingleRun, inProgress } = useSingleRun(true);

    const jwtState = useAppSelector(selectAuthToken);

    const isJWTActual = !jwtState.isDirty;
    const isUserActual = !userState.isDirty && !userLoading;

    useEffect(() => {
      if (isJWTActual && isUserActual && !isSameAddress(jwtState.data?.info.address, userState.data?.address)) {
        if (logout.available && !logout.inAction) {
          if (!inProgress) {
            withSuppressPromise(withSingleRun(logout.act))(true);
          }
        }
      }
    }, [
      inProgress,
      isJWTActual,
      isUserActual,
      jwtState.data?.info.address,
      logout.act,
      logout.available,
      logout.inAction,
      userState.data?.address,
      withSingleRun,
    ]);

    if (inProgress) {
      return (
        <MessageLayout>
          <PageLoading type="withAuthorizationConsistencyGuard" />
        </MessageLayout>
      );
    }
    return <Component {...props} />;
  });

export default withAuthorizationConsistencyGuard;
