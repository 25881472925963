import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nMenu } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';
import type { CustomizableRoute } from '@/layouts/types';
import PaymentsIcon from '@/pages/payments/components/PaymentsIcon';
import { paymentsLink, PaymentsPageRoutes, paymentsRoute } from '@/pages/payments/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (formatMessage: IntlFormattersArrow['formatMessage']): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.PAYMENTS }),
    icon: <PaymentsIcon />,
    path: paymentsLink(),
    hideChildrenInMenu: true,

    children: enumValues(PaymentsPageRoutes)
      .filter((route) => route !== PaymentsPageRoutes.ALL)
      .map((route) => paymentsRoute(route))
      .map((path) => ({ path })),
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const { formatMessage } = useIntl();
  return useMemo(() => menuCreator(formatMessage), [formatMessage]);
}
