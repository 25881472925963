import { Form } from 'antd';
import React, { useCallback, useMemo } from 'react';

import {
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import PayoutTitleItem from '@/features/payouts/components/EditPayoutForm/components/PayoutTitleItem';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { UpdatePayoutTitleFormProps, UpdatePayoutTitleFormValues } from './types';
import type { FormInstance } from 'antd/es/form';

const fields = [nameof<UpdatePayoutTitleFormValues>('title')];

const UpdatePayoutTitleForm: React.FC<UpdatePayoutTitleFormProps> = ({
  'data-test': dataTest,
  values,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const oldValue = values?.title;
  const { form, withResetForm } = useForm<UpdatePayoutTitleFormValues>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: UpdatePayoutTitleFormValues): unknown => onSubmit(val.title)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_DIALOG_ERROR_COMMON} />;
  }, [error]);

  return (
    <Form<UpdatePayoutTitleFormValues>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={values}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
      <PayoutTitleItem<UpdatePayoutTitleFormValues>
        data-test={dataTest && `${dataTest}-title`}
        name={nameof<UpdatePayoutTitleFormValues>('title')}
        required
      />
      <FormCompletenessItem<UpdatePayoutTitleFormValues>
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={useCallback(
          ({ getFieldValue }: FormInstance<UpdatePayoutTitleFormValues>) =>
            getFieldValue(nameof<UpdatePayoutTitleFormValues>('title')) !== oldValue,
          [oldValue],
        )}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-submit`}
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
      />
    </Form>
  );
};

const UpdatePayoutTitleFormMemo = React.memo(UpdatePayoutTitleForm);

export default UpdatePayoutTitleFormMemo;
