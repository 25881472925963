import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { CollectTasksTable } from '@/features/collectable/components';
import { useCollectTaskSummaryForDonationListView } from '@/features/donations/hooks';
import { I18nPageDonations } from '@/generated/i18n/i18n';

import type { DonationCollectTasksCardProps } from './types';

const DonationCollectTasksCard: React.FC<DonationCollectTasksCardProps> = (props) => {
  const parameters = useCollectTaskSummaryForDonationListView(props.donationId);
  return (
    <PageCard
      title={<FormattedMessage id={I18nPageDonations.DONATION_VIEW_COMPONENTS_COLLECT_TASKS_CARD_TITLE} />}
      cardSize="big"
    >
      <CollectTasksTable {...parameters} {...props} />
    </PageCard>
  );
};

const DonationCollectTasksCardMemo = React.memo(DonationCollectTasksCard);

export default DonationCollectTasksCardMemo;
