/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PagePushAddressSortByAPIModel } from './PagePushAddressSortByAPIModel';
import {
    PagePushAddressSortByAPIModelFromJSON,
    PagePushAddressSortByAPIModelFromJSONTyped,
    PagePushAddressSortByAPIModelToJSON,
} from './PagePushAddressSortByAPIModel';
import type { RechargeAddressPredicatesAPIModel } from './RechargeAddressPredicatesAPIModel';
import {
    RechargeAddressPredicatesAPIModelFromJSON,
    RechargeAddressPredicatesAPIModelFromJSONTyped,
    RechargeAddressPredicatesAPIModelToJSON,
} from './RechargeAddressPredicatesAPIModel';

/**
 * 
 * @export
 * @interface RechargeAddressFilterAPIModel
 */
export interface RechargeAddressFilterAPIModel {
    /**
     * 
     * @type {RechargeAddressPredicatesAPIModel}
     * @memberof RechargeAddressFilterAPIModel
     */
    predicates: RechargeAddressPredicatesAPIModel;
    /**
     * 
     * @type {PagePushAddressSortByAPIModel}
     * @memberof RechargeAddressFilterAPIModel
     */
    page: PagePushAddressSortByAPIModel;
}

/**
 * Check if a given object implements the RechargeAddressFilterAPIModel interface.
 */
export function instanceOfRechargeAddressFilterAPIModel(value: object): boolean {
    if (!('predicates' in value)) return false;
    if (!('page' in value)) return false;
    return true;
}

export function RechargeAddressFilterAPIModelFromJSON(json: any): RechargeAddressFilterAPIModel {
    return RechargeAddressFilterAPIModelFromJSONTyped(json, false);
}

export function RechargeAddressFilterAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RechargeAddressFilterAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'predicates': RechargeAddressPredicatesAPIModelFromJSON(json['predicates']),
        'page': PagePushAddressSortByAPIModelFromJSON(json['page']),
    };
}

export function RechargeAddressFilterAPIModelToJSON(value?: RechargeAddressFilterAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'predicates': RechargeAddressPredicatesAPIModelToJSON(value['predicates']),
        'page': PagePushAddressSortByAPIModelToJSON(value['page']),
    };
}

