import { useMemo } from 'react';

import { useCompanyActiveAssets } from '@/features/company-settings/hooks';
import { nameof } from '@/infrastructure/utils/ts';

import type { CreateRechargeAddressFormData } from '../types';

export default function useInitialValues(): Partial<CreateRechargeAddressFormData> | undefined {
  const { data: companyAssets, loading } = useCompanyActiveAssets();
  return useMemo(
    () =>
      !companyAssets.data && !companyAssets.isDirty && !loading
        ? undefined
        : {
            ...(companyAssets.data?.[0]
              ? { [nameof<CreateRechargeAddressFormData>('asset')]: companyAssets.data[0].code }
              : {}),
          },
    [companyAssets.data, companyAssets.isDirty, loading],
  );
}
