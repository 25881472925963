import { Space } from 'antd';
import BigNumber from 'bignumber.js';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { NoExchangeRatesAlert, OperationUpdateStatisticsAsset } from '@/features/company-settings/components';
import { SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import type { Asset } from '@/features/dictionary/blockchain/types';
import { OperationRefreshBalances } from '@/features/statistics/components';
import type { ActualBalancesData } from '@/features/statistics/components/ActualBalancesView/types';
import { useActualBalances } from '@/features/statistics/hooks';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { AssetAmountValue } from '@/infrastructure/api';
import { combine } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { notEmpty } from '@/infrastructure/utils/ts';

import type React from 'react';

const byAsset = (balances: AssetAmountValue[]): Partial<Record<string, AssetAmountValue>> =>
  Object.fromEntries(balances.map((value) => [value.asset, value]));

const withActualBalancesDataLoading =
  <Original extends { data: ActualBalancesData } & TestableProps>(
    Component: React.ComponentType<Original>,
  ): React.FC<Omit<Original, 'data'>> =>
  (props) => {
    const dataTest = props['data-test'];
    const assetsState = useListAssets();
    const balancesState = useActualBalances();

    const data = useMemo(
      () =>
        combine(assetsState.data, balancesState.data, (assets, balances) => {
          const availableByAsset = byAsset(balances.available);
          const collectableByAsset = byAsset(balances.collectable);
          const balancesByAsset = byAsset(balances.balances);
          const lockedByAsset = byAsset(balances.locked);

          const balanceAssets: string[] = uniq([
            ...Object.keys(availableByAsset),
            ...Object.keys(balancesByAsset),
            ...Object.keys(lockedByAsset),
            ...Object.keys(collectableByAsset),
          ]);
          const resolvedAssets: Asset[] = balanceAssets
            .map((balanceAsset) => {
              const assetInfo = assets.find((a) => a.code === balanceAsset);
              if (!assetInfo) {
                console.warn(`Cannot find asset for code = ${balanceAsset}`);
              }
              return assetInfo;
            })
            .filter(notEmpty);

          const dataSource = resolvedAssets
            .map((assetInfo) => {
              const emptyBalance = { value: BigNumber(0), asset: assetInfo.code };
              const balance = balancesByAsset[assetInfo.code] ?? emptyBalance;
              const available = availableByAsset[assetInfo.code] ?? emptyBalance;
              const collectable = collectableByAsset[assetInfo.code] ?? emptyBalance;
              const locked = lockedByAsset[assetInfo.code] ?? emptyBalance;
              return { assetInfo, balance, available, locked, collectable };
            })
            .filter(notEmpty);
          return {
            balances: dataSource,
            total: balances.total,
            totalAvailable: balances.totalAvailable,
            totalLocked: balances.totalLocked,
            totalCollectable: balances.totalCollectable,
          };
        }),
      [assetsState.data, balancesState.data],
    );
    return withCardDataLoading<ActualBalancesData, Original>({
      forceRefresh: balancesState.forceRefresh,
      loading: balancesState.loading,
      data,
      onError: (error, defaultComponent) =>
        error === 'NoExchangeRateFound' ? (
          <NoExchangeRatesAlert data-test={dataTest && `${dataTest}-alert`} onUpdate={balancesState.forceRefresh} />
        ) : (
          defaultComponent
        ),
      'data-test': dataTest,
      CardProps: {
        extra: (
          <Space>
            <Space>
              <OperationUpdateStatisticsAsset data-test={dataTest && `${dataTest}-assetUpdateOp`} mode="inline" />
              <OperationRefreshBalances data-test={dataTest && `${dataTest}-refreshOp`} />
            </Space>
          </Space>
        ),
      },
      title: (
        <Space>
          <FormattedMessage id={I18nPageBalances.ACTUAL_COMPONENTS_BALANCES_CARD_TITLE} />
          <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-selectNetwork`} />
        </Space>
      ),
    })(Component)({ ...props });
  };

export default withActualBalancesDataLoading;
