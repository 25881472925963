/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TempUploadUrlRequestAPIModel
 */
export interface TempUploadUrlRequestAPIModel {
    /**
     * 
     * @type {string}
     * @memberof TempUploadUrlRequestAPIModel
     */
    contentType?: string;
}

/**
 * Check if a given object implements the TempUploadUrlRequestAPIModel interface.
 */
export function instanceOfTempUploadUrlRequestAPIModel(value: object): boolean {
    return true;
}

export function TempUploadUrlRequestAPIModelFromJSON(json: any): TempUploadUrlRequestAPIModel {
    return TempUploadUrlRequestAPIModelFromJSONTyped(json, false);
}

export function TempUploadUrlRequestAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TempUploadUrlRequestAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'contentType': json['contentType'] == null ? undefined : json['contentType'],
    };
}

export function TempUploadUrlRequestAPIModelToJSON(value?: TempUploadUrlRequestAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'contentType': value['contentType'],
    };
}

