import { UnlockTwoTone } from '@ant-design/icons';
import React, { useCallback, useContext } from 'react';

import { FormattedMessage, Operation } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { useAuthActions } from '@/features/auth/hooks';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useFormVisible } from '@/hooks';

import { DisconnectWeb3AuthModal } from './components';

import type { OperationDisconnectWeb3AuthWalletProps } from './types';

const OperationDisconnectWeb3AuthWallet: React.FC<OperationDisconnectWeb3AuthWalletProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
}) => {
  const formControl = useFormVisible(false);
  const { disconnectWeb3Auth } = useAuthActions();
  const { web3Auth } = useContext(Web3AuthContext);

  const doHide = useCallback(() => {
    formControl.hide();
  }, [formControl]);

  const doShow = useCallback(() => {
    formControl.show();
  }, [formControl]);

  return (
    <>
      {formControl.visible && web3Auth?.state && (
        <DisconnectWeb3AuthModal
          data-test={dataTest && `${dataTest}-dialog`}
          state={web3Auth.state}
          onSuccess={doHide}
          onCancel={doHide}
        />
      )}
      <Operation
        title={title ?? <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_TITLE} />}
        tooltip={<FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_TOOLTIP} />}
        disabled={!disconnectWeb3Auth.available || !web3Auth?.state}
        icon={<UnlockTwoTone />}
        inProgress={disconnectWeb3Auth.inAction}
        onClick={doShow}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationDisconnectWeb3AuthWalletMemo = React.memo(OperationDisconnectWeb3AuthWallet);

export default OperationDisconnectWeb3AuthWalletMemo;
