import { List } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage } from '@/components';
import type { CollectTaskAddress } from '@/features/collectable/types';
import { AddressLink, AssetAmount, AssetIcon } from '@/features/dictionary/blockchain/components';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectTaskAddressesListProps } from './types';

const CollectTaskAddressesList: React.FC<CollectTaskAddressesListProps> = ({
  'data-test': dataTest,
  data,
  style,
  className,
}) => (
  <List
    itemLayout="horizontal"
    loadMore={false}
    dataSource={data}
    style={style}
    className={className}
    renderItem={useCallback(
      (item: CollectTaskAddress) => (
        <List.Item key={item.id}>
          <List.Item.Meta
            style={{ alignItems: 'center' }}
            avatar={
              <AssetIcon
                data-test={dataTest && `${dataTest}-${item.asset}-icon`}
                type={item.asset}
                style={{ fontSize: 32 }}
              />
            }
            title={
              <AddressLink
                data-test={dataTest && `${dataTest}-${item.asset}-address`}
                address={item.address}
                assetId={item.asset}
              />
            }
            description={
              item.collectedAmount ? (
                <FormattedMessage
                  id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_ADDRESSES_LIST_DESCRIPTION_COLLECTED}
                  tagName="span"
                  values={{
                    amount: (
                      <AssetAmount
                        data-test={dataTest && `${dataTest}-${item.asset}-address`}
                        value={item.collectedAmount.value}
                        assetId={item.asset}
                        withCurrency
                        withBlockchainMark
                      />
                    ),
                  }}
                />
              ) : (
                <FormattedMessage
                  id={I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_ADDRESSES_LIST_DESCRIPTION_NOT_COLLECTED}
                  tagName="span"
                />
              )
            }
          />
        </List.Item>
      ),
      [dataTest],
    )}
  />
);

const CollectTaskAddressesListMemo = React.memo(CollectTaskAddressesList);

export default CollectTaskAddressesListMemo;
