import { useMemo } from 'react';
import { parseSignature } from 'viem';
import { privateKeyToAccount } from 'viem/accounts';

import { PageLoading } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { useReCaptchaAction } from '@/features/recaptcha/hooks';
import { useSingleRun } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import MessageLayout from '@/layouts/MessageLayout';

import type { Web3AuthJWTBySignatureProps } from './types';
import type React from 'react';

const Web3AuthJWTBySignature: React.FC<Web3AuthJWTBySignatureProps> = ({ web3Auth, withLogIn }) => {
  const { withRecaptcha, initialized } = useReCaptchaAction('auth');

  const { login, logout } = useAuthActions();
  const { withSingleRun, inProgress } = useSingleRun();

  const doLogin = useMemo(() => {
    const address = web3Auth.state?.address;
    const privateKey = web3Auth.state?.privateKey;
    return address && privateKey
      ? withSuppressPromise(
          withSingleRun(
            withLogIn(async (onBeforeSignUp) =>
              login.act(
                {
                  address,
                  signMessage: async (message) => {
                    const account = privateKeyToAccount(privateKey);
                    const rawSignature = await account.signMessage({ message });
                    const signature = parseSignature(rawSignature);
                    return { ...signature, v: Number(signature.v) };
                  },
                  withRecaptcha,
                },
                { onBeforeSignUp },
              ),
            ),
          ),
          (e) => {
            console.error(e);
            withSuppressPromise(logout.act)(true);
          },
        )
      : undefined;
  }, [web3Auth.state?.address, web3Auth.state?.privateKey, withSingleRun, withLogIn, withRecaptcha, login, logout.act]);

  if (initialized && doLogin && !inProgress) {
    doLogin();
  }

  return (
    <MessageLayout>
      <PageLoading type="Web3AuthJWTBySignature" />
    </MessageLayout>
  );
};

export default Web3AuthJWTBySignature;
