import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import DonationTransactionsBreadcrumb from '@/pages/donations/transactions/components/DonationTransactionsBreadcrumb';

import DonationTransactionLink from '../DonationTransactionLink';

import type { DonationTransactionBreadcrumbProps } from './types';

const DonationTransactionBreadcrumb: React.FC<DonationTransactionBreadcrumbProps> = ({
  'data-test': dataTest,
  transactionId,
  disabled,
  items = [],
}) => (
  <DonationTransactionsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage
            id={I18nPageDonations.TRANSACTION_VIEW_COMPONENTS_BREADCRUMB}
            values={{ id: transactionId }}
          />
        ) : (
          <DonationTransactionLink
            data-test={dataTest && `${dataTest}-link`}
            mode="text"
            value={transactionId}
            title={
              <FormattedMessage
                id={I18nPageDonations.TRANSACTION_VIEW_COMPONENTS_BREADCRUMB}
                values={{ id: transactionId }}
              />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const DonationTransactionBreadcrumbMemo = React.memo(DonationTransactionBreadcrumb);

export default DonationTransactionBreadcrumbMemo;
