import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import { rechargeAddressesLink } from '@/pages/recharges/routes';

import type { RechargeAddressesLinkProps } from './types';

const RechargeAddressesLink: React.FC<RechargeAddressesLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={rechargeAddressesLink()}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageRecharges.ADDRESSES_COMPONENTS_LINK_TITLE} />}
  />
);

const RechargeAddressesLinkMemo = React.memo(RechargeAddressesLink);

export default RechargeAddressesLinkMemo;
