import type { AppRootState } from '@/app/store';

import { NAMESPACE } from './types';

export const makeSelectSubscriptionChurnRate = () => (state: AppRootState) => state[NAMESPACE].churnRate;

export const makeSelectSubscriptionMonthlyGrowthRate = () => (state: AppRootState) => state[NAMESPACE].growthRate;

export const makeSelectSubscriptionStatisticsAsset = () => (state: AppRootState) => state[NAMESPACE].asset;

export const makeSelectSubscriptionMonthlyRevenue = () => (state: AppRootState) => state[NAMESPACE].revenue;
