import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import PaymentsPage from './all';
import PaymentAddPage from './payment-add';
import PaymentTransactionViewPage from './payment-transaction-view';
import PaymentViewPage from './payment-view';
import { PaymentsPageRoutes, paymentsLink } from './routes';

import type React from 'react';

export const PaymentsDefaultRoute: React.FC = () => <Navigate to={paymentsLink()} replace />;

const PaymentPagesRaw: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={PaymentsPageRoutes.ALL} Component={PaymentsPage} />
        <Route path={PaymentsPageRoutes.PAYMENT_NEW} Component={PaymentAddPage} />
        <Route path={PaymentsPageRoutes.PAYMENT_VIEW} Component={PaymentViewPage} />
        <Route path={PaymentsPageRoutes.PAYMENT_TRANSACTION_VIEW} Component={PaymentTransactionViewPage} />
        <Route path="*" Component={PaymentsDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const PaymentPages: React.FC = withBasicPageGuard(PaymentPagesRaw);

export default <Route key={AppPageRoutes.PAYMENTS} path={`${AppPageRoutes.PAYMENTS}/*`} Component={PaymentPages} />;
