/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { AuthenticatedUserAPIModel } from './AuthenticatedUserAPIModel';
import {
    AuthenticatedUserAPIModelFromJSON,
    AuthenticatedUserAPIModelFromJSONTyped,
    AuthenticatedUserAPIModelToJSON,
} from './AuthenticatedUserAPIModel';

/**
 * 
 * @export
 * @interface TokenWithUserAPIModel
 */
export interface TokenWithUserAPIModel {
    /**
     * 
     * @type {string}
     * @memberof TokenWithUserAPIModel
     */
    token: string;
    /**
     * 
     * @type {AuthenticatedUserAPIModel}
     * @memberof TokenWithUserAPIModel
     */
    user: AuthenticatedUserAPIModel;
}

/**
 * Check if a given object implements the TokenWithUserAPIModel interface.
 */
export function instanceOfTokenWithUserAPIModel(value: object): boolean {
    if (!('token' in value)) return false;
    if (!('user' in value)) return false;
    return true;
}

export function TokenWithUserAPIModelFromJSON(json: any): TokenWithUserAPIModel {
    return TokenWithUserAPIModelFromJSONTyped(json, false);
}

export function TokenWithUserAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenWithUserAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'user': AuthenticatedUserAPIModelFromJSON(json['user']),
    };
}

export function TokenWithUserAPIModelToJSON(value?: TokenWithUserAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'user': AuthenticatedUserAPIModelToJSON(value['user']),
    };
}

