import { MailOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { I18nComponents } from '@/generated/i18n/i18n';
import { asType } from '@/infrastructure/utils/ts';

import FormattedMessage from '../FormattedMessage';

import type { EmailItemProps } from './types';
import type { RuleType, Store } from 'rc-field-form/es/interface';

const normalize = (value?: string) => value?.trim();

const iconStyle: React.CSSProperties = { color: 'blue' };

const EmailItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  prefix,
  placeholder,
  required,
  messages,
  readonly,
  rules: extraRules = [],
  InputProps = {},
  ItemProps = {},
}: EmailItemProps<Values>) => {
  const rules = useMemo(
    () => [
      ...extraRules,
      ...(!readonly
        ? [
            {
              type: asType<RuleType>('email'),
              message: messages?.wrongFormat ?? <FormattedMessage id={I18nComponents.EMAIL_WRONG_FORMAT} />,
            },
            ...(required
              ? [
                  {
                    required: true,
                    message: messages?.required ?? <FormattedMessage id={I18nComponents.EMAIL_REQUIRED} />,
                  },
                ]
              : []),
          ]
        : []),
    ],
    [extraRules, messages?.required, messages?.wrongFormat, readonly, required],
  );
  const { formatMessage } = useIntl();
  return (
    <Form.Item<Values>
      label={messages?.label || <FormattedMessage id={I18nComponents.EMAIL_LABEL} />}
      rules={rules}
      required={!readonly && required}
      hasFeedback={!readonly}
      {...ItemProps}
      name={name}
      normalize={normalize}
    >
      <Input
        prefix={prefix ?? <MailOutlined style={iconStyle} color="blue" />}
        type="email"
        className="ym-hide-content"
        disabled={readonly}
        placeholder={placeholder || formatMessage({ id: I18nComponents.EMAIL_PLACEHOLDER })}
        data-test={dataTest}
        {...InputProps}
      />
    </Form.Item>
  );
};

const EmailItem = React.memo(EmailItemRaw) as typeof EmailItemRaw;

export default EmailItem;
