import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import type { PaymentTransactionSearchIdAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import PaymentTransactionsBreadcrumb from '@/pages/payments/payment-transactions/components/PaymentTransactionsBreadcrumb';

import PaymentTransactionLink from '../PaymentTransactionLink';

import type { PaymentTransactionBreadcrumbProps } from './types';

const PaymentTransactionBreadcrumb: React.FC<PaymentTransactionBreadcrumbProps> = ({
  'data-test': dataTest,
  paymentId,
  hash,
  disabled,
  items = [],
}) => {
  const id = useMemo<PaymentTransactionSearchIdAPIModel>(() => ({ paymentId, hash }), [hash, paymentId]);
  return (
    <PaymentTransactionsBreadcrumb
      data-test={dataTest}
      paymentId={paymentId}
      items={[
        {
          title: disabled ? (
            <FormattedMessage id={I18nPagePayments.TRANSACTION_VIEW_COMPONENTS_BREADCRUMB} values={{ id: hash }} />
          ) : (
            <PaymentTransactionLink
              data-test={dataTest && `${dataTest}-link`}
              mode="text"
              value={id}
              title={
                <FormattedMessage id={I18nPagePayments.TRANSACTION_VIEW_COMPONENTS_BREADCRUMB} values={{ id: hash }} />
              }
            />
          ),
        },
        ...items,
      ]}
    />
  );
};
const PaymentTransactionBreadcrumbMemo = React.memo(PaymentTransactionBreadcrumb);

export default PaymentTransactionBreadcrumbMemo;
