import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import BrandingSummaryBreadcrumb from '@/pages/settings/branding/summary/components/BrandingSummaryBreadcrumb';

import ToSLink from '../ToSLink';

import type { ToSBreadcrumbProps } from './types';

const ToSBreadcrumb: React.FC<ToSBreadcrumbProps> = ({ items = [], disabled, 'data-test': dataTest }) => (
  <BrandingSummaryBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.BRANDING_TOS_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <ToSLink
            mode="link"
            title={<FormattedMessage id={I18nPageSettings.BRANDING_TOS_COMPONENTS_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const ToSBreadcrumbMemo = React.memo(ToSBreadcrumb);

export default ToSBreadcrumbMemo;
