/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { UserCompanyInfoAPIModel } from './UserCompanyInfoAPIModel';
import {
    UserCompanyInfoAPIModelFromJSON,
    UserCompanyInfoAPIModelFromJSONTyped,
    UserCompanyInfoAPIModelToJSON,
} from './UserCompanyInfoAPIModel';

/**
 * 
 * @export
 * @interface UserCompaniesAPIModel
 */
export interface UserCompaniesAPIModel {
    /**
     * 
     * @type {Array<UserCompanyInfoAPIModel>}
     * @memberof UserCompaniesAPIModel
     */
    companies?: Array<UserCompanyInfoAPIModel>;
}

/**
 * Check if a given object implements the UserCompaniesAPIModel interface.
 */
export function instanceOfUserCompaniesAPIModel(value: object): boolean {
    return true;
}

export function UserCompaniesAPIModelFromJSON(json: any): UserCompaniesAPIModel {
    return UserCompaniesAPIModelFromJSONTyped(json, false);
}

export function UserCompaniesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCompaniesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'companies': json['companies'] == null ? undefined : ((json['companies'] as Array<any>).map(UserCompanyInfoAPIModelFromJSON)),
    };
}

export function UserCompaniesAPIModelToJSON(value?: UserCompaniesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'companies': value['companies'] == null ? undefined : ((value['companies'] as Array<any>).map(UserCompanyInfoAPIModelToJSON)),
    };
}

