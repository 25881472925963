import { useAppLoadableData } from '@/app/hooks';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchMerchantWallets } from '@/features/merchant-wallets/actions';
import { makeSelectMerchantWallets } from '@/features/merchant-wallets/selectors';
import { useUser } from '@/features/user/hooks';

const dataSelector = makeSelectMerchantWallets();
const dataFetchingSelector = makeSelectPending(fetchMerchantWallets);
const fetchFactory = (force: boolean) => fetchMerchantWallets({ force });

export default function useMerchantWallets() {
  const {
    data: { data: user },
  } = useUser();
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector, !!user);
}
