import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchReports } from '@/features/reports/actions';
import { makeSelectReportListData, makeSelectReportListParameters } from '@/features/reports/selectors';
import type { Report } from '@/features/reports/types';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<Report>, Parameters<typeof fetchReports>[0]> => fetchReports({ force });
const pendingSelector = makeSelectPending(fetchReports);
const settlementListParametersSelector = makeSelectReportListParameters();
const settlementListDataSelector = makeSelectReportListData();

export default function useReportList() {
  return useAppListData(fetchFactory, settlementListParametersSelector, pendingSelector, settlementListDataSelector);
}
