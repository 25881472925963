import React from 'react';

import { I18nComponents } from '@/generated/i18n/i18n';

import FormattedMessage from '../FormattedMessage';
import SupportEmail from '../SupportEmail';

import type { MessageToSupportProps } from './types';

const MessageToSupport: React.FC<MessageToSupportProps> = ({ 'data-test': dataTest }) => (
  <FormattedMessage
    id={I18nComponents.MESSAGE_TO_SUPPORT_MESSAGE}
    values={{ email: <SupportEmail data-test={dataTest && `${dataTest}-support`} /> }}
    tagName="span"
  />
);

const MessageToSupportMemo = React.memo(MessageToSupport);

export default MessageToSupportMemo;
