import { PageContainer } from '@/components';
import { KYBRequiredBanner } from '@/features/company/components';
import {
  OperationExportPayments,
  PaymentsDocumentationBanner,
  PaymentsFilterForm,
} from '@/features/payments/components';
import PaymentLink from '@/pages/payments/payment-view/components/PaymentLink';

import { PaymentsBreadcrumb, PaymentsCard } from './components';

import type React from 'react';

const dataTest: string | undefined = 'payments';

const PaymentsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<PaymentsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <KYBRequiredBanner data-test="kyb-banner" PageProps={{ pageSize: 'big' }} />
    <PaymentsDocumentationBanner data-test="payments-help" pageSize="big" />
    <PaymentsCard
      data-test="payments"
      PaymentLink={PaymentLink}
      ExportOperation={OperationExportPayments}
      FilterForm={PaymentsFilterForm}
    />
  </PageContainer>
);

export default PaymentsPage;
