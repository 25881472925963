import { Form } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

import { WrappedUpload } from './components';

import type { S3TempFileUploadItemProps } from './types';
import type { UploadFile } from 'antd/es/upload/interface';
import type { Store } from 'rc-field-form/es/interface';
import type { Rule } from 'rc-field-form/lib/interface';

const S3TempFileUploadItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  messages,
  restrictions,
  rules: extraValidators = [],
  ItemProps = {},
}: S3TempFileUploadItemProps<Values>) => {
  const rules: Rule[] = useMemo(
    () =>
      !readonly
        ? [
            {
              // eslint-disable-next-line @typescript-eslint/require-await
              validator: async (_, value) => {
                if (value && (value as UploadFile).status === 'error') {
                  throw new Error();
                }
              },
              message: <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_FILE_UPLOAD_ITEM_ERRORS_UPLOAD_COMMON} />,
            },
            ...extraValidators,
            ...(required
              ? [
                  {
                    required: true,
                    message: messages?.required ?? (
                      <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_FILE_UPLOAD_ITEM_REQUIRED} />
                    ),
                  },
                ]
              : []),
          ]
        : [],
    [extraValidators, messages?.required, readonly, required],
  );
  return (
    <Form.Item
      label={messages?.label ?? <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_FILE_UPLOAD_ITEM_LABEL} />}
      rules={rules}
      required={!readonly && required}
      hasFeedback={!readonly}
      {...ItemProps}
      name={name}
    >
      <WrappedUpload data-test={dataTest} restrictions={restrictions} />
    </Form.Item>
  );
};

const S3TempFileUploadItemMemo = React.memo(S3TempFileUploadItem) as typeof S3TempFileUploadItem;

export default S3TempFileUploadItemMemo;
