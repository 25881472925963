import BigNumber from 'bignumber.js';
import isNil from 'lodash/isNil';
import React, { useCallback, useMemo } from 'react';

import { FilterForm } from '@/components';
import { AssetAmountRangeItem } from '@/features/dictionary/blockchain/components';
import type { PayoutDestinationFilter } from '@/features/payouts/types';
import { nameof } from '@/infrastructure/utils/ts';

import { PayoutAddressFilterItem } from './components';

import type { PayoutDestinationFilterFormProps, PayoutDestinationFilterFormValues } from './types';

const fields = [nameof<PayoutDestinationFilter>('addressLike'), nameof<PayoutDestinationFilter>('amountRange')];

const PayoutDestinationFilterForm: React.FC<PayoutDestinationFilterFormProps> = ({
  value,
  asset,
  onSubmit,
  ...props
}) => {
  const { 'data-test': dataTest, readonly } = props;
  const doSubmit = useCallback(
    ({ amountRange, ...values }: PayoutDestinationFilterFormValues) =>
      onSubmit?.({
        ...values,
        amountRange: amountRange && {
          gte: amountRange.gte?.valid ? amountRange.gte.value?.toNumber() : undefined,
          lte: amountRange.lte?.valid ? amountRange.lte.value?.toNumber() : undefined,
        },
      }),
    [onSubmit],
  );
  const initialValues = useMemo<PayoutDestinationFilterFormValues>(
    () => ({
      ...value,
      amountRange: {
        gte: !isNil(value?.amountRange?.gte)
          ? {
              value: BigNumber(value.amountRange.gte),
              inputValue: `${value.amountRange.gte}`,
              valid: true,
            }
          : { value: undefined, inputValue: '', valid: true },
        lte: !isNil(value?.amountRange?.lte)
          ? {
              value: BigNumber(value.amountRange.lte),
              inputValue: `${value.amountRange.lte}`,
              valid: true,
            }
          : { value: undefined, inputValue: '', valid: true },
      },
    }),
    [value],
  );
  return (
    <FilterForm<PayoutDestinationFilterFormValues> fields={fields} {...props} onSubmit={doSubmit} value={initialValues}>
      {asset && (
        <AssetAmountRangeItem
          data-test={dataTest && `${dataTest}-amount`}
          name={nameof<PayoutDestinationFilter>('amountRange')}
          assetId={asset}
          readonly={readonly}
          required={false}
          nonNegative
        />
      )}
      <PayoutAddressFilterItem<PayoutDestinationFilter>
        data-test={dataTest && `${dataTest}-address`}
        name={nameof<PayoutDestinationFilter>('addressLike')}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const PayoutDestinationFilterFormMemo = React.memo(PayoutDestinationFilterForm);

export default PayoutDestinationFilterFormMemo;
