import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import DonationViewBreadcrumb from '@/pages/donations/donation-view/components/DonationViewBreadcrumb';

import type { DonationAssetsBreadcrumbProps } from './types';

const DonationAssetsBreadcrumb: React.FC<DonationAssetsBreadcrumbProps> = ({
  'data-test': dataTest,
  donationId,
  items = [],
}) => (
  <DonationViewBreadcrumb
    data-test={dataTest}
    donationId={donationId}
    items={[{ title: <FormattedMessage id={I18nPageDonations.DONATION_ASSETS_COMPONENTS_BREADCRUMB} /> }, ...items]}
  />
);

const DonationAddressViewBreadcrumbMemo = React.memo(DonationAssetsBreadcrumb);

export default DonationAddressViewBreadcrumbMemo;
