import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup } from '@/components';
import { useBlockchainSystemInfo } from '@/features/dictionary/blockchain/hooks';
import useSubscriptionWeb3Actions from '@/features/subscriptions/hooks/useSubscriptionWeb3Actions';
import { WalletOperation } from '@/features/web3/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { mapStoredState } from '@/infrastructure/model';
import { emptyWith, withOnSuccess } from '@/infrastructure/utils/functions';
import { isRejected } from '@/infrastructure/utils/ui';
import SubscriptionLink from '@/pages/subscriptions/subscription-view/components/SubscriptionLink';

import type { OperationCancelSubscriptionWithConnectorProps } from './types';
import type { ReactNode } from 'react';

const OperationCancelSubscriptionWithConnector: React.FC<OperationCancelSubscriptionWithConnectorProps> = ({
  'data-test': dataTest,
  subscription,
  account,
  onSuccess,
  withInProgress = emptyWith,
}) => {
  const { cancel: cancelAction } = useSubscriptionWeb3Actions(subscription.id);
  const blockchainState = useBlockchainSystemInfo(subscription.blockchain);
  const blockchain = useMemo(
    () =>
      mapStoredState(blockchainState.data, (state) =>
        state.apiType === BlockchainAPITypeAPIModel.WEB3 ? state : null,
      ),
    [blockchainState.data],
  );
  const { connector } = useWeb3Connector();
  const { withNotification } = useNotification();
  const options = useMemo(
    () => ({
      error: (error?: unknown) =>
        !isRejected(error)
          ? {
              message: (
                <FormattedMessage
                  id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_FAILED_MESSAGE}
                />
              ),
              description: (
                <FormattedMessageWithMarkup
                  id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_FAILED_DESCRIPTION}
                  values={{ account }}
                />
              ),
            }
          : undefined,
      success: () => ({
        message: (
          <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_SUCCESS_MESSAGE} />
        ),
        description: (
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_SUCCESS_DESCRIPTION}
            values={{ ln: (text: ReactNode) => <SubscriptionLink value={subscription.id} title={text} mode="text" /> }}
          />
        ),
      }),
    }),
    [account, subscription.id],
  );
  const cancel = useMemo(
    () => withNotification(withOnSuccess(withInProgress(cancelAction.act), onSuccess), options),
    [withInProgress, withNotification, cancelAction.act, onSuccess, options],
  );
  const disabledMessage = useMemo(() => {
    if (cancelAction.unavailabilityReason === 'invalid-status') {
      return <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_INVALID_STATUS} />;
    }
    if (cancelAction.unavailabilityReason === 'invalid-chain-id') {
      return (
        <FormattedMessage
          id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_INVALID_CHAIN_ID}
          values={{ network: subscription.blockchain, chainId: blockchain.data?.chainId }}
        />
      );
    }
    return undefined;
  }, [blockchain.data?.chainId, cancelAction.unavailabilityReason, subscription.blockchain]);

  return (
    <WalletOperation
      data-test={dataTest}
      connectorId={connector.id}
      disabled={!cancelAction.available}
      disabledMessage={disabledMessage}
      requiredChain={useMemo(
        () =>
          blockchain.data?.chainId ? { id: blockchain.data.chainId, network: subscription.blockchain } : undefined,
        [blockchain.data?.chainId, subscription.blockchain],
      )}
      requiredAccount={account}
      inProgress={cancelAction.inAction}
      mainAction={{
        onAction: cancel,
        title: (
          <FormattedMessage
            id={I18nFeatureSubscriptions.COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_CONNECTOR_TITLE}
            values={{ connector: connector.name }}
          />
        ),
      }}
      showAccount
    />
  );
};

const OperationCancelSubscriptionWithConnectorHOCed = withWeb3Connector(OperationCancelSubscriptionWithConnector);

const OperationCancelSubscriptionWithConnectorMemo = React.memo(
  OperationCancelSubscriptionWithConnectorHOCed,
) as typeof OperationCancelSubscriptionWithConnectorHOCed;

export default OperationCancelSubscriptionWithConnectorMemo;
