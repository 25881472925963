import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { recalculateSettlementReconciliation } from '@/features/settlement-reconciliations/actions';
import useSettlementReconciliation from '@/features/settlement-reconciliations/hooks/useSettlementReconciliation';
import type { SettlementReconciliation } from '@/features/settlement-reconciliations/types';
import { WithdrawalReconciliationStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';
import { noop } from '@/infrastructure/utils/functions';

type RecalculateUnavailabilityReason = 'invalid-status' | 'no-data' | 'too-late';

export interface UseSettlementReconciliationActions {
  recalculate: HookAction<[], SettlementReconciliation, RecalculateUnavailabilityReason>;
}

const toNextAttempt = (nextAttempt?: Date) => !!nextAttempt && dayjs(nextAttempt).diff(dayjs().add(15, 'seconds'));

export default function useSettlementReconciliationActions(settlementId: string): UseSettlementReconciliationActions {
  const { data } = useSettlementReconciliation(settlementId);
  const { withExtractDataDispatch } = useAppDispatch();

  const [isNextAttemptUpdatable, setIsNextAttemptUpdatable] = useState(() => {
    const nextAttempt = toNextAttempt(data.data?.nextAttempt);
    return !nextAttempt || nextAttempt < 0;
  });
  useEffect(() => {
    const nextAttempt = toNextAttempt(data.data?.nextAttempt);
    if (!nextAttempt || nextAttempt < 0) {
      setIsNextAttemptUpdatable(true);
      if (nextAttempt) {
        const timeout = setTimeout(() => setIsNextAttemptUpdatable(false), nextAttempt);
        return () => clearTimeout(timeout);
      }
      return noop;
    }
    setIsNextAttemptUpdatable(false);
    return noop;
  }, [data.data?.nextAttempt]);

  const recalculateUnavailabilityReason = useMemo<RecalculateUnavailabilityReason | undefined>(() => {
    if (!data.data) {
      return 'no-data';
    }
    if (![WithdrawalReconciliationStatusAPIModel.Failed].includes(data.data.status)) {
      return 'invalid-status';
    }
    if (!isNextAttemptUpdatable) {
      return 'too-late';
    }
    return undefined;
  }, [data.data, isNextAttemptUpdatable]);
  const isRecalculating = useActionPending(recalculateSettlementReconciliation, settlementId);
  const recalculate = {
    act: useCallback(
      () => withExtractDataDispatch(recalculateSettlementReconciliation)({ id: settlementId }),
      [withExtractDataDispatch, settlementId],
    ),
    available: !recalculateUnavailabilityReason,
    unavailabilityReason: recalculateUnavailabilityReason,
    inAction: isRecalculating,
  };
  return { recalculate };
}
