import { PageContainer } from '@/components';
import { SubscriptionsDocumentationBanner } from '@/features/subscriptions/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { SubscriptionCard, SubscriptionChargeBreadcrumb, SubscriptionChargeCard } from './components';
import { withSubscriptionChargeViewPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type SubscriptionChargePageProps = PropsWithChildren<TestableProps & { pageParams: { chargeId: string } }>;

const SubscriptionChargePage: React.FC<SubscriptionChargePageProps> = ({
  'data-test': dataTest = 'charge',
  pageParams: { chargeId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <SubscriptionChargeBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        subscriptionChargeId={chargeId}
        disabled
      />
    }
  >
    <SubscriptionsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <SubscriptionChargeCard data-test={dataTest && `${dataTest}-card`} subscriptionChargeId={chargeId} />
    <SubscriptionCard data-test={dataTest && `${dataTest}-subscription`} subscriptionChargeId={chargeId} />
  </PageContainer>
);

export default withSubscriptionChargeViewPageParams(SubscriptionChargePage);
