import { useContext, useMemo } from 'react';

import { useAppSelector } from '@/app/hooks';
import { PageLoading } from '@/components';
import Web3AuthContext from '@/features/auth/hocs/withWeb3AuthContext/context';
import { makeSelectAuthStatus, makeSelectAuthToken } from '@/features/auth/selectors';
import { AuthStatus } from '@/features/auth/types';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import { isSameAddress } from '@/infrastructure/utils/web3/address';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const selectAuthToken = makeSelectAuthToken();
const selectAuthStatus = makeSelectAuthStatus();

const withWeb3AuthConfirmation = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> => {
  return namedHOC(
    Component,
    'WithWeb3AuthConfirmation',
  )((props) => {
    const webAuth = useContext(Web3AuthContext);
    const authState = useAppSelector(selectAuthStatus);
    const isWeb3AuthInitialized = webAuth.initialized && webAuth.web3Auth?.isInitialized;
    const jwtState = useAppSelector(selectAuthToken);
    const isJWTActual = authState === AuthStatus.AUTHORIZED && !!jwtState.data && !jwtState.isDirty;
    const isAddressConsistent = useMemo(
      () => isSameAddress(jwtState.data?.info.address, webAuth.web3Auth?.state?.address),
      [jwtState.data?.info.address, webAuth.web3Auth?.state?.address],
    );

    if (isWeb3AuthInitialized && isJWTActual && isAddressConsistent && !webAuth.web3Auth?.isConfirmed) {
      webAuth.web3Auth?.markConfirmed();
      return (
        <MessageLayout>
          <PageLoading type="withWeb3AuthConfirmation" />
        </MessageLayout>
      );
    }
    return <Component {...props} />;
  });
};

export default withWeb3AuthConfirmation;
