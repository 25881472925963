import { LockOutlined } from '@ant-design/icons';
import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsPermissionsLink } from '@/pages/settings/routes';

import type { PermissionsLinkProps } from './types';

const PermissionsLink: React.FC<PermissionsLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settingsPermissionsLink()}
    icon={icon ?? <LockOutlined />}
    title={title ?? <FormattedMessage id={I18nPageSettings.PERMISSIONS_LINK} />}
  />
);

const PermissionsLinkMemo = React.memo(PermissionsLink);

export default PermissionsLinkMemo;
