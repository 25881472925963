import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useBrandingProfileActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationDeleteBrandingProfileProps } from './types';

const OperationDeleteBrandingProfile: React.FC<OperationDeleteBrandingProfileProps> = ({
  'data-test': dataTest,
  profileId,
  mode = 'inline',
  confirmation,
  disabled,
  onSuccess = noop,
}) => {
  const { data: profileData, remove: removeAction } = useBrandingProfileActions(profileId);
  const profile = profileData?.data;

  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const doRemoveProfile = useMemo(
    () =>
      withSuccess(withDefaultError(withOnSuccess(removeAction.act, onSuccess)), () => (
        <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_REMOVE_PROFILE_SUCCESS_MESSAGE} />
      )),
    [withSuccess, withDefaultError, removeAction.act, onSuccess],
  );

  const disabledMessage = useMemo(() => {
    if (removeAction.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_REMOVE_PROFILE_ERROR_NO_DATA} />;
    }
    return undefined;
  }, [removeAction.unavailabilityReason]);

  const confirmationProps = useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      profile?.active
        ? {
            title: (
              <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_REMOVE_PROFILE_CONFIRMATION_ACTIVE} />
            ),
          }
        : confirmation
          ? {
              title: (
                <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_REMOVE_PROFILE_CONFIRMATION_INACTIVE} />
              ),
            }
          : undefined,
    [confirmation, profile?.active],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_REMOVE_PROFILE_TITLE} />}
      tooltip={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_REMOVE_PROFILE_TOOLTIP} />}
      icon={<DeleteOutlined />}
      onClick={doRemoveProfile}
      disabled={disabled || !!disabledMessage || !removeAction.available}
      disabledMessage={disabledMessage}
      inProgress={removeAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeleteBrandingProfileMemo = React.memo(OperationDeleteBrandingProfile);

export default OperationDeleteBrandingProfileMemo;
