import { Form, Select } from 'antd';
import React from 'react';

import { DateTimeRangeRelativeEnum } from '@/components/DateTimeRelativeLabel/types';
import { I18nComponents } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import DateTimeRelativeLabel from '../DateTimeRelativeLabel';
import FormattedMessage from '../FormattedMessage';

import type { DateTimeRelativeItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const options = enumValues(DateTimeRangeRelativeEnum).map((value) => ({
  value,
  label: <DateTimeRelativeLabel value={value} />,
}));

const DateTimeRelativeItemRaw = <Values extends Store = Store>({
  'data-test': dataTest,
  label,
  name,
  readonly,
  required,
  placeholder,
  ItemProps = {},
}: DateTimeRelativeItemProps<Values>) => (
  <Form.Item label={label} name={name} required={required} {...ItemProps}>
    <Select
      data-test={dataTest}
      disabled={readonly}
      allowClear
      showSearch
      options={options}
      placeholder={
        !readonly
          ? placeholder || <FormattedMessage id={I18nComponents.DATE_TIME_RELATIVE_LABEL_PLACEHOLDER} />
          : undefined
      }
    />
  </Form.Item>
);

const DateTimeRelativeItem = React.memo(DateTimeRelativeItemRaw) as typeof DateTimeRelativeItemRaw;

export default DateTimeRelativeItem;
