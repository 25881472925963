import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshPayment, PaymentsDocumentationLink } from '@/features/payments/components';
import { usePayment } from '@/features/payments/hooks';
import type { Payment } from '@/features/payments/types';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPaymentDataLoading = <
  Original extends { data: Payment } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { paymentId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithPaymentDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = usePayment(props.paymentId);
    return withCardDataLoading<Payment, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPagePayments.PAYMENT_VIEW_COMPONENTS_PAYMENT_CARD_TITLE}
          values={{ id: props.paymentId }}
        />
      ),
      CardProps: {
        extra: (
          <Space>
            <PaymentsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
            <OperationRefreshPayment paymentId={props.paymentId} data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withPaymentDataLoading;
