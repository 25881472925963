import { Form, Input } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { BrandingToSTextItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const { TextArea } = Input;

const BrandingToSTextItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  containerHeight,
}: BrandingToSTextItemProps<Values>) => {
  const { formatMessage } = useIntl();

  return (
    <Form.Item<Values>
      label={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_TOS_FORM_EDIT_FORM_TEXT_ITEM_LABEL} />}
      name={name}
      rules={[
        {
          transform: (v?: string) => v?.trim(),
          required: true,
          message: (
            <FormattedMessage id={I18nFeatureBranding.COMPONENTS_TOS_FORM_EDIT_FORM_TEXT_ITEM_VALIDATION_REQUIRED} />
          ),
        },
      ]}
    >
      <TextArea
        data-test={dataTest}
        disabled={readonly}
        placeholder={formatMessage({ id: I18nFeatureBranding.COMPONENTS_TOS_FORM_EDIT_FORM_TEXT_ITEM_PLACEHOLDER })}
        style={containerHeight ? { height: containerHeight } : undefined}
      />
    </Form.Item>
  );
};

const BrandingToSTextItemMemo = React.memo(BrandingToSTextItem) as typeof BrandingToSTextItem;

export default BrandingToSTextItemMemo;
