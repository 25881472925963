import { useCallback } from 'react';

import { PageLoading } from '@/components';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { usePreferencesActions, useUser } from '@/features/user/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import { CompanyStep, CongratulationsStep } from './components';
import { withOnboardingStep } from './hocs';
import { OnboardingStep } from './types';

import type React from 'react';

export const OnboardPage: React.FC<{ currentStep: OnboardingStep }> = ({ currentStep }) => {
  const user = useUser();
  const settings = useCompanySettings();

  const onCompanyCreated = settings.forceRefresh;

  const { persist } = usePreferencesActions();
  const onFinish = useCallback(() => withSuppressError(persist.act)({ onboarded: true }), [persist]);

  if (user.data.isDirty) {
    return <PageLoading type="OnboardPage" />;
  }

  return (
    <>
      {currentStep === OnboardingStep.company && (
        <CompanyStep data-test="company-step" userEmail={user.data.data?.email} onSubmit={onCompanyCreated} />
      )}
      {currentStep === OnboardingStep.congratulations
        && (!settings.data.data ? (
          <PageLoading type="OnboardSettingsAwait" />
        ) : (
          <CongratulationsStep data-test="congratulations-step" settings={settings.data.data} onSubmit={onFinish} />
        ))}
    </>
  );
};

export default withOnboardingStep(OnboardPage);
