import { Alert, Tag, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

import { FormattedMessage, SupportEmail, WarningTooltip } from '@/components';
import { useActiveCompany } from '@/features/company/hooks';
import withPayoutBatch from '@/features/payouts/hocs/withPayoutBatch';
import type { PayoutBatchDetailed } from '@/features/payouts/hooks';
import type { PayoutBatch } from '@/features/payouts/types';
import { PayoutBatchStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';
import { useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';

import type { PayoutBatchStatusBannerProps } from './types';

type BannerState = 'none' | 'pending' | 'stale';
const calculateState = (batch: Pick<PayoutBatch, 'status' | 'broadcastAt' | 'staleAt'>): BannerState => {
  if (batch.status !== PayoutBatchStatusAPIModel.Pending) {
    return 'none';
  }
  const now = dayjs();
  if (now.isBefore(batch.broadcastAt)) {
    return 'none';
  }
  if (now.isAfter(batch.staleAt)) {
    return 'stale';
  }
  return 'pending';
};

const PayoutBatchStatusBanner = <
  T extends Pick<PayoutBatch, 'status' | 'broadcastAt' | 'staleAt' | 'num'> = Pick<
    PayoutBatch,
    'status' | 'broadcastAt' | 'staleAt' | 'num'
  >,
>({
  'data-test': dataTest,
  batch,
  mode,
  style,
  className,
}: PayoutBatchStatusBannerProps<T>) => {
  const [state, setState] = useStateMountSafe(() => calculateState(batch));
  const { data: company } = useActiveCompany();
  useEffect(() => {
    const now = dayjs();
    if (batch.broadcastAt && now.isBefore(batch.broadcastAt)) {
      const timeout = setTimeout(
        () => setState(calculateState(batch)),
        batch.broadcastAt.getMilliseconds() - Date.now(),
      );
      return () => clearTimeout(timeout);
    }
    if (batch.staleAt && now.isBefore(batch.staleAt)) {
      const timeout = setTimeout(() => setState(calculateState(batch)), batch.staleAt.getMilliseconds() - Date.now());
      return () => clearTimeout(timeout);
    }
    return noop;
  }, [batch, setState]);
  if (state === 'none') {
    return null;
  }
  if (state === 'stale') {
    return mode === 'tag' ? (
      <WarningTooltip
        type="error"
        title={
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_STALE_DESCRIPTION}
            values={{
              ln: (text: React.ReactNode) => (
                <SupportEmail title={text} subject={`[${company.data?.id}]: Stale payout batch ${batch.num}`} />
              ),
            }}
          />
        }
      >
        <Tag data-test={dataTest} color="error" className={className} style={{ ...style, marginRight: 0 }}>
          <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_STALE_TAG} />
        </Tag>
      </WarningTooltip>
    ) : (
      <Alert
        data-test={dataTest}
        className={className}
        style={style}
        message={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_STALE_TITLE} />}
        description={
          <FormattedMessage
            id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_STALE_DESCRIPTION}
            values={{
              ln: (text: React.ReactNode) => (
                <SupportEmail title={text} subject={`[${company.data?.id}]: Stale payout batch ${batch.num}`} />
              ),
            }}
          />
        }
        type="error"
        showIcon
      />
    );
  }
  return mode === 'tag' ? (
    <Tooltip title={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_PENDING_DESCRIPTION} />}>
      <Tag data-test={dataTest} color="processing" className={className} style={{ ...style, marginRight: 0 }}>
        <FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_PENDING_TAG} />
      </Tag>
    </Tooltip>
  ) : (
    <Alert
      data-test={dataTest}
      className={className}
      style={style}
      message={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_PENDING_TITLE} />}
      description={<FormattedMessage id={I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_BANNER_PENDING_DESCRIPTION} />}
      type="info"
      showIcon
    />
  );
};

const PayoutBatchStatusBannerHOC =
  withPayoutBatch<PayoutBatchStatusBannerProps<PayoutBatchDetailed>>()(PayoutBatchStatusBanner);

const PayoutBatchStatusBannerMemo = React.memo(PayoutBatchStatusBannerHOC) as typeof PayoutBatchStatusBannerHOC;

export default PayoutBatchStatusBannerMemo;
