import { Descriptions, Space, Typography } from 'antd';
import React from 'react';

import { FormattedMessage, ReadonlyComponent } from '@/components';
import { AssetAmount } from '@/features/dictionary/blockchain/components';
import { SubscriptionPlanScheduleView } from '@/features/subscription-plans/components';
import { withSubscriptionPlanDataLoading } from '@/features/subscription-plans/hocs';
import { I18nFeatureSubscriptionPlans, I18nFeatureSubscriptions } from '@/generated/i18n/i18n';

import type { WrappedSubscriptionPlanDetailsProps, SubscriptionPlanDetailsProps } from './types';

const { Text } = Typography;

const SubscriptionPlanShortDetails: React.FC<WrappedSubscriptionPlanDetailsProps> = ({
  data,
  'data-test': dataTest,
  SubscriptionPlanLink,
}) => (
  <SubscriptionPlanLink
    value={data.id}
    mode="text"
    title={
      <Space>
        <Text ellipsis={{ tooltip: data.name }} data-test={dataTest} style={{ overflow: 'unset', color: 'unset' }}>
          {data.name}
        </Text>
        <SubscriptionPlanScheduleView
          value={data.periodSeconds}
          data-test={dataTest && `${dataTest}-period`}
          mode="full"
        />
        <ReadonlyComponent
          value={
            <AssetAmount
              withCurrency
              withBlockchainMark
              assetId={data.amount.asset}
              value={data.amount.value}
              data-test={dataTest && `${dataTest}-amount`}
            />
          }
          copyable={false}
        />
      </Space>
    }
    data-test={dataTest && `${dataTest}-link`}
  />
);

const SubscriptionPlanShortDetailsWrapped = withSubscriptionPlanDataLoading(SubscriptionPlanShortDetails);

const SubscriptionPlanLongDetails: React.FC<WrappedSubscriptionPlanDetailsProps> = ({
  data,
  'data-test': dataTest,
  SubscriptionPlanLink,
}) => (
  <>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_PLAN} />}>
      <SubscriptionPlanLink value={data.id} data-test={dataTest && `${dataTest}-link`} title={data.name} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_PERIOD} />}>
      <SubscriptionPlanScheduleView value={data.periodSeconds} data-test={dataTest && `${dataTest}-period`} />
    </Descriptions.Item>
    <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptionPlans.LABELS_ROW_TITLE_AMOUNT} />}>
      <AssetAmount
        withCurrency
        assetId={data.amount.asset}
        value={data.amount.value}
        currencyMode="long"
        data-test={dataTest && `${dataTest}-amount`}
      />
    </Descriptions.Item>
  </>
);

const SubscriptionPlanLongDetailsWrapped = withSubscriptionPlanDataLoading(SubscriptionPlanLongDetails);

const SubscriptionPlanDetails: React.FC<SubscriptionPlanDetailsProps> = ({
  planId,
  'data-test': dataTest,
  mode,
  SubscriptionPlanLink,
}) => {
  switch (mode) {
    case 'link':
      return (
        <Descriptions.Item label={<FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_PLAN} />}>
          <SubscriptionPlanShortDetailsWrapped
            data-test={dataTest}
            planId={planId}
            SubscriptionPlanLink={SubscriptionPlanLink}
          />
        </Descriptions.Item>
      );
    case 'details':
      return (
        <SubscriptionPlanLongDetailsWrapped
          data-test={dataTest}
          planId={planId}
          SubscriptionPlanLink={SubscriptionPlanLink}
        />
      );
  }
  return null;
};

const SubscriptionPlanDetailsMemo = React.memo(SubscriptionPlanDetails);

export default SubscriptionPlanDetailsMemo;
