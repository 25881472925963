import { useMemo } from 'react';

import { noSingleDataIdFn, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchPayout } from '@/features/payouts/actions';
import { makeSelectPayout } from '@/features/payouts/selectors';
import type { PayoutSummary } from '@/features/payouts/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

import type { UsePayout } from './types';

const fetchFactory = (
  id: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<PayoutSummary>, Parameters<typeof fetchPayout>[0]> =>
  fetchPayout({ id, force });

export default function usePayout(id: string | undefined): UsePayout {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchPayout, id), [id]);
  return useAppSingleData(fetchFactory, makeSelectPayout, noSingleDataIdFn(), id, dataFetchingSelector);
}
