import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { PoweredByTextItemProps } from './types';
import type React from 'react';

const PoweredByTextItem: React.FC<PoweredByTextItemProps> = ({ 'data-test': dataTest, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<PaymentsBranding>
      data-test={dataTest}
      name={[
        nameof<PaymentsBranding, 'poweredBy'>('poweredBy'),
        nameof<NonNullable<PaymentsBranding['poweredBy']>, 'customText'>('customText'),
        nameof<NonNullable<NonNullable<PaymentsBranding['poweredBy']>['customText']>>('text'),
      ]}
      readonly={disabled}
      placeholder={formatMessage({
        id: I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_TEXT_ITEM_PLACEHOLDER,
      })}
      messages={{
        required: (
          <FormattedMessage
            id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_TEXT_ITEM_ERROR_REQUIRED}
          />
        ),
      }}
      rules={disabled ? [{ type: 'string' }] : undefined}
    />
  );
};

export default PoweredByTextItem;
