import { getAddress, getContract, publicActions } from 'viem';

import type { WalletClient, Account, Chain, TransactionReceipt, Transport } from 'viem';

const subscriptionAbi = [
  {
    inputs: [],
    name: 'pause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'unpause',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'destroy',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const;

export const pauseSubscriptionOnBC = async (
  client: WalletClient<Transport, Chain, Account>,
  subscriptionAddress: string,
): Promise<TransactionReceipt> => {
  const contract = getContract({
    address: getAddress(subscriptionAddress),
    abi: subscriptionAbi,
    client,
  });
  const gasPrice = await client.extend(publicActions).getGasPrice();
  const gasLimit = await contract.estimateGas.pause();
  const tx = await contract.write.pause({ gasPrice, gasLimit });
  return client.extend(publicActions).waitForTransactionReceipt({ hash: tx, confirmations: 3 });
};

export const unpauseSubscriptionOnBC = async (
  client: WalletClient<Transport, Chain, Account>,
  subscriptionAddress: string,
): Promise<TransactionReceipt> => {
  const contract = getContract({
    address: getAddress(subscriptionAddress),
    abi: subscriptionAbi,
    client,
  });
  const gasPrice = await client.extend(publicActions).getGasPrice();
  const gasLimit = await contract.estimateGas.unpause({});
  const tx = await contract.write.unpause({ gasPrice, gasLimit });
  return client.extend(publicActions).waitForTransactionReceipt({ hash: tx, confirmations: 3 });
};

export const cancelSubscriptionOnBC = async (
  client: WalletClient<Transport, Chain, Account>,
  subscriptionAddress: string,
): Promise<TransactionReceipt> => {
  const contract = getContract({
    address: getAddress(subscriptionAddress),
    abi: subscriptionAbi,
    client,
  });
  const gasPrice = await client.extend(publicActions).getGasPrice();
  const gasLimit = await contract.estimateGas.destroy();
  const tx = await contract.write.destroy({ gasPrice, gasLimit });
  return client.extend(publicActions).waitForTransactionReceipt({ hash: tx, confirmations: 3 });
};
