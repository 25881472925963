import { Navigate, Route, Routes } from 'react-router-dom';

import { BasicLayout } from '@/layouts';
import { withBasicPageGuard } from '@/pages/hocs';
import { useMenuData } from '@/pages/hooks';
import { AppPageRoutes } from '@/pages/routes';

import MerchantWalletBalanceViewPage from './balance-view';
import MerchantWalletBalancesPage from './balances';
import { auditWalletTransfersLink, AuditPageRoutes } from './routes';
import MerchantWalletTransferEntityPage from './transfer-entity';
import MerchantWalletTransferViewPage from './transfer-view';
import MerchantWalletTransfersPage from './transfers';

import type React from 'react';

export const AuditDefaultRoute: React.FC = () => <Navigate to={auditWalletTransfersLink()} replace />;

const MerchantWalletTransferPages: React.FC = () => {
  const routes = useMenuData();
  return (
    <BasicLayout routes={routes}>
      <Routes>
        <Route path={AuditPageRoutes.TRANSFERS} Component={MerchantWalletTransfersPage} />
        <Route path={AuditPageRoutes.TRANSFER_VIEW} Component={MerchantWalletTransferViewPage} />
        <Route path={AuditPageRoutes.TRANSFER_ENTITY} Component={MerchantWalletTransferEntityPage} />
        <Route path={AuditPageRoutes.BALANCES} Component={MerchantWalletBalancesPage} />
        <Route path={AuditPageRoutes.BALANCE_VIEW} Component={MerchantWalletBalanceViewPage} />
        <Route path="*" Component={AuditDefaultRoute} />
      </Routes>
    </BasicLayout>
  );
};

const MerchantWalletTransferPagesHOC: React.FC = withBasicPageGuard(MerchantWalletTransferPages);

export default (
  <Route key={AppPageRoutes.AUDIT} path={`${AppPageRoutes.AUDIT}/*`} Component={MerchantWalletTransferPagesHOC} />
);
