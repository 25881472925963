import { GlobalOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { Dropdown, Typography } from 'antd';
import React, { useMemo } from 'react';

import { useLocale } from '@/features/i18n/hooks';
import { I18nLocales } from '@/generated/i18n/i18n';
import { withLogError } from '@/infrastructure/utils/functions';
import { enumValues } from '@/infrastructure/utils/ts';

import type { SelectLangProps } from './types';

const { Link } = Typography;
const languageLabels: Record<I18nLocales, string> = {
  [I18nLocales['en-US']]: 'English',
};
const languageIcons: Record<I18nLocales, string> = {
  [I18nLocales['en-US']]: '🇺🇸',
};

const preventDefault = (e: React.MouseEvent) => e.preventDefault();

const SelectLang: React.FC<SelectLangProps> = ({ 'data-test': dataTest, className }) => {
  const { locale, update } = useLocale();
  const menu = useMemo(
    () => ({
      items: enumValues(I18nLocales).map((key) => ({
        'data-test': dataTest && `${dataTest}-locale-${locale}`,
        key,
        label: <span>{languageLabels[locale]}</span>,
        icon: <span>{languageIcons[locale]}</span>,
        disabled: true,
        onClick: () => withLogError(update)(key),
      })),
      selectedKeys: [locale],
    }),
    [dataTest, locale, update],
  );
  return (
    <Dropdown menu={menu} className={className}>
      {}
      <Link
        onClick={preventDefault}
        className={css`
          color: inherit !important;
        `}
      >
        <GlobalOutlined />
      </Link>
    </Dropdown>
  );
};

const SelectLangMemo = React.memo(SelectLang);

export default SelectLangMemo;
