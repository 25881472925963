import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshPayoutBatches, PayoutsDocumentationLink } from '@/features/payouts/components';
import { usePayoutBatches } from '@/features/payouts/hooks';
import type { PayoutBatch } from '@/features/payouts/types';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPayoutBatchesDataLoading = <
  Original extends { data: PayoutBatch[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { payoutId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithPayoutDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = usePayoutBatches(props.payoutId);
    return data.data.data && !data.data.data.length
      ? null
      : withCardDataLoading<PayoutBatch[], Original>({
          ...data,
          'data-test': dataTest,
          title: <FormattedMessage id={I18nPageSettlements.PAYOUT_VIEW_COMPONENTS_BATCHES_CARD_TITLE} />,
          hideBack: true,
          CardProps: {
            extra: (
              <Space>
                <PayoutsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="icon" />
                <OperationRefreshPayoutBatches
                  data-test={dataTest && `${dataTest}-opRefresh`}
                  payoutId={props.payoutId}
                />
              </Space>
            ),
          },
        })(Component)(props);
  });

export default withPayoutBatchesDataLoading;
