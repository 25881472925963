// noinspection ExceptionCaughtLocallyJS
import { Form, Space, Typography } from 'antd';
import React, { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { ErrorFormMessage, FormattedMessage, InputItem, Operation, SupportEmail } from '@/components';
import { I18nFeatureEmail } from '@/generated/i18n/i18n';
import { usePrevious, useSubmitting, useTimer } from '@/hooks';
import { noop, withFinally, withSuppressPromise } from '@/infrastructure/utils/functions';

import { EmailCodeDescription } from './components';

import type { EmailCodeInputItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';
import type { Rule } from 'rc-field-form/lib/interface';

const lettersInCode = 6;

const EmailCodeInputItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  disabled,
  onRequireCode,
  onVerifyCode,
  email,
  codeState,
  isConfirmed,
}: EmailCodeInputItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const [submitting, withSubmitting] = useSubmitting(false);

  const tried = useRef<{ code: string; error?: Error }[]>([]);
  const previousEmail = usePrevious(email);
  useEffect(() => {
    if (previousEmail !== email) {
      tried.current = [];
    }
  }, [previousEmail, email]);
  const [tillRetry] = useTimer(codeState?.data?.retryableAfter);
  const isRateLimit = codeState?.error === 'RateLimit';
  const isToManyAttempts = codeState?.error === 'login.TriesLimit';
  const isEmailBlocked = codeState?.error === 'login.EmailBlocked';
  const isCodeExpired = codeState?.error === 'login.CodeExpired';

  const isNewCodeRequired = isToManyAttempts || isCodeExpired;

  const doRequireCode = useMemo(
    () =>
      onRequireCode
        ? withSuppressPromise(
            withFinally(withSubmitting(onRequireCode), () => {
              tried.current = [];
            }),
          )
        : undefined,
    [onRequireCode, withSubmitting],
  );
  const doVerifyCode = useMemo(
    () => (onVerifyCode ? withSubmitting((code: string) => onVerifyCode({ code })) : undefined),
    [onVerifyCode, withSubmitting],
  );
  const codeValidator: Rule[] = useMemo(
    () => [
      {
        validator: async (_, code?: string) => {
          if (isConfirmed) {
            return;
          }
          if (!code?.length) {
            throw new Error(formatMessage({ id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_REQUIRED }));
          }
          if (code.length > lettersInCode) {
            throw new Error(
              formatMessage(
                { id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_TOO_LONG },
                { number: lettersInCode },
              ),
            );
          }
          if (code.length !== lettersInCode) {
            // this error is to "block" the submit button, but it should not annoy the user with the text
            throw new Error();
          }
          const previousTry = tried.current.find((t) => t.code === code);
          if (previousTry?.error) {
            throw previousTry.error;
          } else if (previousTry) {
            return;
          }
          try {
            try {
              await doVerifyCode?.(code);
              tried.current.push({ code });
            } catch (e) {
              if (e === 'login.BadCode') {
                throw new Error(
                  formatMessage({ id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_CODE_INVALID }),
                );
              } else if (e === 'login.CodeExpired') {
                throw new Error(
                  formatMessage({ id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_CODE_EXPIRED }),
                );
              } else if (e === 'login.TriesLimit') {
                throw new Error(
                  formatMessage({
                    id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_TOO_MANY_ATTEMPTS,
                  }),
                );
              } else if (e === 'login.EmailBlocked') {
                throw new Error(
                  formatMessage({ id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_BLOCKED_SHORT }),
                );
              } else {
                throw new Error(
                  formatMessage({ id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_COMMON }),
                );
              }
            }
          } catch (error) {
            tried.current.push({ code, error: error as Error });
            throw error;
          }
        },
      },
    ],
    [doVerifyCode, formatMessage, isConfirmed],
  );

  return (
    <Space direction="vertical" size="small" style={{ display: 'flex', flex: 1 }}>
      <Typography.Text>
        <EmailCodeDescription
          email={email}
          isSent={codeState?.data?.sentAt && !isNewCodeRequired}
          isConfirmed={isConfirmed}
        />
      </Typography.Text>
      {isEmailBlocked && (
        <ErrorFormMessage
          data-test={dataTest && `${dataTest}-error`}
          content={
            <FormattedMessage
              id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_BLOCKED}
              values={{
                ln: (text: React.ReactNode) => (
                  <SupportEmail
                    data-test={dataTest && `${dataTest}-errorLink`}
                    title={text}
                    subject={`Blocked email ${email || ''}`}
                  />
                ),
              }}
            />
          }
        />
      )}
      {isRateLimit && (
        <ErrorFormMessage
          data-test={dataTest && `${dataTest}-error`}
          content={<FormattedMessage id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_RATE_LIMIT} />}
        />
      )}
      <Form.Item noStyle>
        <div style={{ display: 'flex', flex: 1 }}>
          <Operation
            primary
            style={{ minWidth: 150 }}
            title={
              tillRetry && !isNewCodeRequired ? (
                <FormattedMessage
                  id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_RESEND_BUTTON_TITLE}
                  values={{ timer: tillRetry }}
                />
              ) : (
                <FormattedMessage id={I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_GET_BUTTON_TITLE} />
              )
            }
            disabled={
              disabled
              || isConfirmed
              || submitting
              || isEmailBlocked
              || isRateLimit
              || (!isNewCodeRequired && !!tillRetry)
            }
            data-test={dataTest && `${dataTest}-cancel`}
            icon={null}
            onClick={doRequireCode ?? noop}
            mode="button"
          />
          <InputItem<Values>
            data-test={dataTest && `${dataTest}-code`}
            name={name}
            readonly={
              disabled || isConfirmed || isEmailBlocked || isRateLimit || isNewCodeRequired || !codeState?.data?.sentAt
            }
            rules={codeValidator}
            ItemProps={{
              label: null,
              style: { width: '100%', paddingLeft: 20 },
              normalize: (value?: string) => value?.replace(/[^0-9]*/g, ''),
              validateStatus: isConfirmed ? 'success' : undefined,
            }}
            InputProps={{
              style: { width: '100%' },
              min: 0,
              pattern: '[0-9]*',
              inputMode: 'numeric',
              readOnly: submitting,
              placeholder: formatMessage({ id: I18nFeatureEmail.COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_PLACEHOLDER }),
            }}
          />
        </div>
      </Form.Item>
    </Space>
  );
};

const EmailCodeInputItemMemo = React.memo(EmailCodeInputItem);

export default EmailCodeInputItemMemo;
