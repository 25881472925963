import type { LoadingStateWithDirty } from '@/infrastructure/model';
import type { EmptyObject } from '@/infrastructure/utils/ts';

export const createLoadingDataSelectors = <Global extends EmptyObject, Type extends string, Value, Exc = string>(
  getLoadingState: (state: Global) => LoadingStateWithDirty<Value, Exc>,
  type: Type,
) => {
  const makeSelectLoadingStateName = `makeSelect${type}` as const;
  const makeSelectLoadingDirtyName = `makeSelect${type}IsDirty` as const;
  const makeSelectLoadingDataName = `makeSelect${type}Data` as const;

  const makeSelectLoadingState = () => getLoadingState;
  const makeSelectLoadingDirty = () => (state: Global) => getLoadingState(state).isDirty;
  const makeSelectLoadingData = () => (state: Global) => getLoadingState(state).data;

  // FIXME: redefine the type without the cast
  return {
    [makeSelectLoadingStateName]: makeSelectLoadingState,
    [makeSelectLoadingDataName]: makeSelectLoadingData,
    [makeSelectLoadingDirtyName]: makeSelectLoadingDirty,
  } as Record<typeof makeSelectLoadingStateName, typeof makeSelectLoadingState> &
    Record<typeof makeSelectLoadingDataName, typeof makeSelectLoadingData> &
    Record<typeof makeSelectLoadingDirtyName, typeof makeSelectLoadingDirty>;
};
