import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { DateTimeRangeItem, FormattedMessage } from '@/components';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import type { MerchantWalletTransferBroadcastAtRangeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const MerchantWalletTransferBroadcastAtRangeFilterItemRaw = <Values extends Store = Store>(
  props: MerchantWalletTransferBroadcastAtRangeFilterItemProps<Values>,
) => {
  const { formatMessage } = useIntl();
  return (
    <DateTimeRangeItem<Values>
      {...props}
      messages={useMemo(
        () => ({
          from: {
            label: (
              <FormattedMessage
                id={I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_LABEL}
              />
            ),
            placeholder: formatMessage({
              id: I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_PLACEHOLDER,
            }),
          },
          to: {
            label: (
              <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_LABEL} />
            ),
            placeholder: formatMessage({
              id: I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_PLACEHOLDER,
            }),
          },
        }),
        [formatMessage],
      )}
    />
  );
};

const MerchantWalletTransferBroadcastAtRangeFilterItem = React.memo(
  MerchantWalletTransferBroadcastAtRangeFilterItemRaw,
) as typeof MerchantWalletTransferBroadcastAtRangeFilterItemRaw;

export default MerchantWalletTransferBroadcastAtRangeFilterItem;
