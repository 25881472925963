import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { isSeedValid } from '@/infrastructure/utils/hd';

import type { useIntl } from 'react-intl';

export const seedToParts = (seed?: string) =>
  seed
    ?.trim()
    .split(/\s/)
    .filter((v) => !!v) ?? [];

export const normalizeSeed = (seed?: string) => seedToParts(seed).join(' ');

export const validateSeed = (seed: string, formatMessage: ReturnType<typeof useIntl>['formatMessage']) => {
  const formattedSeed = seedToParts(seed);
  if (formattedSeed.length !== 12 || !isSeedValid(formattedSeed.join(' '))) {
    throw new Error(formatMessage({ id: I18nFeatureDictionary.COMPONENTS_SEED_ITEM_ERROR_FORMAT_INVALID }));
  }
};
