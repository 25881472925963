import { PageContainer } from '@/components';

import {
  SubscriptionChurnRateCard,
  SubscriptionMonthlyGrowthCard,
  SubscriptionMonthlyRevenueCard,
  SubscriptionStatisticsBreadcrumb,
} from './components';

import type React from 'react';

const dataTest: string | undefined = 'statistics';

const SubscriptionStatisticsPage: React.FC = () => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={<SubscriptionStatisticsBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
  >
    <SubscriptionMonthlyRevenueCard data-test={dataTest && `${dataTest}-revenue`} />
    <SubscriptionMonthlyGrowthCard data-test={dataTest && `${dataTest}-grow`} />
    <SubscriptionChurnRateCard data-test={dataTest && `${dataTest}-rate`} />
  </PageContainer>
);

export default SubscriptionStatisticsPage;
