import { Space } from 'antd';

import { FormattedMessage } from '@/components';
import { OperationRefreshCollectTask } from '@/features/collectable/components';
import { useCollectTask } from '@/features/collectable/hooks';
import type { CollectTask } from '@/features/collectable/types';
import { I18nPageCollectable } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withCollectTaskDataLoading = <
  Original extends { data: CollectTask } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { taskId: string },
>(
  Component: React.ComponentType<Original>,
): React.FC<Wrapper> =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithCollectTaskDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useCollectTask(props.taskId);
    return withCardDataLoading<CollectTask, Original>({
      ...data,
      'data-test': dataTest,
      title: (
        <FormattedMessage id={I18nPageCollectable.TASK_VIEW_COMPONENTS_TASK_CARD_TITLE} values={{ id: props.taskId }} />
      ),
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshCollectTask taskId={props.taskId} data-test={dataTest && `${dataTest}-opRefresh`} />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withCollectTaskDataLoading;
