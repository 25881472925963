import React from 'react';

import type { ReportParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/reports';

import PaymentsFilterForm from '../PaymentsFilterForm';

const PaymentsReportParametersViewRaw: React.FC<ReportParametersViewProps> = ({ value, 'data-test': dataTest }) => (
  <PaymentsFilterForm data-test={dataTest} value={value} readonly withNetwork />
);

const PaymentsReportParametersView = React.memo(PaymentsReportParametersViewRaw);

export default PaymentsReportParametersView;
