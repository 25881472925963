import { FormattedMessage } from '@/components';
import { OperationRefreshMerchantGasWallet } from '@/features/gas-wallets/components';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';
import type { GasWallet } from '@/features/gas-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withGasWalletDataLoading = <T extends { data: GasWallet } & TestableProps>(
  Component: React.ComponentType<Omit<T, 'data'> & { data: GasWallet }>,
) =>
  namedHOC<Omit<T, 'data'> & { data: GasWallet }, Omit<T, 'data'> & { bt: BlockchainTypeAPIModel }>(
    Component,
    'WwithGasWalletDataLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = useMerchantGasWallet(props.bt);
    return withCardDataLoading<GasWallet, Omit<T, 'data'> & { data: GasWallet }>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLET_COMPONENTS_DERIVED_CARD_TITLE} />,
      CardProps: {
        extra: (
          <OperationRefreshMerchantGasWallet
            data-test={dataTest && `${dataTest}-refreshOp`}
            mode="inline"
            bt={props.bt}
          />
        ),
      },
    })(Component)(props);
  });

export default withGasWalletDataLoading;
