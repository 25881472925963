import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageDashboard } from '@/generated/i18n/i18n';
import { dashboardLink } from '@/pages/dashboard/routes';

import type { DashboardLinkProps } from './types';

const DashboardLink: React.FC<DashboardLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title }) => (
  <Link
    mode={mode}
    to={dashboardLink()}
    data-test={dataTest}
    title={title || <FormattedMessage id={I18nPageDashboard.COMPONENTS_LINK} />}
  />
);

const DashboardLinkMemo = React.memo(DashboardLink);

export default DashboardLinkMemo;
