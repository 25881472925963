import { Alert } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { merchantWalletTransferKindI18n } from '@/features/merchant-wallet-transfers/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';

import type { EntityLinkNotSupportedAlertProps } from './types';

const EntityLinkNotSupportedAlert: React.FC<EntityLinkNotSupportedAlertProps> = ({ value, 'data-test': dataTest }) => (
  <Alert
    data-test={dataTest}
    type="warning"
    message={
      <FormattedMessage id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_SUPPORTED_ALERT_TITLE} />
    }
    description={
      <FormattedMessage
        id={I18nPageAudit.TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_SUPPORTED_ALERT_DESCRIPTION}
        values={{ entity: <FormattedMessage id={merchantWalletTransferKindI18n[value]} /> }}
      />
    }
  />
);

const EntityLinkNotSupportedAlertMemo = React.memo(EntityLinkNotSupportedAlert);

export default EntityLinkNotSupportedAlertMemo;
