import { useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { createDonation } from '@/features/donations/actions';
import type { NewDonation, Donation } from '@/features/donations/types';
import { useActionPending } from '@/features/global/hooks';
import { type HookAction } from '@/infrastructure/model';

import useDonationList from './useDonationList';

export interface UseDonationsActions {
  create: HookAction<[NewDonation], Donation>;
}

export default function useDonationsActions(): UseDonationsActions {
  const { loading } = useDonationList();
  const { withExtractDataDispatch } = useAppDispatch();
  const isCreating = useActionPending(createDonation);
  const createAction: UseDonationsActions['create']['act'] = useMemo(
    () => withExtractDataDispatch(createDonation),
    [withExtractDataDispatch],
  );
  const createHook: UseDonationsActions['create'] = {
    act: createAction,
    inAction: isCreating,
    available: !loading,
  };

  return { create: createHook };
}
