import { Input } from 'antd';
import React, { useEffect } from 'react';

import WalletConnect from '@/features/web3/components/WalletConnect';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { useStateMountSafe } from '@/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';

import type { WalletAddressProps } from './types';

const WalletAddress: React.FC<WalletAddressProps> = ({ onChange, size, value, 'data-test': dataTest }) => {
  const { connector, account, refreshAccount } = useWeb3Connector();
  useEffect(() => {
    if (account !== value) {
      onChange?.(account);
    }
  }, [account, onChange, value]);

  const [oldConnectorId, setOldConnectorId] = useStateMountSafe<string | undefined>();
  useEffect(() => {
    if (connector.id !== oldConnectorId) {
      withSuppressPromise(refreshAccount)();
      setOldConnectorId(connector.id);
    }
  }, [connector.id, oldConnectorId, refreshAccount, setOldConnectorId]);

  return account ? (
    <Input disabled value={account} data-test={dataTest} size={size} />
  ) : (
    <WalletConnect connectorId={connector.id} data-test={dataTest} onConnect={refreshAccount} />
  );
};

const WalletAddressContexted = withWeb3Connector<WalletAddressProps & { connectorId: string }>(WalletAddress);

const WalletAddressMemo = React.memo(WalletAddressContexted) as typeof WalletAddressContexted;

export default WalletAddressMemo;
