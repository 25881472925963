import React, { useMemo } from 'react';

import { MoneyAmountRangeItem } from '@/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';

import AssetCode from '../AssetCode';
import AssetTooltip from '../AssetTooltip';

import type { AssetAmountRangeItemProps } from './types';

const AssetAmountRangeItem = ({ assetId, ...props }: AssetAmountRangeItemProps) => {
  const { data: asset } = useAsset(assetId);
  const moneyAsset = useMemo(
    () =>
      asset.data
        ? {
            code: asset.data.code,
            title: (
              <AssetTooltip type={asset.data.code}>
                <AssetCode value={asset.data.code} />
              </AssetTooltip>
            ),
            precision: asset.data.formatDecimals,
          }
        : undefined,
    [asset.data],
  );
  if (!moneyAsset) {
    return null;
  }
  return <MoneyAmountRangeItem asset={moneyAsset} {...props} />;
};

const AssetAmountRangeItemMemo = React.memo(AssetAmountRangeItem) as typeof AssetAmountRangeItem;

export default AssetAmountRangeItemMemo;
