import * as React from "react";
const SvgBitcoin = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "512px", height: "512px", viewBox: "0 0 1 1", preserveAspectRatio: "xMidYMid", id: "svg2", ...props }, /* @__PURE__ */ React.createElement("metadata", { id: "metadata22" }), /* @__PURE__ */ React.createElement("defs", { id: "defs4" }, /* @__PURE__ */ React.createElement("filter", { id: "_drop-shadow", colorInterpolationFilters: "sRGB" }, /* @__PURE__ */ React.createElement("feGaussianBlur", { in: "SourceAlpha", result: "blur-out", stdDeviation: 1, id: "feGaussianBlur7" }), /* @__PURE__ */ React.createElement("feBlend", { in: "SourceGraphic", in2: "blur-out", mode: "normal", id: "feBlend9" })), /* @__PURE__ */ React.createElement("linearGradient", { id: "coin-gradient", x1: "0%", y1: "0%", x2: "0%", y2: "100%" }, /* @__PURE__ */ React.createElement("stop", { offset: "0%", style: {
  stopColor: "#f9aa4b"
}, id: "stop12" }), /* @__PURE__ */ React.createElement("stop", { offset: "100%", style: {
  stopColor: "#f7931a"
}, id: "stop14" }))), /* @__PURE__ */ React.createElement("g", { transform: "scale(0.015625)", id: "g16" }, /* @__PURE__ */ React.createElement("path", { id: "coin", d: "m 63.0359,39.741 c -4.274,17.143 -21.637,27.576 -38.782,23.301 -17.138,-4.274 -27.571,-21.638 -23.295,-38.78 4.272,-17.145 21.635,-27.579 38.775,-23.305 17.144,4.274 27.576,21.64 23.302,38.784 z", style: {
  fill: "url(#coin-gradient)"
} }), /* @__PURE__ */ React.createElement("path", { id: "symbol", d: "m 46.1009,27.441 c 0.637,-4.258 -2.605,-6.547 -7.038,-8.074 l 1.438,-5.768 -3.511,-0.875 -1.4,5.616 c -0.923,-0.23 -1.871,-0.447 -2.813,-0.662 l 1.41,-5.653 -3.509,-0.875 -1.439,5.766 c -0.764,-0.174 -1.514,-0.346 -2.242,-0.527 l 0.004,-0.018 -4.842,-1.209 -0.934,3.75 c 0,0 2.605,0.597 2.55,0.634 1.422,0.355 1.679,1.296 1.636,2.042 -3.94,15.801 0,0 -3.94,15.801 -0.174,0.432 -0.615,1.08 -1.609,0.834 0.035,0.051 -2.552,-0.637 -2.552,-0.637 l -1.743,4.019 4.569,1.139 c 0.85,0.213 1.683,0.436 2.503,0.646 l -1.453,5.834 3.507,0.875 1.439,-5.772 c 0.958,0.26 1.888,0.5 2.798,0.726 l -1.434,5.745 3.511,0.875 1.453,-5.823 c 5.987,1.133 10.489,0.676 12.384,-4.739 1.527,-4.36 -0.076,-6.875 -3.226,-8.515 2.294,-0.529 4.022,-2.038 4.483,-5.155 z m -8.022,11.249 c -1.085,4.36 -8.426,2.003 -10.806,1.412 l 1.928,-7.729 c 2.38,0.594 10.012,1.77 8.878,6.317 z m 1.086,-11.312 c -0.99,3.966 -7.1,1.951 -9.082,1.457 l 1.748,-7.01 c 1.982,0.494 8.365,1.416 7.334,5.553 z", style: {
  fill: "#ffffff"
} })));
export default SvgBitcoin;
