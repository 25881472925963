import React from 'react';

import { DateTimeRelativeItem, FormattedMessage } from '@/components';
import { BlockchainItem, NetworkTypeItem } from '@/features/dictionary/blockchain/components';
import {
  GasHistoryAddressFilterItem,
  GasHistoryResultFilterItem,
} from '@/features/gas-history/components/GasHistoryFilterForm/components';
import type { GasHistoryReportPredicates } from '@/features/gas-history/types';
import { I18nFeatureGasHistory } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { GasHistoryReportScheduleParametersFormProps } from './types';

const GasHistoryReportScheduleParametersFormRaw: React.FC<GasHistoryReportScheduleParametersFormProps> = ({
  'data-test': dataTest,
  name,
  readonly,
}) => (
  <>
    <BlockchainItem
      data-test={dataTest && `${dataTest}-blockchain`}
      name={[name, nameof<GasHistoryReportPredicates>('btIn')]}
      readonly={readonly}
      required={false}
      multiselect
    />
    <DateTimeRelativeItem
      data-test={dataTest && `${dataTest}-createdAt`}
      name={[name, nameof<GasHistoryReportPredicates>('createdAtRangeRelative')]}
      label={
        <FormattedMessage
          id={I18nFeatureGasHistory.COMPONENTS_REPORT_SCHEDULE_PARAMETERS_CREATED_AT_RELATIVE_ITEM_LABEL}
        />
      }
      readonly={readonly}
      required={false}
    />
    <GasHistoryResultFilterItem
      data-test={dataTest && `${dataTest}-result`}
      name={[name, nameof<GasHistoryReportPredicates>('resultEq')]}
      readonly={readonly}
    />
    <GasHistoryAddressFilterItem
      data-test={dataTest && `${dataTest}-address`}
      name={[name, nameof<GasHistoryReportPredicates>('addressLike')]}
      readonly={readonly}
    />
    <NetworkTypeItem
      data-test={dataTest && `${dataTest}-network`}
      name={[name, nameof<GasHistoryReportPredicates>('networkEq')]}
      readonly={readonly}
      required={false}
      mode="select"
    />
  </>
);

const GasHistoryReportScheduleParametersForm = React.memo(GasHistoryReportScheduleParametersFormRaw);

export default GasHistoryReportScheduleParametersForm;
