import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageDonations } from '@/generated/i18n/i18n';
import { donationTransactionsLink } from '@/pages/donations/routes';

import type { DonationTransactionsLinkProps } from './types';

const DonationTransactionsLink: React.FC<DonationTransactionsLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={donationTransactionsLink()}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageDonations.TRANSACTIONS_COMPONENTS_LINK} />}
  />
);

const DonationTransactionsLinkMemo = React.memo(DonationTransactionsLink);

export default DonationTransactionsLinkMemo;
