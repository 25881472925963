import { Alert } from 'antd';
import React from 'react';

import type { NotificationMessageProps } from './types';

const NotificationMessage: React.FC<NotificationMessageProps> = ({
  severity = 'error',
  'data-test': dataTest,
  ...props
}) => <Alert type={severity} data-test={dataTest} {...props} />;

const NotificationMessageMemo = React.memo(NotificationMessage);

export default NotificationMessageMemo;
