import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageReports } from '@/generated/i18n/i18n';
import { reportsLink } from '@/pages/reports/routes';

import type { ReportsLinkProps } from './types';

const ReportsLinkRaw: React.FC<ReportsLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title }) => (
  <Link
    data-test={dataTest}
    to={reportsLink()}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageReports.ALL_COMPONENTS_LINK_TITLE} />}
  />
);

const ReportsLink = React.memo(ReportsLinkRaw);

export default ReportsLink;
