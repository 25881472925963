/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MonthlyRevenueRecordAPIModel } from './MonthlyRevenueRecordAPIModel';
import {
    MonthlyRevenueRecordAPIModelFromJSON,
    MonthlyRevenueRecordAPIModelFromJSONTyped,
    MonthlyRevenueRecordAPIModelToJSON,
} from './MonthlyRevenueRecordAPIModel';

/**
 * 
 * @export
 * @interface MonthlyRevenuePerPlanAPIModel
 */
export interface MonthlyRevenuePerPlanAPIModel {
    /**
     * 
     * @type {string}
     * @memberof MonthlyRevenuePerPlanAPIModel
     */
    planId: string;
    /**
     * 
     * @type {Array<MonthlyRevenueRecordAPIModel>}
     * @memberof MonthlyRevenuePerPlanAPIModel
     */
    perMonth?: Array<MonthlyRevenueRecordAPIModel>;
}

/**
 * Check if a given object implements the MonthlyRevenuePerPlanAPIModel interface.
 */
export function instanceOfMonthlyRevenuePerPlanAPIModel(value: object): boolean {
    if (!('planId' in value)) return false;
    return true;
}

export function MonthlyRevenuePerPlanAPIModelFromJSON(json: any): MonthlyRevenuePerPlanAPIModel {
    return MonthlyRevenuePerPlanAPIModelFromJSONTyped(json, false);
}

export function MonthlyRevenuePerPlanAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthlyRevenuePerPlanAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'planId': json['planId'],
        'perMonth': json['perMonth'] == null ? undefined : ((json['perMonth'] as Array<any>).map(MonthlyRevenueRecordAPIModelFromJSON)),
    };
}

export function MonthlyRevenuePerPlanAPIModelToJSON(value?: MonthlyRevenuePerPlanAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'planId': value['planId'],
        'perMonth': value['perMonth'] == null ? undefined : ((value['perMonth'] as Array<any>).map(MonthlyRevenueRecordAPIModelToJSON)),
    };
}

