import { Space } from 'antd';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { FormattedMessage } from '@/components';
import {
  OperationDeleteGasWallet,
  OperationRefreshMerchantGasWallet,
  OperationRefundGasWallet,
} from '@/features/gas-wallets/components';
import { useMerchantGasWallet } from '@/features/gas-wallets/hooks';
import type { GasWallet } from '@/features/gas-wallets/types';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withGasWalletsDataLoading = <T extends { data: GasWallet } & TestableProps>(
  Component: React.ComponentType<Omit<T, 'data'> & { data: GasWallet }>,
): React.FC<Omit<T, 'data'> & { bt: BlockchainTypeAPIModel }> =>
  namedHOC<Omit<T, 'data'> & { data: GasWallet }, Omit<T, 'data'> & { bt: BlockchainTypeAPIModel }>(
    Component,
    'WithGasWalletsDataLoading',
  )((props) => {
    const { 'data-test': dataTest, bt } = props;
    const navigate = useNavigate();
    const onDelete = useCallback(() => navigate(-1), [navigate]);
    const data = useMerchantGasWallet(bt);
    return withCardDataLoading<GasWallet, Omit<T, 'data'> & { data: GasWallet }>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLET_COMPONENTS_WALLET_CARD_TITLE} />,
      CardProps: {
        extra: (
          <Space>
            <OperationRefreshMerchantGasWallet data-test={dataTest && `${dataTest}-refreshOp`} bt={bt} mode="inline" />
            <OperationRefundGasWallet data-test={dataTest && `${dataTest}-refundOp`} bt={bt} mode="inline" />
            <OperationDeleteGasWallet
              data-test={dataTest && `${dataTest}-deleteOp`}
              onSuccess={onDelete}
              bt={bt}
              mode="inline"
            />
          </Space>
        ),
      },
    })(Component)(props);
  });

export default withGasWalletsDataLoading;
