import { useCallback, useMemo } from 'react';

import type { MerchantDomain } from '@/features/branding/types';
import { type HookAction, type LoadingStateWithDirty, mapStoredState } from '@/infrastructure/model';

import useDomains from './useDomains';
import useDomainsActions from './useDomainsActions';

export type DeactivateUnavailabilityReason = 'no-data' | 'invalid-status';
export type ActivateUnavailabilityReason = 'no-data' | 'invalid-status' | 'unapproved';

export interface UseDomainActions {
  data: LoadingStateWithDirty<MerchantDomain>;
  activate: HookAction<[], MerchantDomain[], ActivateUnavailabilityReason>;
  deactivate: HookAction<[], MerchantDomain[], DeactivateUnavailabilityReason>;
  remove: HookAction<[], MerchantDomain[]>;
}

export default function useDomainActions(id: string): UseDomainActions {
  const { activate, deactivate, remove } = useDomainsActions();
  const { data: domainsData } = useDomains();
  const data = useMemo(
    () => mapStoredState(domainsData, (domains) => domains.find((domain) => domain.id === id)),
    [domainsData, id],
  );
  const unavailabilityReason = useMemo(() => {
    if (!data.data) return 'no-data';
    if (data.data.isActive) return 'invalid-status';
    if (!data.data.isApproved) return 'unapproved';
    return undefined;
  }, [data.data]);

  return {
    data,
    remove: {
      ...remove,
      act: useCallback(async () => remove.act(id), [id, remove]),
    },
    activate: {
      ...activate,
      act: useCallback(async () => activate.act(id), [activate, id]),
      available: !unavailabilityReason,
      unavailabilityReason,
    },
    deactivate: {
      ...deactivate,
      act: useCallback(async () => deactivate.act(id), [deactivate, id]),
      available: !!data.data && data.data.isActive,
      // eslint-disable-next-line no-nested-ternary
      unavailabilityReason: !data.data ? 'no-data' : !data.data.isActive ? 'invalid-status' : undefined,
    },
  };
}
