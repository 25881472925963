import { Col, ColorPicker, Row } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { BackgroundColorSelectProps } from './types';
import type { AggregationColor } from 'antd/es/color-picker/color';
import type { PresetsItem } from 'antd/es/color-picker/interface';

const colors: PresetsItem[] = [
  {
    label: <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_PRESETS_LABEL} />,
    colors: ['#000000', '#FFFFFF'],
  },
];

const BackgroundColorSelect: React.FC<BackgroundColorSelectProps> = ({
  'data-test': dataTest,
  value,
  onChange,
  disabled,
}) => {
  const doChange = useCallback((color?: AggregationColor) => onChange?.(color?.toHexString()), [onChange]);
  return (
    <Row gutter={[8, 8]}>
      <Col key="pick">
        <ColorPicker
          data-test={dataTest}
          value={value}
          allowClear
          format="hex"
          presets={colors}
          disabledAlpha
          onChangeComplete={doChange}
          onClear={doChange}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

const BackgroundColorSelectMemo = React.memo(BackgroundColorSelect);

export default BackgroundColorSelectMemo;
