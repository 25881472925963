import { FormattedMessage, OperationRefresh } from '@/components';
import type { PaymentTransaction } from '@/features/payment-transactions/types';
import { usePaymentTransactionsForPayment } from '@/features/payments/hooks';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withPaymentTransactionsLoading = <
  Original extends { data: PaymentTransaction[]; loading: boolean } & TestableProps,
  Wrapper extends Omit<Original, 'data' | 'loading'> & { paymentId: string },
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithPaymentTransactionsLoading',
  )((props) => {
    const dataTest = props['data-test'];
    const data = usePaymentTransactionsForPayment(props.paymentId);
    return withCardDataLoading<PaymentTransaction[], Original, 'loading'>({
      ...data,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPagePayments.PAYMENT_VIEW_COMPONENTS_TRANSACTIONS_CARD_TITLE} />,
      CardProps: {
        extra: (
          <OperationRefresh
            refresh={data.forceRefresh}
            inProgress={data.loading}
            data-test={dataTest && `${dataTest}-refresh`}
            mode="inline"
          />
        ),
      },
    })(Component)(props);
  });

export default withPaymentTransactionsLoading;
