import { shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { flatmapStoredState } from '@/infrastructure/model';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';
import type { LoadingPartialDataState } from '@/infrastructure/model/partial/types';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import type { SingleState } from '@/infrastructure/model/single/types';
import { toMultipleMerged } from '@/infrastructure/model/single/utils';
import type { EmptyObject } from '@/infrastructure/utils/ts';

export const createNormalizedPartialDataSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Value,
  Id = string,
  Key extends string = string,
>(
  getPartialDataState: (state: Global) => LoadingPartialDataState<Key>,
  getSingleState: (state: Global) => SingleState<Value, Key>,
  type: Type,
  mapper: Id extends Key ? undefined : (id: Id) => Key,
) => {
  const singleSelectors = createSingleSelectors<Global, Type, Value, Id, Key>(getSingleState, type, mapper);
  const listSelectors = createLoadingDataSelectors<Global, `${Type}Batch`, Key[]>(getPartialDataState, `${type}Batch`);
  const makeSelectPartialDataName = `makeSelect${type}Batch` as const;
  const makeSelectListData = () => {
    let cache: (LoadingStateWithDirty<Value> | undefined)[] | undefined;
    let cachedList: LoadingPartialDataState<Key> | undefined;
    let result: LoadingPartialDataState<Value> | undefined;
    return createSelector(getPartialDataState, getSingleState, (list, entities): LoadingPartialDataState<Value> => {
      if (result && cachedList === list) {
        if (shallowEqual(list.data?.map((id) => entities[id]) ?? [], cache)) {
          return result;
        }
      }
      cachedList = list;
      cache = list.data?.map((id) => entities[id]) ?? [];
      result = flatmapStoredState(list, (listData) => toMultipleMerged<Value>(entities, listData));
      return result;
    });
  };

  // FIXME: redefine the type without the cast
  return {
    ...listSelectors,
    ...singleSelectors,
    [makeSelectPartialDataName]: makeSelectListData,
  } as Omit<typeof listSelectors, typeof makeSelectPartialDataName> &
    typeof singleSelectors &
    Record<typeof makeSelectPartialDataName, typeof makeSelectListData>;
};
