import React, { useMemo } from 'react';

import BrandingDataView from '@/features/branding/components/BrandingDataView';
import { useCompanySettings } from '@/features/company-settings/hooks';

import type { ActiveProfileContentProps } from './types';

const ActiveProfileContent: React.FC<ActiveProfileContentProps> = ({ 'data-test': dataTest, value }) => {
  const {
    data: { data: settings },
  } = useCompanySettings();
  const data = useMemo(
    () => ({
      ...value.data,
      qr: settings?.qrSupport.filter(({ isEnabled }) => isEnabled).map(({ blockchain }) => blockchain) ?? [],
    }),
    [settings?.qrSupport, value],
  );

  return <BrandingDataView data-test={dataTest} value={data} />;
};

const ActiveProfileContentMemo = React.memo(ActiveProfileContent);

export default ActiveProfileContentMemo;
