import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { addAPIKey } from '@/features/company-settings/actions';
import type { APIKeyFull } from '@/features/company-settings/types';
import { MAX_API_KEYS_PER_NETWORK } from '@/features/company-settings/types';
import { useActionPending } from '@/features/global/hooks';
import type { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';
import { mapStoredState } from '@/infrastructure/model';

import useAPIKeys from '../useAPIKeys';

export type UnavailabilityReason = 'no-data' | 'keys-limit';

export interface UseAPIKeysActionsType {
  addAPIKey: HookAction<[BlockchainNetworkTypeAPIModel], APIKeyFull, UnavailabilityReason>;
}

export default function useAPIKeysActions(network: BlockchainNetworkTypeAPIModel | undefined): UseAPIKeysActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const { loading, data: allData } = useAPIKeys();
  const data = useMemo(
    () => mapStoredState(allData, (keys) => keys.filter((key) => key.network === network)),
    [allData, network],
  );

  const addingAPIKeyPending = useActionPending(addAPIKey);
  const unavailabilityReason = useMemo<UnavailabilityReason | undefined>(() => {
    if (loading || !!data.error) {
      return 'no-data';
    }
    if ((data.data?.length ?? 0) >= MAX_API_KEYS_PER_NETWORK) {
      return 'keys-limit';
    }
    return undefined;
  }, [data.data?.length, data.error, loading]);
  const addAPIKeyAction: UseAPIKeysActionsType['addAPIKey']['act'] = useCallback(
    () => withExtractDataDispatch(addAPIKey)({ network: network! }),
    [network, withExtractDataDispatch],
  );
  const addAPIKeyHook: UseAPIKeysActionsType['addAPIKey'] = useMemo(
    () => ({
      act: addAPIKeyAction,
      available: !unavailabilityReason,
      unavailabilityReason,
      inAction: addingAPIKeyPending,
    }),
    [addAPIKeyAction, unavailabilityReason, addingAPIKeyPending],
  );

  return {
    addAPIKey: addAPIKeyHook,
  };
}
