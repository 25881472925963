import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import BrandingSummaryBreadcrumb from '@/pages/settings/branding/summary/components/BrandingSummaryBreadcrumb';

import MerchantDomainsLink from '../MerchantDomainsLink';

import type { MerchantDomainsBreadcrumbProps } from './types';

const MerchantDomainsBreadcrumb: React.FC<MerchantDomainsBreadcrumbProps> = ({
  'data-test': dataTest,
  items = [],
  disabled,
}) => (
  <BrandingSummaryBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.BRANDING_DOMAINS_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <MerchantDomainsLink
            title={<FormattedMessage id={I18nPageSettings.BRANDING_DOMAINS_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="link"
          />
        ),
      },
      ...items,
    ]}
  />
);

const MerchantDomainsBreadcrumbMemo = React.memo(MerchantDomainsBreadcrumb);

export default MerchantDomainsBreadcrumbMemo;
