import React from 'react';

import { OperationRefresh } from '@/components';
import { useCompanySettings } from '@/features/company-settings/hooks';

import type { OperationRefreshCompanySettingsProps } from './types';

const OperationRefreshCompanySettings: React.FC<OperationRefreshCompanySettingsProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useCompanySettings();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshCompanySettingsMemo = React.memo(OperationRefreshCompanySettings);

export default OperationRefreshCompanySettingsMemo;
