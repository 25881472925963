import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum SettlementsPageRoutes {
  HISTORY = 'history',
  PAYOUTS = 'payouts',
  PENDING_INTENTS = 'intents/pending',
  INTENT_VIEW = 'intents/view/:intentId',
  SCHEDULE = 'schedule',
  ASSET_SCHEDULE = 'schedule/:assetId',
  SETTLEMENT_VIEW = 'view/:settlementId',
}

export enum SettlementPayoutsPageRoutes {
  ALL = '',
  ADD = 'new',
  VIEW = 'view/:payoutId',
  EDIT = 'edit/:payoutId',
  BATCH_VIEW = 'view/:payoutId/batch/:batchNum',
}

export const settlementsRoute = (page: SettlementsPageRoutes) => `${appRoute(AppPageRoutes.SETTLEMENTS)}/${page}`;

export const settlementsHistoryLink = () => settlementsRoute(SettlementsPageRoutes.HISTORY);
export const settlementIntentViewLink = (id: string) => `${appRoute(AppPageRoutes.SETTLEMENTS)}/intents/view/${id}`;
export const settlementsPendingIntentsLink = () => settlementsRoute(SettlementsPageRoutes.PENDING_INTENTS);
export const settlementsScheduleLink = (assetId?: string) =>
  `${settlementsRoute(SettlementsPageRoutes.SCHEDULE)}${assetId ? `/${assetId}` : ''}`;
export const settlementViewLink = (id: string) => `${appRoute(AppPageRoutes.SETTLEMENTS)}/view/${id}`;

export const settlementsPayoutRoutes = (page: SettlementPayoutsPageRoutes) =>
  `${settlementsRoute(SettlementsPageRoutes.PAYOUTS)}${page ? `/${page}` : ''}`;

export const settlementsPayoutsLink = () => settlementsPayoutRoutes(SettlementPayoutsPageRoutes.ALL);
export const settlementsPayoutAddLink = (copyOf?: string) =>
  `${settlementsPayoutRoutes(SettlementPayoutsPageRoutes.ADD)}${copyOf ? `?copy-of=${copyOf}` : ''}`;
export const settlementsPayoutViewLink = (payoutId: string) => `${settlementsPayoutsLink()}/view/${payoutId}`;
export const settlementsPayoutEditLink = (payoutId: string) => `${settlementsPayoutsLink()}/edit/${payoutId}`;

export const settlementsPayoutBatchViewLink = (payoutId: string, batchNum: number) =>
  `${settlementsPayoutViewLink(payoutId)}/batch/${batchNum}`;
