import { ProCard } from '@ant-design/pro-components';
import React from 'react';

import PageLayout from '../PageLayout';

import type { PageCardProps } from './types';

const PageCard = React.forwardRef<HTMLDivElement, PageCardProps>(function PageCard(
  { cardSize, className, ...props },
  ref,
) {
  return (
    <PageLayout ref={ref} pageSize={cardSize}>
      <ProCard type="inner" className={className} boxShadow={cardSize !== 'full'} {...props} />
    </PageLayout>
  );
});

export default PageCard;
