import { Divider, List } from 'antd';
import React from 'react';

import { BooleanSwitch } from '@/components';
import AssetLabel from '@/features/dictionary/blockchain/components/AssetLabel';
import BlockchainLabel from '@/features/dictionary/blockchain/components/BlockchainLabel';

import type { AssetInputListItemProps } from './types';

const AssetInputListItem: React.FC<AssetInputListItemProps> = ({
  'data-test': dataTest,
  data,
  readonly,
  withNetworkMark,
  withBlockchainMark,
  onSelectionChange,
  actions,
  labels,
}) => (
  <List.Item
    actions={
      !readonly
        ? [
            <BooleanSwitch
              key="switch"
              data-test={dataTest && `${dataTest}-switch`}
              value={data.isActive}
              onChange={onSelectionChange}
            />,
          ]
        : actions
    }
  >
    {withBlockchainMark && !readonly && (
      <>
        <BlockchainLabel
          assetId={data.code}
          data-test={dataTest && `${dataTest}-bt`}
          mode="icon"
          withNetworkMark={withNetworkMark}
        />
        <Divider type="vertical" />
      </>
    )}
    <AssetLabel
      value={data}
      data-test={dataTest}
      mode={readonly ? 'long' : 'medium'}
      longLabels={labels}
      withNetworkMark={readonly && withNetworkMark}
      withBlockchainMark={readonly && withBlockchainMark}
    />
  </List.Item>
);

const AssetInputListItemMemo = React.memo(AssetInputListItem);

export default AssetInputListItemMemo;
