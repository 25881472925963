import type { I18nMessages } from '@/generated/i18n/i18n';
import type { InitState } from '@/infrastructure/model';

export const NAMESPACE = 'global';

export type UserAlertPayload = Record<string, unknown> | unknown[] | string | number | undefined | null;

export type BackgroundTaskPayload = UserAlertPayload;

export type Severity = 'success' | 'info' | 'warning' | 'error';

export interface LinkInNotification {
  type: 'link';
  to: string;
}

export interface BaseGlobalNotification {
  severity: Severity;
  duration?: number;
}

export interface GlobalNotificationWithMessageId extends BaseGlobalNotification {
  messageI18n: I18nMessages;
  messageI18nValues?: Record<string, string | ((text: string) => string) | LinkInNotification>;
  descriptionI18n?: I18nMessages;
  descriptionI18nValues?: Record<string, string | ((text: string) => string) | LinkInNotification>;
}

export interface GlobalNotificationWithMessage extends BaseGlobalNotification {
  message: string;
  description: string;
}

export type GlobalNotification = GlobalNotificationWithMessageId | GlobalNotificationWithMessage;
export type GlobalIdentifiedNotification = GlobalNotification & { id: string };

export interface GlobalLoadingState {
  isLoading: boolean;
  messageId?: I18nMessages;
}

export interface GlobalState {
  notifications: GlobalIdentifiedNotification[];
  cookies: InitState<boolean>;
  pending: Partial<Record<string, string[]>>;
  loading: GlobalLoadingState;
  vh: number;

  idle: {
    isIdle: boolean;
    reload: {
      requested: boolean;
      blockedBy: string[];
    };
  };
}
