import React from 'react';

import { MoneyAmount } from '@/components';
import BlockchainLabel from '@/features/dictionary/blockchain/components/BlockchainLabel';

import AssetLabel from '../AssetLabel';

import type { AssetAmountProps } from './types';

const AssetAmount: React.FC<AssetAmountProps> = ({
  'data-test': dataTest,
  className,
  assetId,
  value,
  withCurrency,
  withBlockchainMark,
  currencyMode = 'short',
  noStyle,
  fraction,
}) => (
  <>
    {withBlockchainMark && (
      <>
        <BlockchainLabel assetId={assetId} mode="icon" />
        &nbsp;
      </>
    )}
    <MoneyAmount className={className} value={value} data-test={dataTest} noStyle={noStyle} fraction={fraction} />
    {withCurrency && (
      <>
        &nbsp;
        <AssetLabel
          value={assetId}
          mode={currencyMode === 'short' ? 'short' : 'medium-no-ico'}
          data-test={dataTest && `${dataTest}-currency`}
        />
      </>
    )}
  </>
);

const AssetAmountMemo = React.memo(AssetAmount);

export default AssetAmountMemo;
