import { Spin } from 'antd';
import React, { useMemo } from 'react';

import { BooleanSwitch, FormattedMessage } from '@/components';
import { useCompanySettingsActions } from '@/features/company-settings/hooks';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { useNotification, useSubmitting } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationUpdateCompensationMarkProps } from './types';

const notificationOptions = {
  error: () => (
    <FormattedMessage
      id={
        I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_OPERATION_UPDATE_COMPENSATION_MARK_MESSAGE_ERROR
      }
    />
  ),
  success: () => (
    <FormattedMessage
      id={
        I18nPageSettings.COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_OPERATION_UPDATE_COMPENSATION_MARK_MESSAGE_SUCCESS
      }
    />
  ),
};

const OperationUpdateCompensationMark: React.FC<OperationUpdateCompensationMarkProps> = ({
  'data-test': dataTest,
  disabled,
  value,
}) => {
  const { updateCompensationMark: updateCompensationMarkAction } = useCompanySettingsActions();
  const [submitting, withSubmitting] = useSubmitting(false);
  const { withNotification } = useNotification();

  const updateCompensationMark = useMemo(
    () =>
      withSuppressError(
        withNotification(
          withSubmitting(() => updateCompensationMarkAction.act(!value)),
          notificationOptions,
        ),
      ),
    [withNotification, withSubmitting, updateCompensationMarkAction, value],
  );

  return (
    <Spin spinning={submitting}>
      <BooleanSwitch
        readonly={disabled || submitting}
        data-test={dataTest}
        value={value}
        onChange={updateCompensationMark}
      />
    </Spin>
  );
};

const OperationUpdateCompensationMarkMemo = React.memo(OperationUpdateCompensationMark);

export default OperationUpdateCompensationMarkMemo;
