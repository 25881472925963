// noinspection LongLine

export enum I18nCommon {
  TITLE = 'common.title',
  LOGOUT = 'common.logout',
  MESSAGES_LOADING = 'common.messages.loading',
  MESSAGES_ERRORS_TITLE = 'common.messages.errors.title',
  MESSAGES_ERRORS_DETAILS_SHOW = 'common.messages.errors.details.show',
  MESSAGES_ERRORS_DETAILS_HIDE = 'common.messages.errors.details.hide',
  MESSAGES_ERRORS_RETRY = 'common.messages.errors.retry',
  MESSAGES_MAINTENANCE_TITLE = 'common.messages.maintenance.title',
  MESSAGES_MAINTENANCE_DETAILS = 'common.messages.maintenance.details',
  MESSAGES_NOTIFICATIONS_SUCCESS_TITLE = 'common.messages.notifications.success.title',
  MESSAGES_NOTIFICATIONS_FAILED_TITLE = 'common.messages.notifications.failed.title',
  MESSAGES_NOTIFICATIONS_FAILED_DESCRIPTION = 'common.messages.notifications.failed.description'
}
export enum I18nComponents {
  BOOLEAN_LABEL = 'components.boolean.label',
  CHECKBOX_LABEL = 'components.checkbox.label',
  DURATION_VIEW_DAYS = 'components.duration-view.days',
  DURATION_VIEW_HOURS = 'components.duration-view.hours',
  DURATION_VIEW_MINUTES = 'components.duration-view.minutes',
  DURATION_VIEW_SECONDS = 'components.duration-view.seconds',
  DOCUMENTATION_LINK_TITLE = 'components.documentation-link.title',
  DATE_LABEL = 'components.date.label',
  DATE_PLACEHOLDER = 'components.date.placeholder',
  DATE_REQUIRED = 'components.date.required',
  DATE_TIME_LABEL = 'components.date-time.label',
  DATE_TIME_PLACEHOLDER = 'components.date-time.placeholder',
  DATE_TIME_REQUIRED = 'components.date-time.required',
  DATE_TIME_RANGE_FILTER_FROM_LABEL = 'components.date-time-range-filter.from.label',
  DATE_TIME_RANGE_FILTER_FROM_PLACEHOLDER = 'components.date-time-range-filter.from.placeholder',
  DATE_TIME_RANGE_FILTER_FROM_GTE_TO_ERROR = 'components.date-time-range-filter.from.gte-to.error',
  DATE_TIME_RANGE_FILTER_TO_LABEL = 'components.date-time-range-filter.to.label',
  DATE_TIME_RANGE_FILTER_TO_PLACEHOLDER = 'components.date-time-range-filter.to.placeholder',
  DATE_TIME_RELATIVE_LABEL_VALUE_TODAY = 'components.date-time-relative-label.value.Today',
  DATE_TIME_RELATIVE_LABEL_VALUE_THISWEEK = 'components.date-time-relative-label.value.ThisWeek',
  DATE_TIME_RELATIVE_LABEL_VALUE_THISMONTH = 'components.date-time-relative-label.value.ThisMonth',
  DATE_TIME_RELATIVE_LABEL_VALUE_LASTDAY = 'components.date-time-relative-label.value.LastDay',
  DATE_TIME_RELATIVE_LABEL_VALUE_LASTWEEK = 'components.date-time-relative-label.value.LastWeek',
  DATE_TIME_RELATIVE_LABEL_VALUE_LASTMONTH = 'components.date-time-relative-label.value.LastMonth',
  DATE_TIME_RELATIVE_LABEL_PLACEHOLDER = 'components.date-time-relative-label.placeholder',
  DATA_FETCH_TITLE = 'components.data.fetch.title',
  DATA_FETCH_DESCRIPTION = 'components.data.fetch.description',
  DATA_FETCH_REFRESH = 'components.data.fetch.refresh',
  DATA_FETCH_BACK = 'components.data.fetch.back',
  EMAIL_LABEL = 'components.email.label',
  EMAIL_PLACEHOLDER = 'components.email.placeholder',
  EMAIL_REQUIRED = 'components.email.required',
  EMAIL_WRONG_FORMAT = 'components.email.wrong-format',
  ERROR_PAGE_403 = 'components.error-page.403',
  ERROR_PAGE_404 = 'components.error-page.404',
  ERROR_PAGE_500 = 'components.error-page.500',
  ERROR_PAGE_BACK = 'components.error-page.back',
  FILTER_FORM_FOOTER_BUTTONS_SUBMIT = 'components.filter-form-footer.buttons.submit',
  FILTER_FORM_FOOTER_BUTTONS_RESET = 'components.filter-form-footer.buttons.reset',
  FILTER_FORM_FOOTER_BUTTONS_CANCEL = 'components.filter-form-footer.buttons.cancel',
  FORM_FOOTER_SUBMIT = 'components.form-footer.submit',
  FORM_FOOTER_CANCEL = 'components.form-footer.cancel',
  IFRAME_ERROR_BANNER_TITLE = 'components.iframe.error.banner.title',
  IN_PROGRESS_CANCEL_TITLE = 'components.in-progress.cancel.title',
  LAZY_LOAD_SELECT_PLACEHOLDER = 'components.lazy-load-select.placeholder',
  LAZY_LOAD_SELECT_LOADING = 'components.lazy-load-select.loading',
  LAZY_LOAD_SELECT_ERROR_TEXT = 'components.lazy-load-select.error.text',
  LAZY_LOAD_SELECT_ERROR_RELOAD_TITLE = 'components.lazy-load-select.error.reload.title',
  LAZY_LOAD_SELECT_ERROR_RELOAD_TOOLTIP = 'components.lazy-load-select.error.reload.tooltip',
  MESSAGE_TO_SUPPORT_MESSAGE = 'components.message-to-support.message',
  MONEY_AMOUNT_ITEM_LABEL = 'components.money-amount-item.label',
  MONEY_AMOUNT_ITEM_ERROR_MAX_LIMIT = 'components.money-amount-item.error.max-limit',
  MONEY_AMOUNT_ITEM_ERROR_MIN_LIMIT = 'components.money-amount-item.error.min-limit',
  MONEY_AMOUNT_ITEM_ERROR_TOO_LONG_PRECISION = 'components.money-amount-item.error.too-long-precision',
  MONEY_AMOUNT_ITEM_ERROR_VALUE_INVALID = 'components.money-amount-item.error.value-invalid',
  MONEY_AMOUNT_ITEM_ERROR_VALUE_REQUIRED = 'components.money-amount-item.error.value-required',
  MONEY_AMOUNT_RANGE_FILTER_LTE_LABEL = 'components.money-amount-range-filter.lte.label',
  MONEY_AMOUNT_RANGE_FILTER_GTE_LABEL = 'components.money-amount-range-filter.gte.label',
  MONEY_AMOUNT_RANGE_FILTER_ERRORS_INVALID_RANGE = 'components.money-amount-range-filter.errors.invalid-range',
  OPERATION_CONFIRMATION_OK_LABEL = 'components.operation.confirmation.ok-label',
  OPERATION_CONFIRMATION_CANCEL_LABEL = 'components.operation.confirmation.cancel-label',
  OPERATION_DATE_TIME_RELATIVE_QUICK_FILTER_OPTION_ALL = 'components.operation-date-time-relative-quick-filter.option.All',
  OPERATION_REFRESH_TITLE = 'components.operation-refresh.title',
  OPERATION_REFRESH_TOOLTIP = 'components.operation-refresh.tooltip',
  OPERATION_REFRESH_FAILED_MESSAGE = 'components.operation-refresh.failed-message',
  OPERATION_REFRESH_SUCCESS_MESSAGE = 'components.operation-refresh.success-message',
  PASSWORD_CONFIRM_MESSAGE_DIFFERENT = 'components.password-confirm.message-different',
  PASSWORD_CONFIRM_LABEL = 'components.password-confirm.label',
  PASSWORD_CONFIRM_PLACEHOLDER = 'components.password-confirm.placeholder',
  PASSWORD_LABEL = 'components.password.label',
  PASSWORD_PLACEHOLDER = 'components.password.placeholder',
  PASSWORD_REQUIRED = 'components.password.required',
  PASSWORD_MESSAGE_WEAK = 'components.password.message-weak',
  PAGE_CONTAINER_FOOTER_SUBMIT_TITLE = 'components.page-container.footer.submit.title',
  PAGE_CONTAINER_FOOTER_CANCEL_TITLE = 'components.page-container.footer.cancel.title',
  TABLE_FILTER_TITLE = 'components.table.filter.title',
  TABLE_FILTER_APPLY_TITLE = 'components.table.filter.apply.title',
  TABLE_FILTER_RESET_TITLE = 'components.table.filter.reset.title',
  TABLE_DATA_FETCH_CLEAN_RELOAD_TITLE = 'components.table.data-fetch.clean-reload.title',
  TABLE_LINK_DETAILS = 'components.table.link.details',
  TABLE_PAGINATION_MAX_PAGE = 'components.table.pagination.max-page',
  TABLE_ROW_OPTIONS = 'components.table.row.options',
  WEB_URL_LABEL = 'components.web-url.label',
  WEB_URL_PLACEHOLDER = 'components.web-url.placeholder',
  WEB_URL_ERROR_REQUIRED = 'components.web-url.error-required',
  WEB_URL_ERROR_INVALID = 'components.web-url.error-invalid'
}
export enum I18nHocs {
  WALLET_OWNERSHIP_TITLE = 'hocs.wallet-ownership.title',
  WALLET_OWNERSHIP_DESCRIPTION = 'hocs.wallet-ownership.description',
  WALLET_OWNERSHIP_ACCOUNT_TOOLTIP = 'hocs.wallet-ownership.account-tooltip',
  WALLET_OWNERSHIP_MESSAGE_SUCCESS = 'hocs.wallet-ownership.message-success',
  WALLET_OWNERSHIP_MESSAGE_GENERIC_ERROR = 'hocs.wallet-ownership.message-generic-error',
  WALLET_OWNERSHIP_CREATE_SUBMIT_TITLE = 'hocs.wallet-ownership.create.submit-title',
  WALLET_OWNERSHIP_CREATE_CANCEL_TITLE = 'hocs.wallet-ownership.create.cancel-title'
}
export enum I18nFeatureAuth {
  COMPONENTS_EMAIL_ENTER_FORM_CANCEL = 'feature.auth.components.email-enter-form.cancel',
  COMPONENTS_EMAIL_ENTER_FORM_SUBMIT = 'feature.auth.components.email-enter-form.submit',
  COMPONENTS_EMAIL_ENTER_FORM_DESCRIPTION = 'feature.auth.components.email-enter-form.description',
  COMPONENTS_GOOGLE_IN_PROGRESS_MESSAGE = 'feature.auth.components.google-in-progress.message',
  COMPONENTS_TERMS_OF_SERVICE_DIALOG_TITLE = 'feature.auth.components.terms-of-service.dialog-title',
  COMPONENTS_TERMS_OF_SERVICE_CHECKBOX = 'feature.auth.components.terms-of-service.checkbox',
  COMPONENTS_TERMS_OF_SERVICE_SUBMIT_TITLE = 'feature.auth.components.terms-of-service.submit-title',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_TITLE = 'feature.auth.components.operation-disconnect-web3auth.title',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_TOOLTIP = 'feature.auth.components.operation-disconnect-web3auth.tooltip',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_TITLE = 'feature.auth.components.operation-disconnect-web3auth.connector.title',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_FAILED_MESSAGE = 'feature.auth.components.operation-disconnect-web3auth.connector.failed-message',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_FAILED_DESCRIPTION = 'feature.auth.components.operation-disconnect-web3auth.connector.failed-description',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_SUCCESS_MESSAGE = 'feature.auth.components.operation-disconnect-web3auth.connector.success-message',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_SUCCESS_DESCRIPTION = 'feature.auth.components.operation-disconnect-web3auth.connector.success-description',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_DIALOG_TITLE = 'feature.auth.components.operation-disconnect-web3auth.dialog.title',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_DIALOG_DESCRIPTION = 'feature.auth.components.operation-disconnect-web3auth.dialog.description',
  COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_DIALOG_PRIVATE_KEY = 'feature.auth.components.operation-disconnect-web3auth.dialog.private-key',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_BANNER_TITLE = 'feature.auth.components.web3auth-account-reset.banner-title',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_BANNER_DESCRIPTION = 'feature.auth.components.web3auth-account-reset.banner-description',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_PREPARE_MESSAGE = 'feature.auth.components.web3auth-account-reset.prepare-message',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_RESET_MESSAGE = 'feature.auth.components.web3auth-account-reset.reset-message',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_CONFIRMATION = 'feature.auth.components.web3auth-account-reset.confirmation',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_SUBMIT_TITLE = 'feature.auth.components.web3auth-account-reset.submit-title',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_CANCEL_TITLE = 'feature.auth.components.web3auth-account-reset.cancel-title',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_SUCCESS_MESSAGE = 'feature.auth.components.web3auth-account-reset.success-message',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_SUCCESS_DESCRIPTION = 'feature.auth.components.web3auth-account-reset.success-description',
  COMPONENTS_WEB3AUTH_ACCOUNT_RESET_ERROR_MESSAGE = 'feature.auth.components.web3auth-account-reset.error-message',
  COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_MESSAGE = 'feature.auth.components.web3auth-private-key-secure.message',
  COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_CONFIRMATION = 'feature.auth.components.web3auth-private-key-secure.confirmation',
  COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_SUBMIT_TITLE = 'feature.auth.components.web3auth-private-key-secure.submit-title',
  COMPONENTS_WEB3AUTH_PRIVATE_KEY_SECURE_CANCEL_TITLE = 'feature.auth.components.web3auth-private-key-secure.cancel-title',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_MESSAGE = 'feature.auth.components.web3auth-recovery-key-enter.message',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_DROPZONE = 'feature.auth.components.web3auth-recovery-key-enter.dropzone',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_INVALID = 'feature.auth.components.web3auth-recovery-key-enter.invalid',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_ENTER_RESET_MESSAGE = 'feature.auth.components.web3auth-recovery-key-enter.reset-message',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_TITLE = 'feature.auth.components.web3auth-recovery-key-secure.title',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_PREPARE_MESSAGE = 'feature.auth.components.web3auth-recovery-key-secure.prepare-message',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_CONFIRMATION_MESSAGE = 'feature.auth.components.web3auth-recovery-key-secure.confirmation-message',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_MANUAL_MESSAGE = 'feature.auth.components.web3auth-recovery-key-secure.manual-message',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_CONFIRMATION = 'feature.auth.components.web3auth-recovery-key-secure.confirmation',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_SUBMIT_TITLE = 'feature.auth.components.web3auth-recovery-key-secure.submit-title',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_CANCEL_TITLE = 'feature.auth.components.web3auth-recovery-key-secure.cancel-title',
  COMPONENTS_WEB3AUTH_RECOVERY_KEY_SECURE_ERROR_MESSAGE = 'feature.auth.components.web3auth-recovery-key-secure.error-message',
  COMPONENTS_WALLET_LOGIN_LOGIN_TITLE = 'feature.auth.components.wallet-login.login.title',
  COMPONENTS_WALLET_LOGIN_SIGNUP_TITLE = 'feature.auth.components.wallet-login.signup.title',
  COMPONENTS_WALLET_LOGIN_DESCRIPTION = 'feature.auth.components.wallet-login.description',
  COMPONENTS_WALLET_LOGIN_ACCOUNT_TOOLTIP = 'feature.auth.components.wallet-login.account.tooltip',
  COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_TITLE = 'feature.auth.components.wallet-login.error.connect-in-progress.title',
  COMPONENTS_WALLET_LOGIN_ERROR_CONNECT_IN_PROGRESS_DESCRIPTION = 'feature.auth.components.wallet-login.error.connect-in-progress.description',
  HOOKS_ACTIONS_SIGNUP_MESSAGE = 'feature.auth.hooks.actions.signup.message',
  HOOKS_ACTIONS_LOGIN_MESSAGE = 'feature.auth.hooks.actions.login.message',
  HOOKS_ACTIONS_LOGOUT_CONFIRMATION_OK_TITLE = 'feature.auth.hooks.actions.logout.confirmation.ok.title',
  HOOKS_ACTIONS_LOGOUT_CONFIRMATION_CANCEL_TITLE = 'feature.auth.hooks.actions.logout.confirmation.cancel.title',
  HOOKS_ACTIONS_LOGOUT_CONFIRMATION_TITLE = 'feature.auth.hooks.actions.logout.confirmation.title',
  HOOKS_ACTIONS_LOGOUT_CONFIRMATION_CONTENT = 'feature.auth.hooks.actions.logout.confirmation.content',
  HOOKS_ACTIONS_WEB3AUTH_DISCONNECT_MESSAGE = 'feature.auth.hooks.actions.web3auth-disconnect.message',
  HOOKS_WEB3AUTH_LIFECYCLE_PRIVATE_KEY_TITLE = 'feature.auth.hooks.web3auth-lifecycle.private-key.title'
}
export enum I18nFeatureBranding {
  COMPONENTS_AUTH_SELECT_PLACEHOLDER = 'feature.branding.components.auth-select.placeholder',
  COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_TITLE = 'feature.branding.components.data-edit-panel.auth-block.title',
  COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_HELP = 'feature.branding.components.data-edit-panel.auth-block.help',
  COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_SUPPORTED_QR_PLACEHOLDER = 'feature.branding.components.data-edit-panel.auth-block.supported-qr.placeholder',
  COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_SUPPORTED_QR_LABEL = 'feature.branding.components.data-edit-panel.auth-block.supported-qr.label',
  COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_SUPPORTED_QR_HELP = 'feature.branding.components.data-edit-panel.auth-block.supported-qr.help',
  COMPONENTS_DATA_EDIT_PANEL_AUTH_BLOCK_AUTH_ITEM_ERRORS_REQUIRED = 'feature.branding.components.data-edit-panel.auth-block.auth-item.errors.required',
  COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_TITLE = 'feature.branding.components.data-edit-panel.colors-block.title',
  COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_HELP = 'feature.branding.components.data-edit-panel.colors-block.help',
  COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_BG_ITEM_LABEL = 'feature.branding.components.data-edit-panel.colors-block.bg-item.label',
  COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_ACCENT_ITEM_LABEL = 'feature.branding.components.data-edit-panel.colors-block.accent-item.label',
  COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_PRESETS_LABEL = 'feature.branding.components.data-edit-panel.colors-block.presets.label',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_TITLE = 'feature.branding.components.data-edit-panel.contacts-block.title',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_HELP = 'feature.branding.components.data-edit-panel.contacts-block.help',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_EMAIL_ITEM_PLACEHOLDER = 'feature.branding.components.data-edit-panel.contacts-block.email-item.placeholder',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_EMAIL_ITEM_HELP = 'feature.branding.components.data-edit-panel.contacts-block.email-item.help',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_ITEM_PLACEHOLDER = 'feature.branding.components.data-edit-panel.contacts-block.redirect-item.placeholder',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_ITEM_HELP = 'feature.branding.components.data-edit-panel.contacts-block.redirect-item.help',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_PARAM_ITEM_PLACEHOLDER = 'feature.branding.components.data-edit-panel.contacts-block.redirect-param-item.placeholder',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_PARAM_ITEM_HELP = 'feature.branding.components.data-edit-panel.contacts-block.redirect-param-item.help',
  COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_PARAM_ITEM_ERRORS_INVALID_FORMAT = 'feature.branding.components.data-edit-panel.contacts-block.redirect-param-item.errors.invalid-format',
  COMPONENTS_DATA_EDIT_PANEL_LANG_BLOCK_TITLE = 'feature.branding.components.data-edit-panel.lang-block.title',
  COMPONENTS_DATA_EDIT_PANEL_LANG_BLOCK_HELP = 'feature.branding.components.data-edit-panel.lang-block.help',
  COMPONENTS_DATA_EDIT_PANEL_LANG_BLOCK_LANG_ITEM_ERRORS_REQUIRED = 'feature.branding.components.data-edit-panel.lang-block.lang-item.errors.required',
  COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_TITLE = 'feature.branding.components.data-edit-panel.logo-block.title',
  COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_HELP = 'feature.branding.components.data-edit-panel.logo-block.help',
  COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_POSITION_ITEM_LABEL = 'feature.branding.components.data-edit-panel.logo-block.position-item.label',
  COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_URL_ITEM_PLACEHOLDER = 'feature.branding.components.data-edit-panel.logo-block.url-item.placeholder',
  COMPONENTS_DATA_EDIT_PANEL_LOGO_BLOCK_URL_ITEM_REQUIRED = 'feature.branding.components.data-edit-panel.logo-block.url-item.required',
  COMPONENTS_DATA_EDIT_PANEL_ON_RAMP_BLOCK_TITLE = 'feature.branding.components.data-edit-panel.on-ramp-block.title',
  COMPONENTS_DATA_EDIT_PANEL_ON_RAMP_BLOCK_HELP = 'feature.branding.components.data-edit-panel.on-ramp-block.help',
  COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_TITLE = 'feature.branding.components.data-edit-panel.powered-by-block.title',
  COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_HELP = 'feature.branding.components.data-edit-panel.powered-by-block.help',
  COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_CUSTOM_ENABLED_ITEM_LABEL = 'feature.branding.components.data-edit-panel.powered-by-block.custom-enabled-item.label',
  COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_CUSTOM_ENABLED_ITEM_HELP = 'feature.branding.components.data-edit-panel.powered-by-block.custom-enabled-item.help',
  COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_TEXT_ITEM_PLACEHOLDER = 'feature.branding.components.data-edit-panel.powered-by-block.text-item.placeholder',
  COMPONENTS_DATA_EDIT_PANEL_POWERED_BY_BLOCK_TEXT_ITEM_ERROR_REQUIRED = 'feature.branding.components.data-edit-panel.powered-by-block.text-item.error.required',
  COMPONENTS_DATA_VIEW_COLORS_ACCENT_LABEL = 'feature.branding.components.data-view.colors.accent.label',
  COMPONENTS_DATA_VIEW_COLORS_BG_LABEL = 'feature.branding.components.data-view.colors.bg.label',
  COMPONENTS_DATA_VIEW_AUTH_LABEL = 'feature.branding.components.data-view.auth.label',
  COMPONENTS_DATA_VIEW_QR_LABEL = 'feature.branding.components.data-view.qr.label',
  COMPONENTS_DATA_VIEW_I18N_LABEL = 'feature.branding.components.data-view.i18n.label',
  COMPONENTS_DATA_VIEW_ON_RAMPS_LABEL = 'feature.branding.components.data-view.on-ramps.label',
  COMPONENTS_DATA_VIEW_CONTACT_LABEL = 'feature.branding.components.data-view.contact.label',
  COMPONENTS_DATA_VIEW_REDIRECT_LABEL = 'feature.branding.components.data-view.redirect.label',
  COMPONENTS_DATA_VIEW_LOGO_LABEL = 'feature.branding.components.data-view.logo.label',
  COMPONENTS_DATA_VIEW_POWERED_BY_LABEL = 'feature.branding.components.data-view.powered-by.label',
  COMPONENTS_DATA_VIEW_POWERED_BY_DEFAULT_LABEL = 'feature.branding.components.data-view.powered-by.default.label',
  COMPONENTS_DOMAIN_FORM_CREATE_SUBMIT = 'feature.branding.components.domain-form.create-submit',
  COMPONENTS_DOMAIN_FORM_UPDATE_SUBMIT = 'feature.branding.components.domain-form.update-submit',
  COMPONENTS_DOMAIN_FORM_URL_PLACEHOLDER = 'feature.branding.components.domain-form.url.placeholder',
  COMPONENTS_DOMAIN_FORM_URL_REQUIRED = 'feature.branding.components.domain-form.url.required',
  COMPONENTS_DOMAIN_FORM_URL_DUPLICATE = 'feature.branding.components.domain-form.url.duplicate',
  COMPONENTS_DOMAIN_FORM_ERROR_COMMON = 'feature.branding.components.domain-form.error.common',
  COMPONENTS_LANG_SELECT_PLACEHOLDER = 'feature.branding.components.lang-select.placeholder',
  COMPONENTS_LOGO_POSITIONS_SWITCH_LABELS_BOTTOM = 'feature.branding.components.logo-positions-switch.labels.bottom',
  COMPONENTS_LOGO_POSITIONS_SWITCH_LABELS_TOP = 'feature.branding.components.logo-positions-switch.labels.top',
  COMPONENTS_MERCHANT_DOMAIN_SELECT_EMPTY = 'feature.branding.components.merchant-domain-select.empty',
  COMPONENTS_MERCHANT_DOMAIN_SELECT_PLACEHOLDER = 'feature.branding.components.merchant-domain-select.placeholder',
  COMPONENTS_ON_RAMP_SELECT_PLACEHOLDER = 'feature.branding.components.on-ramp-select.placeholder',
  COMPONENTS_OPERATION_ADD_DOMAIN_FORM_TITLE = 'feature.branding.components.operation-add-domain.form.title',
  COMPONENTS_OPERATION_ADD_DOMAIN_MESSAGE_SUCCESS = 'feature.branding.components.operation-add-domain.message-success',
  COMPONENTS_OPERATION_ADD_DOMAIN_MESSAGE_ERROR = 'feature.branding.components.operation-add-domain.message-error',
  COMPONENTS_OPERATION_ADD_DOMAIN_TITLE = 'feature.branding.components.operation-add-domain.title',
  COMPONENTS_OPERATION_ADD_DOMAIN_ERROR_TOO_MANY = 'feature.branding.components.operation-add-domain.error.too-many',
  COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_ACTIVATE_TITLE = 'feature.branding.components.operation-update-domain-status.activate-title',
  COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_DEACTIVATE_TITLE = 'feature.branding.components.operation-update-domain-status.deactivate-title',
  COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_TOOLTIP = 'feature.branding.components.operation-update-domain-status.tooltip',
  COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_DISABLED_MESSAGE_UNAPPROVED = 'feature.branding.components.operation-update-domain-status.disabled-message.unapproved',
  COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_ERROR_MESSAGE = 'feature.branding.components.operation-update-domain-status.error-message',
  COMPONENTS_OPERATION_UPDATE_DOMAIN_STATUS_SUCCESS_MESSAGE = 'feature.branding.components.operation-update-domain-status.success-message',
  COMPONENTS_OPERATION_DELETE_DOMAIN_TITLE = 'feature.branding.components.operation-delete-domain.title',
  COMPONENTS_OPERATION_DELETE_DOMAIN_CONFIRMATION_TITLE = 'feature.branding.components.operation-delete-domain.confirmation-title',
  COMPONENTS_OPERATION_DELETE_DOMAIN_ERROR_MESSAGE = 'feature.branding.components.operation-delete-domain.error-message',
  COMPONENTS_OPERATION_DELETE_DOMAIN_SUCCESS_MESSAGE = 'feature.branding.components.operation-delete-domain.success-message',
  COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ACTIVATE_TITLE = 'feature.branding.components.operation-update-profile-status.activate-title',
  COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_DEACTIVATE_TITLE = 'feature.branding.components.operation-update-profile-status.deactivate-title',
  COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ACTIVATE_MESSAGE_SUCCESS = 'feature.branding.components.operation-update-profile-status.activate.message-success',
  COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_DEACTIVATE_MESSAGE_SUCCESS = 'feature.branding.components.operation-update-profile-status.deactivate.message-success',
  COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ERROR_NO_DATA = 'feature.branding.components.operation-update-profile-status.error.no-data',
  COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ERROR_ALREADY_ACTIVE = 'feature.branding.components.operation-update-profile-status.error.already-active',
  COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ERROR_ALREADY_INACTIVE = 'feature.branding.components.operation-update-profile-status.error.already-inactive',
  COMPONENTS_OPERATION_REMOVE_PROFILE_TITLE = 'feature.branding.components.operation-remove-profile.title',
  COMPONENTS_OPERATION_REMOVE_PROFILE_TOOLTIP = 'feature.branding.components.operation-remove-profile.tooltip',
  COMPONENTS_OPERATION_REMOVE_PROFILE_ERROR_NO_DATA = 'feature.branding.components.operation-remove-profile.error.no-data',
  COMPONENTS_OPERATION_REMOVE_PROFILE_SUCCESS_MESSAGE = 'feature.branding.components.operation-remove-profile.success-message',
  COMPONENTS_OPERATION_REMOVE_PROFILE_CONFIRMATION_ACTIVE = 'feature.branding.components.operation-remove-profile.confirmation.active',
  COMPONENTS_OPERATION_REMOVE_PROFILE_CONFIRMATION_INACTIVE = 'feature.branding.components.operation-remove-profile.confirmation.inactive',
  COMPONENTS_OPERATION_DELETE_TOS_TITLE = 'feature.branding.components.operation-delete-tos.title',
  COMPONENTS_OPERATION_DELETE_TOS_CONFIRMATION_TITLE = 'feature.branding.components.operation-delete-tos.confirmation-title',
  COMPONENTS_OPERATION_DELETE_TOS_ERROR_MESSAGE = 'feature.branding.components.operation-delete-tos.error-message',
  COMPONENTS_OPERATION_DELETE_TOS_SUCCESS_MESSAGE = 'feature.branding.components.operation-delete-tos.success-message',
  COMPONENTS_OPERATION_DELETE_TOS_ERROR_NO_DATA = 'feature.branding.components.operation-delete-tos.error.no-data',
  COMPONENTS_PROFILE_FORM_HELP = 'feature.branding.components.profile-form.help',
  COMPONENTS_PROFILE_FORM_ERROR_COMMON = 'feature.branding.components.profile-form.error.common',
  COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_PLACEHOLDER = 'feature.branding.components.profile-form.name-edit-panel.name-item.placeholder',
  COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_ERROR_REQUIRED = 'feature.branding.components.profile-form.name-edit-panel.name-item.error-required',
  COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_ERROR_MIN = 'feature.branding.components.profile-form.name-edit-panel.name-item.error-min',
  COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_NAME_ITEM_ERROR_MAX = 'feature.branding.components.profile-form.name-edit-panel.name-item.error-max',
  COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_SUBMIT_TITLE = 'feature.branding.components.profile-form.name-edit-panel.submit-title',
  COMPONENTS_PROFILE_FORM_NAME_EDIT_PANEL_RESET_TITLE = 'feature.branding.components.profile-form.name-edit-panel.reset-title',
  COMPONENTS_PROFILE_PREVIEW_UPDATING = 'feature.branding.components.profile-preview.updating',
  COMPONENTS_PROFILE_SELECT_EMPTY = 'feature.branding.components.profile-select.empty',
  COMPONENTS_PROFILE_SELECT_PLACEHOLDER = 'feature.branding.components.profile-select.placeholder',
  COMPONENTS_PROFILE_VIEW_EMPTY = 'feature.branding.components.profile-view.empty',
  COMPONENTS_PROFILE_VIEW_PROFILE_SELECT_PANEL_COPY_TITLE = 'feature.branding.components.profile-view.profile-select-panel.copy-title',
  COMPONENTS_PROFILE_VIEW_PROFILE_SELECT_PANEL_EDIT_TITLE = 'feature.branding.components.profile-view.profile-select-panel.edit-title',
  COMPONENTS_TOS_FORM_EDIT_FORM_ERROR_COMMON = 'feature.branding.components.tos-form.edit-form.error-common',
  COMPONENTS_TOS_FORM_EDIT_FORM_HELP = 'feature.branding.components.tos-form.edit-form.help',
  COMPONENTS_TOS_FORM_EDIT_FORM_TEXT_ITEM_LABEL = 'feature.branding.components.tos-form.edit-form.text-item.label',
  COMPONENTS_TOS_FORM_EDIT_FORM_TEXT_ITEM_PLACEHOLDER = 'feature.branding.components.tos-form.edit-form.text-item.placeholder',
  COMPONENTS_TOS_FORM_EDIT_FORM_TEXT_ITEM_VALIDATION_REQUIRED = 'feature.branding.components.tos-form.edit-form.text-item.validation.required',
  COMPONENTS_TOS_FORM_DEFINE_SUCCESS_MESSAGE = 'feature.branding.components.tos-form.define.success-message'
}
export enum I18nFeatureCollectable {
  COMPONENTS_COLLECTABLE_KIND_VIEW_DONATION = 'feature.collectable.components.collectable-kind-view.donation',
  COMPONENTS_COLLECTABLE_KIND_VIEW_RECHARGE = 'feature.collectable.components.collectable-kind-view.recharge',
  COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_NOTDEPLOYED = 'feature.collectable.components.collectable-contract-deploy-status-view.NotDeployed',
  COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_DEPLOYING = 'feature.collectable.components.collectable-contract-deploy-status-view.Deploying',
  COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_DEPLOYED = 'feature.collectable.components.collectable-contract-deploy-status-view.Deployed',
  COMPONENTS_COLLECTABLE_CONTRACT_DEPLOY_STATUS_VIEW_FAILED = 'feature.collectable.components.collectable-contract-deploy-status-view.Failed',
  COMPONENTS_COLLECT_SCHEDULE_PERIOD_LABEL_NOT_SET = 'feature.collectable.components.collect-schedule-period-label.not-set',
  COMPONENTS_COLLECT_SCHEDULE_PERIOD_LABEL_LAST_DAY_OF_MONTH = 'feature.collectable.components.collect-schedule-period-label.last-day-of-month',
  COMPONENTS_COLLECT_SCHEDULE_PERIOD_LABEL_LAST_DAY_OF_WEEK = 'feature.collectable.components.collect-schedule-period-label.last-day-of-week',
  COMPONENTS_COLLECT_SCHEDULE_PERIOD_ITEM_LABEL = 'feature.collectable.components.collect-schedule-period-item.label',
  COMPONENTS_COLLECT_SCHEDULE_PERIOD_ITEM_PLACEHOLDER = 'feature.collectable.components.collect-schedule-period-item.placeholder',
  COMPONENTS_COLLECT_SCHEDULE_PERIOD_ITEM_REQUIRED = 'feature.collectable.components.collect-schedule-period-item.required',
  COMPONENTS_COLLECT_SCHEDULE_VIEW_NOT_SET = 'feature.collectable.components.collect-schedule-view.not-set',
  COMPONENTS_COLLECT_SCHEDULE_VIEW_DEFINED = 'feature.collectable.components.collect-schedule-view.defined',
  COMPONENTS_COLLECT_TASK_ADDRESSES_LIST_DESCRIPTION_COLLECTED = 'feature.collectable.components.collect-task-addresses-list.description.collected',
  COMPONENTS_COLLECT_TASK_ADDRESSES_LIST_DESCRIPTION_NOT_COLLECTED = 'feature.collectable.components.collect-task-addresses-list.description.not-collected',
  COMPONENTS_COLLECT_TASK_TABLE_DIALOG_TITLE = 'feature.collectable.components.collect-task-table.dialog.title',
  COMPONENTS_COLLECT_TASK_FILTER_FORM_PUSH_ADDRESS_LABEL = 'feature.collectable.components.collect-task-filter-form.push-address.label',
  COMPONENTS_COLLECT_TASK_FILTER_FORM_PUSH_ADDRESS_PLACEHOLDER = 'feature.collectable.components.collect-task-filter-form.push-address.placeholder',
  COMPONENTS_COLLECT_TASK_FILTER_FORM_PROCESS_AT_FROM_LABEL = 'feature.collectable.components.collect-task-filter-form.process-at.from.label',
  COMPONENTS_COLLECT_TASK_FILTER_FORM_PROCESS_AT_TO_LABEL = 'feature.collectable.components.collect-task-filter-form.process-at.to.label',
  COMPONENTS_COLLECT_TASK_FILTER_FORM_STATUS_LABEL = 'feature.collectable.components.collect-task-filter-form.status.label',
  COMPONENTS_COLLECT_TASK_FILTER_FORM_STATUS_PLACEHOLDER = 'feature.collectable.components.collect-task-filter-form.status.placeholder',
  COMPONENTS_COLLECT_TASK_STATUS_VIEW_AWAITING = 'feature.collectable.components.collect-task-status-view.Awaiting',
  COMPONENTS_COLLECT_TASK_STATUS_VIEW_PENDING = 'feature.collectable.components.collect-task-status-view.Pending',
  COMPONENTS_COLLECT_TASK_STATUS_VIEW_SUCCEEDED = 'feature.collectable.components.collect-task-status-view.Succeeded',
  COMPONENTS_COLLECT_TASK_STATUS_VIEW_FAILED = 'feature.collectable.components.collect-task-status-view.Failed',
  COMPONENTS_COLLECT_TASK_STATUS_BANNER_PENDING_TAG = 'feature.collectable.components.collect-task-status-banner.pending.tag',
  COMPONENTS_COLLECT_TASK_STATUS_BANNER_PENDING_TITLE = 'feature.collectable.components.collect-task-status-banner.pending.title',
  COMPONENTS_COLLECT_TASK_STATUS_BANNER_PENDING_DESCRIPTION = 'feature.collectable.components.collect-task-status-banner.pending.description',
  COMPONENTS_COLLECT_TASK_STATUS_BANNER_STALE_TAG = 'feature.collectable.components.collect-task-status-banner.stale.tag',
  COMPONENTS_COLLECT_TASK_STATUS_BANNER_STALE_TITLE = 'feature.collectable.components.collect-task-status-banner.stale.title',
  COMPONENTS_COLLECT_TASK_STATUS_BANNER_STALE_DESCRIPTION = 'feature.collectable.components.collect-task-status-banner.stale.description',
  COMPONENTS_OPERATION_COLLECT_NOW_TITLE = 'feature.collectable.components.operation-collect-now.title',
  COMPONENTS_OPERATION_COLLECT_NOW_TOOLTIP = 'feature.collectable.components.operation-collect-now.tooltip',
  COMPONENTS_OPERATION_COLLECT_NOW_DISABLED_LOADING = 'feature.collectable.components.operation-collect-now.disabled.loading',
  COMPONENTS_OPERATION_COLLECT_NOW_DISABLED_EMPTY_BALANCE = 'feature.collectable.components.operation-collect-now.disabled.empty-balance',
  COMPONENTS_OPERATION_COLLECT_NOW_SUCCESS_MESSAGE = 'feature.collectable.components.operation-collect-now.success-message',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_TITLE = 'feature.collectable.components.operation-cancel-schedule.title',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_TOOLTIP = 'feature.collectable.components.operation-cancel-schedule.tooltip',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_FAILED_MESSAGE = 'feature.collectable.components.operation-cancel-schedule.failed-message',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_SUCCESS_MESSAGE = 'feature.collectable.components.operation-cancel-schedule.success-message',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_DISABLED_NO_DATA = 'feature.collectable.components.operation-cancel-schedule.disabled.no-data',
  COMPONENTS_OPERATION_SET_SCHEDULE_TITLE = 'feature.collectable.components.operation-set-schedule.title',
  COMPONENTS_OPERATION_SET_SCHEDULE_TOOLTIP = 'feature.collectable.components.operation-set-schedule.tooltip',
  COMPONENTS_OPERATION_SET_SCHEDULE_FAILED_MESSAGE = 'feature.collectable.components.operation-set-schedule.failed-message',
  COMPONENTS_OPERATION_SET_SCHEDULE_SUCCESS_MESSAGE = 'feature.collectable.components.operation-set-schedule.success-message',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_TITLE = 'feature.collectable.components.operation-set-schedule.form.title',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_SUBMIT = 'feature.collectable.components.operation-set-schedule.form.submit',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_ERROR_COMMON = 'feature.collectable.components.operation-set-schedule.form.error.common',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_LABEL = 'feature.collectable.components.operation-set-schedule.form.amount.label',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_MIN = 'feature.collectable.components.operation-set-schedule.form.amount.min',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_PLACEHOLDER = 'feature.collectable.components.operation-set-schedule.form.amount.placeholder',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_REQUIRED = 'feature.collectable.components.operation-set-schedule.form.amount.required',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_HELP = 'feature.collectable.components.operation-set-schedule.form.amount.help',
  COMPONENTS_OPERATION_REFRESH_SCHEDULE_TOOLTIP = 'feature.collectable.components.operation-refresh-schedule.tooltip',
  COMPONENTS_OPERATION_REFRESH_SCHEDULE_MESSAGE_SUCCESS = 'feature.collectable.components.operation-refresh-schedule.message-success',
  COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_TOOLTIP = 'feature.collectable.components.operation-refresh-collect-task.tooltip',
  COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_MESSAGE_SUCCESS = 'feature.collectable.components.operation-refresh-collect-task.message-success',
  COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_TRANSACTION_TOOLTIP = 'feature.collectable.components.operation-refresh-collect-task-transaction.tooltip',
  COMPONENTS_OPERATION_REFRESH_COLLECT_TASK_TRANSACTION_MESSAGE_SUCCESS = 'feature.collectable.components.operation-refresh-collect-task-transaction.message-success',
  LABELS_PROCESS_TX_ROW_TITLE_ASSIGNED_WALLET = 'feature.collectable.labels.process-tx.row.title.assigned-wallet',
  LABELS_PROCESS_TX_ROW_TITLE_FAIL_COUNT = 'feature.collectable.labels.process-tx.row.title.fail-count',
  LABELS_PROCESS_TX_ROW_TITLE_SENT_AT = 'feature.collectable.labels.process-tx.row.title.sent-at',
  LABELS_PROCESS_TX_ROW_TITLE_HASH = 'feature.collectable.labels.process-tx.row.title.hash',
  LABELS_PROCESS_TX_ROW_TITLE_BLOCK_NUM = 'feature.collectable.labels.process-tx.row.title.block-num',
  LABELS_PROCESS_TX_ROW_TITLE_ERROR_REASON = 'feature.collectable.labels.process-tx.row.title.error-reason',
  LABELS_TX_ROW_TITLE_ID = 'feature.collectable.labels.tx.row.title.id',
  LABELS_TX_ROW_TITLE_HASH = 'feature.collectable.labels.tx.row.title.hash',
  LABELS_TX_ROW_TITLE_FROM_ADDRESS = 'feature.collectable.labels.tx.row.title.from-address',
  LABELS_TX_ROW_TITLE_AMOUNT = 'feature.collectable.labels.tx.row.title.amount',
  LABELS_TX_ROW_TITLE_CREATED_AT = 'feature.collectable.labels.tx.row.title.created-at',
  LABELS_TX_ROW_TITLE_BROADCAST_AT = 'feature.collectable.labels.tx.row.title.broadcast-at',
  LABELS_TASK_ROW_TITLE_ID = 'feature.collectable.labels.task.row.title.id',
  LABELS_TASK_ROW_TITLE_KIND = 'feature.collectable.labels.task.row.title.kind',
  LABELS_TASK_ROW_TITLE_ASSET = 'feature.collectable.labels.task.row.title.asset',
  LABELS_TASK_ROW_TITLE_PROCESS_AT = 'feature.collectable.labels.task.row.title.process-at',
  LABELS_TASK_ROW_TITLE_STATUS = 'feature.collectable.labels.task.row.title.status',
  LABELS_TASK_ROW_TITLE_COLLECTED_AMOUNT = 'feature.collectable.labels.task.row.title.collected-amount'
}
export enum I18nFeatureCompany {
  COMPONENTS_COMPANIES_LIST_OWNER_TAG = 'feature.company.components.companies-list.owner.tag',
  COMPONENTS_COMPANY_ITEM_SELECT_LABEL = 'feature.company.components.company-item.select.label',
  COMPONENTS_COMPANY_ITEM_SELECT_PLACEHOLDER = 'feature.company.components.company-item.select.placeholder',
  COMPONENTS_COMPANY_ITEM_SELECT_REQUIRED = 'feature.company.components.company-item.select.required',
  COMPONENTS_COMPANY_ITEM_SELECT_EMPTY_ERROR = 'feature.company.components.company-item.select.empty-error',
  COMPONENTS_COMPANY_NAME_ITEM_LABEL = 'feature.company.components.company-name-item.label',
  COMPONENTS_COMPANY_NAME_ITEM_PLACEHOLDER = 'feature.company.components.company-name-item.placeholder',
  COMPONENTS_COMPANY_NAME_ITEM_ERROR_REQUIRED = 'feature.company.components.company-name-item.error-required',
  COMPONENTS_COMPANY_NAME_ITEM_ERROR_TOO_SHORT = 'feature.company.components.company-name-item.error-too-short',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_TITLE = 'feature.company.components.add-company-user-operation.title',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_ERROR_MESSAGE = 'feature.company.components.add-company-user-operation.error-message',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_SUCCESS_MESSAGE = 'feature.company.components.add-company-user-operation.success-message',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_ERROR_NO_COMPANY = 'feature.company.components.add-company-user-operation.error.no-company',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_TITLE = 'feature.company.components.add-company-user-operation.form.title',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_SUBMIT = 'feature.company.components.add-company-user-operation.form.submit',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ERROR_COMMON = 'feature.company.components.add-company-user-operation.form.error.common',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ADDRESS_ERROR_DUPLICATE = 'feature.company.components.add-company-user-operation.form.address.error-duplicate',
  COMPONENTS_ADD_COMPANY_USER_OPERATION_FORM_ADDRESS_ERROR_OWNER = 'feature.company.components.add-company-user-operation.form.address.error-owner',
  COMPONENTS_OPERATION_REFRESH_COMPANIES_TITLE = 'feature.company.components.operation-refresh-companies.title',
  COMPONENTS_OPERATION_REFRESH_COMPANIES_ERROR_MESSAGE = 'feature.company.components.operation-refresh-companies.error-message',
  COMPONENTS_OPERATION_REFRESH_COMPANIES_SUCCESS_MESSAGE = 'feature.company.components.operation-refresh-companies.success-message',
  COMPONENTS_OPERATION_REFRESH_COMPANY_USERS_TITLE = 'feature.company.components.operation-refresh-company-users.title',
  COMPONENTS_OPERATION_REFRESH_COMPANY_USERS_ERROR_MESSAGE = 'feature.company.components.operation-refresh-company-users.error-message',
  COMPONENTS_OPERATION_REFRESH_COMPANY_USERS_SUCCESS_MESSAGE = 'feature.company.components.operation-refresh-company-users.success-message',
  COMPONENTS_OPERATION_SELECT_COMPANY_MESSAGE_SUCCESS = 'feature.company.components.operation-select-company.message-success',
  COMPONENTS_OPERATION_SELECT_COMPANY_MESSAGE_ERROR = 'feature.company.components.operation-select-company.message-error',
  COMPONENTS_OPERATION_SELECT_COMPANY_LABEL = 'feature.company.components.operation-select-company.label',
  COMPONENTS_OPERATION_SELECT_COMPANY_FORM_TITLE = 'feature.company.components.operation-select-company.form.title',
  COMPONENTS_OPERATION_SELECT_COMPANY_FORM_SUBMIT = 'feature.company.components.operation-select-company.form.submit',
  COMPONENTS_OPERATION_SELECT_COMPANY_FORM_ERROR_COMMON = 'feature.company.components.operation-select-company.form.error.common',
  COMPONENTS_OPERATION_UPDATE_NAME_MESSAGE_SUCCESS_TITLE = 'feature.company.components.operation-update-name.message-success.title',
  COMPONENTS_OPERATION_UPDATE_NAME_MESSAGE_ERROR = 'feature.company.components.operation-update-name.message-error',
  COMPONENTS_OPERATION_UPDATE_NAME_LABEL = 'feature.company.components.operation-update-name.label',
  COMPONENTS_OPERATION_UPDATE_NAME_FORM_ERROR_COMMON = 'feature.company.components.operation-update-name.form.error.common',
  COMPONENTS_OPERATION_UPDATE_NAME_FORM_TITLE = 'feature.company.components.operation-update-name.form.title',
  COMPONENTS_OPERATION_REMOVE_COMPANY_USER_TITLE = 'feature.company.components.operation-remove-company-user.title',
  COMPONENTS_OPERATION_REMOVE_COMPANY_USER_CONFIRMATION_TITLE = 'feature.company.components.operation-remove-company-user.confirmation-title',
  COMPONENTS_OPERATION_REMOVE_COMPANY_USER_ERROR_MESSAGE = 'feature.company.components.operation-remove-company-user.error-message',
  COMPONENTS_OPERATION_REMOVE_COMPANY_USER_SUCCESS_MESSAGE = 'feature.company.components.operation-remove-company-user.success-message',
  HOCS_ACTIVE_COMPANY_GUARD_COMPANY_UNAVAILABLE = 'feature.company.hocs.active-company-guard.company.unavailable',
  HOCS_ACTIVE_COMPANY_GUARD_COMPANY_NO_ACTIVE = 'feature.company.hocs.active-company-guard.company.no-active'
}
export enum I18nFeatureCompanySettings {
  COMPONENTS_COMPANY_ACTIVE_ASSETS_AMOUNT_ITEM_SELECT_ASSET = 'feature.company.settings.components.company-active-assets-amount-item.select-asset',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_ITEM_ERROR_ALREADY_SELECTED = 'feature.company.settings.components.company-active-assets-item.error-already-selected',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_ITEM_ERROR_BLOCKCHAIN_INCONSISTENT = 'feature.company.settings.components.company-active-assets-item.error-blockchain-inconsistent',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_ITEM_ERROR_STABLECOIN_MIX = 'feature.company.settings.components.company-active-assets-item.error-stablecoin-mix',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_WALLETS_ALERT_MESSAGE = 'feature.company.settings.components.company-active-assets-edit-form.wallets-alert.message',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_DESCRIPTION = 'feature.company.settings.components.company-active-assets-edit-form.description',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_MESSAGE_SUCCESS = 'feature.company.settings.components.company-active-assets-edit-form.message-success',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_EDIT_FORM_MESSAGE_ERROR = 'feature.company.settings.components.company-active-assets-edit-form.message-error',
  COMPONENTS_COMPANY_ACTIVE_ASSETS_VIEW_DESCRIPTION = 'feature.company.settings.components.company-active-assets-view.description',
  COMPONENTS_COMPANY_WEBSITE_LABEL = 'feature.company.settings.components.company-website.label',
  COMPONENTS_COMPANY_WEBSITE_PLACEHOLDER = 'feature.company.settings.components.company-website.placeholder',
  COMPONENTS_COMPANY_WEBSITE_ERROR_REQUIRED = 'feature.company.settings.components.company-website.error-required',
  COMPONENTS_KYB_REQUIRED_BANNER_MESSAGE = 'feature.company.settings.components.kyb-required-banner.message',
  COMPONENTS_KYB_REQUIRED_BANNER_OK = 'feature.company.settings.components.kyb-required-banner.ok',
  COMPONENTS_KYB_REQUIRED_BANNER_DETAILS = 'feature.company.settings.components.kyb-required-banner.details',
  COMPONENTS_KYB_REQUIRED_BANNER_SHORT_DESCRIPTION = 'feature.company.settings.components.kyb-required-banner.short-description',
  COMPONENTS_KYB_REQUIRED_BANNER_MEDIUM_DESCRIPTION = 'feature.company.settings.components.kyb-required-banner.medium-description',
  COMPONENTS_KYB_REQUIRED_BANNER_FULL_DESCRIPTION = 'feature.company.settings.components.kyb-required-banner.full-description',
  COMPONENTS_NO_EXCHANGE_RATES_ALERT_MESSAGE = 'feature.company.settings.components.no-exchange-rates-alert.message',
  COMPONENTS_NO_EXCHANGE_RATES_ALERT_TOOLTIP = 'feature.company.settings.components.no-exchange-rates-alert.tooltip',
  COMPONENTS_OPERATION_ADD_API_KEY_TITLE = 'feature.company.settings.components.operation-add-api-key.title',
  COMPONENTS_OPERATION_ADD_API_KEY_MESSAGE_ERROR = 'feature.company.settings.components.operation-add-api-key.message-error',
  COMPONENTS_OPERATION_ADD_API_KEY_KEY_MODAL_TITLE_GENERATED = 'feature.company.settings.components.operation-add-api-key.key-modal.title-generated',
  COMPONENTS_OPERATION_ADD_API_KEY_KEY_MODAL_DESCRIPTION = 'feature.company.settings.components.operation-add-api-key.key-modal.description',
  COMPONENTS_OPERATION_ADD_API_KEY_ERRORS_NO_DATA = 'feature.company.settings.components.operation-add-api-key.errors.no-data',
  COMPONENTS_OPERATION_ADD_API_KEY_ERRORS_KEYS_LIMIT = 'feature.company.settings.components.operation-add-api-key.errors.keys-limit',
  COMPONENTS_OPERATION_ADD_WEBHOOK_LABEL = 'feature.company.settings.components.operation-add-webhook.label',
  COMPONENTS_OPERATION_ADD_WEBHOOK_FORM_TITLE = 'feature.company.settings.components.operation-add-webhook.form.title',
  COMPONENTS_OPERATION_ADD_WEBHOOK_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-add-webhook.message-success',
  COMPONENTS_OPERATION_ADD_WEBHOOK_MESSAGE_ERROR = 'feature.company.settings.components.operation-add-webhook.message-error',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-confirm-email.message-success',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_MESSAGE_SUCCESS_DESCRIPTION = 'feature.company.settings.components.operation-confirm-email.message-success.description',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_MESSAGE_ERROR = 'feature.company.settings.components.operation-confirm-email.message-error',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_LABEL = 'feature.company.settings.components.operation-confirm-email.label',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_TITLE = 'feature.company.settings.components.operation-confirm-email.form.title',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_SUBMIT = 'feature.company.settings.components.operation-confirm-email.form.submit',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_TOKEN_LABEL = 'feature.company.settings.components.operation-confirm-email.form.token.label',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_TOKEN_PLACEHOLDER = 'feature.company.settings.components.operation-confirm-email.form.token.placeholder',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_TOKEN_REQUIRED = 'feature.company.settings.components.operation-confirm-email.form.token.required',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_DUPLICATE_EMAIL = 'feature.company.settings.components.operation-confirm-email.form.error.duplicate-email',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_INVALID_TOKEN = 'feature.company.settings.components.operation-confirm-email.form.error.invalid-token',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_EXPIRED_TOKEN = 'feature.company.settings.components.operation-confirm-email.form.error.expired-token',
  COMPONENTS_OPERATION_CONFIRM_EMAIL_FORM_ERROR_COMMON = 'feature.company.settings.components.operation-confirm-email.form.error.common',
  COMPONENTS_OPERATION_DELETE_API_KEY_TITLE = 'feature.company.settings.components.operation-delete-api-key.title',
  COMPONENTS_OPERATION_DELETE_API_KEY_CONFIRMATION_TITLE = 'feature.company.settings.components.operation-delete-api-key.confirmation-title',
  COMPONENTS_OPERATION_DELETE_API_KEY_MESSAGE_ERROR = 'feature.company.settings.components.operation-delete-api-key.message-error',
  COMPONENTS_OPERATION_DELETE_API_KEY_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-delete-api-key.message-success',
  COMPONENTS_OPERATION_DELETE_WEBHOOK_LABEL = 'feature.company.settings.components.operation-delete-webhook.label',
  COMPONENTS_OPERATION_DELETE_WEBHOOK_CONFIRMATION_TITLE = 'feature.company.settings.components.operation-delete-webhook.confirmation-title',
  COMPONENTS_OPERATION_DELETE_WEBHOOK_MESSAGE_ERROR = 'feature.company.settings.components.operation-delete-webhook.message-error',
  COMPONENTS_OPERATION_DELETE_WEBHOOK_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-delete-webhook.message-success',
  COMPONENTS_OPERATION_UPDATE_API_KEY_ACTIVATE_TITLE = 'feature.company.settings.components.operation-update-api-key.activate.title',
  COMPONENTS_OPERATION_UPDATE_API_KEY_ACTIVATE_MESSAGE_ERROR = 'feature.company.settings.components.operation-update-api-key.activate.message-error',
  COMPONENTS_OPERATION_UPDATE_API_KEY_ACTIVATE_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-update-api-key.activate.message-success',
  COMPONENTS_OPERATION_UPDATE_API_KEY_DEACTIVATE_TITLE = 'feature.company.settings.components.operation-update-api-key.deactivate.title',
  COMPONENTS_OPERATION_UPDATE_API_KEY_DEACTIVATE_MESSAGE_ERROR = 'feature.company.settings.components.operation-update-api-key.deactivate.message-error',
  COMPONENTS_OPERATION_UPDATE_API_KEY_DEACTIVATE_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-update-api-key.deactivate.message-success',
  COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_TITLE = 'feature.company.settings.components.operation-update-statistics-asset.title',
  COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-update-statistics-asset.message-success',
  COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_MESSAGE_ERROR = 'feature.company.settings.components.operation-update-statistics-asset.message-error',
  COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_TITLE = 'feature.company.settings.components.operation-update-statistics-asset.form.title',
  COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_SUBMIT = 'feature.company.settings.components.operation-update-statistics-asset.form.submit',
  COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_ASSET_LABEL = 'feature.company.settings.components.operation-update-statistics-asset.form.asset.label',
  COMPONENTS_OPERATION_UPDATE_STATISTICS_ASSET_FORM_ERROR_COMMON = 'feature.company.settings.components.operation-update-statistics-asset.form.error.common',
  COMPONENTS_OPERATION_UPDATE_EMAIL_UPDATE_MESSAGE_SUCCESS_TITLE = 'feature.company.settings.components.operation-update-email.update.message-success.title',
  COMPONENTS_OPERATION_UPDATE_EMAIL_UPDATE_MESSAGE_SUCCESS_DESCRIPTION = 'feature.company.settings.components.operation-update-email.update.message-success.description',
  COMPONENTS_OPERATION_UPDATE_EMAIL_RESET_MESSAGE_SUCCESS_TITLE = 'feature.company.settings.components.operation-update-email.reset.message-success.title',
  COMPONENTS_OPERATION_UPDATE_EMAIL_RESET_MESSAGE_SUCCESS_DESCRIPTION = 'feature.company.settings.components.operation-update-email.reset.message-success.description',
  COMPONENTS_OPERATION_UPDATE_EMAIL_MESSAGE_ERROR = 'feature.company.settings.components.operation-update-email.message-error',
  COMPONENTS_OPERATION_UPDATE_EMAIL_LABEL = 'feature.company.settings.components.operation-update-email.label',
  COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_TITLE = 'feature.company.settings.components.operation-update-email.form.title',
  COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_SUBMIT = 'feature.company.settings.components.operation-update-email.form.submit',
  COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_ERROR_DUPLICATE_EMAIL = 'feature.company.settings.components.operation-update-email.form.error.duplicate-email',
  COMPONENTS_OPERATION_UPDATE_EMAIL_FORM_ERROR_COMMON = 'feature.company.settings.components.operation-update-email.form.error.common',
  COMPONENTS_OPERATION_UPDATE_WEBHOOK_LABEL = 'feature.company.settings.components.operation-update-webhook.label',
  COMPONENTS_OPERATION_UPDATE_WEBHOOK_FORM_TITLE = 'feature.company.settings.components.operation-update-webhook.form.title',
  COMPONENTS_OPERATION_UPDATE_WEBHOOK_MESSAGE_SUCCESS = 'feature.company.settings.components.operation-update-webhook.message-success',
  COMPONENTS_OPERATION_UPDATE_WEBHOOK_MESSAGE_ERROR = 'feature.company.settings.components.operation-update-webhook.message-error',
  COMPONENTS_OPERATION_UPDATE_WEBSITE_LABEL = 'feature.company.settings.components.operation-update-website.label',
  COMPONENTS_OPERATION_UPDATE_WEBSITE_MESSAGE_SUCCESS_TITLE = 'feature.company.settings.components.operation-update-website.message-success.title',
  COMPONENTS_OPERATION_UPDATE_WEBSITE_MESSAGE_ERROR = 'feature.company.settings.components.operation-update-website.message-error',
  COMPONENTS_OPERATION_UPDATE_WEBSITE_FORM_TITLE = 'feature.company.settings.components.operation-update-website.form.title',
  COMPONENTS_OPERATION_UPDATE_WEBSITE_FORM_SUBMIT = 'feature.company.settings.components.operation-update-website.form.submit',
  COMPONENTS_OPERATION_UPDATE_WEBSITE_FORM_ERROR_COMMON = 'feature.company.settings.components.operation-update-website.form.error.common',
  COMPONENTS_QR_SUPPORT_LIST_ITEM_ASSETS = 'feature.company.settings.components.qr-support-list.item.assets',
  COMPONENTS_QR_SUPPORT_LIST_ITEM_NOT_DEPLOYED_TITLE = 'feature.company.settings.components.qr-support-list.item.not-deployed.title',
  COMPONENTS_QR_SUPPORT_LIST_ITEM_NOT_DEPLOYED_TOOLTIP = 'feature.company.settings.components.qr-support-list.item.not-deployed.tooltip',
  COMPONENTS_UPDATE_QR_SUPPORT_FORM_SUBMIT_TITLE = 'feature.company.settings.components.update-qr-support-form.submit.title',
  COMPONENTS_UPDATE_QR_SUPPORT_FORM_ERROR_COMMON = 'feature.company.settings.components.update-qr-support-form.error.common',
  COMPONENTS_UPDATE_QR_SUPPORT_FORM_DESCRIPTION = 'feature.company.settings.components.update-qr-support-form.description',
  COMPONENTS_UPDATE_QR_SUPPORT_FORM_INPUT_REQUIRED_NETWORK_TOOLTIP = 'feature.company.settings.components.update-qr-support-form.input.required-network.tooltip',
  COMPONENTS_WEBHOOK_FORM_SUBMIT_CREATE = 'feature.company.settings.components.webhook-form.submit.create',
  COMPONENTS_WEBHOOK_FORM_SUBMIT_UPDATE = 'feature.company.settings.components.webhook-form.submit.update',
  COMPONENTS_WEBHOOK_FORM_URL_LABEL = 'feature.company.settings.components.webhook-form.url.label',
  COMPONENTS_WEBHOOK_FORM_URL_PLACEHOLDER = 'feature.company.settings.components.webhook-form.url.placeholder',
  COMPONENTS_WEBHOOK_FORM_URL_REQUIRED = 'feature.company.settings.components.webhook-form.url.required',
  COMPONENTS_WEBHOOK_FORM_URL_DUPLICATE = 'feature.company.settings.components.webhook-form.url.duplicate',
  COMPONENTS_WEBHOOK_FORM_URL_ERROR_INVALID = 'feature.company.settings.components.webhook-form.url.error-invalid',
  COMPONENTS_WEBHOOK_FORM_ERROR_COMMON = 'feature.company.settings.components.webhook-form.error.common'
}
export enum I18nFeatureDictionary {
  COMPONENTS_ADDRESS_ITEM_LABEL = 'feature.dictionary.components.address-item.label',
  COMPONENTS_ADDRESS_ITEM_PLACEHOLDER = 'feature.dictionary.components.address-item.placeholder',
  COMPONENTS_ADDRESS_ITEM_ERROR_REQUIRED = 'feature.dictionary.components.address-item.error.required',
  COMPONENTS_ADDRESS_ITEM_ERROR_ADDRESS_INVALID = 'feature.dictionary.components.address-item.error.address-invalid',
  COMPONENTS_ADDRESS_NATIVE_BALANCE_BALANCE_NOT_FOUND_TITLE = 'feature.dictionary.components.address-native-balance.balance-not-found.title',
  COMPONENTS_ADDRESS_NATIVE_BALANCE_REFRESH_BALANCE_TITLE = 'feature.dictionary.components.address-native-balance.refresh-balance.title',
  COMPONENTS_ADDRESS_NATIVE_BALANCE_REFRESH_BALANCE_ERROR_MESSAGE = 'feature.dictionary.components.address-native-balance.refresh-balance.error-message',
  COMPONENTS_ADDRESS_TOKEN_BALANCE_BALANCE_NOT_FOUND_TITLE = 'feature.dictionary.components.address-token-balance.balance-not-found.title',
  COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_ERROR_MESSAGE = 'feature.dictionary.components.address-token-balance.refresh-balance.error-message',
  COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_TITLE = 'feature.dictionary.components.address-token-balance.refresh-balance.title',
  COMPONENTS_ASSET_ITEM_LABEL = 'feature.dictionary.components.asset-item.label',
  COMPONENTS_ASSET_ITEM_PLACEHOLDER = 'feature.dictionary.components.asset-item.placeholder',
  COMPONENTS_ASSET_ITEM_REQUIRED = 'feature.dictionary.components.asset-item.required',
  COMPONENTS_ASSET_ITEM_EMPTY_ERROR = 'feature.dictionary.components.asset-item.empty-error',
  COMPONENTS_ASSETS_FORM_ERROR_NO_ASSETS = 'feature.dictionary.components.assets-form.error.no-assets',
  COMPONENTS_ASSETS_FORM_EDIT_FORM_ERROR_COMMON = 'feature.dictionary.components.assets-form.edit-form.error.common',
  COMPONENTS_BLOCKCHAIN_ITEM_LABEL = 'feature.dictionary.components.blockchain-item.label',
  COMPONENTS_BLOCKCHAIN_ITEM_PLACEHOLDER = 'feature.dictionary.components.blockchain-item.placeholder',
  COMPONENTS_BLOCKCHAIN_ITEM_REQUIRED = 'feature.dictionary.components.blockchain-item.required',
  COMPONENTS_BLOCKCHAIN_ITEM_EMPTY_ERROR = 'feature.dictionary.components.blockchain-item.empty-error',
  COMPONENTS_FILE_UPLOAD_ITEM_LABEL = 'feature.dictionary.components.file-upload-item.label',
  COMPONENTS_FILE_UPLOAD_ITEM_REQUIRED = 'feature.dictionary.components.file-upload-item.required',
  COMPONENTS_FILE_UPLOAD_ITEM_UPLOAD_BUTTON = 'feature.dictionary.components.file-upload-item.upload-button',
  COMPONENTS_FILE_UPLOAD_ITEM_ERRORS_UPLOAD_COMMON = 'feature.dictionary.components.file-upload-item.errors.upload.common',
  COMPONENTS_FILE_UPLOAD_ITEM_ERRORS_FORMAT_UNSUPPORTED = 'feature.dictionary.components.file-upload-item.errors.format.unsupported',
  COMPONENTS_FILE_UPLOAD_ITEM_ERRORS_SIZE_TOO_BIG = 'feature.dictionary.components.file-upload-item.errors.size.too-big',
  COMPONENTS_NETWORK_LABEL_TOOLTIP_FULL_TESTNET = 'feature.dictionary.components.network-label.tooltip.full.TestNet',
  COMPONENTS_NETWORK_LABEL_TOOLTIP_FULL_MAINNET = 'feature.dictionary.components.network-label.tooltip.full.MainNet',
  COMPONENTS_NETWORK_LABEL_TOOLTIP_SHORT_TESTNET = 'feature.dictionary.components.network-label.tooltip.short.TestNet',
  COMPONENTS_NETWORK_LABEL_TOOLTIP_SHORT_MAINNET = 'feature.dictionary.components.network-label.tooltip.short.MainNet',
  COMPONENTS_NETWORK_TYPE_ITEM_LABEL = 'feature.dictionary.components.network-type-item.label',
  COMPONENTS_NETWORK_TYPE_ITEM_REQUIRED = 'feature.dictionary.components.network-type-item.required',
  COMPONENTS_NETWORK_TYPE_ITEM_SELECT_PLACEHOLDER = 'feature.dictionary.components.network-type-item.select.placeholder',
  COMPONENTS_OPERATION_SELECT_NETWORK_MESSAGE_SUCCESS = 'feature.dictionary.components.operation-select-network.message-success',
  COMPONENTS_OPERATION_SELECT_NETWORK_MESSAGE_ERROR = 'feature.dictionary.components.operation-select-network.message-error',
  COMPONENTS_OPERATION_SELECT_NETWORK_LABEL = 'feature.dictionary.components.operation-select-network.label',
  COMPONENTS_PRIVATE_KEY_ITEM_LABEL = 'feature.dictionary.components.private-key-item.label',
  COMPONENTS_PRIVATE_KEY_ITEM_PLACEHOLDER = 'feature.dictionary.components.private-key-item.placeholder',
  COMPONENTS_PRIVATE_KEY_ITEM_ERROR_REQUIRED = 'feature.dictionary.components.private-key-item.error-required',
  COMPONENTS_PRIVATE_KEY_ITEM_ERROR_INVALID = 'feature.dictionary.components.private-key-item.error-invalid',
  COMPONENTS_PRIVATE_KEY_ITEM_ERROR_XPUB_INVALID = 'feature.dictionary.components.private-key-item.error-xpub-invalid',
  COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_LABEL = 'feature.dictionary.components.private-key-or-seed-item.label',
  COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_PLACEHOLDER = 'feature.dictionary.components.private-key-or-seed-item.placeholder',
  COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_ERROR_REQUIRED = 'feature.dictionary.components.private-key-or-seed-item.error-required',
  COMPONENTS_PRIVATE_KEY_OR_SEED_ITEM_ERROR_XPUB_INVALID = 'feature.dictionary.components.private-key-or-seed-item.error-xpub-invalid',
  COMPONENTS_SEED_ITEM_LABEL = 'feature.dictionary.components.seed-item.label',
  COMPONENTS_SEED_ITEM_PLACEHOLDER = 'feature.dictionary.components.seed-item.placeholder',
  COMPONENTS_SEED_ITEM_ERROR_REQUIRED = 'feature.dictionary.components.seed-item.error.required',
  COMPONENTS_SEED_ITEM_ERROR_FORMAT_INVALID = 'feature.dictionary.components.seed-item.error.format-invalid',
  COMPONENTS_SEED_ITEM_ERROR_XPUB_INVALID = 'feature.dictionary.components.seed-item.error-xpub-invalid'
}
export enum I18nFeatureDonations {
  COMPONENTS_DOCUMENTATION_BANNER_MESSAGE = 'feature.donations.components.documentation-banner.message',
  COMPONENTS_DOCUMENTATION_LINK_TITLE = 'feature.donations.components.documentation-link.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ADD_TITLE = 'feature.donations.components.edit-donation-form.assets-item.add.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_EDIT_TITLE = 'feature.donations.components.edit-donation-form.assets-item.edit.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_REMOVE_TITLE = 'feature.donations.components.edit-donation-form.assets-item.remove.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_REMOVE_DISABLE_DEPLOYED = 'feature.donations.components.edit-donation-form.assets-item.remove.disable.deployed',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_CONFIRM_TITLE = 'feature.donations.components.edit-donation-form.assets-item.confirm.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_CANCEL_TITLE = 'feature.donations.components.edit-donation-form.assets-item.cancel.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ERROR_REQUIRED = 'feature.donations.components.edit-donation-form.assets-item.error.required',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ERROR_NO_ACTIVE = 'feature.donations.components.edit-donation-form.assets-item.error.no-active',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_TITLE = 'feature.donations.components.edit-donation-form.assets-item.asset-amounts-item.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_TOOLTIP = 'feature.donations.components.edit-donation-form.assets-item.asset-amounts-item.tooltip',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_HELP = 'feature.donations.components.edit-donation-form.assets-item.asset-amounts-item.help',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_REMOVE_TITLE = 'feature.donations.components.edit-donation-form.assets-item.asset-amounts-item.remove.title',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_LIMIT_EXCEEDED_MESSAGE = 'feature.donations.components.edit-donation-form.assets-item.asset-amounts-item.limit-exceeded-message',
  COMPONENTS_EDIT_DONATION_FORM_ASSETS_ITEM_ASSET_AMOUNTS_ITEM_DUPLICATE_AMOUNTS_MESSAGE = 'feature.donations.components.edit-donation-form.assets-item.asset-amounts-item.duplicate-amounts-message',
  COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_PLACEHOLDER = 'feature.donations.components.edit-donation-form.description-item.placeholder',
  COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_ERRORS_REQUIRED = 'feature.donations.components.edit-donation-form.description-item.errors.required',
  COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_ERRORS_MAX_LENGTH = 'feature.donations.components.edit-donation-form.description-item.errors.max-length',
  COMPONENTS_EDIT_DONATION_FORM_DESCRIPTION_ITEM_ERRORS_MIN_LENGTH = 'feature.donations.components.edit-donation-form.description-item.errors.min-length',
  COMPONENTS_EDIT_DONATION_FORM_IMAGE_ITEM_REQUIRED = 'feature.donations.components.edit-donation-form.image-item.required',
  COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_PLACEHOLDER = 'feature.donations.components.edit-donation-form.title-item.placeholder',
  COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_ERRORS_REQUIRED = 'feature.donations.components.edit-donation-form.title-item.errors.required',
  COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_ERRORS_MAX_LENGTH = 'feature.donations.components.edit-donation-form.title-item.errors.max-length',
  COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_ERRORS_MIN_LENGTH = 'feature.donations.components.edit-donation-form.title-item.errors.min-length',
  COMPONENTS_EDIT_DONATION_FORM_SUBMIT_BUTTON = 'feature.donations.components.edit-donation-form.submit-button',
  COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_ACTIVATE_TITLE = 'feature.donations.components.operation-change-donation-status.activate.title',
  COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_DEACTIVATE_TITLE = 'feature.donations.components.operation-change-donation-status.deactivate.title',
  COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_ERROR_MESSAGE = 'feature.donations.components.operation-change-donation-status.error-message',
  COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_SUCCESS_MESSAGE = 'feature.donations.components.operation-change-donation-status.success-message',
  COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ACTIVATE_TITLE = 'feature.donations.components.operation-change-donation-asset-status.activate.title',
  COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_DEACTIVATE_TITLE = 'feature.donations.components.operation-change-donation-asset-status.deactivate.title',
  COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ERROR_MESSAGE = 'feature.donations.components.operation-change-donation-asset-status.error-message',
  COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_SUCCESS_MESSAGE = 'feature.donations.components.operation-change-donation-asset-status.success-message',
  COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ERRORS_NO_DATA = 'feature.donations.components.operation-change-donation-asset-status.errors.no-data',
  COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ERRORS_INVALID_STATUS = 'feature.donations.components.operation-change-donation-asset-status.errors.invalid-status',
  COMPONENTS_OPERATION_GENERATE_DONATION_URL_TITLE = 'feature.donations.components.operation-generate-donation-url.title',
  COMPONENTS_OPERATION_GENERATE_DONATION_URL_SUCCESS_FORM_TITLE = 'feature.donations.components.operation-generate-donation-url.success.form.title',
  COMPONENTS_OPERATION_GENERATE_DONATION_URL_LINK_TITLE = 'feature.donations.components.operation-generate-donation-url.link.title',
  COMPONENTS_OPERATION_GENERATE_DONATION_URL_ERROR_MESSAGE = 'feature.donations.components.operation-generate-donation-url.error-message',
  COMPONENTS_OPERATION_REFRESH_DONATION_TOOLTIP = 'feature.donations.components.operation-refresh-donation.tooltip',
  COMPONENTS_OPERATION_REFRESH_DONATION_SUCCESS_MESSAGE = 'feature.donations.components.operation-refresh-donation.success-message',
  COMPONENTS_OPERATION_REFRESH_DONATION_TRANSACTION_TOOLTIP = 'feature.donations.components.operation-refresh-donation-transaction.tooltip',
  COMPONENTS_OPERATION_REFRESH_DONATION_TRANSACTION_SUCCESS_MESSAGE = 'feature.donations.components.operation-refresh-donation-transaction.success-message',
  COMPONENTS_OPERATION_REFRESH_DONATION_ASSET_DEPLOY_TX_SUCCESS_MESSAGE = 'feature.donations.components.operation-refresh-donation-asset-deploy-tx.success-message',
  COMPONENTS_DONATION_STATUS_VIEW_LABEL_ACTIVE = 'feature.donations.components.donation-status-view.label.Active',
  COMPONENTS_DONATION_STATUS_VIEW_LABEL_DISABLED = 'feature.donations.components.donation-status-view.label.Disabled',
  COMPONENTS_DONATION_ADDRESS_STATUS_VIEW_LABEL_ACTIVE = 'feature.donations.components.donation-address-status-view.label.Active',
  COMPONENTS_DONATION_ADDRESS_STATUS_VIEW_LABEL_DISABLED = 'feature.donations.components.donation-address-status-view.label.Disabled',
  COMPONENTS_DONATION_FILTER_CREATED_AT_FROM_LABEL = 'feature.donations.components.donation-filter.created-at.from.label',
  COMPONENTS_DONATION_FILTER_CREATED_AT_TO_LABEL = 'feature.donations.components.donation-filter.created-at.to.label',
  COMPONENTS_DONATION_FILTER_STATUS_LABEL = 'feature.donations.components.donation-filter.status.label',
  COMPONENTS_DONATION_FILTER_STATUS_PLACEHOLDER = 'feature.donations.components.donation-filter.status.placeholder',
  COMPONENTS_DONATION_FILTER_TITLE_LABEL = 'feature.donations.components.donation-filter.title.label',
  COMPONENTS_DONATION_FILTER_TITLE_PLACEHOLDER = 'feature.donations.components.donation-filter.title.placeholder',
  COMPONENTS_DONATIONS_TABLE_DONATION_DIALOG_TITLE = 'feature.donations.components.donations-table.donation-dialog.title',
  COMPONENTS_DONATION_TRANSACTION_FILTER_BROADCAST_AT_FROM_LABEL = 'feature.donations.components.donation-transaction-filter.broadcast-at.from.label',
  COMPONENTS_DONATION_TRANSACTION_FILTER_BROADCAST_AT_TO_LABEL = 'feature.donations.components.donation-transaction-filter.broadcast-at.to.label',
  COMPONENTS_DONATION_TRANSACTION_FILTER_FROM_ADDRESS_LABEL = 'feature.donations.components.donation-transaction-filter.from-address.label',
  COMPONENTS_DONATION_TRANSACTION_FILTER_FROM_ADDRESS_PLACEHOLDER = 'feature.donations.components.donation-transaction-filter.from-address.placeholder',
  LABELS_DONATION_ROW_TITLE_ID = 'feature.donations.labels.donation.row.title.id',
  LABELS_DONATION_ROW_TITLE_TITLE = 'feature.donations.labels.donation.row.title.title',
  LABELS_DONATION_ROW_TITLE_DESCRIPTION = 'feature.donations.labels.donation.row.title.description',
  LABELS_DONATION_ROW_TITLE_IMAGE = 'feature.donations.labels.donation.row.title.image',
  LABELS_DONATION_ROW_TITLE_CREATED_AT = 'feature.donations.labels.donation.row.title.created-at',
  LABELS_DONATION_ROW_TITLE_IS_ACTIVE = 'feature.donations.labels.donation.row.title.is-active',
  LABELS_DONATION_ROW_TITLE_ASSETS = 'feature.donations.labels.donation.row.title.assets',
  LABELS_ADDRESS_ROW_TITLE_ADDRESS = 'feature.donations.labels.address.row.title.address',
  LABELS_ADDRESS_ROW_TITLE_BALANCE = 'feature.donations.labels.address.row.title.balance',
  LABELS_ADDRESS_ROW_TITLE_AMOUNTS = 'feature.donations.labels.address.row.title.amounts'
}
export enum I18nFeatureGasHistory {
  COMPONENTS_FILTER_FORM_CREATED_AT_ITEM_FROM_LABEL = 'feature.gas.history.components.filter-form.created-at-item.from.label',
  COMPONENTS_FILTER_FORM_CREATED_AT_ITEM_TO_LABEL = 'feature.gas.history.components.filter-form.created-at-item.to.label',
  COMPONENTS_FILTER_FORM_RESULT_ITEM_LABEL = 'feature.gas.history.components.filter-form.result-item.label',
  COMPONENTS_FILTER_FORM_RESULT_ITEM_PLACEHOLDER = 'feature.gas.history.components.filter-form.result-item.placeholder',
  COMPONENTS_FILTER_FORM_ADDRESS_ITEM_LABEL = 'feature.gas.history.components.filter-form.address-item.label',
  COMPONENTS_FILTER_FORM_ADDRESS_ITEM_PLACEHOLDER = 'feature.gas.history.components.filter-form.address-item.placeholder',
  COMPONENTS_FILTER_FORM_SPONSOR_ADDRESS_ITEM_LABEL = 'feature.gas.history.components.filter-form.sponsor-address-item.label',
  COMPONENTS_FILTER_FORM_SPONSOR_ADDRESS_ITEM_PLACEHOLDER = 'feature.gas.history.components.filter-form.sponsor-address-item.placeholder',
  COMPONENTS_FILTER_FORM_FUNCTION_ITEM_LABEL = 'feature.gas.history.components.filter-form.function-item.label',
  COMPONENTS_FILTER_FORM_FUNCTION_ITEM_PLACEHOLDER = 'feature.gas.history.components.filter-form.function-item.placeholder',
  COMPONENTS_RESULT_VIEW_SUCCESS = 'feature.gas.history.components.result-view.Success',
  COMPONENTS_RESULT_VIEW_ERROR = 'feature.gas.history.components.result-view.Error',
  COMPONENTS_RESULT_VIEW_UNKNOWN = 'feature.gas.history.components.result-view.Unknown',
  COMPONENTS_REPORT_TITLE = 'feature.gas.history.components.report.title',
  COMPONENTS_REPORT_SCHEDULE_PARAMETERS_CREATED_AT_RELATIVE_ITEM_LABEL = 'feature.gas.history.components.report-schedule-parameters.created-at-relative-item.label',
  COMPONENTS_GAS_HISTORY_TABLE_GAS_HISTORY_DIALOG_TITLE = 'feature.gas.history.components.gas-history-table.gas-history-dialog.title',
  COMPONENTS_GAS_HISTORY_VIEW_TITLE = 'feature.gas.history.components.gas-history-view.title',
  COMPONENTS_OPERATION_EXPORT_TITLE = 'feature.gas.history.components.operation-export.title',
  COMPONENTS_OPERATION_EXPORT_TOOLTIP = 'feature.gas.history.components.operation-export.tooltip',
  COMPONENTS_OPERATION_EXPORT_ERROR_MESSAGE = 'feature.gas.history.components.operation-export.error-message',
  COMPONENTS_OPERATION_EXPORT_GENERATING_MESSAGE = 'feature.gas.history.components.operation-export.generating-message',
  COMPONENTS_OPERATION_RELOAD_GAS_HISTORY_TITLE = 'feature.gas.history.components.operation-reload-gas-history.title',
  COMPONENTS_OPERATION_RELOAD_GAS_HISTORY_TOOLTIP = 'feature.gas.history.components.operation-reload-gas-history.tooltip',
  COMPONENTS_OPERATION_RELOAD_GAS_HISTORY_FAILED_MESSAGE = 'feature.gas.history.components.operation-reload-gas-history.failed-message',
  COMPONENTS_OPERATION_RELOAD_GAS_HISTORY_FAILED_DESCRIPTION = 'feature.gas.history.components.operation-reload-gas-history.failed-description',
  COMPONENTS_OPERATION_RELOAD_GAS_HISTORY_SUCCESS_MESSAGE = 'feature.gas.history.components.operation-reload-gas-history.success-message',
  LABELS_ROW_TITLE_TX = 'feature.gas.history.labels.row.title.tx',
  LABELS_ROW_TITLE_ADDRESS = 'feature.gas.history.labels.row.title.address',
  LABELS_ROW_TITLE_SPONSOR_ADDRESS = 'feature.gas.history.labels.row.title.sponsor-address',
  LABELS_ROW_TITLE_FUNCTION_NAME = 'feature.gas.history.labels.row.title.function-name',
  LABELS_ROW_TITLE_GAS_PRICE = 'feature.gas.history.labels.row.title.gas-price',
  LABELS_ROW_TITLE_GAS_LIMIT = 'feature.gas.history.labels.row.title.gas-limit',
  LABELS_ROW_TITLE_GAS_SPENT = 'feature.gas.history.labels.row.title.gas-spent',
  LABELS_ROW_TITLE_RESULT = 'feature.gas.history.labels.row.title.result',
  LABELS_ROW_TITLE_CREATED_AT = 'feature.gas.history.labels.row.title.created-at'
}
export enum I18nFeatureGasWallets {
  ROW_BLOCKCHAIN = 'feature.gas.wallets.row.blockchain',
  ROW_ADDRESS = 'feature.gas.wallets.row.address',
  ROW_NATIVE_BALANCE = 'feature.gas.wallets.row.native-balance',
  ROW_DERIVED_BALANCE = 'feature.gas.wallets.row.derived-balance',
  COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_SENT = 'feature.gas.wallets.components.gas-wallet-tx-status-view.Sent',
  COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_PENDING = 'feature.gas.wallets.components.gas-wallet-tx-status-view.Pending',
  COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_SUCCEEDED = 'feature.gas.wallets.components.gas-wallet-tx-status-view.Succeeded',
  COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_ABANDONED = 'feature.gas.wallets.components.gas-wallet-tx-status-view.Abandoned',
  COMPONENTS_GAS_WALLET_TX_STATUS_VIEW_FAILED = 'feature.gas.wallets.components.gas-wallet-tx-status-view.Failed',
  COMPONENTS_GAS_WALLET_VIEW_NATIVE_BALANCE_HELP = 'feature.gas.wallets.components.gas-wallet-view.native-balance.help',
  COMPONENTS_GAS_WALLET_VIEW_DERIVED_BALANCE_HELP = 'feature.gas.wallets.components.gas-wallet-view.derived-balance.help',
  COMPONENTS_OPERATION_CONFIRMATION_DIALOG_DIALOG_TITLE = 'feature.gas.wallets.components.operation-confirmation-dialog.dialog.title',
  COMPONENTS_OPERATION_CONFIRMATION_DIALOG_DIALOG_YES = 'feature.gas.wallets.components.operation-confirmation-dialog.dialog.yes',
  COMPONENTS_OPERATION_CONFIRMATION_DIALOG_DIALOG_NO = 'feature.gas.wallets.components.operation-confirmation-dialog.dialog.no',
  COMPONENTS_OPERATION_CONFIRMATION_DIALOG_MESSAGE_LOADING = 'feature.gas.wallets.components.operation-confirmation-dialog.message.loading',
  COMPONENTS_OPERATION_CONFIRMATION_DIALOG_MESSAGE_NON_ZERO_BALANCE = 'feature.gas.wallets.components.operation-confirmation-dialog.message.non-zero-balance',
  COMPONENTS_OPERATION_CONFIRMATION_DIALOG_MESSAGE_BALANCE_CHECK_ERROR = 'feature.gas.wallets.components.operation-confirmation-dialog.message.balance-check-error',
  COMPONENTS_REFUNDING_TASK_MESSAGE = 'feature.gas.wallets.components.refunding-task.message',
  COMPONENTS_OPERATION_CREATE_WALLET_TITLE = 'feature.gas.wallets.components.operation-create-wallet.title',
  COMPONENTS_OPERATION_CREATE_WALLET_MESSAGE_ERROR = 'feature.gas.wallets.components.operation-create-wallet.message-error',
  COMPONENTS_OPERATION_CREATE_WALLET_MESSAGE_SUCCESS = 'feature.gas.wallets.components.operation-create-wallet.message-success',
  COMPONENTS_OPERATION_DELETE_WALLET_TITLE = 'feature.gas.wallets.components.operation-delete-wallet.title',
  COMPONENTS_OPERATION_DELETE_WALLET_UNAVAILABILITY_NO_DATA = 'feature.gas.wallets.components.operation-delete-wallet.unavailability.no-data',
  COMPONENTS_OPERATION_DELETE_WALLET_UNAVAILABILITY_NATIVE_BALANCE_UNKNOWN = 'feature.gas.wallets.components.operation-delete-wallet.unavailability.native-balance-unknown',
  COMPONENTS_OPERATION_DELETE_WALLET_UNAVAILABILITY_NON_EMPTY_BALANCE = 'feature.gas.wallets.components.operation-delete-wallet.unavailability.non-empty-balance',
  COMPONENTS_OPERATION_DELETE_WALLET_CONFIRMATION_TITLE = 'feature.gas.wallets.components.operation-delete-wallet.confirmation-title',
  COMPONENTS_OPERATION_DELETE_WALLET_MESSAGE_ERROR = 'feature.gas.wallets.components.operation-delete-wallet.message-error',
  COMPONENTS_OPERATION_DELETE_WALLET_MESSAGE_SUCCESS = 'feature.gas.wallets.components.operation-delete-wallet.message-success',
  COMPONENTS_OPERATION_REFUND_WALLET_TITLE = 'feature.gas.wallets.components.operation-refund-wallet.title',
  COMPONENTS_OPERATION_REFUND_WALLET_UNAVAILABILITY_NO_DATA = 'feature.gas.wallets.components.operation-refund-wallet.unavailability.no-data',
  COMPONENTS_OPERATION_REFUND_WALLET_UNAVAILABILITY_EMPTY_BALANCE = 'feature.gas.wallets.components.operation-refund-wallet.unavailability.empty-balance',
  COMPONENTS_OPERATION_REFUND_WALLET_MESSAGE_SUCCESS = 'feature.gas.wallets.components.operation-refund-wallet.message-success',
  COMPONENTS_OPERATION_REFUND_WALLET_MESSAGE_ERROR = 'feature.gas.wallets.components.operation-refund-wallet.message-error',
  COMPONENTS_OPERATION_REFUND_WALLET_FORM_TITLE = 'feature.gas.wallets.components.operation-refund-wallet.form.title',
  COMPONENTS_OPERATION_REFUND_WALLET_FORM_ADDRESS = 'feature.gas.wallets.components.operation-refund-wallet.form.address',
  COMPONENTS_OPERATION_REFUND_WALLET_FORM_SUBMIT = 'feature.gas.wallets.components.operation-refund-wallet.form.submit',
  COMPONENTS_OPERATION_REFUND_WALLET_FORM_ERROR = 'feature.gas.wallets.components.operation-refund-wallet.form.error',
  COMPONENTS_INVALID_STATE_MESSAGE_PRESENCE_TAG = 'feature.gas.wallets.components.invalid-state-message.presence.tag',
  COMPONENTS_INVALID_STATE_MESSAGE_PRESENCE_SHORT = 'feature.gas.wallets.components.invalid-state-message.presence.short',
  COMPONENTS_INVALID_STATE_MESSAGE_PRESENCE_FULL = 'feature.gas.wallets.components.invalid-state-message.presence.full',
  COMPONENTS_INVALID_STATE_MESSAGE_REFUNDING_TAG = 'feature.gas.wallets.components.invalid-state-message.refunding.tag',
  COMPONENTS_INVALID_STATE_MESSAGE_REFUNDING_SHORT = 'feature.gas.wallets.components.invalid-state-message.refunding.short',
  COMPONENTS_INVALID_STATE_MESSAGE_REFUNDING_FULL = 'feature.gas.wallets.components.invalid-state-message.refunding.full',
  COMPONENTS_INVALID_STATE_MESSAGE_ANY_SERVICE_TAG = 'feature.gas.wallets.components.invalid-state-message.any-service.tag',
  COMPONENTS_INVALID_STATE_MESSAGE_ANY_SERVICE_SHORT = 'feature.gas.wallets.components.invalid-state-message.any-service.short',
  COMPONENTS_INVALID_STATE_MESSAGE_ANY_SERVICE_FULL = 'feature.gas.wallets.components.invalid-state-message.any-service.full',
  COMPONENTS_INVALID_STATE_MESSAGE_ALL_SERVICE_TAG = 'feature.gas.wallets.components.invalid-state-message.all-service.tag',
  COMPONENTS_INVALID_STATE_MESSAGE_ALL_SERVICE_SHORT = 'feature.gas.wallets.components.invalid-state-message.all-service.short',
  COMPONENTS_INVALID_STATE_MESSAGE_ALL_SERVICE_FULL = 'feature.gas.wallets.components.invalid-state-message.all-service.full',
  COMPONENTS_INVALID_STATE_MESSAGE_DERIVED_BALANCE_TAG = 'feature.gas.wallets.components.invalid-state-message.derived-balance.tag',
  COMPONENTS_INVALID_STATE_MESSAGE_DERIVED_BALANCE_SHORT = 'feature.gas.wallets.components.invalid-state-message.derived-balance.short',
  COMPONENTS_INVALID_STATE_MESSAGE_DERIVED_BALANCE_FULL = 'feature.gas.wallets.components.invalid-state-message.derived-balance.full',
  COMPONENTS_INVALID_STATE_MESSAGE_NATIVE_BALANCE_TAG = 'feature.gas.wallets.components.invalid-state-message.native-balance.tag',
  COMPONENTS_INVALID_STATE_MESSAGE_NATIVE_BALANCE_SHORT = 'feature.gas.wallets.components.invalid-state-message.native-balance.short',
  COMPONENTS_INVALID_STATE_MESSAGE_NATIVE_BALANCE_FULL = 'feature.gas.wallets.components.invalid-state-message.native-balance.full',
  HOCS_BALANCE_LOADING_ERRORS_NO_WALLET_TITLE = 'feature.gas.wallets.hocs.balance-loading.errors.no-wallet.title'
}
export enum I18nFeatureGlobal {
  COMPONENTS_COOKIES_BANNER_MESSAGE = 'feature.global.components.cookies-banner.message',
  COMPONENTS_COOKIES_BANNER_BUTTON_TITLE = 'feature.global.components.cookies-banner.button.title',
  COMPONENTS_DOCUMENTATION_BANNER_OK_TITLE = 'feature.global.components.documentation-banner.ok-title',
  COMPONENTS_PENDING_STATUS_VIEW_RECHARGECOLLECTING = 'feature.global.components.pending-status-view.RechargeCollecting',
  COMPONENTS_PENDING_STATUS_VIEW_SUBSCRIPTIONPAUSING = 'feature.global.components.pending-status-view.SubscriptionPausing',
  COMPONENTS_PENDING_STATUS_VIEW_SUBSCRIPTIONUNPAUSING = 'feature.global.components.pending-status-view.SubscriptionUnPausing',
  COMPONENTS_PENDING_STATUS_VIEW_SUBSCRIPTIONCANCELLING = 'feature.global.components.pending-status-view.SubscriptionCancelling'
}
export enum I18nFeatureEmail {
  COMPONENTS_CONFIRMATION_FORM_FOOTER_SUBMIT_TITLE = 'feature.email.components.confirmation-form.footer.submit.title',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_GET_BUTTON_TITLE = 'feature.email.components.confirmation-item.code-input.get-button.title',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_RESEND_BUTTON_TITLE = 'feature.email.components.confirmation-item.code-input.resend-button.title',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_PLACEHOLDER = 'feature.email.components.confirmation-item.code-input.placeholder',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_REQUIRED = 'feature.email.components.confirmation-item.code-input.required',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_TOO_LONG = 'feature.email.components.confirmation-item.code-input.too-long',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_COMMON = 'feature.email.components.confirmation-item.code-input.errors.common',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_RATE_LIMIT = 'feature.email.components.confirmation-item.code-input.errors.rate-limit',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_CODE_INVALID = 'feature.email.components.confirmation-item.code-input.errors.code-invalid',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_CODE_EXPIRED = 'feature.email.components.confirmation-item.code-input.errors.code-expired',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_TOO_MANY_ATTEMPTS = 'feature.email.components.confirmation-item.code-input.errors.too-many-attempts',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_BLOCKED_SHORT = 'feature.email.components.confirmation-item.code-input.errors.blocked.short',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_ERRORS_BLOCKED = 'feature.email.components.confirmation-item.code-input.errors.blocked',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_SEND_SUCCESS_MESSAGE = 'feature.email.components.confirmation-item.code-input.send.success-message',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_SEND_ERROR_MESSAGE = 'feature.email.components.confirmation-item.code-input.send.error-message',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_NO_EMAIL = 'feature.email.components.confirmation-item.code-input.description.no-email',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_READY_TO_SEND = 'feature.email.components.confirmation-item.code-input.description.ready-to-send',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_SENT = 'feature.email.components.confirmation-item.code-input.description.sent',
  COMPONENTS_CONFIRMATION_ITEM_CODE_INPUT_DESCRIPTION_CONFIRMED = 'feature.email.components.confirmation-item.code-input.description.confirmed'
}
export enum I18nFeatureMerchantWalletBalance {
  COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_LABEL = 'feature.merchant.wallet.balance.components.filter-form.broadcast-at.from.label',
  COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_PLACEHOLDER = 'feature.merchant.wallet.balance.components.filter-form.broadcast-at.from.placeholder',
  COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_LABEL = 'feature.merchant.wallet.balance.components.filter-form.broadcast-at.to.label',
  COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_PLACEHOLDER = 'feature.merchant.wallet.balance.components.filter-form.broadcast-at.to.placeholder',
  COMPONENTS_FILTER_FORM_WALLET_LABEL = 'feature.merchant.wallet.balance.components.filter-form.wallet.label',
  COMPONENTS_FILTER_FORM_WALLET_PLACEHOLDER = 'feature.merchant.wallet.balance.components.filter-form.wallet.placeholder',
  COMPONENTS_CONFIRMATION_STATUS_VIEW_UNCHECKED = 'feature.merchant.wallet.balance.components.confirmation-status-view.Unchecked',
  COMPONENTS_CONFIRMATION_STATUS_VIEW_RETRYING = 'feature.merchant.wallet.balance.components.confirmation-status-view.Retrying',
  COMPONENTS_CONFIRMATION_STATUS_VIEW_FAILED = 'feature.merchant.wallet.balance.components.confirmation-status-view.Failed',
  COMPONENTS_CONFIRMATION_STATUS_VIEW_CONFIRMED = 'feature.merchant.wallet.balance.components.confirmation-status-view.Confirmed',
  COMPONENTS_REPORT_TITLE = 'feature.merchant.wallet.balance.components.report.title',
  COMPONENTS_REPORT_SCHEDULE_PARAMETERS_BROADCAST_AT_RELATIVE_ITEM_LABEL = 'feature.merchant.wallet.balance.components.report-schedule-parameters.broadcast-at-relative-item.label',
  COMPONENTS_BALANCE_TABLE_BALANCE_DIALOG_TITLE = 'feature.merchant.wallet.balance.components.balance-table.balance-dialog.title',
  COMPONENTS_OPERATION_EXPORT_TITLE = 'feature.merchant.wallet.balance.components.operation-export.title',
  COMPONENTS_OPERATION_EXPORT_TOOLTIP = 'feature.merchant.wallet.balance.components.operation-export.tooltip',
  COMPONENTS_OPERATION_EXPORT_ERROR_MESSAGE = 'feature.merchant.wallet.balance.components.operation-export.error-message',
  COMPONENTS_OPERATION_EXPORT_GENERATING_MESSAGE = 'feature.merchant.wallet.balance.components.operation-export.generating-message',
  COMPONENTS_OPERATION_RELOAD_BALANCE_TITLE = 'feature.merchant.wallet.balance.components.operation-reload-balance.title',
  COMPONENTS_OPERATION_RELOAD_BALANCE_TOOLTIP = 'feature.merchant.wallet.balance.components.operation-reload-balance.tooltip',
  COMPONENTS_OPERATION_RELOAD_BALANCE_FAILED_MESSAGE = 'feature.merchant.wallet.balance.components.operation-reload-balance.failed-message',
  COMPONENTS_OPERATION_RELOAD_BALANCE_FAILED_DESCRIPTION = 'feature.merchant.wallet.balance.components.operation-reload-balance.failed-description',
  COMPONENTS_OPERATION_RELOAD_BALANCE_SUCCESS_MESSAGE = 'feature.merchant.wallet.balance.components.operation-reload-balance.success-message',
  COMPONENTS_BALANCE_VIEW_TITLE = 'feature.merchant.wallet.balance.components.balance-view.title',
  LABELS_ROW_TITLE_WALLET = 'feature.merchant.wallet.balance.labels.row.title.wallet',
  LABELS_ROW_TITLE_BLOCK = 'feature.merchant.wallet.balance.labels.row.title.block',
  LABELS_ROW_TITLE_AMOUNT = 'feature.merchant.wallet.balance.labels.row.title.amount',
  LABELS_ROW_TITLE_BALANCE = 'feature.merchant.wallet.balance.labels.row.title.balance',
  LABELS_ROW_TITLE_CONFIRMATION = 'feature.merchant.wallet.balance.labels.row.title.confirmation',
  LABELS_ROW_TITLE_BROADCAST_AT = 'feature.merchant.wallet.balance.labels.row.title.broadcast-at'
}
export enum I18nFeatureMerchantWallets {
  COMPONENTS_STATUS_BANNER_TAG = 'feature.merchant.wallets.components.status-banner.tag',
  COMPONENTS_STATUS_BANNER_TITLE = 'feature.merchant.wallets.components.status-banner.title',
  COMPONENTS_STATUS_BANNER_DESCRIPTION = 'feature.merchant.wallets.components.status-banner.description',
  COMPONENTS_OPERATION_DEPLOY_WALLET_TITLE = 'feature.merchant.wallets.components.operation-deploy-wallet.title',
  COMPONENTS_OPERATION_DEPLOY_WALLET_TOOLTIP = 'feature.merchant.wallets.components.operation-deploy-wallet.tooltip',
  COMPONENTS_OPERATION_DEPLOY_WALLET_ERROR_UNKNOWN_STATUS = 'feature.merchant.wallets.components.operation-deploy-wallet.error.unknown-status',
  COMPONENTS_OPERATION_DEPLOY_WALLET_DIALOG_TITLE = 'feature.merchant.wallets.components.operation-deploy-wallet.dialog.title',
  COMPONENTS_OPERATION_DEPLOY_WALLET_DIALOG_DESCRIPTION = 'feature.merchant.wallets.components.operation-deploy-wallet.dialog.description',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_TITLE = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.title',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_ALREADY_DEPLOYED = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.error.already-deployed',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_NO_BLOCKCHAIN_DATA = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.error.no-blockchain-data',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_NO_WALLET_SIGNATURE = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.error.no-wallet-signature',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_INVALID_CHAIN_ID = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.error.invalid-chain-id',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_ERROR_UNSUPPORTED = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.error.unsupported',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_FAILED_MESSAGE = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.failed-message',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_FAILED_DESCRIPTION = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.failed-description',
  COMPONENTS_OPERATION_DEPLOY_WALLET_CONNECTOR_SUCCESS_MESSAGE = 'feature.merchant.wallets.components.operation-deploy-wallet.connector.success-message',
  HOOKS_ACTIONS_CONFIRM_OWNERSHIP_MESSAGE = 'feature.merchant.wallets.hooks.actions.confirm-ownership.message'
}
export enum I18nFeatureMerchantWalletTransfers {
  COMPONENTS_DIRECTION_VIEW_INCOMING = 'feature.merchant.wallet.transfers.components.direction-view.Incoming',
  COMPONENTS_DIRECTION_VIEW_OUTGOING = 'feature.merchant.wallet.transfers.components.direction-view.Outgoing',
  COMPONENTS_DOCUMENTATION_BANNER_MESSAGE = 'feature.merchant.wallet.transfers.components.documentation-banner.message',
  COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_LABEL = 'feature.merchant.wallet.transfers.components.filter-form.broadcast-at.from.label',
  COMPONENTS_FILTER_FORM_BROADCAST_AT_FROM_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.broadcast-at.from.placeholder',
  COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_LABEL = 'feature.merchant.wallet.transfers.components.filter-form.broadcast-at.to.label',
  COMPONENTS_FILTER_FORM_BROADCAST_AT_TO_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.broadcast-at.to.placeholder',
  COMPONENTS_FILTER_FORM_DIRECTION_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.direction.placeholder',
  COMPONENTS_FILTER_FORM_KIND_LABEL = 'feature.merchant.wallet.transfers.components.filter-form.kind.label',
  COMPONENTS_FILTER_FORM_KIND_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.kind.placeholder',
  COMPONENTS_FILTER_FORM_RECONCILIATION_STATUS_LABEL = 'feature.merchant.wallet.transfers.components.filter-form.reconciliation-status.label',
  COMPONENTS_FILTER_FORM_RECONCILIATION_STATUS_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.reconciliation-status.placeholder',
  COMPONENTS_FILTER_FORM_WALLET_LABEL = 'feature.merchant.wallet.transfers.components.filter-form.wallet.label',
  COMPONENTS_FILTER_FORM_WALLET_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.wallet.placeholder',
  COMPONENTS_FILTER_FORM_SENDER_LABEL = 'feature.merchant.wallet.transfers.components.filter-form.sender.label',
  COMPONENTS_FILTER_FORM_SENDER_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.sender.placeholder',
  COMPONENTS_FILTER_FORM_DESTINATION_LABEL = 'feature.merchant.wallet.transfers.components.filter-form.destination.label',
  COMPONENTS_FILTER_FORM_DESTINATION_PLACEHOLDER = 'feature.merchant.wallet.transfers.components.filter-form.destination.placeholder',
  COMPONENTS_KIND_VIEW_UNKNOWN = 'feature.merchant.wallet.transfers.components.kind-view.Unknown',
  COMPONENTS_KIND_VIEW_PAYMENTTRANSACTION = 'feature.merchant.wallet.transfers.components.kind-view.PaymentTransaction',
  COMPONENTS_KIND_VIEW_DONATIONTRANSACTION = 'feature.merchant.wallet.transfers.components.kind-view.DonationTransaction',
  COMPONENTS_KIND_VIEW_RECHARGETRANSACTION = 'feature.merchant.wallet.transfers.components.kind-view.RechargeTransaction',
  COMPONENTS_KIND_VIEW_SUBSCRIPTIONCHARGE = 'feature.merchant.wallet.transfers.components.kind-view.SubscriptionCharge',
  COMPONENTS_KIND_VIEW_SETTLEMENT = 'feature.merchant.wallet.transfers.components.kind-view.Settlement',
  COMPONENTS_KIND_VIEW_PAYOUT = 'feature.merchant.wallet.transfers.components.kind-view.Payout',
  COMPONENTS_KIND_VIEW_PROCESSINGFEE = 'feature.merchant.wallet.transfers.components.kind-view.ProcessingFee',
  COMPONENTS_KIND_VIEW_PARTNERFEE = 'feature.merchant.wallet.transfers.components.kind-view.PartnerFee',
  COMPONENTS_KIND_VIEW_PUSHCOLLECT = 'feature.merchant.wallet.transfers.components.kind-view.PushCollect',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_UNCHECKED = 'feature.merchant.wallet.transfers.components.reconciliation-status-view.Unchecked',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_UNCONFIRMED = 'feature.merchant.wallet.transfers.components.reconciliation-status-view.Unconfirmed',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_CONFIRMED = 'feature.merchant.wallet.transfers.components.reconciliation-status-view.Confirmed',
  COMPONENTS_REPORT_TITLE = 'feature.merchant.wallet.transfers.components.report.title',
  COMPONENTS_REPORT_SCHEDULE_PARAMETERS_BROADCAST_AT_RELATIVE_ITEM_LABEL = 'feature.merchant.wallet.transfers.components.report-schedule-parameters.broadcast-at-relative-item.label',
  COMPONENTS_TRANSFERS_TABLE_TRANSFER_DIALOG_TITLE = 'feature.merchant.wallet.transfers.components.transfers-table.transfer-dialog.title',
  COMPONENTS_TRANSFER_VIEW_ROW_TITLE_SETTLEMENT_LINK = 'feature.merchant.wallet.transfers.components.transfer-view.row-title.settlement-link',
  COMPONENTS_TRANSFER_VIEW_ROW_TITLE_RECONCILIATION_LINK = 'feature.merchant.wallet.transfers.components.transfer-view.row-title.reconciliation-link',
  COMPONENTS_TRANSFER_VIEW_ROW_TITLE_RECONCILIATION = 'feature.merchant.wallet.transfers.components.transfer-view.row-title.reconciliation',
  COMPONENTS_OPERATION_EXPORT_TITLE = 'feature.merchant.wallet.transfers.components.operation-export.title',
  COMPONENTS_OPERATION_EXPORT_TOOLTIP = 'feature.merchant.wallet.transfers.components.operation-export.tooltip',
  COMPONENTS_OPERATION_EXPORT_ERROR_MESSAGE = 'feature.merchant.wallet.transfers.components.operation-export.error-message',
  COMPONENTS_OPERATION_EXPORT_GENERATING_MESSAGE = 'feature.merchant.wallet.transfers.components.operation-export.generating-message',
  COMPONENTS_OPERATION_RELOAD_TRANSFER_TITLE = 'feature.merchant.wallet.transfers.components.operation-reload-transfer.title',
  COMPONENTS_OPERATION_RELOAD_TRANSFER_TOOLTIP = 'feature.merchant.wallet.transfers.components.operation-reload-transfer.tooltip',
  COMPONENTS_OPERATION_RELOAD_TRANSFER_FAILED_MESSAGE = 'feature.merchant.wallet.transfers.components.operation-reload-transfer.failed-message',
  COMPONENTS_OPERATION_RELOAD_TRANSFER_FAILED_DESCRIPTION = 'feature.merchant.wallet.transfers.components.operation-reload-transfer.failed-description',
  COMPONENTS_OPERATION_RELOAD_TRANSFER_SUCCESS_MESSAGE = 'feature.merchant.wallet.transfers.components.operation-reload-transfer.success-message',
  LABELS_ROW_TITLE_TX = 'feature.merchant.wallet.transfers.labels.row.title.tx',
  LABELS_ROW_TITLE_WALLET = 'feature.merchant.wallet.transfers.labels.row.title.wallet',
  LABELS_ROW_TITLE_SENDER = 'feature.merchant.wallet.transfers.labels.row.title.sender',
  LABELS_ROW_TITLE_DESTINATION = 'feature.merchant.wallet.transfers.labels.row.title.destination',
  LABELS_ROW_TITLE_AMOUNT = 'feature.merchant.wallet.transfers.labels.row.title.amount',
  LABELS_ROW_TITLE_KIND = 'feature.merchant.wallet.transfers.labels.row.title.kind',
  LABELS_ROW_TITLE_DIRECTION = 'feature.merchant.wallet.transfers.labels.row.title.direction',
  LABELS_ROW_TITLE_BROADCAST_AT = 'feature.merchant.wallet.transfers.labels.row.title.broadcast-at'
}
export enum I18nFeatureOnboard {
  COMPONENTS_CONTAINER_TITLE = 'feature.onboard.components.container.title',
  COMPONENTS_CONTAINER_DESCRIPTION = 'feature.onboard.components.container.description',
  COMPONENTS_STEPS_COMPANY_TITLE = 'feature.onboard.components.steps.company.title',
  COMPONENTS_STEPS_COMPANY_FORM_COMPANY_NAME_LABEL = 'feature.onboard.components.steps.company.form.company-name.label',
  COMPONENTS_STEPS_COMPANY_FORM_COMPANY_WEBSITE_LABEL = 'feature.onboard.components.steps.company.form.company-website.label',
  COMPONENTS_STEPS_COMPANY_FORM_COMPANY_EMAIL_LABEL = 'feature.onboard.components.steps.company.form.company-email.label',
  COMPONENTS_STEPS_COMPANY_SUBMIT_TITLE = 'feature.onboard.components.steps.company.submit-title',
  COMPONENTS_STEPS_CONGRATULATIONS_TITLE = 'feature.onboard.components.steps.congratulations.title',
  COMPONENTS_STEPS_CONGRATULATIONS_CONTENT = 'feature.onboard.components.steps.congratulations.content',
  COMPONENTS_STEPS_CONGRATULATIONS_SUBMIT_TITLE = 'feature.onboard.components.steps.congratulations.submit-title'
}
export enum I18nFeaturePayments {
  COMPONENTS_DOCUMENTATION_BANNER_MESSAGE = 'feature.payments.components.documentation-banner.message',
  COMPONENTS_DOCUMENTATION_LINK_TITLE = 'feature.payments.components.documentation-link.title',
  COMPONENTS_ERROR_VIEW_CODES_PAYMENTADDRESSNOTFOUND = 'feature.payments.components.error-view.codes.PaymentAddressNotFound',
  COMPONENTS_ERROR_VIEW_CODES_AMOUNTNOTFOUND = 'feature.payments.components.error-view.codes.AmountNotFound',
  COMPONENTS_ERROR_VIEW_CODES_TOKENNOTFOUND = 'feature.payments.components.error-view.codes.TokenNotFound',
  COMPONENTS_ERROR_VIEW_CODES_TOKENNOTSUPPORTED = 'feature.payments.components.error-view.codes.TokenNotSupported',
  COMPONENTS_ERROR_VIEW_CODES_INVALIDAMOUNT = 'feature.payments.components.error-view.codes.InvalidAmount',
  COMPONENTS_ERROR_VIEW_CODES_INVALIDTOKEN = 'feature.payments.components.error-view.codes.InvalidToken',
  COMPONENTS_ERROR_VIEW_CODES_UNDERPAID = 'feature.payments.components.error-view.codes.UnderPaid',
  COMPONENTS_ERROR_VIEW_CODES_OVERPAID = 'feature.payments.components.error-view.codes.OverPaid',
  COMPONENTS_CREATE_PAYMENT_FORM_EXPIRES_AT_PLACEHOLDER = 'feature.payments.components.create-payment-form.expires-at.placeholder',
  COMPONENTS_CREATE_PAYMENT_FORM_EXPIRES_AT_REQUIRED = 'feature.payments.components.create-payment-form.expires-at.required',
  COMPONENTS_CREATE_PAYMENT_FORM_EXPIRES_AT_TOO_SMALL = 'feature.payments.components.create-payment-form.expires-at.too-small',
  COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NO_WALLET_ERROR_MESSAGE = 'feature.payments.components.create-payment-form.forwarder-settings.input.errors.no-wallet.error-message',
  COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NO_WALLET_ERROR_DESCRIPTION = 'feature.payments.components.create-payment-form.forwarder-settings.input.errors.no-wallet.error-description',
  COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NOT_SELECTED_ERROR_MESSAGE = 'feature.payments.components.create-payment-form.forwarder-settings.input.errors.not-selected.error-message',
  COMPONENTS_CREATE_PAYMENT_FORM_FORWARDER_SETTINGS_INPUT_ERRORS_NOT_SELECTED_ERROR_DESCRIPTION = 'feature.payments.components.create-payment-form.forwarder-settings.input.errors.not-selected.error-description',
  COMPONENTS_CREATE_PAYMENT_FORM_METADATA_PLACEHOLDER = 'feature.payments.components.create-payment-form.metadata.placeholder',
  COMPONENTS_CREATE_PAYMENT_FORM_METADATA_HELP = 'feature.payments.components.create-payment-form.metadata.help',
  COMPONENTS_CREATE_PAYMENT_FORM_METADATA_ERROR_INVALID_JSON = 'feature.payments.components.create-payment-form.metadata.error.invalid-json',
  COMPONENTS_CREATE_PAYMENT_FORM_DESCRIPTION_REQUIRED = 'feature.payments.components.create-payment-form.description.required',
  COMPONENTS_CREATE_PAYMENT_FORM_DESCRIPTION_PLACEHOLDER = 'feature.payments.components.create-payment-form.description.placeholder',
  COMPONENTS_CREATE_PAYMENT_FORM_DESCRIPTION_TOO_LARGE = 'feature.payments.components.create-payment-form.description.too-large',
  COMPONENTS_CREATE_PAYMENT_FORM_SUBMIT_BUTTON = 'feature.payments.components.create-payment-form.submit-button',
  COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_TITLE = 'feature.payments.components.operation-generate-payment-url.title',
  COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_SUCCESS_FORM_TITLE = 'feature.payments.components.operation-generate-payment-url.success.form.title',
  COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_LINK_TITLE = 'feature.payments.components.operation-generate-payment-url.link.title',
  COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_ERROR_MESSAGE = 'feature.payments.components.operation-generate-payment-url.error-message',
  COMPONENTS_OPERATION_EXPORT_TITLE = 'feature.payments.components.operation-export.title',
  COMPONENTS_OPERATION_EXPORT_TOOLTIP = 'feature.payments.components.operation-export.tooltip',
  COMPONENTS_OPERATION_EXPORT_ERROR_MESSAGE = 'feature.payments.components.operation-export.error-message',
  COMPONENTS_OPERATION_EXPORT_GENERATING_MESSAGE = 'feature.payments.components.operation-export.generating-message',
  COMPONENTS_OPERATION_REFRESH_PAYMENT_TITLE = 'feature.payments.components.operation-refresh-payment.title',
  COMPONENTS_OPERATION_REFRESH_PAYMENT_TOOLTIP = 'feature.payments.components.operation-refresh-payment.tooltip',
  COMPONENTS_OPERATION_REFRESH_PAYMENT_FAILED_MESSAGE = 'feature.payments.components.operation-refresh-payment.failed-message',
  COMPONENTS_OPERATION_REFRESH_PAYMENT_FAILED_DESCRIPTION = 'feature.payments.components.operation-refresh-payment.failed-description',
  COMPONENTS_OPERATION_REFRESH_PAYMENT_SUCCESS_MESSAGE = 'feature.payments.components.operation-refresh-payment.success-message',
  COMPONENTS_PAYMENT_FILTER_CREATED_AT_FROM_LABEL = 'feature.payments.components.payment-filter.created-at.from.label',
  COMPONENTS_PAYMENT_FILTER_CREATED_AT_TO_LABEL = 'feature.payments.components.payment-filter.created-at.to.label',
  COMPONENTS_PAYMENT_FILTER_STATUS_LABEL = 'feature.payments.components.payment-filter.status.label',
  COMPONENTS_PAYMENT_FILTER_STATUS_PLACEHOLDER = 'feature.payments.components.payment-filter.status.placeholder',
  COMPONENTS_PAYMENT_STATUS_VIEW_CREATED = 'feature.payments.components.payment-status-view.Created',
  COMPONENTS_PAYMENT_STATUS_VIEW_SUCCEEDED = 'feature.payments.components.payment-status-view.Succeeded',
  COMPONENTS_PAYMENT_STATUS_VIEW_INPROGRESS = 'feature.payments.components.payment-status-view.InProgress',
  COMPONENTS_PAYMENT_STATUS_VIEW_EXPIRED = 'feature.payments.components.payment-status-view.Expired',
  COMPONENTS_PAYMENT_STATUS_VIEW_FAILED = 'feature.payments.components.payment-status-view.Failed',
  COMPONENTS_PAYMENTS_TABLE_PAYMENT_DIALOG_TITLE = 'feature.payments.components.payments-table.payment-dialog.title',
  COMPONENTS_PAYMENTS_REPORT_TITLE = 'feature.payments.components.payments-report.title',
  COMPONENTS_PAYMENTS_REPORT_SCHEDULE_PARAMETERS_FORM_CREATED_AT_RELATIVE_ITEM_LABEL = 'feature.payments.components.payments-report-schedule-parameters-form.created-at-relative-item.label',
  COMPONENTS_TRANSACTION_STATUS_CONFIRMED = 'feature.payments.components.transaction-status.Confirmed',
  COMPONENTS_TRANSACTION_STATUS_CONFIRMING = 'feature.payments.components.transaction-status.Confirming',
  LABELS_ROW_TITLE_ID = 'feature.payments.labels.row.title.id',
  LABELS_ROW_TITLE_AMOUNT = 'feature.payments.labels.row.title.amount',
  LABELS_ROW_TITLE_PAID_AMOUNT = 'feature.payments.labels.row.title.paid-amount',
  LABELS_ROW_TITLE_PAID_AT = 'feature.payments.labels.row.title.paid-at',
  LABELS_ROW_TITLE_STATUS = 'feature.payments.labels.row.title.status',
  LABELS_ROW_TITLE_PAYMENT_ADDRESS = 'feature.payments.labels.row.title.payment-address',
  LABELS_ROW_TITLE_PAYMENT_LINK = 'feature.payments.labels.row.title.payment-link',
  LABELS_ROW_TITLE_EXPIRES_AT = 'feature.payments.labels.row.title.expires-at',
  LABELS_ROW_TITLE_CREATED_AT = 'feature.payments.labels.row.title.created-at',
  LABELS_ROW_TITLE_ERROR = 'feature.payments.labels.row.title.error',
  LABELS_ROW_TITLE_METADATA = 'feature.payments.labels.row.title.metadata',
  LABELS_ROW_TITLE_DESCRIPTION = 'feature.payments.labels.row.title.description'
}
export enum I18nFeaturePaymentTransactions {
  COMPONENTS_OPERATION_REFRESH_PAYMENT_TRANSACTION_TOOLTIP = 'feature.payment.transactions.components.operation-refresh-payment-transaction.tooltip',
  COMPONENTS_OPERATION_REFRESH_PAYMENT_TRANSACTION_FAILED_MESSAGE = 'feature.payment.transactions.components.operation-refresh-payment-transaction.failed-message',
  COMPONENTS_OPERATION_REFRESH_PAYMENT_TRANSACTION_SUCCESS_MESSAGE = 'feature.payment.transactions.components.operation-refresh-payment-transaction.success-message',
  COMPONENTS_PAYMENT_TRANSACTIONS_TABLE_PAYMENT_DIALOG_TITLE = 'feature.payment.transactions.components.payment-transactions-table.payment-dialog.title',
  COMPONENTS_PAYMENT_TRANSACTION_VIEW_ROW_TITLE_SETTLEMENT = 'feature.payment.transactions.components.payment-transaction.view.row-title.settlement',
  COMPONENTS_PAYMENT_TRANSACTION_VIEW_ROW_TITLE_PAYMENT = 'feature.payment.transactions.components.payment-transaction.view.row-title.payment',
  LABELS_ROW_TITLE_TX_ID = 'feature.payment.transactions.labels.row.title.tx-id',
  LABELS_ROW_TITLE_AMOUNT = 'feature.payment.transactions.labels.row.title.amount',
  LABELS_ROW_TITLE_STATUS = 'feature.payment.transactions.labels.row.title.status',
  LABELS_ROW_TITLE_IDX = 'feature.payment.transactions.labels.row.title.idx'
}
export enum I18nFeaturePayouts {
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_LABEL = 'feature.payouts.components.edit-payout-form.components.title-item.label',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_PLACEHOLDER = 'feature.payouts.components.edit-payout-form.components.title-item.placeholder',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_ERRORS_REQUIRED = 'feature.payouts.components.edit-payout-form.components.title-item.errors.required',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_TITLE_ITEM_ERRORS_TOO_SHORT = 'feature.payouts.components.edit-payout-form.components.title-item.errors.too-short',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_PERSIST_OPERATION_SAVE_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.persist-operation.save.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_PERSIST_OPERATION_LOAD_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.persist-operation.load.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_ADD_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.add.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.edit.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_SAVE = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.edit.save',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_CANCEL = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.edit.cancel',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_EDIT_ERRORS_ADDRESS_EXISTS = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.edit.errors.address-exists',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_DELETE_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.delete.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_OPERATIONS_DELETE_CONFIRMATION = 'feature.payouts.components.edit-payout-form.components.destinations-item.operations.delete.confirmation',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_FILTER_BANNER_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.filter.banner.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_FILTER_APPLY_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.filter.apply.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_FILTER_RESET_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.filter.reset.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_FILTER_ADDRESS_PLACEHOLDER = 'feature.payouts.components.edit-payout-form.components.destinations-item.filter.address.placeholder',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_COL_ADDRESS_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.col.address.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_COL_ADDRESS_FILTERED_TITLE = 'feature.payouts.components.edit-payout-form.components.destinations-item.col.address.filtered.title',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_COL_AMOUNT = 'feature.payouts.components.edit-payout-form.components.destinations-item.col.amount',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_FOOTER_TOTAL = 'feature.payouts.components.edit-payout-form.components.destinations-item.footer.total',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_ERRORS_NO_ASSET = 'feature.payouts.components.edit-payout-form.components.destinations-item.errors.no-asset',
  COMPONENTS_EDIT_PAYOUT_FORM_COMPONENTS_DESTINATIONS_ITEM_ERRORS_REQUIRED = 'feature.payouts.components.edit-payout-form.components.destinations-item.errors.required',
  COMPONENTS_EDIT_PAYOUT_FORM_SUBMIT_TITLE = 'feature.payouts.components.edit-payout-form.submit.title',
  COMPONENTS_DOCUMENTATION_BANNER_MESSAGE = 'feature.payouts.components.documentation-banner.message',
  COMPONENTS_OPERATION_DELETE_PAYOUT_TITLE = 'feature.payouts.components.operation-delete-payout.title',
  COMPONENTS_OPERATION_DELETE_PAYOUT_CONFIRMATION_TITLE = 'feature.payouts.components.operation-delete-payout.confirmation-title',
  COMPONENTS_OPERATION_DELETE_PAYOUT_ERROR_MESSAGE = 'feature.payouts.components.operation-delete-payout.error-message',
  COMPONENTS_OPERATION_DELETE_PAYOUT_SUCCESS_MESSAGE = 'feature.payouts.components.operation-delete-payout.success-message',
  COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_TITLE = 'feature.payouts.components.operation-update-payout-title.title',
  COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_TOOLTIP = 'feature.payouts.components.operation-update-payout-title.tooltip',
  COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_DIALOG_TITLE = 'feature.payouts.components.operation-update-payout-title.dialog.title',
  COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_DIALOG_ERROR_COMMON = 'feature.payouts.components.operation-update-payout-title.dialog.error-common',
  COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_SUCCESS_MESSAGE = 'feature.payouts.components.operation-update-payout-title.success-message',
  COMPONENTS_OPERATION_UPDATE_PAYOUT_TITLE_FAILED_MESSAGE = 'feature.payouts.components.operation-update-payout-title.failed-message',
  COMPONENTS_OPERATION_START_PAYOUT_TITLE = 'feature.payouts.components.operation-start-payout.title',
  COMPONENTS_OPERATION_START_PAYOUT_TOOLTIP = 'feature.payouts.components.operation-start-payout.tooltip',
  COMPONENTS_OPERATION_START_PAYOUT_DIALOG_TITLE = 'feature.payouts.components.operation-start-payout.dialog.title',
  COMPONENTS_OPERATION_START_PAYOUT_DIALOG_DESCRIPTION = 'feature.payouts.components.operation-start-payout.dialog.description',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_TITLE = 'feature.payouts.components.operation-start-payout.connector.title',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_STATUS = 'feature.payouts.components.operation-start-payout.connector.error.invalid-status',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_CHAIN_ID = 'feature.payouts.components.operation-start-payout.connector.error.invalid-chain-id',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INVALID_ACCOUNT = 'feature.payouts.components.operation-start-payout.connector.error.invalid-account',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_UNSUPPORTED = 'feature.payouts.components.operation-start-payout.connector.error.unsupported',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_ERROR_INSUFFICIENT_BALANCE = 'feature.payouts.components.operation-start-payout.connector.error.insufficient-balance',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_FAILED_MESSAGE = 'feature.payouts.components.operation-start-payout.connector.failed-message',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_SUCCESS_MESSAGE = 'feature.payouts.components.operation-start-payout.connector.success-message',
  COMPONENTS_OPERATION_START_PAYOUT_CONNECTOR_SUCCESS_DESCRIPTION = 'feature.payouts.components.operation-start-payout.connector.success-description',
  COMPONENTS_OPERATION_REFRESH_PAYOUT_TOOLTIP = 'feature.payouts.components.operation-refresh-payout.tooltip',
  COMPONENTS_OPERATION_REFRESH_PAYOUT_MESSAGE_SUCCESS = 'feature.payouts.components.operation-refresh-payout.message-success',
  COMPONENTS_PAYOUT_STATUS_VIEW_CREATED = 'feature.payouts.components.payout-status-view.Created',
  COMPONENTS_PAYOUT_STATUS_VIEW_PENDING = 'feature.payouts.components.payout-status-view.Pending',
  COMPONENTS_PAYOUT_STATUS_VIEW_PARTLYSUCCEEDED = 'feature.payouts.components.payout-status-view.PartlySucceeded',
  COMPONENTS_PAYOUT_STATUS_VIEW_SUCCEEDED = 'feature.payouts.components.payout-status-view.Succeeded',
  COMPONENTS_PAYOUT_STATUS_VIEW_FAILED = 'feature.payouts.components.payout-status-view.Failed',
  COMPONENTS_PAYOUT_BATCH_STATUS_VIEW_PENDING = 'feature.payouts.components.payout-batch-status-view.Pending',
  COMPONENTS_PAYOUT_BATCH_STATUS_VIEW_SUCCEEDED = 'feature.payouts.components.payout-batch-status-view.Succeeded',
  COMPONENTS_PAYOUT_BATCH_STATUS_VIEW_FAILED = 'feature.payouts.components.payout-batch-status-view.Failed',
  COMPONENTS_PAYOUT_TABLE_DIALOG_TITLE = 'feature.payouts.components.payout-table.dialog.title',
  COMPONENTS_PAYOUT_FILTER_FORM_CREATED_AT_FROM_LABEL = 'feature.payouts.components.payout-filter-form.created-at.from.label',
  COMPONENTS_PAYOUT_FILTER_FORM_CREATED_AT_TO_LABEL = 'feature.payouts.components.payout-filter-form.created-at.to.label',
  COMPONENTS_PAYOUT_FILTER_FORM_STATUS_LABEL = 'feature.payouts.components.payout-filter-form.status.label',
  COMPONENTS_PAYOUT_FILTER_FORM_STATUS_PLACEHOLDER = 'feature.payouts.components.payout-filter-form.status.placeholder',
  COMPONENTS_PAYOUT_FILTER_FORM_TITLE_LABEL = 'feature.payouts.components.payout-filter-form.title.label',
  COMPONENTS_PAYOUT_FILTER_FORM_TITLE_PLACEHOLDER = 'feature.payouts.components.payout-filter-form.title.placeholder',
  COMPONENTS_PAYOUT_DESTINATION_FILTER_FORM_ADDRESS_LABEL = 'feature.payouts.components.payout-destination-filter-form.address.label',
  COMPONENTS_PAYOUT_DESTINATION_FILTER_FORM_ADDRESS_PLACEHOLDER = 'feature.payouts.components.payout-destination-filter-form.address.placeholder',
  COMPONENTS_PAYOUT_DESTINATION_DETAILED_FILTER_FORM_STATUS_LABEL = 'feature.payouts.components.payout-destination-detailed-filter-form.status.label',
  COMPONENTS_PAYOUT_DESTINATION_DETAILED_FILTER_FORM_STATUS_PLACEHOLDER = 'feature.payouts.components.payout-destination-detailed-filter-form.status.placeholder',
  COMPONENTS_PAYOUT_TYPE_LABEL_SIMPLE = 'feature.payouts.components.payout-type-label.simple',
  COMPONENTS_PAYOUT_TYPE_LABEL_MERKLE = 'feature.payouts.components.payout-type-label.merkle',
  COMPONENTS_PAYOUT_STATUS_BANNER_PENDING_TAG = 'feature.payouts.components.payout-status-banner.pending.tag',
  COMPONENTS_PAYOUT_STATUS_BANNER_PENDING_TITLE = 'feature.payouts.components.payout-status-banner.pending.title',
  COMPONENTS_PAYOUT_STATUS_BANNER_PENDING_DESCRIPTION = 'feature.payouts.components.payout-status-banner.pending.description',
  COMPONENTS_PAYOUT_STATUS_BANNER_STALE_TAG = 'feature.payouts.components.payout-status-banner.stale.tag',
  COMPONENTS_PAYOUT_STATUS_BANNER_STALE_TITLE = 'feature.payouts.components.payout-status-banner.stale.title',
  COMPONENTS_PAYOUT_STATUS_BANNER_STALE_DESCRIPTION = 'feature.payouts.components.payout-status-banner.stale.description',
  COMPONENTS_PAYOUT_VIEW_SETTLED_AMOUNT_HELP = 'feature.payouts.components.payout-view.settled-amount.help',
  COMPONENTS_PAYOUT_VIEW_TOTAL_AMOUNT_HELP = 'feature.payouts.components.payout-view.total-amount.help',
  COMPONENTS_PENDING_ALERT_MESSAGE = 'feature.payouts.components.pending-alert.message',
  LABELS_ROW_TITLE_PAYOUT_ID = 'feature.payouts.labels.row.title.payout.id',
  LABELS_ROW_TITLE_PAYOUT_TITLE = 'feature.payouts.labels.row.title.payout.title',
  LABELS_ROW_TITLE_PAYOUT_DESTINATION_COUNT = 'feature.payouts.labels.row.title.payout.destination-count',
  LABELS_ROW_TITLE_PAYOUT_STATUS = 'feature.payouts.labels.row.title.payout.status',
  LABELS_ROW_TITLE_PAYOUT_CREATED_AT = 'feature.payouts.labels.row.title.payout.created-at',
  LABELS_ROW_TITLE_PAYOUT_EXPIRES_AT = 'feature.payouts.labels.row.title.payout.expires-at',
  LABELS_ROW_TITLE_PAYOUT_SETTLED_AMOUNT = 'feature.payouts.labels.row.title.payout.settled-amount',
  LABELS_ROW_TITLE_PAYOUT_TOTAL_AMOUNT = 'feature.payouts.labels.row.title.payout.total-amount',
  LABELS_ROW_TITLE_DESTINATION_ID = 'feature.payouts.labels.row.title.destination.id',
  LABELS_ROW_TITLE_DESTINATION_ADDRESS = 'feature.payouts.labels.row.title.destination.address',
  LABELS_ROW_TITLE_DESTINATION_AMOUNT = 'feature.payouts.labels.row.title.destination.amount',
  LABELS_ROW_TITLE_BATCH_ORDER = 'feature.payouts.labels.row.title.batch.order',
  LABELS_ROW_TITLE_BATCH_STATUS = 'feature.payouts.labels.row.title.batch.status',
  LABELS_ROW_TITLE_BATCH_TX = 'feature.payouts.labels.row.title.batch.tx',
  LABELS_ROW_TITLE_BATCH_SENT_AT = 'feature.payouts.labels.row.title.batch.sent-at',
  LABELS_ROW_TITLE_BATCH_SETTLEMENT = 'feature.payouts.labels.row.title.batch.settlement'
}
export enum I18nFeatureRecaptcha {
  MESSAGE_INVALID_TOKEN = 'feature.recaptcha.message-invalid-token',
  LICENSE = 'feature.recaptcha.license',
  RECAPTCHA_V2_MODAL_TITLE = 'feature.recaptcha.recaptcha-v2.modal-title',
  RECAPTCHA_V2_MESSAGE = 'feature.recaptcha.recaptcha-v2.message',
  ERRORS_PUBLICKEY_UNAVAILABLE = 'feature.recaptcha.errors.publicKey.unavailable'
}
export enum I18nFeatureRecharges {
  COMPONENTS_DOCUMENTATION_BANNER_MESSAGE = 'feature.recharges.components.documentation-banner.message',
  COMPONENTS_DOCUMENTATION_LINK_TITLE = 'feature.recharges.components.documentation-link.title',
  COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_PLACEHOLDER = 'feature.recharges.components.create-recharge-address-form.cid.placeholder',
  COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_LABEL = 'feature.recharges.components.create-recharge-address-form.cid.label',
  COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_ERRORS_REQUIRED = 'feature.recharges.components.create-recharge-address-form.cid.errors.required',
  COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_ERRORS_MAX_LENGTH = 'feature.recharges.components.create-recharge-address-form.cid.errors.max-length',
  COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_CID_ERRORS_MIN_LENGTH = 'feature.recharges.components.create-recharge-address-form.cid.errors.min-length',
  COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_ERRORS_DUPLICATE_CID = 'feature.recharges.components.create-recharge-address-form.errors.duplicate-cid',
  COMPONENTS_CREATE_RECHARGE_ADDRESS_FORM_SUBMIT_BUTTON = 'feature.recharges.components.create-recharge-address-form.submit-button',
  COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_TITLE = 'feature.recharges.components.operation-generate-recharge-url.title',
  COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_SUCCESS_FORM_TITLE = 'feature.recharges.components.operation-generate-recharge-url.success.form.title',
  COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_LINK_TITLE = 'feature.recharges.components.operation-generate-recharge-url.link.title',
  COMPONENTS_OPERATION_GENERATE_RECHARGE_URL_ERROR_MESSAGE = 'feature.recharges.components.operation-generate-recharge-url.error-message',
  COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_TOOLTIP = 'feature.recharges.components.operation-refresh-recharge-address.tooltip',
  COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_SUCCESS_MESSAGE = 'feature.recharges.components.operation-refresh-recharge-address.success-message',
  COMPONENTS_OPERATION_REFRESH_RECHARGE_TRANSACTION_TOOLTIP = 'feature.recharges.components.operation-refresh-recharge-transaction.tooltip',
  COMPONENTS_OPERATION_REFRESH_RECHARGE_TRANSACTION_SUCCESS_MESSAGE = 'feature.recharges.components.operation-refresh-recharge-transaction.success-message',
  COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_DEPLOY_TX_TOOLTIP = 'feature.recharges.components.operation-refresh-recharge-address-deploy-tx.tooltip',
  COMPONENTS_OPERATION_REFRESH_RECHARGE_ADDRESS_DEPLOY_TX_SUCCESS_MESSAGE = 'feature.recharges.components.operation-refresh-recharge-address-deploy-tx.success-message',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_CREATED_AT_FROM_LABEL = 'feature.recharges.components.recharge-address-filter.created-at.from.label',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_CREATED_AT_TO_LABEL = 'feature.recharges.components.recharge-address-filter.created-at.to.label',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_DEPLOY_STATUS_LABEL = 'feature.recharges.components.recharge-address-filter.deploy-status.label',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_DEPLOY_STATUS_PLACEHOLDER = 'feature.recharges.components.recharge-address-filter.deploy-status.placeholder',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_CID_LABEL = 'feature.recharges.components.recharge-address-filter.cid.label',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_CID_PLACEHOLDER = 'feature.recharges.components.recharge-address-filter.cid.placeholder',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_ADDRESS_LABEL = 'feature.recharges.components.recharge-address-filter.address.label',
  COMPONENTS_RECHARGE_ADDRESS_FILTER_ADDRESS_PLACEHOLDER = 'feature.recharges.components.recharge-address-filter.address.placeholder',
  COMPONENTS_RECHARGE_ADDRESSES_TABLE_RECHARGE_ADDRESS_DIALOG_TITLE = 'feature.recharges.components.recharge-addresses-table.recharge-address-dialog.title',
  COMPONENTS_RECHARGE_TRANSACTION_FILTER_BROADCAST_AT_FROM_LABEL = 'feature.recharges.components.recharge-transaction-filter.broadcast-at.from.label',
  COMPONENTS_RECHARGE_TRANSACTION_FILTER_BROADCAST_AT_TO_LABEL = 'feature.recharges.components.recharge-transaction-filter.broadcast-at.to.label',
  COMPONENTS_RECHARGE_TRANSACTION_FILTER_FROM_ADDRESS_LABEL = 'feature.recharges.components.recharge-transaction-filter.from-address.label',
  COMPONENTS_RECHARGE_TRANSACTION_FILTER_FROM_ADDRESS_PLACEHOLDER = 'feature.recharges.components.recharge-transaction-filter.from-address.placeholder',
  LABELS_ADDRESS_ROW_TITLE_ID = 'feature.recharges.labels.address.row.title.id',
  LABELS_ADDRESS_ROW_TITLE_CID = 'feature.recharges.labels.address.row.title.cid',
  LABELS_ADDRESS_ROW_TITLE_BALANCE = 'feature.recharges.labels.address.row.title.balance',
  LABELS_ADDRESS_ROW_TITLE_ADDRESS = 'feature.recharges.labels.address.row.title.address',
  LABELS_ADDRESS_ROW_TITLE_DEPLOY_STATUS = 'feature.recharges.labels.address.row.title.deploy-status',
  LABELS_ADDRESS_ROW_TITLE_CREATED_AT = 'feature.recharges.labels.address.row.title.created-at'
}
export enum I18nFeatureReports {
  LABELS_ROW_TITLE_ID = 'feature.reports.labels.row.title.id',
  LABELS_ROW_TITLE_TEMPLATE = 'feature.reports.labels.row.title.template',
  LABELS_ROW_TITLE_TYPE = 'feature.reports.labels.row.title.type',
  LABELS_ROW_TITLE_STATUS = 'feature.reports.labels.row.title.status',
  LABELS_ROW_TITLE_CREATED_AT = 'feature.reports.labels.row.title.created-at',
  LABELS_ROW_TITLE_ERROR = 'feature.reports.labels.row.title.error',
  COMPONENTS_RESULT_NOTIFICATION_SUCCESS_MESSAGE = 'feature.reports.components.result-notification.success-message',
  COMPONENTS_RESULT_NOTIFICATION_ERROR_MESSAGE = 'feature.reports.components.result-notification.error-message',
  COMPONENTS_OPERATION_DELETE_REPORT_TITLE = 'feature.reports.components.operation-delete-report.title',
  COMPONENTS_OPERATION_DELETE_REPORT_CONFIRMATION_TITLE = 'feature.reports.components.operation-delete-report.confirmation-title',
  COMPONENTS_OPERATION_DELETE_REPORT_ERROR_MESSAGE = 'feature.reports.components.operation-delete-report.error-message',
  COMPONENTS_OPERATION_DELETE_REPORT_SUCCESS_MESSAGE = 'feature.reports.components.operation-delete-report.success-message',
  COMPONENTS_OPERATION_DOWNLOAD_REPORT_TITLE = 'feature.reports.components.operation-download-report.title',
  COMPONENTS_OPERATION_DOWNLOAD_REPORT_ERROR_MESSAGE = 'feature.reports.components.operation-download-report.error-message',
  COMPONENTS_OPERATION_REFRESH_REPORT_TOOLTIP = 'feature.reports.components.operation-refresh-report.tooltip',
  COMPONENTS_OPERATION_REFRESH_REPORT_FAILED_MESSAGE = 'feature.reports.components.operation-refresh-report.failed-message',
  COMPONENTS_OPERATION_REFRESH_REPORT_SUCCESS_MESSAGE = 'feature.reports.components.operation-refresh-report.success-message',
  COMPONENTS_PARAMETERS_VIEW_TITLE = 'feature.reports.components.parameters-view.title',
  COMPONENTS_STATUS_VIEW_LABEL_GENERATING = 'feature.reports.components.status-view.label.Generating',
  COMPONENTS_STATUS_VIEW_LABEL_SUCCESS = 'feature.reports.components.status-view.label.Success',
  COMPONENTS_STATUS_VIEW_LABEL_ERROR = 'feature.reports.components.status-view.label.Error',
  COMPONENTS_TYPE_VIEW_LABEL_PDF = 'feature.reports.components.type-view.label.PDF',
  COMPONENTS_TYPE_VIEW_LABEL_XLS = 'feature.reports.components.type-view.label.XLS',
  COMPONENTS_TYPE_VIEW_LABEL_CSV = 'feature.reports.components.type-view.label.CSV',
  COMPONENTS_TYPE_VIEW_LABEL_TXT = 'feature.reports.components.type-view.label.TXT',
  COMPONENTS_TYPE_VIEW_LABEL_DOC = 'feature.reports.components.type-view.label.DOC',
  COMPONENTS_TYPE_VIEW_LABEL_RTF = 'feature.reports.components.type-view.label.RTF',
  COMPONENTS_TYPE_VIEW_LABEL_UNKNOWN = 'feature.reports.components.type-view.label.Unknown',
  COMPONENTS_REPORTS_TABLE_DIALOG_TITLE = 'feature.reports.components.reports-table.dialog.title',
  COMPONENTS_REPORTS_FILTER_CREATED_AT_FROM_LABEL = 'feature.reports.components.reports-filter.created-at.from.label',
  COMPONENTS_REPORTS_FILTER_CREATED_AT_TO_LABEL = 'feature.reports.components.reports-filter.created-at.to.label',
  COMPONENTS_REPORTS_FILTER_STATUS_LABEL = 'feature.reports.components.reports-filter.status.label',
  COMPONENTS_REPORTS_FILTER_STATUS_PLACEHOLDER = 'feature.reports.components.reports-filter.status.placeholder',
  COMPONENTS_REPORTS_FILTER_TEMPLATE_LABEL = 'feature.reports.components.reports-filter.template.label',
  COMPONENTS_REPORTS_FILTER_TEMPLATE_PLACEHOLDER = 'feature.reports.components.reports-filter.template.placeholder'
}
export enum I18nFeatureReportSchedules {
  LABELS_ROW_TITLE_ID = 'feature.report.schedules.labels.row.title.id',
  LABELS_ROW_TITLE_TEMPLATE = 'feature.report.schedules.labels.row.title.template',
  LABELS_ROW_TITLE_STATUS = 'feature.report.schedules.labels.row.title.status',
  LABELS_ROW_TITLE_DISABLE_REASON = 'feature.report.schedules.labels.row.title.disable-reason',
  LABELS_ROW_TITLE_CRON = 'feature.report.schedules.labels.row.title.cron',
  LABELS_ROW_TITLE_NEXT_AT = 'feature.report.schedules.labels.row.title.next-at',
  LABELS_ROW_TITLE_CREATED_AT = 'feature.report.schedules.labels.row.title.created-at',
  COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE_TITLE = 'feature.report.schedules.components.operation-change-status.activate.title',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_TITLE = 'feature.report.schedules.components.operation-change-status.deactivate.title',
  COMPONENTS_OPERATION_CHANGE_STATUS_ERROR_MESSAGE = 'feature.report.schedules.components.operation-change-status.error-message',
  COMPONENTS_OPERATION_CHANGE_STATUS_SUCCESS_MESSAGE = 'feature.report.schedules.components.operation-change-status.success-message',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_TITLE = 'feature.report.schedules.components.operation-change-status.deactivate-reason-form.title',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_REASON_LABEL = 'feature.report.schedules.components.operation-change-status.deactivate-reason-form.reason.label',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_REASON_PLACEHOLDER = 'feature.report.schedules.components.operation-change-status.deactivate-reason-form.reason.placeholder',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_REASON_REQUIRED = 'feature.report.schedules.components.operation-change-status.deactivate-reason-form.reason.required',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_REASON_FORM_SUBMIT = 'feature.report.schedules.components.operation-change-status.deactivate-reason-form.submit',
  COMPONENTS_OPERATION_DELETE_TITLE = 'feature.report.schedules.components.operation-delete.title',
  COMPONENTS_OPERATION_DELETE_CONFIRMATION_TITLE = 'feature.report.schedules.components.operation-delete.confirmation-title',
  COMPONENTS_OPERATION_DELETE_ERROR_MESSAGE = 'feature.report.schedules.components.operation-delete.error-message',
  COMPONENTS_OPERATION_DELETE_SUCCESS_MESSAGE = 'feature.report.schedules.components.operation-delete.success-message',
  COMPONENTS_OPERATION_REFRESH_TOOLTIP = 'feature.report.schedules.components.operation-refresh.tooltip',
  COMPONENTS_OPERATION_REFRESH_FAILED_MESSAGE = 'feature.report.schedules.components.operation-refresh.failed-message',
  COMPONENTS_OPERATION_REFRESH_SUCCESS_MESSAGE = 'feature.report.schedules.components.operation-refresh.success-message',
  COMPONENTS_CRON_ITEM_LABEL = 'feature.report.schedules.components.cron-item.label',
  COMPONENTS_CRON_ITEM_ERROR_INVALID = 'feature.report.schedules.components.cron-item.error.invalid',
  COMPONENTS_CRON_ITEM_ERROR_REQUIRED = 'feature.report.schedules.components.cron-item.error.required',
  COMPONENTS_FILTER_FORM_STATUS_LABEL = 'feature.report.schedules.components.filter-form.status.label',
  COMPONENTS_FILTER_FORM_STATUS_PLACEHOLDER = 'feature.report.schedules.components.filter-form.status.placeholder',
  COMPONENTS_FILTER_FORM_TEMPLATE_LABEL = 'feature.report.schedules.components.filter-form.template.label',
  COMPONENTS_FILTER_FORM_TEMPLATE_PLACEHOLDER = 'feature.report.schedules.components.filter-form.template.placeholder',
  COMPONENTS_FILTER_FORM_CREATED_AT_FROM_LABEL = 'feature.report.schedules.components.filter-form.created-at.from.label',
  COMPONENTS_FILTER_FORM_CREATED_AT_TO_LABEL = 'feature.report.schedules.components.filter-form.created-at.to.label',
  COMPONENTS_PARAMETERS_VIEW_TITLE = 'feature.report.schedules.components.parameters-view.title',
  COMPONENTS_PARAMETERS_FORM_TITLE = 'feature.report.schedules.components.parameters-form.title',
  COMPONENTS_PARAMETERS_FORM_NO_TEMPLATE = 'feature.report.schedules.components.parameters-form.no-template',
  COMPONENTS_STATUS_VIEW_LABEL_ACTIVE = 'feature.report.schedules.components.status-view.label.Active',
  COMPONENTS_STATUS_VIEW_LABEL_DISABLED = 'feature.report.schedules.components.status-view.label.Disabled',
  COMPONENTS_REPORTS_SCHEDULE_TABLE_DIALOG_TITLE = 'feature.report.schedules.components.reports-schedule-table.dialog.title'
}
export enum I18nFeatureStatistics {
  COMPONENTS_ACTUAL_BALANCES_VIEW_TOTAL_TITLE = 'feature.statistics.components.actual-balances-view.total.title',
  COMPONENTS_ACTUAL_BALANCES_VIEW_AVAILABLE_TITLE = 'feature.statistics.components.actual-balances-view.available.title',
  COMPONENTS_ACTUAL_BALANCES_VIEW_COLLECTABLE_TITLE = 'feature.statistics.components.actual-balances-view.collectable.title',
  COMPONENTS_ACTUAL_BALANCES_VIEW_LOCKED_TITLE = 'feature.statistics.components.actual-balances-view.locked.title',
  COMPONENTS_ACTUAL_BALANCES_VIEW_ROW_TITLE = 'feature.statistics.components.actual-balances-view.row.title',
  COMPONENTS_OPERATION_REFRESH_BALANCES_TITLE = 'feature.statistics.components.operation-refresh-balances.title',
  COMPONENTS_OPERATION_REFRESH_BALANCES_ERROR_MESSAGE = 'feature.statistics.components.operation-refresh-balances.error-message',
  COMPONENTS_OPERATION_REFRESH_BALANCES_SUCCESS_MESSAGE = 'feature.statistics.components.operation-refresh-balances.success-message'
}
export enum I18nFeatureSettlements {
  COMPONENTS_OPERATION_SETTLE_NOW_TITLE = 'feature.settlements.components.operation-settle-now.title',
  COMPONENTS_OPERATION_SETTLE_NOW_TOOLTIP = 'feature.settlements.components.operation-settle-now.tooltip',
  COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_IN_PROGRESS = 'feature.settlements.components.operation-settle-now.disabled.in-progress',
  COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_LOADING = 'feature.settlements.components.operation-settle-now.disabled.loading',
  COMPONENTS_OPERATION_SETTLE_NOW_DISABLED_EMPTY_BALANCE = 'feature.settlements.components.operation-settle-now.disabled.empty-balance',
  COMPONENTS_OPERATION_SETTLE_NOW_SUCCESS_MESSAGE = 'feature.settlements.components.operation-settle-now.success-message',
  COMPONENTS_OPERATION_SETTLE_NOW_SUCCESS_DESCRIPTION = 'feature.settlements.components.operation-settle-now.success-description',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_TITLE = 'feature.settlements.components.operation-cancel-schedule.title',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_TOOLTIP = 'feature.settlements.components.operation-cancel-schedule.tooltip',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_FAILED_MESSAGE = 'feature.settlements.components.operation-cancel-schedule.failed-message',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_SUCCESS_MESSAGE = 'feature.settlements.components.operation-cancel-schedule.success-message',
  COMPONENTS_OPERATION_CANCEL_SCHEDULE_DISABLED_NO_DATA = 'feature.settlements.components.operation-cancel-schedule.disabled.no-data',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_TITLE = 'feature.settlements.components.operation-export-reconciliations.title',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_TOOLTIP = 'feature.settlements.components.operation-export-reconciliations.tooltip',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_FORM_TITLE = 'feature.settlements.components.operation-export-reconciliations.form.title',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_FORM_MESSAGE = 'feature.settlements.components.operation-export-reconciliations.form.message',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_TRANSFERS_TITLE = 'feature.settlements.components.operation-export-reconciliations.transfers.title',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_NO_TRANSFERS_TITLE = 'feature.settlements.components.operation-export-reconciliations.no-transfers.title',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_ERROR_MESSAGE = 'feature.settlements.components.operation-export-reconciliations.error-message',
  COMPONENTS_OPERATION_EXPORT_RECONCILIATIONS_GENERATING_MESSAGE = 'feature.settlements.components.operation-export-reconciliations.generating-message',
  COMPONENTS_OPERATION_SET_SCHEDULE_TITLE = 'feature.settlements.components.operation-set-schedule.title',
  COMPONENTS_OPERATION_SET_SCHEDULE_TOOLTIP = 'feature.settlements.components.operation-set-schedule.tooltip',
  COMPONENTS_OPERATION_SET_SCHEDULE_FAILED_MESSAGE = 'feature.settlements.components.operation-set-schedule.failed-message',
  COMPONENTS_OPERATION_SET_SCHEDULE_SUCCESS_MESSAGE = 'feature.settlements.components.operation-set-schedule.success-message',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_TITLE = 'feature.settlements.components.operation-set-schedule.form.title',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_SUBMIT = 'feature.settlements.components.operation-set-schedule.form.submit',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_ERROR_COMMON = 'feature.settlements.components.operation-set-schedule.form.error.common',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_LABEL = 'feature.settlements.components.operation-set-schedule.form.amount.label',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_MIN = 'feature.settlements.components.operation-set-schedule.form.amount.min',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_PLACEHOLDER = 'feature.settlements.components.operation-set-schedule.form.amount.placeholder',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_REQUIRED = 'feature.settlements.components.operation-set-schedule.form.amount.required',
  COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_HELP = 'feature.settlements.components.operation-set-schedule.form.amount.help',
  COMPONENTS_OPERATION_REFRESH_SETTLEMENT_TOOLTIP = 'feature.settlements.components.operation-refresh-settlement.tooltip',
  COMPONENTS_OPERATION_REFRESH_SETTLEMENT_MESSAGE_SUCCESS = 'feature.settlements.components.operation-refresh-settlement.message-success',
  COMPONENTS_DURATION_ALERT_TITLE = 'feature.settlements.components.duration-alert.title',
  COMPONENTS_PENDING_ALERT_MESSAGE = 'feature.settlements.components.pending-alert.message',
  COMPONENTS_STATUS_VIEW_NEW = 'feature.settlements.components.status-view.New',
  COMPONENTS_STATUS_VIEW_RUNNING = 'feature.settlements.components.status-view.Running',
  COMPONENTS_STATUS_VIEW_FINISHED = 'feature.settlements.components.status-view.Finished',
  COMPONENTS_STATUS_VIEW_PAUSED = 'feature.settlements.components.status-view.Paused',
  COMPONENTS_STATUS_VIEW_CANCELLED = 'feature.settlements.components.status-view.Cancelled',
  COMPONENTS_STATUS_VIEW_ERROR = 'feature.settlements.components.status-view.Error',
  COMPONENTS_SETTLEMENT_TABLE_DIALOG_TITLE = 'feature.settlements.components.settlement-table.dialog.title',
  COMPONENTS_FILTER_FORM_RECONCILIATION_LABEL = 'feature.settlements.components.filter-form.reconciliation.label',
  COMPONENTS_FILTER_FORM_SETTLED_AT_FROM_LABEL = 'feature.settlements.components.filter-form.settled-at.from.label',
  COMPONENTS_FILTER_FORM_SETTLED_AT_TO_LABEL = 'feature.settlements.components.filter-form.settled-at.to.label',
  COMPONENTS_FILTER_FORM_STATUS_LABEL = 'feature.settlements.components.filter-form.status.label',
  COMPONENTS_FILTER_FORM_STATUS_PLACEHOLDER = 'feature.settlements.components.filter-form.status.placeholder',
  COMPONENTS_SETTLEMENT_VIEW_ESTIMATED_AMOUNT_HELP = 'feature.settlements.components.settlement-view.estimated-amount.help',
  COMPONENTS_SETTLEMENT_VIEW_TOTAL_AMOUNT_HELP = 'feature.settlements.components.settlement-view.total-amount.help',
  COMPONENTS_SETTLEMENT_PERIOD_LABEL_NOT_SET = 'feature.settlements.components.settlement-period-label.not-set',
  COMPONENTS_SETTLEMENT_PERIOD_LABEL_FIRST_DAY_OF_MONTH = 'feature.settlements.components.settlement-period-label.first-day-of-month',
  COMPONENTS_SETTLEMENT_PERIOD_LABEL_FIRST_DAY_OF_WEEK = 'feature.settlements.components.settlement-period-label.first-day-of-week',
  COMPONENTS_SETTLEMENT_TYPE_LABEL_PAYOUT = 'feature.settlements.components.settlement-type-label.payout',
  COMPONENTS_SETTLEMENT_TYPE_LABEL_SETTLEMENT = 'feature.settlements.components.settlement-type-label.settlement',
  COMPONENTS_SETTLEMENT_PERIOD_ITEM_LABEL = 'feature.settlements.components.settlement-period-item.label',
  COMPONENTS_SETTLEMENT_PERIOD_ITEM_PLACEHOLDER = 'feature.settlements.components.settlement-period-item.placeholder',
  COMPONENTS_SETTLEMENT_PERIOD_ITEM_REQUIRED = 'feature.settlements.components.settlement-period-item.required',
  COMPONENTS_SCHEDULE_VIEW_NOT_SET = 'feature.settlements.components.schedule-view.not-set',
  COMPONENTS_SCHEDULE_VIEW_DEFINED = 'feature.settlements.components.schedule-view.defined',
  COMPONENTS_INTENT_STATUS_VIEW_SUCCEEDED = 'feature.settlements.components.intent-status-view.Succeeded',
  COMPONENTS_INTENT_STATUS_VIEW_PENDING = 'feature.settlements.components.intent-status-view.Pending',
  COMPONENTS_INTENT_STATUS_VIEW_FAILED = 'feature.settlements.components.intent-status-view.Failed',
  COMPONENTS_INTENT_STATUS_BANNER_PENDING_TAG = 'feature.settlements.components.intent-status-banner.pending.tag',
  COMPONENTS_INTENT_STATUS_BANNER_PENDING_TITLE = 'feature.settlements.components.intent-status-banner.pending.title',
  COMPONENTS_INTENT_STATUS_BANNER_PENDING_DESCRIPTION = 'feature.settlements.components.intent-status-banner.pending.description',
  COMPONENTS_INTENT_STATUS_BANNER_STALE_TAG = 'feature.settlements.components.intent-status-banner.stale.tag',
  COMPONENTS_INTENT_STATUS_BANNER_STALE_TITLE = 'feature.settlements.components.intent-status-banner.stale.title',
  COMPONENTS_INTENT_STATUS_BANNER_STALE_DESCRIPTION = 'feature.settlements.components.intent-status-banner.stale.description',
  COMPONENTS_INTENTS_LIST_DESCRIPTION = 'feature.settlements.components.intents-list.description',
  COMPONENTS_INTENTS_LIST_LINK = 'feature.settlements.components.intents-list.link',
  MESSAGES_PENDING_FINISHED_SUCCESS = 'feature.settlements.messages.pending.finished-success',
  MESSAGES_PENDING_FINISHED_FAILURE = 'feature.settlements.messages.pending.finished-failure',
  LABELS_ROW_TITLE_SETTLEMENT_ID = 'feature.settlements.labels.row.title.settlement.id',
  LABELS_ROW_TITLE_SETTLEMENT_ESTIMATED_AMOUNT = 'feature.settlements.labels.row.title.settlement.estimated-amount',
  LABELS_ROW_TITLE_SETTLEMENT_TOTAL_AMOUNT = 'feature.settlements.labels.row.title.settlement.total-amount',
  LABELS_ROW_TITLE_SETTLEMENT_DESTINATION_ADDRESS = 'feature.settlements.labels.row.title.settlement.destination-address',
  LABELS_ROW_TITLE_SETTLEMENT_ESTIMATED_AT = 'feature.settlements.labels.row.title.settlement.estimated-at',
  LABELS_ROW_TITLE_SETTLEMENT_STATUS = 'feature.settlements.labels.row.title.settlement.status',
  LABELS_ROW_TITLE_SETTLEMENT_SETTLED_AT = 'feature.settlements.labels.row.title.settlement.settled-at',
  LABELS_ROW_TITLE_SETTLEMENT_TRANSACTION = 'feature.settlements.labels.row.title.settlement.transaction',
  LABELS_ROW_TITLE_SETTLEMENT_ERROR = 'feature.settlements.labels.row.title.settlement.error',
  LABELS_ROW_TITLE_SETTLEMENT_RECONCILIATION_STATUS = 'feature.settlements.labels.row.title.settlement.reconciliation-status',
  LABELS_ROW_TITLE_SETTLEMENT_TYPE = 'feature.settlements.labels.row.title.settlement.type',
  LABELS_ROW_TITLE_SETTLEMENT_INTENT_ASSET = 'feature.settlements.labels.row.title.settlement-intent.asset',
  LABELS_ROW_TITLE_SETTLEMENT_INTENT_PROCESS_AT = 'feature.settlements.labels.row.title.settlement-intent.process-at',
  LABELS_ROW_TITLE_SETTLEMENT_INTENT_STATUS = 'feature.settlements.labels.row.title.settlement-intent.status'
}
export enum I18nFeatureSettlementReconciliations {
  COMPONENTS_OPERATION_RECALCULATE_TITLE = 'feature.settlement.reconciliations.components.operation-recalculate.title',
  COMPONENTS_OPERATION_RECALCULATE_TOOLTIP = 'feature.settlement.reconciliations.components.operation-recalculate.tooltip',
  COMPONENTS_OPERATION_RECALCULATE_FAILED_MESSAGE = 'feature.settlement.reconciliations.components.operation-recalculate.failed-message',
  COMPONENTS_OPERATION_RECALCULATE_SUCCESS_MESSAGE = 'feature.settlement.reconciliations.components.operation-recalculate.success-message',
  COMPONENTS_OPERATION_RECALCULATE_DISABLED_NO_DATA = 'feature.settlement.reconciliations.components.operation-recalculate.disabled.no-data',
  COMPONENTS_OPERATION_RECALCULATE_DISABLED_INVALID_STATUS = 'feature.settlement.reconciliations.components.operation-recalculate.disabled.invalid-status',
  COMPONENTS_OPERATION_RECALCULATE_DISABLED_TOO_LATE = 'feature.settlement.reconciliations.components.operation-recalculate.disabled.too-late',
  COMPONENTS_OPERATION_REFRESH_RECONCILIATION_TOOLTIP = 'feature.settlement.reconciliations.components.operation-refresh-reconciliation.tooltip',
  COMPONENTS_OPERATION_REFRESH_RECONCILIATION_FAILED_MESSAGE = 'feature.settlement.reconciliations.components.operation-refresh-reconciliation.failed-message',
  COMPONENTS_OPERATION_REFRESH_RECONCILIATION_SUCCESS_MESSAGE = 'feature.settlement.reconciliations.components.operation-refresh-reconciliation.success-message',
  COMPONENTS_RECONCILIATION_BANNER_EMPTY_TAG = 'feature.settlement.reconciliations.components.reconciliation-banner.empty.tag',
  COMPONENTS_RECONCILIATION_BANNER_EMPTY_TITLE = 'feature.settlement.reconciliations.components.reconciliation-banner.empty.title',
  COMPONENTS_RECONCILIATION_BANNER_EMPTY_DESCRIPTION = 'feature.settlement.reconciliations.components.reconciliation-banner.empty.description',
  COMPONENTS_RECONCILIATION_BANNER_ACTIVE_TAG = 'feature.settlement.reconciliations.components.reconciliation-banner.active.tag',
  COMPONENTS_RECONCILIATION_BANNER_ACTIVE_TITLE = 'feature.settlement.reconciliations.components.reconciliation-banner.active.title',
  COMPONENTS_RECONCILIATION_BANNER_ACTIVE_DESCRIPTION = 'feature.settlement.reconciliations.components.reconciliation-banner.active.description',
  COMPONENTS_RECONCILIATION_BANNER_RETRYING_TAG = 'feature.settlement.reconciliations.components.reconciliation-banner.retrying.tag',
  COMPONENTS_RECONCILIATION_BANNER_RETRYING_TITLE = 'feature.settlement.reconciliations.components.reconciliation-banner.retrying.title',
  COMPONENTS_RECONCILIATION_BANNER_RETRYING_DESCRIPTION = 'feature.settlement.reconciliations.components.reconciliation-banner.retrying.description',
  COMPONENTS_RECONCILIATION_BANNER_FAILED_TAG = 'feature.settlement.reconciliations.components.reconciliation-banner.failed.tag',
  COMPONENTS_RECONCILIATION_BANNER_FAILED_TITLE = 'feature.settlement.reconciliations.components.reconciliation-banner.failed.title',
  COMPONENTS_RECONCILIATION_BANNER_FAILED_DESCRIPTION = 'feature.settlement.reconciliations.components.reconciliation-banner.failed.description',
  COMPONENTS_RECONCILIATION_BANNER_SUCCEEDED_TAG = 'feature.settlement.reconciliations.components.reconciliation-banner.succeeded.tag',
  COMPONENTS_RECONCILIATION_BANNER_SUCCEEDED_TITLE = 'feature.settlement.reconciliations.components.reconciliation-banner.succeeded.title',
  COMPONENTS_RECONCILIATION_BANNER_SUCCEEDED_DESCRIPTION = 'feature.settlement.reconciliations.components.reconciliation-banner.succeeded.description',
  COMPONENTS_RECONCILIATION_FILTER_FORM_SETTLED_AT_FROM_LABEL = 'feature.settlement.reconciliations.components.reconciliation-filter-form.settled-at.from.label',
  COMPONENTS_RECONCILIATION_FILTER_FORM_SETTLED_AT_TO_LABEL = 'feature.settlement.reconciliations.components.reconciliation-filter-form.settled-at.to.label',
  COMPONENTS_RECONCILIATION_FILTER_FORM_STATUS_LABEL = 'feature.settlement.reconciliations.components.reconciliation-filter-form.status.label',
  COMPONENTS_RECONCILIATION_FILTER_FORM_STATUS_PLACEHOLDER = 'feature.settlement.reconciliations.components.reconciliation-filter-form.status.placeholder',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_UNCHECKED = 'feature.settlement.reconciliations.components.reconciliation-status-view.Unchecked',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_RETRYING = 'feature.settlement.reconciliations.components.reconciliation-status-view.Retrying',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_SUCCEEDED = 'feature.settlement.reconciliations.components.reconciliation-status-view.Succeeded',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_FAILED = 'feature.settlement.reconciliations.components.reconciliation-status-view.Failed',
  COMPONENTS_RECONCILIATION_STATUS_VIEW_ACTIVE = 'feature.settlement.reconciliations.components.reconciliation-status-view.Active',
  COMPONENTS_RECONCILIATION_REPORT_SCHEDULE_PARAMETERS_SETTLED_AT_LABEL = 'feature.settlement.reconciliations.components.reconciliation-report-schedule-parameters.settled-at.label',
  COMPONENTS_RECONCILIATION_EXPORT_REPORT_TITLE = 'feature.settlement.reconciliations.components.reconciliation-export-report.title',
  COMPONENTS_RECONCILIATION_EXPORT_TRANSFERS_REPORT_TITLE = 'feature.settlement.reconciliations.components.reconciliation-export-transfers-report.title',
  COMPONENTS_RECONCILIATION_VIEW_NEXT_ATTEMPT_HELP = 'feature.settlement.reconciliations.components.reconciliation-view.next-attempt.help',
  COMPONENTS_RECONCILIATION_VIEW_CALCULATED_AMOUNT_HELP = 'feature.settlement.reconciliations.components.reconciliation-view.calculated-amount.help',
  COMPONENTS_RECONCILIATION_VIEW_BEFORE_SETTLEMENT_BALANCE_TITLE = 'feature.settlement.reconciliations.components.reconciliation-view.before-settlement-balance.title',
  COMPONENTS_RECONCILIATION_VIEW_AFTER_SETTLEMENT_BALANCE_TITLE = 'feature.settlement.reconciliations.components.reconciliation-view.after-settlement-balance.title',
  COMPONENTS_RECONCILIATION_VIEW_SETTLEMENT_LABEL = 'feature.settlement.reconciliations.components.reconciliation-view.settlement.label',
  LABELS_ROW_TITLE_EXPECTED_AMOUNT = 'feature.settlement.reconciliations.labels.row.title.expected-amount',
  LABELS_ROW_TITLE_CALCULATED_AMOUNT = 'feature.settlement.reconciliations.labels.row.title.calculated-amount',
  LABELS_ROW_TITLE_TX = 'feature.settlement.reconciliations.labels.row.title.tx',
  LABELS_ROW_TITLE_ATTEMPTS = 'feature.settlement.reconciliations.labels.row.title.attempts',
  LABELS_ROW_TITLE_NEXT_ATTEMPT = 'feature.settlement.reconciliations.labels.row.title.next-attempt',
  LABELS_ROW_TITLE_ERROR = 'feature.settlement.reconciliations.labels.row.title.error',
  LABELS_ROW_TITLE_REPORT = 'feature.settlement.reconciliations.labels.row.title.report'
}
export enum I18nFeatureSubscriptions {
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_CONNECTOR_TITLE = 'feature.subscriptions.components.operation-cancel-subscription.connector-title',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_TITLE = 'feature.subscriptions.components.operation-cancel-subscription.title',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_INVALID_STATUS = 'feature.subscriptions.components.operation-cancel-subscription.invalid-status',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_INVALID_CHAIN_ID = 'feature.subscriptions.components.operation-cancel-subscription.invalid-chain-id',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_FAILED_MESSAGE = 'feature.subscriptions.components.operation-cancel-subscription.failed-message',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_FAILED_DESCRIPTION = 'feature.subscriptions.components.operation-cancel-subscription.failed-description',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_SUCCESS_MESSAGE = 'feature.subscriptions.components.operation-cancel-subscription.success-message',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_SUCCESS_DESCRIPTION = 'feature.subscriptions.components.operation-cancel-subscription.success-description',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_DIALOG_TITLE = 'feature.subscriptions.components.operation-cancel-subscription.dialog.title',
  COMPONENTS_OPERATION_CANCEL_SUBSCRIPTION_DIALOG_DESCRIPTION = 'feature.subscriptions.components.operation-cancel-subscription.dialog.description',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_TOOLTIP = 'feature.subscriptions.components.operation-refresh-subscription.tooltip',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_FAILED_MESSAGE = 'feature.subscriptions.components.operation-refresh-subscription.failed-message',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_SUCCESS_MESSAGE = 'feature.subscriptions.components.operation-refresh-subscription.success-message',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGES_TOOLTIP = 'feature.subscriptions.components.operation-refresh-subscription-charges.tooltip',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGES_FAILED_MESSAGE = 'feature.subscriptions.components.operation-refresh-subscription-charges.failed-message',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGES_SUCCESS_MESSAGE = 'feature.subscriptions.components.operation-refresh-subscription-charges.success-message',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_CONNECTOR_TITLE = 'feature.subscriptions.components.operation-pause-subscription.connector-title',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_TITLE = 'feature.subscriptions.components.operation-pause-subscription.title',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_INVALID_STATUS = 'feature.subscriptions.components.operation-pause-subscription.invalid-status',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_INVALID_CHAIN_ID = 'feature.subscriptions.components.operation-pause-subscription.invalid-chain-id',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_FAILED_MESSAGE = 'feature.subscriptions.components.operation-pause-subscription.failed-message',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_FAILED_DESCRIPTION = 'feature.subscriptions.components.operation-pause-subscription.failed-description',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_SUCCESS_MESSAGE = 'feature.subscriptions.components.operation-pause-subscription.success-message',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_SUCCESS_DESCRIPTION = 'feature.subscriptions.components.operation-pause-subscription.success-description',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_DIALOG_TITLE = 'feature.subscriptions.components.operation-pause-subscription.dialog.title',
  COMPONENTS_OPERATION_PAUSE_SUBSCRIPTION_DIALOG_DESCRIPTION = 'feature.subscriptions.components.operation-pause-subscription.dialog.description',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_TITLE = 'feature.subscriptions.components.operation-unpause-subscription.title',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_INVALID_STATUS = 'feature.subscriptions.components.operation-unpause-subscription.invalid-status',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_INVALID_CHAIN_ID = 'feature.subscriptions.components.operation-unpause-subscription.invalid-chain-id',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_FAILED_MESSAGE = 'feature.subscriptions.components.operation-unpause-subscription.failed-message',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_FAILED_DESCRIPTION = 'feature.subscriptions.components.operation-unpause-subscription.failed-description',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_SUCCESS_MESSAGE = 'feature.subscriptions.components.operation-unpause-subscription.success-message',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_SUCCESS_DESCRIPTION = 'feature.subscriptions.components.operation-unpause-subscription.success-description',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_CONNECTOR_TITLE = 'feature.subscriptions.components.operation-unpause-subscription.connector-title',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_DIALOG_TITLE = 'feature.subscriptions.components.operation-unpause-subscription.dialog.title',
  COMPONENTS_OPERATION_UNPAUSE_SUBSCRIPTION_DIALOG_DESCRIPTION = 'feature.subscriptions.components.operation-unpause-subscription.dialog.description',
  COMPONENTS_SUBSCRIPTION_ALLOWANCE_PERIOD = 'feature.subscriptions.components.subscription-allowance.period',
  COMPONENTS_SUBSCRIPTION_CANCELLATION_TIMEOUT_ALERT_TITLE = 'feature.subscriptions.components.subscription-cancellation-timeout-alert.title',
  COMPONENTS_SUBSCRIPTION_DEPLOY_ALERT_TITLE_BEFORE_START = 'feature.subscriptions.components.subscription-deploy-alert.title.before-start',
  COMPONENTS_SUBSCRIPTION_DEPLOY_ALERT_TITLE_AFTER_START = 'feature.subscriptions.components.subscription-deploy-alert.title.after-start',
  COMPONENTS_SUBSCRIPTION_FILTER_FORM_CREATED_AT_FROM_LABEL = 'feature.subscriptions.components.subscription-filter-form.created-at.from.label',
  COMPONENTS_SUBSCRIPTION_FILTER_FORM_CREATED_AT_TO_LABEL = 'feature.subscriptions.components.subscription-filter-form.created-at.to.label',
  COMPONENTS_SUBSCRIPTION_FILTER_FORM_STATUS_LABEL = 'feature.subscriptions.components.subscription-filter-form.status.label',
  COMPONENTS_SUBSCRIPTION_FILTER_FORM_STATUS_PLACEHOLDER = 'feature.subscriptions.components.subscription-filter-form.status.placeholder',
  COMPONENTS_SUBSCRIPTION_FILTER_FORM_ADDRESS_PLACEHOLDER = 'feature.subscriptions.components.subscription-filter-form.address.placeholder',
  COMPONENTS_SUBSCRIPTION_FILTER_FORM_PAYER_PLACEHOLDER = 'feature.subscriptions.components.subscription-filter-form.payer.placeholder',
  COMPONENTS_SUBSCRIPTION_GAS_WALLET_ALERT_ERROR_MESSAGE = 'feature.subscriptions.components.subscription-gas-wallet-alert.error-message',
  COMPONENTS_SUBSCRIPTION_MAX_PERIOD_VIEW_TITLE_DATE = 'feature.subscriptions.components.subscription-max-period-view.title.date',
  COMPONENTS_SUBSCRIPTION_MAX_PERIOD_VIEW_TITLE_TIMES = 'feature.subscriptions.components.subscription-max-period-view.title.times',
  COMPONENTS_SUBSCRIPTIONS_DOCUMENTATION_BANNER_MESSAGE = 'feature.subscriptions.components.subscriptions-documentation-banner.message',
  COMPONENTS_SUBSCRIPTIONS_DOCUMENTATION_LINK_TITLE = 'feature.subscriptions.components.subscriptions-documentation-link.title',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_ACTIVE = 'feature.subscriptions.components.subscription-status-view.label.Active',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_TRIAL = 'feature.subscriptions.components.subscription-status-view.label.Trial',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_DRAFT = 'feature.subscriptions.components.subscription-status-view.label.Draft',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_SUSPENDED = 'feature.subscriptions.components.subscription-status-view.label.Suspended',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDING = 'feature.subscriptions.components.subscription-status-view.label.Pending',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PAUSED = 'feature.subscriptions.components.subscription-status-view.label.Paused',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_CANCELLED = 'feature.subscriptions.components.subscription-status-view.label.Cancelled',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_ERROR = 'feature.subscriptions.components.subscription-status-view.label.Error',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_FINISHED = 'feature.subscriptions.components.subscription-status-view.label.Finished',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDINGPAUSE = 'feature.subscriptions.components.subscription-status-view.label.PendingPause',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDINGUNPAUSE = 'feature.subscriptions.components.subscription-status-view.label.PendingUnPause',
  COMPONENTS_SUBSCRIPTION_STATUS_VIEW_LABEL_PENDINGCANCEL = 'feature.subscriptions.components.subscription-status-view.label.PendingCancel',
  COMPONENTS_SUBSCRIPTIONS_TABLE_DIALOG_TITLE = 'feature.subscriptions.components.subscriptions-table.dialog.title',
  COMPONENTS_SUBSCRIPTIONS_TABLE_REFRESH_ERROR_MESSAGE = 'feature.subscriptions.components.subscriptions-table.refresh.error-message',
  COMPONENTS_SUBSCRIPTIONS_TABLE_GAS_WALLET_ERROR_MESSAGE = 'feature.subscriptions.components.subscriptions-table.gas-wallet.error-message',
  LABELS_ROW_TITLE_ADDRESS = 'feature.subscriptions.labels.row.title.address',
  LABELS_ROW_TITLE_CUSTOMER = 'feature.subscriptions.labels.row.title.customer',
  LABELS_ROW_TITLE_PAYER = 'feature.subscriptions.labels.row.title.payer',
  LABELS_ROW_TITLE_PLAN = 'feature.subscriptions.labels.row.title.plan',
  LABELS_ROW_TITLE_STATUS = 'feature.subscriptions.labels.row.title.status',
  LABELS_ROW_TITLE_METADATA = 'feature.subscriptions.labels.row.title.metadata',
  LABELS_ROW_TITLE_CREATED_AT = 'feature.subscriptions.labels.row.title.created-at',
  LABELS_ROW_TITLE_START_FROM = 'feature.subscriptions.labels.row.title.start-from',
  LABELS_ROW_TITLE_LAST_CHARGE_AT = 'feature.subscriptions.labels.row.title.last-charge-at',
  LABELS_ROW_TITLE_MAX_PERIOD = 'feature.subscriptions.labels.row.title.max-period',
  LABELS_ROW_TITLE_NEXT_CHARGE_AT = 'feature.subscriptions.labels.row.title.next-charge-at',
  LABELS_ROW_TITLE_ALLOWANCE = 'feature.subscriptions.labels.row.title.allowance'
}
export enum I18nFeatureSubscriptionCharges {
  COMPONENTS_CHARGE_STATUS_VIEW_PENDING = 'feature.subscription.charges.components.charge-status-view.Pending',
  COMPONENTS_CHARGE_STATUS_VIEW_SUCCEEDED = 'feature.subscription.charges.components.charge-status-view.Succeeded',
  COMPONENTS_CHARGE_STATUS_VIEW_FAILED = 'feature.subscription.charges.components.charge-status-view.Failed',
  COMPONENTS_CHARGE_STATUS_VIEW_RETRYING = 'feature.subscription.charges.components.charge-status-view.Retrying',
  COMPONENTS_CHARGE_STATUS_VIEW_CANCELLED = 'feature.subscription.charges.components.charge-status-view.Cancelled',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGE_TOOLTIP = 'feature.subscription.charges.components.operation-refresh-subscription-charge.tooltip',
  COMPONENTS_OPERATION_REFRESH_SUBSCRIPTION_CHARGE_SUCCESS_MESSAGE = 'feature.subscription.charges.components.operation-refresh-subscription-charge.success-message',
  LABELS_ROW_TITLE_ADDRESS = 'feature.subscription.charges.labels.row.title.address',
  LABELS_ROW_TITLE_AMOUNT = 'feature.subscription.charges.labels.row.title.amount',
  LABELS_ROW_TITLE_CHARGED_AT = 'feature.subscription.charges.labels.row.title.charged-at',
  LABELS_ROW_TITLE_HASH = 'feature.subscription.charges.labels.row.title.hash',
  LABELS_ROW_TITLE_SETTLEMENT = 'feature.subscription.charges.labels.row.title.settlement'
}
export enum I18nFeatureSubscriptionPlans {
  COMPONENTS_EDIT_FORM_SUBMIT_BUTTON = 'feature.subscription.plans.components.edit-form.submit-button',
  COMPONENTS_EDIT_FORM_DESCRIPTION_ITEM_PLACEHOLDER = 'feature.subscription.plans.components.edit-form.description-item.placeholder',
  COMPONENTS_EDIT_FORM_DESCRIPTION_ITEM_VALIDATION_REQUIRED = 'feature.subscription.plans.components.edit-form.description-item.validation.required',
  COMPONENTS_EDIT_FORM_DESCRIPTION_ITEM_VALIDATION_MIN = 'feature.subscription.plans.components.edit-form.description-item.validation.min',
  COMPONENTS_EDIT_FORM_NAME_ITEM_PLACEHOLDER = 'feature.subscription.plans.components.edit-form.name-item.placeholder',
  COMPONENTS_EDIT_FORM_NAME_ITEM_VALIDATION_REQUIRED = 'feature.subscription.plans.components.edit-form.name-item.validation.required',
  COMPONENTS_EDIT_FORM_NAME_ITEM_VALIDATION_MIN = 'feature.subscription.plans.components.edit-form.name-item.validation.min',
  COMPONENTS_EDIT_FORM_GRACE_ITEM_LABEL = 'feature.subscription.plans.components.edit-form.grace-item.label',
  COMPONENTS_EDIT_FORM_GRACE_ITEM_LABEL_SUFFIX = 'feature.subscription.plans.components.edit-form.grace-item.label.suffix',
  COMPONENTS_EDIT_FORM_GRACE_ITEM_PLACEHOLDER = 'feature.subscription.plans.components.edit-form.grace-item.placeholder',
  COMPONENTS_EDIT_FORM_GRACE_ITEM_WARNING_TOO_LOW = 'feature.subscription.plans.components.edit-form.grace-item.warning.too-low',
  COMPONENTS_EDIT_FORM_GRACE_ITEM_HELP = 'feature.subscription.plans.components.edit-form.grace-item.help',
  COMPONENTS_EDIT_FORM_GRACE_ITEM_ERROR_REQUIRED = 'feature.subscription.plans.components.edit-form.grace-item.error.required',
  COMPONENTS_EDIT_FORM_GRACE_ITEM_ERROR_BIGGER_THAN_PERIOD = 'feature.subscription.plans.components.edit-form.grace-item.error.bigger-than-period',
  COMPONENTS_EDIT_FORM_PLAN_ITEM_LABEL = 'feature.subscription.plans.components.edit-form.plan-item.label',
  COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_LABEL = 'feature.subscription.plans.components.edit-form.schedule-item.label',
  COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_LABEL_SUFFIX = 'feature.subscription.plans.components.edit-form.schedule-item.label.suffix',
  COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_PLACEHOLDER = 'feature.subscription.plans.components.edit-form.schedule-item.placeholder',
  COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_ERROR_MIN_DAYS = 'feature.subscription.plans.components.edit-form.schedule-item.error.min-days',
  COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_ERROR_MAX_DAYS = 'feature.subscription.plans.components.edit-form.schedule-item.error.max-days',
  COMPONENTS_EDIT_FORM_SCHEDULE_ITEM_ERROR_REQUIRED = 'feature.subscription.plans.components.edit-form.schedule-item.error.required',
  COMPONENTS_EDIT_FORM_TAGS_ITEM_PLACEHOLDER = 'feature.subscription.plans.components.edit-form.tags-item.placeholder',
  COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE = 'feature.subscription.plans.components.operation-change-status.activate',
  COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE_INVALID_STATUS = 'feature.subscription.plans.components.operation-change-status.activate.invalid-status',
  COMPONENTS_OPERATION_CHANGE_STATUS_ACTIVATE_CONFIRMATION = 'feature.subscription.plans.components.operation-change-status.activate.confirmation',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE = 'feature.subscription.plans.components.operation-change-status.deactivate',
  COMPONENTS_OPERATION_CHANGE_STATUS_DEACTIVATE_INVALID_STATUS = 'feature.subscription.plans.components.operation-change-status.deactivate.invalid-status',
  COMPONENTS_OPERATION_CHANGE_STATUS_ERROR_MESSAGE = 'feature.subscription.plans.components.operation-change-status.error-message',
  COMPONENTS_OPERATION_CHANGE_STATUS_SUCCESS_MESSAGE = 'feature.subscription.plans.components.operation-change-status.success-message',
  COMPONENTS_OPERATION_RELOAD_PLAN_TOOLTIP = 'feature.subscription.plans.components.operation-reload-plan.tooltip',
  COMPONENTS_OPERATION_RELOAD_PLAN_SUCCESS_MESSAGE = 'feature.subscription.plans.components.operation-reload-plan.success-message',
  COMPONENTS_OPERATION_DELETE_PLAN_TITLE = 'feature.subscription.plans.components.operation-delete-plan.title',
  COMPONENTS_OPERATION_DELETE_PLAN_CONFIRMATION_TITLE = 'feature.subscription.plans.components.operation-delete-plan.confirmation-title',
  COMPONENTS_OPERATION_DELETE_PLAN_ERROR_MESSAGE = 'feature.subscription.plans.components.operation-delete-plan.error-message',
  COMPONENTS_OPERATION_DELETE_PLAN_SUCCESS_MESSAGE = 'feature.subscription.plans.components.operation-delete-plan.success-message',
  COMPONENTS_SUBSCRIPTION_PLAN_FILTER_PLAN_STATUS_PLACEHOLDER = 'feature.subscription.plans.components.subscription-plan-filter.plan-status.placeholder',
  COMPONENTS_SUBSCRIPTION_PLAN_SELECT_PLACEHOLDER = 'feature.subscription.plans.components.subscription-plan-select.placeholder',
  COMPONENTS_SUBSCRIPTION_PLAN_STATUS_VIEW_ACTIVE = 'feature.subscription.plans.components.subscription-plan-status-view.Active',
  COMPONENTS_SUBSCRIPTION_PLAN_STATUS_VIEW_ARCHIVED = 'feature.subscription.plans.components.subscription-plan-status-view.Archived',
  COMPONENTS_SUBSCRIPTION_PLAN_STATUS_VIEW_DRAFT = 'feature.subscription.plans.components.subscription-plan-status-view.Draft',
  COMPONENTS_SUBSCRIPTION_PLANS_TABLE_DIALOG_TITLE = 'feature.subscription.plans.components.subscription-plans-table.dialog.title',
  COMPONENTS_SUBSCRIPTION_PLANS_TABLE_REFRESH_ERROR_MESSAGE = 'feature.subscription.plans.components.subscription-plans-table.refresh.error-message',
  COMPONENTS_SUBSCRIPTION_PLANS_TABLE_GAS_WALLET_ERROR_MESSAGE = 'feature.subscription.plans.components.subscription-plans-table.gas-wallet.error-message',
  LABELS_ROW_TITLE_ID = 'feature.subscription.plans.labels.row.title.id',
  LABELS_ROW_TITLE_STATUS = 'feature.subscription.plans.labels.row.title.status',
  LABELS_ROW_TITLE_PERIOD = 'feature.subscription.plans.labels.row.title.period',
  LABELS_ROW_TITLE_GRACE_PERIOD = 'feature.subscription.plans.labels.row.title.grace-period',
  LABELS_ROW_TITLE_TAGS = 'feature.subscription.plans.labels.row.title.tags',
  LABELS_ROW_TITLE_NAME = 'feature.subscription.plans.labels.row.title.name',
  LABELS_ROW_TITLE_DESCRIPTION = 'feature.subscription.plans.labels.row.title.description',
  LABELS_ROW_TITLE_AMOUNT = 'feature.subscription.plans.labels.row.title.amount',
  LABELS_ROW_TITLE_CREATED_AT = 'feature.subscription.plans.labels.row.title.created-at'
}
export enum I18nFeatureSubscriptionStatistics {
  COMPONENTS_CHURN_RATE_GRAPH_X_AXIS_TITLE = 'feature.subscription.statistics.components.churn-rate-graph.x-axis.title',
  COMPONENTS_CHURN_RATE_GRAPH_TOOLTIP_VALUE = 'feature.subscription.statistics.components.churn-rate-graph.tooltip.value',
  COMPONENTS_GROWTH_RATE_GRAPH_TOOLTIP_VALUE = 'feature.subscription.statistics.components.growth-rate-graph.tooltip.value',
  COMPONENTS_REVENUE_GRAPH_X_AXIS_TITLE = 'feature.subscription.statistics.components.revenue-graph.x-axis.title',
  HOOKS_PLAN_LABELS_GRAPH_TOTAL_TITLE = 'feature.subscription.statistics.hooks.plan-labels.graph-total.title',
  HOOKS_PLAN_LABELS_GRAPH_MEAN_TITLE = 'feature.subscription.statistics.hooks.plan-labels.graph-mean.title',
  HOOKS_PLAN_LABELS_GRAPH_MEDIAN_TITLE = 'feature.subscription.statistics.hooks.plan-labels.graph-median.title',
  HOOKS_PLAN_LABELS_LEGEND_PLAN_LINK = 'feature.subscription.statistics.hooks.plan-labels.legend.plan-link'
}
export enum I18nFeatureWeb3 {
  COMPONENTS_CONNECTOR_SELECT_ITEM_LABEL = 'feature.web3.components.connector-select-item.label',
  COMPONENTS_CONNECTOR_SELECT_ITEM_REQUIRED = 'feature.web3.components.connector-select-item.required',
  COMPONENTS_WALLET_ADDRESS_LABEL = 'feature.web3.components.wallet-address.label',
  COMPONENTS_WALLET_ADDRESS_REQUIRED = 'feature.web3.components.wallet-address.required',
  COMPONENTS_WALLET_CONNECT_CONNECT_TITLE = 'feature.web3.components.wallet-connect.connect.title',
  COMPONENTS_WALLET_CONNECT_CANCEL_CONNECT_TITLE = 'feature.web3.components.wallet-connect.cancel-connect.title',
  COMPONENTS_WALLET_CONNECT_NOT_SUPPORTED = 'feature.web3.components.wallet-connect.not-supported',
  COMPONENTS_WALLET_CONNECT_DESCRIPTION = 'feature.web3.components.wallet-connect.description',
  COMPONENTS_WALLET_IN_PROGRESS_MESSAGE = 'feature.web3.components.wallet-in-progress.message',
  COMPONENTS_WALLET_LINK_METAMASK = 'feature.web3.components.wallet-link.metamask',
  COMPONENTS_WALLET_LINK_COINBASE = 'feature.web3.components.wallet-link.coinbase',
  COMPONENTS_WALLET_OPERATION_CANCEL_TITLE = 'feature.web3.components.wallet-operation.cancel.title',
  COMPONENTS_WALLET_OPERATION_DISCONNECT_TITLE = 'feature.web3.components.wallet-operation.disconnect.title',
  COMPONENTS_WALLET_OPERATION_ACCOUNT_TITLE = 'feature.web3.components.wallet-operation.account.title',
  COMPONENTS_WALLET_OPERATION_ACCOUNT_TOOLTIP = 'feature.web3.components.wallet-operation.account.tooltip',
  COMPONENTS_WALLET_OPERATION_SWITCH_NETWORK_TITLE = 'feature.web3.components.wallet-operation.switch-network.title',
  COMPONENTS_WALLET_OPERATION_SWITCH_NETWORK_MANUAL_TITLE = 'feature.web3.components.wallet-operation.switch-network.manual-title',
  COMPONENTS_WALLET_OPERATION_SWITCH_ACCOUNT_MANUAL_TITLE = 'feature.web3.components.wallet-operation.switch-account.manual-title'
}
export enum I18nMenu {
  AUDIT_MAIN = 'menu.audit.main',
  AUDIT_TRANSFERS = 'menu.audit.transfers',
  AUDIT_BALANCES = 'menu.audit.balances',
  BALANCES = 'menu.balances',
  DASHBOARD = 'menu.dashboard',
  DONATIONS_MAIN = 'menu.donations.main',
  DONATIONS_DONATIONS = 'menu.donations.donations',
  DONATIONS_TRANSACTIONS = 'menu.donations.transactions',
  GAS_HISTORY = 'menu.gas-history',
  HOME = 'menu.home',
  PAYMENTS = 'menu.payments',
  SETTINGS_MAIN = 'menu.settings.main',
  SETTINGS_COMPANY_SUMMARY = 'menu.settings.company.summary',
  SETTINGS_COMPANY_INTEGRATION = 'menu.settings.company.integration',
  SETTINGS_COMPANY_BRANDING = 'menu.settings.company.branding',
  SETTINGS_COMPANY_PROFILE = 'menu.settings.company.profile',
  SETTINGS_COMPANY_PERMISSIONS = 'menu.settings.company.permissions',
  SUBSCRIPTIONS_MAIN = 'menu.subscriptions.main',
  SUBSCRIPTIONS_SUBSCRIPTIONS = 'menu.subscriptions.subscriptions',
  SUBSCRIPTIONS_STATISTICS = 'menu.subscriptions.statistics',
  SUBSCRIPTIONS_PLANS = 'menu.subscriptions.plans',
  RECHARGES_MAIN = 'menu.recharges.main',
  RECHARGES_ADDRESSES = 'menu.recharges.addresses',
  RECHARGES_TRANSACTIONS = 'menu.recharges.transactions',
  REPORTS_MAIN = 'menu.reports.main',
  REPORTS_REPORTS = 'menu.reports.reports',
  REPORTS_SCHEDULES = 'menu.reports.schedules',
  SETTLEMENTS_MAIN = 'menu.settlements.main',
  SETTLEMENTS_HISTORY = 'menu.settlements.history',
  SETTLEMENTS_SCHEDULE = 'menu.settlements.schedule',
  SETTLEMENTS_PAYOUTS = 'menu.settlements.payouts'
}
export enum I18nPageAudit {
  BALANCES_COMPONENTS_BREADCRUMB = 'page.audit.balances.components.breadcrumb',
  BALANCES_COMPONENTS_LINK_TITLE = 'page.audit.balances.components.link.title',
  BALANCES_COMPONENTS_BALANCES_CARD_TITLE = 'page.audit.balances.components.balances-card.title',
  BALANCE_VIEW_COMPONENTS_BREADCRUMB = 'page.audit.balance-view.components.breadcrumb',
  BALANCE_VIEW_COMPONENTS_CARD = 'page.audit.balance-view.components.card',
  BALANCE_VIEW_COMPONENTS_LINK_TITLE = 'page.audit.balance-view.components.link.title',
  BALANCE_VIEW_COMPONENTS_TRANSFERS_CARD_TITLE = 'page.audit.balance-view.components.transfers-card.title',
  TRANSFERS_COMPONENTS_BREADCRUMB = 'page.audit.transfers.components.breadcrumb',
  TRANSFERS_COMPONENTS_LINK_TITLE = 'page.audit.transfers.components.link.title',
  TRANSFERS_COMPONENTS_CARD_TITLE = 'page.audit.transfers.components.card.title',
  TRANSFER_VIEW_COMPONENTS_BREADCRUMB = 'page.audit.transfer-view.components.breadcrumb',
  TRANSFER_VIEW_COMPONENTS_CARD = 'page.audit.transfer-view.components.card',
  TRANSFER_VIEW_COMPONENTS_LINK_TITLE = 'page.audit.transfer-view.components.link.title',
  TRANSFER_ENTITY_COMPONENTS_BREADCRUMB = 'page.audit.transfer-entity.components.breadcrumb',
  TRANSFER_ENTITY_COMPONENTS_LINK_TITLE = 'page.audit.transfer-entity.components.link.title',
  TRANSFER_ENTITY_COMPONENTS_LINK_UNLINKED = 'page.audit.transfer-entity.components.link.unlinked',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_TITLE = 'page.audit.transfer-entity.components.entity-card.title',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_LOADING = 'page.audit.transfer-entity.components.entity-card.loading',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_UNKNOWN_TITLE = 'page.audit.transfer-entity.components.entity-card.unknown.title',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_UNKNOWN_DESCRIPTION = 'page.audit.transfer-entity.components.entity-card.unknown.description',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_FOUND_ALERT_TITLE = 'page.audit.transfer-entity.components.entity-card.entity-not-found-alert.title',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_FOUND_ALERT_DESCRIPTION = 'page.audit.transfer-entity.components.entity-card.entity-not-found-alert.description',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_SUPPORTED_ALERT_TITLE = 'page.audit.transfer-entity.components.entity-card.entity-not-supported-alert.title',
  TRANSFER_ENTITY_COMPONENTS_ENTITY_CARD_ENTITY_NOT_SUPPORTED_ALERT_DESCRIPTION = 'page.audit.transfer-entity.components.entity-card.entity-not-supported-alert.description'
}
export enum I18nPageAuth {
  COMPONENTS_EMAIL_BLOCK_TITLE_WELCOME = 'page.auth.components.email-block.title.welcome',
  COMPONENTS_EMAIL_BLOCK_TITLE_WALLET = 'page.auth.components.email-block.title.wallet',
  COMPONENTS_EMAIL_BLOCK_TITLE_GOOGLE = 'page.auth.components.email-block.title.google',
  COMPONENTS_EMAIL_BLOCK_TITLE_EMAIL = 'page.auth.components.email-block.title.email',
  COMPONENTS_EMAIL_BLOCK_DESCRIPTION = 'page.auth.components.email-block.description',
  COMPONENTS_EMAIL_BLOCK_SECURITY_NOTICE = 'page.auth.components.email-block.security-notice',
  COMPONENTS_EMAIL_BLOCK_GOOGLE_LABEL = 'page.auth.components.email-block.google.label',
  COMPONENTS_EMAIL_BLOCK_EMAIL_LABEL = 'page.auth.components.email-block.email.label',
  COMPONENTS_WALLETS_BLOCK_DESCRIPTION = 'page.auth.components.wallets-block.description',
  MESSAGE_SUCCESS = 'page.auth.message-success',
  MESSAGE_WELCOME = 'page.auth.message-welcome',
  MESSAGE_GENERIC_ERROR = 'page.auth.message-generic-error',
  BLOCK_DIVIDER = 'page.auth.block-divider'
}
export enum I18nPageBalances {
  COMPONENTS_BREADCRUMB_TITLE = 'page.balances.components.breadcrumb.title',
  ACTUAL_COMPONENTS_BREADCRUMB_TITLE = 'page.balances.actual.components.breadcrumb.title',
  ACTUAL_COMPONENTS_LINK_TITLE = 'page.balances.actual.components.link.title',
  ACTUAL_COMPONENTS_BALANCES_CARD_TITLE = 'page.balances.actual.components.balances-card.title'
}
export enum I18nPageCallback {
  EMAIL_RENEW_SUCCESS_MESSAGE = 'page.callback.email-renew.success-message',
  EMAIL_RENEW_ERROR_NO_PARAMETERS_TITLE = 'page.callback.email-renew.error.no-parameters.title',
  EMAIL_RENEW_ERROR_NO_PARAMETERS_DESCRIPTION = 'page.callback.email-renew.error.no-parameters.description',
  EMAIL_RENEW_ERROR_INVALID_TOKEN = 'page.callback.email-renew.error.invalid-token',
  EMAIL_RENEW_ERROR_EXPIRED_TOKEN = 'page.callback.email-renew.error.expired-token',
  EMAIL_RENEW_ERROR_COMMON_TITLE = 'page.callback.email-renew.error.common.title',
  EMAIL_RENEW_ERROR_COMMON_DESCRIPTION = 'page.callback.email-renew.error.common.description'
}
export enum I18nPageCollectable {
  TASKS_COMPONENTS_BREADCRUMB = 'page.collectable.tasks.components.breadcrumb',
  TASKS_COMPONENTS_LINK_TITLE = 'page.collectable.tasks.components.link.title',
  TASKS_COMPONENTS_TASKS_CARD_TITLE = 'page.collectable.tasks.components.tasks-card.title',
  TASK_VIEW_COMPONENTS_BREADCRUMB = 'page.collectable.task-view.components.breadcrumb',
  TASK_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE = 'page.collectable.task-view.components.transaction-card.title',
  TASK_VIEW_COMPONENTS_ADDRESSES_CARD_TITLE = 'page.collectable.task-view.components.addresses-card.title',
  TASK_VIEW_COMPONENTS_TASK_CARD_TITLE = 'page.collectable.task-view.components.task-card.title',
  TASK_VIEW_COMPONENTS_LINK = 'page.collectable.task-view.components.link',
  COMPONENTS_SCHEDULE_CARD_TITLE = 'page.collectable.components.schedule-card.title'
}
export enum I18nPageDashboard {
  COMPONENTS_BREADCRUMB = 'page.dashboard.components.breadcrumb',
  COMPONENTS_LINK = 'page.dashboard.components.link',
  COMPONENTS_GAS_WALLETS_CARD_TITLE = 'page.dashboard.components.gas-wallets-card.title',
  COMPONENTS_PAYMENTS_CARD_TITLE = 'page.dashboard.components.payments-card.title',
  COMPONENTS_PAYMENTS_CARD_TODAY_COL_CREATED_TITLE = 'page.dashboard.components.payments-card.today-col.created.title',
  COMPONENTS_PAYMENTS_CARD_TODAY_COL_PAID_TITLE = 'page.dashboard.components.payments-card.today-col.paid.title',
  COMPONENTS_PAYMENTS_CARD_TODAY_COL_EXPIRED_TITLE = 'page.dashboard.components.payments-card.today-col.expired.title',
  COMPONENTS_SALES_VOLUME_CARD_TITLE = 'page.dashboard.components.sales-volume-card.title',
  COMPONENTS_SALES_VOLUME_CARD_COL_COUNT_TODAY_TITLE = 'page.dashboard.components.sales-volume-card.col.count.today.title',
  COMPONENTS_SALES_VOLUME_CARD_COL_AMOUNT_TODAY_TITLE = 'page.dashboard.components.sales-volume-card.col.amount.today.title',
  COMPONENTS_SUBSCRIPTIONS_CARD_TITLE = 'page.dashboard.components.subscriptions-card.title',
  COMPONENTS_SUBSCRIPTIONS_CARD_ACTIVE_TITLE = 'page.dashboard.components.subscriptions-card.active.title',
  COMPONENTS_SUBSCRIPTIONS_CARD_MRR_TITLE = 'page.dashboard.components.subscriptions-card.mrr.title'
}
export enum I18nPageDonations {
  DONATION_ADD_MESSAGES_SUCCESS = 'page.donations.donation-add.messages.success',
  DONATION_ADD_MESSAGES_ERROR = 'page.donations.donation-add.messages.error',
  DONATION_ADD_COMPONENTS_BREADCRUMB_TITLE = 'page.donations.donation-add.components.breadcrumb.title',
  DONATION_ADD_COMPONENTS_LINK_TITLE = 'page.donations.donation-add.components.link.title',
  DONATION_EDIT_MESSAGES_SUCCESS = 'page.donations.donation-edit.messages.success',
  DONATION_EDIT_MESSAGES_ERROR = 'page.donations.donation-edit.messages.error',
  DONATION_EDIT_COMPONENTS_BREADCRUMB_TITLE = 'page.donations.donation-edit.components.breadcrumb.title',
  DONATION_EDIT_COMPONENTS_LINK_TITLE = 'page.donations.donation-edit.components.link.title',
  DONATION_VIEW_COMPONENTS_BREADCRUMB = 'page.donations.donation-view.components.breadcrumb',
  DONATION_VIEW_COMPONENTS_DONATION_CARD_TITLE = 'page.donations.donation-view.components.donation-card.title',
  DONATION_VIEW_COMPONENTS_DONATION_ASSETS_CARD_TITLE = 'page.donations.donation-view.components.donation-assets-card.title',
  DONATION_VIEW_COMPONENTS_COLLECT_TASKS_CARD_TITLE = 'page.donations.donation-view.components.collect-tasks-card.title',
  DONATION_VIEW_COMPONENTS_TRANSACTIONS_CARD_TITLE = 'page.donations.donation-view.components.transactions-card.title',
  DONATION_VIEW_COMPONENTS_LINK = 'page.donations.donation-view.components.link',
  DONATION_ASSETS_COMPONENTS_BREADCRUMB = 'page.donations.donation-assets.components.breadcrumb',
  DONATION_ASSET_VIEW_COMPONENTS_BREADCRUMB = 'page.donations.donation-asset-view.components.breadcrumb',
  DONATION_ASSET_VIEW_COMPONENTS_DONATION_CARD_TITLE = 'page.donations.donation-asset-view.components.donation-card.title',
  DONATION_ASSET_VIEW_COMPONENTS_DONATION_ASSET_CARD_TITLE = 'page.donations.donation-asset-view.components.donation-asset-card.title',
  DONATION_ASSET_VIEW_COMPONENTS_DEPLOY_TRANSACTION_CARD_TITLE = 'page.donations.donation-asset-view.components.deploy-transaction-card.title',
  DONATION_ASSET_VIEW_COMPONENTS_LINK = 'page.donations.donation-asset-view.components.link',
  DONATIONS_COMPONENTS_BREADCRUMB = 'page.donations.donations.components.breadcrumb',
  DONATIONS_COMPONENTS_LINK_TITLE = 'page.donations.donations.components.link.title',
  DONATIONS_COMPONENTS_DONATIONS_CARD_TITLE = 'page.donations.donations.components.donations-card.title',
  TRANSACTION_VIEW_COMPONENTS_BREADCRUMB = 'page.donations.transaction-view.components.breadcrumb',
  TRANSACTION_VIEW_COMPONENTS_DONATION_CARD_TITLE = 'page.donations.transaction-view.components.donation-card.title',
  TRANSACTION_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE = 'page.donations.transaction-view.components.transaction-card.title',
  TRANSACTION_VIEW_COMPONENTS_LINK = 'page.donations.transaction-view.components.link',
  TRANSACTIONS_COMPONENTS_BREADCRUMB = 'page.donations.transactions.components.breadcrumb',
  TRANSACTIONS_COMPONENTS_TRANSACTIONS_CARD_TITLE = 'page.donations.transactions.components.transactions-card.title',
  TRANSACTIONS_COMPONENTS_LINK = 'page.donations.transactions.components.link'
}
export enum I18nPageGasHistory {
  ALL_COMPONENTS_BREADCRUMB = 'page.gas.history.all.components.breadcrumb',
  ALL_COMPONENTS_CARD = 'page.gas.history.all.components.card',
  ALL_COMPONENTS_LINK_TITLE = 'page.gas.history.all.components.link.title',
  GAS_HISTORY_VIEW_COMPONENTS_BREADCRUMB = 'page.gas.history.gas-history-view.components.breadcrumb',
  GAS_HISTORY_VIEW_COMPONENTS_CARD = 'page.gas.history.gas-history-view.components.card',
  GAS_HISTORY_VIEW_COMPONENTS_LINK_TITLE = 'page.gas.history.gas-history-view.components.link.title'
}
export enum I18nPagePayments {
  PAYMENTS_COMPONENTS_BREADCRUMB = 'page.payments.payments.components.breadcrumb',
  PAYMENTS_COMPONENTS_LINK_TITLE = 'page.payments.payments.components.link.title',
  PAYMENTS_COMPONENTS_PAYMENTS_CARD_TITLE = 'page.payments.payments.components.payments-card.title',
  PAYMENT_ADD_MESSAGES_SUCCESS = 'page.payments.payment-add.messages.success',
  PAYMENT_ADD_MESSAGES_ERROR = 'page.payments.payment-add.messages.error',
  PAYMENT_ADD_COMPONENTS_BREADCRUMB_TITLE = 'page.payments.payment-add.components.breadcrumb.title',
  PAYMENT_ADD_COMPONENTS_LINK_TITLE = 'page.payments.payment-add.components.link.title',
  PAYMENT_VIEW_COMPONENTS_BREADCRUMB = 'page.payments.payment-view.components.breadcrumb',
  PAYMENT_VIEW_COMPONENTS_TRANSACTIONS_CARD_TITLE = 'page.payments.payment-view.components.transactions-card.title',
  PAYMENT_VIEW_COMPONENTS_PAYMENT_CARD_TITLE = 'page.payments.payment-view.components.payment-card.title',
  PAYMENT_VIEW_COMPONENTS_LINK = 'page.payments.payment-view.components.link',
  TRANSACTIONS_COMPONENTS_BREADCRUMB = 'page.payments.transactions.components.breadcrumb',
  TRANSACTION_VIEW_COMPONENTS_BREADCRUMB = 'page.payments.transaction-view.components.breadcrumb',
  TRANSACTION_VIEW_COMPONENTS_PAYMENT_CARD_TITLE = 'page.payments.transaction-view.components.payment-card.title',
  TRANSACTION_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE = 'page.payments.transaction-view.components.transaction-card.title',
  TRANSACTION_VIEW_COMPONENTS_LINK = 'page.payments.transaction-view.components.link'
}
export enum I18nPageRecharges {
  ADDRESS_ADD_MESSAGES_SUCCESS = 'page.recharges.address-add.messages.success',
  ADDRESS_ADD_MESSAGES_ERROR = 'page.recharges.address-add.messages.error',
  ADDRESS_ADD_COMPONENTS_BREADCRUMB_TITLE = 'page.recharges.address-add.components.breadcrumb.title',
  ADDRESS_ADD_COMPONENTS_LINK_TITLE = 'page.recharges.address-add.components.link.title',
  ADDRESS_VIEW_COMPONENTS_BREADCRUMB = 'page.recharges.address-view.components.breadcrumb',
  ADDRESS_VIEW_COMPONENTS_ADDRESS_CARD_TITLE = 'page.recharges.address-view.components.address-card.title',
  ADDRESS_VIEW_COMPONENTS_DEPLOY_TRANSACTION_CARD_TITLE = 'page.recharges.address-view.components.deploy-transaction-card.title',
  ADDRESS_VIEW_COMPONENTS_COLLECT_TASKS_CARD_TITLE = 'page.recharges.address-view.components.collect-tasks-card.title',
  ADDRESS_VIEW_COMPONENTS_TRANSACTIONS_CARD_TITLE = 'page.recharges.address-view.components.transactions-card.title',
  ADDRESS_VIEW_COMPONENTS_LINK = 'page.recharges.address-view.components.link',
  ADDRESSES_COMPONENTS_BREADCRUMB = 'page.recharges.addresses.components.breadcrumb',
  ADDRESSES_COMPONENTS_LINK_TITLE = 'page.recharges.addresses.components.link.title',
  ADDRESSES_COMPONENTS_ADDRESSES_CARD_TITLE = 'page.recharges.addresses.components.addresses-card.title',
  TRANSACTION_VIEW_COMPONENTS_BREADCRUMB = 'page.recharges.transaction-view.components.breadcrumb',
  TRANSACTION_VIEW_COMPONENTS_ADDRESS_CARD_TITLE = 'page.recharges.transaction-view.components.address-card.title',
  TRANSACTION_VIEW_COMPONENTS_TRANSACTION_CARD_TITLE = 'page.recharges.transaction-view.components.transaction-card.title',
  TRANSACTION_VIEW_COMPONENTS_LINK = 'page.recharges.transaction-view.components.link',
  TRANSACTIONS_COMPONENTS_BREADCRUMB = 'page.recharges.transactions.components.breadcrumb',
  TRANSACTIONS_COMPONENTS_TRANSACTIONS_CARD_TITLE = 'page.recharges.transactions.components.transactions-card.title',
  TRANSACTIONS_COMPONENTS_LINK = 'page.recharges.transactions.components.link'
}
export enum I18nPageReports {
  ALL_COMPONENTS_BREADCRUMB = 'page.reports.all.components.breadcrumb',
  ALL_COMPONENTS_LINK_TITLE = 'page.reports.all.components.link.title',
  REPORT_VIEW_COMPONENTS_BREADCRUMB = 'page.reports.report-view.components.breadcrumb',
  REPORT_VIEW_COMPONENTS_LINK_TITLE = 'page.reports.report-view.components.link.title',
  REPORT_VIEW_COMPONENTS_REPORT_CARD_TITLE = 'page.reports.report-view.components.report-card.title',
  REPORT_SCHEDULES_COMPONENTS_BREADCRUMB = 'page.reports.report-schedules.components.breadcrumb',
  REPORT_SCHEDULES_COMPONENTS_LINK_TITLE = 'page.reports.report-schedules.components.link.title',
  REPORT_SCHEDULE_VIEW_COMPONENTS_BREADCRUMB = 'page.reports.report-schedule-view.components.breadcrumb',
  REPORT_SCHEDULE_VIEW_COMPONENTS_LINK_TITLE = 'page.reports.report-schedule-view.components.link.title',
  REPORT_SCHEDULE_VIEW_COMPONENTS_REPORT_SCHEDULE_CARD_TITLE = 'page.reports.report-schedule-view.components.report-schedule-card.title',
  REPORT_SCHEDULE_ADD_MESSAGES_SUCCESS = 'page.reports.report-schedule-add.messages.success',
  REPORT_SCHEDULE_ADD_MESSAGES_ERROR = 'page.reports.report-schedule-add.messages.error',
  REPORT_SCHEDULE_ADD_COMPONENTS_BREADCRUMB = 'page.reports.report-schedule-add.components.breadcrumb',
  REPORT_SCHEDULE_ADD_COMPONENTS_LINK_TITLE = 'page.reports.report-schedule-add.components.link.title',
  REPORT_SCHEDULE_EDIT_MESSAGES_SUCCESS = 'page.reports.report-schedule-edit.messages.success',
  REPORT_SCHEDULE_EDIT_MESSAGES_ERROR = 'page.reports.report-schedule-edit.messages.error',
  REPORT_SCHEDULE_EDIT_COMPONENTS_BREADCRUMB = 'page.reports.report-schedule-edit.components.breadcrumb',
  REPORT_SCHEDULE_EDIT_COMPONENTS_LINK_TITLE = 'page.reports.report-schedule-edit.components.link.title'
}
export enum I18nPageSettings {
  TABS_SETTINGS = 'page.settings.tabs.settings',
  TABS_INTEGRATION = 'page.settings.tabs.integration',
  TABS_BRANDING = 'page.settings.tabs.branding',
  TABS_PROFILE = 'page.settings.tabs.profile',
  TABS_PERMISSIONS = 'page.settings.tabs.permissions',
  COMPONENTS_BREADCRUMB = 'page.settings.components.breadcrumb',
  COMPONENTS_LINK = 'page.settings.components.link',
  BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_TITLE = 'page.settings.branding.summary.components.active-profile-card.title',
  BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_EMPTY = 'page.settings.branding.summary.components.active-profile-card.empty',
  BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_NO_ACTIVE = 'page.settings.branding.summary.components.active-profile-card.no-active',
  BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_ACTIVE = 'page.settings.branding.summary.components.active-profile-card.active',
  BRANDING_SUMMARY_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.branding.summary.components.breadcrumb.title',
  BRANDING_SUMMARY_COMPONENTS_LINK_TITLE = 'page.settings.branding.summary.components.link.title',
  BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_TITLE = 'page.settings.branding.summary.components.domains-card.title',
  BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_DESCRIPTION = 'page.settings.branding.summary.components.domains-card.description',
  BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_UNAPPROVED_TITLE = 'page.settings.branding.summary.components.domains-card.unapproved-title',
  BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_DOMAIN_ACTIVE_MESSAGE = 'page.settings.branding.summary.components.domains-card.domain-active.message',
  BRANDING_SUMMARY_COMPONENTS_DOMAINS_CARD_DOMAIN_ACTIVE_TOOLTIP = 'page.settings.branding.summary.components.domains-card.domain-active.tooltip',
  BRANDING_SUMMARY_COMPONENTS_TOS_CARD_TITLE = 'page.settings.branding.summary.components.tos-card.title',
  BRANDING_SUMMARY_COMPONENTS_TOS_CARD_DESCRIPTION_EMPTY = 'page.settings.branding.summary.components.tos-card.description.empty',
  BRANDING_SUMMARY_COMPONENTS_TOS_CARD_DESCRIPTION_DEFINED = 'page.settings.branding.summary.components.tos-card.description.defined',
  BRANDING_DOMAINS_DESCRIPTION = 'page.settings.branding.domains.description',
  BRANDING_DOMAINS_DOMAIN_UNAPPROVED_TITLE = 'page.settings.branding.domains.domain.unapproved-title',
  BRANDING_DOMAINS_DOMAIN_ACTIVE_MESSAGE = 'page.settings.branding.domains.domain.active-message',
  BRANDING_DOMAINS_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.branding.domains.components.breadcrumb.title',
  BRANDING_DOMAINS_COMPONENTS_LINK_TITLE = 'page.settings.branding.domains.components.link.title',
  BRANDING_TOS_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.branding.tos.components.breadcrumb.title',
  BRANDING_TOS_COMPONENTS_LINK_TITLE = 'page.settings.branding.tos.components.link.title',
  BRANDING_PROFILES_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.branding.profiles.components.breadcrumb.title',
  BRANDING_PROFILES_COMPONENTS_LINK_TITLE = 'page.settings.branding.profiles.components.link.title',
  BRANDING_PROFILE_VIEW_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.branding.profile-view.components.breadcrumb.title',
  BRANDING_PROFILE_VIEW_COMPONENTS_LINK_TITLE = 'page.settings.branding.profile-view.components.link.title',
  BRANDING_PROFILE_EDIT_MESSAGES_SUCCESS = 'page.settings.branding.profile-edit.messages.success',
  BRANDING_PROFILE_EDIT_MESSAGES_ERROR = 'page.settings.branding.profile-edit.messages.error',
  BRANDING_PROFILE_EDIT_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.branding.profile-edit.components.breadcrumb.title',
  BRANDING_PROFILE_EDIT_COMPONENTS_LINK_TITLE = 'page.settings.branding.profile-edit.components.link.title',
  BRANDING_PROFILE_ADD_MESSAGES_SUCCESS = 'page.settings.branding.profile-add.messages.success',
  BRANDING_PROFILE_ADD_MESSAGES_ERROR = 'page.settings.branding.profile-add.messages.error',
  BRANDING_PROFILE_ADD_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.branding.profile-add.components.breadcrumb.title',
  BRANDING_PROFILE_ADD_COMPONENTS_LINK_TITLE = 'page.settings.branding.profile-add.components.link.title',
  COMPANY_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.company.components.breadcrumb.title',
  COMPANY_ASSETS_EDIT_BREADCRUMB_TITLE = 'page.settings.company.assets-edit.breadcrumb.title',
  COMPANY_ASSETS_EDIT_LINK_TITLE = 'page.settings.company.assets-edit.link.title',
  COMPANY_GAS_WALLET_BREADCRUMB_TITLE = 'page.settings.company.gas-wallet.breadcrumb.title',
  COMPANY_GAS_WALLET_LINK_TITLE = 'page.settings.company.gas-wallet.link.title',
  COMPANY_GAS_WALLET_COMPONENTS_WALLET_CARD_TITLE = 'page.settings.company.gas-wallet.components.wallet-card.title',
  COMPANY_GAS_WALLET_COMPONENTS_WALLET_CARD_DESCRIPTION = 'page.settings.company.gas-wallet.components.wallet-card.description',
  COMPANY_GAS_WALLET_COMPONENTS_DERIVED_CARD_TITLE = 'page.settings.company.gas-wallet.components.derived-card.title',
  COMPANY_GAS_WALLET_COMPONENTS_DERIVED_CARD_DESCRIPTION = 'page.settings.company.gas-wallet.components.derived-card.description',
  COMPANY_GAS_WALLET_COMPONENTS_ASSETS_CARD_TITLE = 'page.settings.company.gas-wallet.components.assets-card.title',
  COMPANY_GAS_WALLET_COMPONENTS_ASSETS_CARD_MESSAGES_NO_ASSETS = 'page.settings.company.gas-wallet.components.assets-card.messages.no-assets',
  COMPANY_GAS_WALLET_COMPONENTS_ASSETS_CARD_MESSAGES_ASSETS = 'page.settings.company.gas-wallet.components.assets-card.messages.assets',
  COMPANY_GAS_WALLETS_BREADCRUMB_TITLE = 'page.settings.company.gas-wallets.breadcrumb.title',
  COMPANY_GAS_WALLETS_LINK_TITLE = 'page.settings.company.gas-wallets.link.title',
  COMPANY_GAS_WALLETS_COMPONENTS_WALLETS_CARD_TITLE = 'page.settings.company.gas-wallets.components.wallets-card.title',
  COMPANY_GAS_WALLETS_COMPONENTS_WALLETS_CARD_DESCRIPTION = 'page.settings.company.gas-wallets.components.wallets-card.description',
  COMPANY_SETTINGS_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.company.settings.components.breadcrumb.title',
  COMPANY_SETTINGS_COMPONENTS_LINK_TITLE = 'page.settings.company.settings.components.link.title',
  COMPANY_SETTINGS_COMPONENTS_ASSETS_CARD_TITLE = 'page.settings.company.settings.components.assets-card.title',
  COMPANY_SETTINGS_COMPONENTS_GAS_WALLETS_CARD_TITLE = 'page.settings.company.settings.components.gas-wallets-card.title',
  COMPANY_SETTINGS_COMPONENTS_GAS_WALLETS_CARD_DESCRIPTION = 'page.settings.company.settings.components.gas-wallets-card.description',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_TITLE = 'page.settings.company.settings.components.general-card.title',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_LABEL = 'page.settings.company.settings.components.general-card.kyb-state-item.label',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_VALUE_FINISHED = 'page.settings.company.settings.components.general-card.kyb-state-item.value-finished',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_KYB_STATE_ITEM_VALUE_UNFINISHED = 'page.settings.company.settings.components.general-card.kyb-state-item.value-unfinished',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_CONTACT_EMAIL_ITEM_LABEL = 'page.settings.company.settings.components.general-card.contact-email-item.label',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_CONTACT_EMAIL_ITEM_HELP = 'page.settings.company.settings.components.general-card.contact-email-item.help',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_COLLECT_SCHEDULE_ITEM_LABEL = 'page.settings.company.settings.components.general-card.collect-schedule-item.label',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_COLLECT_SCHEDULE_ITEM_VALUE_UNSET = 'page.settings.company.settings.components.general-card.collect-schedule-item.value-unset',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_COLLECT_SCHEDULE_ITEM_VALUE_SET = 'page.settings.company.settings.components.general-card.collect-schedule-item.value-set',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_COLLECT_SCHEDULE_ITEM_HELP = 'page.settings.company.settings.components.general-card.collect-schedule-item.help',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_COLLECT_SCHEDULE_ITEM_LINK = 'page.settings.company.settings.components.general-card.collect-schedule-item.link',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_LABEL = 'page.settings.company.settings.components.general-card.settlement-schedule-item.label',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_VALUE_UNSET = 'page.settings.company.settings.components.general-card.settlement-schedule-item.value-unset',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_VALUE_SET = 'page.settings.company.settings.components.general-card.settlement-schedule-item.value-set',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_HELP = 'page.settings.company.settings.components.general-card.settlement-schedule-item.help',
  COMPANY_SETTINGS_COMPONENTS_GENERAL_CARD_SETTLEMENT_SCHEDULE_ITEM_LINK = 'page.settings.company.settings.components.general-card.settlement-schedule-item.link',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_TITLE = 'page.settings.company.settings.components.payments-configuration-card.title',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_DESCRIPTION = 'page.settings.company.settings.components.payments-configuration-card.description',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_COMPENSATION_TITLE = 'page.settings.company.settings.components.payments-configuration-card.compensation.title',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_COMPENSATION_DESCRIPTION = 'page.settings.company.settings.components.payments-configuration-card.compensation.description',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_DEFERRED_TITLE = 'page.settings.company.settings.components.payments-configuration-card.deferred.title',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_DEFERRED_DESCRIPTION = 'page.settings.company.settings.components.payments-configuration-card.deferred.description',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_OPERATION_UPDATE_DEFERRED_MARK_MESSAGE_SUCCESS = 'page.settings.company.settings.components.payments-configuration-card.operation-update-deferred-mark.message-success',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_OPERATION_UPDATE_DEFERRED_MARK_MESSAGE_ERROR = 'page.settings.company.settings.components.payments-configuration-card.operation-update-deferred-mark.message-error',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_OPERATION_UPDATE_COMPENSATION_MARK_MESSAGE_SUCCESS = 'page.settings.company.settings.components.payments-configuration-card.operation-update-compensation-mark.message-success',
  COMPANY_SETTINGS_COMPONENTS_PAYMENTS_CONFIGURATION_CARD_OPERATION_UPDATE_COMPENSATION_MARK_MESSAGE_ERROR = 'page.settings.company.settings.components.payments-configuration-card.operation-update-compensation-mark.message-error',
  COMPANY_SETTINGS_COMPONENTS_QR_SUPPORT_CARD_TITLE = 'page.settings.company.settings.components.qr-support-card.title',
  COMPANY_SETTINGS_COMPONENTS_QR_SUPPORT_CARD_DESCRIPTION = 'page.settings.company.settings.components.qr-support-card.description',
  COMPANY_QR_SUPPORT_EDIT_MESSAGES_SUCCESS = 'page.settings.company.qr-support-edit.messages.success',
  COMPANY_QR_SUPPORT_EDIT_MESSAGES_ERROR = 'page.settings.company.qr-support-edit.messages.error',
  COMPANY_QR_SUPPORT_EDIT_BREADCRUMB_TITLE = 'page.settings.company.qr-support-edit.breadcrumb.title',
  COMPANY_QR_SUPPORT_EDIT_LINK_TITLE = 'page.settings.company.qr-support-edit.link.title',
  INTEGRATION_COMPONENTS_BREADCRUMB_TITLE = 'page.settings.integration.components.breadcrumb.title',
  INTEGRATION_COMPONENTS_LINK_TITLE = 'page.settings.integration.components.link.title',
  INTEGRATION_COMPONENTS_API_KEYS_CARD_TITLE = 'page.settings.integration.components.api-keys-card.title',
  INTEGRATION_COMPONENTS_API_KEYS_CARD_DESCRIPTION = 'page.settings.integration.components.api-keys-card.description',
  INTEGRATION_COMPONENTS_WEBHOOKS_CARD_TITLE = 'page.settings.integration.components.webhooks-card.title',
  INTEGRATION_COMPONENTS_WEBHOOKS_CARD_DESCRIPTION = 'page.settings.integration.components.webhooks-card.description',
  PERMISSIONS_BREADCRUMB = 'page.settings.permissions.breadcrumb',
  PERMISSIONS_LINK = 'page.settings.permissions.link',
  PERMISSIONS_COMPONENTS_USERS_CARD_TITLE = 'page.settings.permissions.components.users-card.title',
  PERMISSIONS_COMPONENTS_GENERAL_CARD_TITLE = 'page.settings.permissions.components.general-card.title',
  PERMISSIONS_COMPONENTS_GENERAL_CARD_NAME_ITEM_LABEL = 'page.settings.permissions.components.general-card.name-item.label',
  PERMISSIONS_COMPONENTS_GENERAL_CARD_OWNER_ITEM_LABEL = 'page.settings.permissions.components.general-card.owner-item.label',
  PROFILE_BREADCRUMB = 'page.settings.profile.breadcrumb',
  PROFILE_LINK = 'page.settings.profile.link',
  PROFILE_COMPONENTS_COMPANIES_CARD_TITLE = 'page.settings.profile.components.companies-card.title',
  PROFILE_COMPONENTS_PROFILE_CARD_TITLE = 'page.settings.profile.components.profile-card.title',
  PROFILE_COMPONENTS_PROFILE_CARD_LOGIN_EMAIL_ITEM_LABEL = 'page.settings.profile.components.profile-card.login-email-item.label',
  PROFILE_COMPONENTS_PROFILE_CARD_ADDRESS_LABEL = 'page.settings.profile.components.profile-card.address.label',
  PROFILE_COMPONENTS_PROFILE_CARD_ADDRESS_HELP = 'page.settings.profile.components.profile-card.address.help'
}
export enum I18nPageSettlements {
  HISTORY_COMPONENTS_BREADCRUMB = 'page.settlements.history.components.breadcrumb',
  HISTORY_COMPONENTS_LINK_TITLE = 'page.settlements.history.components.link.title',
  HISTORY_COMPONENTS_CARD_ASSET_TITLE = 'page.settlements.history.components.card.asset-title',
  HISTORY_COMPONENTS_CARD_FULL_TITLE = 'page.settlements.history.components.card.full-title',
  HISTORY_COMPONENTS_SCHEDULE_CARD_TITLE = 'page.settlements.history.components.schedule-card.title',
  INTENT_VIEW_COMPONENTS_BREADCRUMB_PARENT_TITLE = 'page.settlements.intent-view.components.breadcrumb.parent.title',
  INTENT_VIEW_COMPONENTS_BREADCRUMB_TITLE = 'page.settlements.intent-view.components.breadcrumb.title',
  INTENT_VIEW_COMPONENTS_LINK_TITLE = 'page.settlements.intent-view.components.link.title',
  INTENT_VIEW_COMPONENTS_SETTLEMENT_CARD_TITLE = 'page.settlements.intent-view.components.settlement-card.title',
  INTENT_VIEW_COMPONENTS_INTENT_CARD_TITLE = 'page.settlements.intent-view.components.intent-card.title',
  PENDING_COMPONENTS_BREADCRUMB = 'page.settlements.pending.components.breadcrumb',
  PENDING_COMPONENTS_INTENTS_CARD_TITLE = 'page.settlements.pending.components.intents-card.title',
  PENDING_COMPONENTS_LINK_TITLE = 'page.settlements.pending.components.link.title',
  PAYOUTS_COMPONENTS_BREADCRUMB = 'page.settlements.payouts.components.breadcrumb',
  PAYOUTS_COMPONENTS_PAYOUTS_CARD_TITLE = 'page.settlements.payouts.components.payouts-card.title',
  PAYOUTS_COMPONENTS_LINK_TITLE = 'page.settlements.payouts.components.link.title',
  PAYOUT_ADD_MESSAGES_SUCCESS_TITLE = 'page.settlements.payout-add.messages.success.title',
  PAYOUT_ADD_MESSAGES_SUCCESS_DESCRIPTION = 'page.settlements.payout-add.messages.success.description',
  PAYOUT_ADD_MESSAGES_ERROR = 'page.settlements.payout-add.messages.error',
  PAYOUT_ADD_COMPONENTS_BREADCRUMB_TITLE = 'page.settlements.payout-add.components.breadcrumb.title',
  PAYOUT_ADD_COMPONENTS_LINK_TITLE = 'page.settlements.payout-add.components.link.title',
  PAYOUT_ADD_COMPONENTS_LINK_COPY_TITLE = 'page.settlements.payout-add.components.link-copy.title',
  PAYOUT_EDIT_MESSAGES_SUCCESS_TITLE = 'page.settlements.payout-edit.messages.success.title',
  PAYOUT_EDIT_MESSAGES_ERROR = 'page.settlements.payout-edit.messages.error',
  PAYOUT_EDIT_COMPONENTS_BREADCRUMB_TITLE = 'page.settlements.payout-edit.components.breadcrumb.title',
  PAYOUT_EDIT_COMPONENTS_LINK_TITLE = 'page.settlements.payout-edit.components.link.title',
  PAYOUT_EDIT_COMPONENTS_LINK_MESSAGES_INVALID_STATUS = 'page.settlements.payout-edit.components.link.messages.invalid-status',
  PAYOUT_VIEW_COMPONENTS_BREADCRUMB = 'page.settlements.payout-view.components.breadcrumb',
  PAYOUT_VIEW_COMPONENTS_LINK_TITLE = 'page.settlements.payout-view.components.link.title',
  PAYOUT_VIEW_COMPONENTS_BATCHES_CARD_TITLE = 'page.settlements.payout-view.components.batches-card.title',
  PAYOUT_VIEW_COMPONENTS_PAYOUT_CARD_TITLE = 'page.settlements.payout-view.components.payout-card.title',
  PAYOUT_VIEW_COMPONENTS_DESTINATIONS_CARD_TITLE = 'page.settlements.payout-view.components.destinations-card.title',
  PAYOUT_BATCHES_VIEW_COMPONENTS_BREADCRUMB = 'page.settlements.payout-batches-view.components.breadcrumb',
  PAYOUT_BATCH_VIEW_COMPONENTS_BREADCRUMB = 'page.settlements.payout-batch-view.components.breadcrumb',
  PAYOUT_BATCH_VIEW_COMPONENTS_LINK_TITLE = 'page.settlements.payout-batch-view.components.link.title',
  PAYOUT_BATCH_VIEW_COMPONENTS_BATCH_CARD_TITLE = 'page.settlements.payout-batch-view.components.batch-card.title',
  PAYOUT_BATCH_VIEW_COMPONENTS_DESTINATIONS_CARD_TITLE = 'page.settlements.payout-batch-view.components.destinations-card.title',
  PAYOUT_BATCH_VIEW_COMPONENTS_PAYOUT_CARD_TITLE = 'page.settlements.payout-batch-view.components.payout-card.title',
  SCHEDULE_COMPONENTS_BREADCRUMB = 'page.settlements.schedule.components.breadcrumb',
  SCHEDULE_COMPONENTS_LINK_TITLE = 'page.settlements.schedule.components.link.title',
  SCHEDULE_COMPONENTS_SCHEDULE_CARD_TITLE = 'page.settlements.schedule.components.schedule-card.title',
  SCHEDULE_COMPONENTS_INTENTS_CARD_TITLE = 'page.settlements.schedule.components.intents-card.title',
  SETTLEMENTS_COMPONENTS_BREADCRUMB = 'page.settlements.settlements.components.breadcrumb',
  SETTLEMENT_VIEW_COMPONENTS_BREADCRUMB = 'page.settlements.settlement-view.components.breadcrumb',
  SETTLEMENT_VIEW_COMPONENTS_LINK_TITLE = 'page.settlements.settlement-view.components.link.title',
  SETTLEMENT_VIEW_COMPONENTS_SETTLEMENT_CARD_TITLE = 'page.settlements.settlement-view.components.settlement-card.title',
  SETTLEMENT_VIEW_COMPONENTS_RECONCILIATION_CARD_TITLE = 'page.settlements.settlement-view.components.reconciliation-card.title',
  SETTLEMENT_VIEW_COMPONENTS_OUTGOING_TRANSFERS_CARD_TITLE = 'page.settlements.settlement-view.components.outgoing-transfers-card.title',
  SETTLEMENT_VIEW_COMPONENTS_OUTGOING_TRANSFERS_CARD_TOTAL_LABEL = 'page.settlements.settlement-view.components.outgoing-transfers-card.total.label',
  SETTLEMENT_VIEW_COMPONENTS_INCOMING_TRANSFERS_CARD_TITLE = 'page.settlements.settlement-view.components.incoming-transfers-card.title',
  SETTLEMENT_VIEW_COMPONENTS_INCOMING_TRANSFERS_CARD_TOTAL_LABEL = 'page.settlements.settlement-view.components.incoming-transfers-card.total.label',
  SETTLEMENT_VIEW_PAYOUT_LINK_TITLE = 'page.settlements.settlement-view.payout-link.title'
}
export enum I18nPageSubscriptions {
  SUBSCRIPTION_VIEW_COMPONENTS_BREADCRUMB = 'page.subscriptions.subscription-view.components.breadcrumb',
  SUBSCRIPTION_VIEW_COMPONENTS_LINK_TITLE = 'page.subscriptions.subscription-view.components.link.title',
  SUBSCRIPTION_VIEW_COMPONENTS_SUBSCRIPTION_CARD_TITLE = 'page.subscriptions.subscription-view.components.subscription-card.title',
  SUBSCRIPTION_VIEW_COMPONENTS_CHARGES_CARD_TITLE = 'page.subscriptions.subscription-view.components.charges-card.title',
  SUBSCRIPTION_VIEW_COMPONENTS_PLAN_SHORT_CARD_TITLE = 'page.subscriptions.subscription-view.components.plan-short-card.title',
  ALL_COMPONENTS_BREADCRUMB = 'page.subscriptions.all.components.breadcrumb',
  ALL_COMPONENTS_LINK_TITLE = 'page.subscriptions.all.components.link.title',
  ALL_COMPONENTS_SUBSCRIPTIONS_CARD_TITLE = 'page.subscriptions.all.components.subscriptions-card.title',
  CHARGE_VIEW_COMPONENTS_BREADCRUMB_TITLE = 'page.subscriptions.charge-view.components.breadcrumb.title',
  CHARGE_VIEW_COMPONENTS_LINK_TITLE = 'page.subscriptions.charge-view.components.link.title',
  CHARGE_VIEW_COMPONENTS_SUBSCRIPTION_CARD_TITLE = 'page.subscriptions.charge-view.components.subscription-card.title',
  CHARGE_VIEW_COMPONENTS_CHARGE_CARD_TITLE = 'page.subscriptions.charge-view.components.charge-card.title',
  PLAN_ADD_COMPONENTS_BREADCRUMB = 'page.subscriptions.plan-add.components.breadcrumb',
  PLAN_ADD_COMPONENTS_LINK_TITLE = 'page.subscriptions.plan-add.components.link.title',
  PLAN_ADD_COMPONENTS_ERROR_MESSAGE = 'page.subscriptions.plan-add.components.error-message',
  PLAN_ADD_COMPONENTS_SUCCESS_MESSAGE = 'page.subscriptions.plan-add.components.success-message',
  PLAN_EDIT_COMPONENTS_BREADCRUMB = 'page.subscriptions.plan-edit.components.breadcrumb',
  PLAN_EDIT_COMPONENTS_LINK_TITLE = 'page.subscriptions.plan-edit.components.link.title',
  PLAN_EDIT_COMPONENTS_DISABLED_REASON_INVALID_STATUS = 'page.subscriptions.plan-edit.components.disabled-reason.invalid-status',
  PLAN_EDIT_COMPONENTS_ERROR_MESSAGE = 'page.subscriptions.plan-edit.components.error-message',
  PLAN_EDIT_COMPONENTS_SUCCESS_MESSAGE = 'page.subscriptions.plan-edit.components.success-message',
  PLAN_VIEW_COMPONENTS_BREADCRUMB = 'page.subscriptions.plan-view.components.breadcrumb',
  PLAN_VIEW_COMPONENTS_LINK_TITLE = 'page.subscriptions.plan-view.components.link.title',
  PLAN_VIEW_COMPONENTS_PLAN_CARD_TITLE = 'page.subscriptions.plan-view.components.plan-card.title',
  PLAN_VIEW_COMPONENTS_SUBSCRIPTIONS_CARD_TITLE = 'page.subscriptions.plan-view.components.subscriptions-card.title',
  PLANS_COMPONENTS_BREADCRUMB = 'page.subscriptions.plans.components.breadcrumb',
  PLANS_COMPONENTS_LINK_TITLE = 'page.subscriptions.plans.components.link.title',
  PLANS_COMPONENTS_PLANS_CARD_TITLE = 'page.subscriptions.plans.components.plans-card.title',
  STATISTICS_COMPONENTS_BREADCRUMB = 'page.subscriptions.statistics.components.breadcrumb',
  STATISTICS_COMPONENTS_LINK_TITLE = 'page.subscriptions.statistics.components.link.title',
  STATISTICS_COMPONENTS_CHURN_RATE_CARD_TITLE = 'page.subscriptions.statistics.components.churn-rate-card.title',
  STATISTICS_COMPONENTS_CHURN_RATE_CARD_HELP = 'page.subscriptions.statistics.components.churn-rate-card.help',
  STATISTICS_COMPONENTS_GROWTH_CARD_TITLE = 'page.subscriptions.statistics.components.growth-card.title',
  STATISTICS_COMPONENTS_GROWTH_CARD_HELP = 'page.subscriptions.statistics.components.growth-card.help',
  STATISTICS_COMPONENTS_REVENUE_CARD_TITLE = 'page.subscriptions.statistics.components.revenue-card.title',
  STATISTICS_COMPONENTS_REVENUE_CARD_HELP = 'page.subscriptions.statistics.components.revenue-card.help'
}

export type I18nMessages = I18nCommon | I18nComponents | I18nHocs | I18nFeatureAuth | I18nFeatureBranding | I18nFeatureCollectable | I18nFeatureCompany | I18nFeatureCompanySettings | I18nFeatureDictionary | I18nFeatureDonations | I18nFeatureGasHistory | I18nFeatureGasWallets | I18nFeatureGlobal | I18nFeatureEmail | I18nFeatureMerchantWalletBalance | I18nFeatureMerchantWallets | I18nFeatureMerchantWalletTransfers | I18nFeatureOnboard | I18nFeaturePayments | I18nFeaturePaymentTransactions | I18nFeaturePayouts | I18nFeatureRecaptcha | I18nFeatureRecharges | I18nFeatureReports | I18nFeatureReportSchedules | I18nFeatureStatistics | I18nFeatureSettlements | I18nFeatureSettlementReconciliations | I18nFeatureSubscriptions | I18nFeatureSubscriptionCharges | I18nFeatureSubscriptionPlans | I18nFeatureSubscriptionStatistics | I18nFeatureWeb3 | I18nMenu | I18nPageAudit | I18nPageAuth | I18nPageBalances | I18nPageCallback | I18nPageCollectable | I18nPageDashboard | I18nPageDonations | I18nPageGasHistory | I18nPagePayments | I18nPageRecharges | I18nPageReports | I18nPageSettings | I18nPageSettlements | I18nPageSubscriptions;

export enum I18nLocales {
  'en-US' = 'en-US'
}