import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { deleteReport, generateReportDownloadLink } from '@/features/reports/actions';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { HookAction } from '@/infrastructure/model';

import useReport from './useReport';

type GenerateUnavailabilityReason = 'invalid-status';

export interface UseSingleReportActionsType {
  generateURL: HookAction<[], string, GenerateUnavailabilityReason>;
  deleteReport: HookAction;
}

export default function useReportActions(reportId: string): UseSingleReportActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useReport(reportId);

  const isGenerating = useActionPending(generateReportDownloadLink, reportId);
  const generateUnavailabilityReason = useMemo<GenerateUnavailabilityReason | undefined>(() => {
    if (!data.data?.fileId) {
      return 'invalid-status';
    }
    return undefined;
  }, [data.data?.fileId]);
  const generateURL: UseSingleReportActionsType['generateURL'] = {
    act: useCallback(
      () => withExtractDataDispatch(generateReportDownloadLink)(reportId),
      [reportId, withExtractDataDispatch],
    ),
    available: !generateUnavailabilityReason,
    inAction: isGenerating,
  };

  const isDeleting = useActionPending(deleteReport, reportId);
  const deleteReportHook: UseSingleReportActionsType['deleteReport'] = {
    act: useCallback(() => withExtractDataDispatch(deleteReport)(reportId), [reportId, withExtractDataDispatch]),
    available: !!data.data && data.data.status !== ReportStatusAPIModel.Generating,
    inAction: isDeleting,
  };

  return { generateURL, deleteReport: deleteReportHook };
}
