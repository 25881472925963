import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureMerchantWalletTransfers } from '@/generated/i18n/i18n';

import type { MerchantWalletTransferSenderLikeFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_SENDER_LABEL} />,
};

const MerchantWalletTransferSenderLikeFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  ItemProps = {},
}: MerchantWalletTransferSenderLikeFilterItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      messages={messages}
      placeholder={formatMessage({ id: I18nFeatureMerchantWalletTransfers.COMPONENTS_FILTER_FORM_SENDER_PLACEHOLDER })}
      {...ItemProps}
    />
  );
};

const MerchantWalletTransferSenderLikeFilterItemMemo = React.memo(
  MerchantWalletTransferSenderLikeFilterItem,
) as typeof MerchantWalletTransferSenderLikeFilterItem;

export default MerchantWalletTransferSenderLikeFilterItemMemo;
