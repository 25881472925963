import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchReportSchedules } from '@/features/report-schedules/actions';
import {
  makeSelectReportScheduleListData,
  makeSelectReportScheduleListParameters,
} from '@/features/report-schedules/selectors';
import type { ReportSchedule } from '@/features/report-schedules/types';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<ReportSchedule>, Parameters<typeof fetchReportSchedules>[0]> =>
  fetchReportSchedules({ force });
const pendingSelector = makeSelectPending(fetchReportSchedules);
const settlementListParametersSelector = makeSelectReportScheduleListParameters();
const settlementListDataSelector = makeSelectReportScheduleListData();

export default function useReportScheduleList() {
  return useAppListData(fetchFactory, settlementListParametersSelector, pendingSelector, settlementListDataSelector);
}
