/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/


import * as runtime from '../runtime';
import type {
  ApiErrorAPIModel,
  HintUpdateStateAPIModel,
  SliceSubscriptionSummarySubscriptionSortByAPIModel,
  SubscriptionChargesListAPIModel,
  SubscriptionFilterAPIModel,
  SubscriptionSummaryAPIModel,
} from '../models/index';
import {
    ApiErrorAPIModelFromJSON,
    ApiErrorAPIModelToJSON,
    HintUpdateStateAPIModelFromJSON,
    HintUpdateStateAPIModelToJSON,
    SliceSubscriptionSummarySubscriptionSortByAPIModelFromJSON,
    SliceSubscriptionSummarySubscriptionSortByAPIModelToJSON,
    SubscriptionChargesListAPIModelFromJSON,
    SubscriptionChargesListAPIModelToJSON,
    SubscriptionFilterAPIModelFromJSON,
    SubscriptionFilterAPIModelToJSON,
    SubscriptionSummaryAPIModelFromJSON,
    SubscriptionSummaryAPIModelToJSON,
} from '../models/index';

export interface CancelSubscriptionRequest {
    id: string;
}

export interface GetSubscriptionRequest {
    id: string;
}

export interface GetSubscriptionChargesRequest {
    id: string;
}

export interface HintUpdateSubscriptionStateRequest {
    hintUpdateStateAPIModel: HintUpdateStateAPIModel;
}

export interface SearchSubscriptionsRequest {
    subscriptionFilterAPIModel: SubscriptionFilterAPIModel;
    withTotal?: boolean;
}

/**
 * SubscriptionApi - interface
 * 
 * @export
 * @interface SubscriptionApiInterface
 */
export interface SubscriptionApiInterface {
    /**
     * Cancels subscription by id and stops charges
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    cancelSubscriptionRaw(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSummaryAPIModel>>;

    /**
     * Cancels subscription by id and stops charges
     */
    cancelSubscription(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSummaryAPIModel>;

    /**
     * Returns subscription by id (contract address)
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getSubscriptionRaw(requestParameters: GetSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSummaryAPIModel>>;

    /**
     * Returns subscription by id (contract address)
     */
    getSubscription(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSummaryAPIModel>;

    /**
     * Returns charges for the specific subscription (deprecated - use subscriptions-charges/search
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    getSubscriptionChargesRaw(requestParameters: GetSubscriptionChargesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionChargesListAPIModel>>;

    /**
     * Returns charges for the specific subscription (deprecated - use subscriptions-charges/search
     * @deprecated
     */
    getSubscriptionCharges(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionChargesListAPIModel>;

    /**
     * Tries to update state by a hint
     * @param {HintUpdateStateAPIModel} hintUpdateStateAPIModel 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    hintUpdateSubscriptionStateRaw(requestParameters: HintUpdateSubscriptionStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSummaryAPIModel>>;

    /**
     * Tries to update state by a hint
     */
    hintUpdateSubscriptionState(hintUpdateStateAPIModel: HintUpdateStateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSummaryAPIModel>;

    /**
     * Returns subscriptions by a filter
     * @param {SubscriptionFilterAPIModel} subscriptionFilterAPIModel 
     * @param {boolean} [withTotal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionApiInterface
     */
    searchSubscriptionsRaw(requestParameters: SearchSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSubscriptionSummarySubscriptionSortByAPIModel>>;

    /**
     * Returns subscriptions by a filter
     */
    searchSubscriptions(subscriptionFilterAPIModel: SubscriptionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSubscriptionSummarySubscriptionSortByAPIModel>;

}

/**
 * 
 */
export class SubscriptionApi extends runtime.BaseAPI implements SubscriptionApiInterface {

    /**
     * Cancels subscription by id and stops charges
     */
    async cancelSubscriptionRaw(requestParameters: CancelSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSummaryAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cancelSubscription().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscriptions/{id}/cancel`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Cancels subscription by id and stops charges
     */
    async cancelSubscription(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSummaryAPIModel> {
        const response = await this.cancelSubscriptionRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscription by id (contract address)
     */
    async getSubscriptionRaw(requestParameters: GetSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSummaryAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSubscription().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscriptions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscription by id (contract address)
     */
    async getSubscription(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSummaryAPIModel> {
        const response = await this.getSubscriptionRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Returns charges for the specific subscription (deprecated - use subscriptions-charges/search
     * @deprecated
     */
    async getSubscriptionChargesRaw(requestParameters: GetSubscriptionChargesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionChargesListAPIModel>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getSubscriptionCharges().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscriptions/{id}/charges`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionChargesListAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns charges for the specific subscription (deprecated - use subscriptions-charges/search
     * @deprecated
     */
    async getSubscriptionCharges(id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionChargesListAPIModel> {
        const response = await this.getSubscriptionChargesRaw({ id: id }, initOverrides);
        return await response.value();
    }

    /**
     * Tries to update state by a hint
     */
    async hintUpdateSubscriptionStateRaw(requestParameters: HintUpdateSubscriptionStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SubscriptionSummaryAPIModel>> {
        if (requestParameters['hintUpdateStateAPIModel'] == null) {
            throw new runtime.RequiredError(
                'hintUpdateStateAPIModel',
                'Required parameter "hintUpdateStateAPIModel" was null or undefined when calling hintUpdateSubscriptionState().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscriptions/hint-update-state`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HintUpdateStateAPIModelToJSON(requestParameters['hintUpdateStateAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SubscriptionSummaryAPIModelFromJSON(jsonValue));
    }

    /**
     * Tries to update state by a hint
     */
    async hintUpdateSubscriptionState(hintUpdateStateAPIModel: HintUpdateStateAPIModel, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SubscriptionSummaryAPIModel> {
        const response = await this.hintUpdateSubscriptionStateRaw({ hintUpdateStateAPIModel: hintUpdateStateAPIModel }, initOverrides);
        return await response.value();
    }

    /**
     * Returns subscriptions by a filter
     */
    async searchSubscriptionsRaw(requestParameters: SearchSubscriptionsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SliceSubscriptionSummarySubscriptionSortByAPIModel>> {
        if (requestParameters['subscriptionFilterAPIModel'] == null) {
            throw new runtime.RequiredError(
                'subscriptionFilterAPIModel',
                'Required parameter "subscriptionFilterAPIModel" was null or undefined when calling searchSubscriptions().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['withTotal'] != null) {
            queryParameters['withTotal'] = requestParameters['withTotal'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("httpAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/subscriptions/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscriptionFilterAPIModelToJSON(requestParameters['subscriptionFilterAPIModel']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SliceSubscriptionSummarySubscriptionSortByAPIModelFromJSON(jsonValue));
    }

    /**
     * Returns subscriptions by a filter
     */
    async searchSubscriptions(subscriptionFilterAPIModel: SubscriptionFilterAPIModel, withTotal?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SliceSubscriptionSummarySubscriptionSortByAPIModel> {
        const response = await this.searchSubscriptionsRaw({ subscriptionFilterAPIModel: subscriptionFilterAPIModel, withTotal: withTotal }, initOverrides);
        return await response.value();
    }

}
