import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import SubscriptionPlanLink from '@/pages/subscriptions/plan-view/components/SubscriptionPlanLink';
import SubscriptionPlansBreadcrumb from '@/pages/subscriptions/plans/components/SubscriptionPlansBreadcrumb';

import type { SubscriptionPlanBreadcrumbProps } from './types';

const SubscriptionPlanBreadcrumb: React.FC<SubscriptionPlanBreadcrumbProps> = ({
  items = [],
  planId,
  disabled,
  'data-test': dataTest,
}) => (
  <SubscriptionPlansBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSubscriptions.PLAN_VIEW_COMPONENTS_BREADCRUMB} values={{ id: planId }} />
        ) : (
          <SubscriptionPlanLink
            mode="text"
            value={planId}
            title={
              <FormattedMessage id={I18nPageSubscriptions.PLAN_VIEW_COMPONENTS_BREADCRUMB} values={{ id: planId }} />
            }
          />
        ),
      },
      ...items,
    ]}
  />
);

const SubscriptionPlanBreadcrumbMemo = React.memo(SubscriptionPlanBreadcrumb);

export default SubscriptionPlanBreadcrumbMemo;
