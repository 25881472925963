import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import GasHistoryIcon from '@/features/gas-history/components/GasHistoryIcon';
import { I18nMenu } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';
import type { CustomizableRoute } from '@/layouts/types';
import { gasHistoryLink, GasHistoryPageRoutes, gasHistoryRoute } from '@/pages/gas-history/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (
  formatMessage: IntlFormattersArrow['formatMessage'],
  featureFlags: FeatureFlags | undefined,
): CustomizableRoute[] =>
  featureFlags?.enableGasHistory
    ? [
        {
          name: formatMessage({ id: I18nMenu.GAS_HISTORY }),
          icon: <GasHistoryIcon />,
          path: gasHistoryLink(),
          hideChildrenInMenu: true,

          children: enumValues(GasHistoryPageRoutes)
            .filter((route) => route !== GasHistoryPageRoutes.ALL)
            .map((route) => gasHistoryRoute(route))
            .map((path) => ({ path })),
        },
      ]
    : [];

export default function useMenuData(): CustomizableRoute[] {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const { formatMessage } = useIntl();
  return useMemo(() => menuCreator(formatMessage, featureFlags), [featureFlags, formatMessage]);
}
