import { BlockOutlined, BorderOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { PayoutTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutTypeViewProps } from './types';

const i18n: Record<PayoutTypeAPIModel, I18nMessages> = {
  [PayoutTypeAPIModel.Simple]: I18nFeaturePayouts.COMPONENTS_PAYOUT_TYPE_LABEL_SIMPLE,
  [PayoutTypeAPIModel.MerkleTree]: I18nFeaturePayouts.COMPONENTS_PAYOUT_TYPE_LABEL_MERKLE,
};

const icons: Record<PayoutTypeAPIModel, React.ReactNode> = {
  [PayoutTypeAPIModel.Simple]: <BorderOutlined />,
  [PayoutTypeAPIModel.MerkleTree]: <BlockOutlined />,
};

const PayoutTypeViewRaw: React.FC<PayoutTypeViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const PayoutTypeView = React.memo(PayoutTypeViewRaw);

export default PayoutTypeView;
