import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchSignupStatus } from '@/features/auth/actions';
import { makeSelectAddressSignupStatus } from '@/features/auth/selectors';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseAddressSignedUpType = UseAppSingleData<boolean>;

const noData = storedDataError<boolean>('no-data');

const fetchFactory = (
  address: string,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<boolean>, Parameters<typeof fetchSignupStatus>[0]> =>
  fetchSignupStatus({ address, force });

const dataSelector = (address: string) => makeSelectAddressSignupStatus(address);

export default function useAddressSignedUp(address: string | undefined): UseAddressSignedUpType {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchSignupStatus, address), [address]);
  return useAppSingleData(fetchFactory, dataSelector, noData, address, dataFetchingSelector);
}
