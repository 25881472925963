import { CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Space, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation, SupportEmail } from '@/components';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification, useStateMountSafe } from '@/hooks';

import type { Web3AuthAccountResetProps } from './types';

const { Text } = Typography;

const Web3AuthAccountReset: React.FC<Web3AuthAccountResetProps> = ({
  'data-test': dataTest,
  email,
  onCancel,
  onReset,
  onCheck,
}) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const [checked, setChecked] = useStateMountSafe(false);
  const doReset = useMemo(
    () =>
      onReset
        ? withSuccess(
            withDefaultError(onReset, {
              message: () => <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_ERROR_MESSAGE} />,
            }),
            () => ({
              message: <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_SUCCESS_MESSAGE} />,
              description: (
                <FormattedMessage id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_SUCCESS_DESCRIPTION} />
              ),
            }),
          )
        : undefined,
    [onReset, withDefaultError, withSuccess],
  );
  return (
    <Space direction="vertical">
      <Alert
        message={<FormattedMessageWithMarkup id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_BANNER_TITLE} />}
        description={
          <FormattedMessageWithMarkup
            id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_BANNER_DESCRIPTION}
            values={{ email }}
          />
        }
        data-test={dataTest && `${dataTest}-banner`}
        type="error"
        showIcon
      />
      {!doReset ? (
        <Text data-test={dataTest && `${dataTest}-prepare`}>
          <FormattedMessageWithMarkup
            id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_PREPARE_MESSAGE}
            values={{
              email,
              ln: (text: React.ReactNode) => (
                <SupportEmail
                  data-test={dataTest && `${dataTest}-support`}
                  title={text}
                  subject={`Account Reset for ${email}`}
                />
              ),
              check: (text: React.ReactNode) => (
                <Operation
                  data-test={dataTest && `${dataTest}-recheck`}
                  icon={null}
                  mode="link"
                  onClick={onCheck}
                  title={text}
                />
              ),
            }}
          />
        </Text>
      ) : (
        <>
          <Text data-test={dataTest && `${dataTest}-prepare`}>
            <FormattedMessageWithMarkup id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_RESET_MESSAGE} />
          </Text>
          <Checkbox style={{ lineHeight: '32px' }} checked={checked} onChange={(e) => setChecked(e.target.checked)}>
            <FormattedMessageWithMarkup id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_CONFIRMATION} />
          </Checkbox>
          <Space style={{ display: 'flex', justifyContent: 'end', flex: 1 }}>
            <Operation
              data-test={dataTest && `${dataTest}-resetOp`}
              icon={<CloseCircleOutlined />}
              primary
              ButtonProps={{ danger: true }}
              mode="button"
              disabled={!checked}
              onClick={doReset}
              title={<FormattedMessageWithMarkup id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_SUBMIT_TITLE} />}
            />
            <Operation
              data-test={dataTest && `${dataTest}-cancelOp`}
              icon={null}
              mode="button"
              onClick={onCancel}
              title={<FormattedMessageWithMarkup id={I18nFeatureAuth.COMPONENTS_WEB3AUTH_ACCOUNT_RESET_CANCEL_TITLE} />}
            />
          </Space>
        </>
      )}
    </Space>
  );
};

const Web3AuthAccountResetMemo = React.memo(Web3AuthAccountReset);

export default Web3AuthAccountResetMemo;
