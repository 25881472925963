import React from 'react';

import SettlementViewLink from '@/pages/settlements/settlement-view/components/SettlementViewLink';

import type { PayoutBatchesListOperationsProps } from './types';

const PayoutBatchesListOperations: React.FC<PayoutBatchesListOperationsProps> = ({ 'data-test': dataTest, value }) =>
  value.settlementId ? (
    <SettlementViewLink
      data-test={dataTest && `${dataTest}-${value.num}-settlementView`}
      value={value.settlementId}
      mode="inline"
    />
  ) : null;

const PayoutBatchesListOperationsMemo = React.memo(PayoutBatchesListOperations);

export default PayoutBatchesListOperationsMemo;
