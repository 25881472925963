import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useSubscriptionPlanActions } from '@/features/subscription-plans/hooks';
import { I18nFeatureSubscriptionPlans } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationRemoveSubscriptionPlanProps } from './types';

const options = {
  error: () => <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_DELETE_PLAN_ERROR_MESSAGE} />,
  success: () => (
    <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_DELETE_PLAN_SUCCESS_MESSAGE} />
  ),
};

const OperationRemoveSubscriptionPlan: React.FC<OperationRemoveSubscriptionPlanProps> = ({
  'data-test': dataTest,
  planId,
  confirmation = true,
  mode = 'inline',
  onSuccess = noop,
}) => {
  const { withNotification } = useNotification();
  const { remove: removeAction } = useSubscriptionPlanActions(planId);

  const remove = useMemo(
    () => withSuppressError(withOnSuccess(withNotification(removeAction.act, options), onSuccess)),
    [removeAction.act, onSuccess, withNotification],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? {
            title: (
              <FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_DELETE_PLAN_CONFIRMATION_TITLE} />
            ),
          }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureSubscriptionPlans.COMPONENTS_OPERATION_DELETE_PLAN_TITLE} />}
      icon={<DeleteOutlined />}
      onClick={remove}
      disabled={!removeAction.available}
      inProgress={removeAction.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationRemoveSubscriptionPlanMemo = React.memo(OperationRemoveSubscriptionPlan);

export default OperationRemoveSubscriptionPlanMemo;
