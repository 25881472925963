import { createReducer } from '@reduxjs/toolkit';

import listenerMiddleware from '@/app/listenerMiddleware';
import { notifyAuthTokenUpdated } from '@/features/auth/actions';
import { storeCompanySettings } from '@/features/company-settings/actions';
import { makeSelectStatisticsAsset } from '@/features/company-settings/selectors';
import { notifyNetworkUpdated } from '@/features/dictionary/blockchain/actions';
import {
  markBalancesDirty,
  markPaymentsSummaryStatsDirty,
  markSubscriptionsSummaryStatsDirty,
  storeBalances,
  storePaymentsSummaryStats,
  storeSubscriptionsSummaryStats,
} from '@/features/statistics/actions';
import { storedDirtyData } from '@/infrastructure/model';
import { createLoadingDataReducers } from '@/infrastructure/model/common/reducers';

import { type StatisticsState } from './types';

import type { Draft } from 'immer';

const initialState: StatisticsState = {
  balances: storedDirtyData,
  payments: storedDirtyData,
  subscriptions: storedDirtyData,
};

const { storeBalancesReducer, markBalancesDirtyReducer } = createLoadingDataReducers(
  'Balances',
  (state: Draft<StatisticsState>) => state.balances,
  (state, balances) => ({ ...state, balances }),
);

const { storePaymentsSummaryStatsReducer, markPaymentsSummaryStatsDirtyReducer } = createLoadingDataReducers(
  'PaymentsSummaryStats',
  (state: Draft<StatisticsState>) => state.payments,
  (state, payments) => ({ ...state, payments }),
);

const { storeSubscriptionsSummaryStatsReducer, markSubscriptionsSummaryStatsDirtyReducer } = createLoadingDataReducers(
  'SubscriptionsSummaryStats',
  (state: Draft<StatisticsState>) => state.subscriptions,
  (state, subscriptions) => ({ ...state, subscriptions }),
);

export const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(markBalancesDirty, markBalancesDirtyReducer)
    .addCase(storeBalances, storeBalancesReducer)

    .addCase(markPaymentsSummaryStatsDirty, markPaymentsSummaryStatsDirtyReducer)
    .addCase(storePaymentsSummaryStats, storePaymentsSummaryStatsReducer)

    .addCase(markSubscriptionsSummaryStatsDirty, markSubscriptionsSummaryStatsDirtyReducer)
    .addCase(storeSubscriptionsSummaryStats, storeSubscriptionsSummaryStatsReducer)

    .addCase(notifyNetworkUpdated, () => initialState)
    .addCase(notifyAuthTokenUpdated, (state, { payload: { previous, current } }) =>
      previous?.address !== current?.address ? initialState : state,
    );

  listenerMiddleware.startListening({
    actionCreator: storeCompanySettings,
    effect: (_, listenerApi) => {
      const previous = makeSelectStatisticsAsset()(listenerApi.getOriginalState());
      const current = makeSelectStatisticsAsset()(listenerApi.getState());
      if (current.data !== previous.data && current.data) {
        listenerApi.dispatch(markBalancesDirty());
        listenerApi.dispatch(markSubscriptionsSummaryStatsDirty());
        listenerApi.dispatch(markPaymentsSummaryStatsDirty());
      }
    },
  });
});

export default reducer;
