import { Space } from 'antd';
import React from 'react';

import OperationSelectNetwork from '@/features/dictionary/blockchain/components/OperationSelectNetwork';

import { NetworkIcon, NetworkTag, NetworkTooltip as BaseNetworkTooltip } from './components';

import type { NetworkLabelProps } from './types';

const NetworkLabel: React.FC<NetworkLabelProps> = ({
  'data-test': dataTest,
  suffix,
  mode = 'medium',
  tooltipMode = 'full',
  noSwitcher,
  value,
  NetworkTooltip = BaseNetworkTooltip,
}) =>
  // eslint-disable-next-line no-nested-ternary
  mode === 'medium' ? (
    noSwitcher ? (
      <NetworkTag value={value} data-test={dataTest}>
        <Space>
          <NetworkTooltip value={value} containerMode={mode} mode={tooltipMode} noSwitcher={noSwitcher}>
            <Space>
              <NetworkIcon value={value} />
              {value}
            </Space>
          </NetworkTooltip>
          {suffix}
        </Space>
      </NetworkTag>
    ) : (
      <NetworkTooltip value={value} containerMode={mode} mode={tooltipMode} noSwitcher={noSwitcher}>
        <OperationSelectNetwork data-test={dataTest} mode="switch" />
      </NetworkTooltip>
    )
  ) : (
    <NetworkTag value={value} data-test={dataTest}>
      <NetworkTooltip value={value} containerMode={mode} mode={tooltipMode} noSwitcher={noSwitcher}>
        <span>
          <NetworkIcon value={value} />
        </span>
      </NetworkTooltip>
    </NetworkTag>
  );

const NetworkLabelMemo = React.memo(NetworkLabel);

export default NetworkLabelMemo;
