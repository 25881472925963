import { CreditCardOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { usePaymentActions } from '@/features/payments/hooks';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationGeneratePaymentURLProps } from './types';

const { Link } = Typography;

const OperationGeneratePaymentURLRaw: React.FC<OperationGeneratePaymentURLProps> = ({
  'data-test': dataTest,
  paymentId,
  disabled,
  mode = 'inline',
}) => {
  const { withError } = useNotification();
  const { generatePaymentURL } = usePaymentActions(paymentId);

  const [modal, contextHolder] = Modal.useModal();
  const generateToken = useMemo(
    () =>
      withOnSuccess(
        withError(generatePaymentURL.act, () => (
          <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_ERROR_MESSAGE} />
        )),
        (data) => {
          modal.info({
            title: (
              <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_SUCCESS_FORM_TITLE} />
            ),
            content: (
              <Link
                href={data}
                copyable={{ text: data }}
                target="_blank"
                data-test={dataTest && `${dataTest}-link`}
                data-ym-target="payment-widget-link"
              >
                <FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_LINK_TITLE} />
              </Link>
            ),
            maskClosable: true,
            width: 500,
          });
        },
      ),
    [withError, generatePaymentURL.act, modal, dataTest],
  );

  return (
    <>
      {contextHolder}
      <Operation
        title={<FormattedMessage id={I18nFeaturePayments.COMPONENTS_OPERATION_GENERATE_PAYMENT_URL_TITLE} />}
        icon={<CreditCardOutlined />}
        onClick={generateToken}
        disabled={disabled || !generatePaymentURL.available}
        inProgress={generatePaymentURL.inAction}
        data-test={dataTest && `${dataTest}-generate`}
        mode={mode}
      />
    </>
  );
};

const OperationGeneratePaymentURL = React.memo(OperationGeneratePaymentURLRaw);

export default OperationGeneratePaymentURL;
