import { useMemo } from 'react';

import type { AppAsyncThunkAction } from '@/app/store';
import type { ListParametersUpdateAction, NestedListParametersUpdateAction } from '@/infrastructure/model/list/actions';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';
import { noop, withVoidOrThrow } from '@/infrastructure/utils/functions';
import { isThenable } from '@/infrastructure/utils/ts';

import useAppDispatch from './useAppDispatch';

import type { UseAppListData } from './useAppListData';
import type { PayloadAction } from '@reduxjs/toolkit';

export default function useAppNestedListDataView<
  Value,
  Filter,
  SortBy extends string,
  FetchResult = LoadingListDataState<Value>,
  ParentId = string,
>(
  state: UseAppListData<Value, Filter, SortBy, FetchResult>,
  updateParametersFactory:
    | ((
        parameters: NestedListParametersUpdateAction<ParentId, Filter, SortBy>['payload'],
      ) => AppAsyncThunkAction<unknown, unknown>)
    | ((
        payload: NestedListParametersUpdateAction<ParentId, Filter, SortBy>['payload'],
      ) => PayloadAction<NestedListParametersUpdateAction<ParentId, Filter, SortBy>['payload']>)
    | ((parameters: NestedListParametersUpdateAction<ParentId, Filter, SortBy>['payload']) => Promise<unknown>),
  parentId: ParentId | undefined,
) {
  const { dispatch } = useAppDispatch();
  const updateParameters = useMemo(
    () =>
      parentId
        ? withVoidOrThrow(async (parameters: ListParametersUpdateAction<Filter, SortBy>['payload']) => {
            const call = updateParametersFactory({ parentId, parameters });
            if (isThenable(call)) {
              return call;
            }
            const dispatched = dispatch(call);
            return isThenable(dispatched) ? dispatched.unwrap() : dispatched;
          })
        : noop,
    [dispatch, parentId, updateParametersFactory],
  );

  return { ...state, updateParameters };
}
