import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageAudit } from '@/generated/i18n/i18n';
import { auditWalletRunningBalanceViewLink } from '@/pages/audit/routes';

import type { MerchantWalletRunningBalanceLinkProps } from './types';

const MerchantWalletRunningBalanceLinkRaw: React.FC<MerchantWalletRunningBalanceLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  value,
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    to={auditWalletRunningBalanceViewLink(value)}
    mode={mode}
    title={title ?? <FormattedMessage id={I18nPageAudit.BALANCE_VIEW_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const MerchantWalletRunningBalanceLink = React.memo(MerchantWalletRunningBalanceLinkRaw);

export default MerchantWalletRunningBalanceLink;
