import { parseCollectableTransaction, parseProcessTransaction } from '@/features/collectable/api';
import type { RechargeAddress, RechargeTransactionFilterPredicate } from '@/features/recharges/types';
import type {
  NewRechargeAddressAPIModel,
  PushAddressSortByAPIModel,
  PushTransactionSortByAPIModel,
  RechargeAddressAPIModel,
  RechargeAddressPredicatesAPIModel,
} from '@/generated/api/ncps-core/merchant-bo';
import { RechargeApi, RechargeTransactionApi } from '@/generated/api/ncps-core/merchant-bo';
import { type SliceDataRequestWithTotal, pageToAPI, sliceFromAPI, amountFromAPI } from '@/infrastructure/api';
import { coreConfigurationFactory } from '@/infrastructure/request';

const rechargeApi = new RechargeApi(coreConfigurationFactory('auth'));
const rechargeTransactionApi = new RechargeTransactionApi(coreConfigurationFactory('auth'));

export const parseRechargeAddress = ({ balance, ...recharge }: RechargeAddressAPIModel): RechargeAddress => ({
  ...recharge,
  balance: amountFromAPI(balance),
});

export const queryRechargeAddress = async (addressId: string, initOverrides?: RequestInit) =>
  parseRechargeAddress(await rechargeApi.getRechargeAddress(addressId, initOverrides));

export const queryRechargeAddresses = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<RechargeAddressPredicatesAPIModel, PushAddressSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await rechargeApi.searchRechargeAddresses(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseRechargeAddress,
  );

export const queryRechargeAddressDeployTransaction = async (addressId: string, initOverrides?: RequestInit) =>
  parseProcessTransaction(await rechargeApi.getRechargeAddressDeployTransaction(addressId, initOverrides));

export const queryRechargeTransaction = async (txId: string, initOverrides?: RequestInit) =>
  parseCollectableTransaction(await rechargeTransactionApi.getRechargeTransaction(txId, initOverrides));

export const queryRechargeTransactions = async (
  {
    page,
    filter,
    orderBy,
    withTotal,
  }: SliceDataRequestWithTotal<RechargeTransactionFilterPredicate, PushTransactionSortByAPIModel>,
  initOverrides?: RequestInit,
) =>
  sliceFromAPI(
    await rechargeTransactionApi.searchRechargeTransactions(
      { page: pageToAPI(page, orderBy), predicates: filter ?? {} },
      withTotal,
      initOverrides,
    ),
    parseCollectableTransaction,
  );

export const requestCreateRechargeAddress = async (address: NewRechargeAddressAPIModel, initOverrides?: RequestInit) =>
  parseRechargeAddress(await rechargeApi.createRechargeAddress(address, initOverrides));
