import { Card, Typography } from 'antd';
import React from 'react';

import { FormattedMessage, FormattedMessageWithMarkup, Operation } from '@/components';
import { I18nFeatureOnboard } from '@/generated/i18n/i18n';

import { OnboardingSteps } from './components';
import { useStyles } from './hooks';

import type { OnboardingContainerProps } from './types';

const { Title, Paragraph } = Typography;

const OnboardingContainerRaw: React.FC<OnboardingContainerProps> = ({
  'data-test': dataTest,
  loading,
  submit,
  selected,
  children,
}) => {
  const styles = useStyles();
  return (
    <Card className={styles.card} loading={loading}>
      <Title data-test={dataTest && `${dataTest}-title`} level={2}>
        <FormattedMessage id={I18nFeatureOnboard.COMPONENTS_CONTAINER_TITLE} />
      </Title>
      <Paragraph className={styles.description}>
        <FormattedMessageWithMarkup id={I18nFeatureOnboard.COMPONENTS_CONTAINER_DESCRIPTION} />
      </Paragraph>
      <OnboardingSteps data-test={dataTest && `${dataTest}-steps`} selected={selected} className={styles.steps} />
      <div className={styles.container}>{children}</div>
      <Operation
        data-test={dataTest && `${dataTest}-submit`}
        ButtonProps={{ size: 'large' }}
        mode="button"
        primary
        className={styles.submit}
        disabled={submit.disabled}
        icon={null}
        onClick={submit.onAct}
        title={submit.title}
      />
    </Card>
  );
};

const OnboardingContainer = React.memo(OnboardingContainerRaw) as typeof OnboardingContainerRaw;

export default OnboardingContainer;
