import isEmpty from 'lodash/isEmpty';
import { useMemo } from 'react';

import type { ListColumnState } from '@/infrastructure/model/list/types';
import { asType } from '@/infrastructure/utils/ts';

import type { ProColumns } from '@ant-design/pro-table';

type UseColumnStateNormalizationType = ListColumnState;

export default function useColumnStateNormalization<T>(
  columns: ProColumns<T>[],
  columnsState?: ListColumnState,
): UseColumnStateNormalizationType {
  return useMemo(
    () =>
      !columnsState || isEmpty(columnsState)
        ? columns.reduce(
            (res, { key, fixed, hideInTable }, order) =>
              key
                ? {
                    ...res,
                    [`${key}`]: {
                      fixed: fixed === 'left' || fixed === 'right' ? fixed : undefined,
                      order,
                      show: !hideInTable,
                    },
                  }
                : res,
            asType<ListColumnState>({}),
          )
        : columnsState,
    [columns, columnsState],
  );
}
