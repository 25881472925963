/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { GasHistorySortByAPIModel } from './GasHistorySortByAPIModel';
import {
    GasHistorySortByAPIModelFromJSON,
    GasHistorySortByAPIModelFromJSONTyped,
    GasHistorySortByAPIModelToJSON,
} from './GasHistorySortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributeGasHistorySortByAPIModel
 */
export interface SortAttributeGasHistorySortByAPIModel {
    /**
     * 
     * @type {GasHistorySortByAPIModel}
     * @memberof SortAttributeGasHistorySortByAPIModel
     */
    attr: GasHistorySortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributeGasHistorySortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributeGasHistorySortByAPIModel interface.
 */
export function instanceOfSortAttributeGasHistorySortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributeGasHistorySortByAPIModelFromJSON(json: any): SortAttributeGasHistorySortByAPIModel {
    return SortAttributeGasHistorySortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributeGasHistorySortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributeGasHistorySortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': GasHistorySortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributeGasHistorySortByAPIModelToJSON(value?: SortAttributeGasHistorySortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': GasHistorySortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

