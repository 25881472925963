import { useMemo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useAppSelector } from '@/app/hooks';
import { ErrorPage, FormattedMessage } from '@/components';
import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import { ReCaptchaContextInitializer } from '@/features/recaptcha/components';
import useReCaptchaLocale from '@/features/recaptcha/hooks/useReCaptchaLocale';
import { makeSelectReCaptcha } from '@/features/recaptcha/selectors';
import { I18nFeatureRecaptcha } from '@/generated/i18n/i18n';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import RecaptchaActionContext from './context';

import type React from 'react';

const selectRecaptcha = makeSelectReCaptcha();

const defaultContext: Parameters<typeof RecaptchaActionContext.Provider>[0]['value'] = { initialized: true };

const withReCaptchaInit = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithReCaptchaInit',
  )((props) => {
    const { publicV3Key, publicV2Key } = useAppSelector(selectRecaptcha);
    const { locale } = useReCaptchaLocale();
    if (!publicV3Key || !publicV2Key) {
      return (
        <ErrorPage type="500" message={<FormattedMessage id={I18nFeatureRecaptcha.ERRORS_PUBLICKEY_UNAVAILABLE} />} />
      );
    }
    return (
      <GoogleReCaptchaProvider reCaptchaKey={publicV3Key} useRecaptchaNet language={locale}>
        <ReCaptchaContextInitializer>
          <Component {...props} />
        </ReCaptchaContextInitializer>
      </GoogleReCaptchaProvider>
    );
  });

const withReCaptchaSupport = <P extends EmptyObject>(Component: React.ComponentType<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithReCaptchaSupport',
  )((props) => {
    const {
      data: { data: featureFlags },
    } = useFeatureToggle();
    const Comp = useMemo(
      () => (featureFlags?.disabledRecaptcha ? Component : withReCaptchaInit(Component)),
      [featureFlags?.disabledRecaptcha],
    );
    return (
      <RecaptchaActionContext.Provider value={defaultContext}>
        <Comp {...props} />
      </RecaptchaActionContext.Provider>
    );
  });

export default withReCaptchaSupport;
