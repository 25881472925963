import { Card } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useHandlers } from '@/features/global/hooks';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';

import type { ReportScheduleParametersViewProps } from './types';

const ReportScheduleParametersViewRaw: React.FC<ReportScheduleParametersViewProps> = ({
  value,
  template,
  'data-test': dataTest,
}) => {
  const handlers = useHandlers('reportSchedules');
  const handler = useMemo(() => handlers.find((h) => h.template === template), [handlers, template]);

  return handler?.ParametersView ? (
    <Card title={<FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_PARAMETERS_VIEW_TITLE} />}>
      <handler.ParametersView value={value} data-test={dataTest} />
    </Card>
  ) : null;
};

const ReportScheduleParametersView = React.memo(ReportScheduleParametersViewRaw);

export default ReportScheduleParametersView;
