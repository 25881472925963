import { Space } from 'antd';
import React from 'react';

import { FormattedMessage, PageCard } from '@/components';
import { AssetLabel, SelectedNetworkLabel } from '@/features/dictionary/blockchain/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import SettlementViewLink from '@/pages/settlements/settlement-view/components/SettlementViewLink';

import { SettlementsAssetTable, SettlementsFullTable } from './components';

import type { SettlementsCardProps } from './types';

const SettlementCard: React.FC<SettlementsCardProps> = ({ 'data-test': dataTest, assetId }) => (
  <PageCard
    title={
      <Space>
        {assetId ? (
          <FormattedMessage
            id={I18nPageSettlements.HISTORY_COMPONENTS_CARD_ASSET_TITLE}
            values={{ asset: <AssetLabel value={assetId} mode="medium" /> }}
          />
        ) : (
          <FormattedMessage id={I18nPageSettlements.HISTORY_COMPONENTS_CARD_FULL_TITLE} />
        )}
        <SelectedNetworkLabel mode="short" data-test={dataTest && `${dataTest}-network`} />
      </Space>
    }
    cardSize="big"
  >
    {assetId ? (
      <SettlementsAssetTable data-test={dataTest} assetId={assetId} SettlementLink={SettlementViewLink} />
    ) : (
      <SettlementsFullTable data-test={dataTest} SettlementLink={SettlementViewLink} />
    )}
  </PageCard>
);

const SettlementCardMemo = React.memo(SettlementCard);

export default SettlementCardMemo;
