import type { PageContainerProps } from '@/components';

export enum SettingsTabType {
  company = 'company',
  branding = 'branding',
  permissions = 'permissions',
  integration = 'integration',
  profile = 'profile',
}

export interface SettingsPageLayoutProps extends PageContainerProps {
  selected: SettingsTabType;
}
