import { useEffect } from 'react';

import type { BrandingProfileFormValues } from '@/features/branding/components/BrandingProfileForm/types';
import { useBrandingProfile, useBrandingProfilesActions } from '@/features/branding/hooks';
import { useCompanySettings } from '@/features/company-settings/hooks';
import { useStateMountSafe } from '@/hooks';

interface UsePrepareInitialData {
  data?: Omit<BrandingProfileFormValues, 'id'>;
}

export default function usePrepareInitialData(copyOf?: string): UsePrepareInitialData {
  const { data: source, loading } = useBrandingProfile(copyOf);
  const { prepareDefault } = useBrandingProfilesActions();
  const settingsState = useCompanySettings();
  const [data, setData] = useStateMountSafe<UsePrepareInitialData['data']>();
  useEffect(() => {
    if (data) {
      return;
    }
    if (!settingsState.data.data && (settingsState.loading || settingsState.data.isDirty)) {
      return;
    }
    const defaultQR =
      settingsState.data.data?.qrSupport.filter(({ isEnabled }) => isEnabled).map(({ blockchain }) => blockchain) ?? [];
    if (!copyOf) {
      const { name, data: defaultData } = prepareDefault();
      setData({ name, ...defaultData, qr: defaultQR });
    } else if (source.data) {
      const { name, data: defaultData, qr } = source.data;
      setData({ name: `${name} (copy)`, ...defaultData, qr });
    } else if (!source.isDirty && !loading) {
      const { name, data: defaultData } = prepareDefault();
      setData({ name, ...defaultData, qr: defaultQR });
    }
  }, [
    copyOf,
    data,
    loading,
    prepareDefault,
    setData,
    settingsState.data.data,
    settingsState.data.isDirty,
    settingsState.loading,
    source.data,
    source.isDirty,
  ]);

  return { data };
}
