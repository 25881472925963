import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import PaymentBreadcrumb from '@/pages/payments/payment-view/components/PaymentBreadcrumb';

import type { PaymentTransactionsBreadcrumbProps } from './types';

const PaymentTransactionsBreadcrumb: React.FC<PaymentTransactionsBreadcrumbProps> = ({
  'data-test': dataTest,
  paymentId,
  items = [],
}) => (
  <PaymentBreadcrumb
    data-test={dataTest}
    paymentId={paymentId}
    items={[
      {
        title: <FormattedMessage id={I18nPagePayments.TRANSACTIONS_COMPONENTS_BREADCRUMB} />,
      },
      ...items,
    ]}
  />
);
const PaymentTransactionsBreadcrumbMemo = React.memo(PaymentTransactionsBreadcrumb);

export default PaymentTransactionsBreadcrumbMemo;
