import { List, Space, Switch, Tag, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { AddressLink, AssetAmount, AssetIcon, AssetLabel } from '@/features/dictionary/blockchain/components';
import { donationAssetStatusIcons } from '@/features/donations/components/DonationAssetStatusView';
import { useDonationActions } from '@/features/donations/hooks';
import type { DonationAddress } from '@/features/donations/types';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';
import { stringComparator } from '@/infrastructure/utils/comparators';

import type { DonationAssetsListProps } from './types';

const addressComparator = stringComparator(({ asset }: DonationAddress) => asset);

const DonationAssetsList: React.FC<DonationAssetsListProps> = ({
  'data-test': dataTest,
  data: baseData,
  donationId,
  DonationAssetLink,
}) => {
  const { updateAssetStatus } = useDonationActions(donationId);
  const data = useMemo(() => [...baseData].sort(addressComparator('ASC')), [baseData]);

  return (
    <List
      data-test={dataTest}
      itemLayout="horizontal"
      loadMore={false}
      style={{ width: '100%' }}
      dataSource={data}
      renderItem={useCallback(
        ({ address, asset, balance, isActive, defaultAmounts }: DonationAddress) => (
          <List.Item
            key={address}
            actions={[
              <Space key="actions">
                {DonationAssetLink && (
                  <DonationAssetLink
                    data-test={dataTest && `${dataTest}-${asset}-link`}
                    value={{ donationId, asset }}
                  />
                )}
                <Tooltip
                  title={
                    <FormattedMessage
                      id={
                        isActive
                          ? I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_DEACTIVATE_TITLE
                          : I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_ASSET_STATUS_ACTIVATE_TITLE
                      }
                    />
                  }
                >
                  <Switch
                    data-test={dataTest && `${dataTest}-${asset}-updateOp`}
                    key="updateStatusOp"
                    value={isActive}
                    onChange={(checked) => updateAssetStatus.act(asset, checked)}
                    checkedChildren={donationAssetStatusIcons.true}
                    unCheckedChildren={donationAssetStatusIcons.false}
                    disabled={!updateAssetStatus.available || updateAssetStatus.inAction}
                  />
                </Tooltip>
              </Space>,
            ]}
          >
            <List.Item.Meta
              key={`${address}-meta`}
              style={{ alignItems: 'center' }}
              avatar={
                <AssetIcon style={{ fontSize: 32 }} type={asset} data-test={dataTest && `${dataTest}-${asset}-icon`} />
              }
              title={
                <Space>
                  <AssetLabel
                    mode="short"
                    value={asset}
                    data-test={dataTest && `${dataTest}-${asset}-asset`}
                    withBlockchainMark
                  />
                  {defaultAmounts.map((amount, index) => (
                    <Tag key={index}>
                      <AssetAmount
                        data-test={dataTest && `${dataTest}-amount-${amount.asset}`}
                        value={amount.value}
                        assetId={amount.asset}
                        withCurrency
                      />
                    </Tag>
                  ))}
                </Space>
              }
              description={
                <Space>
                  <AddressLink
                    assetId={asset}
                    address={address}
                    data-test={dataTest && `${dataTest}-${asset}-address`}
                  />
                </Space>
              }
            />
            <Tag color="default">
              <AssetAmount
                data-test={dataTest && `${dataTest}-${asset}-balance`}
                assetId={balance.asset}
                value={balance.value}
                withCurrency
                withBlockchainMark
              />
            </Tag>
          </List.Item>
        ),
        [DonationAssetLink, dataTest, donationId, updateAssetStatus],
      )}
    />
  );
};

const DonationAssetsListMemo = React.memo(DonationAssetsList);

export default DonationAssetsListMemo;
