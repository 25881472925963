import React from 'react';

import { CollectTaskAddressesList } from '@/features/collectable/components';

import { withCollectTaskAddressesDataLoading } from './hocs';

const CollectTaskAddressesCard = withCollectTaskAddressesDataLoading(CollectTaskAddressesList);

const CollectTaskAddressesCardMemo = React.memo(CollectTaskAddressesCard);

export default CollectTaskAddressesCardMemo;
