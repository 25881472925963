import { Modal } from 'antd';
import React, { useEffect, useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useNativeBalance } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureGasWallets } from '@/generated/i18n/i18n';

import { GasWalletOperationConfirmationDialogContent } from './components';

import type { GasWalletOperationConfirmationDialogProps } from './types';

const GasWalletOperationConfirmationDialog: React.FC<GasWalletOperationConfirmationDialogProps> = ({
  'data-test': dataTest,
  wallet,
  onSuccess,
  onCancel,
}) => {
  const { loading, data } = useNativeBalance(wallet);
  const isBalanceFailed = !!data.error;
  const isBalanceNonZero = useMemo(
    () => !wallet.derivedBalance.isZero() || !data.data?.amount.isZero(),
    [data.data?.amount, wallet.derivedBalance],
  );

  useEffect(() => {
    if (!loading && !data.isDirty && !isBalanceFailed && !isBalanceNonZero) {
      onSuccess();
    }
  }, [data.isDirty, isBalanceFailed, loading, isBalanceNonZero, onSuccess]);

  return (
    <Modal
      open
      maskClosable={false}
      title={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CONFIRMATION_DIALOG_DIALOG_TITLE} />}
      data-test={dataTest && `${dataTest}-modal`}
      onCancel={onCancel}
      onOk={onSuccess}
      okText={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CONFIRMATION_DIALOG_DIALOG_YES} />}
      cancelText={<FormattedMessage id={I18nFeatureGasWallets.COMPONENTS_OPERATION_CONFIRMATION_DIALOG_DIALOG_NO} />}
      okButtonProps={{ disabled: data.isDirty || loading }}
    >
      <GasWalletOperationConfirmationDialogContent
        inProgress={data.isDirty || loading}
        data-test={dataTest && `${dataTest}-content`}
        isBalanceFailed={isBalanceFailed}
        isBalanceNonZero={isBalanceNonZero}
      />
    </Modal>
  );
};

const GasWalletOperationConfirmationDialogMemo = React.memo(GasWalletOperationConfirmationDialog);

export default GasWalletOperationConfirmationDialogMemo;
