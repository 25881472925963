import { Form } from 'antd';
import React, { useMemo } from 'react';

import {
  ErrorFormMessage,
  FormattedMessage,
  FormCompletenessItem,
  FormFooter,
  defaultPageFormLayout,
  defaultPageFormTailLayout,
} from '@/components';
import CompanyNameItem from '@/features/company/components/CompanyNameItem';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useErrorSafeSubmitting, useForm, useStateMountSafe } from '@/hooks';
import { noop } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import type { UpdateCompanyNameFormProps, UpdateCompanyNameFormValues } from './types';

const fields = [nameof<UpdateCompanyNameFormValues>('name')];

const UpdateCompanyNameForm: React.FC<UpdateCompanyNameFormProps> = ({
  'data-test': dataTest,
  values,
  onSubmit,
  onReset = noop,
  layout = defaultPageFormLayout,
  tailLayout = defaultPageFormTailLayout,
}) => {
  const oldValue = values?.name;
  const { form, withResetForm } = useForm<UpdateCompanyNameFormValues>();
  const [isFormComplete, setFormComplete] = useStateMountSafe(false);
  const { submitting, error, withSubmitting, withErrorHandling } = useErrorSafeSubmitting();
  const submit = useMemo(
    () => withSubmitting((val: UpdateCompanyNameFormValues): unknown => onSubmit(val.name)),
    [onSubmit, withSubmitting],
  );
  const reset = useMemo(() => withErrorHandling(withResetForm(onReset)), [onReset, withErrorHandling, withResetForm]);
  const errorMessage = useMemo(() => {
    if (!error) {
      return undefined;
    }
    return <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_UPDATE_NAME_FORM_ERROR_COMMON} />;
  }, [error]);

  return (
    <Form<UpdateCompanyNameFormValues>
      {...layout}
      autoComplete="off"
      form={form}
      initialValues={values}
      size="middle"
      onFinish={submit}
      onReset={reset}
    >
      {errorMessage && <ErrorFormMessage data-test={dataTest && `${dataTest}-error`} content={errorMessage} />}
      <CompanyNameItem<UpdateCompanyNameFormValues>
        data-test={dataTest && `${dataTest}-name`}
        name={nameof<UpdateCompanyNameFormValues>('name')}
        required
      />
      <FormCompletenessItem<UpdateCompanyNameFormValues>
        requiredFields={fields}
        onChange={setFormComplete}
        checkIsComplete={({ getFieldValue }) => getFieldValue(nameof<UpdateCompanyNameFormValues>('name')) !== oldValue}
      />
      <FormFooter
        data-test={dataTest && `${dataTest}-submit`}
        tailLayout={tailLayout}
        submitting={submitting}
        submitDisabled={!isFormComplete}
      />
    </Form>
  );
};

const UpdateCompanyNameFormMemo = React.memo(UpdateCompanyNameForm);

export default UpdateCompanyNameFormMemo;
