import dayjs from 'dayjs';

import type { AppRootState } from '@/app/store';
import type { MerchantWalletTransfer } from '@/features/merchant-wallet-transfers/types';
import type { MerchantWalletTransferSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { createNormalizedFullSelectors } from '@/infrastructure/model/full/selectors';
import type { ListSortBy } from '@/infrastructure/model/list/types';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';
import { dateComparator } from '@/infrastructure/utils/comparators';

import { defaultTransfersBySettlementState, NAMESPACE } from './types';

import type { SettlementMerchantWalletTransfersState, SettlementReconciliationTransferFilterPredicate } from './types';

export const {
  makeSelectSettlementReconciliation,
  makeSelectMultipleSettlementReconciliation,
  makeSelectDirtySettlementReconciliationIds,
} = createSingleSelectors(
  (state: AppRootState) => state[NAMESPACE].entities,
  'SettlementReconciliation' as const,
  undefined,
);

export const {
  makeSelectOutgoingForReconciliationFullData,
  makeSelectOutgoingForReconciliationFilteredData,
  makeSelectOutgoingForReconciliationFullParameters,
} = createNormalizedFullSelectors(
  (state: AppRootState, settlementId: string | undefined): SettlementMerchantWalletTransfersState =>
    state[NAMESPACE].transfers.outgoing[settlementId!] ?? defaultTransfersBySettlementState,
  (state) => state[NAMESPACE].transfers.columnState,
  (state) => state['merchant-wallet-transfers'].entities,
  'OutgoingForReconciliation' as const,
  (sortBy: ListSortBy<MerchantWalletTransferSortByAPIModel>) =>
    dateComparator(({ broadcastAt }: MerchantWalletTransfer) => broadcastAt ?? new Date())(
      sortBy.broadcastAt ?? 'DESC',
    ),
  (filter: SettlementReconciliationTransferFilterPredicate) => (tx: MerchantWalletTransfer) => {
    if (filter.kindIn?.length && !filter.kindIn.includes(tx.kind)) {
      return false;
    }
    if (filter.broadcastAtRange?.from && dayjs(tx.broadcastAt).isBefore(filter.broadcastAtRange.from)) {
      return false;
    }
    if (filter.broadcastAtRange?.to && dayjs(tx.broadcastAt).isAfter(filter.broadcastAtRange.to)) {
      return false;
    }
    return true;
  },
);

export const {
  makeSelectIncomingForReconciliationFullData,
  makeSelectIncomingForReconciliationFilteredData,
  makeSelectIncomingForReconciliationFullParameters,
} = createNormalizedFullSelectors(
  (state: AppRootState, settlementId: string | undefined): SettlementMerchantWalletTransfersState =>
    state[NAMESPACE].transfers.incoming[settlementId!] ?? defaultTransfersBySettlementState,
  (state) => state[NAMESPACE].transfers.columnState,
  (state) => state['merchant-wallet-transfers'].entities,
  'IncomingForReconciliation' as const,
  (sortBy: ListSortBy<MerchantWalletTransferSortByAPIModel>) =>
    dateComparator(({ broadcastAt }: MerchantWalletTransfer) => broadcastAt ?? new Date())(
      sortBy.broadcastAt ?? 'DESC',
    ),
  (filter: SettlementReconciliationTransferFilterPredicate) => (tx: MerchantWalletTransfer) => {
    if (filter.kindIn?.length && !filter.kindIn.includes(tx.kind)) {
      return false;
    }
    if (filter.broadcastAtRange?.from && dayjs(tx.broadcastAt).isBefore(filter.broadcastAtRange.from)) {
      return false;
    }
    if (filter.broadcastAtRange?.to && dayjs(tx.broadcastAt).isAfter(filter.broadcastAtRange.to)) {
      return false;
    }
    return true;
  },
);
