import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import SettingsBreadcrumb from '@/pages/settings/components/SettingsBreadcrumb';

import CompanyLink from '../CompanyLink';

import type { CompanyBreadcrumbProps } from './types';

const CompanyBreadcrumb: React.FC<CompanyBreadcrumbProps> = ({ items = [], 'data-test': dataTest, disabled }) => (
  <SettingsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.COMPANY_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <CompanyLink
            title={<FormattedMessage id={I18nPageSettings.COMPANY_COMPONENTS_BREADCRUMB_TITLE} />}
            mode="text"
          />
        ),
      },
      ...items,
    ]}
  />
);

const CompanyBreadcrumbMemo = React.memo(CompanyBreadcrumb);

export default CompanyBreadcrumbMemo;
