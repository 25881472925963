import { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { OperationRefreshCompanySettings } from '@/features/company-settings/components';
import { useCompanyActiveAssets } from '@/features/company-settings/hooks';
import { useListAssets } from '@/features/dictionary/blockchain/hooks';
import type { BlockchainTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import { mapStoredState } from '@/infrastructure/model';
import type { TestableProps } from '@/infrastructure/utils/react';
import { namedHOC } from '@/infrastructure/utils/react';
import { notEmpty } from '@/infrastructure/utils/ts';

import type React from 'react';

const withGasWalletAssetsDataLoading = <
  Original extends { data: string[] } & TestableProps,
  Wrapper extends Omit<Original, 'data'> & { bt: BlockchainTypeAPIModel },
>(
  Component: React.ComponentType<Original>,
) =>
  namedHOC<Original, Wrapper>(
    Component,
    'WithGasWalletAssetsDataLoading',
  )((props) => {
    const { 'data-test': dataTest, bt } = props;
    const data = useCompanyActiveAssets();
    const {
      data: { data: available },
    } = useListAssets();
    const assets = useMemo(
      () =>
        mapStoredState(data.data, (activeAssets) =>
          activeAssets
            .map(({ code: activeId }) => available?.find(({ code }) => activeId === code))
            .filter(notEmpty)
            .filter(({ blockchain }) => blockchain === bt)
            .filter(notEmpty)
            .map(({ code }) => code),
        ),
      [available, bt, data.data],
    );

    return withCardDataLoading<string[], Original>({
      ...data,
      data: assets,
      'data-test': dataTest,
      title: <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLET_COMPONENTS_ASSETS_CARD_TITLE} />,
      hideBack: true,
      CardProps: {
        extra: <OperationRefreshCompanySettings data-test={dataTest && `${dataTest}-refreshOp`} mode="inline" />,
      },
    })(Component)(props);
  });

export default withGasWalletAssetsDataLoading;
