import { Table } from 'antd';
import { useMemo } from 'react';

import { FormattedMessage, ReadonlyComponent, StopPropagationContainer } from '@/components';
import { AssetLabel } from '@/features/dictionary/blockchain/components';
import type { SettlementIntent } from '@/features/settlements/types';
import { I18nComponents, I18nFeatureSettlements } from '@/generated/i18n/i18n';
import { useLocaleSettings } from '@/hooks';
import { nameof } from '@/infrastructure/utils/ts';

import SettlementIntentStatusView from '../SettlementIntentStatusView';

import type { SettlementPendingIntentsTableProps } from './types';
import type { ColumnType } from 'antd/lib/table/interface';
import type React from 'react';

const SettlementPendingIntentsTable: React.FC<SettlementPendingIntentsTableProps> = ({
  'data-test': dataTest,
  data,
  loading,
  SettlementIntentLink,
}) => {
  const { formatDateTime } = useLocaleSettings();
  const columns: ColumnType<SettlementIntent>[] = useMemo(
    () => [
      {
        key: nameof<SettlementIntent>('asset'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_ASSET} />,
        dataIndex: nameof<SettlementIntent>('asset'),
        render: (_, { id, asset }) => (
          <AssetLabel data-test={dataTest && `${dataTest}-${id}-asset`} mode="medium" value={asset} />
        ),
      },
      {
        key: nameof<SettlementIntent>('processAt'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_PROCESS_AT} />,
        dataIndex: nameof<SettlementIntent>('processAt'),
        valueType: 'dateTime',
        render: (_, { id, processAt }) => (
          <ReadonlyComponent value={formatDateTime(processAt)} data-test={dataTest && `${dataTest}-${id}-processAt`} />
        ),
      },
      {
        key: nameof<SettlementIntent>('status'),
        title: <FormattedMessage id={I18nFeatureSettlements.LABELS_ROW_TITLE_SETTLEMENT_INTENT_STATUS} />,
        dataIndex: nameof<SettlementIntent>('status'),
        hideInSetting: true,
        align: 'center',
        render: (_, { id, status }) => (
          <SettlementIntentStatusView value={status} data-test={dataTest && `${dataTest}-${id}-status`} mode="icon" />
        ),
      },
      {
        key: 'options',
        title: <FormattedMessage id={I18nComponents.TABLE_ROW_OPTIONS} />,
        dataIndex: nameof<SettlementIntent>('id'),
        width: 1,
        fixed: 'right',
        render: (_, { id }) => (
          <StopPropagationContainer>
            {SettlementIntentLink && (
              <SettlementIntentLink data-test={dataTest && `${dataTest}-${id}-link`} value={id} />
            )}
          </StopPropagationContainer>
        ),
      },
    ],
    [SettlementIntentLink, dataTest, formatDateTime],
  );

  return (
    <Table<SettlementIntent>
      data-test={dataTest}
      columns={columns}
      dataSource={data.data ?? []}
      loading={loading}
      pagination={false}
    />
  );
};

export default SettlementPendingIntentsTable;
