import React, { useMemo } from 'react';

import { useLocaleSettings } from '@/hooks';

import ReadonlyComponent from '../ReadonlyComponent';

import type { ReadonlyDateTimeProps } from './types';

const ReadonlyDateTimeRaw: React.FC<ReadonlyDateTimeProps> = ({ value, className, style, 'data-test': dataTest }) => {
  const { formatDateTime } = useLocaleSettings();
  const formattedValue = useMemo(() => (value ? formatDateTime(value) : undefined), [formatDateTime, value]);
  return (
    <ReadonlyComponent
      data-test={dataTest}
      style={style}
      className={className}
      copyable={false}
      value={formattedValue}
    />
  );
};

const ReadonlyDateTime = React.memo(ReadonlyDateTimeRaw) as typeof ReadonlyDateTimeRaw;

export default ReadonlyDateTime;
