import {
  CheckCircleOutlined,
  ClusterOutlined,
  ExclamationCircleOutlined,
  FileOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { PayoutStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeaturePayouts } from '@/generated/i18n/i18n';

import type { PayoutStatusViewProps } from './types';

const i18n: Record<PayoutStatusAPIModel, I18nFeaturePayouts> = {
  [PayoutStatusAPIModel.Created]: I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_VIEW_CREATED,
  [PayoutStatusAPIModel.Pending]: I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_VIEW_PENDING,
  [PayoutStatusAPIModel.PartlySucceeded]: I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_VIEW_PARTLYSUCCEEDED,
  [PayoutStatusAPIModel.Succeeded]: I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_VIEW_SUCCEEDED,
  [PayoutStatusAPIModel.Failed]: I18nFeaturePayouts.COMPONENTS_PAYOUT_STATUS_VIEW_FAILED,
};

const icons: Record<PayoutStatusAPIModel, React.ReactNode> = {
  [PayoutStatusAPIModel.Created]: <FileOutlined style={{ color: 'blue' }} />,
  [PayoutStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <ClusterOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [PayoutStatusAPIModel.PartlySucceeded]: (
    <Badge count={<ExclamationCircleOutlined style={{ color: 'red' }} />} showZero>
      <CheckCircleOutlined style={{ color: 'green' }} />
    </Badge>
  ),
  [PayoutStatusAPIModel.Succeeded]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [PayoutStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const PayoutStatusView: React.FC<PayoutStatusViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const PayoutStatusViewMemo = React.memo(PayoutStatusView) as typeof PayoutStatusView;

export default PayoutStatusViewMemo;
