import { type CommonLoadingState, loadingDataLoaded, mapLoadingState } from '@/infrastructure/model';
import { loadingDataMarkDirtyReducer, loadingDataStoreDataReducer } from '@/infrastructure/model/common/reducers';
import type { LoadingPartialDataState } from '@/infrastructure/model/partial/types';
import { createSingleReducers, multipleStoreReducer } from '@/infrastructure/model/single/reducers';
import type { SingleState } from '@/infrastructure/model/single/types';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { Draft } from 'immer';

export const createPartialDataReducers = <Type extends string, Global extends EmptyObject, Value, Exc = string>(
  type: Type,
  get: (global: Draft<Global>) => LoadingPartialDataState<Value, Exc>,
  set: (global: Draft<Global>, newListState: LoadingPartialDataState<Value, Exc>) => Draft<Global>,
) => {
  const markDirtyName = `mark${type}BatchDirtyReducer` as const;
  const storeDataName = `store${type}BatchReducer` as const;

  const markDirty = loadingDataMarkDirtyReducer(get, set);
  const storeData = loadingDataStoreDataReducer(get, set);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [storeDataName]: storeData,
  } as Record<typeof markDirtyName, typeof markDirty> & Record<typeof storeDataName, typeof storeData>;
};

export const createNormalizedPartialDataReducers = <
  Type extends string,
  Global extends EmptyObject,
  Value,
  Id = string,
  Exc = string,
  Key extends string = string,
>(
  type: Type,
  getPartialData: (global: Draft<Global>) => LoadingPartialDataState<Key, Exc>,
  setPartialData: (global: Draft<Global>, newListState: LoadingPartialDataState<Key, Exc>) => Draft<Global>,
  getSingle: (global: Draft<Global>) => SingleState<Value, Key>,
  setSingle: (state: Draft<Global>, newSingleState: SingleState<Value, Key>) => Draft<Global>,
  extractId: (value: Value) => Id,
  mapper?: (id: Id) => Key,
) => {
  const partialDataReducers = createPartialDataReducers(type, getPartialData, setPartialData);
  const singleReducers = createSingleReducers(type, getSingle, setSingle, mapper);

  const storeDataName = `store${type}BatchReducer` as const;
  const flatPartialDataReducer = loadingDataStoreDataReducer(getPartialData, setPartialData);

  const toStored = (id: Id): Key => (typeof id === 'string' ? (id as unknown as Key) : mapper!(id));
  const storeData = (state: Draft<Global>, { payload }: PayloadAction<CommonLoadingState<Value[], Exc>>) => {
    const partialDataState = flatPartialDataReducer(state, {
      payload: mapLoadingState(payload, (data) => data.map(extractId).map(toStored)),
    });
    return payload.data
      ? multipleStoreReducer(getSingle, setSingle)(partialDataState, {
          payload: payload.data.map((value) => ({
            id: toStored(extractId(value)),
            data: loadingDataLoaded(value),
          })),
        })
      : partialDataState;
  };

  // FIXME: redefine the type without the cast
  return {
    ...partialDataReducers,
    ...singleReducers,
    [storeDataName]: storeData,
  } as Omit<typeof partialDataReducers, typeof storeDataName> &
    typeof singleReducers &
    Record<typeof storeDataName, typeof storeData>;
};
