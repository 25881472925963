import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsCompanyGasWalletsLink } from '@/pages/settings/routes';

import type { GasWalletsLinkProps } from './types';

const GasWalletsLink: React.FC<GasWalletsLinkProps> = ({ 'data-test': dataTest, mode = 'inline', title, icon }) => (
  <Link
    data-test={dataTest}
    to={settingsCompanyGasWalletsLink()}
    mode={mode}
    icon={icon}
    title={title ?? <FormattedMessage id={I18nPageSettings.COMPANY_GAS_WALLETS_LINK_TITLE} />}
  />
);

const GasWalletsLinkMemo = React.memo(GasWalletsLink);

export default GasWalletsLinkMemo;
