import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { createReportSchedule } from '@/features/report-schedules/actions';
import type { NewReportSchedule, ReportSchedule } from '@/features/report-schedules/types';
import type { HookAction } from '@/infrastructure/model';

export interface UseReportSchedulesActions {
  create: HookAction<[NewReportSchedule], ReportSchedule>;
}

export default function useReportSchedulesActions(): UseReportSchedulesActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const isCreating = useActionPending(createReportSchedule);
  const create: UseReportSchedulesActions['create'] = {
    act: useCallback(
      (value: NewReportSchedule) => withExtractDataDispatch(createReportSchedule)(value),
      [withExtractDataDispatch],
    ),
    available: true,
    inAction: isCreating,
  };
  return { create };
}
