import { Form } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureWeb3 } from '@/generated/i18n/i18n';

import { WalletAddress } from './components';

import type { WalletAddressItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const editableRules = [
  {
    required: true,
    message: <FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_ADDRESS_REQUIRED} />,
  },
];

const WalletAddressItem = <Values extends Store = Store>({
  name,
  connectorId,
  required,
  readonly,
  ItemProps = {},
  'data-test': dataTest,
}: WalletAddressItemProps<Values>) => (
  <Form.Item<Values>
    label={<FormattedMessage id={I18nFeatureWeb3.COMPONENTS_WALLET_ADDRESS_LABEL} />}
    rules={!readonly && required ? editableRules : undefined}
    required={!readonly && required}
    {...ItemProps}
    name={name}
  >
    <WalletAddress connectorId={connectorId} data-test={dataTest} />
  </Form.Item>
);

const WalletAddressItemMemo = React.memo(WalletAddressItem) as typeof WalletAddressItem;

export default WalletAddressItemMemo;
