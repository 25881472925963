import { DownloadOutlined } from '@ant-design/icons';
import React, { useCallback, useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useReportActions } from '@/features/reports/hooks';
import { I18nFeatureReports } from '@/generated/i18n/i18n';
import { useNotification, useStateMountSafe } from '@/hooks';

import type { OperationDownloadReportProps } from './types';

const OperationDownloadReportRaw: React.FC<OperationDownloadReportProps> = ({
  'data-test': dataTest,
  disabled,
  reportId,
  mode = 'inline',
}) => {
  const { withError } = useNotification();
  const { generateURL: generateURLAction } = useReportActions(reportId);
  const [reportURL, setReportURL] = useStateMountSafe<string>();

  const generateURL = useMemo(
    () =>
      withError(generateURLAction.act, () => (
        <FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_DOWNLOAD_REPORT_ERROR_MESSAGE} />
      )),
    [generateURLAction.act, withError],
  );

  const downloadReport = useCallback(async () => {
    const url: string = await generateURL();
    setReportURL(url);
  }, [generateURL, setReportURL]);

  return (
    <>
      <Operation
        title={<FormattedMessage id={I18nFeatureReports.COMPONENTS_OPERATION_DOWNLOAD_REPORT_TITLE} />}
        icon={<DownloadOutlined />}
        onClick={downloadReport}
        disabled={disabled || !generateURLAction.available}
        inProgress={generateURLAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
      {/*
       This iframe is a workaround:
       1. we have no way to authorize get request, so we use otp
       2. but there is no safe way to download file programmatically (the browser should do that itself)
       */}
      {reportURL && <iframe src={reportURL} style={{ display: 'none' }} title="report" />}
    </>
  );
};

const OperationDownloadReport = React.memo(OperationDownloadReportRaw);

export default OperationDownloadReport;
