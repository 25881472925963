/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RenewEmailConfirmationAPIModel
 */
export interface RenewEmailConfirmationAPIModel {
    /**
     * 
     * @type {string}
     * @memberof RenewEmailConfirmationAPIModel
     */
    token: string;
    /**
     * 
     * @type {number}
     * @memberof RenewEmailConfirmationAPIModel
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof RenewEmailConfirmationAPIModel
     */
    email: string;
}

/**
 * Check if a given object implements the RenewEmailConfirmationAPIModel interface.
 */
export function instanceOfRenewEmailConfirmationAPIModel(value: object): boolean {
    if (!('token' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('email' in value)) return false;
    return true;
}

export function RenewEmailConfirmationAPIModelFromJSON(json: any): RenewEmailConfirmationAPIModel {
    return RenewEmailConfirmationAPIModelFromJSONTyped(json, false);
}

export function RenewEmailConfirmationAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RenewEmailConfirmationAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'companyId': json['companyId'],
        'email': json['email'],
    };
}

export function RenewEmailConfirmationAPIModelToJSON(value?: RenewEmailConfirmationAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'companyId': value['companyId'],
        'email': value['email'],
    };
}

