import { Select } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { BrandingAuth } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';

import BrandingAuthLabel from '../BrandingAuthLabel';

import type { BrandingAuthSelectProps } from './types';

const BrandingAuthSelect: React.FC<BrandingAuthSelectProps> = ({
  'data-test': dataTest,
  style,
  className,
  readonly,
  multiselect,
  onChange,
  value,
}) => {
  const options = useMemo(
    () =>
      multiselect
        ? enumValues(BrandingAuth).map((val) => ({
            value: val,
            inline: <BrandingAuthLabel data-test={dataTest && `${dataTest}-selected-${val}`} value={val} mode="full" />,
            label: <BrandingAuthLabel data-test={dataTest && `${dataTest}-value-${val}`} value={val} mode="full" />,
          }))
        : enumValues(BrandingAuth).map((val) => ({
            value: val,
            label: <BrandingAuthLabel data-test={dataTest && `${dataTest}-value-${val}`} value={val} mode="full" />,
          })),
    [dataTest, multiselect],
  );
  return multiselect ? (
    <Select<BrandingAuth[]>
      data-test={dataTest}
      style={style}
      mode="tags"
      className={className}
      disabled={readonly}
      onChange={onChange}
      value={value}
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_AUTH_SELECT_PLACEHOLDER} />}
      optionLabelProp="inline"
      options={options}
    />
  ) : (
    <Select<BrandingAuth>
      data-test={dataTest}
      style={style}
      className={className}
      disabled={readonly}
      onChange={onChange}
      value={value}
      placeholder={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_AUTH_SELECT_PLACEHOLDER} />}
      options={options}
    />
  );
};

const BrandingAuthSelectMemo = React.memo(BrandingAuthSelect);

export default BrandingAuthSelectMemo;
