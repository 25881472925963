import React from 'react';

import type { Func } from '@/infrastructure/utils/ts';

import type { Address } from 'abitype';
import type { Account, Chain, Client, Transport } from 'viem';
import type { Connector } from 'wagmi';

export interface Web3ConnectorContextType {
  connector: Connector;
  client?: Client<Transport, Chain, Account>;
  isLocal: boolean;
  account?: Address;
  chainId?: number;
  isConnecting?: boolean;
  disconnectable: boolean;
  isReady: boolean;
  refreshAccount: Func<[], string | undefined>;
  refreshChainId: Func<[], number | undefined>;
  refresh: Func<[], { account?: Address; chainId?: number } | undefined>;
}

const Web3ConnectorContext = React.createContext<Web3ConnectorContextType | undefined>(undefined);

export default Web3ConnectorContext;
