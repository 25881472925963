import React, { useEffect, useRef } from 'react';

import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { FormattedMessage, Link } from '@/components';
import { removeNotificationFromStore } from '@/features/global/actions';
import { makeSelectNotifications } from '@/features/global/selectors';
import type { LinkInNotification } from '@/features/global/types';
import { useNotification } from '@/hooks';

import type { ArgsProps } from 'antd/es/notification';

const selectNotifications = makeSelectNotifications();

const parseValues = (values?: Record<string, string | ((text: string) => string) | LinkInNotification>) =>
  values
    ? Object.entries(values).reduce(
        (result, [key, value]) => ({
          ...result,
          [key]:
            typeof value === 'object' && 'type' in value
              ? (text: string) => <Link title={text} to={value.to} />
              : value,
        }),
        {},
      )
    : undefined;

const Notifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const { notification } = useNotification();
  const notifications = useAppSelector(selectNotifications);

  const shown = useRef<string[]>([]);

  useEffect(() => {
    notifications
      .filter(({ id }) => !shown.current.includes(id))
      .forEach((entity) => {
        const args: ArgsProps = {
          message:
            'messageI18n' in entity ? (
              <FormattedMessage id={entity.messageI18n} values={parseValues(entity.messageI18nValues)} />
            ) : (
              entity.message
            ),
          description:
            // eslint-disable-next-line no-nested-ternary
            'descriptionI18n' in entity ? (
              <FormattedMessage id={entity.descriptionI18n!} values={parseValues(entity.descriptionI18nValues)} />
            ) : 'description' in entity ? (
              entity.description
            ) : undefined,
          duration: entity.duration,
          onClose: () => dispatch(removeNotificationFromStore(entity.id)),
        };
        switch (entity.severity) {
          case 'info':
            notification?.info(args);
            break;
          case 'error':
            notification?.error(args);
            break;
          case 'success':
            notification?.success(args);
            break;
          case 'warning':
            notification?.warning(args);
            break;
          default:
            break;
        }
      });
    shown.current = notifications.map(({ id }) => id);
  }, [dispatch, notification, notifications]);

  return null;
};

const NotificationsMemo = React.memo(Notifications);

export default NotificationsMemo;
