import React from 'react';

import { OperationRefresh } from '@/components';
import { useScheduledIntents } from '@/features/settlements/hooks';

import type { OperationRefreshScheduledIntentsProps } from './types';

const OperationRefreshScheduledIntents: React.FC<OperationRefreshScheduledIntentsProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh } = useScheduledIntents();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} />;
};

const OperationRefreshScheduledIntentsMemo = React.memo(OperationRefreshScheduledIntents);

export default OperationRefreshScheduledIntentsMemo;
