import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';
import { balancesActualLink } from '@/pages/balances/routes';

import type { ActualBalancesLinkProps } from './types';

const ActualBalancesLink: React.FC<ActualBalancesLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    mode={mode}
    to={balancesActualLink()}
    data-test={dataTest}
    title={title ?? <FormattedMessage id={I18nPageBalances.ACTUAL_COMPONENTS_LINK_TITLE} />}
    icon={icon}
  />
);

const ActualBalancesLinkMemo = React.memo(ActualBalancesLink);

export default ActualBalancesLinkMemo;
