import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import SettingsBreadcrumb from '@/pages/settings/components/SettingsBreadcrumb';

import CompanyIntegrationLink from '../CompanyIntegrationLink';

import type { CompanyIntegrationBreadcrumbProps } from './types';

const CompanyIntegrationBreadcrumb: React.FC<CompanyIntegrationBreadcrumbProps> = ({
  'data-test': dataTest,
  disabled,
  items = [],
}) => (
  <SettingsBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.INTEGRATION_COMPONENTS_BREADCRUMB_TITLE} />
        ) : (
          <CompanyIntegrationLink
            mode="text"
            title={<FormattedMessage id={I18nPageSettings.INTEGRATION_COMPONENTS_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const CompanyIntegrationBreadcrumbMemo = React.memo(CompanyIntegrationBreadcrumb);

export default CompanyIntegrationBreadcrumbMemo;
