import { ExclamationCircleOutlined, HourglassOutlined, IssuesCloseOutlined } from '@ant-design/icons';
import React from 'react';

import { StatusViewElement } from '@/components';
import { ReportStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureReports } from '@/generated/i18n/i18n';

import type { ReportStatusViewProps } from './types';

const icons: Record<ReportStatusAPIModel, React.ReactNode> = {
  [ReportStatusAPIModel.Generating]: <HourglassOutlined style={{ color: 'blue' }} />,
  [ReportStatusAPIModel.Success]: <IssuesCloseOutlined style={{ color: 'green' }} />,
  [ReportStatusAPIModel.Error]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const i18n: Record<ReportStatusAPIModel, I18nMessages> = {
  [ReportStatusAPIModel.Generating]: I18nFeatureReports.COMPONENTS_STATUS_VIEW_LABEL_GENERATING,
  [ReportStatusAPIModel.Success]: I18nFeatureReports.COMPONENTS_STATUS_VIEW_LABEL_SUCCESS,
  [ReportStatusAPIModel.Error]: I18nFeatureReports.COMPONENTS_STATUS_VIEW_LABEL_ERROR,
};

const ReportStatusViewRaw: React.FC<ReportStatusViewProps> = ({ value, 'data-test': dataTest, mode = 'full' }) =>
  value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null;

const ReportStatusView = React.memo(ReportStatusViewRaw);

export default ReportStatusView;
