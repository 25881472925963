import { css } from '@emotion/css';
import { List, Space } from 'antd';
import React, { useMemo } from 'react';

import type { OperationMode } from '@/components';
import { AddressLink, BlockchainLabel } from '@/features/dictionary/blockchain/components';
import { GasWalletStateCheckBanner } from '@/features/gas-wallets/components';
import GasWalletDerivedBalanceView from '@/features/gas-wallets/components/GasWalletDerivedBalanceView';
import GasWalletNativeBalanceView from '@/features/gas-wallets/components/GasWalletNativeBalanceView';
import OperationDeleteGasWallet from '@/features/gas-wallets/components/OperationDeleteGasWallet';
import OperationRefundGasWallet from '@/features/gas-wallets/components/OperationRefundGasWallet';
import { useDimensions } from '@/hooks';

import type { GasWalletItemProps } from './types';
import type { SpaceSize } from 'antd/lib/space';

const GasWalletItem: React.FC<GasWalletItemProps> = ({ 'data-test': dataTest, wallet, GasWalletLink }) => {
  const [ref, bounds] = useDimensions();
  const layout: {
    opsDirection: 'horizontal' | 'vertical';
    opsMode: OperationMode;
    opsStyle: React.CSSProperties;
    opsSpaceSize: SpaceSize;
  } = useMemo(
    () =>
      bounds?.width && bounds.width < 400
        ? {
            opsDirection: 'vertical',
            opsMode: 'icon',
            opsStyle: {
              alignItems: 'flex-end',
              flexDirection: 'column-reverse',
            },
            opsSpaceSize: 0,
          }
        : { opsDirection: 'horizontal', opsMode: 'inline', opsStyle: {}, opsSpaceSize: undefined },
    [bounds?.width],
  );
  return (
    <div key={wallet.bt} ref={ref}>
      <List.Item>
        <List.Item.Meta
          avatar={
            <BlockchainLabel
              mode="icon"
              style={{ fontSize: 32 }}
              bt={wallet.bt}
              data-test={dataTest && `${dataTest}-icon`}
            />
          }
          description={
            <AddressLink bt={wallet.bt} data-test={dataTest && `${dataTest}-address`} address={wallet.address} />
          }
          title={
            <Space
              className={css`
                flex-wrap: wrap;
              `}
            >
              <GasWalletNativeBalanceView data-test={dataTest && `${dataTest}-native`} wallet={wallet} />
              <GasWalletDerivedBalanceView data-test={dataTest && `${dataTest}-derived`} wallet={wallet} />
              <GasWalletStateCheckBanner
                mode="icon"
                type="any-service"
                data-test={dataTest && `${dataTest}-state`}
                bt={wallet.bt}
              />
            </Space>
          }
        />
        <Space direction={layout.opsDirection} style={layout.opsStyle} size={layout.opsSpaceSize}>
          <Space>
            {GasWalletLink && <GasWalletLink data-test={dataTest && `${dataTest}-link`} value={wallet.bt} />}
            <OperationRefundGasWallet
              data-test={dataTest && `${dataTest}-opRefund`}
              bt={wallet.bt}
              mode={layout.opsMode}
            />
            <OperationDeleteGasWallet
              data-test={dataTest && `${dataTest}-opDelete`}
              bt={wallet.bt}
              mode={layout.opsMode}
            />
          </Space>
        </Space>
      </List.Item>
    </div>
  );
};

const GasWalletItemMemo = React.memo(GasWalletItem);

export default GasWalletItemMemo;
