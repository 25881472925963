import { Form } from 'antd';
import React from 'react';

import { defaultDialogFormLayout } from '@/components';
import type { ReportScheduleParametersViewProps } from '@/features/global/hocs/withHandlerRegistration/schedules';

import GasHistoryScheduleParametersForm from '../GasHistoryReportScheduleParametersForm';

const GasHistoryScheduleParametersView: React.FC<ReportScheduleParametersViewProps> = ({
  value,
  'data-test': dataTest,
}) => (
  <Form initialValues={{ parameters: value }} {...defaultDialogFormLayout}>
    <GasHistoryScheduleParametersForm data-test={dataTest} name="parameters" readonly />
  </Form>
);
const GasHistoryScheduleParametersViewMemo = React.memo(
  GasHistoryScheduleParametersView,
) as typeof GasHistoryScheduleParametersView;

export default GasHistoryScheduleParametersViewMemo;
