import React from 'react';

import { RechargeAddressView } from '@/features/recharges/components';

import { withRechargeAddressDataLoading } from './hocs';

const RechargeAddressCard = withRechargeAddressDataLoading((props) => <RechargeAddressView {...props} columns={1} />);

const RechargeAddressCardMemo = React.memo(RechargeAddressCard) as typeof RechargeAddressCard;

export default RechargeAddressCardMemo;
