import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  HourglassOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Badge } from 'antd';
import React from 'react';

import { StatusViewElement } from '@/components';
import { PushCollectStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureCollectable } from '@/generated/i18n/i18n';

import type { CollectTaskStatusViewProps } from './types';

const i18n: Record<PushCollectStatusAPIModel, I18nMessages> = {
  [PushCollectStatusAPIModel.Awaiting]: I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_VIEW_AWAITING,
  [PushCollectStatusAPIModel.Pending]: I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_VIEW_PENDING,
  [PushCollectStatusAPIModel.Succeeded]: I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_VIEW_SUCCEEDED,
  [PushCollectStatusAPIModel.Failed]: I18nFeatureCollectable.COMPONENTS_COLLECT_TASK_STATUS_VIEW_FAILED,
};

const icons: Record<PushCollectStatusAPIModel, React.ReactNode> = {
  [PushCollectStatusAPIModel.Awaiting]: <ClockCircleOutlined style={{ color: 'blue' }} />,
  [PushCollectStatusAPIModel.Pending]: (
    <Badge count={<SyncOutlined spin style={{ fontSize: 'smaller' }} />} showZero>
      <HourglassOutlined style={{ color: 'blue' }} />
    </Badge>
  ),
  [PushCollectStatusAPIModel.Succeeded]: <CheckCircleOutlined style={{ color: 'green' }} />,
  [PushCollectStatusAPIModel.Failed]: <ExclamationCircleOutlined style={{ color: 'red' }} />,
};

const CollectTaskStatusView: React.FC<CollectTaskStatusViewProps> = ({
  value,
  'data-test': dataTest,
  mode = 'full',
}) => (value ? <StatusViewElement data-test={dataTest} i18n={i18n[value]} icon={icons[value]} mode={mode} /> : null);

const CollectTaskStatusViewMemo = React.memo(CollectTaskStatusView);

export default CollectTaskStatusViewMemo;
