import React, { useMemo } from 'react';

import { MoneyAmountItem } from '@/components';
import { useAsset } from '@/features/dictionary/blockchain/hooks';

import AssetCode from '../AssetCode';
import AssetTooltip from '../AssetTooltip';

import type { AssetAmountItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const AssetAmountItem = <Values extends Store = Store>({ assetId, ...props }: AssetAmountItemProps<Values>) => {
  const { data: asset } = useAsset(assetId);
  const moneyAsset = useMemo(
    () =>
      asset.data
        ? {
            code: asset.data.code,
            title: (
              <AssetTooltip type={asset.data.code}>
                <AssetCode value={asset.data.code} />
              </AssetTooltip>
            ),
            precision: asset.data.formatDecimals,
          }
        : undefined,
    [asset.data],
  );
  if (!moneyAsset) {
    return null;
  }
  return <MoneyAmountItem<Values> asset={moneyAsset} {...props} />;
};

const AssetAmountItemMemo = React.memo(AssetAmountItem) as typeof AssetAmountItem;

export default AssetAmountItemMemo;
