/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { MerchantReportAPIModel } from './MerchantReportAPIModel';
import {
    MerchantReportAPIModelFromJSON,
    MerchantReportAPIModelFromJSONTyped,
    MerchantReportAPIModelToJSON,
} from './MerchantReportAPIModel';
import type { PageReportSortByAPIModel } from './PageReportSortByAPIModel';
import {
    PageReportSortByAPIModelFromJSON,
    PageReportSortByAPIModelFromJSONTyped,
    PageReportSortByAPIModelToJSON,
} from './PageReportSortByAPIModel';

/**
 * 
 * @export
 * @interface SliceMerchantReportReportSortByAPIModel
 */
export interface SliceMerchantReportReportSortByAPIModel {
    /**
     * 
     * @type {Array<MerchantReportAPIModel>}
     * @memberof SliceMerchantReportReportSortByAPIModel
     */
    list?: Array<MerchantReportAPIModel>;
    /**
     * 
     * @type {PageReportSortByAPIModel}
     * @memberof SliceMerchantReportReportSortByAPIModel
     */
    page: PageReportSortByAPIModel;
    /**
     * 
     * @type {number}
     * @memberof SliceMerchantReportReportSortByAPIModel
     */
    total?: number;
}

/**
 * Check if a given object implements the SliceMerchantReportReportSortByAPIModel interface.
 */
export function instanceOfSliceMerchantReportReportSortByAPIModel(value: object): boolean {
    if (!('page' in value)) return false;
    return true;
}

export function SliceMerchantReportReportSortByAPIModelFromJSON(json: any): SliceMerchantReportReportSortByAPIModel {
    return SliceMerchantReportReportSortByAPIModelFromJSONTyped(json, false);
}

export function SliceMerchantReportReportSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SliceMerchantReportReportSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'list': json['list'] == null ? undefined : ((json['list'] as Array<any>).map(MerchantReportAPIModelFromJSON)),
        'page': PageReportSortByAPIModelFromJSON(json['page']),
        'total': json['total'] == null ? undefined : json['total'],
    };
}

export function SliceMerchantReportReportSortByAPIModelToJSON(value?: SliceMerchantReportReportSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'list': value['list'] == null ? undefined : ((value['list'] as Array<any>).map(MerchantReportAPIModelToJSON)),
        'page': PageReportSortByAPIModelToJSON(value['page']),
        'total': value['total'],
    };
}

