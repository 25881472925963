import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { BrandingIcon } from '@/features/branding/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import { settingsBrandingSummaryLink } from '@/pages/settings/routes';

import type { BrandingSummaryLinkProps } from './types';

const BrandingSummaryLink: React.FC<BrandingSummaryLinkProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  title,
  icon,
}) => (
  <Link
    data-test={dataTest}
    mode={mode}
    to={settingsBrandingSummaryLink()}
    icon={icon ?? <BrandingIcon />}
    title={title ?? <FormattedMessage id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_LINK_TITLE} />}
  />
);

const BrandingSummaryLinkMemo = React.memo(BrandingSummaryLink);

export default BrandingSummaryLinkMemo;
