import { useActiveCompany } from '@/features/company/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type React from 'react';

const withKYBRequired = <T extends EmptyObject = EmptyObject>(Component: React.ComponentType<T>) =>
  namedHOC(
    Component,
    'WithKYBRequired',
  )((props) => {
    const {
      data: { data: company },
    } = useActiveCompany();

    return !company?.isKYBFinished ? <Component {...props} /> : null;
  });

export default withKYBRequired;
