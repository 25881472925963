import { useMemo } from 'react';

import { type UseAppSingleData, useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectCollectEntityProcessTransaction } from '@/features/collectable/selectors';
import type { CollectableEntityProcessTransaction } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchRechargeAddressDeployTransaction } from '@/features/recharges/actions';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseRechargeAddressDeployTransaction = UseAppSingleData<CollectableEntityProcessTransaction>;

const noData = storedDataError<CollectableEntityProcessTransaction>('no-data');

const fetchFactory = (
  addressId: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<CollectableEntityProcessTransaction>,
  Parameters<typeof fetchRechargeAddressDeployTransaction>[0]
> => fetchRechargeAddressDeployTransaction({ addressId, force });

export default function useRechargeAddressDeployTransaction(
  addressId: string | undefined,
): UseRechargeAddressDeployTransaction {
  const dataFetchingSelector = useMemo(
    () => makeSelectPending(fetchRechargeAddressDeployTransaction, addressId),
    [addressId],
  );
  return useAppSingleData(
    fetchFactory,
    makeSelectCollectEntityProcessTransaction,
    noData,
    addressId,
    dataFetchingSelector,
  );
}
