import type { AppRootState } from '@/app/store';
import { createNormalizedListSelectors } from '@/infrastructure/model/list/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectReportSchedule,
  makeSelectReportScheduleListParameters,
  makeSelectReportScheduleListData,
  makeSelectDirtyReportScheduleIds,
  makeSelectMultipleReportSchedule,
} = createNormalizedListSelectors(
  (state: AppRootState) => state[NAMESPACE].list,
  (state: AppRootState) => state[NAMESPACE].entities,
  'ReportSchedule' as const,
  undefined,
);
