import BigNumber from 'bignumber.js';
import React, { useMemo } from 'react';

import { MoneyAmount } from '@/components';
import BlockchainLabel from '@/features/dictionary/blockchain/components/BlockchainLabel';
import { useBlockchain } from '@/features/dictionary/blockchain/hooks';

import AssetLabel from '../AssetLabel';

import type { NativeAssetAmountProps } from './types';

const NativeAssetAmount: React.FC<NativeAssetAmountProps> = ({
  'data-test': dataTest,
  className,
  style,
  bt,
  value: baseValue,
  withCurrency,
  withBlockchainMark,
  currencyMode = 'short',
}) => {
  const { data: bc } = useBlockchain(bt);
  const value = useMemo(() => (typeof baseValue === 'string' ? new BigNumber(baseValue) : baseValue), [baseValue]);
  const normalizedValue = useMemo(
    () => value.shiftedBy(-(bc.data?.nativeAsset.formatDecimals ?? 18)),
    [bc.data?.nativeAsset.formatDecimals, value],
  );
  return (
    <>
      {withBlockchainMark && bc.data?.nativeAsset && (
        <>
          <BlockchainLabel assetId={bc.data.nativeAsset.code} mode="icon" />
          &nbsp;
        </>
      )}
      <MoneyAmount
        className={className}
        style={style}
        value={normalizedValue}
        fraction={bc.data?.nativeAsset.formatDecimals ?? 18}
        data-test={dataTest}
      />
      {withCurrency && bc.data?.nativeAsset && (
        <>
          &nbsp;
          <AssetLabel
            value={bc.data.nativeAsset.code}
            mode={currencyMode === 'short' ? 'short' : 'medium-no-ico'}
            data-test={dataTest && `${dataTest}-currency`}
          />
        </>
      )}
    </>
  );
};

const NativeAssetAmountMemo = React.memo(NativeAssetAmount);

export default NativeAssetAmountMemo;
