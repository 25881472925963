import { PlusCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import {
  Operation,
  defaultDialogFormTailLayout,
  FormattedMessage,
  MessageToSupport,
  defaultDialogFormLayout,
} from '@/components';
import type { WebhooksFormContent } from '@/features/company-settings/components';
import { useWebhooks, useWebhooksActions } from '@/features/company-settings/hooks';
import { useSelectedNetwork } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess, withSuppressError } from '@/infrastructure/utils/functions';
import { nameof } from '@/infrastructure/utils/ts';

import WebhooksForm from '../WebhooksForm';

import type { OperationAddWebhookProps } from './types';

const notificationOptions = {
  error: () => ({
    message: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_WEBHOOK_MESSAGE_ERROR} />,
    description: <MessageToSupport />,
  }),
  success: () => <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_WEBHOOK_MESSAGE_SUCCESS} />,
};

const OperationAddWebhook: React.FC<OperationAddWebhookProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { network } = useSelectedNetwork();
  const initialValues = useMemo(() => ({ [nameof<WebhooksFormContent>('network')]: network }), [network]);

  const { addWebhook } = useWebhooksActions();
  const { data } = useWebhooks();
  const formControl = useFormVisible(false);
  const { withNotification } = useNotification();

  const doAdd = useMemo(
    () =>
      withSuppressError(
        withNotification(formControl.withFormHide(withOnSuccess(addWebhook.act, onSuccess)), notificationOptions),
      ),
    [withNotification, formControl, addWebhook.act, onSuccess],
  );

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_WEBHOOK_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <WebhooksForm
            initialValues={initialValues}
            selectedValues={data.data}
            layout={defaultDialogFormLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={doAdd}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_ADD_WEBHOOK_LABEL} />}
        icon={<PlusCircleOutlined />}
        onClick={formControl.show}
        disabled={disabled || !addWebhook.available || !!data.error}
        inProgress={inProgress || formControl.visible || addWebhook.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationAddWebhookMemo = React.memo(OperationAddWebhook);

export default OperationAddWebhookMemo;
