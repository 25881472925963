import React from 'react';

import { FormattedMessageWithMarkup, KYBMailTo } from '@/components';
import { useActiveCompany } from '@/features/company/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';

import type { KYBRequiredBannerDescriptionFullProps } from './types';

const KYBRequiredBannerDescriptionFull: React.FC<KYBRequiredBannerDescriptionFullProps> = () => {
  const { data } = useActiveCompany();
  return data.data ? (
    <FormattedMessageWithMarkup
      id={I18nFeatureCompanySettings.COMPONENTS_KYB_REQUIRED_BANNER_FULL_DESCRIPTION}
      values={{ email: <KYBMailTo companyId={data.data.id} /> }}
    />
  ) : (
    <FormattedMessageWithMarkup id={I18nFeatureCompanySettings.COMPONENTS_KYB_REQUIRED_BANNER_SHORT_DESCRIPTION} />
  );
};

const KYBRequiredBannerDescriptionFullMemo = React.memo(KYBRequiredBannerDescriptionFull);

export default KYBRequiredBannerDescriptionFullMemo;
