import type { AppRootState } from '@/app/store';
import { createSingleSelectors } from '@/infrastructure/model/single/selectors';

import { NAMESPACE } from './types';

export const {
  makeSelectMultipleSubscriptionCharge,
  makeSelectDirtySubscriptionChargeIds,
  makeSelectSubscriptionCharge,
} = createSingleSelectors((state: AppRootState) => state[NAMESPACE].entities, 'SubscriptionCharge' as const, undefined);
