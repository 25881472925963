import React from 'react';

import { OperationRefresh } from '@/components';
import { useDomains } from '@/features/branding/hooks';

import type { OperationRefreshMerchantDomainsProps } from './types';

const OperationRefreshMerchantDomains: React.FC<OperationRefreshMerchantDomainsProps> = ({
  'data-test': dataTest,
  title,
  mode = 'inline',
}) => {
  const { forceRefresh } = useDomains();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} messages={{ title }} />;
};

const OperationRefreshMerchantDomainsMemo = React.memo(OperationRefreshMerchantDomains);

export default OperationRefreshMerchantDomainsMemo;
