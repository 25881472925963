import { PageContainer } from '@/components';
import { RechargesDocumentationBanner } from '@/features/recharges/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { RechargeAddressCard, RechargeTransactionBreadcrumb, RechargeTransactionCard } from './components';
import { withRechargeTransactionPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type RechargeTransactionViewPageProps = PropsWithChildren<TestableProps & { pageParams: { transactionId: string } }>;

const RechargeTransactionViewPage: React.FC<RechargeTransactionViewPageProps> = ({
  'data-test': dataTest = 'pt',
  pageParams: { transactionId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <RechargeTransactionBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        transactionId={transactionId}
        disabled
      />
    }
  >
    <RechargesDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <RechargeTransactionCard transactionId={transactionId} data-test={dataTest && `${dataTest}-transaction`} />
    <RechargeAddressCard data-test={dataTest && `${dataTest}-recharge`} transactionId={transactionId} />
  </PageContainer>
);

export default withRechargeTransactionPageParams(RechargeTransactionViewPage);
