import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import { FormattedMessage } from '@/components';
import { SubscriptionMonthlyRevenueGraph } from '@/features/subscription-statistics/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withMonthlyRevenueDataLoading } from '@/pages/subscriptions/statistics/hocs';

export default withMonthlyRevenueDataLoading(
  SubscriptionMonthlyRevenueGraph,
  <FormattedMessage id={I18nPageSubscriptions.STATISTICS_COMPONENTS_REVENUE_CARD_TITLE} />,
  <Tooltip
    title={<FormattedMessage id={I18nPageSubscriptions.STATISTICS_COMPONENTS_REVENUE_CARD_HELP} />}
    placement="bottomLeft"
  >
    <QuestionCircleOutlined />
  </Tooltip>,
);
