import { Form } from 'antd';

import { BrandingDataContainer, BrandingDataPreview } from '@/features/branding/components';
import BrandingDataEditPanel from '@/features/branding/components/BrandingDataEditPanel';
import type { BrandingData } from '@/features/branding/types';
import { noop } from '@/infrastructure/utils/functions';

import { withBrandingProfileDataLoading } from './hocs';

import type { BrandingProfileViewCardProps } from './types';
import type React from 'react';

const BrandingProfileViewCard: React.FC<BrandingProfileViewCardProps> = ({ 'data-test': dataTest, data }) => (
  <Form<BrandingData> initialValues={data}>
    <BrandingDataContainer
      dataPanel={<BrandingDataEditPanel data-test={dataTest && `${dataTest}-content`} disabled />}
      previewPanel={
        <BrandingDataPreview data-test={dataTest && `${dataTest}-content`} initialValue={data} onUpdaterReady={noop} />
      }
    />
  </Form>
);

const BrandingProfileViewCardHOC =
  withBrandingProfileDataLoading<BrandingProfileViewCardProps>(BrandingProfileViewCard);

export default BrandingProfileViewCardHOC;
