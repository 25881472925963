import { DeleteOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, MessageToSupport, Operation } from '@/components';
import { useWebhookActions } from '@/features/company-settings/hooks';
import { I18nFeatureCompanySettings } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationDeleteWebhookProps } from './types';

const notificationOptions = {
  error: () => ({
    message: <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_WEBHOOK_MESSAGE_ERROR} />,
    description: <MessageToSupport />,
  }),
  success: () => (
    <FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_WEBHOOK_MESSAGE_SUCCESS} />
  ),
};

const OperationDeleteWebhook: React.FC<OperationDeleteWebhookProps> = ({
  'data-test': dataTest,
  confirmation,
  mode = 'inline',
  value,
}) => {
  const { deleteWebhook } = useWebhookActions(value);
  const { withNotification } = useNotification();

  const doDelete = useMemo(
    () => withSuppressError(withNotification(deleteWebhook.act, notificationOptions)),
    [withNotification, deleteWebhook.act],
  );
  const confirmationProps = useMemo(
    () =>
      confirmation
        ? {
            title: (
              <FormattedMessage
                id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_WEBHOOK_CONFIRMATION_TITLE}
              />
            ),
          }
        : undefined,
    [confirmation],
  );

  return (
    <Operation
      confirmation={confirmationProps}
      title={<FormattedMessage id={I18nFeatureCompanySettings.COMPONENTS_OPERATION_DELETE_WEBHOOK_LABEL} />}
      icon={<DeleteOutlined />}
      onClick={doDelete}
      disabled={!deleteWebhook.available}
      inProgress={deleteWebhook.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationDeleteWebhookMemo = React.memo(OperationDeleteWebhook);

export default OperationDeleteWebhookMemo;
