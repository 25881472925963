import { Form, Input } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage } from '@/components';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { toDerivedXpub } from '@/infrastructure/utils/hd';

import { normalizeSeed, validateSeed as validateSeedBase } from './utils';

import type { SeedItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const { TextArea } = Input;

const SeedItem = <Values extends Store = Store>({
  'data-test': dataTest,
  required,
  name,
  messages,
  expected,
  rules = [],
  ...props
}: SeedItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  const messagesOrDefault = useMemo(
    () => ({
      label: messages?.label || <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_SEED_ITEM_LABEL} />,
      required: messages?.required ?? (
        <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_SEED_ITEM_ERROR_REQUIRED} />
      ),
      placeholder: messages?.placeholder || i18n({ id: I18nFeatureDictionary.COMPONENTS_SEED_ITEM_PLACEHOLDER }),
    }),
    [i18n, messages?.label, messages?.placeholder, messages?.required],
  );
  const validateSeed = useCallback(
    // eslint-disable-next-line @typescript-eslint/require-await
    async (_: unknown, seed: string) => {
      if (!seed && required) {
        return;
      }

      validateSeedBase(seed, i18n);

      // to respect the clean arch this block should be extracted to the separate method
      if (expected?.xpub) {
        const xpub = toDerivedXpub(normalizeSeed(seed));
        if (xpub !== expected.xpub) {
          throw new Error(
            expected.message || i18n({ id: I18nFeatureDictionary.COMPONENTS_SEED_ITEM_ERROR_XPUB_INVALID }),
          );
        }
      }
    },
    [expected?.message, expected?.xpub, i18n, required],
  );

  return (
    <Form.Item<Values>
      required={required}
      label={messagesOrDefault.label}
      name={name}
      rules={[
        { validator: validateSeed },
        ...rules,
        ...(required ? [{ required: true, message: messagesOrDefault.required }] : []),
      ]}
      {...props}
    >
      <TextArea
        name="no-autocomplete"
        autoComplete="off"
        data-test={dataTest}
        rows={4}
        placeholder={messagesOrDefault.placeholder}
        className="ym-hide-content"
        data-gramm="false"
      />
    </Form.Item>
  );
};

const SeedItemMemo = React.memo(SeedItem) as typeof SeedItem;

export default SeedItemMemo;
