import { createAction, type PayloadAction } from '@reduxjs/toolkit';

import type { StoreMultipleActionPayload, StoreSingleActionPayload } from './types';

export interface SingleStateDirtyMarkUpdateAction<Id> {
  payload: Id;
}

export interface SingleStateMultipleDirtyMarksUpdateAction<Id> {
  payload: Id[];
}

export interface SingleStateRemoveAction<Id> {
  payload: Id;
}

export type SingleStateUpdateAction<Value, Id = string> = PayloadAction<StoreSingleActionPayload<Value, Id>>;

export interface MultipleStateUpdateAction<Value, Id = string> {
  payload: StoreMultipleActionPayload<Value, Id>;
}

// TODO: If the Value is object, the default Type should be name of this object - but how to realize it in TS?
export const createSingleActions = <Value, Type extends string, Id = string>(namespace: string, type: Type) => {
  const markDirtyName = `mark${type}Dirty` as const;
  const markMultipleDirtyName = `markMultiple${type}Dirty` as const;
  const storeName = `store${type}` as const;
  const storeMultipleName = `storeMultiple${type}` as const;
  const storeRemoveName = `storeRemove${type}` as const;

  const markDirty = createAction<SingleStateDirtyMarkUpdateAction<Id>['payload']>(`${namespace}/${markDirtyName}`);
  const markMultipleDirty = createAction<SingleStateMultipleDirtyMarksUpdateAction<Id>['payload']>(
    `${namespace}/${markMultipleDirtyName}`,
  );
  const store = createAction<SingleStateUpdateAction<Value, Id>['payload']>(`${namespace}/${storeName}`);
  const storeMultiple = createAction<MultipleStateUpdateAction<Value, Id>['payload']>(
    `${namespace}/${storeMultipleName}`,
  );
  const storeRemove = createAction<SingleStateRemoveAction<Id>['payload']>(`${namespace}/${storeRemoveName}`);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [markMultipleDirtyName]: markMultipleDirty,
    [storeName]: store,
    [storeMultipleName]: storeMultiple,
    [storeRemoveName]: storeRemove,
  } as Record<typeof markDirtyName, typeof markDirty> &
    Record<typeof markMultipleDirtyName, typeof markMultipleDirty> &
    Record<typeof storeName, typeof store> &
    Record<typeof storeMultipleName, typeof storeMultiple> &
    Record<typeof storeRemoveName, typeof storeRemove>;
};
