import { Space } from 'antd';
import React from 'react';

import type { ReportViewProps } from '@/features/reports/components';
import { ReportParametersView, ReportView } from '@/features/reports/components';

import { withReportDataLoading } from './hocs';

const ReportViewCardRaw = withReportDataLoading<ReportViewProps>((props) => (
  <Space direction="vertical">
    <ReportView {...props} columns={1} />
    <ReportParametersView value={props.data.parameters} template={props.data.template} data-test={props['data-test']} />
  </Space>
));

const ReportViewCard = React.memo(ReportViewCardRaw);

export default ReportViewCard;
