import dayjs from 'dayjs';
import { useEffect } from 'react';

import 'dayjs/locale/en';

import { useLocale } from '@/features/i18n/hooks';
import { I18nLocales } from '@/generated/i18n/i18n';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const dayjsLocale: Record<I18nLocales, string> = {
  [I18nLocales['en-US']]: 'en',
};

const withI18nDayjs = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithI18nDayjs',
  )((props) => {
    const { locale } = useLocale();
    useEffect(() => {
      dayjs.locale(dayjsLocale[locale]);
    }, [locale]);
    return <Component {...props} />;
  });

export default withI18nDayjs;
