import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import MerchantWalletRunningBalanceIcon from '@/features/merchant-wallet-balance/components/MerchantWalletRunningBalanceIcon';
import MerchantWalletTransferIcon from '@/features/merchant-wallet-transfers/components/MerchantWalletTransferIcon';
import { I18nMenu } from '@/generated/i18n/i18n';
import type { CustomizableRoute } from '@/layouts/types';
import AuditIcon from '@/pages/audit/components/AuditIcon';
import {
  AuditPageRoutes,
  auditRoute,
  auditWalletRunningBalancesLink,
  auditWalletTransfersLink,
} from '@/pages/audit/routes';
import { AppPageRoutes, appRoute } from '@/pages/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (selected: boolean, formatMessage: IntlFormattersArrow['formatMessage']): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.AUDIT_MAIN }),
    key: appRoute(AppPageRoutes.AUDIT),
    icon: <AuditIcon />,
    path: auditWalletTransfersLink(),
    hideChildrenInMenu: !selected,
    children: [
      {
        name: formatMessage({ id: I18nMenu.AUDIT_TRANSFERS }),
        icon: <MerchantWalletTransferIcon />,
        path: auditWalletTransfersLink(),
        hideChildrenInMenu: true,

        children: [AuditPageRoutes.TRANSFER_VIEW, AuditPageRoutes.TRANSFER_ENTITY]
          .map((route) => auditRoute(route))
          .map((path) => ({ path })),
      },
      {
        name: formatMessage({ id: I18nMenu.AUDIT_BALANCES }),
        icon: <MerchantWalletRunningBalanceIcon />,
        path: auditWalletRunningBalancesLink(),
        hideChildrenInMenu: true,

        children: [AuditPageRoutes.BALANCE_VIEW].map((route) => auditRoute(route)).map((path) => ({ path })),
      },
    ],
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.AUDIT));
  return useMemo(() => menuCreator(selected, formatMessage), [formatMessage, selected]);
}
