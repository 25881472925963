import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementsPendingIntentsLink } from '@/pages/settlements/routes';

import type { SettlementPendingIntentsLinkProps } from './types';

const SettlementPendingIntentsLink: React.FC<SettlementPendingIntentsLinkProps> = ({ title, ...props }) => (
  <Link
    to={settlementsPendingIntentsLink()}
    title={title || <FormattedMessage id={I18nPageSettlements.PENDING_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SettlementPendingIntentsLinkMemo = React.memo(SettlementPendingIntentsLink);

export default SettlementPendingIntentsLinkMemo;
