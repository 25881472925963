import React from 'react';

import { DateTimeRangeItem, FilterForm, FormattedMessage } from '@/components';
import { NetworkTypeItem } from '@/features/dictionary/blockchain/components';
import type { PaymentFilterPredicate } from '@/features/payments/types';
import { I18nFeaturePayments } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { PaymentStatusFilterItem } from './components';

import type { PaymentsFilterFormProps } from './types';

const fields = [
  nameof<PaymentFilterPredicate>('createdRange'),
  nameof<PaymentFilterPredicate>('statusIn'),
  nameof<PaymentFilterPredicate>('network'),
];

const dateTimeMessages = {
  to: { label: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_PAYMENT_FILTER_CREATED_AT_TO_LABEL} /> },
  from: { label: <FormattedMessage id={I18nFeaturePayments.COMPONENTS_PAYMENT_FILTER_CREATED_AT_FROM_LABEL} /> },
};

const PaymentsFilterForm: React.FC<PaymentsFilterFormProps> = ({ withNetwork, ...props }) => {
  const { 'data-test': dataTest, readonly } = props;
  return (
    <FilterForm fields={fields} {...props}>
      <DateTimeRangeItem<PaymentFilterPredicate>
        data-test={dataTest && `${dataTest}-createdAt`}
        name={nameof<PaymentFilterPredicate>('createdRange')}
        messages={dateTimeMessages}
        readonly={readonly}
        relativeUpdateKey="createdRangeRelative"
      />
      <PaymentStatusFilterItem<PaymentFilterPredicate>
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<PaymentFilterPredicate>('statusIn')}
        readonly={readonly}
      />
      <NetworkTypeItem<PaymentFilterPredicate>
        data-test={dataTest && `${dataTest}-network`}
        name={nameof<PaymentFilterPredicate>('network')}
        readonly={readonly}
        ItemProps={{ hidden: !withNetwork }}
      />
    </FilterForm>
  );
};

const PaymentsFilterFormMemo = React.memo(PaymentsFilterForm) as typeof PaymentsFilterForm;

export default PaymentsFilterFormMemo;
