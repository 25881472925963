import { useCallback, useMemo } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { updateWebhooks } from '@/features/company-settings/actions';
import type { NewWebhook, Webhook } from '@/features/company-settings/types';
import { createWebhookId } from '@/features/company-settings/utils';
import { useActionPending } from '@/features/global/hooks';
import type { HookAction } from '@/infrastructure/model';

import useWebhooks from '../useWebhooks';

export type WebhookActionUnavailability = 'no-data';

export interface UseWebhooksActionsType {
  addWebhook: HookAction<[NewWebhook], Webhook[], WebhookActionUnavailability>;
}

export default function useWebhooksActions(): UseWebhooksActionsType {
  const { withExtractDataDispatch } = useAppDispatch();
  const { data } = useWebhooks();

  const unavailabilityReason: WebhookActionUnavailability | undefined = !data.data ? 'no-data' : undefined;

  const updateWebhooksPending = useActionPending(updateWebhooks);
  const addWebhookAction: UseWebhooksActionsType['addWebhook']['act'] = useCallback(
    (newWebhook: NewWebhook) =>
      withExtractDataDispatch(updateWebhooks)([
        { ...newWebhook, id: createWebhookId(newWebhook) },
        ...(data.data ?? []),
      ]),
    [data.data, withExtractDataDispatch],
  );
  const addWebhooksHook: UseWebhooksActionsType['addWebhook'] = useMemo(
    () => ({
      act: addWebhookAction,
      available: !unavailabilityReason,
      inAction: updateWebhooksPending,
      unavailabilityReason,
    }),
    [addWebhookAction, unavailabilityReason, updateWebhooksPending],
  );

  return {
    addWebhook: addWebhooksHook,
  };
}
