import { PlayCircleOutlined } from '@ant-design/icons';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { useBrandingProfileActions } from '@/features/branding/hooks';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { useDefaultNotification, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';

import type { OperationActivateBrandingProfileProps } from './types';

const OperationActivateBrandingProfile: React.FC<OperationActivateBrandingProfileProps> = ({
  'data-test': dataTest,
  profileId,
  disabled,
  inProgress,
  onSuccess = noop,
  mode = 'inline',
}) => {
  const { activate } = useBrandingProfileActions(profileId);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const doActivateProfile = useMemo(
    () =>
      withSuccess(withDefaultError(withOnSuccess(activate.act, onSuccess)), () => (
        <FormattedMessage
          id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ACTIVATE_MESSAGE_SUCCESS}
        />
      )),
    [withSuccess, withDefaultError, activate.act, onSuccess],
  );

  const disabledMessage = useMemo(() => {
    if (disabled) {
      return undefined;
    }
    if (activate.unavailabilityReason === 'no-data') {
      return <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ERROR_NO_DATA} />;
    }
    if (activate.unavailabilityReason === 'already-active') {
      return (
        <FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ERROR_ALREADY_ACTIVE} />
      );
    }
    return undefined;
  }, [activate.unavailabilityReason, disabled]);

  return (
    <Operation
      title={<FormattedMessage id={I18nFeatureBranding.COMPONENTS_OPERATION_UPDATE_PROFILE_STATUS_ACTIVATE_TITLE} />}
      icon={<PlayCircleOutlined style={{ color: 'green' }} />}
      onClick={doActivateProfile}
      disabled={disabled || !!disabledMessage}
      disabledMessage={disabledMessage}
      inProgress={inProgress || activate.inAction}
      data-test={dataTest}
      mode={mode}
    />
  );
};

const OperationActivateBrandingProfileMemo = React.memo(OperationActivateBrandingProfile);

export default OperationActivateBrandingProfileMemo;
