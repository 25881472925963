import { useCallback, useMemo } from 'react';

import { DataFetchError, FormattedMessage, PageCard, PageContainer } from '@/components';
import { EditPayoutForm } from '@/features/payouts/components';
import { usePayout, usePayoutActions, usePayoutDestinations } from '@/features/payouts/hooks';
import type { NewPayout } from '@/features/payouts/types';
import { PayoutTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { useDefaultNotification, useFormPageAction, useNotification, useStateMountSafe } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import withPayoutPageParams from '@/pages/settlements/payouts/hocs/withPayoutPageParams';
import { settlementsPayoutsLink } from '@/pages/settlements/routes';

import { PayoutEditBreadcrumb } from './components';
import { useInitialValues } from './hooks';

import type React from 'react';

interface PayoutEditPageProps extends TestableProps {
  pageParams: { payoutId: string };
}

const PayoutEditPage: React.FC<PayoutEditPageProps> = ({
  'data-test': dataTest = 'payout-edit',
  pageParams: { payoutId },
}) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const payoutState = usePayout(payoutId);
  const destinationState = usePayoutDestinations(payoutId);
  const { update: updateAction } = usePayoutActions(payoutId);
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(settlementsPayoutsLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  const initialValues = useInitialValues(payoutState.data, destinationState.data);

  const doEdit = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(
            async ({ destinations }: NewPayout) => {
              await updateAction.act({
                destinations,
                mode: PayoutTypeAPIModel.MerkleTree,
              });
            },
            () => <FormattedMessage id={I18nPageSettlements.PAYOUT_EDIT_MESSAGES_SUCCESS_TITLE} />,
          ),
          () => <FormattedMessage id={I18nPageSettlements.PAYOUT_EDIT_MESSAGES_ERROR} />,
        ),
        doRedirect,
      ),
    [withDefaultError, withSuccess, updateAction, doRedirect],
  );
  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);
  const forceRefresh = useCallback(async () => {
    await Promise.all([payoutState.forceRefresh(), destinationState.forceRefresh()]);
  }, [payoutState, destinationState]);
  const loading = payoutState.loading || destinationState.loading;

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={
        <PayoutEditBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled payoutId={payoutId} />
      }
      submit={submit}
      cancel={doRedirect}
    >
      <PageCard loading={(!initialValues.data && initialValues.isDirty) || loading} cardSize="big">
        {
          /* eslint-disable-next-line no-nested-ternary */
          !initialValues.data ? (
            !initialValues.isDirty ? (
              <DataFetchError refresh={forceRefresh} back message={initialValues.error} />
            ) : null
          ) : (
            <EditPayoutForm
              initialValues={initialValues.data}
              data-test={dataTest && `${dataTest}-form`}
              onSubmit={doEdit}
              onReset={doRedirect}
              submitCallback={updateOnSubmit}
            />
          )
        }
      </PageCard>
    </PageContainer>
  );
};

export default withPayoutPageParams(PayoutEditPage);
