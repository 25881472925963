import { LoadingOutlined, ReloadOutlined, WarningOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React, { useMemo } from 'react';

import { FormattedMessage, Operation, WarningTooltip } from '@/components';
import { useTokenBalance } from '@/features/dictionary/blockchain/hooks';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import AssetAmount from '../AssetAmount';

import type { AddressTokenBalanceViewProps } from './types';

const AddressTokenBalanceView: React.FC<AddressTokenBalanceViewProps> = ({
  'data-test': dataTest,
  assetId,
  address,
  withCurrency,
  withBlockchainMark,
  currencyMode,
}) => {
  const id = useMemo(() => ({ assetId, address }), [address, assetId]);
  const { data: balance, forceRefresh } = useTokenBalance(id);
  const { withError } = useNotification();
  const reload = useMemo(
    () =>
      withSuppressError(
        withError(forceRefresh, () => (
          <FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_ERROR_MESSAGE} />
        )),
      ),
    [forceRefresh, withError],
  );
  if (balance.isDirty) {
    return <LoadingOutlined />;
  }
  return balance.data?.amount === undefined ? (
    <Space>
      <WarningTooltip
        title={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_BALANCE_NOT_FOUND_TITLE} />}
      >
        <WarningOutlined style={{ color: 'orange' }} />
      </WarningTooltip>
      <Operation
        title={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_TITLE} />}
        icon={<ReloadOutlined />}
        onClick={reload}
        data-test={dataTest}
        mode="icon"
      />
    </Space>
  ) : (
    <Space>
      <AssetAmount
        assetId={assetId}
        value={balance.data.amount}
        data-test={dataTest}
        withCurrency={withCurrency}
        currencyMode={currencyMode}
        withBlockchainMark={withBlockchainMark}
      />
      <Operation
        title={<FormattedMessage id={I18nFeatureDictionary.COMPONENTS_ADDRESS_TOKEN_BALANCE_REFRESH_BALANCE_TITLE} />}
        icon={<ReloadOutlined />}
        onClick={reload}
        data-test={dataTest}
        mode="icon"
      />
    </Space>
  );
};

const AddressTokenBalanceViewMemo = React.memo(AddressTokenBalanceView);

export default AddressTokenBalanceViewMemo;
