import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { useFeatureToggle } from '@/features/feature-toggle/hooks';
import type { FeatureFlags } from '@/features/feature-toggle/types';
import SubscriptionPlansIcon from '@/features/subscription-plans/components/SubscriptionPlansIcon';
import SubscriptionStatisticsIcon from '@/features/subscription-statistics/components/SubscriptionStatisticsIcon';
import SubscriptionsIcon from '@/features/subscriptions/components/SubscriptionsIcon';
import { I18nMenu } from '@/generated/i18n/i18n';
import { enumValues } from '@/infrastructure/utils/ts';
import type { CustomizableRoute } from '@/layouts/types';
import { AppPageRoutes, appRoute } from '@/pages/routes';
import {
  subscriptionPlanRoute,
  SubscriptionPlanRoutes,
  subscriptionPlansLink,
  subscriptionsLink,
  SubscriptionsPageRoutes,
  subscriptionsRoute,
  subscriptionStatisticsLink,
} from '@/pages/subscriptions/routes';

import type { IntlFormattersArrow } from 'react-intl';

const menuCreator = (
  selected: boolean,
  formatMessage: IntlFormattersArrow['formatMessage'],
  featureFlags: FeatureFlags | undefined,
): CustomizableRoute[] => [
  {
    name: formatMessage({ id: I18nMenu.SUBSCRIPTIONS_MAIN }),
    key: appRoute(AppPageRoutes.SUBSCRIPTIONS),
    icon: <SubscriptionsIcon />,
    path: subscriptionsLink(),
    hideChildrenInMenu: !selected,
    children: [
      {
        name: formatMessage({ id: I18nMenu.SUBSCRIPTIONS_SUBSCRIPTIONS }),
        icon: <SubscriptionsIcon />,
        path: subscriptionsLink(),
        hideChildrenInMenu: true,

        children: [SubscriptionsPageRoutes.CHARGE_VIEW, SubscriptionsPageRoutes.SUBSCRIPTION_VIEW]
          .map((route) => subscriptionsRoute(route))
          .map((path) => ({ path })),
      },
      {
        name: formatMessage({ id: I18nMenu.SUBSCRIPTIONS_PLANS }),
        icon: <SubscriptionPlansIcon />,
        path: subscriptionPlansLink(),
        hideChildrenInMenu: true,

        children: enumValues(SubscriptionPlanRoutes)
          .map((route) => subscriptionPlanRoute(route))
          .map((path) => ({ path })),
      },
      ...(featureFlags?.enableSubscriptionStatistics
        ? [
            {
              name: formatMessage({ id: I18nMenu.SUBSCRIPTIONS_STATISTICS }),
              icon: <SubscriptionStatisticsIcon />,
              path: subscriptionStatisticsLink(),
              hideChildrenInMenu: true,
            },
          ]
        : []),
    ],
  },
];

export default function useMenuData(): CustomizableRoute[] {
  const {
    data: { data: featureFlags },
  } = useFeatureToggle();
  const { pathname } = useLocation();
  const { formatMessage } = useIntl();
  const selected = pathname.startsWith(appRoute(AppPageRoutes.SUBSCRIPTIONS));
  return useMemo(() => menuCreator(selected, formatMessage, featureFlags), [featureFlags, formatMessage, selected]);
}
