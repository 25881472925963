import { QuestionCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import type { PaymentsBranding } from '@/features/branding/types';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import type { RedirectParamItemProps } from './types';
import type React from 'react';

const RedirectParamItem: React.FC<RedirectParamItemProps> = ({ 'data-test': dataTest, disabled }) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<PaymentsBranding>
      data-test={dataTest}
      name={nameof<PaymentsBranding>('redirectParam')}
      placeholder={formatMessage({
        id: I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_PARAM_ITEM_PLACEHOLDER,
      })}
      readonly={disabled}
      rules={[
        {
          type: 'string',
          pattern: /^[\w\-_]+$/,
          message: (
            <FormattedMessage
              id={
                I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_PARAM_ITEM_ERRORS_INVALID_FORMAT
              }
            />
          ),
        },
      ]}
      ItemProps={{ label: null }}
      InputProps={{
        suffix: (
          <Tooltip
            title={
              <FormattedMessage
                id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_CONTACTS_BLOCK_REDIRECT_PARAM_ITEM_HELP}
              />
            }
          >
            <QuestionCircleOutlined />
          </Tooltip>
        ),
      }}
    />
  );
};

export default RedirectParamItem;
