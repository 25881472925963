import { Space } from 'antd';
import { useCallback } from 'react';

import { FormattedMessage, OperationRefresh } from '@/components';
import { useSubscriptionCharge } from '@/features/subscription-charges/hooks';
import { SubscriptionsDocumentationLink } from '@/features/subscriptions/components';
import { useSubscription } from '@/features/subscriptions/hooks';
import type { Subscription } from '@/features/subscriptions/types';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { withCardDataLoading } from '@/hocs';
import type { TestableProps } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionDataLoading =
  <T extends { data: Subscription } & TestableProps>(
    Component: React.ComponentType<T>,
  ): React.FC<Omit<T, 'data'> & { subscriptionChargeId: string }> =>
  (props) => {
    const chargeState = useSubscriptionCharge(props.subscriptionChargeId);
    const subscription = useSubscription(chargeState.data.data?.contractAddress);
    const forceRefresh = useCallback(
      async () => Promise.all([subscription.forceRefresh(), chargeState.forceRefresh()]),
      [chargeState, subscription],
    );
    const dataTest = props['data-test'];
    return withCardDataLoading<Subscription, T>({
      data: subscription.data,
      loading: subscription.loading || chargeState.loading,
      forceRefresh,
      hideBack: true,
      'data-test': dataTest,
      title: (
        <FormattedMessage
          id={I18nPageSubscriptions.CHARGE_VIEW_COMPONENTS_SUBSCRIPTION_CARD_TITLE}
          values={{ id: chargeState.data.data?.contractAddress }}
        />
      ),
      CardProps: {
        extra: (
          <Space>
            <SubscriptionsDocumentationLink data-test={dataTest && `${dataTest}-documentationLink`} mode="text_icon" />
            <OperationRefresh data-test={dataTest && `${dataTest}-opRefresh`} refresh={forceRefresh} />
          </Space>
        ),
      },
    })(Component)(props);
  };

export default withSubscriptionDataLoading;
