import React from 'react';

import type { SubscriptionPlan } from '@/features/subscription-plans/types';
import { SubscriptionView, type SubscriptionViewProps } from '@/features/subscriptions/components';
import type { Subscription } from '@/features/subscriptions/types';
import SubscriptionPlanLink from '@/pages/subscriptions/plan-view/components/SubscriptionPlanLink';

import { withSubscriptionDataLoading } from './hocs';

const SubscriptionCard: React.FC<
  Omit<SubscriptionViewProps, 'plan' | 'data'> & {
    data: { subscription: Subscription; plan: SubscriptionPlan };
  }
> = ({ data, ...props }) => (
  <SubscriptionView
    {...props}
    data={data.subscription}
    plan={data.plan}
    columns={1}
    planLink="link"
    SubscriptionPlanLink={SubscriptionPlanLink}
  />
);

const SubscriptionCardLoaded = withSubscriptionDataLoading(SubscriptionCard);

const SubscriptionCardMemo = React.memo(SubscriptionCardLoaded) as typeof SubscriptionCardLoaded;

export default SubscriptionCardMemo;
