/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DaySalesAPIModel
 */
export interface DaySalesAPIModel {
    /**
     * 
     * @type {string}
     * @memberof DaySalesAPIModel
     */
    salesVolume: string;
}

/**
 * Check if a given object implements the DaySalesAPIModel interface.
 */
export function instanceOfDaySalesAPIModel(value: object): boolean {
    if (!('salesVolume' in value)) return false;
    return true;
}

export function DaySalesAPIModelFromJSON(json: any): DaySalesAPIModel {
    return DaySalesAPIModelFromJSONTyped(json, false);
}

export function DaySalesAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): DaySalesAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'salesVolume': json['salesVolume'],
    };
}

export function DaySalesAPIModelToJSON(value?: DaySalesAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'salesVolume': value['salesVolume'],
    };
}

