import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureDonations } from '@/generated/i18n/i18n';

import type { DonationTitleItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  required: <FormattedMessage id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_ERRORS_REQUIRED} />,
  label: <FormattedMessage id={I18nFeatureDonations.LABELS_DONATION_ROW_TITLE_TITLE} />,
};
const maxTitleLength = 512;
const minTitleLength = 4;

const rules = [
  {
    transform: (value?: string) => value?.trim(),
    max: maxTitleLength,
    message: (
      <FormattedMessage
        id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_ERRORS_MAX_LENGTH}
        values={{ val: maxTitleLength }}
      />
    ),
  },
  {
    transform: (value?: string) => value?.trim(),
    min: minTitleLength,
    message: (
      <FormattedMessage
        id={I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_ERRORS_MIN_LENGTH}
        values={{ val: minTitleLength }}
      />
    ),
  },
];

const DonationTitleItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  required,
  readonly,
}: DonationTitleItemProps<Values>) => {
  const { formatMessage: i18n } = useIntl();
  return (
    <InputItem<Values>
      name={name}
      data-test={dataTest}
      readonly={readonly}
      required={required}
      placeholder={i18n({ id: I18nFeatureDonations.COMPONENTS_EDIT_DONATION_FORM_TITLE_ITEM_PLACEHOLDER })}
      messages={messages}
      rules={rules}
    />
  );
};

const DonationTitleItemMemo = React.memo(DonationTitleItem);

export default DonationTitleItemMemo;
