/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewPayoutBatchAPIModel
 */
export interface NewPayoutBatchAPIModel {
    /**
     * 
     * @type {string}
     * @memberof NewPayoutBatchAPIModel
     */
    proof: string;
    /**
     * 
     * @type {string}
     * @memberof NewPayoutBatchAPIModel
     */
    adjacentProof: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof NewPayoutBatchAPIModel
     */
    destinations?: Array<number>;
}

/**
 * Check if a given object implements the NewPayoutBatchAPIModel interface.
 */
export function instanceOfNewPayoutBatchAPIModel(value: object): boolean {
    if (!('proof' in value)) return false;
    if (!('adjacentProof' in value)) return false;
    return true;
}

export function NewPayoutBatchAPIModelFromJSON(json: any): NewPayoutBatchAPIModel {
    return NewPayoutBatchAPIModelFromJSONTyped(json, false);
}

export function NewPayoutBatchAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewPayoutBatchAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'proof': json['proof'],
        'adjacentProof': json['adjacentProof'],
        'destinations': json['destinations'] == null ? undefined : json['destinations'],
    };
}

export function NewPayoutBatchAPIModelToJSON(value?: NewPayoutBatchAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'proof': value['proof'],
        'adjacentProof': value['adjacentProof'],
        'destinations': value['destinations'],
    };
}

