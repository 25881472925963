import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchTokenBalance } from '@/features/dictionary/blockchain/actions';
import { makeSelectTokenBalance } from '@/features/dictionary/blockchain/selectors';
import type { BalanceData, AssetAddress, FetchAssetAddressDataPayload } from '@/features/dictionary/blockchain/types';
import { createAssetAddressKey } from '@/features/dictionary/blockchain/utils';
import { makeSelectPending } from '@/features/global/selectors';
import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDataLoaded } from '@/infrastructure/model';

export type UseSingleTokenBalanceType = UseAppSingleData<BalanceData>;

const noData = storedDataLoaded<BalanceData>();
const fetchFactory = (
  id: AssetAddress,
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<BalanceData>, FetchAssetAddressDataPayload> =>
  fetchTokenBalance({ ...id, force });

export default function useTokenBalance(id: AssetAddress | undefined): UseSingleTokenBalanceType {
  return useAppSingleData(
    fetchFactory,
    makeSelectTokenBalance,
    noData,
    id,
    useMemo(() => makeSelectPending(fetchTokenBalance, id && createAssetAddressKey(id)), [id]),
  );
}
