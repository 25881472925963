import { ProLayout } from '@ant-design/pro-components';
import { css } from '@emotion/css';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/app/hooks';
import Logo from '@/assets/icons/DefaultLogo';
import { makeSelectViewHeight } from '@/features/global/selectors';
import { I18nCommon } from '@/generated/i18n/i18n';
import type { TestableProps } from '@/infrastructure/utils/react';
import {
  AvatarDropdown,
  AvatarTitle,
  BackgroundTasksDropdown,
  SelectLang,
  UserAlertsDropdown,
} from '@/layouts/components';
import { layoutBgToken } from '@/layouts/theme';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type OnBoardLayoutProps = PropsWithChildren<TestableProps>;

const selectViewHeight = makeSelectViewHeight();

const OnBoardLayout: React.FC<OnBoardLayoutProps> = ({ 'data-test': dataTest, children }) => {
  const { formatMessage } = useIntl();
  const height = useAppSelector(selectViewHeight);
  return (
    <ProLayout
      layout="top"
      disableMobile
      title={formatMessage({ id: I18nCommon.TITLE })}
      logo={<Logo width={24} />}
      token={layoutBgToken}
      className={css`
        height: ${100 * height}px;

        .ant-pro-top-nav-header-main {
          padding-inline-end: 16px;
        }
      `}
      avatarProps={useMemo(
        () => ({
          title: <AvatarTitle data-test={dataTest && `${dataTest}-avatar`} />,
          render: (_, dom) => (
            <AvatarDropdown data-test={dataTest && `${dataTest}-avatarMenu`} title={dom} simplified />
          ),
        }),
        [dataTest],
      )}
      actionsRender={useCallback(
        () => [
          <UserAlertsDropdown key="alerts" data-test={dataTest && `${dataTest}-alerts`} />,
          <BackgroundTasksDropdown key="tasks" data-test={dataTest && `${dataTest}-tasks`} />,
          <SelectLang key="lang" data-test={dataTest && `${dataTest}-lang`} />,
        ],
        [dataTest],
      )}
    >
      {children}
    </ProLayout>
  );
};

export default OnBoardLayout;
