import React from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from '@/app/hooks';
import { FormattedMessage, DateTimeRangeItem, InputItem, FilterForm } from '@/components';
import { BlockchainItem } from '@/features/dictionary/blockchain/components';
import { makeSelectSelectedNetwork } from '@/features/dictionary/blockchain/selectors';
import { SubscriptionPlanItem } from '@/features/subscription-plans/components/SubscriptionPlanEditForm/components';
import type { SubscriptionFilterPredicate } from '@/features/subscriptions/types';
import { BlockchainAPITypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { I18nFeatureSubscriptions } from '@/generated/i18n/i18n';
import { nameof } from '@/infrastructure/utils/ts';

import { SubscriptionStatusFilterItem } from './components';

import type { SubscriptionFilterFormProps } from './types';

const networkSelector = makeSelectSelectedNetwork();

const fields = [
  nameof<SubscriptionFilterPredicate>('contractAddress'),
  nameof<SubscriptionFilterPredicate>('payer'),
  nameof<SubscriptionFilterPredicate>('blockchain'),
  nameof<SubscriptionFilterPredicate>('planId'),
  nameof<SubscriptionFilterPredicate>('statusIn'),
  nameof<SubscriptionFilterPredicate>('createdAt'),
];

const createdAtMessages = {
  from: {
    label: <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_FILTER_FORM_CREATED_AT_FROM_LABEL} />,
  },
  to: {
    label: <FormattedMessage id={I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_FILTER_FORM_CREATED_AT_TO_LABEL} />,
  },
};

const payerMessages = {
  label: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_PAYER} />,
};

const addressMessages = {
  label: <FormattedMessage id={I18nFeatureSubscriptions.LABELS_ROW_TITLE_ADDRESS} />,
};

const SubscriptionFilterForm: React.FC<SubscriptionFilterFormProps<SubscriptionFilterPredicate>> = (props) => {
  const { 'data-test': dataTest, hidden, readonly } = props;
  const network = useAppSelector(networkSelector);
  const { formatMessage } = useIntl();

  return (
    <FilterForm<SubscriptionFilterPredicate> fields={fields} {...props}>
      <InputItem<SubscriptionFilterPredicate>
        name={nameof<SubscriptionFilterPredicate>('contractAddress')}
        data-test={dataTest && `${dataTest}-contractAddress`}
        placeholder={formatMessage({
          id: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_FILTER_FORM_ADDRESS_PLACEHOLDER,
        })}
        messages={addressMessages}
        readonly={readonly}
      />
      <InputItem<SubscriptionFilterPredicate>
        name={nameof<SubscriptionFilterPredicate>('payer')}
        data-test={dataTest && `${dataTest}-payer`}
        placeholder={formatMessage({
          id: I18nFeatureSubscriptions.COMPONENTS_SUBSCRIPTION_FILTER_FORM_PAYER_PLACEHOLDER,
        })}
        messages={payerMessages}
        readonly={readonly}
      />
      <BlockchainItem<SubscriptionFilterPredicate>
        name={nameof<SubscriptionFilterPredicate>('blockchain')}
        data-test={dataTest && `${dataTest}-blockchain`}
        network={network}
        blockchainApis={[BlockchainAPITypeAPIModel.WEB3]}
        readonly={readonly}
        required={false}
      />
      {!hidden?.plan && (
        <SubscriptionPlanItem
          data-test={dataTest && `${dataTest}-plan`}
          name={nameof<SubscriptionFilterPredicate>('planId')}
          readonly={readonly}
        />
      )}
      <SubscriptionStatusFilterItem<SubscriptionFilterPredicate>
        data-test={dataTest && `${dataTest}-status`}
        name={nameof<SubscriptionFilterPredicate>('statusIn')}
        multiple
        readonly={readonly}
        required={false}
      />
      <DateTimeRangeItem<SubscriptionFilterPredicate>
        data-test={dataTest && `${dataTest}-createdAt`}
        name={nameof<SubscriptionFilterPredicate>('createdAt')}
        messages={createdAtMessages}
        readonly={readonly}
        required={false}
      />
    </FilterForm>
  );
};

const SubscriptionFilterFormMemo = React.memo(SubscriptionFilterForm) as typeof SubscriptionFilterForm;

export default SubscriptionFilterFormMemo;
