import pLimit from 'p-limit';

import { createAppAsyncThunk } from '@/app/actions';
import { markBalancesDirty } from '@/features/statistics/actions';
import type { PushAddressLinkAPIModel, PushCollectSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { defaultPageFn, withAPICall } from '@/infrastructure/api';
import { loadingDataLoaded, mapLoadingState } from '@/infrastructure/model';
import { createLoadingDataActions } from '@/infrastructure/model/common/actions';
import { createNestedListActions, createNormalizedListActions } from '@/infrastructure/model/list/actions';
import {
  listStateToSliceRequest,
  mapLoadingSliceStateToListData,
  sliceToMultipleEntities,
} from '@/infrastructure/model/list/utils';
import { createSingleActions } from '@/infrastructure/model/single/actions';
import { toMultiplePayload } from '@/infrastructure/model/single/utils';
import { identity } from '@/infrastructure/utils/functions';

import {
  queryCollectTask,
  queryCollectTasks,
  queryCollectSchedule,
  requestDeleteCollectSchedule,
  requestCollectNow,
  requestUpdateCollectSchedule,
  queryCollectTaskProcessTransaction,
} from './api';
import {
  makeSelectCollectTask,
  makeSelectCollectSchedule,
  makeSelectMultipleCollectTaskSummary,
  makeSelectDirtyCollectTaskSummaryIds,
  makeSelectCollectTaskSummaryListData,
  makeSelectCollectTaskSummaryListParameters,
  makeSelectCollectEntityProcessTransaction,
  makeSelectCollectTasksForAddressesListData,
  makeSelectCollectTasksForAddressesListParameters,
} from './selectors';
import { NAMESPACE } from './types';
import { collectableTaskLinksToId, extractCollectTaskId } from './utils';

import type {
  CollectTaskSummary,
  CollectTaskFilterPredicate,
  CollectableEntityProcessTransaction,
  CollectableEntityTransaction,
  CollectTask,
  CollectSchedule,
  CollectScheduleUpdate,
} from './types';

export const { storeCollectTask, markCollectTaskDirty } = createSingleActions<CollectTask, 'CollectTask'>(
  NAMESPACE,
  'CollectTask',
);

export const { storeCollectEntityProcessTransaction, markCollectEntityProcessTransactionDirty } = createSingleActions<
  CollectableEntityProcessTransaction,
  'CollectEntityProcessTransaction'
>(NAMESPACE, 'CollectEntityProcessTransaction');

export const {
  storeCollectTaskSummary,
  storeCollectTaskSummaryListData,
  storeMultipleCollectTaskSummary,
  storeCollectTaskSummaryListParameters,
  markCollectTaskSummaryDirty,
  markCollectTaskSummaryListDirty,
} = createNormalizedListActions<
  CollectTaskSummary,
  'CollectTaskSummary',
  CollectTaskFilterPredicate,
  PushCollectSortByAPIModel
>(NAMESPACE, 'CollectTaskSummary');

export const { storeCollectableTransaction, storeMultipleCollectableTransaction, markCollectableTransactionDirty } =
  createSingleActions<CollectableEntityTransaction, 'CollectableTransaction'>(NAMESPACE, 'CollectableTransaction');

const collectTaskFetchLimit = pLimit(1);
export const fetchCollectTask = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectTask`,
  async ({ force, id }: { force?: boolean; id: string }, { dispatch, getState, signal }) =>
    collectTaskFetchLimit(async () => {
      const saved = makeSelectCollectTask(id)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTask, 'unable to fetch collect task')(id, { signal });
      dispatch(storeCollectTask({ id, data }));
      return makeSelectCollectTask(id)(getState());
    }),
  { idGenerator: extractCollectTaskId },
);

const collectTaskProcessTransactionFetchLimit = pLimit(1);
export const fetchCollectTaskProcessTransaction = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectableTransaction`,
  async ({ force, taskId }: { force?: boolean; taskId: string }, { dispatch, getState, signal }) =>
    collectTaskProcessTransactionFetchLimit(async () => {
      const saved = makeSelectCollectEntityProcessTransaction(taskId)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTaskProcessTransaction, 'unable to fetch process transaction')(
        taskId,
        {
          signal,
        },
      );
      dispatch(storeCollectEntityProcessTransaction({ id: taskId, data }));

      return makeSelectCollectEntityProcessTransaction(taskId)(getState());
    }),
);

const collectTaskSummariesFetchLimit = pLimit(1);
export const fetchCollectTaskSummaries = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectTaskSummaries`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    collectTaskSummariesFetchLimit(async () => {
      const saved = makeSelectCollectTaskSummaryListData()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTasks, 'unable to fetch collect tasks')(
        listStateToSliceRequest({ data: saved, ...makeSelectCollectTaskSummaryListParameters()(getState()) }),
        { signal },
      );
      dispatch(storeCollectTaskSummaryListData(mapLoadingSliceStateToListData(saved.data?.total)(data)));

      return makeSelectCollectTaskSummaryListData()(getState());
    }),
);

const multipleCollectTaskSummaryFetchLimit = pLimit(1);
export const fetchMultipleCollectTaskSummary = createAppAsyncThunk(
  `${NAMESPACE}/fetchMultipleCollectTaskSummary`,
  async ({ force, ids }: { force?: boolean; ids: string[] }, { dispatch, getState, signal }) =>
    multipleCollectTaskSummaryFetchLimit(async () => {
      const absent = makeSelectDirtyCollectTaskSummaryIds(ids)(getState());
      if (!force && !absent.length) {
        return makeSelectMultipleCollectTaskSummary(ids)(getState());
      }

      const data = await withAPICall(queryCollectTasks, 'unable to fetch collect tasks')(
        { filter: { idIn: ids }, page: defaultPageFn({ perPage: ids.length }) },
        { signal },
      );
      dispatch(
        storeMultipleCollectTaskSummary(
          toMultiplePayload(
            mapLoadingState(data, ({ list }) => list),
            ids,
            extractCollectTaskId,
            identity,
          ),
        ),
      );

      return makeSelectMultipleCollectTaskSummary(ids)(getState());
    }),
);

export const {
  storeCollectTasksForAddressesListData,
  storeCollectTasksForAddressesListParameters,
  markCollectTasksForAddressesListDirty,
} = createNestedListActions<
  CollectTaskSummary,
  'CollectTasksForAddresses',
  CollectTaskFilterPredicate,
  PushCollectSortByAPIModel,
  PushAddressLinkAPIModel[]
>(NAMESPACE, 'CollectTasksForAddresses');

const settlementsPerAssetFetchLimit = pLimit(1);
export const fetchCollectTasksForAddresses = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectTasksForAddresses`,
  async (
    { force, addresses }: { force?: boolean; addresses: PushAddressLinkAPIModel[] },
    { dispatch, getState, signal },
  ) =>
    settlementsPerAssetFetchLimit(async () => {
      const saved = makeSelectCollectTasksForAddressesListData(addresses)(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectTasks, 'unable to fetch tasks')(
        listStateToSliceRequest({
          data: saved,
          ...makeSelectCollectTasksForAddressesListParameters(addresses)(getState()),
        }),
        { signal },
      );
      dispatch(
        storeCollectTasksForAddressesListData({
          parentId: addresses,
          data: mapLoadingSliceStateToListData(saved.data?.total)(data),
        }),
      );
      if (data.data) {
        dispatch(storeMultipleCollectTaskSummary(sliceToMultipleEntities(data.data, extractCollectTaskId)));
      }

      return makeSelectCollectTasksForAddressesListData(addresses)(getState());
    }),
  { idGenerator: ({ addresses }) => collectableTaskLinksToId(addresses) },
);

export const { storeCollectSchedule, markCollectScheduleDirty } = createLoadingDataActions<
  CollectSchedule,
  'CollectSchedule'
>(NAMESPACE, 'CollectSchedule');

const collectableScheduleFetchLimit = pLimit(1);
export const fetchCollectSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectSchedule`,
  async ({ force }: { force?: boolean }, { dispatch, getState, signal }) =>
    collectableScheduleFetchLimit(async () => {
      const saved = makeSelectCollectSchedule()(getState());
      if (!force && !saved.isDirty) {
        return saved;
      }

      const data = await withAPICall(queryCollectSchedule, 'unable to fetch collect schedule')({ signal });
      dispatch(storeCollectSchedule(data));

      return makeSelectCollectSchedule()(getState());
    }),
);

export const updateCollectSchedule = createAppAsyncThunk(
  `${NAMESPACE}/fetchCollectSchedule`,
  async (newSchedule: CollectScheduleUpdate | undefined, { dispatch, signal }) => {
    const data = await (newSchedule
      ? withAPICall(requestUpdateCollectSchedule, 'unable to update the collectable schedule')(newSchedule, {
          signal,
        })
      : withAPICall(requestDeleteCollectSchedule, 'unable to remove the collectable schedule')({ signal }));

    const schedule = data.data;
    if (schedule) {
      dispatch(storeCollectSchedule(loadingDataLoaded(schedule)));
      return loadingDataLoaded(schedule);
    } else {
      return dispatch(fetchCollectSchedule({ force: true })).unwrap();
    }
  },
);

export const collectNow = createAppAsyncThunk(
  `${NAMESPACE}/collectNow`,
  async ({ asset }: { asset: string }, { dispatch, signal }) => {
    const data = await withAPICall(requestCollectNow, 'unable to update trigger the collectable')(asset, { signal });

    if (!data.error) {
      dispatch(markCollectTaskSummaryListDirty());
      dispatch(markBalancesDirty());
    }

    return data;
  },
);
