import React from 'react';

import { Link, FormattedMessage } from '@/components';
import { I18nPageSubscriptions } from '@/generated/i18n/i18n';
import { subscriptionStatisticsLink } from '@/pages/subscriptions/routes';

import type { SubscriptionStatisticsLinkProps } from './types';

const SubscriptionStatisticsLink: React.FC<SubscriptionStatisticsLinkProps> = ({
  'data-test': dataTest,
  title,
  ...props
}) => (
  <Link
    to={subscriptionStatisticsLink()}
    data-test={dataTest}
    title={title || <FormattedMessage id={I18nPageSubscriptions.STATISTICS_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SubscriptionStatisticsLinkMemo = React.memo(SubscriptionStatisticsLink);

export default SubscriptionStatisticsLinkMemo;
