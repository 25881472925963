import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import BrandingProfileAddLink from '@/pages/settings/branding/profile-add/components/BrandingProfileAddLink';
import BrandingProfilesLink from '@/pages/settings/branding/profiles/components/BrandingProfilesLink';

import type { InactiveProfileDescriptionProps } from './types';

const InactiveProfileDescription: React.FC<InactiveProfileDescriptionProps> = ({ 'data-test': dataTest, profiles }) =>
  profiles?.length ? (
    <FormattedMessage
      id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_NO_ACTIVE}
      values={{
        op: (text: React.ReactNode) => (
          <BrandingProfilesLink data-test={dataTest && `${dataTest}-viewOp`} mode="text" title={text} />
        ),
      }}
    />
  ) : (
    <FormattedMessage
      id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_EMPTY}
      values={{
        op: (text: React.ReactNode) => (
          <BrandingProfileAddLink data-test={dataTest && `${dataTest}-createOp`} mode="link" title={text} />
        ),
      }}
    />
  );

const InactiveProfileDescriptionMemo = React.memo(InactiveProfileDescription);

export default InactiveProfileDescriptionMemo;
