import { EditOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useMemo } from 'react';

import { defaultDialogFormLayout, defaultDialogFormTailLayout, FormattedMessage, Operation } from '@/components';
import { useCompanyActions } from '@/features/company/hooks';
import { I18nFeatureCompany } from '@/generated/i18n/i18n';
import { useDefaultNotification, useFormVisible, useNotification } from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import { goalReached, YMGoals } from '@/infrastructure/ym';

import { UpdateCompanyNameForm } from './components';

import type { OperationUpdateCompanyNameProps } from './types';

const OperationUpdateCompanyName: React.FC<OperationUpdateCompanyNameProps> = ({
  'data-test': dataTest,
  disabled,
  inProgress,
  onSuccess,
  mode = 'inline',
}) => {
  const { updateName: updateNameAction, data: company } = useCompanyActions();
  const formControl = useFormVisible(false);
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();

  const updateName = useMemo(
    () =>
      withDefaultError(
        withSuccess(
          formControl.withFormHide(
            withOnSuccess(async (name: string) => {
              const result = await updateNameAction.act({ name });
              goalReached(YMGoals.COMPANY_NAME_UPDATED);
              return result;
            }, onSuccess ?? noop),
          ),
          () => ({
            message: (
              <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_UPDATE_NAME_MESSAGE_SUCCESS_TITLE} />
            ),
          }),
        ),
        () => <FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_UPDATE_NAME_MESSAGE_ERROR} />,
      ),
    [formControl, onSuccess, updateNameAction, withDefaultError, withSuccess],
  );

  const showForm = useMemo(
    () => withOnSuccess(formControl.show, () => goalReached(YMGoals.COMPANY_NAME_UPDATE_INIT)),
    [formControl.show],
  );

  return (
    <>
      {formControl.visible && (
        <Modal
          open
          maskClosable={false}
          footer={null}
          title={<FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_UPDATE_NAME_FORM_TITLE} />}
          closable
          onCancel={formControl.hide}
        >
          <UpdateCompanyNameForm
            values={{ name: company?.name }}
            layout={defaultDialogFormLayout}
            tailLayout={defaultDialogFormTailLayout}
            data-test={dataTest && `${dataTest}-form`}
            onReset={formControl.hide}
            onSubmit={updateName}
          />
        </Modal>
      )}
      <Operation
        title={<FormattedMessage id={I18nFeatureCompany.COMPONENTS_OPERATION_UPDATE_NAME_LABEL} />}
        icon={<EditOutlined />}
        onClick={showForm}
        disabled={disabled || !updateNameAction.available}
        inProgress={inProgress || formControl.visible || updateNameAction.inAction}
        data-test={dataTest}
        mode={mode}
      />
    </>
  );
};

const OperationUpdateCompanyNameMemo = React.memo(OperationUpdateCompanyName);

export default OperationUpdateCompanyNameMemo;
