import { css, cx } from '@emotion/css';
import React, { useMemo } from 'react';

import { ReadonlyComponent } from '@/components';
import { useLocaleSettings } from '@/hooks';

import type { MoneyAmountProps } from './types';

const MoneyAmount: React.FC<MoneyAmountProps> = ({
  'data-test': dataTest,
  value,
  fraction,
  noStyle,
  className,
  style,
}) => {
  const { formatBigNumber } = useLocaleSettings();
  const formattedValue = useMemo(() => formatBigNumber(value, fraction), [formatBigNumber, fraction, value]);
  return noStyle ? (
    formattedValue
  ) : (
    <ReadonlyComponent
      data-test={dataTest}
      ellipsis={{ tooltip: formattedValue }}
      copyable={false}
      style={style}
      nonStretches
      className={cx(
        'ym-hide-content',
        className,
        css`
          flex-shrink: 0;
          line-height: inherit;
          height: inherit;
        `,
      )}
      value={formattedValue}
    />
  );
};

const MoneyAmountMemo = React.memo(MoneyAmount);

export default MoneyAmountMemo;
