import type { Subscription } from '@/features/subscriptions/types';
import type { SubscriptionStatusAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const withSubscriptionDataLoading =
  <T extends { data: Subscription }>(expectedStatuses: SubscriptionStatusAPIModel[]) =>
  (Component: React.ComponentType<T>): React.FC<T> =>
    namedHOC(
      Component,
      'WithSubscriptionDataLoading',
    )((props: T) => (expectedStatuses.includes(props.data.status) ? <Component {...props} /> : null));

export default withSubscriptionDataLoading;
