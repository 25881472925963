import { AppPageRoutes, appRoute } from '@/pages/routes';

export enum DonationsPageRoutes {
  DONATIONS = 'donations',
  DONATION_NEW = 'donations/new',
  DONATION_VIEW = 'donations/view/:donationId',
  DONATION_EDIT = 'donations/edit/:donationId',
  DONATION_ASSET_VIEW = 'donations/view/:donationId/asset/:asset',
  TRANSACTIONS = 'transactions',
  TRANSACTION_VIEW = 'transactions/:transactionId',
}

export const donationsRoute = (page: DonationsPageRoutes) => `${appRoute(AppPageRoutes.DONATIONS)}/${page}`;

export const donationsLink = () => donationsRoute(DonationsPageRoutes.DONATIONS);

export const donationAddLink = () => `${donationsLink()}/new`;
export const donationEditLink = (donationId: string) => `${donationsLink()}/edit/${donationId}`;
export const donationViewLink = (donationId: string) => `${donationsLink()}/view/${donationId}`;

export const donationAssetViewLink = (donationId: string, asset: string) =>
  `${donationsLink()}/view/${donationId}/asset/${asset}`;

export const donationTransactionsLink = () => donationsRoute(DonationsPageRoutes.TRANSACTIONS);
export const donationTransactionViewLink = (txId: string) => `${donationTransactionsLink()}/${txId}`;
