import { QuestionCircleOutlined } from '@ant-design/icons';
import { Space, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputNumberItem } from '@/components';
import { I18nFeatureSettlements } from '@/generated/i18n/i18n';

import type { SettlementScheduleAmountInputItemProps } from './types';
import type { Rule, Store } from 'rc-field-form/es/interface';

const messages = {
  label: (
    <Space>
      <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_LABEL} />
      <Tooltip
        title={<FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_HELP} />}
      >
        <QuestionCircleOutlined />
      </Tooltip>
    </Space>
  ),
  required: <FormattedMessage id={I18nFeatureSettlements.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_REQUIRED} />,
};

const SettlementScheduleAmountInputItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  ItemProps = {},
}: SettlementScheduleAmountInputItemProps<Values>) => {
  const { formatMessage } = useIntl();
  const rules = useMemo<Rule[]>(
    () => [
      {
        // eslint-disable-next-line @typescript-eslint/require-await
        validator: async (_, value: number) => {
          if (value < 50) {
            throw new Error(
              formatMessage(
                { id: I18nFeatureSettlements.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_MIN },
                { amount: 50 },
              ),
            );
          }
        },
      },
    ],
    [formatMessage],
  );

  const InputProps = useMemo(
    () => ({
      placeholder: formatMessage({
        id: I18nFeatureSettlements.COMPONENTS_OPERATION_SET_SCHEDULE_FORM_AMOUNT_PLACEHOLDER,
      }),
    }),
    [formatMessage],
  );

  return (
    <InputNumberItem<Values>
      data-test={dataTest && `${dataTest}-amount`}
      name={name}
      rules={rules}
      messages={messages}
      InputProps={InputProps}
      {...ItemProps}
    />
  );
};

const SettlementScheduleAmountInputItemMemo = React.memo(
  SettlementScheduleAmountInputItem,
) as typeof SettlementScheduleAmountInputItem;

export default SettlementScheduleAmountInputItemMemo;
