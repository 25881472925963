import React from 'react';

import { OperationRefresh } from '@/components';
import { useMerchantGasWallets } from '@/features/gas-wallets/hooks';

import type { OperationRefreshMerchantGasWalletsProps } from './types';

const OperationRefreshMerchantGasWallets: React.FC<OperationRefreshMerchantGasWalletsProps> = ({
  'data-test': dataTest,
  mode = 'inline',
}) => {
  const { forceRefresh, loading } = useMerchantGasWallets();
  return <OperationRefresh refresh={forceRefresh} data-test={dataTest} mode={mode} inProgress={loading} />;
};

const OperationRefreshMerchantGasWalletsMemo = React.memo(OperationRefreshMerchantGasWallets);

export default OperationRefreshMerchantGasWalletsMemo;
