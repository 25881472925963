import { Tooltip } from 'antd';
import React from 'react';

import { BrandingOnRamp } from '@/features/branding/types';

import { BrandingOnRampIcon } from './components';

import type { BrandingOnRampLabelProps } from './types';

const mapping: Record<BrandingOnRamp, string> = {
  [BrandingOnRamp.moonpay]: 'Moonpay',
  [BrandingOnRamp.mercuryo]: 'Mercuryo',
  [BrandingOnRamp['on-ramper']]: 'Onramper',
};

const BrandingOnRampLabel: React.FC<BrandingOnRampLabelProps> = ({ mode = 'medium', value, 'data-test': dataTest }) =>
  // eslint-disable-next-line no-nested-ternary
  mode === 'short' ? (
    <Tooltip title={mapping[value]}>
      <BrandingOnRampIcon data-test={dataTest} value={value} />
    </Tooltip>
  ) : mode === 'medium' ? (
    <>{mapping[value]}</>
  ) : (
    <>
      <BrandingOnRampIcon data-test={dataTest} value={value} />
      &nbsp;
      {mapping[value]}
    </>
  );

const BrandingOnRampLabelMemo = React.memo(BrandingOnRampLabel);

export default BrandingOnRampLabelMemo;
