import { useCallback } from 'react';

import useErrorHandler from '@/hooks/useErrorHandler';
import useSubmitting from '@/hooks/useSubmitting';
import type { Func } from '@/infrastructure/utils/ts';

export default function useErrorSafeSubmitting(): {
  submitting: boolean;
  error: string | undefined;
  withSubmitting: <V extends unknown[], R>(func: Func<V, R>) => Func<V, void>;
  withErrorHandling: <V extends unknown[], R>(func: Func<V, R>) => Func<V, void>;
  withReset: <V extends unknown[], R>(func: Func<V, R>) => Func<V, R>;
  reset: () => void;
} {
  const [submitting, withSubmitting, resetSubmit] = useSubmitting(false);
  const [error, withErrorHandling, resetError] = useErrorHandler();
  const reset = useCallback(() => {
    resetSubmit();
    resetError();
  }, [resetError, resetSubmit]);

  const withErrorSafeSubmitting = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) => withErrorHandling(withSubmitting(func)),
    [withErrorHandling, withSubmitting],
  );

  const withReset = useCallback(
    <V extends unknown[], R>(func: Func<V, R>) =>
      async (...params: V) => {
        try {
          return await func(...params);
        } finally {
          reset();
        }
      },
    [reset],
  );

  return { submitting, error, withSubmitting: withErrorSafeSubmitting, withErrorHandling, reset, withReset };
}
