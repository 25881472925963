import { shallowEqual } from 'react-redux';
import { createSelector } from 'reselect';

import type { LoadingStateWithDirty } from '@/infrastructure/model';
import { storedDirtyDataTyped } from '@/infrastructure/model';
import type { EmptyObject } from '@/infrastructure/utils/ts';

import type { SingleState } from './types';

export const createSingleSelectors = <
  Global extends EmptyObject,
  Type extends string,
  Value,
  Id = string,
  Key extends string = string,
>(
  getSingleState: (state: Global) => SingleState<Value>,
  type: Type,
  mapper: Id extends Key ? undefined : (id: Id) => Key,
) => {
  const makeSelectSingleStateName = `makeSelect${type}` as const;
  const makeSelectSingleDirtyName = `makeSelect${type}IsDirty` as const;
  const makeSelectSingleDataName = `makeSelect${type}Data` as const;
  const makeSelectMultipleStateName = `makeSelectMultiple${type}` as const;
  const makeSelectDirtyIdsName = `makeSelectDirty${type}Ids` as const;

  const toStored = (id: Id) => (typeof id === 'string' ? id : mapper!(id));

  const makeSelectSingleState =
    (id: Id) =>
    (state: Global): LoadingStateWithDirty<Value> =>
      getSingleState(state)[toStored(id)] ?? storedDirtyDataTyped();
  const makeSelectSingleDirty =
    (id: Id) =>
    (state: Global): boolean =>
      makeSelectSingleState(id)(state).isDirty;
  const makeSelectSingleData = (id: Id) => (state: Global) => makeSelectSingleState(id)(state).data;
  const makeSelectMultipleState = (ids: Id[]) => {
    let cache: (LoadingStateWithDirty<Value> | undefined)[] | undefined;
    let result: { id: Id; data: LoadingStateWithDirty<Value> }[] | undefined;
    return createSelector(getSingleState, (state) => {
      if (result) {
        const toCache = ids.map((id) => state[toStored(id)]);
        if (shallowEqual(toCache, cache)) {
          return result;
        }
      }
      cache = ids.map((id) => state[toStored(id)]);
      result = ids.map((id) => {
        const key = toStored(id);
        return { id, data: state[key] ?? storedDirtyDataTyped() };
      });
      return result;
    });
  };
  const makeSelectDirtyIds = (ids: Id[]) =>
    createSelector(getSingleState, (state) =>
      ids.filter((id) => {
        const storedId = toStored(id);
        return !state[storedId] || state[storedId].isDirty;
      }),
    );

  // FIXME: redefine the type without the cast
  return {
    [makeSelectSingleStateName]: makeSelectSingleState,
    [makeSelectSingleDirtyName]: makeSelectSingleDirty,
    [makeSelectSingleDataName]: makeSelectSingleData,
    [makeSelectMultipleStateName]: makeSelectMultipleState,
    [makeSelectDirtyIdsName]: makeSelectDirtyIds,
  } as Record<typeof makeSelectSingleStateName, typeof makeSelectSingleState> &
    Record<typeof makeSelectSingleDirtyName, typeof makeSelectSingleDirty> &
    Record<typeof makeSelectSingleDataName, typeof makeSelectSingleData> &
    Record<typeof makeSelectMultipleStateName, typeof makeSelectMultipleState> &
    Record<typeof makeSelectDirtyIdsName, typeof makeSelectDirtyIds>;
};
