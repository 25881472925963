import { FilterForm } from '@/components';
import type { MerchantWalletRunningBalanceFilterPredicate } from '@/features/merchant-wallet-balance/types';
import { nameof } from '@/infrastructure/utils/ts';

import { MerchantWalletLikeFilterItem, MerchantWalletRunningBalanceBroadcastAtRangeFilterItem } from './components';

import type { MerchantWalletRunningBalanceFilterFormProps } from './types';
import type React from 'react';

const fields = [
  nameof<MerchantWalletRunningBalanceFilterPredicate>('walletLike'),
  nameof<MerchantWalletRunningBalanceFilterPredicate>('broadcastAtRange'),
];

const MerchantWalletRunningBalanceFilterForm: React.FC<
  MerchantWalletRunningBalanceFilterFormProps<MerchantWalletRunningBalanceFilterPredicate>
> = (props) => {
  const { 'data-test': dataTest, readonly } = props;
  return (
    <FilterForm fields={fields} {...props}>
      <MerchantWalletLikeFilterItem
        data-test={dataTest && `${dataTest}-wallet`}
        name={nameof<MerchantWalletRunningBalanceFilterPredicate>('walletLike')}
        readonly={readonly}
      />
      <MerchantWalletRunningBalanceBroadcastAtRangeFilterItem<MerchantWalletRunningBalanceFilterPredicate>
        data-test={dataTest && `${dataTest}-broadcastAt`}
        name={nameof<MerchantWalletRunningBalanceFilterPredicate>('broadcastAtRange')}
        readonly={readonly}
        relativeUpdateKey="broadcastAtRangeRelative"
      />
    </FilterForm>
  );
};

export default MerchantWalletRunningBalanceFilterForm;
