/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* NCPS API Merchant Dashboard Gateway
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: local
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CheckCodeReqAPIModel
 */
export interface CheckCodeReqAPIModel {
    /**
     * 
     * @type {string}
     * @memberof CheckCodeReqAPIModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof CheckCodeReqAPIModel
     */
    code: string;
}

/**
 * Check if a given object implements the CheckCodeReqAPIModel interface.
 */
export function instanceOfCheckCodeReqAPIModel(value: object): boolean {
    if (!('email' in value)) return false;
    if (!('code' in value)) return false;
    return true;
}

export function CheckCodeReqAPIModelFromJSON(json: any): CheckCodeReqAPIModel {
    return CheckCodeReqAPIModelFromJSONTyped(json, false);
}

export function CheckCodeReqAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckCodeReqAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'code': json['code'],
    };
}

export function CheckCodeReqAPIModelToJSON(value?: CheckCodeReqAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': value['email'],
        'code': value['code'],
    };
}

