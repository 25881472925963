import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

import type { useIntl } from 'react-intl';

export const validatePrivateKey = (privateKey: string, formatMessage: ReturnType<typeof useIntl>['formatMessage']) => {
  if (privateKey.length !== 111) {
    throw new Error(formatMessage({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_ITEM_ERROR_INVALID }));
  }
  if (!privateKey.startsWith('xprv') && !privateKey.startsWith('tprv')) {
    throw new Error(formatMessage({ id: I18nFeatureDictionary.COMPONENTS_PRIVATE_KEY_ITEM_ERROR_INVALID }));
  }
};
