import { useCallback } from 'react';

import { useAppDispatch } from '@/app/hooks';
import { useActionPending } from '@/features/global/hooks';
import { createSubscriptionPlan } from '@/features/subscription-plans/actions';
import type { NewSubscriptionPlan, SubscriptionPlan } from '@/features/subscription-plans/types';
import type { HookAction } from '@/infrastructure/model';

export interface UseSubscriptionPlansActions {
  create: HookAction<[NewSubscriptionPlan], SubscriptionPlan>;
}

export default function useSubscriptionPlansActions(): UseSubscriptionPlansActions {
  const { withExtractDataDispatch } = useAppDispatch();
  const createAction: UseSubscriptionPlansActions['create']['act'] = useCallback(
    (plan: NewSubscriptionPlan) => withExtractDataDispatch(createSubscriptionPlan)(plan),
    [withExtractDataDispatch],
  );
  const creating = useActionPending(createSubscriptionPlan);
  const create = {
    act: createAction,
    available: true,
    inAction: creating,
  };

  return { create };
}
