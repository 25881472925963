import { useSettlementSchedule } from '@/features/settlements/hooks';
import type { SettlementSchedule } from '@/features/settlements/types';
import { namedHOC } from '@/infrastructure/utils/react';

import type React from 'react';

const emptyComponent = () => null;

const withSettlementScheduleLoad = <T extends { schedule: SettlementSchedule }>(
  Component: React.ComponentType<Omit<T, 'schedule'> & { schedule: SettlementSchedule }>,
  EmptyComponent: React.ComponentType<Omit<T, 'schedule'>>,
) =>
  namedHOC<Omit<T, 'schedule'> & { schedule: SettlementSchedule }, Omit<T, 'schedule'>>(
    Component,
    'WithSettlementScheduleLoad',
  )((props) => {
    const schedule = useSettlementSchedule();
    return schedule.data.data ? <Component {...props} schedule={schedule.data.data} /> : <EmptyComponent {...props} />;
  });

const withSettlementSchedule =
  <T extends { schedule: SettlementSchedule }>(
    EmptyComponent: React.ComponentType<Omit<T, 'schedule'>> = emptyComponent,
  ) =>
  (Component: React.ComponentType<Omit<T, 'schedule'> & { schedule: SettlementSchedule }>) => {
    const LoadComponent = withSettlementScheduleLoad<T>(Component, EmptyComponent);
    return namedHOC(
      Component,
      'WithSettlementSchedule',
    )((props: T | Omit<T, 'schedule'>) =>
      'schedule' in props ? <Component {...props} /> : <LoadComponent {...props} />,
    );
  };

export default withSettlementSchedule;
