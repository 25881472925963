import React from 'react';
import { useIntl } from 'react-intl';

import { FormattedMessage, InputItem } from '@/components';
import { I18nFeatureRecharges } from '@/generated/i18n/i18n';

import type { RechargeTransactionFromAddressFilterItemProps } from './types';
import type { Store } from 'rc-field-form/es/interface';

const messages = {
  label: <FormattedMessage id={I18nFeatureRecharges.COMPONENTS_RECHARGE_TRANSACTION_FILTER_FROM_ADDRESS_LABEL} />,
};

const RechargeTransactionFromAddressFilterItem = <Values extends Store = Store>({
  'data-test': dataTest,
  name,
  readonly,
  required,
  ItemProps = {},
}: RechargeTransactionFromAddressFilterItemProps<Values>) => {
  const { formatMessage } = useIntl();
  return (
    <InputItem<Values>
      data-test={dataTest}
      name={name}
      readonly={readonly}
      required={required}
      messages={messages}
      placeholder={formatMessage({
        id: I18nFeatureRecharges.COMPONENTS_RECHARGE_TRANSACTION_FILTER_FROM_ADDRESS_PLACEHOLDER,
      })}
      {...ItemProps}
    />
  );
};

const RechargeTransactionFromAddressFilterItemMemo = React.memo(
  RechargeTransactionFromAddressFilterItem,
) as typeof RechargeTransactionFromAddressFilterItem;

export default RechargeTransactionFromAddressFilterItemMemo;
