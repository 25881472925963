import { LinkOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React, { forwardRef } from 'react';

import { OperationTooltip } from '@/components/Operation/components';

import { DisableableLink } from './components';

import type { LinkProps } from './types';

const Link = forwardRef<HTMLAnchorElement, LinkProps>(function Link(
  {
    'data-test': dataTest,
    external,
    title,
    icon = <LinkOutlined />,
    to,
    disabled,
    disabledMessage,
    mode = 'text',
    className,
    style,
  },
  ref,
) {
  const testLocator = dataTest && `${dataTest}-action`;
  return (
    <>
      {mode === 'text_icon' && (
        <DisableableLink
          external={external}
          disabled={disabled}
          data-test={testLocator}
          to={to}
          ref={ref}
          className={className}
          style={style}
        >
          <OperationTooltip
            disabledMessage={disabledMessage}
            disabled={disabled}
            component={
              <span>
                {icon}
                &nbsp;
                {title}
              </span>
            }
          />
        </DisableableLink>
      )}
      {mode === 'text' && (
        <DisableableLink
          external={external}
          disabled={disabled}
          data-test={testLocator}
          to={to}
          ref={ref}
          className={className}
          style={style}
        >
          <OperationTooltip disabledMessage={disabledMessage} disabled={disabled} component={<span>{title}</span>} />
        </DisableableLink>
      )}
      {mode === 'inline' && (
        <DisableableLink
          external={external}
          disabled={disabled}
          data-test={testLocator}
          to={to}
          ref={ref}
          className={className}
          style={style}
        >
          <OperationTooltip title={title} disabledMessage={disabledMessage} disabled={disabled} component={icon} />
        </DisableableLink>
      )}
      {mode === 'icon' && (
        <DisableableLink
          external={external}
          disabled={disabled}
          data-test={testLocator}
          to={to}
          ref={ref}
          className={className}
          style={style}
        >
          <OperationTooltip
            title={title}
            disabledMessage={disabledMessage}
            disabled={disabled}
            component={<Button disabled={disabled} icon={icon} />}
          />
        </DisableableLink>
      )}
    </>
  );
});

const LinkMemo = React.memo(Link);

export default LinkMemo;
