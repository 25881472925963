import { useMemo } from 'react';

import type { UseAppSingleData } from '@/app/hooks';
import { useAppSingleData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchCollectTaskProcessTransaction } from '@/features/collectable/actions';
import { makeSelectCollectEntityProcessTransaction } from '@/features/collectable/selectors';
import type { CollectableEntityProcessTransaction } from '@/features/collectable/types';
import { makeSelectPending } from '@/features/global/selectors';
import { type LoadingStateWithDirty, storedDataError } from '@/infrastructure/model';

export type UseCollectTaskProcessTransaction = UseAppSingleData<CollectableEntityProcessTransaction>;

const noData = storedDataError<CollectableEntityProcessTransaction>('no-data');

const fetchFactory = (
  txId: string,
  force: boolean,
): AppAsyncThunkAction<
  LoadingStateWithDirty<CollectableEntityProcessTransaction>,
  Parameters<typeof fetchCollectTaskProcessTransaction>[0]
> => fetchCollectTaskProcessTransaction({ taskId: txId, force });

const useCollectTaskProcessTransaction = (taskId: string | undefined): UseCollectTaskProcessTransaction => {
  const dataFetchingSelector = useMemo(() => makeSelectPending(fetchCollectTaskProcessTransaction, taskId), [taskId]);
  return useAppSingleData(
    fetchFactory,
    makeSelectCollectEntityProcessTransaction,
    noData,
    taskId,
    dataFetchingSelector,
  );
};

export default useCollectTaskProcessTransaction;
