import React from 'react';

import { noop } from '@/infrastructure/utils/functions';

import type { PayoutDestinationTableEditContextType } from './types';

const PayoutDestinationTableEditContext = React.createContext<PayoutDestinationTableEditContextType>({
  formItemName: 'PayoutDestinationTableRow',
  isReady: false,
  setInitialValue: noop,
  setIsReady: noop,
  setEditingKey: noop,
});

export default PayoutDestinationTableEditContext;
