import React from 'react';

import { FormattedMessageWithMarkup } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import BrandingProfileEditLink from '@/pages/settings/branding/profile-edit/components/BrandingProfileEditLink';

import type { ActiveProfileDescriptionProps } from './types';

const ActiveProfileDescription: React.FC<ActiveProfileDescriptionProps> = ({ 'data-test': dataTest, value }) => (
  <FormattedMessageWithMarkup
    id={I18nPageSettings.BRANDING_SUMMARY_COMPONENTS_ACTIVE_PROFILE_CARD_ACTIVE}
    values={{
      active: value.name,
      update: (text: React.ReactNode) => (
        <BrandingProfileEditLink
          data-test={dataTest && `${dataTest}-updateOp`}
          mode="link"
          value={value.id}
          title={text}
        />
      ),
    }}
  />
);

const ActiveProfileDescriptionMemo = React.memo(ActiveProfileDescription);

export default ActiveProfileDescriptionMemo;
