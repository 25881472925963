import { PageContainer } from '@/components';
import { DonationsDocumentationBanner } from '@/features/donations/components';
import type { TestableProps } from '@/infrastructure/utils/react';

import { DonationCard, DonationTransactionBreadcrumb, DonationTransactionCard } from './components';
import { withDonationTransactionPageParams } from './hocs';

import type React from 'react';
import type { PropsWithChildren } from 'react';

type DonationTransactionViewPageProps = PropsWithChildren<TestableProps & { pageParams: { transactionId: string } }>;

const DonationTransactionViewPage: React.FC<DonationTransactionViewPageProps> = ({
  'data-test': dataTest = 'pt',
  pageParams: { transactionId },
}) => (
  <PageContainer
    data-test={dataTest && `${dataTest}-container`}
    breadcrumb={
      <DonationTransactionBreadcrumb
        data-test={dataTest && `${dataTest}-breadcrumb`}
        transactionId={transactionId}
        disabled
      />
    }
  >
    <DonationsDocumentationBanner data-test={dataTest && `${dataTest}-help`} pageSize="big" />
    <DonationTransactionCard transactionId={transactionId} data-test={dataTest && `${dataTest}-transaction`} />
    <DonationCard data-test={dataTest && `${dataTest}-donation`} transactionId={transactionId} />
  </PageContainer>
);

export default withDonationTransactionPageParams(DonationTransactionViewPage);
