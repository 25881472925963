import React from 'react';

import { FormattedMessage, HomeBreadcrumb } from '@/components';
import { I18nPageBalances } from '@/generated/i18n/i18n';

import type { BalancesBreadcrumbProps } from './types';

const BalancesBreadcrumb: React.FC<BalancesBreadcrumbProps> = ({ items = [], 'data-test': dataTest }) => (
  <HomeBreadcrumb
    data-test={dataTest}
    items={[{ title: <FormattedMessage id={I18nPageBalances.COMPONENTS_BREADCRUMB_TITLE} /> }, ...items]}
  />
);

const BalancesBreadcrumbMemo = React.memo(BalancesBreadcrumb);

export default BalancesBreadcrumbMemo;
