import { useCallback, useMemo } from 'react';

import { FormattedMessage, PageCard, PageContainer } from '@/components';
import { CreatePaymentForm } from '@/features/payments/components';
import { usePaymentsActions } from '@/features/payments/hooks';
import { I18nPagePayments } from '@/generated/i18n/i18n';
import {
  useDefaultNotification,
  useFirstRenderEffect,
  useFormPageAction,
  useNotification,
  useStateMountSafe,
} from '@/hooks';
import { noop, withOnSuccess } from '@/infrastructure/utils/functions';
import type { TestableProps } from '@/infrastructure/utils/react';
import type { Func } from '@/infrastructure/utils/ts';
import { goalReached, YMGoals } from '@/infrastructure/ym';
import { paymentsLink } from '@/pages/payments/routes';

import { PaymentAddBreadcrumb } from './components';

import type React from 'react';

type PaymentAddPageProps = TestableProps;

const PaymentAddPage: React.FC<PaymentAddPageProps> = ({ 'data-test': dataTest = 'payment-add' }) => {
  const { withSuccess } = useNotification();
  const { withDefaultError } = useDefaultNotification();
  const { create: createAction } = usePaymentsActions();
  const { redirectOnSubmit } = useFormPageAction();
  const doRedirect = useMemo<Func>(() => redirectOnSubmit(paymentsLink()), [redirectOnSubmit]);
  const [onSubmit, setOnSubmit] = useStateMountSafe<Func | undefined>();
  const updateOnSubmit = useCallback((func?: Func) => setOnSubmit(() => func), [setOnSubmit]);

  useFirstRenderEffect(() => {
    goalReached(YMGoals.INVOICE_CREATE_INIT);
  });

  const doCreate = useMemo(
    () =>
      withOnSuccess(
        withDefaultError(
          withSuccess(createAction.act, () => <FormattedMessage id={I18nPagePayments.PAYMENT_ADD_MESSAGES_SUCCESS} />),
          () => <FormattedMessage id={I18nPagePayments.PAYMENT_ADD_MESSAGES_ERROR} />,
        ),
        () => {
          goalReached(YMGoals.INVOICE_CREATED);
          doRedirect();
        },
      ),
    [withDefaultError, withSuccess, doRedirect, createAction],
  );
  const submit = useMemo(() => ({ onClick: onSubmit ?? noop, disabled: !onSubmit }), [onSubmit]);

  return (
    <PageContainer
      data-test={dataTest}
      breadcrumb={<PaymentAddBreadcrumb data-test={dataTest && `${dataTest}-breadcrumb`} disabled />}
      submit={submit}
      cancel={doRedirect}
    >
      <PageCard>
        <CreatePaymentForm
          data-test={dataTest && `${dataTest}-form`}
          onSubmit={doCreate}
          onReset={doRedirect}
          submitCallback={updateOnSubmit}
        />
      </PageCard>
    </PageContainer>
  );
};

export default PaymentAddPage;
