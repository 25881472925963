import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { useAuthWeb3Actions } from '@/features/auth/hooks';
import type { WalletOperationClientAction } from '@/features/web3/components';
import { WalletOperation } from '@/features/web3/components';
import { withWeb3Connector } from '@/features/web3/hocs';
import { useWeb3Connector } from '@/features/web3/hooks';
import { I18nFeatureAuth } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { emptyWith, withOnSuccess } from '@/infrastructure/utils/functions';
import { type Cancellable, isRejected } from '@/infrastructure/utils/ui';

import type { OperationDisconnectWeb3AuthWithConnectorProps } from './types';

const notificationOptions = {
  error: (e: unknown) =>
    !isRejected(e)
      ? {
          message: (
            <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_FAILED_MESSAGE} />
          ),
          description: (
            <FormattedMessage
              id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_FAILED_DESCRIPTION}
            />
          ),
        }
      : undefined,
  success: () => ({
    message: (
      <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_SUCCESS_MESSAGE} />
    ),
    description: (
      <FormattedMessage id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_SUCCESS_DESCRIPTION} />
    ),
  }),
};

const OperationDisconnectWeb3AuthWithConnector: React.FC<OperationDisconnectWeb3AuthWithConnectorProps> = ({
  'data-test': dataTest,
  connectorId,
  state,
  onSuccess,
  withInProgress = emptyWith,
}) => {
  const { disconnect } = useAuthWeb3Actions();
  const { connector } = useWeb3Connector();
  const { withNotification } = useNotification();

  const doDisconnect = useMemo<WalletOperationClientAction>(
    () =>
      withNotification(
        withOnSuccess(
          withInProgress(({ client }, cancellation?: Cancellable) =>
            disconnect.act({ client }, { cancellationPromise: cancellation }),
          ),
          onSuccess,
        ),
        notificationOptions,
      ),
    [withNotification, withInProgress, onSuccess, disconnect],
  );

  return (
    <WalletOperation
      data-test={dataTest}
      connectorId={connectorId}
      requiredAccount={state.address}
      showAccount
      disabled={!disconnect.available}
      inProgress={disconnect.inAction}
      mainAction={useMemo(
        () => ({
          onAction: doDisconnect,
          title: (
            <FormattedMessage
              id={I18nFeatureAuth.COMPONENTS_OPERATION_DISCONNECT_WEB3AUTH_CONNECTOR_TITLE}
              values={{ connector: connector.name }}
            />
          ),
        }),
        [connector.name, doDisconnect],
      )}
    />
  );
};

const OperationDisconnectWeb3AuthWithConnectorContexted = withWeb3Connector(OperationDisconnectWeb3AuthWithConnector);

const OperationDisconnectWeb3AuthWithConnectorMemo = React.memo(
  OperationDisconnectWeb3AuthWithConnectorContexted,
) as typeof OperationDisconnectWeb3AuthWithConnectorContexted;

export default OperationDisconnectWeb3AuthWithConnectorMemo;
