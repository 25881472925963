import { createSelector } from 'reselect';

import type { AppRootState } from '@/app/store';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { flatmapStoredState } from '@/infrastructure/model';
import { createLoadingDataSelectors } from '@/infrastructure/model/common/selectors';
import { isSameAddress } from '@/infrastructure/utils/web3/address';

import { NAMESPACE } from './types';

export const { makeSelectUser: baseMakeSelectUser } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].user,
  'User',
);

export const makeSelectUser = () =>
  createSelector(makeSelectAuthToken(), baseMakeSelectUser(), (authState, userState) =>
    flatmapStoredState(userState, (user) => ({
      data: user,
      isDirty: !isSameAddress(user.address, authState.data?.info.address),
    })),
  );

export const { makeSelectPreferences } = createLoadingDataSelectors(
  (state: AppRootState) => state[NAMESPACE].preferences,
  'Preferences',
);
