import React from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettings } from '@/generated/i18n/i18n';
import UpdateActiveAssetsLink from '@/pages/settings/company/assets-edit/components/UpdateActiveAssetsLink';
import CompanyBreadcrumb from '@/pages/settings/company/components/CompanyBreadcrumb';

import type { UpdateActiveAssetsBreadcrumbProps } from './types';

const UpdateActiveAssetsBreadcrumb: React.FC<UpdateActiveAssetsBreadcrumbProps> = ({
  items = [],
  disabled,
  'data-test': dataTest,
}) => (
  <CompanyBreadcrumb
    data-test={dataTest}
    items={[
      {
        title: disabled ? (
          <FormattedMessage id={I18nPageSettings.COMPANY_ASSETS_EDIT_BREADCRUMB_TITLE} />
        ) : (
          <UpdateActiveAssetsLink
            mode="link"
            title={<FormattedMessage id={I18nPageSettings.COMPANY_ASSETS_EDIT_BREADCRUMB_TITLE} />}
          />
        ),
      },
      ...items,
    ]}
  />
);

const UpdateActiveAssetsBreadcrumbMemo = React.memo(UpdateActiveAssetsBreadcrumb);

export default UpdateActiveAssetsBreadcrumbMemo;
