import type { UseAppListData } from '@/app/hooks';
import { useAppListData } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { fetchDonationTransactions } from '@/features/donations/actions';
import {
  makeSelectDonationTransactionListData,
  makeSelectDonationTransactionListParameters,
} from '@/features/donations/selectors';
import type { DonationTransaction, DonationTransactionFilterPredicate } from '@/features/donations/types';
import { makeSelectPending } from '@/features/global/selectors';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { LoadingListDataState } from '@/infrastructure/model/list/types';

export type UseDonationTransactionList = UseAppListData<
  DonationTransaction,
  DonationTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingListDataState<DonationTransaction>, Parameters<typeof fetchDonationTransactions>[0]> =>
  fetchDonationTransactions({ force });

const dataSelector = makeSelectDonationTransactionListData();
const dataFetchingSelector = makeSelectPending(fetchDonationTransactions);
const parametersSelector = makeSelectDonationTransactionListParameters();

export default function useDonationTransactionList(): UseDonationTransactionList {
  return useAppListData(fetchFactory, parametersSelector, dataFetchingSelector, dataSelector);
}
