import React from 'react';

import { FormattedMessage, Link } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { settlementIntentViewLink } from '@/pages/settlements/routes';

import type { SettlementIntentLinkProps } from './types';

const SettlementIntentLink: React.FC<SettlementIntentLinkProps> = ({ value, title, ...props }) => (
  <Link
    to={settlementIntentViewLink(value)}
    title={title ?? <FormattedMessage id={I18nPageSettlements.INTENT_VIEW_COMPONENTS_LINK_TITLE} />}
    {...props}
  />
);

const SettlementIntentLinkMemo = React.memo(SettlementIntentLink);

export default SettlementIntentLinkMemo;
