import React from 'react';

import {
  OperationExportReconciliationBySettlement,
  SettlementFilterForm,
  SettlementsTable,
} from '@/features/settlements/components';
import { useSettlementListView } from '@/features/settlements/hooks';

import type { SettlementsFullTableProps } from './types';

const SettlementsFullTable: React.FC<SettlementsFullTableProps> = (props) => {
  const view = useSettlementListView();
  return (
    <SettlementsTable
      FilterForm={SettlementFilterForm}
      ExportOperation={OperationExportReconciliationBySettlement}
      {...view}
      {...props}
    />
  );
};

const SettlementsFullTableMemo = React.memo(SettlementsFullTable);

export default SettlementsFullTableMemo;
