import { Tooltip } from 'antd';
import React from 'react';

import { FormattedMessage } from '@/components';
import { KYBRequiredTooltip } from '@/features/company/components';
import { useActiveCompany } from '@/features/company/hooks';
import type { NetworkTooltipProps } from '@/features/dictionary/blockchain/components/NetworkLabel/types';
import OperationSelectNetwork from '@/features/dictionary/blockchain/components/OperationSelectNetwork';
import { BlockchainNetworkTypeAPIModel } from '@/generated/api/ncps-core/merchant-bo';
import type { I18nMessages } from '@/generated/i18n/i18n';
import { I18nFeatureDictionary } from '@/generated/i18n/i18n';

const shortI18n: Record<BlockchainNetworkTypeAPIModel, I18nMessages> = {
  [BlockchainNetworkTypeAPIModel.TestNet]: I18nFeatureDictionary.COMPONENTS_NETWORK_LABEL_TOOLTIP_SHORT_TESTNET,
  [BlockchainNetworkTypeAPIModel.MainNet]: I18nFeatureDictionary.COMPONENTS_NETWORK_LABEL_TOOLTIP_SHORT_MAINNET,
};

const fullI18n: Record<BlockchainNetworkTypeAPIModel, I18nMessages> = {
  [BlockchainNetworkTypeAPIModel.TestNet]: I18nFeatureDictionary.COMPONENTS_NETWORK_LABEL_TOOLTIP_FULL_TESTNET,
  [BlockchainNetworkTypeAPIModel.MainNet]: I18nFeatureDictionary.COMPONENTS_NETWORK_LABEL_TOOLTIP_FULL_MAINNET,
};

const NetworkTooltip: React.FC<NetworkTooltipProps> = ({ value, mode, containerMode, noSwitcher, children }) => {
  // FIXME: this is a dirty hack, there should be no dependency from settings here
  const {
    data: { data: company },
  } = useActiveCompany();
  return company?.isKYBFinished ? (
    <Tooltip
      title={
        <FormattedMessage
          id={mode === 'full' ? fullI18n[value] : shortI18n[value]}
          values={{ switch: !noSwitcher ? <OperationSelectNetwork mode="icon" /> : undefined }}
        />
      }
    >
      <span>{children}</span>
    </Tooltip>
  ) : (
    <KYBRequiredTooltip mode={mode === 'short' || containerMode === 'short' ? 'short' : 'full'}>
      {children}
    </KYBRequiredTooltip>
  );
};

const NetworkTooltipMemo = React.memo(NetworkTooltip);

export default NetworkTooltipMemo;
