import { useAppLoadableData, useAppSelector } from '@/app/hooks';
import type { AppAsyncThunkAction } from '@/app/store';
import { makeSelectAuthToken } from '@/features/auth/selectors';
import { makeSelectPending } from '@/features/global/selectors';
import { fetchUser } from '@/features/user/actions';
import { makeSelectUser } from '@/features/user/selectors';
import type { User } from '@/features/user/types';
import type { LoadingStateWithDirty } from '@/infrastructure/model';

const dataFetchingSelector = makeSelectPending(fetchUser);
const fetchFactory = (
  force: boolean,
): AppAsyncThunkAction<LoadingStateWithDirty<User>, Parameters<typeof fetchUser>[0]> => fetchUser({ force });
const dataSelector = makeSelectUser();
const authTokenSelector = makeSelectAuthToken();

export default function useUser() {
  const { data: token } = useAppSelector(authTokenSelector);
  return useAppLoadableData(fetchFactory, dataSelector, dataFetchingSelector, !!token);
}
