import { Tooltip, Typography } from 'antd';
import React from 'react';

import type { AddressViewProps } from './types';

const { Text } = Typography;

const AddressView: React.FC<AddressViewProps> = ({ 'data-test': dataTest, address, children }) => (
  <Tooltip
    title={
      <Text className="ym-hide-content" style={{ color: '#fff' }} copyable>
        {address}
      </Text>
    }
  >
    <div
      className="ym-hide-content"
      data-test={dataTest}
      style={{
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {children || address}
    </div>
  </Tooltip>
);

const AddressViewMemo = React.memo(AddressView);

export default AddressViewMemo;
