import { useEffect } from 'react';

import { PageLoading } from '@/components';
import { useAuthActions } from '@/features/auth/hooks';
import { withSuppressPromise } from '@/infrastructure/utils/functions';
import { namedHOC } from '@/infrastructure/utils/react';
import type { EmptyObject } from '@/infrastructure/utils/ts';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const withJWTInit = <T extends EmptyObject>(Component: React.ComponentType<T>): React.FC<T> =>
  namedHOC(
    Component,
    'WithJWTInit',
  )((props) => {
    const { tryInitJWT } = useAuthActions();
    const isJWTInitialized = !tryInitJWT.available && !tryInitJWT.inAction;
    useEffect(() => {
      if (tryInitJWT.available && !tryInitJWT.inAction) {
        withSuppressPromise(tryInitJWT.act)();
      }
    }, [tryInitJWT]);

    if (!isJWTInitialized) {
      return (
        <MessageLayout>
          <PageLoading type="withJWTInit" />
        </MessageLayout>
      );
    }

    return <Component {...props} />;
  });

export default withJWTInit;
