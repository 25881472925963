import { PageLoading } from '@/components';
import CookiesAgreementBanner from '@/features/global/components/CookiesAgreementBanner';
import { useCookiesAgreement } from '@/features/global/hooks';
import { namedHOC } from '@/infrastructure/utils/react';
import MessageLayout from '@/layouts/MessageLayout';

import type React from 'react';

const withCookiesAgreement = <P extends Record<string, unknown>>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithCookiesAgreement',
  )((props) => {
    const { accepted, initialized, accept, strict } = useCookiesAgreement();
    if (!initialized) {
      return (
        <MessageLayout>
          <PageLoading type="CookiesAgreementWrapper" />
        </MessageLayout>
      );
    }

    return (
      <CookiesAgreementBanner accepted={accepted} data-test="cookies-banner" strict={strict} onAccept={accept}>
        <Component {...props} />
      </CookiesAgreementBanner>
    );
  });

export default withCookiesAgreement;
