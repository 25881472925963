/* tslint:disable */
/* eslint-disable */
/* eslint-disable */
// @ts-nocheck
/**
* core-service
* No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
*
* The version of the OpenAPI document: 0.14.8-3517
* 
*
* NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
* https://openapi-generator.tech
* Do not edit the class manually.
*/

import { mapValues } from '../runtime';
import type { PushCollectSortByAPIModel } from './PushCollectSortByAPIModel';
import {
    PushCollectSortByAPIModelFromJSON,
    PushCollectSortByAPIModelFromJSONTyped,
    PushCollectSortByAPIModelToJSON,
} from './PushCollectSortByAPIModel';

/**
 * 
 * @export
 * @interface SortAttributePushCollectSortByAPIModel
 */
export interface SortAttributePushCollectSortByAPIModel {
    /**
     * 
     * @type {PushCollectSortByAPIModel}
     * @memberof SortAttributePushCollectSortByAPIModel
     */
    attr: PushCollectSortByAPIModel;
    /**
     * 
     * @type {boolean}
     * @memberof SortAttributePushCollectSortByAPIModel
     */
    asc: boolean;
}

/**
 * Check if a given object implements the SortAttributePushCollectSortByAPIModel interface.
 */
export function instanceOfSortAttributePushCollectSortByAPIModel(value: object): boolean {
    if (!('attr' in value)) return false;
    if (!('asc' in value)) return false;
    return true;
}

export function SortAttributePushCollectSortByAPIModelFromJSON(json: any): SortAttributePushCollectSortByAPIModel {
    return SortAttributePushCollectSortByAPIModelFromJSONTyped(json, false);
}

export function SortAttributePushCollectSortByAPIModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): SortAttributePushCollectSortByAPIModel {
    if (json == null) {
        return json;
    }
    return {
        
        'attr': PushCollectSortByAPIModelFromJSON(json['attr']),
        'asc': json['asc'],
    };
}

export function SortAttributePushCollectSortByAPIModelToJSON(value?: SortAttributePushCollectSortByAPIModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'attr': PushCollectSortByAPIModelToJSON(value['attr']),
        'asc': value['asc'],
    };
}

