import React, { useCallback } from 'react';

import { FormattedMessage, Operation } from '@/components';
import { donationStatusIcons } from '@/features/donations/components/DonationStatusView';
import { useDonationActions } from '@/features/donations/hooks';
import { I18nCommon, I18nFeatureDonations } from '@/generated/i18n/i18n';
import { useNotification } from '@/hooks';
import { withSuppressError } from '@/infrastructure/utils/functions';

import type { OperationUpdateDonationStatusProps } from './types';

const notificationOptions = {
  error: () => <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_ERROR_MESSAGE} />,
  success: () => (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_SUCCESS_MESSAGE} />
  ),
};

const OperationUpdateDonationStatus: React.FC<OperationUpdateDonationStatusProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  donationId,
}) => {
  const { withNotification } = useNotification();
  const {
    data: { data: donation },
    updateStatus: updateStatusAction,
  } = useDonationActions(donationId);

  const isActive = donation?.isActive;

  const doUpdateStatus = useCallback(
    () => withSuppressError(withNotification(updateStatusAction.act, notificationOptions))(!isActive),
    [withNotification, updateStatusAction.act, isActive],
  );

  const title = isActive ? (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_DEACTIVATE_TITLE} />
  ) : (
    <FormattedMessage id={I18nFeatureDonations.COMPONENTS_OPERATION_CHANGE_DONATION_STATUS_ACTIVATE_TITLE} />
  );
  const icon = donationStatusIcons[`${!isActive}`];

  const disabledMessage =
    updateStatusAction.unavailabilityReason === 'no-data' ? (
      <FormattedMessage id={I18nCommon.MESSAGES_LOADING} />
    ) : undefined;

  return (
    <Operation
      data-test={dataTest}
      title={title}
      icon={icon}
      onClick={doUpdateStatus}
      disabled={!!disabledMessage}
      disabledMessage={disabledMessage}
      inProgress={updateStatusAction.inAction}
      mode={mode}
    />
  );
};

const OperationUpdateDonationStatusMemo = React.memo(OperationUpdateDonationStatus);

export default OperationUpdateDonationStatusMemo;
