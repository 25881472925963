import { css } from '@emotion/css';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';

import { useLocale } from '@/features/i18n/hooks';
import { I18nLocales } from '@/generated/i18n/i18n';
import { namedHOC } from '@/infrastructure/utils/react';

import type { Locale } from 'antd/es/locale';
import type React from 'react';
import type { PropsWithChildren } from 'react';

const antdLocale: Record<I18nLocales, Locale> = {
  [I18nLocales['en-US']]: enUS,
};

const listTheme = {
  className: css`
    li.ant-list-item > div.ant-list-item-meta {
      align-items: center;
    }
  `,
};

const withTheme = <P extends PropsWithChildren>(Component: React.FC<P>): React.FC<P> =>
  namedHOC(
    Component,
    'WithTheme',
  )((props) => {
    const { locale } = useLocale();
    return (
      <ConfigProvider list={listTheme} locale={antdLocale[locale]}>
        <Component {...props} />
      </ConfigProvider>
    );
  });

export default withTheme;
