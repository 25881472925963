import React, { useCallback, useMemo } from 'react';

import CommonReadonlyComponent from '@/components/ReadonlyComponent';
import type { ReadonlyComponentProps } from '@/components/SelectWithReloadingItem/types';

const DefaultReadonlyComponent = <T extends number | string = string>({
  'data-test': dataTest,
  value,
  options,
}: ReadonlyComponentProps<T>) => {
  const singleDataFactory = useCallback(
    (singleValue: ReadonlyComponentProps<T>['value']) =>
      options.data?.find((opt) => opt.value === singleValue)?.label || singleValue,
    [options.data],
  );
  const data = useMemo(() => {
    if (!value) {
      return undefined;
    }
    return Array.isArray(value) ? (
      <>
        {value.map((v, idx) => (
          <React.Fragment key={v}>
            {singleDataFactory(v)}
            {idx < value.length - 1 && ', '}
          </React.Fragment>
        ))}
      </>
    ) : (
      singleDataFactory(value)
    );
  }, [singleDataFactory, value]);

  return <CommonReadonlyComponent data-test={dataTest} value={data} />;
};

const DefaultReadonlyComponentMemo = React.memo(DefaultReadonlyComponent) as typeof DefaultReadonlyComponent;

export default DefaultReadonlyComponentMemo;
