import React from 'react';

import { FormattedMessage } from '@/components';
import { useRechargeAddress } from '@/features/recharges/hooks';
import { I18nPageRecharges } from '@/generated/i18n/i18n';
import RechargeAddressesBreadcrumb from '@/pages/recharges/addresses/components/RechargeAddressesBreadcrumb';

import RechargeAddressViewLink from '../RechargeAddressViewLink';

import type { RechargeAddressViewBreadcrumbProps } from './types';

const RechargeAddressViewBreadcrumb: React.FC<RechargeAddressViewBreadcrumbProps> = ({
  'data-test': dataTest,
  addressId,
  disabled,
  items = [],
}) => {
  const { data } = useRechargeAddress(addressId);
  const address = data.data?.address ?? addressId;
  return (
    <RechargeAddressesBreadcrumb
      data-test={dataTest}
      items={[
        {
          title: disabled ? (
            <FormattedMessage id={I18nPageRecharges.ADDRESS_VIEW_COMPONENTS_BREADCRUMB} values={{ id: address }} />
          ) : (
            <RechargeAddressViewLink
              data-test={dataTest && `${dataTest}-link`}
              mode="text"
              value={addressId}
              title={
                <FormattedMessage id={I18nPageRecharges.ADDRESS_VIEW_COMPONENTS_BREADCRUMB} values={{ id: address }} />
              }
            />
          ),
        },
        ...items,
      ]}
    />
  );
};

const RechargeAddressViewBreadcrumbMemo = React.memo(RechargeAddressViewBreadcrumb);

export default RechargeAddressViewBreadcrumbMemo;
