import { createAction } from '@reduxjs/toolkit';

import type { CommonLoadingState } from '@/infrastructure/model';

// TODO: If the Value is object, the default Type should be name of this object - but how to realize it in TS?
export const createLoadingDataActions = <Value, Type extends string, Exc = string>(namespace: string, type: Type) => {
  const markDirtyName = `mark${type}Dirty` as const;
  const storeDataName = `store${type}` as const;

  const markDirty = createAction(`${namespace}/${markDirtyName}`);
  const storeData = createAction<CommonLoadingState<Value, Exc>>(`${namespace}/${storeDataName}`);

  // FIXME: redefine the type without the cast
  return {
    [markDirtyName]: markDirty,
    [storeDataName]: storeData,
  } as Record<typeof markDirtyName, typeof markDirty> & Record<typeof storeDataName, typeof storeData>;
};
