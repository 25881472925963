import React, { useMemo } from 'react';

import { FormattedMessage } from '@/components';
import { I18nPageSettlements } from '@/generated/i18n/i18n';
import { PayoutViewBreadcrumb } from '@/pages/settlements/payouts/payout-view/components';

import PayoutBatchViewLink from '../PayoutBatchViewLink';

import type { PayoutBatchViewBreadcrumbProps } from './types';

const PayoutBatchViewBreadcrumb: React.FC<PayoutBatchViewBreadcrumbProps> = ({
  'data-test': dataTest,
  payoutId,
  batchNum,
  disabled,
  items = [],
}) => {
  const id = useMemo(() => ({ payoutId, batchNum }), [batchNum, payoutId]);
  return (
    <PayoutViewBreadcrumb
      data-test={dataTest}
      payoutId={payoutId}
      items={[
        {
          title: <FormattedMessage id={I18nPageSettlements.PAYOUT_BATCHES_VIEW_COMPONENTS_BREADCRUMB} />,
        },
        {
          title: disabled ? (
            <FormattedMessage
              id={I18nPageSettlements.PAYOUT_BATCH_VIEW_COMPONENTS_BREADCRUMB}
              values={{ num: batchNum }}
            />
          ) : (
            <PayoutBatchViewLink
              data-test={dataTest && `${dataTest}-link`}
              mode="text"
              value={id}
              title={
                <FormattedMessage
                  id={I18nPageSettlements.PAYOUT_BATCH_VIEW_COMPONENTS_BREADCRUMB}
                  values={{ num: payoutId }}
                />
              }
            />
          ),
        },
        ...items,
      ]}
    />
  );
};

const PayoutBatchViewBreadcrumbMemo = React.memo(PayoutBatchViewBreadcrumb);

export default PayoutBatchViewBreadcrumbMemo;
