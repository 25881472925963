import { type UseAppListDataView, useAppNestedListDataView } from '@/app/hooks';
import { storeRechargeTransactionForAddressListParameters } from '@/features/recharges/actions';
import type { RechargeTransaction, RechargeTransactionFilterPredicate } from '@/features/recharges/types';
import type { PushTransactionSortByAPIModel } from '@/generated/api/ncps-core/merchant-bo';

import useRechargeTransactionsForAddressList from './useRechargeTransactionsForAddressList';

export type UseRechargeTransactionsForAddressListView = UseAppListDataView<
  RechargeTransaction,
  RechargeTransactionFilterPredicate,
  PushTransactionSortByAPIModel
>;

export default function useRechargeTransactionsForAddressListView(
  addressId: string | undefined,
): UseRechargeTransactionsForAddressListView {
  return useAppNestedListDataView(
    useRechargeTransactionsForAddressList(addressId),
    storeRechargeTransactionForAddressListParameters,
    addressId,
  );
}
