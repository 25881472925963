import { Col, ColorPicker, Row } from 'antd';
import React, { useCallback } from 'react';

import { FormattedMessage } from '@/components';
import { I18nFeatureBranding } from '@/generated/i18n/i18n';

import type { AccentColorSelectProps } from './types';
import type { AggregationColor } from 'antd/es/color-picker/color';
import type { PresetsItem } from 'antd/es/color-picker/interface';

const colors: PresetsItem[] = [
  {
    label: <FormattedMessage id={I18nFeatureBranding.COMPONENTS_DATA_EDIT_PANEL_COLORS_BLOCK_PRESETS_LABEL} />,
    colors: ['#0000FF', '#00FFFF', '#008000', '#FF8C00', '#FFB9B4', '#808080'],
  },
];

const AccentColorSelect: React.FC<AccentColorSelectProps> = ({ 'data-test': dataTest, value, onChange, disabled }) => {
  const doChange = useCallback((color?: AggregationColor) => onChange?.(color?.toHexString()), [onChange]);

  return (
    <Row gutter={[8, 8]}>
      <Col>
        <ColorPicker
          data-test={dataTest}
          value={value}
          allowClear
          format="hex"
          presets={colors}
          disabledAlpha
          onChangeComplete={doChange}
          onClear={doChange}
          disabled={disabled}
        />
      </Col>
    </Row>
  );
};

const AccentColorSelectMemo = React.memo(AccentColorSelect);

export default AccentColorSelectMemo;
