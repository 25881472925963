import React from 'react';

import type { OperationRefreshProps } from '@/components';
import { FormattedMessage, OperationRefresh } from '@/components';
import { useReportSchedule } from '@/features/report-schedules/hooks';
import { I18nFeatureReportSchedules } from '@/generated/i18n/i18n';

import type { OperationRefreshReportScheduleProps } from './types';

const messages: OperationRefreshProps['messages'] = {
  tooltip: <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_REFRESH_TOOLTIP} />,
  success: {
    message: <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_REFRESH_SUCCESS_MESSAGE} />,
  },
  error: { message: <FormattedMessage id={I18nFeatureReportSchedules.COMPONENTS_OPERATION_REFRESH_FAILED_MESSAGE} /> },
};

const OperationRefreshReportScheduleRaw: React.FC<OperationRefreshReportScheduleProps> = ({
  'data-test': dataTest,
  mode = 'inline',
  scheduleId,
}) => {
  const { forceRefresh } = useReportSchedule(scheduleId);

  return <OperationRefresh data-test={dataTest} mode={mode} refresh={forceRefresh} messages={messages} />;
};

const OperationRefreshReportSchedule = React.memo(OperationRefreshReportScheduleRaw);

export default OperationRefreshReportSchedule;
